import React from 'react'
import { Button } from '@material-ui/core';
import { CreateCSSProperties, styled } from '@material-ui/styles';
import DeleteIcon from '@material-ui/icons/Delete'
const CloseIcon = require('./ic_close.png')
const pdfIcon = require('./pdf.png')
const excelIcon = require('./ic_xlsx_icon.png')
const docIcon = require('./ic_doc.png')
const imageIcon = require('./ic_image_icon.png')
import { toast } from 'react-toastify';
interface FilePreviewProps {
  file: File,
  onRemove: (e: any) => void,
  filetype?: string,
  filename?: string
  isCorporate?: boolean,
  isCardDisable?:boolean
}

const FilePreview = ({ file, onRemove, filetype, filename, isCorporate, isCardDisable }: FilePreviewProps) => {
  const CloseButton = styled(Button)({
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 1010
  })
  let url, ftype, fname
  if (typeof file === "string") {
    url = file
    ftype = filetype
    fname = filename
  } else if (isCorporate !== undefined) {
    ftype = file.type
    fname = file?.name
  }
  else {
    url = URL.createObjectURL(file);
    ftype = file.type
    fname = file?.name
  }
  const clipEllipses = (name: string) => (name.slice(0, 30) + ((name.length >= 30) ? "..." : ""))
  const openNewWindow = (fileUrl:string) => {
    const newWindowTab = window.open((fileUrl), "_blank");
    if (newWindowTab) {
      newWindowTab.focus();
    } else {
        toast.error("Not able to open file in a new window.")
    }
  }
  const handleDownloadClick = () => {
    const downloadFile:any = file
    if (downloadFile instanceof File) {
        const fileBlob = new Blob([downloadFile], { type: downloadFile.type });
        const fileUrl = URL.createObjectURL(fileBlob);
        openNewWindow(fileUrl)
        URL.revokeObjectURL(fileUrl);
      } else {
        openNewWindow(downloadFile?.url)
      }
}
const getFileIcon = (fname:string | undefined) => {
  if (fname?.includes('.pdf')) {
    return pdfIcon;
  } else if (fname?.includes('.xlsx')) {
    return excelIcon;
  } else if (fname?.includes('.doc')) {
    return docIcon;
  } else {
    return imageIcon;
  }
};

  let previewTile: any
  if (ftype?.startsWith?.('image') && !isCorporate)
    previewTile = <PreviewImage alt={fname} src={url} />
  else if (ftype?.startsWith?.('video'))
    previewTile = <PreviewVideo><source src={url} /></PreviewVideo>
  else if (isCorporate !== undefined)
    previewTile = <PreviewDoc>{clipEllipses(fname || "")}</PreviewDoc>
  else if (ftype?.startsWith?.('application') && !isCorporate)
    previewTile = <PreviewDocument>{clipEllipses(fname || "")}</PreviewDocument>
  else
    previewTile = <PreviewDocument>{clipEllipses(fname || "")}</PreviewDocument>
  return isCorporate && isCorporate !== undefined ?
    <div style={{ position: 'relative', width: 'max-content', display: 'flex' }}>
      <div style={{cursor:"pointer"}} onClick={handleDownloadClick}>{previewTile}</div>
          <img src={getFileIcon(fname)} alt={'Uploaded document'} style={{width: '24px', paddingLeft: '15px', paddingRight: '15px', cursor:"pointer"}} onClick={handleDownloadClick}/>
          {!isCardDisable && <DeleteIcon data-filename={fname} onClick={() => onRemove(file)} style={{ cursor: "pointer" }}/>}    
    </div>
    :
    <div style={{ position: 'relative', width: 'max-content' }}>
      <CloseButton data-filename={fname} onClick={onRemove}><img src={CloseIcon} width="26px" /></CloseButton>
      <div>{previewTile}</div>
    </div>
}


export default FilePreview

const PreviewTileStyle: CreateCSSProperties<{}> = {
  width: '100px',
  height: '150px',
  borderRadius: '10px',
  objectFit: 'cover',
  padding: '0px 5px',

}

const PreviewImage = styled('img')({
  ...PreviewTileStyle
})

const PreviewVideo = styled('video')({
  ...PreviewTileStyle
})

const PreviewDocument = styled('div')({
  background: 'rgba(0,0,0,0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  wordBreak: 'break-all',
  ...PreviewTileStyle
})

const PreviewDoc = styled('div')({
  color: '#17769b'
})

// @ts-ignore
const PreviewRow: any = styled('div')({
  display: 'flex',
  gap: '25px',
  flexWrap: 'wrap'
})

export {
  PreviewRow
}
// Customizable Area Start
import React from "react";

import {
    Button,
    Typography,
    TextField,
    InputAdornment,
    Menu,
    MenuItem,
    List,
    ListItem,
    ListItemText,
    Slide,
} from '@material-ui/core';
import {
    SearchIcon,
} from './assets';

import { withStyles, styled, makeStyles } from '@material-ui/styles';
import { useWindowSize } from '../../../components/src/utils';
import { ArrowRight, ArrowBackIos } from '@material-ui/icons';
import { CloseCircleIcon } from '../../../components/src/Icons.web';
import NestedMenuItem from '../../../components/src/NestedMenuItem.web';
import { DatePicker } from '@material-ui/pickers';

const styles = (theme: any) => ({

    root: {

        width: 400,
        borderRadius: '6px',
        background: theme.palette.background.paper
    },
    wrapper: {
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        borderRadius: '15px',
        marginTop: '15px',
        position: 'absolute',
        zIndex: 100,
        marginLeft: '-50px'
        // left:'100px'
    },
    nested: {

        paddingLeft: theme.spacing.unit * 4
    }
});


export const FilterMenuMobile = (props: any) => {
    const [menu, setMenu] = React.useState("main");
    const onDateMenuOpen = () => setMenu("date");
    const onCategoriesMenuOpen = () => setMenu("categories");
    const onStatusMenuOpen = () => setMenu("status")
    const handleClear = () => {
        setMenu("main")
        props.onClose()
    }
    const handleClose = () => {
        setMenu("main")
        props.onClose()
    }

    const handleMenuBack = () => {
        setMenu("main")
    }
    const { dateControls, catControls, handleDateInputFocus,requestTypeControls, handleDateInputBlur } = props
    const { requestTypes } = requestTypeControls
    console.log(props, "mobile filter props:::")
    const windowSize = useWindowSize()
    if (windowSize?.width > 1024) return <></>

    const clearFromDate = (e: React.MouseEvent) => {
        e.stopPropagation()
        dateControls.handleFromDateChange(null)
    }

    const clearToDate = (e: React.MouseEvent) => {
        e.stopPropagation()
        dateControls.handleToDateChange(null)
    }

    const MainMenu = <MobileFilterList>
        <ListItem button onClick={props.toggleFilterByMe} selected={props.filterByMe}>
            <div>Created By Me</div>
        </ListItem>
        <ListItem button onClick={onStatusMenuOpen}>
        <FlexSpaceBetween>
            <div>By Status</div>
            <ArrowRight />
         </FlexSpaceBetween>
        </ListItem>
        <ListItem button onClick={onCategoriesMenuOpen}>
            <FlexSpaceBetween>
                <div>By Request Type</div>
                <ArrowRight />
            </FlexSpaceBetween>
        </ListItem>
        <ListItem button onClick={onDateMenuOpen}>
            <FlexSpaceBetween>
                <div>Based on Date</div>
                <ArrowRight />
            </FlexSpaceBetween>
        </ListItem>
    </MobileFilterList>
    const DateMenu = <FilterByDateWrapper>
        <FilterByDateRow>
            Choose Date
        </FilterByDateRow>
        <FilterByDateRow>
            <span style={{ flex: 1 }}>From</span>
            <div style={{ flex: 3 }}>
                {/* @ts-ignore  */}
                <DatePicker
                    clearable
                    variant="inline"
                    inputVariant="outlined"
                    onChange={dateControls?.handleFromDateChange}
                    value={dateControls?.filterFromDate}
                    autoOk={true}
                    format="yyyy-MM-dd"
                    style={{ width: '100%' }}
                    InputProps={{
                        endAdornment: (<InputAdornment position="end">
                            {dateControls?.filterFromDate && <CloseCircleIcon
                                onClick={clearFromDate}
                                style={{ width: '24px', cursor: 'pointer' }}
                            />}
                        </InputAdornment>)
                    }}
                    inputProps={{
                        placeholder: 'Select', onFocus: handleDateInputFocus,
                        onBlur: handleDateInputBlur,
                    }}
                    maxDate={new Date()}
                    initialFocusedDate={dateControls?.filterFromDate}
                />

            </div>
        </FilterByDateRow>
        <FilterByDateRow>
            <span style={{ flex: 1 }}>To</span>
            <div style={{ flex: 3 }}>
                {/* @ts-ignore */}
                <DatePicker
                    clearable
                    variant="inline"
                    inputVariant="outlined"
                    onChange={dateControls?.handleToDateChange}
                    value={dateControls?.filterToDate}
                    autoOk={true}
                    format="yyyy-MM-dd"
                    style={{ width: '100%' }}
                    maxDate={new Date()}
                    minDate={dateControls?.filterFromDate || new Date()}
                    InputProps={{
                        endAdornment: (<InputAdornment position="end">
                            {dateControls?.filterToDate && <CloseCircleIcon
                                onClick={clearToDate}
                                style={{ width: '24px', cursor: 'pointer' }}
                            />}
                        </InputAdornment>)
                    }}
                    inputProps={{
                        placeholder: 'Select', onFocus: handleDateInputFocus,
                        onBlur: handleDateInputBlur,
                    }}
                    initialFocusedDate={dateControls?.filterToDate}
                />
            </div>
        </FilterByDateRow>
    </FilterByDateWrapper>
    return <Slide direction="up" in={!!props.menuPosition} mountOnEnter unmountOnExit>
        <MobileFilterBody>
            <div style={{ margin: '10px 10px 0 10px', }}>
                <FlexSpaceBetween>
                    <div style={{ display: 'flex', gap: '10px' }}>
                        {menu !== "main" && <Button onClick={handleMenuBack}>
                            <ArrowBackIos />
                        </Button>}
                        <Typography variant="h5">
                            {menu === "main" && "Filter"}
                            {menu === "categories" && "By request type"}
                            {menu === "tags" && "Filter By Tags"}
                            {menu === "date" && "Filter By Date"}
                        </Typography>
                    </div>
                    <Button onClick={handleClear}>
                        <CloseCircleIcon />
                    </Button>
                </FlexSpaceBetween>
                {menu === "main" && MainMenu}
                {menu === "date" && DateMenu}
                {menu === "categories" && <SearchMobileMenu
                    items={requestTypes}
                    selectedIds={requestTypeControls?.selectedCategories}
                    label="By Request Type"
                    selectHandler={requestTypeControls?.handleSelect}
                />}
                {menu === "status" &&
                    <div style={{marginTop:'16px', display:'flex', flexDirection:'column', gap:'12px'}}>
                        <MenuItem onClick={props.seletedFilterStatus(true)}>
                            Completed
                        </MenuItem>
                        <MenuItem onClick={props.seletedFilterStatus(false)}>
                            Pending
                        </MenuItem>
                    </div>}
                <Button
                    style={{ position: 'absolute', bottom: '10px', width:'95%' }}
                    variant="contained"
                    fullWidth
                    color="primary"
                    disableElevation
                    onClick={handleClose}
                    disabled={menu==="date" && props.disabled}
                >
                    Apply
                </Button>
            </div>
        </MobileFilterBody>
    </Slide>
}

export const FilterMenu = ({ menuPosition, onClose, handleDateInputFocus, handleDateInputBlur, keepOpen, dateControls, requestTypeControls, ...props }: FilterMenuProps) => {
    const { requestTypes } = requestTypeControls
    const [catSearch, setCatSearch] = React.useState("")
    const [filteredRequestTypes, setFilteredRequestType] = React.useState<any[]>(requestTypes)
    const [catSearchFocused, setCatSearchFocused] = React.useState(false)
    const [nestedMenuName, setNestedMenuName] = React.useState("")
    const windowSize = useWindowSize();
    const [focus, setFocus] = React.useState(false)


    const onCatSearchFocus = () => setCatSearchFocused(true)
    const onCatSearchBlur = () => setCatSearchFocused(false)

    React.useEffect(() => {
        setFilteredRequestType(requestTypes)
    }, [requestTypes])

    const onCatSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target
        setCatSearch(value)
        setFilteredRequestType(
            requestTypes.filter((c: any) => c.name.includes(value))
        )
    }
    console.log(windowSize.width)
    if (windowSize?.width < 1024) return <></>
    const clearFromDate = (e: React.MouseEvent) => {
        e.stopPropagation()
        dateControls.handleFromDateChange(null)
    }
    const clearToDate = (e: React.MouseEvent) => {
        e.stopPropagation()
        dateControls.handleToDateChange(null)
    }
    return (
        <Menu open={!!menuPosition} anchorPosition={menuPosition} onClose={onClose} anchorReference="anchorPosition">
            <MenuItem onClick={(e: any)=>props.onClear&&props.onClear(e)}>
                All
            </MenuItem>
            <NestedMenuItem
                name="status"
                nestedMenuName={nestedMenuName}
                setNestedMenuName={setNestedMenuName}
                label="By Status"
                parentMenuOpen={!!menuPosition}
                // @ts-ignore
                MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },


                }}
            >
                <div>
                    <MenuItem onClick={props.seletedFilterStatus(true)}>
                        Completed
                    </MenuItem >
                    <MenuItem onClick={props.seletedFilterStatus(false)}>
                        Pending
                    </MenuItem>
                </div>
            </NestedMenuItem>
            <MenuItem selected={props.filterByMe} onClick={props.toggleFilterByMe}>
                Created by Me
            </MenuItem>
            <SearchNestedMenu
                name="requestType"
                nestedMenuName={nestedMenuName}
                setNestedMenuName={setNestedMenuName}
                items={requestTypes}
                selectedIds={requestTypeControls.selectedRequestType}
                label="By Request Type"
                selectHandler={requestTypeControls.handleSelect}
                parentMenuOpen={!!menuPosition}
            />
            <NestedMenuItem
                name="date"
                nestedMenuName={nestedMenuName}
                setNestedMenuName={setNestedMenuName}
                keepOpen={keepOpen}
                label="By Date"
                parentMenuOpen={!!menuPosition}
                // @ts-ignore
                MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },


                }}
            >
                <FilterByDateWrapper>
                    <FilterByDateRow>
                        Choose Date
                    </FilterByDateRow>
                    <FilterByDateRow>
                        <span style={{ flex: 1 }}>From</span>
                        <div style={{ flex: 3 }}>
                            {/* @ts-ignore  */}
                            <DatePicker
                                clearable
                                variant="inline"
                                inputVariant="outlined"
                                onChange={dateControls.handleFromDateChange}
                                value={dateControls.filterFromDate}
                                autoOk={true}
                                format="yyyy-MM-dd"
                                style={{ width: '100%' }}
                                InputProps={{
                                    endAdornment: (<InputAdornment position="end">
                                        {dateControls.filterFromDate && <CloseCircleIcon
                                            onClick={clearFromDate}
                                            style={{ width: '24px', cursor: 'pointer' }}
                                        />}
                                    </InputAdornment>)
                                }}
                                inputProps={{
                                    placeholder: 'Select', onFocus: handleDateInputFocus,
                                    onBlur: handleDateInputBlur,
                                }}
                                maxDate={new Date()}
                                initialFocusedDate={dateControls.filterFromDate}
                            />

                        </div>
                    </FilterByDateRow>
                    <FilterByDateRow>
                        <span style={{ flex: 1 }}>To</span>
                        <div style={{ flex: 3 }}>
                            <DatePicker
                                clearable
                                variant="inline"
                                inputVariant="outlined"
                                onChange={dateControls.handleToDateChange}
                                value={dateControls.filterToDate}
                                autoOk={true}
                                format="yyyy-MM-dd"
                                maxDate={new Date()}
                                minDate={dateControls.filterFromDate || new Date()}
                                initialFocusedDate={dateControls.filterToDate}
                                style={{ width: '100%' }}
                                inputProps={{
                                    placeholder: 'Select', onFocus: handleDateInputFocus,
                                    onBlur: handleDateInputBlur
                                }}
                                InputProps={{
                                    endAdornment: (<InputAdornment position="end">
                                        {dateControls.filterToDate && <CloseCircleIcon
                                            onClick={clearToDate}
                                            style={{ width: '24px', cursor: 'pointer' }}
                                        />}
                                    </InputAdornment>)
                                }}
                            />

                        </div>
                    </FilterByDateRow>
                    <FilterByDateRow>
                        <div style={{ flex: 1 }}></div>
                        <div style={{ flex: 3 }}>
                            <CancelButton onClick={dateControls.handleCancel}>Cancel</CancelButton>
                            <SubmitButton onClick={dateControls.handleSubmit}>Submit</SubmitButton>
                        </div>
                    </FilterByDateRow>
                </FilterByDateWrapper>
            </NestedMenuItem>
        </Menu>
    )
}

const MobileFilterList = styled(List)({
    maxHeight: 'calc( 100vh - 150px )',
    overflow: 'auto',
    width: '100%',
    borderRadius: '6px',
    lineHeight:'3rem',
})

const FlexSpaceBetween = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignContent: 'center',
    // padding: '20px 0px',
    width: '100%',
})

const MobileFilterBody = styled('div')({
    width: '100%',
    height: '100%',
    minWidth: '100vw',
    minHeight: '100vh',
    background: 'white',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 10,
})


interface FilterMenuProps {
    filterByMe: boolean,
    filterStatus: string
    toggleFilterByMe: () => void,
    seletedFilterStatus: (status: string|Boolean) => (e: any) => void,
    menuPosition: {
        top: number,
        left: number,
    } | undefined
    onClose: () => void,
    onClear?: (e?: any) => void,
    handleDateInputFocus: any,
    handleDateInputBlur: any,
    keepOpen: any,

    requestTypeControls: {
        requestTypes: any[],
        selectedRequestType: any,
        handleSelect: (id: number) => (e: any) => void,
    },
    dateControls: {
        handleFromDateChange: any,
        handleToDateChange: any,
        handleSubmit: () => void,
        handleCancel: () => void,
        filterFromDate?: Date | null,
        filterToDate?: Date | null,
        maxDate?: Date | null,
        minDate?: Date | null,
    }
}

const SearchMobileMenu = (props: any) => {
    const { items, selectedIds, selectHandler, label } = props
    const [search, setSearch] = React.useState("")
    const [focus, setFocus] = React.useState(false)
    const [filteredItems, setFilteredItems] = React.useState<any[]>(items);

    const onFocus = () => setFocus(true)
    const onBlur = () => setFocus(false)

    const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation();
        const value = e.target.value
        setSearch(value)
    }

    React.useEffect(() => {
        setFilteredItems(items.filter(
            (item: any) => item.name.toLowerCase().includes(search.toLowerCase())
        )
        )
    }, [search])

    const stopPropagation = (e: React.KeyboardEvent<any>) => e.stopPropagation()
    return <MobileFilterList>
        <li onKeyDown={stopPropagation} >
            <TextField
                InputProps={{
                    startAdornment: (<InputAdornment position="start">
                        <img src={SearchIcon} width={20} style={{ paddingLeft: '15px' }} />
                    </InputAdornment>
                    ),
                }}
                fullWidth
                style={{ marginTop: '5px 10px' }}
                inputProps={{ style: { padding: '10px 10px' } }}
                onFocus={onFocus}
                onBlur={onBlur}
                value={search}
                onChange={onSearchChange}
                placeholder="Search" />
        </li>
        {items?.length === 0 && "Loading"}
        {filteredItems?.length === 0 && <div style={{ padding: '20px' }}>No match found</div>}
        {
            filteredItems?.map(
                (cat: any) => (
                    <ReverseMenuItem onClick={selectHandler(cat.id)} key={cat.id}>
                        {cat.name}
                    </ReverseMenuItem>
                ))
        }
    </MobileFilterList>
}

interface SearchNestedMenuProps {
    items: any[];
    selectedIds: number[];
    selectHandler: (id: number) => (e: any) => void;
    label: string;
    parentMenuOpen: boolean;
    nestedMenuName?: any;
    setNestedMenuName?: any;
    name?: any;
}

function SearchNestedMenu({ items, selectedIds, selectHandler, label, parentMenuOpen, name, nestedMenuName, setNestedMenuName }: SearchNestedMenuProps) {
    const [search, setSearch] = React.useState("")
    const [focus, setFocus] = React.useState(false)
    const [filteredItems, setFilteredItems] = React.useState<any[]>(items);
    const classes = useNestedMenuStyles();
    const onFocus = () => setFocus(true)
    const onBlur = () => setFocus(false)

    const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation();
        const value = e.target.value
        setSearch(value)
    }

    React.useEffect(() => {
        setFilteredItems(items.filter(
            (item: any) => item.name.toLowerCase().includes(search.toLowerCase())
        )
        )
    }, [search])

    const stopPropagation = (e: React.KeyboardEvent<any>) => e.stopPropagation()
    return <NestedMenuItem
        name={name}
        nestedMenuName={nestedMenuName}
        setNestedMenuName={setNestedMenuName}
        keepOpen={focus}
        label={label}
        parentMenuOpen={parentMenuOpen}
        // @ts-ignore
        MenuProps={{
            classes: { paper: classes.menuPaper, list: classes.list },
            getContentAnchorEl: null,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
            },
            transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
            }
        }}
    >
        <li onKeyDown={stopPropagation} >
            <TextField
                InputProps={{
                    startAdornment: (<InputAdornment position="start">
                        <img src={SearchIcon} width={20} style={{ paddingLeft: '15px' }} />
                    </InputAdornment>
                    ),
                }}
                fullWidth
                style={{ marginTop: '5px 10px' }}
                inputProps={{ style: { padding: '10px 10px' } }}
                onFocus={onFocus}
                onBlur={onBlur}
                value={search}
                onChange={onSearchChange}
                placeholder="Search" />
        </li>
        {items?.length === 0 && <div style={{ padding: '20px' }}>Loading</div>}
        {filteredItems?.length === 0 && <div style={{ padding: '20px' }}>No match found</div>}
        {
            filteredItems?.map(
                (cat: any) => (
                    <ReverseMenuItem onClick={selectHandler(cat.id)} key={cat.id}>
                        <span className={classes.menuItemLabel}>{cat.name}</span>
                    </ReverseMenuItem>
                ))
        }
    </NestedMenuItem>
}

const ReverseMenuItem = styled(MenuItem)({
    display: 'flex',
    justifyContent: 'space-between'
})


const FilterByDateWrapper = styled('div')({
    width: '280px',
    height: '230px',
    padding: '10px 15px',
    background: '#FFF',
    marginTop:'16px',
    lineHeight:'3rem'
})

const FilterByDateRow = styled('div')({
    display: 'flex',
    marginBottom: '10px',
    alignItems: 'center'
})

const SubmitButton = styled(Button)({
    background: '#009c20',
    color: '#FFF',
    '&:hover': {
        background: '#009c20',
        opacity: 0.9
    }
})

const CancelButton = styled(Button)({
    color: '#FFF',
    background: '#999',
    marginRight: '5px',
    '&:hover': {
        background: '#999',
        opacity: 0.9
    }
})

const useNestedMenuStyles = makeStyles((theme: any) => ({
    menuPaper: {
        height: 300,
        display: 'flex',
        pointerEvents: 'auto',
    },
    list: {
        width: 400,
        display: 'flex'
    },
    menuItemLabel: {
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }
}));

// Customizable Area End
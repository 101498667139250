// Customizable Area Start
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const cardIcon = require("../assets/card_icon.png");
export const iconAdd = require("../assets/ic_add.png");
export const editImg = require("../assets/edit.png");
export const deleteImg = require("../assets/delete.png");
export const reportImg = require("../assets/Report.svg");
export const ceCalculatorImg = require("../assets/CeCalculator.svg");
export const warningIcon = require('../assets/warning.png');
export const viewIcon = require('../assets/View.png');
export const SaveLoader = require('../assets/SaveLoader.gif');
export const ExcelIcon = require("../assets/ic_excel.png")
export const PdfIcon = require("../assets/pdf.png")
export const ArrowDownIcon = require('../assets/ic_arrow_down.png')
export const UnverifiedIcon = require('../assets/Unverified.png')
export const VerifiedIcon = require('../assets/verified-icon.png')
// Customizable Area End
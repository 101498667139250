import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  onChange?: (query: string) => void;
  favouriteable_type?: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  data: any;
  arrayHolder:any
  filterMenuPosition: {
    top: number, 
    left: number,
  } | undefined;
  selectingDate?: boolean;
  filterFromDate?: string;
  filterToDate?: string;
  filterText: string;
  filterQuery: string;
  filterByMe: boolean;
  filterByFav: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class FilteritemsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProductApiCallId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: (typeof localStorage !== "undefined" && localStorage.getItem('authToken')) || "",
      data: "",
      arrayHolder:[],
      filterMenuPosition: undefined,
      selectingDate: false,
      filterText: '',
      filterQuery: '',
      filterByMe: false,
      filterByFav: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }
  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };
  navigateToFilter = () => {
      let arrayHolder = this.state.arrayHolder;
      const priceRange= arrayHolder.map((element:any) => element.attributes.price);
      var min = Math.min.apply(null, priceRange);
      var max = Math.max.apply(null, priceRange);
      let params = this.props.navigation.state.params ;
      if(params != undefined){
        if(params.priceSelectedMin && params.priceSelectedMax){
          this.props.navigation.push("Filteroptions" , {min: min , 
            max:max , 
            priceSelectedMin:params.priceSelectedMin,
            priceSelectedMax:params.priceSelectedMax
          });
        }    
      }else{
        this.props.navigation.push("Filteroptions" , {min: min , max:max});
      }
  }
  
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      // this.setState({ token: token });
      // this.getListRequest(token);
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getProductApiCallId != null &&
      this.getProductApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setState({arrayHolder:responseJson.data})
        let params = this.props.navigation.state.params;
        if (params !== undefined) {
          if(params.data){
            if(params.data.length > 0){
              this.setState({ data: params.data });
            }else{
              this.showAlert("Error", "No data Found")  
            }
          }
        } else {
          this.setState({
            data: responseJson.data
          });
        }
       
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.showAlert("Error", errorReponse)  
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }
  getListRequest = (token: any) => {
    
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area Start
  handleFilterMenuClose = () => {
    this.setState({ filterMenuPosition: undefined })
    this.filterPosts()
  }

  handleFilterMenuOpen = (e: React.MouseEvent) => {
    this.setState({ filterMenuPosition: {
      top: e.pageY,
      left: e.pageX
    }})
  }

  filterPosts = () => {
    const query = new URLSearchParams()
    if(this.state.filterFromDate) query.append('from_date', this.state.filterFromDate)
    if(this.state.filterToDate) query.append('to_date', this.state.filterToDate)
    if(this.state.filterQuery) query.append('query', this.state.filterQuery)
    if(this.state.filterByMe) query.append('created_by_me', this.state.filterByMe.toString())
    if(this.state.filterByFav) query.append('favouriteable_type', this.props.favouriteable_type || 'BxBlockPosts::Post')


    const queryStr = query.toString()
    this.props.onChange && this.props.onChange(queryStr)
  }

  toggleFilterByMe = () => this.setState({ filterByMe: !this.state.filterByMe })
  toggleFilterByFav = () => this.setState({ filterByFav: !this.state.filterByFav })

  filterText = () => {
    let filterText = "";
    if(this.state.filterFromDate) {
      filterText += `Date(${this.state.filterFromDate} to ${this.state.filterToDate}) `
    }
    
    if(this.state.filterByMe) filterText += 'Created By Me '
    if(this.state.filterByFav) filterText += "Favourite"

    return filterText
  }

  handleDateInputFocus = () => {
    this.setState({ selectingDate: true })
  }
  handleDateInputBlur = () => (this.setState({ selectingDate: false }))

  handleFromDateChange = (e: any) => {
    this.setState({ filterFromDate: e.target?.value })
  }

  handleToDateChange = (e: any) => {
    this.setState({ filterToDate: e.target?.value })
  }

  handleDateFilterSubmit = () => {
    this.setState({ filterMenuPosition: undefined })
    this.filterPosts()
  }

  handleDateFilterCancel = () => {
    this.setState({ filterMenuPosition: undefined })
  }

  handleQueryChange = (filterQuery: string) => {
    this.setState({ filterQuery }, () => this.filterPosts())
  }
  // Customizable Area End
}

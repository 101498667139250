import React from "react";

// Customizable Area Start
import {
  Avatar,
  Typography,
  Paper,
  Divider,
  Button
  } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import { Scrollbars } from 'react-custom-scrollbars-2';
import DashboardLayout from '../../../components/src/DashboardLayout.web'
import SearchBar from '../../../components/src/Search.web'
import { format } from 'timeago.js'
import { Link } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component';
import { LoadingSpinner } from '../../../components/src/LoadingSpinner.web'
import OverlayLoader from '../../../components/src/Loader.web'
import { withRouter } from 'react-router-dom'
import moment from "moment"

const formatDate = (date: string) => {
  if (moment().format("DD-MM-YYYY") === moment(date).format("DD-MM-YYYY")) {
    return format(date)
  } else {
    const d = new Date(date)
    return d.getDate() +" "+d.toLocaleString('en-us', { month: 'short' }) +', '+ d.toLocaleString('en-us', {'hour': 'numeric', hour12: true })
  }
}

export const formatHeading = (text: string) => {
  let open = true
  const chunks = text.split(/(\*\*)/)
  const result = []
  for(let i=0;i<chunks.length;i++){
    if(chunks[i]=='**')
    {
      result.push(<BlueText key={i}>{chunks[i+1]}</BlueText>)
      i+=2
    } else {
      result.push(<React.Fragment key={i}>
          {chunks[i]}
        </React.Fragment >)
    }
  }
  return result
}
// Customizable Area End

import AllNotificationsController, {
  Props,
  configJSON
} from "./AllNotificationsController";

export default class AllNotifications extends AllNotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    // const notificationData = [
    //     {avatar: '', created_at: '', heading: '', content: '', active: true},
    //     {avatar: '', created_at: '', heading: '', content: ''},
    //   ]

    const notifications: any = this.state.notifications?.length ? this.state.notifications.map(
        (n: any)=> typeof n ==="string"?<DateDivider key={n} date={n} />:<NotificationCard onRead={this.handleRead} key={n.id} {...n} />
      ) : <div style={{ textAlign: 'center'}}>No match found</div>
    const navigation = this.props.navigation
    // Customizable Area End
     return (
      // Customizable Area Start
      <>
      <DashboardLayout navigation={navigation} token={this.state.token}>
        <CenterCard>
          <H1>Notifications</H1>
          
          <FilterRow>
            {this.filterButtons.map((filter: any)=> <FilterButton 
                key={filter.id} 
                onClick={this.handleFilter} 
                data-id={filter.id}
                active={this.state.typefilter === filter.id} 
                variant="contained" 
                disableElevation 
              >{filter.label}</FilterButton>
            )}
            
          </FilterRow>
          <div data-testid ="SearchBarNotification" style={{margin: '18px 0px 25px'}}>
            <SearchBar onChange={this.handleSearch} />
          </div>
          <NotificationsCol>
            <InfiniteScroll
              dataLength={this.state.notifications.length ?? 0 } //This is important field to render the next data
              next={this.loadNextPage}
              hasMore={(!!this.state.pagination?.next_page) && !this.state.loadingNotifications}
              // Show only when this.state.loadingPosts component is not rendered
              loader={!(!this.state.loadingNotifications && this.state.notifications?.length === 0) && <LoadingSpinner />}
            >
              {notifications}
            </InfiniteScroll>
          </NotificationsCol>
        </CenterCard>
        <OverlayLoader loading={!!this.state.loadingNotifications} />
      </DashboardLayout>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
interface DateDividerProps {
  date: string,
}

const DateDivider = ({ date }: DateDividerProps) => {
  console.log(date, 'notify date')
  // const date = new Date(isoTimeString)
  // let displayDate = date.toLocaleString('en-us', { weekday: 'long', day: 'numeric' })
  return <DDWrapper>
    <DateDividerValue>{date}</DateDividerValue>
    <Divider style={{width: '100%', flexShrink: 'unset'}} />
  </DDWrapper>
}

const DDWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: '#6a7279',
  marginBottom: '15px'
})

const DateDividerValue = styled(Typography)({
  width: '15ch',
})




// Start of NotificationCard
const CenterCard = styled(Paper)({
  borderRadius: '7px',
  // width: '100%',
  marginTop: '60px',
  padding: '30px 40px'
})
const H1 = styled(Typography)({
  fontWeight: 700,
  fontSize: '21px',
  color: '#000',
  marginBottom: '36px'
})

const FilterButton = styled(Button)((props: any)=>({
  borderRadius: '25px',
  border: props.active?'1px solid #1EA60A':'1px solid #979797',
  color: props.active?'#1EA60A':'#5F686F',
  fontWeight: 700,
  fontSize: '12px',
  background: '#F2F4F5',
  marginRight: '8px',
  marginBottom: '10px',
}))

const FilterRow = styled('div')({
  display: 'block',
  gap: '10px',

})


const NotificationCard = withRouter((props: any) => {
  const [more, setMore] = React.useState(false)
  const toggle = () => setMore((m: any)=>!m)
  const onNotificationOpen = (e: any)=>{

    props.onRead(e)
    setTimeout(() => props.history.push(props.app_url || '#'), 500)
    
  }
  return <NCWrapper active={!props.is_read}>
    <Link to={props?.user_profile?.profile_id||'#'}>
      <Avatar style={{width: '60px', height: '60px'}} src={props?.user_profile?.avatar_link} />
    </Link>
    <NCRight>
      
        <NCTitle onClick={onNotificationOpen} data-id={props.id}>
          {formatHeading(props.headings)}
        </NCTitle>
      
     {   
        props.contents && more && (<NCContent more={more} >
           { props.contents}
         </NCContent>)
     }
      <NCBottoms>
        {
          props.contents && <MoreButton onClick={toggle}>
            {more?"Hide":"View more"}
          </MoreButton>
        }
        <span></span>
        <NCTime>
          {formatDate(props.created_at)}
        </NCTime>
      </NCBottoms>
    </NCRight>
  </NCWrapper>
})

const NCTime = styled(Typography)({
  fontWeight: 100,
  color: '#9196A1',
  fontSize: '14px',
  '@media screen and (max-width: 425px)': {
    marginTop: '8px',
  }
})

const NCContent = styled(Typography)((props: any)=>({
  color: '#6F6F6F',
  fontSize: '14px',
  transition: 'all 0.3s',
  maxHeight: props.more?'300px': '0px',
  opacity: props.more?1:0
}))

const NCTitle = styled(Typography)({
  fontSize: '16px',
  color: '#4F4F4F',
  cursor: 'pointer'
})

const MoreButton = styled(Button)({
  height: '25px',
  border: '1px solid #979797',
  background: '#EEFF1',
  color: '#979797',
  borderRadius: '7px',
  fontSize: '13px',
  fontWeight: 700,
  textTransform: 'none',
})

const NCRight = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  gap: '5px',
  flexDirection: 'column',
})

const NCBottoms = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '5px',
  '@media screen and (max-width: 425px)': {
    display: 'unset',
  }
})
const NCWrapper = styled('div')((props: any) => ({
  background: props.active ? '#EFF4FF':'#FFF',
  padding: '10px 22px',
  display: 'flex',
  gap: '35px',
  borderRadius: '15px',
  marginBottom: '10px'
}))

// end of NotificationCard

const NotificationsCol = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px'
})

const Wrapper = styled(Paper)({
  padding: '10px 0',
  borderRadius: '7px',
  border: '1px solid #E0E0E0',
  width: '440px',
  height: '560px',
  display: 'flex',
  flexDirection: 'column',
})

const Header = styled('div')({
  padding: '14px 24px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'end',
})

const Body = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column'
})

const AllButton = styled(Button)({
  background: '#F2F4F5 !important',
  width: '100%',
  fontSize: '17px',
  padding: '8px 0',
  borderRadius: '30px',
  '& > span': {
    textTransform: 'none',
    color: '#5F686F',
  }
})

const LINKCOLOR = '#4e80ff'
const BlueText = styled('span')({
  color: LINKCOLOR,
  fontWeight: 600
})
// Customizable Area End

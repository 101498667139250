import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as yup from "yup";
import { toast } from "react-toastify";
import { parseCommunityJoinedMessage, parseCommunityUnjoinedMessage } from './Messages'
import { sendAPIRequest } from "../../../components/src/utils";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  onCloseSidebar: any;
  // Customizable Area End
}

interface S {
  txtSavedValue: string;
  // Customizable Area Start
  token: string;
  communities?: any[];
  pagination?: {
    next_page?: number;
  };
  total_communities?: number;
  showAll: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MyCommunitiesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCommunitiesCallId: string = "";
  paginateCallID?:string  = "";
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const authToken = await getStorageData('authToken');
    this.setState({ token: authToken }, () => {
      this.loadCommunities()
    });
    // Customizable Area End
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.CommunityJoinedMessage),
      getName(MessageEnum.CommunityUnjoinedMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.GetAllCategoriesResponseMessage),
      getName(MessageEnum.GetAllSubCategoriesResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtSavedValue: "",
      // Customizable Area Start
      token: "",
        communities: [],
        showAll: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    parseCommunityJoinedMessage(message, this.handleCommunityJoined)
    parseCommunityUnjoinedMessage(message, this.handleCommunityUnjoined)
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if(apiRequestCallId === this.getCommunitiesCallId) {
        const communities = responseJson?.my_communities?.data
        const total_communities = responseJson?.meta?.pagination?.total_count
        if(communities)
          this.setState({
            total_communities,
            communities,
            pagination: responseJson?.meta?.pagination
          })
      } else if(this.paginateCallID === apiRequestCallId) {
        const newcommunities = responseJson?.my_communities?.data
        // @ts-ignore
        const communities = [...this.state.communities, ...newcommunities]
        this.setState({
          communities,
          pagination: responseJson?.meta?.pagination
        })
        this.paginateCallID = ''
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  loadCommunities = () => {
    const { token } = this.state
    this.getCommunitiesCallId = sendAPIRequest(configJSON.getMyCommunitiesndpoint, {
      method: 'GET',
      headers: { token }
    })
  }

  handleCommunityJoined = () => {
    this.loadCommunities()
    this.setState({ total_communities: (this.state.total_communities||0) + 1 })
  }

  handleCommunityClicked = (id: any) => () => {
    if(!this.state.communities) return
    const communities = this.state.communities.map((com: any) => {
      if(com.id!=id) return com
      return ({...com, attributes: {...com.attributes, unread_posts: 0 } })
    })
    this.setState({ communities })
  }

  handleCommunityUnjoined = () => {
    this.loadCommunities()
    this.setState({ total_communities: (this.state.total_communities||1) - 1 })
  }

  toggleSeeAll = () => {
    this.setState({ showAll: !this.state.showAll })
  }

  handleScroll = (e: any) => {
    const DIV_HEIGHT = 300;
    const THRESHOLD = 30;
    const {scrollTop, scrollHeight} = e.target
    if(scrollTop+DIV_HEIGHT+THRESHOLD>=scrollHeight){
        this.paginate()
    }
  }

  closeSidebar = () => {
    this.props.onCloseSidebar();
  }

  paginate = () => {
    if(this.paginateCallID) return
    const next_page  = this.state?.pagination?.next_page
    if(!next_page) return 
      
    const { token } = this.state
    this.paginateCallID = sendAPIRequest(configJSON.getMyCommunitiesndpoint+`?page=${next_page}`, {
      headers: { token }
    })
  }
  // Customizable Area End
}

import React from "react";
// Customizable Area Start
import { styled } from "@material-ui/styles";
import { Button, Card, Link, Typography } from "@material-ui/core";
import DashboardLayout from "../../../components/src/DashboardLayout.web";
import carbonCalculatorDownloadController, {
  Props,
} from "./carbonCalculatorDownloadController.web";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
// @ts-ignore
import * as config from "../../../framework/src/config";
import UserContext from "../../../components/src/UserProvider";
import { xlsxIcon } from "./assets";
import OverlayLoader from '../../../components/src/Loader.web'
// Customizable Area End
export default class carbonCalculatorDownload extends carbonCalculatorDownloadController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { navigation } = this.props;
    const path = this.context?.user_type == "licensed_user" ? 'Corporatepermission' : 'ESGJourney'
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <DashboardLayout navigation={navigation}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span
              data-test-id="span-navigation"
              onClick={() => navigation.navigate(path)}
              style={{ margin: "40px 0px 15px 12px", cursor: "pointer" }}
            >
              ESG Journey
            </span>
            <BackArrow data-test-id="arrow-back" onClick={() => this.props.navigation.navigate(path)}>
              <ArrowBackIosIcon />
            </BackArrow>
            <CoinsHead>CARBON CALCULATOR TOOL</CoinsHead>
          </div>
          <RoundedCard>
            <DownloadButton>
              <Link
                href={`${config.baseURL}/bx_block_surveys/surveys/download_carbon_calculator`}
              >
                Download Carbon Report
              </Link>
            </DownloadButton>
          </RoundedCard>

          <RoundedCard style={{ display: "flex", flexDirection:"column" }}>
            <div><RoundedButtonMore>
              <label style={{ cursor: "pointer" }} htmlFor="carbonFile">
                Upload Report
              </label>
            </RoundedButtonMore>
            
            <RoundedButtonMore style={{ color: "#009c05", marginLeft: "7px" }}>
            <label>  <Link style={{color:"white"}} href={this.state.uploadedCarbonFile}>Download</Link></label>
            </RoundedButtonMore></div>
          {this.state.carbon_file_name && <BorderDocument data-test-id="download-button" onClick={this.onDownload}>
            <img src={xlsxIcon} width="42px" />
            <FileInformation>
                <Typography style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: 'ellipsis',
                    width: '100%'
                }}>{this.state.carbon_file_name}</Typography>
                <Typography color="textSecondary">{this.state.carbon_file_size}</Typography>
            </FileInformation>
        </BorderDocument>}
          </RoundedCard>
          <input
            type="file"
            accept=".xlsx"
            id="carbonFile"
            data-test-id="carbonFile"
            style={{ display: "none" }}
            onChange={(e) => {
              this.handleCarbonFileUpload(e);
            }}
          />
        </DashboardLayout>
        <OverlayLoader loading={this.state.loading} />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
carbonCalculatorDownloadController.contextType = UserContext

const RoundedButtonMore = styled(Button)({
  cursor: "pointer",
  backgroundColor: "#009c05",
  color: "white",
  borderRadius: "50px",
  padding: "10px 20px",
  float: "left",
  "&:hover": {
    backgroundColor: "#009c05",
  },
  "@media screen and (max-width:600px)": {
    width: "100%",
  },
  "@media screen and (min-width:769px) and (max-width:1200px)": {
    width: "100%",
  },
});

const FileInformation = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '15px',
  gap: '5px',
})


const BackArrow = styled('div')({
  margin: "40px 0px 15px 12px"
})

const RoundedCard = styled(Card)({
  borderRadius: "15px",
  padding: "20px 20px",
  margin: "30px 0px",
});

const DownloadButton = styled(Button)({
  boxSizing: "border-box",
  backgroundColor: "white",
  color: "white",
  borderRadius: "50px",
  padding: "10px 20px",
  border: "1px solid #009c05 ",
  float: "left",

  "@media screen and (max-width:600px)": {
    width: "100%",
  },
  "@media screen and (min-width:769px) and (max-width:1200px)": {
    width: "100%",
  },
});

const BorderDocument = styled('div')({
  border: '1px solid #999',
  padding: '10px 15px',
  borderRadius: '15px',
  display: 'flex',
  alignItems: 'center',
  width: '50%',
  position: 'relative',
  marginTop: '5px'
})

const CoinsHead = styled("div")({
  margin: "40px 0px 15px 12px",
  fontSize: "1.2rem",
  fontFamily: "HelveticaNeue",
  fontWeight: 600,
  "@media screen and (max-width: 768px)": {
    margin: "20px 0px 10px 12px",
  },
});
// Customizable Area End

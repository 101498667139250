import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  categoriesArray: any;
  category: string;
  subCategory: string;
  isVisible: boolean;
  dropdownCategoryStatus: boolean;
  activeModalType: string;
  selectedCategoryID: any;
  allCategories: any[];
  allSubCategories: any[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CategoriessubcategoriesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCategoriesApiCallId: any;
  deleteCategoriesApiCallId: any;
  deleteSubCategoriesApiCallId: any;
  addCategoryApiCallId: any;
  addSubCategoryApiCallId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.GetAllCategoriesMessage),
      getName(MessageEnum.GetAllSubCategoriesMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      categoriesArray: [],
      category: "",
      subCategory: "",
      isVisible: false,
      dropdownCategoryStatus: false,
      activeModalType: "",
      selectedCategoryID: [],
      allCategories: [],
      allSubCategories: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    let authToken = await getStorageData('authToken');

    this.setState({token: authToken});
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
  }
  
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    try{
        if (getName(MessageEnum.SessionResponseMessage) === message.id) {
          runEngine.debugLog("Message Recived", message);
          let token = message.getData(getName(MessageEnum.SessionResponseToken));
          let authToken = await getStorageData('authToken');

          this.setState({token: authToken});
          // this.setState({ token: token }, () => {
          //   this.getCategories();
          // });
    
        } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
          );
    
          var responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
    
          var errorReponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );
          runEngine.debugLog("API Message Recived", message);
          
          if ( apiRequestCallId === this.apiGetAllCategoriesCallId ) {
            if(Array.isArray(responseJson)) this.allCategories = responseJson
            if(Array.isArray(responseJson.data)) this.allCategories = responseJson.data.map(
              (ct: any) => ct.attributes
            )
            this.sendAllCategories()
            // this.setState({ allCategories: responseJson }, () => this.sendAllCategories())
            
          } else if (apiRequestCallId === this.apiGetAllSubcategoriesCallId) {
            if(!Array.isArray(responseJson.data)) return
            const allSubCategories = responseJson.data.map((data: any)=>({...data.attributes}))
            this.allSubCategories = allSubCategories
            this.sendAllSubCategories()
          }
          else if (responseJson.errors) {
            this.parseApiErrorResponse(responseJson);
            this.parseApiCatchErrorResponse(errorReponse);
          }
        } else if (getName(MessageEnum.GetAllCategoriesMessage) === message.id) {
          if(this.allCategories.length){
            this.sendAllCategories()
          } else {
            this.getAllCategories()
          }
        } else if (getName(MessageEnum.GetAllSubCategoriesMessage) === message.id) {
          if(this.allSubCategories.length)
            this.sendAllSubCategories()
          else
            this.getAllSubCategories()
        }
    }catch(err: any){
    console.log("Category block, recieve error", err)
    }
    // Customizable Area End
  }

  // Customizable Area Start
  apiGetAllCategoriesCallId:string = ""
  apiGetAllSubcategoriesCallId: string = ""
  allCategories: any[] = [];
  allSubCategories: any[] = [];

  getAllCategories = async() => {
    const token = await getStorageData('authToken');

    if(token)
      this.apiGetAllCategoriesCallId = this.sendAPIRequest(configJSON.getAllCategoriesEndpoint, {
        method: 'GET',
        headers: {
          token,
        }
      })
  }

  getAllSubCategories = async() => {
    const token = await getStorageData('authToken');
    
    if(token)
      this.apiGetAllSubcategoriesCallId = this.sendAPIRequest(configJSON.getAllSubCategoriesEndpoint, {
          method: 'GET', 
          headers: {
            token,
          }
      })
    
  }

  sendAllCategories = ()=>{
    const msg = new Message(
      getName(MessageEnum.GetAllCategoriesResponseMessage)
    )
    msg.addData(
      getName(MessageEnum.AllCategoriesDataMessage),
      this.allCategories,
    )
    runEngine.sendMessage(msg.id, msg)
  }

  sendAllSubCategories = () => {
    const msg = new Message(
      getName(MessageEnum.GetAllSubCategoriesResponseMessage)
    )
    msg.addData(
      getName(MessageEnum.AllSubCategoriesDataMessage),
      this.allSubCategories
    )
    runEngine.sendMessage(msg.id, msg)
  }

  setCategoryTxt = (text: string) => {
    this.setState({ category: text });
  };
  setSubCategoryTxt = (text: string) => {
    this.setState({ subCategory: text });
  };
  clickCategory = (item: any, Index: number) => {
    let array = this.state.categoriesArray;
    let idarray = this.state.selectedCategoryID;
    let index = idarray.indexOf(item.attributes.id);

    if (index > -1) {
      idarray.splice(index, 1);
      array[Index].Check = false;
      this.setState({ categoriesArray: array });
    } else {
      idarray.push(item.attributes.id);
      array[Index].Check = true;
      this.setState({ categoriesArray: array });
      this.setState({ selectedCategoryID: idarray });
    }
  };

  toggleModal = (type: string) => {
    this.setState({ activeModalType: type, isVisible: !this.state.isVisible });
  };

  expandCategoryView = () => {
    this.setState({
      dropdownCategoryStatus: !this.state.dropdownCategoryStatus
    });
  };
  expand = (id: string) => {
    let array = this.state.categoriesArray;
    for (let i = 0; i < array.length; i++) {
      if (array[i].id === id) {
        array[i].expand = !array[i].expand;
      }
    }
    this.setState({ categoriesArray: array });
  };

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  addCategory = () => {
    if (this.isStringNullOrBlank(this.state.category)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    } else {
      let data = {
        categories: [{ name: this.state.category }]
      };
      const header = {
        "Content-Type": configJSON.categoryApiContentType,
        token: this.state.token
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addCategoryApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.categoryAPIEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };
  addSubCategory = () => {
    if (this.isStringNullOrBlank(this.state.subCategory)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    } else if (this.state.selectedCategoryID.length === 0) {
      this.showAlert(configJSON.errorTitle, configJSON.errorCategory);
      return false;
    } else {
      let data = {
        sub_category: {
          name: this.state.subCategory
        },
        parent_categories: this.state.selectedCategoryID
      };
      const header = {
        "Content-Type": configJSON.categoryApiContentType,
        token: this.state.token
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addSubCategoryApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.subCategoryAPIEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  deleteCategories = (id: number) => {
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryAPIEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  deleteSubCategories = (id: number) => {
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteSubCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subCategoryAPIEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getCategories = () => {

    if (!this.state.token) {
      return;
    }
    
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  sendAPIRequest=(endpoint: string, params?: any)=> {
  const { method="GET", headers, body } = params || {}
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  )
  const callId = requestMessage.messageId
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endpoint
  )

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    method
  )

  if(body) 
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body?.append ? body : JSON.stringify(body)
    )   
  
  if(headers)
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    )

  runEngine.sendMessage(requestMessage.id, requestMessage)

  return callId
}

  // Customizable Area End
}

import React from "react";
// Customizable Area Start

import { styled } from "@material-ui/styles";


//@ts-ignore
// import { Dropdown } from "react-native-material-dropdown";

import RequestToListController from "./RequestToListController";
import { SuccessLogo } from './assets'
import {
  Wrapper,
  Modal,
  CloseButton,
} from "../../../components/src/FormModal.web";
import { CloseCircleIcon } from "../../../components/src/Icons.web";
// Customizable Area End

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class RequestToListSuccesfull extends RequestToListController {
  // Customizable Area Start

  closeSuccessModal = () => {

    this.props.navigation.history.push(`/ProjectManagement`)

  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End

    return (
      // Customizable Area Start
      <>
        <Wrapper>
          <Modal style={{ height: "210px", maxWidth: '800px', alignSelf: 'center' }}>
            <ModalBody>
              <CloseButton onClick={this.closeSuccessModal}>
                <CloseCircleIcon />
              </CloseButton>
              <CenterWrap>
                <SuccessImg src={SuccessLogo} />
                <SubHeading>
                  Your request to list your project for funding has been submitted successfully.
                </SubHeading>
              </CenterWrap>
            </ModalBody>
          </Modal>
        </Wrapper>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const ModalBody = styled("div")({
  padding: "10px",
  position: 'relative'
});

const CenterWrap = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  height: '100%'
});


const SubHeading = styled("span")({
  fontSize: "24px",
  textAlign: 'center',
  lineHeight: '1.6',
  '@media screen and (max-width:500px)': {
    textAlign: 'center',
    fontSize: '15px',
  }
});

const SuccessImg = styled('img')({
  height: '90px',
  marginBottom: '24px',
  '@media screen and (max-width:500px)': {
    height: '90px',
  }
})

// Customizable Area End

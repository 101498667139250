import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import { sendAPIRequest } from '../../../../components/src/utils';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { parseAddRequestMessageData } from "./Messages";
import { getStorageData } from "../../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  users: any[];
  defaultUsers: any[];
  filesArray: any[];
  created_by: any[];
  requestTypes: any[];
  userPaginateCallId?: string;
  userSearchCallId?: string;
  submitRequestCallId?: string;
  respondRequestCallId?: string;
  userQuery: any;
  userPagination?: any;
  openModal: boolean;
  messageData: any;
  selectedRequestType: any;
  selectedRequestOwner: any;
  isViewRequest: boolean;
  isViewResponce: boolean;
  formType: string;
  formData: any;
  isFormInitialized: boolean;
  requestOwnerLoading: boolean;
  requestDetailCallId?: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class AddBaselineReportingController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiGetAllUsersCallId = '';
  apiGetAllRequestTypeCallId = '';
  requestDetailCallId = "";

  async componentDidMount() {
    super.componentDidMount();
    this.onInitialized();
  }

  onInitialized = async() => {
    const authToken= await getStorageData('authToken');

    const requestId = this.props.navigation.getParam('id')
    if (requestId)

      this.requestDetailCallId = sendAPIRequest(
        configJSON.getRequestDetails.replace(':id', requestId),
        {
          headers: { 'token': authToken }
        })
    const userData = await getStorageData('user');

    let requestOwner = JSON.parse(userData as string);
    this.setState({ created_by: [requestOwner] });
    this.setState({ selectedRequestOwner: [requestOwner] });
    this.initialValues.created_by_id = [JSON.parse(requestOwner?.id as string)];
    this.initialValues.sender_id = JSON.parse(requestOwner?.id as string);
    this.apiGetAllUsersCallId = sendAPIRequest(`${configJSON.getUserList}?account_id=${JSON.parse(requestOwner?.id || '0')}`, {
      headers: { token: authToken },
    });
    this.apiGetAllRequestTypeCallId = sendAPIRequest(configJSON.getRequestTypes, {
      headers: { token: authToken },
    });
  }

  onCloseModal = () => {
    this.setState({ openModal: false });
    this.props.navigation.goBack();
  }

  paginateUsersOptions = async(type = '') => {
    const authToken= await getStorageData('authToken');

    const page = this.state?.userPagination?.next_page;
    if (!page) return
    const params = new URLSearchParams()
    params.append("page", page);
    const { userQuery } = this.state;
    params.append("query", userQuery);
    const callId = sendAPIRequest(`${configJSON.getUserList}?account_id=${JSON.parse(this.state?.messageData?.userId || '0')}&${params.toString()}`, {
      headers: { token: authToken },
    })
    this.setState({
      userPaginateCallId: callId
    })
  }


  handleGetUsersAPIResponse = (json: any) => {
    if (!json?.data) return
    let users = json.data.map((user: any) => ({ id: user.id, ...user.attributes }));
    this.setState({ users: users, userPagination: json.meta.pagination, openModal: true });
    setTimeout(() => {
      this.setState({ isFormInitialized: true });
      this.setState(prevState => ({
        users: [...prevState.users, this.state.selectedRequestOwner].flat()
      }))
    }, 100);
  }

  handleGetRequestTypeAPIResponse = (json: any) => {
    if (!json?.data) return
    const requestTypes = json.data?.filter((r: any) => !r.attributes.internal)?.map((request: any) => ({ id: request?.id, title: `${request?.attributes?.title}` }))
    this.setState({ requestTypes });
  }

  handleUserSearch = (userQuery: string) => {
    this.setState({ userQuery, requestOwnerLoading: false }, this.searchUser);
  }

  handleRequestOwnerSearch = (userQuery: string, type = '') => {
    this.setState({ userQuery, requestOwnerLoading: true }, this.searchUser);
  }

  searchUser = async() => {
    const authToken= await getStorageData('authToken');

    const { userQuery } = this.state;
    const params = new URLSearchParams();
    params.append("query", userQuery);
    const callId = sendAPIRequest(`${configJSON.getUserList}?account_id=${JSON.parse(this.state?.messageData.userId || '0')}&${params.toString()}`, {
      headers: { token: authToken },
    })
    this.setState({
      userSearchCallId: callId,
    });
  }

  onSubmitRequestFun: any = {

    SubmitRequest: async(data: any) => {
    const authToken= await getStorageData('authToken');

      const { account_ids = [], ...extras } = data;
      const callId = sendAPIRequest(`${configJSON.addRequest}`, {
        body: { data: { account_ids: account_ids?.map((id: any) => id.toString()).join(','), ...extras } },
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          token: authToken
        },
      })
      this.setState({
        submitRequestCallId: callId,
      });
    },

    ResponseRequest: async(data: any) => {
      const authToken= await getStorageData('authToken');

      const { response, reference_link, attachments } = data;
      const { formData } = this.state;
      const body = new FormData()
      body.append("data[response]", response)
      body.append("data[status]", "Completed")
      body.append("data[reference_link]", reference_link);
      body.append("data[request_id]", formData?.id);
      if (attachments)
        attachments.forEach((file: any) => body.append("data[images][]", file));
      const callId = sendAPIRequest(`${configJSON.sendResponce}`, {
        body: body,
        method: 'POST',
        headers: {
          token: authToken
        },
      })
      this.setState({
        respondRequestCallId: callId,
      });
    },

    AcceptRejectRequest: async(data: any) => {
      const authToken= await getStorageData('authToken');

      const { response, reference_link, attachments } = data;
      const { formData } = this.state;
      const body = new FormData();
      body.append("data[response]", response)
      body.append("data[reference_link]", reference_link);
      body.append("data[request_id]", formData?.id);
      body.append("data[status]", formData?.status);
      if (attachments)
        attachments.forEach((file: any) => body.append("data[images][]", file));
      const callId = sendAPIRequest(`${configJSON.acceptRejectRequest}`, {
        body: body,
        method: 'POST',
        headers: {
          token:authToken
        },
      })
      this.setState({
        respondRequestCallId: callId,
      });
    }
  };

  onSumitForm = (data: any) => {
    this.onSubmitRequestFun[this.state.formType](data);
  }

  handleRequestTypeChange = (e: any, option: any, setTouched: any, setFieldValue: any) => {
    setTouched({ request_type_id: true });
    setFieldValue('request_type_id', (!option ? 0 : JSON.parse(option?.id || '')));
    this.setState({ selectedRequestType: (!option ? {} : option) });
    this.initialValues.request_type_id = (!option ? 0 : JSON.parse(option?.id || ''));
  };


  validationSchema = Yup.object().shape({
    showResponce: Yup.boolean(),
    title: Yup.string().required().label('Title').min(2).max(150).matches(/^[a-zA-Z0-9\.-\s]+$/g, 'Title cannot have special characters'),
    description: Yup.string().required().label('Description').min(2).max(1000),
    request_type_id: Yup.number().positive('Please select Request Type.').label('Request Type'),
    created_by_id: Yup.array().min(1, 'Please select Request Creater.'),
    sender_id: Yup.number().positive('Please select Request Owner.').label('Request Owner'),
    account_ids: Yup.array().min(1, 'Please select user to assign.'),
    response: Yup.string()
      .when("showResponce", {
        is: true,
        then: Yup.string().required("Must enter your responce.")
      }).max(1000),
    reference_link: Yup.string().url().label("Reference Link"),
  })

  initialType: any[] = [];
  initialValues = {
    showResponce: false,
    title: "",
    description: "",
    request_type_id: 0,
    account_ids: this.initialType,
    sender_id: 0,
    created_by_id: this.initialType,
    response: '',
    reference_link: ''
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AddRequestMessaage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      userPaginateCallId: '',
      userSearchCallId: '',
      submitRequestCallId: '',
      userQuery: '',
      users: [],
      defaultUsers: [],
      created_by: [],
      requestTypes: [],
      messageData: {},
      openModal: true,
      selectedRequestType: {},
      selectedRequestOwner: [],
      isViewRequest: false,
      isViewResponce: false,
      formData: {},
      isFormInitialized: false,
      requestOwnerLoading: false,
      formType: 'SubmitRequest',
      filesArray: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    parseAddRequestMessageData(message, this.getAddRequestMessageData);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);
      if (responseJson && responseJson.errors) {
        if (responseJson?.errors[0]?.Request == "Not authorized") {
          this.setState({ respondRequestCallId: '', openModal: false })
          toast.error("You are not authorized to perform this action")
          this.props.navigation.goBack();
          // return ;
        }
        console.log("api response 1 => ", responseJson?.errors[0]?.Request)
        this.parseApiErrorResponse(responseJson.errors);
        this.parseApiCatchErrorResponse(responseJson.errors);
      } else if (responseJson) {
        if (apiRequestCallId === this.apiGetAllUsersCallId) {
          this.handleGetUsersAPIResponse(responseJson);
        }  else if (errorReponse) {
          this.parseApiErrorResponse(errorReponse);
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getAddRequestMessageData = (data: any) => {
    this.setFormValues(data);
  }

  setFormValues = (formData: any) => {
    console.log({ formData })
    if (!formData) return;
    this.setState({ formData });
    const {
      title = '',
      description = '',
      request_type_id,
      sender_id, created_by,
      accounts = {},
      isViewRequest,
      formType,
      response,
    } = formData;
    const { data = [] } = accounts;
    let stateData: any = {};
    if (data.length > 0) {
      this.initialValues.account_ids = data.map((account: any) => account.id);
      stateData.defaultUsers = data.map((user: any) => ({ id: user.id, ...user.attributes }));
    }

    this.initialValues.title = title;
    this.initialValues.description = description;

    if (request_type_id) {
      this.initialValues.request_type_id = request_type_id?.request_type?.id;
      stateData.selectedRequestType = { ...request_type_id?.request_type };
    }

    if (created_by) {
      this.initialValues.created_by_id = [created_by?.id];
      stateData.created_by = [created_by];
    }

    if (sender_id) {
      this.initialValues.sender_id = sender_id?.id;
      stateData.selectedRequestOwner = [sender_id];
    }

    if (formType) {
      this.initialValues.showResponce = true;
      stateData.formType = formType;
    }

    if (isViewRequest) {
      stateData.isViewRequest = isViewRequest;
    }

    if (response) {
      stateData.isViewResponce = true;
      this.initialValues.response = response?.response || response?.data?.attributes?.response;
      this.initialValues.reference_link = response?.reference_link || response?.data?.attributes?.reference_link;
    }

    this.setState({
      ...stateData
    });
  }

  setFilesArray = (file: any) => {
    this.setState({ filesArray: [file] });
  }

  /**
  * Check if field is disabled, set from Message
  */
  isFieldDisabled = (fieldName: string) => (this.state.formData.disable_fields || []).indexOf(fieldName) > -1



  // Customizable Area End
}

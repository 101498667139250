import React from "react";

// Customizable Area Start
import UpdatedReportsController, {
	Props,
} from "./UpdatedReportsController.web";
import { styled } from '@material-ui/styles'
import { Box, Typography, Button } from '@material-ui/core';
import { Selfassesment, Projects, ProjectMilestone, ProjectActivities } from './assets';
import DashboardLayout from "../../../components/src/DashboardLayout.web";
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import UserContext from "../../../components/src/UserProvider";

// Customizable Area End
export default class StakeholderProfiles extends UpdatedReportsController {
	constructor(props: Props) {
		super(props);
		// Customizable Area Start
		// Customizable Area End
	}
	// Customizable Area Start
	// Customizable Area End
	render() {
		// Customizable Area Start
		const { navigation } = this.props;
		const path = this.context?.user_type == "licensed_user" ? 'Corporatepermission' : 'ESGJourney'
		const cardData : {id:number,title:string,path:string,img:string}[] = [{
			id: 1,
			img:Selfassesment,
			title:'Risk and Opportunity Management',
			path: 'RiskOpportunityManagementReport',
		},
		{
			id: 2,
			img:Projects,
			title:'Projects',
			path: 'ProjectReport',
		},
		{
			id: 3,
			img:ProjectMilestone,
			title:'Project Milestone',
			path: 'ProjectMileStoneReport',
		},
		{
			id: 4,
			img:ProjectActivities,
			title:'Project Activities',
			path: 'ProjectActivity',
		},
	]

		// Customizable Area End
		return (
			// Customizable Area Start
			<DashboardLayout isUserSurvey={false} navigation={navigation}>
			<div style={{display: "flex", alignItems: "center"}} >
                    <span data-test-id="ESG-journey-header" onClick={() => this.props.navigation.navigate(path)} style={{margin: "40px 0px 15px 12px", cursor: "pointer" }} >ESG Journey</span>
                    <div data-test-id="arrow-back" onClick={() => this.props.navigation.navigate(path)} style={{margin: "40px 0px 15px 12px",}}  >
                      <ArrowForwardIosOutlinedIcon/>  
                    </div>
                  <CoinsHead>Report</CoinsHead>
                  </div>
				<CardWrapper>
					{cardData.map((value) => (
						<SurveyCard key={value.id}>
							<LogoWrapper>
								<img src={value.img} />
							</LogoWrapper>
							<AssesmentHeading>
								<Typography style={{ fontWeight: 600 }}>{value.title}</Typography>
							</AssesmentHeading>
							<ReportWrapper data-test-id="redirection-path" onClick={() => navigation.navigate(value.path)}>
								<ReportButton> View </ReportButton>
							</ReportWrapper>
						</SurveyCard>
					))}
				</CardWrapper>
			</DashboardLayout>
			// Customizable Area End
		)
	}
}


// Customizable Area Start
UpdatedReportsController.contextType = UserContext

const SurveyCard = styled(Box)({
	width: '200px',
	height: '190px',
	padding: ' 20px 20px',
	marginLeft: '12px',
	marginBottom: '20px',
	marginRight: '8px',
	boxShadow: '1px 2px 20px #CABFC1',
	borderRadius: '10px',
	background: '#fff',
	textAlign: "center",
	display: 'flex',
	flexDirection: 'column',
})

const CoinsHead = styled("div")({
	margin: "36px 0px 15px 12px",
	fontSize: "16px",
	fontFamily: "HelveticaNeue",
	fontWeight: 600,
	"@media screen and (max-width: 768px)": {
	  margin: "20px 0px 10px 12px",
	},
  });

const LogoWrapper = styled(Box)({
	display: 'flex',
	justifyContent: 'center',
	'& img': {
		width: '60px',
	}
})

const AssesmentHeading = styled(Typography)({
	display: 'flex',
	justifyContent: 'center',
	fontWeight: 'bold',
	marginBottom: '15px',
	marginTop: '15px'
})

const ReportWrapper = styled('div')({
	display: 'flex',
	justifyContent: 'center',
	marginTop: 'auto',
})

const ReportButton = styled(Button)({
	display: 'flex',
	justifyContent: 'center',
	width: '80%',
	background: '#0fa60f',
	color: '#fff',
	fontSize: '0.75rem',
	borderRadius: '20px',
	fontWeight: 'bold',
	marginTop: '10px',
	textTransform: 'none',
	'& img': {
			width: '18px',
			marginRight: '10px'
	},
	'&:hover': {
			background: '#0fa60f',
	}
})

const CardWrapper = styled(Box)({
	display: 'flex',
})

// Customizable Area End

// Customizable Area Start
import { runEngine } from '../../../framework/src/RunEngine'
import MessageEnum, {
  getName
} from '../../../framework/src/Messages/MessageEnum'
import { Message } from "../../../framework/src/Message";

export function sendNewQuestionMessage(coins: any) {
  let msg = new Message(getName(MessageEnum.CorpstageCoinsUpdateMessage));
  msg.addData(
    getName(MessageEnum.CorpstageCoinsUpdateData,
    ),
    coins
  );
  runEngine.sendMessage(msg.id, msg);
  return msg.id
}

export function parseNewQuestionMessage(msg: Message, callback: (coins: any) => void) {
  if (msg.id !== getName(MessageEnum.CorpstageCoinsUpdateMessage))
   return
  const coins = msg.getData(getName(MessageEnum.CorpstageCoinsUpdateData))
  callback(coins)
}

// Customizable Area End

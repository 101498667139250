import React from "react";

// Customizable Area Start


import ReportModal from '../../../components/src/ReportModal.web'

// Customizable Area End

import ReportController, {
  Props

} from "./ReportController";

export default class ReportBlock extends ReportController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    if (!this.state.showModal) return <></>
    // Customizable Area End

    return (
      // Customizable Area Start
      <>
        {this.state.postType === 'BxBlockCfcourselisting5::Course' ?
          <ReportModal headerTitle='Report Course' label="Reason to report this course" onSubmit={this.handleSubmit} onClose={this.handleClose} open={this.state.showModal} />
          : <ReportModal onSubmit={this.handleSubmit} onClose={this.handleClose} open={this.state.showModal} />}

      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start



// Customizable Area End

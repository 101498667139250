import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import firebase from "firebase";

// Customizable Area Start
import { sendAPIRequest } from '../../../components/src/utils';
import { toast } from 'react-toastify';
import { sendSharePopupMessage } from '../../share/src/Messages';
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  data?:any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  openModal: boolean;
  showSDG:boolean;
  selectedCollabrators: any[];
  searchInputValue: string;
  txtSavedValue: string;
  tableRowId:any | null;
  allProjectsData: any[];
  collabratorsList: any[];
  seekingFundsData: any[];
  viewProjectData:any;
  openSeekingFunds:boolean;
  particularProject:any;
  openContributros:boolean;
  projectMilestone:any;
  showCollaborators:boolean;
  userID: number | undefined;
  fetchCollaborats:boolean;
  loading:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProjectDetailEndUserController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getProfileDashboardData: any;
  filterRef?: any;
  getAllProjectsData: any;
  getAllCollabrators: any;
  getSeekingFundsList: any;
  getSeekingFundsListById: any;
  addCollabrator: any;
  filterProjectsBYCategories:any;
  projectDetailById:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      particularProject:{},
      showSDG:false,
      fetchCollaborats:false,
      openContributros:false,
      showCollaborators:false,
      collabratorsList: [],
      seekingFundsData: [],
      selectedCollabrators: [],
      openModal: false,
      tableRowId:null,
      viewProjectData:[],
      txtSavedValue: "",
      searchInputValue: "",
      allProjectsData: [],
      openSeekingFunds:false,
      projectMilestone:[],
      userID:undefined,
      loading:false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.filterRef = React.createRef()
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    super.componentDidMount();
    const authToken = await getStorageData('authToken');
    if (authToken == null) {this.props.navigation.navigate('Home')
    }
    const userData = await getStorageData('user');
    const userId = userData ? (JSON.parse(userData)).id : undefined;
    this.setState({userID:userId})
    this.getProjectDataById(this.props.navigation.getParam('id'))
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && responseJson.errors) {
        this.setState({fetchCollaborats:false,loading:false})
        toast.error('Something went wrong')
      }
      else if(apiRequestCallId == this.projectDetailById) {
        this.setState({particularProject:responseJson.project.data,
        projectMilestone:responseJson?.project_milestone,loading:false})
      }
    }
    // Customizable Area End
  }


  // Customizable Area Start

  componentDidUpdate(prevProps:any) {
    if (prevProps.navigation.history?.location.state?.comment) {
      this.getProjectDataById(this.props.navigation?.getParam('id'))
      this.props.navigation.history.replace({state : {}})
    }
  }
  
  handleShareCourse = (p: any) => () => {
    let data = {link: `${window?.location?.href}/projects/${p?.id}`,
      shareableId: p?.id,
      shareableType: 'BxBlockProjectManagement::ProjectManagement',
    }
    sendSharePopupMessage(data);
  }

  goToEvent = (id: any) => () => {
    this.props.navigation.history.push(`${this.props.navigation.history.location.pathname}/projects/${id}`)
  }

  onLikeChange = (postId: any, delta: number) => {
    let particularProject = this.state.particularProject
      if(Boolean(particularProject.attributes?.project_likes?.some((project: any) => Number(project.like_by_id) === Number(this.state.userID))))
        { particularProject.attributes.project_likes_count -= 1
          particularProject.attributes.project_likes = particularProject.attributes?.project_likes.filter((project: any) => Number(project.like_by_id) !== Number(this.state.userID))
        }
        else{particularProject.attributes.project_likes_count += 1}
        this.setState({ 
          particularProject
        })
  }

  onLikeIdUpdate = (postId: any, likeObj: any) => {
    let particularProject = this.state.particularProject
      if (particularProject.id == postId) {
        particularProject.attributes.project_likes = [...particularProject.attributes.project_likes,likeObj];
      }
    this.setState({ particularProject })
  }





  getProjectDataById = async(id:any) => {
    this.setState({loading:true})
    const authToken = await getStorageData('authToken');
    this.projectDetailById = sendAPIRequest(`${configJSON.projectDetailById}?project_id=${id}`, {
      method: 'GET',
      headers: { 'token': authToken || '' }
    })
  }


  handleShowCollaborators = () => {
    this.setState({showCollaborators:true})
  }

  handleCloseCollaborators = () => {
    this.setState({showCollaborators:false})
  }

  handleTopContributorsModalOpen = () => {
    this.setState({openContributros:true})
  }

  handleTopContributorsModalClose = () => {
    this.setState({openContributros:false})
  }

  handleOpenSDGFactor = () => {
    this.setState({showSDG:true})
  }

  handleCloseSDGFactor = () => {
    this.setState({showSDG:false})
  }

  handleSurveyOpen = (id: any) => () => {
    this.props.navigation.history.push(`${this.props.navigation.history.location.pathname}/EndUserSurvey/${id}`)
   }
   navigationFun = ({
    id,
    props,
    screenName,
    }: {
      id?: number;
      props: unknown;
      screenName: string
    }) => {
    const navigationNessage = new Message(getName(MessageEnum.NavigationMessage));
    navigationNessage.addData(getName(MessageEnum.NavigationPropsMessage), props);
    navigationNessage.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    id && navigationNessage.addData(getName(MessageEnum.NavigationScreenNameMessage), id);
    runEngine.sendMessage(navigationNessage.id, navigationNessage);
    }
  // Customizable Area End
}

import React, { useState } from 'react'

export interface UserContextType {
    profile_pic: string,
    setProfilePic: (profile_picture: string) => void,
    removeProfilePic: () => void,
}

const UserDetailsContext = React.createContext<UserContextType>({
    
    //this is like a state
    profile_pic: '',
    setProfilePic: (profile_picture: string) => { },
    removeProfilePic: () => { },
    
});


export const UserDetailsConsumer = UserDetailsContext.Consumer;


export const UserDetailsProvider: React.FC = (props) => {
    const [profile, setProfile] = useState<string | null>(null);

    const handleSetProfile = (profile_picture: string) => {
        setProfile(profile_picture)
        console.log("working", profile_picture)
    }
    const handleRemoveProfile = () => {
        setProfile(null)
    }

    return (
        <UserDetailsContext.Provider value={{
            profile_pic: profile as string,
            setProfilePic: handleSetProfile,
            removeProfilePic: handleRemoveProfile,
        }}>
            {props.children}
        </UserDetailsContext.Provider>
    )
}


export default UserDetailsContext
// Customizable Area Start

import {runEngine} from '../../../framework/src/RunEngine'
import MessageEnum, {
  getName
} from '../../../framework/src/Messages/MessageEnum'
import { Message } from "../../../framework/src/Message";

export function sendEventUpdatedMessage(data: any){
    console.log('inerst uc1')
	let msg = new Message(getName(MessageEnum.EventUpdatedMessage));
    msg.addData(
      getName(MessageEnum.EventUpdatedDataMessage),
      data
    );
    runEngine.sendMessage(msg.id, msg);
    return msg.id
}

/*
*	execute callback only if message type match
*/ 
export function parseEventUpdatedMessage(msg: Message, callback: (data: any) =>any) {
	if(msg.id ===  getName(MessageEnum.EventUpdatedMessage)) {
		const updatedEvent = msg.getData(getName(MessageEnum.EventUpdatedDataMessage))
		callback(updatedEvent)
	}
}


// Customizable Area End
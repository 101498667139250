Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LikeAPost";
exports.labelBodyText = "LikeAPost Body";

exports.btnExampleTitle = "CLICK ME";

exports.likeEndpoint = 'bx_block_like/likes';
// Customizable Area End
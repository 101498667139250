import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { sendAPIRequest } from '../../../components/src/utils';
import { sendPostUpdatedMessage } from '../../postcreation/src/Messages';
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  onClose: Function;
  detail: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  openModal: boolean;
  postData: any;
  postId: string;
  postCommentData: any[];
  pagination?: {
    next_page?: number;
  };
  userComment: string;
  userReply: string;
  reply_id: '';
  openCommentEmoji: boolean;
  openCommentReplyEmoji: boolean;
  openReply: '';
  threedotAnchorEl?: any;
  currentPostId?: any;
  currentPost?: any;
  deletePostId?: any;
  postDeleteCallId?: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProjectController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getProjectDetailCallId = '';
  getAllProjectCommentCallId = '';
  projectCommentCallId = '';
  projectLikeCallId = '';
  projectCommentReplyCallId = '';
  getMoreCommentCallId = '';
  apiFavPostCallId: string[] = []
  favCallIdToProjectId: { [key: string]: string } = {}
  likeCallIdToCommentId: { [key: string]: string } = {}

  async componentDidMount() {
    super.componentDidMount();
    
    const authToken = await getStorageData("authToken");
    if (authToken == null) {
      this.props.navigation.navigate("Home");
    }
    this.setState({token: authToken}, () => {
      this.onInitialized();
    })
  }

  onInitialized = () => {
    this.getAllComments();
  }

  getAllComments = () => {
    const { token } = this.state
    const project_id = this.props.detail?.detail?.id
    this.getAllProjectCommentCallId = sendAPIRequest(configJSON.getAllProjectCommentCallId.replace(':project_id', project_id), {
      method: 'GET',
      headers: { token }
    })
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReportModalOpenMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: '',
      openModal: true,
      postId: '',
      postData: {},
      postCommentData: [],
      userComment: '',
      userReply: '',
      reply_id: '',
      openCommentEmoji: false,
      openCommentReplyEmoji: false,
      openReply: '',
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      
      if (responseJson && responseJson.errors) {
        this.parseApiErrorResponse(responseJson.errors);
        this.parseApiCatchErrorResponse(responseJson.errors);
      } else if (this.getProjectDetailCallId === apiRequestCallId){
        const postData = responseJson.data.attributes
        this.setState({ 
          postData
        })
      } else if (this.getAllProjectCommentCallId === apiRequestCallId){
        let postCommentData = responseJson?.comments?.data?.map((d: any) => ({ ...d.attributes, id: d.id })) || []
        this.setState({ 
          postCommentData, pagination: responseJson?.meta?.pagination
        })
      } else if (this.projectCommentCallId === apiRequestCallId){
        let userComment = '';
        this.setState({ 
          userComment
        })
        this.getAllComments();
      } else if (this.projectLikeCallId === apiRequestCallId){
        const comment_id = this.likeCallIdToCommentId[apiRequestCallId];
        let postCommentData = this.state.postCommentData;
        let updateItem = postCommentData.find((x) => x.id == comment_id);
        let index = postCommentData.indexOf(updateItem);
        delete this.likeCallIdToCommentId[apiRequestCallId]

        if (responseJson?.data) {
          updateItem.like_count += 1;
          updateItem.is_like = true;
          const likeData = { ...responseJson.data, ...responseJson.data?.attributes }
          updateItem.like_id = [...(this.state.postData.like_id || []), likeData];
          postCommentData[index] = updateItem;
          this.setState({postCommentData});
        }
        else {
          updateItem.like_count -= 1;
          updateItem.is_like = false;
          updateItem.like_id = [];
          postCommentData[index] = updateItem;
          this.setState({postCommentData});
        }
      } else if (this.projectCommentReplyCallId === apiRequestCallId){
        let new_reply = responseJson.data ? { ...responseJson.data.attributes, id: responseJson.data.id, like_count: 0 } : []
        if (new_reply) {
          let postCommentData = this.state.postCommentData;
          let updateItem = postCommentData.find((x) => x.id == this.state.reply_id);
          let index = postCommentData.indexOf(updateItem);
          updateItem.replies.push(new_reply);
          postCommentData[index] = updateItem;
          let userReply = '';
          this.setState({ 
            userReply, postCommentData, reply_id: ''
          })
        }
      } else if (this.getMoreCommentCallId === apiRequestCallId){
        let oldPostCommentData = responseJson?.comments?.data?.map((d: any) => ({ ...d.attributes, id: d.id })) || []
        let postCommentData = [...this.state.postCommentData, ...oldPostCommentData] ;
        this.setState({ 
          postCommentData, pagination: responseJson?.meta?.pagination
        })
      } else if (this.apiFavPostCallId.includes(apiRequestCallId)) {
        this.apiFavPostCallId = this.apiFavPostCallId.filter(x => x !== apiRequestCallId)
        delete this.favCallIdToProjectId[apiRequestCallId]
        if (responseJson.data) {
          const fav = { ...responseJson.data, ...responseJson.data?.attributes }
          let postData = { ...this.state.postData, favourite_id: [...(this.state.postData.favourite_id || []), fav] }
          this.setState({ postData })
          const updatedPost = {id:postData.id, ...postData}
          sendPostUpdatedMessage(updatedPost)
        }
      }
       
    }
    // Customizable Area End

  }

  // Customizable Area Start

  handlePostCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ userComment: e.target.value })
  }

  handlePostReplyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ userReply: e.target.value })
  }

  handlePostComment = () => {
    const { token, userComment } = this.state
    const body = new FormData()
    const project_id = this.props.detail?.detail?.id
    body.append("promote_content_id", project_id)
    body.append("content", userComment);

    this.projectCommentCallId = sendAPIRequest(configJSON.projectCommentEndpoint, {
      method: 'POST',
      body,
      headers: { token }
    })

    let stateEmogi = false;
    this.setState({ openCommentEmoji: stateEmogi });
  }

  handleCommentLike = (comment: any) => {
    const { token } = this.state
    const body = new FormData()
    body.append("data[attributes][likeable_type]", 'Comment')
    body.append("data[attributes][likeable_id]", comment.id);
    let url = configJSON.projectLikeEndpoint;
    if (comment.is_like) {
      url = configJSON.projectLikeEndpoint + '/' + comment?.like_id[0]?.id
    }
    this.projectLikeCallId = sendAPIRequest(url, {
      method: comment.is_like ? 'DELETE' : 'POST',
      body,
      headers: { token }
    })
    this.likeCallIdToCommentId[this.projectLikeCallId] = comment.id
  }

  handleSetReplyId = (reply_id: any) => {
    this.setState({
      reply_id
    });
  }

  handleReplyComment = () => {
    const { token, userReply, reply_id } = this.state
    const body = new FormData()
    body.append("comment_id", reply_id)
    body.append("content", userReply);

    this.projectCommentReplyCallId = sendAPIRequest(configJSON.projectCommentReplyEndpoint, {
      method: 'POST',
      body,
      headers: { token }
    })

    let stateEmogi = false;
    this.setState({ openCommentReplyEmoji: stateEmogi });
  }

  loadMore = () => {
    const { token } = this.state
    const project_id = this.props.detail?.detail?.id
    let project_url = configJSON.getAllProjectCommentCallId.replace(':project_id', project_id) + '&page=' + this.state.pagination?.next_page;
    this.getMoreCommentCallId = sendAPIRequest(project_url, {
      method: 'GET',
      headers: { token }
    })
  }

  onLikeChange = (postId: any, delta: number) => {
    let postData = this.state.postData;
    postData.total_likes += delta;
    postData.like_id = [];
    this.setState({ postData })
  }
  onLikeIdUpdate = (postId: any, likeObj: any) => {
    let postData = this.state.postData;
    postData.like_id = [likeObj];
    this.setState({ postData })
  }

  onCommentEmojiClick = (event: any, emojiObject: any) => {
    let emoji = emojiObject?.emoji;
    let userComment = this.state.userComment + emoji;
    this.setState({ userComment });
  }

  openEmojiComment = () => {
    let stateEmogi = !this.state.openCommentEmoji;
    this.setState({ openCommentEmoji: stateEmogi });
  }

  openEmojiCommentReply = () => {
    let stateEmogi = !this.state.openCommentReplyEmoji;
    this.setState({ openCommentReplyEmoji: stateEmogi });
  }

  onReplyEmojiClick = (event: any, emojiObject: any) => {
    let emoji = emojiObject?.emoji;
    let userReply = this.state.userReply + emoji;
    this.setState({ userReply });
  }

  showReply = (comment_id: any) => {
    this.setState({ openReply: comment_id });
  }
  // Customizable Area End
  
}

import React from "react";
// Customizable Area Start
import {
    Box, Grid, Typography, Divider,
    InputAdornment,
    IconButton, OutlinedInput
} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';

import ChatMenuController, { Props } from "../../blocks/dashboard/src/ChatMenuWebController";


import { styled } from '@material-ui/styles';
import SearchFilter from './Search.web';
import CloseIcon from '@material-ui/icons/Close';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import SearchSelect from "./SearchSelectChat.web ";
import { Formik } from 'formik';

// @ts-ignore
import classes from './Share.module.css';
import Picker from 'emoji-picker-react';
import Moment from 'moment';
import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined';
const EmogiIcon = require('./ic_smile.png');
const SendIcon = require('./ic_send.png');
const imageIcon = require('./ic_image_icon.png');
const videoIcon = require('./ic_video_icon.png');
const csvIcon = require('./ic_csv_icon.jpg');
const pdfIcon = require('./ic_pdf.png');
const docIcon = require('./ic_doc.png');
const xlsIcon = require('./ic_xlsx_icon.png');
const genericIcon = require('./ic_generic_file.png');
// Customizable Area End

const SelectItemUser = ({ item, value, onBackClick }: { item: any, value: any, onBackClick: any }) => {
    return <>
        <Grid container onClick={() => onBackClick(item)}>
            <Grid item xs={12} style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <div className={`${classes.listLeftContainer}`}>
                    <SelectItemUserText >{`${item.first_name} ${item.last_name}`}</SelectItemUserText>
                    <Avatar className={`${classes.listAvatar}`} src={(item?.profile_pic)} aria-label="profile-pic">
                    </Avatar>
                </div>
            </Grid>
        </Grid>
    </>
}

const  IconInput = ({onFileSelected}:{
    onFileSelected: (files: FileList) => void;
  }
) => {
    const handleFileSelection = (event:any) => {
      if (onFileSelected) {
        onFileSelected(event);
      }
    };
  
    return (
      <label style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
        <AttachFileOutlinedIcon style={{ color: 'gray' }} />
        <input
          type="file"
          accept="*"
          style={{ display: 'none' }}
          onChange={handleFileSelection}
        />
      </label>
    );
  }

export default class ChatMenu extends ChatMenuController {

    constructor(props: Props) {
        super(props);
    }

    handleAddMessage = (e: any) => {
        this.setState({
            userReply: e.target.value, openCommentReplyEmoji: false
        });
    }

    handleAddUSer = () => {
        this.setState({ isAddMessageList: true, openCommentReplyEmoji: false });
    }

    changeViewChat = () => {
        this.setState({ viewChat: !this.state.viewChat });
    }

    openEmojiCommentReply = () => {
        let stateEmogi = !this.state.openCommentReplyEmoji;
        this.setState({ openCommentReplyEmoji: stateEmogi });
    }

    formatAttachmentName = (name: string) => {
        let matches = name.match(/\b(\w)/g);
        if (matches) {
            let acronym = matches.join('');
            return (acronym.toUpperCase());
        } else {
            return name
        }
    }
       
      
    renderChatContent = () => {
        const fileTypeIcons: { [key: string]: string } = {
            'image/png': imageIcon,
            'image/jpeg': imageIcon,
            'image/jpg': imageIcon,
            'video/mp4': videoIcon,
            'video/avi': videoIcon,
            'text/csv': csvIcon,
            'application/pdf': pdfIcon,
            'application/msword': docIcon,
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': docIcon,
            'application/vnd.ms-excel': xlsIcon,
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': xlsIcon,
          };
        const {
          IsunbletoLoad,
          isAddMessageList,
          isInduMessage,
          openCommentReplyEmoji,
          totalUnreadMessageCount,
          QBuserDetails,
          MessageUSerID,
          MessagesList,
          UserMessageList,
          userReply,
          viewChat,
        } = this.state;
      
        if (IsunbletoLoad) {
          return (
            <Box style={{
                backgroundColor: "white",
                borderRadius: "20px",
                height: this.props.showChatMobileView ? 'auto' : '500px',
                display: "flex",
                padding: "20px 10px",
                flexDirection: "column"
            }} >
                <Typography
                    align="center"
                    style={{ fontWeight: "bold", marginTop: "200px" }}
                >
                    Unable to load chat.
                </Typography>

            </Box>
          );
        } else if (isAddMessageList) {
          return (
            <Box style={{
                backgroundColor: "white",
                borderRadius: "20px",
                height: this.props.showChatMobileView ? 'auto' : '500px',
            }} >
                <div style={{
                    display: "flex",
                    padding: "20px 10px",
                    flexDirection: "column"
                }}>
                    <div style={{ display: "block", marginBottom: '5px' }} >
                        <div style={{ display: "flex" }} >
                            <Typography
                                style={{ fontWeight: "bold", marginLeft: "10px", marginTop: "8px" }}
                            >
                                New Message
                            </Typography>
                        </div>
                        <IconButton style={{ float: 'right', margin: '-35px 0 0 0' }}
                            onClick={() => this.handleClose()}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <Divider />
                    <div style={{ margin: '10px 0 0 0' }}>
                        <Formik initialValues={this.initialValues} onSubmit={() => { }}
                        >
                            <SearchSelect
                                scrollHeight={'175px'}
                                hasMore={!!this.state?.userPagination?.next_page}
                                nextPage={this.paginateUsersOptions}
                                loading={this.state.userLoading}
                                onSearch={this.handleUserSearch}
                                allItems={this.state?.users}
                                items={this.state?.users}
                                name="shared_to_ids"
                                label={"Select Users"}
                                placeholder="Select Users"
                                SelectItem={SelectItemUser}
                                getOptionValue={(user: any) => `${user?.first_name} ${user?.last_name}`}
                                OnBackclick={this.handleAddUSerDetails}
                            />
                        </Formik>
                    </div>
                    <div style={{ height: '285px', overflow: 'auto' }}>
                    </div>
                </div>
            </Box>
          );
        } else if (isInduMessage) {
          return (
            <Box style={{
                backgroundColor: "white",
                borderRadius: "20px",
                height: this.props.showChatMobileView && openCommentReplyEmoji ? 'auto' :
                    (!openCommentReplyEmoji && this.props.showChatMobileView) ? '505px' :
                        (openCommentReplyEmoji && viewChat) ? '820px' :
                            '500px',
            }} >
                <div style={{
                    display: "flex",
                    padding: "20px 10px",
                    flexDirection: "column"
                }}>
                    <div style={{ display: "block", marginBottom: '5px' }} >
                        <div style={{ display: "flex" }} >
                            <Avatar>{this.formatAttachmentName(MessageUSerID.name)}</Avatar>
                            <Typography
                                style={{ fontWeight: "bold", marginLeft: "10px", marginTop: "8px" }}
                            >
                                {MessageUSerID.name}
                            </Typography>
                        </div>

                        <IconButton style={{ float: 'right', margin: '-45px 0 0 0' }}
                            onClick={() => this.handleClose()}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                    <Divider />
                    <div id="chat-content" style={{ height: '345px', overflow: 'auto' }}>
                        {UserMessageList.map((list: any, index: any) => (
                            <div key={list._id} style={{ margin: '10px 0px', display: "flex" }} id={index == UserMessageList.length - 1 ? `chat-scroll` : ""}>
                                <Avatar style={{ width: '28px', height: '28px', margin: '0 5px' }}>
                                    {list.sender_id == QBuserDetails.id ?
                                        this.formatAttachmentName(QBuserDetails.full_name)
                                        :
                                        this.formatAttachmentName(MessageUSerID.name)
                                    }</Avatar>
                                <Typography
                                    variant="subtitle2"
                                    component="div"
                                    style={{ fontSize: 12 }}
                                >
                                    <div style={{ display: 'flex' }}>

                                        <div style={{ fontWeight: "bold", margin: '0px 5px' }} >
                                            {list.sender_id == QBuserDetails.id ?
                                                QBuserDetails.full_name :
                                                MessageUSerID.name}
                                        </div>
                                        <div style={{ fontSize: '10px', marginTop: '2px' }}>{Moment(list.created_at).format('MMM DD hh:mm')}</div>
                                    </div>
                                    <div style={{ margin: '0px 5px', fontSize: '12px',wordWrap: 'break-word', maxWidth: '200px' }} >
                                        {list.message}</div>
                                    {list &&
                                        list.attachments &&
                                        list.attachments.map((attachment: any) => (
                                            <div key={attachment.id}>
                                                    <BorderDiv>
                                                        <div style={{ display: "flex" }} onClick={() => this.downloadAttachment(attachment.uid,list.message)}>
                                                            <img src={fileTypeIcons[attachment.type] || genericIcon} width="42px" />
                                                            <DocumentBox>
                                                                <Typography style={{
                                                                    whiteSpace: "nowrap",
                                                                    overflow: "hidden",
                                                                    textOverflow: 'ellipsis',
                                                                    width: '100%'
                                                                }}>{attachment.fileName || list.message}</Typography>
                                                            </DocumentBox>
                                                        </div>
                                                    </BorderDiv>
                                            </div>
                                        ))}
                                </Typography>
                            </div>
                        ))}
                    </div>
                </div>
                <div style={{ margin: '0 10px' }}
                >
                    <OutlinedInput
                        id="outlined-adornment-weight"
                        value={userReply}
                        onChange={this.handleAddMessage}
                        placeholder="Write a message..."
                        onKeyDown={(event) => {
                            if (event.key === 'Enter' || event.keyCode === 13) {
                                this.handleCourseComment()
                            }
                        }}
                        endAdornment={
                            <InputAdornment position="end">
                                {/* <AttachFileOutlinedIcon style={{color:'gray', cursor:"pointer"}} onClick={(e:any) => this.handleAttachment(e)}/> */}
                                <IconInput onFileSelected={this.handleAttachment} />
                                <BtnImg src={EmogiIcon}
                                    onClick={() => this.openEmojiCommentReply()}
                                    style={{
                                        width: '18px',
                                        paddingLeft: '10px'
                                    }} />
                                <BtnImg src={SendIcon}
                                    onClick={() => this.handleCourseComment()}
                                    style={{
                                        width: '18px',
                                        paddingLeft: '25px',
                                        float: 'right',
                                        paddingRight: '2px',
                                        cursor: 'pointer'
                                    }} />
                            </InputAdornment>}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                            'aria-label': 'weight',
                        }}
                        style={{ width: '100%' }}
                    />
                </div>
                {openCommentReplyEmoji ?
                    <Picker onEmojiClick={this.onCommentEmojiClick} pickerStyle={{ width: '100%' }} /> : ""}
            </Box>
          );
        } else {
          return (
            <Box style={{
                backgroundColor: "white",
                borderRadius: "20px",
                height: this.props.showChatMobileView ? 'auto' :
                    viewChat ? '500px' : '90px',
                overflow: 'auto',
                boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'
            }} >
                <div style={{
                    display: "flex",
                    padding: "20px 10px",
                    flexDirection: "column"
                }}>

                    <div style={{ display: "block", marginBottom: '5px' }} >
                        <div style={{ display: "flex" }} >
                            <Avatar src={this.state.profile_pic} style={{ marginLeft: '5px' }} />
                            <Typography
                                style={{ fontWeight: "bold", marginLeft: "10px", marginTop: "8px" }}
                            >
                                Chat
                            </Typography>
                            <Avatar style={{
                                width: '24px',
                                height: '24px',
                                margin: '5px 0 0 10px',
                                backgroundColor: '#f44336', fontSize: '12px', fontWeight: 'bold'
                            }}
                            >
                                {totalUnreadMessageCount}
                            </Avatar>
                        </div>
                        <div style={{ float: 'right' }}>
                            {this.state.viewChat &&
                                <IconButton style={{ margin: '-45px 0 0 0' }}
                                    onClick={() => this.handleAddUSer()}>
                                    <PersonAddIcon />
                                </IconButton>
                            }
                            {this.state.viewChat ?
                                <IconButton style={{ margin: '-45px 0 0 0' }}
                                    onClick={() => this.changeViewChat()}>
                                    <KeyboardArrowUpIcon />
                                </IconButton>
                                :
                                <IconButton style={{ margin: '-45px 0 0 0' }}
                                    onClick={() => this.changeViewChat()}>
                                    <KeyboardArrowDownIcon />
                                </IconButton>
                            }
                        </div>
                    </div>
                    {this.state.viewChat &&
                        <>
                            <Divider />
                            <div style={{ margin: '15px 0 0 0' }}>
                                <SearchFilter onChange={(e) => this.onListSerach(e)} />
                            </div>
                            <div>
                                {MessagesList.map((list: any, index: any) => (
                                    <div key={list._id} onClick={() => this.handleMesage(list)} style={{ cursor: "pointer" }}>
                                        <div style={{
                                            margin: "15px 0px", display: "flex"
                                        }}>
                                            <Avatar>{this.formatAttachmentName(list.name)}</Avatar>
                                            <Typography
                                                variant="subtitle2"
                                                component="div"
                                                style={{ fontSize: 12, margin: '2px 8px', width: '200px' }}
                                            >
                                                <span style={{ fontWeight: "bold" }} >{list.name}{list.unread_messages_count > 0 ?
                                            <Avatar style={{
                                                width: '24px',
                                                float: 'right',
                                                height: '24px',
                                                backgroundColor: '#1ea60a', fontSize: '12px', fontWeight: 'bold'
                                            }}>{list.unread_messages_count > 0 ? list.unread_messages_count : 0}</Avatar>
                                            : null}</span><br />
                                                <span>{list.last_message}</span>
                                            </Typography>
                                        </div>
                                        
                                        {MessagesList?.length - 1 == index ? null :
                                            <Divider />
                                        }
                                    </div>
                                ))}
                            </div>
                        </>
                    }
                </div>
            </Box>
          );
        }
      }
      

    render() {
        const user = this.state.userString ? JSON.parse(this.state.userString) : {}
        const showChatMobileView = user?.role?.role_level !== 10 && (this.props.showChatMobileView || window.screen.width > 768);
        return (
            <Grid container style={{position: "fixed",
                width: "300px",
                bottom: 0,
               }}>
                {showChatMobileView && (
                    <div style={{ marginTop: showChatMobileView ? "50px" : "70px", width: "100%" }}>
                        {this.renderChatContent()}
                    </div>
                )}
            </Grid>
        )
    }
}
// Customizable Area Start

const SelectItemUserText = styled('span')({
    overflow: 'hidden',
    textOverflow: 'ellipsis'
})

const BtnImg = styled('img')({
    width: 25
})
const BorderDiv = styled('div')({
    border: '1px solid #999',
    padding: '10px 15px',
    borderRadius: '15px',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    marginTop: '5px',
    cursor: 'pointer'
})

const DocumentBox = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '10px',
    gap: '5px',
    justifyContent: 'center',
})

// Customizable Area End




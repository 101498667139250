Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "BaselineReporting";
exports.labelBodyText = "BaselineReporting Body";

exports.btnExampleTitle = "CLICK ME";
exports.requestApiCallEndpoint = "bx_block_request_management/requests";
exports.requestApiCallMethodType = "GET";
exports.getUserList = "bx_block_share/get_users";
exports.addRequest = "bx_block_request_management/requests";
exports.getRequestTypes = "bx_block_request_management/request_types";
exports.getAllRequests = "bx_block_request_management/requests";
exports.getRequestDetails = "bx_block_request_management/requests/:id"
exports.sendResponce = "bx_block_request_management/response";
exports.acceptRejectRequest = "bx_block_request_management/approve_or_reject_request";
exports.filterRequest = "bx_block_request_management/filter_request";
exports.getAllPolicies = "bx_block_policies/policies";
exports.detePolicy = "bx_block_policies/policies/:id";
exports.createPolicy = "bx_block_policies/policies";
exports.showPolicy = "bx_block_policies/policies/:id";
exports.deletePolicyAttachmentEndPoint = "bx_block_policies/policies/"
exports.showAllCards = "bx_block_abilities/abilities/show_esg_journey"
  // Customizable Area End
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum,{
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { sendAPIRequest } from "../../../components/src/utils";
import { getStorageData } from "../../../framework/src/Utilities";
//Customizable Area End

export const configJSON = require("./config.js")

export interface Props{
    navigation:any;
    id:string;
    //Customizable Area Start
    //Customizable Area End
}

interface S{
    txtInputValue:string;
    txtSavedValue:string;
    enableField:boolean;
    token:string;
    //Customizable Area Start
    emailPopup:boolean;
    allQuestion:any;
    deleteTemplteId:any;
    deleteQuestion:boolean;
    deleteId:number;
    check:boolean;
    emailData:any;
    subject:string;
    content:string;
    emailErrorMsg:string;
    sendTo:any[];
    newEmail:string;
    viewChat:any;
    surveyData:any;
    surveyProjectData:any;
    openSurvey: boolean;
    openProjectSeekingFund: boolean;
    modelSurveyDetail:any;
    activeFundButton:boolean;
    activeSurveyButton:boolean;
    total_survey_pages: number | undefined;
    survey_pages: number;
    loadingSurvey: boolean;
    userDataString: string;
    //Customizable Area End
}

interface SS{
    id:any;
    //Customizable Area Start
    //Customizable Area End
}

export default class UserSurveyController extends BlockComponent<
    Props,
    S,
    SS
> {

    //Customizable Area Start
    getServeyDetailCallId = '';
    getAllQuestionId = '';
    questionDeleteCallId = '';
    templateDeleteCallId = '';
    sendEmailCallId = '';
    apiUserSurveyCallId:string = '';
    apiUserSurveyProjectCallId:string = '';
    getLikeForProjectCallId: string = '';
    apiUserLikeCountCallId: string = '';
    // Customizable Area End
    constructor(props:Props){
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            //Customizable Area Start
            txtInputValue:"",
            txtSavedValue:"A",
            enableField:false,
            token: '',
            survey_pages: 1,
            loadingSurvey:false,
            total_survey_pages: undefined,
            emailPopup:false,
            allQuestion:[],
            deleteTemplteId:'',
            deleteQuestion:false,
            deleteId:0,
            check:true,
            emailData:{},
            subject:'Share your valuable thoughts with us',
            content:`As an open and transparant company committed to sustainbility,we truly value feedback of our stakeholders.Please share with us your experience of dealing and engaging with our company by taking part in this short survey.
            About the survey:
            To access the survey, click on this link.
            It will take approximately xx minutes to complete this survey. Please provide your response by "end date".

            Thanks
            Company Stakeholders engagement Team`,
            emailErrorMsg:"",
            sendTo:[],
            newEmail:'',
            viewChat: window.screen.width < 768 ? true : false,
              surveyData: [],
              surveyProjectData: [],
              openSurvey: false,
              openProjectSeekingFund: false,
              modelSurveyDetail: {},
              activeFundButton: false,
              activeSurveyButton:true,
              userDataString:''
              //Customizable Area End 
            };
            runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

            //Customizable Area Start
            //Customizable Area End

    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        const authToken = await getStorageData("authToken");
        let userData = await getStorageData("user");

        if(authToken == null){
            this.props.navigation.navigate("Home");
        }
        this.setState({userDataString: userData, token: authToken}, () => {
       this.onInitialized()
    })
    }

    onInitialized = async() =>{
        this.setState({loadingSurvey: true});
        this.apiUserSurveyCallId = sendAPIRequest(`${configJSON.getUserSurveyEndpoint}?page=${this.state.survey_pages}`,{
            headers : {token : this.state.token},
        });        
    }

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): Promise<void> {
        if (prevState?.surveyProjectData !== this.state?.surveyProjectData) {
            let detailObj = this.state?.surveyProjectData?.find((item: any) => item?.id === this.state.modelSurveyDetail?.id)
            this.setState({ ...this.state, modelSurveyDetail: detailObj})
          }
          if (prevState.activeFundButton !== this.state.activeFundButton) {
            if (this.state.activeFundButton) {
                this.setState({loadingSurvey: true});
              this.apiUserSurveyProjectCallId = sendAPIRequest(configJSON.getUserSurveyProjectEndpoint, {
                headers: { token: this.state.token}
              });
            }
          }
          if (prevState.activeSurveyButton !== this.state.activeSurveyButton) {
            if (this.state.activeSurveyButton) {
                this.setState({loadingSurvey: true});
                this.apiUserSurveyCallId = sendAPIRequest(`${configJSON.getUserSurveyEndpoint}?page=${this.state.survey_pages}`,{
                    headers : {token : this.state.token}
                });  
            }
          }
    }
    // Customizable Area End
    async receive(from: string, message: Message){
        //Customizable Area Start
        runEngine.debugLog("Message Recieved",message);
        
        if(message.id === getName(MessageEnum.AccoutLoginSuccess)){
            let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

            this.showAlert(
                "Change Value",
                "From:" + this.state.txtSavedValue + "To:" + value
            );
            this.setState({ txtSavedValue : value});
        }
        
       
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            if(responseJson){
                switch (apiRequestCallId) {
                    case this.apiUserSurveyCallId:
                        this.handleGetUserSurveyAPIResponse(responseJson);
                      break;
              
                    case this.apiUserSurveyProjectCallId:
                        this.handleGetUserProjectSurveyAPIResponse(responseJson);
                      break;
              
                    case this.getLikeForProjectCallId:
                        this.handleGetLikeForProject(responseJson);
                      break;
              
                    case this.apiUserLikeCountCallId:
                        this.handleLikeCount(responseJson);
                      break;
              
                    default:
                        this.setState({loadingSurvey: false});
                      if (responseJson.errors) {
                        this.parseApiErrorResponse(responseJson.errors);
                        this.parseApiCatchErrorResponse(responseJson.errors);
                      }
                      break;
                  }
            }else{
                this.setState({loadingSurvey: false});
            }
            
        }
        //Customizable Area End
    }

    // Customizable Area Start
    handleSurveyPageChange = (event: any, value: any) => {
        this.setState({ survey_pages: value }, this.onInitialized)
      }

    handleGetUserSurveyAPIResponse = (json:any) =>{
        this.setState({...this.state, surveyData: json?.surveys?.data,total_survey_pages: json?.meta?.pagination?.total_pages,loadingSurvey: false});
    }

    handleGetUserProjectSurveyAPIResponse = (json:any) => {
        this.setState({...this.state, surveyProjectData: json?.data, loadingSurvey: false});
        this.getLikeForProjectCallId = sendAPIRequest(configJSON.getLikeForProjectEndpoint, {
            headers : {token : this.state.token},
        })
    }

    handleGetLikeForProject = async(json: any) => {
        let tempSurveyProject = this.state.surveyProjectData;
        let filterLike: any = [];
        let userData: any = this.state.userDataString

        const projectIds = tempSurveyProject?.map((item: any) => item?.id)

        json?.data?.map((item: any) => {
            if(item?.attributes?.likeable_type === "BxBlockProjectportfolio::PromoteContent"){
                if(projectIds?.includes(item?.attributes?.likeable_id.toString())){
                    tempSurveyProject?.map((items: any, index: any)=>{
                        if(items.id === item?.attributes?.likeable_id.toString()){
                            tempSurveyProject[index]['attributes']['likes'] = item;
                        }
                    })
                }

                if(item?.attributes?.like_by_id == JSON.parse(userData)?.id){
                    filterLike.push(item?.attributes?.likeable_id?.toString());
                }
                

            }
        })

        tempSurveyProject?.map((item: any, index: any) => {
            tempSurveyProject[index]['attributes']['is_liked'] = filterLike?.includes(item?.id)
        })
        
        this.setState({...this.state, surveyProjectData: tempSurveyProject});
        
    }

    handleLikeChange = (id?: any) => {
        this.apiUserLikeCountCallId = sendAPIRequest(configJSON.getUserLikeCountEndpoint.replace(':id', id), {
            headers : {token : this.state.token}
        })
    }

    handleLikeCount = (json: any) => {
        let userData: any = this.state.userDataString
        let id: any = userData?.id;
        let userIds: any = [];
        let project_like_id: any = null;
        json?.data?.attributes?.liked_by_users?.map((item: any) => {
            if(item?.likeable_type == "BxBlockProjectportfolio::PromoteContent"){
                userIds.push(item?.like_by_id)
                
                if(item?.like_by_id == JSON.parse(userData)?.id){
                    project_like_id = item?.id;
                }
            }
        });
        this.setState({...this.state, modelSurveyDetail: {...this.state.modelSurveyDetail, attributes: {...this.state.modelSurveyDetail.attributes, likes : json?.data, is_liked: userIds.includes(id), project_like_id: project_like_id}}});
    }
    fundButtonCall = () => {
        this.setState({ ...this.state, activeFundButton: true, activeSurveyButton: false })
    }
    surveyButtonCall = () => {
        this.setState({ ...this.state, activeFundButton: false, activeSurveyButton: true })
    }
    handleCarbonCalButtonClick = () => {
        const url = `${configJSON.carbonCalculateToolUrl}`;
        window.open(url, '_blank');
      };

    // Customizable Area End
 }
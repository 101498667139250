Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "DesktopNotifications2";
exports.labelBodyText = "DesktopNotifications2 Body";

exports.allNotificationsEndpoint = "/bx_block_notifications/notifications"
exports.allNotificationsFilterEndpoint = "/bx_block_notifications/all_notification"
exports.markAllReadEndpoint = "/bx_block_notifications/notifications/mark_as_read/"
exports.markReadEndpoint = "/bx_block_notifications/notifications/:id"
exports.btnExampleTitle = "CLICK ME";
// Customizable Area End
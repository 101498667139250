import React from 'react'
import { Formik, Form } from 'formik'
import { Wrapper, Modal, CloseButton, ModalBody, MInput, InputRow, FormikSubmit } from './FormModal.web'
import { styled } from '@material-ui/styles'
import { 
  Avatar, 
  Button, 
  Checkbox, 
  CircularProgress,
  Divider, 
  FormControl, 
  Input,
  InputAdornment, 
  InputLabel, 
  ListItemText, 
  MenuItem, 
  Paper, 
  Select,
  SelectProps, 
  TextField as TextFieldBase,
  TextFieldProps, 
  Typography } from '@material-ui/core';
const CloseIcon = require('./ic_close.png')
import * as yup from 'yup'

const validationSchema = yup.object().shape({
  reason: yup.string().required().max(1000).label('Reason'),
})
const initialValues = {
  reason: ''
}

export default function ReportModal({headerTitle, onClose, onSubmit, label}: any){
	return <Wrapper style={{ zIndex: '9999999'}}>
		<SmallModal>
			<Formik 
        validationSchema={validationSchema} 
        initialValues={initialValues} 
        onSubmit={onSubmit}
      >
			<ModalBody translate="yes">
               	 <div>
                   <Typography style={{color: '#444'}} variant="h6">
                     <b>{headerTitle || "Report Post"}</b>
                   </Typography>
                   <CloseButton style={{top: '10px', right: '5px'}} onClick={onClose}>
                     <img src={CloseIcon} width="35px" />
                   </CloseButton>
                 </div>
                <InputRow 
                	label={label || "Reason to report this post*"}
                	input={<MInput 
                		name="reason" 
                		placeholder="Type here"
                		multiline
                		minRows={4}
                		maxRows={4} 
                	/>}
                />
            	<Actions>
            		<CancelButton onClick={onClose}>
            			Cancel
            		</CancelButton>
                <FormikSubmit component={SubmitButton} props={{}}>
                  Submit
                </FormikSubmit>
            		
            	</Actions>
            </ModalBody>

        </Formik>
		</SmallModal>
	</Wrapper>
} 

const Actions = styled('div')({
	display: 'flex',
	justifyContent: 'center',
	gap: '10px'
})

const SmallModal = styled(Modal)({
	height: '270px',
	overflow: 'hidden',
  top: 'calc( 50vh - 250px )'
})

const CancelButton = styled(Button)({
  color: '#FFF',
  background: '#D0D0D0',
  padding: '10px 18px 10px 18px',
  margin: '5px',
  '&:hover': {
    background: '#999',
    opacity: 0.9
  }
})

const SubmitButton = styled(Button)((props: any)=>({
  color: '#FFF !important',
  background: props.disabled?'#D0D0D0':'#019B07',
  padding: '10px 18px 10px 18px',
  margin: '5px',
  '&:hover': {
    background: '#999',
    opacity: 0.9
  }})
)
import React, { useEffect } from "react";
// Customizable Area Start
import TopNavBar from "../../../components/src/TopNavbar";
import {
    Box,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Avatar,
    Typography,
    Button,
    MenuItem,
    Select,
    List, 
    ListItem,
    ListItemAvatar,
    ListItemText,
    Checkbox,
    FormControl,
    TextField,
    InputBase,
    InputAdornment,
    ListSubheader,
    CircularProgress,
    Popover,
    Paper,
} from "@material-ui/core";
import { SelectItemUser } from './CreateModal.web'
import { CloseCircleIcon, SearchIcon } from "../../../components/src/Icons.web";
import { useDebounce } from '../../../components/src/utils'
import LanguageIcon from "@material-ui/icons/Language";
import SeeMore from "../../../components/src/SeeMore.web";
import PlusMorePopup from '../../../components/src/PlusMorePopup.web'
import WriteAPost from '../../../components/src/WriteAPostCard.web'
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import LeftMenuWeb from "../../../components/src/LeftMenuWeb";
import RightMenuWeb from "../../../components/src/RightMenuWeb";
import DashboardLayout from "../../../components/src/DashboardLayout.web";
import { FieldEffect } from "../../../components/src/FormikEffect.web";
import SelectInput from "../../../components/src/SelectInput.web";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { LoadingSpinner } from "../../../components/src/LoadingSpinner.web";
import SearchSelect from "../../../components/src/SearchSelect.web";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import OverlayLoader from "../../../components/src/Loader.web";
import FilterPosts from '../../filteritems/src/FilterPosts.web'
import { Scrollbars } from 'react-custom-scrollbars-2';
import Share from "../../share/src/Share.web";
import { Link } from 'react-router-dom'
// import SearchIcon from '@material-ui/icons/Search';
import { Formik, Form, useField } from "formik";
import FormModal, {
    InputRow,
    MInput,
    MCountedTextArea,
    MFileDrop,
    MTimePicker,
    MDatePicker,
    MSelect,
    FInputLabel,
    MTextArea,
} from "../../../components/src/FormModal.web";
import Chip from "@material-ui/core/Chip";
import Flex from "../../../components/src/Flex.web";
import Switch from "../../../components/src/Switch.web";
import { styled } from "@material-ui/styles";
import UserContext from "../../../components/src/UserProvider";
import { CommunityCard, FilterSelect } from "./Components.web";
import ActivityFeed from '../../ActivityFeed/src/ActivityFeed.web'
import CreateModal from './CreateModal.web'
import { icEdit, icShare } from './assets'
import CommunityDetailController, {
    Props,
    configJSON,
} from "./CommunityDetailController";
// @ts-ignore
import classes from './CommunityWeb.module.css'

const getName = (user: any) => `${user?.attributes?.first_name} ${user?.attributes?.last_name}`

// Customizable Area End

export default class CommunityDetailWeb extends CommunityDetailController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { community } = this.state
        if(community && this.props.navigation.getParam('id') !=community.id) {
            this.loadCommunityDetail()
            this.initDetailPage()
            this.setState({ community: undefined })
        }
        // Customizable Area End
        return (
            // Customizable Area Start
            <DashboardLayout
                token={this.token}
                navigation={this.props.navigation}
            >
                <div className={classes.DetailCard}>
                <div style={{ maxWidth: "1000px", width: "100%" }}>
                    <CommunityDetailCard 
                        onEdit={this.editCommunityClick}
                        onShare={this.shareCommunityClick}
                        community={this.state.community}
                        onAddMember={this.addMemberClick}
                        onJoinUnjoinClick={this.joinUnjoinClick}
                        memberPopoverProps={{
                            onPaginate: this.handleMemberPopPaginate,
                            onSearch: this.handleMemberPopSearch,
                            paginateLoading: Boolean(this.state.memberPopPaginateCallId || this.state.memberPopSearchCallId),
                            onExport: this.handleMemberExport,
                            onClose: () => this.handleMemberPopSearch('')
                        }}
                        moderatorPopoverProps={{
                            onPaginate: this.handleModeratorPopPaginate,
                            onSearch: this.handleModeratorPopSearch,
                            paginateLoading: Boolean(this.state.moderatorPopPaginateCallId || this.state.moderatorPopSearchCallId),
                            onExport: this.handleModeratorExport,
                            onClose: () => this.handleModeratorPopSearch('')
                        }}

                    />
                    <AddMemberPopover 
                        onClose={() => this.setState({ addMemberAnchorEl: null })}
                        addMemberAnchorEl={this.state.addMemberAnchorEl}
                        users={this.state.memberOptions || []}  
                        onSearch={this.addMemberSearch} 
                        onPaginate={this.addMemberPaginate}
                        paginateLoading={this.state.memberPaginateCallId?.length>0}
                        onSubmit={this.handleAddMembersSubmit}
                    />
                    { // delay CreateModal mounting till community is populated,
                      // so that moderators and members are injected in the 
                      // constructor of CreateModal 
                    this.state.community && this.state.modal === "edit" && <CreateModal
                            open={this.state.modal === "edit"} 
                            onClose={this.hideModal} 
                            onCreate={this.handleCommunityUpdateCallback}
                            moderators={this.getModerators()}
                            members={this.getMembers()}
                            initialValues={this.state.initialValues}
                            community_id={this.props.navigation.getParam('id')} 
                            {...this.props} 
                        />
                    }

                    {(
                        this.state.community?.in_community || this.state.community?.community_type === "public"
                     ) && <ActivityFeed inside="CommunityDetail" {...this.props} />
                                        }
                    <OverlayLoader
                        loading={!this.state.community || !!this.state.addMemberCallId || !!this.state.followCommunityCallId}
                    //   loading={this.state.loadingCommunities || this.state.loadingCreate}
                    />
                </div>
                </div>
            </DashboardLayout>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

CommunityDetailController.contextType = UserContext;

const CommunityDetailCard = (props: any) => {
    
    const community = props.community;
    if (!community) return <></>;
    const adminName = `${community.admin?.data?.attributes?.first_name} ${community?.admin?.data?.attributes?.last_name}`
    const membersList = community.members.data.map((user: any) => <Link to={`/user/${user.id}`}><ListItemUser key={user.id} user={user} /></Link>)
    const moderatorsList = community.moderators.data.map((user: any) => <Link to={`/user/${user.id}`}><ListItemUser key={user.id} user={user} /></Link>)
    const [membersAvatar, setMembersAvatar] = React.useState<any[]>([])
  const [moderatorsAvatar, setModeratorsAvatar] = React.useState<any[]>([])
  const [lastUpdated, setLastUpdated] = React.useState<any>(undefined)
  React.useEffect(() => {
    if(lastUpdated===community.updatedAt) return
        setLastUpdated(community.updatedAt)
      setMembersAvatar(community
        .members
        .data
        .slice(0, 3)
        .map(
          (m:any) => <Avatar style={{ width: '38px', height: '38px' }} key={m.id} alt={`${m.attributes.first_name} ${m.attributes.last_name}`} src={m.attributes.profile_pic} />
        )
      )
      setModeratorsAvatar(community
        .moderators
        .data
        .slice(0, 4)
        .map(
          (m:any)=> <Avatar key={m.id} alt={`${m.attributes.first_name} ${m.attributes.last_name}`} src={m.attributes.profile_pic} />)
        )
  }, [community, membersAvatar, moderatorsAvatar, lastUpdated, setLastUpdated])
    return (
        <CommunityCardPaper>
            <div className={classes.ccheader}>
                <CFirst style={{ display: "flex", alignItems: "center", gap: "20px", overflow: 'hidden', width: '100%' }}>
                    <Avatar
                        style={{ width: "70px", height: "70px" }}
                        src={community.community_thumbnail_url}
                    />
                    <div style={{ overflow: 'hidden', width: '100%' }}>
                        <CCTitle>{community.name}</CCTitle>
                        <div
                            className={classes.langDiv}
                            style={{
                                display: "flex",
                                // justifyContent: "space-between",
                                gap: '20px'
                            }}
                        >
                            <div
                                style={{
                                    overflow: 'hidden',
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginBottom: '4px'
                                }}
                            >
                                <LanguageIcon style={{ color: "#424242", fontSize: 16 }} />
                                <Typography
                                    style={{
                                        textTransform: "capitalize",
                                        color: "#4D4D4D",
                                        fontSize: 14.58,
                                        marginLeft: "6px",
                                        fontFamily: "helveticaneue",
                                        fontWeight: 300,
                                    }}
                                >
                                    {community.community_type}
                                </Typography>
                            </div>
                            <Typography style={{ color: "#A7A7A7", fontSize: 14.58 }}>
                                {community.post_count || 0} post
                            </Typography>
                        </div>
                    </div>
                </CFirst>
                <div className={classes.ccAction}>
                    <BTN>
                    <AddButton onClick={props.onAddMember} variant="outlined" color="primary">
                        Add Member
                    </AddButton>{" "}
                    <AddButton 
                        disabled={community.community_type === "private" && community.join_request_pending}
                        onClick={props.onJoinUnjoinClick} 
                        variant="contained" 
                        disableElevation 
                        color="primary"
                    >
                        {community.in_community?"Unjoin":"Join"}
                    </AddButton>
                    </BTN>
                    <BTN2>
                        <img src={icEdit} width="45px" onClick={props.onEdit} style={{cursor: 'pointer'}} />{" "}
                        <img src={icShare} width="45px" onClick={props.onShare} style={{cursor: 'pointer'}} />
                    </BTN2>
                </div>
            </div>
            <SeeMore text={community.description} />
            <div style={{ display: 'flex', gap: '20px', flexWrap:'wrap' }}>
                <div style={{ display: "flex", gap: '10px' }}>
                    <Typography style={{ fontSize: 16, marginRight: "-5px", color: '#000', lineHeight: '24.52px', fontWeight: 600 }}>
                        Preference:
                    </Typography>
                    <Typography style={{ color: "#2A353F", fontSize: 16, paddingTop: 1, width: "max-content", marginRight: "15px" }}>
                        {community.preferences.map((p: any) => p?.slice?.(0, 1)).join(', ')}
                    </Typography>
                </div>
                <div style={{ display: "flex", gap: '10px' }}>
                    <Typography style={{ fontSize: 16, marginRight: "-5px", color: '#000', fontWeight: 600 }}>
                        Category:
                    </Typography>
                    <Typography style={{ color: "#2A353F", fontSize: 16, paddingTop: 1 }}>
                        {community.categories[0]}
                        {
                            community.categories.length > 1 && <>
                                <PlusMorePopup heading="Category" listItems={community.categories}>
                                    {` +${community.categories.length - 1}`}
                                </PlusMorePopup>
                            </>
                        }
                    </Typography>
                </div>
                <div style={{ display: "flex", gap: '10px' }}>
                    <Typography style={{ fontSize: 16, marginRight: "-5px", minWidth: '110px', color: '#000', fontWeight: 600 }}>
                        Sub Category:
                    </Typography>
                    <Typography style={{ color: "#2A353F", fontSize: 16, paddingTop: 1 }}>
                        {community.Sub_categories[0]}
                        {
                            community.Sub_categories.length > 1 && <>
                                <PlusMorePopup heading="Sub Category" listItems={community.Sub_categories}>
                                    {` +${community.Sub_categories.length - 1}`}
                                </PlusMorePopup>
                            </>
                        }
                    </Typography>
                </div>
            </div>
            <CCMemberRow >
                <div style={{ display: "flex", alignItems: 'center', flexWrap:'wrap' }}>
                    <Typography style={{ fontSize: 16, marginRight: "10px", color: "#000" }}>
                        Moderators :
                    </Typography>
                    <PlusMorePopup 
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} 
                        heading="Moderators" 
                        listItems={moderatorsList}
                        {...props.moderatorPopoverProps}
                    >
                        <AvatarGroup >
                            {
                                moderatorsAvatar
                            }
                        </AvatarGroup>
                        {
                            community.moderators.meta.total > 4 && <>
                                {` +${community.moderators.meta.total - 4}`}
                            </>
                        }
                    </PlusMorePopup>
                </div>
                <div style={{ display: "flex", alignItems: 'center' }}>
                    <Typography style={{ fontSize: 16, marginRight: "10px", color: "#000",  }}>
                        Total Members:
                    </Typography>
                    <PlusMorePopup  
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} 
                        heading="Members" 
                        listItems={membersList} 
                        {...props.memberPopoverProps}
                    >
                        <AvatarGroup >
                            {
                                membersAvatar
                            }
                        </AvatarGroup>
                        {
                            community.members.meta.total > 3 && <>
                                {` +${community.members.meta.total - 3}`}
                            </>
                        }
                    </PlusMorePopup>

                </div>
                <div style={{ display: "flex", alignItems: 'center' }}>
                    <Typography style={{ fontSize: 16,  marginRight: "10px", color: "#000",  }}>
                        Admin:
                    </Typography>
                    <div style={{ display: "flex" }}>
                        <Avatar alt={adminName} src={community.admin.data.attributes.profile_pic} style={{ width: "38px", height: "38px", marginRight: "5px" }} />
                        <Typography style={{ color: "#2A353F", fontSize: 16, maxWidth: '20ch', overflow: 'hidden', textOverflow: 'ellipsis', paddingTop: 6, }}>
                            {adminName}
                        </Typography>
                    </div>
                </div>
            </CCMemberRow>
        </CommunityCardPaper>
    );
};

// // Add Member Components
const AddMemberPopover = (props: any) => {
    const [selected, setSelected] = React.useState<any[]>([])
    const [search, setSearch] = React.useState("")
    const debouncedSearch = useDebounce(search, 300);
    const handleSelect = (e: any) => {
        const id = parseInt(e.currentTarget.dataset.id)
        console.log({id, selected})
        if(selected.indexOf(id)>-1) {
            setSelected(selected.filter((i:any) => i!==id))
        } else {
            setSelected([...selected, id])
        }
    }
    const handleInputChange = (e: any) => {
        setSearch(e?.target?.value)
    }

    const handleScroll = (e: any)=>{
        const DIV_HEIGHT = 300;
        const THRESHOLD = 30;
        const {scrollTop, scrollHeight} = e.target
        if(scrollTop+DIV_HEIGHT+THRESHOLD>=scrollHeight){
            props.onPaginate && props.onPaginate()
        }
    }

    const handleSubmit = () => {
        if(props.onSubmit){
            setSelected([]);
            props.onSubmit(selected)
        }
    }

    React.useEffect(() => {
        props.onSearch && props.onSearch(debouncedSearch)
    }, [debouncedSearch])
    const list = props.users.map((user: any)=><MenuItem data-id={user.id} onClick={handleSelect} style={{
                    height: "50px",
                    display: "flex",
                    padding: "20px 20px",
                    flexDirection: 'row-reverse',
                    justifyContent: 'space-between',
                }} value={user.id} key={user.id}>
                    <SelectItemUser item={user} value={selected} />
                </MenuItem>
                ) 
    return <PopoverCard
                transitionDuration={200}
                open={!!props.addMemberAnchorEl}
                anchorEl={props.addMemberAnchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                PaperProps={{
                  style: {
                    overflow: 'hidden !important'
                  }
                }}
              >
                <Wrapper>
                  <Header>
                    <Typography style={{ fontWeight: "bold" }} variant="h6">
                        Add Members
                    </Typography>
                    <CloseButton onClick={props.onClose}>
                      <CloseCircleIcon width="20px" />
                    </CloseButton>
                  </Header>
                  <SearchRow>
                    <TextField
                      onChange={handleInputChange}
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon width="16px" />
                          </InputAdornment>
                        ),
                      }}
                      placeholder="Search"
                    />
                    <AMSubmit disabled={selected.length===0} color="primary" onClick={handleSubmit} variant="outlined">
                        Submit
                    </AMSubmit>
                  </SearchRow>
                  <div>
                    {props.users.length === 0 && search.length !==0 && <Typography color="textSecondary" style={{marginTop: '10px'}} align="center"> No Match Found</Typography>}
                  </div>
                  <List style={{ height: "300px", overflow: 'auto' }}>
                    <Scrollbars
                        onScroll={handleScroll}
                        renderTrackHorizontal={
                          (props: any) => <div {...props} style={{display: 'none'}} className="track-horizontal"/>
                        }
                    >
                    {list}
                  </Scrollbars>
                  </List>
                   <Typography 
                    color="textSecondary" 
                    style={{marginTop: '10px'}} 
                    align="center"
                    >
                        {props.paginateLoading && 'Loading...'}
                    </Typography>
                </Wrapper>
              </PopoverCard>
}

const CloseButton = styled("button")({
  margin: "5px",
  background: "none",
  color: "inherit",
  border: "none",
  padding: 0,
  font: "inherit",
  outline: "inherit",
  cursor: "pointer",
});

const PopoverCard = styled(Popover)({
    '& > .MuiPopover-paper':{
        overflowX :'auto',
    }
});

const PlusWrapper = styled("span")({
  cursor: "pointer",
});

const Header = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "20px",
});

const SearchRow = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: 'center',
  gap: '20px'
});

const Wrapper = styled("div")({
  padding: "20px",
  width: "420px",
});

const AMSubmit  = styled(Button)({
    margin: '5px 0'
})

// End of Add Member Components
const AddButton = styled(Button)({
    borderWidth: '2px',
    borderRadius: '25px',
    padding: '8px 18px',
    borderOpacity: 1,
    minWidth: '100px',
    '&:hover': {
        borderWidth: '2px'
    },
    '& > .MuiButton-label': {
        fontWeight: 'bold',
        textTransform: 'none'
    }
})
//
const ListItemUser = ({ user }: { user: any }) => <ListItem >
    <ListItemAvatar  >
        <Avatar src={user.attributes.profile_pic} />
    </ListItemAvatar>
    <ListItemText primary={getName(user)} />
</ListItem>

const CommunityCardPaper = styled(Paper)({
    padding: "35px",
    marginTop: "30px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    boxShadow: "0px 2px 4px rgba(0,0,0,0.105)",
    borderRadius: "12px",
});

const CCTitle = styled(Typography)({
    color: "#29D32",
    fontFamily: "helvetica",
    fontSize: "20px",
    fontWeight: 700,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '@media screen and (min-width:769px) and (max-width:850px)':{
        textAlign:'center',
    },
    '@media screen and (min-width:320px) and (max-width:400px)':{
        textAlign:'center',
    }
});

const CCHeader = styled("div")({
    display: "flex",
    justifyContent: "space-between",
    overflow: 'hidden'
});
const CCMemberRow = styled("div")({
    display: "flex",
    gap: '20px',
    '@media(max-width: 1500px)': {
        flexWrap: 'wrap',
        justifyContent: 'space-between'
    }
});

const BTN = styled('div')({
    '@media screen and (min-width:769px) and (max-width:920px)':{
        display:'flex',
        flexDirection:'column',
        width:'inherit',
        gap:'5px',
    },
    '@media screen and (min-width:320px) and (max-width:400px)':{
        display:'flex',
        flexDirection:'column',
        width:'inherit',
        gap:'5px',
    }
})

const BTN2 = styled('div')({
    '@media screen and (min-width:769px) and (max-width:920px)':{
       width :'100%',
       textAlign:'center'
    },
    '@media screen and (min-width:320px) and (max-width:400px)':{
        width :'100%',
        textAlign:'center'
     }
})

const CFirst = styled('div')({
    '@media screen and (min-width:769px) and (max-width:850px)':{
        display:'flex',
        flexDirection:'column',
        alignItems:'baseline',
        // justifyContent:'center'
    },
    '@media screen and (min-width:320px) and (max-width:400px)':{
        display:'flex',
        flexDirection:'column',
        alignItems:'baseline',
        // justifyContent:'center'
    }
})



// Customizable Area End

import React from "react";

// Customizable Area Start
import {Box,Grid,Typography,Divider,Button,Badge,Link} from '@material-ui/core';
import { makeStyles, Theme, createStyles,withStyles } from '@material-ui/core/styles';
import FolderOutlinedIcon from '@material-ui/icons/FolderOutlined';
import { imgPasswordInVisible } from '../../email-account-login/src/assets';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
// @ts-ignore
import classes from './Chat9.module.css'
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import UserContext from '../../../components/src/UserProvider'


const BLACKLIST = ["/", "/EmailAccountRegistrationWeb"]
// Customizable Area End

import Chat9Controller, {
  Props,
  configJSON
} from "./Chat9Controller";

export default class Chat9 extends Chat9Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    if(BLACKLIST.includes(window.location.pathname))
      return <></>
    if(!this.context.id) return <></>
    // @todo disabled for current release
    return <></>
    // Customizable Area End
    return (
      // Customizable Area Start
      <div className={classes.wrapper}>
        <Card onClick={this.toggleExpanded} className={classes.closedChatCard} elevation={8}>
            <div 
              className={classes.chatHeader} 
              style={{display: 'flex', justifyContent: 'space-between'}}
            >
              <ListItemIcon >
                    <Avatar src={this.context.profile_pic}>
                    </Avatar>
              </ListItemIcon>
              <ListItemText primary="Messaging" style={{ fontWeight: "bolder", color:"black",marginTop:"8px"}} >
                  </ListItemText>
             <div style={{display: 'flex'}}>
                <Typography
                    variant="subtitle2"
                    component="div"
                    style={{ fontWeight: "bold", fontSize:10,backgroundColor:"red",color:"white",borderRadius:"50%",padding:"2px", width:"20px",height:"20px",display:"flex",justifyContent:"center",alignItems:"center",marginTop:"7px",marginRight:"20px"}}
                    >
                        16
                </Typography>
                <IconButton onClick={this.toggleExpanded}>
                 {this.state.expanded?<ExpandMoreIcon />:<ExpandLessIcon />} 
                </IconButton>
             </div>
            </div>
            <div className={classes.collapsibleAnimation} style={{height: this.state.expanded?'80vh':'0px'}}> 
            </div>
       </Card>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
Chat9Controller.contextType = UserContext

// Customizable Area End

// Customizable Area Start
/**
*	Generic form modal with two action buttons, one close button and title 
**/
import React, { useEffect } from 'react'
import { CreateCSSProperties, styled } from '@material-ui/styles'
import {
  Avatar,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  SelectProps,
  TextField as TextFieldBase,
  TextFieldProps,
  Typography
} from '@material-ui/core';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { DatePicker, TimePicker } from '@material-ui/pickers'
import { Formik, Form, useField, useFormikContext } from 'formik'
import SelectInput from '../../../components/src/SelectInput.web'
import FileDropBase from '../../../components/src/FileDrop.web'
import Flex from '../../../components/src/Flex.web'
import CountedTextArea from '../../../components/src/CountedTextArea.web'
const CloseIcon = require('../../../components/src/ic_close.png')
const CalendarIcon = require('../../../components/src/ic_calendar.png')
const ClockIcon = require('../../../components/src/ic_clock.png')

interface FormModalProps {
  headerTitle?: string | JSX.Element;
  onClose: () => void;
  onSubmit?: (() => void) | ((e: React.FormEvent<{}>) => void);
  children: React.ReactNode;
  cancelLabel?: string;
  submitLabel?: string;
  isDirty?: boolean; // if true context.dirty is not checked, this enables button for edit/update form
}

export default function FormModal({ headerTitle, onClose, onSubmit, children, cancelLabel, submitLabel, isDirty }: FormModalProps) {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => { document.body.style.overflow = "auto"; }
  }, [])
  return <Wrapper>
    <Modal>
      <Scrollbars
        autoHide
        renderTrackHorizontal={
          (props: any) => <div {...props} style={{ display: 'none' }} className="track-horizontal" />
        }>
        <ModalBody translate="yes">
          <div>
            <Typography variant="h6" style={{ paddingLeft: '30px', paddingRight: '30px'}}>
              {headerTitle}
            </Typography>
            <CancelButton onClick={onClose}>
              <img src={CloseIcon} width="35px" />
            </CancelButton>
          </div>
          <Divider />
          <Flex col gap="25px" style={{ paddingLeft: '30px', paddingRight: '30px'}}>
            {children}
          </Flex>
         
          <Divider />
          <ModalActions>
            <CardButton onClick={onClose}>{cancelLabel || 'Cancel'}</CardButton>
            <FormikSubmit isDirty={isDirty} component={FormButton} props={{}}>
              {submitLabel || 'Submit'}
            </FormikSubmit>
          </ModalActions>
          </ModalBody> 
      </Scrollbars>
    </Modal>
  </Wrapper>
}


interface InputRow {
  label: string | JSX.Element;
  input: JSX.Element;
  notWrapped?: boolean;
  rowClass?: string;
  labelClass?: string;
}

export function InputRow({ label, input, notWrapped, rowClass, labelClass }: InputRow) {
  if (!notWrapped)
    return <InputRowWrapper className={rowClass} >
      <FInputLabel className={labelClass} > <GreyLabel>{label}</GreyLabel> </FInputLabel>
      <div>{input}</div>
    </InputRowWrapper>

  return <InputRowWrapper>
    {label}
    {input}
  </InputRowWrapper>
}

export const FInputLabel = styled(Typography)({
  fontSize: '1.147rem',
  lineHeight: '1.345rem',
  fontWeight: 500,
  marginBottom: '5px',
  // fontFamily: 'helveticaneue',
}) as typeof Typography


export function MSelect(props: SelectProps) {
  const [field, meta, helpers] = useField(props.name || '')
  return <>
    <SelectInput fullWidth error={meta.touched && Boolean(meta.error)} {...field} {...props}>
      {props.children}
    </SelectInput>
    <Typography variant="caption" color="error"> {meta.touched && Boolean(meta.error) ? String(meta.error) : null} </Typography>
  </>
}

export function MInput(props: TextFieldProps) {
  const [field, meta, helpers] = useField(props.name || '')
  return <>
    <MInputBase variant="outlined" error={meta.touched && Boolean(meta.error)} {...field} fullWidth {...props} />
    <Typography variant="caption" color="error"> {meta.touched && Boolean(meta.error) ? String(meta.error) : null} </Typography>
  </>
}

export function MFileDrop(props: any) {
  const [field, meta, helpers] = useField(props.name || '')
  const onChange = (e: any) => {
    helpers.setTouched(true, true)
    if (props.multiple) {
      const newFiles = (field.value || []).concat(Array.from(e.target.files))
      helpers.setValue(newFiles)
    } else {
      helpers.setValue(e.target.files[0])
    }
  }

  // only called for multi-input
  const onRemove = (e: React.MouseEvent<HTMLButtonElement>) => {
    const filename = e.currentTarget.dataset.filename
    const newFiles = field.value.filter((file: File) => file.name !== filename)
    helpers.setValue(newFiles)
  }

  const onSingleRemove = (e: any) => {
    e.stopPropagation()
    helpers.setValue('')
  }

  const fileUrl = field.value
  const inputProps = props.inputProps || {}
  return <>
    <FileDropBase onRemove={onSingleRemove} showDelete={!!fileUrl} fileUrl={fileUrl} inputProps={{ onChange, ...inputProps, multiple: props.multiple }} />
    {/*<input type="file" onChange={onChange}  />*/}
    {props.multiple && field.value && <PreviewFiles files={field.value || []} onRemove={onRemove} />}
    <Typography variant="caption" color="error"> {meta.touched && Boolean(meta.error) ? String(meta.error) : null} </Typography>
  </>
}

const PreviewFiles = ({ files, onRemove }: { files: File[], onRemove: (e: React.MouseEvent<HTMLButtonElement>) => void }) => {
  return <Flex wrap gap="5px">
    {
      files?.map && files.map(
        (file: File) => <FilePreview key={file.name} file={file} onRemove={onRemove} />
      )
    }
  </Flex>
}

const FilePreview = ({ file, onRemove }: { file: File, onRemove: (e: React.MouseEvent<HTMLButtonElement>) => void }) => {
  const CloseButton = styled(Button)({
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 1010
  })

  const clipEllipses = (name: string) => (name.slice(0, 30) + ((name.length >= 30) ? "..." : ""))

  return <div style={{ position: 'relative', width: 'max-content', marginTop: '10px' }}>
    <CloseButton data-filename={file.name} onClick={onRemove}><img src={CloseIcon} width="26px" /></CloseButton>
    {(file.type || "").startsWith('image') && <PreviewImage alt={file.name} src={URL.createObjectURL(file)} />}
  </div>
}

const PreviewTileStyle: CreateCSSProperties<{}> = {
  width: '100px',
  height: '150px',
  borderRadius: '10px',
  objectFit: 'cover',
  padding: '0px 5px',

}

const PreviewImage = styled('img')({
  ...PreviewTileStyle
})

export function MTimePicker(props: any) {
  const [field, meta, helpers] = useField(props.name || '')
  const value = field.value
  const onChange = (e: any) => helpers.setValue(e)

  let inputProps: any = {
    endAdornment: <InputAdornment position="end"><img src={ClockIcon} width="20px" /></InputAdornment>
  }
  if (props.InputProps)
    inputProps = { ...props.InputProps, ...inputProps }

  return <>
    <TimePickerBase
      inputVariant="outlined"
      fullWidth
      {...props}
      error={meta.touched && Boolean(meta.error)}
      InputProps={inputProps}
      value={value}
      onChange={onChange} />
    <Typography variant="caption" color="error"> {meta.touched && Boolean(meta.error) ? String(meta.error) : null} </Typography>
  </>
}

export function MCountedTextArea(props: any) {
  const [field, meta, helpers] = useField(props.name || '')
  const onChange = (e: any) => helpers.setValue(e.target.value)
  return <>
    <CountedTextArea maxLength={1000} {...field} className={props.classN} error={meta.touched && Boolean(meta.error)} {...props} onChange={onChange} />
    <Typography variant="caption" color="error"> {meta.touched && Boolean(meta.error) ? String(meta.error) : null} </Typography>
  </>
}
export function MTextArea(props: any) {
  const [field, meta, helpers] = useField(props.name || '')
  const onChange = (e: any) => helpers.setValue(e.target.value)
  return <>
    <CountedTextArea {...field} error={meta.touched && Boolean(meta.error)} {...props} onChange={onChange} />
    <Typography variant="caption" color="error"> {meta.touched && Boolean(meta.error) ? String(meta.error) : null} </Typography>
  </>
}

interface FormikSubmitProps {
  children: any;
  component: any;
  props: any;
  isDirty?: boolean; // if true context.dirty is not checked, this enables button for edit/update form
}

export function FormikSubmit(props: FormikSubmitProps) {
  const SubmitButton = props.component
  const context = useFormikContext()
  if (!SubmitButton) return <></>

  return <SubmitButton {...(props.props || {})} disabled={!(context.isValid && (context.dirty || props.isDirty))} type="submit">
    {props.children}
  </SubmitButton>
}

export function MDatePicker(props: any) {
  const [field, meta, helpers] = useField(props.name || '')
  let value = field.value // default value is null

  const onChange = (e: any) => {
    props.onChange && props.onChange(e)
    helpers.setValue(e)
  }

  let inputProps: any = {
    endAdornment: <InputAdornment position="end"><img src={CalendarIcon} width="20px" /></InputAdornment>
  }
  if (props.InputProps)
    inputProps = { ...props.InputProps, ...inputProps }
  return <>
    <DatePickerBase error={meta.touched && Boolean(meta.error)} inputVariant="outlined" fullWidth format="dd/MM/yyyy" value={value} {...props} InputProps={inputProps} onChange={onChange} />
    <Typography variant="caption" color="error"> {meta.touched && Boolean(meta.error) ? String(meta.error) : null} </Typography>
  </>
}

export function MCheckboxes(props: any) {
  const [field, meta, helpers] = useField(props.name || '')
  const handleChange = (e: any) => {
    const { checked, value } = e.target
    if (checked) helpers.setValue([...field.value, value])
    else helpers.setValue(field.value.filter((x: string) => x === value))
  }
  return <>
    <div>
      {
        props.options?.map(
          (option: any) => <span key={option[0]}>
            <Checkbox color="primary" value={option[1]} name={props.name} onChange={handleChange} /> {option[0]}
          </span>)
      }
    </div>
    <Typography variant="caption" color="error"> {meta.touched && Boolean(meta.error) ? String(meta.error) : null} </Typography>
  </>
}

const TimePickerBase = styled(TimePicker)({
  '& > div > input': {
    padding: '13px 20px'
  }
})

const MInputBase = styled(TextFieldBase)({
  '& > div > input': {
    padding: '13px 10px'
  }
})

const DatePickerBase = styled(DatePicker)({
  '& > div > input': {
    padding: '13px 20px'
  }
})

const FlexCol = styled('div')({
  display: 'flex',
  flexDirection: 'column',
})

const InputRowWrapper = styled(FlexCol)({
  gap: '5px',
})


export const Wrapper = styled('div')({
  top: 0,
  left: 0,
  position: 'fixed',
  minWidth: '100vw',
  minHeight: '100vh',
  height: '100%',
  background: 'rgba(0,0,0,0.5)',
  display: 'flex',
  justifyContent: 'center',
  // alignItems: 'center',
  zIndex: 10,
}) as any;

export const Modal = styled(Paper)({
  borderRadius: '23px',
  margin: '20px 0',
  width: '100%',
  marginRight: '40px',
  maxWidth: '700px',
  minHeight: '150px',
  padding: '20px 0px 20px 0px',
  position: 'relative',
  overflowY: 'auto',
  zIndex: 15,
})

export const ModalBody = styled(Form)({
  display: 'flex',
  gap: '15px',
  flexDirection: 'column',
})

const ModalActions = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '20px',
  gap: '10px'
}) as any;

const CancelButton = styled(Button)({
  position: 'absolute',
  top: '-5px',
  right: '20px'
})

const BaseButton = styled(Button)({
  color: '#FFFFFF !important',
  padding: '5px 25px',

})

const GreenButton = styled(BaseButton)({
  background: '#009b21',
  '&:hover': {
    opacity: 0.9,
    background: '#009b21',
  },
})

const CardButton = styled(BaseButton)({
  background: '#d0d0d0',
  '&:hover': {
    opacity: 0.9,
    background: '#009c05',
  },
})

const GreyLabel = styled('div')({
  fontSize: '15.35px',
  color: '#404040',
  fontFamily: 'HelveticaNeue'
})

const FormButton = (props: any) => {
  const Btn = props.disabled ? CardButton : GreenButton
  return <Btn {...(props || {})}>{props.children}</Btn>
} 

// Customizable Area End
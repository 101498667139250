import { BlockComponent } from "../../../framework/src/BlockComponent";
export const configJSON = require("./config");
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";

import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { sendAPIRequest } from '../../../components/src/utils';
import { toast } from 'react-toastify';
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  requestors: any[];
  loadingSurveyTemplates: boolean;
  token: string;
  txtSavedValue: string;
  profileCompletionCheck: boolean;
  selfAssessmentCheck: boolean;
  loading: boolean;
  userName:string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}





export default class ESGJourneyController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  requestorsCallId: string = '';
  checkStatusCallId: string = '';
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      txtSavedValue: "A",
      requestors: [],
      profileCompletionCheck: false,
      selfAssessmentCheck: false,

      loadingSurveyTemplates: false,
      token:'',
      loading: false,
      userName: '',
      // Customizable Area End
    }
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    const authToken = await getStorageData("authToken");
    const userString = await getStorageData("user");
    const user = userString ? JSON.parse(userString) : {};
    const userName = user && user.role && user.role.name ? user.role.name : '';
    this.setState({userName: userName});
    if (authToken == null) {
      this.props.navigation.navigate('Home')
    }
    this.esgJourneyCards()
    this.checkStatus()
  }

  onBack = () => {
    this.props.navigation.goBack();
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    console.log("rece-self");

    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.errors) {
        this.parseApiErrorResponse(responseJson.errors);
        this.parseApiCatchErrorResponse(responseJson.errors);
      }else if (apiRequestCallId === this.requestorsCallId) {
        console.log("self-a", responseJson.access?.data);

        if (responseJson) {
          const req = responseJson?.access?.data
          this.setState({ requestors: req })
        }
      }else if(apiRequestCallId === this.checkStatusCallId){
        this.setState({profileCompletionCheck: responseJson.user_has_complete_profile, selfAssessmentCheck:responseJson.has_user_completed_self_assesment, loading: false})
      }
    }
    // Customizable Area End

  }


  // Customizable Area Start
  checkStatus = async () => {
    this.setState({loading: true})
    this.checkStatusCallId = sendAPIRequest(`${configJSON.getStatusEndpoint}`, {
      method: 'GET',
      headers: {
        token: await getStorageData('authToken'),
        'content-type': 'appliction/json'
      },
    })
  }

  esgJourneyCards = async () => {
    const authToken = await getStorageData('authToken')
    this.requestorsCallId = sendAPIRequest(`${configJSON.showAllCards}`, {
      method: "GET",
      headers: {token: authToken},
    })
  }

  goToPage = async(data:any) => {
    const states = {alloted_by_id: data.alloted_by_id, ability_type: data.ability_type}
    console.log("data of card", data, data.ability_card,)
    await setStorageData("ability_type",data.ability_type)
    if(data.ability_card == "Corporate Profile Form"){
      this.props.navigation.history.push("CorporateProfileForm", 
      states)
    }
    if(data.ability_card == "Stakeholder Corporate Profile"){
      this.props.navigation.history.push("StakeHolderProfile", 
      states)
    }
    if(data.ability_card == "Self Assessments")this.props.navigation.history.push("ESGJourneyReportingFrameworks", states)
    if(data.ability_card == "Self Assessment Dashboard"){
      this.props.navigation.history.push("AssesmentDashboard", 
      states)
    }

    if(data.ability_card == "Risk and Opportunity Management"){
      this.handleRiskAndOpportunityManagement(data)
      
    }
    if(data.ability_card == "Initiative Evaluation"){
      if(this.state.profileCompletionCheck === false){
        toast.warn("Please complete your profile")
      }else if(this.state.selfAssessmentCheck === false){
        toast.warn("Please complete self assessment")
      }else{
        this.props.navigation.history.push("InitiativeEvaluation", 
      states)
      }
      
    }
    if(data.ability_card == "Supplier Due Diligence Form"){
      this.props.navigation.history.push("DueDiligence", 
      states)
    }
    if(data.ability_card == "Download Carbon Calculator- GHG Tool"){
      this.props.navigation.history.push("CarbonCalculatorCard", 
      states)
    }
    if(data.ability_card == "Carbon Emission Calculator - GHG Tool"){
      this.props.navigation.history.push("CarbonCalculatorCard", 
      states)
    }
    if(data.ability_card == "Reports"){
      this.props.navigation.history.push("Reports", 
      states)
    }
    if(data.ability_card == "Reference Policies"){
      this.props.navigation.history.push("ReferencePolicy", 
      states)
    }
  }

  handleRiskAndOpportunityManagement = (data:any)=>{
    const states = {alloted_by_id: data.alloted_by_id, ability_type: data.ability_type}

    if(this.state.profileCompletionCheck === false){
        toast.warn("Please complete your profile")
      }else if(this.state.selfAssessmentCheck === false){
        toast.warn("Please complete self assessment")
      }else{
        this.props.navigation.history.push("RiskManagement", 
      states)
      }
  }


  // Customizable Area End
}

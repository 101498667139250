import React from "react";
// Customizable Area Start
import TopNavBar from "../../../components/src/TopNavbar";
import {
  Box, Grid,
  Avatar,
  Typography,
  Card,Tooltip
} from '@material-ui/core';
import UserContext from "../../../components/src/UserProvider";
import { styled } from '@material-ui/styles'
import LeftMenuWeb from "../../../components/src/LeftMenuWeb";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Flex from '../../../components/src/Flex.web'
import OverlayLoader from '../../../components/src/Loader.web'
import Pagination from '@material-ui/lab/Pagination';

import InitiativeEvaluationController, {
  Props,
} from "./InitiativeEvaluationController";
// @ts-ignore
// Customizable Area End


export default class InitiativeEvaluation extends InitiativeEvaluationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
 
  // Customizable Area End

  render() {
    // Customizable Area Start
    console.log("all activity = ", this.state?.allProjects)
    const projects:any = this.state.allProjects;
	const path = this?.context?.user_type == "licensed_user" ? 'Corporatepermission' : 'ESGJourney'
    // Customizable Area End
    return (
      // Customizable Area Start
      <MainBox>
        <Box>
          <TopNavBar navigation={this.props.navigation} id={""} />
        </Box>

        <OuterGrid container spacing={3}>
          <LeftGrid item xs={12} sm={12} md={3} lg={3}>
            <Box >
              <LeftMenuWeb navigation={this.props.navigation} id={""} />
            </Box>
          </LeftGrid>
          <MiddleGrid item xs={12} sm={12} md={6} lg={6} >
		  <OverlayLoader loading={this.state.loading} />
            <SurveyCardWrapper>
              <HeadWrapper>
                <Head>
                 <Typography style={{color:'gray', fontWeight:'bold', cursor:'pointer'}} onClick={() => this.props.navigation.navigate(path)}> ESG Journey </Typography>
                <ArrowBackIosIcon style={{fontWeight:100,color:'gray', marginLeft: '10px'}} onClick={() => this.props.navigation.navigate(path)}/>
                Initiative Evaluation
                </Head>
              </HeadWrapper>
              <SectionItem >
                {projects?.map((evl:any)=> {
                    return (
			    <Card style={{ borderRadius: "10px", border: "1px solid #f2f2f2", width: '30%' }}>
				<SpacingSection>
					<Box style={{ width: '100%', display: "flex", flexDirection: "column", padding: '5px 0px' }}>
						<Flex justify="space-between" align="center" >
							<Flex style={{ flexDirection: 'row', width: `calc(100% - 100px)` }} >
								<Avatar alt="Remy Sharp" src={''} variant="rounded" />	<TemplateName onClick={()=> this.showProjectEval(evl.attributes.project_objective_id, false)}>{evl.attributes.project_name}</TemplateName>
							</Flex>
							<div style={{ display: 'flex', gap: '10px', alignItems: 'center', cursor: 'pointer' }}>
								<EditOutlinedIcon color="disabled" onClick={() => this.handleProjectEval(evl.attributes.project_objective_id, false)}/>
							</div>
						</Flex>
					</Box>
					<DetailSection style={{ fontWeight: 'normal', paddingRight: '10px' }}>
						<SectionText>
							<Typography variant="subtitle1" component="div">Project ID</Typography>
							<Typography variant="subtitle2" component="div">{evl.attributes.project_id}</Typography>
						</SectionText>
						<SectionText style={{ textAlign: 'left' }}>
							<Typography variant="subtitle1" component="div">Location</Typography>
							<Box marginLeft="23px" ><Typography variant="subtitle2" component="div">{evl.attributes?.location.length > 15  ? 
							<Tooltip title={<div style={{
								height: 'auto',
								fontSize: '13px',
								width: 'auto',
								color: 'white',
								overflow: 'hidden',
								margin: "0px"
							  }}>
								{evl.attributes?.location}
							  </div>}>
							  <span>{evl.attributes?.location.slice(0, 15)}...</span>
							  </Tooltip> 
							: 
							evl.attributes?.location}</Typography></Box>
						</SectionText>
					</DetailSection>

					<SectionText style={{ height: "90px", width: '100%' }}>
						<Typography variant="subtitle1" className="preText" component="div">Description</Typography>
						<Typography style={{wordWrap: "break-word"}} className="CustomeText" variant="subtitle2" component="div">
								
							 {evl.attributes.description?.length > 125 ? <Tooltip title={<div style={{
            				height: 'auto',
            				fontSize: '13px',
            				width: 'auto',
            				color: 'white',
            				overflow: 'hidden',
            				margin: "0px"
        					}}> {evl.attributes.description}</div>}>
							<span>{evl.attributes.description.slice(0, 125)} &nbsp; see more </span>
								</Tooltip> 
								: 
								evl.attributes.description}
						</Typography>
					</SectionText>

					<DetailSection style={{ fontWeight: 'normal', paddingRight: '10px' }}>

						<SectionText>
							<Typography variant="subtitle1" component="div">Preferences</Typography>
							<Typography variant="subtitle2" component="div">{}</Typography>
						</SectionText>
						<SectionText>
							<Typography variant="subtitle1" component="div">Creation Date</Typography>
							<Typography variant="subtitle2" component="div">{evl?.attributes?.created_at}</Typography>
						</SectionText>
					</DetailSection>
					<DetailSection style={{ fontWeight: 'normal', paddingRight: '10px' }}>
						<SectionText style={{ maxWidth: "50%" }}>
							<Typography variant="subtitle1" className="preText" component="div">Project Leader</Typography>
							<UserAvtarDetail>
								<Avatar style={{ marginRight: "5px", height: "25px", width: "25px", border: "3px rgb(242, 212, 212) solid" }} src={evl.attributes.project_leader.data.attributes.profile_pic} />
								<Typography variant="subtitle2" component="div">{evl.attributes.project_leader.data.attributes.first_name +" "+evl.attributes.project_leader.data.attributes.last_name}</Typography>
							</UserAvtarDetail>
						</SectionText>
						<SectionText>
							<Typography variant="subtitle1" component="div">Project Status</Typography>
                            <Typography variant="subtitle2" component="div">{evl.attributes.status}</Typography>
						</SectionText>
					</DetailSection>

				</SpacingSection>
			    </Card>
                    )
                })}
		</SectionItem>
		<Pagin>
            <Pagination color="primary" variant="outlined" count={this.state.total_pages} page={this.state.pages} onChange={this.handlePageChange} />
        </Pagin>
            </SurveyCardWrapper>
          </MiddleGrid>
        </OuterGrid>

      </MainBox>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
InitiativeEvaluationController.contextType = UserContext

const MainBox = styled(Box)({
  backgroundColor: "#edebeb",
  display: "flex",
  flexWrap: 'wrap',
  flexDirection: "column",
  paddingBottom: "20px",
  overflowX: "hidden",
})

const OuterGrid = styled(Grid)({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
})

const LeftGrid = styled(Grid)({
  maxWidth: '300px',
  '@media screen and (max-width: 985px)': {
    maxWidth: '200px',
  },
  '@media screen and (max-width: 768px)': {
    display: 'none',
    visibility: 'hidden',
  },
})

const MiddleGrid = styled(Grid)({
  width: 'calc(100vw - 300px)',
  maxWidth: 'unset',
  flexBasis: 'unset',
  justifyContent: 'center',
  '@media screen and (max-width: 768px)': {
    width: '100%'
  }
})

const SurveyCardWrapper = styled(Box)({
  width: '95%',
  height: 'auto',
  marginTop: '20px',
  marginRight: '20px',
  padding: '20px',
})

const Pagin = styled('div')({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	padding: '10px',
	'@media screen and (max-width:350px)': {
	  display: 'initial',
	  padding: '10px 1px',
	},
	'@media screen and (min-width:768px) and (max-width:850px)': {
	  display: 'initial',
	  padding: '10px 1px',
	}
  })

const HeadWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%'
})

const Head = styled('div')({
  display: 'flex',
  alignItems: 'center',
  color: '#000',
  margin: '10px 0px 30px 12px',
  fontSize: '1rem',
  fontFamily: 'HelveticaNeue',
  fontWeight: 600,
  '@media screen and (max-width: 768px)': {
    margin: '20px 0px 10px 12px',
  },
})


const SectionItem = styled(Box)({
	margin: "8px 0px",
	'& .MuiTypography-root.MuiTypography-subtitle1': {
		fontWeight: "bold",
		fontSize: 12,
	},
	'& .MuiTypography-root.MuiTypography-subtitle2': {
		fontWeight: "normal",
		color: "grey",
		fontSize: 12,
	},
    display: 'flex',
    flexDirection: 'row',
    gap: '25px',
	flexWrap: 'wrap',
})

const SpacingSection = styled('div')({
	padding: "10px"
})

const TemplateName = styled(Typography)({
	fontWeight: "bold",
	color: "#292D32",
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	textAlign: 'left',
	alignSelf: 'center',
	paddingLeft: '5px',
	whiteSpace: 'nowrap',
	'@media screen and (max-width:380px)': {
		fontSize: '14px',
	},
})

const DetailSection = styled('div')({
	display: "flex",
})

const SectionText = styled(Box)({
	margin: "4px 0px",
	color: '#464646',
	width: '50%',

	'& .MuiTypography-root.MuiTypography-subtitle1': {
		fontWeight: "bold",
		fontSize: 12,

	},
	'& .MuiTypography-root.MuiTypography-subtitle2': {
		fontWeight: "normal",
		color: "grey",
		fontSize: 12,
	},
	'@media (max-width:1370px) and (min-width:1280px)': {
		marginTop: '20px'
	},
	'@media (max-width:1100px) and (min-width:960px)': {
		marginTop: '30px'
	}
})

const BoldText = styled('span')({
	fontWeight: "bold",
	color: "#685b5b"
})

const UserAvtarDetail = styled('div')({
	display: "flex",
	flexDirection: "row",
	alignItems: "center"
})

// Customizable Area End

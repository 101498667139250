import React from 'react'
import { TextField, TextFieldProps, StandardTextFieldProps } from '@material-ui/core'
import { styled } from '@material-ui/styles'

interface CountedTextAreaProps extends StandardTextFieldProps {
	/** Max length of input string allowed */
	maxLength?: number;
	value?: string;
}

export default function CountedTextArea({ value, onChange, maxLength, ...props }: CountedTextAreaProps ) {
	const length = value?.length || 0
	console.log(value, 'textarea value')
	function handleChange(e: React.ChangeEvent<HTMLInputElement>){
		onChange && onChange(e)
	}
	return <Wrapper><Count>{length} {maxLength && '/'} {maxLength}</Count>
		<TextFieldStyled variant="outlined" multiline maxRows={4} minRows={4} fullWidth onChange={handleChange} {...props} value={value}/>
	</Wrapper>
}

const Wrapper = styled('div')({
	position: 'relative',
})

const Count = styled('div')({
	position: 'absolute',
	bottom: '5px',
	right: '15px',
	color: '#777',
})

const TextFieldStyled = styled(TextField)({
	'& > div > textarea': {
		// paddingTop: '20px',
	},
})
Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Maps";
exports.labelBodyText = "Maps Body";
exports.placesAPIKey = "AIzaSyC7yNLqVX1-LMCEhUVgPqKp3YsY3k2L0f4"

exports.btnExampleTitle = "CLICK ME";

exports.getPlaceNameEndpoint = "bx_block_joblisting2/jobs/get_place_name";
exports.bdcApiEndpoint = "https://api.bigdatacloud.net/data/reverse-geocode-client";
// Customizable Area End
// Customizable Area Start
import {runEngine} from '../../../framework/src/RunEngine'
import MessageEnum, {
  getName
} from '../../../framework/src/Messages/MessageEnum'
import { Message } from "../../../framework/src/Message";

export function sendNewJobCreatedMessage(job: any){
	let msg = new Message(getName(MessageEnum.NewJobCreatedMessage));
  msg.addData(
    getName(MessageEnum.NewJobData),
    job
  );
  runEngine.sendMessage(msg.id, msg);
  return msg.id
}

export function sendJobApplyMessage(job: any) {
  let msg = new Message(getName(MessageEnum.JobApplyMessage))
  msg.addData(
    getName(MessageEnum.JobApplyData),
    job
  )
  runEngine.sendMessage(msg.id, msg);
  return msg.id

}

export function sendJobUpdatedMessage(job: any){
	let msg = new Message(getName(MessageEnum.JobUpdatedMessage));
  msg.addData(
    getName(MessageEnum.JobUpdatedData),
    job
  );
  runEngine.sendMessage(msg.id, msg);
  return msg.id
}

export function parseJobCreatedMessage(msg: Message, callback: (job: any)=>void){
	if(msg.id !== getName(MessageEnum.NewJobCreatedMessage)) return 

	const job = msg.getData(getName(MessageEnum.NewJobData))
	callback(job)
}

export function parseJobUpdatedMessage(msg: Message, callback: (job: any)=>void){
	if(msg.id !== getName(MessageEnum.JobUpdatedMessage)) return 
	const job = msg.getData(getName(MessageEnum.JobUpdatedData))
	callback(job)	
}

export function parseJobApplyMessage(msg: Message, callback: (job: any)=>void){
  if(msg.id !== getName(MessageEnum.JobApplyMessage)) return
  const job = msg.getData(getName(MessageEnum.JobApplyData))
  callback(job) 
}
// Customizable Area End
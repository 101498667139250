import React from "react";

// Customizable Area Start
import {
  Grid,
  Divider,
  Typography,
  Modal,
  Backdrop,
  Box, 
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Avatar,
  Button, OutlinedInput, InputAdornment
} from '@material-ui/core'
import { styled } from '@material-ui/styles'

import { Link } from 'react-router-dom';
import LikeAPost from '../../LikeAPost/src/LikeAPost.web'
import PlusMorePopup from '../../../components/src/PlusMorePopup.web'
import MediaCarousel from '../../../components/src/MediaCarousel.web'
import UserContext from '../../../components/src/UserProvider'
import Attachments from '../../../components/src/Attachments.web'
import Picker from 'emoji-picker-react';

import {
  OyoAvatar,
  ThumbGreenIcon,
  CloseIcon,
  ShareIcon,
  StarIcon,
  StarActiveIcon,
  SendIcon,
  EmogiIcon,
  Smiley
} from './assets'

// Customizable Area End

import PostController, {
  Props,
  configJSON
} from "./PostController";


export default class PostBlock extends PostController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  
  // Customizable Area End

  render() {
    const { navigation } = this.props
    let { postData, postCommentData } = this.state
    return (
      // Customizable Area Start
      
      <Wrapper>
      <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
      }}
      open={this.state.openModal}
      //onClose={}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
          timeout: 500,
      }}
  >
    
      <Box
          style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              background: '#fff',
              borderRadius: 8,
              width: '700px',
              position: "relative",
              margin:'10px',
          }} className="schedule_modal">
            <CloseButton onClick={this.onCloseModal}>
              <img src={CloseIcon} width="26px" />
            </CloseButton>
          <Grid container spacing={0}>
              <ModelBody item xs={12}>
              <RoundedCard style={{ padding: '0', margin: '0', maxHeight: 'calc(100vh - 50px)', overflow: 'scroll' }} key={postData?.id} variant="outlined">
                  <CardHeader
                    style={{ padding: '25px 20px 15px 20px' }}
                    avatar={<Link to={`/user/${postData?.created_by?.id}`}><Avatar style={{ width: 55.26, height: 51.87 }} src={postData?.created_by?.profile_pic || OyoAvatar} onClick={() => navigation.navigate(`/user/${postData?.created_by?.id}`)} /></Link>}
                    title={<span style={{ fontWeight: 600, marginBottom: '4px', fontSize: "1.021rem", maxWidth: '30ch', overflow: 'hidden', textOverflow: 'ellipsis', display: 'block', textTransform: 'capitalize' }}><Link to={`/user/${postData?.created_by?.id}`}>{postData?.created_by?.name}</Link></span>}
                    subheader={<Grid style={{ gap: '10px' }} container alignItems="center">
                      <span style={{ textTransform: 'capitalize', fontSize: '0.75rem' }}>{postData.created_by?.location}</span>
                      <Divider orientation="vertical" flexItem />
                      <span style={{ fontSize: '0.75rem' }}>{postData.created_at?.replaceAll('about', '')}</span>
                    </Grid>} 
                  />

                  <Divider />
                  <CardContent style={{ padding: '16px 30px' }}>
                    <FlexSpaceBetween style={{ gap: '10px', padding: '0', alignItems: 'start', flexWrap:'wrap' }}>
                      <div>
                        <span style={{ fontWeight: 700, fontSize: "1.021rem", marginRight: '5px' }}>
                          Community:
                        </span>
                        <MR5 style={{ wordBreak: 'break-word'}}>{postData.community ? postData.community[0] : ''}</MR5>
                        {postData?.community?.length > 1 && <PlusMorePopup heading="Community" listItems={postData?.community}>
                          +{postData?.community?.length - 1}
                        </PlusMorePopup>}
                      </div>
                      <div>
                        <span style={{ fontSize: "1.021rem", marginRight: '5px', color: '#000', fontWeight: 700 }}>
                          Category:
                        </span>
                        <MR5>{postData.category ? postData.category[0] : ''}</MR5>
                        {postData?.category?.length > 1 && <PlusMorePopup heading="Category" listItems={postData?.category}>
                          +{postData?.category?.length - 1}
                        </PlusMorePopup>}
                      </div>
                      <div>
                        <span style={{ fontSize: "1.021rem", marginRight: '5px', color: '#000', fontWeight: 700 }}>
                          Sub Category:
                        </span>
                        <MR5>{postData.Sub_category ? postData.Sub_category[0] : ''}</MR5>
                        {postData?.Sub_category?.length > 1 && <PlusMorePopup heading="Sub Category" listItems={postData?.Sub_category}>
                          +{postData?.Sub_category?.length - 1}
                        </PlusMorePopup>}
                      </div>
                    </FlexSpaceBetween>
                  </CardContent>
                  <Divider />
                  <CardContent style={{ padding: '16px 30px' }}>
                    <PostMainContent>
                      <Typography style={{ marginBottom: '8px' }}>
                        <b style={{ wordBreak: 'break-all', fontSize: '1.125rem', lineHeight: '25px', color: '#2A353F', textTransform: 'capitalize' }}>{postData.name || ""}</b>
                      </Typography>
                      {/* <SeeMore text={p.body || ""} /> */}
                      <Typography color="textSecondary" style={{ color: 'rgba(42, 53, 63, 0.32)', fontSize: '1.125rem' }}>
                        <Typography color="textSecondary">
                        {postData.body || postData.description || ""}
                        </Typography>
                      </Typography>
                      <MediaCarousel items={(postData.images_and_videos || []).filter((file: any) => file.type !== "application")} />
                      <Attachments files={(postData.images_and_videos || []).filter((file: any) => file.type === "application")} />
                    </PostMainContent>
                    <FlexSpaceBetween style={{ padding: 0 }}>
                      <div style={{ lineHeight: '30px', display: 'flex', gap: '15px', fontSize: '0.875rem', color: '#212529', marginTop: '15px' }}>
                        <img src={ThumbGreenIcon} width="30" /> <span>{postData?.total_likes}</span>
                      </div>
                      <div>
                      </div>

                    </FlexSpaceBetween>
                  </CardContent>
                  <Divider />
                  <CardActions>
                    <FlexSpaceBetween style={{ padding: '0px 0px 5px 0px' }}>
                      <CardButtonWrapper>
                        <LikeAPost
                          navigation={this.props.navigation}
                          id=""
                          likeable_type="BxBlockPosts::Post"
                          isLiked={postData?.is_liked}
                          post_id={postData?.id}
                          like_id={postData?.like_id ? postData?.like_id[0]?.id : ''}
                          onLikeChange={this.onLikeChange}
                          onLikeIdUpdate={this.onLikeIdUpdate}
                        />
                        <CardButton startIcon={<BtnImg src={ShareIcon} />} onClick={() => this.handleSharePost(postData)}>Share</CardButton>

                      </CardButtonWrapper>
                      <div>
                        <CardButton data-id={postData.id} onClick={this.handleFavoriteClick || ''} variant="outlined" startIcon={<BtnImg src={postData.is_favourite ? StarActiveIcon : StarIcon} />} />
                      </div>
                    </FlexSpaceBetween>
                  </CardActions>
                  
                  <DiscussSection>
                      <DiscussHeader>Discussion</DiscussHeader>
                      <div style={{ marginBottom: '40px'}}>
                      <CommentUserAvtar>
                          <Link to={`/user/${this.context?.id}`}><Avatar style={{ width: 35.26, height: 31.87 }} src={this.context?.profile_pic || OyoAvatar}/></Link>
                        </CommentUserAvtar>
                            <div style={{  width: 'calc(100% - 50px)', display: 'inline-block', boxSizing: 'border-box' }}>
                            
                              <OutlinedInput
                                id="outlined-adornment-weight"
                                value={ this.state.userComment }
                                onChange={this.handlePostCommentChange}
                                placeholder="Comment your views"
                                endAdornment={<InputAdornment position="end"><BtnImg src={Smiley} onClick={this.openEmojiComment } style={{width: '18px', paddingLeft: '10px', paddingRight: '10px'}}/>
                                <Divider orientation="vertical" style={{ height: '55px', color: '#fefefe'}} />
                                { this.state.userComment.length > 0 ? 
                                  <BtnImg src={SendIcon} onClick={() => this.handlePostComment(postData.id) } style={{width: '18px', paddingLeft: '25px', float: 'right', paddingRight: '2px', cursor:'pointer'}}/> 
                                  : <BtnImg src={SendIcon} style={{width: '18px', paddingLeft: '25px', float: 'right', paddingRight: '2px', opacity:0.5}}/>
                                }</InputAdornment>}
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{
                                  'aria-label': 'weight',
                                }}
                                style={{ width: '100%'}}
                              />
                            </div>
                            { this.state.openCommentEmoji ? 
                             <Picker onEmojiClick={this.onCommentEmojiClick} pickerStyle={{ width: '100%' }}/> : "" }
                              
                      </div>
                      { postCommentData?.map?.(p => <div style={{ marginBottom: '15px'}}>
                        <UserAvtar>
                          <Link to={`/user/${p?.account_id}`}><Avatar style={{ width: 35.26, height: 31.87 }} src={p?.profile_pic || OyoAvatar} onClick={() => navigation.navigate(`/user/${p?.account_id}`)} /></Link>
                        </UserAvtar>
                        <CommentDetail>
                            <AccountName>{ p?.account_name }</AccountName>
                            <CommentData>{ p?.content }</CommentData>
                        </CommentDetail>
                          <CardActions>
                            <FlexSpaceBetween style={{ padding: '0px 0px 5px 5px' }}>
                              <CardButtonWrapper>
                                <CardButton onClick={() => this.handleCommentLike(p)}>{ !p.is_like ? "Like": <div style={{ color: '#009c05'}}>Like</div>}</CardButton>
                                <div style={{ lineHeight: '2.5', color: '#d4d4d4' }}>|</div>
                                <CardButton onClick={() => this.handleSetReplyId(p.id)}>Reply</CardButton>
                              </CardButtonWrapper>
                              { p?.like_count ? 
                              <div style={{ display: 'flex', alignItems: 'center'}}>
                                <BtnImg src={ThumbGreenIcon} style={{width: '18px'}}/> 
                                <span style={{ paddingLeft: '3px', fontSize: '13px'}}>{ p?.like_count }</span> 
                              </div> : '' }
                            </FlexSpaceBetween>
                          </CardActions>
                          { this.state.reply_id === p.id ? 
                          <div style={{ marginTop: '15px', marginBottom: '40px', paddingLeft: '50px'}}>
                            <CommentUserAvtar>
                                <Link to={`/user/${this.context?.id}`}><Avatar style={{ width: 35.26, height: 31.87 }} src={this.context?.profile_pic || OyoAvatar}/></Link>
                              </CommentUserAvtar>
                              <div style={{  width: 'calc(100% - 50px)', display: 'inline-block', boxSizing: 'border-box' }}>
                            
                              <OutlinedInput
                                id="outlined-adornment-weight"
                                value={ this.state.userReply }
                                onChange={this.handlePostReplyChange}
                                placeholder="Comment your views"
                                endAdornment={<InputAdornment position="end"><BtnImg src={EmogiIcon} onClick={this.openEmojiCommentReply } style={{width: '18px', paddingLeft: '10px'}}/> 
                                { this.state.userReply.length > 0 ? 
                                  <BtnImg src={SendIcon} onClick={() => this.handleReplyComment() } style={{width: '18px', paddingLeft: '25px', float: 'right', paddingRight: '2px', cursor:'pointer'}}/> 
                                  : <BtnImg src={SendIcon} style={{width: '18px', paddingLeft: '25px', float: 'right', paddingRight: '2px', opacity:0.5}}/>
                                }</InputAdornment>}
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{
                                  'aria-label': 'weight',
                                }}
                                style={{ width: '100%'}}
                              />
                            </div>
                            { this.state.openCommentReplyEmoji ? 
                             <Picker onEmojiClick={this.onReplyEmojiClick} pickerStyle={{ width: '100%' }}/> : "" }
                            </div> : ''}
                            { p?.replies?.length > 0 && this.state.openReply != p.id ?
                            <div onClick={() => this.showReply(p.id) } style={{ width: 'calc(100% - 50px)', height: '8px', margin: '0px 50px 25px', borderBottom: '1px solid #cdcdcd', textAlign: 'center'}}>
                            <span style={{fontSize: '13px', backgroundColor: '#fff', padding: '0 30px', color: '#a9a9a9'}}>
                              View  { p?.replies?.length } replies
                            </span>
                          </div>
                          : '' }
                              { this.state.openReply == p.id  ? p?.replies?.map?.((r: any) => <div style={{ padding: '10px 0px 10px 50px' }}>
                                <UserAvtar>
                                  <Link to={`/user/${r?.account_id}`}><Avatar style={{ width: 35.26, height: 31.87 }} src={r?.profile_pic || OyoAvatar} onClick={() => navigation.navigate(`/user/${r?.account_id}`)} /></Link>
                                </UserAvtar>
                                <CommentDetail>
                                    <AccountName>{ r?.account_name }</AccountName>
                                    <CommentData>{ r?.content }</CommentData>
                                </CommentDetail>
                                </div>
                              ) : ''}
                      </div>) }

                      {
                        this.state.pagination?.next_page ? <div style={{ marginTop: '50px', marginBottom: '50px', width: '100%', textAlign: 'center', color: '#b9b9b9', fontSize: '15px', fontWeight: 'bold', cursor: 'pointer', backgroundColor: '#f0f0f0', padding: '15px 0px', borderRadius: '10px', borderColor: '#fefefe'}} onClick={this.loadMore}>Load more discussion</div> : '' 
                      }
                  </DiscussSection>
               </RoundedCard>
              </ModelBody>

          </Grid>
      </Box>
  </Modal >
  </Wrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
PostController.contextType = UserContext
const ModelBody = styled(Grid)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'unset',
  flexDirection: 'column',
  padding: '0px'
})


const SelectItemUserText = styled('span')({
  overflow: 'hidden',
  textOverflow: 'ellipsis'
})

const RoundedCard = styled(Card)({
  borderRadius: '15px',
  padding: '10px 20px',
  margin: '20px 0px'
})

const FlexSpaceBetween = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignContent: 'center',
  padding: '20px 0px',
  width: '100%',
})

const CardButton = styled(Button)({
  border: 'none',
  color: '#aaaaaa',
  fontWeight: 600,
  textTransform: 'none'
})

const CardButtonWrapper = styled('div')({
  display: 'flex',
  gap: '5px',
  padding: '0 20px'
})

const MR5 = styled('span')({
  marginRight: '5px',
  color: '#2a353f',
  textTransform: 'capitalize'
})
const BtnImg = styled('img')({
  width: 25
})
const PostMainContent = styled('div')({
  padding: '10px 0'
})
const CloseButton = styled(Button)({
  position: 'absolute',
  top: '-10px',
  right: '-50px',
  '@media screen and (max-width:768px)':{
    top: '15px',
    right: '-4px',
  }
})
const Wrapper = styled('div')({
  zIndex: 1010,
  minWidth: '100vw',
  minHeight: '100vh',
  background: 'rgba(0,0,0,0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const DiscussSection = styled('div')({
  padding: '16px 30px'
})

const AccountName = styled('div')({
  color: '#009c05',
  'font-weight': 'bold',
  'font-size': '13px'
})

const CommentData = styled('div')({
  width: '100%',
  'word-break': 'break-word',
  marginTop: '5px'
})

const DiscussHeader = styled('div')({
  fontSize: '18px',
  fontWeight: 'bold',
  padding: "15px 18px 25px"
})

const UserAvtar = styled('div')({
  display: 'inline-block',
  width: '50px',
  height: '40px',
  verticalAlign: 'top',
  paddingTop: '3px'
})

const CommentUserAvtar = styled('div')({
  display: 'inline-block',
  width: '50px',
  height: '40px',
  verticalAlign: 'top',
  paddingTop: '12px'
})

const CommentDetail = styled('div')({
  display: 'inline-block',
  width: 'calc(100% - 50px)',
  boxSizing: 'border-box',
  padding: '10px 20px 20px',
  minHeight: '80px',
  border: '1px solid #e8e4e4',
  borderRadius: '5px',
  lineHeight: '1.3',
  textAlign: 'justify'
})
// Customizable Area End

import React from "react";

// Customizable Area Start
import {
  Avatar,
  Badge, 
  MenuItem, 
  IconButton,
  Popover,
  Typography,
  Paper,
  Divider,
  Button,Popper,
  ClickAwayListener
  } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Link } from 'react-router-dom'
import { format } from 'timeago.js'
import { formatHeading } from './AllNotifications.web'

// Customizable Area End

import NotificationBellController, {
  Props,
  configJSON
} from "./NotificationBellController";

export default class NotificationBell extends NotificationBellController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
      const notifications = this.state.notifications.slice(0, 5)
    // Customizable Area End
     return (
      // Customizable Area Start
      <>
      {window.screen.width > 600 ?
        <IconButton onClick={this.onBellClick} aria-label="show 17 new notifications"  style={{color:"grey", background: 'transparent'}}>
          <Badge badgeContent={this.state.unread_notifications||0} color="secondary">
            <NotificationsNoneIcon />
          </Badge>
        </IconButton>
      :
        <Link to="/notifications">
          <IconButton aria-label="show 17 new notifications"  style={{color:"grey", background: 'transparent'}}>
            <Badge badgeContent={this.state.unread_notifications||0} color="secondary">
              <NotificationsNoneIcon />
            </Badge>
          </IconButton>
        </Link>
      }
      <NotificationList
        onRead={this.handleRead}
        markAllRead={this.markAllRead}
        notifications={notifications} 
        anchorEl={this.state.anchorEl} 
        handleClose={this.handlePopupClose} 
      />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
function removeDuplicates(items: any[]){
  const visited = new Set()
  return items.filter((it: any)=>{
    if(!visited.has(it.id)) {
      visited.add(it.id)
      return true
    }
    return false
  })
}
export const NotificationList = (props: any) => {
  const notifications = removeDuplicates(props.notifications).map((n: any) => <NotificationCard key={n.id} onRead={props.onRead} {...n}/>)
  return <TopPopover
        open={!!props.anchorEl}
        anchorEl={props.anchorEl}
        placement="bottom-end" // Adjust the placement as needed
      ><ClickAwayListener data-test-id="notification-popup-clickAway" onClickAway={props.handleClose}>
        <Wrapper variant="outlined">
          <Header>
            <Typography variant="h5" style={{ fontWeight: 700, fontSize: '21px' }}>
              Notifications
            </Typography>
            <Typography 
              data-test-id="mark-all-read"
              onClick={props.markAllRead } 
              style={{color: LINKCOLOR, fontSize: '13px', cursor: 'pointer'}}
            >
              Mark all as read
            </Typography>
          </Header>
          <Divider />
          <Body>
            <div>
              {notifications}
            </div>
            <div style={{margin: '15px 23px 0 23px'}}>
              <Link to="/notifications">
                <AllButton disableElevation variant="contained">See all notification</AllButton> 
              </Link>
            </div>
          </Body>
        </Wrapper>
        </ClickAwayListener>
      </TopPopover>
}

// Start of NotificationCard
const NotificationCard = withRouter((props: any) => {
  const [more, setMore] = React.useState(false)
  const onOpen = (e: any) => {
    props.onRead(e)
    setTimeout(() => props.history.push(props.app_url || '#',true), 500)
  } 
  return <NCWrapper active={!props.is_read}>
    <Link to={props.user_profile?.profile_id || props.app_url || ''}>
      <Avatar  style={{width: '52px', height: '52px'}} src={props.user_profile?.avatar_link} />
    </Link>
    <NCRight>
      <div style={{ marginBottom: '5px'}}>
          <NCTitle data-test-id="mark-read" data-id={props.id} onClick={onOpen}>{formatHeading(props.headings)}</NCTitle>
      </div>
      {/* @ts-ignore */}
      {more ? <div style={{ fontSize: '13px', lineHeight: '1.4em', marginBottom: '5px', wordWrap: 'anywhere' }}> { props.contents } </div>: ''}
      <NCBottoms>
        {props.contents && (
            <MoreButton onClick={()=>setMore(!more)}>
              {more?"View Less":"View More"}
            </MoreButton>)
        }
        <NCTime>
          {format(props.created_at)}
        </NCTime>
      </NCBottoms>
    </NCRight>
  </NCWrapper>
})

const TopPopover = styled(Popper)({
  position:'relative',
  zIndex:9,
  overflowY:'scroll',
  backgroundColor:'#FFFFFF',
  '& .MuiPopover-paper': {
    overflowX: 'auto',
  }
})

const MoreButton = styled(Button)({
  height: '25px',
  border: '1px solid #979797',
  background: '#EEFF1',
  color: '#979797',
  borderRadius: '7px',
  fontSize: '13px',
  fontWeight: 700,
  textTransform: 'none',
})

const NCRight = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
})

const NCTitle = styled(Typography)({
  color: '#4c4c4c',
  fontSize: '14px',
  cursor: 'pointer',
  // @ts-ignore
  wordWrap: 'anywhere'
})
const NCContent = styled(Typography)({

})
const NCTime = styled(Typography)({
  color: '#9196A1',
  fontSize: '12px',
  float: 'right'
})
const NCBottoms = styled('div')({
  justifyContent: 'space-between',
  marginTop: '5px'
})
const NCWrapper = styled('div')((props: any) => ({
  background: props.active ? '#EFF4FF':'#FFF',
  padding: '16px 22px',
  borderBottom: '1px solid #D1D2D6',
  display: 'flex',
  gap: '35px'
}))

// end of NotificationCard

const Wrapper = styled(Paper)({
  padding: '10px 0',
  borderRadius: '7px',
  border: '1px solid #E0E0E0',
  width: '440px',
  maxHeight: '560px',
  display: 'flex',
  flexDirection: 'column',
})

const Header = styled('div')({
  padding: '14px 24px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'end',
})

const Body = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column'
})

const AllButton = styled(Button)({
  background: '#F2F4F5 !important',
  width: '100%',
  fontSize: '17px',
  padding: '8px 0',
  borderRadius: '30px',
  '& > span': {
    textTransform: 'none',
    color: '#5F686F',
  }
})

const LINKCOLOR = '#4e80ff'
// Customizable Area End

import React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';
import { styled } from "@material-ui/styles";
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import PDFViewerController from './PdfViewerController';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class PDFViewer extends PDFViewerController{

    constructor(props: any) {
        super(props);
    }
    renderPages = () => {
        const { numPages } = this.state;
        const pages = [];

        for (let i = 1; i <= numPages; i++) {
            const pageRef:any = React.createRef();
            this.pagesRefs.push(pageRef);

            pages.push(
                <div key={i} ref={pageRef}>
                    <Page pageNumber={i} />
                </div>
            );
        }
        return pages;
    };
    render() {
        const { scale, numPages, pageNumber }: any = this.state;

        const isLastPage = pageNumber === numPages
        const isFirstPage = pageNumber === 1;

        const isMaxZoom = scale >= 2.0;
        const isMinZoom = scale < 0.6;

        return (
            <WrapperDiv>
                <PDFSection id="pdf-section">
                    <ControlWrapperDiv>
                        <PageHandleWrapperDiv>
                            <PreviousPageIcon
                                id='previous-page'
                                data-testid='previous-page-button'
                                style={{ cursor: isFirstPage ? 'not-allowed' : 'pointer' }}
                                onClick={() => this.goToPreviousPage(isFirstPage, pageNumber)}
                            >
                                <ArrowBackIosIcon style={{ fontSize: '14px', marginRight: '-5px' }} />
                            </PreviousPageIcon>
                            <span>
                                Page <span data-testid = "current-page">{pageNumber}</span> of {numPages}
                            </span>
                            <NextPageIcon
                                onClick={() => this.goToNextPage(isLastPage, pageNumber)}
                                style={{ cursor: isLastPage ? 'not-allowed' : 'pointer' }}
                                data-testid='next-page-button'
                            >
                                <ArrowForwardIos style={{ fontSize: '14px', marginRight: '-2px' }} />
                            </NextPageIcon>
                        </PageHandleWrapperDiv>
                        <ZoomHandleWrapperDiv>
                            <ZoomOutSpan
                                onClick={() => this.zoomOut(isMinZoom, scale)}
                                style={{ cursor: scale < 0.6 ? 'not-allowed' : 'pointer' }}
                                data-testid="pdf-zoom-out"
                            >
                                -
                            </ZoomOutSpan>
                            <span data-testid="current-zoom">{(scale * 100).toFixed()}%</span>
                            <ZoomInSpan
                                onClick={() => this.zoomIn(isMaxZoom, scale)}
                                style={{ cursor: scale >= 2.0 ? 'not-allowed' : 'pointer'}}
                                data-testid="pdf-zoom-in"
                            >
                                +
                            </ZoomInSpan>
                        </ZoomHandleWrapperDiv>
                        <div style={{ marginLeft: '1rem' }}>
                            <a  
                                href={this.props.url}
                                data-testid="download-pdf"
                                style={{ 
                                    textDecoration: 'none', 
                                    cursor: 'pointer', 
                                    display: 'flex', 
                                    alignItems: 'center', 
                                    color: 'green',
                                    fontWeight: 'bold',
                                    fontSize: '14px'
                                }}
                                download={true}
                                title="download"
                                target="_blank"
                            >
                                <CloudDownloadIcon style={{ color: 'green', marginRight: '6px' }} /> DOWNLOAD
                            </a>
                        </div>
                    </ControlWrapperDiv>
                        <div
                            ref={this.pdfContainerRef}
                            style={{ height: '600px', overflowY: 'auto', overflowX: 'hidden' }}
                            onScroll={this.handleScroll}
                            data-testid ="scrollable-element"
                        >
                            <Document
                                onLoadSuccess={this.onDocumentLoadSuccess}
                                file={this.props.url}
                            >
                                <Page pageNumber={pageNumber} scale={scale} />
                            </Document>
                        </div>
                    <StyledLink
                        title="download"
                        data-testid="open-new-tab"
                        href={this.props.url}
                        target="_blank"
                        download
                    >
                        OPEN IN NEW TAB
                    </StyledLink>
                    {/* </div> */}
                </PDFSection>
            </WrapperDiv>
        );
    }
}

export default PDFViewer;

const WrapperDiv = styled('div')({
    width: '100%',
    textAlign: 'center'
})

const PDFSection = styled('section')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
})

const ControlWrapperDiv = styled('div')({
    margin: '1rem 0',
    padding: '.5rem 2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    border: '2px solid green',
    borderRadius: '50px',
    width: '60%',
})

const PageHandleWrapperDiv = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
})

const PreviousPageIcon = styled('span')({
    marginRight: '1rem',
    height: '25px',
    width: '25px',
    border: '1px solid green',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
})

const NextPageIcon = styled('span')({
    marginLeft: '1rem',
    height: '25px',
    width: '25px',
    border: '1px solid green',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
})

const ZoomHandleWrapperDiv = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
})

const ZoomOutSpan = styled('span')({
    margin: '0 1rem',
    height: '20px',
    width: '20px',
    lineHeight: '20px',
    border: '1px solid green',
    borderRadius: '50%',
})

const ZoomInSpan = styled('span')({
    marginLeft: '1rem',
    height: '20px',
    width: '20px',
    lineHeight: '20px',
    border: '1px solid green',
    borderRadius: '50%',
})

const PrintDiv = styled('div')({
    marginLeft: '3px', 
    cursor: 'pointer'
})


const StyledLink = styled('a')({
    textDecoration: 'none',
    cursor: 'pointer',
    padding: '10px 15px',
    borderRadius: '5px',
    color: 'white',
    backgroundColor: 'green',
    fontSize: '14px',
    fontWeight: 'bold',
    display: 'inline-block',
    marginTop: '2rem',
});
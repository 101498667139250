import React from "react";

// Customizable Area Start
import {
  Avatar,
  Typography,
  Box,
} from "@material-ui/core";
import DashboardLayout from "../../../components/src/DashboardLayout.web";
import ArrowForwardOutlined from '@material-ui/icons/ArrowForwardOutlined';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { styled } from "@material-ui/styles";
import {
  reportImg,
  ceCalculatorImg
} from './assets'
// Customizable Area End

import CfCarbonCalculatorController, {
  Props
} from "./CfCarbonCalculatorController";
export default class CfCarbonCalculator extends CfCarbonCalculatorController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  
  render() {
    // Customizable Area Start
    const { navigation } = this.props
    const abilityType = navigation?.history?.location;
    const path = this.context.user_type == "licensed_user" ? 'Corporatepermission' : 'ESGJourney'
    
    const rows = [
      this.createDataMenu('Reports', 'CfCarbonCalculatorReport', reportImg, 'Reports','1'),
      this.createDataMenu('Carbon Emission (GHG) Calculator', 'CarbonCalculatorCardForm', ceCalculatorImg, 'Download Carbon Calculator - GHG Tool','2'),
    ]
    // Customizable Area Start
    return (
        // Customizable Area Start
      <DashboardLayout isDashboard={true} isUserSurvey={false} navigation={navigation} >
        <CecfWrapper>
          <CecfHeadingWrapper>
            <CecfPageTitle onClick={() => navigation.navigate(path)} data-testid='navigateBack'>
              ESG Journey
              <ArrowBackIosIcon />
            </CecfPageTitle>
            <CoinsHead>Carbon Emission Calculator - GHG Tool</CoinsHead>
          </CecfHeadingWrapper>
            <CecfCardWrapper>
              {console.log("Rows",rows)
              }
              {rows.map((row, index) => (
                  <SurveyBox key={row.cardNo} onClick={() => navigation.history.push(row.File, abilityType)}>
                    <CecfCardArrowWrapper>
                      <ArrowForwardOutlined color="disabled" />
                    </CecfCardArrowWrapper>
                    <CecfCardContent>
                      <LogoDiv>
                        <Avatar style={{ width: 105.26, height: 101.87 }} src={row.img} />
                      </LogoDiv>
                      <HedingText>{row.Name}</HedingText>
                    </CecfCardContent>
                  </SurveyBox>
              ))}
            </CecfCardWrapper>
        </CecfWrapper>
      </DashboardLayout>
        // Customizable Area End
    );
}
}

// Customizable Area Start
const SurveyBox = styled(Box)({
  backgroundColor: "white",
  display: "flex",
  flexDirection: "column",
  borderRadius: "15px",
  padding: "20px", 
  height: "331px", 
  width: "296px", 
  filter: "drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.11))",
  cursor: "pointer"
})

const HedingText = styled(Typography)({
  fontWeight: 700, 
  marginBottom: '4px', 
  fontSize: "20px", 
  overflow: 'hidden', 
  textOverflow: 'ellipsis', 
  display: 'block', 
  textTransform: 'capitalize', 
  whiteSpace: 'pre-line', 
  textAlign: 'center', 
  lineHeight: '20px',
  
  '@media screen and (max-width:600px)': {
      width: '100%',
      textAline:'center'
  },
  '@media screen and (min-width:769px) and (max-width:1200px)': {
      width: '100%',
      textAline:'center'
  }
})

const LogoDiv = styled('div')({
  margin: "5px 0 5px 0",
  display:"flex",
  justifyContent: "center",
  '& img':{
      objectFit: 'contain'
  }
})

const CoinsHead = styled("div")({
  margin: "40px 0px 15px 0px",
  fontSize: "1.4rem",
  fontWeight: "bolder",
  textTransform: "uppercase",
  "@media screen and (max-width: 768px)": {
    margin: "20px 0px 10px 12px",
  },
});

const CecfWrapper = styled("div")({
  display: 'flex', 
  flexWrap: 'wrap',
  flexDirection:'column', 
  padding: '0px 40px', 
  gap:'30px'
});

const CecfHeadingWrapper = styled("div")({
  display: "flex", 
  alignItems: "center"
});

const CecfPageTitle = styled("span")({
  margin: "40px 5px 15px 12px", 
  cursor: "pointer",
  display: 'flex',
  alignItems: "center",
  gap: "15px",
});

const CecfCardWrapper = styled("div")({
  display:'flex', 
  gap:'25px', 
  flexWrap:'wrap'
});

const CecfCardArrowWrapper = styled("div")({
  display: 'flex', 
  justifyContent: 'end'
});

const CecfCardContent = styled("div")({
  display: 'flex', 
  flexDirection: "column", 
  justifyContent: 'space-around', 
  alignItems: "center",
  height:"260px"
});
// Customizable Area End

Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.validationFormData="multipart/form-data";
exports.updateAPiEndPointWeb = "account_block/accounts/update_profile";
exports.updateChangePasswordEndPointWeb="bx_block_forgot_password/passwords/change_password";
exports.updateAPiMethod = "PUT";
exports.updateAPiGetMethod = "GET";
exports.updateAPiGetDetailsEndPointWeb = "account_block/accounts/show";
exports.updateProfilePicWeb="account_block/accounts/profile_picture_update";
exports.updateApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Settings5";
exports.labelBodyText = "Settings5 Body";
exports.errorPasswordNotValid = "Password not valid.";
exports.passwordError="Pasword Must contains alteast 1 upper case ,1 lower case,1 number , 1 special character"
exports.btnExampleTitle = "CLICK ME";
exports.getTermsAndConditions="account_block/static_pages/term_condition";
exports.getHelpAndSupport="account_block/help_supports/help_support";
exports.getPlaceNameEndpoint = "bx_block_joblisting2/jobs/get_place_name";
exports.taggedCompany = "account_block/accounts/search_company"
exports.allFollowers = "/bx_block_followers/follows"
exports.allViewers = "account_block/accounts/account_viewed_by"
exports.getContactUs = "/bx_block_contact_us/contacts"
// Customizable Area End
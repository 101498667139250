import React, { RefObject } from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";

export interface Props {
    // Customizable Area Start
    url: string
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    scale: number,
    numPages: any,
    pageNumber: number,
    isLoading: boolean,
    currentPage: number,
    scrollingUp: boolean,
    prevScrollTop: number, 
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class PDFViewerController extends BlockComponent<
    Props,
    S,
    SS
> {
    pagesRefs:any
    constructor(props: any) {
        super(props);
        this.pdfContainerRef = React.createRef();
        this.pagesRefs = [];
        this.state = {
            scale: 1.5,
            numPages: null,
            pageNumber: 1,
            isLoading: true,
            currentPage: 1,
            scrollingUp: false,
            prevScrollTop: 0, 
        };
    }

    onDocumentLoadSuccess = ({ numPages }: any) => {
        this.setState({ numPages, isLoading: false });
    };

    pdfContainerRef: RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();

    async componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    async componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        const container = this.pdfContainerRef.current;
        if (!container) return;
        
        const top = container.scrollTop;
        const scrollingUp = top < this.state.prevScrollTop;
        this.setState({ prevScrollTop: top, scrollingUp });

        const isAtTop = top === 0;

    if (scrollingUp && isAtTop) {
        this.setState((prevState) => {
            if (prevState.currentPage > 1) {
                return { ...prevState, currentPage: prevState.currentPage - 1 , pageNumber: prevState.currentPage - 1} as Pick<S, keyof S>;
            }
            return prevState;
        });
    }
    
        const bottom = container.scrollHeight - container.clientHeight;
        if (container.scrollTop === bottom) {
            this.setState((prevState) => {
                if (prevState.currentPage < prevState.numPages) {
                    return { currentPage: prevState.currentPage + 1 , pageNumber: prevState.currentPage + 1};
                } else {
                    return null;
                }
            },() => {
                this.scrollToTop()});
        } else {
            return null;
        }
    };
    scrollToTop = () => {
        const container = this.pdfContainerRef.current;
        if (container && typeof container.scrollTo === 'function') {
            container.scrollTo({ top: 2, behavior: 'smooth' });
        }
    };
    
    setScale = (newScale: any) => {
        this.setState({ scale: newScale })
    }

    setPageNumber = (newPageNumber: any) => {
        this.setState({ pageNumber: newPageNumber ,currentPage:newPageNumber}, () => { this.scrollToTop()})
    }
    
    goToNextPage = (isLastPage: any, pageNumber: any) => {
        if (!isLastPage) this.setPageNumber(pageNumber + 1);
    };

    goToPreviousPage = (isFirstPage: any, pageNumber: any) => {
        if (!isFirstPage) this.setPageNumber(pageNumber - 1);
    };

    zoomIn = (isMaxZoom: any, scale: any) => {
        if (!isMaxZoom) this.setScale(scale + 0.1);
    };

    zoomOut = (isMinZoom: any, scale: any) => {
        if (!isMinZoom) this.setScale(scale - 0.1);
    };  
    
}
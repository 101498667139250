import React from "react";
// Customizable Area Start

import { Box, Typography, Divider, Button } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { styled } from '@material-ui/styles';

// @ts-ignore

const CloseIcon = require('../assets/ic_close.png');

import { Link } from 'react-router-dom';

//customizable Area End

export const UserSurveyDetailModel: Function = ({navigation, detail, onCloseClick }: any) => {
    console.log("UserSurveyDetailModel",detail);
    return<>
    <ModalSection>
        <SurveyModelWrapper>
        <SurveyModelSection>
            <SectionItem style={{ fontSize: "14px", marginBottom:'0' }}>
                <TitleSection>
                    <Typography style={{ fontSize: "24px", color: "#2A3035" }} variant="subtitle1" component="div">
                        {detail?.attributes?.survey_title}
                    </Typography>
                    <DivSection>
                        <BtnImg id="CloseBtn" src={CloseIcon} onClick={() => onCloseClick()} style={{ height: "40px", width: "40px" }} alt="close" />
                    </DivSection>
                </TitleSection>
            </SectionItem>
            <Divider />

            <MobileSectionItem>
                <DivSection>
                    <SectionItem style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
                        <SubTitle style={{ fontSize: "14px",fontWeight:'bold', color:'rgb(57,57,57)' }}>
                            Start Date&nbsp;&nbsp;:&nbsp;&nbsp;
                        </SubTitle>
                        <Typography variant="subtitle2" component="div" style={{ fontSize: "14px", fontWeight:'bold',lineHeight: "normal", color:'#515256' }}>
                            {detail?.attributes?.start_date}
                        </Typography>
                    </SectionItem>
                    <SectionItem style={{ display: "flex", alignItems: "center", color:"#555555" }}>
                        <SubTitle style={{ fontSize: "14px",color:"#656565",fontWeight:'bold' }}>
                            Created By&nbsp;&nbsp;:&nbsp;&nbsp;
                        </SubTitle>

                        <UserAvtarDetail>
                        <Link to={`/user/${detail?.attributes?.created_by?.id}`}>
                            <Avatar data-test-id = "user-avatar-created-by" style={{ marginRight: "5px", height: "30px", width: "30px", border:"3px solid rgb(242, 212, 212)" }} src={detail?.attributes?.created_by?.profile_pic} alt={detail?.attributes?.created_by?.first_name} />
                            </Link>
                            <Typography variant="subtitle2" component="div" style={{ color:'#515256',fontWeight:'bold' }}>
                                {`${(detail?.attributes?.created_by?.first_name) ? detail?.attributes?.created_by?.first_name : ""}
                                ${(detail?.attributes?.created_by?.last_name) ? detail?.attributes?.created_by?.last_name : ""}`}
                            </Typography>
                        </UserAvtarDetail>
                    </SectionItem>
                </DivSection>
                <DivMobileSection>
                    <MobileSectionItemLeft>
                        <SubTitle style={{ fontSize: "14px",fontWeight:'bold', color:'rgb(57,57,57)' }}>
                            End Date&nbsp;&nbsp;:&nbsp;&nbsp;
                        </SubTitle>
                        <Typography variant="subtitle2" component="div" style={{ fontSize: "14px",fontWeight:'bold', lineHeight: "normal", color:'#515256' }}>
                            {detail?.attributes?.end_date}
                        </Typography>
                    </MobileSectionItemLeft>
                    <MobileSectionItemLeft>
                        <SubTitle style={{ fontSize: "14px" ,color:'#656565', fontWeight:'bold' }}>
                            Requested By&nbsp;&nbsp;:&nbsp;&nbsp;
                        </SubTitle>
                        {detail?.attributes?.requested_by?.id && <UserAvtarDetail>
                        <Link to={`/user/${detail.attributes.requested_by.id}`}>
                            <Avatar data-test-id ="user-avatar-requested-by"style={{ marginRight: "5px", height: "30px", width: "30px", border:"3px solid rgb(242, 212, 212)" }} src={detail?.attributes?.requested_by?.profile_pic} alt={detail?.attributes?.requested_by?.first_name} />
                            </Link>
                            <Typography variant="subtitle2" component="div" style={{ color:'#515256',fontWeight:'bold' }}>
                                {`${(detail?.attributes?.requested_by?.first_name) ? detail?.attributes?.requested_by?.first_name : ""}
                                ${(detail?.attributes?.requested_by?.last_name) ? detail?.attributes?.requested_by?.last_name : ""}`}
                            </Typography>
                        </UserAvtarDetail>}
                    </MobileSectionItemLeft>
                </DivMobileSection>
            </MobileSectionItem>
            <Divider />
            <SectionItem>
                <Typography variant="subtitle1" component="div" style={{ fontSize: "16px", color:'#2f3438' }}>Description</Typography>
                <Typography variant="subtitle2" component="div" style={{ fontSize: "14px", color: "#b9b9b9", wordWrap: 'break-word' }}>
                    {detail?.attributes?.description} 
                </Typography>
            </SectionItem>
            <Divider />

            <LaunchSurveySection>
                <PlanCardButton onClick={()=>{navigation.history.push(`/SurveyEnduser/${detail.id}`)}} id="launchSurveyBtn" style={{ backgroundColor: "#00A100", color: "white", height: "45px", width: "75%" }}>
                    Launch Survey
                </PlanCardButton>
            </LaunchSurveySection>
        </SurveyModelSection>
        </SurveyModelWrapper>
    </ModalSection>
    </>
};

// Customizable Area Start


const DivSection = styled('div')({
    cursor: "pointer"
})

const DivMobileSection = styled('div')({
    display: "flex", 
    flexDirection: "column", 
    rowGap: "3px"
})

const ModalSection = styled('div')({
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%,-50%)`,
    position: `absolute`
})

const LaunchSurveySection = styled('div')({
    display: "flex", 
    justifyContent: "center"
})

const PlanCardButton = styled(Button)({
    border: '2px solid transparant',
    color: '#009B07',
    fontWeight: 600,
    backgroundColor: '#FFFFFF',
    textTransform: 'none',
    borderRadius: '38px',
    // height:'100px',
    padding: '0 65px 0 65px',
    '@media screen and (max-width : 1110px) and (min-width:769px)': {
        padding: '0 35px 0 35px',
    },
    '@media screen and (max-width: 624px)': {
        padding: '0 35px 0 35px',
    },
    '@media screen and (max-width: 500px)': {
        padding: '0 25px 0 25px'
    }
});

const SectionItem = styled(Box)({
    // margin: '8px 0px',
    '& .MuiTypography-root.MuiTypography-subtitle1': {
        fontWeight: 'bold',
        fontSize: 11,
    },
    '& .MuiTypography-root.MuiTypography-subtitle2': {
        fontWeight: 'normal',
        color: 'grey',
        fontSize: 14,
        // paddingTop: '10px',
    }
});

const SurveyModelWrapper = styled(Box)({
    background: 'white',
    borderRadius: "20px",
    padding: "35px",
    width: '700px',
    '@media screen and (max-width:768px)': {
        width: '550px'
    },
    '@media screen and (max-width:600px)': {
        width: '420px'
    },
    '@media screen and (max-width:468px)': {
        width: '320px'
    },
    '@media screen and (max-width:370px)': {
        width: '245px'
    }
})

const SurveyModelSection = styled(Box)({
    backgroundColor: "white",
    overflow: "auto",
    display: "flex",
    gap: "18px",
    flexDirection: "column",
    justifyContent: "space-between",
    maxHeight: '80vh',
});

const MobileSectionItem = styled(Box)({
    margin: '0px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom:'-10px',
    '& .MuiTypography-root.Muitypography-subtitle-1': {
        fontWeight: 'bold',
        fontSize: 14
    },
    '& .MuiTypography-root.MuiTypography-subtitle2': {
        fontWeight: 'normal',
        color: 'grey',
        fontSize: 14,
    },
    '@media screen and (max-width:486px)': {
        display: 'block'
    }
});

const MobileSectionItemLeft = styled(Box)({
    margin: '8px 0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    '& .MuiTypography-root.MuiTypography-subtitle1': {
        fontWeight: 'bold',
        fontSize: '12px',
    },
    '& .MuiTypography-root.MuiTypography-subtitle2': {
        fontWeight: 'normal',
        color: 'grey',
        fontSize: 14
    },
    '@media screen and (max-width:468px)': {
        justifyContent: 'start',
        marginTop: '20px'
    }

});

const BtnImg = styled('img')({
    height: "40px", 
    width: "40px"
});

const UserAvtarDetail = styled('div')({
    display: "flex", 
    flexDirection: "row", 
    alignItems: "center"
})

const TitleSection = styled('div')({
    display: "flex", 
    justifyContent: "space-between", 
    alignItems: "center",
    color:'#303539'
})

const SubTitle = styled('div')({
    fontWeight:'normal'
})


// Customizable Area End
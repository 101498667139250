import React from "react";
// Customizable Area Start

import { Avatar, Box, Card, Grid, Typography, Button, Modal } from '@material-ui/core';
import UserSurveyController, { Props } from './UserSurveyController';
import DashboardLayout from "../../../components/src/DashboardLayout.web";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { styled } from '@material-ui/styles';
import { UserSurveyDetailModel } from "./UserSurveyDetailModel.web";
import { SeekingFund } from "./SeekingFund";
import { SeekingFundDetailModel } from "./SeekingFundDetailModel.web";
import Pagination from '@material-ui/lab/Pagination';
import OverlayLoader from '../../../components/src/Loader.web'


export const Survey: Function = ({ detail, onModelClick }: any) => {
    return (<SectionItem>
            <Card style={{ borderRadius: "10px", border: "1px solid #f2f2f2", height: "300px" }}>
                <CardSection>
                    <DivSection id="ModelClickDiv" onClick={() => { onModelClick(detail) }}>
                        <DetailSection>
                            <SectionItem style={{ fontSize: "20px" }}>
                                {detail?.attributes?.survey_title?.length > 15 ? (
                                    <Typography style={{ fontSize: "20px" }} variant="subtitle1" component="div">
                                        {detail?.attributes?.survey_title?.slice(0, 12)}
                                        <NormalText onClick={() => onModelClick(detail)}> see more...</NormalText>
                                    </Typography>
                                ) : (
                                    <Typography style={{ fontSize: "20px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} variant="subtitle1" component="div">{detail?.attributes?.survey_title}</Typography>
                                )
                                }
                            </SectionItem>
                            <SectionItem>
                                {detail?.attributes?.status !== 'inactive' ? <LiveBtn>
                                    {detail?.attributes?.status}
                                </LiveBtn> : <InactiveBtn>{detail?.attributes?.status}</InactiveBtn>}
                            </SectionItem>
                        </DetailSection>
                        <DetailSection>
                            <SectionText>
                                <Typography variant="subtitle1" component="div">Start Date</Typography>
                                <Typography variant="subtitle2" component="div">{detail?.attributes?.start_date}</Typography>
                            </SectionText>
                            <SectionText>
                                <Typography variant="subtitle1" component="div">End Date</Typography>
                                <Typography variant="subtitle2" component="div">{detail?.attributes?.end_date}</Typography>
                            </SectionText>
                        </DetailSection>
                        <SectionText style={{ height: "90px" }}>
                            <Typography variant="subtitle1" className="preText" component="div">Description</Typography>
                            {detail?.attributes?.description?.length > 80 ? (<Typography className="CustomeText" variant="subtitle2" component="div">
                                {detail?.attributes?.description?.slice(0, 80)} <BoldText id="ModelOpenBtn" onClick={() => onModelClick(detail)}>see more...</BoldText>
                            </Typography>) : (<Typography className="CustomeText" variant="subtitle2" component="div">
                                {detail?.attributes?.description}
                            </Typography>)}
                        </SectionText>
                        <DetailSection style={{ margin: "4px 0" }}>
                            <SectionText style={{ maxWidth: "50%" }}>
                                <Typography variant="subtitle1" className="preText" component="div">Created by</Typography>
                                <UserAvtarDetail>
                                    <Avatar style={{ marginRight: "5px", height: "25px", width: "25px", border: "3px rgb(242, 212, 212) solid" }} src={detail?.attributes?.created_by?.profile_pic} alt={detail?.attributes?.created_by?.first_name} />
                                    <Typography variant="subtitle2" component="div">{`${(detail?.attributes?.created_by?.first_name) ? detail?.attributes?.created_by?.first_name : ""} ${(detail?.attributes?.created_by?.last_name) ? detail?.attributes?.created_by?.last_name : ""}`}</Typography>
                                </UserAvtarDetail>
                            </SectionText>
                            <SectionText>
                                <Typography variant="subtitle1" className="preText" component="div">Requested by</Typography>
                                <UserAvtarDetail>
                                    <Avatar style={{ marginRight: "5px", height: "25px", width: "25px", border: "3px rgb(242, 212, 212) solid" }} src={detail?.attributes?.requested_by?.profile_pic} alt={detail?.attributes?.requested_by?.first_name} />
                                    <Typography variant="subtitle2" component="div">{`${(detail?.attributes?.requested_by?.first_name) ? detail?.attributes?.requested_by?.first_name : ""}${(detail?.attributes?.requested_by?.last_name) ? detail?.attributes?.requested_by?.last_name : ""}`}</Typography>
                                </UserAvtarDetail>
                            </SectionText>
                        </DetailSection>
                    </DivSection>
                </CardSection>
            </Card>
        </SectionItem>)
}

//customizable Area End

export default class UserSurvey extends UserSurveyController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { navigation } = this.props
        // Customizable Area End

        return (
            // Customizable Area Start
            <DashboardLayout navigation={navigation} isUserSurvey={false} token={""} showChatMobileView={""} isActivityFeed={false} changeChatMobileView={""}>
                <Grid container spacing={3}>
                    <TopGrid item xs={12}>
                        <NewsSection>
                            <HeaderSection>
                                <HeaderSectionLeft>
                                    <ArrowBackIosIcon id="BackBtnDiv" style={{ color: "#353535" }} onClick={() => this.props.navigation.goBack()} />
                                    <RoundedButtonBase>
                                        <PlanCardButton id="ProjectSeekingBtn" onClick={this.fundButtonCall} style={this.state.activeFundButton ? { backgroundColor: "#00A100", color: "white" } : { backgroundColor: "white" }}>PROJECT SEEKING FUNDS</PlanCardButton>
                                        <HistoryCardButton id="SurveyBtn" onClick={this.surveyButtonCall} style={this.state.activeSurveyButton ? { backgroundColor: "#00A100", color: "white" } : { backgroundColor: "white" }}>SURVEY</HistoryCardButton>
                                    </RoundedButtonBase>
                                </HeaderSectionLeft>
                                <PlanCardButton id="CarbonCalculatorBtn" style={{ backgroundColor: "#00A100", color: "white", height: "45px", }} onClick={this.handleCarbonCalButtonClick}>Carbon Calculator Tool</PlanCardButton>
                            </HeaderSection>
                            {this.state.activeSurveyButton &&
                            <>
                                <SurveyBox>
                                    {this.state?.surveyData && this.state.surveyData.length > 0 && this.state.surveyData.map((surveyDetail: any) => {

                                        return (
                                            <>
                                                <Survey detail={surveyDetail} onModelClick={(detail: any) => {
                                                    this.setState({ ...this.state, openSurvey: true, modelSurveyDetail: detail })
                                                }} />
                                            </>)
                                    })}
                                   
                                </SurveyBox>
                                <Pagin>
                  					<Pagination color="primary" variant="outlined" count={this.state.total_survey_pages} page={this.state.survey_pages} onChange={this.handleSurveyPageChange} />
                				</Pagin>
                                 </>
                            }
                            {this.state.activeFundButton &&
                                <FundBox>
                                    {this.state?.surveyProjectData && this.state.surveyProjectData.length > 0 && this.state.surveyProjectData.map((surveyProjectDetail: any) => {
                                        
                                        return (
                                            <>
                                                <SeekingFund detail={surveyProjectDetail} onModelClick={(detail: any) => {
                                                    this.setState({ ...this.state, openProjectSeekingFund: true, modelSurveyDetail: detail })
                                                }} />
                                            </>)
                                    })}
                                </FundBox>
                            }
                        </NewsSection>
                    </TopGrid>
                    <Modal
                        id="ModalComponent"
                        open={this.state.openSurvey || this.state.openProjectSeekingFund}
                        onClose={() => { this.setState({ ...this.state, openSurvey: false, openProjectSeekingFund: false }) }}
                        aria-labelledby="simple-model-title"
                        aria-describedby="simple-model-description"
                    >
                        {this.state.openSurvey ?
                            <UserSurveyDetailModel navigation={this.props.navigation} detail={this.state.modelSurveyDetail} onCloseClick={() => { this.setState({ ...this.state, openSurvey: false }) }} /> 
                            :
                            <SeekingFundDetailModel navigation={this.props.navigation} detail={this.state.modelSurveyDetail} onCloseClick={() => { this.setState({ ...this.state, openProjectSeekingFund: false }) }} handleLikeChange={this.handleLikeChange} />
                        }
                    </Modal>
                </Grid>
            <OverlayLoader loading={this.state.loadingSurvey} />

            </DashboardLayout>
            // Customizable Area End
        )
    }
}
// Customizable Area Start

const TopGrid = styled(Grid)({
    '@media screen and (max-width:768px)': {
        // display:"none",
        // visibility:'hidden'
    }
})

const DivSection = styled('div')({
    cursor: "pointer"
})

const SurveyBox = styled(Box)({
    display: "flex",
    rowGap: "10px",
    columnGap: "20px",
    flexWrap: "wrap",
    '@media screen and (max-width:768px)': {
        justifyContent: "center"
    }
})

const FundBox = styled(Box)({
    display: "flex",
    rowGap: "10px",
    columnGap: "20px",
    flexWrap: "wrap",
    '@media screen and (max-width:768px)': {
        justifyContent: "center"
    }
})

const HeaderSection = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center", marginBottom: "35px",
    flexWrap: "wrap",
    rowGrap: "10px",
    rowGap: "15px",
    '@media screen and (max-width:768px)': {
        justifyContent: "center",

    }
})

const HeaderSectionLeft = styled(Box)({
    display: "flex", alignItems: "center", columnGap: "20px",
    '@media screen and (max-width:768px)': {
        columnGap: '5px'
    }
})

const Pagin = styled('div')({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	padding: '10px',
	'@media screen and (max-width:350px)': {
	  display: 'initial',
	  padding: '10px 1px',
	},
	'@media screen and (min-width:768px) and (max-width:850px)': {
	  display: 'initial',
	  padding: '10px 1px',
	}
  })

const NewsSection = styled(Box)({
    display: "flex",
    margin: "25px 10px 0px 10px",
    padding: "20px 10px",
    flexDirection: "column"
});

const LiveBtn = styled(Typography)({
    background: '#E4EBF5',
    border: '1px solid #E5EDE6',
    borderRadius: '10px',
    color: '#2175E7',
    padding: '8px 25px',
    fontSize: '14px',
    width: 'max-content',
    fontWeight: 'bold',
    mixBlendMode: 'normal',
    textTransform: 'capitalize'
});

const InactiveBtn = styled(Typography)({
    background: '#E5E5E5',
    border: "1px solid #E5EDE6",
    borderRadius: '10px',
    color: '#787878',
    padding: '8px 25px',
    fontSize: '14px',
    width: 'max-content',
    fontWeight: 'bold',
    mixBlendMode: 'normal',
    textTransform: 'capitalize'
});

const RoundedButtonBase = styled('div')((props: any) => ({
    height: '47px',
    fontWeight: 700,
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    backgroundColor: '#FFFFFF',
    flex: 'wrap',
    color: '#009E00',
    borderRadius: '38px',
    border: '2px solid #009B07',
    '@media screen and (max-width:370px)': {
        width: '313px'
    }



}));
const PlanCardButton = styled(Button)({
    border: '2px solid transparant',
    color: '#009B07',
    fontWeight: 600,
    backgroundColor: '#FFFFFF',
    textTransform: 'none',
    borderRadius: '38px',
    padding: '0 65px 0 65px',
    '@media screen and (max-width : 1110px) and (min-width:769px)': {
        padding: '0 35px 0 35px',
    },
    '@media screen and (max-width: 624px)': {
        padding: '0 35px 0 35px',
    },
    '@media screen and (max-width: 500px)': {
        padding: '0 25px 0 25px'
    }
});

const HistoryCardButton = styled(Button)({
    border: '2px solid traqnsparant',
    color: '#009B07',
    fontWeight: 600,
    backgroundColor: '#FFFFFF',
    textTransform: 'none',
    borderRadius: '38px',
    height: '100%',
    width: '200px',
    padding: '0 50px 0 50px',
    '@media screen and (max-width: 1110px) and (min-width:768px)': {
        padding: '0 30px 0 30px',

    },
    '@media screen and (max-width:624px)': {
        padding: '0 30px 0 30px',
    },
    '@media screen and "(max-width:500px)': {
        padding: '0 20px 0 20px'
    }
});

const SectionItem = styled(Box)({
    margin: '8px 0px',
    '& .MuiTypography-root.MuiTypography-subtitle1': {
        fontWeight: 'bold',
        fontSize: 11,
    },
    '& .MuiTypography-root.MuiTypography-subtitle2': {
        fontWeight: 'normal',
        color: 'grey',
        fontSize: 14
    }
});


const SectionText = styled(Box)({
    margin: "4px 0px",
    color: '#464646',
    '& .MuiTypography-root.MuiTypography-subtitle1': {
        fontWeight: "bold",
        fontSize: 14,
    },
    '& .MuiTypography-root.MuiTypography-subtitle2': {
        fontWeight: "normal",
        color: "grey",
        fontSize: 14,
    }
})

const NormalText = styled('span')({
    fontWeight: "normal",
    color: "#685b5b",
    fontSize: "15px",
    fontweight: "bold"
})

const BoldText = styled('span')({
    fontWeight: "bold",
    color: "#685b5b"
})

const UserAvtarDetail = styled('div')({
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
})

const DetailSection = styled('div')({
    display: "flex",
    justifyContent: "space-between"
})

const CardSection = styled('div')({
    padding: "15px",
    width: "300px"
})

// Customizable Area End
import React from 'react'
import { Formik } from 'formik'
import { Wrapper, Modal, CloseButton, ModalBody, MInput, InputRow } from './FormModal.web'
import { styled } from '@material-ui/styles'
import { 
  Avatar, 
  Button, 
  Checkbox, 
  CircularProgress,
  Divider, 
  FormControl, 
  Input,
  InputAdornment, 
  InputLabel, 
  ListItemText, 
  MenuItem, 
  Paper, 
  Select,
  SelectProps, 
  TextField as TextFieldBase,
  TextFieldProps, 
  Typography } from '@material-ui/core';
const CloseIcon = require('./ic_close.png')

export default function DeleteModal({ onClose, onSubmit, label}: any){
	return <Wrapper>
		<SmallModal>
        <Typography align="center" variant="h5">
          { label ? label : 'Delete this post?' } 
        </Typography>
      	<Actions>
      		<CancelButton data-test-id='closeDeleteModal' onClick={onClose}>
      			Cancel
      		</CancelButton>
      		<SubmitButton data-test-id='deleteData' onClick={onSubmit}>
      			Delete
      		</SubmitButton>
      	</Actions>
		</SmallModal>
	</Wrapper>
} 

const Actions = styled('div')({
	display: 'flex',
	justifyContent: 'center',
	gap: '10px'
})

const SmallModal = styled(Modal)({
	height: 'max-content',
	overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: '20px',
  top: 'calc( 50vh - 190px )',
  marginRight: '40px',
  paddingLeft: '10px'
})

const CancelButton = styled(Button)({
  color: '#FFF',
  background: '#D0D0D0',
  padding: '5px 10px',
  margin: '5px',
  '&:hover': {
    background: '#999',
    opacity: 0.9
  }
})

const SubmitButton = styled(Button)({
  color: '#FFF',
  background: '#019B07',
  padding: '5px 10px',
  margin: '5px',
  '&:hover': {
    background: '#999',
    opacity: 0.9
  }
})
import React, { Component } from "react";
// Customizable Area Start
import {
    Box, Grid, Typography, Divider,
    Button, TextField,Modal
} from '@material-ui/core';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import { UserSurveyDetailModel } from "../../blocks/Surveys/src/UserSurveyDetailModel.web";
import { SeekingFundDetailModel } from "../../blocks/Surveys/src/SeekingFundDetailModel.web";
import ChatMenu from './ChatMenu.web';

import { Link } from "react-router-dom";

import DashboardController, { Props } from "../../blocks/dashboard/src/DashboardController";
import { styled } from '@material-ui/styles';
// @ts-ignore
import classes from './Share.module.css';

const rightMenuSurvey = require('./RightMenuSurvey.png')

// Customizable Area End

const SelectItemUser = ({ item, value, onBackClick }: { item: any, value: any, onBackClick: any }) => {
    return <>
        <Grid container onClick={() => onBackClick(item)}>
            <Grid item xs={12} style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <div className={`${classes.listLeftContainer}`}>
                    <SelectItemUserText >{`${item.first_name} ${item.last_name}`}</SelectItemUserText>
                    <Avatar className={`${classes.listAvatar}`} src={(item?.profile_pic)} aria-label="profile-pic">
                    </Avatar>
                </div>
            </Grid>
        </Grid>
    </>
}

const Survey = ({ detail, onModelClick }: any) => {
    return <>
        <SectionItem onClick={() => { onModelClick(detail) }}>
            <Card style={{ borderRadius: "10px", border: "1px solid #f2f2f2" }}>
                <SpacingSection>
                    <SectionItem style={{ fontSize: "14px" }}>
                        {detail?.attributes?.survey_title?.length > 15 ? (
                            <Typography style={{ fontSize: "18px" }} variant="subtitle1" component="div">
                                {detail?.attributes?.survey_title?.slice(0, 10)}
                                <NormalText>&nbsp;see more...</NormalText>
                            </Typography>
                        ) : (

                            <Typography style={{ fontSize: "16px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} variant="subtitle1" component="div">{detail?.attributes?.survey_title}</Typography>
                        )
                        }

                    </SectionItem>
                    <DetailSection style={{ fontWeight: 'normal' }}>
                        <SectionText>
                            <Typography variant="subtitle1" component="div">Start date</Typography>
                            <Typography variant="subtitle2" component="div">{detail?.attributes?.start_date}</Typography>
                        </SectionText>
                        <SectionText>
                            <Typography variant="subtitle1" component="div">End date</Typography>
                            <Typography variant="subtitle2" component="div">{detail?.attributes?.end_date}</Typography>
                        </SectionText>
                    </DetailSection>
                    <SectionText>
                        <Typography variant="subtitle1" component="div">Description</Typography>
                        {detail?.attributes?.description?.length > 60 ? (<Typography variant="subtitle2" component="div">
                            {detail?.attributes?.description?.slice(0, 60)}<span style={{ fontWeight: "bold", color: "#868686" }}>&nbsp;see more...</span>
                        </Typography>) : (<Typography variant="subtitle2" component="div">
                            {detail?.attributes?.description}
                        </Typography>)}
                    </SectionText>
                    <DetailSection>
                        <SectionText>
                            <Typography variant="subtitle1" component="div">Created by</Typography>
                            <AvatarSection>
                                <Avatar style={{ marginRight: "5px", height: "25px", width: "25px", border: "3px solid rgb(242, 212, 212)" }} src={detail?.attributes?.created_by?.profile_pic} alt={detail?.attributes?.created_by?.first_name} />
                                <Typography variant="subtitle2" component="div">{`${(detail?.attributes?.created_by?.first_name) ? detail?.attributes?.created_by?.first_name : ""} ${(detail?.attributes?.created_by?.last_name) ? detail?.attributes?.created_by?.last_name : ""}`}</Typography>
                            </AvatarSection>
                        </SectionText>
                        <SectionText>
                            <Typography variant="subtitle1" component="div">Requested by</Typography>
                            <AvatarSection>
                                <Avatar style={{ marginRight: "5px", height: "25px", width: "25px", border: "3px solid rgb(242, 212, 212)" }} src={detail?.attributes?.requested_by?.profile_pic} alt={detail?.attributes?.requested_by?.first_name} />
                                <Typography variant="subtitle2" component="div">{`${(detail?.attributes?.requested_by?.first_name) ? detail?.attributes?.requested_by?.first_name : ""} ${(detail?.attributes?.requested_by?.last_name) ? detail?.attributes?.requested_by?.last_name : ""}`}</Typography>
                            </AvatarSection>
                        </SectionText>
                    </DetailSection>
                </SpacingSection>
            </Card>
        </SectionItem>
    </>
}

const FundCard = ({ detail, onModelClick }: any) => {
    return <>
        <SectionItem onClick={() => { onModelClick(detail) }}>
            <Card style={{ borderRadius: "10px", border: "1px solid #f2f2f2" }}>
                <SpacingSection>
                    <SectionItem style={{ fontSize: "14px" }}>
                        <FundHeaderBox>
                            <>
                                {detail?.attributes?.project_name?.length > 10 ? (
                                    <Typography style={{ fontSize: "18px" }} variant="subtitle1" component="div">
                                        {detail?.attributes?.project_name?.slice(0, 4)}
                                        <NormalText>&nbsp;see more...</NormalText>
                                    </Typography>
                                ) : (

                                    <Typography style={{ fontSize: "16px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} variant="subtitle1" component="div">{detail?.attributes?.project_name}</Typography>
                                )
                                }
                                {detail?.attributes?.project_status !== 'inactive' ?
                                    <LiveBtn>{detail?.attributes?.mandatory ? "Mandatory" : detail?.attributes?.project_status}</LiveBtn>
                                    :
                                    <InactiveBtn>{detail?.attributes?.project_status}</InactiveBtn>
                                }
                            </>
                        </FundHeaderBox>
                    </SectionItem>
                    <DetailSection style={{ fontWeight: 'normal' }}>
                        <FundSectionText>
                            <Typography variant="subtitle1" component="div">Project ID</Typography>
                            <Typography variant="subtitle2" component="div">{detail?.attributes?.project_unique_id}</Typography>
                        </FundSectionText>
                        <FundSectionText>
                            <Typography variant="subtitle1" component="div">Location</Typography>
                            <Typography variant="subtitle2" component="div">{detail?.attributes?.location}</Typography>
                        </FundSectionText>
                    </DetailSection>
                    <DetailSection style={{ fontWeight: 'normal' }}>
                        <FundSectionText>
                            <Typography variant="subtitle1" component="div">SDG Focus</Typography>
                            <AvatarGroup spacing='small'>
                                {detail?.attributes?.images?.map((sdgImageURL: any, index: any) => <Avatar style={{ marginRight: "5px", height: "25px", width: "25px", border: "3px solid rgb(255, 255, 255)",zIndex:0 }} src={sdgImageURL.length > 0 && sdgImageURL[0]?.image_url} alt={detail?.attributes?.goals_sdg[index]} />)}
                            </AvatarGroup>
                        </FundSectionText>
                        <FundSectionText>
                            <Typography variant="subtitle1" component="div">Preferences</Typography>
                            <Typography variant="subtitle2" component="div">{detail?.attributes?.preferences?.join("\n")}</Typography>
                        </FundSectionText>
                    </DetailSection>
                    <DetailSection style={{ fontWeight: 'normal' }}>
                        <FundSectionText>
                            <Typography variant="subtitle1" component="div">Funding Required</Typography>
                            <Typography variant="subtitle2" component="div">${detail?.attributes?.funding_amount}</Typography>
                        </FundSectionText>
                        <FundSectionText>
                            <Typography variant="subtitle1" component="div">Company Name</Typography>
                            <Typography variant="subtitle2" component="div">{detail?.attributes?.organization_name}</Typography>
                        </FundSectionText>
                    </DetailSection>
                </SpacingSection>
            </Card>
        </SectionItem>
    </>
}

export default class RightMenuWeb extends DashboardController {

    constructor(props: Props) {
        super(props);
    }

    handleAddMessage = (e: any) => {
        this.setState({
            userReply: e.target.value, openCommentReplyEmoji: false
        });
    }

    handleAddUSer = () => {
        this.setState({ isAddMessageList: true, openCommentReplyEmoji: false });
    }

    changeViewChat = () => {
        this.setState({ viewChat: !this.state.viewChat });
    }

    openEmojiCommentReply = () => {
        let stateEmogi = !this.state.openCommentReplyEmoji;
        this.setState({ openCommentReplyEmoji: stateEmogi });
    }

    formatAttachmentName = (name: string) => {
        var matches = name.match(/\b(\w)/g);
        if (matches) {
            var acronym = matches.join('');
            return (acronym.toUpperCase());
        } else {
            return name
        }
    }

    render() {

        let projectSeekingCards = 0;
        let surveyCards = 0;      
            
        const surveyDataLength = !this.state.surveyData || !Array.isArray(this.state.surveyData) ? 0 : this.state.surveyData.length
        const surveyProjectDataLength =!this.state.surveyProjectData || !Array.isArray(this.state.surveyProjectData) ? 0 : this.state.surveyProjectData.length
        
        if (surveyDataLength >= 2 && surveyProjectDataLength >= 2) {
            projectSeekingCards = 2;
            surveyCards = 2;
        } else {
            if (surveyDataLength < 2) {
                surveyCards = surveyDataLength;
                projectSeekingCards = 4 - surveyDataLength;
            } else {
                projectSeekingCards = surveyProjectDataLength;
                surveyCards = 4 - surveyProjectDataLength;
            }
        }
        
        const showLinkSection = (surveyDataLength + surveyProjectDataLength > 3);
        return (
            <Grid container spacing={3}>
                <TopGrid item xs={12} style={{marginBottom:"70px"}}>
                    <Section>
                        <NewsSection>
                            <Heading>
                                <DivSection>
                                    <SurveyImg src={rightMenuSurvey} alt="Survey" />
                                </DivSection>
                                <Typography variant="subtitle1" component="div">
                                    Project seeking funds/ Survey
                                </Typography>
                            </Heading>
                            <Divider style={{ marginBottom: "10px" }} />
                            {this.state?.surveyProjectData && this.state.surveyProjectData.length > 0 && this.state.surveyProjectData.slice(0, projectSeekingCards).map((surveyProjectDetail: any) => {
                                return (
                                    <>
                                        <FundCard navigation={this.props.navigation} detail={surveyProjectDetail} onModelClick={(detail: any) => {
                                            this.setState({ ...this.state, openUserSurveyDetailModel: false, openUserProjectSurveyDetailModel: true, UserSurveyDataModel: detail })
                                        }} />
                                    </>
                                )
                            })}
                            {this.state?.surveyData && this.state.surveyData.length > 0 && this.state.surveyData.slice(0, surveyCards).map((surveyDetail: any) => {
                                return (
                                    <>
                                        <Survey navigation={this.props.navigation} detail={surveyDetail} onModelClick={(detail: any) => {
                                            this.setState({ ...this.state, openUserSurveyDetailModel: true, openUserProjectSurveyDetailModel: false, UserSurveyDataModel: detail })
                                        }} />
                                    </>
                                )
                            })}
                            {showLinkSection ?
                                <LinkSection>
                                    <Link to="/UserSurvey" style={{ display: "flex", justifyContent: "end", alignItems: "center", color: "#244b87" }}><span>See All</span> <KeyboardArrowRightIcon /></Link>
                                </LinkSection> : <></>
                            }
                        </NewsSection>
                    </Section>
                </TopGrid>
                <Modal
                    id="ModalComponent"
                    open={this.state.openUserSurveyDetailModel || this.state.openUserProjectSurveyDetailModel}
                    onClose={() => { this.setState({ ...this.state, openUserSurveyDetailModel: false, openUserProjectSurveyDetailModel: false }) }}
                    aria-labelledby="simple-model-title"
                    aria-describedby="simple-model-description"
                >
                    <>
                        {this.state.openUserSurveyDetailModel ?
                            <UserSurveyDetailModel navigation={this.props.navigation} detail={this.state.UserSurveyDataModel} onCloseClick={() => { this.setState({ ...this.state, openUserSurveyDetailModel: false, openUserProjectSurveyDetailModel: false }) }} />
                            :
                            <SeekingFundDetailModel navigation={this.props.navigation} detail={this.state.UserSurveyDataModel} handleLikeChange={this.handleLikeChange} onCloseClick={() => { this.setState({ ...this.state, openUserSurveyDetailModel: false, openUserProjectSurveyDetailModel: false }) }} />
                        }
                    </>
                </Modal>
                {/* <Grid container spacing={3}> */}
                {/* <TopGrid item xs={12}>
                    <Section>
                        <NewsSection>
                            <Heading>
                                <FolderOutlinedIcon fontSize="medium" />
                                <Typography variant="subtitle1" component="div">
                                    Top news/ Blogs
                                </Typography>
                            </Heading>
                            <TopSection>
                                <div>
                                    <img src={imgPasswordInVisible} width="80px" height="80px" style={{ borderRadius: "10px", border: "1px solid grey" }} />
                                </div>
                                <div>
                                    <Typography variant="subtitle1" component="div">
                                        CRA News
                                    </Typography>
                                    <Typography variant="subtitle2"component="div">
                                        Europe's Climate masterplan aims to slashemissions within a decade
                                    </Typography>
                                </div>
                            </TopSection>
                            <Divider />
                            <SectionItem>

                                <Typography variant="subtitle1" component="div">
                                    ESG Investing news
                                </Typography>
                                <Typography variant="subtitle2" component="div">
                                    ESG investing news funds weigh sovereign debt profits against human rights.
                                </Typography>

                            </SectionItem>
                            <Divider />
                            <SectionItem>
                                <Typography variant="subtitle1" component="div">
                                    What are Green Bonds?
                                </Typography>
                                <Typography variant="subtitle2" component="div">
                                    How to consider whether green bonds have a place in your investment portfolio.
                                </Typography>
                            </SectionItem>
                            <Divider />
                            <SectionItem>

                                <Typography variant="subtitle1" component="div">
                                    ESG Investing news
                                </Typography>
                                <Typography variant="subtitle2" component="div">
                                    ESG investing news funds weigh sovereign debt profits against human rights.
                                </Typography>

                            </SectionItem>
                            <Divider />
                            <SectionItem style={{ margin: "15px 0px" }}>
                                <Typography variant="subtitle1" component="div">
                                    What are Green Bonds?
                                </Typography>
                                <Typography variant="subtitle2" component="div">
                                    How to consider whether green bonds have a place in your investment portfolio.
                                </Typography>
                            </SectionItem>
                        </NewsSection>
                    </Section>
                </TopGrid> */}
                <Grid item xs={12}>
                    {/* <LowerSection>
                        <LowerSectionItem>

                            <div style={{ margin: "10px", display: "flex", color: "grey", justifyContent: "space-around", alignItems: "center" }}>
                                <Link color="inherit" to="/" style={{ display: "flex", textDecoration: "none" }}>
                                    <FiberManualRecordIcon style={{
                                        fontSize: 12,
                                        marginRight: 5,
                                        marginTop: "2px",
                                        color: "#696868"
                                    }} />
                                    About
                                </Link>
                                <Link color="inherit" to="/" style={{ display: "flex", textDecoration: "none" }}>
                                    <FiberManualRecordIcon style={{
                                        fontSize: 12,
                                        marginRight: 5,
                                        marginTop: "2px",
                                        color: "#696868"
                                    }} />
                                    Privacy
                                </Link>
                                <Link color="inherit" to="/" style={{ display: "flex", textDecoration: "none" }}>
                                    <FiberManualRecordIcon style={{
                                        fontSize: 12,
                                        marginRight: 5,
                                        marginTop: "2px",
                                        color: "#696868"
                                    }} />
                                    Terms
                                </Link>
                            </div>
                            <div style={{ margin: "15px 40px", display: "flex", color: "grey", justifyContent: "space-around", alignItems: "center" }}>
                                <Link color="inherit" to="/" style={{ display: "flex", textDecoration: "none" }}>
                                    <FiberManualRecordIcon style={{
                                        fontSize: 12,
                                        marginRight: 5,
                                        marginTop: "2px",
                                        color: "#696868"
                                    }} />
                                    Feedback
                                </Link>
                                <Link color="inherit" to="/" style={{ display: "flex", textDecoration: "none" }}>
                                    <FiberManualRecordIcon style={{
                                        fontSize: 12,
                                        marginRight: 5,
                                        marginTop: "2px",
                                        color: "#696868"
                                    }} />
                                    Contact Us
                                </Link>
                            </div>

                            <Divider style={{ margin: "10px 50px", height: "2px", backgroundColor: "#8c8b8b" }} />

                            <div style={{ margin: "15px 40px", display: "flex", color: "grey", justifyContent: "center", alignItems: "center" }}>
                                <Link color="inherit" to="/" style={{ display: "flex" }}>
                                    <img src={imgPasswordInVisible} style={{ borderRadius: "10px", }} />
                                </Link>
                                <Link color="inherit" to="/" style={{ display: "flex" }}>
                                    <img src={imgPasswordInVisible} style={{ borderRadius: "10px", }} />
                                </Link>
                                <Link color="inherit" to="/" style={{ display: "flex" }}>
                                    <img src={imgPasswordInVisible} style={{ borderRadius: "10px", }} />
                                </Link>
                            </div>

                            <div style={{ position: "relative" }}>
                                <Avatar style={{
                                    width: "70px",
                                    height: "70px",
                                    position: "absolute",
                                    top: "80%",
                                    left: "43%",
                                    backgroundColor: "#edebeb"
                                }} >
                                    <img src={imgPasswordInVisible} style={{ borderRadius: "10px", }} />
                                </Avatar>
                            </div>


                        </LowerSectionItem>

                    </LowerSection > */}
                    <ChatMenu navigation={this.props.navigation} id={""}/>
                    
                </Grid>
            {/* </Grid > */}
            </Grid>
        )
        // Customizable Area Start
        // <Grid container spacing={3}>
        //     <TopGrid item xs={12}>
        //         <Section>
        //             <NewsSection>
        //                 <Heading>
        //                     <FolderOutlinedIcon fontSize="medium" />DashboardController
        //                     <Typography variant="subtitle1" component="div">
        //                         Top news/ Blogs
        //                     </Typography>
        //                 </Heading>
        //                 <TopSection>
        //                     <div>
        //                         <img src={imgPasswordInVisible} width="80px" height="80px" style={{ borderRadius: "10px", border: "1px solid grey" }} />
        //                     </div>
        //                     <div>
        //                         <Typography variant="subtitle1" component="div">
        //                             CRA News
        //                         </Typography>
        //                         <Typography variant="subtitle2"component="div">
        //                             Europe's Climate masterplan aims to slashemissions within a decade
        //                         </Typography>
        //                     </div>
        //                 </TopSection>
        //                 <Divider />
        //                 <SectionItem>

        //                     <Typography variant="subtitle1" component="div">
        //                         ESG Investing news
        //                     </Typography>
        //                     <Typography variant="subtitle2" component="div">
        //                         ESG investing news funds weigh sovereign debt profits against human rights.
        //                     </Typography>

        //                 </SectionItem>
        //                 <Divider />
        //                 <SectionItem>
        //                     <Typography variant="subtitle1" component="div">
        //                         What are Green Bonds?
        //                     </Typography>
        //                     <Typography variant="subtitle2" component="div">
        //                         How to consider whether green bonds have a place in your investment portfolio.
        //                     </Typography>
        //                 </SectionItem>
        //                 <Divider />
        //                 <SectionItem>

        //                     <Typography variant="subtitle1" component="div">
        //                         ESG Investing news
        //                     </Typography>
        //                     <Typography variant="subtitle2" component="div">
        //                         ESG investing news funds weigh sovereign debt profits against human rights.
        //                     </Typography>

        //                 </SectionItem>
        //                 <Divider />
        //                 <SectionItem style={{ margin: "15px 0px" }}>
        //                     <Typography variant="subtitle1" component="div">
        //                         What are Green Bonds?
        //                     </Typography>
        //                     <Typography variant="subtitle2" component="div">
        //                         How to consider whether green bonds have a place in your investment portfolio.
        //                     </Typography>
        //                 </SectionItem>
        //             </NewsSection>
        //         </Section>
        //     </TopGrid>
        //     <Grid item xs={12}>
        //         <LowerSection>
        //             <LowerSectionItem>

        //                 <div style={{ margin: "10px", display: "flex", color: "grey", justifyContent: "space-around", alignItems: "center" }}>
        //                     <Link color="inherit" href="/" style={{ display: "flex", textDecoration: "none" }}>
        //                         <FiberManualRecordIcon style={{
        //                             fontSize: 12,
        //                             marginRight: 5,
        //                             marginTop: "2px",
        //                             color: "#696868"
        //                         }} />
        //                         About
        //                     </Link>
        //                     <Link color="inherit" href="/" style={{ display: "flex", textDecoration: "none" }}>
        //                         <FiberManualRecordIcon style={{
        //                             fontSize: 12,
        //                             marginRight: 5,
        //                             marginTop: "2px",
        //                             color: "#696868"
        //                         }} />
        //                         Privacy
        //                     </Link>
        //                     <Link color="inherit" href="/" style={{ display: "flex", textDecoration: "none" }}>
        //                         <FiberManualRecordIcon style={{
        //                             fontSize: 12,
        //                             marginRight: 5,
        //                             marginTop: "2px",
        //                             color: "#696868"
        //                         }} />
        //                         Terms
        //                     </Link>
        //                 </div>
        //                 <div style={{ margin: "15px 40px", display: "flex", color: "grey", justifyContent: "space-around", alignItems: "center" }}>
        //                     <Link color="inherit" href="/" style={{ display: "flex", textDecoration: "none" }}>
        //                         <FiberManualRecordIcon style={{
        //                             fontSize: 12,
        //                             marginRight: 5,
        //                             marginTop: "2px",
        //                             color: "#696868"
        //                         }} />
        //                         Feedback
        //                     </Link>
        //                     <Link color="inherit" href="/" style={{ display: "flex", textDecoration: "none" }}>
        //                         <FiberManualRecordIcon style={{
        //                             fontSize: 12,
        //                             marginRight: 5,
        //                             marginTop: "2px",
        //                             color: "#696868"
        //                         }} />
        //                         Contact Us
        //                     </Link>
        //                 </div>

        //                 <Divider style={{ margin: "10px 50px", height: "2px", backgroundColor: "#8c8b8b" }} />

        //                 <div style={{ margin: "15px 40px", display: "flex", color: "grey", justifyContent: "center", alignItems: "center" }}>
        //                     <Link color="inherit" href="/" style={{ display: "flex" }}>
        //                         <img src={imgPasswordInVisible} style={{ borderRadius: "10px", }} />
        //                     </Link>
        //                     <Link color="inherit" href="/" style={{ display: "flex" }}>
        //                         <img src={imgPasswordInVisible} style={{ borderRadius: "10px", }} />
        //                     </Link>
        //                     <Link color="inherit" href="/" style={{ display: "flex" }}>
        //                         <img src={imgPasswordInVisible} style={{ borderRadius: "10px", }} />
        //                     </Link>
        //                 </div>

        //                 <div style={{ position: "relative" }}>
        //                     <Avatar style={{
        //                         width: "70px",
        //                         height: "70px",
        //                         position: "absolute",
        //                         top: "80%",
        //                         left: "43%",
        //                         backgroundColor: "#edebeb"
        //                     }} >
        //                         <img src={imgPasswordInVisible} style={{ borderRadius: "10px", }} />
        //                     </Avatar>
        //                 </div>


        //             </LowerSectionItem>

        //         </LowerSection >

        //         {(this.props.showChatMobileView || window.screen.width > 768) &&
        //             <div style={{ marginTop: this.props.showChatMobileView ? "50px" : "70px" }}>{
        //                 this.state.IsunbletoLoad ?
        //                     <Box style={{
        //                         backgroundColor: "white",
        //                         borderRadius: "20px",
        //                         height: this.props.showChatMobileView ? 'auto' : '500px',
        //                     }} >
        //                         <div style={{
        //                             display: "flex",
        //                             padding: "20px 10px",
        //                             flexDirection: "column"
        //                         }}>

        //                             <Typography
        //                                 align="center"
        //                                 style={{ fontWeight: "bold", marginTop: "200px" }}
        //                             >
        //                                 Unble to load chat
        //                             </Typography>
        //                         </div>

        //                     </Box>
        //                     :
        //                     this.state.isAddMessageList ?
        //                         <Box style={{
        //                             backgroundColor: "white",
        //                             borderRadius: "20px",
        //                             height: this.props.showChatMobileView ? 'auto' : '500px',
        //                         }} >
        //                             <div style={{
        //                                 display: "flex",
        //                                 padding: "20px 10px",
        //                                 flexDirection: "column"
        //                             }}>
        //                                 <div style={{ display: "block", marginBottom: '5px' }} >
        //                                     <div style={{ display: "flex" }} >
        //                                         <Typography
        //                                             style={{ fontWeight: "bold", marginLeft: "10px", marginTop: "8px" }}
        //                                         >
        //                                             New Message
        //                                         </Typography>
        //                                     </div>
        //                                     <IconButton style={{ float: 'right', margin: '-35px 0 0 0' }}
        //                                         onClick={() => this.handleClose()}>
        //                                         <CloseIcon />
        //                                     </IconButton>
        //                                 </div>
        //                                 <Divider />
        //                                 <div style={{ margin: '10px 0 0 0' }}>
        //                                     <Formik initialValues={this.initialValues} onSubmit={() => { console.log("kjdfnvkjd") }}
        //                                     >

        //                                         <SearchSelect
        //                                             scrollHeight={'175px'}
        //                                             hasMore={!!this.state?.userPagination?.next_page}
        //                                             nextPage={this.paginateUsersOptions}
        //                                             loading={(!!this.state?.userSearchCallId) || (!!this.state?.userPaginateCallId)}
        //                                             onSearch={this.handleUserSearch}
        //                                             allItems={this.state?.users}
        //                                             items={this.state?.users}
        //                                             name="shared_to_ids"
        //                                             label={"Select Users"}
        //                                             placeholder="Select Users"
        //                                             SelectItem={SelectItemUser}
        //                                             getOptionValue={(user: any) => `${user?.first_name} ${user?.last_name}`}
        //                                             OnBackclick={this.handleAddUSerDetaisl}
        //                                         />
        //                                     </Formik>
        //                                 </div>
        //                                 <div style={{ height: '285px', overflow: 'auto' }}>
        //                                 </div>
        //                             </div>
        //                             <div style={{ margin: '0 10px' }}>

        //                                 <OutlinedInput
        //                                     id="outlined-adornment-weight"
        //                                     value={this.state.userReply}
        //                                     onChange={this.handleAddMessage}
        //                                     // onKeyPress={() => this.handleCourseComment()}
        //                                     placeholder="Write a message..."
        //                                     endAdornment={
        //                                         <InputAdornment position="end">
        //                                             <BtnImg src={EmogiIcon}
        //                                                 style={{ width: '18px', paddingLeft: '10px' }} />
        //                                             <BtnImg src={SendIcon}
        //                                                 style={{ width: '18px', paddingLeft: '25px', float: 'right', paddingRight: '2px', cursor: 'pointer' }} />
        //                                         </InputAdornment>}
        //                                     aria-describedby="outlined-weight-helper-text"
        //                                     inputProps={{
        //                                         'aria-label': 'weight',
        //                                     }}
        //                                     style={{ width: '100%' }}
        //                                 />
        //                             </div>
        //                         </Box>
        //                         :
        //                         this.state.isInduMessage ?
        //                             <Box style={{
        //                                 backgroundColor: "white",
        //                                 borderRadius: "20px",
        //                                 height: this.props.showChatMobileView && this.state.openCommentReplyEmoji ? 'auto' :
        //                                 (!this.state.openCommentReplyEmoji && this.props.showChatMobileView) ? '505px' : 
        //                                 (this.state.openCommentReplyEmoji && this.state.viewChat) ? '820px' :
        //                                 '500px',
        //                             }} >
        //                                 <div style={{
        //                                     display: "flex",
        //                                     padding: "20px 10px",
        //                                     flexDirection: "column"
        //                                 }}>
        //                                     <div style={{ display: "block", marginBottom: '5px' }} >
        //                                         <div style={{ display: "flex" }} >
        //                                             <Avatar>{this.formatAttachmentName(this.state.MessageUSerID.name)}</Avatar>
        //                                             <Typography
        //                                                 style={{ fontWeight: "bold", marginLeft: "10px", marginTop: "8px" }}
        //                                             >
        //                                                 {this.state.MessageUSerID.name}
        //                                             </Typography>
        //                                         </div>

        //                                         <IconButton style={{ float: 'right', margin: '-45px 0 0 0' }}
        //                                             onClick={() => this.handleClose()}>
        //                                             <CloseIcon />
        //                                         </IconButton>
        //                                     </div>
        //                                     <Divider />
        //                                     <div id="chat-content" style={{ height: '345px', overflow: 'auto' }}>
        //                                         {this.state.UserMessageList.map((list: any, index: any) => (
        //                                             <div style={{ margin: '10px 0px', display: "flex" }} id={index == this.state.UserMessageList.length - 1 ? `chat-scroll` : ""}>
        //                                                 <Avatar style={{ width: '28px', height: '28px', margin: '0 5px' }}>
        //                                                     {list.sender_id == this.state?.QBuserDetails.id ?
        //                                                         this.formatAttachmentName(this.state?.QBuserDetails.full_name) 
        //                                                         :
        //                                                         this.formatAttachmentName(this.state.MessageUSerID.name)
        //                                                     }</Avatar>
        //                                                 <Typography
        //                                                     variant="subtitle2"
        //                                                     component="div"
        //                                                     style={{ fontSize: 12 }}
        //                                                 >
        //                                                     <div style={{ display: 'flex' }}>

        //                                                         <div style={{ fontWeight: "bold", margin: '0px 5px' }} >
        //                                                             {list.sender_id == this.state?.QBuserDetails.id ?
        //                                                                 this.state?.QBuserDetails.full_name :
        //                                                                 this.state.MessageUSerID.name}
        //                                                         </div>
        //                                                         <div style={{ fontSize: '10px', marginTop: '2px' }}>{Moment(list.created_at).format('MMM DD hh:mm')}</div>
        //                                                     </div>
        //                                                     {/* <br /> */}
        //                                                     <div style={{ margin: '0px 5px', fontSize: '11px' }} >
        //                                                         {list.message}</div>
        //                                                 </Typography>


        //                                             </div>

        //                                         ))}
        //                                         {/* <div id="chat-scroll"></div> */}

        //                                     </div>
        //                                 </div>
        //                                 <div style={{ margin: '0 10px' }}
        //                                 >
        //                                     <OutlinedInput
        //                                         id="outlined-adornment-weight"
        //                                         value={this.state.userReply}
        //                                         onChange={this.handleAddMessage}
        //                                         placeholder="Write a message..."
        //                                         endAdornment={
        //                                             <InputAdornment position="end">
        //                                                 <BtnImg src={EmogiIcon}
        //                                                     onClick={() => this.openEmojiCommentReply()}
        //                                                     style={{
        //                                                         width: '18px',
        //                                                         paddingLeft: '10px'
        //                                                     }} />
        //                                                 <BtnImg src={SendIcon}
        //                                                     onClick={() => this.handleCourseComment()}
        //                                                     style={{
        //                                                         width: '18px',
        //                                                         paddingLeft: '25px',
        //                                                         float: 'right',
        //                                                         paddingRight: '2px',
        //                                                         cursor: 'pointer'
        //                                                     }} />
        //                                             </InputAdornment>}
        //                                         aria-describedby="outlined-weight-helper-text"
        //                                         inputProps={{
        //                                             'aria-label': 'weight',
        //                                         }}
        //                                         style={{ width: '100%' }}
        //                                     />
        //                                 </div>
        //                                 {this.state.openCommentReplyEmoji ?
        //                                     <Picker onEmojiClick={this.onCommentEmojiClick} pickerStyle={{ width: '100%' }} /> : ""}


        //                             </Box>
        //                             :
        //                             <Box style={{
        //                                 backgroundColor: "white",
        //                                 borderRadius: "20px",
        //                                 height: this.props.showChatMobileView ? 'auto' :
        //                                     this.state.viewChat ? '500px' : '80px',
        //                                 overflow: 'auto'
        //                             }} >
        //                                 <div style={{
        //                                     display: "flex",
        //                                     padding: "20px 10px",
        //                                     flexDirection: "column"
        //                                 }}>

        //                                     <div style={{ display: "block", marginBottom: '5px' }} >
        //                                         <div style={{ display: "flex" }} >
        //                                             <Avatar src={profile_pic} style={{ marginLeft: '5px' }} />
        //                                             <Typography
        //                                                 style={{ fontWeight: "bold", marginLeft: "10px", marginTop: "8px" }}
        //                                             >
        //                                                 Chat
        //                                             </Typography>
        //                                             <Avatar style={{
        //                                                 width: '24px',
        //                                                 height: '24px',
        //                                                 margin: '5px 0 0 10px',
        //                                                 backgroundColor: '#f44336', fontSize: '12px', fontWeight: 'bold'
        //                                                 }}
        //                                             >
        //                                                 {this.state.totalUnreadMessageCount}
        //                                             </Avatar>
        //                                         </div>
        //                                         <div style={{ float: 'right' }}>
        //                                             {this.state.viewChat &&
        //                                                 <IconButton style={{ margin: '-45px 0 0 0' }}
        //                                                     onClick={() => this.handleAddUSer()}>
        //                                                     <PersonAddIcon />
        //                                                 </IconButton>
        //                                             }
        //                                             {this.state.viewChat ? 
        //                                                 <IconButton style={{ margin: '-45px 0 0 0' }}
        //                                                     onClick={() => this.changeViewChat()}>
        //                                                     <KeyboardArrowUpIcon />
        //                                                 </IconButton>
        //                                                 :
        //                                                 <IconButton style={{ margin: '-45px 0 0 0' }}
        //                                                     onClick={() => this.changeViewChat()}>
        //                                                     <KeyboardArrowDownIcon />
        //                                                 </IconButton>
        //                                             }
        //                                         </div>
        //                                     </div>
        //                                     {this.state.viewChat &&
        //                                     <>
        //                                     <Divider />
        //                                     <div style={{ margin: '15px 0 0 0' }}>
        //                                         <SearchFilter onChange={(e) => this.onListSerach(e)} />
        //                                     </div>
        //                                     <div>
        //                                         {this.state?.MessagesList.map((list: any, index: any) => (
        //                                             <div onClick={() => this.handleMesage(list)} style={{ cursor: "pointer" }}>
        //                                                 <div style={{
        //                                                     margin: "15px 0px", display: "flex"
        //                                                 }}>
        //                                                     <Avatar>{this.formatAttachmentName(list.name)}</Avatar>
        //                                                     <Typography
        //                                                         variant="subtitle2"
        //                                                         component="div"
        //                                                         style={{ fontSize: 12, margin: '2px 8px', width : '130px' }}
        //                                                     >
        //                                                         <span style={{ fontWeight: "bold" }} >{list.name}</span><br />
        //                                                         <span>{list.last_message}</span>
        //                                                     </Typography>
        //                                                 </div>
        //                                                 {list.unread_messages_count > 0 ?
        //                                                 <Avatar style={{
        //                                                             width: '24px',
        //                                                             float: 'right',
        //                                                             height: '24px', margin: '-45px 10px 0px',
        //                                                             backgroundColor: '#1ea60a', fontSize: '12px', fontWeight: 'bold'
        //                                                         }}>{list.unread_messages_count > 0 ? list.unread_messages_count : 0}</Avatar>
        //                                                         : null}
        //                                                 {this.state?.MessagesList.length - 1 == index ? null :
        //                                                     <Divider />
        //                                                 }
        //                                             </div>
        //                                         ))}
        //                                     </div>
        //                                     </>
        //                                     }
        //                                 </div>
        //                             </Box>

        //             }

        //             </div>
        //         }
        //     </Grid>
        // </Grid >
        // Customizable Area End

    }


}
// Customizable Area Start

const TopGrid = styled(Grid)({
    '@media screen and (max-width: 768px)': {
        display: 'none',
        visibility: 'hidden',
    }
})

const SpacingSection = styled('div')({
    padding: "10px"
})

const NormalText = styled('span')({
    fontWeight: "normal", color: "#868686", fontSize: '14px'
})

const LinkSection = styled('div')({
    marginTop: "10px"
})

const DetailSection = styled('div')({
    display: "flex",
    justifyContent: "space-between"
})

const SurveyImg = styled('img')({
    height: "24px",
    width: "24px"
})

const FundHeaderBox = styled('div')({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
})

const Section = styled(Box)({
    backgroundColor: "white",
    borderRadius: "20px",
})

const NewsSection = styled(Box)({
    display: "flex",
    margin: "30px 10px 0px 10px",
    padding: "20px 10px",
    flexDirection: "column"
})

const ellipsisLines = styled(Box)({
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitLineOrient: "vertical"
})

const AvatarSection = styled('div')({
    display: "flex", flexDirection: "row", alignItems: "center"
})

const Heading = styled(Box)({
    display: "flex",
    '& .MuiTypography-root': {
        fontWeight: "bold", marginLeft: "10px",
    }
})

const TopSection = styled(Box)({
    display: "flex",
    margin: "10px 0px",
    '& .MuiTypography-root.MuiTypography-subtitle1': {
        fontWeight: "normal",
        marginLeft: "10px",
        color: "grey",
    },
    '& .MuiTypography-root.MuiTypography-subtitle2': {
        fontWeight: "bold",
        marginLeft: "10px",
        fontSize: 12,
    },
    '@media screen and (max-width: 985px)': {
        justifyContent: "center",
        flexWrap: "wrap",
        '& .MuiTypography-root.MuiTypography-subtitle1': {
            fontWeight: "bold",
            marginLeft: "10px",
            color: "grey",
            justifyContent: "center",
        }
    },
})

const SectionItem = styled(Box)({
    margin: "8px 0px",
    '& .MuiTypography-root.MuiTypography-subtitle1': {
        fontWeight: "bold",
        fontSize: 12,
    },
    '& .MuiTypography-root.MuiTypography-subtitle2': {
        fontWeight: "normal",
        color: "grey",
        fontSize: 12,
    }
})

const SectionText = styled(Box)({
    margin: "4px 0px",
    color: '#464646',

    '& .MuiTypography-root.MuiTypography-subtitle1': {
        fontWeight: "bold",
        fontSize: 12,
    },
    '& .MuiTypography-root.MuiTypography-subtitle2': {
        fontWeight: "normal",
        color: "grey",
        fontSize: 12,
    }
})

const FundSectionText = styled(Box)({
    margin: "4px 0px",
    color: '#464646',
    width: '50%',

    '& .MuiTypography-root.MuiTypography-subtitle1': {
        fontWeight: "bold",
        fontSize: 12,
    },
    '& .MuiTypography-root.MuiTypography-subtitle2': {
        fontWeight: "normal",
        color: "grey",
        fontSize: 12,
    }
})

const LiveBtn = styled(Typography)({
    background: '#ebf3fc',
    border: '1px solid #E5EDE6',
    borderRadius: '10px',
    color: '#2175E7',
    padding: '8px 25px',
    fontSize: '14px',
    width: 'max-content',
    fontWeight: 'bold',
    // opacity: 0.11,
    mixBlendMode: 'normal',
    textTransform: 'capitalize',
})

const InactiveBtn = styled(Typography)({
    background: '#E5E5E5',
    border: '1px solid #E5EDE6',
    borderRadius: '10px',
    color: '#787878',
    padding: '8px 25px',
    fontSize: '14px',
    width: 'max-content',
    fontWeight: 'bold',
    // opacity: 0.11,
    mixBlendMode: 'normal',
    textTransform: 'capitalize',

})

const LowerSection = styled(Box)({
    backgroundColor: "white",
    display: "flex",
    flexWrap: "wrap",
    borderRadius: "20px",
    '@media screen and (max-width: 768px)': {
        display: 'none',
        visibility: 'hidden',
    }
})

const LowerSectionItem = styled(Box)({
    padding: "30px 0px 30px 0px",
})

const PlanCardButton = styled(Button)({
    border: "2px solid transparant",
    color: "#009B07",
    fontWeight: 600,
    backgroundColor: "#FFFFFF",
    textTransform: "none",
    borderRadius: "38px",
    height: "100px",
    padding: "0 40px",
    '@media screen and (max-width : 1110px) and (min-width:769px)': {
        padding: "0 35px 0 35px"
    },
    '@media screen and (max-width:624px)': {
        padding: "0 35px 0 35px",

    },
    '@media screen and (max-width : 500px)': {
        padding: "0 25px 0 25px"
    }
});




const TextFieldRounded = styled(TextField)({
    width: '100%',
    background: '#FFFFFF',
    borderRadius: '15px',
    '& > .MuiOutlinedInput-root': {
        borderRadius: '15px',
    },
    '& > div > input': {
        paddingTop: '14px',
        paddingBottom: '14px'
    }
})


const SelectItemUserText = styled('span')({
    overflow: 'hidden',
    textOverflow: 'ellipsis'
})


const BtnImg = styled('img')({
    width: 25
})

const DivSection = styled('div')({})

// Customizable Area End




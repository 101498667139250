// Customizable Area Start

import {runEngine} from '../../../framework/src/RunEngine'
import MessageEnum, {
  getName
} from '../../../framework/src/Messages/MessageEnum'
import { Message } from "../../../framework/src/Message";

export function sendPostUpdatedMessage(data: any){
	let msg = new Message(getName(MessageEnum.PostUpdatedMessage));
    msg.addData(
      getName(MessageEnum.PostUpdatedDataMessage),
      data
    );
    runEngine.sendMessage(msg.id, msg);
    return msg.id
}

/*
*	execute callback only if message type match
*/ 
export function parsePostUpdatedMessage(msg: Message, callback: (data: any) =>any) {
	if(msg.id ===  getName(MessageEnum.PostUpdatedMessage)) {
		const updatedPost = msg.getData(getName(MessageEnum.PostUpdatedDataMessage))
		callback(updatedPost)
	}
}


// Customizable Area End
import React from "react";

// Customizable Area Start
import {
  Box,
  Button,
  withStyles,
  Typography,
  Modal,
  Backdrop,
  Grid,
  Paper,
  IconButton,
  TextField,
  ListItemText,
  Avatar,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { styled } from '@material-ui/styles';
import SearchSelect, { Checkbox } from "../../../components/src/SearchSelect.web";
import { Formik, Form, useField } from 'formik';
import {
  UserProfile
} from './assets';
// @ts-ignore
import classes from './Share.module.css';
// Customizable Area End

import ShareController, { Props, configJSON } from "./ShareController.web";

export default class Share extends ShareController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleClose = () => {
    this.setState({ showModal: false });
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",

          }}
          open={this.state.openModal}
          onClose={() => {
            this.setState({
              openModal: false
            })
          }}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              background: '#fff',
              borderRadius: 8,
              width: '650px',
              position: "relative",
              margin:'0 10px',
            }} className="schedule_modal">
            <Grid container spacing={0}>

              {/* Modal Title */}
              <ModalTitle item xs={12} >
                <Typography style={{ paddingTop: '14px', fontWeight: 'bold' }}>
                  {this?.state?.typeCheck == "BxBlockSurveys::Survey" ? "SHARE SURVEY" : this?.state?.typeCheck == "BxBlockProjectportfolio::PromoteContent" ? "SHARE PROJECT" : "SHARE POST"}
                </Typography>
                {/* Close Btn */}
                <CloseIconButton onClick={() => {
                  this.setState({
                    openModal: false
                  })
                }}>
                  <CloseIcon style={{}} />
                </CloseIconButton>
                {/* Close Btn END */}
              </ModalTitle>
              {/* Modal Title END */}

              <Grid item xs={12} style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                padding: '18px 36px'
              }}>
                <Formik initialValues={this.initialValues} validationSchema={this.validationSchema} onSubmit={this.sharePost}>
                  <RightFormWrapper translate="no">
                    <div className={`${classes.linkInput}`} style={{ width: '100%' }}>
                      <FormikTextField
                        name="link"
                        disabled={true}
                        value={this.state?.messageData?.link?.length > 50 ?
                          `${this.state?.messageData?.link?.substr(0, 50)} ...`
                          : this.state?.messageData?.link
                        }
                        variant="outlined"
                        InputProps={{
                          endAdornment: (
                            <CopyLinkButton
                              aria-label="copy link"
                              edge="end"
                              onClick={() => { navigator.clipboard.writeText(this.state?.messageData?.link) }}
                            >
                              Copy Link
                            </CopyLinkButton>)
                        }}
                      />
                    </div>
                    <div style={{ width: '100%' }}>
                      <SearchSelect
                        scrollHeight={'275px'}
                        hasMore={!!this.state?.userPagination?.next_page}
                        nextPage={this.paginateUsersOptions}
                        loading={(!!this.state?.userSearchCallId) || (!!this.state?.userPaginateCallId)}
                        onSearch={this.handleUserSearch}
                        allItems={this.state?.users}
                        items={this.state?.users}
                        name="shared_to_ids"
                        label={"Select Users"}
                        placeholder="Select Users"
                        SelectItem={SelectItemUser}
                        getOptionValue={(user: any) => `${user?.first_name} ${user?.last_name}`}
                      />
                    </div>
                    <Grid item xs={12} className={`${classes.shareButtons}`}>
                      <DialogCancelButton onClick={() => {
                        this.setState({
                          openModal: false
                        })
                      }}>
                        CANCEL
                      </DialogCancelButton>
                      <ShareNowButton type="submit">
                        SHARE NOW
                      </ShareNowButton>
                    </Grid>
                  </RightFormWrapper>
                </Formik>
              </Grid>

            </Grid>
          </Box>
        </Modal>
      </div >
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const SelectItemUser = ({ item, value }: { item: any, value: any }) => {
  return <>
    <Grid container>
      <Grid item xs={12} style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      }}>
        {/* <div className={`${classes.listLeftContainer}`}>
          <SelectItemUserText >{`${item.first_name} ${item.last_name}`}</SelectItemUserText>
          <Avatar className={`${classes.listAvatar}`} src={(item?.profile_pic || UserProfile)} aria-label="profile-pic">
          </Avatar>
        </div> */}
        <ListItemText style={{ flex: 2 }} disableTypography primary={
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar alt="Remy Sharp" src={item.profile_pic} style={{ width: "30px", height: "30px", marginRight: "10px" }} />
            <Typography style={{ color: "#333232", fontWeight: "bold", fontSize: 12, width: '35ch', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {item.first_name} {item.last_name}
            </Typography>
          </div>
        } />
        <Typography component={'div'} style={{ flex: 3, overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {`${item.email}`}
        </Typography>
        <Checkbox checked={value?.indexOf?.(item.id) > -1} />
      </Grid>
    </Grid>
  </>
}

const CopyLinkButton = styled(IconButton)({
  fontSize: '14px',
  background: '#019B07',
  color: '#FAFAFA',
  borderRadius: '26px',
  marginRight: '0',
  marginLeft:'8px',
  padding: '7px 18px 7px 18px',
  '&:hover': {
    background: '#999',
    opacity: 0.9
  }
})

const SelectItemUserText = styled('span')({
  overflow: 'hidden',
  textOverflow: 'ellipsis'
})

const CloseIconButton = styled(IconButton)({
  background: '#676767',
  color: '#fcfcfc',
  width: '32px',
  height: '32px',
  '&:hover': {
    background: '#999',
    opacity: 0.9
  }
})

const DialogCancelButton = styled(Button)({
  color: '#FFF',
  background: '#D0D0D0',
  padding: '10px 18px 8px 18px',
  margin: '5px',
  '&:hover': {
    background: '#999',
    opacity: 0.9
  },
  '@media screen and (max-width:400px)':{
    width:'-moz-available',
  }
})

const ShareNowButton = styled(Button)({
  color: '#FFF',
  background: '#019B07',
  padding: '10px 18px 8px 18px',
  margin: '5px',
  '&:hover': {
    background: '#999',
    opacity: 0.9
  },
  '@media screen and (max-width:400px)':{
    width:'-moz-available',
  }
})

const ModalTitle = styled(Grid)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItem: 'center',
  flexDirection: 'row',
  width: '100%',
  borderBottom: '0.2px solid #cbc1c1',
  padding: '13px 25px 13px 31px'
})

const RightFormWrapper = styled(Form)({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '25px',
  overflowX: 'hidden',
  paddingRight: '10px',
  maxHeight: '375px'
})

const FormikTextField = (props: any) => {
  const [field, meta, helpers] = useField(props.name)
  return <><StyledTextField
    error={!!(meta.touched && meta.error)}
    size="small"
    style={{
      width: '100%',
    }}
    variant="outlined"
    {...field}
    {...props}
  />
    <Typography color="error">{meta.touched && meta.error}</Typography>
  </>
}

const StyledTextField = withStyles((theme) => ({
  root: {
    width: '100%',
    "& .MuiInputBase-root": {
      color: '#2d4e92',
      paddingTop:'6px',
      paddingBottom:'6px',
      display: "flex",
      justifyContent: "space-between",
    },
    '& .MuiInputBase-input':{
      textOverflow:'ellipsis',
      },
    '@media screen and (max-width: 589px)': {
      '& .MuiInputBase-input': {
        // maxWidth: "100px",
      },
      '& .MuiButtonBase-root': {
        marginLeft: "15px",
      }
    },
  }
}))(TextField);

// Customizable Area End

import React, { useState, useEffect } from 'react'
import { Typography } from '@material-ui/core'
import { withStyles, styled } from "@material-ui/styles";
interface SeeMoreProps {
	text?: string;
	limit?: number;
	// don't wrap content in Typography
	noWrap?: boolean;
}

export default function SeeMore({text="", noWrap, limit=250}: SeeMoreProps){
	const [display, setDisplay] = useState("");
	const [expanded, setExpanded] = useState(false);

	useEffect(() => {
		setDisplay(text.substr(0, limit))
	}, [])

	const toggle = () => {
		if(expanded){
			setDisplay(text.substr(0, limit))
		} else{
			setDisplay(text)
		}
		setExpanded(e => !e)
	}
	const content = <>
			{display} 
			{text?.length>limit && <b style={{marginLeft: '2ch', cursor:'pointer'}} onClick={toggle}>{expanded?'hide.':'see more...'}</b>}
		</>
	if(noWrap) return content
	return <SeeMoreText  style={{fontSize:"14px"}}>
		{content}
	</SeeMoreText>
}
//*********** styles ***************//
const SeeMoreText = styled(Typography)({
    color: "#717171cf",
    fontSize: "20px",
    marginBottom: "8px",
    fontFamily:"helvetica"
  });

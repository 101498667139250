// Customizable Area Start

import {runEngine} from '../../../framework/src/RunEngine'
import MessageEnum, {
  getName
} from '../../../framework/src/Messages/MessageEnum'
import { Message } from "../../../framework/src/Message";

export function sendCourseUpdatedMessage(data: any){
    console.log('inerst uc1')
	let msg = new Message(getName(MessageEnum.CourseUpdatedMessage));
    msg.addData(
      getName(MessageEnum.CourseUpdatedDataMessage),
      data
    );
    runEngine.sendMessage(msg.id, msg);
    return msg.id
}

/*
*	execute callback only if message type match
*/ 
export function parseCourseUpdatedMessage(msg: Message, callback: (data: any) =>any) {
	if(msg.id ===  getName(MessageEnum.CourseUpdatedMessage)) {
		const updatedCourse = msg.getData(getName(MessageEnum.CourseUpdatedDataMessage))
		callback(updatedCourse)
	}
}


// Customizable Area End
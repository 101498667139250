import React from "react";
// Customizable Area Start
import TopNavBar from "../../../components/src/TopNavbar";
import {Box, Grid} from '@material-ui/core';
import LeftMenuWeb from "../../../components/src/LeftMenuWeb";
import RightMenuWeb from "../../../components/src/RightMenuWeb";
import SettingProfile from "./SettingsProfile";
import DashboardLayout from '../../../components/src/DashboardLayout.web'

import Settings5Controller, {
  Props,
  configJSON
} from "./Settings5Controller";
// Customizable Area End
export default class SettingsWeb extends Settings5Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <DashboardLayout {...this.props}>
        <div style={{maxWidth: '800px'}}>
          <SettingProfile navigation={this.props.navigation} id={""} />
        </div>
      </DashboardLayout>
    )
    // Customizable Area End
    return (
      // Customizable Area Start
      <Box style={{backgroundColor:"#edebeb", display:"flex",flexDirection:"column", paddingBottom:"20px",overflowX:"hidden"}}>
      <Box>
        <TopNavBar navigation={this.props.navigation} id={''}/>
      </Box>
      
       <Box>
           <Grid container spacing={5}>
               <Grid  item xs={12} sm={12} md={3} lg={3}>
                   <Box >
                       <LeftMenuWeb navigation={this.props.navigation} id={""}/>
                   </Box>    
               </Grid>
               <Grid  item xs={12} sm={12} md={6} lg={6} >
                   <Box >
                       <SettingProfile navigation={this.props.navigation} id={""}/>
                   </Box>
               </Grid>
               <Grid  item xs={12} sm={12} md={3} lg={3}>
               <Box >
                   <RightMenuWeb navigation={this.props.navigation} id={""}/>
                   </Box>
               </Grid>
           </Grid>
          
       </Box>
  </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { sendAPIRequest,esgPathRecords } from '../../../components/src/utils';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { parseAddRequestMessageData, sendRequestCreateMessage, sendRequestRespondMessage } from "./Messages";
import { Context } from "react";
import UserContext, { User } from "../../../components/src/UserProvider";
import { getStorageData } from "../../../framework/src/Utilities";

interface RecordAttributes {
  id: number;
  user_name: string | null;
  created_at: string;
  updated_at: string;
  email: string;
  company_name: string | null;
  ability_card: string;
  ability_type: string;
  profile_picture: string | null;
  card_serial_number: number;
  card_banner: string;
}

interface Record {
  id: string;
  type: string;
  attributes: RecordAttributes;
}

interface ESGRecordData {
  access: {
    data: Record[];
  };
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  users: any[];
  defaultUsers: any[];
  filesArray: any[];
  created_by: any[];
  requestTypes: any[];
  userPaginateCallId?: string;
  userSearchCallId?: string;
  submitRequestCallId?: string;
  respondRequestCallId?: string;
  userQuery: any;
  userPagination?: any;
  openModal: boolean;
  messageData: any;
  selectedRequestType: any;
  selectedRequestOwner: any;
  isViewRequest: boolean;
  isViewResponce: boolean;
  formType: string;
  formData: any;
  isFormInitialized: boolean;
  requestOwnerLoading: boolean;
  requestDetailCallId?: string;
  showCorporateform1?: boolean;
  showCorporateform2?: boolean;
  cartificate_data: any;
  corporateProfileData: any;
  corporateProfileID:any;
  popOverAnchorEL: any;
  corporateProfilePic: any;
  corporateProfilePicSent: any;
  corporateCoverPic: any;
  corporateCoverPicSent: any;
  pastValues: any;
  loading: boolean;
  certificateEditData: any;
  certificationSaveToggle: boolean;
  certificateEditId: any;
  showCertificateForm: boolean;
  otherSector: boolean;
  otherIndustries: boolean;
  otherSubIndustries: boolean;
  otherStatus: boolean;
  otherOwnershipType: boolean;
  otherInvestmentType: boolean;
  otherShareholdingPattern: boolean;
  otherCertificate: boolean;
  otherCompanyStatus: boolean;
  cetificateTempData: any;
  isdisabled: boolean;
  mapVisible: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class CorporateProfileFormController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  static contextType?: Context<User> = UserContext;
  apiGetAllUsersCallId = '';
  apiGetAllRequestTypeCallId = '';
  requestDetailCallId = "";
  corpProfileSumbitCallId = "";
  deleteCertifictionCallId ="";
  getCorporateDataId = "";
  certification_dropdowns = ["LEED", "USDA Organic", "Green Business Bureau", "WELL", "Safer Choice", "Green C Certification", "Energy Star", "EDGE", "The Institute for Green Business Certification", "PEER", "Fair Trade USA Certified", "Green Seal", "SITES", "Forest Stewardship Council", "GRESB", "Bay Area Green Business Program", "WasteWise", "Parksmart", "BioPreferred", "WaterSense", "Animal Welfare Approved", "Green America", "Certified Humane", "Green Plus", "EPEAT", "ISO 26000:2010", "ISO 140001", "ISO 140004", "ISO 14005", "The Vinyl Council of Australia", "Vietnam Green Building Council (VGBC)", "Singapore Green Building Council (SGBC)", "Green Building Council Indonesia (GBCI)", "Indian Green Building Council (IGBC)", "Green Building Japan (GBJ)", "Green Building Council of Australia (GBCA)", "Singapore Green Building Product (SGBP) Certification Scheme — One tick", "The Carpet and Rug Institute (CRI) Green Label Plus", "Singapore Green Building Product (SGBP) Certification Scheme — Three ticks", "NSF/ANSI 332 – Silver Certified", "Global GreenTag's GreenRate certification – Level A", "FloorScore®", "Best Practice Guidelines for PVC"];
  financialYear = ["2010-2011", "2011-2012", "2012-2013", "2013-2014", "2014-2015", "2015-2016", "2016-2017", "2017-2018", "2018-2019", "2019-2020", "2020-2021", "2021-2022", "2022-2023", "2023-2024", "2024-2025", "2025-2026"]
  shareHoldingPattern = ["Institutional Investors 25 %", "Promoters 75 % (foreign promoters)", "Other Corporates", "Debt Funding", "PE/ VC 100 %", "Government", "Limited Partners"]
  invesmentType = ["Safety Industrial", 'Transportation', "Electronics", "Health Care", "Consumer"];
  officeLocation = ["Worldwide"];
  companySector = ["Communication Services", "Construction", "Consulting", "Consumer Discretionary", "Consumer Staples", "Education", "Energy", "Farming / Fishing", "Financials", "Healthcare", "Hospitality and Leisure", "Industrials", "Information Technology", "Manufacturing", "Materials", "Public Sector", "Real Estate", "Research and Development", "Retail", "Utilities- Electricity / Gas",]
  companyRegion = ["Asia", "ANZ", "International", "North America, Canada and South Americas", "Africa", "Europe", "Middle East"]
  industrySub = ["Advertising,",
    "Aerospace Industry",
    "Agriculture, Forestry",
    "Air Freight & Logistics",
    "Airlines",
    "Arms Industry",
    "Asset Management",
    "Auto Components",
    "Automobiles",
    "Automotive Industry",
    "Banks",
    "Beverages",
    "Biotechnology",
    "Broadcasting",
    "Building Products",
    'Capital Markets',
    "Chemicals",
    "Coal Mining",
    'Commercial Services & Supplies',
    "Communications Equipment",
    "Computer Industry",
    "Construction & Engineering",
    "Construction Materials",
    "Consumer Finance",
    "Containers & Packaging",
    "Creative",
    "Cultural , Sporting and Recreational Services",
    "Defense Industry",
    "Distributors",
    "Diversified Consumer Services",
    "Diversified Financial Services",
    "Diversified Telecommunication Services",
    "Education Industry",
    "Electric Power Industry",
    "Electric Utilities",
    "Electronic Equipment, Instruments & Components",
    " Energy Equipment & Services",
    "Energy Industry",
    "Entertainment Industry",
    "Equity Real Estate Investment Trusts (REITs)",
    "Fashion and Apparrel",
    "Film Industry",
    "Financial services Industry",
    "Floral",
    "Food & Staples Retailing",
    "Food Products",
    "Furniture and Saw mills",
    "Gambling Industry",
    "Gas Utilities",
    "General and Special Purpose Pachinery",
    "Health Care Equipment & Supplies",
    "Health Care Providers & Services",
    "Health Care Technology",
    "Hospitality Industry",
    "Hotels, Restaurants & Leisure",
    "Household Durables",
    "Independent Power and Renewable Electricity Producers",
    "Industrial Conglomerates",
    "Industrial Robot Industry",
    "Information Industry",
    "Insurance",
    "Interactive Media & Services",
    "Internet",
    "Internet & Direct Marketing Retail",
    "Internet Software & Services",
    "IT Services",
    "Leisure Industry",
    "Leisure Products",
    "Life Sciences Tools & Services",
    "Low Technology Industry",
    "Machinery",
    "Main article: Tertiary Sector of the Economy",
    "Marine",
    "Mass media",
    "Meat",
    "Meat Packing",
    "Metals & Mining",
    "Micro- Finance/ Micro-lending",
    "Mining- ( Metal Ores, Gemstones)",
    "Mortgage Real Estate Investment Trusts (REITs)",
    "Multi-Utilities",
    "Multiline Retail",
    "Music Industry",
    "News Media",
    "Oil Shale",
    "Oil, Gas & Consumable Fuels",
    "Paper & Forest Products",
    "Personal Products",
    "Petroleum and Natural Gas industry",
    "Pharmaceuticals",
    "Professional Services",
    "Public Administration and Social Security",
    "Publishing",
    "Pulp and Paper Industry",
    "Real Estate Management & Development",
    "Renewables- Wind, Solar, Tidal",
    "Rental and Business Services",
    "Retail Trade",
    "Road & Rail",
    "Scrap and Saste",
    "Semiconductors & Semiconductor Equipment",
    "Shipbuilding Industry",
    "Social Enterprise",
    "Software",
    "Specialty Retail",
    "Sport Industry",
    "Steel Industry",
    "Technology Hardware, Storage & Peripherals",
    "Telecommunications Industry",
    "Textiles, Apparel & Luxury Goods",
    "Thrifts & Mortgage Finance",
    "Tobacco",
    "Trading Companies & Distributors",
    "Transport Industry",
    "Transportation Infrastructure",
    "Video Game Industry",
    "Water Industry",
    "Water Utilities",
    "Wireless Telecommunication Services"]
  industrySelect = ["Advertising,",
    "Aerospace Industry",
    "Agriculture, Forestry",
    "Air Freight & Logistics",
    "Airlines",
    "Arms Industry",
    "Asset Management",
    "Auto Components",
    "Automobiles",
    "Automotive Industry",
    "Banks",
    "Beverages",
    "Biotechnology",
    "Broadcasting",
    "Building Products",
    'Capital Markets',
    "Chemicals",
    "Coal Mining",
    'Commercial Services & Supplies',
    "Communications Equipment",
    "Computer Industry",
    "Construction & Engineering",
    "Construction Materials",
    "Consumer Finance",
    "Containers & Packaging",
    "Creative",
    "Cultural , Sporting and Recreational Services",
    "Defense Industry",
    "Distributors",
    "Diversified Consumer Services",
    "Diversified Financial Services",
    "Diversified Telecommunication Services",
    "Education Industry",
    "Electric Power Industry",
    "Electric Utilities",
    "Electronic Equipment, Instruments & Components",
    "Energy Equipment & Services",
    "Energy Industry",
    "Entertainment Industry",
    "Equity Real Estate Investment Trusts (REITs)",
    "Fashion and Apparrel",
    "Film Industry",
    "Financial services Industry",
    "Floral",
    "Food & Staples Retailing",
    "Food Products",
    "Furniture and Saw Mills",
    "Gambling Industry",
    "Gas Utilities",
    "General and Special Purpose Machinery",
    "Health Care Equipment & Supplies",
    "Health Care Providers & Services",
    "Health Care Technology",
    "Hospitality Industry",
    "Hotels, Restaurants & Leisure",
    "Household Durables",
    "Independent Power and Renewable Electricity Producers",
    "Industrial Conglomerates",
    "Industrial Robot Industry",
    "Information Industry",
    "Insurance",
    "Interactive Media & Services",
    "Internet",
    "Internet & Direct Marketing Retail",
    "Internet Software & Services",
    "IT Services",
    "Leisure Industry",
    "Leisure Products",
    "Life Sciences Tools & Services",
    "Low technology Industry",
    "Machinery",
    "Main article: Tertiary Sector of the Economy",
    "Marine",
    "Mass Media",
    "Meat",
    "Meat Packing",
    "Metals & Mining",
    "Micro- Finance/ Micro-lending",
    "Mining- ( Metal Ores, Gemstones)",
    "Mortgage Real Estate Investment Trusts (REITs)",
    "Multi-Utilities",
    "Multiline Retail",
    "Music Industry",
    "News Media",
    "Oil Shale",
    "Oil, Gas & Consumable Fuels",
    "Paper & Forest Products",
    "Personal Products",
    "Petroleum and Natural Gas Industry",
    "Pharmaceuticals",
    "Professional Services",
    "Professional Services",
    "Public Administration and Social Security",
    "Publishing",
    "Pulp and Paper Industry",
    "Real Estate Management & Development",
    "Renewables- Wind, Solar, Tidal",
    "Rental and Business Services",
    "Retail Trade",
    "Road & Rail",
    "Scrap and Waste",
    "Semiconductors & Semiconductor Equipment",
    "Shipbuilding Industry",
    "Social Enterprise",
    "Software",
    "Specialty Retail",
    "Sport Industry",
    "Steel Industry",
    "Technology Hardware, Storage & Peripherals",
    "Telecommunications Industry",
    "Textiles, Apparel & Luxury Goods",
    "Thrifts & Mortgage Finance",
    "Tobacco",
    "Trading Companies & Distributors",
    "Transport Industry",
    "Transportation Infrastructure",
    "Video game Industry",
    "Water Industry",
    "Water Utilities",
    "Wireless Telecommunication Services"]
  companyStatus = ["Operating", "Out of Business", "Reorganising", "Operating Subsidiary"]
  Ownership_Type = ["Private Limited Company",
    "Public Private Partenrship",
    "Public Limited Company",
    "Private Equity",
    "Venture Capital",
    "Debt Funding",
    "Limited Partners",
    "Public Sector Undertaking",
    "Not for Profit/ NGO",
    "Licensed Investment company",
    "Educational Institution"]
  esgAccessCallId:string = ''
  async componentDidMount() {
    super.componentDidMount();
    let authToken = await getStorageData('authToken');
    const userData = await getStorageData('user');
    const user = JSON.parse(userData);
    let isAllowed = user?.role?.role_level < 5 || user.user_type === "licensed_user"
    if(isAllowed){
      this.esgAccess(authToken)
    }
    this.setState({token: authToken}, () => {
    this.onInitialized();
    this.getFormData();
    });
  }


  onInitialized = async () => {
    const authToken = await getStorageData('authToken')

    const access = this.props?.navigation?.history?.location?.state?.ability_type
    if(access == "read"){
      this.setState({isdisabled:true})
    }

    const requestId = this.props.navigation.getParam('id')
    if (requestId)
      this.requestDetailCallId = sendAPIRequest(
        configJSON.getRequestDetails.replace(':id', requestId),
        {
          headers: { 'token': authToken }
        })
    const userData = await getStorageData('user');
    let requestOwner = JSON.parse(userData as string);
    this.setState({ created_by: [requestOwner] });
    this.setState({ selectedRequestOwner: [requestOwner] });
    this.apiGetAllUsersCallId = sendAPIRequest(`${configJSON.getUserList}?account_id=${JSON.parse(requestOwner?.id || '0')}`, {
      headers: { token: authToken },
    });
    this.apiGetAllRequestTypeCallId = sendAPIRequest(configJSON.getRequestTypes, {
      headers: { token: authToken },
    });

  }


  handlePopover = (event: any) => {
    this.setState({
      popOverAnchorEL: event.currentTarget
    })
  }

  handleProfilePic = (event: any) => {
    
    if (event.target?.files) {
      this.setState({
        corporateProfilePic: URL.createObjectURL(event.target.files[0]),
        corporateProfilePicSent: (event.target.files[0])
      }
      )
    }
  }
  handleCoverPic = (event: any) => {
    // console.log(event.target.files[0], 'test1')
    if (event.target?.files) {
      this.setState({
        corporateCoverPic: URL.createObjectURL(event.target.files[0]),
        corporateCoverPicSent: (event.target.files[0])
      }
      )
    }
  }

  


  handleGetUsersAPIResponse = (json: any) => {
    if (!json?.data) return
    let users = json.data.map((user: any) => ({ id: user.id, ...user.attributes }));
    this.setState({ users: users, userPagination: json.meta.pagination, openModal: true });
    setTimeout(() => {
      this.setState({ isFormInitialized: true });
      this.setState(prevState => ({
        users: [...prevState.users, this.state.selectedRequestOwner].flat()
      }))
    }, 100);
  }

  handleGetRequestTypeAPIResponse = (json: any) => {
    if (!json?.data) return
    const requestTypes = json.data?.filter((r: any) => !r.attributes.internal)?.map((request: any) => ({ id: request?.id, title: `${request?.attributes?.title}` }))
    this.setState({ requestTypes });
  }


  handleGoBack = () => {
    (this.setState({ showCorporateform1: true }))
  }

  onNextForm = () => {
    console.log("values gdjgdkgjk");
    (this.setState({ showCorporateform1: false }))
    
  }

  onCertificationSubmit = (data:any,resetForm: any) => {
    if (this.state.certificationSaveToggle) {
      
      let tempArr = [...this.state.cartificate_data]
      
      tempArr.splice(this.state.certificateEditId, 1, data)
      
      this.setState({certificationSaveToggle:false, cartificate_data: tempArr})
      this.setState({
        certificateEditData: {
          ...this.state.certificateEditData,
          certificationName:  "",
          certification_id:"",
          certificationEpiryDate: null  ,
          certificationDescription: "",
          certificationDocument: "",
        }
      })

      console.log("test");
    } else {
      
    
      this.setState({cartificate_data: [...this.state.cartificate_data,data],certificationSaveToggle:false})
      console.log("asas", this.state.cartificate_data);
      this.setState({
        certificateEditData: {
          ...this.state.certificateEditData,
          certificationName:  "",
          certification_id:"",
          certificationEpiryDate: null  ,
          certificationDescription: "",
          certificationDocument: "",
        }
      })
      resetForm();
    }
    this.setState({ showCertificateForm: false })
    toast.success("Certificate uploaded successfully")
  }

  handleCertificationDelete = async(item: any,id:any) => {
    let token = await getStorageData('authToken');

    this.deleteCertifictionCallId = sendAPIRequest(`${configJSON.deleteCertification}/${this.state.corporateProfileID}/archived_certifications/${item?.certification_id}`, {
      method: "DELETE",
      headers: {
        token
      },
    })
    const arr = this.state.cartificate_data.filter((item: any, index: any) => 
      index !== id
    )
    this.setState({ cartificate_data: arr })
  }

  handleCertificationEdit = (id: any) => {
    this.setState({showCertificateForm: true})
    this.setState({ certificationSaveToggle: true , certificateEditId: id})
    console.log("asasa", this.state.cartificate_data, id);
    let selectedObject = this.state.cartificate_data[id]
    console.log(selectedObject,"selectedObject");
    
    this.setState({
      certificateEditData: {
        ...this.state.certificateEditData,
        certificationName:  selectedObject?.certificationName,
        certification_id: selectedObject?.certification_id,
        certificationEpiryDate: selectedObject?.certificationEpiryDate  ,
        certificationDescription: selectedObject?.certificationDescription,
        certificationDocument: selectedObject?.certificationDocument,
      }
    }
    )
  }

  onCertificateUploadButton = (e :any) => {
   
    
  }

  onFormClose =()=> {
    this.setState({showCertificateForm: false})
  }
  onFormAdd=()=> {
    this.setState({showCertificateForm: true})
  }

  onSubmitForm = (data: any) => {
    console.log("sdfsfsdffd");
    this.setState({loading: true})
    const body = new FormData()
    // const FormData = {
    //   companyName: data.company_name,
    //   tagline: data.title,
    //   companyWebsite: data.company_website,
    //   companyEmail: data.company_email,
    //   businessPhone: data.business_phone,
    //   companyHeadquarter: data.company_headquaters,
    //   companyRegion: data.company_region,
    //   businessCity: data.business_city,
    //   businessState: data.business_state,
    //   businessCountry: data.business_country,
    //   financialYear: data.financial_year,
    //   companySector: data.sector,
    //   companyIndustry: data.industry,
    //   comapnySubIndustry: data.sub_industry,
    //   TurnOver: data.turnover,
    //   fullTimeEmployes: data.full_time_employees,
    //   partTimeEmployes: data.part_time_employees,
    //   partTimePercent: data.workforce,
    //   companyStatus: data.company_status,
    //   OwnershipType: data.ownership_type,
    //   InvesmentType: data.product,
    //   Others: data.Other,
    //   businessDescription: data.description,
    //   facilities: data.facilities,
    //   subsidaries: data.subsidiaries,
    //   shareholdingPatter: data.Shareholding_Pattern,
    //   womenOwner: data.owners_women,
    //   indigenousPeope: data.owners_indigenuos,
    //   ownerMinority: data.owners_minorities,
    //   MissionAndVision: {
    //     organisationPurpose: data.orgainsation_purpose,
    //     organisationVision: data.orgainsation_vision,
    //     organisationMission: data.orgainsation_mission,
    //     organisationValues: data.orgainsation_values,
    //     CEOMessage: data.messageCeo,
    //   },
    //   FinancialKeyMatrics: {
    //     marketCapitalization: data.market_capitalization,
    //     netSales: data.net_sales,
    //     operatingIncome: data.operating_income,
    //     totalDebt: data.total_debt,
    //     researchAndDevlopment: data.research_devlopment,
    //     cashFlow: data.free_cash_flow,
    //     patentFiled: data.patent_filed,
    //     netIncome: data.net_income,
    //     perShare: data.per_share_basic,
    //     perShareDiluted: data.per_share_diluted,
    //     capitalExpenses: data.capital_expences,
    //     globalGiving: data.global_giving,
    //     profitMargin: data.profit_margin,
    //     grossMargin: data.gross_margin,
    //     ROE: data.roe,
    //     ROCE: data.roce,
    //     debtRatio: data.debt_ratio,
    //     currentRatio: data.current_ratio,
    //   }

    // }
    if (this.state.showCorporateform1) {
      // sessionStorage.setItem("values", JSON.stringify(data))
      console.log("sdfsfsdffd", data);
      
      this.setState({ showCorporateform1: false, loading:false })
    } else {
      console.log("sdfsfsdffd");

      body.append(`company_name`, `${data.company_name}`)
      body.append(`tagline`, `${data.title}`)
      body.append(`website_link`, `${data.company_website}`)
      body.append(`email`, `${data.company_email}`)
      body.append(`business_phone_number`, `${data.business_phone}`)
      body.append(`headquarters`, `${data.company_headquaters}`)
      body.append(`region`, `${data.company_region}`)
      body.append(`city`, `${data.business_city}`)
      body.append(`state`, `${data.business_state}`)
      body.append(`country`, `${data.business_country}`)
      body.append(`financial_year`, `${data.start_date}` + "-" + `${data.end_date}`)
      body.append(`industry`, `${data.industry}`)
      body.append(`sub_industry`, `${data.sub_industry}`)
      body.append(`sector`, `${data.sector}`)
      body.append(`average_turnover`, `${data.turnover}`)
      body.append(`fulltime_employees`, `${data.full_time_employees}`)
      body.append(`part_time_employees`, `${data.part_time_employees}`)
      body.append(`part_time_workforce_percent`, `${data.workforce}`)
      body.append(`facilities`, `${data.facilities}`)
      body.append(`subsidiaries`, `${data.subsidiaries}`)
      body.append(`ownership_type`, `${data.ownership_type}`)
      body.append(`investment_type`, `${data.product}`)
      body.append(`other_investment_type`, `${data.Other}`)
      body.append(`shareholding_pattern`, `${data.Shareholding_Pattern}`)
      body.append(`woman_owner_percent`, `${data.owners_women}`)
      body.append(`minorities_owner_percent`, `${data.owners_minorities}`)
      body.append(`business_description`, `${data.description}`)
      body.append(`indigenous_owner_percent`, `${data.owners_indigenuos}`)
      body.append(`company_status`, `${data.company_status}`)
      body.append(`mission_and_vision_attributes[purpose]`, `${data.orgainsation_purpose}`)
      body.append(`mission_and_vision_attributes[vision]`, `${data.orgainsation_vision}`)
      body.append(`mission_and_vision_attributes[mission]`, `${data.orgainsation_mission}`)
      body.append(`mission_and_vision_attributes[values]`, `${data.orgainsation_values}`)
      body.append(`mission_and_vision_attributes[message_from_ceo]`, `${data.messageCeo}`)
      body.append(`key_financial_metrics_attributes[market_capitalisation]`, `${data.market_capitalization}`)
      body.append(`key_financial_metrics_attributes[net_sales_million]`, `${data.net_sales}`)
      body.append(`key_financial_metrics_attributes[operating_income]`, `${data.operating_income}`)
      body.append(`key_financial_metrics_attributes[total_debt]`, `${data.total_debt}`)
      body.append(`key_financial_metrics_attributes[res_dev_related_expences]`, `${data.research_devlopment}`)
      body.append(`key_financial_metrics_attributes[free_cash_flow]`, `${data.free_cash_flow}`)
      body.append(`key_financial_metrics_attributes[patents_filed]`, `${data.patent_filed}`)
      body.append(`key_financial_metrics_attributes[total_operating_million_expenses]`, `${data.operating_expence}`)
      body.append(`key_financial_metrics_attributes[net_income_attributable]`, `${data.net_income}`)
      body.append(`key_financial_metrics_attributes[per_share_basic_actual]`, `${data.per_share_basic}`)
      body.append(`key_financial_metrics_attributes[per_share_diluted_actual]`, `${data.per_share_diluted}`)
      body.append(`key_financial_metrics_attributes[capital_expenditures_million]`, `${data.capital_expences}`)
      body.append(`key_financial_metrics_attributes[payments_to_provider_capital]`, `${data.payments_to_provider}`)
      body.append(`key_financial_metrics_attributes[provision_for_million_income_taxes]`, `${data.million_tax_income}`)
      body.append(`key_financial_metrics_attributes[total_global_giving]`, `${data.global_giving}`)
      body.append(`key_financial_metrics_attributes[total_cash_donation]`, `${data.cash_donations}`)
      body.append(`key_financial_metrics_attributes[total_in_kind_donation]`, `${data.in_kind_donations}`)
      body.append(`key_financial_metrics_attributes[net_profit_margin]`, `${data.profit_margin}`)
      body.append(`key_financial_metrics_attributes[gross_profit_margin]`, `${data.gross_margin}`)
      body.append(`key_financial_metrics_attributes[roe]`, `${data.roe}`)
      body.append(`key_financial_metrics_attributes[roce]`, `${data.roce}`)
      body.append(`key_financial_metrics_attributes[debt_equity_ratio]`, `${data.debt_ratio}`)
      body.append(`key_financial_metrics_attributes[current_ratio]`, `${data.current_ratio}`)
      
      
      this.state.cartificate_data && this.state.cartificate_data.map((item:any,index:any) => {
        if (item?.certification_id != "") { 
          console.log(item,"id01");
          
          body.append(`archived_certifications[${index}][id]`,`${item.certification_id}`)
        }
        if (item?.certificationDocument?.path == undefined) {
          body.append(`archived_certifications[${index}][title]`, `${item.certificationName}`)
          body.append(`archived_certifications[${index}][expire_at]`, `${item.certificationEpiryDate}`)
          body.append(`archived_certifications[${index}][description]`, `${item.certificationDescription}`)
          item?.certificationDocument?.name && body.append(`archived_certifications[${index}][document]`, item.certificationDocument)
        }
      })
      console.log("cartificate_data",this.state.cartificate_data);
      if (this.state.corporateProfilePicSent?.name) {
        body.append(`logo`, this.state.corporateProfilePicSent)
      }
    
      if (this.state.corporateCoverPicSent?.name) {
        body.append(`cover_pic`, this.state.corporateCoverPicSent) 
      }   
      
      this.setState({loading: true})
      const { token } = this.state
      this.corpProfileSumbitCallId = sendAPIRequest(configJSON.corporateProflieForm, {
        method: "POST",
        headers: {
          token
        },
        body
      })
    }
  }

  getFormData = () => {
    console.log("navigation data", this.props.navigation)
    const { token } = this.state
    this.setState({loading: true})
    this.getCorporateDataId = sendAPIRequest(configJSON.getCorporatProfileData, {
      method: "GET",
      headers: {
        token
      },
    })
}

  handleRequestTypeChange = (e: any, option: any, setTouched: any, setFieldValue: any) => {
    setTouched({ request_type_id: true });
    setFieldValue('request_type_id', (!option ? 0 : JSON.parse(option?.id || '')));
    this.setState({ selectedRequestType: (!option ? {} : option) });
  };

  handleDuplicateName = (setFieldValue:any,value:any) => {
    this.state.cartificate_data.filter((el: any) => el.certificationName === value).length == 0 && setFieldValue("certificationName", value)
    this.state.cartificate_data.filter((el: any) => el.certificationName === value).length !== 0 && toast ("Name cannot be duplicate",{ type: 'error' }) 
  }
  
  handleResterCertification = () => {
    this.setState({certificateEditData: {
      certificationName: "",
      certification_id:"",
      certificationEpiryDate:  null,
      certificationDescription: "",
      certificationDocument: ""},showCertificateForm: false})
  }


  validationSchema1 = Yup.object().shape({
    company_name: Yup.string()
      .required()
      .min(0)
      .max(160)
      .label('Company Name'),
    company_website: Yup.string()
      .required()
      .min(2)
      .max(50)
      .label('Company Website')
      .matches((/^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/), 'URL is not valid'),
    company_email: Yup.string()
      .required()
      .email()
      .label('Company Email')
      .matches(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, "Email is not Valid"),
    company_headquaters: Yup.string()
      .required()
      .min(2)
      .max(50)
      .label('Company Headquaters'),

    description: Yup.string()
      .required()
      .label('Description')
      .min(2)
      .max(2000),
    business_country: Yup.string()
      .required()
      .min(2)
      .max(50)
      .label('Business Country')
      .matches(/^[a-zA-Z ]+$/, 'Country accepts only alphabets'),
    // financial_year: Yup.string().required()
    //   .label('Financial Year'),
    start_date: Yup.date().required(),
    end_date:Yup.date().required(),
    sector: Yup.string()
      .required()
      .min(2)
      .max(50)
      .label('Sector'),
    industry: Yup.string().required().label("Industry"),
    sub_industry: Yup.string().typeError("Sub Industry is required")
      .label('Sub-Industry'),
    turnover: Yup.number().typeError('Turover can only contain numbers')
      .positive('Turnover can not be Negative')
      .label('Financial Year'),
    business_phone: Yup.string().matches(/^\+?[1-9][0-9]{7,14}$/, 'Phone Number is not valid').label('Business Phone No'),
    full_time_employees: Yup.number().typeError('This field can only contain numbers').required().label('Full Time Employees'),
    part_time_employees: Yup.number().typeError('This field can only contain numbers').label('Part Time Employees'),
    company_status: Yup.string().required().label('Company Status'),
    ownership_type: Yup.string().required().label('Ownership Type'),
    product: Yup.string().required().label('This'),
    Other: Yup.string().label('Others'),
    title: Yup.string().min(0).max(160).label('Tagline/Slogan'),
    facilities: Yup.string().min(0).max(100).label('This'),
    subsidiaries: Yup.string().min(0).max(100).label('Subsidiaries'),
    Shareholding_Pattern: Yup.string().required().label('Shareholding Pattern'),
    owners_women: Yup.number().typeError('This field can only contain numbers').required().min(0).max(100).label('Women Owners'),
    owners_indigenuos: Yup.number().typeError('This field can only contain numbers').required().min(0).max(100).label('Indigenuos People'),
    owners_minorities: Yup.number().typeError('This field can only contain numbers').required().min(0).max(100).label('Minority'),
    workforce: Yup.number().typeError('This field can only contain numbers').min(0).max(100).label("Workforce"),
    // request_type_id: Yup.number().positive('Please select Request Type.').label('Request Type'),
    // created_by_id: Yup.array().min(1, 'Please select Request Creater.'),
    // sender_id: Yup.number().positive('Please select Request Owner.').label('Request Owner'),
    // account_ids: Yup.array().min(1, 'Please select atleast one'),

  })

  validationSchema2 = Yup.object().shape({

    orgainsation_purpose: Yup.string().max(1000).required().label("Orgainsation Purpose"),
    orgainsation_vision: Yup.string().max(1000).required().label("Orgainsation Vision"),
    orgainsation_mission: Yup.string().max(1000).required().label("Orgainsation Mission"),
    orgainsation_values: Yup.string().max(1000).required().label("Orgainsation Values"),
    messageCeo: Yup.string().max(1000).required().label("CEO's Message"),
    market_capitalization: Yup.number().typeError('This field can only contain numbers').label("Market Capitalization"),
    net_sales: Yup.number().typeError('This field can only contain numbers').required().label("Net Sales"),
    operating_income: Yup.number().typeError('This field can only contain numbers').required().label("Operating Income"),
    operating_expence: Yup.number().typeError('This field can only contain numbers').required().label("Operating Expence"),
    net_income: Yup.number().typeError('This field can only contain numbers').required().label("Net Income"),
    total_debt: Yup.number().typeError('This field can only contain numbers').label("Total Debt"),
    research_devlopment: Yup.number().typeError('This field can only contain numbers').label("Research Revlopment"),
    free_cash_flow: Yup.number().typeError('This field can only contain numbers').label("Free Cash Flow"),
    patent_filed: Yup.number().typeError('This field can only contain numbers').label("Patent Filed"),
    per_share_basic: Yup.number().typeError('This field can only contain numbers').label("per Share Basic"),
    per_share_diluted: Yup.number().typeError('This field can only contain numbers').label("per Share Diluted"),
    capital_expences: Yup.number().typeError('This field can only contain numbers').label("Capital Expences"),
    global_giving: Yup.number().typeError('This field can only contain numbers').label("Global Giving"),
    profit_margin: Yup.number().typeError('This field can only contain numbers').min(0).max(100).label("Profit Margin"),
    gross_margin: Yup.number().typeError('This field can only contain numbers').min(0).max(100).label("Gross Margin"),
    roe: Yup.number().min(0).typeError('This field can only contain numbers').max(100).label("ROE"),
    roce: Yup.number().min(0).typeError('This field can only contain numbers').max(100).label("ROCE"),
    debt_ratio: Yup.string().typeError('This field can only contain numbers').label("Debt Ratio"),
    current_ratio: Yup.number().typeError('This field can only contain numbers').label("Current Ratio"),
    payments_to_provider: Yup.number().typeError('This field can only contain numbers').label("Payments to provider"),
    cash_donations: Yup.number().typeError('This field can only contain numbers').label("Total Cash Donations"),
    in_kind_donations: Yup.number().typeError('This field can only contain numbers').label("Total in-kind Donations"),
    million_tax_income: Yup.number().typeError('This field can only contain numbers').required().label("Provision for Million Income Taxes"),
    // certification_description: Yup.string().min(2).label("Description")
  })

  certificationValidation = Yup.object().shape({
    certificationName: Yup.string().required(),
    certificationDescription: Yup.string().max(1000).min(2).label("Description"),
    certificationDocument: Yup.mixed()
    .required('A PDF file is required')
    .test(
      'fileFormat',
      'Only PDF files are accepted',
      (value) => value && value.type === 'application/pdf'
    ),
  })

  

  certificateIntialValues= this.state?.certificateEditData

  

  initialType: any[] = [];
  initialValues = {
    showResponce: false,
    title: "",
    description: "",
    workforce: "",
    request_type_id: 0,
    account_ids: this.initialType,
    sender_id: 0,
    created_by_id: this.initialType,
    response: '',
    reference_link: '',
    company_name: "",
    company_website: "",
    company_region: '',
    company_email: "",
    business_phone: "",
    start_date: new Date(),
    end_date: new Date(),
    company_headquaters: "",
    business_country: "",
    business_city: "",
    business_state: "",
    financial_year: "",
    sector: "",
    industry: "",
    sub_industry: "",
    turnover: "",
    full_time_employees: "",
    part_time_employees: "",
    company_status: "",
    ownership_type: "",
    Shareholding_Pattern: "",
    product: "",
    Other: "",
    operating_expence: "",
    facilities: "",
    subsidiaries: "",
    owners_women: "",
    owners_indigenuos: "",
    owners_minorities: "",
    orgainsation_purpose: "",
    orgainsation_vision: "",
    orgainsation_mission: "",
    orgainsation_values: "",
    messageCeo: "",
    market_capitalization: "",
    net_sales: "",
    operating_income: "",
    total_debt: "",
    research_devlopment: "",
    free_cash_flow: "",
    patent_filed: "",
    net_income: "",
    per_share_basic: "",
    per_share_diluted: "",
    capital_expences: "",
    global_giving: "",
    in_kind_donations: "",
    cash_donations: "",
    million_tax_income: "",
    payments_to_provider: "",
    profit_margin: "",
    gross_margin: "",
    roe: "",
    roce: "",
    debt_ratio: "",
    current_ratio: "",

  }


  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);


    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AddRequestMessaage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: '',
      userPaginateCallId: '',
      userSearchCallId: '',
      submitRequestCallId: '',
      userQuery: '',
      users: [],
      defaultUsers: [],
      created_by: [],
      requestTypes: [],
      messageData: {},
      openModal: true,
      selectedRequestType: {},
      selectedRequestOwner: [],
      isViewRequest: false,
      isViewResponce: false,
      formData: {},
      isFormInitialized: false,
      requestOwnerLoading: false,
      formType: 'SubmitRequest',
      filesArray: [],
      showCorporateform1: true,
      showCorporateform2: false,
      cartificate_data: [],
      corporateProfileData: {},
      corporateProfileID:"",
      popOverAnchorEL: null,
      corporateProfilePic: "",
      corporateProfilePicSent: {},
      corporateCoverPic: "",
      corporateCoverPicSent: {},
      pastValues: {},
      loading: false,
      certificateEditData: {
        certificationName: "",
        certification_id:"",
        certificationEpiryDate:  null,
        certificationDescription: "",
        certificationDocument: ""},
      certificationSaveToggle: false,
      certificateEditId: "",
      showCertificateForm: true,
      otherSector: true,
      otherIndustries: true,
      otherSubIndustries: true,
      otherStatus: true,
      otherCompanyStatus: true,
      otherOwnershipType: true,
      otherInvestmentType: true,
      otherCertificate: true,
      otherShareholdingPattern: true,
      cetificateTempData: [],
      isdisabled: false,
      mapVisible: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    parseAddRequestMessageData(message, this.getAddRequestMessageData);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);
      if (responseJson && responseJson.errors) {
        if (responseJson?.errors[0]?.Request == "Not authorized") {
          this.setState({ respondRequestCallId: '', openModal: false })
          toast.error("You are not authorized to perform this action")
          this.props.navigation.goBack();
        }
        console.log("api response 1 => ", responseJson?.errors[0]?.Request)
        this.parseApiErrorResponse(responseJson.errors);
        this.parseApiCatchErrorResponse(responseJson.errors);
      } else if (responseJson) {
        if(apiRequestCallId === this.esgAccessCallId){
          this.handleEsgPageAuth(responseJson)
        }
        else if (apiRequestCallId === this.apiGetAllUsersCallId) {
          this.handleGetUsersAPIResponse(responseJson);
        } else if (apiRequestCallId === this.apiGetAllRequestTypeCallId) {
          this.handleGetRequestTypeAPIResponse(responseJson);
        } else if (apiRequestCallId === this.state?.userPaginateCallId) {
          if (!Array.isArray(responseJson?.data)) return
          const users = [...this.state?.users, ...responseJson.data.map((c: any) => c.attributes)]
          this.setState({ userPaginateCallId: '', userPagination: responseJson?.meta?.pagination, users })
        } else if (apiRequestCallId === this.state?.userSearchCallId) {
          let users: any = []
          if (Array.isArray(responseJson?.data))
            users = responseJson.data.map((c: any) => c.attributes)
          this.setState({ userSearchCallId: '', userPagination: responseJson?.meta?.pagination, users })
        }
        else if (apiRequestCallId === this.state?.submitRequestCallId) {
          toast("Submit request successfully", { type: 'success' });
          this.setState({ openModal: false, submitRequestCallId: '' });
          if (responseJson?.data)
            sendRequestCreateMessage(responseJson.data)
          this.props.navigation.goBack();

        } else if (apiRequestCallId === this.requestDetailCallId) {

          if (!responseJson?.data?.attributes) return;

          this.setFormValues({
            ...responseJson.data.attributes,
            id: responseJson.data.id,
            isViewRequest: true,
          })
        } else if (apiRequestCallId === this.state.respondRequestCallId) {
          console.log("api response => ", responseJson.errors);
          this.setState({ respondRequestCallId: '', openModal: false })
          toast("Response Submitted", { type: 'success' });
          sendRequestRespondMessage(this.state.formData?.id, responseJson.data, this.state.formData?.status)
          // this.onCloseModal()
        } else if (apiRequestCallId === this.corpProfileSumbitCallId) {
          this.setState({loading: false})
          console.log("response", responseJson);
          toast("Response Submitted", { type: 'success' });
          this.props.navigation.goBack();
        }
        else if(apiRequestCallId === this.deleteCertifictionCallId) {
          toast.success("Achieved certification deleted successfully")
        }
        else if (apiRequestCallId === this.getCorporateDataId) {
          this.setState({
            corporateProfileID: responseJson?.data?.id,
            corporateProfileData: responseJson?.data?.attributes,
            corporateProfilePic: responseJson?.data?.attributes?.logo,
            corporateCoverPic: responseJson?.data?.attributes?.cover_pic,
            cetificateTempData: responseJson?.data?.attributes?.archived_certifications,
            loading: false,
          }, () => {
            const { corporateProfileData } = this.state
            let Obj: any = {
              showResponce: false,
              title: corporateProfileData?.tagline || "",
              description: corporateProfileData?.business_description || "",
              workforce: corporateProfileData?.part_time_workforce_percent || "",
              request_type_id: 0,
              // account_ids: this.initialType,
              sender_id: 0,
              // created_by_id: this.initialType,
              response: '',
              reference_link: '',
              company_name: corporateProfileData?.company_name || "",
              company_website: corporateProfileData?.website_link || "",
              company_region: corporateProfileData?.region || "",
              company_email: corporateProfileData?.email || "",
              business_phone: corporateProfileData?.business_phone_number|| "" ,
              company_headquaters: corporateProfileData?.headquarters || "", 
              business_country: corporateProfileData?.country || "",
              business_city: corporateProfileData?.city || "",
              business_state: corporateProfileData?.state || "",
              financial_year: corporateProfileData?.financial_year || "",
              start_date: corporateProfileData?.financial_year == null ? new Date() : corporateProfileData?.financial_year.split("-")[0] ,
              end_date:corporateProfileData?.financial_year == null ? new Date() : corporateProfileData?.financial_year.split("-")[1],
              sector: corporateProfileData?.sector || "",
              industry: corporateProfileData?.industry || "",
              sub_industry: corporateProfileData?.sub_industry || "",
              turnover: corporateProfileData?.average_turnover || "",
              full_time_employees: corporateProfileData?.fulltime_employees || "",
              part_time_employees: corporateProfileData?.part_time_employees || "",
              company_status: corporateProfileData?.company_status || "",
              ownership_type: corporateProfileData?.ownership_type || "",
              Shareholding_Pattern: corporateProfileData?.shareholding_pattern || "",
              product: corporateProfileData?.investment_type || "",
              Other: corporateProfileData?.other_investment_type || "",
              operating_expence: corporateProfileData?.key_financial_metrics?.total_operating_million_expenses || "" ,
              facilities: corporateProfileData?.facilities || "",
              subsidiaries: corporateProfileData?.subsidiaries || "" ,
              owners_women: corporateProfileData?.woman_owner_percent|| "",
              owners_indigenuos: corporateProfileData?.indigenous_owner_percent|| "",
              owners_minorities: corporateProfileData?.minorities_owner_percent|| "",
              orgainsation_purpose: corporateProfileData?.vision?.purpose || "",
              orgainsation_vision: corporateProfileData?.vision?.vision || "",
              orgainsation_mission: corporateProfileData?.vision?.mission || "",
              orgainsation_values: corporateProfileData?.vision?.values || "",
              messageCeo: corporateProfileData?.vision?.message_from_ceo || "",
              // certification_description: "" ,
              market_capitalization: corporateProfileData?.key_financial_metrics?.market_capitalisation || "",
              net_sales: corporateProfileData?.key_financial_metrics?.net_sales_million || "",
              operating_income: corporateProfileData?.key_financial_metrics?.operating_income || "",
              total_debt: corporateProfileData?.key_financial_metrics?.total_debt || "",
              research_devlopment: corporateProfileData?.key_financial_metrics?.res_dev_related_expences || "",
              free_cash_flow: corporateProfileData?.key_financial_metrics?.free_cash_flow || "",
              patent_filed: corporateProfileData?.key_financial_metrics?.patents_filed || "",
              net_income: corporateProfileData?.key_financial_metrics?.net_income_attributable || "",
              per_share_basic: corporateProfileData?.key_financial_metrics?.per_share_basic_actual || "",
              per_share_diluted: corporateProfileData?.key_financial_metrics?.per_share_diluted_actual || "",
              capital_expences: corporateProfileData?.key_financial_metrics?.capital_expenditures_million || "",
              global_giving: corporateProfileData?.key_financial_metrics?.total_global_giving || "",
              in_kind_donations: corporateProfileData?.key_financial_metrics?.total_in_kind_donation || "",
              cash_donations: corporateProfileData?.key_financial_metrics?.total_cash_donation || "",
              million_tax_income: corporateProfileData?.key_financial_metrics?.provision_for_million_income_taxes || "",
              payments_to_provider: corporateProfileData?.key_financial_metrics?.payments_to_provider_capital || "",
              profit_margin: corporateProfileData?.key_financial_metrics?.net_profit_margin || "",
              gross_margin: corporateProfileData?.key_financial_metrics?.gross_profit_margin || "",
              roe: corporateProfileData?.key_financial_metrics?.roe || "",
              roce: corporateProfileData?.key_financial_metrics?.roce || "",
              debt_ratio: corporateProfileData?.key_financial_metrics?.debt_equity_ratio || "",  
              current_ratio: corporateProfileData?.key_financial_metrics?.current_ratio || "",
            }
            // cetificateTempData: corporateProfileData?.archived_certifications,
            this.initialValues = { ...Obj }
            
            
          })
          
          let arr = responseJson?.data?.attributes?.archived_certifications || []
          let arr1 = arr.map((el:any)=>{return  {
            certificationName: el.title,
            certification_id: el.id,
            certificationEpiryDate:  el.expire_at,
            certificationDescription: el.description,
            certificationDocument: el.document
           }})
          this.setState({cartificate_data: arr1})
          
          let sectorFilter = this.companySector.filter((item: any) =>  {
            return item == this.state.corporateProfileData?.sector
          }  )  
          let industryFilter = this.industrySelect.filter((item: any) =>  {
            return item == this.state.corporateProfileData?.industry
          })  
          let SubindustryFilter = this.industrySub.filter((item: any) =>  {
            return item == this.state.corporateProfileData?.sub_industry
          })  
          let CompanyStatusFilter = this.companyStatus.filter((item: any) =>  {
            return item == this.state.corporateProfileData?.company_status
          })  
          let OwnerShipFilter = this.Ownership_Type.filter((item: any) =>  {
            return item == this.state.corporateProfileData?.ownership_type
          })  
          let investmentTypeFilter = this.invesmentType.filter((item: any) =>  {
            return item == this.state.corporateProfileData?.investment_type
          })  
          let shareholdingFilter = this.shareHoldingPattern.filter((item: any) =>  {
            return item == this.state.corporateProfileData?.shareholding_pattern
          })  
          
          

          
          this.setState({
            otherSector: sectorFilter.length > 0 ? true : false,
            otherIndustries: industryFilter.length > 0 ? true : false,
            otherSubIndustries: SubindustryFilter.length > 0 ? true : false,
            otherCompanyStatus: CompanyStatusFilter.length > 0 ? true : false,
            otherOwnershipType: OwnerShipFilter.length > 0 ? true : false,
            otherInvestmentType: investmentTypeFilter.length > 0 ? true : false,
            otherShareholdingPattern: shareholdingFilter.length > 0 ? true : false,
           
          })

        }
        else if (errorReponse) {
          this.parseApiErrorResponse(errorReponse);
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getAddRequestMessageData = (data: any) => {
    this.setFormValues(data);
  }

  handleFilterMenuOpen = (e: React.MouseEvent) => {

  }

  setFormValues = (formData: any) => {
    console.log({ formData })
    if (!formData) return;
    this.setState({ formData });
    const {
      company_name = "",
      company_website = "",
      company_email = "",
      business_phone = "",
      company_headquaters = "",
      description = "",
      business_country = "",
      financial_year = "",
      sector = "",
      industry = "",
      sub_industry = "",
      turnover = "",
      full_time_employees = "",
      part_time_employees = "",
      company_status = "",
      ownership_type = "",
      product = "",
      Other = "",
      facilities = "",
      subsidiaries = "",
      shareholding_pattern = "",
      owners_women = "",
      owners_indigenuos = "",
      owners_minorities = "",
      title = '',
    } = formData;

    let stateData: any = {};

    this.initialValues.company_name = company_name;
    this.initialValues.description = description;
    this.initialValues.title = title;
    this.setState({
      ...stateData
    });
  }
  handleOpenMap = () => {
    this.setState({ mapVisible: true });
  };

  handleCloseMap = () => {
    this.setState({ mapVisible: false });
  };

  handleMapClick = (value: { city: string, state: string, country: string, setCity: any }) => {
    value.setCity("business_city", value.city)
    value.setCity("business_state", value.state)
    value.setCity("business_country",value.country)
    this.setState({ mapVisible: false });
    
  }
  setFilesArray = (file: any) => {
    this.setState({ filesArray: [file] });
  }

  /**
  * Check if field is disabled, set from Message
  */
  isFieldDisabled = (fieldName: string) => (this.state.formData.disable_fields || []).indexOf(fieldName) > -1

  redirectTo = (route: string, props: Props) => {
    const redirectMessageData = new Message(getName(MessageEnum.NavigationMessage));
    redirectMessageData.addData(getName(MessageEnum.NavigationTargetMessage), route);
    redirectMessageData.addData(getName(MessageEnum.NavigationPropsMessage), props);   
    this.send(redirectMessageData);
  }
  esgAccess = async (authToken:string) => {
    this.esgAccessCallId = sendAPIRequest(`${configJSON.showAllCards}`, {
      method: "GET",
      headers: {token: authToken},
    })
  }
  handleEsgPageAuth(esgData:ESGRecordData){
    let abilityResult = esgPathRecords(esgData)
    if(!abilityResult){
      let errMessage = "Sorry, You do not have access to this page"
      let backPage = "ActivityFeed"
      toast.warn(errMessage)
      this.redirectTo(backPage,this.props)   
    }
  }
  // Customizable Area End
}

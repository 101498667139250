import { BlockComponent } from "../../../framework/src/BlockComponent";
export const configJSON = require("./config");
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";

import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { sendAPIRequest } from '../../../components/src/utils';
import * as yup from 'yup'
import { toast } from 'react-toastify';
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End

}
interface S {
  // Customizable Area Start
  requestors: any[];
  accessUsers:any[];
  loadingSurveyTemplates: boolean;
  token: string;
  txtSavedValue: string;
  modal: string;
  allEmails: any[];
  filterdEmails: any[];
  deleteAccess: boolean;
  deleteAccessId: number;
  loading: boolean;
  editData: any;
  check: boolean;
  emailPagination?: any;
  requestorSearchCallId: string;
  requestorPaginateCallId?: any;
  requestorQuery: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}





export default class ESGJourneyController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  userEmailsCallId: string = '';
  allAccessUserData: string = '';
  createAccessCallId: string = '';
  deleteAccessCallId: string = '';
  searchCallId: string = '';
  showAccessCallId: string = '';
  updateAccessCallId: string = '';
  initEmailPagination: any = undefined;
  requestorSearchCallId: string = "";

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      txtSavedValue: "A",
      requestors: [],
      accessUsers:[],
      modal: '',
      allEmails: [],
      filterdEmails: [],

      loadingSurveyTemplates: false,
      token: '',
      deleteAccess: false,
      deleteAccessId: 0,
      loading: false,
      editData: null,
      check: true,
      requestorSearchCallId: "",
      requestorQuery: '',
      // Customizable Area End
    }
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.setState({loading: true})
    super.componentDidMount();
    const authToken = await getStorageData('authToken');
    console.log("check states", this.props.navigation.getParam('type'))
    if (authToken == null) {
      this.props.navigation.navigate('Home')
    }
    this.setState({token: authToken}, () => {
      this.allUserEmails()
      this.showAllAccessUser(this.props.navigation.getParam('type'),'')
    })
  }

  onBack = () => {
    this.props.navigation.goBack();
  }

  handleUpdateData = (responseJson:any) => {
          this.state?.accessUsers?.map((data:any)=> {
            if(data.id == responseJson.access.data.id){
              data.attributes = responseJson.access.data.attributes
            }
          })
          this.setState({editData: null})
  }

  handleUserEmailsResponse = (responseJson:any) => {
    const pagination = responseJson && responseJson.meta?.pagination;
    this.initEmailPagination = pagination;
    this.setState({ allEmails: responseJson && responseJson.accounts?.data, emailPagination: responseJson && responseJson.meta?.pagination });
  };
  
  handleAllAccessUserDataResponse = (responseJson:any) => {
    this.setState({ accessUsers: responseJson?.access?.data ? responseJson?.access?.data : [], loading: false });
    console.log("adjsk", responseJson);
  };
  
  handleCreateAccessResponse = (responseJson:any) => {
    const newAccessUser = responseJson.access.data;
    this.setState({ accessUsers: [...(this.state.accessUsers || []), newAccessUser], modal: '', loading: false });
    toast.success("Access Created Successfully");
  };
  
  handleDeleteAccessResponse = (responseJson:any) => {
    console.log("access deleted");
    const accessUsers = this.state?.accessUsers?.filter((data) => data.id !== this.state.deleteAccessId);
    this.setState({ deleteAccess: false, accessUsers });
    toast.success('Deleted Successfully');
  };
  
  handleSearchResponse = (responseJson:any) => {
    const accessUsers = responseJson?.access?.data;
    this.setState({ accessUsers });
  };
  
  handleShowAccessResponse = (responseJson:any) => {
    this.setState({ editData: responseJson?.access?.data });
    const concatData = [
      {
        id: responseJson.access.data.attributes.user_id,
        type: "email_ability",
        attributes: {
          id: responseJson.access.data.attributes.user_id,
          email: responseJson.access.data.attributes.email,
          first_name: "Test ",
          company_name: {
            id: responseJson.access.data.attributes.id,
            account_id: responseJson.access.data.attributes.user_id,
            company_name: responseJson.access.data.attributes.company_name,
            created_at: responseJson.access.data.attributes.created_At,
            updated_at: responseJson.access.data.attributes.updated_at
          }
        }
      }]
      this.setState({modal:'create', allEmails: [...concatData, this.state.allEmails]})
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    console.log("rece-self");

    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.errors) {
        this.parseApiErrorResponse(responseJson.errors);
        this.parseApiCatchErrorResponse(responseJson.errors);
      }
      switch (apiRequestCallId) {
        case this.userEmailsCallId:
          this.handleUserEmailsResponse(responseJson);
          break;
        case this.allAccessUserData:
          this.handleAllAccessUserDataResponse(responseJson);
          break;
        case this.createAccessCallId:
          this.handleCreateAccessResponse(responseJson);
          break;
        case this.deleteAccessCallId:
          this.handleDeleteAccessResponse(responseJson);
          break;
        case this.searchCallId:
          this.handleSearchResponse(responseJson);
          break;
        case this.showAccessCallId:
          this.handleShowAccessResponse(responseJson);
          break;
        case this.state.requestorPaginateCallId:
          this.handleEmailPaginate(responseJson);
          break;
        case this.state.requestorSearchCallId:
          this.handleEmailSearchfactor(responseJson);
          break;
        default:
          break;
      }
         if(apiRequestCallId == this.updateAccessCallId){
          console.log("update=> ", responseJson, this.state?.accessUsers)
          this.handleUpdateData(responseJson)
          this.setState({loading: false, modal: ''})
          toast.success('Updated Successfully');
        }
      
    }
    // Customizable Area End

  }

  // Customizable Area Start
  validationSchema = yup.object().shape({
    email_id: yup.string().required("Email is required"),
    access: yup.string().required("Access is required"),
  })

  handleSearch = async (e:any) => {
    setTimeout(()=> {
      this.setState({loading:true})
    },1000)
    this.setState({loading: true})
    this.searchCallId = sendAPIRequest(`${configJSON.accessUser}?type=${this.props.navigation.getParam('type')}&search=${e.target.value}`,{
      method: 'GET',
      headers: {token: await getStorageData('authToken')},
    })
    setTimeout(()=> {
      this.setState({loading:false})
    },3000)
  }

  handleClose = () => {
    this.setState({modal: '', editData: null})
  }

  allUserEmails = async () => {
    this.userEmailsCallId = sendAPIRequest(`${configJSON.getEmailData}`, {
      method: "GET",
      headers: {
        token: await getStorageData('authToken'),
      },
    })
  }

  showAllAccessUser = async (type:any,searchQuery:any) => {
    const authToken = await getStorageData("authToken");
    this.allAccessUserData = sendAPIRequest(`${configJSON.accessUser}?type=${type}&search=${searchQuery}`, {
      method: "GET",
      headers: {
        token: authToken,
        'content-type': 'appliction/json'
      },
    })
  }

  handleEdit = async(id:number) => {
    const authToken = await getStorageData("authToken");
    this.showAccessCallId = sendAPIRequest(configJSON.showAbility.replace(':id', id),{
      method: 'GET',
      headers: { token: authToken},
    })
  }

  submitAccess = async(data:any) => {
    const cardId = await getStorageData("card_id");
    this.state?.accessUsers?.map((x:any)=> {
      if(x.attributes.user_id == data.email_id){
        this.setState({check: false})
      }
    })
    const account = this.state.allEmails?.filter((email)=> email?.id == data?.email_id)
    const body = new FormData();
    console.log("submit " , account)
    body.append('ability[email]', account[0]?.attributes?.email)
    body.append('ability[ability_card]', this.props.navigation.getParam('type'))
    body.append('ability[ability_type]', data?.access)
    body.append('ability[alloted_by_id]', this.context.id)
    body.append('ability[account_id]', data?.email_id)
    body.append('ability[company_name]', this.context.company_name)
    body.append('ability[card_id]', cardId || '')


    if(this.state.editData){
      console.log("inside edit condition ")
      const authToken = await getStorageData("authToken");
      this.setState({loading:true})
      this.updateAccessCallId = sendAPIRequest(`${configJSON.updateAbility.replace(':id', this.state.editData.id)}?ability=${data?.access}`,{
        method: 'PUT',
        headers: {token: authToken},
      })
    }
    else{
      if(this.state.check){
        console.log("inside submit condition")
        const authToken = await getStorageData("authToken");
      this.setState({loading:true})
      this.createAccessCallId = sendAPIRequest(configJSON.addAbilityData, {
        method: 'POST',
        headers: {token: authToken},
        body
      })
    }
    else{
      toast.warn("This user has already got access");
      this.setState({check: true})
    }
    }
  }

  openDeletePopup = (id: number) => () => {
    this.setState({deleteAccess: true})
    this.setState({deleteAccessId: id});
  }

  closeDeletePopup = () => {
    this.setState({deleteAccess: false})
  }

  deleteAccess  = (id:number) => async() => {
    const authToken = await getStorageData("authToken");
    this.deleteAccessCallId = sendAPIRequest(configJSON.deleteAbility.replace(':id', id), {
      method: 'DELETE',
      headers: {token: authToken}
    })
  }

  handleEmailSearch = (query: string) => {
    
    if (query)
        this.setState({
            requestorSearchCallId: this.handleRequestorUserSearch(query),
            requestorQuery: query,
            requestorPaginateCallId: ''
        })
    else
        this.setState({
            allEmails: this.state.allEmails,
            emailPagination: this.initEmailPagination,
            requestorQuery: '',
            requestorSearchCallId: this.handleRequestorUserSearch(query),
        })
      
}

handleEmailSearchfactor(responseJson:any) {
  if (!Array.isArray( responseJson.accounts && responseJson.accounts.data))
    this.setState({
      requestorSearchCallId: "",
      allEmails: [],
      emailPagination: undefined,
    });
  else {
    const allEmails = responseJson.accounts.data;
    this.setState({
      requestorSearchCallId: "",
      allEmails,
      emailPagination: responseJson.meta?.pagination,
    });
  }
}

  handleRequestorUserSearch = (query: string, page?: number) => {
    console.log("get query =", query)
    const { token } = this.state
    const params = new URLSearchParams()
    query && params.append('query', query)
    page && params.append('page', String(page))
    const url = configJSON.getEmailData + "?" + params.toString()
    return sendAPIRequest(url, { method: 'GET', headers: { token } })
  }

  paginateEmails = () => {
    console.log( "get query page ", )
    if (this.state.requestorSearchCallId !== '') return
    const { next_page } = this.state.emailPagination
    this.setState({ requestorPaginateCallId: this.handleRequestorUserSearch(this.state.requestorQuery, next_page) })
  }

  filterUnique = (items: any[]) => {
    const hashmap = new Set()
    return items.filter((i: any) => {
        if (hashmap.has(i.id)) return false
        hashmap.add(i.id)
        return hashmap
    })
  }

  handleEmailPaginate(responseJson:any) {
    if (Array.isArray(responseJson.accounts.data)) {
      const options = responseJson.accounts.data;
      const allEmails = this.filterUnique(this.state.allEmails.concat(options));
      this.setState({
        allEmails,
        emailPagination: responseJson.meta?.pagination,
      });
    }
  }


  handleEmailClose = () => {
  this.setState({
      requestorSearchCallId: '',
      requestorQuery: '',
      requestorPaginateCallId: '',
      allEmails: this.state.allEmails,
      emailPagination: this.initEmailPagination,
  })
}

// Customizable Area End
}

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from 'react'
import { toast } from 'react-toastify';
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  onChange?: (query: string) => void;
  favouriteable_type?: string;
  onQueryChange?: (query: URLSearchParams) => void;
  filterAll?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  data: any;
  arrayHolder:any
  filterMenuPosition: {
    top: number, 
    left: number,
  } | undefined;
  selectingDate?: boolean;
  filterFromDate?: any;
  filterToDate?: any ;
  filterText: string;
  filterQuery: string;
  filterByMe: boolean;
  filterByFav: boolean;
  filterCategories: number[];
  filterSubCategories: any[];
  categories: any[];
  subCategories: any[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class FilterPostsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProductApiCallId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.GetAllCategoriesResponseMessage),
      getName(MessageEnum.GetAllSubCategoriesResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      data: "",
      arrayHolder:[],
      filterMenuPosition: undefined,
      selectingDate: false,
      filterText: '',
      filterQuery: '',
      filterByMe: false,
      filterByFav: false,
      filterFromDate: null,
      filterToDate: null,
      filterCategories: [],
      filterSubCategories: [],
      categories: [],
      subCategories: [],
      // Customizable Area End
    };
    // Customizable Area Start
    this.filterRef = React.createRef()
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    let authToken = await getStorageData("authToken")
    this.setState({token: authToken}, () => {
    this.loadCategoriesSubCategories()
    });
    // Customizable Area End
  }
  
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getProductApiCallId != null &&
      this.getProductApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setState({arrayHolder:responseJson.data})
        let params = this.props.navigation.state.params;
        if (params !== undefined) {
          if(params.data){
            if(params.data.length > 0){
              this.setState({ data: params.data });
            }else{
              this.showAlert("Error", "No data Found")  
            }
          }
        } else {
          this.setState({
            data: responseJson.data
          });
        }
       
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.showAlert("Error", errorReponse)  
        this.parseApiCatchErrorResponse(errorReponse);
      }
    } else if (message.id === getName(MessageEnum.GetAllCategoriesResponseMessage)) {
      console.log("categories", message)
      const categories = message.getData(
        getName(MessageEnum.AllCategoriesDataMessage)
      )
      this.setState({ categories })
    } else if (message.id === getName(MessageEnum.GetAllSubCategoriesResponseMessage)) {
      console.log("sub-categories", message)
      
      const subCategories = message.getData(
        getName(MessageEnum.AllSubCategoriesDataMessage)
      )
      this.setState({ subCategories })
    }
    // Customizable Area End
  }

  // Customizable Area Start
  filterRef?: any;
  handleFilterMenuClose = () => {
    this.setState({ filterMenuPosition: undefined })
    // this.filterPosts()
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };
  navigateToFilter = () => {
      let arrayHolder = this.state.arrayHolder;
      const priceRange= arrayHolder.map((element:any) => element.attributes.price);
      var min = Math.min.apply(null, priceRange);
      var max = Math.max.apply(null, priceRange);
      let params = this.props.navigation.state.params ;
      if(params != undefined){
        if(params.priceSelectedMin && params.priceSelectedMax){
          this.props.navigation.push("Filteroptions" , {min: min , 
            max:max , 
            priceSelectedMin:params.priceSelectedMin,
            priceSelectedMax:params.priceSelectedMax
          });
        }    
      }else{
        this.props.navigation.push("Filteroptions" , {min: min , max:max});
      }
  }

  handleCategorySelect = (id: number) => (e: any ) => {
    e.preventDefault()
    e.stopPropagation()
    if(this.state.filterCategories.indexOf(id)===-1){
      // add id
      this.setState({...this.getBlankFilters(), filterCategories: [...this.state.filterCategories, id]}, ()=>this.filterPosts())
    } else {
      // remove id
      this.setState({...this.getBlankFilters(), filterCategories: this.state.filterCategories.filter((x: number) => x !== id)}, ()=>this.filterPosts())
    }
  }
  
  handleTagSelect = (id: number) => () => {
    if(this.state.filterSubCategories.indexOf(id)===-1){
      this.setState({...this.getBlankFilters(), filterSubCategories: [...this.state.filterSubCategories, id] }, ()=>this.filterPosts())
    } else {
      this.setState({...this.getBlankFilters(), filterSubCategories: this.state.filterSubCategories.filter((x: number) => x!== id ) }, ()=>this.filterPosts())
    }
  }

  handleFilterMenuOpen = (e: React.MouseEvent) => {
    if(!this.filterRef) return ;
    const box = this.filterRef.current.getBoundingClientRect();
    this.setState({ filterMenuPosition: {
      top: box.bottom,
      left: box.left+20
    }})
  }

  filterPosts = () => {
    const query = new URLSearchParams()
    if (this.state.filterCategories.length) query.append('category_ids', this.state.filterCategories.join(','))
    if (this.state.filterSubCategories.length) query.append('tag', this.state.filterSubCategories.join(','))
    if(this.state.filterFromDate) query.append('from_date', this.state.filterFromDate)
    if(this.state.filterToDate) query.append('to_date', this.state.filterToDate)
    if(this.state.filterQuery) query.append('query', this.state.filterQuery)
    if(this.state.filterByMe) query.append('created_by_me', this.state.filterByMe.toString())
    if(this.state.filterByFav) query.append('favouriteable_type', this.props.favouriteable_type || 'BxBlockPosts::Post')


    const queryStr = query.toString()
    this.props.onChange && this.props.onChange(queryStr)
    this.props.onQueryChange && this.props.onQueryChange(query)
  }

  toggleFilterByMe = () => this.setState({ ...this.getBlankFilters(), filterByMe: !this.state.filterByMe, filterMenuPosition: undefined }, ()=>this.filterPosts())
  toggleFilterByFav = () => this.setState({ ...this.getBlankFilters(),filterByFav: !this.state.filterByFav, filterMenuPosition: undefined }, () => this.filterPosts())

  filterText = () => {
    let filterText = "";
    if(this.state.filterFromDate || this.state.filterToDate) {
      filterText += `Date(${this.state.filterFromDate?.toISOString()?.slice(0,10) || '-'} to ${this.state.filterToDate?.toISOString()?.slice(0,10) || '-'} ) `
    } 
    
    if (this.state.filterCategories?.length) {
      const cats = this.state.filterCategories.map(this.getCategoryById)
      filterText += `Categories: ${cats.join(',')} `
    }
    if(this.state.filterByMe) filterText += 'Created By Me '
    if(this.state.filterByFav) filterText += "Favourite"
    if (this.state.filterSubCategories?.length) {
      const tags = this.state.filterSubCategories.map(this.getSubCategoryById)
      filterText += `Tags: ${tags.join(',')}`
    }

    return filterText
  }

  clearFilters = (e: React.MouseEvent) => {
    e.stopPropagation()
    this.setState({
      filterFromDate: null,
      filterToDate: null,
      filterCategories: [],
      filterSubCategories: [],
      filterByMe: false,
      filterByFav: false,
      filterQuery: '',
      // feedPosts: [],
    })
    // load posts with no filters
    this.props.filterAll();
  }


  clearFromDate = () => {
    this.setState({filterFromDate: null})
  }

  clearToDate = () => {
    this.setState({filterToDate: null})
  }

  handleDateInputFocus = () => {
    this.setState({ selectingDate: true })
  }
  handleDateInputBlur = () => (this.setState({ selectingDate: false }))

  handleFromDateChange = (date: any) => {
    this.setState({ ...this.getBlankFilters(), filterToDate: this.state.filterToDate, filterFromDate: date })
  }

  handleToDateChange = (date: any) => {
    this.setState({ ...this.getBlankFilters(), filterFromDate: this.state.filterFromDate, filterToDate: date })
  }

  handleDateFilterSubmit = () => {
    if(this.state.filterFromDate && this.state.filterToDate) {
      this.setState({ filterMenuPosition: undefined })
      this.filterPosts()
    }
    else{
      toast.warn("Please fill start and end date")
    }
  }

  handleDateFilterCancel = () => {
    this.setState({ filterMenuPosition: undefined })
  }

  handleQueryChange = (filterQuery: string) => {
    this.setState({ filterQuery }, () => this.filterPosts())
  }

  getBlankFilters = () => {
    return {
      filterFromDate: null,
      filterToDate: null,
      filterCategories: [],
      filterSubCategories: [],
      filterByMe: false,
      filterByFav: false,
    }
  }
  getCategoryById = (id: number) => {
    const cat = this.state.categories.find(c => c.id === id)
    if (cat) return cat.name
    return ""
  }

  getSubCategoryById = (id: number) => {
    const cat = this.state.subCategories.find(c => c.id === id)
    if (cat) return cat.name
    return ""
  }
  loadCategoriesSubCategories = () => {
    const msg = new Message(
      getName(MessageEnum.GetAllCategoriesMessage)
    )
    runEngine.sendMessage(msg.id, msg)

    const msg2 = new Message(
      getName(MessageEnum.GetAllSubCategoriesMessage)
    )
    runEngine.sendMessage(msg2.id, msg2)
  }
  // Customizable Area End
}

import React from 'react'
import { 
  Typography, 
  CircularProgress, 
  } from '@material-ui/core'

export function LoadingSpinner() {
	return <div style={{ 
		display: 'flex', 
		margin: '40px 0', 
		alignItems: 'center', 
		justifyContent: 'center', 
		flexDirection: 'column', 
		gap:'10px', }}
	>
		<CircularProgress />
		<Typography>Loading</Typography>
	</div>
}
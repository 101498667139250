Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CommunityForum";
exports.labelBodyText = "CommunityForum Body";


exports.createCommunityEndpoint = 'bx_block_communityforum/community'
exports.listCommunitiesEndpoint = "bx_block_communityforum/community"
exports.getUsersEndpoint = "account_block/accounts/filter"
exports.getUserRoleEndpoint = "account_block/accounts/get_user_role"
exports.getSampleExcelEndpoint = "bx_block_communityforum/community/sample_file"
exports.importSampleCsvEndpoint = "bx_block_communityforum/community/import_file"
exports.followCommunityEndpoint = "bx_block_communityforum/community_joins/join"
exports.followPvtCommunityEndpoint = "bx_block_communityforum/community_joins"
exports.updateCommunityEndpoint = "bx_block_communityforum/community/:id"
exports.addMembersEndpoint = "bx_block_communityforum/community/:id/add_member"
exports.getCommunityDetailEndpoint = "bx_block_communityforum/community/:id"
exports.getMyCommunitiesndpoint = "bx_block_communityforum/community/my_communities/"
exports.listCommunityMembersEndpoint = "bx_block_communityforum/community/:id/users"
exports.listCommunityModeratorsEndpoint = "bx_block_communityforum/community/:id/moderators"
exports.exportModeratorsEndpoint = "/bx_block_communityforum/community/:id/moderators/export"
exports.exportMembersEndpoint = "/bx_block_communityforum/community/:id/users/export"
exports.btnExampleTitle = "CLICK ME";
// Customizable Area End
import React, { useCallback, useEffect, useRef, useState } from 'react';



// utils
export const LINKEDIN_OAUTH2_STATE = 'linkedin_oauth2_state';

export function parse(search: any) {
  const query = search.substring(1);
  const vars = query.split('&');
  const parsed: any = {};
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (pair.length > 1) {
      parsed[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
    }
  }
  return parsed;
}

// end of utils


// types
export interface useLinkedInType {
  redirectUri: string;
  clientId: string;
  onSuccess: (code: string) => void;
  onError?: ({
    error,
    errorMessage,
  }: {
    error: string;
    errorMessage: string;
  }) => void;
  state?: string;
  scope?: string;
  closePopupMessage?: string;
}

export interface LinkedInType extends useLinkedInType {
  children: ({ linkedInLogin }: {linkedInLogin: any}) => JSX.Element;
}

//// end of types


// useLinkedin
const getPopupPositionProperties = ({ width = 600, height = 600 }) => {
  const left = screen.width / 2 - width / 2;
  const top = screen.height / 2 - height / 2;
  return `left=${left},top=${top},width=${width},height=${height}`;
};

const generateRandomString = (length = 20) => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export function useLinkedIn({
  redirectUri,
  clientId,
  onSuccess,
  onError,
  scope = 'r_liteprofile r_emailaddress',
  state = '',
  closePopupMessage = 'User closed the popup',
}: useLinkedInType) {
  const popupRef = useRef<Window|null>(null);
  const popUpIntervalRef = useRef<number|null>(null);

  const receiveMessage = useCallback(
    (event: MessageEvent) => {
      const savedState = localStorage.getItem(LINKEDIN_OAUTH2_STATE);
      if (event.origin === window.location.origin) {
        if (event.data.errorMessage && event.data.from === 'Linked In') {
          // Prevent CSRF attack by testing state
          if (event.data.state !== savedState) {
            popupRef.current && popupRef.current.close();
            return;
          }
          onError && onError(event.data);
          popupRef.current && popupRef.current.close();
        } else if (event.data.code && event.data.from === 'Linked In') {
          // Prevent CSRF attack by testing state
          if (event.data.state !== savedState) {
            console.error('State does not match');
            popupRef.current && popupRef.current.close();
            return;
          }
          onSuccess && onSuccess(event.data.code);
          popupRef.current && popupRef.current.close();
        }
      }
    },
    [onError, onSuccess],
  );

  // comment out for not working in chrome
  /*useEffect(() => {
    return () => {
      window.removeEventListener('message', receiveMessage, false);

      if (popupRef.current) {
        console.log('145')
        popupRef.current.close();
        popupRef.current = null;
      }
      if (popUpIntervalRef.current) {
        console.log('1451')
        window.clearInterval(popUpIntervalRef.current);
        popUpIntervalRef.current = null;
      }
    };
  }, [receiveMessage]);*/

  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);
    return () => {
      window.removeEventListener('message', receiveMessage, false);
    };
  }, [receiveMessage]);

  const getUrl = () => {
    const scopeParam = `&scope=${encodeURI(scope)}`;
    const generatedState = state || generateRandomString();
    localStorage.setItem(LINKEDIN_OAUTH2_STATE, generatedState);
    const linkedInAuthLink = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}${scopeParam}&state=${generatedState}`;
    return linkedInAuthLink;
  };

  const linkedInLogin = () => {
    popupRef.current?.close();
    popupRef.current = window.open(
      getUrl(),
      '_blank',
      getPopupPositionProperties({ width: 600, height: 600 }),
    );

    if (popUpIntervalRef.current) {
      window.clearInterval(popUpIntervalRef.current);
      popUpIntervalRef.current = null;
    }
    popUpIntervalRef.current = window.setInterval(() => {
      try {
        if (popupRef.current && popupRef.current.closed) {
          popUpIntervalRef.current && window.clearInterval(popUpIntervalRef.current);
          popUpIntervalRef.current = null;
          if (onError) {
            onError({
              error: 'user_closed_popup',
              errorMessage: closePopupMessage,
            });
          }
        }
      } catch (error) {
        console.error(error);
        popUpIntervalRef.current && window.clearInterval(popUpIntervalRef.current);
        popUpIntervalRef.current = null;
      }
    }, 1000);
  };

  return {
    linkedInLogin,
  };
}

// end of useLinkedin

export function LinkedIn({
  children,
  redirectUri,
  clientId,
  onSuccess,
  onError,
  state,
  scope,
  closePopupMessage,
}: LinkedInType) {
  const { linkedInLogin } = useLinkedIn({
    redirectUri,
    clientId,
    onSuccess,
    onError,
    state,
    scope,
    closePopupMessage,
  });
  return children({ linkedInLogin });
}


// Linkedin Callback


type ParamsType = {
  state: string;
  code?: string;
  error?: string;
  error_description?: string;
};

export function LinkedInCallback() {
  const [errorMessage, setErrorMessage] = useState<string>('');
  useEffect(() => {
    const params = parse(window.location.search) as ParamsType;
    if (params.state !== localStorage.getItem(LINKEDIN_OAUTH2_STATE)) {
      setErrorMessage('State does not match');
    } else if (params.error) {
      const errorMessage =
        params.error_description || 'Login failed. Please try again.';
      window.opener &&
        window.opener.postMessage(
          {
            error: params.error,
            state: params.state,
            errorMessage,
            from: 'Linked In',
          },
          window.location.origin,
        );
      // Close tab if user cancelled login
      if (params.error === 'user_cancelled_login') {
        window.close();
      }
    }
    if (params.code) {
      window.opener &&
        window.opener.postMessage(
          { code: params.code, state: params.state, from: 'Linked In' },
          window.location.origin,
        );
    }
  }, []);

  return <div>{errorMessage}</div>;
}

// end linkedin callback
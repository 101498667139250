import React, { useState } from 'react'
import { CreateCSSProperties, styled } from '@material-ui/styles';
import { Typography } from '@material-ui/core'

import AttachmentModal from './AttachmentModal.web'
import { toast } from 'react-toastify'


const pdfIcon = require('./ic_pdf.png')
const xlsIcon = require('./ic_xlsx_icon.png')
const videoIcon = require('./ic_video_icon.png')
const imageIcon = require('./ic_image_icon.png')
const genericIcon = require('./ic_generic_file.png')
const csvIcon = require('./ic_csv_icon.jpg')
const docIcon = require('./ic_doc.png')
const downloadIcon = require('./ic_download.png')

interface File{
	url: string;
	id: number;
	filename: string;
	size: string;
	type: string;
}

interface AttachmentsProps{
	files: File[];
	label?:string;
}

interface AttachmentProps {
	file: File;
}

export default function Attachments({ files,label }: AttachmentsProps){
	if(!files.length) return <></>;
	return <div>
		<Heading><b>{label?label:'Attachments'}</b></Heading>
		<ListWrapper>
			{ 
				files.map( f => <Attachment key={f.id} file={f} /> )
			}
		</ListWrapper>
	</div>
}

const formatAttachmentName = (name: string)=> `${name?.slice(0, 20)}${name?.length>20?'...'+name.substr(name.length - 5):''}`

function fileIcon(fileType: string, filename: string) {
	console.log(fileType, 'file types')
	console.log(filename, 'file types')
	const extension = filename?.split(".").pop();
	if (fileType === 'image') {
		return imageIcon;
	} else if (fileType === 'video') {
		return videoIcon;
	} else if (fileType === 'text' || extension === 'csv') {
		return csvIcon;
	} else if (fileType === 'application'||fileType === 'application/pdf') {
		// doc, xls, pdf
		if (extension === 'pdf') {
			return pdfIcon;
		} else if (extension === 'doc' || extension === 'docx') {
			return docIcon;
		} else if (extension === 'xls' || extension === 'xlsx') {
			return xlsIcon;
		}
	} else {
		return genericIcon;
	}
}

function Attachment({ file }: AttachmentProps) {
	const [view, setView] = useState<boolean>(false)
	//const [fileIcon, setFileIcon] = useState<string>('')

	const toggleView = () => setView((v: boolean) => !v)

	
	return <Border>

		<img src={fileIcon(file.type, file.filename)} width="42px" />
		<FileInfo>
			<Typography >{formatAttachmentName(file.filename)}</Typography>
			<Typography color="textSecondary">{file?.size}</Typography>
		</FileInfo>
		<DownloadIcon onClick={()=>openUrl(file.url)} src={downloadIcon} width="25px" />
		{view && <AttachmentModal onClose={toggleView} url={file.url} />}
	</Border>
}
const openUrl = (fileUrl:string) => {
	const newTab = window.open((fileUrl), "_blank");
	if (newTab) {
	  newTab.focus();
	} else {
		toast.error("Failed to open file in a new tab.")
	}
}

const Heading = styled(Typography)({
		margin: '20px 0px 10px 0px',	
})

const ListWrapper = styled('div')({
	display: 'flex',
	gap: '35px',
	flexWrap: 'wrap'
})

const DownloadIcon = styled('img')({
	marginLeft: '35px',
	cursor: 'pointer',
})

const FileInfo = styled('div')({
	display: 'flex',
	flexDirection: 'column',
	marginLeft: '15px',
	gap: '5px',
})

const Border = styled('div')({
	border: '1px solid #999',
	padding: '10px 15px',
	borderRadius: '15px',
	display: 'flex',
	alignItems: 'center'
})
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Service, Category, CorpstageWallet } from './types'
import * as yup from 'yup'
import { toast } from 'react-toastify'
import { sendAPIRequest } from '../../../components/src/utils'
import { sendCoinsUpdatedMessage, sendNewServiceCreatedMessage, sendServiceUpdatedMessage } from './Messages'
import { useFormikContext } from "formik";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { checkServerIdentity } from "tls";
import { DangerIcon } from "./assets";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    // Customizable Area Start
    token: string;
    services: Service[];
    selectedService?: Service;
    selectedMyTab: number;
    modal: 'transfer' | 'deficient' | null;
    loadingServices: boolean;
    filterQuery: string;
    randomServiceId?: string;
    getServiceDetailCallId?: string;
    service?: any;
    submittingForm?: boolean;
    moderatorSearchCallId: string;
    moderatorOptions: any[];
    moderatorPagination?: any;
    moderatorPaginateCallId?: any;
    moderatorQuery: any;
    allUserOptions: any[],
    memberOptions: any[],
    memberPagination?: any;
    memberSearchCallId: string;
    memberPaginateCallId?: any;
    selectedItem?: any;
    loadingCorpstageCoins: boolean,
    corpstageWallet: any,
    totalSelectedCoins: number;
    coinErrors: any[];
    importLoading: boolean;
    importedUsers: any[];
    transferData: any[];
    enableBtn:boolean;
    disableBtn:boolean;
    totalCoins:any;
    selectedCountCoins:{id:number, coins:number}[];
    postUser:any[];
    // errMssge:string;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class TransferModalController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    apiServicesFilterCallId: string = ""
    apiCategoryListCallId: string = ""
    apiCreateServiceCallId: string = ""
    generateServiceIdCallId: string = ""
    favCallIdToPostId: { [key: string]: string } = {}

    moderatorSearchCallId: string = ""
    initUsersPagination: any = undefined;
    initUsersCallId: string = "";
    initUsersList: any[] = [];
    coinsWalletRequestId: string = "";
    transferCoinsId: string = "";
    importCsvCallId: string = "";


    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            // Customizable Area Start
            token: '',
            services: [],
            selectedMyTab: 0,
            modal: null,
            loadingServices: false,
            filterQuery: '',
            randomServiceId: '',

            moderatorSearchCallId: "",
            moderatorOptions: [],
            moderatorQuery: '',
            allUserOptions: [],
            memberOptions: [],
            memberSearchCallId: "",
            selectedItem: [],
            loadingCorpstageCoins: false,
            corpstageWallet: {},
            totalSelectedCoins: 0,
            coinErrors: [],
            importLoading: false,
            importedUsers: [],
            transferData: [],
            enableBtn:false,
            disableBtn:false,
            totalCoins:0,
            selectedCountCoins:[],
            postUser:[],
            // errMssge:""
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End

    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        const authToken = await getStorageData('authToken');
        if (authToken == null) {
            this.props.navigation.navigate('Home')
        } else {
            this.setState({token: authToken}, () => {
                this.loadInitialUsers()
                this.getCoinsList()
                this.getTransferData()
                this.handleEnableBtn()
            })
           
        }
        
        console.log(this.props.navigation.history.location.state, "props data:::");
        // Customizable Area End
    }
    // Customizable Area End

    async receive(from: string, message: Message) {

        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
            let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

            this.showAlert(
                "Change Value",
                "From: " + this.state.txtSavedValue + " To: " + value
            );

            this.setState({ txtSavedValue: value });
        }

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (responseJson && responseJson.errors) {
                this.parseApiErrorResponse(responseJson.errors);
                this.parseApiCatchErrorResponse(responseJson.errors);
            } else if (responseJson) {
                console.log(responseJson, "response json");
                if (apiRequestCallId === this.initUsersCallId) {
                    if (!Array.isArray(responseJson.data)) return;
                    const allUserOptions = responseJson.data.map((d: any) => d.attributes)
                    const pagination = responseJson.meta.pagination
                    this.initUsersList = allUserOptions
                    this.initUsersPagination = pagination
                    this.setState({
                        moderatorOptions: allUserOptions,
                        memberOptions: allUserOptions,
                        allUserOptions,
                        memberPagination: pagination,
                        moderatorPagination: pagination
                    })
                } else if (apiRequestCallId === this.state.moderatorPaginateCallId) {
                    if (Array.isArray(responseJson.data)) {
                        const options = responseJson.data.map((d: any) => d.attributes)
                        const moderatorOptions = this.filterUnique(this.state.moderatorOptions.concat(options))
                        this.setState({
                            moderatorOptions,
                            moderatorPagination: responseJson.meta.pagination
                        })
                    }
                } else if (apiRequestCallId === this.state.memberPaginateCallId) {
                    if (Array.isArray(responseJson.data)) {
                        const options = responseJson.data.map((d: any) => d.attributes)
                        const memberOptions = this.filterUnique(this.state.memberOptions.concat(options))
                        this.setState({
                            memberOptions,
                            memberPagination: responseJson?.meta?.pagination
                        })
                    }
                } else if (apiRequestCallId === this.state.moderatorSearchCallId) {
                    if (!Array.isArray(responseJson.data))
                        this.setState({
                            moderatorSearchCallId: '',
                            moderatorOptions: [],
                            moderatorPagination: undefined
                        })
                    else {
                        const moderatorOptions = responseJson.data.map((d: any) => d.attributes)
                        this.setState({
                            moderatorSearchCallId: '',
                            moderatorOptions,
                            moderatorPagination: responseJson.meta.pagination
                        })
                    }
                } else if (apiRequestCallId === this.state.memberSearchCallId) {
                    if (!Array.isArray(responseJson.data))
                        this.setState({ memberSearchCallId: '', memberOptions: [], memberPagination: undefined })
                    else {
                        const memberOptions = responseJson.data.map((d: any) => d.attributes)
                        this.setState({
                            memberSearchCallId: '',
                            memberOptions,
                            memberPagination: responseJson?.meta?.pagination
                        })
                    }
                } else if (apiRequestCallId === this.coinsWalletRequestId) {
                    if (responseJson?.user_wallet) {
                        console.log(responseJson, "response json :::")
                        const corpstageWallet = responseJson?.user_wallet
                        console.log(corpstageWallet, "corpstageWalletcorpstageWallet")
                        this.setState({ corpstageWallet })
                    }
                    this.setState({ loadingCorpstageCoins: false })
                } else if (apiRequestCallId === this.transferCoinsId) {
                    if (responseJson.Message) {
                        const coinsWallet = responseJson.wallet
                        toast.success(responseJson.Message);
                        this.props.navigation.history.push('/CorpsStageCoins',{isNavigated:true})
                        sendCoinsUpdatedMessage(coinsWallet)
                        setTimeout(() => {
                            this.setState({disableBtn:false})
                        }, 1000);
                    }
                    setTimeout(() => {
                        this.setState({disableBtn:false})
                    }, 1000);
                } else if (apiRequestCallId === this.importCsvCallId) {
                    if (Array.isArray(responseJson?.data)) {
                        const importIds = responseJson.data.map((d: any) => d.attributes.id)
                        const emailErrors = responseJson.meta.invalid_emails
                        console.log(emailErrors, "emailErrorsemailErrors:::")
                        const importedUsers = responseJson.data.map((d: any) => d.attributes)
                        { emailErrors?.length > 0 && toast.warn(`Could not import ${emailErrors?.length} emails, invalid or not found.`) }
                        toast.success(`${importIds.length} users imported from CSV`)
                        this.setState({
                            // importIds, 
                            importLoading: false,
                            importedUsers,
                            selectedCountCoins:[],
                            totalCoins:0
                            // importedUsers:[...this.state.importedUsers, importedUsers]
                        })
                    }
                }
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    getTransferData = async() => {
        const transferDataS= await getStorageData('transferData');
        const totalSelectedCoinsS = await getStorageData('totalSelectedCoins');
        if (transferDataS) {
            const transferData = JSON.parse(transferDataS)
            const totalSelectedCoins=JSON.parse(totalSelectedCoinsS)
            if (transferData && transferData.length > 0) {
                this.setState({ transferData, selectedCountCoins:totalSelectedCoins })
                this.totalCoinsCalc(totalSelectedCoins)
            }
        }
    }

    totalCoinsCalc = (totalSelectedCoins:any)=>{
        const totalCoins= totalSelectedCoins.reduce((total:any,b:any)=>{
            return total+b.coins
        },0)
    this.setState({totalCoins:totalCoins})
    }

    isEmpty = (str: any) => (!str?.length);

    handleTransferSubmit = async (values: any) => {
        this.setState({disableBtn:true})
        console.log(values, this.state.corpstageWallet, "value on submit:::")
        const checkError = this.customFieldArrayErrors(values)
        this.deficientCalc(values);

        const users = values?.beneficiary_ids?.map((item: any) => {
            return {
                email: item.email,
                no_of_coins: item.coins,
                comment: values.comments
            }
        });
        const body = { users };
        if ((checkError.length === 0) && (this.state.corpstageWallet.total_coin >= this.getTotalSelectedCoins(values))) {
            this.transferCoinsId = sendAPIRequest(configJSON.transferCoinsEndPoint, {
                method: 'POST',
                body,
                headers: { 'token': this.state.token, "Content-Type": "application/json", }
            })
            await removeStorageData("transferData")
            await removeStorageData("totalSelectedCoins")
        } else {
            setStorageData("transferData", JSON.stringify(users))
            setStorageData("totalSelectedCoins", JSON.stringify(this.state.selectedCountCoins))
            setTimeout(() => {
                this.setState({disableBtn:false})
            }, 1000);
        }
    }

    deficientCalc = (values: any) => {
        //@ts-ignore
        let count = this.state.corpstageWallet?.total_coin - (values.beneficiary_ids && this.getTotalSelectedCoins(values))
        if (Math.sign(count) === -1) {
            this.setState({ modal: "deficient" })
        }
    }

    customFieldArrayErrors = (values: any) => {
        let errors: any = []
        if (values && values.beneficiary_ids && values.beneficiary_ids.length > 0) {
            values.beneficiary_ids.map((item: any, index: any) => {
                if (item && (!item.coins || this.isEmpty(item.coins))) {
                    errors.push({ index, message: `No. of coins to be transferred is mandatory corresponding to each beneficiary` })
                } else if (isNaN(item.coins)) {
                    if (errors[index]) {
                        errors[index].message = "Coin must be a number"
                    } else {
                        errors.push({ index, message: 'Coin must be a number' })
                    }
                }
                else if (Number(item.coins < 1)) {
                    if (errors[index]) {
                        errors[index].message = "Coin can't be zero"
                    } else {
                        errors.push({ index, message: "Coin can't be zero" })
                    }
                }
                else {
                    delete errors[index];
                }
            })
        }
        console.log(errors, 'errorserrors')
        this.setState({ coinErrors: errors })
        return errors
    }

    errMssg = (i: number, data: any) => {
        var message = "";
        if (data && data.coins) {
            if (isNaN(data.coins)) {
                message = "Coin must be a number"
            }
            else if (Number(data.coins < 1)) {
                message = "Coin can't be zero"
            }
            return message;
        }

        let coinValue = this.state.coinErrors.find(item => item.index === i);
        if (coinValue && coinValue.message) {
            message = coinValue.message;
        }
        return message;
    }

    hideModal = async() => { this.props.navigation.navigate('CorpsStageCoins'); await removeStorageData("transferData"); await removeStorageData("totalSelectedCoins"); }

    hideDeficientModal = () => { this.setState({ modal: null }) }

    handleUserSearch = (query: string, page?: number) => {
        console.log({ page })
        const { token } = this.state
        const params = new URLSearchParams()
        query && params.append('query', query)
        page && params.append('page', String(page))
        const url = configJSON.getUsersEndpoint + "?" + params.toString()
        return sendAPIRequest(url, { method: 'GET', headers: { token } })
    }

    paginateModerators = () => {
        if (this.state.moderatorSearchCallId !== '') return
        const { next_page } = this.state.moderatorPagination
        this.setState({ moderatorPaginateCallId: this.handleUserSearch(this.state.moderatorQuery, next_page) })
    }

    handleModeratorSearch = (query: string) => {
        console.log({ query }, "query::")
        if (query)
            this.setState({
                moderatorSearchCallId: this.handleUserSearch(query),
                moderatorQuery: query,
                moderatorPaginateCallId: ''
            })
        else
            this.setState({
                moderatorOptions: this.state.allUserOptions,
                moderatorPagination: this.initUsersPagination,
                moderatorQuery: '',
                moderatorSearchCallId: '',
            })
    }

    handleModeratorClose = () => {
        this.setState({
            moderatorSearchCallId: '',
            moderatorQuery: '',
            moderatorPaginateCallId: '',
            moderatorOptions: this.state.allUserOptions,
            moderatorPagination: this.initUsersPagination,
        })
    }

    loadInitialUsers = () => {
        const { token } = this.state
        this.initUsersCallId = sendAPIRequest(
            configJSON.getUsersEndpoint,
            { method: 'GET', headers: { token } }
        )
    }

    filterUnique = (items: any[]) => {
        const hashmap = new Set()
        return items.filter((i: any) => {
            if (hashmap.has(i.id)) return false
            hashmap.add(i.id)
            return hashmap
        })
    }

    handlePurchaseCoins = () => {
        this.props.navigation.navigate("PurchaseCoins");
    }

    validationSchema = yup.object().shape({
        beneficiary_ids: yup.array().min(1, "Select atleast one Member"),
        //   .of(
        //     yup.object().shape({
        //       coins: yup.string().required('Required'), // these constraints take precedence
        //     })
        //   )
        // .required('Must have friends') // these constraints are shown if and only if inner constraints are satisfied,
        comments: yup.string().label('Comments').required().max(1000)
    });

    handleEnableBtn=async()=>{
        const transferDataS= await getStorageData('transferData');

        if(transferDataS){
            this.setState({enableBtn:true})
        }
    }

    getCoinsList = () => {
        this.setState({ loadingCorpstageCoins: true })
        let url = configJSON.coinsListEndPoint
        this.coinsWalletRequestId = sendAPIRequest(url, {
            method: "GET",
            headers: { 'token': this.state.token },
        })
       this.setState({postUser:this.props.navigation.history.location.state}) 
    }

    getTotalSelectedCoins = (values: any) => {
        let beneficiaries = values.beneficiary_ids
        let test: any[] = []
        let total = 0;
        beneficiaries?.forEach((f: any, i: number) => {
            test.push(parseInt(f?.coins));
            total = test.reduce((a: any, b: any) => a + b, 0)
        })
        this.setState({ totalSelectedCoins: total })
        return total
    }

    handleBlur=(val:number, index:number)=>{
        let arr:any[]=[]
         const  existCoins= this.state.selectedCountCoins.find((item)=>{
                return  item.id===index
            })
            console.log(existCoins, "existCoins:::");
            if(existCoins){
              arr= this.state.selectedCountCoins.map((item)=>{
                if(item.id==index){
                    item.coins=val
                    return item;
                }
                return item
              })  
            }else{
                arr=[...this.state.selectedCountCoins, {id:index, coins:val}]
            }
      
        this.setState({selectedCountCoins:arr})
        const total= arr.reduce((total:any,b:any)=>{
                console.log(total, b.coins, "ab ::::")
                return total+b.coins
            },0)
        console.log(total, "total:::")
        this.setState({totalCoins:total})
    }

    importCsv = (files: File[]) => {
        console.log(files, "files?:::")
        // let fileObj=e.target.files
        // console.log(fileObj, "onj:::")
        const file = Array.from(files)[0]
        if (file) {
            const { token } = this.state
            const body = new FormData()
            //@ts-ignore
            body.append('file', file)
            this.importCsvCallId = sendAPIRequest(configJSON.importCsvEndpoint, {
                method: 'POST',
                body,
                headers: { token }
            })
            this.setState({ importLoading: true })
            // setTimeout(() => {
            //     e.target.files=''
            //     console.log( e.target.files, "check dile::")
            // }, 500);
        }
    }

    handleRemoveField = (arrayHelpers: any, us: any, index: number) => {
        //removing field
        arrayHelpers.remove(index)
        const filtered=this.state.importedUsers.filter((item,i)=>index!==i)
        this.setState({importedUsers:filtered})

        //removing csv target file
        const file = document.querySelector('.userFile');
        console.log(file, "file::::")
        //@ts-ignore
        file.value = '';

        var node = document.getElementById("csvID");
        const input = document.querySelector('input')  
        //@ts-ignore
        console.log(node.value, "node::", input)

        //sum up coins on removing
        const onRemove=this.state.selectedCountCoins.filter((item:any)=>item.id!==index)
        const updateId = onRemove.map((item)=>{
            if(item.id>=index){
                item.id=item.id-1
                return item
            }
                return item
        })
        this.setState({selectedCountCoins:updateId})
        this.totalCoinsCalc(updateId)
    }


    // onSelectInput = (selectedItem: any) => {
    //     console.log(selectedItem, "selected item console:::")
    //     if (selectedItem !== undefined) {
    //         // let result = this.state.moderatorOptions.filter((o1: any) => selectedItem?.some((o2: any) => o2 === o1.id));
    //         // this.setState({ selectedItem: result })
    //         this.setState({ selectedItem })
    //     }
    //     console.log(this.state.selectedItem, "selected item con::")
    // }

    // getEmail=(id:any)=>{
    //     console.log(id,"idf")
    //     if(id){
    //         let result = this.state.moderatorOptions.find((o1: any) => o1.id===id);
    //         console.log(result.email, "email resyult>>")
    //         if(result){
    //             return result.email;
    //         }else{
    //         return;
    //         }
    //     }
    // }

    // Customizable Area End

}


import React from "react";

// Customizable Area Start
import {
  
    TextField,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
   
    InputAdornment,
    Menu,
    MenuItem,
    Paper,
  
    Hidden,
    
} from '@material-ui/core';
import { useWindowSize } from "../../../components/src/utils";
import { styled } from '@material-ui/styles';
import FillSurveysController, { Props,configJSON } from "./FillSurveysController";
import DashboardLayout from "../../../components/src/DashboardLayout.web";
import SearchFilter from '../../../components/src/Search.web';
import NestedMenuItem from "../../../components/src/NestedMenuItem.web";
import { CloseCircleIcon } from "../../../components/src/Icons.web";
import { ArrowDownIcon } from "./assets";


// Customizable Area End
export default class FillSurveys extends FillSurveysController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { navigation } = this.props
        // Customizable Area End
        return (

            // Customizable Area Start
            <>
                <DashboardLayout token={localStorage.getItem('authToken') || ''} navigation={navigation}>
                    <CoinsHead>
                        Self Assessment Dashboard
                    </CoinsHead>
                    <FlexSpaceBetween style={{ gap: 12 }}>
                        <div style={{ width: 250 }}>
                            <SearchFilter
                                onChange={this.onFilterQueryChange}
                            />
                        </div>
                        <div style={{ width: 250 }}>
                            <TextFieldRounded
                                value={this.filterAssessment()}
                                onClick={this.handleFilterMenuOpen}

                                InputProps={{
                                    ref: this.filterRef,
                                    endAdornment: (<InputAdornment position="start">
                                        {this.filterAssessment() && <CloseCircleIcon
                                            onClick={this.clearFilters}
                                            style={{ margin: '0 10px', width: '24px', cursor: 'pointer' }}
                                        />}
                                        <img src={ArrowDownIcon} width="16" />
                                    </InputAdornment>)
                                }} variant="outlined"
                                placeholder={configJSON.labelFilterBy}
                            />
                             {/* @ts-ignore */}
                            <Hidden mdDown>
                                <FilterMenu
                                    filterByMe={this.state.filterByMe}
                                    handleFilterByStatus={this.handlefilterAssesmentName}
                                    toggleFilterByMe={this.toggleFilterByMe}
                                    menuPosition={this.state.filterMenuPosition}
                                    onClose={this.handleFilterMenuClose}
                                    // tagControls={{
                                    //     tags: this.state.communities,
                                    //     selectedTags: this.state.filterCommunities,
                                    //     handleSelect: this.handleTagSelect,
                                    // }}
                                    keepOpen={undefined}
                                />
                            </Hidden>

                        </div>

                    </FlexSpaceBetween>
                    <CorpsTable>
                        <TableContainer component={Paper} >
                            <Table style={{minWidth:650}} aria-label="simple table">
                            <TableHead>
                                    <TableRow>
                                        <TH>Sl. No.</TH>
                                        <TH>
                                           Frequency
                                        </TH>
                                        {window.screen.width>768 &&
                                        <TH>Self Assessment Survey Name</TH>
                                        }
                                        <TH>
                                        Self Assessment Survey Date
                                        </TH>
                                        <TH>Self Assessment Survey Score</TH>

                                        <TH>Self Assessment Survey By</TH>
                                        <TH>Approved By</TH>
                                        <TH>Want to Verify</TH>
                                        <TH>Status</TH>
                                        <TH>Verified By</TH>
                                        <TH>Comments</TH>
                                    
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {QuaterlyCheck ?.map((item:any,index:number) => {
                                        return(
                                            <>
                                             <TableRow>
                                                <TR>{(index + 1)}</TR>
                                                <TR>{item.frequency}</TR>
                                                <TR>{item.assessmentName}</TR>
                                                <TR>{item.assessmentDate}</TR>
                                                <TR>{item.assessmentScore}</TR>
                                                <TR>{item.assessmentBy}</TR>
                                                <TR>{item.ApprovedBy}</TR>
                                                <TR>{item.wantVerify}</TR>
                                                <TR>{item.Status}</TR>
                                                <TR>{item.verified}</TR>
                                                <TR>{item.commnets}</TR>
                                                
                                             </TableRow>
                                            </>
                                        )
                                    })}

                                </TableBody>
                            </Table>
                        </TableContainer>  
                    </CorpsTable>

                </DashboardLayout>
            </>
            // Customizable Area End
        )
    }
}

// Customizable Area Start
const QuaterlyCheck = [
    {
        frequency:"Quaterly",
        assessmentName:"Risk Management Assessment",
        assessmentDate:"23.02.2022",
        assessmentScore:80,
        assessmentBy:"John",
        ApprovedBy:"Daniel Thomas",
        wantVerify:'Yes',
        Status:"Verified",
        verified:"maria D",
        commnets:"No"

    },
    {
        frequency:"Quaterly",
        assessmentName:"Risk Management Assessment",
        assessmentDate:"23.02.2022",
        assessmentScore:80,
        assessmentBy:"John",
        ApprovedBy:"Daniel Thomas",
        wantVerify:'Yes',
        Status:"Verified",
        verified:"maria D",
        commnets:"No"

    },
    {
        frequency:"Quaterly",
        assessmentName:"Risk Management Assessment",
        assessmentDate:"23.02.2022",
        assessmentScore:80,
        assessmentBy:"John",
        ApprovedBy:"Daniel Thomas",
        wantVerify:'Yes',
        Status:"Verified",
        verified:"maria D",
        commnets:"No"

    },
    {
        frequency:"Quaterly",
        assessmentName:"Risk Management Assessment",
        assessmentDate:"23.02.2022",
        assessmentScore:80,
        assessmentBy:"John",
        ApprovedBy:"Daniel Thomas",
        wantVerify:'Yes',
        Status:"Verified",
        verified:"maria D",
        commnets:"No"

    },
]

interface FilterMenuProps {
    filterByMe: boolean,
    // filterByFav: boolean,
    handleFilterByStatus: (status: any) => void,
    toggleFilterByMe: () => void,
    // toggleFilterByFav: () => void,
    menuPosition: {
        top: number,
        left: number,
    } | undefined
    onClose: () => void,
    // handleDateInputFocus: any,
    // handleDateInputBlur: any,
    keepOpen: any,
    inside?: string
}

const FilterMenu = ({ menuPosition, onClose, keepOpen, ...props }: FilterMenuProps) => {

    const [nestedMenuName, setNestedMenuName] = React.useState("")
    const windowSize = useWindowSize()

    console.log(windowSize.width)
    if (windowSize?.width < 1024) return <></>

    return (
        <Menu open={!!menuPosition} anchorPosition={menuPosition} onClose={onClose} anchorReference="anchorPosition">
            <MenuItem selected={props.filterByMe} onClick={props.toggleFilterByMe}>
                All
            </MenuItem>

            <NestedMenuItem
                name="SelfAssessmentName"
                nestedMenuName={nestedMenuName}
                setNestedMenuName={setNestedMenuName}
                keepOpen={keepOpen}
                label="By Self Assessment Name"
                parentMenuOpen={!!menuPosition}
                // @ts-ignore
                MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },


                }}
            >
                <FilterByTableWrapper>

                    <MenuItem selected={props.filterByMe} onClick={() => props.handleFilterByStatus('0')}>
                        Strategy Assessment
                    </MenuItem>
                    <MenuItem selected={props.filterByMe} onClick={() => props.handleFilterByStatus('1')}>
                        Risk Management Assessment
                    </MenuItem>
                    <MenuItem selected={props.filterByMe} onClick={() => props.handleFilterByStatus('2')}>
                        E Assessment
                    </MenuItem>

                    <MenuItem selected={props.filterByMe} onClick={() => props.handleFilterByStatus('3')}>
                        S Assessment
                    </MenuItem>
                    <MenuItem selected={props.filterByMe} onClick={() => props.handleFilterByStatus('4')}>
                        G Assessment
                    </MenuItem>
                    <MenuItem selected={props.filterByMe} onClick={() => props.handleFilterByStatus('5')}>
                        Stackholder Materiality
                    </MenuItem>
                </FilterByTableWrapper>
            </NestedMenuItem>

            <NestedMenuItem
                name="SelfAssessmentDate"
                nestedMenuName={nestedMenuName}
                setNestedMenuName={setNestedMenuName}
                keepOpen={keepOpen}
                label="By Self Assessment Date"
                parentMenuOpen={!!menuPosition}
                // @ts-ignore
                MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                }}
            >
            </NestedMenuItem>
        </Menu>
    ) 
}


const TH = styled(TableCell)({
    color: "#000",
    fontWeight: 600,
    lineHeight: "1.5rem",
    padding: '16px 10px',
    textAlign: 'center',
    position: 'relative',
    border: "1px solid #E8E8E8",
})

const TR = styled(TableCell)({
    lineHeight: "1.5rem",
    padding: '16px 10px',
    textAlign: 'center',
    border: "1px solid #E8E8E8",
})

const CorpsTable = styled('div')({
    // backgroundColor: '#fff',
    borderRadius: "12.97px",
    padding: '10px'
})

const CoinsHead = styled('div')({
    margin: '40px 0px 15px 12px',
    fontSize: '1.2rem',
    fontWeight: 600,
    '@media screen and (max-width: 768px)': {
        margin: '20px 0px 10px 12px',
    },
})
const TextFieldRounded = styled(TextField)({
    width: '100%',
    background: '#FFFFFF',
    borderRadius: '15px',
    '& > .MuiOutlinedInput-root': {
        borderRadius: '15px',
    },
    '& > div > input': {
        paddingTop: '14px',
        paddingBottom: '14px'
    }
})

const FlexSpaceBetween = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 0',
    // paddingBottom: '0',
    width: '100%',
    '@media screen and (max-width:600px)': {
        flexDirection: 'column',
        alignItems: 'normal',
    },
    '@media screen and (min-width:769px) and (max-width:1200px)': {
        flexDirection: 'column',
        alignItems: 'normal',
    }
})
const FilterByTableWrapper = styled('div')({
    width: '280px',
    height: '230px',
    padding: '10px 0px',
    background: '#FFF'
})
// Customizable Area End



import React from "react";
// Customizable Area Start
import SurveysDetailsController, { Props, configJSON } from "./SurveysDetailsController";
import DashboardLayout from "../../../components/src/DashboardLayout.web";
import {
    InputBase, Chip, Grid,Divider, Typography, IconButton, Button, Box, Avatar, InputAdornment, TextField,MenuItem,MenuList,Popper,Paper,ClickAwayListener
} from '@material-ui/core'
import { styled } from '@material-ui/styles'
import ShareIcon from '@material-ui/icons/Share';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CancelIcon from '@material-ui/icons/Cancel';
import { Link } from 'react-router-dom';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined';
import FlexBase from "../../../components/src/Flex.web";
import OverlayLoader from '../../../components/src/Loader.web'
import { BitmapIcon0, BitmapIcon1, BitmapIcon2, BitmapIcon3, BitmapIcon4, ArrowDownIcon, ArrowRound } from "./assets";
import UserContext from "../../../components/src/UserProvider";
import SeeMore from "../../../components/src/SeeMore.web";
import PlusMorePopup from '../../../components/src/PlusMorePopup.web'
import { profileAvatar } from "../../../components/src/LeftMenuWeb";
import LinearProgress from '@material-ui/core/LinearProgress';
import DeleteModal from '../../../components/src/DeleteModal.web';
import Attachments from '../../../components/src/Attachments.web';
import { Attachment as AttachmentType ,UserProfileType } from './types';

// Customizable Area End

export default class SurveysDetails extends SurveysDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    showTaggedCommunities = (mandatory: boolean) => {
        if (!mandatory) {
            return <Grid xs item style={{ flexGrow: 0 }}>
                {this.forTaggedCommunities()}
            </Grid>
        }
    }

    questionList() {
        const { allQuestion, surveyData ,resultQueData} = this.state;
        const { status,start_date,mandatory,end_date } = surveyData
        const isDeleteAllowed = this.isUpdateAllowed(status,start_date,mandatory,end_date)
        let resultData = resultQueData
        
        
        const renderQuestion = (question: any, index: number, isChild: boolean, parentId?: string) => {
                      
            const {
              id,
              attributes: {
                response_type,
                question: questionText,
                question_type,
                question_attachments,
                options,
                sequence_no,
                childQue,
                unique_question_id
              },
            } = question; 
            const isChildAllowed = this.checkResponseType(question)
            const indexLabel = this.getIndexLabel(index, isChild,resultData, parentId);
            return (
              <div key={id} style={{ width: '100%', display: 'flex', flexDirection: 'column', border: '1px solid #B3AEAE', borderRadius: '15px',marginTop:'10px' }}>
                <div style={{ padding: '15px', marginTop:'5px' }}>
                  <FlexBase justify="space-between">
                    <div style={{ flexDirection: 'column' }}>
                      <TemplateName style={{ fontSize: '15px', wordBreak: 'break-all' }}>{indexLabel} <span style={{color: "gray" }}> {unique_question_id} </span>{questionText} ({this.snakeCaseToTitleCase(response_type)}) {question_type === 'mandatory' && <span style={{ color: 'red' }}> * </span>}</TemplateName>
                    </div>
                    {
                      isDeleteAllowed && !this.state.isEditSequence ?
                      <QuestionActionWraper>
                        <div style={{ gap: '10px', display: 'flex', flexDirection: 'row', cursor: 'pointer',justifyContent: 'end' }}>
                          <DeleteOutlineIcon color="disabled" onClick={this.deleteQuestion(id)} data-test-id="delete-question" />
                          <EditOutlinedIcon color="disabled" onClick={this.onEditQuestion(id, sequence_no)} data-test-id="edit-question" />
                        </div> 
                        {isChildAllowed ? <AddBtn data-testid="add-child-question" style={{width:'200px', marginTop:'10px'}} type="button" onClick={() => this.onChildQuestionAdd(question)}>
                            <AddCircleIcon />
                            ADD CHILD QUESTION
                        </AddBtn> : <></>}
                        </QuestionActionWraper>
                        :
                        <></>
                    }
                    {
                      status === 'active' && this.state.isEditSequence ?
                        <div style={{ gap: '10px', display: 'flex', flexDirection: 'row' }}>
                          <img src={ArrowRound}
                            color="disabled"
                            data-testid="sequenceUp"
                            style={{
                              transform: 'rotate(180deg)',
                              cursor: 'pointer',
                              width: '25px',
                              height: '25px',
                            }}
                            onClick={() => this.handleUpButtonClick(index, isChild, parentId)}
                            alt={`${questionText} Sequence Up`}
                          />
                          <img src={ArrowRound} data-testid="sequenceDown" color="disabled" alt={`${questionText} Sequence Down`} style={{ cursor: 'pointer', width: '25px',
                              height: '25px' }} onClick={() => this.handleDownButtonClick(index, isChild, parentId)} />
                        </div> :
                        <></>
                    }
                  </FlexBase>
        
                  {/* Extract option rendering */}
                  {response_type === 'multiple_choice' && this.renderOptions(options)}
                  {response_type === 'rating' && this.renderRatingOptions(options)}
                  {response_type === 'scoring' && this.renderScoringOptions(options)}
                  {response_type === 'summation' && this.renderOptions(options)}
        
                  {/* Extract attachment rendering */}
                  {this.renderAttachments(question_attachments)}
        
                  {/* Recursively render child questions */}
                  {childQue && childQue.length > 0 && (
                    <div>
                      {childQue.map((childQuestion: any, index: number) => renderQuestion(childQuestion, index, true, id))}
                    </div>
                  )}
                </div>
              </div>
            );
          };
        
          return (
            <>
              {resultData.map((question: any, index: number) => renderQuestion(question, index, false))}
            </>
          );
      }
      
      // Extract attachment rendering function
      renderAttachments(attachments:any) {
        const qAttachments : AttachmentType[] = this.updatedAttachments(attachments)
        return qAttachments && qAttachments.length > 0 && (
          <Attachments files={qAttachments.map((attachment:any) => ({ ...attachment }))} label=" " />
        );
      }
      
      renderOptions(options:any) {
        return (
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly', gap: '10px', marginTop: '10px' }}>
            {options.map((option:any) => (
              <CustomChip key={option.title}>{option.title}</CustomChip>
            ))}
          </div>
        );
      }
      
      renderRatingOptions(options:any) {
        const iconArray = [BitmapIcon0, BitmapIcon1, BitmapIcon2, BitmapIcon3, BitmapIcon4]
        return (
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', justifyContent: 'space-evenly', marginTop: '10px' }}>
            
            {options.map((option:any, index:number) => (
              <CustomChip key={index}>
                <BtnImg src={iconArray[index]} />
                {option.title}
              </CustomChip>
            ))}
          </div>
        );
      }
      
      // Extract scoring option rendering function
      renderScoringOptions(options:any) {
        return (
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly', gap: '10px', marginTop: '10px' }}>
            {options.map((option:any) => (
              <CustomChip key={option.title}>{option.title} - {option.score}</CustomChip>
            ))}
          </div>
        );
      }
      

    emailScreen() {
        if(this.state.emailPopup) return <div style={{ position: 'fixed', bottom: 0, right: 0, width: '330px', height: '430px', background: '#fff', border: '2px solid #E5EDE6', borderRadius: '10px' }}>
                {/* this is right corner box */}
                <Box style={{ display: 'flex', justifyContent: 'space-between', height: '30px', alignItems: 'center', padding: '10px', background: '#0d0629' }}>
                    <Typography style={{ color: '#fff', fontWeight: 'bold', fontSize: '1rem' }}>COMPOSE</Typography>
                    <CancelIcon style={{ color: '#fff', cursor: 'pointer' }} onClick={this.closeEmailPopup} />
                </Box>
                <div style={{ overflow: 'auto', height: '400px', display: 'flex', flexDirection: 'column' }}>
                    <Box style={{ display: 'flex', height: '30px', alignItems: 'center', padding: '10px' }}>
                        <Typography style={{ marginRight: '10px' }}>From:</Typography>
                        <Chip
                            size="medium"
                            avatar={<Avatar alt={this.context.email} src={this.context.profile_pic} />}
                            label={this.context.email}
                        />
                    </Box>
                    <Divider variant="fullWidth" />
                    <Box style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', padding: '10px' }}>
                        <Typography style={{ marginRight: '10px', position: 'static' }}>{"To: "}</Typography>
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '3px' }}>
                                {this.state?.sendTo?.map((email: any, index: number) => {
                                    return (
                                        <Chip
                                            key={`email`}
                                            size="medium"
                                            avatar={<Avatar>{email?.charAt(0)}</Avatar>}
                                            label={email}
                                            onDelete={() => this.deleteEmail(email)}
                                        />)
                                }
                                )}
                            </div>
                            <InputBase style={{ width: '100%', paddingLeft: '10px' }}
                                // value={this.state?.subject}
                                value={this.state?.newEmail}
                                onChange={this.emailValue}
                                onKeyDown={this.addEmail}
                                autoFocus
                                inputProps={{ 'data-testid': 'to-email-input' }}
                            />
                        </div>
                    </Box>
                    <Divider variant="fullWidth" />
                    <Box style={{ display: 'flex', height: '30px', alignItems: 'center', padding: '10px' }}>
                        <Typography>Subject:</Typography>
                        <InputBase style={{ width: '100%', paddingLeft: '10px' }}
                            value={this.state?.subject}
                            onChange={this.changeSubject}
                        />
                       
                    </Box>
                    <Divider variant="fullWidth" />
                    <InputBase
                        id="outlined-multiline-static"
                        multiline
                        rows={8}
                        value={this.state.content}
                        onChange={this.changeContent}
                        style={{ width: '100%', paddingLeft: '10px', marginBottom: '5px' }}
                    />
                    <Box style={{ display: 'flex', margin: '5px', paddingBottom: '5px', gap: '5px', justifyContent: 'flex-end' }}>                           
                    <div style={{width: '120px', borderRadius:'20px', background:'#e6e4e4', color:'gray', fontSize:'12px', fontWeight:'bold', display:'flex', alignItems:'center', justifyContent:'center'}}>
                    <AttachFileOutlinedIcon style={{color:'gray'}}/>
                    <label htmlFor="attachement">Attachement</label>
                    <input
                                data-test-id="attachement-input"
                                type="file"
                                accept="*"
                                id="attachement" multiple
                                style={{ display:'none' }}
                                onChange={(e) => this.handleAttachement(e)}
                            />
                    </div>         
                        <EmailBtn type="submit"
                        data-testid="sendEmailBtn"
                        onClick={this.handleSendEmail}
                        >
                            Send
                        </EmailBtn>
                    </Box>
                    <Box style={{display:"flex",alignItems:"center",flexDirection:"column"}}>                   
                            {this.state.emailAttachements?.length ? this.state.emailAttachements.map((file: any, index: number) => (
                                <BorderDocument key={index}>
                                    <div id="fileDiv"style={{ display: "flex" }} onClick={(e) => this.onDownload(index, file)}>
                                        <img src={this.fileIcon(file.type, file.name)} width="42px" />
                                        <FileInformation>
                                            <Typography style={{
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: 'ellipsis',
                                                width: '200px'
                                            }}>{file.name}</Typography>
                                            <Typography color="textSecondary">{Math.floor(file.size / 1000) + "KB"}</Typography>
                                        </FileInformation>
                                    </div>
                                    <div id="cancelButton" style={{ position: "absolute", right: "5px", cursor: "pointer" }} onClick={() => this.deleteAttachement(index)}>
                                        <CancelIcon />
                                    </div>
                                </BorderDocument>
                            )): <></>}
                    </Box>
                </div>
            </div>
    }

    QuestionDelete() {
        if(this.state.deleteQuestion) return <DeleteModal label="Are you sure you want to delete this question ?" onClose={this.closeModal} onSubmit={this.onDeleteQuestion(this.state?.deleteId)} />
    }

    TemplateDelete() {
        if(this.state.deleteTemplteId) return <DeleteModal label="Delete this Survey?" onClose={this.closeDeleteModal} onSubmit={this.handleDeleteTemplate(this.state?.surveyData?.id)} />
    }

    isQuestions() {
        if(this.state?.allQuestion?.data?.length < 1) return <SubText>No questions added!</SubText>
    }

    addQuestion() {
        const { surveyData } = this.state;
        const { status,start_date,mandatory,end_date } = surveyData
        
        const isDeleteAllowed = this.isUpdateAllowed(status,start_date,mandatory,end_date)   
        if(isDeleteAllowed) return <div>
        <AddBtn data-test-id="add-Question-button" onClick={this.onQuestionAdd}>
            <AddCircleIcon />
            ADD
        </AddBtn>
    </div>
    }

    sequenceQuestion() {
        const { surveyData } = this.state;
        const { status, start_date, mandatory, end_date } = surveyData
        const isDeleteAllowed = this.isUpdateAllowed(status, start_date, mandatory, end_date)
        const allQuestionLength = this.state.allQuestion?.data?.length
        if (isDeleteAllowed && allQuestionLength > 0) return <SequenceButton
            onClick={() => this.setState({ isEditSequence: !this.state.isEditSequence })}
            data-testid="sequencing"
        >
            SEQUENCING
        </SequenceButton>
    }
    shareButton() {
        if(!this.state?.surveyData?.mandatory) return <Grid xs item>
        <FlexBase justify="end" align="center" gap={8}>
            <SubTextGray>Share : </SubTextGray>
            <ShareButton aria-label="settings" onClick={this.handleShareClick(this.state?.surveyData)}>
                <ShareIcon />
            </ShareButton>
        </FlexBase>
    </Grid>
    }

    isAbleDelete() {
        if(!this.state?.surveyData?.mandatory) {
            <DeleteOutlineIcon color="disabled" onClick={this.handleDeletePopup(this.state?.surveyData)} />
        }
    }

    forInactiveDate() {
        if(this.state?.surveyData?.inactive_date) return <>
        <Divider style={{ height: '20px', alignSelf: 'center', marginLeft: '15px', marginRight: "15px" }} orientation="vertical" flexItem />
            <Grid xs item style={{ flexGrow: 0 }}>
                <Typography style={{ whiteSpace: 'nowrap' }} >Inactive Date : <SubTextGray>{this.state?.surveyData?.inactive_date}</SubTextGray></Typography>
            </Grid>
        </>
    }

    forTaggedProject() {
        if(this.state?.surveyData?.tagged_project) return <>
        <Divider style={{ height: '20px', alignSelf: 'center', marginLeft: '15px', marginRight: "15px" }} orientation="vertical" flexItem />
            <Grid xs item style={{ flexGrow: 0 }}>
                <Typography style={{ whiteSpace: 'nowrap' }} >Tagged Project : <SubTextGray>{ }</SubTextGray></Typography>
            </Grid>
        </>
    }

    forFrequency() {
        if(this.state?.surveyData?.reminder_frequency) return <Typography style={{ whiteSpace: 'nowrap' }} >Reminder Frequency : <SubTextGray>{this.state?.surveyData?.reminder_duration}{" "}{this.state?.surveyData?.reminder_duration ? this.state?.surveyData?.reminder_type : ""}</SubTextGray></Typography>
    }

    forTaggedCommunities() {
        return <Typography style={{ whiteSpace: 'nowrap' }} >Tagged Communities : <SubTextGray>{this.state?.surveyData?.tagged_communities?.data[0] != undefined && this.state?.surveyData?.tagged_communities?.data[0].attributes.name}{" "}{this.state?.surveyData?.tagged_communities?.data?.length > 1 ? <PlusMorePopup heading="Tagged Communities" listItems={this.state?.surveyData?.tagged_communities?.data?.map((com: any) => com.attributes.name)}>
                +{this.state?.surveyData?.tagged_communities?.data?.length - 1}
                </PlusMorePopup>:"Not Applicable"}</SubTextGray>
                </Typography>
    }

    forTaggedUserPopup() {
        if(this.state?.surveyData?.tagged_users?.data?.length > 1) return <PlusMorePopup heading="Tagged Users" listItems={this.state?.surveyData?.tagged_users?.data?.map((com: any) => com.attributes.email)}>
                +{this.state?.surveyData?.tagged_users?.data?.length - 1}
        </PlusMorePopup>
    }

    renderSurveyTitle(surveyDataText:string, maxLength = 30) {
        const surveyTitle = surveyDataText
        if (!surveyTitle) return null;
      
        if (surveyTitle.length <= maxLength) {
          return surveyTitle;
        } else {
          const truncatedTitle = surveyTitle.substring(0, maxLength);
          return (
            <>
              {truncatedTitle}...
            </>
          );
        }
      }

    renderFullName(userData:UserProfileType) {
        const firstName = userData?.first_name || "";
        const lastName = userData?.last_name || "";
        return `${firstName} ${lastName}`.trim();
    }
      
    // Customizable Area End

    render() {
        // Customizable Area Start
        const questionCondtion = this.getQuestionList(this.state)
        const questionList = questionCondtion ? <></> : this.questionList()
        const emailScreen = this.emailScreen()
        const QuestionDelete = this.QuestionDelete()
        const TemplateDelete = this.TemplateDelete()
        const { navigation } = this.props;
        const { surveyData, allQuestion } = this.state;
        const { status, mandatory } = surveyData
        const tagedUSerData = this.getTagedUserCondition(surveyData?.tagged_users) 
        const questionsBoxMinHeight = this.getQuestionsBoxMinHeight(allQuestion?.data?.length)
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <DashboardLayout token={ this.tokenData || ''} navigation={navigation}>
                    <OverlayLoader loading={this.state.loading} />
                    {TemplateDelete}
                    {QuestionDelete}
                    <DIV>
                        <DashboardActionWraper >
                            {
                                surveyData.mandatory ?
                                    <TitleWrapper>
                                        <MsHeadingWrapper>
                                            <MsPageTitle data-test-id="back-button" onClick={() => this.getNavigationMessage('MandatorySurveys', this.props)}>
                                                <HeadGreenTitle>{this.reportingFrameworkTitle}</HeadGreenTitle>
                                                <ArrowBackIosIcon />
                                            </MsPageTitle>
                                            <HeadCoinsHead>{surveyData.template_name}</HeadCoinsHead>
                                        </MsHeadingWrapper>
                                    </TitleWrapper> :
                                    <CoinsHead data-test-id="back-button" onClick={() => this.getNavigationMessage('Surveys', this.props)}>
                                        <ArrowBackIosIcon />
                                        Back
                                    </CoinsHead>
                            }
                            <DownloadButton data-test-id="download-excel" onClick={() => this.downloadReport('excel')}>
                                <ArrowDownwardIcon color="inherit" />
                                Download Report
                            </DownloadButton>
                        </DashboardActionWraper>
                        <DashboardDetailsWraper >
                            <SurveyBox style={{ backgroundColor: "white", width: '100%', overflow: 'hidden', display: "flex", gap: '18px', flexDirection: "column", justifyContent: 'space-between', borderRadius: "10px", padding: "20px" }}>
                                <Box style={{ width: '100%', display: "flex", flexDirection: "column" }}>
                                    <FlexBase justify="space-between">
                                        <FlexBase style={{ flexDirection: 'column' }}>
                                            <TemplateName>{this.renderSurveyTitle(surveyData?.template_name)}</TemplateName>
                                            <Temp style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                <SubText>{this.renderSurveyTitle(surveyData?.survey_title)}</SubText>
                                                <TemplateId>Template ID : {surveyData?.survey_uniq_id}</TemplateId>
                                            </Temp>
                                        </FlexBase>
                                        <div>
                                            {this.isAbleDelete()}
                                            <EditOutlinedIcon color="disabled" onClick={this.handleEditTemplate(surveyData?.id)} />
                                        </div>
                                    </FlexBase>
                                </Box>
                                <SeeMore text={`${surveyData?.description}`} />
                                <div>
                                    <Grid style={{ marginBottom: '12px' }} spacing={2} container alignItems="center" justifyContent="flex-start" wrap="wrap" >
                                        <Grid xs item style={{ flexGrow: 0 }}>
                                            <Typography style={{ whiteSpace: 'nowrap' }} >Start Date : <SubTextGray data-test-id="start-date-typography">{surveyData?.start_date}</SubTextGray></Typography>
                                        </Grid>
                                        <Divider style={{ height: '20px', alignSelf: 'center', marginLeft: '15px', marginRight: "15px" }} orientation="vertical" flexItem />
                                        <Grid xs item style={{ flexGrow: 0 }}>
                                            <Typography style={{ whiteSpace: 'nowrap' }} >End Date : <SubTextGray data-test-id="end-date-typography">{surveyData?.end_date}</SubTextGray></Typography>
                                        </Grid>
                                        {this.forInactiveDate()}
                                    </Grid>
                                    <Grid style={{ marginBottom: '12px' }} spacing={2} container alignItems="center" justifyContent="flex-start" wrap="wrap">
                                        {this.showTaggedCommunities(mandatory)}
                                        {this.forTaggedProject()}
                                    </Grid>
                                    <Grid style={{ marginBottom: '12px' }} spacing={2} container alignItems="center" justifyContent="flex-start" wrap="wrap">
                                        <Grid xs item style={{ flexGrow: 0 }}>
                                            <Typography style={{ whiteSpace: 'nowrap' }} >Approximate Time to Complete The Survey: <SubTextGray>{surveyData?.survey_time ? surveyData?.survey_time : 0}{" mins"}</SubTextGray> </Typography>
                                        </Grid>
                                        <Divider style={{ height: '20px', alignSelf: 'center', marginLeft: '15px', marginRight: "15px" }} orientation="vertical" flexItem />
                                        <Grid xs item style={{ flexGrow: 0 }}>
                                            {this.forFrequency()}
                                        </Grid>
                                    </Grid>
                                    <Grid style={{ marginBottom: '12px' }} spacing={2} container alignItems="center" justifyContent="flex-start" wrap="wrap">
                                        <Grid xs item style={{ flexGrow: 0 }}>
                                            <Typography style={{ whiteSpace: 'nowrap' }} >Target Respondents: <SubTextGray>{surveyData?.no_of_respondents}</SubTextGray></Typography>
                                        </Grid>
                                        <Divider style={{ height: '20px', alignSelf: 'center', marginLeft: '15px', marginRight: "15px" }} orientation="vertical" flexItem />
                                        <Grid xs item style={{ flexGrow: 0 }}>
                                            <Typography style={{ whiteSpace: 'nowrap' }} >No of Responses : <SubTextGray>{surveyData?.no_of_responses}</SubTextGray></Typography>
                                        </Grid>
                                        <Divider style={{ height: '20px', alignSelf: 'center', marginLeft: '15px', marginRight: "15px" }} orientation="vertical" flexItem />
                                        <Grid xs item style={{ flexGrow: 0 }}>
                                            <DashboardResponseDetails>
                                                <Typography style={{ whiteSpace: 'nowrap' }} >Response Status : </Typography>
                                                <LinearProgress variant="determinate" value={surveyData?.response_status} style={{ height: '6px', borderRadius: '5px', maxWidth: '170px', minWidth: '80px', marginLeft: "8px" }} />
                                                <Typography style={{ marginLeft: '5px' }}>{surveyData?.response_status}%</Typography>
                                            </DashboardResponseDetails>
                                        </Grid>
                                    </Grid>
                                    <Grid style={{ marginBottom: '0px' }} spacing={2} container alignItems="center" justifyContent="center" wrap="wrap">
                                        <Grid xs item>
                                            <FlexBase justify="start" align="center" gap={8}>
                                                <Typography style={{ whiteSpace: 'nowrap' }} > <SubTextGray>Created By : </SubTextGray> </Typography>
                                                <Link to={`/user/${surveyData?.created_by?.id}`}>
                                                    <BottomAvatar style={{ border: '3px solid rgb(242,212,212)' }} >
                                                        <img src={this.context.profile_pic ? this.context.profile_pic : profileAvatar} alt="img" width="100%" height="100%" />
                                                    </BottomAvatar>
                                                </Link>
                                                <Link to={`/user/${surveyData?.created_by?.id}`}>
                                                    <Typography style={{ fontSize: '15px', fontWeight: 550 }}>{this.renderFullName(surveyData?.created_by)}</Typography>
                                                </Link>
                                            </FlexBase>
                                        </Grid>
                                        <Grid xs item>
                                            <FlexBase justify="start" align="center" gap={8}>
                                                {surveyData?.requested_by?.id && (<>
                                                    <Typography style={{ whiteSpace: 'nowrap' }} > <SubTextGray>Requested By : </SubTextGray></Typography>
                                                    <Link to={`/user/${surveyData?.requested_by?.id}`} style={{display:"flex", alignItems:"center"}}>
                                                        <BottomAvatar style={{ border: '3px solid rgb(242,212,212)' }}>
                                                            <img src={surveyData.requested_by?.profile_pic ? surveyData.requested_by?.profile_pic : profileAvatar} alt="img" width="100%" height="100%" />
                                                        </BottomAvatar>
                                                        <Typography style={{ fontSize: '15px', fontWeight: 550 }}>{this.renderFullName(surveyData?.requested_by)}</Typography>
                                                    </Link>
                                                </>
                                                )}
                                            </FlexBase>
                                        </Grid>
                                        <Grid xs item>
                                            <FlexBase justify="end" align="center" gap={8}>
                                                <EmailBtn data-testid="EmailBtn" onClick={this.openEmailPopup}>
                                                    Send Email
                                                </EmailBtn>
                                            </FlexBase>
                                        </Grid>
                                    </Grid>
                                </div>
                                <Divider variant="fullWidth" />
                                <Grid spacing={2} container alignItems="center" justifyContent="center" wrap="wrap">
                                    <Grid xs item>
                                    {tagedUSerData && (<FlexBase style={{ width: '100%', display: 'flex' }} justify="start" align="center" gap={8}>
                                            <>
                                            <Typography style={{ whiteSpace: 'nowrap' }} > <SubTextGray>Tagged Users : </SubTextGray> </Typography>
                                            <DashboardTaggedDetails>
                                                <Link to={`/user/${surveyData?.tagged_users?.data[0]?.id}`}>
                                                    <BottomAvatar style={{ border: '3px solid rgb(242,212,212)' }}>
                                                        <img src={surveyData?.tagged_users?.data[0]?.profile_pic ? surveyData?.tagged_users?.data[0]?.profile_pic : profileAvatar} alt="img" width="100%" height="100%" />
                                                    </BottomAvatar>
                                                </Link>
                                                <Link to={`/user/${surveyData?.tagged_users?.data[0]?.id}`}>
                                                    <SubTextGray>{surveyData?.tagged_users?.data[0]?.attributes.email}{" "}</SubTextGray>
                                                </Link>
                                                {surveyData?.tagged_users?.data?.length > 1 && <PlusMorePopup heading="Tagged Users" listItems={surveyData?.tagged_users?.data?.map((com: any) => com.attributes.email)}>
                                                    +{surveyData?.tagged_users?.data?.length - 1}
                                                </PlusMorePopup>}
                                            </DashboardTaggedDetails>
                                            </>
                                        </FlexBase>)}
                                    </Grid>
                                    {this.shareButton()}

                                </Grid>
                            </SurveyBox>
                            {this.state.surveyData?.mandatory ? <MuiMenu >
                                <StyledTextField
                                    data-test-id="quarter-dropdown"
                                    value={this.state.filterText}
                                    onClick={this.handleFilterMenuOpen}
                                    InputProps={{
                                        endAdornment: (<InputAdornment position="start" style={{ cursor: 'pointer' }}>
                                            <img src={ArrowDownIcon} width="16" />
                                        </InputAdornment>)
                                    }} 
                                    inputProps={{ readOnly: true }} //searching is not supported for now
                                    variant="outlined"
                                    placeholder={configJSON.labelFilterBy} />
                                <Popper
                                    open={Boolean(this.state.AnchorEl)}
                                    anchorEl={this.state.AnchorEl}
                                    role={undefined}
                                    transition
                                    placement="bottom-end"
                                >
                                <Paper>
                                    <ClickAwayListener onClickAway={this.handleClose}>
                                        <MenuList style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
                                            {this.state.quarterValue?.length ? 
                                                this.state.quarterValue.map((item) => {
                                                    const filterText = `${item.start_date} - ${item.end_date}`
                                                    return <MenuItem data-test-id="quarter-menu-item" key={item.survey_id} onClick={() => this.onQuarterSelect(item.survey_id, filterText)}>
                                                        {filterText}
                                                    </MenuItem>
                                                })
                                                :
                                                (
                                                    <MenuItem>No data</MenuItem>
                                                )
                                            }
                                             <Button
                                                variant="contained"
                                                color="primary"
                                                data-test-id="add-quarter-button"
                                                onClick={this.createQuarter}
                                            >
                                                {configJSON.createdQuarter}
                                            </Button>
                                        </MenuList>
                                           
                                    </ClickAwayListener>
                                </Paper>
                            </Popper>
                            </MuiMenu>:<></>}
                            
                            <QuestionBox style={{ minHeight: questionsBoxMinHeight }}>
                                <Box style={{ width: '100%', display: "flex", flexDirection: "column" }}>
                                    <FlexBase justify="space-between">
                                        <FlexBase style={{ flexDirection: 'column' }}>
                                            <TemplateName>Questions</TemplateName>
                                            <Temp>
                                                {this.isQuestions()}
                                            </Temp>
                                        </FlexBase>
                                        {
                                            (status === 'active') &&
                                            (!this.state.isEditSequence ?
                                                <QueButtonsWrapper>
                                                    {this.sequenceQuestion()}
                                                    {this.addQuestion()}
                                                </QueButtonsWrapper> :
                                                <SequenceButton
                                                    onClick={() => this.saveNewSequence()}
                                                    data-testid="saveSequence"
                                                >
                                                    SAVE NEW SEQUENCE
                                                </SequenceButton>)
                                        }
                                    </FlexBase>
                                </Box>
                                {questionList}
                            </QuestionBox>
                        </DashboardDetailsWraper>
                    </DIV>
                </DashboardLayout>
                {emailScreen}
            </>
            // Customizable Area End
        )
    }
}

// Customizable Area Start
SurveysDetailsController.contextType = UserContext


const DIV = styled('div')({
    '@media (max-width: 768px)': {
        padding: '0px 20px',
    }
})

const CoinsHead = styled('div')({
    display: 'flex',
    alignItems: 'center',
    color: 'gray',
    margin: '40px 0px 30px 12px',
    fontSize: '1rem',
    fontFamily: 'HelveticaNeue',
    fontWeight: 500,
    cursor: 'pointer',
    '@media screen and (max-width: 768px)': {
        margin: '20px 0px 10px 12px',
    },
})

const Attachment = styled('div')({
    padding: '5px 0',
    marginTop: '10px',
    minWidth: '250px',
    '@media screen and (max-width:480px)': {
        '& > div > div > div': {
            width: '170px',
            height: '35px',
        },
    },
    '& > div > p': {
        display: 'none',
    }
})

const QueButtonsWrapper = styled('div')({display: 'flex'})

const SurveyBox = styled(Box)({
    width: '-moz-available !important'
})

const QuestionBox = styled(Box)({
    // width: '-moz-available !important',
    backgroundColor: "white",
    width: '100%',
    overflow: 'hidden',
    display: "flex",
    gap: '18px',
    flexDirection: "column",
    justifyContent: 'space-between',
    borderRadius: "10px",
    padding: "20px"
})

const TemplateName = styled(Typography)({
    fontWeight: "bold",
    color: "#292D32",
    fontSize: '20px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
})

const SubText = styled(Typography)({
    color: "#292D32",
    fontSize: '14px',
})

const SubTextGray = styled('span')({
    color: "rgba(21, 20, 20, 0.65)",
    alignSelf: 'center',
})

const Temp = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '10px',
})

const TemplateId = styled(Typography)({
    background: '#F5F5F5',
    border: '1px solid #E5EDE6',
    borderRadius: '10px',
    color: '#282D33',
    padding: '10px 15px',
    fontSize: '14px',
    fontWeight: 'bold',
    width: 'max-content',
})

const DownloadButton = styled(Button)({
    paddingRight : '5px',
    paddingBottom : '20px',
    display: 'flex',
    textTransform: 'none',
    justifyContent: 'space-around',
    border: '1px solid Green',
    color: 'Green',
    fontSize: '12px',
    borderRadius: '15px',
    height: '30px',
    width: '150px',
    paddingTop : '20px',
    fontWeight: 'bold',
})

const SequenceButton = styled(Button)({
    backgroundColor: 'green',
    color: 'white',
    borderRadius: '50px',
    padding: '6px 20px',
    height: 'max-content',
    '&:hover': {
        background: 'Green',
}})

const LiveBtn = styled(Typography)({
    background: '#ebf3fc',
    border: '1px solid #E5EDE6',
    borderRadius: '10px',
    color: '#2175E7',
    padding: '8px 25px',
    fontSize: '14px',
    width: 'max-content',
    fontWeight: 'bold',
    // opacity: 0.11,
    mixBlendMode: 'normal'
})

const ShareButton = styled(IconButton)({
    background: '#EBEBEB',
    '@media screen and (max-width:480px)': {
        marginTop: '20px'
    }
});

const EmailBtn = styled(Button)({
    background: 'Green',
    color: '#fff',
    borderRadius: '20px',
    fontSize: '12px',
    width: '120px',
    fontWeight: 'bold',
    '&:hover': {
        background: 'Green',
    }
})

const AddBtn = styled(Button)({
    background: 'Green',
    color: '#fff',
    borderRadius: '20px',
    fontSize: '12px',
    width: '95px',
    fontWeight: 'bold',
    justifyContent: 'space-evenly',
    marginLeft: '20px',
    '&:hover': {
        background: 'Green',
    }
})

const BottomAvatar = styled(Avatar)({
    margin: '2px',
    '@media screen and (max-width:400px)': {
        height: '35px',
        width: '35px',
        gap: '5px'
    }
})

const CustomChip = styled(Box)({
    background: 'rgba(96, 89, 89, 0.1)',
    padding: '10px',
    borderRadius: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '100%',
    wordBreak: 'break-all'
    // minWidth:'130px',
})

const BtnImg = styled('img')({
    width: 25
})

const FileInformation = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '15px',
    gap: '5px',
})

const BorderDocument = styled('div')({
    border: '1px solid #999',
    padding: '10px 15px',
    borderRadius: '15px',
    display: 'flex',
    alignItems: 'center',
    width: '80%',
    position: 'relative',
    marginTop: '5px'
})
const MuiMenu = styled(MenuList)({
    marginLeft: 'auto',
})

const StyledTextField = styled(TextField)({
    width: '100%',
    background: '#FFFFFF',
    cursor: 'pointer',
    borderRadius: '15px',
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor:'transparent',
      },
    '& .MuiOutlinedInput-root': {
      borderRadius: '15px',
    },
    '& .MuiInput-input': {
      paddingTop: '14px',
      paddingBottom: '14px',
      cursor: 'pointer',
    }
  })

  const TitleWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
})

const MsHeadingWrapper = styled("div")({
    display: "flex",
    alignItems: "center",
});
const MsPageTitle = styled("span")({
    display: 'flex',
    cursor: "pointer",
    gap: "15px",
    alignItems: "center",
    margin: "30px 5px 15px 12px",
    });
const HeadGreenTitle = styled('span')({
    color: 'green',
    fontWeight: 'bold',
    fontSize: "1.4rem",
    })
const HeadCoinsHead = styled("div")({
    textTransform: "capitalize",
    fontSize: "1.4rem",
    margin: "40px 0px 25px 0px",
    fontWeight: "bolder",
    "@media screen and (max-width: 768px)": {
    margin: "20px 0px 10px 12px",
    },
    });
const QuestionActionWraper = styled('div')({
    display: 'flex',
    alignItems: 'flec-end',
    flexDirection: 'column',
});
const DashboardActionWraper = styled('div')({
    display: 'flex', justifyContent: 'space-between', alignItems: 'center'
})
const DashboardDetailsWraper = styled('div')({
    display: 'flex', flexWrap: 'wrap', gap: '10px'
})
const DashboardResponseDetails = styled('div')({
    display: 'flex', alignItems: 'center'
})
const DashboardTaggedDetails = styled('div')({
    gap: '5PX', display: 'flex', alignItems: 'center', background: '#F5F5F5', border: '0.01rem solid #B3AEAE', borderRadius: '25px', paddingRight: "7px"
})
        
// Customizable Area End
import React from "react";

// Customizable Area Start
import {
  Card, CardHeader, CardContent,
  Grid,
  Divider,
  Typography,
  IconButton,
  MenuItem,
  Menu,
  Button,
  Avatar,
} from '@material-ui/core'
import { styled } from '@material-ui/styles'
import { Formik } from 'formik'

import {
  iconLocation,
  iconCalendar,
  iconCorp,
  iconGreenThumb,
  avatarBuilding,
  LogoHex,
} from './assets'
import InsertLinkIcon from '@material-ui/icons/InsertLink';
import { commentCount, MoreHorizIcon } from '../../ActivityFeed/src/assets'
import PostCard, { CommonActions } from '../../../components/src/PostCard.web'
import RoundedButton from '../../../components/src/RoundedButton.web'
import FormModal, {
  InputRow,
  MInput,
  MCountedTextArea,
  MFileDrop,
  MTimePicker,
  MDatePicker,
  MSelect,
} from '../../../components/src/FormModal.web'
import CountedTextArea from '../../../components/src/CountedTextArea.web'
import DashboardLayout from '../../../components/src/DashboardLayout.web'
import FileDrop from '../../../components/src/FileDrop.web'
import SeeMore from '../../../components/src/SeeMore.web'
import Flex from '../../../components/src/Flex.web'
import { LoadingSpinner } from '../../../components/src/LoadingSpinner.web'
import MarketplaceTopbar from '../../../components/src/MarketplaceTopbar.web'
import UserContext from "../../../components/src/UserProvider";
import { FieldEffect } from '../../../components/src/FormikEffect.web'
import DeleteModal from '../../../components/src/DeleteModal.web'
import DiscountCard, { FilterSelect } from '../../../components/src/DiscountCard.web'
import InfiniteScroll from 'react-infinite-scroll-component';
import PlusMorePopup from '../../../components/src/PlusMorePopup.web'
import { Link } from 'react-router-dom';
import FilterPosts from '../../filteritems/src/FilterPosts.web'
import LikeAPost from '../../LikeAPost/src/LikeAPost.web'
import Maps from '../../maps/src/Maps.web'
import PreferenceCatSubCat from '../../categoriessubcategories/src/PreferenceCatSubCat.web'

import Events2Controller, {
  Props,
  configJSON
} from "./Events2Controller";
// @ts-ignore
import classes from './Event2Web.module.css'
import MediaCarousel from "../../../components/src/MediaCarousel.web";

// Customizable Area End
export default class Events2 extends Events2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  createEventModal() {
    const user = this.context
    console.log("user context ", user)
    const event_id_1 = `${user.first_name.slice(0, 3)}${user.last_name.slice(0, 2)}`.toUpperCase();
    const event_id_2 = this.state.eventID;
    const initialValues = {
      event_id_1,
      event_id_2,
      name: '',
      email: '',
      description: '',
      link: '',
      location: '',
      category_id: -1,
      language: '',
      participation_coins: '',
      start_date: null,
      end_date: null,
      start_time: null,
      end_time: null,
      preference_ids: [],
      category_ids: [],
      sub_category_ids: []
    }

    if (this.state.modal !== 'create') return <></>
    else
      return <Formik initialValues={initialValues} onSubmit={this.handleCreateFormSubmit} validationSchema={this.createFormSchema} >
        <div className={classes.modalTopCls}>
          <FormModal headerTitle={<CreateHeader>Create Event</CreateHeader>} submitLabel="Submit" onClose={this.hideModal} onSubmit={this.handleCreateFormSubmit}>
            <FieldEffect name="category_ids" onChange={this.categoryChangeEffect} />
            <FieldEffect name="preference_ids" onChange={this.preferenceChangeEffect} />
            <InputRow label="Event ID*" input={
              <IDWrapper>
                <Flex1>
                  <MInput name="event_id_1" placeholder="Event ID" disabled />
                </Flex1>
                <Flex1>
                  <MInput name="event_id_2" placeholder="Event ID" disabled />
                </Flex1>
              </IDWrapper>
            } />
            <InputRow label="Event Name*" input={<><MInput name="name" placeholder="Enter Event Name" /></>} />
            <InputRow label="Event Link*" input={<><MInput name="link" placeholder="Enter URL" /></>} />
            <InputRow label="Start Date and Time*" input={<Flex gap="5px">
              <StartWrapper>
              <StartDate >
                <MDatePicker name="start_date" InputProps={{ placeholder: "Enter Date" }} maxDate={this.state.end_date || null} disablePast onChange={this.startDateChange} />
              </StartDate>
              <StartTime>
                <MTimePicker name="start_time" InputProps={{ placeholder: "Enter Time" }} onChange={this.startTimeChange}/>
              </StartTime>
            </StartWrapper>
            </Flex>}
            />
            <InputRow label="End Date and Time*" input={<Flex gap="5px">
              <EndWrapper>
              <EndDate>
                <MDatePicker name="end_date" InputProps={{ placeholder: "Enter Date" }} disablePast minDate={this.state.start_date || null} onChange={this.endDateChange} />
              </EndDate>
              <EndTime>
                <MTimePicker name="end_time" InputProps={{ placeholder: "Enter Time" }} disablePast minTime={ this.state.start_time || null} onChange={this.endTimeChange} />
              </EndTime>
              </EndWrapper>
            </Flex>}
            />
            {/* @ts-ignore */}
            <InputRow label="Location*" name="location" input={<><Maps {...this.props} input /></>} />
            
            <PreferenceCatSubCat {...this.props} />
            <InputRow label="Language*" input={<><MInput name="language" placeholder="Enter" /></>} />
            <InputRow label="Participation Coins*" input={<><MInput name="participation_coins" placeholder="" /></>} />
            <InputRow label="Description*" input={<MCountedTextArea class={classes.rowTextStyleClass} name="description" />} />
            <InputRow label="Upload Image (Optional)" input={<><MFileDrop name="image" multiple/></>} />
          </FormModal>
        </div>
      </Formik>
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const eventApply = this.createEventModal()
    const createModal = this.createEventModal()
    const { navigation } = this.props
    const filterMenu = <FilterPosts filterAll={this.onFilterChange} onQueryChange={this.onFilterQueryChange} favouriteable_type="BxBlockEvents::Event" id={this.props.id} navigation={this.props.navigation} />
    const events = this.state.events
    console.log("state : ", this.state)
    const path = navigation.history.location.pathname
    // const image=this.state.events.map((i:any)=>i).map((item:any)=>item?.image)
    // console.log(image, "imagesss:::::")
    // Customizable Area End
    
    return (
      // Customizable Area Start
      <>
        {createModal}
        {eventApply}
        <DashboardLayout token={this.token} navigation={navigation}>
          <MarketplaceTopbar filters={filterMenu} userRole={this.context.role.role_level} buttonMinWidth="200px" onCreate={this.createEvent} selectedTab={1} onTabChange={() => { }} path={path}/>
          {this.state?.loadingEvent &&  <LoadingSpinner />}
          {!this.state.loadingEvent && this.state.events.length === 0 && <Typography style={{padding: '50px 0'}} align="center" variant="h5">No Match Found</Typography>}
          {this.state.deleteEventId && <DeleteModal label="Delete this event?" onClose={this.closeDeleteModal} onSubmit={this.handleEventDeleteConfirm} />}
          <Menu
        id="threedot-menu"
        anchorEl={this.state.threedotAnchorEl}
        anchorOrigin={{
          vertical: 'bottom', 
          horizontal: 'left'
        }}
        keepMounted
        open={Boolean(this.state.threedotAnchorEl)}
        onClose={this.handleThreeDotClose}
      >
        <MenuItem 
          disabled={this.state.currentEvent?.created_by?.id == this.context.id}
          onClick={this.handleEventReport}
        >
            Report This Listing
        </MenuItem>
        <MenuItem
          disabled={!(this.state.currentEvent?.created_by?.id == this.context.id)}
          onClick={this.handleEventEdit}
        >
          Edit
        </MenuItem>
        <MenuItem
          disabled={!(this.state.currentEvent?.created_by?.id == this.context.id)}
          onClick={this.handleEventDelete}
        >
          Delete
        </MenuItem>
        <MenuItem
          disabled={this.state.currentEvent?.created_by?.id == this.context.id}
          onClick={this.handleRaiseRequest}
        >
          Raise A Request
        </MenuItem>
      </Menu>

          <InfiniteScroll
          dataLength={events.length} //This is important field to render the next data
          next={this.loadNextPage}
          hasMore={(!!this.state.pagination?.next_page) && !this.state.loadingEvent}
          // Show only when this.state.loadingPosts component is not rendered
          loader={this.state.loadingEvent && <LoadingSpinner />}
        >
          {events && events.map(e => (
            <RoundedCard style={{ padding: '0' }} key={e.id} variant="outlined">
              <CardHeader
                style={{ padding: '25px 20px 15px 20px' }}
                avatar={<Link to={`/user/${e?.created_by?.id}`}><Avatar style={{ width: 55.26, height: 51.87 }} src={e.created_by?.profile_pic || ''} onClick={() => navigation.navigate(`user/${e?.created_by?.id}`)} /></Link>}
                title={<span style={{ fontWeight: 600, marginBottom: '4px', fontSize: "1.021rem", maxWidth: '30ch', overflow: 'hidden', textOverflow: 'ellipsis', display: 'block', textTransform: 'capitalize' }}><Link to={`/user/${e?.created_by?.id}`}>{e.created_by?.name}</Link></span>}
                subheader={<Grid style={{ gap: '10px' }} container alignItems="center">
                  <span style={{ textTransform: 'capitalize', fontSize: '0.75rem' }}>{e.created_by?.location}</span>
                  <Divider orientation="vertical" flexItem />
                  <span style={{ fontSize: '0.75rem' }}>{e.created_at?.replace(/about/g, '')}</span>
                </Grid>}

              // @todo this is scheduled for later release                        
              action={<div onClick={this.openThreeDot} data-id={e.id}>
                <IconButton style={{ backgroundColor: 'transparent' }} disableFocusRipple disableRipple>
                  <img src={MoreHorizIcon} width="30px" />
                </IconButton>
              </div>}

              >
              </CardHeader >
              <Divider />
              <CardHeader
                style={{ padding: '25px 20px 0px 30px' }}
                title={<span style={{ fontWeight: 600, marginBottom: '4px', fontSize: "1.021rem", maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis', display: 'block', textTransform: 'capitalize' }}>{e.name}</span>}
                subheader={<Grid style={{ gap: '10px' }} container alignItems="center">
                  <span style={{ textTransform: 'capitalize', fontSize: '0.75rem' }}>{e.location}</span>
                </Grid>}

              >
              </CardHeader >
              <CardContent style={{ padding: '16px 30px' }}>
                <img src={iconCalendar} style={{ width: '20px', marginBottom: '-4px', marginRight: '5px' }} />
                <Typography component="span" variant="body2"><b>{e.start_date_time} - {e.end_date_time}</b> </Typography>
                <FlexSpaceBetweenContent style={{ padding: '0', alignItems: 'start', paddingTop: '20px' }}>

                  <ContentWrapper>
                    <CourseContent>
                      Category :
                    </CourseContent>
                    <MR5>{e.categories ? e.categories[0] : ''}</MR5>
                    {e.categories?.length > 1 && <PlusMorePopup heading="Category" listItems={e.categories}>
                      +{e.categories?.length - 1}
                    </PlusMorePopup>}
                  </ContentWrapper>
                  <ContentWrapper>
                    <CourseContent>
                      Language :
                    </CourseContent>
                    <MR5>{e.language}</MR5>
                  </ContentWrapper>
                  <ContentWrapper1>
                    <CourseContent>
                      Event Link :
                    </CourseContent>
                    <MR5> <a href={e.link} style={{ color: "#48a15b", textTransform: 'none' }} target="_blank" ref="noreferrer noopener">
                            <div style={{display:'flex', flexDirection:'column', paddingLeft:'10px'}}>
                              <img src={LogoHex} width="25px" />
                                <InsertLinkIcon style={{color:'inherit', marginTop:'-16px', marginLeft:'8px'}}/>
                              </div>
                          </a> 
                    </MR5>
                  </ContentWrapper1>
                </FlexSpaceBetweenContent>
              {/*
                <FlexSpaceBetween style={{ gap: '10px', padding: '0', alignItems: 'start', marginTop: '15px' }}>
                </FlexSpaceBetween>
              */}
              </CardContent>
              {/* <Divider /> */}
              <CardContent>
                <Flex col py="10px" gap="20px" style={{ padding: '0px 15px 15px' }}>
                  <Flex col>
                    <Typography color="textSecondary" style={{ wordWrap: 'break-word', alignItems: 'justify' }}>{e.description.length > 455 ? `${e.description.substring(0, 455)}` : e.description}{e.description.length> 455 ? <span style={{ fontWeight: "bold"}} onClick={this.goToEvent(e.id)}>see more...</span> : null}</Typography>
                  </Flex>
                  { e.images && <MediaCarousel items={e.images} />}
                 {e.participation_coins>0 && <EarnCoins coins={e.participation_coins} /> }
                  <Flex align="center" justify="space-between">
                    <div style={{ lineHeight: '30px', display: 'flex', gap: '10px', fontSize: '0.875rem', color: '#212529' }}>
                      <img src={iconGreenThumb} width="30" />
                      <span>{e.total_likes}</span>
                      <img src={commentCount} width="30" />
                      <span>{e.total_comments}</span>
                    </div>
                    {e.is_interested ?
                    <ActionRoundedButtonInterested variant="outlined" disabled={e.created_by.id==this.context.id}>Interested</ActionRoundedButtonInterested> :
                    <ActionRoundedButton disabled={e.created_by.id==this.context.id} onClick={()=> this.onInterested(e.id)} >Interested</ActionRoundedButton> }
                  </Flex>

                </Flex>
              </CardContent>
              <Divider />
                <CommonActions
                  onStarClick={this.handleFavClick(e.id)}
                  onShareClick={this.handleShareCourse(e)}
                  isFavourite={e.is_favourite}
                  onCommentClick={this.goToEvent(e.id)}
                  likeButton={
                    <LikeAPost
                      navigation={this.props.navigation}
                      id=""
                      likeable_type="BxBlockEvents::Event"
                      isLiked={e.is_liked}
                      post_id={String(e.id)}
                      like_id={e.like_id[0]?.id}
                      onLikeChange={this.onLikeChange}
                      onLikeIdUpdate={this.onLikeIdUpdate}
                    />}
                />
            </RoundedCard>
          ))}
          </InfiniteScroll>
        </DashboardLayout>
        {this.state.modal === 'create' && createModal}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

Events2Controller.contextType = UserContext;

const EarnCoins = ({ coins }: { coins: number }) => <GreenBg>
  <img src={iconCorp} width='35px' />
  <Typography variant="h6" style={{ fontWeight: 600 }} color="primary">Earn {coins} Corpstage coins</Typography>
</GreenBg>

const TotalLikes = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
})

const RoundedCard = styled(Card)({
  borderRadius: '15px',
  padding: '10px 20px',
  margin: '20px 0px'
})

const StartWrapper = styled('div')({
  display: "flex",
  gap: "5px",
  // width: "100%",
  justifyContent: "space-between",
  '@media screen and (max-width: 480px)': {
    display: "flex",
    flexWrap: "wrap",
  }
})

const StartDate = styled('div')({
  flex: 1,
  '@media screen and (max-width: 480px)': {
    flex: 'none',
    width: "100%",
  }
})

const StartTime = styled('div')({
  flex: 1,
  '@media screen and (max-width: 480px)': {
    flex: 'none',
    width: "100%",
  }
})

const EndWrapper = styled('div')({
  display: "flex",
  gap: "5px",
  // width: "100%",
  justifyContent: "space-between",
  '@media screen and (max-width: 480px)': {
    display: "flex",
    flexWrap: "wrap",
  }
})

const EndDate = styled('div')({
  flex: 1,
  '@media screen and (max-width: 480px)': {
    flex: 'none',
    width: "100%",
  }
})

const EndTime = styled('div')({
  flex: 1,
  '@media screen and (max-width: 480px)': {
    flex: 'none',
    width: "100%",
  }
})

const IDWrapper = styled('div')({
  display: 'flex', 
  gap: '5px',
  '@media screen and (max-width: 480px)': {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  }
})

const Flex1 = styled('div')({
  flex: 1,
  '@media screen and (max-width: 480px)': {
    flex: 'none',
    width: '100%',
  }

})

const FlexSpaceBetweenContent = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignContent: 'center',
  padding: '20px 0px',
  width: '100%',
  gap: '10px',
  "@media screen and (max-width: 480px)": {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
})

const ContentWrapper = styled('div')({
  flex: 1,
  "@media screen and (max-width: 480px)": {
    // display: "flex",
    flex: "none",
  },
})

const ContentWrapper1 = styled('div')({
  display: 'flex',
  alignItems: 'center',
  "@media screen and (max-width: 480px)": {
    display: "flex",
    flex: "none",
  },
})

const FlexSpaceBetween = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignContent: 'center',
  padding: '20px 0px',
  width: '100%',
})

const MR5 = styled('span')({
  marginRight: '5px',
  color: '#2a353f',
  textTransform: 'capitalize'
})

const CourseContent = styled('span')({
  fontWeight: 700,
  fontSize: "15px",
  marginRight: '5px'
})

const ActionRoundedButton = styled(Button)((props: any)=>({
  color: '#2caa38',
  border: `2px solid ${props.disabled?'rgba(0, 0, 0, 0.26)':'#79bb74'}`,
  borderRadius: '25px',
  padding: '5px 35px',
  textTransform: 'none',
  fontWeight: 600
 }))

 const ActionRoundedButtonInterested = styled(Button)((props: any)=>({
   color: '#fff',
   background: '#2caa38',
   border: '2px solid #79bb74',
   borderRadius: '25px',
   padding: '5px 35px',
   textTransform: 'none',
   fontWeight: 600,
   "&:hover": {
     background: '#2caa38',
   }
  }))

const ActionRoundedButtonInterestedDisabled = styled(ActionRoundedButtonInterested)({

})

const GreenBg = styled('div')({
  padding: '15px',
  background: '#F2F7E4',
  borderRadius: '20px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '25px'
})

const GreyText = styled('span')({
  color: '#777'
})

const CreateHeader = styled('div')({
  fontSize: '24px',
  fontWeight: 500,
  fontFamily: 'HelveticaNeue'
})

const EventLabel = styled('div')({
  fontSize: '15.35px',
  color: '#404040',
  fontFamily: 'HelveticaNeue'
})

// Customizable Area End

import React, { useEffect } from 'react'
import { Box, Grid, Accordion, AccordionSummary, Typography, AccordionDetails, Button } from '@material-ui/core';
// Customizable Area Start
import { withStyles, styled, makeStyles } from '@material-ui/styles'
import Avatar from '@material-ui/core/Avatar';
import ForumIcon from '@material-ui/icons/Forum';
import CloseIcon from '@material-ui/icons/Close';

// Customizable Area End-

import LeftMenuWeb from "./LeftMenuWeb";
import RightMenuWeb from "./RightMenuWeb";
import TopNavBar from "./TopNavbar";

interface DashboardLayoutProps {
	children: React.ReactNode;
	navigation: any;
	token?: string;
	showChatMobileView?: any;
	isActivityFeed?: any;
	changeChatMobileView?: any;
	isUserSurvey?:boolean;
	isDashboard?:boolean;
}

const DashboardLayout = ({ children, navigation, token, changeChatMobileView, showChatMobileView, isActivityFeed,isUserSurvey=true, isDashboard=false }: DashboardLayoutProps) => {
	return (<MainBox >
	        <Box>
	          <TopNavBar navigation={navigation} id={token||""} />
	        </Box>
	        <OuterGrid container spacing={0}>
	            <LeftGrid item xs={12} sm={12} md={2} lg={2}>
				{/* @ts-ignore */}
	                <Box >
	                    <LeftMenuWeb navigation={navigation} id={""}/>
	                </Box>
	            </LeftGrid>
	            <MiddleGrid item xs={12} sm={12} md={isUserSurvey ? 6 : isDashboard ? 10 : 6} lg={isUserSurvey ? 6 : isDashboard ? 10 : 9} >
	            <Box style={showChatMobileView ? { width: 0, display: 'none' } : { width: navigation.history.location.pathname=='/Surveys' || '/Surveys/CreateSurveys' ? '100%' : '100%' }}>
	               {children}
	            </Box>
	            </MiddleGrid>
				{isActivityFeed &&
					<>
						{showChatMobileView ?
							<ChatCloseAvatar onClick={changeChatMobileView}>
								<CloseIcon />
							</ChatCloseAvatar>
						:
							<ChatAvatar onClick={changeChatMobileView}>
								<ForumIcon />
							</ChatAvatar>
						}
					</>
				}
				{isUserSurvey &&
	            <RightGrid item style={showChatMobileView ? {width: '100%', maxWidth: '100%'} : {}}>
				{/* @ts-ignore */}
	             	<Box>
	                    <RightMenuWeb showChatMobileView={showChatMobileView} navigation={navigation} id={""}/>
	                </Box>
	            </RightGrid>
                } 
	        </OuterGrid>
	    </MainBox>)
}

const MainBox = styled(Box)({
	backgroundColor:"#edebeb",
	display:"flex",
	flexWrap: 'wrap',
	flexDirection:"column",
	paddingBottom:"20px",
	overflowX:"hidden",
})

const OuterGrid = styled(Grid)({
	display: 'flex', 
	flexWrap: 'wrap',
	justifyContent: 'space-between',
})

const LeftGrid = styled(Grid)({
	// maxWidth: '300px',
	'@media screen and (max-width: 985px)': {
        maxWidth: '200px',
      },
	  '@media screen and (max-width: 959px)': {
        display: 'none',
        visibility: 'hidden',
    },
})

const MiddleGrid = styled(Grid)({
	width: 'calc(100vw - 600px)',
	// maxWidth: 'unset',
	// flexBasis: 'unset',
	display: 'flex',
	justifyContent: 'center',
	'@media screen and (max-width: 985px)': {
        width: 'calc(100vw - 500px)',
      },
	'@media screen and (max-width: 768px)': {
        width: '100%'
      }
})

const RightGrid = styled(Grid)({
	maxWidth: '300px',
	'@media screen and (max-width: 985px)': {
        maxWidth: '250px',
      },
	  '@media screen and (max-width: 768px)': {
        maxWidth: 'auto',
		width: '100%'
    },
})

const ChatAvatar = styled(Avatar)({
	display: 'none',
	width: '50px',
	height: '50px',
	backgroundColor: '#1ea60a',
	fontSize: '12px',
	fontWeight: 'bold',
	position: 'fixed',
	bottom: '15px',
	right: '15px',
	zIndex: 9999999,
	cursor: "pointer",
	'@media screen and (max-width: 768px)': {
        display: 'flex',
      }
})

const ChatCloseAvatar = styled(Avatar)({
	display: 'none',
	top: "85px",
	width: '50px',
	height: '50px',
	backgroundColor: '#1ea60a',
	fontSize: '12px',
	fontWeight: 'bold',
	position: 'absolute',
	left: '45%',
	cursor: "pointer",
	'@media screen and (max-width: 768px)': {
        display: 'flex',
      }
})

export default DashboardLayout
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { sendAPIRequest } from '../../../components/src/utils';
import { sendSharePopupMessage } from "../../share/src/Messages";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    userId: string;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    userProfile: any;
    followers: number;
    isCurrentUser: boolean;
    loading: boolean;
    anchorElForPopupNested:  HTMLElement | null;
    allViewer: {}[];
    anchorElForPopupFollower: HTMLElement | null;
    allFollowers: {}[];
    corporate_profile_card_present: boolean;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class PublicProfileController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    apiGetUserProfileCallId = '';
    apiFollowUnfollowCallId = '';
    getAllViewersCallId ="";
    getAllFollowersCallId = "";
    userId = this.props.navigation.getParam('user_id');
    token:string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage)
            // Customi.able Area End
        ];

        this.state = {
            // Customizable Area Start
            userProfile: {},
            followers: 0,
            isCurrentUser: false,
            loading: false,
            anchorElForPopupNested:null,
            allViewer: [],
            anchorElForPopupFollower: null,
            allFollowers: [],
            corporate_profile_card_present:false,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End

    }

    async receive(from: string, message: Message) {
        // Customizable Area Start

        runEngine.debugLog("Message Recived", message);

        if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
            let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

        }

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
            runEngine.debugLog("API Message Recived", message);
            if (responseJson && responseJson.errors) {
                this.setState({loading: false});
                this.parseApiErrorResponse(responseJson.errors);
                this.parseApiCatchErrorResponse(responseJson.errors);
            } else if (responseJson) {
                switch (apiRequestCallId) {
                    case this.apiGetUserProfileCallId:
                        this.handleUserProfileResponce(responseJson);
                        break;
                    case this.apiFollowUnfollowCallId:
                        this.handleFollowUnfollowApiResponce(responseJson);
                        break;
                    case this.getAllViewersCallId: {
                        this.setState({allViewer: responseJson.data, loading:false})
                        break;
                    }
                    case this.getAllFollowersCallId: {
                        this.setState({allFollowers: responseJson.data, loading: false})
                        break;
                    }
                    default:
                        break;
                }
            } else if (errorReponse) {
                this.setState({loading: false});
                this.parseApiErrorResponse(errorReponse);
                this.parseApiCatchErrorResponse(errorReponse);
            }
        }
        // Customizable Area End

    }


    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        let authToken = await getStorageData('authToken');
        this.token = authToken
        this.onInitialized();
    }

    componentDidUpdate(prevProps: Readonly<Props>): void {
        if(this.props.userId !== prevProps.userId) {
            this.onInitialized();
        }    
    }

    openFollowerList = async(e:any) => {
        let authToken = this.token;
        this.setState({...this.state, anchorElForPopupFollower: e.currentTarget,anchorElForPopupNested:null,allFollowers:[], loading:true}) 
        const userID =JSON.parse(this.props.userId)
        const requestURL = configJSON.allFollowers + (`?user_id=${userID}&followers=true`);
        this.getAllFollowersCallId = sendAPIRequest(requestURL, { method: 'GET', headers: {token: authToken} })
      }

    handleCloseConnection = (follower:boolean) => {
        if(follower){
          this.setState({anchorElForPopupFollower:null})
        }else{
          this.setState({anchorElForPopupNested:null})
        }
      }

    openViewsMenu = async(e:any) => {

        this.setState({...this.state, anchorElForPopupNested: e.currentTarget, anchorElForPopupFollower:null,allViewer:[], loading:true})
        const userID = JSON.parse(this.props.userId)
        const requestURL = configJSON.allViewers + (`?user_id=${userID}`);
          this.getAllViewersCallId = sendAPIRequest(requestURL, {
            method: 'GET',
            headers: {token: this.token}
          })
      }

    onInitialized = async() => {
        let authToken = await getStorageData('authToken');

        this.apiGetUserProfileCallId = sendAPIRequest(configJSON.getUserProfile + JSON.parse(this.props.userId || '0'),
            { headers: { token: authToken } },
        );
    }

    handleUserProfileResponce = async(json: any) => {
        if (!json?.data) return
        const userProfile = { ...json.data.attributes, ...json?.meta };
        const userData = await getStorageData('user');
        
        let currentUser = JSON.parse(userData as string);
        this.setState({ userProfile, corporate_profile_card_present: userProfile.corporate_profile_card_present, followers: userProfile.total_followers, isCurrentUser: (Number(currentUser?.id || 0) === Number(userProfile.id || 0)) })
    }

    followUnFollowUser = async() => {
        let authToken = await getStorageData('authToken');
        
        this.setState({ loading: true })
        let requestBody = {
            data: {
                attributes: {
                    account_id: JSON.parse(this.props.userId || '0')
                }
            }
        };
        const { userProfile } = this.state;
        this.apiFollowUnfollowCallId = userProfile?.is_follow ?
            sendAPIRequest(
                `${configJSON.followUserProfile}/${JSON.parse(this.props.userId || '0')}`,
                { method: 'DELETE', headers: { token: authToken } },
            ) : sendAPIRequest(
                configJSON.followUserProfile,
                {
                    body: requestBody,
                    method: 'POST',
                    headers: { token: authToken, 'Content-Type': 'application/json' }
                },
            );
    }

    handleClick = () => {

    }

    goToPosts = () => {
        this.props.navigation?.navigate('ActivityFeed')
    }

    handleFollowUnfollowApiResponce = (json: any) => {
        let { userProfile, followers } = this.state;
        userProfile?.is_follow ? followers-- : followers++
        let userProfileStateData = { ...userProfile, is_follow: userProfile?.is_follow ? false : true };
        this.setState({ userProfile: userProfileStateData, followers, loading: false });
    }

    handleShareProfile = (profile: any) => {
        let data = {
            userId: profile?.id,
            link: `${window?.location?.origin}/user/${JSON.parse(profile?.id || '0')}`,
            shareableType: 'AccountBlock::Account',
            shareableId: profile?.id,
        }
        sendSharePopupMessage(data);
    }
    // Customizable Area End

}

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { sendAPIRequest } from '../../../components/src/utils'
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { Notification } from './types'
import { getStorageData } from "../../../framework/src/Utilities";


export function sendNewNotificationMessage(notification: any){
  const msg = new Message(
    getName(MessageEnum.NewNotificationMessage)
  )
  msg.addData(
    getName(MessageEnum.NewNotificationData),
    notification
  )
  runEngine.sendMessage(msg.id, msg)
}

export function sendMarkAllAsRead(token: string){
  const call = sendAPIRequest(configJSON.markAllReadEndpoint, {
      method: 'PUT',
      headers: {token}
    })
  const msg = new Message(
    getName(MessageEnum.MarkAllNotificationReadMessage)
  )
  runEngine.sendMessage(msg.id, msg)
  return call
}

export function sendMarkRead(id: string, token: string){
  const url = configJSON.markReadEndpoint.replace(':id', id)
  const msg = sendAPIRequest(url, {
      method: 'PATCH',
      headers: {token}
    })
  return msg
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  anchorEl: any | null;
  token?: string;
  notifications: (Notification | string)[];
  loadingNotifications?: boolean;
  pagination?: any;
  query?: string;
  searchCallId?: string;
  typefilter?: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AllNotificationsController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  allNotificationsCallId: string = ""
  paginateCallId: string = ""
  lastDate: string = "" // last date added to the notifications list
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.MarkAllNotificationReadMessage),
      getName(MessageEnum.NewNotificationMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      anchorEl: null,
      notifications: [],
      token: '',
      loadingNotifications: false,
      typefilter: '',
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
    }

    if(message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const requestId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
      if(requestId === this.allNotificationsCallId || requestId === this.state.searchCallId) {
        if(!Array.isArray(responseJson.notifications.data)) return
        let notifications: any = responseJson.notifications.data.map((n: any) => n.attributes)
        notifications = this.initNotifications(notifications)
        this.setState({ 
          notifications, 
          pagination: responseJson.meta?.pagination,
          loadingNotifications: false
        })
        
      } else if (requestId === this.paginateCallId ) {
        if(!Array.isArray(responseJson.notifications.data)) return 
        const newNotifications = responseJson.notifications.data.map((n: any) => n.attributes)
        const notifications = [
            ...this.state.notifications, 
            ...this.pushNotifications(newNotifications),
          ]
        this.setState({ 
          notifications, 
          pagination: responseJson.meta?.pagination, 
          searchCallId: '', 
          loadingNotifications: false 
        })

      }
    } else if(message.id === getName(MessageEnum.MarkAllNotificationReadMessage)) {
      let notifications = this.state.notifications.map((n:any)=>typeof n ==="string"?n:({...n, is_read: true}))
      this.setState({ notifications })
    } else if (message.id === getName(MessageEnum.NewNotificationMessage)) {
      let notification = message.getData(
        getName(MessageEnum.NewNotificationData)
      )
      this.newNotificationAdd(notification)

    }
    // Customizable Area End

  }

  

  // Customizable Area Start
  filterButtons: any[] = [
    {id: "", label: "All"},
    {id: "BxBlockPosts::Post", label: "Post"},
    {id: "BxBlockJobListing2::Job", label: "Jobs"},
    {id: "BxBlockEvents::Event", label: "Events"},
    {id: "BxBlockCfproductlisting11::Product", label: "Products"},
    {id: "BxBlockCfcourselisting5::Course", label: "Courses"},
    {id: "AccountService", label: "Services"},
    {id: "BxBlockProjectportfolio::Project", label: "Projects"},
  ]

  fetchAllNotifications = () => {
    const { token } = this.state
    this.allNotificationsCallId = sendAPIRequest(configJSON.allNotificationsEndpoint, {
      method: 'GET',
      headers: { token }
    })
  }

  async componentDidMount() {
    let authToken = await getStorageData("authToken")

    this.setState({token: authToken}, () => {
    this.fetchAllNotifications()
    });
  }

  getDate = (isoTimeString: string) => {
    const date = new Date(isoTimeString)
    const today = new Date()
    const yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1)
    if(date.toDateString() === today.toDateString()) return "Today"
    if(date.toDateString() === yesterday.toDateString()) return "Yesterday"
    let displayDate = date.getDate() + ' ' + date.toLocaleString('en-us', { month: 'long' })
    return displayDate
  }

  loadNextPage = () => {
    const next_page = this.state.pagination.next_page
    const { token } = this.state
    const params = this.getQueryParams(this.state.query||'', this.state.typefilter||'', next_page||'')
    this.paginateCallId = sendAPIRequest(configJSON.allNotificationsFilterEndpoint+`?${params}`, {
      method: 'GET',
      headers: { token }
    })
  }

  handleSearch = async(query: string) => {
    if(this.state.query === query) return // avoid duplicate calls
    const params = this.getQueryParams(query, this.state.typefilter||'')
    let authToken = await getStorageData("authToken")
    if(authToken){
      const searchCallId = sendAPIRequest(configJSON.allNotificationsFilterEndpoint+"?" +params, {
          headers: {token:authToken},
          method: 'GET'
        })
      this.setState({ searchCallId, query, loadingNotifications: true })
    }
  }

  getQueryParams = (query: string, filter: string, page?: string) => {
    const params = new URLSearchParams()
    if(query)
      params.append('query', query)
    if(filter)
      params.append('type', filter)
    if(page)
      params.append('page', page)
    return params.toString()
  }

  handleFilter = (e: any) => {
    const filter = e.currentTarget.dataset.id
    if(this.state.typefilter === filter) return
    const params = this.getQueryParams(this.state.query||"", filter||"")
    const { token } = this.state
    const searchCallId = sendAPIRequest(configJSON.allNotificationsFilterEndpoint+"?" +params, {
        headers: {token},
        method: 'GET'
    })
    
    this.setState({ typefilter: filter, searchCallId, loadingNotifications: true })
  }
  
  handleRead = (e: any) => {
    const id = e.currentTarget.dataset.id
    const n = this.getNotificationByID(id)
    
    if(typeof n === "string") return // ts fix
    if(!n || n.is_read) return
    const notifications = this.state.notifications.map((n: any) => {
      if(n.id!==id) return n
      return {...n, is_read: true}
    })
    sendMarkRead(id||'', this.state.token||'')

    this.setState({ notifications })
  }

  getNotificationByID = (id: any) => {
    return this.state.notifications.find((x: any)=>x.id == id)
  }

  initNotifications = (notifications: any) => {
    if(!notifications.length) {
      this.setState({ notifications })
      return []
    }
    let lastDate: string = ''
    const notifs: any[] = []
    notifications.forEach((n: any) => {
      const d = this.getDate(n.created_at)
      if(lastDate!=d)
        notifs.push(d)
      lastDate = d
      notifs.push(n)
    })
    this.lastDate = lastDate 
    return notifs
  }

  pushNotifications = (notifications: any)=>{
    let lastDate = this.lastDate || ''
    const notifs: any[] = []
    notifications.forEach((n: any)=>{
      const d = this.getDate(n.created_at)
      if(lastDate!=d)
        notifs.push(d)
      lastDate = d
      notifs.push(n)

    })
    this.lastDate = lastDate 
    return notifs
  }

  newNotificationAdd = (notification: any) => {
    let notifications = [...this.state.notifications]
    notifications.splice(1, 0, notification)
    this.setState({ notifications })
  }
  // Customizable Area End
  
}

import React from "react";


// Customizable Area Start
import { Formik, useFormikContext } from 'formik'
import { styled } from '@material-ui/styles'
import { InputAdornment } from '@material-ui/core'


import PreferenceCatSubCat from '../../categoriessubcategories/src/PreferenceCatSubCat.web'

import UserContext from "../../../components/src/UserProvider";
import FormModal, { InputRow, MInput, MSelect, MFileDrop, MCountedTextArea, MCheckboxes } from '../../../components/src/FormModal.web'


import Flex from '../../../components/src/Flex.web'

// @ts-ignore
import classes from '../../CfCourseListing5/src/CfCourseListing5Web.module.css'
import OverlayLoader from '../../../components/src/Loader.web'

// Customizable Area End

import CreateProductController, {
  Props,
  configJSON
} from "./CreateProductController";
export default class CreateProduct extends CreateProductController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  
  createProductModal = () => {
    let user = this.context
    let product_id_1 = `${user.first_name.slice(0,3)}${user.last_name.slice(0, 2)}`.toUpperCase()
    let product_id_2 = this.state.randomProductId || '-----'
    if (this.state.product?.product_unique_id) {
      product_id_1 = this.state.product?.product_unique_id.split('-')[0]
      product_id_2 = this.state.product?.product_unique_id.split('-')[1]
    }
    let product = this.state.product
    let initialValues: any = { 
      product_id_1, 
      product_id_2, 
      product_name: product?.product_name || '',
      preference_ids: product?.preference_ids || [],
      category_ids: product?.category_ids || [],
      sub_category_ids: product?.Sub_category_ids || [], 
      website_link: product?.website_link || '', 
      price: product?.price.usd || '', 
      price_inr: product?.price.inr || '', 
      coin_discount: product?.coin_discount || '0', 
      description: product?.description || '', 
      uploaded_image: product?.images || [],
      image: '',
      equivalent_coins: product?.equivalent_coins || '0'
    }
    let attachmentName = ""
    if (this.state.product?.attachments)
      attachmentName = this.state.product.attachments.map((j: any) => j.filename).join(', ')
    const isEditPage = !!this.props.navigation.getParam('id')
    return <Formik enableReinitialize initialValues={initialValues} validationSchema={isEditPage ? this.updateFormSchema : this.createFormSchema} onSubmit={this.handleCreateProductSubmit}>
        {({ values, errors }: any) => (
        <FormModal headerTitle={!isEditPage ? <CreateHeader>Create Product</CreateHeader>: <CreateHeader>Edit Product</CreateHeader>} submitLabel={!isEditPage ? "submit" : "update"} onClose={this.hideModal} >
          <LogFormik />
          <InputRow label="Product ID" input={
                 <IDWrapper>
                    <Flex1>
                      <MInput disabled name="product_id_1"  placeholder="Product ID" />
                    </Flex1>
                    <Flex1>
                      <MInput disabled name="product_id_2" placeholder="Product ID" />
                    </Flex1>
                  </IDWrapper>
              } />
          <InputRow label="Product Name*" input={
              <MInput name="product_name" placeholder="Enter product here" />
          } />
          {(!isEditPage || this.state.product) && <PreferenceCatSubCat initialValues={this.state.product} {...this.props} />}
          <InputRow label="Website Link*" input={
                <MInput name="website_link" placeholder="Enter URL" />
          } />
          <InputRow label="Price*" input={
            <Flex col gap="5px">
              <MInput name="price" type="number" placeholder="Enter" InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>
                }} 
              />
              <MInput name="price_inr" type="number" placeholder="Enter" InputProps={{
                  startAdornment: <InputAdornment position="start">₹</InputAdornment>
                }} 
              />
            </Flex>
          } />
          <InputRow label="Coin Discount (%)*" input={
              <MInput name="coin_discount" />
          } />
          <InputRow label="Equivalent Coins*" input={
              <MInput name="equivalent_coins" disabled={values.coin_discount<=0} />
          } />
          <InputRow label="Product Description*" input={
              <MCountedTextArea name="description" placeholder="Product description" class={classes.rowTextStyleClass} />
          } />
          <InputRow label="Upload Product Image*" input={
            <>
              <MFileDrop inputProps={{ accept: "image/*" }} multiple name="image" />
            </>
        } />
        </FormModal>
        )}
      </Formik>
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const create = this.createProductModal()
    console.log("prodct_data", this.state.product)
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
      {create}
      <OverlayLoader loading={!!this.state.submittingForm} />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

CreateProductController.contextType = UserContext

const RateButton = styled('button')({
  background: '#ececec',
  border: '2px solid #e3e3e3',
  borderRadius: '10px',
  color: '#727373',
  padding: '5px 15px',
  marginTop: '0px'
})

const IDWrapper = styled('div')({
  display: 'flex', 
  gap: '5px',
  '@media screen and (max-width: 480px)': {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  }
})

const CreateHeader = styled('div')({
  fontSize: '24px',
  fontWeight: 500,
  fontFamily: 'HelveticaNeue'
})


const Flex1 = styled('div')({
  flex: 1,
  '@media screen and (max-width: 480px)': {
    flex: 'none',
    width: '100%',
  }
})

const LogFormik = ()=>{
  const ctx = useFormikContext()
  console.log({ createProductFormik: ctx })
  return <></>
}

// Customizable Area End

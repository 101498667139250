Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
const config = require("../../../framework/src/config");
exports.baseURLString = config.baseURL;
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Surveys";
exports.labelBodyText = "Surveys Body";

exports.btnExampleTitle = "CLICK ME";

exports.getUserSurveyEndpoint = "bx_block_surveys/user_surveys"
exports.getUserSurveyProjectEndpoint = "bx_block_promote_content/user_projects"
exports.getAccountEndpoint = "account_block/accounts/filter";
exports.getUsersEndpoint = "bx_block_surveys/surveys/users";  //this api is just used for getting selected users in surveys first

exports.getRequestedByEndpoint = "bx_block_surveys/surveys/account";
exports.createTemplateUniqueId = "bx_block_surveys/surveys/generate_uniq_id";
exports.createSurveyTemplate="bx_block_surveys/surveys";
exports.getSurveyByIdEndpoint="bx_block_surveys/surveys/:sid";
exports.getSurveyTemplates="bx_block_surveys/surveys/my_surveys";
exports.addQuestionEndpoint="bx_block_surveys/surveys/:qid/survey_questions"
exports.getTaggedCommunities="bx_block_surveys/surveys/get_communities";

exports.getCommunities="bx_block_communityforum/community/community";
exports.getTaggedCommunitiesWithPagination="bx_block_surveys/surveys/communities"; ////this api is just used for getting selected communities in surveys first

exports.getSurveyEditEndpoint="bx_block_surveys/surveys";
exports.deleteSurveyTemplate="bx_block_surveys/surveys/:id";
exports.getRequestorsEndpoint="bx_block_surveys/surveys/request_accounts";
exports.senEmailEndpoint="bx_block_surveys/surveys/send_mail";
exports.importCsvEndpoint="bx_block_surveys/surveys/import_file";
exports.endUserQuestion="/bx_block_surveys/surveys";
exports.downloadReportEndpoint="/bx_block_surveys/surveys/";
exports.downloadPDFReportEndpoint="/bx_block_surveys/surveys/:id/get_survey_pdf_user";
exports.getLikeForProjectEndpoint="/bx_block_like/likes";

exports.downloadSurveyPdfReport="bx_block_surveys/surveys/:id/survey_responses/download_pdf_report";
exports.downloadSurveyExcelReport="bx_block_surveys/surveys/:id/survey_responses/download_xls_report";
exports.getAllAssesments="bx_block_surveys/survey_responses/mandatory_survey_current_user";
exports.showAssesment="/bx_block_surveys/survey_responses/show_response";
exports.getAdminAssesment="bx_block_surveys/survey_responses/mandatory_survey_all_response";
exports.getUserRoleEndpoint = "account_block/accounts/get_user_role"
exports.editApiendpoint="bx_block_surveys/surveys/201/survey_responses/:id"

exports.getAllProjectCommentCallId="bx_block_projectportfolio/comments?promote_content_id=:project_id";
exports.projectLikeEndpoint = "bx_block_like/likes"
exports.projectCommentReplyEndpoint = "bx_block_projectportfolio/comments/create_reply"
exports.projectCommentEndpoint = "bx_block_projectportfolio/comments"
exports.getUserLikeCountEndpoint = "bx_block_projectportfolio/promote_contents/get_likes_count?promote_content_id=:id"
exports.ProjectPromoteEndpoint="/bx_block_projectportfolio/promote_contents";
exports.projectPromoteEnquiryEndpoint = "bx_block_projectportfolio/project_enquiries"
exports.endUserScoringQuestion="/bx_block_scoring/surveys";

// Fill Survey
exports.labelFilterBy = "Filter by:";

// ESG Journey Cards
exports.esgJourneyCards = "bx_block_surveys/self_assessments"
exports.accessUser = "bx_block_abilities/abilities"
exports.getEmailData = "bx_block_abilities/abilities/get_email_corporate_users"
exports.addAbilityData = "bx_block_abilities/abilities"
exports.deleteAbility = "/bx_block_abilities/abilities/:id"
exports.showAbility = "bx_block_abilities/abilities/:id"
exports.updateAbility = "bx_block_abilities/abilities/:id"
exports.showAllCards = "bx_block_abilities/abilities/show_esg_journey"
exports.likeSharePost="account_block/accounts/user_profile/"
exports.getStatusEndpoint="bx_block_risk_management/objectives/permissions"

// Initiative evaluetion
exports.getAllProjects = "bx_block_project_evaluation/project_evaluation/all?"
exports.updateprojectEval = 'bx_block_project_evaluation/project_evaluation/update'
exports.postproject = 'bx_block_project_evaluation/project_evaluation/create'
exports.getSponsorList =  'bx_block_project_evaluation/project_evaluation/search_sponsors'
exports.getUserList = "bx_block_project_evaluation/project_evaluation/search_all_approvers";
exports.getUpdateStatus = 'bx_block_project_evaluation/project_evaluation/check_status_access'
exports.updateStatus = 'bx_block_project_evaluation/project_evaluation/set_status'
exports.getFinanceReport="bx_block_project_evaluation/project_evaluation/download_finance_sheet"
exports.getProjectEvalution = 'bx_block_project_evaluation/project_evaluation/generate'
exports.getShowApi='bx_block_project_evaluation/project_evaluation/show'
exports.getTaggedProject='bx_block_peoplemanagement2/corporate_profile_dashboards/get_user_projects'
exports.getScoringSurveyByIdEndpoint="bx_block_scoring/surveys/:sid"
exports.getScoringQuestionByIdEndpoint="bx_block_scoring/surveys/:sid/survey_questions"
exports.removeAttachmentScoringQuestion="bx_block_scoring/surveys/:sid/surveys_questions/:qid/remove_question_attachments"
exports.removeAttachmentSurveyQuestion="bx_block_surveys/surveys/:sid/surveys_questions/:qid/remove_question_attachments"
exports.deleteProjectEvaluationAttachmentEndPoint="bx_block_project_evaluation/project_evaluation/delete/proposal_documents"
exports.deleteProjectEvaluationReferenceAttachmentEndPoint="bx_block_project_evaluation/project_evaluation/delete/reference_documents"
exports.deleteProjectEvaluationFinanceAttachmentEndPoint="bx_block_project_evaluation/project_evaluation/delete/finance_sheet"
exports.carbonCalculateToolUrl=`${config.baseURL}/bx_block_surveys/surveys/download_carbon_calculator`;
exports.labelFilterBy = "Select Quarter"
exports.createdQuarter = "Create New Quarter"
exports.createQuarterEndPoint = "/bx_block_surveys/surveys/:sid/copy_mandatory_survey"
exports.getQuarterData = "/bx_block_surveys/surveys/:sid/get_survey_date_from_template_name"
exports.getCardsEndPoint="/bx_block_surveys/survey_cards"
exports.getReportingFrameworkCardsEndPoint="/bx_block_surveys/reporting_frameworks"
exports.saveNewSequenceQuestions="bx_block_surveys/surveys/:id/update_sequence"
exports.getAvaliableParentQuestions="/bx_block_surveys/surveys/:survey_id/survey_questions/three_types_questions"
exports.generateQuesUniqId="bx_block_surveys/surveys/survey_questions/generate_ques_uniq_id"

// Customizable Area End
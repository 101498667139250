import React, { Component } from "react";
import {
  Dialog,
  Box,
  Grid,
  DialogContent,
  Typography,
  Divider,
  Button,
  TextField
} from "@material-ui/core";
import StripeCheckout from 'react-stripe-checkout';
export const configJSON = require("../../blocks/customisableusersubscriptions/src/config.js");
import { withStyles, styled, makeStyles } from '@material-ui/styles'
import { CloseIcon } from "../../blocks/postcreation/src/assets";
import CustomisableusersubscriptionsController from "../../blocks/customisableusersubscriptions/src/CustomisableusersubscriptionsController"
import SeeMoreSubscribtion from "../src/SeeMoreSubscribtion.web"
import { formatMoneyINR, formatMoneyUSD } from './utils'
import {Formik, Form, useField} from 'formik'
import * as Yup from 'yup';
import { FormikSubmit } from './FormModal.web'

export default function ChoosePackageSubscribtionPopup(props: any) { 
    const { open, closepopup,subscribtionList,subscriptionReqProps,requestpmCorporatetValue,
      handlesubscribtionindividualReqProps,
      requestindividualValue,
      variablePmCorporateINRCost,makePayment,pay,payServiceProvider,payment} = props;
    console.log("POPUPDATA",props.subscribtionPopupdata)
   const onSubmit = ()=>{
    if(props.subscribtionPopupdata.attributes.license_coverd !== null)
      pay(
      props.subscribtionPopupdata,
     Number(props.subscribtionPopupdata.attributes.member_covered)+Number(requestpmCorporatetValue)+Number(requestindividualValue),
      Number(Number(props.subscribtionPopupdata.attributes.price_inr)),
      Number(Number(props.subscribtionPopupdata.attributes.price))
           )
      else pay(
                    
                   props.subscribtionPopupdata,
                   Number(props.subscribtionPopupdata.attributes.member_covered)+Number(requestpmCorporatetValue)+Number(requestindividualValue),
                   Number(Number(props.subscribtionPopupdata.attributes.price_inr)+Number(props.subscribtionPopupdata.attributes.additional_pm_corpo_license_inr)*Number(props.subscribtionPopupdata.attributes.period)*Number(requestpmCorporatetValue)),
                   Number(Number(props.subscribtionPopupdata.attributes.price)+Number(props.subscribtionPopupdata.attributes.additional_pm_corpo_license_usd)*Number(props.subscribtionPopupdata.attributes.period)*Number(requestpmCorporatetValue)),
                   Number(requestpmCorporatetValue)+Number(requestindividualValue)
                   )       
   }
   const schema = Yup.object().shape({
      addPm: Yup.number().typeError("Must be a number")
.min(0,"Must be a positive number").label('Additional PM').required("Required"),
      addInd: Yup.number().typeError("Must be a number")
.min(0, "Must be a positive number").label('Additional Individual').required("Required"),
    })
    const intialValues = {
      addPm: '0',
      addInd: '0'
    }
    return (
      // Customizable Area Start
      <Dialog open={open} onClose={closepopup} maxWidth="md">
        <DialogContent>
          <Formik validationSchema={schema} onSubmit={onSubmit} initialValues={intialValues}>
          <Form translate="no">
          <Box>
            {/* {
             props.subscribtionPopupdata.map((item:any)=>{
                return( */}
                  <>
                    <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "20px",
              }}
            >
              <div>
                <TextHeading style={{fontWeight:800,textTransform:"uppercase"}}>Choose package</TextHeading>
              </div>
              <div>
                <img
                  style={{ width: "30px", height: "30px" }}
                  src={CloseIcon}
                  onClick={closepopup}
                />
              </div>
            </Box>
            <Divider variant="fullWidth" />
            <TitleBox>
              <div>
                <TextHeading style={{fontWeight:800,textTransform:"capitalize"}}>
                {props.subscribtionPopupdata.attributes.name}<span style={{fontWeight:500,fontSize:"18px",marginLeft:"5px"}}> ({props.subscribtionPopupdata.attributes.role})</span>
                </TextHeading>
              </div>
              <div> 
                <TextHeading style={{fontWeight:800}}>${formatMoneyUSD(props.subscribtionPopupdata.attributes.price)} <span style={{fontWeight:500,fontSize:"18px"}}>({formatMoneyINR(props.subscribtionPopupdata.attributes.price_inr)})</span></TextHeading>
              </div>
            </TitleBox>
            <Box
            style={{
              display: "flex",
              
              flexWrap:"wrap",
              marginTop: "8px",
            }}
            >
              <div
              style={{
                marginRight:"30px",
              }}
              ><TextSubHeading>Members covered : <span style={{fontWeight:"bold"}}>{props.subscribtionPopupdata.attributes.member_covered}</span> </TextSubHeading></div>
              <div
              style={{
                marginRight:"30px",
              }}
              ><TextSubHeading>Period :  <span style={{fontWeight:"bold"}}>{props.subscribtionPopupdata.attributes.period} Months</span></TextSubHeading></div>
              <div
              style={{
                marginRight:"30px",
              }}
              ><TextSubHeading>Start Date :  <span style={{fontWeight:"bold"}}>{props.subscribtionPopupdata.attributes.start_date}</span></TextSubHeading></div>
              <div
              style={{
                marginRight:"30px",
              }}
              
              ><TextSubHeading>End Date : <span style={{fontWeight:"bold"}}>{props.subscribtionPopupdata.attributes.end_date}</span> </TextSubHeading></div>
             

            </Box>
            {props.subscribtionPopupdata.attributes.license_coverd &&<PMLicenceCoveredBox>
                <div><TextSubHeading>PM/Corporate Licence Covered :<span style={{fontWeight:"bold",marginLeft:"3px"}}>{props.subscribtionPopupdata.attributes.license_coverd}</span> </TextSubHeading></div>
    
                </PMLicenceCoveredBox>
              }
            <Box
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginTop: "8px",
            }}
            >
            
              <FadedText>
              
              <SeeMoreSubscribtion text={props.subscribtionPopupdata.attributes.description} limit={100} />
              </FadedText>
            </Box>
             {/* PM Corporate license Covered */}
             {/* Hide box when Service Provider or individual */}
             {
               props.subscribtionPopupdata.attributes.license_coverd !== null &&
            <Box>
               <AdditonalPMLicenseText>
                 <AdditonalPMLicenseTextInner>
                 <div style={{
                   marginRight:"24px"
                 }}><LightText style={{fontWeight:700}}>Add PM / Corporate employee licences</LightText></div>
                 <div><LightText style={{fontStyle:"italic"}}>({formatMoneyUSD(props.subscribtionPopupdata.attributes.additional_pm_corpo_license_usd)} / {formatMoneyINR(props.subscribtionPopupdata.attributes.additional_pm_corpo_license_inr)} per licence per month ) </LightText></div>
                  
                 </AdditonalPMLicenseTextInner>
                
               
   
               </AdditonalPMLicenseText>
               <AdditonalPMLicense>
                
                   <Box>
                     <TextSubHeading>Charges per licence</TextSubHeading>
                     <Box  
                    style={{
                      width:"144px",
                      height:"44px",
                      border:"1px solid #EBEBEB",
                      borderRadius:"7px",
                      backgroundColor:"#FBFBFB",
                       marginTop:"15px",
                       alignItems:"center",
                       display:"flex",
                       justifyContent:"center"
                    }}
                     > 
                      
                        <Typography>
                          <span style={{fontWeight:"bold"}}>
                          ${props.subscribtionPopupdata.attributes.additional_pm_corpo_license_usd}
                          </span>
                          <span >({formatMoneyINR(props.subscribtionPopupdata.attributes.additional_pm_corpo_license_inr)})</span>
                        </Typography>
                     </Box>
                     
                      </Box>
                      <Box style={{
                        alignItems:"center",
                        justifyContent:"center",
                        marginTop:"30px"
                      }}>*</Box>
                   <Box>
                     <TextSubHeading> No of months </TextSubHeading>
                     <Box  
                    style={{
                      width:"144px",
                      height:"44px",
                      border:"1px solid #EBEBEB",
                      borderRadius:"7px",
                      backgroundColor:"#FBFBFB",
                      marginTop:"15px",
                      alignItems:"center",
                      display:"flex",
                      justifyContent:"center"
                    }}
                     >
                        <Typography>{props.subscribtionPopupdata.attributes.period}</Typography>
   
                     </Box>
                    </Box>
                    <Box style={{
                        alignItems:"center",
                        justifyContent:"center",
                        marginTop:"30px"
                      }}>*</Box>
                   <Box>
                     <TextSubHeading>No of members </TextSubHeading>
                      <FInput
                        //  disabled={props.subscribtionPopupdata.attributes.role === 'Service Provider' || props.subscribtionPopupdata.attributes.role ==='individual'}
                        onChange={subscriptionReqProps}
                        name="addPm"
                      />
                     </Box>
                     <Box style={{
                        alignItems:"center",
                        justifyContent:"center",
                        marginTop:"30px"
                      }}>=</Box>
                   <Box
                   
                   >
                     <TextSubHeading>Variable Cost</TextSubHeading>
                     <Box  
                    style={{
                      width:"144px",
                      height:"44px",
                      border:"1px solid #EBEBEB",
                      borderRadius:"7px",
                      backgroundColor:"#FBFBFB",
                      marginTop:"15px",
                      alignItems:"center",
                      display:"flex",
                      justifyContent:"center"
                    }}
                     >
                     
                     {/* {
                      variablePmCorporateINRCost=Number(props.subscribtionPopupdata.attributes.additional_pm_corpo_license_inr)*Number(props.subscribtionPopupdata.attributes.period)*Number(requestpmCorporatetValue)
                     } */}
                        <Typography><span style={{fontWeight:"bold"}}>{formatMoneyUSD(Number(props.subscribtionPopupdata.attributes.additional_pm_corpo_license_usd)*Number(props.subscribtionPopupdata.attributes.period)*Number(requestpmCorporatetValue))}({formatMoneyINR(Number(props.subscribtionPopupdata.attributes.additional_pm_corpo_license_inr)*Number(props.subscribtionPopupdata.attributes.period)*Number(requestpmCorporatetValue))}</span>) </Typography>
                      
   
                     </Box>
                     </Box>
   
               
                 
   
               </AdditonalPMLicense>
   
               <AdditonalPersonalLicenseTextInner>
                 <div style={{
                   marginRight:"24px",
                   marginTop:"12px"
                 }}>
                   <LightText style={{fontWeight:600}}>Add individual user licences</LightText></div>
                 <div style={{ marginTop:"12px" }}><LightText style={{fontStyle:"italic"}}>(${props.subscribtionPopupdata.attributes.additional_individual_license_usd} / {formatMoneyINR(props.subscribtionPopupdata.attributes.additional_individual__license_inr)} per licence per month )</LightText></div>
   
                 
               </AdditonalPersonalLicenseTextInner>
               <AdditonalPersonalLicense>
                
                   <Box>
                     <TextSubHeading
                     
                     >Charges per licence</TextSubHeading>
                     <Box  
                    style={{
                      width:"144px",
                      height:"44px",
                      border:"1px solid #EBEBEB",
                      borderRadius:"7px",
                      backgroundColor:"#FBFBFB",
                       marginTop:"15px",
                       display:"flex",
                       justifyContent:"center",
                       alignItems:"center"
                    }}
                     >
                       <Typography>
                       <span style={{fontWeight:"bold"}}>${props.subscribtionPopupdata.attributes.additional_individual_license_usd}</span>
                        <span >({formatMoneyINR(props.subscribtionPopupdata.attributes.additional_individual__license_inr)})</span></Typography>
   
                      
   
                     </Box>
                     
                      </Box>
   
                      <Box style={{
                        alignItems:"center",
                        justifyContent:"center",
                        marginTop:"30px"
                      }}>*</Box>
                      
                   <Box>
                     <TextSubHeading> No of months </TextSubHeading>
                     <Box  
                    style={{
                      width:"144px",
                      height:"44px",
                      border:"1px solid #EBEBEB",
                      borderRadius:"7px",
                      backgroundColor:"#FBFBFB",
                      marginTop:"15px",
                      alignItems:"center",
                      display:"flex",
                      justifyContent:"center"
                    }}
                     >
                          <Typography>{props.subscribtionPopupdata.attributes.period}</Typography>
   
                     </Box>
                    </Box>
                    <Box style={{
                        alignItems:"center",
                        justifyContent:"center",
                        marginTop:"30px"
                      }}>*</Box>
                   <Box>
                     <TextSubHeading>No of members </TextSubHeading>
                      <FInput
                        //  disabled={props.subscribtionPopupdata.attributes.role === 'Service Provider' || props.subscribtionPopupdata.attributes.role ==='individual'}
                        onChange={handlesubscribtionindividualReqProps}
                        name="addInd"
                      />
                     </Box>
                     <Box style={{
                        alignItems:"center",
                        justifyContent:"center",
                        marginTop:"30px"
                      }}>=</Box>
                   <Box>
                     <TextSubHeading>Variable Cost</TextSubHeading>
                     <Box  
                    style={{
                      width:"144px",
                      height:"44px",
                      border:"1px solid #EBEBEB",
                      borderRadius:"7px",
                      backgroundColor:"#FBFBFB",
                      marginTop:"15px",
                      alignItems:"center",
                      display:"flex",
                      justifyContent:"center"                         
                    }}
                     >
                      
                      <Typography><span style={{fontWeight:"bold"}}>
                      <span>
                        {formatMoneyUSD(Number(props.subscribtionPopupdata.attributes.additional_individual_license_usd)*Number(props.subscribtionPopupdata.attributes.period)*Number(requestindividualValue))}
                      </span>
                      ({formatMoneyINR(Number(props.subscribtionPopupdata.attributes.additional_individual__license_inr)*Number(props.subscribtionPopupdata.attributes.period)*Number(requestindividualValue))}</span>) </Typography>
                     </Box>
                     </Box>
   
               
                 
   
               </AdditonalPersonalLicense>
          </Box>      
             }
       

            {/* Footer */}
            <FooterMainBox>
              <Box
              style={{
                display:"flex",
                flexDirection:"column",
                justifyContent:"center",
                marginTop: "9px"
              }}
              >
                <FooterText
                style={{
                  paddingBottom:"-50px",
                }}
                >Total Members :

                {
                 props.subscribtionPopupdata.role === "Service Provider" ? <span style={{marginLeft:"4px"}}>{props.subscribtionPopupdata.attributes.member_covered}</span>:
                 <span style={{marginLeft:"4px"}}>{Number(props.subscribtionPopupdata.attributes.license_coverd)+Number(props.subscribtionPopupdata.attributes.member_covered)+Number(requestpmCorporatetValue)+Number(requestindividualValue)}</span>
               }
                </FooterText>
                <LightTextTwo style={{fontStyle:"italic"}}>(Members covered + Additional members) </LightTextTwo>
              </Box>
              <Box
              style={{
                display:"flex",
                flexDirection:"column",
                justifyContent:"center"
              }}
              >
                <FooterText
                style={{
                  paddingBottom:"-50px",
                }}
                >Total Cost<span style={{marginLeft:"3px",marginRight:"3px"}}>:</span>
                {
                 props.subscribtionPopupdata.attributes.role === "Service Provider" ? <span style={{color: "#000000",
                 fontSize:"20px",
                 fontFamily:"helvetica"}}><span style={{fontWeight:800}}>${formatMoneyUSD(props.subscribtionPopupdata.attributes.price)}</span><span style={{marginLeft:"4px",marginBottom:"13px",fontWeight:400,marginRight:"10px"}}>({formatMoneyINR(Number(props.subscribtionPopupdata.attributes.price_inr))})</span></span>:
               <span style={{color: "#000000",
               fontSize:"20px",
               fontFamily:"helvetica"}}>
              <span style={{fontWeight:800,marginRight:"10px"}}>
                {formatMoneyUSD(Number(props.subscribtionPopupdata.attributes.price)+(Number(props.subscribtionPopupdata.attributes.additional_pm_corpo_license_usd)*Number(props.subscribtionPopupdata.attributes.period)*Number(requestpmCorporatetValue))+(Number(props.subscribtionPopupdata.attributes.additional_individual_license_usd)*Number(props.subscribtionPopupdata.attributes.period)*Number(requestindividualValue)))}
              </span>
              <span style={{fontWeight:400}}>
              ({formatMoneyINR(Number(props.subscribtionPopupdata.attributes.price_inr)+(Number(props.subscribtionPopupdata.attributes.additional_pm_corpo_license_inr)*Number(props.subscribtionPopupdata.attributes.period)*Number(requestpmCorporatetValue))+(Number(props.subscribtionPopupdata.attributes.additional_individual__license_inr)*Number(props.subscribtionPopupdata.attributes.period)*Number(requestindividualValue)))})
                </span>
                </span>
               }
 
                </FooterText>
                <LightTextTwo style={{fontStyle:"italic"}}>(Variable cost + Package cost)  </LightTextTwo>
              </Box>
              {/* <Box style={{paddingBottom: '20px'}}>
              <FooterInternalBox>
                <p style={{ display: "inherit"}}>
                <FooterText style={{marginBottom:"13px"}}>Total Cost<span style={{marginLeft:"3px",marginRight:"3px"}}>:</span></FooterText>
                {
                 props.subscribtionPopupdata.attributes.role === "Service Provider" ? <TextHeading style={{marginBottom:"10px"}}><span style={{fontWeight:800}}>${formatMoneyUSD(props.subscribtionPopupdata.attributes.price)}</span><span style={{marginLeft:"4px",marginBottom:"13px",fontWeight:400,marginRight:"10px"}}>({formatMoneyINR(Number(props.subscribtionPopupdata.attributes.price_inr))})</span></TextHeading>:
               
              <TextHeading style={{marginRight:"3px",marginBottom:"13px"}}>
              <span style={{fontWeight:800,marginRight:"10px"}}>
                {formatMoneyUSD(Number(props.subscribtionPopupdata.attributes.price)+(Number(props.subscribtionPopupdata.attributes.additional_pm_corpo_license_usd)*Number(props.subscribtionPopupdata.attributes.period)*Number(requestpmCorporatetValue))+(Number(props.subscribtionPopupdata.attributes.additional_individual_license_usd)*Number(props.subscribtionPopupdata.attributes.period)*Number(requestindividualValue)))}
              </span>
              <span style={{fontWeight:400}}>
              ({formatMoneyINR(Number(props.subscribtionPopupdata.attributes.price_inr)+(Number(props.subscribtionPopupdata.attributes.additional_pm_corpo_license_inr)*Number(props.subscribtionPopupdata.attributes.period)*Number(requestpmCorporatetValue))+(Number(props.subscribtionPopupdata.attributes.additional_individual__license_inr)*Number(props.subscribtionPopupdata.attributes.period)*Number(requestindividualValue)))})
                </span>
                
              </TextHeading>
               }
               </p>
                <LightText style={{fontStyle:"italic"}}>(Variable cost + Package cost)  </LightText>
              </FooterInternalBox>
              

              </Box> */}
              <Box
                style={{
                  marginLeft:"30px"
                }}
                >
                <PayButton
                  type="submit"
                 >
                   Pay Now
                 </PayButton>
                
               
                
          
                </Box>
            </FooterMainBox>
                  </>


          
          </Box>
          </Form>
          </Formik>
        </DialogContent>
      </Dialog>
       // Customizable Area End
    );
  }
//*********** styles ***************//
const FadedText = styled(Typography)({
  color: "#C9C9C9",
  fontSize:"14px",
  fontFamily:"helvetica"
})
const TextHeading = styled(Typography)({
  color: "#000000",
  fontSize:"20px",
  fontFamily:"helvetica"
})

const TextSubHeading = styled(Typography)({
  color: "#000000",
  fontSize:"14px"
})
const LightText = styled(Typography)({
  color: "#515C51",
  fontSize:"14px",
  fontFamily:"helvetica"
})
const LightTextTwo = styled(Typography)({
  color: "#515C51",
  fontSize:"14px",
  fontFamily:"helvetica",
  marginTop: "20px",
  '@media screen and (max-width: 620px)': {
    marginTop: 0,
    marginBottom: "15px"
  }
})
const TitleBox = styled(Box)({
  display:"flex",
  justifyContent: "space-between",
  marginTop: "18px",
  '@media screen and (max-width: 620px)': {
    display: "unset",
  }
})
const PMLicenceCoveredBox = styled(Box)({
  display: "flex",
  justifyContent: "flex-start",
  marginTop: "8px",
  '@media screen and (max-width: 754px)': {
    marginTop: 0,
  }
})
const AdditonalPMLicenseText = styled(Box)({
  display: "flex",
  justifyContent: "flex-start",
  marginTop: "8px",
  '@media screen and (max-width: 520px)': {
    display: "unset",
  }
})
const AdditonalPMLicenseTextInner = styled(Box)({
  display:"flex",
  alignItems: "center",
  '@media screen and (max-width: 520px)': {
    display: "unset",
  }
})
const AdditonalPMLicense = styled(Box)({
  display: "flex",
  justifyContent: "space-evenly",
  marginTop: "8px",
  border:"1.07178px solid #C0D3C0",
  borderRadius:"10px",
  height:"140px",
  alignItems:"center",
  overflow: "auto",
  '@media screen and (max-width: 680px)': {
    justifyContent: "unset",
  }
})
const AdditonalPersonalLicenseTextInner = styled(Box)({
  display:"flex",
  alignItems: "center",
  '@media screen and (max-width: 520px)': {
    display: "unset",
  }
})
const AdditonalPersonalLicense = styled(Box)({
  display: "flex",
  justifyContent: "space-evenly",
  marginTop: "8px",
  border:"1.07178px solid #C0D3C0",
  borderRadius:"10px",
  height:"144px",
  alignItems:"center",
  overflow: "auto",
  '@media screen and (max-width: 680px)': {
    justifyContent: "unset",
  }
})
const FooterMainBox = styled(Box)({
  display:"flex",
  justifyContent:"space-between",
  alignItems:"center",
  marginTop:"10px",
  '@media screen and (max-width: 620px)': {
    display: "unset",
    marginTop:"25px"
  }
})
const FooterInternalBox = styled(Box)({
  display:"flex",
  justifyContent:"space-between",
  alignItems:"center",
  flexDirection:"column",
  '@media screen and (max-width: 620px)': {
    alignItems: "flex-start",
  }
})
const PayButton = styled(Button)({
  color: "#FFFFFF",
  fontSize:"14px",
  backgroundColor:"00A100",
  borderRadius:"6px",
  width:"154px",
  '&:hover': {
    background: '#00A100',
    color:"#FFFFFF",
    borderRadius:"6px",
  },
})
const FooterText = styled(Typography)({
  color: "#000000",
  fontSize:"14px",
  fontWeight:"bold",
  fontFamily:"helvetica"
 
 
})
const TextFieldChoosePackage = styled(TextField)({
 textDecoration:"!important none",
 border:0,
 outline:0,
 marginTop:"15px",
 fontFamily:"helvetica",
 // height: '44px',
 "& > div > input": {
  "padding": "14px 14px",
  width: "80px"
 }
//  border:"!important none"
 
 
})
// const TextFieldChoosePackage = withStyles((theme) => ({
 
 
 
//   root: {
//         '& .MuiInputBase-input MuiInput-input': {
//           border: 'none',
//         },
        
            
//     },
//     outline:0,
//     marginTop:"10px",
// }))((props: any) => <TextField {...props} />)
const FInput = (props: any) => {
  const [field, meta, helpers] = useField(props.name)
  const onChange = (e: any) => {
    helpers.setTouched(true)
    helpers.setValue(e.target.value, true)
    if(props.onChange) props.onChange(e.target.value)
  }

  return <TextFieldChoosePackage
    variant="outlined"
    error={meta.touched&&!!meta.error} 
    value={field.value}
    onChange={onChange}
    helperText={meta.error} 
  />
}
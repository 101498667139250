import { runEngine } from "../../../framework/src/RunEngine";
// @ts-ignore
import * as config from "../../../framework/src/config";
import { Message } from "../../../framework/src/Message";
import { Block } from "../../../framework/src/Block";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, { getName } from '../../../framework/src/Messages/MessageEnum';
import { Platform, Alert } from "react-native";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area Start
// Customizable Area End

export default class ExportCSVBlock extends Block {
  // Customizable Area Start
  token?: string;
  // Customizable Area End
  constructor() {
    super();
    // Customizable Area Start
    const subscribedMessages = [getName(MessageEnum.ExportCSVMessage)]
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, 
      subscribedMessages
    );
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if(message.id === getName(MessageEnum.ExportCSVMessage)) {
      const link = message.getData(getName(MessageEnum.ExportCSVLinkData)) as string;
      this.downloadCsv(link)
    }
    // Customizable Area End
  }
  
  // Customizable Area Start
  downloadCsv = async (link: string) => {
    const authToken= await getStorageData('authToken');

    console.log({config})
    const res = await fetch(`${config.baseURL}${link}`, {
      headers: {token: authToken || ''}
    })
    const text = await res.text()
    this.saveStreamCSV("export.csv", text)
    const msg = new Message(getName(MessageEnum.ExportCSVComplete))
    runEngine.sendMessage(msg.id, msg)
  }

  saveStreamCSV = (filename: any, text: any) => {
    if(window.navigator.msSaveBlob) {
        // IE 10 and later, and Edge.
        var blobObject = new Blob([text], {type: 'text/csv'});
        window.navigator.msSaveBlob(blobObject, filename);
    } else {
        // Everthing else (except old IE).
        // Create a dummy anchor (with a download attribute) to click.
        var anchor = document.createElement('a');
        anchor.download = filename;
        if(window.URL.createObjectURL) {
            // Everything else new.
            var blobObject = new Blob([text], {type: 'text/csv'});
            anchor.href = window.URL.createObjectURL(blobObject);
        } else {
            // Fallback for older browsers (limited to 2MB on post-2010 Chrome).
            // Load up the data into the URI for "download."
            anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(text);
        }
        // Now, click it.
        if (document.createEvent) {
            var event = document.createEvent('MouseEvents');
            event.initEvent('click', true, true);
            anchor.dispatchEvent(event);
        }
        else {
            anchor.click();
        }
    }
}
  // Customizable Area End

}


import React from "react";
// Customizable Area Start
import {Box, Grid,Typography,} from '@material-ui/core';
import { makeStyles, Theme, createStyles,withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneIcon from '@material-ui/icons/Phone';
import './TermStyles.css';

import { Document, Page, pdfjs } from 'react-pdf'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


import Settings5Controller, {
  Props,
  configJSON
} from "./Settings5Controller";
// Customizable Area End
export default class Terms extends Settings5Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
      //@ts-ignore
      const {classes}= this.props;
      // Customizable Area End
    return (
      // Customizable Area Start
      <Grid container spacing={3}>
            <Grid item xs={12}>
                <Box style={{ backgroundColor:"white",
                        borderRadius:"10px",
                        paddingBottom:"20px"}}>
                       <div style={{display:"flex", 
                        flexDirection:"column",
                        margin:"30px 10px 0px 10px", 
                        padding:"50px 10px",
                        justifyContent:"center",
                        alignItems:"center",}}>
                            <Typography
                      variant="h5"
                      gutterBottom
                      style={{
                        fontWeight: "bold",
                        textAlign: "center",
                        marginBottom: "20px",
                      }}
                      component="div"
                    >
                    Terms and Condition
                    </Typography>
                           {/*<Typography  style={{marginBottom:"20px",fontSize:14}} component="div">
                            {this.state.contain ? this.state.contain:""}
                            </Typography>*/}
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                              <PDFViewer url={this.state.termsFile} />
                           </div>

                         </div>
                </Box>

            </Grid>

        </Grid>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export function PDFViewer(props: any) {
  const [numPages, setNumPages] = React.useState<number|null>(null);

  function onDocumentLoadSuccess({ numPages }: {numPages: number}) {
    setNumPages(numPages);
  }

  return (
    <Document
      file={props.url}
      onLoadSuccess={onDocumentLoadSuccess}
      
    >
      {!numPages && "Loading PDF"}
      {Array.from(
        new Array(numPages),
        (el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
          />
        ),
      )}
    </Document>
  );

}
// Customizable Area End

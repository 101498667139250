import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "../../JobListing2/src/assets";
import { Service, Category } from './types'
import * as yup from 'yup'
import { toast } from 'react-toastify'
import { sendAPIRequest } from '../../../components/src/utils'
import { getStorageData } from "../../../framework/src/Utilities";
// import { sendNewServiceEnquiredMessage, sendServiceUpdatedMessage } from './Messages'
yup.addMethod(yup.string, "phoneNumber", function(){
  const validationError = "Please enter a valid phone number"
  return this.test('test-name', validationError, function(value: string | undefined){
    if((value || "").slice(0, 3) === '+91') { // indian number
      const re =  /^(\+91)\d{10}$/
      if(re.test(value || "")) return true
      return this.createError({message: "Number starting with +91 must have 10 digits"})
    } else {
      const re = /^\+\d{5,15}$/
      return re.test(value || "")
    }
    return false
  })
})

yup.addMethod(yup.mixed, "maxFileSize", function(error: string, maxSize: number) {
  return this.test('test-name', error, function(file: File|undefined){
    if(!file) return true
    if(file.size>maxSize) return false
    return true
  })
})

yup.addMethod(yup.mixed, "fileType", function(fileTypes: string[], error: string){
  return this.test('test-name', error, function(file?: File){
    if(!file) return true
    return fileTypes.some(
      ft => (!!file.type.match(ft))
    )
  })
})

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  token: string;
  services: Service[];
  selectedService?: Service;
  selectedMyTab: number;
  modal: 'apply' | 'create' | null;
  loadingServices: boolean;
  filterQuery: string;
  randomServiceId?: string;
  getServiceDetailCallId?: string;
  service?: any;
  submittingForm?: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class EnquireServiceController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  apiServicesFilterCallId: string = ""
  apiCategoryListCallId: string = ""
  apiEnquireServiceCallId: string = ""
  apiApplyServiceCallId: string = ""
  apiFavServiceCallIds: string[] = []
  apiUnfavServiceCallIds: string[] = []
  generateServiceIdCallId: string = ""
  favCallIdToPostId:{[key: string]: string} = {}
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      token: '',
      services: [],
      selectedMyTab: 0,
      modal: null,
      loadingServices: false,
      filterQuery: '',
      randomServiceId: '',
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && responseJson.errors) {
        this.parseApiErrorResponse(responseJson.errors);
        this.parseApiCatchErrorResponse(responseJson.errors);
      } else if (responseJson) {
         if (apiRequestCallId === this.apiEnquireServiceCallId){
            toast.success("Enquiry Registered.")
            this.setState({ modal: null, submittingForm: true})
            this.props.navigation.goBack()
          } else if(apiRequestCallId === this.state.getServiceDetailCallId){
            if (responseJson.error) {
              toast.warn("Service Not Found")
              this.props.navigation.goBack()
              return 
            }
          this.setState({ service: responseJson.data.attributes })
        }
      }
    }
    // Customizable Area End

  }



  // Customizable Area Start
  phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  urlRegExp = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm
  
  

  enquireFormSchema = yup.object({
    name: yup.string().label('Name').required().max(255),
    email: yup.string().label('Email').required().email(),
    phone_number: yup.string().label('Phone Number')
      .required()
      .matches(/^(\+){0,1}[0-9]{10,15}$/, "Enter a valid phone number"),
    location: yup.string().label('Location').required(),
    description: yup.string().label('Description').required().max(1000),
  })


  async componentDidMount() {
    // @todo call account data block for token
    const id = this.props.navigation.getParam('id')
    const authToken = await getStorageData('authToken');

    this.setState({token: authToken}, () => {
      if (id) {
        const msg = sendAPIRequest(configJSON.serviceDetailEndpoint.replace(':id', id), {
          method: 'GET',
          headers: { 'token': authToken }
        })
        this.setState({
          getServiceDetailCallId: msg
        })
      } else {
        this.generateServiceIdCallId = sendAPIRequest(configJSON.generateServiceId, {
          method: 'GET',
          headers: { 'token': authToken }
        })
      }
    })
  }
  

  sendGetRequest = (endpoint: string, ) => {
    const headers = {
      "Content-Type": "application/json",
      "token": this.state.token,
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    const callId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return callId;
  }
  
  showEnquireModal = () => { this.setState({ modal: 'create' }) }

  hideModal = () => { this.props.navigation.goBack() }

  handleEnquireServiceSubmit = (data: any) => {
    let { service_id_1="", service_id_2="", ...reqData } = {  ...data }
    
    
    const body = new FormData()

    
    
    Object.entries(reqData).forEach(data => body.append(`service_enquiry[${data[0]}]`, data[1] as string))
    
    
    const headers = {
      "token": this.state.token,
    }
    let url = configJSON.serviceEnquireAPIEndpoint
    let method = 'POST'
    const serviceId = this.props.navigation.getParam('id')
    
    body.append('service_enquiry[marketplace_service_id]', serviceId)
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.apiEnquireServiceCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.serviceEnquireEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    this.setState({ submittingForm: true })
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  

  // Customizable Area End
  
}

import React from "react";

// Customizable Area Start
import {
  Divider,
  CardActions,
  Avatar,
  Button,
  OutlinedInput, InputAdornment
} from '@material-ui/core'
import { styled } from '@material-ui/styles'
import { Link } from 'react-router-dom';
import Picker from 'emoji-picker-react';

import {
  OyoAvatar,
  CloseIcon,
  LikedIcon,
  SendIcon,
  EmogiIcon,
} from './assets'

// Customizable Area End

import ProjectController, {
  Props,
} from "./ProjectController";

export default class ProjectBlock extends ProjectController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { navigation } = this.props
    let { postCommentData } = this.state
    // Customizable Area End
    return (
      // Customizable Area Start   
                  <DiscussSection>
                    <DiscussHeadWrapper>
                      <DiscussHeader>Discussion</DiscussHeader>
                      <BtnImg id="CloseBtn" src={CloseIcon} onClick={()=>this.props.onClose()} style={{ height: "25px", width: "25px" }} alt="close" />
                      </DiscussHeadWrapper>
                      <div style={{ marginBottom: '40px'}}>
                      <CommentUserAvtar>
                          <Link to={`/user/${this.context?.id}`}><Avatar style={{ width: 35.26, height: 31.87 }} src={this.props?.detail?.attributes?.image || OyoAvatar}/></Link>
                        </CommentUserAvtar>
                            <div style={{  width: 'calc(100% - 50px)', display: 'inline-block', boxSizing: 'border-box' }}>
                            
                              <OutlinedInput
                                id="outlined-adornment-weight"
                                value={ this.state.userComment }
                                onChange={this.handlePostCommentChange}
                                placeholder="Comment your views"
                                endAdornment={<InputAdornment position="end"><BtnImg src={EmogiIcon} onClick={this.openEmojiComment } style={{width: '18px', paddingLeft: '10px', paddingRight: '10px'}}/>
                                <Divider orientation="vertical" style={{ height: '55px', color: '#fefefe'}} />
                                { this.state.userComment.length > 0 ? 
                                  <BtnImg src={SendIcon} onClick={() => this.handlePostComment() } style={{width: '18px', paddingLeft: '25px', float: 'right', paddingRight: '2px', cursor:'pointer'}}/> 
                                  : <BtnImg src={SendIcon} style={{width: '18px', paddingLeft: '25px', float: 'right', paddingRight: '2px', opacity:0.5}}/>
                                }</InputAdornment>}
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{
                                  'aria-label': 'weight',
                                }}
                                style={{ width: '100%'}}
                              />
                            </div>
                            { this.state.openCommentEmoji ? 
                             <Picker onEmojiClick={this.onCommentEmojiClick} pickerStyle={{ width: '100%' }}/> : "" }
                              
                      </div>
                      { postCommentData?.map?.(p => <div style={{ marginBottom: '15px'}}>
                        <UserAvtar>
                          <Link to={`/user/${p?.account_id}`}><Avatar style={{ width: 35.26, height: 31.87 }} src={p?.profile_pic || OyoAvatar} onClick={() => navigation.navigate(`/user/${p?.account_id}`)} /></Link>
                        </UserAvtar>
                        <CommentDetail>
                            <AccountName>{ p?.account_name }</AccountName>
                            <CommentData>{ p?.content }</CommentData>
                        </CommentDetail>
                          <CardActions>
                            <FlexSpaceBetween style={{ padding: '0px 0px 5px 5px' }}>
                              <CardButtonWrapper>
                                <CardButton onClick={() => this.handleCommentLike(p)}>{ !p.is_like ? "Like": <div style={{ color: '#009c05'}}>Like</div>}</CardButton>
                                <div style={{ lineHeight: '2.5', color: '#d4d4d4' }}>|</div>
                                <CardButton onClick={() => this.handleSetReplyId(p.id)}>Reply</CardButton>
                              </CardButtonWrapper>
                              { p?.like_count ? 
                              <div style={{ display: 'flex', alignItems: 'center'}}>
                                <BtnImg src={LikedIcon} style={{width: '18px'}}/> 
                                <span style={{ paddingLeft: '3px', fontSize: '13px'}}>{ p?.like_count }</span> 
                              </div> : '' }
                            </FlexSpaceBetween>
                          </CardActions>
                          { this.state.reply_id === p.id ? 
                          <div style={{ marginTop: '15px', marginBottom: '40px', paddingLeft: '50px'}}>
                            <CommentUserAvtar>
                                <Link to={`/user/${this.context?.id}`}><Avatar style={{ width: 35.26, height: 31.87 }} src={this.context?.profile_pic || OyoAvatar}/></Link>
                              </CommentUserAvtar>
                              <div style={{  width: 'calc(100% - 50px)', display: 'inline-block', boxSizing: 'border-box' }}>
                            
                              <OutlinedInput
                                id="outlined-adornment-weight"
                                value={ this.state.userReply }
                                onChange={this.handlePostReplyChange}
                                placeholder="Comment your views"
                                endAdornment={<InputAdornment position="end"><BtnImg src={EmogiIcon} onClick={this.openEmojiCommentReply } style={{width: '18px', paddingLeft: '10px'}}/> 
                                { this.state.userReply.length > 0 ? 
                                  <BtnImg src={SendIcon} onClick={() => this.handleReplyComment() } style={{width: '18px', paddingLeft: '25px', float: 'right', paddingRight: '2px', cursor:'pointer'}}/> 
                                  : <BtnImg src={SendIcon} style={{width: '18px', paddingLeft: '25px', float: 'right', paddingRight: '2px', opacity:0.5}}/>
                                }</InputAdornment>}
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{
                                  'aria-label': 'weight',
                                }}
                                style={{ width: '100%'}}
                              />
                            </div>
                            { this.state.openCommentReplyEmoji ? 
                             <Picker onEmojiClick={this.onReplyEmojiClick} pickerStyle={{ width: '100%' }}/> : "" }
                            </div> : ''}
                            { p?.replies?.length > 0 && this.state.openReply != p.id ?
                            <div onClick={() => this.showReply(p.id) } style={{ width: 'calc(100% - 50px)', height: '8px', margin: '0px 50px 25px', borderBottom: '1px solid #cdcdcd', textAlign: 'center'}}>
                            <span style={{fontSize: '13px', backgroundColor: '#fff', padding: '0 30px', color: '#a9a9a9'}}>
                              View  { p?.replies?.length } replies
                            </span>
                          </div>
                          : '' }
                              { this.state.openReply == p.id  ? p?.replies?.map?.((r: any) => <div style={{ padding: '10px 0px 10px 50px' }}>
                                <UserAvtar>
                                  <Link to={`/user/${r?.account_id}`}><Avatar style={{ width: 35.26, height: 31.87 }} src={r?.profile_pic || OyoAvatar} onClick={() => navigation.navigate(`/user/${r?.account_id}`)} /></Link>
                                </UserAvtar>
                                <CommentDetail>
                                    <AccountName>{ r?.account_name }</AccountName>
                                    <CommentData>{ r?.content }</CommentData>
                                </CommentDetail>
                                </div>
                              ) : ''}
                      </div>) }

                      {
                        this.state.pagination?.next_page ? <div style={{ marginTop: '50px', marginBottom: '50px', width: '100%', textAlign: 'center', color: '#b9b9b9', fontSize: '15px', fontWeight: 'bold', cursor: 'pointer', backgroundColor: '#f0f0f0', padding: '15px 0px', borderRadius: '10px', borderColor: '#fefefe'}} onClick={this.loadMore}>Load more discussion</div> : '' 
                      }
                  </DiscussSection>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// PostController.contextType = UserContext

const FlexSpaceBetween = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignContent: 'center',
  padding: '20px 0px',
  width: '100%',
})

const CardButton = styled(Button)({
  border: 'none',
  color: '#aaaaaa',
  fontWeight: 600,
  textTransform: 'none'
})

const CardButtonWrapper = styled('div')({
  display: 'flex',
  gap: '5px',
  padding: '0 20px'
})

const DiscussSection = styled('div')({
  padding: '16px 30px'
})

const AccountName = styled('div')({
  color: '#009c05',
  'font-weight': 'bold',
  'font-size': '13px'
})

const CommentData = styled('div')({
  width: '100%',
  'word-break': 'break-word',
  marginTop: '5px'
})

const DiscussHeadWrapper = styled('div')({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center"
})

const BtnImg = styled('img')({
  width: 25
});

const DiscussHeader = styled('div')({
  fontSize: '18px',
  fontWeight: 'bold',
  padding: "15px 18px 25px"
})

const UserAvtar = styled('div')({
  display: 'inline-block',
  width: '50px',
  height: '40px',
  verticalAlign: 'top',
  paddingTop: '3px'
})

const CommentUserAvtar = styled('div')({
  display: 'inline-block',
  width: '50px',
  height: '40px',
  verticalAlign: 'top',
  paddingTop: '12px'
})

const CommentDetail = styled('div')({
  display: 'inline-block',
  width: 'calc(100% - 50px)',
  boxSizing: 'border-box',
  padding: '10px 20px 20px',
  minHeight: '80px',
  border: '1px solid #e8e4e4',
  borderRadius: '5px',
  lineHeight: '1.3',
  textAlign: 'justify'
})

// Customizable Area End

Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ProjectPortfolio";
exports.labelBodyText = "ProjectPortfolio Body";
exports.sendRequestEndpoint="/bx_block_projectportfolio/promote_contents";
exports.sendRequestCreateEndpoint="/bx_block_projectportfolio/project_seeking_funds";
exports.sendProjectEditEndpoint="/bx_block_projectportfolio/promote_contents";
exports.getMilestoneEndpoint="/bx_block_projectportfolio/project_milestones";
exports.getLeadersEndpoint="bx_block_projectportfolio/project_milestones/milestone_leaders";
exports.getModeratorEndpoint="bx_block_projectportfolio/project_milestones/moderators";
exports.getMilestoneUniqueId="/bx_block_projectportfolio/project_milestones/generate_milestone_id";
exports.getMilestoneCategoriesEndpoint="bx_block_projectportfolio/project_milestones/get_sub_categories";
exports.getUOMId="/bx_block_projectportfolio/unit_of_measurements";
exports.getProjectUniqueId="bx_block_projectportfolio/project_seeking_funds/generate_project_id";
exports.getSDGFocusEndpoint="bx_block_projectportfolio/sdg_factors";
exports.btnExampleTitle= "CLICK ME";
exports.getAllActivities= "bx_block_projectportfolio/milestone_activities";
exports.getMilestoneIds= "bx_block_projectportfolio/milestone_activities/get_milestone_ids"
exports.generateActivityId= "bx_block_projectportfolio/milestone_activities/generate_activity_id";
exports.createActivity= "bx_block_projectportfolio/milestone_activities"
exports.deleteActivity= "bx_block_projectportfolio/milestone_activities/:id"
exports.editActivity= "bx_block_projectportfolio/milestone_activities/:id"
exports.predecessorValues= "bx_block_projectportfolio/milestone_activities/predecessor_values"
exports.editEndPoint= "bx_block_projectportfolio/milestone_activities/:id"
exports.getTypeOfInvestmentsEndpoint="bx_block_projectportfolio/type_of_investments";
exports.ongoingProjectEndPoint = "bx_block_project_management/project_management/all?per_page=10&";
exports.getAnOngoingProjectDetailEndPoint = "bx_block_project_management/project_management/show?project_id=";
exports.projectModeratorsEndPoint = "bx_block_project_management/project_management/search_moderators";
exports.getGoalsEndPoint = "bx_block_project_management/project_management/get_goals";
exports.getSDGFocusEndpoint = "bx_block_project_management/project_management/get_sdg_factors?per_page=17";
exports.projectCollaboratorEndPoint = "bx_block_project_management/project_management/search_collaborators";
exports.projectSponsorsEndPoint = "bx_block_project_evaluation/project_evaluation/search_sponsors"
exports.projectInvestorEndPoint = "bx_block_project_management/project_management/search_investors";
exports.projectMembersEndPoint = "bx_block_project_management/project_management/search_moderators";
exports.getDimensionsEndPoint = "bx_block_project_management/project_management/get_dimensions?preference=";
exports.getTargetEndPoint = "bx_block_project_management/project_management/get_targets?goal="
exports.editOngoingProjectEndpoint = "bx_block_project_management/project_management/update";
exports.getProjectDataEndpoint = "bx_block_projectportfolio/project_milestones/get_project_data";
exports.getApproverEndPoint = "bx_block_project_evaluation/project_evaluation/search_all_approvers"
exports.createGhgEmissionFirstScope = 'bx_block_ghg_emissions/ghg_emission_first_scopes';
exports.updateGhgEmissionFirstScope = 'bx_block_ghg_emissions/ghg_emission_first_scopes/update_multiple';
exports.createGhgEmissionSecondScope = 'bx_block_ghg_emissions/ghg_emission_second_scopes';
exports.updateGhgEmissionSecondScope = 'bx_block_ghg_emissions/ghg_emission_second_scopes/update_multiple';
exports.createGhgEmissionThirdScope = 'bx_block_ghg_emissions/ghg_emission_third_scopes';
exports.updateGhgEmissionThirdScope = 'bx_block_ghg_emissions/ghg_emission_third_scopes/update_multiple';
exports.createLandEmissions = 'bx_block_emissions/land_emissions';
exports.updateLandEmissions = 'bx_block_emissions/land_emissions/update_multiple';
exports.createAirEmissions = 'bx_block_emissions/air_emissions';
exports.updateAirEmissions = 'bx_block_emissions/air_emissions/update_multiple';
exports.getSdgChart = 'bx_block_peoplemanagement2/corporate_profile_dashboards/sdg_last_five_years';
exports.getAllSdgProjects = 'bx_block_peoplemanagement2/corporate_profile_dashboards/yearly_sdg_projects';
exports.getGhgEmissionFirstScopes = 'bx_block_ghg_emissions/ghg_emission_first_scopes';
exports.getGhgEmissionSecondScopes = 'bx_block_ghg_emissions/ghg_emission_second_scopes';
exports.getGhgEmissionThirdScopes = 'bx_block_ghg_emissions/ghg_emission_third_scopes';
exports.getGhgEmissionTotalScopes = 'bx_block_ghg_emissions/ghg_emission_total_scopes';
exports.getAirEmissions = 'bx_block_emissions/air_emissions';
exports.getWasteEmissions = 'bx_block_emissions/waste_emissions';
exports.createWasteEmissions = 'bx_block_emissions/waste_emissions';
exports.updateWasteEmissions = 'bx_block_emissions/waste_emissions/update_multiple';
exports.getLandEmissions = 'bx_block_emissions/land_emissions';
exports.getWaterEmissions = 'bx_block_emissions/water_emissions';
exports.createWaterEmissions = 'bx_block_emissions/water_emissions';
exports.updateWaterEmissions = 'bx_block_emissions/water_emissions/update_multiple';
exports.createFundNumbers = 'bx_block_non_compliances/fund_numbers';
exports.updateFundNumbers = 'bx_block_non_compliances/fund_numbers/update_multiple';
exports.getFundNumbers = 'bx_block_non_compliances/fund_numbers';
exports.createMonetaryFund = 'bx_block_non_compliances/monetary_funds';
exports.updateMonetaryFund = 'bx_block_non_compliances/monetary_funds/update_multiple';
exports.getMonetaryFund = 'bx_block_non_compliances/monetary_funds';
exports.getAuditTrailEndPoint = 'bx_block_project_management/project_management/audit_trail?';
exports.attachmentMilestoneDeleteEndpoint="bx_block_projectportfolio/project_milestones/delete_milestone_attachment?";
exports.getUserLikeCountEndpoint = "bx_block_projectportfolio/promote_contents/get_likes_count?promote_content_id=:id";
exports.deleteProjectPlanEndPoint = "bx_block_project_management/project_management/delete/project_plan_documents";
exports.deleteMilestoneAttachmentEndpoint="/bx_block_projectportfolio/project_milestones/delete_milestone_attachment?id=:id";
exports.projectOwnerEndPoint = "bx_block_projectportfolio/project_milestones/moderators?per_page=100"
// Customizable Area End
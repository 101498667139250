import React from "react";

// Customizable Area Start
import {
  Button,
  Typography,
  Grid,
  Avatar,
  CardHeader,
  Card,
  IconButton,
  InputAdornment,
  Chip,
  Select,
  MenuItem,
  Box,
  TextField,
  FormControl,
  Popover,
  Modal,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import OverlayLoader from "../../../components/src/Loader.web";
import { DatePicker } from "@material-ui/pickers";
// import styles from "./CustomForm.module.css"
import DashboardLayout from "../../../components/src/DashboardLayout.web";
import { styled } from "@material-ui/styles";
import SearchSelect, {
  Checkbox,
} from "../../../components/src/SearchSelect.web";
import { Formik, Form, useField } from "formik";
import { UserProfile, PdfIcon2, orgMission, orgVision, CEOmsg } from "./assets";
// @ts-ignore
import {
  InputRow,
  MCountedTextArea,
  MDatePicker,
  MInput,
} from "../../../components/src/FormModal.web";
import { FormType } from "./enums";

import CorporateProfileFormController, {
  Props,
} from "./CorporateProfileForm1Controller.web";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import LanguageIcon from "@material-ui/icons/Language";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import PhoneOutlinedIcon from "@material-ui/icons/PhoneOutlined";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import PeopleOutlineOutlinedIcon from "@material-ui/icons/PeopleOutlineOutlined";
import "./CustomForm.module.css";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import CloseIcon from "@material-ui/icons/Close";
import { iconAdd } from "../../JobListing2/src/assets";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import moment from "moment";
import UserContext from "../../../components/src/UserProvider";
import MapContainer from "./GoogleMaps.web";

// Customizable Area End

export default class CorporateProfileForm1 extends CorporateProfileFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    const path =
      this.context.user_type == "licensed_user"
        ? "Corporatepermission"
        : "ESGJourney";

    const { navigation } = this.props;
    const dollarFieldProps = {
      placeholder: "Enter here",
      InputProps: {
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      },
    };
    const percentFieldProps = {
      placeholder: "Enter here",
      InputProps: {
        startAdornment: <InputAdornment position="start">%</InputAdornment>,
      },
    };
    const initialData = { ...this.initialValues };
    // Customizable Area End
    return (
      // Customizable Area Start

      <DashboardLayout navigation={navigation}>
        <OverlayLoader loading={this.state.loading} />
        <Formik
          enableReinitialize
          initialValues={initialData}
          onSubmit={(e) => {
            this.onSubmitForm(e);
          }}
          // onSubmit={(e)=>{console.log("values",e);
          // }}
          validationSchema={
            this.state.showCorporateform1
              ? this.validationSchema1
              : this.validationSchema2
          }
        >
          {({
            values,
            handleChange,
            handleSubmit,
            errors,
            touched,
            setFieldValue,
            setTouched,
          }) => (
            <Form translate={undefined}>
              {this.state.showCorporateform1 ? (
                <div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span
                      id="navigation-back"
                      onClick={() => navigation.navigate(path)}
                      style={{
                        margin: "40px 0px 15px 12px",
                        cursor: "pointer",
                      }}
                    >
                      ESG Journey
                    </span>
                    <div
                      id="navigation-goback"
                      onClick={() => navigation.navigate(path)}
                      style={{ margin: "40px 0px 15px 12px" }}
                    >
                      <ArrowBackIosIcon />
                    </div>
                    <CoinsHead>CORPORATE PROFILE</CoinsHead>
                  </div>
                  <RoundedCard
                    style={{
                      backgroundImage: `url(${this.state.corporateCoverPic})`,
                      backgroundSize: "cover",
                    }}
                  >
                    <CardHeader
                      id="popOver-avtar"
                      style={{ padding: "25px 20px 15px 20px" }}
                      avatar={
                        <div style={{ position: "relative" }}>
                          <span style={{ display: "flex" }}>
                            <Avatar
                              style={{
                                width: "70px",
                                height: "70px",
                              }}
                            >
                              <img
                                style={{}}
                                height="70px"
                                width="70px"
                                src={this.state.corporateProfilePic}
                                alt="profile pic"
                              />
                            </Avatar>

                            <Avatar
                              id = "avtarOnCLick"
                              onClick={(e) => this.handlePopover(e)}
                              style={{
                                cursor: "pointer",
                                position: "absolute",
                                color: "white",
                                top: "60%",
                                left: "60%",
                                backgroundColor: "#1ea60a",
                                width: "25px",
                                height: "25px",
                                padding: "3px",
                                border: "1px solid white",
                              }}
                            >
                              <BorderColorIcon fontSize="small" />
                            </Avatar>
                            <Popover
                              id={
                                Boolean(this.state.popOverAnchorEL)
                                  ? "simple-popover"
                                  : undefined
                              }
                              open={Boolean(this.state.popOverAnchorEL)}
                              anchorEl={this.state.popOverAnchorEL}
                              onClose={(
                                event: React.MouseEvent<HTMLButtonElement>
                              ) =>
                                this.setState({
                                  ...this.state,
                                  popOverAnchorEL: null,
                                })
                              }
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                              style={{ marginTop: "10px" }}
                            >
                              <PopupWrapper>
                                <PopupText>
                                  <label
                                    style={{ cursor: "pointer" }}
                                    htmlFor="profile_pic"
                                  >
                                    Upload Profile Picture
                                  </label>
                                </PopupText>
                                <input
                                  type="file"
                                  accept=".png, .jpeg"
                                  id="profile_pic"
                                  style={{ display: "none" }}
                                  // onChange={(event:any)=>{ console.log(event.target.files[0].name)
                                  //     this.setState({image:event.target.files[0]})
                                  //     this.fileUploaded(event);
                                  // }}
                                  onChange={(e) => {
                                    this.handleProfilePic(e);
                                  }}
                                  // onChange={handleChange}
                                />
                                <PopupText>
                                  <label htmlFor="cover_pic">
                                    Upload Cover Picture
                                  </label>
                                </PopupText>
                                <input
                                  type="file"
                                  accept=".png, .jpeg"
                                  id="cover_pic"
                                  style={{ display: "none" }}
                                  // onChange={(event:any)=>{ console.log(event.target.files[0].name)
                                  //     this.setState({image:event.target.files[0]})
                                  //     this.fileUploaded(event);
                                  // }}
                                  onChange={(e) => {
                                    this.handleCoverPic(e);
                                  }}
                                  // onChange={handleChange}
                                />
                              </PopupWrapper>
                            </Popover>
                          </span>
                        </div>
                      }
                    />
                  </RoundedCard>
                  <RoundedCard>
                    <RightFormWrapper translate="no">
                      <div style={{ width: "100%" }}>
                        <FlexSpaceBetween style={{ gap: 6 }}>
                          <div style={{ flex: 1 }}>
                            <Box>
                              <Box style={{ marginBottom: "8px" }}>
                                <label htmlFor="">Company Name*</label>
                              </Box>

                              <TextField
                                placeholder="Enter Here"
                                size="small"
                                fullWidth
                                variant="outlined"
                                onChange={handleChange}
                                id="company_name"
                                name="company_name"
                                value={values.company_name}
                              />
                              <span style={{ color: "red", fontSize: "10px" }}>
                                {errors.company_name}
                              </span>
                            </Box>
                            {/* <InputRow
                                                label="Company Name*"
                                                rowClass='formRow'
                                                labelClass='formLabel'
                                                input={<><MInput
                                                    disabled={this.state.isViewRequest}
                                                    name="company_name"
                                                    placeholder=""
                                                /></>}
                                            /> */}
                          </div>
                          <div style={{ flex: 1 }}>
                            {/* <InputRow
                                                label="Tagline / Slogan"
                                                rowClass='formRow'
                                                labelClass='formLabel'
                                                input={<><MInput
                                                    disabled={this.state.isViewRequest}//
                                                    name="title" placeholder="" /></>}
                                            /> */}
                            <Box>
                              <Box style={{ marginBottom: "8px" }}>
                                <label htmlFor="">Tagline / Slogan</label>
                              </Box>
                              <TextField
                                placeholder="Enter Here"
                                size="small"
                                fullWidth
                                variant="outlined"
                                onChange={handleChange}
                                id="title"
                                name="title"
                                value={values.title}
                              />
                              <span style={{ color: "red", fontSize: "10px" }}>
                                {errors.title}
                              </span>
                            </Box>
                          </div>
                        </FlexSpaceBetween>
                        <FlexSpaceBetween style={{ gap: 6 }}>
                          <div style={{ flex: 1 }}>
                            {/* <InputRow
                                                label="Company Website*"
                                                rowClass='formRow'
                                                labelClass='formLabel'
                                                input={<><MInput
                                                    disabled={this.state.isViewRequest}
                                                    name="company_website"
                                                    placeholder=""
                                                    InputProps={{
                                                        endAdornment: (<>
                                                            {<EmailIcon color="inherit" />}
                                                        </>),
                                                    }} /></>}
                                            /> */}
                            <Box>
                              <Box style={{ marginBottom: "8px" }}>
                                <label htmlFor="">Company Website*</label>
                              </Box>
                              <TextField
                                placeholder="Starts with https:// or http://"
                                size="small"
                                fullWidth
                                variant="outlined"
                                onChange={handleChange}
                                id="company_website"
                                name="company_website"
                                value={values.company_website}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <LanguageIcon
                                        style={{ color: "#009c05" }}
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              <span style={{ color: "red", fontSize: "10px" }}>
                                {errors.company_website}
                              </span>
                            </Box>
                          </div>
                          <div style={{ flex: 1 }}>
                            {/* <InputRow
                                                label="Company Email*"
                                                rowClass='formRow'
                                                labelClass='formLabel'
                                                input={<><MInput
                                                    disabled={this.state.isViewRequest}
                                                    name="company_email"
                                                    placeholder=""
                                                    InputProps={{
                                                        endAdornment: (<>
                                                            {<EmailIcon color="inherit" />}
                                                        </>),
                                                    }} /></>}
                                                /> */}
                            <Box>
                              <Box style={{ marginBottom: "8px" }}>
                                <label htmlFor="">Company Email*</label>
                              </Box>
                              <TextField
                                placeholder="Enter Here"
                                size="small"
                                fullWidth
                                variant="outlined"
                                onChange={handleChange}
                                id="company_email"
                                name="company_email"
                                value={values.company_email}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <EmailOutlinedIcon
                                        style={{ color: "#009c05" }}
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              <span style={{ color: "red", fontSize: "10px" }}>
                                {errors.company_email}
                              </span>
                            </Box>
                          </div>
                        </FlexSpaceBetween>
                        <FlexSpaceBetween style={{ gap: 6 }}>
                          <div style={{ flex: 1 }}>
                            {/* <InputRow
                                                label="Business Phone No."
                                                rowClass='formRow'
                                                labelClass='formLabel'
                                                input={<><MInput
                                                    disabled={this.state.isViewRequest}
                                                    name="business_phone"
                                                    placeholder=""
                                                    InputProps={{
                                                        endAdornment: (<>
                                                            {<EmailIcon color="inherit" />}
                                                        </>),
                                                    }} /></>}
                                            /> */}
                            <Box>
                              <Box style={{ marginBottom: "8px" }}>
                                <label htmlFor="">Business Phone No.</label>
                              </Box>
                              <TextField
                                placeholder="Enter Here"
                                size="small"
                                fullWidth
                                variant="outlined"
                                onChange={handleChange}
                                id="business_phone"
                                name="business_phone"
                                value={values.business_phone}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <PhoneOutlinedIcon
                                        style={{ color: "#009c05" }}
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              <span style={{ color: "red", fontSize: "10px" }}>
                                {errors.business_phone}
                              </span>
                            </Box>
                          </div>
                          <div style={{ flex: 1 }}>
                            {/* <InputRow
                                                label="Company Headquaters*"
                                                rowClass='formRow'
                                                labelClass='formLabel'
                                                input={<><MInput
                                                    disabled={this.state.isViewRequest}
                                                    name="company_headquaters" placeholder="" /></>}
                                            /> */}
                            <Box>
                              <Box style={{ marginBottom: "8px" }}>
                                <label htmlFor="">Company Headquaters*</label>
                              </Box>
                              <TextField
                                placeholder="Enter Here"
                                size="small"
                                fullWidth
                                variant="outlined"
                                onChange={handleChange}
                                id="company_headquaters"
                                name="company_headquaters"
                                value={values.company_headquaters}
                              />
                              <span style={{ color: "red", fontSize: "10px" }}>
                                {errors.company_headquaters}
                              </span>
                            </Box>
                          </div>
                        </FlexSpaceBetween>
                        <FlexSpaceBetween style={{ gap: 6 }}>
                          <div style={{ flex: 1 }}>
                            {/* <InputRow
                          label="Company Region"
                          rowClass='formRow'
                          labelClass='formLabel'
                          input={
                            <SearchSelect
                              multiple={false}
                              selectProps={{ style: { minHeight: '56px' }, disabled: this.state.isViewRequest || this.isFieldDisabled('sender_id') }}
                              hasMore={!!this.state?.userPagination?.next_page}
                              nextPage={this.paginateUsersOptions}
                              loading={((!!this.state?.userSearchCallId) || (!!this.state?.userPaginateCallId)) && !!this.state?.requestOwnerLoading}
                              onSearch={this.handleRequestOwnerSearch}
                              allItems={(this.state?.users || [])}
                              items={(this.state?.users || [])}
                              name={"company_region"}
                              label={"Select Region"}
                              placeholder="Select Region"
                              SelectItem={{}}
                              SelectedItem={SelectedItem}
                              getOptionValue={(user: any) => `${user.first_name} ${user.last_name}`}
                            />
                          }
                        /> */}
                            <Box style={{ marginBottom: "8px" }}>
                              <label htmlFor="">Company Region</label>
                            </Box>
                            <FormControl fullWidth size="small">
                              <Select
                                fullWidth
                                variant="outlined"
                                value={values.company_region}
                                onChange={handleChange}
                                name="company_region"
                                id="company_region"
                              >
                                {this.companyRegion &&
                                  this.companyRegion.map((item, index) => {
                                    return (
                                      <MenuItem value={item}>{item}</MenuItem>
                                    );
                                  })}
                              </Select>
                              <span style={{ color: "red", fontSize: "10px" }}>
                                {errors.company_region}
                              </span>
                            </FormControl>
                          </div>
                          <div style={{ flex: 1 }}>
                            {/* <InputRow
                                                label="Business City"
                                                rowClass='formRow'
                                                labelClass='formLabel'
                                                input={<><MInput
                                                    disabled={this.state.isViewRequest}
                                                    name="business_city"
                                                    placeholder=""
                                                    InputProps={{
                                                        endAdornment: (<>
                                                            {<EmailIcon color="inherit" />}
                                                        </>),
                                                    }} /></>} */}
                            {/* /> */}
                            <Box>
                              {/* //@ts-ignore */}
                              <Box style={{ marginBottom: "8px" }}>
                                <label htmlFor="">Business City</label>
                              </Box>
                              <TextField
                                placeholder="Enter Here"
                                size="small"
                                fullWidth
                                variant="outlined"
                                onChange={handleChange}
                                id="business_city"
                                name="business_city"
                                value={values.business_city}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <LocationOnOutlinedIcon
                                        style={{
                                          color: "#009c05",
                                          cursor: "pointer",
                                        }}
                                        onClick={this.handleOpenMap}
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              <span style={{ color: "red", fontSize: "10px" }}>
                                {errors.business_city}
                              </span>
                            </Box>
                          </div>
                        </FlexSpaceBetween>
                        <FlexSpaceBetween style={{ gap: 6 }}>
                          <div style={{ flex: 1 }}>
                            {/* <InputRow
                                                label="Business State"
                                                rowClass='formRow'
                                                labelClass='formLabel'
                                                input={<><MInput disabled={this.state.isViewRequest} name="business_state" placeholder="" /></>}
                                            /> */}
                            <Box>
                              <Box style={{ marginBottom: "8px" }}>
                                <label htmlFor="">Business State</label>
                              </Box>
                              <TextField
                                placeholder="Enter Here"
                                size="small"
                                fullWidth
                                variant="outlined"
                                onChange={handleChange}
                                id="business_state"
                                name="business_state"
                                value={values.business_state}
                              />
                              <span style={{ color: "red", fontSize: "10px" }}>
                                {errors.business_state}
                              </span>
                            </Box>
                          </div>
                          <div style={{ flex: 1 }}>
                            {/* <InputRow
                          label="Business Country*"
                          rowClass='formRow'
                          labelClass='formLabel'
                          input={
                            <AssignToSelect
                              selectProps={{ style: { maxHeight: '45px' }, disabled: this.state.isViewRequest }}
                              hasMore={!!this.state?.userPagination?.next_page}
                              nextPage={this.paginateUsersOptions}
                              loading={(!!this.state?.userSearchCallId)}
                              onSearch={this.handleUserSearch}
                              allItems={(this.state?.defaultUsers)}
                              items={(this.state?.defaultUsers)}
                              name="business_country"
                              label={"Select Country"}
                              placeholder="Select Country"
                              SelectItem={SelectItemUser}
                              SelectedItem={SelectedItem}
                              getOptionValue={(user: any) => `${user.first_name} ${user.last_name}`}
                            />
                          }
                        /> */}
                            <Box>
                              <Box style={{ marginBottom: "8px" }}>
                                <label htmlFor="">Business Country*</label>
                              </Box>
                              <TextField
                                placeholder="Enter Here"
                                size="small"
                                fullWidth
                                variant="outlined"
                                onChange={handleChange}
                                id="business_country"
                                name="business_country"
                                value={values.business_country}
                              />
                              <span style={{ color: "red", fontSize: "10px" }}>
                                {errors.business_country}
                              </span>
                            </Box>
                          </div>
                        </FlexSpaceBetween>
                        <FlexSpaceBetween style={{ gap: 6 }}>
                          <div style={{ flex: 1 }}>
                            {/* <InputRow
                          label="Last Fiscal / Financial Year*"
                          rowClass='formRow'
                          labelClass='formLabel'
                          input={<><MInput
                            disabled={this.state.isViewRequest}
                            name="financial_year"
                            placeholder=""
                            InputProps={{
                              endAdornment: (<>
                                {<EmailIcon color="inherit" />}
                              </>),
                            }} /></>}
                        /> */}
                            {/* <Box>
                              <Box style={{ marginBottom: "8px" }}>
                                <label htmlFor="">
                                  Last Fiscal / Financial Year*
                                </label>
                              </Box>
                              <TextField
                                placeholder="Enter Here"
                                size="small"
                                fullWidth
                                variant="outlined"
                                type="date"
                                onChange={handleChange}
                                id="financial_year"
                                name="financial_year"
                              />
                              <span style={{ color: "red", fontSize: "10px" }}>
                                {errors.financial_year}
                              </span>
                            </Box> */}
                            <Box style={{ marginBottom: "8px" }}>
                              <label htmlFor="">
                                Last Fiscal / Financial Year*
                              </label>
                            </Box>
                            <Box
                              style={{ display: "flex", marginRight: "2px" }}
                            >
                              <DatePicker
                                style={{ flex: "1" }}
                                margin="none"
                                size="small"
                                inputVariant="outlined"
                                id="start_date"
                                name="start_date"
                                placeholder="Start Date"
                                format="dd/MM/yyyy"
                                value={values.start_date}
                                onChange={(e) => {
                                  setFieldValue("start_date", e);
                                }}
                              />
                              <DatePicker
                                style={{ flex: "1", marginLeft: "2px" }}
                                size="small"
                                margin="none"
                                inputVariant="outlined"
                                id="end_date"
                                name="end_date"
                                placeholder="End Date"
                                format="dd/MM/yyyy"
                                value={values.end_date}
                                onChange={(e) => {
                                  setFieldValue("end_date", e);
                                }}
                              />
                            </Box>
                          </div>
                          <div style={{ flex: 1 }}>
                            {/* <InputRow
                          label="Sector*"
                          rowClass='formRow'
                          labelClass='formLabel'
                          input={
                            <AssignToSelect
                              selectProps={{ style: { maxHeight: '45px' }, disabled: this.state.isViewRequest }}
                              hasMore={!!this.state?.userPagination?.next_page}
                              nextPage={this.paginateUsersOptions}
                              loading={(!!this.state?.userSearchCallId)}
                              onSearch={this.handleUserSearch}
                              allItems={(this.state?.defaultUsers)}
                              items={(this.state?.defaultUsers)}
                              name="sector"
                              label={"Select Sector"}
                              placeholder="Select Sector"
                              SelectItem={SelectItemUser}
                              SelectedItem={SelectedItem}
                              getOptionValue={(user: any) => `${user.first_name} ${user.last_name}`}
                            />
                          }
                        /> */}
                            {this.state.otherSector ? (
                              <>
                                {" "}
                                <Box style={{ marginBottom: "8px" }}>
                                  <label htmlFor="">Sector*</label>
                                </Box>
                                <FormControl fullWidth size="small">
                                  <Select
                                    variant="outlined"
                                    onChange={handleChange}
                                    name="sector"
                                    id="sector"
                                    value={values.sector}
                                    fullWidth
                                  >
                                    {this.companySector &&
                                      this.companySector.map((item, index) => {
                                        return (
                                          <MenuItem value={item}>
                                            {item}
                                          </MenuItem>
                                        );
                                      })}
                                    <MenuItem
                                      id="othersec"
                                      onClick={() => {
                                        this.setState({ otherSector: false });
                                      }}
                                    >
                                      Others - Please Specify
                                    </MenuItem>
                                  </Select>
                                  <span
                                    style={{ color: "red", fontSize: "10px" }}
                                  >
                                    {errors.sector}
                                  </span>
                                </FormControl>
                              </>
                            ) : (
                              <Box>
                                <Box style={{ marginBottom: "8px" }}>
                                  <label htmlFor="">
                                    Sector - Please Specify (others)*
                                  </label>
                                </Box>
                                <TextField
                                  placeholder="Enter Here"
                                  size="small"
                                  fullWidth
                                  variant="outlined"
                                  onChange={handleChange}
                                  id="sector"
                                  name="sector"
                                  value={values.sector}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <ArrowDropDownIcon
                                          onClick={() => {
                                            this.setState({
                                              otherSector: true,
                                            });
                                          }}
                                        />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                                <span
                                  style={{ color: "red", fontSize: "10px" }}
                                >
                                  {errors.sector}
                                </span>
                              </Box>
                            )}
                          </div>
                        </FlexSpaceBetween>
                        <FlexSpaceBetween style={{ gap: 6 }}>
                          <div style={{ flex: 1 }}>
                            {/* <InputRow
                          label="Industry*"
                          rowClass='formRow'
                          labelClass='formLabel'
                          input={
                            <AssignToSelect
                              selectProps={{ style: { maxHeight: '45px' }, disabled: this.state.isViewRequest }}
                              hasMore={!!this.state?.userPagination?.next_page}
                              nextPage={this.paginateUsersOptions}
                              loading={(!!this.state?.userSearchCallId)}
                              onSearch={this.handleUserSearch}
                              allItems={(this.state?.defaultUsers)}
                              items={(this.state?.defaultUsers)}
                              name="industry"
                              label={"Select Industry"}
                              placeholder="Select Industry"
                              SelectItem={SelectItemUser}
                              SelectedItem={SelectedItem}
                              getOptionValue={(user: any) => `${user.first_name} ${user.last_name}`}
                            />
                          }
                        /> */}
                            {this.state.otherIndustries ? (
                              <>
                                <Box style={{ marginBottom: "8px" }}>
                                  <label htmlFor="">Industry*</label>
                                </Box>
                                <FormControl fullWidth size="small">
                                  <Select
                                    variant="outlined"
                                    onChange={handleChange}
                                    name="industry"
                                    id="industry"
                                    value={values.industry}
                                  >
                                    {this.industrySelect &&
                                      this.industrySelect.map((item, index) => {
                                        return (
                                          <MenuItem value={item}>
                                            {item}
                                          </MenuItem>
                                        );
                                      })}
                                    <MenuItem
                                      id="otherindus"
                                      onClick={() => {
                                        this.setState({
                                          otherIndustries: false,
                                        });
                                      }}
                                    >
                                      Others - Please Specify
                                    </MenuItem>
                                  </Select>
                                  <span
                                    style={{ color: "red", fontSize: "10px" }}
                                  >
                                    {errors.industry}
                                  </span>
                                </FormControl>{" "}
                              </>
                            ) : (
                              <>
                                <Box>
                                  <Box style={{ marginBottom: "8px" }}>
                                    <label htmlFor="">
                                      Industry - Please Specify (others)*
                                    </label>
                                  </Box>
                                  <TextField
                                    placeholder="Enter Here"
                                    size="small"
                                    fullWidth
                                    variant="outlined"
                                    onChange={handleChange}
                                    id="industry"
                                    name="industry"
                                    value={values.industry}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <ArrowDropDownIcon
                                            onClick={() => {
                                              this.setState({
                                                otherIndustries: true,
                                              });
                                            }}
                                          />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                  <span
                                    style={{ color: "red", fontSize: "10px" }}
                                  >
                                    {errors.industry}
                                  </span>
                                </Box>{" "}
                              </>
                            )}
                          </div>
                          <div style={{ flex: 1 }}>
                            {/* <InputRow
                          label="Sub-Industry"
                          rowClass='formRow'
                          labelClass='formLabel'
                          input={
                            <AssignToSelect
                              selectProps={{ style: { maxHeight: '45px' }, disabled: this.state.isViewRequest }}
                              hasMore={!!this.state?.userPagination?.next_page}
                              nextPage={this.paginateUsersOptions}
                              loading={(!!this.state?.userSearchCallId)}
                              onSearch={this.handleUserSearch}
                              allItems={(this.state?.defaultUsers)}
                              items={(this.state?.defaultUsers)}
                              name="sub_industry"
                              label={"Select Sub-Industry"}
                              placeholder="Select Sub-Industry"
                              SelectItem={SelectItemUser}
                              SelectedItem={SelectedItem}
                              getOptionValue={(user: any) => `${user.first_name} ${user.last_name}`}
                            />
                          }
                        /> */}
                            {this.state.otherSubIndustries ? (
                              <>
                                <Box style={{ marginBottom: "8px" }}>
                                  <label htmlFor="">Sub-Industry</label>
                                </Box>
                                <FormControl fullWidth size="small">
                                  <Select
                                    variant="outlined"
                                    onChange={handleChange}
                                    name="sub_industry"
                                    id="sub_industry"
                                    value={values.sub_industry}
                                  >
                                    {this.industrySub.map((item, index) => {
                                      return (
                                        <MenuItem value={item}>{item}</MenuItem>
                                      );
                                    })}
                                    <MenuItem
                                      id="otherSUBIN"
                                      onClick={() => {
                                        this.setState({
                                          otherSubIndustries: false,
                                        });
                                      }}
                                    >
                                      Others - Please Specify
                                    </MenuItem>
                                  </Select>
                                  <span
                                    style={{ color: "red", fontSize: "10px" }}
                                  >
                                    {errors.sub_industry}
                                  </span>
                                </FormControl>
                              </>
                            ) : (
                              <>
                                <Box>
                                  <Box style={{ marginBottom: "8px" }}>
                                    <label htmlFor="">
                                      Sub Industry - Please Specify (others)*
                                    </label>
                                  </Box>
                                  <TextField
                                    placeholder="Enter Here"
                                    size="small"
                                    fullWidth
                                    variant="outlined"
                                    onChange={handleChange}
                                    id="sub_industry"
                                    name="sub_industry"
                                    value={values.sub_industry}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <ArrowDropDownIcon
                                            onClick={() => {
                                              this.setState({
                                                otherSubIndustries: true,
                                              });
                                            }}
                                          />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                  <span
                                    style={{ color: "red", fontSize: "10px" }}
                                  >
                                    {errors.sub_industry}
                                  </span>
                                </Box>{" "}
                              </>
                            )}
                          </div>
                        </FlexSpaceBetween>
                        <FlexSpaceBetween style={{ gap: 6 }}>
                          <div style={{ flex: 1 }}>
                            {/* <InputRow
                                                label="Last three Years Average AUM or Turnover (In Milions)"
                                                rowClass='formRow'
                                                labelClass='formLabel'
                                                input={<><MInput disabled={this.state.isViewRequest} name="turnover" placeholder="Turnover" /></>}
                                            /> */}
                            <Box>
                              <Box style={{ marginBottom: "8px" }}>
                                <label htmlFor="">
                                  Last three year's average AUM or Turnover(In
                                  millions)
                                </label>
                              </Box>
                              <TextField
                                placeholder="Enter Here"
                                size="small"
                                fullWidth
                                variant="outlined"
                                onChange={handleChange}
                                id="turnover"
                                name="turnover"
                                value={values.turnover}
                              />
                              <span style={{ color: "red", fontSize: "10px" }}>
                                {errors.turnover}
                              </span>
                            </Box>
                          </div>
                          <div style={{ flex: 1 }}>
                            {/* <InputRow
                                                label="Full-Time Employees*"
                                                rowClass='formRow'
                                                labelClass='formLabel'
                                                input={<><MInput
                                                    disabled={this.state.isViewRequest}
                                                    name="full_time_employees"
                                                    placeholder="Full-Time Employees"
                                                    InputProps={{
                                                        endAdornment: (<>
                                                            {<EmailIcon color="inherit" />}
                                                        </>),
                                                    }} /></>}
                                            /> */}
                            <Box>
                              <Box style={{ marginBottom: "8px" }}>
                                <label htmlFor="">Full-Time Employees*</label>
                              </Box>
                              <TextField
                                placeholder="Enter Here"
                                size="small"
                                fullWidth
                                variant="outlined"
                                onChange={handleChange}
                                id="full_time_employees"
                                name="full_time_employees"
                                value={values.full_time_employees}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <PeopleOutlineOutlinedIcon
                                        style={{ color: "#009c05" }}
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              <span style={{ color: "red", fontSize: "10px" }}>
                                {errors.full_time_employees}
                              </span>
                            </Box>
                          </div>
                        </FlexSpaceBetween>
                        <FlexSpaceBetween style={{ gap: 6 }}>
                          <div style={{ flex: 1 }}>
                            {/* <InputRow
                                                label="Part-Time Employees"
                                                rowClass='formRow'
                                                labelClass='formLabel'
                                                input={<><MInput
                                                    disabled={this.state.isViewRequest}
                                                    name="part_time_employees"
                                                    placeholder="Part-Time Employees"
                                                    InputProps={{
                                                        endAdornment: (<>
                                                            {<EmailIcon color="inherit" />}
                                                        </>),
                                                    }} /></>}
                                                /> */}
                            <Box>
                              <Box style={{ marginBottom: "8px" }}>
                                <label htmlFor="">Part-Time Employees</label>
                              </Box>
                              <TextField
                                placeholder="Enter Here"
                                size="small"
                                fullWidth
                                variant="outlined"
                                onChange={handleChange}
                                id="part_time_employees"
                                name="part_time_employees"
                                value={values.part_time_employees}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <PeopleOutlineOutlinedIcon
                                        style={{ color: "#009c05" }}
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              <span style={{ color: "red", fontSize: "10px" }}>
                                {errors.part_time_employees}
                              </span>
                            </Box>
                          </div>
                          <div style={{ flex: 1 }}>
                            {/* <InputRow
                                                label="Number of Part-Time % of workforce"
                                                rowClass='formRow'
                                                labelClass='formLabel'
                                                input={<><MInput disabled={this.state.isViewRequest} name="workforce" placeholder="Workforce" /></>}
                                            /> */}
                            <Box>
                              <Box style={{ marginBottom: "8px" }}>
                                <label htmlFor="">
                                  Number of Part-Time % of workforce
                                </label>
                              </Box>
                              <TextField
                                placeholder="Enter Here"
                                size="small"
                                fullWidth
                                variant="outlined"
                                onChange={handleChange}
                                id="workforce"
                                name="workforce"
                                value={values.workforce}
                              />
                              <span style={{ color: "red", fontSize: "10px" }}>
                                {errors.workforce}
                              </span>
                            </Box>
                          </div>
                        </FlexSpaceBetween>
                        <FlexSpaceBetween style={{ gap: 6 }}>
                          <div style={{ flex: 1 }}>
                            {/* <InputRow
                          label="Company Status*"
                          rowClass='formRow'
                          labelClass='formLabel'
                          input={
                            <AssignToSelect
                              selectProps={{ style: { maxHeight: '45px' }, disabled: this.state.isViewRequest }}
                              hasMore={!!this.state?.userPagination?.next_page}
                              nextPage={this.paginateUsersOptions}
                              loading={(!!this.state?.userSearchCallId)}
                              onSearch={this.handleUserSearch}
                              allItems={(this.state?.defaultUsers)}
                              items={(this.state?.defaultUsers)}
                              name="company_status"
                              label={"Select Company Status"}
                              placeholder="Select Company Status"
                              SelectItem={SelectItemUser}
                              SelectedItem={SelectedItem}
                              getOptionValue={(user: any) => `${user.first_name} ${user.last_name}`}
                            />
                          }
                        /> */}
                            {this.state.otherCompanyStatus ? (
                              <>
                                <Box style={{ marginBottom: "8px" }}>
                                  <label htmlFor="">Company Status*</label>
                                </Box>
                                <FormControl size="small" fullWidth>
                                  <Select
                                    variant="outlined"
                                    onChange={handleChange}
                                    name="company_status"
                                    id="company_status"
                                    value={values.company_status}
                                  >
                                    {this.companyStatus &&
                                      this.companyStatus.map((item, index) => {
                                        return (
                                          <MenuItem value={item}>
                                            {item}
                                          </MenuItem>
                                        );
                                      })}
                                    <MenuItem
                                      id="otherComStat"
                                      onClick={() => {
                                        this.setState({
                                          otherCompanyStatus: false,
                                        });
                                      }}
                                    >
                                      Others - Please Specify
                                    </MenuItem>
                                  </Select>
                                  <span
                                    style={{ color: "red", fontSize: "10px" }}
                                  >
                                    {errors.company_status}
                                  </span>
                                </FormControl>
                              </>
                            ) : (
                              <>
                                <Box>
                                  <Box style={{ marginBottom: "8px" }}>
                                    <label htmlFor="">
                                      Company Status - Please Specify (others)*
                                    </label>
                                  </Box>
                                  <TextField
                                    placeholder="Enter Here"
                                    size="small"
                                    fullWidth
                                    variant="outlined"
                                    onChange={handleChange}
                                    id="company_status"
                                    name="company_status"
                                    value={values.company_status}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <ArrowDropDownIcon
                                            onClick={() => {
                                              this.setState({
                                                otherCompanyStatus: true,
                                              });
                                            }}
                                          />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                  <span
                                    style={{ color: "red", fontSize: "10px" }}
                                  >
                                    {errors.company_status}
                                  </span>
                                </Box>{" "}
                              </>
                            )}
                          </div>
                          <div style={{ flex: 1 }}>
                            {/* <InputRow
                          label="Ownership Type*"
                          rowClass='formRow'
                          labelClass='formLabel'
                          input={
                            <AssignToSelect
                              selectProps={{ style: { maxHeight: '45px' }, disabled: this.state.isViewRequest }}
                              hasMore={!!this.state?.userPagination?.next_page}
                              nextPage={this.paginateUsersOptions}
                              loading={(!!this.state?.userSearchCallId)}
                              onSearch={this.handleUserSearch}
                              allItems={(this.state?.defaultUsers)}
                              items={(this.state?.defaultUsers)}
                              name="ownership_type"
                              label={"Select Ownership Type"}
                              placeholder="Select Ownership Type"
                              SelectItem={SelectItemUser}
                              SelectedItem={SelectedItem}
                              getOptionValue={(user: any) => `${user.first_name} ${user.last_name}`}
                            />
                          }
                        /> */}
                            {this.state.otherOwnershipType ? (
                              <>
                                <Box style={{ marginBottom: "8px" }}>
                                  <label htmlFor="">Ownership Type*</label>
                                </Box>
                                <FormControl fullWidth size="small">
                                  <Select
                                    variant="outlined"
                                    onChange={handleChange}
                                    name="ownership_type"
                                    id="ownership_type"
                                    value={values.ownership_type}
                                  >
                                    {this.Ownership_Type &&
                                      this.Ownership_Type.map((item, index) => {
                                        return (
                                          <MenuItem value={item}>
                                            {item}
                                          </MenuItem>
                                        );
                                      })}
                                    <MenuItem
                                      id="ownerType"
                                      onClick={() => {
                                        this.setState({
                                          otherOwnershipType: false,
                                        });
                                      }}
                                    >
                                      Others - Please Specify
                                    </MenuItem>
                                  </Select>
                                  <span
                                    style={{ color: "red", fontSize: "10px" }}
                                  >
                                    {errors.ownership_type}
                                  </span>
                                </FormControl>
                              </>
                            ) : (
                              <>
                                <Box>
                                  <Box style={{ marginBottom: "8px" }}>
                                    <label htmlFor="">
                                      Ownership Type - Please Specify (others)*
                                    </label>
                                  </Box>
                                  <TextField
                                    placeholder="Enter Here"
                                    size="small"
                                    fullWidth
                                    variant="outlined"
                                    onChange={handleChange}
                                    id="ownership_type"
                                    name="ownership_type"
                                    value={values.ownership_type}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <ArrowDropDownIcon
                                            onClick={() => {
                                              this.setState({
                                                otherOwnershipType: true,
                                              });
                                            }}
                                          />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                  <span
                                    style={{ color: "red", fontSize: "10px" }}
                                  >
                                    {errors.ownership_type}
                                  </span>
                                </Box>{" "}
                              </>
                            )}
                          </div>
                        </FlexSpaceBetween>
                        <FlexSpaceBetween style={{ gap: 6 }}>
                          <div style={{ flex: 1 }}>
                            {/* <InputRow
                          label="Product / Services (Investment Types)*"
                          rowClass='formRow'
                          labelClass='formLabel'
                          input={
                            <AssignToSelect
                              selectProps={{ style: { maxHeight: '45px' }, disabled: this.state.isViewRequest }}
                              hasMore={!!this.state?.userPagination?.next_page}
                              nextPage={this.paginateUsersOptions}
                              loading={(!!this.state?.userSearchCallId)}
                              onSearch={this.handleUserSearch}
                              allItems={(this.state?.defaultUsers)}
                              items={(this.state?.defaultUsers)}
                              name="product"
                              label={"Select Product"}
                              placeholder="Select Product"
                              SelectItem={SelectItemUser}
                              SelectedItem={SelectedItem}
                              getOptionValue={(user: any) => `${user.first_name} ${user.last_name}`}
                            />
                          }
                        /> */}
                            {this.state.otherInvestmentType ? (
                              <>
                                <Box style={{ marginBottom: "8px" }}>
                                  <label htmlFor="">
                                    Product / Services (Investment Types)*
                                  </label>
                                </Box>
                                <FormControl fullWidth size="small">
                                  <Select
                                    variant="outlined"
                                    onChange={handleChange}
                                    name="product"
                                    id="product"
                                    value={values.product}
                                  >
                                    {this.invesmentType.map((item, index) => {
                                      return (
                                        <MenuItem value={item}>{item}</MenuItem>
                                      );
                                    })}
                                    <MenuItem
                                      id="otherInvesttype"
                                      onClick={() => {
                                        this.setState({
                                          otherInvestmentType: false,
                                        });
                                      }}
                                    >
                                      Others - Please Specify
                                    </MenuItem>
                                  </Select>
                                  <span
                                    style={{ color: "red", fontSize: "10px" }}
                                  >
                                    {errors.product}
                                  </span>
                                </FormControl>
                              </>
                            ) : (
                              <>
                                <Box>
                                  <Box style={{ marginBottom: "8px" }}>
                                    <label htmlFor="">
                                      Product / Services - Please Specify
                                      (others)*
                                    </label>
                                  </Box>
                                  <TextField
                                    placeholder="Enter Here"
                                    size="small"
                                    fullWidth
                                    variant="outlined"
                                    onChange={handleChange}
                                    id="product"
                                    name="product"
                                    value={values.product}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <ArrowDropDownIcon
                                            onClick={() => {
                                              this.setState({
                                                otherInvestmentType: true,
                                              });
                                            }}
                                          />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                  <span
                                    style={{ color: "red", fontSize: "10px" }}
                                  >
                                    {errors.product}
                                  </span>
                                </Box>{" "}
                              </>
                            )}
                          </div>
                        </FlexSpaceBetween>
                        <FlexSpaceBetween style={{ gap: 6 }}>
                          <div style={{ flex: 1 }}>
                            {/* <InputRow
                                                label="Business Description (or Fund focus)*"
                                                rowClass='formRow'
                                                labelClass='formLabel'
                                                input={
                                                    <>
                                                        <MInput
                                                            variant="outlined"
                                                            name="description"
                                                            disabled={this.state.isViewRequest}
                                                            multiline
                                                            maxRows={4}
                                                            minRows={4}
                                                            fullWidth
                                                        />
                                                    </>}
                                                /> */}
                            <Box>
                              <Box style={{ marginBottom: "8px" }}>
                                <label htmlFor="">
                                  Business Description (or Fund Focus)*
                                </label>
                              </Box>
                              <TextField
                                placeholder="Enter Here"
                                size="small"
                                fullWidth
                                variant="outlined"
                                multiline
                                maxRows={4}
                                minRows={4}
                                onChange={handleChange}
                                id="description"
                                name="description"
                                value={values.description}
                              />
                              <span style={{ color: "red", fontSize: "10px" }}>
                                {errors.description}
                              </span>
                            </Box>
                          </div>
                        </FlexSpaceBetween>
                        <FlexSpaceBetween style={{ gap: 6 }}>
                          <div style={{ flex: 1 }}>
                            {/* <InputRow
                          label="Facilities / Offices / Locations*"
                          rowClass='formRow'
                          labelClass='formLabel'
                          input={
                            <AssignToSelect
                              selectProps={{ style: { maxHeight: '45px' }, disabled: this.state.isViewRequest }}
                              hasMore={!!this.state?.userPagination?.next_page}
                              nextPage={this.paginateUsersOptions}
                              loading={(!!this.state?.userSearchCallId)}
                              onSearch={this.handleUserSearch}
                              allItems={(this.state?.defaultUsers)}
                              items={(this.state?.defaultUsers)}
                              name="facilities"
                              label={"Select Facilities"}
                              placeholder="Select Facilities"
                              SelectItem={SelectItemUser}
                              SelectedItem={SelectedItem}
                              getOptionValue={(user: any) => `${user.first_name} ${user.last_name}`}
                            />
                          }
                        /> */}
                            {/* <Box style={{ marginBottom: "8px" }}>
                              Facilities / Offices / Locations*
                            </Box>
                            <FormControl fullWidth size="small">
                              <Select
                                variant="outlined"
                                onChange={handleChange}
                                name="facilities"
                                id="facilities"
                              >
                                {this.officeLocation.map((item, index) => {
                                  return (
                                    <MenuItem value={item}>{item}</MenuItem>
                                  );
                                })}
                              </Select>
                              <span style={{ color: "red", fontSize: "10px" }}>
                                {errors.facilities}
                              </span>
                            </FormControl> */}
                            <Box>
                              <Box style={{ marginBottom: "8px" }}>
                                <label htmlFor="">
                                  Facilities / Offices / Locations
                                </label>
                              </Box>
                              <TextField
                                placeholder="Enter Here"
                                size="small"
                                fullWidth
                                variant="outlined"
                                onChange={handleChange}
                                id="facilities"
                                name="facilities"
                                value={values.facilities}
                              />
                              <span style={{ color: "red", fontSize: "10px" }}>
                                {errors.facilities}
                              </span>
                            </Box>
                          </div>
                          <div style={{ flex: 1 }}>
                            {/* <InputRow
                                                label="Subsidiaries*"
                                                rowClass='formRow'
                                                labelClass='formLabel'
                                                input={<><MInput disabled={this.state.isViewRequest} name="subsidiaries" placeholder="" /></>}
                                                /> */}
                            <Box>
                              <Box style={{ marginBottom: "8px" }}>
                                <label htmlFor="">Subsidiaries</label>
                              </Box>
                              <TextField
                                placeholder="Enter Here"
                                size="small"
                                fullWidth
                                variant="outlined"
                                onChange={handleChange}
                                id="subsidiaries"
                                name="subsidiaries"
                                value={values.subsidiaries}
                              />
                              <span style={{ color: "red", fontSize: "10px" }}>
                                {errors.subsidiaries}
                              </span>
                            </Box>
                          </div>
                        </FlexSpaceBetween>
                        <FlexSpaceBetween style={{ gap: 6 }}>
                          <div style={{ flex: 1 }}>
                            {/* <InputRow
                                                label="Shareholding Pattern*"
                                                rowClass='formRow'
                                                labelClass='formLabel'
                                                input={<><MInput disabled={this.state.isViewRequest} name="Shareholding_Pattern" placeholder="" /></>}
                                                /> */}
                            {/* <Box>
                              <Box style={{ marginBottom: "8px" }}>
                                <label htmlFor="">Shareholding Pattern*</label>
                              </Box>
                              <TextField
                                placeholder="Enter Here"
                                size="small"
                                fullWidth
                                variant="outlined"
                                onChange={handleChange}
                                id="Shareholding_Pattern"
                                name="Shareholding_Pattern"
                              />
                              <span style={{ color: "red", fontSize: "10px" }}>
                                {errors.Shareholding_Pattern}
                              </span>
                            </Box> */}
                            {this.state.otherShareholdingPattern ? (
                              <>
                                <Box style={{ marginBottom: "8px" }}>
                                  <label htmlFor="">
                                    Shareholding Pattern*
                                  </label>
                                </Box>
                                <FormControl fullWidth size="small">
                                  <Select
                                    variant="outlined"
                                    onChange={handleChange}
                                    name="Shareholding_Pattern"
                                    id="Shareholding_Pattern"
                                    value={values.Shareholding_Pattern}
                                  >
                                    {this.shareHoldingPattern.map(
                                      (item, index) => {
                                        return (
                                          <MenuItem value={item}>
                                            {item}
                                          </MenuItem>
                                        );
                                      }
                                    )}
                                    <MenuItem
                                      id="otherShare"
                                      onClick={() => {
                                        this.setState({
                                          otherShareholdingPattern: false,
                                        });
                                      }}
                                    >
                                      Others - Please Specify
                                    </MenuItem>
                                  </Select>
                                  <span
                                    style={{ color: "red", fontSize: "10px" }}
                                  >
                                    {errors.Shareholding_Pattern}
                                  </span>
                                </FormControl>
                              </>
                            ) : (
                              <>
                                <Box>
                                  <Box style={{ marginBottom: "8px" }}>
                                    <label htmlFor="">
                                      Shareholding Pattern - Please Specify
                                      (others)*
                                    </label>
                                  </Box>
                                  <TextField
                                    placeholder="Enter Here"
                                    size="small"
                                    fullWidth
                                    variant="outlined"
                                    onChange={handleChange}
                                    id="Shareholding_Pattern"
                                    name="Shareholding_Pattern"
                                    value={values.Shareholding_Pattern}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <ArrowDropDownIcon
                                            onClick={() => {
                                              this.setState({
                                                otherShareholdingPattern: true,
                                              });
                                            }}
                                          />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                  <span
                                    style={{ color: "red", fontSize: "10px" }}
                                  >
                                    {errors.Shareholding_Pattern}
                                  </span>
                                </Box>{" "}
                              </>
                            )}
                          </div>
                          <div style={{ flex: 1 }}>
                            {/* <InputRow
                                                label="% of owners who are women*"
                                                rowClass='formRow'
                                                labelClass='formLabel'
                                                input={<><MInput disabled={this.state.isViewRequest} name="owners_women" placeholder="" /></>}
                                                /> */}
                            <Box>
                              <Box style={{ marginBottom: "8px" }}>
                                <label htmlFor="">
                                  % of owners who are women*
                                </label>
                              </Box>
                              <TextField
                                placeholder="Enter Here"
                                size="small"
                                fullWidth
                                variant="outlined"
                                onChange={handleChange}
                                id="owners_women"
                                name="owners_women"
                                value={values.owners_women}
                              />
                              <span style={{ color: "red", fontSize: "10px" }}>
                                {errors.owners_women}
                              </span>
                            </Box>
                          </div>
                        </FlexSpaceBetween>
                        <FlexSpaceBetween style={{ gap: 6 }}>
                          <div style={{ flex: 1 }}>
                            {/* <InputRow
                                                label="% of owners who are indigenuos people*"
                                                rowClass='formRow'
                                                labelClass='formLabel'
                                                input={<><MInput disabled={this.state.isViewRequest} name="owners_indigenuos" placeholder="" /></>}
                                                /> */}
                            <Box>
                              <Box style={{ marginBottom: "8px" }}>
                                <label htmlFor="">
                                  % of owners who are indigenuos people*
                                </label>
                              </Box>
                              <TextField
                                placeholder="Enter Here"
                                size="small"
                                fullWidth
                                variant="outlined"
                                onChange={handleChange}
                                id="owners_indigenuos"
                                name="owners_indigenuos"
                                value={values.owners_indigenuos}
                              />
                              <span style={{ color: "red", fontSize: "10px" }}>
                                {errors.owners_indigenuos}
                              </span>
                            </Box>
                          </div>
                          <div style={{ flex: 1 }}>
                            {/* <InputRow
                                                label="% of owners who are other minorities*"
                                                rowClass='formRow'
                                                labelClass='formLabel'
                                                input={<><MInput disabled={this.state.isViewRequest} name="owners_minorities" placeholder="" /></>}
                                                /> */}
                            <Box>
                              <Box style={{ marginBottom: "8px" }}>
                                <label htmlFor="">
                                  % of owners who are other minorities*
                                </label>
                              </Box>
                              <TextField
                                placeholder="Enter Here"
                                size="small"
                                fullWidth
                                variant="outlined"
                                onChange={handleChange}
                                id="owners_minorities"
                                name="owners_minorities"
                                value={values.owners_minorities}
                              />
                              <span style={{ color: "red", fontSize: "10px" }}>
                                {errors.owners_minorities}
                              </span>
                            </Box>
                          </div>
                        </FlexSpaceBetween>
                      </div>

                      <Grid
                        item
                        xs={12}
                        style={{
                          display: "flex",
                          justifyContent: "right",
                          alignItems: "right",
                          flexDirection: "row",
                        }}
                      >
                        <RoundedButtonBase
                          color="primary"
                          variant="contained"
                          id="nextbtn"
                          onClick={() => {
                            handleSubmit();
                          }}
                          // type={"submit"}
                        >
                          NEXT
                        </RoundedButtonBase>
                      </Grid>
                    </RightFormWrapper>

                    <OverlayLoader
                      loading={Boolean(
                        this.state.respondRequestCallId ||
                          this.state.submitRequestCallId
                      )}
                    />
                  </RoundedCard>
                </div>
              ) : (
                <div>
                  <DIV>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                        id="goback1page"
                        onClick={this.handleGoBack}
                        style={{
                          margin: "40px 0px 15px 0px",
                          cursor: "pointer",
                        }}
                      >
                        <ArrowBackIosIcon />
                      </div>
                      <CoinsHead>Mission And Vision</CoinsHead>
                    </div>
                    {/* <Formik enableReinitialize initialValues={this.initialValues} onSubmit={this.handleSubmit}   > */}
                    {/* {({ values,errors, handleChange }) => ( */}
                    <div>
                      <RoundedCard>
                        <FlexSpaceBetween style={{ gap: 6 }}>
                          <div style={{ flex: 1 }}>
                            <InputRow
                              label="Organization Purpose*"
                              rowClass="formRow"
                              labelClass="formLabel"
                              input={
                                <MCountedTextArea
                                  name="orgainsation_purpose"
                                  value={values.orgainsation_purpose}
                                  placeholder="Enter Here"
                                />
                              }
                            />
                            {/* <Box>
                              <Box style={{ marginBottom: "8px" }}>
                                <label htmlFor="">Organization Purpose*</label>
                              </Box>

                                <TextField
                                  placeholder="Enter Here"
                                multiline
                                minRows={4}
                                maxRows={4}
                                size="small"
                                fullWidth
                                variant="outlined"
                                onChange={handleChange}
                                id="orgainsation_purpose"
                                  name="orgainsation_purpose"
                                  value={values.orgainsation_purpose}
                              />
                              <span style={{ color: "red", fontSize: "10px" }}>
                                {errors.orgainsation_purpose}
                              </span>
                            </Box> */}
                          </div>
                        </FlexSpaceBetween>
                        <FlexSpaceBetween style={{ gap: 6 }}>
                          <div style={{ flex: 1 }}>
                            <InputRow
                              label="Organization Vision*"
                              rowClass="formRow"
                              labelClass="formLabel"
                              icon={orgVision}
                              input={
                                <MCountedTextArea
                                  name="orgainsation_vision"
                                  value={values.orgainsation_vision}
                                  placeholder="Enter here"
                                />
                              }
                            />
                            {/* <Box>
                              <Box style={{ marginBottom: "8px" }}>
                                <label htmlFor="">Organization Vision*</label>
                              </Box>
                              <TextField
                              placeholder="Enter Here"
                                multiline
                                minRows={4}
                                maxRows={4}
                                size="small"
                                fullWidth
                                variant="outlined"
                                onChange={handleChange}
                                id="orgainsation_vision"
                                  name="orgainsation_vision"
                                  value={values.orgainsation_vision}
                              />
                              <span style={{ color: "red", fontSize: "10px" }}>
                                {errors.orgainsation_vision}
                              </span>
                            </Box> */}
                          </div>
                        </FlexSpaceBetween>
                        <FlexSpaceBetween style={{ gap: 6 }}>
                          <div style={{ flex: 1 }}>
                            <InputRow
                              label="Organization Mission*"
                              rowClass="formRow"
                              labelClass="formLabel"
                              icon={orgMission}
                              input={
                                <MCountedTextArea
                                  name="orgainsation_mission"
                                  value={values.orgainsation_mission}
                                  placeholder="Enter here"
                                />
                              }
                            />
                            {/* <Box>
                              <Box style={{ marginBottom: "8px" }}>
                                <label htmlFor="">Organization Mission*</label>
                              </Box>
                                <TextField
                                  placeholder="Enter Here"
                                multiline
                                minRows={4}
                                maxRows={4}
                                size="small"
                                fullWidth
                                variant="outlined"
                                onChange={handleChange}
                                id="orgainsation_mission"
                                  name="orgainsation_mission"
                                  value={values.orgainsation_mission}
                              />
                              <span style={{ color: "red", fontSize: "10px" }}>
                                {errors.orgainsation_mission}
                              </span>
                            </Box> */}
                          </div>
                        </FlexSpaceBetween>
                        <FlexSpaceBetween style={{ gap: 6 }}>
                          <div style={{ flex: 1 }}>
                            <InputRow
                              label="Organization Values*"
                              rowClass="formRow"
                              labelClass="formLabel"
                              input={
                                <MCountedTextArea
                                  name="orgainsation_values"
                                  value={values.orgainsation_values}
                                  placeholder="Enter here"
                                />
                              }
                            />
                            {/* <Box>
                              <Box style={{ marginBottom: "8px" }}>
                                <label htmlFor="">Organization Values*</label>
                              </Box>
                                <TextField
                                  placeholder="Enter Here"
                                multiline
                                minRows={4}
                                maxRows={4}
                                size="small"
                                fullWidth
                                variant="outlined"
                                onChange={handleChange}
                                id="orgainsation_values"
                                  name="orgainsation_values"
                                  value={values.orgainsation_values}
                              />
                              <span style={{ color: "red", fontSize: "10px" }}>
                                {errors.orgainsation_values}
                              </span>
                            </Box> */}
                          </div>
                        </FlexSpaceBetween>
                        <FlexSpaceBetween style={{ gap: 6 }}>
                          <div style={{ flex: 1 }}>
                            <InputRow
                              label={`Message from CEO and Chairman*`}
                              rowClass="formRow"
                              labelClass="formLabel"
                              icon={CEOmsg}
                              input={
                                <MCountedTextArea
                                  name="messageCeo"
                                  value={values.messageCeo}
                                  placeholder="Enter here"
                                />
                              }
                              // icon={<MessageOutlinedIcon style={{color:"#009c05"}}  />}
                            />

                            {/* <Box>
                              <Box style={{ marginBottom: "8px" }}>
                                <label htmlFor="">
                                  Message from CEO and Chairman*
                                </label>
                              </Box>
                                <TextField
                                  placeholder="Enter Here"
                                multiline
                                minRows={4}
                                maxRows={4}
                                size="small"
                                fullWidth
                                variant="outlined"
                                onChange={handleChange}
                                id="messageCeo"
                                  name="messageCeo"
                                  value={values.messageCeo}
                              />
                              <span style={{ color: "red", fontSize: "10px" }}>
                                {errors.messageCeo}
                              </span>
                            </Box> */}
                          </div>
                        </FlexSpaceBetween>
                      </RoundedCard>
                      <CoinsHead>List of Certifications Achieved</CoinsHead>
                      <Formik
                        enableReinitialize
                        initialValues={this.state.certificateEditData}
                        validationSchema={this.certificationValidation}
                        onSubmit={(values, { resetForm }) => {
                          this.onCertificationSubmit(values, resetForm);
                        }}
                      >
                        {({
                          values,
                          handleChange,
                          errors,
                          touched,
                          setFieldValue,
                          setTouched,
                        }) => (
                          <Form translate={undefined}>
                            <RoundedCard>
                              {this.state.cartificate_data &&
                                this.state.cartificate_data.map(
                                  (item: any, index: any) => {
                                    return (
                                      <FlexSpaceBetween style={{ gap: 6 }}>
                                        <div style={{ flex: 1 }}>
                                          <RoundedCardBorder>
                                            <FlexSpaceBetween
                                              style={{ gap: 6 }}
                                            >
                                              <div style={{ flex: 1 }}>
                                                <Typography variant="h6">
                                                  {item?.certificationName}
                                                </Typography>
                                              </div>

                                              <div style={{ float: "right" }}>
                                                <div>
                                                  <DeleteOutlineIcon
                                                    id="outlineIcon1"
                                                    color="disabled"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                      this.handleCertificationDelete(
                                                        item,
                                                        index
                                                      )
                                                    }
                                                  />
                                                  <EditOutlinedIcon
                                                    id="editIcon"
                                                    color="disabled"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                      this.handleCertificationEdit(
                                                        index
                                                      );
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                            </FlexSpaceBetween>
                                            {item?.certificationEpiryDate && (
                                              <Chip
                                                label={`Expiry Date : ${moment(
                                                  item?.certificationEpiryDate
                                                ).format("DD/MM/YYYY")}`}
                                              />
                                            )}
                                            <Typography
                                              variant="body2"
                                              style={{ margin: "10px" }}
                                            >
                                              {item?.certificationDescription}
                                            </Typography>
                                            {item?.certificationDocument && (
                                              <Box
                                                id="certifcateDes"
                                                onClick={() =>
                                                  window.open(
                                                    item?.certificationDocument
                                                      ?.path
                                                  )
                                                }
                                                style={{
                                                  display: "flex",
                                                  cursor: "pointer",
                                                }}
                                              >
                                                <div>
                                                  <img
                                                    src={PdfIcon2}
                                                    height="43px"
                                                    width="38px"
                                                    alt=""
                                                  />
                                                </div>
                                                <div
                                                  style={{ marginLeft: "15px" }}
                                                >
                                                  <Typography
                                                    style={{
                                                      marginBottom: "0px",
                                                      fontWeight: "normal",
                                                    }}
                                                  >
                                                    {
                                                      item
                                                        ?.certificationDocument
                                                        ?.name
                                                    }
                                                  </Typography>
                                                  <Typography
                                                    style={{ color: "gray" }}
                                                    variant="body2"
                                                  >
                                                    {
                                                      item
                                                        ?.certificationDocument
                                                        ?.size
                                                    }{" "}
                                                    Kb
                                                  </Typography>
                                                </div>
                                              </Box>
                                            )}
                                          </RoundedCardBorder>
                                        </div>
                                      </FlexSpaceBetween>
                                    );
                                  }
                                )}
                              {this.state.showCertificateForm && (
                                <FlexSpaceBetween style={{ gap: 6 }}>
                                  <div style={{ flex: 1 }}>
                                    <RoundedCardBorder
                                      style={{ position: "relative" }}
                                    >
                                      <FlexSpaceBetween
                                        style={{ gap: 6, flexWrap: "wrap" }}
                                      >
                                        <div
                                          style={{ flex: 1, minWidth: "244px" }}
                                        >
                                          {this.state.otherCertificate ? (
                                            <>
                                              {" "}
                                              <Box
                                                style={{ marginBottom: "8px" }}
                                              >
                                                <label htmlFor="">
                                                  Certification Name
                                                </label>
                                              </Box>
                                              <FormControl
                                                fullWidth
                                                size="small"
                                              >
                                                <Select
                                                  style={{ padding: "2.5px" }}
                                                  variant="outlined"
                                                  onChange={(e: any) =>
                                                    this.handleDuplicateName(
                                                      setFieldValue,
                                                      e.target.value
                                                    )
                                                  }
                                                  name="certificationName"
                                                  id="certificationName"
                                                  value={
                                                    values.certificationName
                                                  }
                                                  fullWidth
                                                >
                                                  {this
                                                    .certification_dropdowns &&
                                                    this.certification_dropdowns.map(
                                                      (
                                                        item: any,
                                                        index: any
                                                      ) => {
                                                        return (
                                                          <MenuItem
                                                            value={item}
                                                          >
                                                            {item}
                                                          </MenuItem>
                                                        );
                                                      }
                                                    )}
                                                    <MenuItem
                                                      id="othercert"
                                                    onClick={() => {
                                                      this.setState({
                                                        otherCertificate: false,
                                                      });
                                                    }}
                                                  >
                                                    Others - Please Specify
                                                  </MenuItem>
                                                </Select>
                                                <span
                                                  style={{
                                                    color: "red",
                                                    fontSize: "10px",
                                                  }}
                                                >
                                                  {errors.certificationName}
                                                </span>
                                              </FormControl>
                                            </>
                                          ) : (
                                            <>
                                              <Box>
                                                <Box
                                                  style={{
                                                    marginBottom: "8px",
                                                  }}
                                                >
                                                  <label htmlFor="">
                                                    Certification Name - Please
                                                    Specify (others)*
                                                  </label>
                                                </Box>
                                                    <TextField
                                                      
                                                  placeholder="Enter Here"
                                                  size="small"
                                                  fullWidth
                                                  variant="outlined"
                                                  onChange={(e: any) =>
                                                    this.handleDuplicateName(
                                                      setFieldValue,
                                                      e.target.value
                                                    )
                                                  }
                                                  id="certificationName"
                                                  name="certificationName"
                                                  value={
                                                    values.certificationName
                                                  }
                                                  InputProps={{
                                                    endAdornment: (
                                                      <InputAdornment position="end">
                                                        <ArrowDropDownIcon
                                                          onClick={() => {
                                                            this.setState({
                                                              otherCertificate: true,
                                                            });
                                                          }}
                                                        />
                                                      </InputAdornment>
                                                    ),
                                                  }}
                                                />
                                                <span
                                                  style={{
                                                    color: "red",
                                                    fontSize: "10px",
                                                  }}
                                                >
                                                  {errors.certificationName}
                                                </span>
                                              </Box>
                                            </>
                                          )}
                                        </div>
                                        <div
                                          style={{ flex: 1, minWidth: "244px" }}
                                        >
                                          <InputRow
                                            label="Expiry Date"
                                            rowClass="formRow"
                                            labelClass="formLabel"
                                            input={
                                              <MDatePicker
                                                value={
                                                  values.certificationEpiryDate
                                                }
                                                name="certificationEpiryDate"
                                                id="certificationEpiryDate"
                                                disablePast
                                                InputProps={{
                                                  placeholder: "Enter Date",
                                                }}
                                              />
                                            }
                                          />
                                        </div>
                                      </FlexSpaceBetween>
                                      <CloseIconButton
                                        style={{
                                          position: "absolute",
                                          top: "7",
                                          right: "10",
                                        }}
                                        onClick={() => {
                                          this.handleResterCertification();
                                        }}
                                      >
                                        <CloseIcon />
                                      </CloseIconButton>
                                      <FlexSpaceBetween style={{ gap: 6 }}>
                                        <div style={{ flex: 1 }}>
                                          <InputRow
                                            label="Description"
                                            rowClass="formRow"
                                            labelClass="formLabel"
                                            input={
                                              <>
                                                <MInput
                                                  variant="outlined"
                                                  multiline
                                                  maxRows={4}
                                                  minRows={4}
                                                  fullWidth
                                                  name="certificationDescription"
                                                  value={
                                                    values.certificationDescription
                                                  }
                                                  id="certificationDescription"
                                                  placeholder="Enter here"
                                                />
                                              </>
                                            }
                                          />
                                        </div>
                                      </FlexSpaceBetween>
                                      <div style={{ margin: "10px 0" }}>
                                        {!values.certificationDocument && (
                                          <UploadButton>
                                            <label htmlFor="certificationDocument">
                                              Upload Attachment
                                            </label>
                                          </UploadButton>
                                        )}
                                      </div>
                                      <span
                                        style={{
                                          color: "red",
                                          fontSize: "10px",
                                        }}
                                      >
                                        {errors.certificationDocument}
                                      </span>
                                      <span style={{ fontSize: "10px" }}>
                                        {values.certificationDocument && (
                                          <Box
                                            style={{
                                              display: "flex",
                                              padding: "10px",
                                            }}
                                          >
                                            <div>
                                              <img
                                                src={PdfIcon2}
                                                height="43px"
                                                width="38px"
                                                alt=""
                                              />
                                            </div>
                                            <div style={{ marginLeft: "15px" }}>
                                              <Typography
                                                style={{
                                                  marginBottom: "0px",
                                                  fontWeight: "normal",
                                                }}
                                              >
                                                {
                                                  values.certificationDocument
                                                    ?.name
                                                }
                                              </Typography>
                                              <Typography
                                                style={{ color: "gray" }}
                                                variant="body2"
                                              >
                                                {
                                                  values.certificationDocument
                                                    ?.size
                                                }{" "}
                                                Kb
                                              </Typography>
                                            </div>
                                            <div>
                                              <IconButton>
                                                <CloseIcon
                                                  onClick={() => {
                                                    setFieldValue(
                                                      "certificationDocument",
                                                      ""
                                                    );
                                                  }}
                                                />
                                              </IconButton>
                                            </div>
                                          </Box>
                                        )}
                                      </span>
                                      <input
                                        type="file"
                                        accept=".pdf"
                                        id="certificationDocument"
                                        style={{ display: "none" }}
                                        onChange={(e: any) =>
                                          setFieldValue(
                                            "certificationDocument",
                                            e.target.files[0]
                                          )
                                        }
                                      />
                                      <div>
                                        <RoundedButtonBaseCer
                                          style={{ marginTop: "20px" }}
                                          variant="contained"
                                          disableElevation
                                          disabled={this.state.isdisabled}
                                          type="submit"
                                        >
                                          Save
                                        </RoundedButtonBaseCer>
                                      </div>
                                    </RoundedCardBorder>
                                  </div>
                                </FlexSpaceBetween>
                              )}
                              <RoundedButtonMore
                                onClick={this.onFormAdd}
                                variant="contained"
                                disableElevation
                                disabled={
                                  this.state.isdisabled ||
                                  this.state.showCertificateForm
                                }
                                startIcon={
                                  <img
                                    src={iconAdd}
                                    width={30}
                                    style={{ marginLeft: "-5px" }}
                                  />
                                }
                              >
                                add
                              </RoundedButtonMore>
                            </RoundedCard>
                          </Form>
                        )}
                      </Formik>

                      <CoinsHead>Key Financial Metrics</CoinsHead>
                      <RoundedCard>
                        <FlexSpaceBetween style={{ gap: 6 }}>
                          <div style={{ flex: 1 }}>
                            <InputRow
                              label="Market Capitalisation"
                              rowClass="formRow"
                              labelClass="formLabel"
                              input={
                                <>
                                  <MInput
                                    name="market_capitalization"
                                    value={values.market_capitalization}
                                    {...dollarFieldProps}
                                  />
                                </>
                              }
                            />
                          </div>
                          <div style={{ flex: 1 }}>
                            <InputRow
                              label="Net Sales Million*"
                              rowClass="formRow"
                              labelClass="formLabel"
                              input={
                                <>
                                  <MInput
                                    name="net_sales"
                                    value={values.net_sales}
                                    {...dollarFieldProps}
                                  />
                                </>
                              }
                            />
                          </div>
                        </FlexSpaceBetween>
                        <FlexSpaceBetween style={{ gap: 6 }}>
                          <div style={{ flex: 1 }}>
                            <InputRow
                              label="Operating Income*"
                              rowClass="formRow"
                              labelClass="formLabel"
                              input={
                                <>
                                  <MInput
                                    name="operating_income"
                                    value={values.operating_income}
                                    {...dollarFieldProps}
                                  />
                                </>
                              }
                            />
                          </div>
                          <div style={{ flex: 1 }}>
                            <InputRow
                              label="Total Debt"
                              rowClass="formRow"
                              labelClass="formLabel"
                              input={
                                <>
                                  <MInput
                                    name="total_debt"
                                    value={values.total_debt}
                                    {...dollarFieldProps}
                                  />
                                </>
                              }
                            />
                          </div>
                        </FlexSpaceBetween>
                        <FlexSpaceBetween style={{ gap: 6 }}>
                          <div style={{ flex: 1 }}>
                            <InputRow
                              label="Research, Development and Related Expenses"
                              rowClass="formRow"
                              labelClass="formLabel"
                              input={
                                <>
                                  <MInput
                                    name="research_devlopment"
                                    value={values.research_devlopment}
                                    {...dollarFieldProps}
                                  />
                                </>
                              }
                            />
                          </div>
                          <div style={{ flex: 1 }}>
                            <InputRow
                              label="Free Cash Flow"
                              rowClass="formRow"
                              labelClass="formLabel"
                              input={
                                <>
                                  <MInput
                                    name="free_cash_flow"
                                    value={values.free_cash_flow}
                                    {...dollarFieldProps}
                                  />
                                </>
                              }
                            />
                          </div>
                        </FlexSpaceBetween>
                        <FlexSpaceBetween style={{ gap: 6 }}>
                          <div style={{ flex: 1 }}>
                            <InputRow
                              label="Patents Filed"
                              rowClass="formRow"
                              labelClass="formLabel"
                              input={
                                <>
                                  <MInput
                                    name="patent_filed"
                                    value={values.patent_filed}
                                    {...dollarFieldProps}
                                  />
                                </>
                              }
                            />
                          </div>
                          <div style={{ flex: 1 }}>
                            <InputRow
                              label="Total Operating Million Expenses*"
                              rowClass="formRow"
                              labelClass="formLabel"
                              input={
                                <>
                                  <MInput
                                    name="operating_expence"
                                    value={values.operating_expence}
                                    {...dollarFieldProps}
                                  />
                                </>
                              }
                            />
                          </div>
                        </FlexSpaceBetween>
                        <FlexSpaceBetween style={{ gap: 6 }}>
                          <div style={{ flex: 1 }}>
                            <InputRow
                              label="Net Income Attributable*"
                              rowClass="formRow"
                              labelClass="formLabel"
                              input={
                                <>
                                  <MInput
                                    name="net_income"
                                    value={values.net_income}
                                    {...dollarFieldProps}
                                  />
                                </>
                              }
                            />
                          </div>
                          <div style={{ flex: 1 }}>
                            <InputRow
                              label="Basic EPS Actual"
                              rowClass="formRow"
                              labelClass="formLabel"
                              input={
                                <>
                                  <MInput
                                    name="per_share_basic"
                                    value={values.per_share_basic}
                                    {...dollarFieldProps}
                                  />
                                </>
                              }
                            />
                          </div>
                        </FlexSpaceBetween>
                        <FlexSpaceBetween style={{ gap: 6 }}>
                          <div style={{ flex: 1 }}>
                            <InputRow
                              label="Diluted EPS"
                              rowClass="formRow"
                              labelClass="formLabel"
                              input={
                                <>
                                  <MInput
                                    name="per_share_diluted"
                                    value={values.per_share_diluted}
                                    {...dollarFieldProps}
                                  />
                                </>
                              }
                            />
                          </div>
                          <div style={{ flex: 1 }}>
                            <InputRow
                              label="Capital Expenditures Million"
                              rowClass="formRow"
                              labelClass="formLabel"
                              input={
                                <>
                                  <MInput
                                    name="capital_expences"
                                    value={values.capital_expences}
                                    {...dollarFieldProps}
                                  />
                                </>
                              }
                            />
                          </div>
                        </FlexSpaceBetween>
                        <FlexSpaceBetween style={{ gap: 6 }}>
                          <div style={{ flex: 1 }}>
                            <InputRow
                              label="Payments to Providers Million of Capital"
                              rowClass="formRow"
                              labelClass="formLabel"
                              input={
                                <>
                                  <MInput
                                    name="payments_to_provider"
                                    value={values.payments_to_provider}
                                    {...dollarFieldProps}
                                  />
                                </>
                              }
                            />
                          </div>
                          <div style={{ flex: 1 }}>
                            <InputRow
                              label="Provision for Million Income Taxes*"
                              rowClass="formRow"
                              labelClass="formLabel"
                              input={
                                <>
                                  <MInput
                                    name="million_tax_income"
                                    value={values.million_tax_income}
                                    {...dollarFieldProps}
                                  />
                                </>
                              }
                            />
                          </div>
                        </FlexSpaceBetween>
                        <FlexSpaceBetween style={{ gap: 6 }}>
                          <div style={{ flex: 1 }}>
                            <InputRow
                              label="Total Global Giving Million"
                              rowClass="formRow"
                              labelClass="formLabel"
                              input={
                                <>
                                  <MInput
                                    name="global_giving"
                                    value={values.global_giving}
                                    {...dollarFieldProps}
                                  />
                                </>
                              }
                            />
                          </div>
                          <div style={{ flex: 1 }}>
                            {/* <InputRow
                              label="Net Profit Margin"
                              rowClass='formRow'
                              labelClass='formLabel'
                              input={<><MInput name="profit_margin"  {...percentFieldProps} /></>}
                            /> */}
                            <InputRow
                              label="Total Cash Donations Million"
                              rowClass="formRow"
                              labelClass="formLabel"
                              input={
                                <>
                                  <MInput
                                    name="cash_donations"
                                    value={values.cash_donations}
                                    {...dollarFieldProps}
                                  />
                                </>
                              }
                            />
                          </div>
                        </FlexSpaceBetween>
                        <FlexSpaceBetween style={{ gap: 6 }}>
                          <div style={{ flex: 1 }}>
                            <InputRow
                              label="Total in-kind Donations Million"
                              rowClass="formRow"
                              labelClass="formLabel"
                              input={
                                <>
                                  <MInput
                                    name="in_kind_donations"
                                    value={values.in_kind_donations}
                                    {...dollarFieldProps}
                                  />
                                </>
                              }
                            />
                          </div>
                          <div style={{ flex: 1 }}>
                            <InputRow
                              label="Net Profit Margin"
                              rowClass="formRow"
                              labelClass="formLabel"
                              input={
                                <>
                                  <MInput
                                    name="profit_margin"
                                    value={values.profit_margin}
                                    {...percentFieldProps}
                                  />
                                </>
                              }
                            />
                          </div>
                        </FlexSpaceBetween>
                        <FlexSpaceBetween style={{ gap: 6 }}>
                          <div style={{ flex: 1 }}>
                            <InputRow
                              label="Gross Profit Margin"
                              rowClass="formRow"
                              labelClass="formLabel"
                              input={
                                <>
                                  <MInput
                                    name="gross_margin"
                                    value={values.gross_margin}
                                    {...percentFieldProps}
                                  />
                                </>
                              }
                            />
                          </div>
                          <div style={{ flex: 1 }}>
                            <InputRow
                              label="ROE"
                              rowClass="formRow"
                              labelClass="formLabel"
                              input={
                                <>
                                  <MInput
                                    name="roe"
                                    {...percentFieldProps}
                                    value={values.roe}
                                  />
                                </>
                              }
                            />
                          </div>
                        </FlexSpaceBetween>
                        <FlexSpaceBetween style={{ gap: 6 }}>
                          <div style={{ flex: 1 }}>
                            <InputRow
                              label="ROCE"
                              rowClass="formRow"
                              labelClass="formLabel"
                              input={
                                <>
                                  <MInput
                                    name="roce"
                                    {...percentFieldProps}
                                    value={values.roce}
                                  />
                                </>
                              }
                            />
                          </div>
                          <div style={{ flex: 1 }}>
                            <InputRow
                              label="Debt Equity Ratio"
                              rowClass="formRow"
                              labelClass="formLabel"
                              input={
                                <>
                                  <MInput
                                    placeholder="Enter Here"
                                    name="debt_ratio"
                                    value={values.debt_ratio}
                                  />
                                </>
                              }
                            />
                          </div>
                        </FlexSpaceBetween>
                        <FlexSpaceBetween style={{ gap: 6 }}>
                          <div style={{ flex: 1 }}>
                            <InputRow
                              label="Current Ratio"
                              rowClass="formRow"
                              labelClass="formLabel"
                              input={
                                <>
                                  <MInput
                                    placeholder="Enter Here"
                                    name="current_ratio"
                                    value={values.current_ratio}
                                  />
                                </>
                              }
                            />
                          </div>
                          <div style={{ flex: 1.5 }}></div>
                        </FlexSpaceBetween>
                      </RoundedCard>
                      <div>
                        <RoundedButtonBase
                          // onClick={(values)=>{this.onSubmitForm(values)}}
                          type="submit"
                          variant="contained"
                          disabled={this.state.isdisabled}
                        >
                          Save
                        </RoundedButtonBase>
                      </div>
                    </div>
                    {/* </Formik> */}
                  </DIV>
                </div>
              )}
              <Modal open={this.state.mapVisible} onClose={this.handleCloseMap}>
                <MapContainer
                  setCity={setFieldValue}
                  city={values.business_city}
                  handleCloseMap={this.handleMapClick}
                />
              </Modal>
            </Form>
          )}
        </Formik>
      </DashboardLayout>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
CorporateProfileFormController.contextType = UserContext;

const SelectItemUserText = styled("span")({
  overflow: "hidden",
  textOverflow: "ellipsis",
});

const DIV = styled("div")({
  "@media (max-width: 768px)": {
    padding: "0px 20px",
  },
});

const RightFormWrapper = styled(Form)({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "25px",
  padding: "10px 0",
  overflowX: "hidden",
  paddingRight: "10px",
  // maxHeight: '500px'
});

const CloseIconButton = styled(IconButton)({
  background: "#676767",
  color: "#fcfcfc",
  width: "32px",
  height: "32px",
  "&:hover": {
    background: "#999",
    opacity: 0.9,
  },
});

const FlexSpaceBetween = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "10px 0",
  // paddingBottom: '0',
  width: "100%",
  "@media screen and (max-width:600px)": {
    flexDirection: "column",
    alignItems: "normal",
  },
  "@media screen and (min-width:769px) and (max-width:1200px)": {
    flexDirection: "column",
    alignItems: "normal",
  },
});
const RoundedCardBorder = styled(Card)({
  borderRadius: "15px",
  padding: "10px 20px",
  margin: "20px 0px",
  border: "1px solid grey",
});

const CoinsHead = styled("div")({
  margin: "40px 0px 15px 12px",
  fontSize: "1.2rem",
  fontWeight: 600,
  "@media screen and (max-width: 768px)": {
    margin: "20px 0px 10px 12px",
  },
});

const RoundedCard = styled(Card)({
  borderRadius: "15px",
  padding: "10px 20px",
  margin: "20px 0px",
});

const RoundedButtonBase = styled(Button)({
  backgroundColor: "#009c05",
  color: "white",
  borderRadius: "5px",
  padding: "10px 40px",
  float: "right",
  "&:hover": {
    backgroundColor: "#009c05",
  },
  "@media screen and (max-width:600px)": {
    width: "100%",
  },
  "@media screen and (min-width:769px) and (max-width:1200px)": {
    width: "100%",
  },
});
const RoundedButtonBaseCer = styled(Button)({
  backgroundColor: "#009c05",
  color: "white",
  borderRadius: "5px",
  padding: "10px 40px",
  marginBottom: "20px",
  "&:hover": {
    backgroundColor: "#009c05",
  },
  "@media screen and (max-width:600px)": {
    width: "100%",
  },
  "@media screen and (min-width:769px) and (max-width:1200px)": {
    width: "100%",
  },
});
const PopupWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
});

const PopupText = styled("div")({
  padding: "20px 40px",
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.04)",
  },
});

const RoundedButtonMore = styled(Button)({
  backgroundColor: "#009c05",
  color: "white",
  borderRadius: "50px",
  padding: "5px 20px",
  float: "left",
  "&:hover": {
    backgroundColor: "#009c05",
  },
  "@media screen and (max-width:600px)": {
    width: "100%",
  },
  "@media screen and (min-width:769px) and (max-width:1200px)": {
    width: "100%",
  },
});

const FilterDiv = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingLeft: "10px",
  paddingRight: "10px",
  background: "#fff",
  width: "230px",
  height: "40px",
  borderRadius: "10px",
  border: "0.1rem solid #c2c0c0 ",
  color: "#a29c9c",
  textOverflow: "hidden",
});

const UploadButton = styled("div")({
  border: "2px solid #009c05",
  color: "#009c05",
  borderRadius: "50px",
  padding: "12px 10px",
  width: "fit-content",
  cursor: "pointer",
});

// Customizable Area End

import React from "react";
// Customizable Area Start
import DashboardLayout from "../../../../components/src/DashboardLayout.web";
import {
    Card,
    Button,
    Typography,
    TextField,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableCell,
    TableRow,
    Paper,
    TablePagination,
    Tooltip,
    Avatar
} from '@material-ui/core';
import {
    toolTipImg, deleteImg, editImg,
} from '../assets'
import { styled } from '@material-ui/styles'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import UserContext from '../../../../components/src/UserProvider';
import { iconAdd } from '../../../JobListing2/src/assets';
// Customizable Area End

import BaselineReportingController, {
    Props
} from "./BaselineReportingController";


export default class BaselineReporting extends BaselineReportingController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { navigation } = this.props;
        const { reportingData } = this.state;

        const rows = [
            this.createData(1, 'Demo Policy', 'Test Description', 'Demo', 'Demo user', '17/10/2022  08:10 PM'),
            this.createData(2, 'Demo Policy', 'Test Description', 'Demo', 'Demo user', '17/10/2022  08:10 PM'),
            this.createData(3, 'Demo Policy', 'Test Description', 'Demo', 'Demo user', '17/10/2022  08:10 PM'),
            this.createData(4, 'Demo Policy', 'Test Description', 'Demo', 'Demo user', '17/10/2022  08:10 PM'),
            this.createData(5, 'Demo Policy', 'Test Description', 'Demo', 'Demo user', '17/10/2022  08:10 PM'),
            this.createData(6, 'Demo Policy', 'Test Description', 'Demo', 'Demo user', '17/10/2022  08:10 PM'),
            this.createData(7, 'Demo Policy', 'Test Description', 'Demo', 'Demo user', '17/10/2022  08:10 PM'),
            this.createData(8, 'Demo Policy', 'Test Description', 'Demo', 'Demo user', '17/10/2022  08:10 PM'),
            this.createData(9, 'Demo Policy', 'Test Description', 'Demo', 'Demo user', '17/10/2022  08:10 PM'),
            this.createData(10, 'Demo Policy', 'Test Description', 'Demo', 'Demo user', '17/10/2022  08:10 PM'),
        ]
        // Customizable Area End
        return (
            // Customizable Area Start
            <DashboardLayout navigation={navigation} >
                {/* <DeleteModal label="Delete this Report?" onClose={this.closeDeleteModal} onSubmit={this.handleDeleteTemplate(reportingData?.id)} />
                   <DeleteModal label="Delete this Report?" onClose={this.closeModal} /> */}
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <CoinsHead>
                        <ArrowBackIosIcon />
                        <Typography variant='h5' style={{ fontWeight: 'bold', color: 'black' }} >Reference Policies</Typography>
                    </CoinsHead>
                    <RoundedButtonBase
                        onClick={() => navigation.navigate('NewPolicy')}
                        variant="contained"
                        disableElevation
                        startIcon={<img src={iconAdd} width={30} style={{ marginLeft: '-5px' }} />}>
                        New Policy
                    </RoundedButtonBase>
                </div>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ fontWeight: 'bold', color: 'black' }}>Sl No.</TableCell>
                                <TableCell style={{ fontWeight: 'bold', color: 'black' }}>Policy Name</TableCell>
                                <TableCell style={{ fontWeight: 'bold', color: 'black' }}>Description</TableCell>
                                <TableCell style={{ fontWeight: 'bold', color: 'black' }}>Attachments</TableCell>
                                <TableCell style={{ fontWeight: 'bold', color: 'black' }}>Created By</TableCell>
                                <TableCell style={{ fontWeight: 'bold', color: 'black' }}>Last Updated</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow>
                                    <TableCell>{row.SlNo}</TableCell>
                                    <TableCell>{row.Policy_Name}</TableCell>
                                    <TableCell>{row.Description}
                                        <Tooltip title={row.Description}>
                                            {<BtnImg src={toolTipImg} style={{ marginLeft: '10px' }} />}
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>{row.Attachments}</TableCell>
                                    <TableCell style={{ display: 'flex' }}><Avatar style={{ width: 25.26, height: 25.87, marginRight: '10px' }} src={''} onClick={() => navigation.navigate(``)} />{row.Created_By}</TableCell>
                                    <TableCell>{row.Last_Updated}</TableCell>
                                    <TableCell>{<BtnImg src={editImg} />}</TableCell>
                                    <TableCell>{<BtnImg src={deleteImg}/>}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DashboardLayout>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

BaselineReportingController.contextType = UserContext

// ************* small components (only view, don't add controller logic here ) **********//
const PromotedCard = () => (<RoundedCard style={{ padding: 0 }} variant="outlined">

</RoundedCard>)


//*********** styles ***************//
const TextFieldRounded = styled(TextField)({
    width: '100%',
    background: '#FFFFFF',
    borderRadius: '15px',
    '& > .MuiOutlinedInput-root': {
        borderRadius: '15px',
    },
    '& > div > input': {
        paddingTop: '14px',
        paddingBottom: '14px'
    }
})

const PageWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    minHeight: '100vh',
    width: '100%',
    '@media screen and (max-width:600px)': {
        padding: '0 15px',
        width: '94.4%',
    },
    '@media screen and (max-width:400px)': {
        padding: '0 10px',
        width: '94.4%',
    },
    '@media screen and (min-width:601px) and (max-width:768px)': {
        padding: '0 15px',
        width: '96%',
    }
})


const BtnImg = styled('img')({
    width: 25
})

const RoundedButtonBase = styled(Button)({
    //width: '100%',
    //display: 'flex',
    //justifyContent: 'space-between',
    backgroundColor: '#009c05',
    color: 'white',
    borderRadius: '20px',
    '&:hover': {
        backgroundColor: '#009c05',
    }
})

const CenterRow = styled('div')(({ theme }) => ({
    // maxWidth: '60vw',
    width: '100%',
    marginTop: '60px',
    '@media screen and (max-width:768px)': {
        marginTop: '20px'
    },
}))

const FlexSpaceBetween = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 0',
    paddingBottom: '0',
    width: '100%',
    '@media screen and (max-width:600px)': {
        flexDirection: 'column',
        alignItems: 'normal',
    },
    '@media screen and (min-width:769px) and (max-width:1200px)': {
        flexDirection: 'column',
        alignItems: 'normal',
    }
})

const FlexSpaceBetweenN = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 0',
    paddingBottom: '0',
    width: '100%',
})


const RoundedCard = styled(Card)({
    borderRadius: '15px',
    padding: '10px 20px',
    margin: '20px 0px'
})

const TimeStamp = styled('div')({
    display: 'flex',
    gap: '35px',
    margin: '0 0 10px 0',
    '@media screen and (max-width:450px)': {
        gap: '0px',
        flexDirection: 'column'
    },
    '@media screen and (min-width:769px) and (max-width:1200px)': {
        gap: '0px',
        flexDirection: 'column'
    }
})

const CoinsHead = styled('div')({
    display: 'flex',
    alignItems: 'center',
    color: 'gray',
    margin: '40px 0px 30px 12px',
    fontSize: '1rem',
    fontFamily: 'HelveticaNeue',
    fontWeight: 500,
    '@media screen and (max-width: 768px)': {
        margin: '20px 0px 10px 12px',
    },
})

const CardButtonWrapper = styled('div')({
    display: 'flex',
    gap: '5px',
    padding: '0 20px',
    flexWrap: 'wrap'
})

// Customizable Area End

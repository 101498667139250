import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { sendAPIRequest } from '../../../components/src/utils'
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    // Customizable Area Start
    token: string;
    projectID: string | null;
    auditTrailData: any;
    total_pages: number | undefined;
    pages: number;
    // Customizable Area End
  }

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class AuditTrailController extends BlockComponent<
  Props,
  S,
  SS
> {

    // Customizable Area Start
    auditTrailCallId: string = "";
    strone: string = "";
    strtwo: string="";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
    
        this.subScribedMessages = [
          getName(MessageEnum.AccoutLoginSuccess),
          // Customizable Area Start
          getName(MessageEnum.RestAPIResponceMessage),
          getName(MessageEnum.GetAllCategoriesResponseMessage),
          getName(MessageEnum.GetAllSubCategoriesResponseMessage),
          // Customizable Area End
        ];
    
        this.state = {
          txtInputValue: "",
          txtSavedValue: "A",
          enableField: false,
          // Customizable Area Start
          token: '',
          projectID : "",
          auditTrailData: [],
          total_pages: undefined,
          pages: 1,
          // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    
        // Customizable Area Start
        // Customizable Area End
    
      }

      async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        let authToken = await getStorageData("authToken")
        this.setState({token: authToken}, () => {
        this.getAuditTrail()
        })
        // Customizable Area End
      }

      async receive(from: string, message: Message) {

        runEngine.debugLog("Message Recived", message);
    
        // Customizable Area Start
        this.getResponse(message)
        // Customizable Area End
    
      }

      // Customizable Area Start
      getResponse(message: any) {
        if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
          return;
        }
      
        const apiRequestId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
      
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
      
        switch (apiRequestId) {
          case this.auditTrailCallId:
            if (responseJson) {
              this.setState({ auditTrailData: responseJson?.audit_trails});
              const total_pages = responseJson.meta?.pagination?.total_pages
            this.setState({ total_pages})
            }
            break;
          
            case this.strone:
              break;
            case this.strtwo:
              break;
            
          
          
          
          default:
            break;
        }
      }

      getAuditTrail = async() =>{
        const authToken = await getStorageData("authToken")
        const id = this.props.navigation.getParam('id')
        console.log(`${configJSON.getAuditTrailEndPoint}page=${this.state.pages}&per_page=10&project_id=${id}`, "Audit Url =")
        if (id) {
          this.auditTrailCallId = sendAPIRequest(`${configJSON.getAuditTrailEndPoint}page=${this.state.pages}&per_page=10&project_id=${id}`, {
            method: 'GET',
            headers: { 'token': authToken }
          })
        }
      }

      handlePageChange = (event: any, value: any) => {
        this.setState({ pages: value }, this.getAuditTrail)
      }

      goBack = () => { this.props.navigation.goBack() }

      // Customizable Area End
     
}


import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'

const THRESHOLD = 5 * 60 * 1000 // 5min, expire token before it has expired
const CHECK_WAIT_TIME = 60 * 1000 // 1min, check regularly if token has expired

function logoutLocalStorage(){
	localStorage.removeItem('authToken')
}

function checkTokenAge(){
	const authToken = localStorage.getItem('authToken')
	if(!authToken) return false
	const jwt = JSON.parse(atob(authToken.split('.')[1]))
	const expiryTime = new Date(jwt.exp * 1000)
	const currentTime = new Date()
	currentTime.setSeconds(currentTime.getSeconds() + THRESHOLD)
	if(currentTime>expiryTime) {
		logoutLocalStorage()
		return false
	}
	return true
}

function useCheckToken(){
	const [expired, setExpired] = useState(checkTokenAge())
	useEffect(() => {
		const intervalId = setInterval(() => {
			if(expired) {
				logoutLocalStorage()
				return ;
			}
			const exp = checkTokenAge()
			if(expired!==exp)
				setExpired(exp)
		}, CHECK_WAIT_TIME)
		return () => clearInterval(intervalId)
	}, [expired])
	return expired
}

const publicRoutes = [
	"/",
	"/LinkedinRedirect",
	"/ForgotPasswordWeb",
	"/EmailAccountRegistrationWeb",
	"/OTPInputAuth",
	"/ForgotPassword",
	"/ForgotPasswordOTP",
	"/NewPassword",
	"/EmailAccountRegistration",
	"/MicrosoftLogin3",
	"/NewPasswordWeb",
	"/ChangePasswordWeb",
	"/ForgotPasswordOTPWeb",
]

const AuthCheckWrapper = (props: any) => {
	// const expired = useCheckToken()
	const isPublicRoute = publicRoutes.includes(props.location.pathname)
	
	// @todo update it later, currently this is not working as expected
	// if(!isPublicRoute && expired) {
	// 	props.history.push("/")
	// 	toast.warn("Your session has expired. Login again.")
	// 	return <>Loading</>
	// }

	return props.children
}

export default withRouter(AuthCheckWrapper)
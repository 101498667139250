import React from "react";
// Customizable Area Start
import TopNavBar from "../../../components/src/TopNavbar";
import {
  Box, Grid, Typography,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio, TableContainer,
  TextField,
  InputAdornment,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody, Popover
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { styled } from '@material-ui/styles'
import LeftMenuWeb from "../../../components/src/LeftMenuWeb";
import { SearchIcon, EditIcon, VerifiedIcon, UnverifiedIcon, PdfIcon } from './assets';
import { Formik, Field } from 'formik'
import FormModal, {
  InputRow,
  MInput,
} from '../../../components/src/FormModal.web'
import OverlayLoader from '../../../components/src/Loader.web'

import AssesmentDashboardCOntroller, {
  Props,
} from "./AssesmentDashboardController";
// @ts-ignore
import classes from './AssesmentWeb.module.css'
import UserContext from '../../../components/src/UserProvider'
// Customizable Area End
export default class SelfAssesment extends AssesmentDashboardCOntroller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  editSurveyAssesment() {
    const initialValues = {
      survey_name: this.state?.editData?.survey_name,
      assesment_date: this.state?.editData?.assesment_date,
      score: this.state?.editData?.score,
      assessment_by: this.state?.editData?.assessment_by,
      approved_by: this.state?.editData?.approved_by == null ? '' : this.state?.editData?.approved_by,
      verify_to: this.state?.editData?.verify_to,
      status: this.state?.editData?.status,
      verified_by: this.state?.editData?.verified_by == null ? '' : this.state?.editData?.verified_by,
      comments: this.state?.editData?.comments == null ? '' : this.state?.editData?.comments,
    }

    if (this.state.modal !== 'create') return <></>
    else
      return <Formik enableReinitialize initialValues={initialValues} onSubmit={this.handleSubmit} validationSchema={this.validationSchema} >
        {({ values, errors }: any) => (
          <div className={classes.modalTopCls}>
            {console.log("chcek values ", values)}
            <FormModal headerTitle={<CreateHeader>EDIT SELF ASSESSMENT DATA</CreateHeader>} submitLabel="SAVE" onClose={() => this.setState({ modal: '' })} >
              <InputRow label="Frequency*" input={<MInput style={{ background: '#ddd7d7bf' }} disabled name="frequenct" value={"Quaterly"} />} />
              <InputRow label="Self Assessment Survey Name*" input={<><MInput style={{ background: '#ddd7d7bf' }} disabled name="survey_name" /></>} />
              <InputRow label="Self Assessment Date*" input={<><MInput style={{ background: '#ddd7d7bf' }} disabled name="assesment_date" /></>} />
              <InputRow label="Self Assessment Score*" input={<><MInput style={{ background: '#ddd7d7bf' }} disabled name="score" /></>} />
              <InputRow label="Self Assessment By*" input={<><MInput style={{ background: '#ddd7d7bf' }} disabled name="assessment_by" /></>} />
              <InputRow label="Approved By*" input={<><MInput onKeyPress={this.allowOnlyCharacters} name="approved_by" /></>} />
              <InputRow label="Want to verify*" input={
                <Field
                  render={({ field }: any) => <><RadioGroup {...field} aria-label="quiz"
                    name="verify_to"
                    value={Number(values?.verify_to)}
                    style={{ flexDirection: 'row' }}
                  // onChange={this.handleChange}
                  >
                    <FormControlLabel style={{ marginLeft: '0', marginRight: '10' }} value={1} control={<Radio style={{ color: 'gray' }} />} label="Yes" />
                    <FormControlLabel style={{ marginLeft: '0', marginRight: '10' }} value={0} control={<Radio style={{ color: 'gray' }} />} label="No" />
                  </RadioGroup>
                  </>
                  }
                />
              } />
              <InputRow label="Status*" input={
                <Field
                  render={({ field }: any) => <><RadioGroup {...field} aria-label="quiz"
                    name="status"
                    value={Number(values?.verify_to) == 0 ? 0 : Number(values?.status)}
                    style={{ flexDirection: 'row' }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <FormControlLabel style={{ marginLeft: '0', marginRight: '10' }} value={1} control={<Radio disabled={Number(values?.verify_to) == 0} style={{ color: 'gray' }} />} label="" />
                      <img src={VerifiedIcon} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <FormControlLabel style={{ marginLeft: '0', marginRight: '10' }} value={0} control={<Radio disabled={Number(values?.verify_to) == 0} style={{ color: 'gray' }} />} label="" />
                      <img src={UnverifiedIcon} width="90px" />
                    </div>
                  </RadioGroup>
                  </>
                  }
                />
              } />
              <InputRow label="Verified By*" input={<><MInput disabled={values.verify_to != 1} onKeyPress={this.allowOnlyCharacters} name="verified_by" /></>} />
              <InputRow label="Comment*" input={<><MInput name="comments" /></>} />

            </FormModal>
          </div>
        )}
      </Formik>
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const editAssesment = this.editSurveyAssesment();
    const path = this?.context?.user_type == "licensed_user" ? 'Corporatepermission' : 'ESGJourney'
    // Customizable Area End
    return (
      // Customizable Area Start
      <MainBox>
        {editAssesment}
        <Box>
          <TopNavBar navigation={this.props.navigation} id={""} />
        </Box>

        <OuterGrid container spacing={3}>
          <LeftGrid item xs={12} sm={12} md={3} lg={3}>
            <Box >
              <LeftMenuWeb navigation={this.props.navigation} id={""} />
            </Box>
          </LeftGrid>
          <MiddleGrid item xs={12} sm={12} md={6} lg={6} >
            <SurveyCardWrapper>
              <OverlayLoader loading={this.state.loading} />
              <HeadWrapper>
                <div style={{ display: "flex", alignItems: "center" }} >
                  <span id="navigation-esg" onClick={() => this.props.navigation.navigate(path)} style={{ margin: "0px 0px 20px 12px", cursor: "pointer" }} >ESG Journey</span>
                  <div id="navigation-path" onClick={() => this.props.navigation.navigate(path)} style={{ margin: "0px 0px 20px 12px", }}  >
                    <ArrowBackIosIcon />
                  </div>
                  <Head>
                    Self Assessment Dashboard
                  </Head>
                </div>
              </HeadWrapper>
              <FilderWrapper>
                <SearchContainer>
                  <CustomTextField
                    size="small"
                    variant="outlined"
                    InputProps={{
                      startAdornment: (<InputAdornment position="start">
                        <img src={SearchIcon} width={20} />
                      </InputAdornment>
                      ),
                    }}
                    placeholder="Search"
                    id="text-field"
                    onChange={(e) => this.handleSearch(e)}
                    style={{ width: '260' }}
                  />
                </SearchContainer>
                <FilterDiv
                  id="filter_div"
                  onClick={(event) => this.openMenu(event)}
                >
                 <span>Filter by: {typeof this.state?.filterName === 'string' ? this.state.filterName.substring(0, 15) : ''}</span>
                  <ExpandMoreIcon />
                </FilterDiv>
                <Popover
                  data-test-id ="popover"
                  id={Boolean(this.state.anchorElForPopup) ? 'simple-popover' : undefined}
                  open={Boolean(this.state.anchorElForPopup)}
                  anchorEl={this.state.anchorElForPopup}
                  onClose={(event: React.MouseEvent<HTMLButtonElement>) => this.setState({ ...this.state, anchorElForPopup: null })}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  style={{ marginTop: '10px' }}
                >
                  <PopupWrapper>
                    <PopupText
                      aria-describedby={Boolean(this.state.anchorElForPopupNested) ? 'nested-popover' : undefined}
                      onClick={this.onInitialized}
                    >
                      All
                    </PopupText>
                    <PopupText
                      id="nested-menu"
                      aria-describedby={Boolean(this.state.anchorElForPopupNested) ? 'nested-popover' : undefined}
                      onClick={(event) => this.openNestedMenu(event)}
                    >
                      {"By Self Assessment Name >"}
                    </PopupText>
                    <PopupText
                      id="nested-menu-date"
                      aria-describedby={Boolean(this.state.anchorElForPopupDate) ? 'date-popover' : undefined}
                      onClick={(event) => this.openNestedDateMenu(event)}
                    >
                      {"By Self Assessment Date >"}
                    </PopupText>
                  </PopupWrapper>
                </Popover>
                <Popover
                  id={Boolean(this.state.anchorElForPopupNested) ? 'nested-popover' : undefined}
                  open={Boolean(this.state.anchorElForPopupNested)}
                  anchorEl={this.state.anchorElForPopupNested}
                  data-test-id = "popover-2"
                  onClose={(event: React.MouseEvent<HTMLButtonElement>) => this.setState({ ...this.state, anchorElForPopupNested: null })}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  style={{ marginTop: '10px' }}
                >
                  <PopupWrapper>
                    <PopupText id="strategy-assess" onClick={() => this.handleFilterByAssesmentName("Strategy Assessment")} >{"Strategy Assessment"}</PopupText>
                    <PopupText id="risk-management-assess" onClick={() => this.handleFilterByAssesmentName("Risk Management Assessment")} >{"Risk Management Ass.."}</PopupText>
                    <PopupText id="e-assess" onClick={() => this.handleFilterByAssesmentName("E Assessment")} >{"E Assessment "}</PopupText>
                    <PopupText id="s-assess" onClick={() => this.handleFilterByAssesmentName("S Assessment")} >{"S Assessment"}</PopupText>
                    <PopupText id="g-assess" onClick={() => this.handleFilterByAssesmentName("G Assessment")} >{"G Assessment"}</PopupText>
                    <PopupText id="stakeholed-mat" onClick={() => this.handleFilterByAssesmentName("Stakeholder Materiality")} >{"Stakeholder Materiality"}</PopupText>
                  </PopupWrapper>
                </Popover>
                <Popover
                  id={Boolean(this.state.anchorElForPopupDate) ? 'date-popover' : undefined}
                  open={Boolean(this.state.anchorElForPopupDate)}
                  anchorEl={this.state.anchorElForPopupDate}
                  onClose={(event: React.MouseEvent<HTMLButtonElement>) => this.setState({ ...this.state, anchorElForPopupDate: null })}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  style={{ marginTop: '10px' }}
                >
                  <PopupWrapper>
                    <PopupText>Choose Date</PopupText>
                    <PopupText >
                      <StartDateContainer >
                        <Typography>From</Typography>
                        <DatePicker
                          clearable
                          inputVariant="outlined"
                          maxDate={this.state.endDate || new Date()}
                          format="dd-MM-yyyy"
                          placeholder="Select"
                          value={this.state.startDate}
                          onChange={this.handleStartDate} />
                      </StartDateContainer>
                    </PopupText>
                    <PopupText >
                      <EndDateContainer style={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }}>
                        <Typography>To</Typography>
                        <DatePicker
                          clearable
                          inputVariant="outlined"
                          minDate={this.state.startDate}
                          maxDate={new Date()}
                          format="dd-MM-yyyy"
                          placeholder="Select"
                          value={this.state.endDate}
                          onChange={this.handleEndDate} />
                      </EndDateContainer>
                    </PopupText>
                    <PopupText>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button style={{ background: 'gray', color: 'white', marginRight: '5px' }} onClick={() => this.setState({ anchorElForPopupDate: null, startDate: null, endDate: null })}>Cancel</Button>
                        <Button style={{ background: 'green', color: 'white' }} onClick={this.filterDate} >Submit</Button>
                      </div>
                    </PopupText>
                  </PopupWrapper>
                </Popover>

              </FilderWrapper>
              <TableContainer>
                <Table style={{ width: '100%', background: '#fff', overflowX: 'hidden' }}>
                  <TableHead>
                    <TableRow>
                      <TH>S.No</TH>
                      <TH>Frequency</TH>
                      <TH>Self Assessment Survey Name</TH>
                      <TH>Self Assessment Date</TH>
                      <TH>Self Assessment Score</TH>
                      <TH>Self Assessment By</TH>
                      <TH>Approved By</TH>
                      <TH>Want to verify</TH>
                      <TH>Status</TH>
                      <TH>Verified by</TH>
                      <TH>Comment</TH>
                      <TH>Attachment</TH>
                      <THLast></THLast>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this?.state?.assesments?.map((data: any, i: number) => {
                      return (
                        <TableRow>
                          <TR>{i + 1}</TR>
                          <TR>Quaterly</TR>
                          <TR>{data?.survey_name}</TR>
                          <TR>{data?.assesment_date}</TR>
                          <TR>{data?.score}</TR>
                          <TR>{data?.assessment_by}</TR>
                          <TR>{data?.approved_by?.substring(0, 20)}</TR>
                          <TR>{data?.verify_to == 1 ? "Yes" : "No"}</TR>
                          <TR>{data?.status == 1 ? <img src={VerifiedIcon} /> : <img src={UnverifiedIcon} width="90px" />}</TR>
                          <TR>{data?.verified_by?.substring(0, 20)}</TR>
                          <TR>{data?.comments?.substring(0, 20)}</TR>
                          <TR><img width="25px" src={PdfIcon} onClick={() => window.open(data?.response_pdf)} /></TR>
                          <TRLast><img src={EditIcon} width="20px" onClick={() => this.handleEdit(data?.response_id)} /></TRLast>
                        </TableRow>)
                    })
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            </SurveyCardWrapper>
            {(this.state?.assesments?.length == 0 && !this.state?.loading) &&
              <div style={{ width: '100%', display: 'flex', justifyContent: 'center', fontSize: '25px', marginTop: '25px' }}>No Result Found</div>}
          </MiddleGrid>
        </OuterGrid>

      </MainBox>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
AssesmentDashboardCOntroller.contextType = UserContext


const MainBox = styled(Box)({
  backgroundColor: "#edebeb",
  display: "flex",
  flexWrap: 'wrap',
  flexDirection: "column",
  paddingBottom: "20px",
  overflowX: "hidden",
})

const OuterGrid = styled(Grid)({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
})

const LeftGrid = styled(Grid)({
  maxWidth: '300px',
  '@media screen and (max-width: 985px)': {
    maxWidth: '200px',
  },
  '@media screen and (max-width: 768px)': {
    display: 'none',
    visibility: 'hidden',
  },
})

const MiddleGrid = styled(Grid)({
  width: 'calc(100vw - 300px)',
  maxWidth: 'unset',
  flexBasis: 'unset',
  justifyContent: 'center',
  '@media screen and (max-width: 768px)': {
    width: '100%'
  }
})

const SurveyCardWrapper = styled(Box)({
  width: '95%',
  height: 'auto',
  marginTop: '20px',
  marginRight: '20px',
  padding: '20px',
})

const FilderWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '15px',
})

const CreateHeader = styled('div')({
  fontSize: '24px',
  fontWeight: 500,
  fontFamily: 'HelveticaNeue'
})

const HeadWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%'
})

const PopupWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
})

const PopupText = styled('div')({
  cursor: 'pointer',
  padding: '20px 18px',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
})

const FilterDiv = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingLeft: '10px',
  paddingRight: '10px',
  background: '#fff',
  width: '230px',
  height: '40px',
  borderRadius: '10px',
  border: '0.1rem solid #c2c0c0 ',
  color: '#a29c9c',
  textOverflow: 'hidden'
})

const TH = styled(TableCell)({
  color: "#000",
  fontWeight: 600,
  lineHeight: "1.5rem",
  fontSize: '0.85rem',
  padding: '16px 10px',
  textAlign: 'center',
  position: 'relative',
  borderRight: '1px solid #c5c0c0',
})

const THLast = styled(TableCell)({
  color: "#000",
  fontWeight: 600,
  lineHeight: "1.5rem",
  fontSize: '0.85rem',
  padding: '16px 10px',
  textAlign: 'center',
  position: 'relative',
})

const TR = styled(TableCell)({
  color: "#373232;",
  lineHeight: "1.5rem",
  fontSize: '0.85rem',
  padding: '16px 10px',
  textAlign: 'center',
  position: 'relative',
  borderRight: '1px solid #c5c0c0',
})

const TRLast = styled(TableCell)({
  color: "#000",
  lineHeight: "1.5rem",
  fontSize: '0.85rem',
  padding: '16px 10px',
  textAlign: 'center',
  position: 'relative',
})

const CustomTextField = styled(TextField)({

  '& fieldset': {
    border: '1px solid #c2c0c0',
    borderRadius: '12px',
    height: '45px',
  },
})



const Head = styled('div')({
  display: 'flex',
  alignItems: 'center',
  color: '#000',
  margin: '10px 0px 30px 12px',
  fontSize: '1rem',
  fontFamily: 'HelveticaNeue',
  fontWeight: 600,
  '@media screen and (max-width: 768px)': {
    margin: '20px 0px 10px 12px',
  },
})
const SearchContainer = styled('div')({
  width: '260px', 
  height: '42px',
  background: '#fff', 
  borderRadius: '15px'
})
const StartDateContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row', 
  gap: '10px'
})

const EndDateContainer = styled('div')({
  display: 'flex', 
  justifyContent:'space-between', 
  gap: '10px'
})



// Customizable Area End

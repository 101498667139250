Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CfCourseListing5";
exports.labelBodyText = "CfCourseListing5 Body";
exports.courseCreateEndpoint = "bx_block_cfcourselisting5/courses"
exports.courseListEndpoint = "bx_block_cfcourselisting5/my_courses"
exports.courseMyListEndpoint = "bx_block_cfcourselisting5/courses"
exports.courseEnquireEndpoint = "bx_block_cfcourselisting5/course_enquiries"
exports.courseFilterAPIEndpoint = "bx_block_cfcourselisting5/courses/filter"
exports.setCourseFavEndpoint = "bx_block_favourites/favourites"
exports.setCourseUnFavEndpoint = "bx_block_favourites/favourites/:id"
exports.getUserRoleEndpoint = "account_block/accounts/get_user_role"
exports.generateCourseIDEndpoint = "bx_block_cfcourselisting5/generate_course_id"
exports.getCourseDataByIDEndpoint = "bx_block_cfcourselisting5/courses/:cid"
exports.getAllCourseCommentEndpoint = "bx_block_cfcourselisting5/comments/?course_id=:cid"
exports.courseCommentEndpoint = "bx_block_cfcourselisting5/comments"
exports.courseLikeEndpoint = "bx_block_like/likes"
exports.courseCommentReplyEndpoint = "bx_block_posts/comments/create_reply"
exports.courseDeleteEndpoint = "bx_block_cfcourselisting5/courses/:id"
exports.courseEditEndpoint = "bx_block_cfcourselisting5/courses/:id"
exports.btnExampleTitle = "CLICK ME";
// Customizable Area End
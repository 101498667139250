import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { sendAPIRequest } from '../../../components/src/utils'
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { sendMarkAllAsRead, sendMarkRead } from './AllNotificationsController'
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  anchorEl: any | null;
  token?: string;
  unread_notifications?: number;
  notifications: any[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NotificationBellController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  allNotificationsCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NewNotificationMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      anchorEl: null,
      token: '',
      notifications: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
    }

    if(message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const requestId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
      if(requestId === this.allNotificationsCallId) {
        if(Array.isArray(responseJson.notifications.data)){
          this.setState({ 
            notifications: responseJson.notifications.data.map((n: any) => n.attributes), 
            unread_notifications: responseJson.notifications.meta?.total_unread  
          })
        }
      }
    } else if (message.id === getName(MessageEnum.NewNotificationMessage)) {
      let notification = message.getData(
        getName(MessageEnum.NewNotificationData)
      )
      this.newNotificationAdd(notification)

    }
    // Customizable Area End

  }

  

  // Customizable Area Start
  onBellClick = (e: any) => this.setState({ anchorEl: e.currentTarget })
  handlePopupClose = () => this.setState({ anchorEl: null })
  fetchAllNotifications = () => {
    const { token } = this.state
    this.allNotificationsCallId = sendAPIRequest(configJSON.allNotificationsEndpoint, {
      method: 'GET',
      headers: { token }
    })
  }

  async componentDidMount() {
    let authToken = await getStorageData("authToken")
    this.setState({token: authToken}, () => {
    this.fetchAllNotifications()
  })}

  markAllRead = () => {
    sendMarkAllAsRead(this.state.token || '')
    let notifications = [...this.state.notifications]
    notifications = notifications.map(
        (n: any) => ({ ...n, is_read:true })
      )
    console.log({ notifications })
    this.setState({ notifications, unread_notifications: 0 })
  }

  newNotificationAdd = (notification: any) => {
    let notifications = [...this.state.notifications]
    notifications.splice(0, 0, notification)
    
    this.setState({ 
      notifications, 
      unread_notifications: (this.state.unread_notifications||0)+1 
    })
  }

  handleRead = (e: any) => {
    const id = e.currentTarget.dataset.id

    sendMarkRead(id||'', this.state.token||'')
    const notifications = this.state.notifications.map((n: any) => {
      if(n.id!=id) return n
      return {...n, is_read: true}
    })
    this.setState({ 
      notifications, 
      unread_notifications: Math.max((this.state.unread_notifications||1)-1, 0) 
    })

  }
  // Customizable Area End
  
}

// Customizable Area Start
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const iconLocation = require("../assets/ic_location.png")
export const iconCalendar = require("../assets/ic_calendar.png")
export const iconCorp = require("../assets/ic_corp.png")
export const iconGreenThumb = require("../assets/ic_greenthumb.png")
export const avatarBuilding = require("../assets/avatar_building.png")
export const OyoAvatar = require('../assets/ic_oyo_profile.png')
export const SendIcon = require("../assets/ic_send.png")
export const EmogiIcon = require("../assets/smile.png")
export const LikedIcon = require("../assets/ic_greenthumb.png")
export const CloseIcon = require("../assets/ic_close.png")
export const LogoHex = require('../assets/ic_logohex.png')
// Customizable Area End
export const corpstageCoini = require("../assets/ImagePasted.png")
export const location = require("../assets/ic_green_location.png")
export const MaleIcon = require("../assets/male.png")
export const FemalIcon = require("../assets/female.png")
export const AverageIndicator = require("../assets/meter.png")
export const iconadd = require("../assets/ic_add.png")
export const iconCorpstage = require("../assets/iconImg.png")
export const viewUploadedPicture = require("../assets/viewImg.jpg")
export const ic_greenthumb = require("../assets/ic_greenthumb.png")
export const commentIcon = require("../assets/commentIcon.png")
export const sharIcon = require("../assets/shareIcon.png")
export const likeIcon = require("../assets/likeIcon.png")
export const contributors = require("../assets/contributors.png")
export const collabrators = require("../assets/collabrators.png")
export const loadingIcon = require("../assets/loadingIcon.png")
export const icon1 = require("../assets/icon1.png")
export const icon2 = require("../assets/icon2.png")
export const icon3 = require("../assets/icon3.png")
export const icon4 = require("../assets/icon4.png")
export const EmogiIcon = require("../assets/smile.png")
export const SendIcon = require("../assets/ic_send.png")
export const LikedIcon = require("../assets/ic_greenthumb.png")
export const thumbIcon =require("../assets/thumb.png")
export const iconLocation = require('../assets/ic_location.png');
export const iconAdd = require('../assets/ic_add.png')
export const iconUpload = require('../assets/ic_upload.png')
export const logoIkea = require('../assets/logo_ikea.png');
export const logoCowi = require('../assets/logo_cowi.png');
export const logoWashington = require('../assets/logo_washington.png');
export const iconUploadBlack = require('../assets/ic_upload_black.png')
export const OyoAvatar = require('../assets/ic_oyo_profile.png')
export const iconThumbGreen = require("../assets/ic_greenthumb.png")
export const LogoHex = require('../assets/ic_logohex.png')
export const CloseIcon = require('../assets/ic_close.png')
export const Group1 = require("../assets/Group.png");
export const ArrowDownIcon = require("../assets/ic_arrow_down.png")
export const editIcon = require("../assets/edit.png")


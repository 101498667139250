import { Button } from '@material-ui/core'
import { styled } from '@material-ui/styles'

const RoundedButton = styled(Button)({
 color: '#009c05',
 border: '2px solid #009c05',
 borderRadius: '25px',
 padding: '10px 20px',
 textTransform: 'none',
 fontWeight: 600
})

export default RoundedButton
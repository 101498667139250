import React from "react";
// Customizable Area Start
import {
    MenuItem,
    Avatar,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import { styled } from '@material-ui/styles';
import { toast } from 'react-toastify';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import PreferenceCatSubCat from '../../categoriessubcategories/src/PreferenceCatSubCat.web'
import OverlayLoader from '../../../components/src/Loader.web'
import { Formik } from 'formik'
import FormModal, { InputRow, MInput, MSelect, MCountedTextArea } from '../../../components/src/FormModal.web'
import UserContext from "../../../components/src/UserProvider";
import UploadButtonInput from '../../../components/src/UploadButtonnew.web'
import CreateRequestToListController, { Props } from "./RequestToListController";
//@ts-ignore
import Maps from '../../maps/src/Maps.web';
// Customizable Area End

export default class CreateRequestToList extends CreateRequestToListController {
    selectedItem: any;
    getuserDetails: any;
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    CreateTemplateModal() {




        let initialValues: any = {
            category: -1,
            preference_ids: [],
            category_ids: [],
            sub_category_ids: [],
            location: '',
            project_unique_id: this.state?.projectId
        }

        return <Formik enableReinitialize initialValues={initialValues} onSubmit={this.handleSubmit} validationSchema={this.validationSchema}


        >
            {({ setFieldValue }: any) => (
                <ModalWrap>
                    <FormModal modalStyle={{ maxWidth: '700px' }}  headerTitle={<b>REQUEST TO LIST YOUR PROJECT FOR FUNDING</b>} submitLabel={"create"} onClose={this.hideModal} >
                        <div style={{
                            display: "flex",
                            padding: " 10px",
                            alignItems: "flex-start"
                        }}
                        >
                            <div style={{ position: "relative" }}>

                                <label htmlFor="upload-button">

                                    <span style={{ display: "flex", cursor: "pointer" }}>
                                        <Avatar style={{
                                            width: "70px",
                                            height: "70px",
                                        }}>
                                            {this.state?.profile_pic_show != '' && <img src={this.state?.profile_pic_show} alt="img" width="100%" height="100%" />}
                                        </Avatar>
                                        <Avatar style={{
                                            position: "absolute",
                                            color: "white",
                                            top: "60%",
                                            left: "60%",
                                            backgroundColor: "#1ea60a",
                                            width: "25px",
                                            height: "25px",
                                            padding: "3px",
                                            border: "1px solid white"
                                        }}>
                                            <BorderColorIcon fontSize="small" />

                                        </Avatar>
                                    </span>
                                </label>
                                <input
                                    type="file"
                                    accept=".png, .jpeg, .jpg"
                                    id="upload-button"
                                    data-test-id="upload-button1"
                                    style={{ display: "none" }}
                                    onChange={(e: any) => {
                                        const file = e.currentTarget?.files[0];

                                        if (!file) return;

                                        const fileSize = file.size;
                                        const fileType = file.type;

                                        if (fileSize > 1048576) {
                                            toast.error("Cannot Upload Picture more than 1 MB");
                                            e.currentTarget.value = null;
                                        } else if (!/image\/(png|jpeg)/.test(fileType)) {
                                            toast.error("image should only be png or jpeg or jpg");
                                        } else {
                                            this.handleFileChange(e);
                                        }
                                    }}
                                />
                            </div>
                            <div style={{ alignSelf: 'center', paddingLeft: '10px' }}>
                                Project Image
                            </div>
                        </div>
                        <InputRow label={<JobLabel>Project Name*</JobLabel>} input={
                            <MInput name="project_name" placeholder="" />
                        } />
                        <InputRow label={<JobLabel>Project ID</JobLabel>} input={
                            <MInput name="project_unique_id" value={this.state?.projectId} disabled placeholder="" />
                        } />

                        <InputRow label={<JobLabel>Description*</JobLabel>} input={
                            <MCountedTextArea length={100} name="description" placeholder="" />
                        } />
                        <InputRow label={<JobLabel>Attachments*</JobLabel>} input={
                            <>
                                <div style={{ margin: '10px 0' }}>
                                    <UploadButtonInput
                                        multiple
                                        name="attachments"
                                        setFileInState={this.setFilesArray}
                                        label="Upload Attachment"
                                    />
                                </div>
                            </>
                        } />



                        {/* @ts-ignore */}
                        <InputRow label={<JobLabel>Location*</JobLabel>} input={<Maps input {...this.props} />} />

                        <InputRow label={<JobLabel>SDG focus*</JobLabel>} data-test-id="sdg_focus" input={
                            <MSelect
                                labelId="select-users-label"
                                name="sdg_focus"
                                data-test-id="select_sdg_focus"
                                multiple
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left",

                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null,
                                    style: { maxHeight: '150px !important;' }
                                }}

                                value={this.state.selectedSDGFactor}
                                onChange={(e) => this.setState({ selectedSDGFactor: e.target.value }, () => {
                                    setFieldValue('sdg_focus', this.state.selectedSDGFactor)
                                })}
                                renderValue={(selected: any) => (
                                    <AvatarGroup max={3}>
                                        {this.state?.sdgFactor?.filter((user: any) => selected.includes(user.id))
                                            .map((user: any) => (
                                                <Avatar key={user.id} alt={user.name} src={user?.attributes?.image} />
                                            ))}
                                    </AvatarGroup>
                                )}
                            >
                                {this.state?.sdgFactor?.map((user: any) => (
                                    <MenuItem key='' value={user?.id}>
                                        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                            <Avatar src={user?.attributes?.image} />
                                            <label htmlFor={`checkbox-${user?.id}`}>
                                                <FormControlLabel
                                                    label={user?.attributes?.goal}
                                                    style={{
                                                        height: "50px",
                                                        padding: "5px 5px 5px 15px",
                                                    }}

                                                    id={`checkbox-${user?.id}`}
                                                    control={
                                                        <></>
                                                    }
                                                />
                                            </label>

                                        </div>
                                        <Checkbox
                                            checked={this.state.selectedSDGFactor.some((item: any) => item === user?.id)}
                                            value={user?.id}
                                        />
                                    </MenuItem>
                                ))}
                            </MSelect>
                        } />

                        <InputRow label={<JobLabel>Organization Name*</JobLabel>} input={
                            <MInput name="organization_name" placeholder="" />
                        } />

                        <InputRow label={<JobLabel>Funding Required (Amount in USD)*</JobLabel>} input={
                            <MInput name="funding_amount" placeholder="" />
                        } />

                        <InputRow label={<JobLabel>Project Leader</JobLabel>} input={
                            <MInput name="project_leader" placeholder="" />
                        } />


                        <PreferenceCatSubCat {...this.props} prefererence />


                        <InputRow label={<JobLabel>Form of Funding*</JobLabel>} input={
                            <MSelect name="form_of_funding" placeholder="Select" >
                                <MenuItem value="Self Funded">Self Funded</MenuItem>
                                <MenuItem value="Self Funded (Reserves)">Self Funded (Reserves)</MenuItem>
                                <MenuItem value="Unsecured Loans">Unsecured Loans</MenuItem>
                                <MenuItem value="Bank Loans (Secured)">Bank Loans (Secured)</MenuItem>
                                <MenuItem value="Bank Loans (Unsecured)">Bank Loans (Unsecured)</MenuItem>
                                <MenuItem value="Angel Investors">Angel Investors</MenuItem>
                                <MenuItem value="Venture Capital">Venture Capital</MenuItem>
                                <MenuItem value="New Equity">New Equity</MenuItem>
                                <MenuItem value="Crypto(ICO)">Crypto(ICO)</MenuItem>
                                <MenuItem value="Grants">Grants</MenuItem>
                                <MenuItem value="Others">Others</MenuItem>
                            </MSelect>
                        } />

                        <InputRow label={<JobLabel>Status*</JobLabel>} input={
                            <MSelect name="status" placeholder="Select"  >
                                <MenuItem value="Open">Open</MenuItem>
                                <MenuItem value="Closed">Closed</MenuItem>
                                <MenuItem value="Funded">Funded</MenuItem>
                            </MSelect>
                        } />
                        <OverlayLoader loading={!!this.state.submittingForm} />
                    </FormModal>
                </ModalWrap>
            )}
        </Formik>
    }

    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            <>
                {this.CreateTemplateModal()}
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

CreateRequestToListController.contextType = UserContext






const JobLabel = styled('div')({
    fontSize: '15.35px',
    color: '#404040',
    fontFamily: 'HelveticaNeue'
})





export const ModalWrap = styled('div')({
})


// Customizable Area End
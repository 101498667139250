import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as yup from 'yup'
import { toast } from 'react-toastify';
import { sendAPIRequest } from '../../../components/src/utils';
import { sendSharePopupMessage } from '../../share/src/Messages';
import { sendEventUpdatedMessage } from './Messages'
import { sendAddRequestMessage } from '../../RequestManagement/src/Messages'
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  openModal: boolean;
  eventData: any;
  eventId: string;
  eventCommentData: any[];
  pagination?: {
    next_page?: number;
  };
  userComment: string;
  userReply: string;
  reply_id: '';
  openCommentEmoji: boolean;
  openCommentReplyEmoji: boolean;
  openReply: '';
  images: any[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CourseDetailController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getEventDetailCallId = '';
  getAllEventCommentCallId = '';
  eventCommentCallId = '';
  interestedEventId: string = "";
  eventLikeCallId = '';
  eventCommentReplyCallId = '';
  getMoreCommentCallId = '';
  apiFavCourseCallId: string[] = []
  favCallIdToCourseId: { [key: string]: string } = {}
  likeCallIdToCommentId: { [key: string]: string } = {}

  async componentDidMount() {
    super.componentDidMount();
    
    let authToken = await getStorageData("authToken")
    if (authToken == null) {
      this.props.navigation.navigate("Home");
    } else {
    }

    this.onInitialized();
  }

  onInitialized = async() => {
    const token=await getStorageData("authToken")
    const event_id = this.props.navigation.getParam('event_id')
    this.getEventDetailCallId = sendAPIRequest(configJSON.getEventDataByIdEndpoint.replace(':eid', event_id), {
      method: 'GET',
      headers: { token }
    })

    this.getAllComments();
  }

  getAllComments = async() => {
    const token=await getStorageData("authToken")
    const event_id = this.props.navigation.getParam('event_id')
    this.getAllEventCommentCallId = sendAPIRequest(configJSON.getAllCommentEndpoint.replace(':eid', event_id), {
      method: 'GET',
      headers: { token }
    })
  }
  onCloseModal = () => {
    this.setState({ openModal: false });
    if(this.props.navigation.history.location.pathname.includes('Events2')){
      this.props.navigation.history.push(`/MyMarketplace/Events2`,{comment:true});
    }
    else{
      this.props.navigation.goBack()
    }
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReportModalOpenMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: '',
      openModal: true,
      eventId: '',
      eventData: {},
      eventCommentData: [],
      userComment: '',
      userReply: '',
      reply_id: '',
      openCommentEmoji: false,
      openCommentReplyEmoji: false,
      openReply: '',
      images: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      
      if (responseJson && responseJson.errors) {
        // this.setState({ refresh: false });
        this.parseApiErrorResponse(responseJson.errors);
        this.parseApiCatchErrorResponse(responseJson.errors);
      } else if (this.getEventDetailCallId === apiRequestCallId){
        const eventData = { ...responseJson.data.attributes, id: responseJson.data.id }
        const images = [responseJson.data.attributes.image]
        this.setState({ 
          eventData, images
        })
      } else if (this.getAllEventCommentCallId === apiRequestCallId){
        let eventCommentData = responseJson?.comments?.data?.map((d: any) => ({ ...d.attributes, id: d.id })) || []
        this.setState({ 
          eventCommentData, pagination: responseJson?.meta?.pagination
        })
      } else if (this.eventCommentCallId === apiRequestCallId){
        let userComment = '';
        this.setState({ 
          userComment
        })
        this.getAllComments();
      } else if (this.eventLikeCallId === apiRequestCallId){
        const comment_id = this.likeCallIdToCommentId[apiRequestCallId];
        let eventCommentData = this.state.eventCommentData;
        let updateItem = eventCommentData.find((x) => x.id == comment_id);
        let index = eventCommentData.indexOf(updateItem);
        delete this.likeCallIdToCommentId[apiRequestCallId]
        //let comment_id = responseJson?.data?.attributes?.likeable_id
        if (responseJson?.data) {
          updateItem.like_count += 1;
          updateItem.is_like = true;
          const likeData = { ...responseJson.data, ...responseJson.data?.attributes }
          updateItem.like_id = [...(this.state.eventData.like_id || []), likeData];
          eventCommentData[index] = updateItem;
          this.setState({eventCommentData});
        }
        else {
          updateItem.like_count -= 1;
          updateItem.is_like = false;
          updateItem.like_id = [];
          eventCommentData[index] = updateItem;
          this.setState({eventCommentData});
        }
      } else if (this.eventCommentReplyCallId === apiRequestCallId){
        let new_reply = responseJson.data ? { ...responseJson.data.attributes, id: responseJson.data.id, like_count: 0 } : []
        if (new_reply) {
          let eventCommentData = this.state.eventCommentData;
          let updateItem = eventCommentData.find((x) => x.id == this.state.reply_id);
          let index = eventCommentData.indexOf(updateItem);
          updateItem.replies.push(new_reply);
          eventCommentData[index] = updateItem;
          let userReply = '';
          this.setState({ 
            userReply, eventCommentData, reply_id: ''
          })
        }
      } else if (this.getMoreCommentCallId === apiRequestCallId){
        let oldCourseCommentData = responseJson?.comments?.data?.map((d: any) => ({ ...d.attributes, id: d.id })) || []
        let eventCommentData = [...this.state.eventCommentData, ...oldCourseCommentData] ;
        this.setState({ 
          eventCommentData, pagination: responseJson?.meta?.pagination
        })
      } else if (this.apiFavCourseCallId.includes(apiRequestCallId)) {
        const postId = this.favCallIdToCourseId[apiRequestCallId]
        this.apiFavCourseCallId = this.apiFavCourseCallId.filter(x => x !== apiRequestCallId)
        delete this.favCallIdToCourseId[apiRequestCallId]
        if (responseJson.data) {
          const fav = { ...responseJson.data, ...responseJson.data?.attributes }
          let eventData = { ...this.state.eventData, favourite_id: [...(this.state.eventData.favourite_id || []), fav] }
          this.setState({ eventData })
          const updatedEventData = {id:responseJson.data.id, ...eventData}
          sendEventUpdatedMessage(updatedEventData)
        }
      }
       
    }
    // Customizable Area End

  }

  // Customizable Area Start
  handleShareCourse = (p: any) => () => {
    let data = {
      userId: p?.created_by?.id,
      postId: p?.id,
      link: `${window?.location?.origin}/user/${JSON.parse(p?.created_by?.id || '0')}`,
      shareableType: 'BxBlockEvents::Event',
      shareableId: p?.id,
    }
    sendSharePopupMessage(data);
  }

  handleEventCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ userComment: e.target.value })
  }

  handleCourseReplyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ userReply: e.target.value })
  }

  handleEventComment = async(event_id: any) => {
    const token=await getStorageData("authToken")
    const { userComment } = this.state
    const body = new FormData()
    body.append("event_id", event_id)
    body.append("content", userComment);

    this.eventCommentCallId = sendAPIRequest(configJSON.eventCommentEndpoint, {
      method: 'POST',
      body,
      headers: { token }
    })

    let stateEmogi = false;
    this.setState({ openCommentEmoji: stateEmogi });
  }

  handleCommentLike = async(event: any) => {
    const token=await getStorageData("authToken")
    const body = new FormData()
    body.append("data[attributes][likeable_type]", 'Comment')
    body.append("data[attributes][likeable_id]", event.id);
    let url = configJSON.eventLikeEndPoint;
    if (event.is_like) {
      url = configJSON.eventLikeEndPoint + '/' + event?.like_id[0]?.id
    }
    this.eventLikeCallId = sendAPIRequest(url, {
      method: event.is_like ? 'DELETE' : 'POST',
      body,
      headers: { token }
    })
    this.likeCallIdToCommentId[this.eventLikeCallId] = event.id
  }

  handleSetReplyId = (reply_id: any) => {
    this.setState({
      reply_id
    });
  }

  handleReplyComment = async() => {
    const token=await getStorageData("authToken")
    const { userReply, reply_id } = this.state
    const body = new FormData()
    body.append("comment_id", reply_id)
    body.append("content", userReply);

    this.eventCommentReplyCallId = sendAPIRequest(configJSON.eventCommentReplyEndPoint, {
      method: 'POST',
      body,
      headers: { token }
    })

    let stateEmogi = false;
    this.setState({ openCommentReplyEmoji: stateEmogi });
  }

  loadMore = async() => {
    const token=await getStorageData("authToken")
    const course_id = this.props.navigation.getParam('course_id')
    let course_url = configJSON.getAllCourseCommentEndpoint.replace(':cid', course_id) + '&page=' + this.state.pagination?.next_page;
    this.getMoreCommentCallId = sendAPIRequest(course_url, {
      method: 'GET',
      headers: { token }
    })
  }

  onLikeChange = (postId: any, delta: number) => {
    let eventData = this.state.eventData;
    eventData.total_likes += delta;
    eventData.like_id = [];
    this.setState({ eventData })
  }
  onLikeIdUpdate = (postId: any, likeObj: any) => {
    let eventData = this.state.eventData;
    eventData.like_id = [likeObj];
    this.setState({ eventData })
  }

  handleFavoriteClick = async() => {
    let eventData = this.state.eventData;
    console.log(eventData, 'course DATA')
    // if a fav request already exists, skip this one
    //if (Array.from(Object.values(this.favCallIdToPostId)).includes(postId)) return;

    if (eventData) {
      let endpoint = eventData.is_favourite ? configJSON.unFavEventEndPoint : configJSON.favEventEndPoint
      const method = eventData.is_favourite ? 'DELETE' : 'POST'
      if (!eventData.is_favourite) {
        const token=await getStorageData("authToken")
        // POST REQUEST
        const body = new FormData()
        body.append("data[favouriteable_type]", "BxBlockEvents::Event")
        body.append("data[favouriteable_id]", eventData.id as string)
        const callId = sendAPIRequest(endpoint, { body, method, headers: { token: token } })
        this.apiFavCourseCallId.push(callId)
        this.favCallIdToCourseId[callId] = eventData.id
      } else {
        const token=await getStorageData("authToken")
        // DELETE REQUEST
        if (!eventData.favourite_id?.length) return;

        endpoint = endpoint.replace(':id', String(eventData.favourite_id[0].id))
        sendAPIRequest(endpoint, { method, headers: { token: token } })
      }
      // optimistic update
      eventData.is_favourite = !eventData.is_favourite
      eventData.favourite_id = []
      this.setState({ eventData })
      const updatedEventData = {id:eventData.id, ...eventData}
      sendEventUpdatedMessage(updatedEventData)
    }
  }

  onInterested = async(interestedId: any) => {
    const eventData = this.state.eventData;
    if(eventData.id == interestedId){
      eventData.is_interested = true;
      this.setState({ eventData })
    }
    const token=await getStorageData("authToken")
    const body = new FormData();
    body.append('id', interestedId);
    this.interestedEventId = sendAPIRequest(
      configJSON.interestedEventEndPoint,
      {
        method: "POST",
        headers: {token},
        body
      })
      // this.getAllEvent();
  }

  onCommentEmojiClick = (event: any, emojiObject: any) => {
    let emoji = emojiObject?.emoji;
    let userComment = this.state.userComment + emoji;
    this.setState({ userComment });
  }

  openEmojiComment = () => {
    let stateEmogi = !this.state.openCommentEmoji;
    this.setState({ openCommentEmoji: stateEmogi });
  }

  openEmojiCommentReply = () => {
    let stateEmogi = !this.state.openCommentReplyEmoji;
    this.setState({ openCommentReplyEmoji: stateEmogi });
  }

  onReplyEmojiClick = (event: any, emojiObject: any) => {
    let emoji = emojiObject?.emoji;
    let userReply = this.state.userReply + emoji;
    this.setState({ userReply });
  }

  showReply = (comment_id: any) => {
    this.setState({ openReply: comment_id });
  }
  // Customizable Area End
  
}

import React from "react";
// Customizable Area Start
import AuditTrailController, { Props } from "./AuditTrailController";
import {
	Grid, Box, Typography, Avatar
} from '@material-ui/core';
import { styled } from '@material-ui/styles'
import UserContext from "../../../components/src/UserProvider";
import TopNavBar from "../../../components/src/TopNavbar";
import LeftMenuWeb from "../../../components/src/LeftMenuWeb";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Pagination from '@material-ui/lab/Pagination';
// Customizable Area End
export default class AuditTrail extends AuditTrailController {
	constructor(props: Props) {
		super(props);
		// Customizable Area Start
		// Customizable Area End
	}

	// Customizable Area Start
	attributes=(item: any,attribute:any)=>{
		if(attribute === "project_name"){
			return <>
			<Box fontSize="13px" marginTop={"10px"}>
				<Typography style={{fontWeight:"bold"}}>Changed</Typography>
				<Typography>Project Name "{item?.old_value}" to "{item.new_value}"</Typography>
			</Box>
			</>
		}

		if(attribute === "no_of_milestones"){
			return <>
			<Box fontSize="13px" marginTop={"10px"}>
				<Typography style={{fontWeight:"bold"}}>Changed</Typography>
				<Typography>Number of Milestone from "{item?.old_value}" to "{item?.new_value}"</Typography>
			</Box>
			</>
		}
		if(attribute === "project_status"){
			return <>
			<Box fontSize="13px" marginTop={"10px"}>
				<Typography style={{fontWeight:"bold"}}>Changed</Typography>
				<Typography>Status "{item?.old_value}" to "{item?.new_value}"</Typography>
			</Box>
			</>
		}

		if(attribute === "project_members"){
			return<>
			<Box marginTop="8px" display="flex" alignItems="center" style={{gap:"10px" , fontSize:"14px"}}>
				<Typography>Added Project Members :</Typography>
				{this.renderValue(item)}
			</Box>
			</>
		}
		if(attribute === "project_investors"){
			return<>
			<Box marginTop="8px" display="flex" alignItems="center" style={{gap:"10px" , fontSize:"14px"}}>
				<Typography>Added Project Investor :</Typography>
				{this.renderValue(item)}
			
			</Box>
			</>
		}
		if(attribute === "project_moderators"){
			return<>
			<Box marginTop="8px" display="flex" alignItems="center" style={{gap:"10px" , fontSize:"14px"}}>
				<Typography>Added Project Moderators :</Typography>
				{this.renderValue(item)}
			</Box>
			</>
		}
		if(attribute === "approvers_level_1"){
			return<>
			<Box marginTop="8px" display="flex" alignItems="center" style={{gap:"10px" , fontSize:"14px"}}>
				<Typography>Added Level 1 Approvers :</Typography>
			
				{this.renderValue(item)}
			</Box>
			</>
		}
		if(attribute === "approvers_level_2"){
			return<>
			<Box marginTop="8px" display="flex" alignItems="center" style={{gap:"10px" , fontSize:"14px"}}>
				<Typography>Added Level 2 Approvers :</Typography>
				{this.renderValue(item)}

			</Box>
			</>
		}
		if(attribute === "approvers_level_3"){
			return<>
			<Box marginTop="8px" display="flex" alignItems="center" style={{gap:"10px" , fontSize:"14px"}}>
				<Typography>Added Level 3 Approvers :</Typography>

				{this.renderValue(item)}
			</Box>
			</>
		}

	}

	attributes2=(item: any,attribute:any)=>{
		if(attribute === "planned_source_of_funding"){
			return <>
			<Box fontSize="13px" marginTop={"10px"}>
				<Typography style={{fontWeight:"bold"}}>Changed</Typography>
				<Typography>Planned Source of Funding from "{item?.old_value}" to "{item?.new_value}"</Typography>
			</Box>
			</>
		}
		
		if(attribute === "actual_source_of_funding"){
			return <>
			<Box fontSize="13px" marginTop={"10px"}>
				<Typography style={{fontWeight:"bold"}}>Changed</Typography>
				<Typography>Actual Source of Funding from "{item?.old_value}" to "{item?.new_value}"</Typography>
			</Box>
			</>
		}
		if(attribute === "expected_cost_of_capital"){
			return <>
			<Box fontSize="13px" marginTop={"10px"}>
				<Typography style={{fontWeight:"bold"}}>Changed</Typography>
				<Typography>Expected Cost of Capital from "{item?.old_value}" to "{item?.new_value}"</Typography>
			</Box>
			</>
		}

		if(attribute === "project_impact_name"){
			return <>
			<Box fontSize="13px" marginTop={"10px"}>
				<Typography style={{fontWeight:"bold"}}>Changed</Typography>
				<Typography>Project Impact Name from "{item?.old_value}" to "{item?.new_value}"</Typography>
			</Box>
			</>
		}
		if(attribute === "project_impact_outcome"){
			return <>
			<Box fontSize="13px" marginTop={"10px"}>
				<Typography style={{fontWeight:"bold"}}>Changed</Typography>
				<Typography>Project Impact Outcome from <br/> "{item?.old_value}" <br/> to <br/>"{item?.new_value}"</Typography>
			</Box>
			</>
		}
		if(attribute === "operation_model"){
			return <>
			<Box fontSize="13px" marginTop={"10px"}>
				<Typography style={{fontWeight:"bold"}}>Changed</Typography>
				<Typography>Operating Model from "{item?.old_value}" to "{item?.new_value}"</Typography>
			</Box>
			</>
		}
		if(attribute === "industry"){
			return <>
			<Box fontSize="13px" marginTop={"10px"}>
				<Typography style={{fontWeight:"bold"}}>Changed</Typography>
				<Typography>Industry from "{item?.old_value}" to "{item?.new_value}"</Typography>
			</Box>
			</>
		}
		if(attribute === "organization_name"){
			return <>
			<Box fontSize="13px" marginTop={"10px"}>
				<Typography style={{fontWeight:"bold"}}>Changed</Typography>
				<Typography>Organization Name from "{item?.old_value}" to "{item?.new_value}"</Typography>
			</Box>
			</>
		}
		if(attribute === "organization_objective_id"){
			return <>
			<Box fontSize="13px" marginTop={"10px"}>
				<Typography style={{fontWeight:"bold"}}>Changed</Typography>
				<Typography>Organization Objective ID from "{item?.old_value}" to "{item?.new_value}"</Typography>
			</Box>
			</>
		}
		if(attribute === "project_id"){
			return <>
			<Box fontSize="13px" marginTop={"10px"}>
				<Typography style={{fontWeight:"bold"}}>Changed</Typography>
				<Typography>Project ID from "{item?.old_value}" to "{item?.new_value}"</Typography>
			</Box>
			</>
		}
		if(attribute === "expected_rr"){
			return <>
			<Box fontSize="13px" marginTop={"10px"}>
				<Typography style={{fontWeight:"bold"}}>Changed</Typography>
				<Typography>Expected IRR from "{item?.old_value}" to "{item?.new_value}"</Typography>
			</Box>
			</>
		}
		if(attribute === "project_collaborators"){
			return<>
			<Box marginTop="8px" display="flex" alignItems="center" style={{gap:"10px" , fontSize:"14px"}}>
				<Typography>Added Project Collaborators :</Typography>
				{this.renderValue(item)}
			</Box>
			</>
		}
		if(attribute === "project_leaders"){
			return<>
			<Box marginTop="8px" display="flex" alignItems="center" style={{gap:"10px" , fontSize:"14px"}}>
				<Typography>Added Project Leader:</Typography>
				{this.renderValue(item)}
			</Box>
			</>
		}
		if(attribute === "project_sponsors"){
			return<>
			<Box marginTop="8px" display="flex" alignItems="center" style={{gap:"10px" , fontSize:"14px"}}>
				<Typography>Added Project Sponsors:</Typography>
				{this.renderValue(item)}
			
			</Box>
			</>
		}
	}

	renderValue = (item: any) =>{
		return<>
		{item?.new_value?.map((pm:any)=>{
			console.log(pm[0], "@@@@@@@@@PMdata")
			return(
				<Box display="flex" alignItems="center" style={{gap:"5px"}} >
					<Avatar style={{height:"30px" ,width:"30px"}} alt={pm[0]?.data?.attributes?.first_name} src={pm[0]?.data?.attributes?.profile_pic} ></Avatar> <Typography >{pm[0]?.data?.attributes?.first_name} {pm[0]?.data?.attributes?.last_name}</Typography>
				</Box>)
			})}</>
	}


	viewAuditTrail = () =>{
		return <>

			<h3 style={{ padding: '5px 5px 0px 10px', marginBottom: '10px' }}>
				<ArroWrapper  style={{ display: 'inline-block', position: 'relative', top: '6', left: '10' }}>
					<ArrowBackIosIcon id="backBtn" onClick={this.goBack} />
				</ArroWrapper>
				AUDIT TRAIL
			</h3>

			<ViewFunding>
				
				{this.state.auditTrailData?.map((item:any)=>{
					return<>
					{console.log(item, "?????????")}
					<Box
					 marginBottom="15px" 
					 p="15px" 
					 border="1px solid lightgray"
					 borderRadius="5px" 
					 >
						<Box display="flex" alignItems="center" style={{gap:"10px"}} >
							<Box><Avatar style={{height:"35px", width:"35px"}} alt={item?.user_id?.data?.attributes?.first_name} src={item?.user_id?.data?.attributes?.profile_pic} /></Box>
							<Box fontWeight="700" >{item?.user_id?.data?.attributes?.first_name} {item?.user_id?.data?.attributes?.last_name}</Box>
							<Box fontSize="13px" color="#392e2e">{item?.date}</Box>
							<Box fontSize="13px" color="#392e2e">{item?.time}</Box>
						</Box>
						{
							item?.data_attribute === "project_term" && <Box fontSize="13px" marginTop={"10px"}>
								<Typography style={{fontWeight:"bold"}}>Changed</Typography>
								<Typography>Project Term "{item?.old_value}" to "{item?.new_value}"</Typography>
								
							</Box>
						}
						{
							item?.data_attribute === "preference" && <Box fontSize="13px" marginTop={"10px"}>
								<Typography style={{fontWeight:"bold"}}>Changed</Typography>
								<Typography>Preference "{item?.old_value}" to "{item?.new_value}"</Typography>
								
							</Box>
						}
						{
							item?.data_attribute === "project_type" && <Box fontSize="13px" marginTop={"10px"}>
								<Typography style={{fontWeight:"bold"}}>Changed</Typography>
								<Typography>Project Type "{item?.old_value}" to "{item?.new_value}"</Typography>
								
							</Box>
						}
						{
							item?.data_attribute === "actual_cost_of_capital" && <Box fontSize="13px" marginTop={"10px"}>
								<Typography style={{fontWeight:"bold"}}>Changed</Typography>
								<Typography>Actual Cost of Capital from "{item?.old_value}" to "{item?.new_value}"</Typography>
								
							</Box>
						}
						{
							item?.data_attribute === "sdg_targets" && <Box fontSize="13px" marginTop={"10px"}>
								<Typography style={{fontWeight:"bold"}}>Changed</Typography>
								<Typography>SDG Target from "{item?.old_value}" <br /> to <br /> "{item?.new_value}"</Typography>
								
							</Box>
						}
						{
							item?.data_attribute === "dimensions" && <Box fontSize="13px" marginTop={"10px"}>
								<Typography style={{fontWeight:"bold"}}>Changed</Typography>
								<Typography>Dimension from "{item?.old_value}" to "{item?.new_value}"</Typography>
								
							</Box>
						}
						{
							item?.data_attribute === "project_start_date" && <Box fontSize="13px" marginTop={"10px"}>
								<Typography style={{fontWeight:"bold"}}>Changed</Typography>
								<Typography>Project Start Date to "{item?.new_value}"</Typography>
								
							</Box>
						}
						{
							item?.data_attribute === "project_end_date" && <Box fontSize="13px" marginTop={"10px"}>
								<Typography style={{fontWeight:"bold"}}>Changed</Typography>
								<Typography>Project End Date to "{item?.new_value}"</Typography>
								
							</Box>
						}
						{
							item?.data_attribute === "project_impact_output" && <Box fontSize="13px" marginTop={"10px"}>
								<Typography style={{fontWeight:"bold"}}>Changed</Typography>
								<Typography>Project Impact/Output from "{item?.old_value}" to "{item?.new_value}"</Typography>
								
							</Box>
						}
						{this.attributes(item, item?.data_attribute)}
						{this.attributes2(item, item?.data_attribute)}
						
					</Box>

				</>
				})}
				<Pagin>
                  	<Pagination color="primary" variant="outlined" count={this.state.total_pages} page={this.state.pages} onChange={this.handlePageChange} />
                </Pagin>
			</ViewFunding>
			</>
	}

    // Customizable Area End

    render() {
		return (
			// Customizable Area Start
			<>
				<ProjectMainBox>
					<Box>
						<TopNavBar navigation={this.props.navigation} id={""} />
					</Box>

					<OutGrid container spacing={3}>
						<LeftSideGrid item xs={12} sm={12} md={3} lg={3}>
							<Box >
								<LeftMenuWeb navigation={this.props.navigation} id={""} />
							</Box>
						</LeftSideGrid>
						<MiddleSideGrid item xs={12} sm={12} md={6} lg={6} >
							{this.viewAuditTrail()}
						</MiddleSideGrid>
					</OutGrid>

				</ProjectMainBox>
			</>
			// Customizable Area End
		)
	}
}
// Customizable Area Start
AuditTrailController.contextType = UserContext


const ProjectMainBox = styled(Box)({
	backgroundColor: "#edebeb",
	display: "flex",
	flexWrap: 'wrap',
	flexDirection: "column",
	paddingBottom: "20px",
	overflowX: "hidden",
})

const OutGrid = styled(Grid)({
	display: 'flex',
	flexWrap: 'wrap',
	justifyContent: 'space-between',
})



const LeftSideGrid = styled(Grid)({
	maxWidth: '300px',
	'@media screen and (max-width: 985px)': {
		maxWidth: '200px',
	},
	'@media screen and (max-width: 768px)': {
		display: 'none',
		visibility: 'hidden',
	},
})

const MiddleSideGrid = styled(Grid)({
	width: 'calc(100vw - 300px)',
	maxWidth: 'unset',
	flexBasis: 'unset',
	justifyContent: 'center',
	'@media screen and (max-width: 768px)': {
		width: '100%'
	}
})

const ArroWrapper = styled('div')({
	color: 'gray',
	marginRight: '10px',
	fontWeight: 100
})
const Pagin = styled('div')({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	padding: '10px',
	'@media screen and (max-width:350px)': {
	  display: 'initial',
	  padding: '10px 1px',
	},
	'@media screen and (min-width:768px) and (max-width:850px)': {
	  display: 'initial',
	  padding: '10px 1px',
	}
  })

const ViewFunding = styled('div')({
	backgroundColor: '#fff',
	borderRadius: "12.97px",
	padding: '25px',
	marginRight: '15px'
})
// Customizable Area End
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as yup from 'yup'
import { toast } from 'react-toastify';
import { Service } from "./types";
import { sendAPIRequest } from '../../../components/src/utils';
import { sendSharePopupMessage } from '../../share/src/Messages';
import { sendNewServiceCreatedMessage, sendServiceUpdatedMessage } from './Messages'
import { sendAddRequestMessage } from '../../RequestManagement/src/Messages'
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token:string;
  openModal: boolean;
  modal: 'rateService' | null;
  serviceData: any;
  eventId: string;
  serviceCommentData: any[];
  pagination?: {
    next_page?: number;
  };
  userComment: string;
  userReply: string;
  reply_id: '';
  modalRating: number;
  openCommentEmoji: boolean;
  openCommentReplyEmoji: boolean;
  openReply: '';
  selectedService?: Service;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CourseDetailController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getServiceDetailCallId = '';
  getAllServiceCommentCallId = '';
  serviceCommentCallId = '';
  rateServiceCallId: string = ""
  interestedEventId: string = "";
  serviceCommentLikeCallId = '';
  eventCommentReplyCallId = '';
  getMoreCommentCallId = '';
  apiFavCourseCallId: string[] = []
  favCallIdToCourseId: { [key: string]: string } = {}
  likeCallIdToCommentId: { [key: string]: string } = {}

  async componentDidMount() {
    super.componentDidMount();
    
    const authToken = await getStorageData('authToken');
    if (authToken == null) {
      this.props.navigation.navigate("Home");
    } else {
    }

    this.onInitialized();
  }

  onInitialized = async() => {
    const token= await getStorageData('authToken') || ''
    const service_id = this.props.navigation.getParam('service_id')
    this.getServiceDetailCallId = sendAPIRequest(configJSON.getServiceByIdEndpoint.replace(':sid', service_id), {
      method: 'GET',
      headers: { token }
    })

    this.getAllComments();
  }

  getAllComments = async() => {
    const token= await getStorageData('authToken') || ''
    const service_id = this.props.navigation.getParam('service_id')
    this.getAllServiceCommentCallId = sendAPIRequest(configJSON.getAllCommentEndpoint.replace(':sid', service_id), {
      method: 'GET',
      headers: { token }
    })
  }
  onCloseModal = () => {
    this.setState({ openModal: false });
    if(this.props.navigation.history.location.pathname.includes('Services')){
      this.props.navigation.history.push(`/MyMarketplace/Services`,{comment:true});
    }
    else{
      this.props.navigation.goBack()
    }  
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReportModalOpenMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: '',
      openModal: true,
      modal: null,
      eventId: '',
      serviceData: {},
      serviceCommentData: [],
      userComment: '',
      userReply: '',
      modalRating: 0,
      reply_id: '',
      openCommentEmoji: false,
      openCommentReplyEmoji: false,
      openReply: '',
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      
      if (responseJson && responseJson.errors) {
        // this.setState({ refresh: false });
        this.parseApiErrorResponse(responseJson.errors);
        this.parseApiCatchErrorResponse(responseJson.errors);
      } else if (this.getServiceDetailCallId === apiRequestCallId){
        console.log("response => ", responseJson);
        const serviceData = { ...responseJson.data.attributes, id: responseJson.data.id }
        this.setState({ 
            serviceData
        })
        console.log("service Data", this.state.serviceData);
      } else if (this.getAllServiceCommentCallId === apiRequestCallId){
        let serviceCommentData = responseJson?.comments?.data?.map((d: any) => ({ ...d.attributes, id: d.id })) || []
        this.setState({ 
            serviceCommentData, pagination: responseJson?.meta?.pagination
        })
      } else if (this.serviceCommentCallId === apiRequestCallId){
        let userComment = '';
        this.setState({ 
          userComment
        })
        this.getAllComments();
      } else if (this.serviceCommentLikeCallId === apiRequestCallId){
        const comment_id = this.likeCallIdToCommentId[apiRequestCallId];
        let serviceCommentData = this.state.serviceCommentData;
        let updateItem = serviceCommentData.find((x) => x.id == comment_id);
        let index = serviceCommentData.indexOf(updateItem);
        delete this.likeCallIdToCommentId[apiRequestCallId]
        //let comment_id = responseJson?.data?.attributes?.likeable_id
        if (responseJson?.data) {
          updateItem.like_count += 1;
          updateItem.is_like = true;
          const likeData = { ...responseJson.data, ...responseJson.data?.attributes }
          updateItem.like_id = [...(this.state.serviceData.like_id || []), likeData];
          serviceCommentData[index] = updateItem;
          this.setState({serviceCommentData});
        }
        else {
          updateItem.like_count -= 1;
          updateItem.is_like = false;
          updateItem.like_id = [];
          serviceCommentData[index] = updateItem;
          this.setState({serviceCommentData});
        }
      } else if (this.eventCommentReplyCallId === apiRequestCallId){
        let new_reply = responseJson.data ? { ...responseJson.data.attributes, id: responseJson.data.id, like_count: 0 } : []
        if (new_reply) {
          let serviceCommentData = this.state.serviceCommentData;
          let updateItem = serviceCommentData.find((x) => x.id == this.state.reply_id);
          let index = serviceCommentData.indexOf(updateItem);
          updateItem.replies.push(new_reply);
          serviceCommentData[index] = updateItem;
          let userReply = '';
          this.setState({ 
            userReply, serviceCommentData, reply_id: ''
          })
        }
      } else if (this.getMoreCommentCallId === apiRequestCallId){
        let oldCourseCommentData = responseJson?.comments?.data?.map((d: any) => ({ ...d.attributes, id: d.id })) || []
        let serviceCommentData = [...this.state.serviceCommentData, ...oldCourseCommentData] ;
        this.setState({ 
            serviceCommentData, pagination: responseJson?.meta?.pagination
        })
      } else if (this.apiFavCourseCallId.includes(apiRequestCallId)) {
        const postId = this.favCallIdToCourseId[apiRequestCallId]
        this.apiFavCourseCallId = this.apiFavCourseCallId.filter(x => x !== apiRequestCallId)
        delete this.favCallIdToCourseId[apiRequestCallId]
        if (responseJson.data) {
          const fav = { ...responseJson.data, ...responseJson.data?.attributes }
          let serviceData = { ...this.state.serviceData, favourite_id: [...(this.state.serviceData.favourite_id || []), fav] }
          this.setState({ serviceData })
          const updatedService = {id:responseJson.data.id, ...serviceData}
          sendServiceUpdatedMessage(updatedService)
        }
      } else if (apiRequestCallId === this.rateServiceCallId) {
        console.log("response json ", responseJson);
        let serviceData = {id: responseJson?.data?.id, ...{...this.state.serviceData, is_rated:true, average_rating:responseJson?.data?.attributes?.average_rating}}
        this.setState({serviceData :{...this.state.serviceData, is_rated:true, average_rating:responseJson?.data?.attributes?.average_rating}})
        this.setState({ modalRating: 0, modal: null })
        sendServiceUpdatedMessage(serviceData)
        toast.success("Rated Service Successfully")
        // this.setState({ 
        //   modalRating: responseJson.data.attributes.rating, modal: null
        // })
      }
       
    }
    // Customizable Area End

  }

  // Customizable Area Start
  handleShareCourse = (p: any) => () => {
    let data = {
      userId: p?.created_by?.id,
      postId: p?.id,
      link: `${window?.location?.origin}/user/${JSON.parse(p?.created_by?.id || '0')}`,
      shareableType: 'BxBlockContentManagement::MarketplaceService',
      shareableId: p?.id,
    }
    sendSharePopupMessage(data);
  }

  handleCourseReplyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ userReply: e.target.value })
  }

  handleServiceComment = async(service_id: any) => {
    const token= await getStorageData('authToken') || ''
    const { userComment } = this.state
    const body = new FormData()
    body.append("marketplace_service_id", service_id)
    body.append("content", userComment);
    console.log("service comment= ", service_id, userComment);

    this.serviceCommentCallId = sendAPIRequest(configJSON.serviceCommentEndpoint, {
      method: 'POST',
      body,
      headers: { token }
    })

    let stateEmogi = false;
    this.setState({ openCommentEmoji: stateEmogi });
  }

  handleCommentLike = async(service: any) => {
    const token =  await getStorageData('authToken') || ''
    const body = new FormData()
    body.append("data[attributes][likeable_type]", 'Comment')
    body.append("data[attributes][likeable_id]", service.id);
    let url = configJSON.likeCommentEndpoint;
    if (service.is_like) {
      url = configJSON.likeCommentEndpoint + '/' + service?.like_id[0]?.id
    }
    this.serviceCommentLikeCallId = sendAPIRequest(url, {
      method: service.is_like ? 'DELETE' : 'POST',
      body,
      headers: { token }
    })
    this.likeCallIdToCommentId[this.serviceCommentLikeCallId] = service.id
  }

  handleSetReplyId = (reply_id: any) => {
    this.setState({
      reply_id
    });
  }

  handleServiceCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ userComment: e.target.value })
}

  handleReplyComment = async() => {
    const token= await getStorageData('authToken') || ''
    const { userReply, reply_id } = this.state
    const body = new FormData()
    body.append("comment_id", reply_id)
    body.append("content", userReply);

    this.eventCommentReplyCallId = sendAPIRequest(configJSON.serviceCommentReplyEndpoint, {
      method: 'POST',
      body,
      headers: { token }
    })

    let stateEmogi = false;
    this.setState({ openCommentReplyEmoji: stateEmogi });
  }

  loadMore = async() => {
    const token= await getStorageData('authToken') || ''
    const course_id = this.props.navigation.getParam('course_id')
    let course_url = configJSON.getAllCourseCommentEndpoint.replace(':cid', course_id) + '&page=' + this.state.pagination?.next_page;
    this.getMoreCommentCallId = sendAPIRequest(course_url, {
      method: 'GET',
      headers: { token }
    })
  }

  onLikeChange = (postId: any, delta: number) => {
    let serviceData = this.state.serviceData;
    serviceData.total_likes += delta;
    serviceData.like_id = [];
    this.setState({ serviceData })
  }
  onLikeIdUpdate = (postId: any, likeObj: any) => {
    let serviceData = this.state.serviceData;
    serviceData.like_id = [likeObj];
    this.setState({ serviceData })
  }

  handleFavoriteClick = async() => {
    let serviceData = this.state.serviceData;
    const authToken = await getStorageData('authToken');
    console.log(serviceData, 'service DATA')
    // if a fav request already exists, skip this one
    //if (Array.from(Object.values(this.favCallIdToPostId)).includes(postId)) return;

    if (serviceData) {
      let endpoint = serviceData.is_favourite ? configJSON.setUnFavEndpoint : configJSON.setFavEndpoint
      const method = serviceData.is_favourite ? 'DELETE' : 'POST'
      if (!serviceData.is_favourite) {
        // POST REQUEST
        const body = new FormData()
        body.append("data[favouriteable_type]", "BxBlockContentManagement::MarketplaceService")
        body.append("data[favouriteable_id]", serviceData.id as string)
        const callId = sendAPIRequest(endpoint, { body, method, headers: { token: authToken || '' } })
        this.apiFavCourseCallId.push(callId)
        this.favCallIdToCourseId[callId] = serviceData.id
      } else {
        // DELETE REQUEST
        if (!serviceData.favourite_id?.length) return;
        endpoint = endpoint.replace(':id', String(serviceData.favourite_id[0].id))
        sendAPIRequest(endpoint, { method, headers: { token: authToken || '' } })
      }
      // optimistic update
      serviceData.is_favourite = !serviceData.is_favourite
      serviceData.favourite_id = []
      this.setState({ serviceData })
      const updatedService = {id:serviceData.id, ...serviceData}
      sendServiceUpdatedMessage(updatedService)
    }
  }

  onInterested = async(interestedId: any) => {
    const serviceData = this.state.serviceData;
    if(serviceData.id == interestedId){
        serviceData.is_interested = true;
      this.setState({ serviceData })
    }
    const token= await getStorageData('authToken') || ''
    const body = new FormData();
    body.append('id', interestedId);
    this.interestedEventId = sendAPIRequest(
      configJSON.interestedEventEndPoint,
      {
        method: "POST",
        headers: {token},
        body
      })
  }

  onCommentEmojiClick = (event: any, emojiObject: any) => {
    let emoji = emojiObject?.emoji;
    let userComment = this.state.userComment + emoji;
    this.setState({ userComment });
  }

  openEmojiComment = () => {
    let stateEmogi = !this.state.openCommentEmoji;
    this.setState({ openCommentEmoji: stateEmogi });
  }

  openEmojiCommentReply = () => {
    let stateEmogi = !this.state.openCommentReplyEmoji;
    this.setState({ openCommentReplyEmoji: stateEmogi });
  }

  onReplyEmojiClick = (event: any, emojiObject: any) => {
    let emoji = emojiObject?.emoji;
    let userReply = this.state.userReply + emoji;
    this.setState({ userReply });
  }

  showReply = (comment_id: any) => {
    this.setState({ openReply: comment_id });
  }

  handleEnquireClick = (e: any) => {
    const id = e.currentTarget.dataset.id
    const service = this.state.serviceData
    if(service?.created_by?.id == this.context.id) {
      toast.warn('Cannot enquire your own service')
      return 
    }
    this.props.navigation.history.push(`/enquire-service/${id}`)
  }

  hideModal = () => { this.setState({ modal: null, modalRating: 0 }) }

  handleRateServices = async(value: any) => {
    // console.log("hgh");
    const authToken = await getStorageData('authToken');
    this.rateServiceCallId = sendAPIRequest(configJSON.rateServiceEndpoint,{
      method: 'POST',
      headers: { 
        token: authToken, 
        'content-type': 'application/json' 
      },
      body: {
        "data":{
          "attributes": {
            "rating": value.rating,
            "marketplace_service_id": this.state.selectedService?.id,
          }
        }
      },
    })
  }

  setModalRating = ( modalRating: number ) => {
    this.setState({ modalRating })
  }

  handleServicesRating = (e: any) => {
    console.log("check")
    const id = Number(e.currentTarget.dataset['id'])
    const service = this.state?.serviceData
    if(service.is_rated){
      toast.warn('You have already rated this service.')
      return 
    }
    if(!id) {
      console.warn('button should have data-id attribute')
    } 
    if(service?.created_by?.id == this.context.id) {
      toast.warn('Cannot rate your own service')
    } else {
      console.log("else");
      this.setState({ selectedService: service, modal: 'rateService' })
    }
  }

//   handleRateServices = (value: any) => {
//     // console.log("hgh");
//     this.rateServiceCallId = sendAPIRequest(configJSON.rateServiceEndpoint,{
//       method: 'POST',
//       headers: { 
//         token: localStorage.getItem('authToken'), 
//         'content-type': 'application/json' 
//       },
//       body: {
//         "data":{
//           "attributes": {
//             "rating": value.rating,
//             "service_id": this.state.selectedService?.id,
//           }
//         }
//       },
//     })
//   }
  // Customizable Area End
  
}

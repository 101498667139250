import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as Yup from 'yup'
import { sendAPIRequest } from '../../../components/src/utils'

import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { sendAddRequestMessage, parseRequestCreateMessage, parseRequestRespondMessage } from "./Messages";
import { map } from "lodash";
import { FormType } from "./enums";
import React from 'react'
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  searchText: string;
  importIds: number[];
  allRequest: any[];
  filterModal: boolean;
  filterQuery: string;
  pagination?: {
    next_page?: number;
  };
  filterFromDate?: Date | null;
  filterToDate?: Date | null;
  filterStatus: string;
  filterRequestType?: string;
  filterCreatedByMe: boolean;
  apiPaginateRequestCallId?: string;
  loadingRequests: boolean;
  filterMenuPosition: {
    top: number,
    left: number,
  } | undefined;
  selectingDate?: boolean;
  requestTypes: any[];
  queryStr: string;
  filterRequestTypes: any[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class RequestManagementController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getRequestListApiCallId: any;
  apiFilterRequestsCallId: string = "";
  apiGetAllRequestTypeCallId: string = "";
  filterRef?: any;

  async componentDidMount() {
    super.componentDidMount();
    this.onInitialized();
  }

  onInitialized = () => {
    this.getAllRequests();
    this.getAllRequestTypes();
  }

  getAllRequests = async() => {
    let token = await getStorageData("authToken")
    this.getRequestListApiCallId = sendAPIRequest(`${configJSON.getAllRequests}?per_page=6`, {
      headers: { token: token},
    });
    this.setState({ loadingRequests: true });
  }

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AddRequestMessaage),
      getName(MessageEnum.RequestRespondMessage),
      getName(MessageEnum.RequestCreateMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      allRequest: [],
      importIds: [],
      searchText: '',
      filterModal: false,
      filterQuery: '',
      filterFromDate: null,
      filterToDate: null,
      filterStatus: '',
      filterCreatedByMe: false,
      apiPaginateRequestCallId: '',
      loadingRequests: false,
      filterMenuPosition: undefined,
      requestTypes: [],
      filterRequestType: '',
      queryStr: '',
      filterRequestTypes: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.filterRef = React.createRef()
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);
      if (responseJson && responseJson.errors) {
        this.parseApiErrorResponse(responseJson.errors);
        this.parseApiCatchErrorResponse(responseJson.errors);
      } else if (responseJson) {
        if (apiRequestCallId === this.getRequestListApiCallId || apiRequestCallId === this.apiFilterRequestsCallId) {
          if (Array.isArray(responseJson?.requests?.data || responseJson?.requests?.data)) {
            const requests = responseJson?.requests?.data || []
            let allRequest = requests?.map((request: any) => ({ ...request?.attributes, id: request?.id, is_completed: !!request?.attributes?.response })) || [];
            this.setState({ allRequest, pagination: responseJson?.meta?.pagination });
          }
          else
            this.setState({ allRequest: [] });
          this.setState({ loadingRequests: false });
        } else if (apiRequestCallId === this.state?.apiPaginateRequestCallId) {
          const requests = responseJson?.requests?.data || []
          let allRequest = requests?.map((request: any) => ({ ...request?.attributes, id: request?.id, is_completed: !!request?.attributes?.response })) || [];
          allRequest = [...this.state.allRequest, ...allRequest]
          this.setState({ allRequest, pagination: responseJson?.meta?.pagination, loadingRequests: false });
        } else if (apiRequestCallId === this.apiGetAllRequestTypeCallId) {
          this.handleGetRequestTypeAPIResponse(responseJson);
        } else if (errorReponse) {
          this.parseApiErrorResponse(errorReponse);
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }
    parseRequestCreateMessage(message, this.receiveRequestCreate)
    parseRequestRespondMessage(message, this.receiveRequestRespond)
    // Customizable Area End

  }
  // Customizable Area Start
  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  }

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  }


  handleGetAllRequestResponce = (responceData: any) => {
    const { requests, meta } = responceData;
    if (!requests) return
    const allRequest = requests?.data?.map((request: any) => ({ ...request?.attributes, id: request?.id }));
    this.setState({ allRequest });
  }

  submitRequest = () => {
    console.log('submitted');

  }
  handleFilterMenuOpen = (e: React.MouseEvent) => {
    console.log('filterRef');
    console.log(this.filterRef);
    if (!this.filterRef) return;
    const box = this.filterRef?.current?.getBoundingClientRect();
    this.setState({
      filterMenuPosition: {
        top: box?.bottom,
        left: box?.left + 20
      }
    })
  }


  handleUserSearch = async(query: string, page?: number) => {
    console.log({ page })
    const token=await getStorageData("authToken")
    const params = new URLSearchParams()
    query && params.append('query', query)
    page && params.append('page', String(page))
    const url = configJSON.getUsersEndpoint + "?" + params.toString()
    return sendAPIRequest(url, { method: 'GET', headers: { token } })
  }


  openRequestDetailPopup = async(request: any) => {
    const { accounts, request_type_id, response } = request;
    const userData = await getStorageData('user');

    let currentUser = JSON.parse(userData as string);
    const isRespoceMode = (accounts?.data || []).map((account: any) => account.id).includes(currentUser?.id);
    const isAcceptRejectRequest = request_type_id?.response_type?.name === "accepted-rejected"
    setTimeout(() => {
      sendAddRequestMessage(
        {
          ...request,
          isViewRequest: true,
          formType: isRespoceMode && isAcceptRejectRequest ?
            FormType.AcceptRejectRequest : isRespoceMode ?
              FormType.ResponseRequest : undefined,
          formTitle: isRespoceMode && response ? 'REQUEST DETAILS' : isRespoceMode ? 'ANSWER TO REQUEST' : 'REQUEST DETAILS'
        });
    }, 500);
    const { navigation } = this.props;
    navigation.navigate('Request', {id: request.id})
    // navigation.navigate('AddRequest');
  }

  hideModal = () => { }
  handleChange = () => { }

  getAllRequestTypes = async() => {
    const token= await getStorageData("authToken")
    this.apiGetAllRequestTypeCallId = sendAPIRequest(configJSON.getRequestTypes, {
      headers: { token: token },
    });
  }

  handleGetRequestTypeAPIResponse = (json: any) => {
    if (!json?.data) return
    const filterRequestTypes = json.data.map((r: any)=>({id: r.id, name: r?.attributes?.title}))
    const requestTypes = json?.data?.filter((r: any) => !r.attributes.internal)?.map((request: any) => ({ id: request?.id, name: `${request?.attributes?.title}` }))
    this.setState({ requestTypes, filterRequestTypes });
    console.log({ requestTypes })
  }

  getFilterQueryParams = () => {
    const query = new URLSearchParams();
    if (this.state.filterFromDate)
      query.append('from_date', this.state.filterFromDate.toISOString().slice(0, 10));

    if (this.state.filterToDate)
      query.append('to_date', this.state.filterToDate.toISOString().slice(0, 10));

    if (this.state.filterQuery)
      query.append('query', this.state.filterQuery);

    if (this.state.filterCreatedByMe)
      query.append('created_by_me', this.state.filterCreatedByMe.toString());

    if (typeof this.state.filterStatus === "boolean")
      query.append('is_completed', this.state.filterStatus);

    if (this.state.filterRequestType)
      query.append('request_type', this.state.filterRequestType.toString());
    console.log({ requestFilter: this.state })
    return query;
  }

  loadNextPage = async() => {
    const token= await getStorageData("authToken")
    const query = this.getFilterQueryParams();
    const isFilterSet = (query.toString().length === 0) || (this.filterText().length > 0);
    query.append('per_page', '5');
    query.append('page', String(this.state.pagination?.next_page));
    if (this.state.filterQuery.length > 0)
      query.append('query', this.state.filterQuery);

    const callId = isFilterSet ? sendAPIRequest(`${configJSON.filterRequest}?${query.toString()}`, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        token: token
      },
    }) : sendAPIRequest(`${configJSON.getAllRequests}?${query.toString()}`, {
      headers: { token: token },
    });
    this.setState({
      apiPaginateRequestCallId: callId,
      loadingRequests: true
    });
  }

  filterRequests = async() => {
    const token= await getStorageData("authToken")
    const queryStr = this.getFilterQueryParams().toString();
    if (queryStr === this.state.queryStr) return
    this.setState({ queryStr, allRequest: [] });
    if (queryStr) {
      this.apiFilterRequestsCallId = sendAPIRequest(`${configJSON.filterRequest}?${queryStr}`, {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          token:token
        },
      });

      this.setState({ loadingRequests: true });
    } else {
      this.getAllRequests();
    }
  }

  getBlankFilters = () => {
    return {
      filterFromDate: null,
      filterToDate: null,
      filterStatus: '',
      filterRequestType: '',
      filterCreatedByMe: false
    }
  }

  handleFromDateChange = (date: any) => {
    this.setState({ ...this.getBlankFilters(), filterFromDate: date, filterToDate: this.state.filterToDate })
  }

  handleToDateChange = (date: any) => {
    this.setState({ ...this.getBlankFilters(), filterToDate: date, filterFromDate: this.state.filterFromDate })
  }

  handleDateFilterSubmit = () => {
    this.filterRequests()
  }

  filterText = () => {
    let filterText = "";
    if (this.state.filterFromDate || this.state.filterToDate) {
      filterText += `Date(${this.state.filterFromDate?.toISOString()?.slice(0, 10) || '-'} to ${this.state.filterToDate?.toISOString()?.slice(0, 10) || '-'} ) `
    }

    if (typeof this.state?.filterStatus === "boolean") {
      filterText += `Status: ${this.state.filterStatus?"Complete":"Pending"}`
    }
    if (this.state.filterCreatedByMe) filterText += 'Created By Me'
    if (this.state.filterRequestType?.length) {
      const req = this.state.filterRequestTypes.find(
        (x:any) => x.id == this.state.filterRequestType
      )
      if(req)
        filterText += `Request Type: ${req?.name}`
    }

    return filterText
  }

  toggleFilterByMe = () => {
    const filters = this.getBlankFilters();
    filters['filterCreatedByMe'] = !this.state.filterCreatedByMe;
    this.setState({ ...filters }, () => this.filterRequests());
  }

  handleFilterMenuClose = () => {
    this.setState({ filterMenuPosition: undefined });
    this.filterRequests();
  }

  handleFilterMenuClear = () => {
    this.setState({ filterMenuPosition: undefined });
    this.filterRequests();
  }

  handleDateInputFocus = () => {
    this.setState({ selectingDate: true })
  }
  handleDateInputBlur = () => (this.setState({ selectingDate: false }));

  handleDateFilterCancel = () => {
    this.setState({ filterMenuPosition: undefined })
  }

  onSelecteFilterStatus = (status: any) => (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    if (this.state?.filterStatus !== status) {
      // add id
      this.setState({ 
        ...this.getBlankFilters(), 
        filterStatus: status, 
      }, () => this.filterRequests())
    } else {
      // remove id
      this.setState({ ...this.getBlankFilters(), filterStatus: '' }, () => this.filterRequests())
    }
  }

  handleRequestTypeSelect = (id: number) => (e: any) => {
    console.log({id, filterRequestType: '469'})
    e.preventDefault()
    e.stopPropagation()
    if (this.state?.filterRequestType!=id.toString()) {
      // add id
      this.setState({ 
        ...this.getBlankFilters(), 
        filterRequestType: id.toString() 
      }, () => this.filterRequests())
    } else {
      // remove id
      this.setState({ 
        ...this.getBlankFilters(), 
        filterRequestType: '' 
      }, () => this.filterRequests())
    }
  }

  clearFilters = (e?: React.MouseEvent) => {
    e && e.stopPropagation()
    this.setState({
      filterFromDate: null,
      filterToDate: null,
      filterStatus: '',
      filterRequestType: '',
      filterCreatedByMe: false,
      queryStr: ''
    })
    // load posts with no filters
    this.getAllRequests();
  }

  onFilterQueryChange = (filterQuery: string) => {
    this.setState({ filterQuery }, () => this.filterRequests())
  }

  receiveRequestRespond = (requestId: any, response: any, status?: any) => {
    const allRequest = this.state.allRequest.map((req: any) => {
      if(req.id!==requestId) return req
      return {...req, response: {data: response}, is_completed: true, status}
    })
    this.setState({ allRequest })
  }

  receiveRequestCreate = (request: any) => {
    console.log({requestCreate: request, line: '523'})
    let { allRequest } = this.state
    allRequest = [{id: request.id, ...request.attributes}, ...allRequest]
    this.setState({ allRequest })
  }

  // Customizable Area End

}

import React, { useState } from "react";
// Customizable Area Start
import ProjectPortfolioController, { Props } from "./ProjectPortfolioController";
import {
	Grid, Card, Box, Tooltip, Typography, Button, Avatar, Checkbox, MenuItem, FormControlLabel, ListItemText, Fade, Modal, Backdrop
} from '@material-ui/core'
import { styled } from '@material-ui/styles'
import Flex from "../../../components/src/Flex.web";
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Formik } from "formik";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { InputRow, MDatePicker } from "../../../components/src/FormModal.web";
import { MInput, MSelect } from "../../CfCourseListing5/src/Components.web";
import Attachments from '../../../components/src/Attachments.web'
import UserContext from "../../../components/src/UserProvider";
import { iconAdd } from "../../JobListing2/src/assets";
import TopNavBar from "../../../components/src/TopNavbar";
import LeftMenuWeb from "../../../components/src/LeftMenuWeb";
import SearchSelect from "../../../components/src/SearchSelect.web";
import { MCountedTextArea } from "../../Events2/src/Components.web";
import { toast } from 'react-toastify';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import UploadButton from '../../../components/src/UploadButtonnew.web'
import { SeekingFundDetailModel } from "../../../blocks/Surveys/src/SeekingFundDetailModel.web";
import { calenderIcon, locationIcon, closeIcon, ProfilePicDummy } from './assets';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Maps from "../../maps/src/Maps.web";
import Pagination from '@material-ui/lab/Pagination';
import OverlayLoader from '../../../components/src/Loader.web'
import { setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

// Customizable Area Start
interface UserModalInterface {
	showModal: boolean
	detail: string
	Delete?: any
	value?: any
	onClose?: any
	onDelete?: any
}

const UserModal = ({ showModal, detail, onClose }: UserModalInterface) => {
	return (
		<Modal
			open={showModal}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={showModal}>
				<Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
					<Box style={{ backgroundColor: '#fff', position: 'relative', padding: '30px 30px', borderRadius: '8px', textAlign: 'center', maxWidth: '500px', margin: '0 auto' }}>


						<Typography style={{wordWrap: "break-word"}}>
							{detail}
						</Typography>
						<Box style={{ display: 'flex', marginTop: '20px', alignItems: 'center', justifyContent: 'center', gap: '20px' }}>
							<button onClick={() => onClose()} style={{ backgroundColor: 'red', color: '#fff', borderRadius: '5px', padding: '5px 20px', cursor: 'pointer', border: 'none' }}>Close</button>
						</Box>
					</Box>
				</Box>
			</Fade>
		</Modal>
	)
}


const statusButton = (status: any) => {
	let statusButton;

	switch (status) {
		case 'Open':
			statusButton = <LiveBtnOpen>Open</LiveBtnOpen>;
			break;
		case 'Closed':
			statusButton = <LiveBtnClosed>Closed</LiveBtnClosed>;
			break;
		case 'Funded':
			statusButton = <LiveBtnFunded>Funded</LiveBtnFunded>;
			break;
		case 'Created':
			statusButton = <LiveBtnFunded>Created</LiveBtnFunded>;
			break;
		case 'Started':
			statusButton = <LiveBtnStarted>Started</LiveBtnStarted>;
			break;
		case 'WIP':
			statusButton = <LiveBtnWIP>WIP</LiveBtnWIP>;
			break;
		case 'Suspended':
			statusButton = <LiveBtnSuspended>Suspended</LiveBtnSuspended>;
			break;
		case 'On Hold':
			statusButton = <LiveBtnOnHold>On Hold</LiveBtnOnHold>;
			break;
		case 'Completed':
			statusButton = <LiveBtnCompleted>Completed</LiveBtnCompleted>;
			break;
		default:
			statusButton = <></>;
	}

	return statusButton;
}

const avatarGroup = (images: any) => {
	return <StyledAvatar max={4} >
		{images.map((details: any) => {
			return <Avatar alt="" src={details[0]?.image_url} style={{ height: '25px', width: '25px' }} />
		})}

	</StyledAvatar>
}
const avatarGroup2 = (list: any) => {
	const sdgArray: string[] = [];
	Array.isArray(list) && list.map((item: any) => (
		sdgArray.push(item?.data?.attributes?.image)
	))
	return <StyledAvatar max={4} >

		{sdgArray.map((images: any) => { return <Avatar alt="" src={images} style={{ height: '25px', width: '25px' }} /> })}

	</StyledAvatar>
}


const OnFunding = ({ detail, onEdit, onView, roleName }: any) => {
	return <>
		<SectionItem >
			<Card style={{ borderRadius: "10px", border: "1px solid #f2f2f2" }}
			>
				<SpacingSection style={{ cursor: 'pointer' }} onClick={(e: any) => {
					onView()
				}}>
					<Box style={{ width: '100%', display: "flex", flexDirection: "column", padding: '5px 0px' }}>
						<Flex justify="space-between" align="center" >
							<Flex style={{ flexDirection: 'row', width: `calc(100% - 150px)` }} >
								<Avatar alt="Remy Sharp" src={detail?.attributes?.image} variant="rounded" />	<TemplateName>{detail?.attributes?.project_name}</TemplateName>
							</Flex>
							<div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
								{statusButton(detail?.attributes?.project_status)}
							</div>
						</Flex>
					</Box>
					<span >
						<DetailSection style={{ fontWeight: 'normal', paddingRight: '10px' }} >
							<SectionText>
								<Typography variant="subtitle1" component="div">Project ID</Typography>
								<Typography variant="subtitle2" component="div">{detail?.attributes?.project_unique_id}</Typography>
							</SectionText>
							<SectionText style={{ textAlign: 'left' }}>
								<Typography variant="subtitle1" component="div">Location</Typography>
								<Typography variant="subtitle2" component="div">{detail?.attributes?.location}</Typography>
							</SectionText>
						</DetailSection>
						<DetailSection style={{ fontWeight: 'normal', paddingRight: '10px' }}>
							<SectionText>
								<Typography variant="subtitle1" component="div">Organization Name</Typography>
								<Typography variant="subtitle2" component="div">{detail?.attributes?.organization_name}</Typography>
							</SectionText>
							<SectionText>
								<Typography variant="subtitle1" component="div">Preferences</Typography>
								<Typography variant="subtitle2" component="div">{detail?.attributes?.preferences?.map((preference_name: any) => { return <>{preference_name}<br /></> })}</Typography>
							</SectionText>
						</DetailSection>
						<DetailSection style={{ fontWeight: 'normal', paddingRight: '10px' }}>
							<SectionText>
								<Typography variant="subtitle1" component="div">Funding Required</Typography>
								<Typography variant="subtitle2" component="div">${detail?.attributes?.funding_amount}</Typography>
							</SectionText>
							<SectionText>
								<Typography variant="subtitle1" component="div">SDG Focus</Typography>
								{avatarGroup(detail?.attributes?.images)}
							</SectionText>
						</DetailSection>
					</span>
				</SpacingSection>
			</Card>
		</SectionItem>
	</>
}

export const OnGoing = ({ detail, onEditOngoing, }: any) => {

	const [modalStatus, setModalStatus] = useState(false)

	return <>
		<SectionItem >
			<Card style={{ borderRadius: "10px", border: "1px solid #f2f2f2" }}>
				<SpacingSection>
					<Box style={{ width: '100%', display: "flex", flexDirection: "column", padding: '5px 0px' }}>
						<Flex justify="space-between" align="center" >
							<Flex style={{ flexDirection: 'row', width: `calc(100% - 150px)` }} >
								<Avatar alt="Remy Sharp" src={detail?.attributes?.project_profile_picture} variant="rounded" />
								<TemplateName>{detail?.attributes?.project_name.length > 10  ? 
							<Tooltip title={<div style={{
								height: 'auto',
								fontSize: '13px',
								width: 'auto',
								color: 'white',
								overflow: 'hidden',
								margin: "0px"
							  }}>
								{detail?.attributes?.project_name}
							  </div>}>
							  <span>{detail?.attributes?.project_name.slice(0, 10)}...</span>
							  </Tooltip> 
							: 
							detail?.attributes?.project_name}
								</TemplateName>
							</Flex>
							<div style={{ display: 'flex', gap: '10px', alignItems: 'center', cursor: 'pointer' }}>
								<EditOutlinedIcon id="EditOngoingBtn" color="disabled" onClick={() => onEditOngoing()} />
								{statusButton(detail?.attributes?.project_status)}
							</div>
						</Flex>
					</Box>
					<DetailSection id="EditOngoingBtn" onClick={() => onEditOngoing()} style={{ fontWeight: 'normal', paddingRight: '10px' }}>
						<SectionText>
							<Typography variant="subtitle1" component="div">Project ID</Typography>
							<Typography variant="subtitle2" component="div">{detail?.attributes?.project_id}</Typography>
						</SectionText>
						<SectionText style={{ textAlign: 'left' }}>
							<Box display="flex" alignItems="center"><img width="15px" src={locationIcon} style={{ marginRight: "8px" }} />
								<Typography variant="subtitle1" component="div">Location</Typography></Box>
							<Box marginLeft="23px" ><Typography variant="subtitle2" component="div">{detail?.attributes?.location.length > 15 ?
								<Tooltip title={<div style={{
									height: 'auto',
									fontSize: '13px',
									width: 'auto',
									color: 'white',
									overflow: 'hidden',
									margin: "0px"
								}}>
									{detail?.attributes?.location}
								</div>}>
									<span>{detail?.attributes?.location.slice(0, 15)}...</span>
								</Tooltip>
								:
								detail?.attributes?.location}</Typography></Box>
						</SectionText>
					</DetailSection>

					<SectionText style={{ height: "90px", width: '100%' }}>
						<Typography variant="subtitle1" className="preText" component="div">Description</Typography>
						{detail?.attributes?.description?.length > 150 ? (<Typography style={{wordWrap: "break-word"}} className="CustomeText" variant="subtitle2" component="div">
							{detail?.attributes?.description?.slice(0, 150)} &nbsp;
							<BoldText onClick={() => { setModalStatus(true) }} id="ModelOpenBtn">see more...</BoldText>
						</Typography>) : (<Typography style={{wordWrap: "break-word"}} className="CustomeText" variant="subtitle2" component="div">
							{detail?.attributes?.description}
						</Typography>)}
					</SectionText>
					{modalStatus && <UserModal detail={detail?.attributes?.description} showModal={modalStatus} onClose={() => setModalStatus(false)} />}
					<DetailSection id="EditOngoingBtn" onClick={() => onEditOngoing()} style={{ fontWeight: 'normal', paddingRight: '10px' }}>

						<SectionText>
							<Typography variant="subtitle1" component="div">Preferences</Typography>
							<Typography variant="subtitle2" component="div">{detail?.attributes?.preference}</Typography>
						</SectionText>
						<SectionText>
							<Box display="flex" alignItems="center"><img width="15px" src={calenderIcon} style={{ marginRight: "8px" }} />
								<Typography variant="subtitle1" component="div">Creation Date</Typography></Box>
							<Box marginLeft="23px" ><Typography variant="subtitle2" component="div">{detail?.attributes?.created_date}</Typography></Box>
						</SectionText>
					</DetailSection>
					<DetailSection id="EditOngoingBtn" onClick={() => onEditOngoing()} style={{ fontWeight: 'normal', paddingRight: '10px' }}>
						<SectionText style={{ maxWidth: "50%" }}>
							<Typography variant="subtitle1" className="preText" component="div">Project Leader</Typography>
							<UserAvtarDetail>
								<Avatar style={{ marginRight: "5px", height: "25px", width: "25px", border: "3px rgb(242, 212, 212) solid" }} src={detail?.attributes?.project_leaders[0]?.data?.attributes?.profile_pic} alt={detail?.attributes?.created_by?.first_name} />
								<Typography variant="subtitle2" component="div">{detail?.attributes?.project_leaders[0]?.data?.attributes?.first_name}</Typography>
							</UserAvtarDetail>
						</SectionText>
						<SectionText>
							<Typography variant="subtitle1" component="div">SDG Factors</Typography>
							{avatarGroup2(detail?.attributes?.sdg_factors)}
						</SectionText>
					</DetailSection>

				</SpacingSection>
			</Card>
		</SectionItem>
	</>
}
// Customizable Area End

export default class ProjectManagement extends ProjectPortfolioController {
	constructor(props: Props) {
		super(props);
		// Customizable Area Start
		// Customizable Area End
	}


    // Customizable Area Start
	PeopleManagementTabs() {

		return <>

			<PeoplePageWrapper>
				<PageWrapperCenterRow>
					<Grid container style={{ margin: '40px 0px', justifyContent: "space-between" }}>
						<Grid item >
							<RoundedButtonBaseTabs>
								<CorporateLicenceTabs
									id="OnGoingProjectBtn"
									onClick={(e) => this.onGoingProject(e)}
									style={this.state.onGoingProjectButton ? { backgroundColor: "#00A100", color: "white" } : { backgroundColor: "white" }}
								>ONGOING PROJECTS</CorporateLicenceTabs>
								<IndividualUserTabs
									id="projectSeekingFundBtn"
									onClick={(e) => { this.onSeekingFund(e) }}
									style={this.state.seekingFundButton ? { backgroundColor: "#00A100", color: "white" } : { backgroundColor: "white" }}
								>PROJECT SEEKING FUNDS</IndividualUserTabs>
							</RoundedButtonBaseTabs>
						</Grid>
						{(this.state.seekingFundButton && this.context.user_type !== "licensed_user") && <Grid item >
							<RoundedButtonBaseCreate
								id="CreateTemplateBtn"
								onClick={() => this.props.navigation.history.push(`${this.props.navigation.history.location.pathname}/RequestToList`)}
								variant="contained"
								disableElevation
								style={{ backgroundColor: "#00A100", color: "white" }}
								startIcon={<img src={iconAdd} width={30} style={{ marginLeft: '-5px' }} />}
							>
								Request to list your project for funding
							</RoundedButtonBaseCreate>
						</Grid>}

					</Grid>
					{this.state.seekingFundButton &&
						<div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', overflow: 'hidden' }}>

							<Grid spacing={4} container >
								{this.state.seekingFundData.map((detail: any) => {
									return <Grid xs={12} sm={12} md={6} lg={4} xl={4} item >
										<OnFunding roleName={this.context?.role?.name} detail={detail} onView={(e: any) => this.setState({ openSeekingFundsDetailModel: true, viewProjectData: detail })}
											onEdit={() => this.props.navigation.history.push(`${this.props.navigation.history.location.pathname}/RequestToListEdit/${detail?.id}`)
											} />
									</Grid>
								})}

							</Grid>

						</div>
					}


					{this.state.onGoingProjectButton &&
						<div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', overflow: 'hidden' }}>
							{this.state.no_project_found && <h2>No Projects Found</h2>}
							{this.state.isError ? <div>{this.state.ongoingProjectError}</div>
								:
								<>

								<Grid spacing={4} container >
									{this.state.ongoingProjectData?.map((projectData: any) => {
										return (
											<Grid xs={12} sm={12} md={6} lg={4} xl={4} item>
												<OnGoing detail={projectData} onEditOngoing={() => this.abc(projectData?.attributes?.project_id)} />
												
											</Grid>
										);
									})}
									
								</Grid>
								<Box width="100%" >
								<Pagin>
                  					<Pagination color="primary" variant="outlined" count={this.state.total_pages} page={this.state.pages} onChange={this.handlePageChange} />
                				</Pagin>
								</Box>
								</>
							}


						</div>
					}
				</PageWrapperCenterRow>
			</PeoplePageWrapper>

		</>
	}

	ProfilePicture(pic: any) {
		return <>
			<div style={{
				display: "flex",
				padding: " 10px",
				justifyContent: "center",
				alignItems: "center"
			}}>
				<div style={{ position: "relative" }}>

					<label htmlFor="upload-button">

						<span style={{ display: "flex", cursor: "pointer" }}>
							<Avatar style={{
								width: "70px",
								height: "70px",
							}}>
								
								{this.state.profile_pic_show != '' ? <img src={this.state.profile_pic_show} alt="img" width="100%" height="100%" /> : <img src={pic || ProfilePicDummy} alt="img" width="100%" height="100%" />}
							</Avatar>
							<Avatar style={{
								position: "absolute",
								color: "white",
								top: "60%",
								left: "60%",
								backgroundColor: "#1ea60a",
								width: "25px",
								height: "25px",
								padding: "3px",
								border: "1px solid white"
							}}>
								<BorderColorIcon fontSize="small" />

							</Avatar>
						</span>
					</label>
					<input type="file" accept=".png, .jpeg, .jpg" id="upload-button" style={{ display: "none" }}
						onChange={e => {
							const len = e?.currentTarget?.files;
							if (len !== null) {
								if (len[0]?.size > 1048576) {
									toast.error("Cannot Upload Picture more than 1 MB");
									e.currentTarget.files = null;
								}
								else if (len[0]?.type !== "image/png" && len[0]?.type !== "image/jpeg") {
									toast.error("image should only be png or jpeg or jpg")
								}
								else {
									this.handleFileChange(e)
								}
							}
						}}
					/>
				</div>
			</div>
		</>
	}

	saveButton(isValid:any, dirty:any) {
		return <>
			<Button
				id="saveBtn"
				style={{ height: `${!this.state.isEditable ? "0" : ""}`, backgroundColor: `${isValid && dirty ?  "#009c05" : "gray"}` , padding: `${this.state.isEditable ? "12px 40px" : "0"}`, color: "white", borderRadius: "5px", fontSize: "13px", fontWeight: "bold" }}
				type="submit"
			disabled={!isValid && !dirty}
			>{this.state.isEditable ? "SAVE" : ""}
			</Button>
		</>
	}

	getInitialValues() {
		const { AnOngoingProjectData } = this.state;
		let initialValues: any = {
		
			location: "",
			project_leader: AnOngoingProjectData?.attributes?.project_leader?.map((item: any) => item?.data?.attributes?.id) || [],
			approved_by: AnOngoingProjectData?.attributes?.approved_by?.map((item: any) => item?.data?.attributes?.id) || [],
			preference: AnOngoingProjectData?.attributes?.preference || "",
			project_impact_name: AnOngoingProjectData?.attributes?.project_impact_name ?? "",
			project_impact_outcome: AnOngoingProjectData?.attributes?.project_impact_outcome || "",
			created_on: AnOngoingProjectData?.attributes?.created_on,
			expected_cost_of_capital: AnOngoingProjectData?.attributes?.expected_cost_of_capital,
			actual_cost_of_capital: AnOngoingProjectData?.attributes?.actual_cost_of_capital,
			expected_rr: AnOngoingProjectData?.attributes?.expected_rr,
			project_impact_output: AnOngoingProjectData?.attributes?.project_impact_output || "",
			project_impact_output_please_specify: AnOngoingProjectData?.attributes?.project_impact_output_please_specify,
			created_by: AnOngoingProjectData?.attributes?.created_by?.data?.attributes,
			sdg_factors:[],
			dimensions: AnOngoingProjectData?.attributes?.dimensions || "",
			sdg_goals: AnOngoingProjectData?.attributes?.sdg_goals || "",
			planned_source_of_funding: AnOngoingProjectData?.attributes?.planned_source_of_funding ?? "",
			planned_source_of_funding_please_specify: AnOngoingProjectData?.attributes?.planned_source_of_funding_please_specify, 
			actual_source_of_funding: AnOngoingProjectData?.attributes?.actual_source_of_funding ?? "",
			actual_source_of_funding_please_specify: AnOngoingProjectData?.attributes?.actual_source_of_funding_please_specify,
			sdg_targets: AnOngoingProjectData?.attributes?.sdg_targets || "",
			start_date: AnOngoingProjectData?.attributes?.project_start_date,
			end_date: AnOngoingProjectData?.attributes?.project_end_date,
			project_type_please_specify: AnOngoingProjectData?.attributes?.project_type_please_specify

		}

		return initialValues
	}

	getInitialValues2() {
		const { AnOngoingProjectData } = this.state;
		let initialValues2: any = {
			ongoing_project_pdfs: AnOngoingProjectData?.attributes?.project_plans || [],
			project_name: AnOngoingProjectData?.attributes?.project_name,
			project_id: AnOngoingProjectData?.attributes?.project_id,
			project_term: AnOngoingProjectData?.attributes?.project_term ?? "",
			no_of_milestones: AnOngoingProjectData?.attributes?.no_of_milestones ?? "",
			industry: AnOngoingProjectData?.attributes?.industry,
			project_type: AnOngoingProjectData?.attributes?.project_type ?? "",
			project_status: AnOngoingProjectData?.attributes?.project_status ?? "",
			organization_name: AnOngoingProjectData?.attributes?.organization_name,
			operation_model: AnOngoingProjectData?.attributes?.operation_model ?? "",
			organization_objective_id: AnOngoingProjectData?.attributes?.organization_objective_id,
			project_sponsors: Array.isArray(AnOngoingProjectData?.attributes?.project_sponsors) ? AnOngoingProjectData?.attributes?.project_sponsors?.map((item: any) => item?.data?.attributes?.id) || [] : [],
		}
		return initialValues2
	}

	getInitialValues3() {
		const { AnOngoingProjectData } = this.state;
		let initialValues3: any = {
			project_moderator: Array.isArray(AnOngoingProjectData?.attributes?.project_moderators) ? AnOngoingProjectData?.attributes?.project_moderators?.map((item: any) => item?.data?.attributes?.id) ?? [] : [],
			project_collaborators: Array.isArray(AnOngoingProjectData?.attributes?.project_collaborators) ? AnOngoingProjectData?.attributes?.project_collaborators?.map((item: any) => item?.data?.attributes?.id) ?? [] : [],
			project_investor: Array.isArray(AnOngoingProjectData?.attributes?.project_investors) ? AnOngoingProjectData?.attributes?.project_investors?.map((item: any) => item?.data?.attributes?.id) ?? [] : [],
			project_members: Array.isArray(AnOngoingProjectData?.attributes?.project_members) ? AnOngoingProjectData?.attributes?.project_members?.map((item: any) => item?.data?.attributes?.id) ?? [] : [],
			approvers_level_1: Array.isArray(AnOngoingProjectData?.attributes?.approvers_level_1) ? AnOngoingProjectData?.attributes?.approvers_level_1?.map((item: any) => item?.data?.attributes?.id) || [] : [],
			approvers_level_2: Array.isArray(AnOngoingProjectData?.attributes?.approvers_level_2) ? AnOngoingProjectData?.attributes?.approvers_level_2?.map((item: any) => item?.data?.attributes?.id) || [] : [],
			approvers_level_3: Array.isArray(AnOngoingProjectData?.attributes?.approvers_level_3) ? AnOngoingProjectData?.attributes?.approvers_level_3?.map((item: any) => item?.data?.attributes?.id) || [] : [],
		}

		return initialValues3
	}

	viewOngoingProject = () => {
		const { AnOngoingProjectData } = this.state;
		const initialValues1 = this.getInitialValues();
		const initialValues2 = this.getInitialValues2();
		const initialValues3 = this.getInitialValues3();

		const initialValues = { ...initialValues1, ...initialValues2, ...initialValues3 }
		const CL = [...this.state.onGoingProjectCollaboratorList, ...this.state.collaboratorList.filter((item: any) => !this.state.onGoingProjectCollaboratorIdList.includes(item.id))]
		
		return <>
			<h3 style={{ padding: '5px 5px 0px 10px', marginBottom: '10px' }}>
				<ArroWrapper style={{ display: 'inline-block', position: 'relative', top: '6', left: '10' }}>
					<ArrowBackIosIcon onClick={this.onBackToOngoing} />
				</ArroWrapper>
				{this.state.isEditable ? "EDIT PROJECT" : "PROJECT DETAILS"}
				{!this.state.isEditable ?
					<span style={{ float: 'right', marginTop: '-10px', marginRight: '15px' }}>
						<Button variant="outlined" style={{ fontWeight: 700, borderColor: '#5aa069', color: '#5aa069' }} onClick={() => this.setState({ isEditable: true })}>
							Edit
						</Button>
					</span>
					:
					""}

			</h3>

			<Formik enableReinitialize initialValues={initialValues} onSubmit={this.handleSubmit} validationSchema={this.validationSchema}>
				{({isValid, dirty, values, setFieldValue, errors, handleSubmit }) => (
					<form onSubmit={handleSubmit} >
						<ViewFunding>
							{this.ProfilePicture(AnOngoingProjectData?.attributes?.project_profile_picture)}
							<Box marginTop={2} >
								<InputRow label="Project Name*" input={
									<MInput name="project_name" disabled placeholder="" />
								} />
							</Box>
						
							<Box marginTop={2} >
								<Box display="flex" style={{ gap: "10px" }} width={"100%"}>
									<Box width={"50%"} >
										<InputRow label="Project ID*" input={
											<MInput name="project_id" disabled placeholder="" />
										} />
									</Box>
									<Box width={"50%"} >
										<InputRow label="Industry" input={
											<MInput name="industry" disabled placeholder="" />
										} />
									</Box>
								</Box>

							</Box>
							<Box marginTop={2} >
								<Box display="flex" style={{ gap: "10px" }} width={"100%"}>
									<Box width={"50%"} >
										<InputRow label="Organization Name" input={
											<MInput name="organization_name" disabled placeholder="" />
										} />
									</Box>
									<Box width={"50%"} >
										<InputRow label="Organization Objective ID*" input={
											<MInput name="organization_objective_id" disabled placeholder="" />
										} />
									</Box>
								</Box>

							</Box>
							<Box marginTop={2} >
								<Box display="flex" style={{ gap: "10px" }} width={"100%"}>
									<InputRow data-test-id="approvedBy"  label="Approved By*" style={{ width: "50%" }} input={<> <SearchSelect
										data-test-id="approvedBySearchSelect"
										multiple={true}
										pagination={this.state.approverPagination}
										hasMore={!!this.state.approverPagination?.next_page}
										nextPage={this.paginateApprovers}
										loading={false} 
										onSearch={this.handleApproversSearchQuery}
										allItems={[...this.state.onGoingApprovedByList, ...this.state.approversList.filter((item: any) => !this.state.onGoingApprovedByIdList.includes(item.id))]}
										items={[...this.state.onGoingApprovedByList, ...this.state.approversList.filter((item: any) => !this.state.onGoingApprovedByIdList.includes(item.id))]}
										name="approved_by"
										selectProps={{ onClose: this.handleApproversClose }}
										SelectItem={SelectItemUser}
										isAvatar={true}
										getOptionValue={(user: any) => user.profile_pic} /></>} />
									<InputRow data-test-id="projectSponsors" label="Project Sponsors*" style={{ width: "50%" }} input={<> <SearchSelect
										data-test-id="projectSponsorsSearchSelect"
										multiple={true}
										selectProps={{ onClose: this.handleSponsorsClose }}
										pagination={this.state.sponsorsPagination}
										hasMore={!!this.state.sponsorsPagination?.next_page}
										nextPage={this.paginateSponsors}
										loading={false}
										onSearch={this.handleSponsorsSearchQuery}
										allItems={[...this.state.onGoingProjectSponsorsList, ...this.state.sponsorsList.filter((item: any) => !this.state.onGoingProjectSponsorsIdList.includes(item.id))]}
										items={[...this.state.onGoingProjectSponsorsList, ...this.state.sponsorsList.filter((item: any) => !this.state.onGoingProjectSponsorsIdList.includes(item.id))]}
										name="project_sponsors"
										SelectItem={SelectItemUser}
										isAvatar={true}
										getOptionValue={(user: any) => user.profile_pic} /></>} />

								</Box>
							</Box>

							<Box marginTop={2} >
								<Box display="flex" style={{ gap: "10px" }} width={"100%"}>
									<InputRow label="City, State(Country)" style={{ width: "50%" }} input={<Maps autoCompleteStyles={undefined} customBorder={""} input {...this.props} />} />
									<Box style={{ width: "50%", display: "flex", alignItems: "center" }} >
										{AnOngoingProjectData?.attributes?.locations.map((location: any) => {
											return <>

												<Box display="flex" alignItems="center" style={{ fontSize: "13px", gap: "5px" }} bgcolor={"e0e0e0"} p="8px" borderRadius="15px" >
													{location}
													<img style={{ cursor: "pointer" }} height="15px" width="15px" src={closeIcon}></img>

												</Box>
											</>
										})}
									</Box>
								</Box>
							</Box>

							<Box marginTop={2} >
								<Box display="flex" style={{ gap: "10px" }} width={"100%"}>
									<InputRow data-test-id="projectLeader" label="Project Leader*" style={{ width: "50%" }} input={<> <SearchSelect
										data-test-id="projectLeaderSearchSelect"
										multiple={true}
										selectProps={{ onClose: this.handleSponsorsClose }}
										pagination={this.state.moderatorPagination}
										hasMore={!!this.state.moderatorPagination?.next_page}
										nextPage={this.paginateModerator}
										loading={false}
										onSearch={this.handleModeratorSearchQuery}
										allItems={[...this.state.onGoingProjectLeaderList, ...this.state.moderatorLists.filter((item: any) => !this.state.onGoingProjectLeaderIdList.includes(item.id))]}
										items={[...this.state.onGoingProjectLeaderList, ...this.state.moderatorLists.filter((item: any) => !this.state.onGoingProjectLeaderIdList.includes(item.id))]}
										name="project_leader"
										SelectItem={SelectItemUser}
										isAvatar={true}
										getOptionValue={(user: any) => user.profile_pic} /></>} />
									<InputRow data-test-id="projectModerator" label="Project Moderator*" style={{ width: "50%" }} input={<> <SearchSelect
										data-test-id="projectModeratorSearchSelect"
										multiple={true}
										pagination={this.state.moderatorPagination}
										hasMore={!!this.state.moderatorPagination?.next_page}
										nextPage={this.paginateModerator}
										loading={false}
										onSearch={this.handleModeratorSearchQuery}
										allItems={[...this.state.onGoingProjectModeratorsList, ...this.state.moderatorLists.filter((item: any) => !this.state.onGoingProjectModeratorsIdList.includes(item.id))]}
										items={[...this.state.onGoingProjectModeratorsList, ...this.state.moderatorLists.filter((item: any) => !this.state.onGoingProjectModeratorsIdList.includes(item.id))]}
										name="project_moderator"
										selectProps={{ onClose: this.handleModeratorClose }}
										SelectItem={SelectItemUser}
										isAvatar={true}
										getOptionValue={(user: any) => user.profile_pic} /></>} />

								</Box>
							</Box>
							<Box marginTop={2} >
								<Box display="flex" style={{ gap: "10px" }} width={"100%"}>
									<Box width="50%">
										<Box>
											<label style={{ color: "#404040" }} >Project Duration*</label>
										</Box>
										<Box display={"flex"} width="100%" style={{ gap: "10px" }} >
											<InputRow label={<JobLabel></JobLabel>} style={{ width: "50%" }} input={
												<MDatePicker name="start_date"
													InputProps={{ placeholder: "From" }}
												/>
											} />
											<InputRow label={<JobLabel></JobLabel>} style={{ width: "50%" }} input={
												<MDatePicker name="end_date"
													InputProps={{ placeholder: "To" }}
												/>
											} />

										</Box>
										<Box mt="5px" color="red" fontSize="13px" >{errors.end_date}</Box>
									</Box>
									<Box width={"50%"} >
										<InputRow label="Project Term" input={
											<MSelect name="project_term" placeholder="Select"  >
												<MenuItem value="Immediate">Immediate</MenuItem>
												<MenuItem value="Short Term">Short Term</MenuItem>
												<MenuItem value="Medium Term">Medium Term</MenuItem>
												<MenuItem value="Long Term">Long Term</MenuItem>
											</MSelect>
										} />
									</Box>
								</Box>
							</Box>
							<Box marginTop={2} >
								<Box display="flex" style={{ gap: "10px" }} width={"100%"}>
									<InputRow label="Project Type*" style={{ width: "50%" }} input={
										<MSelect name="project_type" placeholder="Select" >
											<MenuItem value="Carbon Credit Project">Carbon Credit Project</MenuItem>
											<MenuItem value="Impact Credit Project">Impact Credit Project</MenuItem>
											<MenuItem value="Carbon Emission Mitigation Project(Uncertified)">Carbon Emission Mitigation Project(Uncertified)</MenuItem>
											<MenuItem value="Carbon Mitigation Certified">Carbon Mitigation Certified</MenuItem>
											<MenuItem value="Internal Stakeholders Project">Internal Stakeholders Project</MenuItem>
											<MenuItem value="External Stakeholders Project">External Stakeholders Project</MenuItem>
											<MenuItem value="Social Responsibility Project">Social Responsibility Project</MenuItem>
											<MenuItem value="Wastage Reduction Project">Wastage Reduction Project</MenuItem>
											<MenuItem value="Air Quality Project">Air Quality Project</MenuItem>
											<MenuItem value="Technology For Sustainability">Technology For Sustainability</MenuItem>
											<MenuItem value="Product Innovation">Product Innovation</MenuItem>
											<MenuItem value="Governance Project">Governance Project</MenuItem>
											<MenuItem value="Others - Please Specify">Others - Please Specify</MenuItem>
										</MSelect>
									} />
									<InputRow data-test-id="projectContributors" label="Top Contributors*" style={{ width: "50%" }} input={<SearchSelect
										data-test-id="projectContributorsSearchSelect"
										multiple={true}
										pagination={this.state.membersPagination}
										hasMore={!!this.state.membersPagination?.next_page}
										nextPage={this.paginateMembers}
										loading={false}
										onSearch={this.handleMembersSearchQuery}
										allItems={[...this.state.onGoingProjectMembersList, ...this.state.membersList.filter((item: any) => !this.state.onGoingProjectMembersIdList.includes(item.id))]}
										selectProps={{ onClose: this.handleMembersClose }}
										items={[...this.state.onGoingProjectMembersList, ...this.state.membersList.filter((item: any) => !this.state.onGoingProjectMembersIdList.includes(item.id))]}
										name="project_members"
										SelectItem={SelectItemUser}
										getOptionValue={(user: any) => user.profile_pic}
										isAvatar={true}
									/>}
									/>

								</Box>
							</Box>
							{values?.project_type == "Others - Please Specify" && <Flex gap={8}>
								<InputRow label={''} style={{ width: "49.5%" }} input={
									<MInput name="project_type_please_specify" placeholder="Please Specify" />
								} />
							</Flex>
							}
							<Box marginTop={2} >
								<Box display="flex" style={{ gap: "10px" }} width={"100%"}>
									<Box width={"50%"} >
										<InputRow label="Number of Milestone*" input={
											<MInput name="no_of_milestones" placeholder="Number of Milestone" />
										} />
									</Box>
									<Box width={"50%"} >
										<InputRow label="Project Status*" input={
											<MSelect name="project_status" placeholder="Select"  >
												<MenuItem value="Started">Started</MenuItem>
												<MenuItem value="WIP">WIP</MenuItem>
												<MenuItem value="On Hold">On Hold</MenuItem>
												<MenuItem value="Suspended">Suspended</MenuItem>
												<MenuItem value="Completed">Completed</MenuItem>

											</MSelect>
										} />
									</Box>
								</Box>
							</Box>
							<Box marginTop={2} >
								<Box display="flex" style={{ gap: "10px" }} width={"100%"}>
									<Box width={"50%"} >
										<InputRow label="Operating Model*" input={
											<MSelect name="operation_model" placeholder="Select"  >
												<MenuItem value="Inhouse">Inhouse</MenuItem>
												<MenuItem value="External">External</MenuItem>
												<MenuItem value="Hybrid">Hybrid</MenuItem>
											</MSelect>
										} />
									</Box>
									<Box width={"50%"} >
										<InputRow data-test-id="preferenceInput" label="Preference*" input={
											<MSelect
												data-test-id="preferenceMSelect"
												name="preference"
												onChange={(e)=>this.handlePreferenceChange(setFieldValue, e)}
												value={this.state.preferenceState === "" ? initialValues.preference : this.state.preferenceState}
												placeholder="Select">
												<MenuItem value="E">E - Environmental</MenuItem>
												<MenuItem value="S">S - Social</MenuItem>
												<MenuItem value="G">G - Governance</MenuItem>
											</MSelect>
										} />
									</Box>
								</Box>
							</Box>
							<Box marginTop={2} borderBottom="1px solid lightgray" >
								<p style={{ fontSize: "13px", fontWeight: "bold", color: "rgb(64, 64, 64)" }} >Approver</p>
							</Box>
							<Box marginTop={2} >
								<InputRow data-test-id="Level1" label="Level 1*" input={<> <SearchSelect
									data-test-id="Level1SearchSelect"
									multiple={true}
									pagination={this.state.approverPagination}
									hasMore={!!this.state.approverPagination?.next_page}
									nextPage={this.paginateApprovers}
									loading={false}
									onSearch={this.handleApproversSearchQuery}
									allItems={this.state.approversList}
									items={this.state.approversList}
									name="approvers_level_1"
									selectProps={{ onClose: this.handleApproversClose }}
									SelectItem={SelectItemUser}
									isAvatar={true}
									getOptionValue={(user: any) => user.profile_pic} /></>} />

							</Box>
							<Box marginTop={2} >
								<InputRow data-test-id="Level2" label="Level 2" input={<> <SearchSelect
									data-test-id="Level2SearchSelect"
									multiple={true}
									pagination={this.state.approverPagination}
									hasMore={!!this.state.approverPagination?.next_page}
									nextPage={this.paginateApprovers}
									loading={false}
									onSearch={this.handleApproversSearchQuery}
									allItems={this.state.approversList}
									items={this.state.approversList}
									name="approvers_level_2"
									selectProps={{ onClose: this.handleApproversClose }}
									SelectItem={SelectItemUser}
									isAvatar={true}
									getOptionValue={(user: any) => user.profile_pic} /></>} />
							</Box>
							<Box marginTop={2} >
								<InputRow data-test-id="Level3" label="Level 3" input={<> <SearchSelect
									data-test-id="Level3SearchSelect"
									multiple={true}
									pagination={this.state.approverPagination}
									hasMore={!!this.state.approverPagination?.next_page}
									nextPage={this.paginateApprovers}
									loading={false}
									onSearch={this.handleApproversSearchQuery}
									allItems={this.state.approversList}
									items={this.state.approversList}
									name="approvers_level_3"
									selectProps={{ onClose: this.handleApproversClose }}
									SelectItem={SelectItemUser}
									isAvatar={true}
									getOptionValue={(user: any) => user.profile_pic} /></>} />
							</Box>
							<Box marginTop={2} >
								<Box display="flex" style={{ gap: "10px" }} width={"100%"}>
									<InputRow label="Dimensions*" style={{ width: "50%" }} input={
										<MSelect name="dimensions" placeholder="Select">
											{this.state.dimensions?.map((item: any) => (
												<MenuItem value={item}>{item}</MenuItem>
											))}


										</MSelect>
									} />
									<InputRow data-test-id="SDGFactorInput" label="SDG Factors*" style={{ width: "50%" }} input={
										<MSelect data-test-id="SDGFactorMSelect" labelId="select-users-label" name="sdg_factors" multiple
											value={this.state.selectedSDGFactor}
											onChange={(e) => {
												this.setState({selectedSDGFactor: e.target.value })
												setFieldValue("sdg_factors", this.state.selectedSDGFactor)
											}}
											renderValue={(selected: any) => (
												<AvatarGroup max={3}>
													{this.state?.sdgFactor?.filter((user: any) => selected.includes(user.id) || this.state.selectedSDGFactor.includes(user.id))
														.map((user: any) => (<Avatar key={user.id} alt={user.name} src={user?.attributes?.image} />))}
												</AvatarGroup>
											)}
										>

											{this.state?.sdgFactor?.map((user: any) => (
												<MenuItem key='' value={user?.id}>
													<Avatar src={user?.attributes?.image} />
													<FormControlLabel
														label={user?.attributes?.goal}
														style={{ height: "20px", display: "flex", padding: "20px 20px", flex: 1, justifyContent: 'space-between', flexDirection: 'row-reverse', }}
														control={<Checkbox color="primary" checked={this.state.selectedSDGFactor.some((item: any) => item === user?.id)} value={user?.id} />}
													/>
												</MenuItem>
											))}
										</MSelect>
									} />
								</Box>
							</Box>
							<Box marginTop={2} >
								
								<Box display="flex" style={{ gap: "10px" }} width={"100%"}>
									<InputRow data-test-id="SDGGoalsInput" label="SDG Goals*" style={{ width: "50%" }} input={
										<MSelect
											data-test-id="SDGGoalsMSelect"
											name="sdg_goals"
											onChange={(e)=>this.handleSDGGoalChange(setFieldValue, e)}
											value={this.state.goalState === "" ? initialValues.sdg_goals : this.state.goalState}
											placeholder="Select"
										>
											{this.state.goalsData?.map((goal: any) => (
												<MenuItem
													style={{ width: "465px", whiteSpace: "break-spaces", borderBottom: "1px solid lightGray" }} value={goal} >{goal}</MenuItem>
											))}
										</MSelect>
									} />
									<InputRow label="SDG Targets*" style={{ width: "50%" }} input={
										<MSelect name="sdg_targets" placeholder="Select">
											{this.state.targets?.map((target: any) => (
												<MenuItem style={{ width: "465px", whiteSpace: "break-spaces", borderBottom: "1px solid lightGray" }} value={target}>{target}</MenuItem>
											))}
										</MSelect>
									} />
								</Box>
							</Box>
							<Box marginTop={2} >
								<Box display="flex" style={{ gap: "10px" }} width={"100%"}>
									<Box width={"50%"} >
										<InputRow label="Expected IRR in %" input={
											<MInput name="expected_rr" placeholder="" />} />
									</Box>
									<Box width={"50%"} padding="0 15px" borderRadius="5px" color="#404040" style={{ fontSize: "13px", backgroundColor: "lightgray" }} >
										<p style={{ fontWeight: "bold" }} >Note</p>
										<p>Please refer to approved Project Finance sheet</p>
									</Box>
								</Box>
							</Box>
							<Box marginTop={2} >
								<Box display="flex" style={{ gap: "10px" }} width={"100%"}>
									<Box style={{ width: "50%" }} >
										<InputRow label="Planned Sources of Funding*" input={
											<MSelect name="planned_source_of_funding" placeholder="Select"  >
												<MenuItem value="Self Funded(Reserves)">Self Funded(Reserves)</MenuItem>
												<MenuItem value="Unsecured Loans">Unsecured Loans</MenuItem>
												<MenuItem value="Bank Loans">Bank Loans</MenuItem>
												<MenuItem value="Angle Investors">Angle Investors</MenuItem>
												<MenuItem value="Venture Capital">Venture Capital</MenuItem>
												<MenuItem value="New Equity">New Equity</MenuItem>
												<MenuItem value="Crypto(ICO)">Crypto(ICO)</MenuItem>
												<MenuItem value="Grants">Grants</MenuItem>
												<MenuItem value="Others - Please Specify">Others - Please Specify</MenuItem>
											</MSelect>
										} />

										{values?.planned_source_of_funding == "Others - Please Specify" && <Flex gap={8}>
											<InputRow label={''} style={{ width: "100%" }} input={
												<MInput name="planned_source_of_funding_please_specify" placeholder="Please Specify" />
											} />
										</Flex>
										}
									</Box>
									<Box style={{ width: "50%" }} >
										<InputRow label="Actual Source of Funding*" input={
											<MSelect name="actual_source_of_funding" placeholder="Select"  >
												<MenuItem value="New Equity">New Equity</MenuItem>

												<MenuItem value="Crypto(ICO)">Crypto(ICO)</MenuItem>

												<MenuItem value="Bank Loans">Bank Loans</MenuItem>

												<MenuItem value="Self Funded(Reserves)">Self Funded(Reserves)</MenuItem>
												<MenuItem value="Unsecured Loans">Unsecured Loans</MenuItem>
												<MenuItem value="Grants">Grants</MenuItem>

												<MenuItem value="Angle Investors">Angle Investors</MenuItem>
												<MenuItem value="Venture Capital">Venture Capital</MenuItem>


												<MenuItem value="Others - Please Specify">Others - Please Specify</MenuItem>
											</MSelect>
										} />

										{values?.actual_source_of_funding == "Others - Please Specify" && <Flex gap={8}>
											<InputRow label={''} style={{ width: "100%" }} input={
												<MInput name="actual_source_of_funding_please_specify" placeholder="Please Specify" />
											} />
										</Flex>
										}
									</Box>
								</Box>
							</Box>

							<Box marginTop={2} >
								<Box display="flex" style={{ gap: "10px" }} width={"100%"}>
									<Box width={"50%"} >
										<InputRow label="Expected Cost of Capital in %" input={
											<MInput name="expected_cost_of_capital" placeholder="" />
										} />
									</Box>
									<Box width={"50%"} >
										<InputRow label="Actual Cost of Capital in %" input={
											<MInput name="actual_cost_of_capital" placeholder="" />
										} />
									</Box>
								</Box>

							</Box>

							<Box marginTop={2} >
								<Box display="flex" style={{ gap: "10px" }} width={"100%"}>
									<Box width={"50%"} >
										<InputRow label="Project Impact Name" input={
											<MInput name="project_impact_name" placeholder="" />
										} />
									</Box>
									<Box width={"50%"} >
										<InputRow label="Project Impact/Output" style={{ width: "100%" }} input={
											<MSelect name="project_impact_output" placeholder="Select"  >
												<MenuItem value="Million $ USD">Million $ USD</MenuItem>
												<MenuItem value="Volunteer Hours">Volunteer Hours</MenuItem>
												<MenuItem value="Number">Number</MenuItem>
												<MenuItem value="Average">Average</MenuItem>
												<MenuItem value="Metric CO2 Tons">Metric CO2 Tons</MenuItem>
												<MenuItem value="MWh">MWh</MenuItem>
												<MenuItem value="MMBtu">MMBtu</MenuItem>
												<MenuItem value="Million Cubic Meters">Million Cubic Meters</MenuItem>
												<MenuItem value="Litres">Litres</MenuItem>
												<MenuItem value="Others - Please Specify">Others - Please Specify</MenuItem>

											</MSelect>
										} />
										{values?.project_impact_output == "Others - Please Specify" && <Flex gap={8}>
											<InputRow label={''} style={{ width: "100%" }} input={
												<MInput name="project_impact_output_please_specify" placeholder="Please Specify" />
											} />
										</Flex>
										}
									</Box>



								</Box>
							</Box>
							<Box marginTop={2} >
								<Box width="100%" display="flex" style={{ gap: "10px" }} >
									<Box width="50%" display="flex" flexDirection="column" >
										<InputRow label={"Project Impact Outcome"} input={
											<MCountedTextArea name="project_impact_outcome" placeholder="Project Impact Outcome" />} />
									</Box>
									<Box width="50%" >
										<InputRow label="Upload Project Plan" input={
											<>
												<div style={{ margin: '10px 0' }}>
													<UploadButton
														multiple
														input
														name="ongoing_project_pdfs"
														setFileInState={this.setFilesArray}
														onDelete={this.removeAttachments}
													/>
												</div>
											</>
										} />

									</Box>

								</Box>
							</Box>

							<Box marginTop={2} >
								<Box display="flex" style={{ gap: "10px" }} width={"100%"}>
									<InputRow data-test-id="projectInvestor" label="Project Investor" style={{ width: "50%" }} input={<> <SearchSelect
										data-test-id="projectInvestorSearchSelect"
										multiple={true}
										pagination={this.state.investorsPagination}
										hasMore={!!this.state.investorsPagination?.next_page}
										nextPage={this.paginateInvestors}
										loading={false}
										onSearch={this.handleInvestorsSearchQuery}
										allItems={[...this.state.onGoingProjectInvestorsList, ...this.state.investorsList.filter((item: any) => !this.state.onGoingProjectInvestorsIdList.includes(item.id))]}
										items={[...this.state.onGoingProjectInvestorsList, ...this.state.investorsList.filter((item: any) => !this.state.onGoingProjectInvestorsIdList.includes(item.id))]}
										name="project_investor"
										selectProps={{ onClose: this.handleInvestorsClose }}
										SelectItem={SelectItemUser}
										isAvatar={true}
										getOptionValue={(user: any) => user.profile_pic} /></>} />
									<Box width={"50%"} >
										<InputRow label="Project Created By*" input={<><div style={{
											display: "flex", fontSize: 14,
											borderRadius: "5px",
											border: "1px solid #BEBEBE",
											height: "45px",
											textAlign: "center",
											alignItems: "center",
											justifyContent: "start",
											padding: "0 10px",
										}}>
											<Avatar alt={`${initialValues?.created_by?.first_name} ${initialValues?.created_by?.first_name}`} src={initialValues.created_by?.profile_pic} style={{ width: "30px", height: "30px", marginRight: "10px" }} />
											<Typography style={{ color: "#333232" }}>
												{initialValues?.created_by?.first_name} {initialValues?.created_by?.last_name}
											</Typography>
										</div>
										</>} />
									</Box>


								</Box>
							</Box>

					<Box marginTop={2} >
						<Box display="flex" style={{gap:"10px"}} width={"100%"}>
						<InputRow data-test-id="projectCollaborators" label="Project Collaborators" style={{ disabled: "true", width: "50%" }} input={<> <SearchSelect
                                            data-test-id="projectCollaboratorsSearchSelect"
											multiple={true}
                                            pagination={this.state.collaboratorPagination}
                                            hasMore={!!this.state.collaboratorPagination?.next_page}
                                            nextPage={this.paginateCollaborators}
                                            loading={false}
                                            onSearch={this.handleCollaboratorsSearchQuery}
                                            allItems={CL}
                                            items={CL}
                                            name="project_collaborators"
											selectProps={{ onClose: this.handleCollaboratorsClose }}
                                            SelectItem={SelectItemUser}
											isAvatar={true}
                                            getOptionValue={(user: any) => user.profile_pic}/></>} />
						
						<InputRow label="Created On*" style={{ width: "50%" }} input={
							<MDatePicker name="created_on" disabled InputProps={{ placeholder: "" }}/>} />
							
						</Box>
					</Box>
					{(this.context.user_type != "licensed_user" || this.state.licenceFlag) &&
					<Box data-test-id="BtnBox" marginTop={4} display="flex" justifyContent="space-between" >
					<Button
					data-test-id="auditTrailBtn"
					style={{backgroundColor:"#009c05",padding:"12px 30px", color:"white", borderRadius:"5px", fontSize:"13px", fontWeight:"bold"}}
					onClick={async()=>{
						await setStorageData('project_id',AnOngoingProjectData?.attributes?.project_id)
						this.props.navigation.history.push(`AuditTrail/${AnOngoingProjectData?.attributes?.project_id}`)
					}}
					>AUDIT TRAIL</Button>
					<Button
					data-test-id="milestoneBtn"
					style={{backgroundColor:"#009c05",padding:"12px 30px", color:"white", borderRadius:"5px", fontSize:"13px", fontWeight:"bold"}}
					type="submit"
					onClick={async()=>{
						await setStorageData('projectId',AnOngoingProjectData?.id)
						await setStorageData('projectName', AnOngoingProjectData?.attributes?.project_name)
						this.props.navigation.history.push(`ProjectMilestone/${AnOngoingProjectData?.id}`)
						
					}}
					
					>PROJECT PHASES AND MILESTONE</Button>
					
					{this.saveButton(isValid, dirty)}
					</Box>
					}


						</ViewFunding>
					</form>
				)}
			</Formik>

		</>
	}


	ViewFunding = () => {}

    // Customizable Area End


	render() {

		// Customizable Area Start
		// Customizable Area End
		return (
		// Customizable Area Start
		<>
			<ProjectMainBox>
			<OverlayLoader loading={this.state.loading} />
				<Box> <TopNavBar navigation={this.props.navigation} id={""} /> </Box>

				<OutGrid container spacing={3}>
					<LeftSideGrid item xs={12} sm={12} md={3} lg={3}> <Box > <LeftMenuWeb navigation={this.props.navigation} id={""} /> </Box> </LeftSideGrid>
					{this.state.seekingFundButton ?
						<MiddleSideGrid item xs={12} sm={12} md={6} lg={6} > {this.state.onSeekingFundEdit ? this.ViewFunding() : this.PeopleManagementTabs()} </MiddleSideGrid> :
						<MiddleSideGrid item xs={12} sm={12} md={6} lg={6} > {this.state.onGoingProjectEdit ? this.viewOngoingProject() : this.PeopleManagementTabs()} </MiddleSideGrid>}
					<Modal
						id="ModalComponent"
						open={this.state.openSeekingFundsDetailModel}
						onClose={() => { this.setState({ openSeekingFundsDetailModel: false }) }}
						aria-labelledby="simple-model-title"
						aria-describedby="simple-model-description"
					>

						<SeekingFundDetailModel navigation={this.props.navigation} detail={this.state.viewProjectData} handleLikeChange={this.handleLikeChange} onCloseClick={() => { this.setState({ ...this.state, openSeekingFundsDetailModel: false }) }} />

					</Modal>

				</OutGrid>

			</ProjectMainBox>
		</>
		// Customizable Area End
		)
	}
}

// Customizable Area Start
const SelectItemUser = ({ item, value }: { item: any, value: any[] }) => {

	return <div style={{ width: '100%', display: 'flex', flexDirection: 'row-reverse', alignItems: 'center' }}>
		<ListItemText style={{ flex: 2 }} disableTypography primary={
			<div style={{ display: "flex", alignItems: "center" }}>
				<Avatar alt="Remy Sharp" src={item?.profile_pic} style={{ width: "30px", height: "30px", marginRight: "10px" }} />

				<Typography style={{ color: "#333232", fontWeight: "bold", width: "30%", fontSize: 12, overflow: 'hidden', textOverflow: 'ellipsis' }}>
					{item?.first_name} {item?.last_name}
				</Typography>
				<Typography component={'div'} style={{ width: '65%', fontSize: 12, fontWeight: "bold", overflow: 'hidden', textOverflow: 'ellipsis' }}>
					{item?.email}
				</Typography>

				{Array.isArray(value) && <Checkbox
					icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
					checkedIcon={<CheckBoxIcon fontSize="small" style={{ color: "#1ea60a" }} />}
					checked={value?.findIndex((x) => Number(x) == item?.id) > -1}
				/>}

			</div>
		} />
	</div>
}



ProjectPortfolioController.contextType = UserContext

const onGoingProjectEditInputStyle = {
	padding: "10px 13px",
	border: "1px solid lightgray",
	borderRadius: "5px",
	width: "100%",
	marginTop: "10px",
	height: "46px"
}

const Pagin = styled('div')({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	padding: '10px',
	'@media screen and (max-width:350px)': {
	  display: 'initial',
	  padding: '10px 1px',
	},
	'@media screen and (min-width:768px) and (max-width:850px)': {
	  display: 'initial',
	  padding: '10px 1px',
	}
  })

const ProjectMainBox = styled(Box)({
	backgroundColor: "#edebeb",
	display: "flex",
	flexWrap: 'wrap',
	flexDirection: "column",
	paddingBottom: "20px",
	overflowX: "hidden",
})

const OutGrid = styled(Grid)({
	display: 'flex',
	flexWrap: 'wrap',
	justifyContent: 'space-between',
})



const LeftSideGrid = styled(Grid)({
	maxWidth: '300px',
	'@media screen and (max-width: 985px)': {
		maxWidth: '200px',
	},
	'@media screen and (max-width: 768px)': {
		display: 'none',
		visibility: 'hidden',
	},
})




const MiddleSideGrid = styled(Grid)({
	width: 'calc(100vw - 300px)',
	maxWidth: 'unset',
	flexBasis: 'unset',
	justifyContent: 'center',
	'@media screen and (max-width: 768px)': {
		width: '100%'
	}
})

const TextareaView = styled('div')({
	border: '2px solid #bdbdbd',
	color: '#bdbdbd',
	borderRadius: '5px',
	padding: '5px'
})


const JobLabel = styled('div')({
	fontSize: '14.35px',
	color: '#404040',
	fontFamily: 'HelveticaNeue'
})


const ViewFunding = styled('div')({
	backgroundColor: '#fff',
	borderRadius: "12.97px",
	padding: '25px',
	marginRight: '15px'
})

const ArroWrapper = styled('div')({
	color: 'gray',
	marginRight: '10px',
	fontWeight: 100
})



const CorporateLicenceTabs = styled(Button)({
	border: "2px solid transparant", color: "#009B07", fontWeight: 600, backgroundColor: "#FFFFFF", textTransform: "none", borderRadius: "38px", height: "100%",
	padding: "0 65px 0 65px",
	'@media screen and (max-width: 1110px) and (min-width: 769px)': {
		padding: "0 35px 0 35px",
	},
	'@media screen and (max-width: 624px)': {
		padding: "0 35px 0 35px",
	},
	'@media screen and (max-width: 500px)': {
		padding: "0 25px 0 25px",
	}
});
const IndividualUserTabs = styled(Button)({
	border: "2px solid transparant", color: "#009B07", fontWeight: 600, backgroundColor: "#FFFFFF", textTransform: "none", borderRadius: "38px", height: "100%",
	padding: "0 50px 0 50px",
	'@media screen and (max-width: 1110px) and (min-width: 769px)': {
		padding: "0 30px 0 30px",
	},
	'@media screen and (max-width: 624px)': {
		padding: "0 30px 0 30px",
	},
	'@media screen and (max-width: 500px)': {
		padding: "0 20px 0 20px",
	}
});

const PeoplePageWrapper = styled("div")({ display: 'flex', justifyContent: 'center', minHeight: '100vh' });

const PageWrapperCenterRow = styled("div")((props: any) => ({ width: '100%', }));

const RoundedButtonBaseTabs = styled('div')((props: any) => ({
	height: "47px", fontWeight: 700, display: 'flex', textAlign: 'center', justifyContent: 'center', backgroundColor: "#FFFFFF", flex: "wrap",
	color: "#009E00",
	borderRadius: '38px',
	border: " 2px solid #009B07"


}))

const RoundedButtonBaseCreate = styled(Button)({
	backgroundColor: '#009c05', color: 'white', borderRadius: '20px',
	'&:hover': {
		backgroundColor: '#009c05',
	},
	'@media screen and (max-width:600px)': {
		width: '100%'
	},
	'@media screen and (min-width:769px) and (max-width:1200px)': {
		width: '100%'
	}
})



const TemplateName = styled(Typography)({
	fontWeight: "bold",
	color: "#292D32",
	fontSize: '17px',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	textAlign: 'left',
	alignSelf: 'center',
	paddingLeft: '5px',
	whiteSpace: 'pre-wrap',
	'@media screen and (max-width:380px)': {
		fontSize: '14px',
	},
})

const SectionItem = styled(Box)({
	margin: "8px 0px",
	'& .MuiTypography-root.MuiTypography-subtitle1': {
		fontWeight: "bold",
		fontSize: 12,
	},
	'& .MuiTypography-root.MuiTypography-subtitle2': {
		fontWeight: "normal",
		color: "grey",
		fontSize: 12,
	}
})

const SpacingSection = styled('div')({
	padding: "10px"
})

const DetailSection = styled('div')({
	display: "flex",
})

const SectionText = styled(Box)({
	margin: "4px 0px",
	color: '#464646',
	width: '50%',

	'& .MuiTypography-root.MuiTypography-subtitle1': {
		fontWeight: "bold",
		fontSize: 12,

	},
	'& .MuiTypography-root.MuiTypography-subtitle2': {
		fontWeight: "normal",
		color: "grey",
		fontSize: 12,
	},
	'@media (max-width:1370px) and (min-width:1280px)': {
		marginTop: '20px'
	},
	'@media (max-width:1100px) and (min-width:960px)': {
		marginTop: '30px'
	}
})


const LiveBtnOpen = styled(Typography)({
	background: '#e4f4e7',
	border: '1px solid #E5EDE6',
	borderRadius: '10px',
	color: '#388141',
	padding: '8px 25px',
	fontSize: '14px',
	width: 'max-content',
	fontWeight: 'bold',
	// opacity: 0.11,
	mixBlendMode: 'normal',
	textTransform: 'capitalize',
})

const LiveBtnFunded = styled(Typography)({
	background: '#e3f0ff',
	border: '1px solid #e3f0ff',
	borderRadius: '10px',
	color: '#3187e0',
	padding: '8px 25px',
	fontSize: '14px',
	width: 'max-content',
	fontWeight: 'bold',
	// opacity: 0.11,
	mixBlendMode: 'normal',
	textTransform: 'capitalize',
})

const LiveBtnClosed = styled(Typography)({
	background: '#ffe4e5',
	border: '1px solid #ffe4e5',
	borderRadius: '10px',
	color: '#e93951',
	padding: '8px 25px',
	fontSize: '14px',
	width: 'max-content',
	fontWeight: 'bold',
	// opacity: 0.11,
	mixBlendMode: 'normal',
	textTransform: 'capitalize',
})

const LiveBtnStarted = styled(Typography)({
	background: '#ffff006e',
	borderRadius: '10px',
	color: '#bdbd33',
	padding: '8px 25px',
	fontSize: '14px',
	width: 'max-content',
	fontWeight: 'bold',
	mixBlendMode: 'normal',
	textTransform: 'capitalize',
})

const LiveBtnSuspended = styled(Typography)({
	background: '#ff00005c',
	borderRadius: '10px',
	color: '#ff0000',
	padding: '8px 25px',
	fontSize: '14px',
	width: 'max-content',
	fontWeight: 'bold',
	mixBlendMode: 'normal',
	textTransform: 'capitalize',
})

const LiveBtnWIP = styled(Typography)({
	background: '#ffa5006b',
	borderRadius: '10px',
	color: '#e89600',
	padding: '8px 25px',
	fontSize: '14px',
	width: 'max-content',
	fontWeight: 'bold',
	mixBlendMode: 'normal',
	textTransform: 'capitalize',
})

const LiveBtnCompleted = styled(Typography)({
	background: '#0080007a',
	borderRadius: '10px',
	color: '#008000',
	padding: '8px 25px',
	fontSize: '14px',
	width: 'max-content',
	fontWeight: 'bold',
	// opacity: 0.11,
	mixBlendMode: 'normal',
	textTransform: 'capitalize',
})

const LiveBtnOnHold = styled(Typography)({
	background: '#a52a2a82',
	borderRadius: '10px',
	color: '#a52a2a',
	padding: '8px 25px',
	fontSize: '14px',
	width: 'max-content',
	fontWeight: 'bold',
	mixBlendMode: 'normal',
	textTransform: 'capitalize',
})




const StyledAvatar = styled(AvatarGroup)({
	'& .MuiAvatar-root': { width: 24, height: 24, fontSize: 15 },
})

const StyledAvatarView = styled(AvatarGroup)({
	'& .MuiAvatar-root': { width: 18, height: 18, fontSize: 13 },
})

const BoldText = styled('span')({
	fontWeight: "bold",
	color: "#685b5b",
	cursor: "pointer"
})

const UserAvtarDetail = styled('div')({
	display: "flex",
	flexDirection: "row",
	alignItems: "center"
})
// Customizable Area End
import React,{ChangeEvent} from 'react'
// Customizable Area Start
import RiskManageMentController, {
  Props,
} from './RiskManageMentController';
import OverlayLoader from '../../../components/src/Loader.web'
import LeftMenuWeb from "../../../components/src/LeftMenuWeb";
import { Typography, Grid, Button, Box, TableBody, TableCell, MenuItem, Tooltip, ListSubheader, InputAdornment,Divider, Modal, } from '@material-ui/core'
import { styled } from '@material-ui/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Pagination from '@material-ui/lab/Pagination';
import { AddCircleIcon } from '../../../components/src/Icons.web';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TopNavBar from "../../../components/src/TopNavbar";
import { Tooltips, crossIcon, xlsxIcon, EditIcon,closeIcon, docIcon, imageIcon, csvIcon, pdfIcon, xlsIcon, genericIcon} from "./assets"
import { FilterSelect } from '../../CommunityForum/src/Components.web';
import { Formik, Form} from 'formik';
import FormModal, { InputRow, MCountedTextArea, MInput, MSelect } from '../../../components/src/FormModal.web';
import UploadButton from '../../../components/src/UploadButtonnew.web'
import UserContext from '../../../components/src/UserProvider'
// Customizable Area End
export default class RiskManageMent extends RiskManageMentController {

  constructor(props: Props) {
    // Customizable Area Start
    super(props);
    // Customizable Area End
  }

  // Customizable Area Start
  handleImpactedStakeholder = (value:any)=>{
    return  <> 
      <InputRow
                  label="Impacted Stakeholders*"
                  rowClass='formRow'
                  labelClass='formLabel'
                  input={<MSelect name="Impacted_Stakeholders"
                  >
                    <MenuItem value="Tax Authorities">Tax Authorities</MenuItem>
                    <MenuItem value="Ministry of Environment">Ministry of Environment</MenuItem>
                    <MenuItem value="NGOs/NPOs">NGOs/NPOs</MenuItem>
                    <MenuItem value="Corporate Law Authorities">Corporate Law Authorities</MenuItem>
                    <MenuItem value="Bankers">Bankers</MenuItem>
                    <MenuItem value="Stock Exchange">Stock Exchange</MenuItem>
                    <MenuItem value="Communities">Communities</MenuItem>
                    <MenuItem value="Customers">Customers</MenuItem>
                    <MenuItem value="Employees">Employees</MenuItem>
                    <MenuItem value="Suppliers">Suppliers</MenuItem>
                    <MenuItem value="Shareholders">Shareholders</MenuItem>
                    <MenuItem value="Others - Please Specify">Others - Please Specify</MenuItem>
                    <MenuItem value="None">None</MenuItem>
                  </MSelect>}
                />
                {value == "Others - Please Specify" && 
                            <InputRow label={''} style={{marginTop:"-30px"}} input={
                                <MInput name="impacted_stakeholders_please_specify" placeholder="Please Specify" />
                            } />
                        
                }
                </>

  }

  handleCategory = (value:any) =>{
    return <>
    <InputRow
                  label='Category*'
                  rowClass='formRow'
                  labelClass='formLabel'
                  input={
                    <MSelect name="categories" >
                      <MenuItem disabled value={""}>Select</MenuItem>
                      <MenuItem value="Risk Mitigation">Risk Mitigation</MenuItem>
                      <MenuItem value="Opportunity">Opportunity</MenuItem>
                      <MenuItem value="Contingency">Contingency</MenuItem>
                      <MenuItem value="Others - Please Specify">Others - Please Specify</MenuItem>
                    </MSelect>
                  }
                />
                {value == "Others - Please Specify" && 
                            <InputRow label={''} style={{marginTop:"-30px"}} input={
                                <MInput name="category_please_specify" placeholder="Please Specify" />
                            } />
                        
                        }
    </>
  }

  AddRiskManageMent() {

    const { preferences } = this.state;

    const initialValues = {
      objectiveId: this.getObjectiveId(),
      description: this.getAttribute('description'),
      preferences: this.getAttribute('preferences', preferences),
      pestle: this.getAttribute('pestile'),
      issues: this.getAttribute('issues_concern'),
      Scope_of_Operation: this.getAttribute('scope_of_operations'),
      Impact_Term: this.getAttribute('impact_term'),
      categories: this.getAttribute('category'),
      Reference: this.getAttribute('reference'),
      Impact_description: this.getAttribute('impact_desc'),
      Impact_range: this.getAttribute('impact'),
      probability: this.getAttribute('impact_probability'),
      Types_of_impact: this.getAttribute('type_of_impact'),
      Estimated_amount_of_impact: this.getAttribute('estimated_amount_of_impact'),
      Action_plan: this.getAttribute('action_plan'),
      Impacted_Stakeholders: this.getAttribute('impacted_stakeholders'),
      impacted_stakeholders_please_specify: this.getAttribute('impacted_stakeholders_please_specify'),
      Estimated_Financial_Impact: this.getAttribute('impact_on_finance'),
      Estimated_Impact_on_Enterprise: this.getAttribute('impact_on_enterprise_value'),
      attachments: this.getAttribute('pdf_documents'),
      category_please_specify: this.getAttribute('category_please_specify')
    };



    const materiality = [
      { value: [1, 5], bg: '#bbe33d' },
      { value: [6, 12], bg: '#ffff00' }, { value: [13, 20], bg: '#ffbf00' }, { value: [21, 25], bg: '#ff0000' }
    ]

    const condition1materiality = (Number(this.state.editData?.attributes?.materiality?.split(",")[0]) * Number(this.state.editData?.attributes?.materiality?.split(",")[1]))

    
    if (this.state.modal === "create") {
      return <Formik enableReinitialize initialValues={initialValues} onSubmit={this.handleSubmit} validationSchema={this.validationSchema}
      >
        {({ values, errors, setFieldValue }: any) => (
          <div style={{ width: '100%' }}>
            <FormModal headerTitle={<CreateHeader>
              {this.headerTitles(this.state.editData)}
            </CreateHeader>} submitLabel="Submit" onClose={() => this.setState({ modal: '' })} >
              <RightFormWrapper translate="no">
                <InputRow
                  label="Objective ID*"
                  rowClass='formRow'
                  labelClass='formLabel'
                  input={<MInput name="objectiveId" disabled />}
                />
                <InputRow label={<>Preferences*</>} input={
                  <FilterSelect items={this.allPreferences}
                  onChange={(selectedValue:ChangeEvent<HTMLSelectElement>) =>{
                    setFieldValue("preferences", selectedValue.target.value)
                    this.SelectPreferences(selectedValue)}
                  } name="preferences" 
                  value={values.preferences} >
                  </FilterSelect>
                } />
                <InputRow
                  label='Issue/Concern*'
                  rowClass='formRow'
                  labelClass='formLabel'
                  input={
                    <>
                      {this.getIssuesFlag(initialValues.issues) ?
                        <>
                          <MSelect name="issues" >
                            {this.state.Issues_concern.map((e: any, index: number) => (
                              <MenuItem key={index} value={e.label}>{e.label}</MenuItem>
                            ))}
                          </MSelect>
                        </>
                        : <MenuItem>Select Preference </MenuItem>
                      }
                    </>
                  }
                />
                {this.state.addAFactor ? <>
                  <InputRow
                    label='PESTLE*'
                    rowClass='formRow'
                    labelClass='formLabel'
                    input={
                      <MSelect name="pestle"  >
                        <ListSubheader style={{
                          position: "relative",
                          top: 0,
                          left: 0,
                        }}>Political</ListSubheader>
                        {this.state.PestleData.political.map((e: any, index: number) => (
                          <MenuItem value={e.name} key={index}>{e.name}</MenuItem>
                        ))}
                        <ListSubheader style={{
                          position: "relative",
                          top: 0,
                          left: 0,
                        }}>Economic Factor</ListSubheader>
                        {this.state.PestleData.Economic.map((e: any, index: number) => (
                          <MenuItem value={e.name} key={index}>{e.name}</MenuItem>
                        ))}
                        <ListSubheader style={{
                          position: "relative",
                          top: 0,
                          left: 0,
                        }}>Sociological Factors</ListSubheader>
                        {this.state.PestleData.Sociological_Factors.map((e: any, index: number) => (
                          <MenuItem value={e.name} key={index}>{e.name}</MenuItem>
                        ))}
                        <ListSubheader style={{
                          position: "relative",
                          top: 0,
                          left: 0,
                        }}>Technological Factors</ListSubheader>
                        {this.state.PestleData.Technological_Factors.map((e: any, index: number) => (
                          <MenuItem value={e.name} key={index}>{e.name}</MenuItem>
                        ))}
                        <ListSubheader style={{
                          position: "relative",
                          top: 0,
                          left: 0,
                        }}>Legal Factors</ListSubheader>
                        {this.state.PestleData.Legal_Factors.map((e: any, index: number) => (
                          <MenuItem value={e.name} key={index}>{e.name}</MenuItem>
                        ))}
                        <ListSubheader style={{
                          position: "relative",
                          top: 0,
                          left: 0,
                        }}>Environmental Factors</ListSubheader>
                        {this.state.PestleData.Enviromental_Factors.map((e: any, index: number) => (
                          <MenuItem key={index} value={e.name}>{e.name}</MenuItem>
                        ))}
                        <MenuItem onClick={() => { this.setState({ addAFactor: false }) }}>Add A Factor</MenuItem>
                      </MSelect>
                    }
                  />
                </> : <>

                  <InputRow
                    label='Add Factor'
                    rowClass='formRow'
                    labelClass='formLabel'
                    input={
                      <MInput name="pestle" InputProps={{
                        endAdornment: (
                          <InputAdornment position="end" >
                            <CrossIcons style={{
                              color: "black",
                              fontSize: '1.9rem',
                              background: '#938e8e',
                              borderRadius: ' 21px',
                              width: '19px',
                            }} onClick={() => { this.setState({ addAFactor: true }) }} />
                          </InputAdornment>
                        ),
                      }} />
                    }
                  />
                </>}

                <InputRow
                  label='Scope Of Operations*'
                  rowClass='formRow'
                  labelClass='formLabel'
                  input={
                    <MSelect name="Scope_of_Operation" >
                      <MenuItem disabled value={-1}>Select</MenuItem>
                      <MenuItem value="Global">Global</MenuItem>
                      <MenuItem value="Region">Region</MenuItem>
                      <MenuItem value="Unit/Zone">Unit/Zone</MenuItem>
                      <MenuItem value="Country">Country</MenuItem>
                    </MSelect>
                  }
                />
                <InputRow label= 'Description*' input={
                  <MCountedTextArea name="description" />
                } />
                
                {this.handleCategory(values?.categories)}
                <InputRow
                  label='Reference*'
                  rowClass='formRow'
                  labelClass='formLabel'
                  input={
                    <MSelect name="Reference" >
                      <MenuItem disabled value={-1}>Select</MenuItem>
                      <MenuItem value="Assessment Report">Assessment Report</MenuItem>
                      <MenuItem value="Audit Committee Recommendation">Audit Committee Recommendation</MenuItem>
                      <MenuItem value="Govt Recommendation">Govt Recommendation </MenuItem>
                      <MenuItem value="Assumption">Assumption</MenuItem>
                      <MenuItem value="Compliance">Compliance</MenuItem>
                      <MenuItem value="Media Reports">Media Reports</MenuItem>
                      <MenuItem value="Internal Stakeholders">Internal Stakeholders</MenuItem>
                      <MenuItem value="External Stakeholders">External Stakeholders</MenuItem>
                    </MSelect>
                  }
                />
                <div style={{color:"#404040",fontSize: "13px", fontWeight: "bolder"}}>Document*</div>
                <div style={{ margin: '10px 0' }}>
                  <UploadButton
                    multiple
                    onDelete={this.removeAttachments}
                    setFileInState={this.setFilesArray}
                    name="attachments"
                    label="Upload Attachment"
                  />
                </div>
                
                <InputRow
                  label="Impact - Description*"
                  rowClass='formRow'
                  labelClass='formLabel'
                  input={<><MInput name="Impact_description" /></>}
                />
                <InputRow
                  label='Impact(1-5)*'
                  rowClass='formRow'
                  labelClass='formLabel'
                  input={
                    <MSelect name="Impact_range">
                      <MenuItem value={5}>Very high</MenuItem>
                      <MenuItem value={4}>High</MenuItem>
                      <MenuItem value={3}>Moderate</MenuItem>
                      <MenuItem value={2}>Low</MenuItem>
                      <MenuItem value={1}>Negligible</MenuItem>
                    </MSelect>
                  }
                />
                <InputRow
                  label='Probablity(1-5)*'
                  rowClass='formRow'
                  labelClass='formLabel'
                  input={
                    <MSelect name="probability">
                      <MenuItem value={5}>Most Likely</MenuItem>
                      <MenuItem value={4}>Likely</MenuItem>
                      <MenuItem value={3}>Moderate</MenuItem>
                      <MenuItem value={2}>Less likely</MenuItem>
                      <MenuItem value={1}>Least likely</MenuItem>
                    </MSelect>
                  }
                />
                {this.state.editData ?
                  <>
                    <Typography style={{ fontWeight: "bold", fontSize: 13, marginBottom: '-13px', color: '#403838' }}>
                      Materiality
                    </Typography>
                    {materiality.map((mat: any, index: number) => (
                      this.getCondition1materiality(mat, condition1materiality) ? <ColoredBox style={{ background: `${mat.bg}`, padding: "20px" }} key={index}>{this.state.editData?.attributes?.materiality}</ColoredBox> : <></>
                    ))}
                  </>
                  : <InputRow label="Materiality" rowClass="formRow" labelClass='formLabel'
                    input={
                      <MInput disabled name="materiality" variant="filled" />
                    }
                  />
                }
                <InputRow
                  label='Types of Impact*'
                  rowClass='formRow'
                  labelClass='formLabel'
                  input={
                    <MSelect name="Types_of_impact">
                      <MenuItem value="Direct">Direct</MenuItem>
                      <MenuItem value="Indirect-Dependency">Indirect-Dependency</MenuItem>
                      <MenuItem value="Indirect-Relationship">Indirect-Relationship</MenuItem>
                    </MSelect>
                  }
                />
                <InputRow
                  label='Estimated Financial Impact*'
                  rowClass='formRow'
                  labelClass='formLabel'
                  input={
                    <MSelect name="Estimated_Financial_Impact">
                      <MenuItem value="Income Statement">Income Statement (+/-)</MenuItem>
                      <MenuItem value="Cash Flow Statement">Cash Flow Statement (+/-)</MenuItem>
                      <MenuItem value="Balance Sheet">Balance Sheet (+/-)</MenuItem>
                    </MSelect>
                  }
                />
                <InputRow
                  label='Estimated Impact On Enterprise Value*'
                  rowClass='formRow'
                  labelClass='formLabel'
                  input={
                    <MSelect name="Estimated_Impact_on_Enterprise">
                      <MenuItem value="Cash Inflows">Cash Inflows</MenuItem>
                      <MenuItem value="Cash Outflows">Cash Outflows</MenuItem>
                      <MenuItem value="Increase Number Of Shares(Raising Equity)">Increase Number Of Shares(Raising Equity)</MenuItem>
                      <MenuItem value="Reduction Of Shares/Number Of Shares">Reduction Of Shares/Number Of Shares</MenuItem>
                      <MenuItem value="Expected Increase In Share Price">Expected Increase In Share Price</MenuItem>
                      <MenuItem value="Expected Decrease In Share Price">Expected Decrease In Share Price</MenuItem>
                      <MenuItem value="Positive Impact On BV Of Short Term Debt">Positive Impact On BV Of Short Term Debt</MenuItem>
                      <MenuItem value="Negative Impact On BV Of Short Term Debt">Negative Impact On BV Of Short Term Debt</MenuItem>
                      <MenuItem value="Positive Impact On BV Of Long Term Debt">Positive Impact On BV Of Long Term Debt</MenuItem>
                      <MenuItem value="Negative Impact On BV Of Long Term Debt">Negative Impact On BV Of Long Term Debt</MenuItem>
                    </MSelect>
                  }
                />
                <InputRow
                  label="Estimated Amount of Impact*"
                  rowClass='formRow'
                  labelClass='formLabel'
                  input={<><MInput name="Estimated_amount_of_impact"
                  /></>}
                />
                <InputRow
                  label="Impact Term*"
                  rowClass='formRow'
                  labelClass='formLabel'
                  input={<MSelect name="Impact_Term">
                    <MenuItem value="Immediate (0-2 years) ">Immediate (0-2 years) </MenuItem>
                    <MenuItem value="Short Term (2-5 years)">Short Term (2-5 years)</MenuItem>
                    <MenuItem value="Medium Term (5-10 years)">Medium Term (5-10 years)</MenuItem>
                    <MenuItem value="Long Term (10 years)">Long Term (10 years)</MenuItem>
                  </MSelect>}
                />
                <InputRow
                
                  label="Action Plan*"
                  rowClass='formRow'
                  labelClass='formLabel'
                  input={<>
                    <MSelect name="Action_plan">
                      <MenuItem value="Avoided">Avoided</MenuItem>
                      <MenuItem value="Transferred">Transferred</MenuItem>
                      <MenuItem value="Delayed">Delayed</MenuItem>
                      <MenuItem value="Reduce/Prevent">Reduce/Prevent</MenuItem>
                      <MenuItem value="Accept">Accept</MenuItem>
                      <MenuItem value="Initiative To Be Taken">Initiative To Be Taken</MenuItem>
                    </MSelect>
                  </>}
                />
                
                {this.handleImpactedStakeholder(values?.Impacted_Stakeholders)}
                {(
                  <Grid item xs={12} style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    flexDirection: 'row',
                  }}>
                  </Grid>)
                }
              </RightFormWrapper>
            </FormModal>
          </div>
        )}
      </Formik>
    }

  }
  fileIcon(fileType: string, filename: string) {
    const extension = filename && filename.split(".").pop();
    const imageType = fileType && fileType.split('/')?.[0];
    if (fileType === 'image' || imageType === 'image') {
        return imageIcon;
    } else if (fileType === 'text' || extension === 'csv') {
        return csvIcon;
    } else if (fileType === 'application' || fileType === 'application/pdf' || imageType === "application/vnd.ms-excel" || imageType === "application") {
        // doc, xls, pdf
        if (extension === 'pdf') {
            return pdfIcon;
        } else if (extension === 'doc' || extension === 'docx') {
            return docIcon;
        } else if (extension === 'xls' || extension === 'xlsx') {
            return xlsIcon;
        }
    } else {
        return genericIcon;
    }
}

  handleOthersPleaseSpecify = (row:any) => {
    return <TR>{row.attributes.category === "Others - Please Specify" ? row.attributes.category_please_specify : row.attributes.category }</TR>

  }
  handleOthersPleaseSpecifyIS = (row:any) =>{
    return <TR>{row.attributes.impacted_stakeholders === "Others - Please Specify" ? row.attributes.impacted_stakeholders_please_specify : row.attributes.impacted_stakeholders}</TR> 

  }
  handlePreference = (row:any) =>{
    return (
      <TR>
          {row.attributes.preferences.map((p:any, i:any)=> {
            if(row.attributes.preferences.length === 1 || row.attributes.preferences.length === i+1 ){
              return<>
                {p}
                  </>
            }else if(row.attributes.preferences.length > 1 || row.attributes.preferences.length < i){
              return<>
                {p},
              </>
            }
                              
          })}                          
      </TR>
    )
  }

  handleDocument = (data:any) => {
      return(
        <TR >

        {data?
        data.map((item:any)=>{
        
          return <WrapDoc key="" style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', justifyContent: 'center' }}>
          {item?.filename?.length > 15 ? <Tooltip title={<div style={{
            height: 'auto',
            fontSize: '13px',
            width: 'auto',
            color: 'white',
            overflow: 'hidden',
            margin: "0px"
        }}>
          {item?.filename}
        </div>}>
        <span>{item?.filename.slice(0, 15)}...</span>
        </Tooltip> 
                                : 
                                item?.filename}
                                
                                <img id="iconbtn" src={this.fileIcon(item.type, item.filename)} width="30px" height="20px" onClick={() => window.open(item.url)} />
          </WrapDoc>}) :
           <>--</>}
        </TR>
      )
    
  }

  handleimpactdiscription = (data: any) => {
    if (data) {
      return (
        <TR><div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
        {data?.substring(0, 22)}{"..."}
        <Tooltip title={<div style={{
          height: 'auto',
          fontSize: '13px',
          width: 'auto',
          color: 'white',
          overflow: 'hidden',
          margin: "0px"
        }}>
          {data}
        </div>}>
          {<BtnImg src={Tooltips} style={{ marginLeft: '10px' }} />}
        </Tooltip>
      </div></TR>
      )
    }
    else {
      return (
        <TR>--</TR>
      )
    }
  }
  handleConclusion = (row: any) => {
    if (row.attributes.conclusion) {
      return (
        <PlanCardButton className='createProjectBtn' style={{ backgroundColor: "white", color: "#00A100", height: "45px", border: '1px solid #00A100', width: '90px', display: 'flex', justifyContent: 'center', justifyItems: 'center', padding: '5px' }} onClick={() => this.handleProjectEval(row.attributes.objective_id, true)}>
          <Typography style={{ fontSize: '15px', fontWeight:"bold" }}>{row.attributes.conclusion.substring(0, 10)}</Typography>
        </PlanCardButton>
      )
    }
    else {
      return (
        <PlanCardButton className='createProjectBtn' style={{ backgroundColor: "#00A100", color: "white", height: "45px" }} onClick={() => this.handleProjectEval(row.attributes.objective_id, false)}>
          <Typography style={{ fontSize: '15px', fontWeight:"bold" }}>Create Project</Typography>
        </PlanCardButton>
      )

    }
  }

  // Customizable Area End
  render() {
    // Customizable Area Start
    const AddRisk = this.AddRiskManageMent();
    const Impact = [
      { title: "Negligible", color: "white", bg: "#07fa09" },
      { title: "Low", color: "black", bg: "#9ff6a0" },
      { title: "Moderate", color: "black", bg: "yellow" },
      { title: "High", color: "white", bg: "#ff972f" },
      { title: "Very High", color: "white", bg: "red" },
    ]
    const materiality = [
      { value: [1, 5], bg: '#bbe33d' },
      { value: [6, 12], bg: '#ffff00' }, { value: [13, 20], bg: '#ffbf00' }, { value: [21, 25], bg: '#ff0000' }
    ]

    const probablity = [
      { title: "Least likely" }, { title: "Less likely" }, { title: "Moderate" }, { title: "Likely" }, { title: "Most likely" }
    ]
    const path = this?.context?.user_type == "licensed_user" ? 'Corporatepermission' : 'ESGJourney'

    // Customizable Area End
    return (

      // Customizable Area Start
      <MainBox>
        {AddRisk}
        <OverlayLoader loading={this.state.loading} />
        <Box>
          <TopNavBar navigation={this.props.navigation} id={""} />
        </Box>
        <OuterGrid container spacing={3}>
          <LeftGrid item xs={12} sm={12} md={3} lg={3}>
            <Box >
              <LeftMenuWeb navigation={this.props.navigation} id={""} />
            </Box>
          </LeftGrid>
          <MiddleGrid item xs={12} sm={12} md={6} lg={6} >
            <ModalTitle item xs={12} >
              <div style={{ display: "flex", alignItems: "center" }} >
                <span onClick={() => this.props.navigation.navigate(path)} style={{ margin: "13px 0px 0px 12px", cursor: "pointer" }} >ESG Journey</span>
                <div style={{ margin: "10px 0px 0px 12px", }}  >
                  <ArrowBackIosIcon />
                </div>
                <Typography style=
                  {{
                    paddingTop: '14px',
                    fontWeight: 'bold'
                  }}>
                  Risk Management
                </Typography>
              </div>
              <PlanCardButton 
                style={{ backgroundColor: "#00A100", color: "white", height: "45px" }} 
                id='addObjBtn'
                onClick={this.handleAddObj}
                disabled= {this.state.isdisabled}>
                <AddCircleIcon style={{ width: '27px', margin: '10px' }} />
                <Typography>ADD OBJECTIVE</Typography>
              </PlanCardButton>
            </ModalTitle>
            <Paper >
              <TableContainer >
                <Table stickyHeader aria-label="sticky table" >
                  <TableHead>
                    <TableRow >
                      <TR style={{ fontWeight: 'bold', color: 'black' }}>Objective ID</TR>
                      <TR style={{ fontWeight: 'bold', color: 'black' }}>Issue/Concern</TR>
                      <TR style={{ fontWeight: 'bold', color: 'black' }}>Preferences</TR>
                      <TR style={{ fontWeight: 'bold', color: 'black' }}>Pestle</TR>
                      <TR style={{ fontWeight: 'bold', color: 'black' }}>Scope Of Operations</TR>
                      <TR style={{ fontWeight: 'bold', color: 'black' }}>Description</TR>
                      <TR style={{ fontWeight: 'bold', color: 'black' }}>Category</TR>
                      <TR style={{ fontWeight: 'bold', color: 'black' }}>Reference</TR>
                      <TR style={{ fontWeight: 'bold', color: 'black' }}>Documents</TR>
                      <TR style={{ fontWeight: 'bold', color: 'black' }}>Impact-Desc</TR>
                      <TR style={{ fontWeight: 'bold', color: 'black' }}>Impact(1-5)</TR>
                      <TR style={{ fontWeight: 'bold', color: 'black' }}>Probability(1-5)</TR>
                      <TR style={{ fontWeight: 'bold', color: 'black' }}>Materiality</TR>
                      <TR style={{ fontWeight: 'bold', color: 'black' }}>Type Of Impact</TR>
                      <TR style={{ fontWeight: 'bold', color: 'black' }}>Estimated Financial Impact </TR>
                      <TR style={{ fontWeight: 'bold', color: 'black' }}>Estimated Impact on Enterprise value</TR>
                      <TR style={{ fontWeight: 'bold', color: 'black' }}>Amount Of Impact</TR>
                      <TR style={{ fontWeight: 'bold', color: 'black' }}>Impact Term</TR>
                      <TR style={{ fontWeight: 'bold', color: 'black' }}>Action Plan</TR>
                      <TR style={{ fontWeight: 'bold', color: 'black' }}>Impacted Stakeholders</TR>
                      <TR style={{ fontWeight: 'bold', color: 'black' }}>Recommended Projects</TR>
                      <TR style={{ fontWeight: 'bold', color: 'black' }}>Edit</TR>
                      <TR style={{ fontWeight: 'bold', color: 'black' }}>Conclusion</TR>
                      <THLast></THLast>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.allobjectives.map((row: any, i: number) => (
                      <>
                        <TableRow>
                          <TR>{row.attributes.objective_id}</TR>
                          <TR>{row.attributes.issues_concern}</TR>
                          {this.handlePreference(row)}
                          
                          <TR>{row.attributes.pestile}</TR>
                          <TR>{row.attributes.scope_of_operations}</TR>
                          <TR><div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                            {row.attributes.description?.substring(0, 15)}{"..."}
                            <Tooltip title={<div style={{
                              height: 'auto',
                              fontSize: '13px',
                              width: 'auto',
                              color: 'white',
                              overflow: 'hidden',
                              margin: "0px"
                            }}>
                              {row.attributes.description}
                            </div>}>
                              {<BtnImg src={Tooltips} style={{ marginLeft: '10px' }} />}
                            </Tooltip>
                          </div>
                          </TR>
                          {this.handleOthersPleaseSpecify(row)}
                          <TR>{row.attributes.reference}</TR>
                          {this.handleDocument(row?.attributes?.pdf_documents)}
                          
                          {this.handleimpactdiscription(row.attributes?.impact_desc)}

                          <TR>
                            {Impact.map((imp: any, i: number) => (
                              row.attributes.impact == i + 1 ? <ColoredBox style={{ color: `${imp.color}`, background: `${imp.bg}`, margin: 'auto' }} key={i}>{row.attributes.impact}</ColoredBox> : <></>
                            ))}
                          </TR>
                          <TR>
                            {probablity.map((pro: any, index: number) => (
                              row.attributes.impact_probability == index + 1 ? <>{pro.title}</> : <></>
                            ))}
                          </TR>
                          <TR>
                            {materiality.map((mat: any, i: number) => (
                              (row.attributes.impact * row.attributes.impact_probability >= mat.value[0] && row.attributes.impact * row.attributes.impact_probability <= mat.value[1]) ? <ColoredBox key={i} style={{ background: `${mat.bg}`, margin: 'auto' }}>{row.attributes.materiality}</ColoredBox> : <></>
                            ))}

                          </TR>
                          <TR>{row.attributes.type_of_impact}</TR>
                          <TR>{row.attributes.impact_on_finance}</TR>
                          <TR>{row.attributes.impact_on_enterprise_value}</TR>
                          <TR>{row.attributes.estimated_amount_of_impact}</TR>
                          <TR>{row.attributes.impact_term}</TR>
                          <TR>{row.attributes.action_plan}</TR>
                          {this.handleOthersPleaseSpecifyIS(row)}
                          <TR>
                            <PlanCardButton className='recommendedProjBtn' style={{ backgroundColor: "white", color: "#00A100", padding: '7px', border: '1.5px solid #096b09' }} onClick={() => this.getRecommendedProject(row)} >
                              <Typography style={{ lineHeight: '100%', color: '#096b09',fontSize:"13px", fontWeight:"bold"}}>Recommended projects</Typography>
                            </PlanCardButton>
                          </TR>
                          <TR className='editObjBtn' onClick={() => this.handleEdit(row.attributes.objective_id)}><img src={EditIcon} width="20px" /></TR>
                          <TR >
                            {this.handleConclusion(row)}
                          </TR>
                        </TableRow>
                      </>
                    ))}
                  </TableBody>
                </Table>
                <Pagin>
                  <Pagination color="primary" variant="outlined" count={this.state.total_pages} page={this.state.pages} onChange={this.handlePageChange} />
                </Pagin>
              </TableContainer>
            </Paper>
          </MiddleGrid>
        </OuterGrid>
        <Modal
            id="RecommendedProjectModalComponent"
            open={this.state.openRPModel}
            onClose={() => this.handleClose()}
        >
            <RecommendedModel projects ={this.state?.recommendedProjectData} page={this.state.current_page_rp} handlePageChangeInRP={this.handlePageChangeInRP} count={this.state.total_pages_rp} onCloseClick={this.handleClose} onDownloadClick={this.handleRecomendProject} reportLink={this.state?.recommendedProjectLink} />
        </Modal>
      </MainBox>
      // Customizable Area End
    )
  }
}


// Customizable Area Start
export const RecommendedModel = ({ projects, onCloseClick,onDownloadClick,reportLink,page,count,handlePageChangeInRP }: any) => {
  const headers = Object.keys(projects[0].attributes).map((head:string) => head.split('_').map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' '));
  return <ModalSection>
      <SurveyModelWrapper>
          <SectionItem style={{ fontSize: "14px" }}>
              <TitleSection>
                  <Typography style={{ fontSize: "18px", color: "#2A3035" }} variant="subtitle1" component="div">
                    RECOMMENDED PROJECTS
                  </Typography>
                  <DivSection>
                      <PlanCardButton id='recommendedProjectDownloadBtn' style={{border: '1px solid',padding:'5px 15px' , marginRight: '15px' }} onClick={() => onDownloadClick()} >
                        <BtnImg id="xlsxIconBtn" src={xlsxIcon} alt="xlsxIcon" />
                        <Typography style={{ lineHeight: '100%', color: '#1EA60A' }}>Download</Typography>
                      </PlanCardButton>
                      <CloseBtn id="CloseBtn" src={closeIcon} onClick={() => onCloseClick()} alt="close" />
                  </DivSection>
              </TitleSection>
          </SectionItem>
          <Divider />
          <SurveyModelSection>
          {headers && headers.length ?
           <TableContainer >
              <Table stickyHeader aria-label="sticky table" >
                  <TableHead>
                      <TableRow>
                      {headers.map(header => (
                          <TR key={header} style={{ fontWeight: 'bold', color: 'green' }}>{`${header} ${header === 'Project Suggestions' ? '(Project Name)' : ''}`}</TR>
                        ))}
                      </TableRow>
                  </TableHead>
                  <TableBody>
                  {projects.map((project:any) => (
                    <TableRow key={project.id}>
                      {Object.values(project.attributes).map((attribute :any, index) => (
                        <TR key={index}>{attribute}</TR>
                      ))}
                    </TableRow>
                  ))}
                  </TableBody>
              </Table>
              <Pagin>
                  <Pagination color="primary" variant="outlined" count={count} page={page} onChange={handlePageChangeInRP} />
                </Pagin>
              </TableContainer>
              :<Typography style={{ fontSize: "20px", color: "#2A3035", alignItems:'center', textAlign:'center' }} variant="subtitle1" component="div">
                  No Data Found
              </Typography> }
  </SurveyModelSection>
      </SurveyModelWrapper>
  </ModalSection>
}

RiskManageMentController.contextType = UserContext

const ModalSection = styled('div')({
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%,-50%)`,
    position: `absolute`
});
const SurveyModelWrapper = styled(Box)({
  background: 'white',
  borderRadius: "20px",
  padding: "35px",
  width: '1350px',
  '@media screen and (max-width:1000px)': {
    width: '700px'
},
  '@media screen and (max-width:768px)': {
      width: '550px'
  },
  '@media screen and (max-width:600px)': {
      width: '420px'
  },
  '@media screen and (max-width:468px)': {
      width: '320px'
  },
  '@media screen and (max-width:370px)': {
      width: '245px'
  }
})
const SurveyModelSection = styled(Box)({
  backgroundColor: "white",
  overflow: "auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  maxHeight: '80vh',
});
const SectionItem = styled(Box)({
  marginBottom: '10px',
  '& .MuiTypography-root.MuiTypography-subtitle1': {
      fontWeight: 'bold',
      fontSize: 11,
  },
  '& .MuiTypography-root.MuiTypography-subtitle2': {
      fontWeight: 'normal',
      color: 'grey',
      fontSize: 14,
      // paddingTop: '10px',
  }
});
const TitleSection = styled('div')({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  color: '#303539',
});
const DivSection = styled('div')({
  cursor: "pointer",
  display: 'flex',
  justifyContent: 'space-between',
  alignItem: 'center',
})

const OuterGrid = styled(Grid)({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
});

const ModalTitle = styled(Grid)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItem: 'center',
  flexDirection: 'row',
  width: '100%',
  padding: '10px 20px 10px 32px'
});

const MiddleGrid = styled(Grid)({
  width: 'calc(100vw - 300px)',
  maxWidth: 'unset',
  flexBasis: 'unset',
  justifyContent: 'center',
  '@media screen and (max-width: 768px)': {
    width: '100%'
  }
});
const WrapDoc = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  '& img': {
    width: '20px',
    marginLeft: '2px',
  }
});
const TRLast = styled(TableCell)({
  color: "#000",
  // fontWeight: 600,
  lineHeight: "1.5rem",
  fontSize: '0.7rem',
  padding: '16px 10px',
  textAlign: 'center',
  position: 'relative',
})
const PlanCardButton = styled(Button)({
  border: '2px solid transparant',
  display: 'flex',
  justifyContent: 'space-between',
  color: '#009B07',
  fontWeight: 300,
  backgroundColor: '#FFFFFF',
  textTransform: 'none',
  borderRadius: '30px',
  padding: '0 5px 0 5px',
});
const TR = styled(TableCell)({
  color: "#000",
  lineHeight: "1.5rem",
  minWidth: "100px",

  fontSize: '0.9rem',
  padding: '16px 10px',
  textAlign: 'center',
  position: 'relative',
  borderRight: '1px solid gray',

})
const MainBox = styled(Box)({
  backgroundColor: "#edebeb",
  display: "flex",
  flexWrap: 'wrap',
  flexDirection: "column",
  paddingBottom: "20px",
  overflowX: "hidden",
});
const JobLabel = styled('div')({
  fontSize: '15.35px',
  color: '#404040',
  fontFamily: 'HelveticaNeue'
});

const LeftGrid = styled(Grid)({
  maxWidth: '300px',
  '@media screen and (max-width: 985px)': {
    maxWidth: '200px',
  },
  '@media screen and (max-width: 768px)': {
    display: 'none',
    visibility: 'hidden',
  },
});
const BtnImg = styled('img')({
  width: 25
})
const CloseBtn = styled('img')({
  height: "40px",
  width: "40px",
  '@media screen and (max-width:768px)': {
      height: "30px",
      width: "30px",
  },
});

const ColoredBox = styled(Box)({
  borderRadius: '10px',
  maxWidth: 'fix-content',
  width: '90px',
  height: '51px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '15px',
})
const THLast = styled(TableCell)({
  color: "#000",
  fontWeight: 600,
  lineHeight: "1.5rem",
  fontSize: '0.7rem',
  padding: '16px 10px',
  textAlign: 'center',
  position: 'relative',
});
const RightFormWrapper = styled(Form)({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '25px',
  padding: '10px 0',
  overflowX: 'hidden',
  paddingRight: '10px',
  maxHeight: '500px'
});
const CreateHeader = styled('div')({
  fontSize: '24px',
  fontWeight: 500,
  fontFamily: 'HelveticaNeue'
})

const Pagin = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px',
  '@media screen and (max-width:350px)': {
    display: 'initial',
    padding: '10px 1px',
  },
  '@media screen and (min-width:768px) and (max-width:850px)': {
    display: 'initial',
    padding: '10px 1px',
  }
})
const CrossIcons = (props: any) => <img src={crossIcon} width="32px" {...props} />
// Customizable Area End
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { sendAPIRequest } from '../../../components/src/utils';
import * as yup from 'yup';
import { toast } from 'react-toastify'
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  token: string;
  onGoingProjectButton: boolean;
  showDescriptionModal: boolean;
  seekingFundButton: boolean;
  seekingFundData: any;
  ongoingProjectData: any;
  onGoingProjectDataSDGFactor: any[];
  AnOngoingProjectData: any;
  onSeekingFundEdit: boolean;
  loading:boolean,
  onGoingProjectEdit: boolean;
  ongoingProjectError: any;
  isError: boolean;
  individualProjectID: any;
  viewProjectData: any;
  individualOnGoingProjectData: any;
  no_project_found: boolean;
  moderatorLists: any[];
  collaboratorList: any[];
  membersList: any[];
  leadersList: any[];
  sponsorsList: any[]
  investorsList: any[];
  goalsData: any[];
  approversList: any[];
  moderatorPagination?: any,
  collaboratorPagination?: any,
  investorsPagination?: any,
  membersPagination?: any,
  sponsorsPagination?: any,
  approverPagination?: any,
  selectedSDGFactor: any,
  sdgFactor: any,
  filesArray?: any,
  dimensions: any[],
  targets: any[],
  profile_pic_show: any,
  profile_pic: any,
  isEditable: boolean,
  preferenceState: any,
  dimensionState: any,
  goalState: any,
  moderatorPaginateCallId?: any,
  collaboratorsPaginateCallId?: any,
  membersPaginateCallId?: any,
  sponsorsPaginateCallId?: any,
  investorsPaginateCallId?: any,
  approversPaginateCallId?: any,
  modratorsSearchCallId: string,
  collaboratorsSearchCallId: string,
  membersSearchCallId: string,
  sponsorsSearchCallId: string,
  investorsSearchCallId: string,
  approversSearchCallId: string,
  moderatorQuery: any,
  membersQuery: any,
  sponsorsQuery: any,
  investorsQuery: any,
  collaboratorsQuery: any,
  approverQuery: any,
  allModratorOptions: any[],
  allCollaboratorsOptions: any[],
  allMembersOptions: any[],
  allSponsorsOptions: any[],
  allInvestorsOptions: any[],
  allApproversOptions: any[],
  openSeekingFundsDetailModel: any,
  removedAttachments: number[],
  total_pages: number | undefined,
  pages: number,
  onGoingProjectSponsorsList: any[],
  onGoingApprovedByList: any[],
  onGoingProjectLeaderList: any[],
  onGoingProjectModeratorsList: any[],
  onGoingProjectMembersList: any[],
  onGoingProjectInvestorsList: any[],
  onGoingProjectCollaboratorList: any[],
  onGoingProjectSponsorsIdList: any[],
  onGoingApprovedByIdList: any[],
  onGoingProjectLeaderIdList: any[],
  onGoingProjectModeratorsIdList: any[],
  onGoingProjectMembersIdList: any[],
  onGoingProjectInvestorsIdList: any[],
  onGoingProjectCollaboratorIdList: any[],
  licenceFlag : boolean,

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProjectPortfolioController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  projectSeekingFundCallId: string = '';
  ongoingProjectCallId: string = '';
  getAnOngoingProjectDetailId: string = '';
  getDimensionsId: string = '';
  getTargetId: string = '';
  projectModeratorsCallId: string = '';
  projectInvestorCallId: string = '';
  projectCollaboratorCallId: string = '';
  projectSponsorsCallId: string = '';
  projectMembersCallId: string = '';
  apiUserLikeCountCallId: string = '';
  initModratorPagination: any = undefined;
  initCollaboratorsPagination: any = undefined;
  initMembersPagination: any = undefined;
  initSponsorsPagination: any = undefined;
  initInvestorsPagination: any = undefined;
  initApproversPagination: any = undefined;
  goalsCallId: string = '';
  sdgFactorCallId: string = '';
  approverCallId: string = '';
  editOngoingProjectCallId: string = '';

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.GetAllCategoriesResponseMessage),
      getName(MessageEnum.GetAllSubCategoriesResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "hello@aol.com",
      enableField: false,
      // Customizable Area Start
      token: '',
      onGoingProjectButton: true,
      seekingFundButton: false,
      showDescriptionModal: false,
      seekingFundData: [],
      ongoingProjectData: [],
      onGoingProjectDataSDGFactor: [],
      ongoingProjectError: "",
      isError: false,
      AnOngoingProjectData: [],
      viewProjectData: [],
      individualOnGoingProjectData: [],
      no_project_found: false,
      onSeekingFundEdit: false,
      loading: false,
      onGoingProjectEdit: false,
      individualProjectID: "",
      moderatorLists: [],
      collaboratorList: [],
      membersList: [],
      leadersList:[],
      sponsorsList: [],
      investorsList: [],
      approversList: [],
      goalsData: [],
      selectedSDGFactor: [],
      sdgFactor: [],
      filesArray: [],
      dimensions: [],
      targets: [],
      profile_pic_show: "",
      profile_pic: "",
      isEditable: true,
      preferenceState: "",
      dimensionState: "",
      goalState: "",
      modratorsSearchCallId: "",
      collaboratorsSearchCallId: "",
      membersSearchCallId: "",
      sponsorsSearchCallId: "",
      investorsSearchCallId: "",
      approversSearchCallId: "",
      moderatorQuery: "",
      membersQuery: "",
      sponsorsQuery: "",
      investorsQuery: "",
      collaboratorsQuery: "",
      approverQuery: "",
      allModratorOptions: [],
      allCollaboratorsOptions: [],
      allMembersOptions: [],
      allSponsorsOptions: [],
      allInvestorsOptions: [],
      allApproversOptions: [],
      openSeekingFundsDetailModel: false,
      removedAttachments: [],
      total_pages: undefined,
      pages: 1,
      onGoingProjectSponsorsList: [],
      onGoingApprovedByList: [],
      onGoingProjectLeaderList: [],
      onGoingProjectModeratorsList: [],
      onGoingProjectMembersList: [],
      onGoingProjectInvestorsList: [],
      onGoingProjectCollaboratorList: [],
      onGoingProjectSponsorsIdList: [],
      onGoingApprovedByIdList: [],
      onGoingProjectLeaderIdList: [],
      onGoingProjectModeratorsIdList: [],
      onGoingProjectMembersIdList: [],
      onGoingProjectInvestorsIdList: [],
      onGoingProjectCollaboratorIdList: [],
      licenceFlag: false,

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }


  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    const authToken = await getStorageData("authToken")
    if (authToken == null) {
      this.props.navigation.navigate('Home')
    }
    const userString = await getStorageData('user');
    const userJson = JSON.parse(userString);
    const { role: { role_level = 0 } = {}} = userJson ?? {};
    const isAllowed = role_level > 4
    if(!isAllowed){
      let warning = "Sorry, You do not have access to this page"
      let homeLink = "ActivityFeed"
      toast.warn(warning)
      this.navToFun(homeLink,this.props)    
    }
    this.setState({token: authToken}, () => {
    this.getProjectSeekingFundApi();
    this.getOngoingProjectApi();
    this.getProjectModeratorsApi();
    this.getProjectCollaboratorApi();
    this.getSponsorsApi();
    this.getInvestorApi();
    this.getMembersApi();
    this.getGoalsApi();
    this.getSDGFactorApi();
    this.getApproverApi();
    });
  }
  // Customizable Area End

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    this.getResponse(message);
    this.getResponse2(message);
    this.getResponse3(message);
    this.getResponse4(message);
    this.getResponse5(message);

    // Customizable Area End

  }


  // Customizable Area Start

  getResponse(message: any) {
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    const apiRequestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    switch (apiRequestId) {
      case this.editOngoingProjectCallId:
        if (responseJson.project.data) {
          this.setState({loading : false})
          toast.success('Project updated successfully.');

        } else {
          this.setState({loading : false})
          toast.error('Something went wrong.');
        }
        break;
      case this.projectModeratorsCallId:
        if (responseJson) {
          const moderatorOptions = responseJson?.leaders_moderators_and_members.data.map((d: any) => d.attributes)
          this.initModratorPagination = responseJson?.leaders_moderators_and_members.meta.pagination;
          this.setState({ moderatorLists: moderatorOptions, allModratorOptions: moderatorOptions, moderatorPagination: responseJson?.leaders_moderators_and_members.meta.pagination })
        }
        break;

      case this.state.moderatorPaginateCallId:
        const modratoroptions = responseJson?.leaders_moderators_and_members.data.map((d: any) => d.attributes)
        const modratorOptions = this.filterUnique(this.state.moderatorLists.concat(modratoroptions))
        this.setState({ moderatorLists: modratorOptions, moderatorPagination: responseJson?.leaders_moderators_and_members.meta.pagination })

        break;

      case this.state.modratorsSearchCallId:
        if (!Array.isArray(responseJson.leaders_moderators_and_members?.data))
          this.setState({
            modratorsSearchCallId: '',
            moderatorLists: [],
            moderatorPagination: undefined
          })
        else {
          const modratorOptions = responseJson?.leaders_moderators_and_members.data.map((d: any) => d.attributes)
          this.setState({
            modratorsSearchCallId: '',
            moderatorLists: modratorOptions,
            moderatorPagination: responseJson?.leaders_moderators_and_members.meta.pagination,
          })
        }
        break;

      case this.projectCollaboratorCallId:
        if (responseJson) {
          const collaboratorOptions = responseJson?.project_collaborators?.data.map((d: any) => d.attributes)
          this.initCollaboratorsPagination = responseJson?.project_collaborators?.meta?.pagination;
          this.setState({ collaboratorList: collaboratorOptions, allCollaboratorsOptions: collaboratorOptions, collaboratorPagination: responseJson?.project_collaborators?.meta?.pagination })
        }
        break;
      case this.state.collaboratorsPaginateCallId:
        const collaboratorsoptions = responseJson?.project_collaborators?.data.map((d: any) => d.attributes)
        const collaboratorsOptions = this.filterUnique(this.state.collaboratorList.concat(collaboratorsoptions))
        this.setState({ collaboratorList: collaboratorsOptions, collaboratorPagination: responseJson?.project_collaborators?.meta?.pagination })

        break;
      case this.state.collaboratorsSearchCallId:
        if (!Array.isArray(responseJson.project_collaborators?.data))
          this.setState({
            collaboratorsSearchCallId: '',
            collaboratorList: [],
            collaboratorPagination: undefined
          })
        else {
          const collaboratorsOptions = responseJson?.project_collaborators?.data.map((d: any) => d.attributes)
          this.setState({
            collaboratorsSearchCallId: '',
            collaboratorList: collaboratorsOptions,
            collaboratorPagination: responseJson?.project_collaborators?.meta?.pagination,
          })
        }
        break;


      default:
        break;
    }
  }

  getResponse2(message: any) {
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    const apiRequestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    switch (apiRequestId) {
      case this.projectSeekingFundCallId:
        if (responseJson.data) {
          this.setState({ seekingFundData: responseJson?.data })
        }
        break;
      case this.getDimensionsId:
        if (responseJson) {
          this.setState({ dimensions: responseJson?.dimensions })
        }
        break;
      case this.getTargetId:
        if (responseJson) {
          this.setState({ targets: responseJson?.targets })
        }
        break;
      case this.projectInvestorCallId:
        if (responseJson) {
          const investorOptions = responseJson?.project_investors?.data.map((d: any) => d.attributes)
          this.initInvestorsPagination = responseJson?.project_investors?.meta?.pagination;
          this.setState({ investorsList: investorOptions, allInvestorsOptions: investorOptions, investorsPagination: responseJson?.project_investors?.meta?.pagination })
        }
        break;
      case this.state.investorsPaginateCallId:
        const investorsoptions = responseJson?.project_investors?.data.map((d: any) => d.attributes)
        const investorsOptions = this.filterUnique(this.state.investorsList.concat(investorsoptions))
        this.setState({ investorsList: investorsOptions, investorsPagination: responseJson?.project_investors?.meta?.pagination })

        break;
      case this.state.investorsSearchCallId:
        console.log(responseJson.project_investors?.data, "%%%%%%%MSCI")
        if (!Array.isArray(responseJson.project_investors?.data))
          this.setState({
            investorsSearchCallId: '',
            investorsList: [],
            investorsPagination: undefined
          })
        else {
          const investorsOptions = responseJson?.project_investors?.data.map((d: any) => d.attributes)
          this.setState({
            investorsSearchCallId: '',
            investorsList: investorsOptions,
            investorsPagination: responseJson?.project_investors?.meta?.pagination,
          })
        }
        break;

    }
  }

  getResponse3(message: any) {
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    const apiRequestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );


    switch (apiRequestId) {
      case this.ongoingProjectCallId:
        if (responseJson) {
          console.log(responseJson?.message, "????????")
          if (responseJson?.message === "No projects found") {
            this.setState({ no_project_found: true })
          }
          if (responseJson?.errors) {
            this.setState({ ongoingProjectError: responseJson?.errors[0], isError: true })
          } else {
            console.log(responseJson, "&&&&&&&&PD")
            this.setState({ ongoingProjectData: responseJson?.projects?.data, loading : false })
            const total_pages = responseJson?.projects?.meta?.pagination?.total_pages
            this.setState({ total_pages})
          }
        }
        break;
      case this.apiUserLikeCountCallId: {
        this.handleLikeCount(responseJson);
        break;
      }
      case this.goalsCallId:
        if (responseJson) {
          this.setState({ goalsData: responseJson?.goals })
        }
        break;
    }
  }

  getResponse4(message: any) {
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    const apiRequestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    switch (apiRequestId) {
      case this.projectMembersCallId:
        if (responseJson) {
          const membersOptions = responseJson?.leaders_moderators_and_members.data.map((d: any) => d.attributes)
          this.initMembersPagination = responseJson?.leaders_moderators_and_members.meta.pagination;
          this.setState({ membersList: membersOptions, allMembersOptions: membersOptions, membersPagination: responseJson?.leaders_moderators_and_members.meta.pagination })
        }
        break;

      case this.state.membersPaginateCallId:
        const membersoptions = responseJson?.leaders_moderators_and_members.data.map((d: any) => d.attributes)
        const membersOptions = this.filterUnique(this.state.membersList.concat(membersoptions))
        this.setState({ membersList: membersOptions, membersPagination: responseJson?.leaders_moderators_and_members.meta.pagination })

        break;

      case this.state.membersSearchCallId:
        console.log(responseJson.leaders_moderators_and_members?.data, "%%%%%%%MSCI")
        if (!Array.isArray(responseJson.leaders_moderators_and_members?.data))
          this.setState({
            membersSearchCallId: '',
            membersList: [],
            membersPagination: undefined
          })
        else {
          const membersOptions = responseJson?.leaders_moderators_and_members.data.map((d: any) => d.attributes)
          this.setState({
            membersSearchCallId: '',
            membersList: membersOptions,
            membersPagination: responseJson?.leaders_moderators_and_members.meta.pagination,
          })
        }
        break;

      case this.approverCallId:
        if (responseJson) {
          const approverOptions = responseJson?.approvers?.data.map((d: any) => d.attributes)
          this.initApproversPagination = responseJson?.approvers?.meta?.pagination;
          this.setState({ approversList: approverOptions, allApproversOptions: approverOptions, approverPagination: responseJson?.approvers?.meta?.pagination })
        }
        break;

      case this.state.approversPaginateCallId:
        const approversoptions = responseJson?.approvers?.data.map((d: any) => d.attributes)
        const approversOptions = this.filterUnique(this.state.approversList.concat(approversoptions))
        this.setState({ approversList: approversOptions, approverPagination: responseJson?.approvers?.meta?.pagination })

        break;

      case this.state.approversSearchCallId:
        console.log(responseJson.approvers?.data, "%%%%%%%MSCI")
        if (!Array.isArray(responseJson.approvers?.data))
          this.setState({
            approversSearchCallId: '',
            approversList: [],
            approverPagination: undefined
          })
        else {
          const approversOptions = responseJson?.approvers?.data.map((d: any) => d.attributes)
          this.setState({
            approversSearchCallId: '',
            approversList: approversOptions,
            approverPagination: responseJson?.approvers?.meta?.pagination,
          })
        }
        break;

    }
  }

  getResponse5(message: any) {
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    const apiRequestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    switch (apiRequestId) {
      case this.projectSponsorsCallId:
        if (responseJson) {
          const sponsorsOptions = responseJson?.project_sponsors?.data.map((d: any) => d.attributes)
          this.initSponsorsPagination = responseJson?.project_sponsors?.meta?.pagination;
          this.setState({ sponsorsList: sponsorsOptions, allSponsorsOptions: sponsorsOptions, sponsorsPagination: responseJson?.project_sponsors?.meta?.pagination })
        }
        break;

      case this.state.sponsorsPaginateCallId:
        const sponsorsoptions = responseJson?.project_sponsors?.data.map((d: any) => d.attributes)
        const sponsorsOptions = this.filterUnique(this.state.sponsorsList.concat(sponsorsoptions))
        this.setState({ sponsorsList: sponsorsOptions, sponsorsPagination: responseJson?.project_sponsors?.meta?.pagination })

        break;

      case this.state.sponsorsSearchCallId:
        console.log(responseJson.project_sponsors?.data, "%%%%%%%MSCI")
        if (!Array.isArray(responseJson.project_sponsors?.data))
          this.setState({
            sponsorsSearchCallId: '',
            sponsorsList: [],
            sponsorsPagination: undefined
          })
        else {
          const sponsorsOptions = responseJson?.project_sponsors?.data.map((d: any) => d.attributes)
          this.setState({
            sponsorsSearchCallId: '',
            sponsorsList: sponsorsOptions,
            sponsorsPagination: responseJson?.project_sponsors?.meta?.pagination,
          })
        }
        break;
      case this.sdgFactorCallId:
        if (responseJson) {
          this.setState({ sdgFactor: responseJson?.sdg_factors?.data })
        }
        break;
      case this.getAnOngoingProjectDetailId:
        if (responseJson) {
          this.fetchDimensions(responseJson?.project?.data?.attributes?.preference)
          this.fetchTarget(responseJson?.project?.data?.attributes?.sdg_goals);
          console.log('ongoing12341234', responseJson?.project?.data);
          let onGoingApprovedByIdList = responseJson?.project?.data?.attributes?.approved_by.map((id: any) => Number(id.data.id));
          let onGoingProjectSponsorsIdList = responseJson?.project?.data?.attributes?.project_sponsors.map((id: any) => Number(id.data.id));
          let onGoingProjectLeaderIdList = responseJson?.project?.data?.attributes?.project_leader.map((id: any) => Number(id.data.id));
          let onGoingProjectModeratorsIdList = responseJson?.project?.data?.attributes?.project_moderators.map((id: any) => Number(id.data.id));
          let onGoingProjectMembersIdList = responseJson?.project?.data?.attributes?.project_members.map((id: any) => Number(id.data.id));
          let onGoingProjectInvestorsIdList = responseJson?.project?.data?.attributes?.project_investors.map((id: any) => Number(id.data.id));
          let onGoingProjectCollaboratorIdList = responseJson?.project?.data?.attributes?.project_collaborators.map((id: any) => Number(id.data.id));
          
          this.setState({
            loading: false,
            AnOngoingProjectData: responseJson?.project?.data,
            onGoingApprovedByList: responseJson?.project?.data?.attributes?.approved_by.map((d: any) => d.data.attributes),
            onGoingProjectSponsorsList: responseJson?.project?.data?.attributes?.project_sponsors.map((d: any) => d.data.attributes),
            onGoingProjectLeaderList: responseJson?.project?.data?.attributes?.project_leader.map((d: any) => d.data.attributes),
            onGoingProjectModeratorsList: responseJson?.project?.data?.attributes?.project_moderators.map((d: any) => d.data.attributes),
            onGoingProjectMembersList: responseJson?.project?.data?.attributes?.project_members.map((d: any) => d.data.attributes),
            onGoingProjectInvestorsList: responseJson?.project?.data?.attributes?.project_investors.map((d: any) => d.data.attributes),
            onGoingProjectCollaboratorList: responseJson?.project?.data?.attributes?.project_collaborators.map((d: any) => d.data.attributes),
            onGoingApprovedByIdList,
            onGoingProjectSponsorsIdList,
            onGoingProjectLeaderIdList,
            onGoingProjectModeratorsIdList,
            onGoingProjectMembersIdList,
            onGoingProjectInvestorsIdList,
            onGoingProjectCollaboratorIdList,
            preferenceState: responseJson?.project?.data?.attributes?.preference,
            selectedSDGFactor: (responseJson?.project?.data?.attributes?.sdg_factors || []).map((data: any) => data.data.id)
          })
          this.flag()
        }
        break;
    }
  }


  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowhideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideimageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideimageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleprops = {
    onPress: () => this.doButtonPressed()
  };

  onGoingProject = (e: any) => {
    this.setState({ onGoingProjectButton: true, seekingFundButton: false });
  }

  onSeekingFund = (e: any) => {
    this.setState({ onGoingProjectButton: false, seekingFundButton: true });
  }

  handleLikeChange = async(id?: any) => {
    let authToken = await getStorageData("authToken")
    this.apiUserLikeCountCallId = sendAPIRequest(configJSON.getUserLikeCountEndpoint.replace(':id', id), {
      headers: { token: authToken }
    })
  }
  handleLikeCount = async(json: any) => {
    let userData: any = await getStorageData('user');
    let id: any = JSON.parse(userData)?.id;
    let userIds: any = [];
    let project_like_id: any = null;
    json?.data?.attributes?.liked_by_users?.map((item: any) => {
      if (item?.likeable_type == "BxBlockProjectportfolio::PromoteContent") {
        userIds?.push(item?.like_by_id)

        if (item?.like_by_id == id) {
          project_like_id = item?.id;
        }
      }
    });

    this.setState({ ...this.state, viewProjectData: { ...this.state.viewProjectData, attributes: { ...this.state?.viewProjectData?.attributes, likes: json?.data, is_liked: userIds.includes(id), project_like_id: project_like_id } } });
  }

  onBack = () => {
    this.setState({ onSeekingFundEdit: false })
  }
  onBackToOngoing = () => {
    this.setState({ onGoingProjectEdit: false })
  }

  setFilesArray = (file: any) => {
    console.log('file check', file)
    this.setState({ filesArray: [file] });
  }

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  }

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  }

  getProjectSeekingFundApi = () => {
    this.projectSeekingFundCallId = sendAPIRequest(`${configJSON.sendRequestEndpoint}`, {
      method: "GET",
      headers: { 'token': this.state.token },
    })
  }
  
  getOngoingProjectApi = () => {
    console.log("inside", "^^^^^^^^")
    console.log(`${configJSON.ongoingProjectEndPoint}page=${this.state.pages}`, "^^^^^^^^^^^url")
    this.ongoingProjectCallId = sendAPIRequest(`${configJSON.ongoingProjectEndPoint}page=${this.state.pages}`, {
      method: "GET",
      headers: { 'token': this.state.token },
    })
  }
  getProjectModeratorsApi = () => {
    this.projectModeratorsCallId = sendAPIRequest(`${configJSON.projectModeratorsEndPoint}`, {
      method: "GET",
      headers: { 'token': this.state.token },
    })
  }

  getProjectCollaboratorApi = () => {
    this.projectCollaboratorCallId = sendAPIRequest(`${configJSON.projectCollaboratorEndPoint}`, {
      method: "GET",
      headers: { 'token': this.state.token },
    })
  }

  getSponsorsApi = () => {
    this.projectSponsorsCallId = sendAPIRequest(`${configJSON.projectSponsorsEndPoint}`, {
      method: "GET",
      headers: { 'token': this.state.token },
    })
  }
  getInvestorApi = () => {
    this.projectInvestorCallId = sendAPIRequest(`${configJSON.projectInvestorEndPoint}`, {
      method: "GET",
      headers: { 'token': this.state.token },
    })
  }
  getMembersApi = () => {
    this.projectMembersCallId = sendAPIRequest(`${configJSON.projectMembersEndPoint}`, {
      method: "GET",
      headers: { 'token': this.state.token },
    })
  }

  getGoalsApi = () => {
    this.goalsCallId = sendAPIRequest(`${configJSON.getGoalsEndPoint}`, {
      method: "GET",
      headers: { 'token': this.state.token },
    })
  }
  getSDGFactorApi = () => {
    this.sdgFactorCallId = sendAPIRequest(`${configJSON.getSDGFocusEndpoint}`, {
      method: "GET",
      headers: { 'token': this.state.token },
    })
  }
  getApproverApi = () => {
    this.approverCallId = sendAPIRequest(`${configJSON.getApproverEndPoint}`, {
      method: "GET",
      headers: { 'token': this.state.token },
    })
  }

  validationSchema = yup.object().shape({
    project_name: yup.string().required(),
    project_id: yup.string().required(),
    project_impact_outcome: yup.string().label('Project Impact Outcome').max(1000),
    organization_objective_id: yup.string().required(),
    expected_rr: yup.number().typeError('Expected IRR must be a number'),
    expected_cost_of_capital: yup.number().typeError('Expected Cost of Capital must be a number'),
    actual_cost_of_capital: yup.number().typeError('Actual Cost of Capital must be a number'),
    project_moderator: yup.array().min(1, 'Select atleast one Project Moderator').required(),
    project_leader: yup.array().min(1, 'Select atleast one Project Leader').required(),
    approved_by: yup.array().min(1, 'Select atleast one Project Approver').required(),
    project_sponsors: yup.array().min(1, 'Select atleast one Project Sponsors').required(),
    project_type: yup.string().label('Project Type').required(),
    sdg_goals: yup.string().label('SDG Goal').required(),
    preference: yup.string().label('Preference').required(),
    dimensions: yup.string().label('Dimensions').required(),
    sdg_targets: yup.string().label('SDG Target').required(),
    project_members: yup.array().min(1, 'Select atleast one Member').required(),
    no_of_milestones: yup.number().label('Number of Milestone').typeError('Number of Milestone must be a numeric value').min(1, 'Number of Milestone can not be less than 1').max(50, 'Number of Milestone must be less than or equal to 50').required(),
    project_status: yup.string().label('Project Status').required(),
    operation_model: yup.string().label('Operating Model').required(),
    approvers_level_1: yup.array().min(1, 'Select atleast one Level 1 Approver').required(),
    planned_source_of_funding: yup.string().label('Planned Source of Funding').required(),
    actual_source_of_funding: yup.string().label('Actual Source of Funding').required(),
    start_date: yup.date().label('').required("Project Duration is a mandatory field.").nullable(),
    end_date: yup.date().label('').required("Project Duration is a mandatory field.").nullable().min(yup.ref('start_date'), 'End date cannot be less than start date')
  })

  handleSubmit = async (data: any) => {
    this.setState({ isEditable: false, loading: true })
    let { project_id = "", project_term = "", project_type = '', no_of_milestones = "", expected_rr = "", operation_model = "", approved_by = [], project_collaborators = [], project_moderator = [], project_investor = [],
      approvers_level_1 = [], project_leader = [], planned_source_of_funding = "", planned_source_of_funding_please_specify = "", dimensions = "", project_status = "", approvers_level_2 = [], approvers_level_3 = [], project_members = [],
      actual_source_of_funding = "", actual_source_of_funding_please_specify = "", location = "", ongoing_project_pdfs = [], project_type_please_specify = "", project_sponsors = [], start_date = "", end_date = "", sdg_targets = "", project_impact_name = "", project_impact_output_please_specify = "", project_impact_outcome = "", project_impact_output = "", expected_cost_of_capital = "", actual_cost_of_capital = "" } = { ...data }


    console.log(project_sponsors, "************>inside handle submit")

    const body = new FormData()

    if (this.state.profile_pic) {
      body.append('project_profile_picture', this.state.profile_pic)
    }
    if (location) {
      body.append('locations[]', location)
    }
    if (this.state.goalState) {
      body.append('sdg_goals', this.state.goalState)
    }
    if (this.state.preferenceState) {
      body.append('preference', this.state.preferenceState)
    }

    body.append('project_end_date', end_date)
    body.append('actual_source_of_funding_please_specify', actual_source_of_funding_please_specify)
    body.append('planned_source_of_funding_please_specify', planned_source_of_funding_please_specify)
    body.append('project_impact_output_please_specify', project_impact_output_please_specify)
    body.append('project_start_date', start_date)
    body.append('project_id', project_id)
    body.append('project_term', project_term)
    body.append('project_type', project_type)
    body.append('expected_rr', expected_rr)
    body.append('no_of_milestones', no_of_milestones)
    body.append('operation_model', operation_model)
    body.append('project_status', project_status)
    body.append('project_type_please_specify', project_type_please_specify)
    body.append('dimensions', dimensions)
    body.append('planned_source_of_funding', planned_source_of_funding)
    body.append('actual_source_of_funding', actual_source_of_funding)
    body.append('expected_cost_of_capital', expected_cost_of_capital)
    body.append('actual_cost_of_capital', actual_cost_of_capital)
    body.append('project_impact_outcome', project_impact_outcome)
    body.append('project_impact_output', project_impact_output)
    body.append('project_impact_name', project_impact_name)
    body.append('sdg_targets', sdg_targets)
    ongoing_project_pdfs.forEach((file: any) => { if (!file?.id) { body.append('project_plans[]', file) } })
    project_collaborators.forEach((id: any) => body.append('project_collaborators[]', id))
    approved_by.forEach((id: any) => body.append('approved_by[]', id))
    project_moderator.forEach((id: any) => body.append('project_moderators[]', id))
    project_leader.forEach((id: any) => body.append('project_leaders[]', id))
    project_investor.forEach((id: any) => body.append('project_investors[]', id))
    project_members.forEach((id: any) => body.append('project_members[]', id))
    project_sponsors.forEach((id: any) => body.append('project_sponsors[]', id))
    approvers_level_1.forEach((id: any) => body.append('approvers_level_1[]', id))
    approvers_level_2.forEach((id: any) => body.append('approvers_level_2[]', id))
    approvers_level_3.forEach((id: any) => body.append('approvers_level_3[]', id))
    this.state.selectedSDGFactor.forEach((id: any) => body.append('sdg_factors[]', id))

    const { token } = this.state;
    if (this.state.removedAttachments.length > 0) {
      const removeAttachmentUrl = configJSON.deleteProjectPlanEndPoint

      const removeAttachmentBody = { project_id: project_id, ids: this.state.removedAttachments };
      const removeAttachmentPromise = new Promise<void>((resolve, reject) => {
        sendAPIRequest(removeAttachmentUrl, {
          method: 'POST',
          headers: { token, 'content-type': 'application/json' },
          body: removeAttachmentBody,
        });
        resolve();
      });
      await removeAttachmentPromise;
    }


    const headers = {
      "token": this.state.token,
    }
    let url = configJSON.editOngoingProjectEndpoint;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.editOngoingProjectCallId = requestMessage.messageId;
    let method = 'PUT';

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  removeAttachments = (attachmentId: number) => {
    if (attachmentId) {
      this.setState(prevState => ({
        removedAttachments: [...prevState.removedAttachments, attachmentId]
      }))
    }
  }

  flag = () => {
    this.state.AnOngoingProjectData.attributes?.project_moderators.map((items:any)=>{
      if(items.data.id == this.context.id){
        this.setState({licenceFlag: true})
      } 
    })
  }
  paginateModerator = () => {
    if (this.state.modratorsSearchCallId !== '') return
    const { next_page } = this.state.moderatorPagination
    this.setState({ moderatorPaginateCallId: this.handleModeratorSearch(this.state.moderatorQuery, next_page) })
  }

  paginateMembers = () => {
    if (this.state.membersSearchCallId !== '') return
    const { next_page } = this.state.membersPagination
    this.setState({ membersPaginateCallId: this.handleMembersSearch(this.state.membersQuery, next_page) })
  }

  paginateSponsors = () => {
    if (this.state.sponsorsSearchCallId !== '') return
    const { next_page } = this.state.sponsorsPagination
    this.setState({ sponsorsPaginateCallId: this.handleSponsorsSearch(this.state.sponsorsQuery, next_page) })
  }

  paginateApprovers = () => {
    if (this.state.approversSearchCallId !== '') return
    const { next_page } = this.state.approverPagination
    this.setState({ approversPaginateCallId: this.handleApproversSearch(this.state.approverQuery, next_page) })
  }

  paginateInvestors = () => {
    if (this.state.investorsSearchCallId !== '') return
    const { next_page } = this.state.investorsPagination
    this.setState({ investorsPaginateCallId: this.handleInvestorsSearch(this.state.investorsQuery, next_page) })
  }

  paginateCollaborators = () => {
    if (this.state.collaboratorsSearchCallId !== '') return
    const { next_page } = this.state.collaboratorPagination
    this.setState({ collaboratorsPaginateCallId: this.handleCollaboratorsSearch(this.state.collaboratorsQuery, next_page) })
  }

  handleModeratorSearchQuery = (query: string) => {
    console.log({ query }, "query::>>>")
    if (query)
      this.setState({
        modratorsSearchCallId: this.handleModeratorSearch(query),
        moderatorQuery: query,
        moderatorPaginateCallId: ''
      })
    else
      this.setState({
        moderatorLists: this.state.allModratorOptions,
        moderatorPagination: this.initModratorPagination,
        moderatorQuery: '',
        modratorsSearchCallId: '',
      })
  }
  handleMembersSearchQuery = (query: string) => {
    if (query)
      this.setState({
        membersSearchCallId: this.handleMembersSearch(query),
        membersQuery: query,
        membersPaginateCallId: ''
      })
    else
      this.setState({
        membersList: this.state.allMembersOptions,
        membersPagination: this.initMembersPagination,
        membersQuery: '',
        membersSearchCallId: '',
      })
  }

  handleSponsorsSearchQuery = (query: string) => {
    if (query)
      this.setState({
        sponsorsSearchCallId: this.handleSponsorsSearch(query),
        sponsorsQuery: query,
        sponsorsPaginateCallId: ''
      })
    else
      this.setState({
        sponsorsList: this.state.allSponsorsOptions,
        sponsorsPagination: this.initSponsorsPagination,
        sponsorsQuery: '',
        sponsorsSearchCallId: '',
      })
  }
  handleInvestorsSearchQuery = (query: string) => {
    if (query)
      this.setState({
        investorsSearchCallId: this.handleInvestorsSearch(query),
        investorsQuery: query,
        investorsPaginateCallId: ''
      })
    else
      this.setState({
        investorsList: this.state.allInvestorsOptions,
        investorsPagination: this.initInvestorsPagination,
        investorsQuery: '',
        investorsPaginateCallId: '',
      })
  }
  handleCollaboratorsSearchQuery = (query: string) => {
    if (query)
      this.setState({
        collaboratorsSearchCallId: this.handleCollaboratorsSearch(query),
        collaboratorsQuery: query,
        collaboratorsPaginateCallId: ''
      })
    else
      this.setState({
        collaboratorList: this.state.allCollaboratorsOptions,
        collaboratorPagination: this.initCollaboratorsPagination,
        collaboratorsQuery: '',
        collaboratorsPaginateCallId: '',
      })
  }
  handleApproversSearchQuery = (query: string) => {
    if (query)
      this.setState({
        approversSearchCallId: this.handleApproversSearch(query),
        approverQuery: query,
        approversPaginateCallId: ''
      })
    else
      this.setState({
        approversList: this.state.allApproversOptions,
        approverPagination: this.initApproversPagination,
        approverQuery: '',
        approversSearchCallId: '',
      })
  }

  handleModeratorSearch = (query: string, page?: number) => {
    console.log({ page })
    const { token } = this.state;
    const params = new URLSearchParams()
    query && params.append('moderators', query)
    page && params.append('page', String(page))
    const url = configJSON.projectModeratorsEndPoint + "?" + params.toString()
    return sendAPIRequest(url, { method: 'GET', headers: { token } })
  }

  handleMembersSearch = (query: string, page?: number) => {
    console.log({ page })
    const { token } = this.state;
    const params = new URLSearchParams()
    query && params.append('moderators', query)
    page && params.append('page', String(page))
    const url = configJSON.projectMembersEndPoint + "?" + params.toString()
    return sendAPIRequest(url, { method: 'GET', headers: { token } })
  }

  handleSponsorsSearch = (query: string, page?: number) => {
    console.log({ page })
    const { token } = this.state;
    const params = new URLSearchParams()
    query && params.append('sponsors', query)
    page && params.append('page', String(page))
    const url = configJSON.projectSponsorsEndPoint + "?" + params.toString()
    return sendAPIRequest(url, { method: 'GET', headers: { token } })
  }

  handleInvestorsSearch = (query: string, page?: number) => {
    console.log({ page })
    const { token } = this.state;
    const params = new URLSearchParams()
    query && params.append('investors', query)
    page && params.append('page', String(page))
    const url = configJSON.projectInvestorEndPoint + "?" + params.toString()
    return sendAPIRequest(url, { method: 'GET', headers: { token } })
  }
  handleCollaboratorsSearch = (query: string, page?: number) => {
    console.log({ page })
    const { token } = this.state;
    const params = new URLSearchParams()
    query && params.append('collaborators', query)
    page && params.append('page', String(page))
    const url = configJSON.projectCollaboratorEndPoint + "?" + params.toString()
    return sendAPIRequest(url, { method: 'GET', headers: { token } })
  }

  handleApproversSearch = (query: string, page?: number) => {
    console.log({ page })
    const { token } = this.state;
    const params = new URLSearchParams()
    query && params.append('approvers', query)
    page && params.append('page', String(page))
    const url = configJSON.getApproverEndPoint + "?" + params.toString()
    return sendAPIRequest(url, { method: 'GET', headers: { token } })
  }

  handleModeratorClose = () => {
    this.setState({
      modratorsSearchCallId: '',
      moderatorQuery: '',
      moderatorPaginateCallId: '',
      moderatorLists: this.state.allModratorOptions,
      moderatorPagination: this.initModratorPagination,
    })
  }
  handleMembersClose = () => {
    this.setState({
      membersSearchCallId: '',
      membersQuery: '',
      membersPaginateCallId: '',
      membersList: this.state.allMembersOptions,
      membersPagination: this.initMembersPagination,
    })
  }
  handleInvestorsClose = () => {
    this.setState({
      investorsSearchCallId: '',
      investorsQuery: '',
      investorsPaginateCallId: '',
      investorsList: this.state.allInvestorsOptions,
      investorsPagination: this.initInvestorsPagination,
    })
  }
  handleSponsorsClose = () => {
    this.setState({
      sponsorsSearchCallId: '',
      sponsorsQuery: '',
      sponsorsPaginateCallId: '',
      sponsorsList: this.state.allSponsorsOptions,
      sponsorsPagination: this.initSponsorsPagination,
    })
  }
  handleCollaboratorsClose = () => {
    this.setState({
      collaboratorsSearchCallId: '',
      collaboratorsQuery: '',
      collaboratorsPaginateCallId: '',
      collaboratorList: this.state.allCollaboratorsOptions,
      collaboratorPagination: this.initCollaboratorsPagination,
    })
  }
  handleApproversClose = () => {
    this.setState({
      approversSearchCallId: '',
      approverQuery: '',
      approversPaginateCallId: '',
      approversList: this.state.allApproversOptions,
      approverPagination: this.initApproversPagination,
    })
  }

  filterUnique = (items: any[]) => {
    console.log(items, "##########FU")
    const hashmap = new Set()
    return items.filter((i: any) => {
      if (hashmap.has(i.id)) return false
      hashmap.add(i.id)
      return hashmap
    })
  }

  abc = (id: any) => {
    this.setState({ onGoingProjectEdit: true, loading: true })


    if (id) {
      this.getAnOngoingProjectDetailId = sendAPIRequest(`${configJSON.getAnOngoingProjectDetailEndPoint}${id}`, {
        method: 'GET',
        headers: { 'token': this.state.token }
      })
    }
  }

  handlePageChange = (event: any, value: any) => {
    this.setState({ pages: value, loading : true }, this.getOngoingProjectApi)
  }

  handleFileChange = (event: any) => {
    if (event?.target?.files) {
      this.setState({
        profile_pic: event.target.files[0],
        profile_pic_show: URL.createObjectURL(event.target.files[0]),
      });
    }
  }
  handlePreferenceChange = (setFieldValue: any,event: any) => {
    const selectedPreference = event.target.value;
    this.setState((prevState) => {
      if (prevState.preferenceState !== selectedPreference) {
        setFieldValue("preference", selectedPreference)
        setFieldValue("dimensions", "")
        this.fetchDimensions(selectedPreference);
        return { preferenceState: selectedPreference };
      }
      return null;
    });
  };
  handleSDGGoalChange = (setFieldValue: any,event: any) => {
    const selectedGoal = event.target.value;
    this.setState((prevState) => {
      if (prevState.goalState !== selectedGoal) {
        setFieldValue("sdg_goals", selectedGoal)
        setFieldValue("sdg_targets", "")
        this.fetchTarget(selectedGoal);
        return { goalState: selectedGoal };
      }
      return null;
    });
  };

  fetchDimensions = async(preference: any) => {
    let authToken = await getStorageData("authToken")

    if (preference) {
      this.getDimensionsId = sendAPIRequest(`${configJSON.getDimensionsEndPoint}${preference}`, {
        method: 'GET',
        headers: { 'token': authToken }
      })
    }
  }

  fetchTarget = async(target: any) => {
    let authToken = await getStorageData("authToken")


    if (target) {
      this.getTargetId = sendAPIRequest(`${configJSON.getTargetEndPoint}${target}`, {
        method: 'GET',
        headers: { 'token': authToken }
      })
    }
  }

  navToFun = (route: string, props: Props) => {
    const projectMessageData = new Message(getName(MessageEnum.NavigationMessage));
    projectMessageData.addData(getName(MessageEnum.NavigationTargetMessage), route);
    projectMessageData.addData(getName(MessageEnum.NavigationPropsMessage), props);   
    this.send(projectMessageData);
  }

  // Customizable Area End

}

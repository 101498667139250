import React from "react";
// Customizable Area Start
import UpdatedReportsController, {
	Props,
} from "./UpdatedReportsController.web";
import { styled } from '@material-ui/styles'
import {
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableCell,
	TableRow,
	Paper,
	Avatar,
	Link,
	Grid,
	Box,
	ListItem,
    ListItemAvatar,
    ListItemText,
} from '@material-ui/core';
import TopNavBar from "../../../components/src/TopNavbar";
import LeftMenuWeb from "../../../components/src/LeftMenuWeb";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Pagination from '@material-ui/lab/Pagination';
import { xlsxIcon, calendar } from './assets';
import OverlayLoader from '../../../components/src/Loader.web'
import moment from "moment";
import PlusMorePopup from '../../../components/src/PlusMorePopup.web'
import { Link as Links } from 'react-router-dom'
import AvatarGroup from '@material-ui/lab/AvatarGroup';
// Customizable Area End
export default class ProjectMileStoneReport extends UpdatedReportsController {
	constructor(props: Props) {
		super(props);
		// Customizable Area Start
		// Customizable Area End
	}

	// Customizable Area Start
	// Customizable Area End

	render() {
  		// Customizable Area Start
		const { navigation } = this.props;

		const ImportButtonBase = (props: any) => {
			const icon = xlsxIcon
			const clickHandler = props.onClick || (() => { });
			const label = "Download"
			return <ImportButton onClick={clickHandler}>
				<img src={icon} width="25px" />{label}
			</ImportButton>
		}

		const DownloadButton = () => {
			return <ImportButtonBase />
		}

  		// Customizable Area End

		return (
      		// Customizable Area Start
			<MainBox>
				<Box>
					<TopNavBar navigation={this.props.navigation} id={""} />
				</Box>

				<OuterGrid container spacing={3}>
					<LeftGrid item xs={12} sm={12} md={3} lg={3}>
						<Box >
							<LeftMenuWeb /*closeModal={this.closeModal}*/ navigation={this.props.navigation} id={""} />
						</Box>
					</LeftGrid>
					<MiddleGrid item xs={12} sm={12} md={6} lg={6} >
						<div style={{ justifyContent: "space-between", display: "flex", alignItems: "center" }}>
							<div style={{ display: "flex" }} >
								<div data-test-id="navigate-back-button" onClick={navigation.goBack} style={{ margin: "40px 0px 15px 12px",cursor: "pointer" }}  >
									<ArrowBackIosIcon />
								</div>
								<CoinsHead>PROJECT MILESTONE REPORT</CoinsHead>
							</div>
							{(this.state.projectMilestoneUrl!==undefined && this.state.projectMilestoneUrl!==null && this.state.projectMilestoneUrl?.length>0) ? <Link data-test-id="download-project-report-url" href={this.state.projectMilestoneUrl}>
								<DownloadButton />
							</Link> :
								<div data-test-id="download-project-report" onClick={() => this.showPopup()}>
									<DownloadButton />
								</div>
							}
						</div>
						<div style={{ marginTop: '20px', alignItems: 'center' }}>
							<TableContainer component={Paper}>
								<Table>
									<TableHead>
										<TableRowBox>
											<TableCell style={{ fontWeight: 'bold', color: 'black' }}>SL.no</TableCell>
											<TableCell style={{ fontWeight: 'bold', color: 'black' }}>Objective ID</TableCell>
											<TableCell style={{ fontWeight: 'bold', color: 'black' }}>Project ID</TableCell>
											<TableCell style={{ fontWeight: 'bold', color: 'black' }}>Project Name</TableCell>
											<TableCell style={{ fontWeight: 'bold', color: 'black' }}>Milestone ID</TableCell>
											<TableCell style={{ fontWeight: 'bold', color: 'black' }}>Milestone Categories</TableCell>
											<TableCell style={{ fontWeight: 'bold', color: 'black' }}>Milestone Name</TableCell>
											<TableCell style={{ fontWeight: 'bold', color: 'black' }}>Start Date</TableCell>
											<TableCell style={{ fontWeight: 'bold', color: 'black' }}>End Date</TableCell>
											<TableCell style={{ fontWeight: 'bold', color: 'black' }}>Milestone Leader</TableCell>
											<TableCell style={{ fontWeight: 'bold', color: 'black' }}>Milestone Moderator</TableCell>
										</TableRowBox>
									</TableHead>
									<TableBody>
										{this.state.projectMilestoneList?.length>0 ? this.state.projectMilestoneList?.map((value: any, index: any) => {
											const { attributes } = value
											const milestone_moderator_list = attributes?.milestone_moderators?.length>0 ? attributes?.milestone_moderators?.map((user: any) => <Links to={`/user/${user?.data?.id}`}><ListItemUser key={user?.data?.id} user={user} /></Links>) : []
											const milestone_leader = attributes?.milestone_leaders?.length>0 ? attributes?.milestone_leaders?.map((user: any) => <Links to={`/user/${user?.data?.id}`}><ListItemUser key={user?.data?.id} user={user} /></Links>) : []
											
											return (
												<TableBodyBox>
													<TableCell>{10 * (this.state.currentPageOfProjectMilestone - 1) + (index + 1)}</TableCell>
													<TableCell>{attributes?.objective_id}</TableCell>
													<TableCell >{attributes?.project_id}</TableCell>
													<TableCell>{attributes?.project_name}</TableCell>
													<TableCell>{attributes?.milestone_unique_id}</TableCell>
													<TableCell>{attributes?.sub_category_name}</TableCell>
													<TableCell>{attributes?.milestone_name}</TableCell>
													<TableCell>{<div style={{display:"inline"}}>{moment(attributes?.start_date)?.format("DD/MM/YYYY")}<img style={{width:"20px",verticalAlign: 'middle', marginLeft:"8px"}} src={calendar}/></div>}</TableCell>
													<TableCell>{<div style={{display:"inline"}}>{moment(attributes?.end_date)?.format("DD/MM/YYYY")}<img style={{width:"20px",verticalAlign: 'middle', marginLeft:"8px"}} src={calendar}/></div>}</TableCell>
													<TableCell>
													<PlusMorePopup 
														isRedirect={true}
														style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', whiteSpace: 'nowrap', }} 
														heading="Milestone Leaders" 
														listItems={milestone_leader}
													>
														<AvatarGroup style={{marginRight:'5px'}} >
														{attributes?.milestone_leaders?.length>0 && attributes?.milestone_leaders?.slice(0, 3)?.map((data: any) => {
																return (
																	<Avatar style={{ width: "34px", height: '34px', objectFit: 'cover', border: 'solid 1px #196519', borderRadius: "50%", verticalAlign: "middle" }} src={data?.data?.attributes?.profile_pic} alt="profile picture" />
																)
															})}
														</AvatarGroup>
														{
															attributes?.milestone_leaders?.length > 3 && <>
																{`+${attributes?.milestone_leaders?.length - 3} more`}
															</>
														}
													</PlusMorePopup>
													</TableCell>
													<TableCell>
														<PlusMorePopup 
															isRedirect={true}
															style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', whiteSpace: 'nowrap', }} 
															heading="Milestone Moderators" 
															listItems={milestone_moderator_list}
														>
															<AvatarGroup style={{marginRight: '5px'}}>
															{attributes?.milestone_moderators?.length>0 && attributes?.milestone_moderators?.slice(0, 3)?.map((data: any) => {
																	return (
																		<Avatar style={{ width: "34px", height: '34px', objectFit: 'cover', border: 'solid 1px #196519', borderRadius: "50%", verticalAlign: "middle" }} src={data?.data?.attributes?.profile_pic} alt="profile picture" />
																	)
																})}
															</AvatarGroup>
															{
																attributes?.milestone_moderators?.length > 3 && <>
																	{`+${attributes?.milestone_moderators?.length - 3} more`}
																</>
															}
														</PlusMorePopup>
													</TableCell>
												</TableBodyBox>
											)
										}) : <TableCell style={{textAlign:"center"}} colSpan={14}>No Data Found</TableCell>}
									</TableBody>
								</Table>
							</TableContainer>
							<Pagin>
								{window.screen.width > 950 ?
									<Pagination disabled={this.state.totalPageOfProjectMilestone <= 1} defaultPage={1} color="primary" variant="outlined" count={this.state.totalPageOfProjectMilestone} page={this.state.currentPageOfProjectMilestone} onChange={this.handlePageChangeOfProjectMileStone} />
									:
									<Pagination disabled={this.state.totalPageOfProjectMilestone <= 1} defaultPage={1} color="primary" size="small" count={this.state.totalPageOfProjectMilestone} page={this.state.currentPageOfProjectMilestone} onChange={this.handlePageChangeOfProjectMileStone} />
								}
							</Pagin>
							<OverlayLoader loading={this.state.overlayLoaderForMilestone} />
						</div>
					</MiddleGrid>
				</OuterGrid>
			</MainBox>
			// Customizable Area End
		)
	}
}

// Customizable Area Start
const getEmail = (user: any) => `${user?.data?.attributes?.email}`

const ListItemUser = ({ user }: { user: any }) => <ListItem >
    <ListItemAvatar  >
        <Avatar src={user?.data?.attributes?.profile_pic} />
    </ListItemAvatar>
    <ListItemText primary={getEmail(user)} />
</ListItem>

const TableRowBox = styled(TableRow)({
	'& th': {
		borderRight: '1px solid rgba(224, 224, 224, 1)',
		textAlign: 'center',
	},
	'& th:first-child': {
		minWidth: '45px',
	},
	'& th:nth-child(2)': {
		minWidth: '120px',
	},
	'& th:nth-child(3)': {
		minWidth: '110px',
	},
	'& th:nth-child(4)': {
		minWidth: '110px',
	},
	'& th:nth-child(5)': {
		minWidth: '90px',
	},
	'& th:nth-child(6)': {
		minWidth: '110px',
	},
})

const MainBox = styled(Box)({
	backgroundColor: "#edebeb",
	display: "flex",
	flexWrap: 'wrap',
	flexDirection: "column",
	paddingBottom: "20px",
	overflowX: "hidden",
})

const OuterGrid = styled(Grid)({
	display: 'flex',
	flexWrap: 'wrap',
	justifyContent: 'space-between',
})

const LeftGrid = styled(Grid)({
	maxWidth: '300px',
	'@media screen and (max-width: 985px)': {
		maxWidth: '200px',
	},
	'@media screen and (max-width: 768px)': {
		display: 'none',
		visibility: 'hidden',
	},
})

const MiddleGrid = styled(Grid)({
	width: 'calc(100vw - 300px)',
	maxWidth: 'unset',
	flexBasis: 'unset',
	paddingRight: '30px !important',
	justifyContent: 'center',
	'@media screen and (max-width: 768px)': {
		width: '100%'
	}
})

const ImportButton = styled('div')({
	cursor: 'pointer',
	marginTop: "16px",
	color: '#1F7244',
	border: '1px solid #1F7244',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	gap: '10px',
	padding: '5px 12px',
	borderRadius: '28px',
	fontSize: '13px',
})


const TableBodyBox = styled(TableRow)({
	'& td': {
		borderRight: '1px solid rgba(224, 224, 224, 1)',
		textAlign: 'center',
	},
})

const Pagin = styled('div')({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	padding: '10px',
	'@media screen and (max-width:350px)': {
		display: 'initial',
		padding: '10px 1px',
	},
	'@media screen and (min-width:768px) and (max-width:850px)': {
		display: 'initial',
		padding: '10px 1px',
	},
	'& > MuiPaginationItem-icon': {
		color: 'green',
	},

	ul: {
		"& > .MuiPaginationItem-root": {
			color: "green"
		}
	}
})

const CoinsHead = styled("div")({
	margin: "37px 0px 15px 12px",
	fontSize: "20px",
	fontFamily: "HelveticaNeue",
	fontWeight: 600,
	"@media screen and (max-width: 768px)": {
		margin: "20px 0px 10px 12px",
	},
});
// Customizable Area End

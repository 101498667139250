Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Share";
exports.labelBodyText = "Share Body";

exports.btnShareTitle = "Share Builder.ai";
exports.url = "https://www.builder.ai/"
exports.quote = "We make software so easy, everyone can do it"
exports.hashtag = "#builderai";
exports.getUserList = "bx_block_share/get_users";
exports.sharePost = "bx_block_share/share";

// Customizable Area End
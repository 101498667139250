Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Analytics";
exports.labelBodyText = "Analytics Body";

exports.btnExampleTitle = "CLICK ME";
exports.profileDashboard = "/bx_block_peoplemanagement2/corporate_profile_dashboards";
exports.likeAProject = "/bx_block_like/likes";
exports.getLikes = "/bx_block_peoplemanagement2/corporate_profile_dashboards/get_likes_project";
exports.unlikeProject ="/bx_block_like/likes";
exports.shareProject = "/bx_block_share/share";
exports.createComments = "/bx_block_project_management/comments";
exports.getComments = "/bx_block_project_management/comments";
exports.likeProjectComment = "bx_block_like/likes";
exports.getCommentsLikes = "/bx_block_project_management/comments/show_comment_likes"
exports.commentsReply = "/bx_block_project_management/comments/create_reply"
exports.likeSharePost="/accounts/user_profile"
exports.categoryWiseProjects= "bx_block_peoplemanagement2/corporate_profile_dashboards/category_wise_project"
exports.allCollabrators ="bx_block_peoplemanagement2/corporate_profile_dashboards/get_users_collaborators"
exports.collabratorsUrl = "bx_block_peoplemanagement2/corporate_profile_dashboards"
exports.showAllCards = "bx_block_abilities/abilities/show_esg_journey"
exports.profileDashBoard= "bx_block_peoplemanagement2/corporate_profile_dashboards"
exports.getAllProjectsList = "bx_block_projectportfolio/end_user_projects"
exports.seekingFunds = "bx_block_projectportfolio/promote_contents"
exports.getuserAssociatedProject= "bx_block_projectportfolio/user_associated_projects"
exports.seekingFundsById = "bx_block_projectportfolio/promote_contents"
exports.getProjectUniqueId="bx_block_projectportfolio/project_seeking_funds/generate_project_id";
exports.getSDGFocusEndpoint="bx_block_projectportfolio/sdg_factors";
exports.sendRequestEndpoint="bx_block_projectportfolio/promote_contents";
exports.sendRequestCreateEndpoint="bx_block_projectportfolio/project_seeking_funds";
exports.sendProjectEditEndpoint="bx_block_projectportfolio/promote_contents";
exports.getFilteredProjects = "bx_block_projectportfolio/filter"
exports.projectDetailById= "bx_block_projectportfolio/project_details"
exports.filterdMyProjects = "bx_block_projectportfolio/filter_my_projects"
exports.unfavJobAPIEndpoint = "bx_block_favourites/favourites/:id"
exports.favJobAPIEndpoint = "bx_block_favourites/favourites";
exports.getUserLikeCountEndpoint = "bx_block_projectportfolio/promote_contents/get_likes_count?promote_content_id=:id"
exports.userSurveyEndPoint = "bx_block_projectportfolio/project_management_surveys"
exports.companyNameEndpoint= "bx_block_peoplemanagement2/corporate_profile_dashboards"
exports.showModeratorsCollaborators="bx_block_request_management/show_moderators_collaborators_list"
exports.collaboratorEndPoint = "bx_block_request_management/requests"
exports.getStatusESGRequiredDatEndpoint="bx_block_risk_management/objectives/permissions"
// Customizable Area End
import React, { RefObject, useEffect, useState }  from 'react'


import {runEngine} from '../../framework/src/RunEngine'
import MessageEnum, {
  getName
} from '../../framework/src/Messages/MessageEnum'
import { Message } from "../../framework/src/Message";
import generatePassword from 'generate-password';
const QuickBlox = require('quickblox/quickblox.min').QuickBlox;
const QuickBloxClient = new QuickBlox();
import { toast } from 'react-toastify'

type HttpMethods = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'

interface RequestParams {
	method?: HttpMethods;
	headers?: object;
	body?: object | FormData | any;
}

/**
* generic methods to construct and send an RestAPIRequestMessage
* returns callId - message id of the sent message, used to read response later
*/
export function sendAPIRequest(endpoint: string, params?: RequestParams) {
	const { method="GET", headers, body } = params || {}
	const requestMessage = new Message(
		getName(MessageEnum.RestAPIRequestMessage)
	)
	const callId = requestMessage.messageId
	requestMessage.addData(
		getName(MessageEnum.RestAPIResponceEndPointMessage),
		endpoint
	)

	requestMessage.addData(
		getName(MessageEnum.RestAPIRequestMethodMessage),
		method
	)

	if(body) 
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			body?.append ? body : JSON.stringify(body)
		)		
	
	if(headers)
		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			headers
		)

	runEngine.sendMessage(requestMessage.id, requestMessage)

	return callId
}


export function useDebounce(value: string, delay: number){
	const [debounced, setDebounced] = React.useState(value)

	React.useEffect(() => {
		const t = setTimeout(() => setDebounced(value), delay)
		return () => clearTimeout(t)
	}, [value, delay])

	return debounced

}

export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = React.useState<{width: number, height: number}>({
    width:-1,
    height: -1,
  });

  React.useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}



interface Args extends IntersectionObserverInit {

  freezeOnceVisible?: boolean

}


export function useIntersectionObserver(

  elementRef: RefObject<Element>,

  {

    threshold = 0,

    root = null,

    rootMargin = '0%',

    freezeOnceVisible = false,

  }: Args,

): IntersectionObserverEntry | undefined {

  const [entry, setEntry] = useState<IntersectionObserverEntry>()


  const frozen = entry?.isIntersecting && freezeOnceVisible


  const updateEntry = ([entry]: IntersectionObserverEntry[]): void => {

    setEntry(entry)

  }


  useEffect(() => {

    const node = elementRef?.current // DOM Ref

    const hasIOSupport = !!window.IntersectionObserver


    if (!hasIOSupport || frozen || !node) return


    const observerParams = { threshold, root, rootMargin }

    const observer = new IntersectionObserver(updateEntry, observerParams)
    observer.observe(node)
    console.log(node, observer)
    return () => observer.disconnect()
    // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [elementRef, JSON.stringify(threshold), root, rootMargin, frozen])


  return entry

}



export function formatMoneyINR(value: any): string {
    const val =  value.toLocaleString('hi', { style: 'currency', currency: 'INR' });
    return val.split('.')[0]
}

export function formatMoneyUSD(value: any): string {
    const val = value.toLocaleString('en-us', { style: 'currency', currency: 'USD' });
    return val.split('.')[0]
}

export function quickBlocksInitiated(
  email: string,
  fullName: string,
  APPLICATION_ID: string,
  AUTH_KEY: string,
  AUTH_SECRET: string,
  ACCOUNT_KEY: string
):any {
  let userEmail = email;
  let userName = fullName;

  const createAccount = async () => {
    await QuickBloxClient.init(APPLICATION_ID, AUTH_KEY, AUTH_SECRET, ACCOUNT_KEY);
    return new Promise((resolve, reject) => {
      QuickBloxClient.createSession((error: {
        code?: number;
        status?: string;
        detail?: string | string[];
        message: string | string[];
      }) => {
        if (error) {
          reject({ error: "User Already Exist" });
        } else {
          resolve(null);
        }
      });
    });
  };

  const createRandomPassword = () => {
    const passwordOptions = {
      numbers: true,
      length: 12,
      uppercase: true,
      excludeSimilarCharacters: true,
      symbols: false,
      strict: true,
    };
    const password = generatePassword.generate(passwordOptions);
    return "id" + password;
  };

  const createUserWithQuickBlox = (password: string) => {
    const userParams = {
      login: userEmail,
      password: password,
      full_name: userName
    };

    return new Promise((resolve, reject) => {
      QuickBloxClient.users.create(userParams, (error: {
        code?: number;
        status?: string;
        detail?: string | string[];
        message: string | string[];
      }, result: { id: string }) => {
        if (error) {
          reject({ error: "User Already Exist" });
        } else {
          let qb_id = result.id;
          let qb_pass = password;
          let qb_login = userEmail;
          console.log("QB start", qb_id, qb_pass, qb_login);
          resolve({ qb_id, qb_pass, qb_login });
        }
      });
    });
  };

  const initiate = async () => {
    try {
      await createAccount();
      const password = createRandomPassword();
      const user = await createUserWithQuickBlox(password);
      return user;
    } catch (error) {
      toast.error("Creating user on quick-blox failed");
      return error;
    }
  };

  return initiate();
}
interface RecordAttributes {
  id: number;
  user_name: string | null;
  created_at: string;
  updated_at: string;
  email: string;
  company_name: string | null;
  ability_card: string;
  ability_type: string;
  profile_picture: string | null;
  card_serial_number: number;
  card_banner: string;
}

interface Record {
  id: string;
  type: string;
  attributes: RecordAttributes;
}

interface ESGRecordData {
  access: {
    data: Record[];
  };
}
export function esgPathRecords(esgData:ESGRecordData):boolean{
  const eSGRecord = [
    {
      "cardName": "Corporate Profile Form",
      "ability_card": "/CorporateProfileForm"
    },
    {
      "cardName": "Stakeholder Corporate Profile",
      "ability_card": "/StakeHolderProfile"
    },
    {
      "cardName": "Self Assessments",
      "ability_card": "/ESGJourneyReportingFrameworks"
    },
    {
      "cardName": "Self Assessment Dashboard",
      "ability_card": "/AssesmentDashboard"
    },
    {
      "cardName": "Risk and Opportunity Management",
      "ability_card": "/RiskManagement"
    },
    {
      "cardName": "Initiative Evaluation",
      "ability_card": "/InitiativeEvaluation"
    },
    {
      "cardName": "Supplier Due Diligence Form",
      "ability_card": "/DueDiligence"
    },
    {
      "cardName": "Carbon Emission Calculator - GHG Tool",
      "ability_card": "/CarbonCalculatorCard"
    },
    {
      "cardName": "Reports",
      "ability_card": "/Reports"
    },
    {
      "cardName": "Reference Policies",
      "ability_card": "/ReferencePolicy"
    }
  ]
  let cardPath = window.location.pathname
  let cardRecord = eSGRecord.find((esgData : {
    "cardName": string,
    "ability_card":string
  })=> esgData.ability_card === cardPath)
  let cardName = cardRecord ? cardRecord.cardName : ""
  const pathSegments = cardPath.split('/');
  const hasPermissionDashboard = pathSegments.includes('PermissionDashboard');
  const hasESGJourney = pathSegments.includes('ESGJourney')
  let corporatePaths = hasPermissionDashboard || hasESGJourney;
  const abilityResult = esgData.access.data.some(record => record.attributes.ability_card === cardName);
  return abilityResult && !corporatePaths
}
import React from "react";

// Customizable Area Start
import {
  Button,
  Divider,
  InputAdornment,
  MenuItem,
  Paper,
  Tabs,
  Tab,
  Typography,
  Menu,
  CircularProgress
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import { styled } from '@material-ui/styles'
import InsertLinkIcon from '@material-ui/icons/InsertLink';
import { Formik, useField } from 'formik'
import { sendAddRequestMessage } from '../../RequestManagement/src/Messages'
import DeleteModal from '../../../components/src/DeleteModal.web'
import Attachments from '../../../components/src/Attachments.web'
import { iconThumbGreen, iconLocation, iconAdd, iconUpload, logoIkea, logoCowi, logoWashington, LogoHex } from './assets'
import MediaCarousel from '../../../components/src/MediaCarousel.web'
import FilterPosts from '../../filteritems/src/FilterPosts.web'
// import { iconThumbGreen } from '../../blocks/CfCourseListing5/src/assets'
import FilterItems from '../../filteritems/src/Filteritems.web'
import DashboardLayout from '../../../components/src/DashboardLayout.web'
import RoundedButton from '../../../components/src/RoundedButton.web'
import PostCard, { CommonActions, Image, HorizontalPadding } from '../../../components/src/PostCard.web'
import Search from '../../../components/src/Search.web'
import FormModal, { InputRow, MInput, MSelect, MFileDrop, MCountedTextArea, MCheckboxes } from '../../../components/src/FormModal.web'
import UserContext from "../../../components/src/UserProvider";
import MarketplaceTopbar from '../../../components/src/MarketplaceTopbar.web'
import FileDrop from '../../../components/src/FileDrop.web'
import SeeMore from '../../../components/src/SeeMore.web'
import Flex from '../../../components/src/Flex.web'
import { LoadingSpinner } from '../../../components/src/LoadingSpinner.web'
import LikeAPost from '../../LikeAPost/src/LikeAPost.web'
import Maps from '../../maps/src/Maps.web'

import { CreateFormValues } from './types'
import InfiniteScroll from 'react-infinite-scroll-component';
import PlusMorePopup from '../../../components/src/PlusMorePopup.web'

interface ApplyFormValues {
  name: string;
  email: string;
  phone_number: string;
  location: string;
  comment: string;
  file: File | string;
}
import { commentCount } from "../../ActivityFeed/src/assets";
// Customizable Area End

import JobListing2Controller, {
  Props,
  configJSON
} from "./JobListing2Controller";

export default class JobListing2 extends JobListing2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }


  // Customizable Area Start
  ApplyJobModal() {
    const initialValues: ApplyFormValues = {
      name: '', email: '', phone_number: '', location: '', comment: '', file: ''
    }
    const juid_split = this.state.selectedJob?.job_unique_id.split('-') || ""
    const [job_unique_id, job_unique_id_2] = [juid_split[0], juid_split[1]]
    const labels: string[] = ['Job Title', 'Name*', 'Email ID*', 'Phone Number*'];
    const inputs: {
      name: string;
      value?: string;
      disabled?: boolean;
      placeholder?: string;
      type?: string;
    }[] = [
      { name: 'job_title', value: this.state.selectedJob?.job_title, disabled: true },
      { name: 'name', placeholder: 'Enter full name' },
      { name: 'email', type: 'email', placeholder: 'Enter email id' },
      { name: 'phone_number', placeholder: 'Enter phone number' },
    ];
    return <Formik initialValues={initialValues} validationSchema={this.applyFormSchema} onSubmit={this.handleApplyJobSubmit}>
      <FormModal headerTitle={<ApplyHeader>APPLY JOB</ApplyHeader>} submitLabel="apply" onClose={this.hideModal}>
        <InputRow
          label={<ApplyLabel>Job ID</ApplyLabel>}
          input={
          <IdWrapper >
            <MInput disabled name="job_unique_id" value={job_unique_id} />
            <MInput disabled name="job_unique_id_2" value={job_unique_id_2} />
          </IdWrapper>}
        />
        {labels.map((label, index) => (
          <InputRow
            key={index}
            label={<ApplyLabel>{label}</ApplyLabel>}
            input={<MInput name={inputs[index].name} value={inputs[index].value} disabled={inputs[index].disabled} />}
          />
        ))}
        <InputRow label={<ApplyLabel>Location*</ApplyLabel>} input={<><Maps input={true} navigation={this.props.navigation} id={"InputMap"}  autoCompleteStyles={undefined} customBorder={""}/></>} />
        <InputRow label={<ApplyLabel>Comment*</ApplyLabel>} input={<><MCountedTextArea maxLength={550} name="comment" placeholder="Type your comment" /></>} />
        <InputRow label={<ApplyLabel>Upload CV*</ApplyLabel>}
          input={<UploadButton
            maxSize={2 * 1024 * 1024}
            maxSizeError={"Cannot upload document more than 2Mb"}
            filetype="application/pdf, application/msword"
            name="file"
            label="Upload"
          />}
        />
      </FormModal>
      </Formik>
  }

  jobsList = () => {
    return this.state.jobs.map(
      job => <PostCard
        onThreeDotClick={this.openThreeDot}
        dataId={job.id}
        header={{ name: job.created_by.name, profile_pic: job.created_by.profile_pic, created_at: `${this.toTitleCase(job.created_by.location)} | ${job.created_at.replace('about', '')}`, avatarLink: `/user/${job.created_by.id}` }}
        key={job.id}
      >
        <Divider />
        <HorizontalPadding>
          {/* <Link to={`/job/${job.id}`}> */}
            <FlexCol>
              <InnerFlexCol>
                <BoldTypography variant="subtitle1">{job.job_title}</BoldTypography>
                <ContainerDiv> <img src={iconLocation} width="20"/>{job.location} </ContainerDiv>
                <Flex col>
                    <Typography color="textSecondary">{job.description.length > 455 ? `${job.description.substring(0, 455)}` : job.description}{job.description.length> 455 ? <Link style={{ fontWeight: "bold"}} to={`/job/${job.id}`}>...See more</Link> : null}</Typography>
                  </Flex>
              </InnerFlexCol>
              <Content>
                <Category>
                  <BoldBlock>Category:</BoldBlock> {job.job_category[0]}{job.job_category.length > 1 && <PlusMorePopup heading="Category" listItems={job.job_category}> +{job.job_category.length - 1}</PlusMorePopup>}
                </Category>
                <Experience>
                  <b>Experience Required:</b> {job.experience_required}{Number(job.experience_required)>1 ? "Years" : "Year"}
                </Experience>
                <JobId>
                  <BoldBlock>Job ID:</BoldBlock> {job.job_unique_id}
                </JobId>
              </Content>
              {job.reference_link && 
              <Flex gap={10} align="center">
                <BoldBlock>Reference Link:</BoldBlock>
                 <a style={{ color: "#48a15b",}} href={job.reference_link} target="blank">
                  <div style={{display:'flex', flexDirection:'column'}}>
                    <img src={LogoHex} width="25px" />
                    <InsertLinkIcon style={{color:'inherit', marginTop:'-16px', marginLeft:'8px'}}/>
                  </div>
                </a>
              </Flex>}
            </FlexCol>
          {/* </Link> */}
          {job.images && <MediaCarousel items={job.images} />}
          <FlexSpaceBetween>
            <ContainerDiv>
          <LikeArea>
            <LikeContent>
              <img src={iconThumbGreen} />
              <ContainerSpan>{job.total_likes}</ContainerSpan>
              <img src={commentCount} />
              <ContainerSpan>{job.total_comments}</ContainerSpan>
            </LikeContent>
          </LikeArea>
            </ContainerDiv>
            {
              <RoundedButton onClick={this.handleJobApply} data-id={job.id} style={{ padding: '0px 40px', height: '50px' }}>Apply</RoundedButton>
            }
          </FlexSpaceBetween>
          <Attachment>
            <Attachments
              files={(job.attachments || []).map((j: any) => ({ ...j, }))}
            />
          </Attachment>
          
        </HorizontalPadding>

        <Divider />
        <CommonActions
          onCommentClick={this.showDetails(job.id)}
          onStarClick={this.handleFavClick(job.id)}
          onShareClick={this.handleShareClick(job)}
          isFavourite={job.is_favourite}
          likeButton={
            <LikeAPost
              navigation={this.props.navigation}
              id=""
              likeable_type="BxBlockJoblisting2::Job"
              isLiked={!!job.like_id[0]?.id}
              post_id={String(job.id)}
              like_id={job.like_id[0]?.id}
              onLikeChange={this.onLikeChange}
              onLikeIdUpdate={this.onLikeIdUpdate}
            />
          }
        />
      </PostCard>
    )
  }

  threeDotMenu = () => {
    console.log({ threedotmenu: '', context: this.context, cp: this.state })
    return <>
      {this.state.deleteJobId && <DeleteModal onClose={this.closeDeleteModal} onSubmit={this.handleJobDeleteConfirm} />}
      <Menu
        id="threedot-menu"
        anchorEl={this.state.threedotAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        keepMounted
        open={Boolean(this.state.threedotAnchorEl)}
        onClose={this.handleThreeDotClose}
      >
        <MenuItem
          disabled={this.state.currentJob?.created_by?.id == this.context.id}
          onClick={this.handlePostReport}>Report This Listing</MenuItem>
        <MenuItem
          disabled={!(this.state.currentJob?.created_by?.id == this.context.id)}
          onClick={this.handlePostEdit}
        >
          Edit
        </MenuItem>
        <MenuItem
          disabled={!(this.state.currentJob?.created_by?.id == this.context.id)}
          onClick={this.handlePostDelete}
        >
          Delete
        </MenuItem>
        <MenuItem
          disabled={this.state.currentJob?.created_by?.id == this.context.id}
          onClick={this.handleRaiseRequest}
        >
          Raise A Request
        </MenuItem>
      </Menu>
    </>
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const tabLabels = [
      configJSON.labelMyJobs,
      configJSON.labelMyEvents,
      configJSON.labelMyProducts,
      configJSON.labelMyServices,
      configJSON.labelMyCourses,
    ]
    const applyModal = this.ApplyJobModal()
    const filterMenu = <FilterPosts filterAll={this.onJobsFilterChange} onQueryChange={this.onFilterQueryChange} favouriteable_type="BxBlockJoblisting2::Job" id={this.props.id} navigation={this.props.navigation} />
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <DashboardLayout token={this.state.token} navigation={this.props.navigation}>
          <MarketplaceTopbar userRole={this.context.role.role_level} buttonMinWidth="200px" filters={filterMenu} selectedTab={this.state.selectedMyTab} onTabChange={this.handleSelectedMyTabChange} onCreate={this.showCreateModal} />
          {this.threeDotMenu()}
          <WrapperDiv style={{ margin: '10px 0' }}>
            {!this.state.loadingJobs && this.state.jobs.length === 0 && <Typography style={{ padding: '50px 0' }} align="center" variant="h5">No Match Found</Typography>}
            {this.state.loadingJobs && <LoadingSpinner />}
            <InfiniteScroll
              dataLength={this.state.jobs.length} //This is important field to render the next data
              next={this.loadNextPage}
              hasMore={(!!this.state.pagination?.next_page) && !this.state.loadingJobs}
              // Show only when this.state.loadingPosts component is not rendered
              loader={!(!this.state.loadingJobs && this.state.jobs?.length === 0) && <LoadingSpinner />}
            >
              {this.jobsList()}
            </InfiniteScroll>

          </WrapperDiv>
          {/*     
          <RecommendedJobs />
        */}
        </DashboardLayout>
        {this.state.modal === 'apply' && applyModal}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

JobListing2Controller.contextType = UserContext




const UploadButton = (props: any) => {
  const [field, meta, helpers] = useField(props)
  const [filename, setFilename] = React.useState("")
  const [validationError, setValidationError] = React.useState("")
  const inputRef = React.useRef<HTMLInputElement>(null)
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.currentTarget.files && e.currentTarget.files[0]
    if (!file) return
    if (props.maxSize && file.size > props.maxSize) {
      setValidationError(props.maxSizeError || `Cannot upload document more than allowed size`)
      return
    }
    helpers.setValue(file)
    setFilename(file.name)
    setValidationError('')
  }

  const onClick = () => inputRef?.current && inputRef?.current?.click()

  return <label>
    <RoundedButton onClick={onClick} startIcon={<img width="25px" src={iconUpload} />}>{props.label}</RoundedButton>
    <input type="file" accept={props.filetype || "*"} onChange={onChange} ref={inputRef} name={props.name} style={{ display: 'none' }} />
    <div>{filename}</div>
    {validationError && <Typography color="error">{validationError}</Typography>}
    {meta.touched && (meta.error || validationError) && <Typography color="error">{meta.error || validationError}</Typography>}
  </label>
}

const dollarFieldProps = {
  placeholder: "Enter",
  InputProps: {
    startAdornment: <InputAdornment position="start">$</InputAdornment>
  }
}

const rupeeFieldProps = {
  placeholder: "Enter",
  InputProps: {
    startAdornment: <InputAdornment position="start">₹</InputAdornment>
  }
}

const IdWrapper = styled('div')({
  display: 'flex', 
  gap: '5px', 
  '@media screen and (max-width: 480px)': {
    flexWrap:'wrap',
  }
})


const BoldBlock = styled('div')({
  fontWeight: "bold"
})

const FlexCol = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '15px', 
  marginTop: '10px'
})

const InnerFlexCol = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '5px',
  '& .MuiTypography-root':{
    wordWrap: 'break-word', 
    alignItems: 'justify',
  },
  '& .MuiTypography-root.MuiTypography-subtitle1':{
    wordBreak: 'break-all',
  },
  '& img': {
    marginBottom: '-4px', 
    marginRight: '5px' 
  },
})

const Content = styled('div')({
  display: 'flex', 
  gap: '25px',
  '@media screen and (max-width: 480px)': {
    display: 'flex',
    flexDirection: 'column',
  },
})

const Category = styled('div')({
  wordBreak: 'break-all',
})

const Experience = styled('div')({
  wordBreak: 'break-all', 
  // maxWidth: '33.33%'
})

const JobId = styled('div')({
  wordBreak: 'break-all',
})

const RoundedButtonBase = styled(Button)({
  backgroundColor: '#009c05',
  color: 'white',
  '&:hover': {
    backgroundColor: '#009c05',
  }
})

const FlexSpaceBetween = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  margin: '20px 0'
})

const LikeArea = styled('div')({
  padding: '10px 0',
})

const LikeContent = styled('div')({
  lineHeight: '30px', 
  display: 'flex', 
  gap: '10px', 
  fontSize: '0.875rem', 
  color: '#212529',
  '& img': {
    width: '30px',
  },
})

const Attachment = styled('div')({
  padding: '5px 0', 
  marginTop: '-20px'
})

const recommendedJobs = [{
  title: 'Lecturer Sustainable Finance',
  company: 'University of Washington',
  location: 'Toronto, Canada',
  created_at: '2 hr ago',
  logo: logoWashington,
}, {
  title: 'Sustainability Expert',
  company: 'IKEA',
  location: 'Toronto, Canada',
  created_at: '2 hr ago',
  logo: logoIkea,
}, {
  title: 'Senior Consultant and Project Manager',
  company: 'IKEA',
  location: 'Toronto, Canada',
  created_at: '2 hr ago',
  logo: logoCowi,
},]

interface RJCardProps {
  title: string;
  company: string;
  location: string;
  created_at: string;
  logo: any;
}

const RJCard = (props: RJCardProps) => {
  return <RJCardWrapper>
    <div>
      <img src={props.logo} />
    </div>
    <div>
      <RJCardTitle variant="h6">{props.title}</RJCardTitle>
      <RJContent>
        <span>{props.company}</span>
        <Divider orientation="vertical" />
        <span><img src={iconLocation} width="20" style={{ marginBottom: '-4px', marginRight: '5px' }} />{props.location}</span>
        <Divider orientation="vertical" />
        <span>{props.created_at}</span>
      </RJContent>
    </div>
  </RJCardWrapper>
}

const RJCardTitle = styled(Typography)({

})

const RJContent = styled('div')({
  height: '20px',
  display: 'flex',
  gap: '15px',
  color: '#444'
})

const RJCardWrapper = styled('div')({
  padding: '20px',
  display: 'flex',
  alignItems: 'center',
  gap: '25px',
})

const RecommendedJobs = () => {
  return <RJWrapper>
    <RJHeader><b>Jobs</b> you may be interested in</RJHeader>
    <Divider />
    {recommendedJobs.map((rj, idx) => <React.Fragment key={idx}>
      <RJCard {...rj} />
      <Divider />
    </React.Fragment>)}
    <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
      <RoundedButton>SEE MORE</RoundedButton>
    </div>
  </RJWrapper>
}

const RJWrapper = styled('div')({
  borderRadius: '2px',
  margin: '20px 0px',
  background: 'white',
  padding: '10px 0px',
})

const RJHeader = styled('div')({
  borderRadius: '15px',
  border: '3px solid #eee',
  padding: '20px 20px',
  margin: '20px 10px',
  color: '#444'
})

const ApplyHeader = styled('div')({
  fontSize: '24px',
  fontWeight: 500,
  fontFamily: 'HelveticaNeue'
})

const ApplyLabel = styled('div')({
  fontSize: '15.35px',
  color: '#404040',
  fontFamily: 'HelveticaNeue'
})
const BoldTypography = styled(Typography)({
  fontWeight: "bold"
})

const ContainerDiv = styled('div')({})
const ContainerSpan = styled('span')({})
const WrapperDiv = styled('div')({
  margin: '10px 0' 
})
// Customizable Area End

import React from "react";
import {
  Avatar,
  Typography,
  Box,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import SurveyCardsController, { Props } from "./SurveyCardsController";
import DashboardLayout from "../../../components/src/DashboardLayout.web";
import ArrowForwardOutlined from '@material-ui/icons/ArrowForwardOutlined';
import OverlayLoader from '../../../components/src/Loader.web'



export default class SurveyCards extends SurveyCardsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  render() {
    const { navigation } = this.props;

    return (
      // Customizable Area Start
      <DashboardLayout isDashboard={true} isUserSurvey={false} navigation={navigation} >
        <CecfWrapper>
          <CecfHeadingWrapper>
            <CoinsHead>Survey Templates</CoinsHead>
          </CecfHeadingWrapper>
          <CecfCardWrapper>
            {this.state.cardsRow && this.state.cardsRow.length ? this.state.cardsRow.map((row, index) => (
              <SurveyBox data-testid="surveyCardType" key={row.cardNo} onClick={() => this.getNavigationMessage(row.File, this.props)}>
                <CecfCardArrowWrapper>
                  <ArrowForwardOutlined color="disabled" />
                </CecfCardArrowWrapper>
                <CecfCardContent>
                  <LogoDiv>
                    <Avatar style={{ width: 105.26, height: 101.87 }} src={row.img} />
                  </LogoDiv>
                  <HedingText>{row.Name}</HedingText>
                </CecfCardContent>
              </SurveyBox>
            )) : <></>
          }
          </CecfCardWrapper>
        </CecfWrapper>
      <OverlayLoader loading={this.state.loading} />

      </DashboardLayout>
      // Customizable Area End
    );
  }
  // Customizable Area End
}

// Customizable Area Start
const HedingText = styled(Typography)({
  fontWeight: 700,
  marginBottom: '4px',
  fontSize: "20px",
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'block',
  textTransform: 'capitalize',
  whiteSpace: 'pre-line',
  textAlign: 'center',
  lineHeight: '20px',

  '@media screen and (max-width:600px)': {
    width: '100%',
    textAline: 'center'
  },
  '@media screen and (min-width:769px) and (max-width:1200px)': {
    width: '100%',
    textAline: 'center'
  }
})

const SurveyBox = styled(Box)({
  backgroundColor: "white",
  display: "flex",
  flexDirection: "column",
  borderRadius: "15px",
  padding: "20px",
  height: "331px",
  width: "296px",
  filter: "drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.11))",
  cursor: "pointer"
})

const CecfWrapper = styled("div")({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  padding: '0px 40px'
});

const LogoDiv = styled('div')({
  margin: "5px 0 5px 0",
  display: "flex",
  justifyContent: "center",
  '& img': {
    objectFit: 'contain'
  }
})

const CoinsHead = styled("div")({
  margin: "40px 0px 25px 0px",
  fontSize: "1.4rem",
  fontWeight: "bolder",
  textTransform: "capitalize",
  "@media screen and (max-width: 768px)": {
    margin: "20px 0px 10px 12px",
  },
});

const CecfHeadingWrapper = styled("div")({
  display: "flex",
  alignItems: "center"
});

const CecfCardWrapper = styled("div")({
  display: 'flex',
  gap: '25px',
  flexWrap: 'wrap'
});

const CecfCardContent = styled("div")({
  display: 'flex',
  flexDirection: "column",
  justifyContent: 'space-around',
  alignItems: "center",
  height: "260px"
});

const CecfCardArrowWrapper = styled("div")({
  display: 'flex',
  justifyContent: 'end'
});
// Customizable Area End
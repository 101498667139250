import React from "react";
// Customizable Area Start
import {
    MenuItem,
    Avatar,
    Button, Fade, Backdrop, Modal, Box, Typography
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { warningIcon } from './assets'
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import { styled } from '@material-ui/styles';
import Flex from "../../../components/src/Flex.web";
import Attachments from '../../../components/src/Attachments.web';
import { Formik } from 'formik'
import FormModal, { InputRow, MInput, MSelect, MCountedTextArea, MDatePicker } from '../../../components/src/FormModal.web'
import UserContext from "../../../components/src/UserProvider";
// Customizable Area End
import CreateRequestToListController, { Props } from "./ProjectMilstoneController";


// Customizable Area Start
interface UserModalInterface {
    showModal: boolean
    handleModal: any
    Delete?: any
    value?: any
    onClose?: any
    onDelete?: any
}

export const UserModal = ({ showModal, handleModal, Delete, value, onClose, onDelete }: UserModalInterface) => {
    return (
        <Modal
            open={showModal}
            onClose={() => handleModal(value)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={showModal}>
                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
                    <Box style={{ backgroundColor: '#fff', position: 'relative', padding: '30px 30px', borderRadius: '8px', textAlign: 'center', maxWidth: '500px', margin: '0 auto' }}>

                        <Typography variant="h6" component="h2">
                            <img src={warningIcon} alt='waning' style={{ width: "50px", marginBottom: "24px" }} />
                        </Typography>
                        <Typography >
                            Are you sure you want to delete Milestone ?
                        </Typography>
                        <Box style={{ display: 'flex', marginTop: '20px', alignItems: 'center', justifyContent: 'center', gap: '20px' }}>
                            <button onClick={() => onDelete()} style={{ backgroundColor: 'green', color: '#fff', borderRadius: '5px', padding: '5px 20px', border: 'none', cursor: 'pointer' }}>Yes</button>
                            <button onClick={() => onClose()} style={{ backgroundColor: 'red', color: '#fff', borderRadius: '5px', padding: '5px 20px', cursor: 'pointer', border: 'none' }}>No</button>
                        </Box>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    )
}
// Customizable Area End

export default class CreateRequestToList extends CreateRequestToListController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    selectedItem: any;
    getuserDetails: any;
    CreateTemplateModal() {
        let initialValues: any = {
            category: -1,
            preference_ids: [],
            category_ids: [],
            sub_category_ids: [],
        }



        return <Formik enableReinitialize initialValues={initialValues} onSubmit={() => { }} validationSchema={{}}


        >
            <ModalWrap>
                <FormModal modalStyle={{ maxWidth: '700px' }} headerTitle={<><b>VIEW MILESTONE </b>
                    <Button onClick={() => {
                        this.setState({ deleteId: this.state?.milestoneViewData?.id, delete: true })
                    }} variant="outlined" style={{ float: 'right', borderColor: 'red', color: 'red', marginRight: '50px' }} startIcon={<DeleteIcon />}>
                        Delete
                    </Button>
                    <Button onClick={() => this.props.navigation.history.push(`/ProjectMilestone/EditMilestone/${this.state?.milestoneViewData?.id}`)
                    } variant="outlined" style={{ float: 'right', borderColor: '#429846', color: '#429846', marginRight: '15px' }} startIcon={<EditIcon />}>
                        Edit
                    </Button>

                </>} buttonHide onClose={this.goBack} >
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                        <InputRow label="Objective ID" input={
                            <MInput name="objectiveId" value={this.state?.milestoneViewData?.attributes?.objective_id} disabled />
                        } />

                        <InputRow label="Project ID" input={
                            <MInput name="projectId" value={this.state?.milestoneViewData?.attributes?.project_id} disabled />
                        } />

                        <InputRow label="MilestoneID" input={
                            <MInput name="project_name" value={this.state?.milestoneViewData?.attributes?.milestone_unique_id} disabled />
                        } />
                    </div>
                    <InputRow label="Milestone Name*" input={
                        <MInput name="milestone_name" value={this.state?.milestoneViewData?.attributes?.milestone_name
                        } disabled />
                    } />

                    <InputRow label={<JobLabel>Milestone Category*</JobLabel>} input={
                        <MSelect name="form_of_funding" value={-1} disabled>
                            <MenuItem disabled value={-1}>{this.state?.milestoneViewData?.attributes?.sub_category_name}</MenuItem>

                        </MSelect>
                    } />
                    <Flex gap={8}>
                        <InputRow label={<JobLabel>Start Date*</JobLabel>} style={{ width: "50%" }} input={

                            <MDatePicker name="start_date" value={this.state?.milestoneViewData?.attributes?.start_date}
                                InputProps={{ disabled: true }}
                            />
                        } />
                        <InputRow label={<JobLabel>End Date*</JobLabel>} style={{ width: "50%" }} input={

                            <MDatePicker name="end_date" value={this.state?.milestoneViewData?.attributes?.end_date}
                                InputProps={{ disabled: true }}
                            />
                        } />
                    </Flex>
                    <Flex gap={8}>
                        <InputRow label={<JobLabel>Milestone Leader*</JobLabel>} style={{ width: "50%" }} input={

                            <AvatarGroup max={3} style={{ border: '1px solid #bdbdbd', height: '38px', padding: '8px 8px 0px', borderRadius: '5px' }}>
                                {this.state?.milestoneViewData?.attributes?.milestone_leaders?.map((item: any) => (
                                    <Avatar style={{ marginRight: "5px", height: "25px", width: "25px", border: "3px solid rgb(242, 212, 212)" }} alt="Remy Sharp" src={item?.data?.attributes?.profile_pic} />
                                ))}
                            </AvatarGroup>
                        } />

                        <InputRow label={<JobLabel>Milestone Moderator*</JobLabel>} style={{ width: "50%" }} input={
                            <AvatarGroup max={3} style={{ border: '1px solid #bdbdbd', height: '38px', padding: '8px 8px 0px', borderRadius: '5px' }}>
                                {this.state?.milestoneViewData?.attributes?.milestone_moderators?.map((item: any) => (
                                    <Avatar style={{ marginRight: "5px", height: "25px", width: "25px", border: "3px solid rgb(242, 212, 212)" }} alt="Remy Sharp" src={item?.data?.attributes?.profile_pic} />
                                ))}
                            </AvatarGroup>

                        } />

                    </Flex>

                    <InputRow label={<JobLabel>KPI*</JobLabel>} input={
                        <MCountedTextArea length={100} maxRows={1} name="description" value={this.state?.milestoneViewData?.attributes?.kpi} disabled />
                    } />

                    <Flex gap={8}>
                        <InputRow label={<JobLabel>UoM</JobLabel>} style={{ width: "50%" }} input={
                            <MSelect name="form_of_funding" value={-1} disabled >
                                <MenuItem disabled value={-1}>{this.state?.milestoneViewData?.attributes?.uom
                                }</MenuItem>

                            </MSelect>
                        } />

                        <InputRow label="Baseline (Numeric)" style={{ width: "50%" }} input={
                            <MInput name="baseline" value={this.state?.milestoneViewData?.attributes?.baseline} disabled />
                        } />

                    </Flex>

                    <Flex gap={8}>

                        <InputRow label="Target Output Direct (Numeric)" style={{ width: "50%" }} input={
                            <MInput name="target_direct" value={this.state?.milestoneViewData?.attributes?.target_output_direct} disabled />
                        } />

                        <InputRow label="Actual Output Direct (Numeric)" style={{ width: "50%" }} input={
                            <MInput name="actual_direct" value={this.state?.milestoneViewData?.attributes?.actual_output_direct} disabled />
                        } />
                    </Flex>


                    <InputRow label={<JobLabel>Target Outcome Direct</JobLabel>} input={
                        <MCountedTextArea length={100} maxRows={1} name="description" value={this.state?.milestoneViewData?.attributes?.target_outcome_direct} disabled />
                    } />

                    <InputRow label={<JobLabel>Actual Outcome Direct</JobLabel>} input={
                        <MCountedTextArea length={100} maxRows={1} name="description" value={this.state?.milestoneViewData?.attributes?.actual_outcome_direct} disabled />
                    } />

                    <Flex gap={8}>

                        <InputRow label="Target Output Indirect (Numeric)" style={{ width: "50%" }} input={
                            <MInput name="target_direct" value={this.state?.milestoneViewData?.attributes?.target_output_indirect} disabled />
                        } />

                        <InputRow label="Actual Output Indirect (Numeric)" style={{ width: "50%" }} input={
                            <MInput name="actual_direct" value={this.state?.milestoneViewData?.attributes?.actual_output_indirect} disabled />
                        } />
                    </Flex>
                    <InputRow label={<JobLabel>Target Outcome Indirect</JobLabel>} input={
                        <MCountedTextArea length={100} maxRows={1} name="description" value={this.state?.milestoneViewData?.attributes?.target_outcome_indirect} disabled />
                    } />

                    <InputRow label={<JobLabel>Actual Outcome Indirect</JobLabel>} input={
                        <MCountedTextArea length={100} maxRows={1} name="description" value={this.state?.milestoneViewData?.attributes?.actual_outcome_indirect} disabled />
                    } />
                    <Flex gap={8}>

                        <InputRow label="Estimated Cost*" style={{ width: "50%" }} input={
                            <MInput name="target_direct" value={this.state?.milestoneViewData?.attributes?.estimated_cost} disabled />
                        } />

                        <InputRow label="Actual Cost" style={{ width: "50%" }} input={
                            <MInput name="actual_direct" value={this.state?.milestoneViewData?.attributes?.actual_cost} disabled />
                        } />
                    </Flex>

                    <InputRow label={<JobLabel>Estimated Inputs</JobLabel>} input={
                        <MCountedTextArea length={100} maxRows={1} name="description" value={this.state?.milestoneViewData?.attributes?.estimated_inputs} disabled />
                    } />

                    <InputRow label={<JobLabel>Actual Inputs</JobLabel>} input={
                        <MCountedTextArea length={100} maxRows={1} name="description" value={this.state?.milestoneViewData?.attributes?.actual_inputs} disabled />
                    } />

                    <Flex gap={8}>

                        <InputRow label="Estimated Capex" style={{ width: "50%" }} input={
                            <MInput name="target_direct" value={this.state?.milestoneViewData?.attributes?.estimated_capex} disabled />
                        } />

                        <InputRow label="Actual Capex" style={{ width: "50%" }} input={
                            <MInput name="actual_direct" value={this.state?.milestoneViewData?.attributes?.actual_capex} disabled />
                        } />
                    </Flex>

                    <Flex gap={8}>

                        <InputRow label="Estimated Saving" style={{ width: "50%" }} input={
                            <MInput name="target_direct" value={this.state?.milestoneViewData?.attributes?.estimated_savings} disabled />
                        } />

                        <InputRow label="Actual Saving" style={{ width: "50%" }} input={
                            <MInput name="actual_direct" value={this.state?.milestoneViewData?.attributes?.actual_savings} disabled />
                        } />
                    </Flex>


                    <InputRow label={<JobLabel>Type of Investment*</JobLabel>} input={
                        <MSelect name="type_of_investment" value={-1} disabled >
                            <MenuItem disabled value={-1}>{this.state?.milestoneViewData?.attributes?.type_of_investment_name}</MenuItem>
                        </MSelect>
                    } />
                    {this.state?.milestoneViewData?.attributes?.project_milestone_pdfs?.length > 0 ? <Attachments label="Upload Milestone Plan"
                        files={this.state?.milestoneViewData?.attributes?.project_milestone_pdfs.map((j: any) => ({ ...j, }))}
                    /> : ''}


                    <InputRow label="Description*" input={
                        <MCountedTextArea length={500} name="description" value={this.state?.milestoneViewData?.attributes?.description} disabled />
                    } />

                    <InputRow label={<JobLabel>Milestone Status</JobLabel>} input={
                        <MSelect name="status" placeholder="Select" value={-1} disabled>
                            <MenuItem value={-1}>{this.state?.milestoneViewData?.attributes?.milestone_status}</MenuItem>
                        </MSelect>
                    } />
                    {this.state.delete && <UserModal showModal={this.state.delete} handleModal={this.handleModal} onClose={this.handleCloseDelete} onDelete={this.onDeleteView} />}
                </FormModal>
            </ModalWrap>
        </Formik>
    }

    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            <>
                {this.CreateTemplateModal()}
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

CreateRequestToListController.contextType = UserContext






const JobLabel = styled('div')({
    fontSize: '15.35px',
    color: '#404040',
    fontFamily: 'HelveticaNeue'
})



const ModalWrap = styled('div')({
})


// Customizable Area End
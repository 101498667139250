import React from 'react';
import { styled } from '@material-ui/styles'
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';


interface MediaCarouselProps {
	items: {
		id: string | number,
		filename: string,
		url: string,
		type: 'video' | 'image' | 'application',
	}[],
}

export default function MediaCarousel({items}: MediaCarouselProps){
	const settings = {
		
	}
	const refs = React.useRef<(HTMLVideoElement | HTMLImageElement | null)[]>([])
	const onChange= (idx: number) => {
		// const vid = refs.current[idx]
		// if(vid?.tagName==="VIDEO") {		
		// }
		// pause all videos on slide change
		refs.current.forEach(el => {
			if(el?.tagName === "VIDEO")
				(el as HTMLVideoElement).pause()
		})
	}
	return <>
		{/* @ts-ignore */}
		<CarouselStyled showThumbs={false} onChange={onChange} dynamicHeight={true} swipeable={true} emulateTouch={true} showStatus={false}>
			{items?.map((item, idx)=>{
				return (
						<React.Fragment key={item?.url}> 
							{item?.type === "image" && <PostImage ref={el=>refs.current[idx] = el} src={item.url} />}
							{item?.type === "video" && <VideoWrapper>
								<PostVideo ref={el=>refs.current[idx] = el} controls ><source src={item.url} /></PostVideo>	
							</VideoWrapper>}
						</React.Fragment>
					);
				})}
		</CarouselStyled>
	</>
}



const PostImage = styled('img')({
//   width: '100%',
  borderRadius: '15px',
  margin: '20px 0',
  maxHeight: '350px',
  objectFit: 'cover',
})


const PostVideo = styled('video')({
  width: '100%',
  borderRadius: '15px',
  margin: '20px 0px',
  maxHeight: '350px',
})

const VideoWrapper = styled('div')({
	padding: '0 30px',
})

const CarouselStyled = styled(Carousel)({
	'& > div > div': {
		height: 'auto !important',
	},
	'& .carousel.carousel-slider .control-arrow': {
		background: 'rgba(0,0,0,0.2)',
	}
})
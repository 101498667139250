import React from 'react'
import { Divider, Paper, Tabs, Tab, Typography, Button, Box } from '@material-ui/core'
import { styled } from '@material-ui/styles'
import { Link,  } from 'react-router-dom'

import RoundedButton from './RoundedButton.web'
import Search from './Search.web'

const iconAdd = require('./ic_add.png')

const routeMapping = [
  'JobListing2',
  'Events2',
  'CfProductListing11',
  'Services',
  'CfCourseListing5'
]


interface MarkteplaceTopbarProps {
  selectedTab: number;
  onTabChange: any;
  onCreate: () => void;
  filters?: any;
  buttonMinWidth?: any;
  userRole?: any;
  path?: string;
}

export default function MarketplaceTopbar({ selectedTab, onTabChange, onCreate, filters, buttonMinWidth, userRole,path }: MarkteplaceTopbarProps) {
  // const history = useHistory()
  
  const tabLabels = path?.includes('/MyMarketplace') ? [
        'My Jobs', 
        'My Events', 
        'My Products', 
        'My Services', 
        'My Courses',
  ] : [
    'Jobs', 
    'Events', 
    'Products', 
    'Services', 
    'Courses',
]
  const buttonLabels = ['Job', 'Event', 'Product', 'Service', 'Course']
  return <>
    <Paper style={{ marginTop: "10px" }} variant="outlined">
          {/* @ts-ignore */}
          <Tabs variant="scrollable"
                scrollButtons="on"
                allowScrollButtonsMobile 
                value={selectedTab} 
                indicatorColor="primary" 
                textColor="primary" 
                onChange={onTabChange}
          > 
           { tabLabels.map(
                (l, idx) => <Tab key={idx} label={l} component={Link} to={routeMapping[idx]} />
              )
            }
          </Tabs>
    </Paper>
    <TopbarWrapper>
      {filters || ''}
      {(userRole > 2) 
      ? <div style={{flex: 1, minWidth: buttonMinWidth}}>
        <RoundedButtonBase 
          onClick={ onCreate } 
          variant="contained" 
          disableElevation 
          startIcon={<img src={iconAdd} width={30} style={{marginLeft: '-1px', marginTop: '-2px'}} />}
          >
            <span style={{ paddingLeft: '0px', width: '100%', fontSize: '0.8rem', fontWeight: 600 }}>CREATE {buttonLabels[selectedTab]}</span>
        </RoundedButtonBase>
      </div> : ''}
    </TopbarWrapper>
  </>
}


const TopbarWrapper = styled('div')((props: any) => ({
  margin: '10px 0', 
  gap: '10px', 
  display: 'flex',
  alignItems:'center',
  [props.theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    '& > div': {
      width: '100%' // child with 100% width
    }
  }
}))


const RoundedButtonBase = styled(Button)({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: '#009c05',
  color: 'white',
  borderRadius: '20px',
  padding: '7px 12px 5px 12px',
  '&:hover': {
    backgroundColor: '#009c05',
  }
})


import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as yup from 'yup'
import { toast } from 'react-toastify'
import { sendAPIRequest } from '../../../components/src/utils'
import { sendNewQuestionMessage, } from './Messages'
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import { Attachment } from './types';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    // Customizable Area Start
    token: string;
    question_type: any;
    modal: 'transfer' | 'deficient' | null;
    enableBtn:boolean;
    disableBtn:boolean;
    editQuestionData: any;
    erroeMsg: any[];
    responseTypeId:number;
    setError: any;
    isEdit: boolean;
    scorringOptionData: any[]
    uploadFile: any;
    removedAttachments: number[];
    loading: boolean;
    remove_option_ids:number[];
    avaliableParentQuestions: any;
    selectedParent:any;
    isCondtional:number;
    uid:any;
    select_options:any;
    SummationParentDefaultOption:{
        id: number;
        title: string;
        isSelected: boolean;
        value: number;
    }[],
    isChildCreate:boolean;
    // select_option_2:any;

    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class AddSurveyQuestionsController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start

    initUsersPagination: any = undefined;
    addQuestionCallId: string = "";
    getQuestionByIdCallId = "";
    updateQuestionCallId = "";
    removeAttachmentCallId="";
    deleteOptionCallId = "";
    getScoringQuestionByIdCallId = "";
    AvaliableParentQueCallId=""
    generateQuesUniqIdCallId=""
    uniqueQuestionId=''
    hasDueDiligenceDetails=false
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            // Customizable Area Start
            token: '',
            modal: null,
            question_type: 0,
            editQuestionData: {},
            erroeMsg: [],

            enableBtn:false,
            disableBtn:false,
            responseTypeId:-1,
            setError: {},
            isEdit: false,
            scorringOptionData: [],
            uploadFile: [],
            removedAttachments: [],
            loading:false,
            remove_option_ids:[],
            avaliableParentQuestions:[],
            selectedParent:{},
            isCondtional: 0,
            uid:{},
            select_options:{},
            SummationParentDefaultOption:[{
                id: 1,
                title: "Total is less than",
                isSelected: false,
                value: 0
            }, {
                id: 2,
                title: "Total is greater than",
                isSelected: false,
                value: 0
            }],
            isChildCreate:false
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End

    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        const id = this.props.navigation.getParam('id')
        const authToken = await getStorageData("authToken")
        const pathUrl = this.props.navigation.history.location.pathname
        this.getAvaliableParentQuestions()
        this.hasDueDiligenceDetails = pathUrl.includes("/DueDiligenceDetails/");
        
        
        if (authToken == null || authToken == '') {
            this.props.navigation.navigate('Home')
        } else {
            this.setState({token: authToken}, () => {
            const parentQuestion = this.props.navigation.history.location.parentQuestion;
            if(id){
                this.setState({isEdit: true,loading: true})
                
                    const survey_id = Number(this.props.navigation.history.location.state);
                    const questionData = this.props.navigation.history.location.allQuestion?.find((obj : any) => obj.id === id)
                    if(questionData?.attributes?.response_type_id == 4){
                        this.getScoringQuestionByIdCallId = sendAPIRequest(`${configJSON.getScoringSurveyByIdEndpoint.replace(':sid', survey_id)}/survey_question/${id}`, {
                            method: 'GET',
                            headers: {'token': authToken},
                         })
                    }
                    else{
                        this.getQuestionByIdCallId = sendAPIRequest(`${configJSON.getSurveyByIdEndpoint.replace(':sid', survey_id)}/survey_questions/${id}`, {
                            method: 'GET',
                            headers: {'token': authToken},
                         })
                    }
                
            } 
            else if(parentQuestion && Object.keys(parentQuestion).length){
                this.setState({isChildCreate:true,selectedParent:parentQuestion})
            }  
            })
        }
        this.handleEnableBtn()
    }
    // Customizable Area End
    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);

        if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
            let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

            this.showAlert(
                "Change Value",
                "From: " + this.state.txtSavedValue + " To: " + value
            );

            this.setState({ txtSavedValue: value });
        }

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            const responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            const errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (responseJson && responseJson.errors) {
                this.parseApiErrorResponse(responseJson.errors);
                this.parseApiCatchErrorResponse(responseJson.errors);
                if(responseJson.errors[0].token){
                    this.props.navigation.navigate('Home')
                  }
            } else if (responseJson) {
                switch(apiRequestCallId) {
                    case this.addQuestionCallId:
                        toast.success("Question added Successfully");
                        sendNewQuestionMessage(responseJson.data);
                        const pathUrlData = this.generatePathUrlData()
                        this.props.navigation.history.replace({pathname: `${pathUrlData}/${this.props.navigation.history.location.survey_id}`, state:{survey_id: this.props.navigation.history.location.survey_id,changeQuestion:true}});
                        break;
                    case this.getQuestionByIdCallId:
                        const givenQuestionData = responseJson.data.attributes;
                        const updatedQuestionAttachments =  this.updatedAttachments(givenQuestionData);
                        const editQuestionDataObj = {...responseJson.data.attributes, id: responseJson.data.id,uploadFile:updatedQuestionAttachments, selectedParent:responseJson.data.attributes.parent };
                        this.setState({editQuestionData:editQuestionDataObj,selectedParent:responseJson.data.attributes.parent});
                        const current_question_type = this.getQuestionType(responseJson.data.attributes);
                        this.setState({question_type:current_question_type});
                        const currentResponseTypeId = this.getResponseType(responseJson.data.attributes);
                        this.setState({responseTypeId:currentResponseTypeId,loading:false});
                        break;
                    case this.getScoringQuestionByIdCallId:
                        const givenData = responseJson.data.attributes;
                        const updatedAttachments =  this.updatedAttachments(givenData);
                        const editScoringQuestionData = {...responseJson.data.attributes, id: responseJson.data.id,response_type_id:4, response_type:"scoring", uploadFile:updatedAttachments, selectedParent:responseJson.data.attributes.parent};
                        this.setState({editQuestionData:editScoringQuestionData,selectedParent:responseJson.data.attributes.parent});
                        const question_type = this.getQuestionType(responseJson.data.attributes);
                        this.setState({question_type});
                        const responseTypeId = this.getResponseType(responseJson.data.attributes);
                        this.setState({responseTypeId,loading:false});
                        break;
                    case this.updateQuestionCallId:
                        this.handleUpdateQuestionResponse(responseJson)
                        break;
                    case this.deleteOptionCallId:
                        toast.success("Option Deleted Successfully");
                        break;
                    case this.AvaliableParentQueCallId:
                        this.setAvaliableParentQuestions(responseJson)
                        break;
                    default:
                        break;
                }
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
    setAvaliableParentQuestions = (responseJson:any) => {

        let questionUpData = responseJson.questions && responseJson.questions.data;
        const upQuestions = Array.isArray(questionUpData) && questionUpData.length && questionUpData.map((question:any) => {
            const queObj = question.attributes;
            // Check if response_type is null or empty
            if (!queObj.response_type) {
              queObj["response_type"] = "scoring";
              queObj["response_type_id"] = 4;
            }
            return question;
          });
        
        Array.isArray(upQuestions) && this.setState({avaliableParentQuestions: upQuestions});
    }
    getQuestionType = (data:any) => {
      let questionType =  (data && data.question_type == "mandatory")? 0 : 1;
        return questionType
    }

    getResponseType = (data:any) => {
        let responseType =  (data && data.response_type == "subjective")? 0 : 1;
          return responseType
      }

    deleteOptions = (id:any) => {
        const {token} = this.state;
        const question_id = Number(this.props.navigation.history.location.question_id);
        this.deleteOptionCallId = sendAPIRequest(`bx_block_surveys/survey_questions/${question_id}/remove_option/${id}`, {
            method: 'DELETE',
            headers: {token}
        })
    }
    updatedAttachments = (givenData:any) => {
        const host = configJSON.baseURLString
        const updatedAttachments = givenData?.question_attachments?.map((attachment:Attachment) => {
            if (attachment.url) {
              return { ...attachment, url: host + attachment.url };
            }
            return attachment;
          });
          return updatedAttachments
    }

    removeAttachments = (attachmentId:number) => {
        if(attachmentId){
            this.setState(prevState => ({
                removedAttachments: [...prevState.removedAttachments, attachmentId]
            }))
        }
    }

    handleChange = (event: { target: { value: any; }; }) => {
        this.setState({question_type: Number(event.target.value)});
      };
    handleSubmit = async (values: any): Promise<void> => {
        this.setState({ disableBtn: true, loading: true });
    
        const id: string | undefined = this.props.navigation.getParam('id');
        const { token }: { token: string } = this.state;
        const validationFn: Function | undefined = this.getResponseValidationFunction(values.response_type);
        validationFn && validationFn(values)
        this.checkParentExpectedAnswer(values)
        if (!this.isEmpty(this.state.setError)) {
            toast.warn("Please fill the form properly");
            this.setState({ disableBtn: false, loading: false });
            return;
        }
    
        const question_id: number = Number(this.props.navigation.history.location.state);
        
        const body: any = this.buildRequestBody(values, id);
    
        if (!id) {
            await this.handleCreateQuestion(values, body, question_id, token);
        } else {
            this.setState({ disableBtn: false, loading: false });
            await this.handleUpdateQuestion(values, body, question_id, token);
        }
    }
    checkParentExpectedAnswer(values:any){
        let errorMsg: any = {};
        let parentExpectedAnswerData = this.getParenExpectedAnswer(values);
        let parent_expected_answer:any = [];
        
        if (Array.isArray(parentExpectedAnswerData)) {
            parent_expected_answer = [...parent_expected_answer, ...parentExpectedAnswerData];
        } else if (typeof parentExpectedAnswerData === 'object' && parentExpectedAnswerData !== null) {
            parent_expected_answer.push(parentExpectedAnswerData);
        }
        if (((!isNaN(values.parent_id) && Number(values.parent_id) >= 0)) && (!this.state.isEdit) && !(Array.isArray(parent_expected_answer) && parent_expected_answer.length)) {
            errorMsg.parent_expected_answer = "Parent Response is mandatory";
            const currentErrorState = this.state.setError;
            const updatedErrorState = { ...currentErrorState, ...errorMsg };
            this.setState({ setError: updatedErrorState });
        }
    }
    getResponseValidationFunction(responseType: number): Function | undefined {
        const responseHandlers: Record<number, Function> = {
            0: this.subjectiveValidation,
            1: this.multipleChoiceValidation,
            3: this.ratingValidation,
            4: this.scoringValidation,
            2: this.summationValidation,
        };
        return responseHandlers[responseType];
    }
    buildRequestBody(values: any, id: string | undefined): any {
        const selectedParentType = this.state.selectedParent?.attributes?.response_type_id;
        const parent_type = this.getselectedParentType(selectedParentType)
        const parent_expected_answer = this.getParenExpectedAnswer(values)
        const bodyData: any = {
            question: values.question,
            question_type: Number(values.questiontype),
            response_type: values.response_type,
            options_attributes: [],
            unique_question_id:values.unique_question_id
            
        };
        let body = this.state.isEdit ? bodyData :  {...bodyData,question_condition_type: values.question_condition_type,
            parent_id: values.parent_id,
            parent_type,
            parent_expected_answer: parent_expected_answer,
            unique_question_id:values.unique_question_id} 
        switch (values.response_type) {
            case 1:
                body.options_attributes = this.mapOptions(values?.options_attributes, id, this.mapGeneral);
                break;
            case 3:
                body.options_attributes = this.mapOptions(this.buildTemplateOptions(values, id), id, this.mapGeneral);
                break;
            case 4:
                body.options_attributes = this.mapOptions(values?.score_options, id, this.mapGeneral);
                break;
            case 2:
                body.options_attributes = this.mapOptions(values?.summation_options, id, this.mapGeneral);
                break;
        }
    
        return body;
    }
    
    mapOptions(values: any[] | undefined, id: string | undefined, mappingFn: Function): any[] {
        return values?.map((item: any) => {
            const mappedItem: any = mappingFn(item, id);
            return mappedItem;
        }) || [];
    }
    
    mapGeneral(item: any, id: string | undefined): any {
        const mappedItem: any = {
            title: item?.title,
        };
        if (id && item?.id) {
            mappedItem.id = item.id;
        }
        if (item?.answer !== undefined) {
            mappedItem.score = item.answer;
            mappedItem.is_deleted = item?.is_deleted || false;
        }
        return mappedItem;
    }


    buildTemplateOptions(values: any, id: string | undefined): any[] {
        const templateOptions: any[] = [
            values.template_option1,
            values.template_option2,
            values.template_option3,
            values.template_option4,
            values.template_option5,
        ];
    
        const options: any[] = templateOptions.reduce((acc: any[], templateOption: any, index: number) => {
            if (templateOption) {
                if(id){
                    acc.push({
                        title: templateOption,
                        id: id ? this.state?.editQuestionData?.options[index]?.id : 0,
                        })
                    }
                    else{
                        acc.push({title: templateOption})
                    }
            }
            return acc;
        }, []);
    
        return options;
    }
    
    async handleCreateQuestion(values: any, body: any, question_id: number, token: string): Promise<void> {
        if (this.isEmpty(this.state?.setError)) {
            const formData: FormData = this.buildFormData(values, body);
            const endpoint: string = values?.response_type === 4
                ? configJSON.getScoringSurveyByIdEndpoint.replace(':sid', question_id.toString())
                : configJSON.getSurveyByIdEndpoint.replace(':sid', question_id.toString());
            const url: string = `${endpoint}/survey_questions`;
    
            this.addQuestionCallId = sendAPIRequest(url, {
                method: 'POST',
                headers: { token },
                body: formData,
            });
        }
    }
    
    async handleUpdateQuestion(values: any, body: any, question_id: number, token: string): Promise<void> {
        if (this.isEmpty(this.state?.setError)) {
            delete body.response_type;
            const formData: FormData = this.buildFormData(values, body);
            const updateQuestionUrl: string = `${values?.response_type === 4
                ? configJSON.getScoringSurveyByIdEndpoint.replace(':sid', question_id.toString())
                : configJSON.getSurveyByIdEndpoint.replace(':sid', question_id.toString())}/survey_questions/${this.state?.editQuestionData?.id}`;
    
            if (this.state.removedAttachments.length > 0) {
                const removeAttachmentUrl: string = values?.response_type === 4
                    ? configJSON.removeAttachmentScoringQuestion.replace(":sid", question_id.toString()).replace(":qid", this.state?.editQuestionData?.id)
                    : configJSON.removeAttachmentSurveyQuestion.replace(":sid", question_id.toString()).replace(":qid", this.state?.editQuestionData?.id);
    
                const removeAttachmentBody: { ids: string[] | number[] } = { ids: this.state.removedAttachments };
                const removeAttachmentPromise = new Promise<void>((resolve, reject) => {
                    sendAPIRequest(removeAttachmentUrl, {
                        method: 'PUT',
                        headers: { token,'content-type': 'application/json' },
                        body: removeAttachmentBody,
                    });
                    resolve();
                    });
                    await removeAttachmentPromise;
            }
    
            this.updateQuestionCallId =  sendAPIRequest(updateQuestionUrl, {
                method: 'PUT',
                headers: { token },
                body: formData,
            });
        }
    }
    
    buildFormData(values: any, body: any): FormData {
        const formData: FormData = new FormData();
        const data: { [key: string]: any } = { ...body, sequence_no: this.props?.navigation?.history?.location?.sequence_no ?? 1 };
        Object.entries(data).forEach(([key, value]) => {
            if (key === 'options_attributes') {
                this.appendOptionsAttributes(value, 'options_attributes', formData);
            }else if (key === 'parent_expected_answer'){
                formData.append('parent_expected_answer[]',JSON.stringify(value));
            }
            else if (key === 'question_condition_type'){
                formData.append(key, value);
            }
             else {
                formData.append(key, value as string);
            }
        });
        values.attachments.forEach((file: File) => { if(file instanceof File) { formData.append("question_attachments[]", file)}});
        formData.append('remove_option_ids', JSON.stringify(this.state.remove_option_ids))
        return formData;
    }

    appendOptionsAttributes(obj:any, prefix:any,formData:any) {
        for (let key in obj) {
          if (obj.hasOwnProperty(key)) {
            let value = obj[key];
            if (typeof value === 'object') {
              this.appendOptionsAttributes(value, prefix + '[' + key + ']',formData);
            } else {
              formData.append(prefix + '[' + key + ']', value);
            }
          }
        }
      }
    isEmpty = (obj: any) => (Object.keys(obj).length === 0);
    isBlank = (value: string | number| undefined | null): boolean => {
        if (typeof value === "number") {
            value = value.toString();
          }
        return !value || value.trim() === "";
      };
    isNumber = (value: string): boolean => {
        return  /^\d+$/.test(value);
      };
      QuestionValidation = (values: any, errorObj:any): { Question: string; QuestionLength: number; questionError:string | undefined; errorObj:any } => {
        let Question = values.question;
        let QuestionLength = Question ? Question.length : 0;
        let questionError 
        if (this.isBlank(Question)) {
            questionError = "Question is mandatory"
            errorObj.question = "Question is mandatory"
        }
        else if (QuestionLength > 250) {
            questionError = "Question length can not be more than 250 character"
            errorObj.question = "Question length can not be more than 250 characters"
        }
        if (values.question_condition_type === "conditional" && !values.parent_id) {
            errorObj.parent_id = "Parent Question is required";
        }
        if (this.isBlank(values.unique_question_id)) {
            errorObj.unique_question_id = "Questions Unique ID is mandatory"
        }else if (values.unique_question_id.length > 10){
            errorObj.unique_question_id = "Questions Unique ID length can not be more than 10 character"
        }
        return { Question, QuestionLength, questionError ,errorObj };
      };

     

    subjectiveValidation = (values: any) => {
        let errorMsg:any = {};
        if(!this.isEmpty(values)){
            let errorData =  this.QuestionValidation(values,errorMsg)
            errorMsg = errorData.errorObj
            if(this.isBlank(values.questiontype)){
                errorMsg.questiontype = "Question Type is mandatory"
            }
            this.setState({setError : errorMsg})
        }     
    }
   
    
    ratingValidation = (values: any) => {
        let regex = /^[A-Za-z0-9 ]+$/
        let errorMsg:any = {};
        if(!this.isEmpty(values))
        {
        const { Question:ratingQuestion, QuestionLength:ratingQuestionLength } = this.QuestionValidation(values,errorMsg);
        if(this.isBlank(ratingQuestion)){
            errorMsg.question = "Question is mandatory"
        }
        if(ratingQuestionLength > 250){
            errorMsg.question = "Question length can not be more than 250 character"
        }
        if(this.isBlank(values.questiontype)){
            errorMsg.questiontype = "Question Type is mandatory"
        }
        const checkTemplateOption = (
            option: any,
            optionNumber: number,
            maxCharacters: number
            ) => {
                if (this.isBlank(values[option])) {
                    errorMsg[option] = "Field should not be empty";
                } else if (!regex.test(values[option])) {
                    errorMsg[option] = `Option ${optionNumber} must not contain special characters`;
                } else if (values[option].length > maxCharacters) {
                    errorMsg[option] = `Option length cannot be more than ${maxCharacters} characters`;
                }
            };
         
            for (let i = 1; i <= 5; i++) {
                const optionKey = `template_option${i}`;
                checkTemplateOption(optionKey, i, 50);
            }
    }
        this.setState({setError : errorMsg})
    }

    multipleChoiceValidation = (values: any) => {
        let errorMsg:any = {};
        if(!this.isEmpty(values)){
            const { Question:question, QuestionLength:questionLength } = this.QuestionValidation(values,errorMsg);
            if(this.isBlank(question)){
                errorMsg.question = "Question is mandatory"
            }
            if(questionLength > 250){
                errorMsg.question = "Question length can not be more than 250 character"
            }
            if(this.isBlank(values.questiontype)){
                errorMsg.questiontype = "Question Type is mandatory"
            }
            const validateOption = (option: {title: string}, index: number): string | undefined => {
                if (this.isBlank(option.title)) {
                  return `Option ${index + 1} is required`;
                } else if (option.title.length > 75) {
                  return `Option ${index + 1} length cannot be greater than 75 characters`;
                }
                return undefined;
              };
              values.options_attributes.forEach((option:any, index:number) => {
                const error = validateOption(option, index);
                if (error) {
                  errorMsg[`options_attributes${index}`] = error;
                }
              });
        }      
        this.setState({setError : errorMsg})
    } 

    scoringValidation = (values: any) => {
        let errorMsg: any = {};
        if (!this.isEmpty(values)) {
            values.score_options = this.state.scorringOptionData
            const { questionError} = this.QuestionValidation(values,errorMsg);
            if (questionError) {
                errorMsg.question = questionError;
              }
            if (this.isBlank(values.questiontype)) {
                errorMsg.questiontype = "Question Type is mandatory"
            } 
            values.score_options.forEach((option:any, index:number) => {
                if (this.isBlank(option.title)) {
                  errorMsg[`score_title${index}`] = "Option is mandatory";
                } else if (option.title.length > 75) {
                  errorMsg[`score_title${index}`] =
                    "Option length cannot be greater than 75 characters";
                }
                if (this.isBlank(option.answer)) {
                  errorMsg[`score_title${index}`] = "Option score is mandatory";
                } else if (!this.isNumber(option.answer)) {
                  errorMsg[`score_title${index}`] = "Only numbers allowed in score column";
                } else if (Number(option.answer) > 100) {
                  errorMsg[`score_title${index}`] = "Score cannot be greater than 100";
                }
            });
        }
        this.setState({setError : errorMsg})
    }

    summationValidation = (values: any) => {
        let errorMsg:any = {};
        let summationQuestion = values.question
        let summationQuestionLength = summationQuestion ? summationQuestion.length : 0
        if (!this.isEmpty(values)) {
            const { questionError} = this.QuestionValidation(values,errorMsg);
            if (questionError) {
                errorMsg.question = questionError;
              }
            if (summationQuestionLength > 250) {
                errorMsg.question = "Question length can not be more than 250 character"
            }
            if (this.isBlank(values.questiontype)) {
                errorMsg.questiontype = "Question Type is mandatory"
            } 
            values.summation_options.forEach((option:any, index:number) => {
                const optionKey = `summation_title${index}`;
                if (this.isBlank(option.title)) {
                  errorMsg[optionKey] = "Option is mandatory";
                } else if (option.title.length > 75) {
                  errorMsg[optionKey] = "Option length cannot be more than 75 characters";
                }
              });
            
        }
        this.setState({setError : errorMsg})
    }

    hideModal = () => { this.props.navigation.goBack(); removeStorageData("transferData"); }

    scoringQuestionBody = (items: any[]) => {
        this.setState({scorringOptionData:items})
    }

    validationSchema = yup.object().shape({
        question: yup.string().required("Question is required"),
        questiontype: yup.number().required("question type is required"),
        response_type: yup.number().required("Response Type is required"),
        unique_question_id: yup.string().required('Questions Unique ID field is mandatory'),
        parent_id: yup.string().when('question_condition_type', {
            is: 'conditional',
            then: yup.string().required('Parent Question is required'),
            otherwise: yup.string(), // No validation if condition is not met
        }),
    });

    handleEnableBtn=()=>{
        // if("transferData" in localStorage){
            this.setState({enableBtn:true})
        // }
    }

    onSelectInput=(data:any)=>{
        this.setState({responseTypeId:data})
    }

    onParentQuestionSelect=(parentQuestionID:any)=>{
        let selectedParent = this.state.avaliableParentQuestions.find((p:any) => p.id == parentQuestionID)
        this.setState({selectedParent})
        
    }
    handleQuestionConditionType = (value:any,setFieldValue:any)=>{
        if(value == "conditional"){
            this.setState({isCondtional:1})
        }else{
            setFieldValue("parent_id",undefined)
            setFieldValue("parentOptionId","")
            setFieldValue("question_condition_type","non_conditional")
            this.setState({isCondtional:0,selectedParent:{} })
        }

    }
    readFileData = (file:any) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (event) => {
            resolve(event.target?.result);
          };
          reader.onerror = (error) => {
            reject(error);
          };
          reader.readAsBinaryString(file); // or use readAsDataURL for base64 encoding
        });
      };
      getAvaliableParentQuestions = async() => {
        const token = await getStorageData("authToken");
        const survey_id = Number(this.props.navigation.history.location.state);

        this.AvaliableParentQueCallId = sendAPIRequest(`${configJSON.getAvaliableParentQuestions.replace(':survey_id', survey_id.toString())}`, {
          method: 'GET',
          headers: { token }
        })
      }

     
      getselectedParentType = (selectedParentType:any) => {   
          if (selectedParentType === null || selectedParentType === undefined) {
              return "";
          } else {
              const selectedParenttypeData = selectedParentType == "4" ? "BxBlockScoring::ScoringSurveyQuestion" : "BxBlockSurveys::SurveyQuestion";
              return selectedParenttypeData;
          }
      }

      getParenExpectedAnswer = (data:any) => {
            const selectedParentType = this.state.selectedParent?.attributes?.response_type_id 
            if(selectedParentType == "1"){
                let optId = data.parentOptionId
                const selectedParentOption = this.state.selectedParent?.attributes?.options.find((option:any) => (option.id).toString() === optId)
                return selectedParentOption
            }else if(selectedParentType == "4"){
                const flattenedOptions = data?.selectedOptions?.flat();
                const selectedIds = flattenedOptions?.map((id:string) => parseInt(id));
                const filteredOptions = this.state.selectedParent?.attributes?.options?.filter((option:any) => selectedIds?.includes(option.id));
                return filteredOptions
            }else if(selectedParentType == "2"){
            const selectedParentConditions = this.state.SummationParentDefaultOption.filter(option => option.isSelected === true)
            return selectedParentConditions
            }
        return []
      }

    getResponseTypeRender(isEdit: boolean, selectedParent: any, responseTypeId: any) {
        if (isEdit && selectedParent) {
                return selectedParent.response_type;
        } else {
            return responseTypeId;
        }
    }
    
    getOptions(isEdit: boolean, selectedParent: any, optionsData: any) {
        if (isEdit && selectedParent) {
            return selectedParent.options;
        } else {
            return optionsData;
        }
    }
    
    getSelectedOption(isEdit:boolean,editQuestionData:any) {
        return isEdit ? editQuestionData.parent_expected_answer : [];
    }
    
    getParsedSelectedOption(isEdit:boolean, selectedOption:any) {
        return isEdit ? JSON.parse(selectedOption) : {};
    }
    
    getColor(isEdit:boolean) {
        return isEdit ? 'gray' : 'Green';
    }
    getRadioColor(isEdit:boolean, isChild:boolean) {
        return (isEdit || isChild) ? 'gray' : 'Green';
    }

    isItDisabled(isEdit:boolean, isChild:boolean) {
        return (isEdit || isChild) ? true : false;
    }
    getUniqueQuestionId(editQuestionData:any, uniqueQuestionId:string) {
        return editQuestionData?.unique_question_id || uniqueQuestionId;
    }
    determineCheckboxChecked(values:any,opt:any, isEdit:boolean, ParsedSelectedOption:any) {
        let isChecked = false;
        if (!isEdit) {
            let data = values.selectedOptions?.flat()?.map((option:any) => Number(option))
            isChecked = data && data.includes(opt.id);
        } else {
            isChecked = Array.isArray(ParsedSelectedOption) ? ParsedSelectedOption.some((selectedOpt: any) => selectedOpt.id === opt.id) : false;
        }
        return isChecked;
    }

    mergeOptions(isEdit:boolean,defaultOptions:any, editOptions:any) {
        let mergedOptions = []
        if(!isEdit) {
            mergedOptions = defaultOptions
        }else{
            mergedOptions = defaultOptions.map((defaultOpt:any) => {
                const editOpt = editOptions.find((editOpt:any) => editOpt.id === defaultOpt.id);
                return editOpt ? editOpt : defaultOpt;
            });
        }
        return mergedOptions
    }
    getQuestionConditionType(state:any) {
        const isChildCreate = state.isChildCreate;
        const editQuestionData = state.editQuestionData;
        
        return isChildCreate ? 'conditional' : (editQuestionData?.question_condition_type || "non_conditional");
    }
    getParentID(state:any) {
        const isChildCreate = state.isChildCreate;
        const editQuestionData = state.editQuestionData;
        const selectedParent = state.selectedParent;
    
        return isChildCreate ? selectedParent?.id : editQuestionData?.parent_id;
    }
    getParentError(data:any,key:string) {
        if(key == 'unique_question_id'){
            return data.unique_question_id ? data.unique_question_id : ''
        }
        else if(key == 'parent_id') {
        return data.parent_id ? data.parent_id : ""
        }
        else if (key == 'questiontype'){
            return data.questiontype ? data.questiontype : ""
        }
        return ''
    }
    checkSlectedParentAndEdit(state:any){
        return state.isEdit && state.selectedParent
    }
    checkParentQuestionList(parentQuestions : any) {
        return Array.isArray(parentQuestions) && parentQuestions.length
    }
    getIndexLabel(index:number) {
        const firstCharCode = "A".charCodeAt(0);
        const numLetters = 26;
      
        if (index < numLetters) {
          return String.fromCharCode(firstCharCode + index) + ".";
        } else {
          const firstLetterIndex = Math.floor(index / numLetters) - 1;
          const secondLetterIndex = index % numLetters;
          return (
            String.fromCharCode(firstCharCode + firstLetterIndex) +
            String.fromCharCode(firstCharCode + secondLetterIndex) +
            "."
          );
        }
      }
      generatePathUrlData() {
        return this.hasDueDiligenceDetails ? "/DueDiligenceDetails" : "/SurveysDetails";
        }
      handleUpdateQuestionResponse(responseJson:any) {
        if(responseJson.error){
            toast.error(responseJson.error);
        } else {
            toast.success("Question Updated Successfully");
            const pathUrlData = this.generatePathUrlData()
            this.props.navigation.history.replace({pathname: `/${pathUrlData}/${this.props.navigation.history.location.survey_id}`,  state:{survey_id: this.props.navigation.history.location.survey_id,changeQuestion:true}});
        }
      }

    // Customizable Area End
}

// Customizable Area Start
import { runEngine } from '../../../../framework/src/RunEngine'
import MessageEnum, {
  getName
} from '../../../../framework/src/Messages/MessageEnum'
import { Message } from "../../../../framework/src/Message";

// used for sending request data, to Add Request Modal
// for prefilling field of `Add Request`
export function sendAddRequestMessage(data: any) {
  let msg = new Message(getName(MessageEnum.AddRequestMessaage));
  msg.addData(
    getName(MessageEnum.AddRequestData),
    data
  );
  runEngine.sendMessage(msg.id, msg);
  return msg.id;
}

export function sendRequestCreateMessage(request: any) {
  let msg = new Message(getName(MessageEnum.RequestCreateMessage))
  msg.addData(
    getName(MessageEnum.RequestData),
    request
  )
  runEngine.sendMessage(msg.id, msg)
  return msg.id
}

/**
* @depricated a request cannot be updated, but rather a response can be generated for a request
*/
export function sendRequestUpdateMessage(request: any) {
  let msg = new Message( getName(MessageEnum.RequestUpdateMessage) )
  msg.addData(
    getName(MessageEnum.RequestData),
    request
  )
  runEngine.sendMessage(msg.id, msg)
  return msg.id
}

/**
* Response of a request generated
*/
export function sendRequestRespondMessage(requestId: any, response: any, status: any) {
  let msg = new Message( getName(MessageEnum.RequestRespondMessage) )
  msg.addData(
    getName(MessageEnum.RequestData),
    { id: requestId, response, status }
  )
  runEngine.sendMessage(msg.id, msg)
  return msg.id
}

/*
*	execute callback only if message type match
*/
function callBackAddRequestMessage(msg: Message, id: string, callback: (data: any) => any) {
  if (msg.id === id) {
    const addRequestData = msg.getData(getName(MessageEnum.AddRequestData))
    callback(addRequestData);
  }
}


export function parseAddRequestMessageData(msg: Message, callback: (data: any) => any) {
  callBackAddRequestMessage(msg, getName(MessageEnum.AddRequestMessaage), callback);
}



export function parseRequestUpdateMessage(msg: Message, callback: (data: any) => any) {
  if(msg.id !== getName(MessageEnum.RequestUpdateMessage) ) return 
  const request = msg.getData(getName(MessageEnum.RequestData))
  callback(request)
}

export function parseRequestCreateMessage(msg: Message , callback: (data: any)=>any){
  if(msg.id!==getName(MessageEnum.RequestCreateMessage)) return
  const request = msg.getData(getName(MessageEnum.RequestData))
  callback(request)
}

export function parseRequestRespondMessage(msg: Message, callback:(request_id: any, response: any, status?: any)=>void) {
  if(msg.id!==getName(MessageEnum.RequestRespondMessage)) return
  const request = msg.getData(getName(MessageEnum.RequestData))
  callback(request.id, request.response, request.status)
}
// Customizable Area End
// Customizable Area Start
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const DownloadIcon = require('../assets/ic_download.png');
export const ExcelIcon = require('../assets/ic_excel.png');
export const warningIcon = require('../assets/warning.png');
export const iconUpload = require('../assets/ic_upload.png');
export const SuccessLogo = require('../assets/SucessfullLogo.png');
export const pdfIcon = require('../assets/ic_pdf.png');
export const closeIcon = require('../assets/ic_close.png')
export const editImg = require("../assets/edit.png");
export const deleteImg = require("../assets/delete.png");
export const xlsIcon = require('../assets/ic_xlsx_icon.png')
export const videoIcon = require('../assets/ic_video_icon.png')
export const imageIcon = require('../assets/ic_image_icon.png')
export const genericIcon = require('../assets/ic_generic_file.png')
export const csvIcon = require('../assets/ic_csv_icon.jpg')
export const docIcon = require('../assets/ic_doc.png')
export const calenderIcon = require('../assets/ic_calendar.png')
export const locationIcon = require('../assets/ic_location.png')
export const Tooltips = require('../assets/tooltip.png')
export const ProfilePicDummy = require('../assets/person.png')
// Customizable Area End
import React from "react";

// Customizable Area Start
import {
	Box,
	TableContainer,
	Paper,
	Backdrop,
	Button,
	IconButton,
	Grid,
	Typography as TextData,
	Modal,
	Avatar,
	TableBody,
	TableHead,
	Table,
	TableCell as TableColumn,
	TableRow,
} from "@material-ui/core";
import { styled } from '@material-ui/styles';
import { InputRow, MInput } from "../../../components/src/FormModal.web";
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloseIcon from "@material-ui/icons/Close";
import { iconLocation, contributors, iconCorpstage, ic_greenthumb } from './assets';
import { Formik, Form } from 'formik';
import { Link } from 'react-router-dom'
import UserContext from "../../../components/src/UserProvider";
import SeeMore from "../../../components/src/SeeMore.web";
import OverlayLoader from '../../../components/src/Loader.web'
import LeftMenuWeb from "../../../components/src/LeftMenuWeb";
import RightMenuWeb from "../../../components/src/RightMenuWeb";
import TopNavBar from "../../../components/src/TopNavbar";

export const configJSON = require("./config");

// Customizable Area End

import ProjectDetailEndUserController, { Props } from "./ProjectDetailEndUserController.web";
import { CommonActions } from "../../../components/src/PostCard.web";
import LikeAPost from "../../LikeAPost/src/LikeAPost.web";
import { commentCount } from "../../ActivityFeed/src/assets";

export default class ProjectDetailEndUSer extends ProjectDetailEndUserController {
	constructor(props: Props) {
		super(props);
		// Customizable Area Start
		// Customizable Area End
	}

	// Customizable Area Start

	showStatus = (val: any) => {
		if (this.state.projectMilestone.length > 0) {
			if (val?.milestone_status == "Live") {
				return <Button style={{ textTransform: "none", padding: '6px 12px', backgroundColor: '#e3eaf5', color: '#4358b4', borderRadius: '6px', }}>
					{val.milestone_status}
				</Button>
			}
			else if (val?.milestone_status == "Closed") {
				return <Button style={{ textTransform: "none", padding: '6px 12px', backgroundColor: '#ff000045', color: 'red', borderRadius: '6px', }}>
					{val.milestone_status}
				</Button>
			}
			else if (val?.milestone_status == "Inprogress") {
				return <Button style={{ textTransform: "none", padding: '6px 12px', backgroundColor: '#ffff0038', color: '#f09507', borderRadius: '6px', }}>
					{"Inprogress"}
				</Button>
			}
			else if (val?.milestone_status == "On Hold") {
				return <Button style={{ textTransform: "none", padding: '6px 12px', backgroundColor: '#57050578', color: '#570505', borderRadius: '6px', }}>
					{"On Hold"}
				</Button>
			}
		}
	}

	showColorBar = (val: any) => {
		if (this.state.projectMilestone.length > 0) {
			if (val?.milestone_status == "Live") {
				return <div style={{ backgroundColor: '#00ab18', marginTop: '15px', borderRadius: '0px 0px 6px 6px', height: '6px', }}></div>
			}
			else if (val?.milestone_status == "Closed") {
				return <div style={{ backgroundColor: 'red', marginTop: '15px', borderRadius: '0px 0px 6px 6px', height: '6px', }}></div>
			}
			else if (val?.milestone_status == "Inprogress") {
				return <div style={{ backgroundColor: '#f09507', marginTop: '15px', borderRadius: '0px 0px 6px 6px', height: '6px', }}></div>
			}
			else if(val?.milestone_status == "On Hold"){
				return <div style={{ backgroundColor: '#570505', marginTop: '15px', borderRadius: '0px 0px 6px 6px', height: '6px', }}></div>
			}
		}
	}

	showLocations = () => {
		return(
		<>
		{this.state.particularProject.attributes?.locations?.map((value: any) => {
			return (
				<div style={{ flexDirection: 'row', gap: '20px', display: 'inline-flex', }}>
					<div style={{ backgroundColor: '#f0f0f0', padding: '12px', borderRadius: '6px', width: '210px', }}>
						<div style={{ width: '100%', height: '120px', marginBottom: '8px', borderRadius: '6px', overflow: 'hidden', }}>
							<img style={{ width: '100%' }} src={this.state.particularProject.attributes.project_profile_picture}></img>
						</div>
						<TextData style={{ marginBottom: '3px', fontWeight: "bold" }}>{this.state.particularProject.attributes?.project_name}</TextData>
						<div style={{ display: "flex" }}>
							<img style={{ width: "16px", height: '18px', verticalAlign: 'middle', }} src={iconLocation} alt="locationIcon" />
							<TextData style={{ fontSize: '14px', }}>{value}</TextData>
						</div>
					</div>
				</div>
			)
		})}
		</>
		)
	}


	sdgFactorsData = () => {
		if(this.state.showSDG){
			return <ShowSdgFactorsPopup onClose={this.handleCloseSDGFactor} openModal={this.state.showSDG} data={this.state.particularProject.attributes.sdg_factors}/>
		}
	}
	
	showPreference = (value:any) => {
		if(value!==null && value!=="null"){
			if(value=="E"){
				return "Environment"
			}
			else if(value=="S"){
				return "Social"
			}
			else if(value=="G"){
				return "Governance"
			}
			else {
				return ""
			}
		}
		else {
			return ""
		}
	}

	showCollaborators = () => {
		if(this.state.showCollaborators){
			return <CollaboratorList data={this.state.particularProject} openModal={this.state.showCollaborators} handleCollabratorClose={this.handleCloseCollaborators} />
		}
	}
	showCompanyName = () => {
		const companyName = this.state.particularProject.attributes?.company_name 
		const companyId = this.state.particularProject.attributes?.corporate_id
		return (
			<div style={{ display: "flex", color: 'rgb(79, 74, 74)', fontSize: '16px', marginTop: "6px", alignItems: 'center' }}>
				<TextData style={{  marginRight: "7px", fontWeight: "bold" }}>Company Name:</TextData>
				<TextData data-testid="companyLink" style={{ fontSize: '16px', textDecorationLine: "underline", cursor: "pointer", color: 'rgb(119, 113, 113)' }} onClick={() => this.navigationFun({id:companyId, props:this.props, screenName:"CorporateProfileEndUser"})}>
					{companyName}
				</TextData>
			</div>
			
		);
	}

	showDescription = () => {
		if(this.state.particularProject.attributes?.description.length > 455){
			return (
			<>
			{this.state.particularProject.attributes?.description?.substring(0, 455)} <Link style={{ fontWeight: "bold" }} to={`${this.props.navigation.history.location.pathname}/projects/${this.state.particularProject.id}`}>...See more</Link>
			</>)
		}
		else{
			return this.state.particularProject.attributes?.description
		}
	}
	// Customizable Area End

	render() {
		return (
			// Customizable Area Start
			<>
				<MainBoxGrid style={{backgroundColor:"#edebeb", display:"flex",flexDirection:"column", paddingBottom:"20px",overflowX:"hidden"}}>
						<Box>
							<TopNavBar 
								navigation={this.props.navigation} id={""} 
							/>
						</Box>

						<OuterBoxGrid container spacing={3}>
							<LeftBoxGrid item sm={12} xs={12}  md={3} lg={3}>
								<Box ><LeftMenuWeb 
								id={""} 
								navigation={this.props.navigation}  /></Box>
							</LeftBoxGrid>
							<MiddleBoxGrid item xs={12} sm={12} md={6} lg={6} >
							<>
							<div>
								<div style={{ display: 'flex', gap: '20px' }}>
									<div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
										<ProjectTabContainer>
											<ProjectTabCard>
												<ProjectTabCardHeader>
													<div style={{ display: 'flex', gap: '15px' }}>
														<Link to={`/user/${this.state.particularProject.attributes?.created_by?.data?.attributes?.id}`}>
														<div style={{ width: '55px', height: '55px', background: 'gray', borderRadius: '50%' }}>
															<img style={{ width: '55px', height: '55px', borderRadius: '50%' }} src={this.state.particularProject.attributes?.created_by?.data?.attributes?.profile_pic} />
														</div>
														</Link>
														<div style={{ display: 'flex', flexDirection: 'column', }}>
															<div style={{ color: '#000', fontSize: '22px', marginBottom: '7px', fontWeight: "bold" }}>
																{this.state.particularProject.attributes?.project_name}
															</div>
															<div style={{ color: 'rgb(119, 113, 113)', fontSize: '16px', display: "flex" }}>
																<div>
																	<TextData>{this.state.particularProject.attributes?.location}</TextData>
																</div>
																<div style={{ marginLeft: "240px", display: "flex" }}>
																	<TextData style={{ fontWeight: "bold",color:'#4a4747' }}>Preference: </TextData>
																	<TextData style={{ marginLeft: "6px", color: "rgb(119, 113, 113)" }}>{this.showPreference(this.state.particularProject.attributes?.preference)}</TextData>
																</div>
															</div>
															{this.showCompanyName()}
														</div>
													</div>
													<div style={{ color: 'rgb(119, 113, 113)', fontSize: '14px', gap: '10px', display: 'flex', alignItems: 'center' }}>
														<img style={{ width: '25px' }} src={iconCorpstage} alt="icon" />
														<TextData style={{paddingTop: '2px'}}>{this.state.particularProject.attributes?.completed_project_milestones}/{this.state.particularProject.attributes?.total_project_milestones}</TextData>
													</div>
												</ProjectTabCardHeader>
												<p style={{ color: 'rgb(119, 113, 113)', fontSize: '16px', marginBottom: '20px',wordWrap:"break-word"  }}>
													{this.showDescription()}
												</p>
												{this.state.particularProject.attributes?.project_profile_picture?.length>0 && <div style={{ borderRadius: '6px', minHeight: '100px', width: '100%', marginBottom: '20px' }}>
													<img style={{ width: '100%', height: '41vh', borderRadius: '8px' }} src={this.state.particularProject.attributes?.project_profile_picture} />
												</div>}
												<div style={{ fontSize: "14px", display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
													<div style={{ display: 'flex', gap: '10px', alignItems: "baseline" }}><span style={{ color: '#000', fontSize: '14px' }}>Collaborators :</span> <div style={{ color: '#989898', fontSize: '12px', cursor:'pointer',display:'flex' }} id='viewCollaborators' onClick={() => this.state.particularProject.attributes?.project_collaborators?.length>0 && this.handleShowCollaborators()}>{this.state.particularProject.attributes?.project_collaborators?.slice(0, 3)?.map((val: any) => val.data.attributes?.image ? <img style={{ width: "25px", height: '25px', objectFit: 'cover', border: 'solid 1px #196519', marginRight: '-10px', borderRadius: "50%", verticalAlign: "middle",  backgroundColor: '#fff' }} src={val.data.attributes?.image} />  : <Avatar style={{ width: "25px", height: '25px', border: 'solid 1px #196519', marginRight: '-10px', borderRadius: "50%", backgroundColor:'a9a9a970',color:'#000', fontSize:'14px', verticalAlign: "middle" , }}>{val?.data?.attributes?.first_name?.charAt(0)?.toUpperCase()}</Avatar>)}</div>
														<div>
															<div>{this.state.particularProject.attributes?.project_collaborators?.length > 3 && <div id='showCollaborators' onClick={() => this.handleShowCollaborators()} style={{ cursor: "pointer", marginLeft: '5px', fontSize: "14px", color: "rgb(119, 113, 113)" }}>{` +${this.state.particularProject.attributes?.project_collaborators?.length - 3} more`}</div>}</div>
														</div>
													</div>
													<div style={{ display: 'flex', gap: '10px', alignItems: "baseline" ,marginRight:'10px'}}><span style={{ color: '#000', fontSize: '14px' }}>SDG Factors :</span>
														<div id='viewSDGFactors' onClick={()=> this.state.particularProject.attributes?.sdg_factors !== "" && this.handleOpenSDGFactor()} style={{cursor:"pointer", display: "flex", color: "rgb(119, 113, 113)", fontSize: '14px'}}>
															{this.state.particularProject.attributes?.sdg_factors !== "" && this.state.particularProject.attributes?.sdg_factors?.slice(0,5)?.map((val: any) => {
																return (
																	<Avatar style={{ width: "25px", height: '25px', objectFit: 'cover', border: 'solid 1px #196519', marginRight: '-10px', borderRadius: "50%", verticalAlign: "middle" }} src={val?.data?.attributes?.image} />
																)
															})}
															{this.state.particularProject.attributes?.sdg_factors!=="" && this.state.particularProject.attributes?.sdg_factors?.length>5 && <TextData style={{marginLeft:"5px", cursor:"pointer"}} onClick={()=>this.handleOpenSDGFactor()}>{`+${this.state.particularProject.attributes?.sdg_factors?.length-5} more`}</TextData>}
														</div>
													</div>
												</div>
												<div style={{ display: 'flex', marginTop: '20px', justifyContent: 'space-between', marginBottom: '10px' }}>
													<div style={{ display: 'flex', gap: '10px', }}>
														<div style={{ color: 'rgb(119, 113, 113)', fontSize: '14px', gap: '6px', display: 'flex', alignItems: 'center' }}>
															<img style={{ width: '18px' }} src={ic_greenthumb} /> <div style={{ color: '#000', fontSize: "14px", paddingTop: '2px' }}>{this.state.particularProject.attributes?.project_likes_count}</div>
															<img style={{ width: '18px' }} src={commentCount} /> <div style={{ color: '#000', fontSize: "14px", paddingTop: '2px' }}>{this.state.particularProject.attributes?.project_comments_count}</div>
														</div>
														<div id='viewTopContributors' onClick={() => this.handleTopContributorsModalOpen()} style={{ color: '#000', fontSize: "14px", gap: '6px', display: 'flex', alignItems: 'center', cursor: "pointer" }}><img src={contributors} style={{ width: "19px" }} /> Top Contributors</div>
													</div>
													<div><Button data-test-id="Request-To-Collaborate" style={{ color: 'rgb(0, 161, 0)', border: 'solid 1px rgb(0, 161, 0)', borderRadius: '25px', fontWeight: 600, fontSize: '10px', padding: '0px 10px 0px 10px', textTransform:'none' }} onClick={() => this.props.navigation.history.push(`${this.props.navigation.history.location.pathname}/RequestToCollaborate/${this.state.particularProject.id}`)}>Request To Collaborate</Button></div>
												</div>
											</ProjectTabCard>
											
											{Boolean(this.state.particularProject.id) ?  <CommonActions
												onStarClick={() => { }}
												onShareClick={this.handleShareCourse(this.state.particularProject)}
												isFavourite={false}
												onCommentClick={this.goToEvent(this.state.particularProject.id)}
												isHideStar={true}
												isProject={true}
												onSurveyClick={this.handleSurveyOpen(this.state.particularProject.id)}
												likeButton={
													<LikeAPost
														navigation={this.props.navigation}
														id=""
														likeable_type="BxBlockProjectManagement::ProjectManagement"
														isLiked={Boolean(this.state.particularProject.attributes?.project_likes?.some((project: any) => Number(project.like_by_id) === Number(this.state.userID)))}
														post_id={this.state.particularProject.id}
														like_id={this.state.particularProject.attributes?.project_likes.find((project: any) => Number(project.like_by_id) == (this.state.userID))?.id}
														onLikeChange={this.onLikeChange}
														onLikeIdUpdate={this.onLikeIdUpdate}
													/>}
											/>: <></>}
											{this.sdgFactorsData()}
											{this.showCollaborators()}
										</ProjectTabContainer>
									</div>
								</div>
								<div>
									<ProjectTabContainer style={{ padding: '20px', width: '97%', }}>
										<TextData style={{ fontWeight: 600, fontSize: '20px', }}>Location</TextData>
										<div style={{ width: '100%', overflowX: 'auto' }}>
											{this.showLocations()}
										</div>
									</ProjectTabContainer>
								</div>
								<div>
									<ProjectTabContainer style={{ padding: '20px', width: '97%', }}>
										<TextData style={{ fontWeight: 600, fontSize: '20px', marginBottom: '20px', }}>Project Scorecard</TextData>
										{this.state.projectMilestone?.length > 0 && this.state.projectMilestone?.map((val: any,index:any) => {
											return (
												<>
													<ProjectScoreCard>
														<div style={{ display: "flex", padding: '0 20px', boxSizing: 'border-box', justifyContent: "space-between" }}>
															<div>
																<TextData style={{ width: '100%', fontSize: '16px' }}>Milestone Name</TextData>
																<TextData style={{ width: '100%', color: '#909090', fontSize: '14px' }}>{this.state.projectMilestone?.length > 0 && val?.milestone_name}</TextData>
															</div>
															<div>
																<TextData style={{ width: '100%', fontSize: '16px' }}>Milestone ID</TextData>
																<TextData style={{ width: '100%', color: '#909090', fontSize: '14px' }}>{this.state.projectMilestone?.length > 0 && val?.milestone_unique_id}</TextData>
															</div>
															<div>
																<TextData style={{ width: '100%', fontSize: '16px' }}>Status</TextData>
																<div style={{ width: '100%', }}>
																	{this.showStatus(val)}
																</div>
															</div>
														</div>
														<div style={{ width: '100%', marginTop: '10px', boxSizing: 'border-box', padding: '0px 20px' }}>
															<TextData style={{ width: '100%', fontSize: '16px' }}>Milestone Description</TextData>
															<TextData style={{ width: '100%', color: '#909090', fontSize: '14px', wordWrap: "break-word" }}>
															{this.state.projectMilestone?.length>0 && <SeeMore limit={170} text={val?.description || ''} />}
															</TextData>
														</div>
														{this.showColorBar(val)}
													</ProjectScoreCard>
													{ this.state.projectMilestone?.length-1!==index &&
													<div style={{width: '10px', height: '40px', backgroundColor: 'rgba(0, 0, 0, 0.4)', marginLeft: '60px', marginTop: '-7px',}}></div>
													}
													</>
											)
										})}
									</ProjectTabContainer>
								</div>
							</div>
							<TopContibutors data={this.state.particularProject.attributes?.top_contributors} handleTopContributorsModalClose={this.handleTopContributorsModalClose} openModal={this.state.openContributros} />
							</>
							</MiddleBoxGrid>
							<RightGrid  item xs={12} sm={12} md={3} lg={3}>
							<Box >
								<RightMenuWeb navigation={this.props.navigation} id={""}/>
								</Box>
							</RightGrid>
						</OuterBoxGrid>
					</MainBoxGrid>
				<OverlayLoader loading={this.state.loading}/>
			</>
			// Customizable Area End
		);
	}
}

// Customizable Area Start
ProjectDetailEndUserController.contextType = UserContext

interface SdgFactorProps {
	data:any,
	openModal:boolean,
	onClose:any,
}

export const ShowSdgFactorsPopup = ({onClose,data,openModal}: SdgFactorProps) => {
	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				onClose={() => {
					onClose()
				}}
				closeAfterTransition
				BackdropComponent={Backdrop}
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",

				}}
				open={openModal}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Box
					style={{
						flexDirection: 'column',
						background: '#fff',
						borderRadius: 8,
						width: '650px',
						position: "relative",
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						margin: '0 10px',
						maxHeight:'550px',
					}} className="schedule_modal">
					<Grid container spacing={0}>
						<ModalTitle data-test-id='ModalTitleId' item xs={12} >
							<TextData style={{ paddingTop: '14px', fontWeight: 'bold' }}>SDG Factors</TextData>
							<CloseIconButton id='closeSDGFactorModalId' onClick={() => {onClose()}}>
								<CloseIcon style={{}} />
							</CloseIconButton>
						</ModalTitle>

						<Grid item xs={12} 
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							flexDirection: 'column',
							padding: '18px 36px',
						}}>
								<RightFormWrapperDiv>
									<Grid item xs={12}>
										<TableContainer component={Paper}>
											<Table>
												<TableHead>
													<TableColumn style={{ color: 'black', fontWeight: 'bold', }}>S.No</TableColumn>
													<TableColumn style={{ fontWeight: 'bold', color: 'black' }}>Goal</TableColumn>
													<TableColumn style={{ fontWeight: 'bold', color: 'black' }}>Picture</TableColumn>
													</TableHead>
												<TableBody>
													{data?.map((value: any, index: any) => {
														return (
															<TableRow>
																<TableColumn>{index + 1}</TableColumn>
																<TableColumn>{value?.data?.attributes?.goal}</TableColumn>
																<TableColumn>
																	<Avatar style={{width:"25px", height:"25px"}} src={value?.data?.attributes?.image}/>
																</TableColumn>
															</TableRow>
														)
													})}
												</TableBody>
											</Table>
										</TableContainer>
									</Grid>
								</RightFormWrapperDiv>
						</Grid>
					</Grid>
				</Box>
			</Modal>
		</div >
	)
}

interface TopContributorsProps {
	openModal: boolean;
	handleTopContributorsModalClose: any;
	data: any
}

export const TopContibutors = ({ data, openModal, handleTopContributorsModalClose }: TopContributorsProps) => {
	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",

				}}
				open={openModal}
				onClose={() => {
					handleTopContributorsModalClose()
				}}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Box
					style={{
						borderRadius: 8,
						width: '650px',
						position: "relative",
						margin: '0 10px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						flexDirection: 'column',
						background: '#fff',
					}} className="schedule_modal">
					<Grid container spacing={0}>

						{/* Modal Title */}
						<ModalTitle data-test-id="modalTitle" item xs={12} >
							<TextData style={{ paddingTop: '14px', fontWeight: 'bold' }}>Top Contributors</TextData>
							{/* Close Btn */}
							<CloseIconButton id='closeTopContributorModalId' onClick={() => {
								handleTopContributorsModalClose()
							}}>
								<CloseIcon style={{}} />
							</CloseIconButton>
							{/* Close Btn END */}
						</ModalTitle>
						{/* Modal Title END */}

						<Grid item xs={12} 
						style={{
							alignItems: 'center',
							flexDirection: 'column',
							display: 'flex',
							justifyContent: 'center',
							padding: '18px 36px'
						}}>
								<RightFormWrapperDiv>
									<Grid item xs={12}>
										<TableContainer component={Paper}>
											<Table>
												<TableHead>
													<TableColumn style={{ fontWeight: 'bold', color: 'black' }}>S.No</TableColumn>
													<TableColumn style={{ fontWeight: 'bold', color: 'black' }}>First Name</TableColumn>
													<TableColumn style={{ fontWeight: 'bold', color: 'black' }}>Last Name</TableColumn>
													<TableColumn style={{ fontWeight: 'bold', color: 'black' }}>Preference</TableColumn>
													<TableColumn style={{ fontWeight: 'bold', color: 'black' }}>Company Name</TableColumn>
												</TableHead>
												<TableBody>
													{data?.map((value: any, index: any) => {
														return (
															<TableRow>
																<TableColumn>{index + 1}</TableColumn>
																<TableColumn>{value?.first_name}</TableColumn>
																<TableColumn>{value?.last_name}</TableColumn>
																<TableColumn>{value?.preference}</TableColumn>
																<TableColumn>{value?.company_name}</TableColumn>
															</TableRow>
														)
													})}
												</TableBody>
											</Table>
										</TableContainer>
									</Grid>
								</RightFormWrapperDiv>
						</Grid>
					</Grid>
				</Box>
			</Modal>
		</div >
	)
}


interface CollaboratorListProps {
	openModal: boolean;
	handleCollabratorClose: any;
	data: any;
}

export const CollaboratorList = ({ data, openModal, handleCollabratorClose }: CollaboratorListProps) => {
	return (
		<Modal
			onClose={() => {
				handleCollabratorClose()
			}}
			open={openModal}
			aria-labelledby="transition-modal-title"
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
			aria-describedby="transition-modal-description"
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
			closeAfterTransition
		>
			<ModalWrapper
				 className="schedule_modal">
				<Grid container spacing={0}>

					{/* Modal Title */}
					<ModalTitle data-test-id='collaboratorTitileBlock' item xs={12} >
						<TextData 
						style={{ paddingTop: '14px',
						 fontWeight: 'bold' 
						 }}>
							Collaborators
						</TextData>
						{/* Close Btn */}
						<CloseIconButton data-test-id='closeCOllaborator' onClick={() => {handleCollabratorClose()}}>
							<CloseIcon style={{}} />
						</CloseIconButton>
						{/* Close Btn END */}
					</ModalTitle>
					{/* Modal Title END */}

					<Grid item xs={12} 
					style={{
						padding: '18px 36px',
						display: 'flex',
						maxHeight: "550px",
						justifyContent: 'center',
						alignItems: 'center',
						flexDirection: 'column',
						overflow:'scroll'
					}}>
							<RightFormWrapperDiv>
								<Grid item xs={12}>
									<div>
										<Table 
										style={{
											 minWidth: 650
											 }} 
										aria-label="simple table"
										>
											<TableHead>
												<TableRow>
													<TableColumn>S.No</TableColumn>
													<TableColumn>Name</TableColumn>
													<TableColumn>Profile</TableColumn>
													<TableColumn>Location</TableColumn>
												</TableRow>
											</TableHead>
											<TableBody>
												{data?.attributes?.project_collaborators?.map((val: any, index: any) => {
													const {data} = val
													return (
														<TableRow>
															<TableColumn>{index + 1}</TableColumn>
															<TableColumn>{data?.attributes?.full_name}</TableColumn>
															<TableColumn>{<Avatar style={{ width: 34, height: 34, margin: "auto" }} src={data?.attributes?.image} />}</TableColumn>
															<TableColumn>{data?.attributes?.location}</TableColumn>
														</TableRow>
													)
												})}
											</TableBody>
										</Table>
									</div>
								</Grid>
							</RightFormWrapperDiv>
					</Grid>
				</Grid>
			</ModalWrapper>
		</Modal>
	)
}

const ProjectTabContainer = styled('div')({
	width: '100%',
	marginTop: '30px',
	borderRadius: '6px',
	background: '#fff',
})

const ProjectTabCard = styled('div')({
	padding: '30px 30px 6px 30px',
	borderBottom: 'solid 1px rgb(227, 227, 227)',
})

const ProjectTabCardHeader = styled('div')({
	display: 'flex',
	marginBottom: '20px',
	alignItems: 'flex-start',
	justifyContent: 'space-between',
	gap: '20px',
})

const RightFormWrapper = styled(Form)({
	width: '100%',
	display: 'flex',
	flexDirection: 'column',
	gap: '25px',
	overflowX: 'hidden',
	paddingRight: '10px',
})

const LeftBoxGrid = styled(Grid)({
	maxWidth: '300px',
	'@media screen and (max-width: 985px)': {
        maxWidth: '200px',
      },
	  '@media screen and (max-width: 768px)': {
        display: 'none',
        visibility: 'hidden',
    },
})

const MiddleBoxGrid = styled(Grid)({
	width: 'calc(100vw - 600px)',
	maxWidth: 'unset',
	flexBasis: 'unset',
	justifyContent: 'center',
	'@media screen and (max-width: 985px)': {
        width: 'calc(100vw - 500px)',
      },
	'@media screen and (max-width: 768px)': {
        width: '100%'
      }
})

const RightGrid = styled(Grid)({
	maxWidth: '300px',
	'@media screen and (max-width: 985px)': {
        maxWidth: '250px',
      },
	  '@media screen and (max-width: 768px)': {
        maxWidth: 'auto',
		width: '100%'
    },
})

const DialogCancelBtn = styled(Button)({
	color: '#FFF',
	background: '#D0D0D0',
	padding: '10px 18px 8px 18px',
	margin: '5px',
	'&:hover': {
		background: '#999',
		opacity: 0.9
	},
	'@media screen and (max-width:400px)': {
		width: '-moz-available',
	}
})

const ShareNowBtn = styled(Button)({
	color: '#FFF',
	background: '#019B07',
	padding: '10px 18px 8px 18px',
	margin: '5px',
	'&:hover': {
		background: '#999',
		opacity: 0.9
	},
	'@media screen and (max-width:400px)': {
		width: '-moz-available',
	}
})

const ModalWrapper = styled(Box)({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	flexDirection: 'column',
	background: '#fff',
	borderRadius: 8,
	width: '670px',
	position: "relative",
	margin: '0 10px',
})

const MainBoxGrid = styled(Box)({
	backgroundColor: "#edebeb",
	display: "flex",
	flexWrap: 'wrap',
	flexDirection: "column",
	paddingBottom: "20px",
	overflowX: "hidden",
})


const OuterBoxGrid = styled(Grid)({
	display: 'flex',
	flexWrap: 'wrap',
	justifyContent: 'space-between',
})

const CloseIconButton = styled(IconButton)({
	background: '#676767',
	color: '#fcfcfc',
	width: '32px',
	height: '32px',
	'&:hover': {
		background: '#999',
		opacity: 0.9
	}
})

const CloseButton = styled(Button)({
	margin: '5px',color: '#FFF',background: '#D0D0D0',padding: '10px 18px 8px 18px',
	'&:hover': {
		background: '#999',
		opacity: 0.9
	},
	'@media screen and (max-width:400px)': {
		width: '-moz-available',
	}
})

const ModalTitle = styled(Grid)({
	borderBottom: '0.2px solid #cbc1c1',
	padding: '13px 25px 13px 31px',
	display: 'flex',
	justifyContent: 'space-between',
	alignItem: 'center',
	flexDirection: 'row',
	width: '100%',
})

const RightFormWrapperDiv = styled('div')({
	width: '100%',
	display: 'flex',
	flexDirection: 'column',
	gap: '25px',
	overflowX: 'hidden',
	paddingRight: '10px',
	maxHeight: '375px'
})

const ProjectScoreCard = styled('div')({
	width: '100%',
	border: "1px solid #e6e6e6",
	padding: '20px 0px 0px 0px',
	borderRadius: '6px',
	boxSizing: 'border-box',
	position: 'relative',
})

// Customizable Area End
import React, {useState} from 'react'
import { styled } from '@material-ui/styles'
import { MenuItem, Divider, Typography, InputAdornment,
  ListSubheader,
  ListItem,
  ListItemAvatar,
  ListItemText, 
  FormControl, 
  TextField, Checkbox} from '@material-ui/core'
import { useField } from 'formik'
import { useDebounce } from './utils'
import FormModal, {
  MSelect,
   } from './FormModal.web'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { SearchIcon } from './Icons.web'
const icLogoHex = require('./ic_logohex.png')

const DiscountCard = (p: {coins: number, discount?: number}) => {
  return <DiscountWrapper>
    <Typography style={{ fontWeight: 'bold', fontSize: '17px'}}>{p.discount || ''}% discount for </Typography>
    <LogoImg src={icLogoHex} width='25px' />
    <Typography style={{ color: '#1da509', fontWeight: 'bold', fontSize: '17px'}}> {p.coins} coins</Typography>
  </DiscountWrapper>
}


const DiscountWrapper = styled('div')({
  background: '#f0f8e1',
  borderRadius: '12px',
  padding: '10px 15px',
  display: 'flex',
  justifyContent: 'center',
})

const LogoImg = styled('img')({
  width: '25px',
  margin: '0 10px'
})

export const FilterSelect = (props: any) => { 
	const [field, meta, helpers] = useField(props.name)
	const [fItems, setFItems] = useState<any[]>(props.displayItems || props.items||[])
	const [search, setSearch] = React.useState('')
	const debouncedSearch = useDebounce(search, 300)
  const onSearchChange = (e: any) => {
    setSearch(e.target.value)
  }
	  React.useEffect(() => {
	  	const newItems = props.items
	    		.filter(
	    			(item: any) => (item.label || item.name || '').toLowerCase().indexOf(
	    					debouncedSearch.toLowerCase()
	    				) > -1
	    		)
	    console.log({newItems})
	    setFItems(newItems)
	  }, [debouncedSearch, setFItems])
  
  // edge case fix, when items are loaded async. with API, and intial value is []
  React.useEffect(() => setFItems(props.displayItems || props.items), [props.items, props.displayItems])

	const renderValue = (selected: any) => {
    console.log({selected, component: "FilterSelect"})
		if(selected.length === 0)
			return <Typography color="textSecondary">Select</Typography>
    console.log({selected, component: "FilterSelect"})
		return selected.map((s: any)=>props.items.find((i:any)=>i?.id===s)).map((i:any)=>i?.label || i?.name).join(', ')
	}
  const {items, ...otherProps} = props
	// open menu below the input field
	const MenuProps = {
	  anchorOrigin: {
	    vertical: "bottom",
	    horizontal: "left"
	  },
	  transformOrigin: {
	    vertical: "top",
	    horizontal: "left"
	  },
	  getContentAnchorEl: null
	}
	return <MSelect multiple displayEmpty MenuProps={MenuProps} renderValue={renderValue} placeholder="Select" inputProps={{placeholder: "Select"}} {...otherProps}>
				<ListSubheader style={{ backgroundColor: 'white' }}>
                        <TextField
                          
                          onChange={onSearchChange}
                          size="small"
                          autoFocus
                          placeholder="Search"
                          fullWidth
                          style={{borderBottom:"1px solid #edebeb", marginTop:"10px", }}
                          onClick={e => e.stopPropagation()}
                          InputProps={{
                              startAdornment: (
                              <InputAdornment position="start">
                                  <SearchIcon width="16px" style={{marginBottom: '5px'}} />
                              </InputAdornment>
                              ),
                              disableUnderline:true,
                          }}
                          // onChange={(e) =>  helpers.setValue({searchText:e.target.value})}
                          onKeyDown={(e) => {
                              if (e.key !== "Escape") {
                              // Prevents autoselecting item while typing (default Select behaviour)
                              e.stopPropagation();
                              }
                          }}
                        />
                        { props.loading && (
                              <Typography style={{paddingLeft: '15px'}}>
                                Loading ....
                              </Typography>)
                        }
                        {props.name === "sub_category_ids" && !props.loading && props.displayItems.length === 0 && (
                              <Typography style={{paddingLeft: '15px'}}>
                                Select a category first
                              </Typography>)}
                        {props.name === "category_ids" && !props.loading && props.items.length === 0 && (
                              <Typography style={{paddingLeft: '15px'}}>
                                Select a preference first
                              </Typography>)}
                    </ListSubheader>
                    
		{fItems?.map?.((item)=>(
                      <MenuItem style={{
                        height:"50px",
                        display:"flex",
                        padding:"20px 20px",
                        flexDirection: 'row-reverse',
                      }} key={item.id} value={item.id}>
	                      <Checkbox 
	                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
	                        checkedIcon={<CheckBoxIcon fontSize="small" style={{color:"#1ea60a"}} />}
	                        checked={field.value?.indexOf?.(item.id) > -1} 
	                      />
	                      <ListItemText>
	                            {item.label || item.name}
	                      </ListItemText>
	                      </MenuItem>))}
	  </MSelect>
}

export default DiscountCard
import React from "react";
// Customizable Area Start
import {
    InputAdornment,
    MenuItem,
    ListItemText,
    Avatar,
    Typography,
    CircularProgress,
    Checkbox,
    TextField,
    Modal,
    Fade,
    Box,
    Backdrop
} from '@material-ui/core'
import { styled } from '@material-ui/styles'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Field, Formik, useField } from 'formik'
import FormModal, { InputRow, MInput, MSelect, MCountedTextArea, MDatePicker } from '../../../components/src/FormModal.web'
import UserContext from "../../../components/src/UserProvider";
import Flex from '../../../components/src/Flex.web';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SearchSelect from "../../../components/src/SearchSelect.web";
import { DownloadIcon, ExcelIcon, warningIcon } from "./assets";
import { CloseCircleIcon } from "../../../components/src/Icons.web";
//@ts-ignore
import Switch from "../../../components/src/Switch.web";

import moment from "moment";
import CreateCreateDueDiligenceTemplateController,{ Props } from "./CreateDueDiligenceTemplateController";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" style={{ color: "#1ea60a" }} />;
// Customizable Area End
export default class CreateDueDiligenceTemplate extends CreateCreateDueDiligenceTemplateController {
    selectedItem: any;
    getuserDetails: any;
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    getInitialValues2(){
        let isEditPage = !!this.props.navigation.getParam('id')
        let getCopyPath = this.props.navigation.history.location.pathname.includes('CopyDueDilligence')
        let initialValuesData: any = {
        inactive_date: this.state.inactive_date || null,
        start_date: (isEditPage && !getCopyPath) ? this.state?.start_date : this.state.survey.start_date || null,
        end_date: (isEditPage && !getCopyPath) ? this.state.end_date : this.state.survey.end_date || null,
        survey_time: this.state.survey.survey_time,
        reminder_duration: this.state.survey.reminder_duration || '',
        reminder_frequency: this.state.survey.reminder_frequency,
        }
        return initialValuesData
    }
    getInitialValues(){
        let isEditPage = !!this.props.navigation.getParam('id')
        let getCopyPath = this.props.navigation.history.location.pathname.includes('CopyDueDilligence')
        let initialValuesData: any = {
            template_id: this.state.template_id || '',
            reminder_type: this.state.reminder_type || 0,
            template_name: this.state.survey.template_name || '',
            survey_title: this.state.survey.survey_title || '',
            description: this.state.survey.description || '',
            requested_by: this.state.requested_by || [Number(this.context.id)] || [],
            tagged_communities: this.state?.survey?.tagged_communities?.data?.map((item: any) => item?.attributes?.id) || this.state.survey.tagged_communities || [],
            tagged_projects: this.state.tagged_projects || [],
            tagged_users: this.state.tagged_users || [],
            status: this.state.survey.status,
           
        }

        return initialValuesData
    }
    handleTemplateName(){
        return <InputRow label="Template Name*" input={
            <MInput name="template_name" placeholder="" disabled={this.state.survey.status === "inactive" || this.state.survey.status === "live" || this.state?.survey?.mandatory} />
        } />
    }
    handleDisplayName(){
        return <InputRow label="Display Name*" input={
            <MInput name="survey_title" disabled={this.state.survey.status === "inactive" || this.state.survey.status === "live"} placeholder="" />
        } />
    }
    handleInactiveDate(values:any){
       return <> {!this.state?.survey?.mandatory  && (<InactiveFlex gap="5px" justify="space-between">
                            <div>
                                <Field
                                    render={({ field }: any) => <FormControlLabel {...field}
                                        control={<Checkbox icon={icon} checkedIcon={checkedIcon} />}
                                        label="Inactive"
                                        name="status"
                                        onChange={(e: any) => this.handleChange(e, values)}
                                        value={values.status}
                                        checked={this.state.status}
                                        disabled={(this.state.status === '' || values.status === 'inactive') ? true : false}
                                    />}
                                    variant="outlined"
                                    margin="dense"
                                />
                               <Modal
									open={this.state.openErrorModal}
									onClose={this.handleErrorModal}
									closeAfterTransition
									BackdropComponent={Backdrop}
									BackdropProps={{
									    timeout: 500,
									}}
								>
									<Fade in={this.state.openErrorModal}>
										<Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
											<Box style={{ backgroundColor: '#fff', position: 'relative', padding: '30px 30px', borderRadius: '8px', textAlign: 'center', maxWidth: '500px', margin: '0 auto' }}>
												<Box onClick={this.handleErrorModal} style={{ position: 'absolute', right: '15px', top: '15px', color: '#fff', width: '20px', height: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '8px' }}>
													<CloseCircleIcon />
												</Box>
												<Typography variant="h6" component="h2">
													<img src={warningIcon} alt='waning' style={{ width: "50px", marginBottom: "24px" }} />
												</Typography>
												<Typography >You will not be able to make it active again.</Typography>
												<Box style={{ display: 'flex', marginTop: '20px', alignItems: 'center', justifyContent: 'center', gap: '20px' }}>
													<button onClick={this.handleErrorModal} style={{ backgroundColor: 'green', color: '#fff', borderRadius: '5px', padding: '5px 20px', border: 'none' }}>OK</button>	
												</Box>
											</Box>
										</Box>
									</Fade>
								</Modal>
                            </div>
                            <Flex gap={10} align="center" style={{ whiteSpace: 'nowrap' }}><b>Inactive Date</b>:
                                <Flex align="center" justify="center" gap="5px">
                                    <MDatePicker name="inactive_date" autoOk value={this.state.status ? this.state.survey.inactive_date || new Date() : null} formatDate={(date: any) => moment(new Date()).format('MM-DD-YYYY')} InputProps={{ placeholder: "Enter Date" }} disablePast disabled={this.state.status === '' || values.status} />
                                </Flex>
                            </Flex>
                        </InactiveFlex>)} </>
    }

    CreateTemplateModal() {
        let isEditPage = !!this.props.navigation.getParam('id')
        const initialValuesData1 = this.getInitialValues()
        const initialValuesData2 = this.getInitialValues2()
        const initialValuesData = {...initialValuesData1, ...initialValuesData2}
        
        const copyUrl = this.props.navigation.history.location.pathname.includes('CopyDueDilligence') && isEditPage

        return <Formik enableReinitialize initialValues={initialValuesData} onSubmit={this.handleCreateTemplateSubmit} validationSchema={this.validationSchema}
        >
            {({ values, errors }: any) => (
                <div>
                    <FormModal modalStyle={{ maxWidth: '700px' }} isDirty={isEditPage ? !!initialValuesData : false} headerTitle={copyUrl ? <b>COPY TEMPLATE</b> : this.handleTemplateTitle(isEditPage)} submitLabel={copyUrl ? "create" : this.handleCreateButton(isEditPage)} onClose={this.hideModal} >
                        <InputRow label="Template ID" input={
                            <MInput name="template_id" value={this.state.template_id} disabled placeholder="" />
                        } />
                       { this.handleTemplateName()}
                        {this.handleDisplayName()}
                        <InputRow label="Description*" input={
                            <MCountedTextArea length={1000} name="description" placeholder="" disabled={this.state.survey.status === "inactive"} />
                        } />
                        <StartWrapper>
                            <StartDateN className="opop" >
                                {isEditPage ?
                                    <InputRow data-test-id="start-date" label={<Flex justify="space-between" style={{flexWrap:'wrap'}}><span>Start Date*</span><span style={{fontSize:'0.7rem'}}>Note: (Dates will be considered in EST timezone)</span></Flex>} input={
                                        <Field   render={({ field }: any) =>
                                            <MDatePicker {...field} name="start_date" value={this.state.start_date} onChange={this.startDateChange} disablePast disabled={this.state.survey.status === "inactive" || this.state?.survey?.mandatory || this.state.survey.status === "live"}
                                                InputProps={{ placeholder: "Enter Date" }} maxDate={values?.end_date}
                                            />} />
                                    } /> : <InputRow data-test-id="start-date" label={<Flex justify="space-between" style={{flexWrap:'wrap'}}><span>Start Date*</span><span style={{fontSize:'0.7rem'}}>Note: (Dates will be considered in EST timezone)</span></Flex>} input={
                                        <Field data-test-id="fieldfirst" render={({ field }: any) =>
                                            <MDatePicker {...field} name="start_date" value={this.state.start_date} onChange={this.startDateChange} disablePast disabled={this.state.survey.status === "inactive" || this.state.survey.status === "live"}
                                                InputProps={{ placeholder: "Enter Date" }} minDate={values?.start_date} {...this.state.validEndDate}
                                            />} />
                                    }
                                    />
                                }
                            </StartDateN>
                            <StartDateN className="end-date-wrapper" >
                                <InputRow data-test-id="end-date" label={<Flex justify="space-between" style={{flexWrap:'wrap'}}><span>End Date*</span><span style={{fontSize:'0.7rem'}}>Note: (Dates will be considered in EST timezone)</span></Flex>} input={<Field data-test-id="endDateField" render={({ field }: any) =>
                                    <MDatePicker {...field} name="end_date" disablePast value={this.state.end_date} onChange={this.endDateChange}
                                        InputProps={{ placeholder: "Enter Date" }} disabled={this.state.survey.status === "inactive" || this.state?.survey?.mandatory }
                                        minDate={values.start_date || null}
                                    />} />}
                                />
                            </StartDateN>
                        </StartWrapper>

                        <StartWrapperApprox>
                            <StartDate className="approx-time-wrapper" >
                                <InputRow data-test-id="approx-time" label="Approximate Time to Complete The Survey" input={<Field render={({ field }: any) => <TextFieldRounded {...field}
                                            style={{
                                                 marginTop: '7px'
                                            }}
                                            value={this.state.survey_time}
                                            onChange={this.handleSurveyTimeChange}
                                            name="survey_time"
                                            variant="outlined"
                                            placeholder="0"
                                            disabled={this.state.survey.status === "inactive" || this.state.survey.status === "live"}
                                            // @ts-ignore 
                                            InputProps={{ endAdornment: (<InputAdornment position="start"> Minutes </InputAdornment>)}}
                                        />}
                                        margin="dense"
                                        variant="outlined"
                                    />}
                                />
                            </StartDate>
                            <StartDate className="reminder-wrapper" >
                                <InputRow 
                                    data-test-id="reminder-frequency" 
                                    label={<Flex gap={15}>
                                    <Flex gap={10}>Reminder Frequency
                                        <Flex align="center" justify="center" gap="5px">
                                            <RemainderFreq name="reminder_frequency" disabled={this.state.survey.status === "inactive"} />
                                        </Flex>
                                    </Flex>
                                </Flex>} input={
                                    <Reminder>
                                        <InputRow data-test-id="reminder-duration" label="" input={ <Field render={({ field }: any) =>
                                                <MInput {...field} 
                                                name="reminder_duration" 
                                                value={this.state.reminder_duration} 
                                                onChange={this.handleDurationChange} 
                                                disabled={this.state.survey.status === "inactive" || this.state?.survey?.mandatory || !values?.reminder_frequency} placeholder="0" />
                                            }
                                            />
                                        } />
                                        <Field render={({ field }: any) => <MSelect {...field} style={{ width: '38%' }} name="reminder_type" value={values?.reminder_type} placeholder="Select" disabled={this.state.survey.status === "inactive" || this.state?.survey?.mandatory || !values?.reminder_frequency} defaultValue={0} >
                                                <MenuItem value={0}>Hour</MenuItem>
                                                <MenuItem value={1}>Day</MenuItem>
                                            </MSelect>} />
                                    </Reminder>
                                    } />
                            </StartDate>
                        </StartWrapperApprox>
                        <InputRow 
                        data-test-id="requestedBy" 
                        label="Requested by" 
                        input={<SearchSelect
                            data-test-id="searchselect"
                            multiple={false}
                            name="requested_by"
                            pagination={this.state.requestorPagination}
                            hasMore={!!this.state.requestorPagination?.next_page}
                            nextPage={this.paginateRequestors}
                            loading={this.state.requestorSearchCallId !== ""}
                            onSearch={this.handleRequestorSearch}
                            items={this.state.requestorOptions}
                            allItems={this.state.requestorOptions}
                            selectProps={{ onClose: this.handleRequestorClose, disabled: this.state?.survey?.status === "inactive" || this.state?.survey?.status === "live" || this.state?.survey?.mandatory }}
                            SelectItem={SelectItemUserWithoutCheckbox}
                            getOptionValue={(user: any) => `${user.first_name} ${user.last_name}`}
                            isChip={true}
                            isChipWithName
                        />
                        }
                        />
                        {this.handleInactiveDate(values)}

                        {!this.state?.survey?.mandatory &&(<InputRow data-test-id="taggedCommunities" label="Tagged Communities" input={<SearchSelect
                            data-test-id="taggedCommunitiesSearchSelect"
                            pagination={this.state.taggedComPagination}
                            hasMore={!!this.state.taggedComPagination?.next_page}
                            nextPage={this.paginateTaggedCom}
                            loading={this.state.taggedComSearchCallId !== ""}
                            onSearch={this.handleTaggedCommunitySearch}
                            allItems={this.state.taggedComOptions}
                            selectProps={{ onClose: this.handleTaggedComClose, disabled: this.state?.survey?.status === "inactive" }}
                            items={this.state.taggedComOptions}
                            name="tagged_communities"
                            SelectItem={SelectTaggedCom}
                            getOptionValue={(user: any) => `${user.name}`}
                        
                        />
                        }
                        />)}
                        <InputRow data-test-id="taggedProjects" label="Tagged Projects" input={<SearchSelect
                            data-test-id="taggedProjectsSearchSelect"
                            pagination={this.state.taggedProjectPagination}
                            hasMore={!!this.state.taggedProjectPagination?.next_page}
                            nextPage={this.paginateTaggedProject}
                            loading={this.state.taggedProjectSearchCallId !== ""}
                            onSearch={this.handleTaggedProjectsSearch}
                            allItems={this.state.taggedProjectOptions}
                            selectProps={{ onClose: this.handleTaggedProjectClose, disabled: this.state?.survey?.status === "inactive" }}
                            items={this.state.taggedProjectOptions}
                            name="tagged_projects"
                            SelectItem={SelectTaggedProject}
                            getOptionValue={(user: any) => `${user?.project_name}`}
                        />
                        }
                        />
                        <ImportIdsInject name="tagged_users" importIds={this.state.importIds} />
                        <InputRow data-test-id="taggedUsers" label={<FlexR><div>Tagged to specific users</div><ImportBtn>
                            <ImportCSVButton onImport={this.importCsv} loading={this.state.importLoading} />
                            <DownloadLink href="/user emails.csv"><DownloadSampleButton /></DownloadLink>
                        </ImportBtn></FlexR>} input={<SearchSelect
                            data-test-id="taggedUsersSearchSelect"
                            pagination={this.state.moderatorPagination}
                            hasMore={!!this.state.moderatorPagination?.next_page}
                            nextPage={this.paginateModerators}
                            loading={this.state.moderatorSearchCallId !== ""}
                            onSearch={this.handleModeratorSearch}
                            allItems={this.state.moderatorOptions}
                            selectProps={{ onClose: this.handleModeratorClose, disabled: this.state?.survey?.status === "inactive"}}
                            items={this.state.moderatorOptions}
                            name="tagged_users"
                            SelectItem={SelectItemUser}
                            getOptionValue={(user: any) => `${user.first_name} ${user.last_name}`}
                            isChip={true}
                            taggedUser={this.state?.survey?.mandatory ? this.state.tagged_users_obj : false}
                        
                        />
                    }
                    />
                    </FormModal>
                </div>
            )}
        </Formik>
    }

    // Customizable Area End
    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                {this.CreateTemplateModal()}
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

CreateCreateDueDiligenceTemplateController.contextType = UserContext

const FlexR = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media screen and (max-width: 500px)': {
        flexDirection: 'column-reverse',
        alignItems: 'start',
    },
})
export const StartWrapper = styled('div')({
    display: "flex",
    gap: "15px",
    width: "100%",
    justifyContent: "space-between",
    '@media screen and (max-width: 480px)': {
        display: "flex",
        flexWrap: "wrap",
    }
})

export const StartWrapperApprox = styled('div')({
    display: "flex",
    gap: "15px",
    width: "100%",
    justifyContent: "space-between",
    '@media screen and (max-width: 768px)': {
        flexDirection: 'column',
        gap: '10px'
    }
})

export const StartDate = styled('div')({
    flex: 1,
    '@media screen and (max-width: 480px)': {
        flex: 'none',
        width: "100%",
    }
})

export const StartDateN = styled('div')({
    flex: 1,
    '& div > div > p': {
        display: 'none'
    },
    '@media screen and (max-width: 480px)': {
        flex: 'none',
        width: "100%",
    }
})

const FlexMQ = styled(Flex)({
    '@media screen and (max-width:700px)': {
        flexWrap: 'wrap-reverse',
    }
})

const InactiveFlex = styled(Flex)({
    '@media screen and (max-width:700px)': {
        flexDirection: 'column',
    }
})

const RemainderFreq = (props: any) => {
    const [field] = useField("reminder_frequency")
    const handleChange = (e: any) => {
        // helpers.setValue(e.target.checked)
    }

    return <>
        <Switch toggleDisabled={field.value} checked={field.value} onChange={handleChange} name="reminder_frequency" />
    </>
}

const ImportCSVButton = (props: any) => {
    const ref = React.useRef<HTMLInputElement | null>(null)
    const onClick = () => ref?.current && ref.current?.click?.()
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => props.onImport && props.onImport(e.target.files)
    return <>
        <ImportButtonBase loading={props.loading} downloadIcon onClick={onClick} {...props} />
        <input id="csvID" className="userFile" onChange={handleChange} name="csv_file" type="file" accept=".csv" style={{ display: 'none' }} ref={ref} />
    </>
}

export const ImportButtonBase = (props: any) => {
    const icon = props.downloadIcon ? DownloadIcon : ExcelIcon
    const clickHandler = props.onClick || (() => { });
    const label = !props.downloadIcon ? "Download Sample" : "Import from CSV"
    return <ImportButton onClick={clickHandler}>
        {!props.loading && <img src={icon} width="25px" />}{!props.loading && label}
        {props.loading && <CircularProgress size={18} />}{props.loading && "Loading..."}
    </ImportButton>
}

const ImportButton = styled('div')({
    cursor: 'pointer',
    backgroundColor: '#ececec',
    color: '#7A7A7A',
    // border: '1px solid #aaa',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    padding: '5px 12px',
    borderRadius: '6px',
    fontSize: '13px',
})

const ImportBtn = styled('div')({
    gap: '5px',
    display: 'flex',
    '@media screen and (max-width:480px)': {
        width: '100%',
        justifyContent: 'center',
        marginBottom: '15px',
        flexDirection: 'column'
    },
})

const DownloadLink = styled('a')({
    textDecoration: 'none'
})

const DownloadSampleButton = () => {
    return <ImportButtonBase />
}

export const SelectItemUserWithoutCheckbox = ({ item, value }: { item: any, value: any[] }) => {
    return <div style={{ width: '100%', display: 'flex', flexDirection: 'row-reverse', alignItems: 'center' }}
    >
        
        <Typography component={'div'} style={{ flex: 3, overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {item.email}
        </Typography>
        <ListItemText style={{ flex: 2 }} user-id={item.id} disableTypography primary={
            <div style={{ display: "flex", alignItems: "center" }}>
                <Avatar alt="Remy Sharp" src={item.profile_pic} style={{ width: "30px", height: "30px", marginRight: "10px" }} />
                <Typography style={{ color: "#333232", fontWeight: "bold", fontSize: 12, width: '35ch', overflow: 'hidden', textOverflow: 'ellipsis' }}
                    user-id={item.id}>
                    {item.first_name} {item.last_name}
                </Typography>
            </div>
        } />
    </div>
}

export const SelectItemUser = ({ item, value }: { item: any, value: any[] }) => {
    console.log(value, "value==")
    return <div style={{ width: '100%', display: 'flex', flexDirection: 'row-reverse', alignItems: 'center' }}
    >
        {Array.isArray(value) && <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" style={{ color: "#1ea60a" }} />}
            checked={value?.findIndex((x) => Number(x) === item?.id) > -1}
        />}
        <Typography component={'div'} style={{ flex: 3, overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {item.email}
        </Typography>
        <ListItemText style={{ flex: 2 }} user-id={item.id} disableTypography primary={
            <div style={{ display: "flex", alignItems: "center" }}>
                <Avatar alt="Remy Sharp" src={item.profile_pic} style={{ width: "30px", height: "30px", marginRight: "10px" }} />
                <Typography style={{ color: "#333232", fontWeight: "bold", fontSize: 12, width: '35ch', overflow: 'hidden', textOverflow: 'ellipsis' }}
                    user-id={item.id}>
                    {item.first_name} {item.last_name}
                </Typography>
            </div>
        } />
    </div>
}

export const SelectTaggedCom = ({ item, value }: { item: any, value: any[] }) => {
    return <div style={{ width: '100%', display: 'flex', flexDirection: 'row-reverse', alignItems: 'center' }}
    >
        {Array.isArray(value) && <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" style={{ color: "#1ea60a" }} />}
            checked={value?.findIndex(x => x === item?.id) > -1}
        />}
        <Typography component={'div'} style={{ flex: 3, overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {item.name}
        </Typography>
    </div>
}
export const SelectTaggedProject = ({ item, value }: { item: any, value: any[] }) => {
    return <div style={{ width: '100%', display: 'flex', flexDirection: 'row-reverse', alignItems: 'center' }}
    >
        {Array.isArray(value) && <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" style={{ color: "#1ea60a" }} />}
            checked={value?.findIndex(x => x === item?.id) > -1}
        />}
        <Typography component={'div'} style={{ flex: 3, overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {item.project_name}
        </Typography>
    </div>
}


const TC = styled('div')({
    fontSize: '1.5rem',
    fontFamily: 'Helvetica',
    fontWeight: 700,
    lineHeight: '35px',
    textAlign: 'initial',
})

const Typo = styled(Typography)({
    fontSize: '1rem',
    lineHeight: '3.5',
    color: 'grey'
})

export const Reminder = styled('div')({
    display: 'flex',
    alignItems: 'start',
    '& > div': {
        gap: '0px',
    },
    '& > div >p': {
        marginBottom: '0px',
    }
});

const TextFieldRounded = styled(TextField)({
    width: '100%',
    background: '#FFFFFF',
    borderRadius: '7px',
    '& > .MuiOutlinedInput-root': {
        borderRadius: '7px',
    },
    '& > div > input': {
        paddingTop: '14px',
        paddingBottom: '14px'
    }
})

const ErrorText = styled('div')({
    fontSize: '14px',
    color: 'red'
})

const ImportIdsInject = (props: any) => {
    const [field, meta, helpers] = useField(props)
    React.useEffect(() => {
        const users = Array.from(new Set([...(field.value || []), ...props.importIds]))
        helpers.setValue(users||[])
    }, [props.importIds])
    return <></>
  }


// Customizable Area End
import React from "react";

// Customizable Area Start
import {
  Grid,
  Divider,
  Typography,
  Modal,
  Backdrop,
  Menu,
  MenuItem,
  Box, 
  Card,
  CardHeader,
  CardContent,
  CardActions,
  IconButton,
  Avatar,
  Button,
  TextField,
  FormControl, OutlinedInput, InputAdornment
} from '@material-ui/core'
import { styled } from '@material-ui/styles'
import { Formik } from 'formik'
import RoundedButton from '../../../components/src/RoundedButton.web'
import FormModal, { 
  InputRow, 
  FRating,
  MInput, 
  MCountedTextArea, 
  MFileDrop, 
  MTimePicker, 
  MDatePicker,
  MSelect, } from '../../../components/src/FormModal.web'
import CountedTextArea from '../../../components/src/CountedTextArea.web'
import DashboardLayout from '../../../components/src/DashboardLayout.web'
import FileDrop from '../../../components/src/FileDrop.web'
import SeeMore from '../../../components/src/SeeMore.web'
import { Link } from 'react-router-dom';
import Rating from "../../../components/src/Rating.web";
import LikeAPost from '../../LikeAPost/src/LikeAPost.web'
import PlusMorePopup from '../../../components/src/PlusMorePopup.web'
import MediaCarousel from '../../../components/src/MediaCarousel.web'
import UserContext from '../../../components/src/UserProvider'
import Attachments from '../../../components/src/Attachments.web'
import DeleteModal from '../../../components/src/DeleteModal.web'
import Picker from 'emoji-picker-react';
import ReportModal from '../../../components/src/ReportModal.web'
import Flex from '../../../components/src/Flex.web'
import PostCard, { CommonActions, Image } from '../../../components/src/PostCard.web'
import DiscountCard, { FilterSelect } from '../../../components/src/DiscountCard.web'
import { iconGreenThumb, OyoAvatar, SendIcon, EmogiIcon, LikedIcon, CloseIcon, iconCorp, iconCalendar, } from './assets'
import Moment from 'moment'
import RoomIcon from '@material-ui/icons/Room'
//import ReportModal from '../../../components/src/ReportModal.web'
import { calculateDelta } from "../../../framework/src/Utilities";
// Customizable Area End

import CourseDetailController, {
  Props,
  configJSON
} from "./serviceDetailsController";

export default class CourseDetails extends CourseDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  RateServicesModal = () => {
    if(this.state.modal !== 'rateService') return <></>
    return <Formik initialValues={{}} onSubmit={this.handleRateServices}>

    <FormModal modalStyle={{ height: '360px' }} headerTitle="Rate this Service" submitLabel="submit review" onClose={this.hideModal}>
    <Flex py="25px" gap="10px" col align="center">

          <Typography style={{ wordBreak: 'break-all' }} variant="h5">{this.state.selectedService?.service_name}</Typography>
          <Typography>Service ID: {this.state.selectedService?.service_unique_id}</Typography>
          <Typography color="textSecondary" variant="h5">Rate your service!</Typography>
          <FRating value={this.state.modalRating} onChange={this.setModalRating} />
        </Flex>
      </FormModal></Formik>

  }
  // Customizable Area End

  render() {
    console.log("rate= ",this.state.modal);
    const rate = this.RateServicesModal();
    const { navigation } = this.props
    let { serviceData, serviceCommentData } = this.state
    return (
      // Customizable Area Start
      <>
      {this.state.modal == "rateService" ? rate :
      <Wrapper>
      <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
      }}
      open={this.state.openModal}
      //onClose={}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
          timeout: 500,
      }}
  >
    
      <Box
          style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              background: '#fff',
              borderRadius: 8,
              width: '700px',
              minWidth: '300px',
              position: "relative",
              margin: '20px 40px',
          }} className="schedule_modal">
            <CloseButton onClick={this.onCloseModal}>
              <img src={CloseIcon} width="26px" />
            </CloseButton>
          <Grid container spacing={0}>
              <ModelBody item xs={12}>
              <RoundedCard style={{ padding: '0', margin: '0', maxHeight: 'calc(100vh - 50px)', overflow: 'scroll' }} key={serviceData?.id} variant="outlined">
              <CardHeader
                style={{ padding: '25px 20px 15px 20px' }}
                avatar={<Link to={`user/${serviceData?.created_by?.id}`}><Avatar style={{ width: 55.26, height: 51.87 }} src={serviceData?.created_by?.profile_pic || OyoAvatar} onClick={() => navigation.navigate(`user/${serviceData?.created_by?.id}`)} /></Link>}
                title={<span style={{ fontWeight: 600, marginBottom: '4px', fontSize: "1.021rem", maxWidth: '30ch', overflow: 'hidden', textOverflow: 'ellipsis', display: 'block', textTransform: 'capitalize' }}><Link to={`user/${serviceData?.created_by?.id}`}>{serviceData.created_by?.name}</Link></span>}
                subheader={<Grid style={{ gap: '10px' }} container alignItems="center">
                  <span style={{ textTransform: 'capitalize', fontSize: '0.75rem' }}>{serviceData.created_by?.location}</span>
                  <Divider orientation="vertical" flexItem />
                  <span style={{ fontSize: '0.75rem' }}>{serviceData.created_at?.replaceAll('about', '')}</span>
                </Grid>}
            />

              <Divider />
              <CardContent style={{ padding: '16px 30px' }}>
              <FlexSpaceBetween style={{ padding: '0', alignItems: 'start', paddingTop: '12px' }}>
                  <div style={{ flex: 1 }}>
                    <CourseContent>
                      Category :
                    </CourseContent>
                    <MR5>{serviceData?.service_categories ? serviceData?.service_categories[0] : ''}</MR5>
                    {serviceData?.categories?.length > 1 && <PlusMorePopup heading="Category" listItems={serviceData?.categories}>
                      +{serviceData?.categories?.length - 1}
                    </PlusMorePopup>}
                  </div>
                  <div style={{ flex: 1 }}>
                      <CourseContent>
                        Sub Category :
                      </CourseContent>
                      <MR5>{serviceData?.service_subcategories ? serviceData?.service_subcategories[0] : ''}</MR5>
                      {serviceData.service_subcategories?.length > 1 && <PlusMorePopup heading="Sub Category" listItems={serviceData.service_subcategories}>
                        +{serviceData.service_subcategories?.length - 1}
                      </PlusMorePopup>}
                    </div>
                    <div style={{ flex: 1 }}>
                      <CourseContent>
                        Skills :
                      </CourseContent>
                      <MR5>{serviceData?.skills}</MR5>
                    </div>
                </FlexSpaceBetween>
                <FlexSpaceBetween style={{ padding: '0', alignItems: 'start', marginTop: '15px' }}>
                <div style={{ flex: 1 }}>
                      <CourseContent>
                        Experience :
                      </CourseContent>
                      <MR5>{serviceData.service_experience}</MR5>
                    </div>
                    <div style={{ flex: 2 }}>
                      <CourseContent>
                        Service ID :
                      </CourseContent>
                      <MR5>{serviceData.service_unique_id}</MR5>
                    </div>
                </FlexSpaceBetween>
              </CardContent>
                  <Divider />
                  <CardContent>
                  <Flex col py="10px" gap="20px" style={{ padding: '0px 15px 15px' }}>
                    <Flex col >
                      <InnerContent gap="20px" align="center" justify="space-between">
                        <Typography style={{ color: '#2A353F', fontSize: '20px', fontWeight: 'bold', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                          {serviceData?.service_name?.length > 80 ? `${serviceData?.service_name?.substring(0, 80)}...` : serviceData?.service_name}
                        </Typography>
                        <div style={{ transform: 'scale(0.7)' }}>
                          <Rating disabled={true} total={5} value={serviceData?.average_rating} />
                        </div>
                      </InnerContent>
                      <PlContent>
                            <div style={{display: 'flex'}}>
                              <PriceContent>
                                Price Range :
                              </PriceContent>
                              <PR5>{`$${serviceData.price_min_usd} (₹${serviceData.price_min_inr}) to $${serviceData.price_max_usd} (₹${serviceData.price_max_inr})`}</PR5>
                            </div>
                            <div style={{display: 'flex', alignItems: 'end'}}>
                              <span style={{color: '#646464',  marginRight: '10px'}}><RoomIcon /></span>
                              <span style={{ color:'#2A353F', opacity: 0.34, fontSize:"17px", }}>
                                {serviceData.location}
                              </span>
                            </div>
                      </PlContent>
                    </Flex>
                  </Flex>
               </CardContent>
                  <Divider />
                  <CardContent>
                  <Flex col py="10px" gap="20px" style={{ padding: '0px 15px 15px' }}>
                    {
                      serviceData.description && <Flex col style={{ color: 'rgba(0,0,0,0.32)' }}>
                        <SeeMore limit={100} text={serviceData.description} />
                      </Flex>
                    }
                    {serviceData.images && <MediaCarousel items={serviceData.images} />}
                    {Number(serviceData.coin_discount)>0 && <DiscountCard coins={Number(serviceData.equivalent_coins)} discount={Number(serviceData.coin_discount)} /> }
                    <Flex align="center" justify="space-between">
                      <div style={{ lineHeight: '30px', display: 'flex', gap: '5px', fontSize: '0.875rem', color: '#212529', paddingRight:'5px' }}>
                        <img src={iconGreenThumb} width="30" />
                        <span>{serviceData.total_likes}</span>
                      </div>
                      <Flex style={{ gap: '10px' }}>
                        {
                          serviceData.is_rated ?
                          <RatedService area-aria-disabled data-id={serviceData.id}>Rate this Service</RatedService>
                          :
                          <RateService onClick={this.handleServicesRating} data-id={serviceData.id}>Rate this Service</RateService>
                        }
                        
                        <ActionRoundedButton data-id={serviceData.id} onClick={this.handleEnquireClick}>Enquire</ActionRoundedButton>
                      </Flex>
                    </Flex>
                  </Flex>
                </CardContent>
            <CardContent>
            <CommonActions 
              onStarClick={this.handleFavoriteClick} 
              onShareClick={this.handleShareCourse(serviceData)}
              isFavourite={serviceData?.is_favourite}
              isHideComment={true}
              likeButton={
                <LikeAPost
                    navigation={this.props.navigation}
                    id=""
                    likeable_type="BxBlockContentManagement::MarketplaceService"
                    isLiked={serviceData?.is_liked}
                    post_id={String(serviceData?.id)}
                    like_id={serviceData?.like_id ? serviceData?.like_id[0]?.id : ''}
                    onLikeChange={this.onLikeChange}
                    onLikeIdUpdate={this.onLikeIdUpdate}
                />} 
            />
            </CardContent>
                  
                  <DiscussSection>
                      <DiscussHeader>Discussion</DiscussHeader>
                      <div style={{ marginBottom: '40px'}}>
                      <CommentUserAvtar>
                          <Link to={`/user/${this.context?.id}`}><Avatar style={{ width: 35.26, height: 31.87 }} src={this.context?.profile_pic || OyoAvatar}/></Link>
                        </CommentUserAvtar>
                            <div style={{  width: 'calc(100% - 50px)', display: 'inline-block', boxSizing: 'border-box' }}>
                            
                              <OutlinedInput
                                id="outlined-adornment-weight"
                                value={ this.state.userComment }
                                onChange={this.handleServiceCommentChange}
                                placeholder="Comment your views"
                                endAdornment={<InputAdornment position="end"><BtnImg src={EmogiIcon} onClick={this.openEmojiComment } style={{width: '18px', paddingLeft: '10px', paddingRight: '10px'}}/>
                                <Divider orientation="vertical" style={{ height: '55px', color: '#fefefe'}} />
                                { this.state.userComment.length > 0 ? 
                                  <BtnImg src={SendIcon} onClick={() => this.handleServiceComment(serviceData.id) } style={{width: '18px', paddingLeft: '25px', float: 'right', paddingRight: '2px', cursor:'pointer'}}/> 
                                  : <BtnImg src={SendIcon} style={{width: '18px', paddingLeft: '25px', float: 'right', paddingRight: '2px', opacity:0.5}}/>
                                }</InputAdornment>}
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{
                                  'aria-label': 'weight',
                                }}
                                style={{ width: '100%'}}
                              />
                            </div>
                            { this.state.openCommentEmoji ? 
                             <Picker onEmojiClick={this.onCommentEmojiClick} pickerStyle={{ width: '100%' }}/> : "" }
                              
                      </div>
                      { serviceCommentData?.map?.(p => <div style={{ marginBottom: '15px'}}>
                        <UserAvtar>
                          <Link to={`/user/${p?.account_id}`}><Avatar style={{ width: 35.26, height: 31.87 }} src={p?.profile_pic || OyoAvatar} onClick={() => navigation.navigate(`/user/${p?.account_id}`)} /></Link>
                        </UserAvtar>
                        <CommentDetail>
                            <AccountName>{ p?.account_name }</AccountName>
                            <CommentData>{ p?.content }</CommentData>
                        </CommentDetail>
                          <CardActions>
                            <FlexSpaceBetween style={{ padding: '0px 0px 5px 5px' }}>
                              <CardButtonWrapper>
                                <CardButton onClick={() => this.handleCommentLike(p)}>{ !p.is_like ? "Like": <div style={{ color: '#009c05'}}>Like</div>}</CardButton>
                                <div style={{ lineHeight: '2.5', color: '#d4d4d4' }}>|</div>
                                <CardButton onClick={() => this.handleSetReplyId(p.id)}>Reply</CardButton>
                              </CardButtonWrapper>
                              { p?.like_count ? 
                              <div style={{ display: 'flex', alignItems: 'center'}}>
                                <BtnImg src={LikedIcon} style={{width: '18px'}}/> 
                                <span style={{ paddingLeft: '3px', fontSize: '13px'}}>{ p?.like_count }</span> 
                              </div> : '' }
                            </FlexSpaceBetween>
                          </CardActions>
                          { this.state.reply_id === p.id ? 
                          <div style={{ marginTop: '15px', marginBottom: '40px', paddingLeft: '50px'}}>
                            <CommentUserAvtar>
                                <Link to={`/user/${this.context?.id}`}><Avatar style={{ width: 35.26, height: 31.87 }} src={this.context?.profile_pic || OyoAvatar}/></Link>
                              </CommentUserAvtar>
                              <div style={{  width: 'calc(100% - 50px)', display: 'inline-block', boxSizing: 'border-box' }}>
                            
                              <OutlinedInput
                                id="outlined-adornment-weight"
                                value={ this.state.userReply }
                                onChange={this.handleCourseReplyChange}
                                placeholder="Comment your views"
                                endAdornment={<InputAdornment position="end"><BtnImg src={EmogiIcon} onClick={this.openEmojiCommentReply } style={{width: '18px', paddingLeft: '10px'}}/> 
                                { this.state.userReply.length > 0 ? 
                                  <BtnImg src={SendIcon} onClick={() => this.handleReplyComment() } style={{width: '18px', paddingLeft: '25px', float: 'right', paddingRight: '2px', cursor:'pointer'}}/> 
                                  : <BtnImg src={SendIcon} style={{width: '18px', paddingLeft: '25px', float: 'right', paddingRight: '2px', opacity:0.5}}/>
                                }</InputAdornment>}
                                aria-describedby="outlined-weight-helper-text"
                                inputProps={{
                                  'aria-label': 'weight',
                                }}
                                style={{ width: '100%'}}
                              />
                            </div>
                            { this.state.openCommentReplyEmoji ? 
                             <Picker onEmojiClick={this.onReplyEmojiClick} pickerStyle={{ width: '100%' }}/> : "" }
                            </div> : ''}
                            { p?.replies?.length > 0 && this.state.openReply != p.id ?
                            <div onClick={() => this.showReply(p.id) } style={{ width: 'calc(100% - 50px)', height: '8px', margin: '0px 50px 25px', borderBottom: '1px solid #cdcdcd', textAlign: 'center'}}>
                            <span style={{fontSize: '13px', backgroundColor: '#fff', padding: '0 30px', color: '#a9a9a9'}}>
                              View  { p?.replies?.length } replies
                            </span>
                          </div>
                          : '' }
                              { this.state.openReply == p.id  ? p?.replies?.map?.((r: any) => <div style={{ padding: '10px 0px 10px 50px' }}>
                                <UserAvtar>
                                  <Link to={`/user/${r?.account_id}`}><Avatar style={{ width: 35.26, height: 31.87 }} src={r?.profile_pic || OyoAvatar} onClick={() => navigation.navigate(`/user/${r?.account_id}`)} /></Link>
                                </UserAvtar>
                                <CommentDetail>
                                    <AccountName>{ r?.account_name }</AccountName>
                                    <CommentData>{ r?.content }</CommentData>
                                </CommentDetail>
                                </div>
                              ) : ''}
                      </div>) }

                      {
                        this.state.pagination?.next_page ? <div style={{ marginTop: '50px', marginBottom: '50px', width: '100%', textAlign: 'center', color: '#b9b9b9', fontSize: '15px', fontWeight: 'bold', cursor: 'pointer', backgroundColor: '#f0f0f0', padding: '15px 0px', borderRadius: '10px', borderColor: '#fefefe'}} onClick={this.loadMore}>Load more discussion</div> : '' 
                      }
                  </DiscussSection>
               </RoundedCard>
              </ModelBody>

          </Grid>
      </Box>
  </Modal >
  </Wrapper> }
    </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
CourseDetailController.contextType = UserContext

const EarnCoins = ({ coins }: { coins: number }) => <GreenBg>
  <img src={iconCorp} width='35px' />
  <Typography variant="h6" style={{ fontWeight: 600 }} color="primary">Earn {coins} Corpstage coins</Typography>
</GreenBg>

const GreenBg = styled('div') ({
    padding: '15px',
    background: '#F2F7E4',
    borderRadius: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '25px'
  })

const ModelBody = styled(Grid)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'unset',
  flexDirection: 'column',
  padding: '0px'
})

const PriceContent = styled('span')({
    // fontWeight:500,
    // fontSize"15px",
    marginRight: '5px',
    marginTop: '2px',
  })

  const PR5 = styled('div')({
    fontWeight: 700,
  })

  const RatedService = styled('div')({
    padding: '15px',
    background: '#ccc',
    borderRadius: '10px',
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    // gap: '25px'
  })

  const RateService = styled('div')({
    padding: '15px',
    background: '#2caa38',
    borderRadius: '10px',
    color: '#fff',
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    // gap: '25px'
  })

const ActionRoundedButton = styled(Button)({
    color: '#2caa38',
    border: '2px solid #79bb74',
    borderRadius: '25px',
    padding: '5px 35px',
    textTransform: 'none',
    fontWeight: 600
   })

   const ActionRoundedButtonInterested = styled(Button)((props: any)=>({
    color: '#fff',
    background: '#2caa38',
    border: '2px solid #79bb74',
    borderRadius: '25px',
    padding: '5px 35px',
    textTransform: 'none',
    fontWeight: 600,
    "&:hover": {
      background: '#2caa38',
    }
   }))


const SelectItemUserText = styled('span')({
  overflow: 'hidden',
  textOverflow: 'ellipsis'
})

const RoundedCard = styled(Card)({
  borderRadius: '15px',
  padding: '10px 20px',
  margin: '20px 0px'
})

const FlexSpaceBetween = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignContent: 'center',
  padding: '20px 0px',
  gap: '10px',
  width: '100%',
  '@media screen and (max-width: 480px)': {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
})

const InnerContent = styled(Flex)({
  gap: "20px", 
  alignItems: "center", 
  justifyContent: "space-between",
  '@media screen and (max-width: 480px)': {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    // alignItems: "start"
  },
})

const PlContent = styled(Flex)({
  fontSize: '16px',
  color: '#2A353F', 
  gap: '30px', 
  padding: '0', 
  alignItems: 'center', 
  marginTop: '15px',
  '@media screen and (max-width: 480px)': {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    // alignItems: "start"
  },
})

const CardButton = styled(Button)({
  border: 'none',
  color: '#aaaaaa',
  fontWeight: 600,
  textTransform: 'none'
})

const CardButtonWrapper = styled('div')({
  display: 'flex',
  gap: '5px',
  padding: '0 20px',
})

const MR5 = styled('span')({
  marginRight: '5px',
  color: '#2a353f',
  textTransform: 'capitalize'
})
const BtnImg = styled('img')({
  width: 25
})
const PostMainContent = styled('div')({
  padding: '10px 0'
})
const CloseButton = styled(Button)({
  position: 'absolute',
  top: '-10px',
  right: '-50px'
})
const Wrapper = styled('div')({
  zIndex: 1010,
  minWidth: '100vw',
  minHeight: '100vh',
  background: 'rgba(0,0,0,0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const DiscussSection = styled('div')({
  padding: '16px 30px'
})

const AccountName = styled('div')({
  color: '#009c05',
  'font-weight': 'bold',
  'font-size': '13px'
})

const CommentData = styled('div')({
  width: '100%',
  'word-break': 'break-word',
  marginTop: '5px'
})

const DiscussHeader = styled('div')({
  fontSize: '18px',
  fontWeight: 'bold',
  padding: "15px 18px 25px"
})

const UserAvtar = styled('div')({
  display: 'inline-block',
  width: '50px',
  height: '40px',
  verticalAlign: 'top',
  paddingTop: '3px'
})

const CommentUserAvtar = styled('div')({
  display: 'inline-block',
  width: '50px',
  height: '40px',
  verticalAlign: 'top',
  paddingTop: '12px'
})

const CommentDetail = styled('div')({
  display: 'inline-block',
  width: 'calc(100% - 50px)',
  boxSizing: 'border-box',
  padding: '10px 20px 20px',
  minHeight: '80px',
  border: '1px solid #e8e4e4',
  borderRadius: '5px',
  lineHeight: '1.3',
  textAlign: 'justify'
})

const CourseContent = styled('span')({
  fontWeight: 700, 
  fontSize: "15px", 
  marginRight: '5px'
})
// Customizable Area End

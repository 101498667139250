import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { sendAPIRequest } from '../../../components/src/utils';
import * as Yup from 'yup';
import { toast } from 'react-toastify'
import { parseAddRequestMessageData } from "./Messages";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  carbonReport: string;
  uploadedCarbonFile: any;
  carbon_file_name: string;
  carbon_file_size: string;
  loading: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class carbonCalculatorDownloadController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiGetAllUsersCallId = '';
  apiGetAllRequestTypeCallId = '';
    getDownloadURL = "";
  uploadCarbonFileId = "";
  downloadUploadedFileId = "";

  async componentDidMount() {
    super.componentDidMount();  
    this.setState({loading:true});
    this.DownloadUploadedFile();
  }

  handleCarbonFileUpload = async(event: any) => {
    const authToken = await getStorageData('authToken')
    const uploadedFile = event.target.files[0];
    this.setState({
      uploadedCarbonFile: uploadedFile,
      carbon_file_name: uploadedFile.name,
      carbon_file_size: uploadedFile.size,
    });
    const body = new FormData()
      body.append(`carbon_file`, event.target.files[0])
      this.uploadCarbonFileId = sendAPIRequest(configJSON.uploadCarbonFile, {
       method: "POST",
           headers: {
               token: authToken
           }, body
     })
    
  }
         
  DownloadUploadedFile = async() => {
    const authToken = await getStorageData('authToken')
    
    this.downloadUploadedFileId = sendAPIRequest(configJSON.DownloadUploadedFile, {
      method: "GET",
      headers: {
          token: authToken
      },
    })
  }

  initialType: any[] = [];
  initialValues = {
    showResponce: false,
    title: "",
    description: "",
    request_type_id: 0,
    account_ids: this.initialType,
    sender_id: 0,
    created_by_id: this.initialType,
    response: '',
    reference_link: ''
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AddRequestMessaage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      carbonReport: "",
      uploadedCarbonFile: "",
      carbon_file_name: "",
      carbon_file_size: "",
      loading: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      runEngine.debugLog("API Message Recived", message);
      if (responseJson && responseJson.error) {
        this.parseApiErrorResponse(responseJson.error);
        this.parseApiCatchErrorResponse(responseJson.error);
        this.setState({loading: false});
      } else if (responseJson) {
        if (apiRequestCallId === this.downloadUploadedFileId) {
          this.setState({uploadedCarbonFile:responseJson.carbon_link_url,carbon_file_name:responseJson.carbon_file_name,carbon_file_size:responseJson.carbon_file_size,loading: false})
        }
        else if (apiRequestCallId === this.uploadCarbonFileId) {
          this.setState({uploadedCarbonFile:responseJson.carbon_link_url,carbon_file_name:responseJson.carbon_file_name,carbon_file_size:responseJson.carbon_file_size})
          toast.success("File uploaded Successfully")
         }
      } 
    }
    // Customizable Area End
  }

  // Customizable Area Start
  onDownload = () => {
    const downloadFile: any = this.state.uploadedCarbonFile
    if (downloadFile instanceof File) {
      const fileBlob = new Blob([downloadFile], { type: downloadFile.type });
      const fileUrl = URL.createObjectURL(fileBlob);
      this.openUrl(fileUrl)
      URL.revokeObjectURL(fileUrl);
    } else {
      this.openUrl(this.state.uploadedCarbonFile)
    }
  }
  openUrl = (fileUrl: string) => {
    const newTab = window.open((fileUrl), "_blank");
    if (newTab) {
      newTab.focus();
    } else {
      toast.error("Failed to open file in a new tab.")
    }
  }
  // Customizable Area End
}

import React from "react";
// Customizable Area Start
import { styled, makeStyles } from '@material-ui/styles'
import { Card, 
  CardHeader, 
  CardContent, 
  CardActions,
  Checkbox,
  CircularProgress,
  Grid, 
  IconButton, 
  Divider, 
  Button, 
  Typography, 
  TextField,
  Avatar, 
  InputAdornment,
  Menu,
  MenuItem,
  ListItemText, } from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import { CloseCircleIcon } from '../../../components/src/Icons.web'
import { 
  SearchIcon, } from '../../ActivityFeed/src/assets'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { ArrowDownIcon } from './assets'
import Flex from '../../../components/src/Flex.web'
import SearchFilter from '../../../components/src/Search.web'
import NestedMenuItem from '../../../components/src/NestedMenuItem.web'
export const configJSON = require("./config");

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
// Customizable Area End

import FilterPostsController, { Props } from "./FilterPostsController";


export default class FilterPosts extends FilterPostsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
 
  render() {
    // Customizable Area Start
    return (
      // Customizable Area Start
      <FilterRowWrapper>
        <Flex f={1}>
          <SearchFilter onChange={this.handleQueryChange} />
        </Flex>
        <Flex f={1}>
           <FilterMenu
              filterByMe={this.state.filterByMe}
              filterByFav={this.state.filterByFav}
              toggleFilterByMe={this.toggleFilterByMe}
              toggleFilterByFav={this.toggleFilterByFav} 
              handleDateInputFocus={this.handleDateInputFocus} 
              handleDateInputBlur={this.handleDateInputBlur} 
              keepOpen={this.state.selectingDate} 
              menuPosition={this.state.filterMenuPosition} 
              onClose={this.handleFilterMenuClose}
              catControls={{
                categories: this.state.categories,
                handleSelect: this.handleCategorySelect,
                selectedCategories: this.state.filterCategories,
              }}
              tagControls={{
                tags: this.state.subCategories,
                selectedTags: this.state.filterSubCategories,
                handleSelect: this.handleTagSelect,
              }}
              dateControls={{
                  handleSubmit: this.handleDateFilterSubmit,
                  handleCancel: this.handleDateFilterCancel,
                  handleFromDateChange: this.handleFromDateChange,
                  handleToDateChange: this.handleToDateChange,
                  filterFromDate: this.state.filterFromDate,
                  filterToDate: this.state.filterToDate,
                  clearFromDate: this.clearFromDate,
                  clearToDate: this.clearToDate,
                  minDate: this.state.filterFromDate,
                  maxDate: this.state.filterToDate
                }}
              /> 
          <TextFieldRounded
            value={this.filterText()}
            onClick={this.handleFilterMenuOpen}
            // @ts-ignore 
            InputProps={{
              ref: this.filterRef, 
              endAdornment: (<InputAdornment position="start">
                {this.filterText() && <CloseCircleIcon
                  onClick={this.clearFilters}
                  style={{ margin: '0 10px', width: '24px', cursor: 'pointer' }}
                />}
                <img src={ArrowDownIcon} width="16" /></InputAdornment>)
          }} variant="outlined" placeholder={configJSON.labelFilterBy}  />
        </Flex>
      </FilterRowWrapper>
      // Customizable Area End
    );
    // Customizable Area End
  }
  
}

// Customizable Area Start
const TextFieldRounded = styled(TextField)({
  width: '100%',
  background: '#FFFFFF',
  borderRadius: '15px',    
  '& > .MuiOutlinedInput-root': {
    borderRadius: '15px',    
  },
  '& > div > input': {
    paddingTop: '14px',
    paddingBottom: '14px'
  }
})

const FilterRowWrapper = styled('div')((props: any) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px 0',
  width: '100%',
  gap: '5px',
  [props.theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    '& > div': {
      width: '100%' // child with 100% width
    }
  }
}))


const FilterMenu = ({ menuPosition, onClose, handleDateInputFocus, handleDateInputBlur, keepOpen, catControls, tagControls, dateControls, ...props  }: FilterMenuProps) => {
    const {clearFromDate, clearToDate} = dateControls
    const [nestedMenuName, setNestedMenuName] = React.useState("")

    return (
  <Menu open={!!menuPosition} anchorPosition={menuPosition} onClose={onClose} anchorReference="anchorPosition">
    <CloseFilter><Button onClick={onClose}><CloseCircleIcon /></Button></CloseFilter>
    <MenuItem selected={props.filterByMe} onClick={props.toggleFilterByMe}>
      Created by Me
    </MenuItem>
    <MenuItem selected={props.filterByFav} onClick={props.toggleFilterByFav}>Favourite</MenuItem>
    <SearchNestedMenu
      name="category" 
      nestedMenuName={nestedMenuName} 
      setNestedMenuName={setNestedMenuName} 
      items={catControls.categories} 
      selectedIds={catControls.selectedCategories} 
      label="Based on Category"
      selectHandler={catControls.handleSelect}  
      parentMenuOpen={!!menuPosition} 
    />
    <SearchNestedMenu
      name="subcategory"
      nestedMenuName={nestedMenuName} 
      setNestedMenuName={setNestedMenuName}  
      items={tagControls.tags} 
      selectedIds={tagControls.selectedTags} 
      label="Based on Tags"
      selectHandler={tagControls.handleSelect}  
      parentMenuOpen={!!menuPosition} 
    />
    <NestedMenuItem 
      keepOpen={keepOpen} 
      label="Based on Date" 
      parentMenuOpen={!!menuPosition}
      // @ts-ignore
      MenuProps={{
        getContentAnchorEl:null,
        anchorOrigin:{
          vertical: 'top',
          horizontal: 'right',
        },
        transformOrigin:{
          vertical: 'top',
          horizontal: 'left',
        },
      }}
      >
      <FilterByDateWrapper>
        <FilterByDateRow>
          Choose Date
        </FilterByDateRow>
        <FilterByDateRow>
          <From >From</From>
          <FromDate> 
            {/* @ts-ignore  */}
            <DatePicker
              clearable
              variant="inline"
              inputVariant="outlined"
              onChange={dateControls.handleFromDateChange}
              value={dateControls.filterFromDate}
              autoOk={true}
              format="yyyy-MM-dd"
              style={{width: '100%'}}
              InputProps={{
                endAdornment: (<InputAdornment position="end">
                      {dateControls.filterFromDate && <CloseCircleIcon 
                        onClick={clearFromDate} 
                        style={{ width: '24px', cursor: 'pointer' }} 
                      />}
                    </InputAdornment>)
              }}
              inputProps={{placeholder: 'Select',onFocus: handleDateInputFocus,
              onBlur: handleDateInputBlur,}}
              maxDate={window.location.href.indexOf("Events2") > -1 ? new Date('2050-12-12') : new Date()}
              initialFocusedDate={dateControls.filterToDate}
            />
          </FromDate>
        </FilterByDateRow>

        <FilterByDateRow>
          <To>To</To>
          <ToDate>
            <DatePicker
              clearable
              variant="inline"
              inputVariant="outlined"
              onChange={dateControls.handleToDateChange}
              value={dateControls.filterToDate}
              autoOk={true}
              format="yyyy-MM-dd"
              maxDate={window.location.href.indexOf("Events2") > -1 ? new Date('2050-12-12') : new Date()}
              minDate={dateControls.filterFromDate || new Date()}
              initialFocusedDate={dateControls.filterFromDate}
              style={{width: '100%'}}
              // maxDate={dateControls.handleToDateChange}
              inputProps={{placeholder: 'Select',onFocus: handleDateInputFocus,
              onBlur: handleDateInputBlur}}
              // @ts-ignore
              // shouldDisableDate={(date: any)=> dateControls.filterFromDate && date<dateControls.filterFromDate}
              InputProps={{
                endAdornment: (<InputAdornment position="end">
                      {dateControls.filterToDate && <CloseCircleIcon 
                        onClick={clearToDate} 
                        style={{ width: '24px', cursor: 'pointer' }} 
                      />}
                    </InputAdornment>)
              }}
            />
          </ToDate> 
        </FilterByDateRow>
        <FilterByDateRow>
          <div style={{ flex: 1 }}></div>
          <div style={{ flex: 3 }}>
            <CancelButton onClick={dateControls.handleCancel}>Cancel</CancelButton>
            <SubmitButton onClick={dateControls.handleSubmit}>Submit</SubmitButton>
          </div>
        </FilterByDateRow>
      </FilterByDateWrapper>
    </NestedMenuItem>
  </Menu>
)}


interface FilterMenuProps {
  filterByMe: boolean,
  filterByFav: boolean,
  toggleFilterByMe: ()=>void,
  toggleFilterByFav: ()=>void,
  menuPosition: {
    top: number,
    left: number,
  } | undefined
  onClose: ()=>void,
  handleDateInputFocus: any, 
  handleDateInputBlur: any, 
  keepOpen: any,
  catControls: {
    categories: any[],
    selectedCategories: number[],
    handleSelect: (id: number) => (e: any) => void,
  },
  tagControls: {
    tags: any[],
    selectedTags: number[],
    handleSelect: (id: number) => () => void,
  },
  dateControls: {
    handleFromDateChange: any,
    handleToDateChange: any,
    handleSubmit: () => void,
    handleCancel: () => void,
    clearFromDate: () => void,
    clearToDate: () => void,
    filterFromDate?: Date | null,
    filterToDate?: Date | null,
    maxDate?: Date | null,
    minDate?: Date | null,
  }
}


const FilterByDateWrapper = styled('div')({
  width: '300px', 
  height: '200px', 
  padding: '10px 15px',
  background: '#FFF'
})

const FilterByDateRow = styled('div')({
  display: 'flex',
  marginBottom: '10px',
  alignItems: 'center',
  '@media screen and (max-width: 480px)': {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '7px',
  }
})

const From = styled('span')({
  flex:1,
  '@media screen and (max-width: 480px)': {
    flex:'none',
  }
})

const FromDate = styled('div')({
  flex:3,
  '@media screen and (max-width: 480px)': {
    flex:'none',
  }
})

const To = styled('span')({
  flex:1,
  '@media screen and (max-width: 480px)': {
    flex:'none',
  }
})

const ToDate = styled('div')({
  flex:3,
  '@media screen and (max-width: 480px)': {
    flex:'none',
    marginLeft: '16px',
  }
})

const SubmitButton = styled(Button)({
  background: '#009c20',
  color: '#FFF',
  '&:hover': {
    background: '#009c20',
    opacity: 0.9
  }
})

const CancelButton = styled(Button)({
  color: '#FFF',
  background: '#999',
  marginRight: '5px',
  '&:hover': {
    background: '#999',
    opacity: 0.9
  }
})

interface SearchNestedMenuProps {
  items: any[];
  selectedIds: number[];
  selectHandler: (id: number) => (e: any) => void;
  label: string;
  parentMenuOpen: boolean;
  nestedMenuName?: any; 
  setNestedMenuName?: any; 
  name?: any;
}

function SearchNestedMenu({ items, selectedIds, selectHandler, label, parentMenuOpen, name, nestedMenuName, setNestedMenuName }: SearchNestedMenuProps){
  const [search, setSearch] = React.useState("")
  const [focus, setFocus] = React.useState(false)
  const [filteredItems, setFilteredItems] = React.useState<any[]>(items);
  const classes = useNestedMenuStyles();
  const onFocus = () => setFocus(true)
  const onBlur = () => setFocus(false)

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const value = e.target.value
    setSearch(value)
  } 

  React.useEffect(() => {
    setFilteredItems(items.filter(
      (item: any) => item.name.toLowerCase().includes(search.toLowerCase())
      )
    )
  }, [search])

  const stopPropagation = (e: React.KeyboardEvent<any>) => e.stopPropagation()
  return <NestedMenuItem 
      name={name} 
      nestedMenuName={nestedMenuName} 
      setNestedMenuName={setNestedMenuName} 
      keepOpen={focus} 
      label={label} 
      parentMenuOpen={parentMenuOpen}
      // @ts-ignore
      MenuProps={{ 
        classes: { paper: classes.menuPaper, list: classes.list }, 
        getContentAnchorEl:null,
        anchorOrigin:{
          vertical: 'top',
          horizontal: 'right',
        },
        transformOrigin:{
          vertical: 'top',
          horizontal: 'left',
        }
     }}
    >
      <li onKeyDown={stopPropagation} >
        <TextField
          InputProps={{ startAdornment: ( <InputAdornment position="start">
                <img src={SearchIcon} width={20} style={{paddingLeft: '15px'}} />
              </InputAdornment>
            ),
          }}
          fullWidth
          style={{marginTop: '5px 10px'}}
          inputProps={{ style: {padding: '10px 10px'} }} 
          onFocus={onFocus} 
          onBlur={onBlur} 
          value={search} 
          onChange={onSearchChange} 
          placeholder="Search" />
      </li>
      {items?.length === 0 && <div style={{padding: '20px'}}>Loading</div>}
      {filteredItems?.length === 0 && <div style={{ padding: '20px' }}>No match found</div>}
      {
        filteredItems?.map(
          (cat: any) => (
            <ReverseMenuItem onClick={selectHandler(cat.id)} key={cat.id}>
                <Checkbox
                    onChange={selectHandler(cat.id)}
                    checked={selectedIds.indexOf(cat.id)!==-1}
                    icon={icon}
                    color="primary"
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                  />
                <span className={classes.menuItemLabel}>{cat.name}</span>
            </ReverseMenuItem>
        ))
      }
  </NestedMenuItem>
}

const ReverseMenuItem = styled(MenuItem)({
  display: 'flex', 
  flexDirection: 'row-reverse',
  justifyContent: 'space-between',
  '@media screen and (max-width: 480px)': {
    flexWrap: 'wrap',
    '& span': {
      maxWidth: '200px',
    },
},
})

const CloseFilter = styled('div')({
  display: 'none',
  '@media screen and (max-width:480px)': {
    display: 'flex',
    justifyContent: 'flex-end',
    top: 0,
  }
})

const useNestedMenuStyles = makeStyles((theme:any) => ({
  menuPaper: {
    height: 300,
    display: 'flex',
    // Fix for scroll bug 
    // https://github.com/mui/material-ui/issues/10601#issuecomment-400479793
    pointerEvents: 'auto',
    '@media screen and (max-width:480px)': {
      height: 185,
    }
  },
  list: {
    width: 400,
    display: 'flex'
  },
  menuItemLabel: {
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}));
// Customizable Area End


import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as yup from 'yup'
import { sendAPIRequest } from '../../../components/src/utils';
import { toast } from 'react-toastify';
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  token: string;
  submittingForm?: boolean;
  profile_pic?: any;
  profile_pic_show?: any;
  filesArray?: any;
  sdgFactor: any;
  selectedSDGFactor?: any;
  projectEditData?: any;
  projectId?: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start

  // Customizable Area End
}

export default class CreateSurveysController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  apiRequestCallId: string = ""
  sdgFactorCallId: string = '';
  projectUniqueIdCallId: string = '';
  getProjectEditCallId: string = '';
  apiUpdateRequestCallId:string='';
  attachmentDeleteCallId :string='';
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.GetAllCategoriesResponseMessage),
      getName(MessageEnum.GetAllSubCategoriesResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      token: '',
      profile_pic: '',
      profile_pic_show: '',
      filesArray: [],
      sdgFactor: [],
      selectedSDGFactor: [],
      projectEditData: {},
      projectId: ''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    // Customizable Area End

  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
      let authToken = await getStorageData('authToken'); 
    const id = this.props.navigation.getParam('id')
    console.log(authToken, 'auth token check');
    this.setState({ token: authToken}, () => {
    if (id) {
      this.getViewData(id,authToken)
    }

    this.getSDGFactorApi()
    this.getProjectUniqueIdApi()
  })
  }
  // Customizable Area End

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);


    // Customizable Area Start
    this.getResponse(message);
    // Customizable Area End

  }

  // Customizable Area Start
 async getResponse(message: any) {
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }
  
    const apiRequestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
  
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
  
    switch (apiRequestId) {
      case this.apiRequestCallId:
      case this.apiUpdateRequestCallId:
        this.setState({ submittingForm: false });

        if (responseJson?.data) {
          this.hideModal();
          this.props.navigation.navigate('RequestToListSuccessfull');
       
          return;
        }else if(responseJson?.status===500){
          toast.error('something went wrong')
        }
        
        break;
      case this.sdgFactorCallId:
        if (responseJson.sdg_factors) {
          this.setState({ sdgFactor: responseJson?.sdg_factors?.data });
        }
        break;
      case this.getProjectEditCallId:
        if (responseJson.data) {
          this.setState({
            projectEditData: responseJson.data, 
            selectedSDGFactor: responseJson.data.attributes?.sdg_focus_ids?.map((i: any) => (`${i}`)),
            profile_pic_show:responseJson.data.attributes?.image,
            filesArray:[responseJson.data.attributes?.document]
          });
        }
        break;
      case this.projectUniqueIdCallId:
        if (responseJson.project_unique_id) {
          this.setState({ projectId: responseJson.project_unique_id });
        }
        break;
        case this.attachmentDeleteCallId:
          let authToken = await getStorageData('authToken'); 
          const id = this.props.navigation.getParam('id')
          this.getViewData(id,authToken)
          this.setState({ submittingForm: false })
          break;
      default:
        break;
    }
  }
  

  validationSchema = yup.object().shape({
    preference_ids: yup.array().min(1, 'Select atleast one Preference'),
    category_ids: yup.array().min(1, 'Select atleast one Category'),
    sub_category_ids: yup.array().min(1, 'Select atleast one Sub-Category'),
    location: yup.string().label('Location').required(),
    attachments:yup.mixed().required(),
    description:yup.string().label('Description').required().max(100),
    project_name:yup.string().label('Project Name').required(),
    sdg_focus:yup.string().label('SDG focus').required(),
    organization_name:yup.string().label('Organization Name').required(),
    funding_amount:yup.string().label('Funding Required (Amount in USD)').required(),
    form_of_funding:yup.string().label('Form of Funding').required(),
    status:yup.string().label('Status').required(),
  });

  setFilesArray = (file: any) => {
    console.log('file check',file)
    this.setState({ filesArray: [file] });
  }
  handleFileChange = (event: any) => {
    if (event?.target?.files) {
      this.setState({
        profile_pic: event.target.files[0],
        profile_pic_show: URL.createObjectURL(event.target.files[0]),
      });
    }
  }

getViewData=(id:any,authToken:any)=>{
  this.getProjectEditCallId = sendAPIRequest(`${configJSON.sendProjectEditEndpoint}/${id}`, {
    method: 'GET',
    headers: { 'token': authToken }
  })
}

  handleFormData = (data: any, id: number | undefined, method: string) => {
    let { project_name = "", description = '', organization_name = '', project_unique_id = '',
      funding_amount = '', project_leader = '', attachments = "", preference_ids = [],
      form_of_funding = '', status = '', category_ids = [], sub_category_ids = [],location=''
    } = { ...data }
    const body = new FormData()
  
    preference_ids.forEach((id: any) => body.append('data[preference_ids][]', id))
    category_ids.forEach((id: any) => body.append('data[category_ids][]', id))
    sub_category_ids.forEach((id: any) => body.append('data[sub_category_ids][]', id))
    this.state?.selectedSDGFactor?.forEach((id: any) => body.append('data[sdg_focus_ids][]', id))
    body.append('data[project_name]', project_name)
    body.append('data[project_unique_id]', this.state?.projectId)
    body.append('data[description]', description)
    body.append('data[location]', location)
    body.append('data[organization_name]', organization_name)
    body.append('data[funding_amount]', funding_amount)
    body.append('data[project_leader]', project_leader)
    body.append('data[form_of_funding]', form_of_funding)
    if(this.state.profile_pic){
      body.append('data[image]', this.state.profile_pic)
    }
  
    body.append('data[status]', status)
    if(id){
      attachments?.forEach((file: any) =>file?.url? null: body.append("data[docs][]", file));
    }else{
      attachments.forEach((file: any) => body.append("data[docs][]", file));
    }
   
  
    for (const value of body.entries()) {
      console.log("value ", value);
    }
  
    const headers = {
      "token": this.state.token,
    }
    let url = id?configJSON.sendRequestEndpoint:configJSON.sendRequestCreateEndpoint;
    if (id) {
      url = `${url}/${id}`;
    }
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    if(id){
      this.apiUpdateRequestCallId=requestMessage.messageId;
    }else{
      this.apiRequestCallId=requestMessage.messageId;
    }
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
  
    this.setState({ submittingForm: true })
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
  handleSubmit = (data: any) => {
    this.handleFormData(data, undefined, 'POST');
  }
  
  handleEditSubmit = (data: any) => {
    const id = this.props.navigation.getParam('id')
    this.handleFormData(data, id, 'PUT');
  }
  

  hideModal = () => { this.props.navigation.goBack() }
  getSDGFactorApi = () => {
    this.sdgFactorCallId = sendAPIRequest(`${configJSON.getSDGFocusEndpoint}`, {
      method: "GET",
      headers: { 'token': this.state.token },
    })
  }
  getProjectUniqueIdApi = () => {
    this.projectUniqueIdCallId = sendAPIRequest(`${configJSON.getProjectUniqueId}`, {
      method: "GET",
      headers: { 'token': this.state.token },
    })
  }

  onDelete = (id:any) => {
    const projectId = this.props.navigation.getParam('id')
    this.setState({submittingForm:true})
    this.attachmentDeleteCallId = sendAPIRequest(`${configJSON.attachmentSeekingDeleteEndpoint}id=${projectId}&attachment_id=${id}&attachment_type=docs`, {
      method: "DELETE",
      headers: { 'token': this.state.token },
    })
  }


  // Customizable Area End

}


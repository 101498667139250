import React from "react";
// Customizable Area Start
import TopNavBar from "../../../components/src/TopNavbar";
import { Box, Grid, Typography, Button } from '@material-ui/core';
import { styled } from '@material-ui/styles'
import LeftMenuWeb from "../../../components/src/LeftMenuWeb";
import { Selfassesment, Survey, PdfIcon } from './assets';

import ESGJourneyController, {
    Props,
} from "./ESGJourneyController";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import UserContext from '../../../components/src/UserProvider'
// Customizable Area End
export default class SelfAssesment extends ESGJourneyController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start

        const { navigation } = this.props;
        console.log("idd", this.state?.requestors)
        let Assesments:any = this?.state?.requestors;
        const path = this.context.user_type == "licensed_user" ? "Corporatepermission" : "ESGJourney"
        // Customizable Area End
        return (
            // Customizable Area Start
            <MainBox>
                <Box>
                    <TopNavBar navigation={this.props.navigation} id={""} />
                </Box>

                <OuterGrid container spacing={3}>
                    <LeftGrid item xs={12} sm={12} md={3} lg={3}>
                        <Box >
                            <LeftMenuWeb navigation={this.props.navigation} id={""} />
                        </Box>
                    </LeftGrid>
                    <MiddleGrid item xs={12} sm={12} md={6} lg={6} >
                        <SurveyCardWrapper>
                            <HeadWrapper>
                                <Head>
                                    <div id="backClick" onClick={() => this.props.navigation.navigate(path)}>
                                        <GreyText  >
                                            ESG Journey
                                        </GreyText>
                                    </div>
                                    <ArroWrapper id="revertToFramework" onClick={() => this.props.navigation.navigate(path)}>
                                        <ArrowForwardIosIcon />
                                    </ArroWrapper>
                                    <div id="backClickFramework" onClick={() => this.props.navigation.navigate('ESGJourneyReportingFrameworks')}>
                                        <GreyText  >
                                        Reporting Frameworks
                                        </GreyText>
                                    </div>
                                    <ArroWrapper id="revert" onClick={() => this.props.navigation.navigate('ESGJourneyReportingFrameworks')}>
                                        <ArrowForwardIosIcon />
                                    </ArroWrapper>
                                    Self Assessment
                                </Head>
                            </HeadWrapper>
                            <Cards>
                                {this.state.requestors &&
                                    this.state.requestors.map((item: any, i: number) => {
                                        return (
                                            <SurveyCard key={item.id}>
                                                <LogoWrapper>
                                                    <img src={Selfassesment} />
                                                </LogoWrapper>
                                                <AssesmentHeading>
                                                    {item.name}
                                                </AssesmentHeading>
                                                <SurveyDiv id="fill" onClick={() => this.fillSurvey(item.id)}>
                                                    Survey
                                                    <img src={Survey} />
                                                </SurveyDiv>
                                                <ReportWrapper>
                                                    <ReportButton id="report"
                                                        onClick={() => this.downloadPDFReport('pdf', item.id)}
                                                    > <img src={PdfIcon} /> Report</ReportButton>
                                                </ReportWrapper>
                                                <SubmissionDate>Last date to submit: {Assesments[i]?.end_date}</SubmissionDate>
                                                <Score>Score: {Assesments[i]?.score}</Score>
                                            </SurveyCard>
                                        )
                                    })

                                }
 
                            </Cards>
                        </SurveyCardWrapper>
                    </MiddleGrid>
                </OuterGrid>

            </MainBox>
            // Customizable Area End
        );
    }
}
// Customizable Area Start
ESGJourneyController.contextType = UserContext


const MainBox = styled(Box)({
    backgroundColor: "#edebeb",
    display: "flex",
    flexWrap: 'wrap',
    flexDirection: "column",
    paddingBottom: "20px",
    overflowX: "hidden",
})

const OuterGrid = styled(Grid)({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
})

const LeftGrid = styled(Grid)({
    maxWidth: '300px',
    '@media screen and (max-width: 985px)': {
        maxWidth: '200px',
    },
    '@media screen and (max-width: 768px)': {
        display: 'none',
        visibility: 'hidden',
    },
})

const MiddleGrid = styled(Grid)({
    width: 'calc(100vw - 300px)',
    maxWidth: 'unset',
    flexBasis: 'unset',
    justifyContent: 'center',
    '@media screen and (max-width: 768px)': {
        width: '100%'
    }
})

const SurveyCardWrapper = styled(Box)({
    width: '95%',
    height: 'auto',
    marginTop: '30px',
    marginRight: '20px',
    padding: '20px',
})

const Cards = styled('div')({
    display: 'flex',
    flexWrap: 'wrap',
    gap: '20px'
})

const HeadWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
})

const ArroWrapper = styled('div')({
    color: 'gray',
    marginRight: '10px',
    fontWeight: 100
})

const LogoWrapper = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    '& img': {
        width: '60px',
    }
})

const SurveyDiv = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    gap: '5px',
    '& img': {
        width: '18px',
    }
})

const ReportWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'center'
})

const SurveyCard = styled(Box)({
    width: '250px',
    height: '250px',
    padding: ' 20px 10px',
    marginBottom: '20px',
    boxShadow: '1px 2px 20px #CABFC1',
    borderRadius: '10px',
    background: '#fff'
})

const GreyText = styled(Typography)({
    color: 'gray',
    marginRight: '10px',
    cursor: "pointer",
})

const AssesmentHeading = styled(Typography)({
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 'bold',
    marginBottom: '15px',
    marginTop: '15px'
})

const SubmissionDate = styled(Typography)({
    display: 'flex',
    justifyContent: 'center',
    fontSize: '12px',
    marginTop: '10px',
    marginBottom: '10px'
})

const Head = styled('div')({
    display: 'flex',
    alignItems: 'center',
    color: '#000',
    margin: '10px 0px 30px 12px',
    fontSize: '1rem',
    fontWeight: 600,
    '@media screen and (max-width: 768px)': {
        margin: '20px 0px 10px 12px',
    },
})

const ReportButton = styled(Button)({
    display: 'flex',
    justifyContent: 'center',
    width: '80%',
    background: '#0fa60f',
    color: '#fff',
    fontSize: '0.75rem',
    borderRadius: '20px',
    fontWeight: 'bold',
    marginTop: '10px',
    textTransform: 'none',
    '& img': {
        width: '18px',
        marginRight: '10px'
    },
    '&:hover': {
        background: '#0fa60f',
    }
})

const Score = styled(Typography)({
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 'bold',
    color: 'green',
})



// Customizable Area End

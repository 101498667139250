import React from "react";

// Customizable Area Start
import {
  Grid,
  Divider,
  Modal,
  Backdrop,
  Box,
  Button,
  InputAdornment
} from '@material-ui/core'
import { styled } from '@material-ui/styles'
import { Formik } from 'formik'
import FormModal, { InputRow, MInput, MCountedTextArea, MDatePicker, MTimePicker, } from './Components.web'
import { MFileDrop} from '../../../components/src/FormModal.web'
import UserContext from '../../../components/src/UserProvider'
import Flex from '../../../components/src/Flex.web'
import Maps from '../../maps/src/Maps.web'
import PreferenceCatSubCat from '../../categoriessubcategories/src/PreferenceCatSubCat.web'
// @ts-ignore
import classes from './Event2Web.module.css'

// Customizable Area End

import CourseEditController, {
  Props,
  configJSON
} from "./EventEditController";

export default class CourseEdit extends CourseEditController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  
  // Customizable Area End

  render() {
    // Customizable Area Start
    let { eventData } = this.state
    const event_id_1 = eventData?.event_id_1 || ''
    const event_id_2 = eventData?.event_id_2 || ''
    const startDateTime = eventData?.start_date_time ? new Date(eventData.start_date_time) : null;
    const endDateTime = eventData?.end_date_time ? new Date(eventData.end_date_time) : null;
    const start_date = startDateTime;
    const end_date = endDateTime;
    const start_time = startDateTime;
    const end_time = endDateTime;
    const initialValues = { 
      event_id_1, 
      event_id_2, 
      name: eventData?.name || '',  
      link: eventData?.link || '',
      location: eventData?.location || '',
      description: eventData?.description || '',
      participation_coins: eventData?.participation_coins || '',
      language: eventData?.language || '',  
      start_date,
      end_date, 
      start_time,
      end_time,
      preference_ids: eventData?.preference_ids || [], 
      category_ids: eventData?.category_ids || [], 
      sub_category_ids: eventData?.Sub_category_ids || [],
      uploaded_image: eventData?.images || [],
      image: ''
    }
    // Customizable Area End
    return (
      // Customizable Area Start
      
        <Wrapper>
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
            open={this.state.openModal}
            //onClose={}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
    
            <Box
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                background: '#fff',
                borderRadius: 8,
                width: '700px',
                position: "relative",
            }} className="schedule_modal">
                <Grid container spacing={0}>
                <ModelBody item xs={12}>

                <Formik initialValues={initialValues} enableReinitialize={ true } onSubmit={this.handleEditFormSubmit} validationSchema={this.editFormSchema} >
                {({ values, errors }: any) => (
                    <div className={ classes.modalTopCls }>
                    <FormModal headerTitle="Update Event" isDirty={true} onClose={this.onCloseModal} onSubmit={this.handleEditFormSubmit} submitLabel="Update">
                    <InputRow label="Event ID*" input={
              <IDWrapper>
                <Flex1>
                  <MInput name="event_id_1" placeholder="Event ID" disabled />
                </Flex1>
                <Flex1>
                  <MInput name="event_id_2" placeholder="Event ID" disabled />
                </Flex1>
              </IDWrapper>
            } />
            <InputRow label="Event Name*" input={<><MInput name="name" placeholder="Enter Event Name" /></>} />
            <InputRow label="Event Link*" input={<><MInput name="link" placeholder="Enter URL" /></>} />
            <InputRow label="Start Date and Time*" input={<Flex gap="5px">
            <StartWrapper>
              <StartDate>
                <MDatePicker name="start_date" InputProps={{ placeholder: "Enter Date" }} maxDate={values.end_date} disablePast />
              </StartDate>
              <StartTime>
                <MTimePicker name="start_time" InputProps={{ placeholder: "Enter Time" }} />
              </StartTime>
              </StartWrapper>
            </Flex>}
            />
            <InputRow label="End Date and Time*" input={<Flex gap="5px">
            <EndWrapper>
              <EndDate>
                <MDatePicker name="end_date" InputProps={{ placeholder: "Enter Date" }} disablePast minDate={this.state.start_date || null} />
              </EndDate>
              <EndTime>
                <MTimePicker name="end_time" InputProps={{ placeholder: "Enter Time" }}  disablePast minTime={values.start_date} />
              </EndTime>
              </EndWrapper>
            </Flex>}
            />
            {/* @ts-ignore */}
            <InputRow label="Location*" name="location" input={<><Maps {...this.props} input /></>} />
            
            <PreferenceCatSubCat {...this.props} />
            <InputRow label="Language*" input={<><MInput name="language" placeholder="Enter" /></>} />
            <InputRow label="Participation Coins*" input={<><MInput name="participation_coins" placeholder="" /></>} />
            <InputRow label="Description*" input={<MCountedTextArea class={classes.rowTextStyleClass} name="description" />} />
            <InputRow label="Upload Image (Optional)" input={<><MFileDrop name="image"  multiple onDelete={this.removeUploadedImages}/></>} />
                    </FormModal>
                    </div>
                    )}
                    </Formik>

                </ModelBody>

                </Grid>
            </Box>
        </Modal >`
        </Wrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
CourseEditController.contextType = UserContext
const dollarFieldProps = {
    placeholder: "Enter",
    InputProps: {
      startAdornment: <InputAdornment position="start" style={{ paddingLeft: '6px' }}>$ <Divider style={{ marginLeft: '15px', border: '1px solid #f1f1f1', height: '44px'}} /></InputAdornment> 
    }
  }
  
  const rupeeFieldProps = {
    placeholder: "Enter",
    InputProps: {
      startAdornment: <InputAdornment position="start" style={{ paddingLeft: '6px' }}>₹ <Divider style={{ marginLeft: '15px', border: '1px solid #f1f1f1', height: '44px'}} /></InputAdornment>
    }
  }
  
const ModelBody = styled(Grid)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'unset',
  flexDirection: 'column',
  padding: '0px',
  '& .MuiPaper-root': {
    marginLeft: "40px",
  }
})
const CloseButton = styled(Button)({
  position: 'absolute',
  top: '-10px',
  right: '-50px'
})

const IDWrapper = styled('div')({
  display: 'flex', 
  gap: '5px',
  '@media screen and (max-width: 480px)': {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  }
})

const StartWrapper = styled('div')({
  display: "flex",
  gap: "5px",
  // width: "100%",
  justifyContent: "space-between",
  '@media screen and (max-width: 480px)': {
    display: "flex",
    flexWrap: "wrap",
  }
})

const StartDate = styled('div')({
  flex: 1,
  '@media screen and (max-width: 480px)': {
    flex: 'none',
    width: "100%",
  }
})

const StartTime = styled('div')({
  flex: 1,
  '@media screen and (max-width: 480px)': {
    flex: 'none',
    width: "100%",
  }
})

const EndWrapper = styled('div')({
  display: "flex",
  gap: "5px",
  // width: "100%",
  justifyContent: "space-between",
  '@media screen and (max-width: 480px)': {
    display: "flex",
    flexWrap: "wrap",
  }
})

const EndDate = styled('div')({
  flex: 1,
  '@media screen and (max-width: 480px)': {
    flex: 'none',
    width: "100%",
  }
})

const EndTime = styled('div')({
  flex: 1,
  '@media screen and (max-width: 480px)': {
    flex: 'none',
    width: "100%",
  }
})

const Flex1 = styled('div')({
  flex: 1,
  '@media screen and (max-width: 480px)': {
    flex: 'none',
    width: '100%',
  }

})

const Wrapper = styled('div')({
  zIndex: 1010,
  minWidth: '100vw',
  minHeight: '100vh',
  background: 'rgba(0,0,0,0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

// Customizable Area End

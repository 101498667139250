// Customizable Area Start
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const ExcelIcon = require('../assets/ic_excel.png')
export const DownloadIcon = require('../assets/ic_download.png')
export const ArrowDownIcon = require('../assets/ic_arrow_down.png')
export const SearchIcon = require('../assets/ic_search.png')
export const BitmapIcon0 = require('../assets/Bitmap.png')
export const BitmapIcon1 = require('../assets/Bitmap2.png')
export const BitmapIcon2 = require('../assets/Bitmap3.png')
export const BitmapIcon3 = require('../assets/Bitmap4.png')
export const BitmapIcon4 = require('../assets/Bitmap5.png')
export const TestImg = require('../assets/pexels-photo-358457.jpeg')
export const Selfassesment = require('../assets/Selfassesment.png')
export const Survey = require('../assets/Survey.png')
export const Stackholder = require('../assets/Stackholder.png')
export const Sassesment = require('../assets/Sassesment.png')
export const Eassesment = require('../assets/Eassesment.png')
export const Gassesment = require('../assets/Gassesment.png')
export const EditIcon = require('../assets/Edit.png')
export const PdfIcon = require('../assets/image_Bitmap.png')
export const VerifiedIcon = require('../assets/verified-icon.png')
export const UnverifiedIcon = require('../assets/Unverified.png')
export const DownloadIC = require('../assets/ic_download_white.png')
export const warningIcon = require('../assets/warning.png')
export const Group1 = require("../assets/Group.png");
export const Group3 = require("../assets/Group 3.png");
export const Group4 = require("../assets/Group 4.png");
export const Group7 = require("../assets/group_Group 2.png");
export const Group9 = require("../assets/group_Group 4.png");
export const Group11 = require("../assets/group_Group 6.png");
export const deleteImg = require("../assets/delete.png");
export const LocationIcon = require('../assets/ic_location.png')
export const OyoAvatar = require('../assets/ic_oyo_profile.png')
export const ThumbGreenIcon = require('../assets/ic_thumbgreen.png')
export const CloseIcon = require('../assets/ic_close.png')
export const ShareIcon = require('../assets/ic_share.png')
export const LikedIcon = require('../assets/ic_thumbgreen.png');
export const SendIcon = require('../assets/ic_send.png');
export const EmogiIcon = require('../assets/smile.png')
export const iconUpload = require('../assets/ic_upload_green.png')
export const imageIcon = require('../assets/ic_image_icon.png')
export const videoIcon = require('../assets/ic_video_icon.png')
export const csvIcon = require('../assets/ic_csv_icon.jpg')
export const pdfIcon = require('../assets/ic_pdf.png')
export const docIcon = require('../assets/ic_doc.png')
export const xlsIcon = require('../assets/ic_xlsx_icon.png')
export const genericIcon = require('../assets/ic_generic_file.png')
export const reportImg = require("../assets/Report.svg")
export const ceCalculatorImg = require("../assets/CeCalculator.svg")
export const CDPLogo = require("../assets/CDP.png")
export const CSRDLogo = require("../assets/CSRD.png")
export const GRILogo = require("../assets/GRI.png")
export const ISSBLogo = require("../assets/ISSB.png")
export const ArrowRound = require("../assets/ArrowRound.png")
// Customizable Area End

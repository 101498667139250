import React from "react";
// @ts-ignore
import * as config from "../../../framework/src/config";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { Block } from "../../../framework/src/Block";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
// Customizable Area Start
import { sendNewNotificationMessage } from './AllNotificationsController'
import { getStorageData } from "../../../framework/src/Utilities";
const USER_NOTIFICATION_CHANNEL = "UserNotificationChannel"
// Customizable Area End



/**
* Responsible for making web socket connection to rails cabel, connecting
* and disconnecting to various channels
*/
export default class CableBlock extends Block {
  // Customizable Area Start
  messagePositiveButton: any
  messageNegativeButton: any
  messageNeutralButton: any
  socket: WebSocket
  subScribedMessages: any[];
  currentUserId: any;
  // Customizable Area End
  


  constructor() {
    super();

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AlertMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionUpdateMessage),
      // Customizable Area End
     ]
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    const wsurl = config.baseURL.replace('https', 'wss')+"/cable"
    this.socket = new WebSocket(wsurl)
    this.socket.onopen = this.onopen
    this.socket.onmessage = this.handleMessage
    // Customizable Area End
    
    this.receive = this.receive.bind(this);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if(message.id === getName(MessageEnum.SessionUpdateMessage)){
      const user = message.getData(getName(MessageEnum.SessionUpdateData))
      if(!user) {
      if(this.currentUserId)
        this.unsubscribe(USER_NOTIFICATION_CHANNEL, { user_id: this.currentUserId })
      } else {
        this.currentUserId = user.id
        this.subscribe(USER_NOTIFICATION_CHANNEL, {user_id: this.currentUserId})
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  onopen = (e: any) => {
    console.log("[WSS] Connectio Opened")
    setTimeout(() => this.subscribeToNotificationsOfCurrentUser(), 500)
    
  }
  
  checkOpen = () => this.socket.readyState === WebSocket.OPEN

  handleMessage = (e: any) => {
    console.log("notification",e?.data,e?.data?.identifier)
    const message = JSON.parse(e?.data||"{}")
    if(message.identifier) {
      const identifier = JSON.parse(message.identifier)
      if(identifier.channel === USER_NOTIFICATION_CHANNEL) {
        if(!message.message) return // ignore all other messages
        const notification = {
            ...message.message.notification, 
            user_profile: {
              profile_id: `/user/${message.message?.account?.data?.id}`,
              avatar_link: message.message?.account?.data?.attributes?.profile_pic
            }
          }
        sendNewNotificationMessage(notification)
      } 
    }
  }

  subscribe = (channel: string, params?: any) => {
    if(this.checkOpen()) {
      const data = JSON.stringify({
          command: 'subscribe',
          identifier: JSON.stringify({
            channel,
            ...(params||{})
          })
        })
      this.socket.send(
        data
      )
    } else {
      console.log("[WSS] Connection not open yet")
    }
  }

  unsubscribe = (channel: string, params?: any) => {
    if(this.checkOpen()) {
      const data = JSON.stringify({
        command: 'unsubscribe',
        identifier: JSON.stringify({
          channel,
          ...(params || {})
        })
      })
      this.socket.send(data)
    } else {
      console.log("[WSS] Connection not open yet")
    }
  }

  subscribeToNotificationsOfCurrentUser = async() => {
    let token: any =  await getStorageData("authToken")
    let user: any = await getStorageData('user');
    if(!token) return
    user = JSON.parse(user)
    try {
      this.currentUserId = user.id
      this.subscribe(USER_NOTIFICATION_CHANNEL, {user_id: this.currentUserId})
    } catch(err){
      console.error(err)
    }
  }
  // Customizable Area End
}



// Customizable Area Start
// Customizable Area End
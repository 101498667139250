
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { sendAPIRequest } from "../../../components/src/utils";
import { getStorageData } from "../../../framework/src/Utilities";
import { parseServiceCreatedMessage } from "./Messages";
import { CorpstageCoins } from "./types";
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  // enableField: boolean;
  // Customizable Area Start
  token: string;
  // // selectedMyTab: number;
  corpstageCoins: CorpstageCoins[];
  modal: 'purchase' | null;
  userRole: any,
  pagination?: any,
  loadingCorpstageCoins: boolean,
  // loadingPosts: boolean;
  // Customizable Area End
  // urlSearchParam?: any;
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  // enableField: boolean;
  // Customizable Area Start
  token: string;
  // selectedMyTab: number;
  corpstageCoins: CorpstageCoins[];
  modal: 'purchase' | null;
  userRole: any,
  pagination?: any,
  loadingCorpstageCoins: boolean,
  // loadingPosts: boolean;
  // Customizable Area End
  // urlSearchParam?: any;
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PurchaseCoinsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  corpstageCoinsListRequestId: string = ""
  userRoleCallId: string = ""
  serviceFilterCallId: string = ""
  apiPaginateCallId: string = ""
  apiUnfavCallIds: any[] = []
  favCallIdToPostId: any = {}
  apiFavCallIds: any[] = []
  purchaseCallId: string = ""
  // routeCallId:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.GetAllCategoriesResponseMessage),
      getName(MessageEnum.GetAllSubCategoriesResponseMessage),
      getName(MessageEnum.NewServiceCreatedMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      modal: null,
      // Customizable Area Start
      token: '',
      corpstageCoins: [],
      userRole: "",
      loadingCorpstageCoins: false,
      // loadingPosts: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      console.log(apiRequestCallId, "apiRequestCallIdapiRequestCallIdapiRequestCallId");

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      console.log(responseJson, "responseJsonresponseJsonresponseJsonresponseJson");


      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      console.log({ events: responseJson })
      if (responseJson && responseJson.errors) {
        // this.setState({ refresh: false });
        this.parseApiErrorResponse(responseJson.errors);
        this.parseApiCatchErrorResponse(responseJson.errors);
      } else if (apiRequestCallId === this.corpstageCoinsListRequestId || apiRequestCallId === this.apiPaginateCallId) {
        console.log("response from service ==> ", responseJson)
        if (Array.isArray(responseJson?.packages)) {
          const corpstageCoins = responseJson?.packages.map((e: any) => e)
          this.setState({ corpstageCoins, pagination: responseJson?.meta?.pagination })
        }
        this.setState({ loadingCorpstageCoins: false })
      } else if (apiRequestCallId === this.purchaseCallId) {
        if (responseJson.url) {
          window.location.href = responseJson?.url
        }
      }
      // else if (apiRequestCallId===this.routeCallId){
      //   if(responseJson){
      //     console.log(responseJson, "suuccess message:::")
      //   }
      // }

      // else if (apiRequestCallId === this.serviceFilterCallId) {
      //   if (Array.isArray(responseJson?.packages)) {
      //     const corpstageCoins = [...this.state.corpstageCoins, ...responseJson?.packages.map((e: any) => e)]
      //     this.setState({ corpstageCoins, pagination: responseJson?.meta?.pagination })
      //   }
      //   this.setState({ loadingCorpstageCoins: false })
      // } else if (apiRequestCallId === this.userRoleCallId) {
      //   this.setState({
      //     userRole: responseJson.user_role || ''
      //   })
      // } else if (this.apiFavCallIds.includes(apiRequestCallId)) {
      //   const postId = this.favCallIdToPostId[apiRequestCallId]
      //   this.apiFavCallIds = this.apiFavCallIds.filter((x: any) => x !== apiRequestCallId)
      //   delete this.favCallIdToPostId[apiRequestCallId]
      //   if (responseJson.data) {
      //     const fav = { ...responseJson.data, ...responseJson.data?.attributes }
      //     const newCorpstageCoins = this.state.corpstageCoins.map((post: any) => {
      //       if (String(post.id) === postId)
      //         return { ...post, favourite_id: [...(post.favourite_id || []), fav] }
      //       return { ...post }
      //     })
      //     this.setState({ corpstageCoins: newCorpstageCoins })
      //   }
      // }
    }
    parseServiceCreatedMessage(message, this.handleServiceCreatedMessage)
    // Customizable Area End

  }

  // Customizable Area Start
  async componentDidMount() {
    console.log("yes:::")
    let param=this.props.navigation.history.location.pathname
    const authToken = await getStorageData('authToken');
    console.log(param,this.props.navigation.history, "param and history:::")

    this.setState({token: authToken}, () => {
      this.getUserRole()
      this.getAllCorpstageCoins()
    })
  //  if(param==="/PurchaseCoins/PaymentSuccessful/coins_success"){
  //     console.log("hits route api:::")
  //     this.handleRoute()
  //   } 
  }

  getAllCorpstageCoins = async() => {
    console.log("getAllEvent ")
    const token= await getStorageData('authToken') || ''
    this.setState({ loadingCorpstageCoins: true })
    let url = configJSON.corpstageCoins
    // const myUrl = this.getMyUrl()
    // if(myUrl)
    //   url = myUrl
    this.corpstageCoinsListRequestId = sendAPIRequest(
      url, {
      method: 'GET',
      headers: { 'token': token},
    }
    )
  }

  handlePurchase = async(e: any) => {
    console.log(e.currentTarget.dataset.id, "value in purchase click>>")
    // this.setState({ modal: 'purchase' })
    this.purchaseCallId = sendAPIRequest(`${configJSON.purchaseLinkEndPoint}?coin_package_id=${e.currentTarget.dataset.id}`, {
      method: 'POST',
      headers: {
        token: this.state.token,
        'content-type': 'appliction/json'
      },
      body: {}
    })
    console.log(this.purchaseCallId, "purchase call id ::::::::;")
  }

  hideModal = () => { this.setState({ modal: null }) }

  getUserRole = async() => {
    const token= await getStorageData('authToken') || ''
    const url = configJSON.getUserRoleEndpoint
    this.userRoleCallId = sendAPIRequest(url, { method: 'GET', headers: { token } })
  }

  handleServiceCreatedMessage = (coin: any) => {
    const corpstageCoins = [coin, ...this.state.corpstageCoins]
    console.log(corpstageCoins, "coprs coins::")
    this.setState({ corpstageCoins })
  }

  // handleRoute=()=>{
  //   console.log("test:::")
  //   const {token}=this.state
  //   this.routeCallId=sendAPIRequest(configJSON.successMessageEndpoint,{
  //     method:'POST',
  //     body: {},
  //     headers:{token,
  //       'content-type': 'appliction/json'
  //     },
  //   })
  //   console.log("ttytytyytt")
  //   console.log(this.routeCallId, "callll idd:::")
  // }

  // Customizable Area End
}


import React from 'react';
import { BlockComponent } from '../../../framework/src/BlockComponent';
// Customizable Area Start
import { getStorageData } from '../../../framework/src/Utilities';
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    filterMenuPosition: {
        top: number,
        left: number,
    } | undefined;

    token: string;
    filterQuery: any;

    filterByMe: boolean;
    filterAssesmentName: any;

    queryStr: string;
    serveyTemplates: any;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}


export default class FillSurveysController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    filterRef?: any;
    tokendata:any;
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.state = {
            // Customizable Area Start
            token: '',
            filterMenuPosition: undefined,
            filterQuery: '',
            filterByMe: false,
            filterAssesmentName: '',
            queryStr: '',
            serveyTemplates: [],
            // Customizable Area End
        };


        // Customizable Area Start
        this.filterRef = React.createRef()
        // Customizable Area End

    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        const authToken = await getStorageData("authToken");
        this.tokendata = authToken;
      }
    
    onFilterQueryChange = (filterQuery: string) => {
        this.setState({ filterQuery })
    }

    getBlankFilters = () => {
        return {
            filterAssesmentName: ''
        }
    }

    clearFilters = (e?: React.MouseEvent) => {
        console.log("checkll:::")
        e && e.stopPropagation()
        this.setState({

            filterAssesmentName: '',
            queryStr: '',
        })

    }
    getFilterQueryParams = () => {
        const query = new URLSearchParams()

        if (this.state.filterAssesmentName) query.append('status', this.state.filterAssesmentName)

        return query
    }
    filterPosts = () => {
        const queryStr = this.getFilterQueryParams().toString()
        console.log(queryStr, "query str :::")
        // if (queryStr === this.state.queryStr) return
        this.setState({ queryStr, serveyTemplates: [] })
    }
    filterAssessment = () => {
        let filterText = "";

        return filterText
    }

    handleFilterMenuOpen = (e: React.MouseEvent) => {
        if (!this.filterRef) return;
        const box = this.filterRef.current.getBoundingClientRect();
        this.setState({
            filterMenuPosition: {
                top: box.bottom,
                left: box.left + 20
            }
        })
    }


    handlefilterAssesmentName = (status: any) => {
        const filters = this.getBlankFilters()
        this.setState({ filterAssesmentName: status, filterMenuPosition: undefined }, () => this.filterPosts())
    }
    toggleFilterByMe = () => {
        this.clearFilters()

         
    }
    handleFilterMenuClose = () => {
        this.setState({ filterMenuPosition: undefined })
        // this.filterPosts()
    }

   
    // Customizable Area End
}
import React from "react";
// Customizable Area Start
import TopNavBar from "../../../components/src/TopNavbar";
import { Box, Grid, Typography } from '@material-ui/core';
import ArrowForwardOutlined from '@material-ui/icons/ArrowForwardOutlined';
import { styled } from '@material-ui/styles'
import LeftMenuWeb from "../../../components/src/LeftMenuWeb";
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import OverlayLoader from '../../../components/src/Loader.web';

import EditIcon from '@material-ui/icons/Edit';

import CorporatepermissionController, {
    Props,
} from "./CorporatepermissionController";

// Customizable Area End
export default class Corporatepermission extends CorporatepermissionController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
       
        // Customizable Area End
        return (
            // Customizable Area Start
            <MainBox>
                <OverlayLoader 
                    loading={this.state.loading} 
                />
                <Box>
                    <TopNavBar navigation={this.props.navigation} id={""} />
                </Box>

                <OuterGrid container spacing={3}>
                    <LeftGrid item xs={12} sm={12} md={3} lg={3}>
                        <Box >
                            <LeftMenuWeb navigation={this.props.navigation} id={""} />
                        </Box>
                    </LeftGrid>
                    <MiddleGrid item xs={12} sm={12} md={6} lg={6} >
                        <SurveyCardWrapper>
                            <HeadWrapper>
                                <Head>
                                    ESG Journey - {this.state.userName}
                                </Head>
                            </HeadWrapper>
                            <Cards>
                                {this.state?.requestors?.map((data:any)=> {
                                    return(
                                <SurveyCard id="cardbtn" onClick={()=>this.goToPage(data.attributes)}>
                                    <div>
                                        <ArroWrapper>
                                            <ArrowForwardOutlined color="disabled" />
                                        </ArroWrapper>
                                        <LogoWrapper>
                                            <img src={data.attributes.card_banner} />
                                        </LogoWrapper>
                                        <AssesmentHeading>
                                            {data.attributes.ability_card}
                                        </AssesmentHeading>
                                    </div>
                                    <div style={{display:'flex', flexDirection:'row', justifyContent:'end',gap:'2px'}}>
                                        <ImportContactsIcon color="disabled"/>
                                        {data.attributes.ability_type == "write" && <EditIcon color="disabled"/>}
                                    </div>
                                </SurveyCard>)
                                    })}
                            </Cards>
                        </SurveyCardWrapper>
                    </MiddleGrid>
                </OuterGrid>

            </MainBox>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const MainBox = styled(Box)({
    backgroundColor: "#edebeb",
    display: "flex",
    flexWrap: 'wrap',
    flexDirection: "column",
    paddingBottom: "20px",
    overflowX: "hidden",
})

const OuterGrid = styled(Grid)({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
})

const LeftGrid = styled(Grid)({
    maxWidth: '300px',
    '@media screen and (max-width: 985px)': {
        maxWidth: '200px',
    },
    '@media screen and (max-width: 768px)': {
        display: 'none',
        visibility: 'hidden',
    },
})

const MiddleGrid = styled(Grid)({
    width: 'calc(100vw - 300px)',
    maxWidth: 'unset',
    flexBasis: 'unset',
    justifyContent: 'center',
    '@media screen and (max-width: 768px)': {
        width: '100%'
    }
})

const SurveyCardWrapper = styled(Box)({
    width: '95%',
    height: 'auto',
    marginTop: '30px',
    marginRight: '20px',
    padding: '20px',
})

const Cards = styled('div')({
    display: 'flex',
    flexWrap: 'wrap',
    gap: '20px'
})

const HeadWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
})

const ArroWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end',
    color: 'gray',
    marginRight: '10px',
    fontWeight: 100
})

const LogoWrapper = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    '& img': {
        width: '60px',
    }
})

const SurveyCard = styled(Box)({
    width: '300px',
    height: '200px',
    padding: ' 20px 10px',
    marginBottom: '20px',
    boxShadow: '1px 2px 20px #CABFC1',
    borderRadius: '10px',
    background: '#fff',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column'
})

const AssesmentHeading = styled(Typography)({
    fontWeight: 'bold',
    marginBottom: '15px',
    marginTop: '30px',
    textAlign: 'center'
})

const Head = styled('div')({
    display: 'flex',
    alignItems: 'center',
    color: '#000',
    margin: '10px 0px 30px 12px',
    fontSize: '1rem',
    fontWeight: 600,
    '@media screen and (max-width: 768px)': {
        margin: '20px 0px 10px 12px',
    },
})


// Customizable Area End

import React from "react";
// Customizable Area Start
import UpdatedReportsController, {
	Props,
} from "./UpdatedReportsController.web";
import { styled } from '@material-ui/styles'
import {
	Grid,
	Box,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableCell,
	TableRow,
	Paper,
	Avatar,
	Link,
    ListItem,
    ListItemAvatar,
    ListItemText,
} from '@material-ui/core';
import TopNavBar from "../../../components/src/TopNavbar";
import LeftMenuWeb from "../../../components/src/LeftMenuWeb";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Pagination from '@material-ui/lab/Pagination';
import { xlsxIcon } from './assets';
import OverlayLoader from '../../../components/src/Loader.web';
import PlusMorePopup from '../../../components/src/PlusMorePopup.web';
import { Link as Links } from 'react-router-dom';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
// Customizable Area End
export default class ProjectReport extends UpdatedReportsController {
	constructor(props: Props) {
		super(props);
		// Customizable Area Start
		// Customizable Area End
	}

    // Customizable Area Start
	showDownloadButton = () => {
		const ImportButtonBase = (props: any) => {
			const icon = xlsxIcon
			const clickHandler = props.onClick || (() => { });
			const label = "Download"
			return <ImportButton onClick={clickHandler}>
				<img src={icon} width="25px" />{label}
			</ImportButton>
		}

		const DownloadButton = () => {
			return <ImportButtonBase />
		}

		if(this.state.projectUrl!==undefined  && this.state.projectUrl!==null && this.state.projectUrl?.length>0){
			return(<Link data-test-id="download-project-report-url" href={this.state.projectUrl}>
				<DownloadButton />
			</Link>)
		} 
		else {
			return(<div data-test-id="download-project-report" onClick={() => this.showPopup()}>
				<DownloadButton />
			</div>)
		}
	}

	showPlusMorePopover = (listItems:any,data:any,heading:any) => {
		return (
			<PlusMorePopup 
				isRedirect={true}
				style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', whiteSpace: 'nowrap', }} 
				heading={heading}
				listItems={listItems}
			>
				<AvatarGroup style={{marginRight: '5px',}}>
				{data?.length>0 && data?.slice(0, 3)?.map((data: any) => {
						return (
							<Avatar style={{ width: "34px", height: '34px', objectFit: 'cover', border: 'solid 1px #196519', borderRadius: "50%", verticalAlign: "middle" }} src={data?.data?.attributes?.profile_pic} alt="profile picture" />
						)
					})}
				</AvatarGroup>
				{
					data?.length > 3 && <>
						{`+${data?.length - 3} more`}
					</>
				}
			</PlusMorePopup>
		)
	}
    // Customizable Area End

	render() {

      	// Customizable Area Start
		const { navigation } = this.props;		
		// Customizable Area End
		return (
			// Customizable Area Start
			<MainBox>
				<Box>
					<TopNavBar navigation={this.props.navigation} id={""} />
				</Box>

				<OuterGrid container spacing={3}>
					<LeftGrid item xs={12} sm={12} md={3} lg={3}>
						<Box >
							<LeftMenuWeb /*closeModal={this.closeModal}*/ navigation={this.props.navigation} id={""} />
						</Box>
					</LeftGrid>
					<MiddleGrid item xs={12} sm={12} md={6} lg={6} >
						<div style={{ justifyContent: "space-between", display: "flex", alignItems: "center" }}>
							<div style={{ display: "flex" }} >
								<div data-test-id="arrow-back" onClick={navigation.goBack} style={{ cursor: "pointer", margin: "40px 0px 15px 12px", }}  >
									<ArrowBackIosIcon />
								</div>
								<CoinsHead>PROJECTS REPORT</CoinsHead>
							</div>
							{this.showDownloadButton()}
						</div>
						<div style={{ marginTop: '20px', alignItems: 'center' }}>
							<TableContainer component={Paper}>
								<Table>
									<TableHead>
										<TableRowBox>
											<TableCell style={{ fontWeight: 'bold', color: 'black' }}>SL.no</TableCell>
											<TableCell style={{ fontWeight: 'bold', color: 'black' }}>Project Name</TableCell>
											<TableCell style={{ fontWeight: 'bold', color: 'black' }}>Project ID</TableCell>
											<TableCell style={{ fontWeight: 'bold', color: 'black' }}>Industry</TableCell>
											<TableCell style={{ fontWeight: 'bold', color: 'black' }}>Organization Name</TableCell>
											<TableCell style={{ fontWeight: 'bold', color: 'black' }}>Organization Objective ID</TableCell>
											<TableCell style={{ fontWeight: 'bold', color: 'black' }}>Approved By</TableCell>
											<TableCell style={{ fontWeight: 'bold', color: 'black' }}>Project Sponsors</TableCell>
											<TableCell style={{ fontWeight: 'bold', color: 'black' }}>Location</TableCell>
											<TableCell style={{ fontWeight: 'bold', color: 'black' }}>Project Leader</TableCell>
											<TableCell style={{ fontWeight: 'bold', color: 'black' }}>Project Moderator</TableCell>
											<TableCell style={{ fontWeight: 'bold', color: 'black' }}>Project Duration</TableCell>
										</TableRowBox>
									</TableHead>
									<TableBody>
										{this.state.projectsList?.length>0 ? this.state.projectsList?.map((value: any, index: any) => {
											const { attributes } = value
											const approved_by_list = attributes?.approved_by?.length>0 ? attributes?.approved_by?.map((user: any) => <Links to={`/user/${user?.data?.id}`}><ListItemUser key={user?.data?.id} user={user} /></Links>) : []
											const project_sponsor_list = attributes?.project_sponsors?.length>0 ? attributes?.project_sponsors?.map((user: any) => <Links to={`/user/${user?.data?.id}`}><ListItemUser key={user?.data?.id} user={user} /></Links>) : []
											const project_leader_list = attributes?.project_leader?.length>0 ? attributes?.project_leader?.map((user: any) => <Links to={`/user/${user?.data?.id}`}><ListItemUser key={user?.data?.id} user={user} /></Links>) : []
											const project_moderator_list = attributes?.project_moderators?.length>0 ? attributes?.project_moderators?.map((user: any) => <Links to={`/user/${user?.data?.id}`}><ListItemUser key={user?.data?.id} user={user} /></Links>) : []

											return (
												<TableBodyBox>
													<TableCell>{10 * (this.state.currentPageOfProjects - 1) + (index + 1)}</TableCell>
													<TableCell>{attributes?.project_name}</TableCell>
													<TableCell >{attributes?.project_id}</TableCell>
													<TableCell>{attributes?.industry}</TableCell>
													<TableCell>{attributes?.organization_name}</TableCell>
													<TableCell>{attributes?.organization_objective_id}</TableCell>
													<TableCell>
														{this.showPlusMorePopover(approved_by_list,attributes?.approved_by,'Project Approvers')}
													</TableCell>
													<TableCell>
														{this.showPlusMorePopover(project_sponsor_list,attributes?.project_sponsors,'Project Sponsors')}
													</TableCell>
													<TableCell>{attributes?.locations?.toString()}</TableCell>
													<TableCell>
														{this.showPlusMorePopover(project_leader_list,attributes?.project_leader,'Project Leaders')}
													</TableCell>
													<TableCell>
														{this.showPlusMorePopover(project_moderator_list,attributes?.project_moderators,'Project Moderators')}
													</TableCell>
													<TableCell>{attributes?.project_term}</TableCell>
												</TableBodyBox>
											)
										}) : <TableCell style={{textAlign:"center"}} colSpan={14}>No Data Found</TableCell>}
									</TableBody>
								</Table>
							</TableContainer>
							<Pagin>
								{window.screen.width > 950 ?
									<Pagination disabled={this.state.totalPageOfProjects <= 1} defaultPage={1} color="primary" variant="outlined" count={this.state.totalPageOfProjects} page={this.state.currentPageOfProjects} onChange={this.handlePageChangeOfProjects} />
									:
									<Pagination disabled={this.state.totalPageOfProjects <= 1} defaultPage={1} color="primary" size="small" count={this.state.totalPageOfProjects} page={this.state.currentPageOfProjects} onChange={this.handlePageChangeOfProjects} />
								}
							</Pagin>
							<OverlayLoader loading={this.state.overlayLoaderForReport} />
						</div>
					</MiddleGrid>
				</OuterGrid>
			</MainBox>
			// Customizable Area End
		)
	}
}

// Customizable Area Start
const getEmail = (user: any) => `${user?.data?.attributes?.email}`

const ListItemUser = ({ user }: { user: any }) => <ListItem >
    <ListItemAvatar  >
        <Avatar src={user?.data?.attributes?.profile_pic} />
    </ListItemAvatar>
    <ListItemText primary={getEmail(user)} />
</ListItem>

const MainBox = styled(Box)({
	backgroundColor: "#edebeb",
	display: "flex",
	flexWrap: 'wrap',
	flexDirection: "column",
	paddingBottom: "20px",
	overflowX: "hidden",
})

const OuterGrid = styled(Grid)({
	display: 'flex',
	flexWrap: 'wrap',
	justifyContent: 'space-between',
})

const LeftGrid = styled(Grid)({
	maxWidth: '300px',
	'@media screen and (max-width: 985px)': {
		maxWidth: '200px',
	},
	'@media screen and (max-width: 768px)': {
		display: 'none',
		visibility: 'hidden',
	},
})

const MiddleGrid = styled(Grid)({
	width: 'calc(100vw - 300px)',
	maxWidth: 'unset',
	flexBasis: 'unset',
	paddingRight: '30px !important',
	justifyContent: 'center',
	'@media screen and (max-width: 768px)': {
		width: '100%'
	}
})

const TableRowBox = styled(TableRow)({
	'& th': {
		borderRight: '1px solid rgba(224, 224, 224, 1)',
		textAlign: 'center',
	},
	'& th:first-child': {
		minWidth: '45px',
	},
	'& th:nth-child(2)': {
		minWidth: '120px',
	},
	'& th:nth-child(3)': {
		minWidth: '110px',
	},
	'& th:nth-child(4)': {
		minWidth: '110px',
	},
	'& th:nth-child(5)': {
		minWidth: '90px',
	},
	'& th:nth-child(6)': {
		minWidth: '110px',
	},
})

const TableBodyBox = styled(TableRow)({
	'& td': {
		borderRight: '1px solid rgba(224, 224, 224, 1)',
		textAlign: 'center',
	},
})

const Pagin = styled('div')({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	padding: '10px',
	'@media screen and (max-width:350px)': {
		display: 'initial',
		padding: '10px 1px',
	},
	'@media screen and (min-width:768px) and (max-width:850px)': {
		display: 'initial',
		padding: '10px 1px',
	},
	'& > MuiPaginationItem-icon': {
		color: 'green',
	},

	ul: {
		"& > .MuiPaginationItem-root": {
			color: "green"
		}
	}
})

const CoinsHead = styled("div")({
	margin: "37px 0px 15px 12px",
	fontSize: "20px",
	fontFamily: "HelveticaNeue",
	fontWeight: 600,
	"@media screen and (max-width: 768px)": {
		margin: "20px 0px 10px 12px",
	},
});


const ImportButton = styled('div')({
	cursor: 'pointer',
	marginTop: "16px",
	color: '#1F7244',
	border: '1px solid #1F7244',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	gap: '10px',
	padding: '5px 12px',
	borderRadius: '28px',
	fontSize: '13px',
})
// Customizable Area End

import React from "react";

// Customizable Area Start
import {
    Button,
    MenuItem,
    Typography,
    Checkbox, TextField
} from '@material-ui/core'
import { styled } from '@material-ui/styles'
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { BitmapIcon0, BitmapIcon1, BitmapIcon2, BitmapIcon3, BitmapIcon4 } from "./assets";
import { Field, FieldArray, Formik } from 'formik'
import FormModal, { InputRow, MInput, MSelect, MTextArea } from '../../../components/src/FormModal.web'
import UserContext from "../../../components/src/UserProvider";
import Flex from '../../../components/src/Flex.web'
import OverlayLoader from '../../../components/src/Loader.web'
import AddSurveyQuestionsController, { Props } from "./AddSurveyQuestionsController";
import UploadButton from "../../../components/src/UploadButtonnew.web";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Scoring from "../../Scoring/src/Scoring.web";
// Customizable Area End
export default class AddSurveyQuestions extends AddSurveyQuestionsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    selectedItem: any;
    getuserDetails: any;
    handleDeleteOptionsClick(index: any, values: any, options: any, arrayHelpers: any) {
        if (!values.isEdit) {
            arrayHelpers.remove(index);
        } else {
            arrayHelpers.remove(index);
            const idToRemove = options[index]?.id
            options[index]?.id && this.setState(prevState => ({
                remove_option_ids: [...prevState.remove_option_ids, options[index]?.id]
            }));
            const updatedOptions = this.state.editQuestionData.options.filter((option: any) => option.id !== idToRemove);

            this.setState({
                editQuestionData: {
                    ...this.state.editQuestionData,
                    options: updatedOptions,
                },
            });
        }
    }
    getTemplateOption(options: any, index: number, defaultValue: string) {
        if (options && options[index - 1] && options[index - 1].title) {
            return options[index - 1].title;
        } else {
            return defaultValue;
        }
    }
    handleAddOptionsClick(arrayHelpers: any) {
        arrayHelpers.push({ title: '' })
    }
    OptionsRenderer = (optionsData:any, responseTypeId:number, handleChange:any, values:any ) => {
        let responseType = this.getResponseTypeRender(this.state.isEdit, this.state.selectedParent, responseTypeId);
        let options = this.getOptions(this.state.isEdit, this.state.selectedParent, optionsData);
        let selectedOption = this.getSelectedOption(this.state.isEdit,this.state.editQuestionData);
        let ParsedSelectedOption = this.getParsedSelectedOption(this.state.isEdit, selectedOption);
        let color = this.getColor(this.state.isEdit);
        switch (responseType) {
            case 1:
            case "multiple_choice":
                return (
                    <>
                    <ParentOptionLable>Parent Response*</ParentOptionLable>
                    <RadioGroup aria-label="quiz" name="parentOptionId" value={Number(values?.parentOptionId)} onChange={handleChange}>
                        {Array.isArray(options) && options.map((opt: any, index: number) => (
                            <FormControlLabel
                                data-testid="optionRadioLabel"
                                id="optionRadioLabel"
                                key={opt.id}
                                value={opt.id}
                                control={<Radio style={{ color, marginRight: "10px" }} />}
                                label={opt.title}
                                checked={this.state.isEdit ? ParsedSelectedOption.id === opt.id : Number(values?.parentOptionId) == opt.id}
                                disabled={this.state.isEdit}
                            />
                        ))}
                    </RadioGroup>
                    <Typography style={{ color: 'red', fontSize: '0.8rem' }}>{this.state.setError.parent_expected_answer ? this.state.setError.parent_expected_answer : ""}</Typography>
                    </>
                );
            case 2:
            case "summation":
                const mergedOptions = this.mergeOptions(this.state.isEdit, this.state.SummationParentDefaultOption, ParsedSelectedOption);
                return (
                    <div>
                        <ParentOptionLable>Parent Response*</ParentOptionLable>
                        {mergedOptions.map((opt: any, index: any) => {
                            return (
                                <SummationWraper key={opt.id}>
                                    <Typography style={{ alignSelf: 'center', marginRight: '10px' }}> {String.fromCharCode(65 + index)}{"."}</Typography>
                                    <Checkbox
                                        name={`selectedSumationOptions[${index}].id`}
                                        checked={opt.isSelected}
                                        value={opt.id}
                                        style={{ color, fontSize: 10 }}
                                        onChange={(e) => {
                                            handleChange(e);
                                            const isChecked = e.target.checked;
                                            const updatedOptions = [...this.state.SummationParentDefaultOption];
                                            updatedOptions[index].isSelected = isChecked;
                                            this.setState({ SummationParentDefaultOption: updatedOptions });
                                        }}
                                        disabled={this.state.isEdit}
                                        data-testid="selectedSumationOptionsCheckBox"
                                    />
                                    <span style={{ minWidth: "150px" }}>{opt.title}</span>
                                    <MInputBase
                                        data-testid="SumationOptionsvalue"
                                        variant="outlined"
                                        name={`selectedSumationOptions[${index}].value`}
                                        value={opt.isSelected ? opt.value : ''}
                                        disabled={!opt.isSelected || this.state.isEdit}
                                        onChange={(e) => {
                                            const inputValue = e.target.value;
                                            if (/^\d*$/.test(inputValue)) {
                                                handleChange(e);
                                                const updatedOptions = [...this.state.SummationParentDefaultOption];
                                                updatedOptions[index].value = Number(inputValue);
                                                this.setState({ SummationParentDefaultOption: updatedOptions });
                                            }
                                        }}
                                    />
                                </SummationWraper>
                            );
                        })}
                    <Typography style={{ color: 'red', fontSize: '0.8rem' }}>{this.state.setError.parent_expected_answer ? this.state.setError.parent_expected_answer : ""}</Typography>
                    </div>
                );
            case undefined:
            case 4:
                return (
                    <div>
                        <ParentOptionLable>Parent Response*</ParentOptionLable>
                        {options?.map((opt: any, index: number) => {
                            let isChecked = this.determineCheckboxChecked(values,opt, this.state.isEdit, ParsedSelectedOption);
                            return (
                                <OptionWraper key={opt.id}>
                                    <Typography style={{ alignSelf: 'center', marginRight: '10px' }}> {String.fromCharCode(65 + index)}{"."}</Typography>
                                    <Checkbox
                                        name={`selectedOptions[${index}]`}
                                        value={opt.id}
                                        style={{ color, fontSize: 10 }}
                                        onChange={handleChange}
                                        data-testid="scoringOptionCheckbox"
                                        checked={isChecked}
                                        disabled={this.state.isEdit}
                                    />
                                    <MInput
                                        data-test-id="inputBase"
                                        variant="outlined"
                                        name={`score_options`}
                                        value={opt.title}
                                        disabled={true}
                                    />
                                </OptionWraper>
                            )
                        })}
                    <Typography style={{ color: 'red', fontSize: '0.8rem' }}>{this.state.setError.parent_expected_answer ? this.state.setError.parent_expected_answer : ""}</Typography>
                    </div>
                );
        }
        return null;
    }
    CreateQuestionModal(initialValuesData: any, headerTitle: string, submitLabel: string) {
        const questionError = this.state?.setError?.question ?? ""
        const templateOptions = ['template_option1', 'template_option2', 'template_option4', 'template_option5', 'template_option3'];
        let radioColor = this.getRadioColor(this.state.isEdit,this.state.isChildCreate)
        let isDisable = this.isItDisabled(this.state.isEdit,this.state.isChildCreate)
        let parentIdErrorMsg = this.getParentError(this.state.setError,"parent_id")
        let uniqueQuestionIdErrorMsg = this.getParentError(this.state.setError,"unique_question_id")
        let questiontypeErrorMsg = this.getParentError(this.state.setError,"questiontype")
        let inParent = this.checkSlectedParentAndEdit(this.state)
        let isHaveParent = this.checkParentQuestionList(this.state.avaliableParentQuestions)
        const defaultStyle = { color: 'red', fontSize: '0.8rem' };
        return <Formik enableReinitialize initialValues={initialValuesData} onSubmit={this.handleSubmit}>
            {({ values, errors, setFieldValue, handleChange }: any) => (
                <ModalWrap>
                    <FormModal modalStyle={{ maxWidth: '700px' }} isDirty={true} headerTitle={<b>{headerTitle}</b>} submitLabel={submitLabel} onClose={this.hideModal} >
                        <InputRow label="Response Type*" input={
                            <MSelect name="response_type" disabled={this.state?.isEdit} placeholder="Select" value={values?.response_type} onSelectInput={this.onSelectInput}>
                                <MenuItem disabled value={-1}>Select</MenuItem>
                                <MenuItem value={0}>Subjective</MenuItem>
                                <MenuItem value={1}>Multiple Choice</MenuItem>
                                <MenuItem value={2}>Summation</MenuItem>
                                <MenuItem value={3}>Rating</MenuItem>
                                <MenuItem value={4}>Scoring</MenuItem>
                            </MSelect>
                        } />
                        <InputRow label="Questions Unique ID*" input={<>
                            <MInput name="unique_question_id"  data-testid="unique_question_id" value={values.unique_question_id} placeholder="" />
                            <Typography style={{ color: 'red', fontSize: '0.8rem' }}>{uniqueQuestionIdErrorMsg}</Typography>
                        </>
                        } />
                        <InputRow label="Question*" input={<>
                            <MTextArea name="question" placeholder="" data-testid="writeQue" />
                            <Typography style={{ color: 'red', fontSize: '0.8rem' }}>{questionError}</Typography>
                        </>
                        } />
                        {values?.response_type != 0 && <Typography>Options</Typography>}


                        {values.response_type == 1 && <FieldArray
                            name="options_attributes"
                            render={(arrayHelpers: any) => (
                                <div>

                                    {values.options_attributes.map((option: any, index: number) => (
                                        <WrapperDiv key={index} style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                            <Field
                                                name={`options_attributes[${index}]`}
                                                render={({ field, form: { touched, errors } }: any) => {
                                                    return <InputRow label="" input={
                                                        <div style={{ display: 'flex', flexDirection: 'column', width: "100%", marginRight: "5px" }}>
                                                            <div style={{ display: 'flex', flexDirection: 'row', marginRight: '5px', width: '100%' }}>
                                                                <Typography style={{ alignSelf: 'center', marginRight: '10px' }}> {this.getIndexLabel(index)}</Typography>
                                                                <MInput name={`options_attributes[${index}].title`} placeholder="" style={{ width: "100%", marginRight: "5px" }} />
                                                            </div>
                                                            <Typography style={{ color: 'red', fontSize: '0.8rem', marginLeft: '25px' }}>{this.state.setError?.[`options_attributes${index}`]}</Typography>
                                                        </div>
                                                    } />
                                                }} />
                                            <CancelWraper
                                                type="button"
                                                className="delete-option-btn"
                                                data-test-id='delete-option-btn'
                                                disabled={values?.options_attributes.length < 3}
                                                onClick={() => this.handleDeleteOptionsClick(index, this.state, values?.options_attributes, arrayHelpers)} // remove a friend from the list
                                            >
                                                <CancelIcon style={{ color: '#eee', cursor: 'pointer', fontSize: '2.5rem' }} />
                                            </CancelWraper>
                                        </WrapperDiv>
                                    ))
                                    }
                                    <AddMoreWraper>
                                        <AddBtn type="button" data-test-id='AddBtn' className="AddBtn" onClick={() => this.handleAddOptionsClick(arrayHelpers)}>
                                            <AddCircleIcon />
                                            Add more options
                                        </AddBtn>
                                    </AddMoreWraper>


                                </div>
                            )}
                        />}

                        {values.response_type == 3 && <div>
                            <EmoteFlex gap={10}>
                                <Flex gap={10} align="center" style={{ flexDirection: 'column' }}>
                                    <>
                                        <BtnImg src={BitmapIcon0} />
                                        <MInput name="template_option1" placeholder="" />
                                    </>
                                </Flex>
                                <Flex gap={10} align="center" style={{ flexDirection: 'column' }}>
                                    <>
                                        <BtnImg src={BitmapIcon1} />
                                        <MInput name="template_option2" placeholder="" />
                                    </>
                                </Flex>
                                <Flex gap={10} align="center" style={{ flexDirection: 'column' }}>
                                    <>
                                        <BtnImg src={BitmapIcon2} />
                                        <MInput name="template_option3" placeholder="Type here..." />
                                    </>
                                </Flex>
                                <Flex gap={10} align="center" style={{ flexDirection: 'column' }}>
                                    <>
                                        <BtnImg src={BitmapIcon3} />
                                        <MInput name="template_option4" placeholder="" />
                                    </>
                                </Flex>
                                <Flex gap={10} align="center" style={{ flexDirection: 'column' }}>
                                    <>
                                        <BtnImg src={BitmapIcon4} />
                                        <MInput name="template_option5" placeholder="" />
                                    </>
                                </Flex>
                            </EmoteFlex>
                            {templateOptions.map((option, index) => (
                                <Typography key={index} style={defaultStyle}>
                                    {this.state.setError?.[option] || ""}
                                </Typography>
                            ))}
                        </div>}

                        {values.response_type == 2 && <FieldArray
                            name="summation_options"
                            render={(arrayHelpers: any) => (
                                <div>

                                    {values.summation_options.map((option: any, index: number) => (
                                        <WrapperDiv key={index} style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                            <Field
                                                name={`summation_options[${index}]`}
                                                render={({ field, form: { touched, errors } }: any) => {
                                                    return <InputRow label="" input={
                                                        <div style={{ display: 'flex', flexDirection: 'column', }}>
                                                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                                <Typography style={{ alignSelf: 'center', marginRight: '10px' }}> {this.getIndexLabel(index)}</Typography>
                                                                <MInput name={`summation_options[${index}].title`} placeholder="" style={{ marginRight: '10px' }} />
                                                                <MInput name={`summation_options[${index}].answer`} placeholder="" disabled />
                                                            </div>
                                                            <Typography style={{ color: 'red', fontSize: '0.8rem', marginLeft: '25px' }}>{this.state.setError?.[`summation_title${index}`] || ""}</Typography>
                                                        </div>
                                                    } />
                                                }} />
                                            <button
                                                style={{ background: '#fff', border: "none" }}
                                                disabled={values?.summation_options.length < 3}
                                                type="button"
                                                onClick={() => this.handleDeleteOptionsClick(index, this.state, values?.summation_options, arrayHelpers)} // remove a friend from the list
                                            >
                                                <CancelIcon style={{ color: '#eee', cursor: 'pointer', fontSize: '2.5rem' }} />
                                            </button>
                                        </WrapperDiv>
                                    ))
                                    }
                                    <AddMoreWraper>
                                        <AddBtn type="button" onClick={() => arrayHelpers.push({ title: '', answer: '' })}>
                                            <AddCircleIcon />
                                            Add more options
                                        </AddBtn>
                                    </AddMoreWraper>


                                </div>
                            )}
                        />}

                        {values.response_type == 4 && <Scoring id={this.props.id} navigation={this.props.navigation} scoreValue={values} setError={this.state?.setError} isEdit={this.state?.isEdit} scoringQuestionFn={this.scoringQuestionBody} />}

                        {values.response_type == 2 &&
                            <div>
                                <Typography style={{ display: 'flex', justifyContent: 'flex-end' }}>Total: 0</Typography>
                            </div>}
                        <div>
                            <Field
                                render={({ field }: any) => <><RadioGroup {...field} aria-label="quiz"
                                    name="questiontype"
                                    value={Number(values?.questiontype)}
                                    style={{ flexDirection: 'row' }}
                                >
                                    <FormControlLabel value={0} control={<Radio style={{ color: 'Green' }} />} label="Mandatory" />
                                    <FormControlLabel value={1} control={<Radio style={{ color: 'Green' }} />} label="Optional" />
                                </RadioGroup>
                                    <Typography style={{ color: 'red', fontSize: '0.8rem' }}>{questiontypeErrorMsg}</Typography>
                                </>
                                }
                            />
                            {!this.hasDueDiligenceDetails && <Field
                                render={({ field }: any) => <>
                                    <RadioGroup
                                        {...field}
                                        aria-label="question_condition_type"
                                        name="question_condition_type"
                                        style={{ flexDirection: 'row' }}
                                        value={values.question_condition_type}
                                        onChange={(event) => {
                                            handleChange(event)
                                            this.handleQuestionConditionType(event.target.value, setFieldValue)
                                        }}
                                        data-testid="question-condition-type"
                                    >
                                        <FormControlLabel disabled={isDisable} data-testid="question-non-condition-type" value={"non_conditional"} control={<Radio style={{ color:radioColor }} />} label="Non-Conditional" />
                                        <FormControlLabel disabled={isDisable} data-testid="question-condition-radio-type" value={"conditional"} control={<Radio style={{ color:radioColor }} />} label="Conditional" />
                                    </RadioGroup></>
                                }
                            />}
                        </div>
                        {values.question_condition_type === "conditional" ?
                            <>
                                <InputRow label="Parent Question*" input={
                                    <>
                                    <MSelect data-testid="parentQuestion" name="parent_id" placeholder="Select" disabled={isDisable} value={(inParent) ? this.state.selectedParent.id : values?.parent_id}
                                        onChange={(event) => {
                                            handleChange(event)
                                        }}
                                        >
                                        <MenuItem disabled value={-1}>Select</MenuItem>
                                        {(isHaveParent)? this.state.avaliableParentQuestions.map((question: any) => (
                                            <MenuItem style={{width:'700px', whiteSpace: 'normal', wordWrap: 'break-word', wordBreak: 'break-all'  }} onClick={() => this.onParentQuestionSelect(question.id)} data-testid="p_que" key={question.id} value={question.id}>
                                             {question.attributes.question}
                                            </MenuItem>
                                        )) : <MenuItem style={{display:"flex",justifyContent:"center"}} disabled>No Data</MenuItem>}
                                    </MSelect>
                                    <Typography style={{ color: 'red', fontSize: '0.8rem' }}>{parentIdErrorMsg}</Typography>
                                    </>
                                } />
                                {this.OptionsRenderer(
                                    this.state.selectedParent?.attributes?.options,
                                    this.state.selectedParent?.attributes?.response_type_id,
                                    handleChange,
                                    values,
                                )}
                             </>   : <></>
                        }
                        <UploadWraper style={{ margin: '10px 0' }}>
                            <UploadButton
                                multiple
                                name="attachments"
                                onDelete={this.removeAttachments}
                                label="Upload Attachment"
                            />
                        </UploadWraper>

                    </FormModal>
                </ModalWrap>
            )}
        </Formik>
    }

    // Customizable Area End
    render() {
        // Customizable Area Start
        const options = this.state.editQuestionData?.options;
        let uniqueQuestionId = this.getUniqueQuestionId(this.state.editQuestionData, this.uniqueQuestionId);
        const template_option1 = this.getTemplateOption(options, 1, 'Very Poor');
        const template_option2 = this.getTemplateOption(options, 2, 'Poor');
        const template_option3 = this.getTemplateOption(options, 3, '');
        const template_option4 = this.getTemplateOption(options, 4, 'Very Good');
        const template_option5 = this.getTemplateOption(options, 5, 'Excellent');
        const question_condition_type_value = this.getQuestionConditionType(this.state)
        const parentID = this.getParentID(this.state);
        const initialValuesData: {
            question: string;
            questiontype: string;
            response_type: number;
            options_attributes: {
                id?: number;
                title: string;
                answer?: string;
            }[];
            template_options: string[];
            attachments: string[];
            score_options: {
                id?: number;
                title: string;
                answer?: string;
            }[];
            summation_options: {
                id?: number;
                title: string;
                answer?: string;
            }[];
            template_option1?: string;
            template_option2?: string;
            template_option3?: string;
            template_option4?: string;
            template_option5?: string;
            question_condition_type: string;
            parent_id: number
            unique_question_id: string;
            select_options: {
                id?: number;
                title: string;
                value?: number;
            }[];
        } = {
            question: this.state.editQuestionData?.question || '',
            questiontype: this.state.question_type ?? '1',
            response_type: this.state.editQuestionData?.response_type_id || 0,
            options_attributes: [],
            template_options: ['Very Poor', 'Poor', '', 'Very Good', 'Excellent'],
            attachments: this.state.editQuestionData?.uploadFile || [],
            template_option1,
            template_option2,
            template_option3,
            template_option4,
            template_option5,
            score_options: [],
            summation_options: [],
            question_condition_type: question_condition_type_value,
            parent_id: parentID,
            unique_question_id: uniqueQuestionId,
            select_options:[],
        };

        if (this.state.editQuestionData?.options) {
            this.state.editQuestionData.options.forEach((option: any) => {
                const optionItem: any = {
                    id: option.id,
                    title: option.title,
                };

                if (this.state.editQuestionData.response_type_id === 4) {
                    optionItem.answer = String(option.score);
                    initialValuesData.score_options.push(optionItem);
                } else if (this.state.editQuestionData.response_type_id === 2) {
                    initialValuesData.summation_options.push(optionItem);
                }

                initialValuesData.options_attributes.push(optionItem);
            });
        }

        while (initialValuesData.options_attributes.length < 2) {
            initialValuesData.options_attributes.push({ title: '' });
        }

        while (initialValuesData.score_options.length < 2) {
            initialValuesData.score_options.push({ title: '', answer: '0' });
        }

        while (initialValuesData.summation_options.length < 2) {
            initialValuesData.summation_options.push({ title: '', answer: '' });
        }

        const headerTitle = this.state.isEdit ? "UPDATE QUESTION" : "ADD QUESTION";
        const submitLabel = this.state.isEdit ? "UPDATE" : "ADD";

        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                {
                    this.CreateQuestionModal(initialValuesData, headerTitle, submitLabel)
                }
                <OverlayLoader loading={this.state.loading} />
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

AddSurveyQuestionsController.contextType = UserContext

const MInputBase = styled(TextField)({
    '& > div > input': {
        padding: '13px 10px'
    },
    width: '70%'
})
const EmoteFlex = styled(Flex)({
    '@media screen and (max-width:768px)': {
        flexWrap: 'wrap',
        justifyContent: 'space-evenly'
    }
})

const ModalWrap = styled('div')({

})

const AddBtn = styled(Button)({
    background: 'Green',
    color: '#fff',
    borderRadius: '20px',
    fontSize: '12px',
    width: '200px',
    fontWeight: 'bold',
    justifyContent: 'space-evenly',
    '&:hover': {
        background: 'Green',
    }
})

const WrapperDiv = styled('div')({
    '& > div': {
        width: '100%',
    }
})

const BtnImg = styled('img')({
    width: 45
})

const SummationWraper = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'row', 
    marginTop: "5px", 
    alignItems: "center"
})


const OptionWraper = styled('div')({
    width: '100%', 
    display: 'flex', 
    flexDirection: 'row', 
    marginTop: "5px" 
})

const UploadWraper = styled('div')({
    margin: '10px 0' 
})

const AddMoreWraper = styled('div')({
    display: 'flex', 
    justifyContent: 'flex-end',
    marginTop: '10px'
})

const CancelWraper = styled('button')({
    background: '#fff', 
    border: "none"
})
const ParentOptionLable = styled('span')({
    fontSize: "13px", 
})

// Customizable Area End
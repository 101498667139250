import React from "react";
// Customizable Area Start
import { ScrollView, SafeAreaView, StyleSheet } from "react-native";
import {
  Avatar,
  Button,
  CircularProgress,
  FormControl,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  Dialog,
} from "@material-ui/core";
import { CreateCSSProperties, styled } from "@material-ui/styles";
import { CameraAlt, Videocam, InsertDriveFile } from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import scale, { verticalScale } from "../../../components/src/Scale";
import { Formik, Form, useField } from "formik";
import * as Yup from "yup";
import { Scrollbars } from "react-custom-scrollbars-2";
import OverlayLoader from "../../../components/src/Loader.web";
//@ts-ignore
// import { Dropdown } from "react-native-material-dropdown";
import PostCreationController from "./PostCreationCommonController";
import Scale from "../../../components/src/Scale";
import UserContext from "../../../components/src/UserProvider";
import SearchSelect, {
  Checkbox,
} from "../../../components/src/SearchSelect.web";
import CustomisableusersubscriptionsController, {
  configJSON,
} from "./CustomisableusersubscriptionsController";

import { EndAdornment } from "../../../components/src/SelectInput.web";
import { PaymentSuccessLogo } from './assets'
// @ts-ignore
import * as classes from "./PostCreation.web.module.css";
import {
  Wrapper,
  Modal,
  CloseButton,
} from "../../../components/src/FormModal.web";
import { CloseCircleIcon } from "../../../components/src/Icons.web";
import { getStorageData } from "../../../framework/src/Utilities";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// Customizable Area End

export interface Props {
  navigation: any;
  // Customizable Area Start
  id: string;
  route: any;
  data: any;
  // Customizable Area End
}

export default class PaymentSuccesfull extends CustomisableusersubscriptionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    setTimeout(() => {
      this.closeSuccessModal();
    }, 7000);
  }

  closeSuccessModal=async()=>{
    const transferData= await getStorageData('transferData');

    let param=this.props.navigation.getParam('failed')
    if(param=="failed"){
      this.props.navigation.history.push("/PurchaseCoins")
    }
    if(transferData && param=="failed"){
      this.props.navigation.history.push("/CorpsStageCoins/create-transfer")
    }
  }
//   closeSuccessModal = () => {
//     const params = this.props.navigation.getParam('success');
//     const constant = configJSON.afterPaymemtSuccessRoutes.find((item: any) => item?.success === params)
//     let url = '/Customisableusersubscriptions/success'
//     const transferData = localStorage.getItem("transferData") || '';
//     console.log(this.props.navigation, "this:::")
//     if (transferData.length > 0) {
//       this.props.navigation.history.push("/CorpsStageCoins/create-transfer")
//     } else {
//       switch (params) {
//         case constant?.success:
//           url = constant?.url
//           break;
//         default: configJSON.defaultPaymentUrl
//           break;
//       }
//       this.props.navigation.history.push(url)
//     }
//   }

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { navigation } = this.props;
    console.log({ context: this.context });
    console.log({ state: this.state });

    // const initialValues = {
    //   ...this.initialValues,
    //   title: this.state.currentPost?.name||'',
    // }
    // console.log({initialValues, cp: this.state.currentPost})
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Wrapper>
          <Modal style={{ height: "350px", maxWidth: '800px', }}>
            <ModalBody>
              <CloseButton onClick={this.closeSuccessModal}>
                <CloseCircleIcon />
              </CloseButton>

              <CenterWrap>
                <img src={PaymentSuccessLogo} style={{ marginBottom: '24px' }} />
                <Heading>PAYMENT FAILED!</Heading>
                {/* <SubHeading>
                  Transaction completed successfully. Thank you for your
                  billing.
                </SubHeading> */}
              </CenterWrap>
            </ModalBody>
          </Modal>
        </Wrapper>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const ModalBody = styled("div")({
  padding: "10px",
  position: 'relative'
});

const CenterWrap = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  height: '100%'
});

const Heading = styled("h1")({
  fontSize: "32px",
  color: "#202022",
});

const SubHeading = styled("span")({
  fontSize: "18px",
});

// Customizable Area End

import React from "react";

// Customizable Area Start
import {
  Button,
  Divider,
  InputAdornment,
  MenuItem,
  Typography,
} from '@material-ui/core'
import { styled } from '@material-ui/styles'
import { Formik, useFormikContext } from 'formik'
import OverlayLoader from '../../../components/src/Loader.web'

import { iconLocation, logoIkea, logoCowi, logoWashington } from './assets'
import PreferenceCatSubCat from '../../categoriessubcategories/src/PreferenceCatSubCat.web'
import RoundedButton from '../../../components/src/RoundedButton.web'
import FormModal, { InputRow, MInput, MSelect, MFileDrop, MCountedTextArea, MCheckboxes } from '../../../components/src/FormModal.web'
import UserContext from "../../../components/src/UserProvider";
import Flex from '../../../components/src/Flex.web'
import Maps from '../../maps/src/Maps.web'
import UploadButton from '../../../components/src/UploadButtonnew.web';

interface ApplyFormValues {
  name: string;
  email: string;
  phone_number: string;
  location: string | null;
  comment: string;
  file: File | string;
}
// Customizable Area End

import CreateJobController, {
  Props,
  configJSON
} from "./CreateJobController";

export default class CreateJob extends CreateJobController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }


  // Customizable Area Start
  CreateJobModal() {
    const [job_id_1, job_id_2] = this.getJobIds();
    const salaryTypeToId: any = {
      'per_hour': 0,
      'per_month': 1,
      'per_year': 2,
      'per_assignment': 3,
    }
    const job = this.state.job || {};
    const {
      job_title = '',
      description = '',
      reference_link = '',
      location = null,
      experience_required = '',
      business_name = '',
      min_salary_in_dollar = '',
      min_salary_in_rs = '',
      max_salary_in_dollar = '',
      max_salary_in_rs = '',
      attachments = [],
      preference_ids = [],
      category_ids = [],
      sub_category_ids = [],
      salary_type = '',
      job_type = [],
      images = [],
    } = job;
    let initialValues = {
      job_id_1,
      job_id_2,
      job_title,
      category: -1,
      description,
      reference_link,
      location,
      experience_required,
      business_name,
      min_salary_in_dollar,
      min_salary_in_rs,
      max_salary_in_dollar,
      max_salary_in_rs,
      image: '',
      attachments,
      preference_ids,
      category_ids,
      sub_category_ids: this.state?.job!==undefined ? this.state?.job?.job_sub_category_ids : sub_category_ids,
      salary_type: salaryTypeToId[salary_type] || -1,
      job_type: Array.isArray(job_type) ? job_type : [],
      uploaded_image: images
    }
    const isEditPage = !!this.props.navigation.getParam('id');
    const jobTypeOptions: [string, string][] = [
      ["Full Time", "full-time"],
      ["Part Time", "part-time"],
      ["Remote", "remote"],
      ["Others", "others"]
    ];
    return <Formik enableReinitialize initialValues={initialValues} validationSchema={this.createFormSchema} onSubmit={this.handleCreateJobSubmit}>
      <FormModal headerTitle={isEditPage ? <b>EDIT JOB</b> : <b>CREATE JOB</b>} submitLabel={isEditPage ? "UPDATE" : "create"} onClose={this.hideModal}>
        <FormikLogger />
        <InputRow label={<JobLabel>Job ID</JobLabel>} input={
          <IdWrapper>
            <Flex1>
              <MInput name="job_id_1" disabled placeholder="Job ID" />
            </Flex1>
            <Flex1>
              <MInput name="job_id_2" disabled placeholder="Job ID" />
            </Flex1>
          </IdWrapper>
        } />
        <InputRow label={<JobLabel>Job Title*</JobLabel>} input={
          <MInput name="job_title" placeholder="Enter title here" />
        } />

        {(!isEditPage || this.state.job) && <PreferenceCatSubCat initialValues={this.state.job} {...this.props} />}
        <InputRow label={<JobLabel> Description*</JobLabel>} input={
          <MCountedTextArea name="description" placeholder="Job description" />
        } />
        <InputRow label={<JobLabel>Reference Link*</JobLabel>} input={
          <MInput name="reference_link" placeholder="Enter URL" />
        } />

        {/* @ts-ignore */}
        <InputRow label={<JobLabel>Job Location*</JobLabel>} input={<Maps input {...this.props} />} />
        <InputRow label={<JobLabel>Experience Required*</JobLabel>} input={
          <MInput name="experience_required" placeholder="Enter here" />
        } />
        <InputRow label={<JobLabel>Business Name*</JobLabel>} input={
          <MInput name="business_name" placeholder="Enter business name" />
        } />
        <InputRow label={<JobLabel>Salary Type*</JobLabel>} input={
          <MSelect name="salary_type" placeholder="Select">
            <MenuItem disabled value={-1}>Select</MenuItem>
            <MenuItem value={0}>Per Hour</MenuItem>
            <MenuItem value={1}>Per Month</MenuItem>
            <MenuItem value={2}>Per Year</MenuItem>
            <MenuItem value={3}>Per Assignment</MenuItem>
          </MSelect>
        } />

        <Flex gap="10px" style={{ display: 'flex', flexWrap: 'wrap' }}>
          <Flex1>
            <InputRow label={
              <JobLabel><Flex1>Minimum Salary*</Flex1></JobLabel>
            } input={
              <FlexCol style={{ gap: '5px' }}>
                <MInput name="min_salary_in_dollar"  {...dollarFieldProps} />
                <MInput name="min_salary_in_rs"  {...rupeeFieldProps} />
              </FlexCol>
            } />
          </Flex1>
          <Flex1>
            <InputRow label={
              <JobLabel><Flex1>Maximum Salary*</Flex1></JobLabel>
            } input={
              <FlexCol style={{ gap: '5px' }}>
                <MInput name="max_salary_in_dollar"  {...dollarFieldProps} />
                <MInput name="max_salary_in_rs"  {...rupeeFieldProps} />
              </FlexCol>
            } />
          </Flex1>
        </Flex>
        <InputRow label={<JobLabel>Job Type*</JobLabel>} input={
          <div>
            <MCheckboxes options={jobTypeOptions} name="job_type" />
          </div>
        } />
        <InputRow label={<JobLabel>Upload Image (optional)</JobLabel>} input={
          <>
            <MFileDrop inputProps={{ accept: "image/*" }} multiple name="image" onDelete={this.removeUploadedImages} />
            <div style={{ margin: '10px 0' }}>
              <UploadButton
                multiple
                name="attachments"
                onDelete={this.removeAttachments}
                label="Upload Attachment"
              />
            </div>
          </>
        } />
        <OverlayLoader loading={!!this.state.submittingForm} />
      </FormModal>
    </Formik>
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        {this.CreateJobModal()}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

CreateJobController.contextType = UserContext

function FormikLogger() {
  const formikContext = useFormikContext()
  console.log({ formikContext })
  return <></>
}


const dollarFieldProps = {
  placeholder: "Enter",
  InputProps: {
    startAdornment: <InputAdornment position="start">$</InputAdornment>
  }
}

const rupeeFieldProps = {
  placeholder: "Enter",
  InputProps: {
    startAdornment: <InputAdornment position="start">₹</InputAdornment>
  }
}

const IdWrapper = styled('div')({
  display: 'flex', 
  gap: '5px',
  '@media screen and (max-width: 480px)': {
    display: 'flex',
    flexWrap: 'wrap'
  }
})

const FlexRow = styled('div')({
  display: 'flex',
  flexDirection: 'row',
})

const Flex1 = styled('div')({
  flex: 1,
  '@media screen and (max-width: 480px)': {
    flex: 'none',
    width: '100%',
  }

})

const FlexCol = styled('div')({
  display: 'flex',
  flexDirection: 'column'
})

const RoundedButtonBase = styled(Button)({
  backgroundColor: '#009c05',
  color: 'white',
  '&:hover': {
    backgroundColor: '#009c05',
  }
})

const FlexSpaceBetween = styled('div')({
  display: 'flex',
  justifyContent: 'space-between'
})


const recommendedJobs = [{
  title: 'Lecturer Sustainable Finance',
  company: 'University of Washington',
  location: 'Toronto, Canada',
  created_at: '2 hr ago',
  logo: logoWashington,
}, {
  title: 'Sustainability Expert',
  company: 'IKEA',
  location: 'Toronto, Canada',
  created_at: '2 hr ago',
  logo: logoIkea,
}, {
  title: 'Senior Consultant and Project Manager',
  company: 'IKEA',
  location: 'Toronto, Canada',
  created_at: '2 hr ago',
  logo: logoCowi,
},]

interface RJCardProps {
  title: string;
  company: string;
  location: string;
  created_at: string;
  logo: any;
}

const RJCard = (props: RJCardProps) => {
  return <RJCardWrapper>
    <div>
      <img src={props.logo} />
    </div>
    <div>
      <RJCardTitle variant="h6">{props.title}</RJCardTitle>
      <RJContent>
        <span>{props.company}</span>
        <Divider orientation="vertical" />
        <span><img src={iconLocation} width="20" style={{ marginBottom: '-4px', marginRight: '5px' }} />{props.location}</span>
        <Divider orientation="vertical" />
        <span>{props.created_at}</span>
      </RJContent>
    </div>
  </RJCardWrapper>
}

const RJCardTitle = styled(Typography)({

})

const RJContent = styled('div')({
  height: '20px',
  display: 'flex',
  gap: '15px',
  color: '#444'
})

const RJCardWrapper = styled('div')({
  padding: '20px',
  display: 'flex',
  alignItems: 'center',
  gap: '25px',
})

const RecommendedJobs = () => {
  return <RJWrapper>
    <RJHeader><b>Jobs</b> you may be interested in</RJHeader>
    <Divider />
    {recommendedJobs.map((rj, idx) => <React.Fragment key={idx}>
      <RJCard {...rj} />
      <Divider />
    </React.Fragment>)}
    <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
      <RoundedButton>SEE MORE</RoundedButton>
    </div>
  </RJWrapper>
}

const RJWrapper = styled('div')({
  borderRadius: '2px',
  margin: '20px 0px',
  background: 'white',
  padding: '10px 0px',
})

const RJHeader = styled('div')({
  borderRadius: '15px',
  border: '3px solid #eee',
  padding: '20px 20px',
  margin: '20px 10px',
  color: '#444'
})


const ApplyHeader = styled('div')({
  fontSize: '24px',
  fontWeight: 500,
  fontFamily: 'HelveticaNeue'
})

const JobLabel = styled('div')({
  fontSize: '15.35px',
  color: '#404040',
})
// Customizable Area End

import React, { useEffect, useState } from "react";
import { CreateCSSProperties, styled } from "@material-ui/styles";
import {
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
  Button,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import { Scrollbars } from 'react-custom-scrollbars-2';
import { CloseCircleIcon, SearchIcon } from "./Icons.web";
import { useDebounce, useIntersectionObserver } from "./utils";

interface PlusMorePopupProps {
  // items to render on the list
  listItems: string[] | (React.ReactElement)[];
  // callback function for search
  onSearch?: (query: string) => void;
  // callback when end of the list is reached
  onPaginate?: () => void;
  // show pagination at the end of scroll
  paginateLoading?: boolean;
  heading?: string;
  children: any;
  isRedirect?:boolean;
  [s: string]: any;
}

export default function PlusMorePopup({
  listItems,
  children,
  heading,
  isRedirect,
  onSearch,
  ...props
}: PlusMorePopupProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [search, setSearch] = React.useState("");
  const debouncedSearch = useDebounce(search, 300);
  const handleOpen = (e: any) => setAnchorEl(e.currentTarget);
  const wrapperRef =React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const handleClickOutside = (event: MouseEvent) => {
    if (wrapperRef.current && !wrapperRef.current?.contains(event.target as Node)) {
      handleClose();
    }
  };
  


  const handleClose = () => {
    props.onClose && props.onClose()
    setAnchorEl(null);
    setSearch("")
  }
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearch(e.target.value);
  useEffect(() => {
    onSearch && onSearch(debouncedSearch);
  }, [debouncedSearch]);
  

  useEffect(() => {
    document.addEventListener("scroll", handleClose);
    return () => document.removeEventListener('scroll', handleClose)
  }, [handleClose]);

  const handleScroll = (e: any) => {
        const DIV_HEIGHT = 300;
        const THRESHOLD = 30;
        const {scrollTop, scrollHeight} = e.target
        if(scrollTop+DIV_HEIGHT+THRESHOLD>=scrollHeight){
            props.onPaginate && props.onPaginate()
        }
    }

  let list: any[] = [];
  if (listItems.length > 0) {
    if (typeof listItems[0] === "string"){
      // @ts-ignore
      list = listItems.filter(
          (item: string) =>
            item.toLowerCase().indexOf(debouncedSearch.toLowerCase()) >= 0
        )
        .map((item: string) => {
           return(
          <ListItem key={item}>
            <ListItemText primary={item} />
          </ListItem>
         )
        })}
        else if(isRedirect){
          // @ts-ignore
          list = listItems.filter(
            (item: any) => item?.props?.children?.props?.user?.data?.attributes?.email?.toLowerCase().indexOf(debouncedSearch.toLowerCase()) >= 0
          )
          .map((item: any) => {
             return(
            <ListItem key={item}>
              <ListItemText primary={item} />
            </ListItem>
           )
          })
        }
    else list = listItems;
  }
  
  return (
    <>
      <Popover
        transitionDuration={200}
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        disableScrollLock={true}
        PaperProps={{
          style: {
            overflow: 'hidden !important'
          }
        }}
      >
        <Wrapper ref={wrapperRef}>
          <Header>
            <Typography style={{ fontWeight: "bold" }} variant="h6">
              {heading}
            </Typography>
            <CloseButton onClick={handleClose}>
              <CloseCircleIcon width="20px" />
            </CloseButton>
          </Header>
          <SearchRow>
            <TextField
              onChange={handleInputChange}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon width="16px" />
                  </InputAdornment>
                ),
              }}
              placeholder="Search"
            />
            {props.onExport && <ExportButton onClick={props.onExport}>
                          Export
                        </ExportButton>}
          </SearchRow>
          <Typography 
            color="textSecondary" 
            style={{marginTop: '10px', height: '15px'}} 
            align="center"
            >
              {props.paginateLoading && 'Loading...'}
              {!props.paginateLoading && search && list?.length ===0 && 'No Match Found'}
          </Typography>
          <List style={{ height: "200px", overflow: 'auto' }}>
            <Scrollbars
                onScroll={handleScroll}
                renderTrackHorizontal={
                  (props: any) => <div {...props} style={{display: 'none'}} className="track-horizontal"/>
                }
            >
              {list}
            </Scrollbars>
          </List>
          
        </Wrapper>
      </Popover>
      <PlusWrapper {...props} onClick={handleOpen}>{children}</PlusWrapper>
    </>
  );
}

const ExportButton = styled(Button)({
  height: '25px',
  border: '1px solid #979797',
  background: '#EEFF1',
  color: '#979797',
  borderRadius: '7px',
  fontSize: '13px',
  fontWeight: 700,
  textTransform: 'none',
})

const CloseButton = styled("button")({
  margin: "5px",
  background: "none",
  color: "inherit",
  border: "none",
  padding: 0,
  font: "inherit",
  outline: "inherit",
  cursor: "pointer",
});

const PlusWrapper = styled("span")({
  cursor: "pointer",
});

const Header = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "20px",
});

const SearchRow = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  gap: '5px',
  alignItems: 'center'
});

const Wrapper = styled("div")({
  padding: "20px",
  minWidth:'260px',
  maxWidth:'320px'
});

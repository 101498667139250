import React from "react";
// Customizable Area Start
import {Box, Grid,Typography,} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import {address} from "./assets"
import {Contact} from "./assets"
import {inbox} from "./assets"
import {phone} from "./assets"
import OverlayLoader from '../../../components/src/Loader.web'

import Settings5Controller, {
  Props,
  configJSON
} from "./Settings5Controller";
// Customizable Area End
export default class Help extends Settings5Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
      //@ts-ignore
      const {classes}= this.props;
      // Customizable Area End
    return (
      // Customizable Area Start
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box style={{
            backgroundColor: "white",
            borderRadius: "10px",
            paddingBottom: "20px",
          }}>
            <div style={{
              display: "flex",
              flexDirection: "column",
              margin: "30px 10px 0px 10px",
              padding: "50px 10px",
              justifyContent: "start",
              alignItems: "start",
              width: "100%",
            }}>
              <Typography variant="h5" gutterBottom style={{ fontWeight: "bold", textAlign: "center", marginBottom: "20px", }} component="div">
                Help & Support
              </Typography>

              {(this.state.question && this.state.question.length)? (
                this.state.question.map((dataItem: any) => (
                  <Accordion
                    key={dataItem.id}
                    square
                    data-test-id="Accordion-question"
                    expanded={this.state.expanded === `panel${dataItem.id}`}
                    onChange={this.handleChangeButton(`panel${dataItem.id}`)}
                  >
                    <AccordionSummary
                      expandIcon={
                        this.state.expanded === `panel${dataItem.id}` ? (
                          <RemoveIcon style={{ color: "#1ea60a" }} />
                        ) : (
                          <AddIcon />
                        )
                      }
                      aria-controls={`panel${dataItem.id}-content`}
                      id={`panel${dataItem.id}-header`}
                    >
                      <Typography style={{ fontWeight: this.state.expanded === `panel${dataItem.id}` ? "bold" : "normal" }}>
                        {dataItem.attributes.question || ""}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        {dataItem.attributes.answer || ""}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                ))
              ) : (
                <Typography>No Data Added</Typography>
              )}
              <Typography variant="h5" gutterBottom style={{ fontWeight: "bold", textAlign: "center", margin: "20px 0px", }} component="div">
                Contact Us
              </Typography>
              {(this.state.contactUs && this.state.contactUs.length) ? this.state.contactUs.map((dataItem: any) => (
                <div key={dataItem.id} style={{ border: "1px solid rgba(0, 0, 0, .125)", borderRadius: "25px", padding: "10px", width: "92%" }}>
                  {[
                    { img: Contact, label: "Contact Us:", value: dataItem.attributes.name },
                    { img: inbox, label: "Email:", value: dataItem.attributes.email },
                    { img: phone, label: "Phone No:", value: dataItem.attributes.phone_number },
                    { img: address, label: "Address:", value: dataItem.attributes.description }
                  ].map((info, index) => (
                    <div key={index} style={{ display: "flex", margin: "10px" }}>
                      <img src={info.img} style={{ color: "grey", width: "16.91px", height: "18.9px", marginTop: "3px" }} />
                      <Typography style={{ margin: "1px 10px", color: "#424242" }}>
                        {info.label}
                      </Typography>
                      <Typography style={{ fontWeight: "bold", color: "#383838" }}>
                        {info.value || ""}
                      </Typography>
                    </div>
                  ))}
                </div>
              )):<Typography>Contact Information is not available</Typography>}
            </div>
          </Box>

        </Grid>
        <OverlayLoader loading={this.state.isLoading}/>
        </Grid>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    borderRadius:"30px",
    boxShadow: 'none',
    marginTop:"10px",
    width:"95%",
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      marginTop: '10px',
      marginBottom:"0px",
      border:"2px solid #1ea60a",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'white',
    borderRadius:"30px",
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
      borderBottom:"none",
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
     
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
   
  },
}))(MuiAccordionDetails);


// Customizable Area End

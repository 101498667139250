import React from 'react'
import {
    Typography,
} from "@material-ui/core";
const iconUpload = require('./ic_upload_green.png');
const imageIcon = require('./ic_image_icon.png');
const videoIcon = require('./ic_video_icon.png');
const csvIcon = require('./ic_csv_icon.jpg');
const pdfIcon = require('./ic_pdf.png');
const docIcon = require('./ic_doc.png');
const xlsIcon = require('./ic_xlsx_icon.png');
const genericIcon = require('./ic_generic_file.png');
const closeIcon = require('./ic_close.png');
import { useField } from 'formik'
import { styled } from "@material-ui/styles";
import RoundedButton from "./RoundedButton.web";
import { toast } from 'react-toastify';

const UploadButtonSingle = (props: any) => {
    const [field,_,helpers] = useField(props)
    const [filename, setFilename] = React.useState(props.filename || "")
    const [filesize, setFilesize] = React.useState(props.filesize || "")
    const [fileurl, setFileurl] = React.useState(props.fileurl || "")
    const [fileID, setFileID] = React.useState(props.fileId || "")
    React.useEffect(() => {
        setFilename(props.filename)
        setFilesize(props.filesize)
        setFileurl(props.fileurl)
        setFileID(props.fileId)
    }, [props.filename, props.filesize, props.fileurl ])
    const [validationError, setValidationError] = React.useState("")
    const inputRef = React.useRef<HTMLInputElement>(null)
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        helpers.setTouched(true)
        const file = e.currentTarget.files && Array.from(e.currentTarget.files)
        console.log(file, "^^^^^^^^onchange")
        if (!file) return
        const totalSize = file.map((f: any) => f.size).reduce((a: number, b: number) => a + b)
        if (props.maxSize && totalSize > props.maxSize) {
            setValidationError(props.maxSizeError || `Cannot upload document more than 20Mb size`)
            return
        }
        helpers.setValue(file)
        setFilename(file.map((f: any) => f.name).join(', '))
        setFilesize(file.map((f: any) => f.size/1024).join(', '))
    }
    const uploadedFile = field.value;
    const onDownload = () => {
        const downloadFile =uploadedFile ? uploadedFile[0] : ''
        if (downloadFile instanceof File) {
            const fileBlob = new Blob([downloadFile], { type: downloadFile.type });
            const fileUrl = URL.createObjectURL(fileBlob);
            openUrl(fileUrl)
            URL.revokeObjectURL(fileUrl);
          } else {
            
            openUrl(fileurl)
          }
    }
    const openUrl = (fileUrl:string) => {
        const newTab = window.open((fileUrl), "_blank");
        if (newTab) {
          newTab.focus();
        } else {
            toast.error("Failed to open file in a new tab.")
        }
    }

    const onClick = () => inputRef?.current && inputRef?.current?.click()

    const onRemove = () => {
        console.log("inside remove")
        helpers.setValue(null)
        setFilename("");
        if (typeof props.onDelete === 'function') {
            props.onDelete(fileID);
          }
    };
    const hasFiles = filename?.length > 0;
    return <div style={{ display: 'flex', flexDirection: 'column' }}>
        <RoundedButton onClick={onClick} startIcon={<img width="25px" src={iconUpload} />}>{props.label}</RoundedButton>
        <input multiple={props.multiple} type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={onChange} ref={inputRef} name={props.name} style={{ display: 'none' }} />
        {/* <label style={{ marginTop: '15px' }} onClick={onDownload}>{filename} {filesize}</label> */}
        {hasFiles && (
                <>
        <BorderDocument>
                            <div style={{display:"flex"}} onClick={onDownload}>
                            <img src={xlsIcon} width="42px" />
                            <FileInformation>
                                <Typography style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: 'ellipsis',
                                    width: '200px'
                                }}>{filename}</Typography>
                                <Typography color="textSecondary">{filesize}KB</Typography>
                            </FileInformation>
                            </div>
                            <div style={{ position: "absolute", right: "5px", cursor: "pointer" }} onClick={onRemove}>
                                <img src={closeIcon} width="40px" />
                            </div>
                        </BorderDocument></>)}
    </div>
}

const FileInformation = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '15px',
    gap: '5px',
})

const BorderDocument = styled('div')({
    border: '1px solid #999',
    padding: '10px 15px',
    borderRadius: '15px',
    display: 'flex',
    alignItems: 'center',
    // width: '50%',
    position: 'relative',
    marginTop: '5px'
})

export default UploadButtonSingle
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
//@ts-ignore
import * as config from "../../../framework/src/config";
import { Context } from "react";
import UserContext, { User } from "../../../components/src/UserProvider";
import { sendAPIRequest } from '../../../components/src/utils';
import { toast } from 'react-toastify';
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
var QuickBlox = require('quickblox/quickblox.min').QuickBlox;
var QB = new QuickBlox();
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  closeModal?:()=>void;
  showChatMobileView?: any;
  onCloseSidebar?: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: any;
  drawerOpen: boolean,
  token: string;
  errorMsg: string;
  loading: boolean;
  test1:any;
  mobileMoreAnchorEl:any;
  profile_pic:any;
  first_name:string;
  last_name:string;
  about:string;
  isInduMessage:Boolean;
  MessageUSerID:any;
  UserMessageList:any;
  userReply:any;
  isAddMessageList:any;
  users:any[];
  defaultUsers:any[];
  filesArray:any[];
  created_by:any[];
  requestTypes:any[];
  userPaginateCallId?:string;
  userSearchCallId?:string;
  submitRequestCallId?:string;
  respondRequestCallId?:string;
  userQuery:any;
  userPagination?:any;
  openModal:boolean;
  messageData:any;
  selectedRequestType:any;
  selectedRequestOwner:any;
  isViewRequest:boolean;
  isViewResponce:boolean;
  formType:string;
  formData:any;
  isFormInitialized:boolean;
  requestOwnerLoading:boolean;
  sharePostCallId?:string;
  sharePostScucess:boolean;
  isRecallAPI?:boolean;
  MessagesList:any;
  DialogDetails:any;
  QBuserDetails:any;
  openCommentReplyEmoji:boolean;
  IsunbletoLoad:boolean;
  isserach:boolean;
  serachvalue:boolean;
  MessagesListS:any;
  corpstageCoins:any;
  viewChat:any;
  totalUnreadMessageCount:any;
  surveyData : any;
  surveyProjectData: any;
  openUserSurveyDetailModel:boolean;
  openUserProjectSurveyDetailModel: boolean;
  UserSurveyDataModel:any;
  anchorElForPopupNested: HTMLButtonElement | null;
  allViewer: any[];
  allFollowers: any[];
  anchorElForPopupFollower: HTMLButtonElement | null;
  surveyProjectDataCount: number; 
  
  // Customizable Area End
}
interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  tokenData:string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CorpstageCoinsUpdateMessage),
      getName(MessageEnum.CorpstageCoinsNoMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage),

      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      dashboardData: [],
      drawerOpen: false,
      errorMsg: "",
      token: "",
      loading: false,
      test1: null,
      mobileMoreAnchorEl:null,
      profile_pic:'',
      first_name:'',
      last_name:'',
      about:'',
      isInduMessage: false,
      MessageUSerID: '',
      UserMessageList: [],
      userReply: "",
      isAddMessageList: false,
      isFormInitialized: false,
      users: [],
      defaultUsers: [],
      filesArray: [],
      created_by: [],
      requestTypes: [],
      userQuery: "",
      openModal: false,
      messageData: "",
      selectedRequestType: "",
      selectedRequestOwner: "",
      isViewRequest: false,
      isViewResponce: false,
      formType: "",
      formData: "",
      requestOwnerLoading: false,
      sharePostScucess: false,
      isRecallAPI: false,
      MessagesList: [],
      DialogDetails: "",
      QBuserDetails: "",
      openCommentReplyEmoji: false,
      IsunbletoLoad: false,
      isserach:false,
      serachvalue:false,
      MessagesListS: [],
      corpstageCoins:0,
      viewChat: window.screen.width < 768 ? true : false,
      totalUnreadMessageCount: 0,
      surveyData : [], 
      surveyProjectData: [],
      openUserSurveyDetailModel:false,
      openUserProjectSurveyDetailModel: false,
      UserSurveyDataModel:[],
      anchorElForPopupNested: null,
      allViewer: [],
      allFollowers: [],
      anchorElForPopupFollower: null,
      surveyProjectDataCount:0,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener('willFocus', () => {
        this.getToken();
      });
    }
    let authToken = await getStorageData("authToken")
    
    if (authToken == null) {
      this.props.navigation.navigate('Home')
    }
    this.tokenData = authToken
    this.getDetails();
    this.onInitialized();
    // Customizable Area End
  }
  
  // Customizable Area Start
  getToken=()=>{
    const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msg);
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dashboardGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  // Customizable Area End
  
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      const token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token, loading: true }, () => {
        // this.getDashboardData();
        this.setState({ loading: false });
      });
      return;
    }
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
  const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
  const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

  runEngine.debugLog("API Message Recived", message);

  if (responseJson?.errors) { 
    let tokenError = responseJson?.errors?.some((obj:any) => 'token' in obj)
    this.isTokenError(tokenError)
    this.parseApiErrorResponse(responseJson.errors);
    this.parseApiCatchErrorResponse(responseJson.errors);
    this.setState({ loading: false });
    return;
  }

  if (!responseJson) {
    return;
  }


  switch (apiRequestCallId) {
    case this.apiGetQueryStrinurl: {
      if (responseJson.data && this.context?.set) {
        const data={id: responseJson?.data?.id, ...responseJson.data.attributes}
        this.context.set({...data})
      }
      this.setState({ loading: false });
      break;
    }
    case this.apiGetAllUsersCallId: {
      this.handleGetUsersAPIResponse(responseJson);
      break;
    }
    case this.apiUserProjectSurvey: {
      this.handleGetUserProjectSurveyAPIResponse(responseJson);
      break;
    }
    case this.apiUserLikeCountCallId: {
      this.handleLikeCount(responseJson);
      break;
    }
    case this.getLikeForProjectCallId: {
      this.handleGetLikeForProject(responseJson);
      break;
    }
    case this.state?.userPaginateCallId: {
      if (!Array.isArray(responseJson?.data)) {
        return;
      }
      const users = [...this.state?.users, ...responseJson.data.map((c: any) => c.attributes)];
      this.setState({ userPaginateCallId: '', userPagination: responseJson?.meta?.pagination, users });
      break;
    }
    case this.state?.userSearchCallId: {
      let users: any = [];
      this.setState({ users });
      if (Array.isArray(responseJson?.data)) {
        users = responseJson.data.map((c: any) => c.attributes);
        this.setState({ userSearchCallId: '', userPagination: responseJson?.meta?.pagination, users });
      }
      break;
    }
    case this.state?.sharePostCallId: {
      this.setState({ sharePostCallId: '', sharePostScucess: true });
      toast("Shared Successfully", { type: 'success' });
      this.setState({ openModal: false });
      break;
    }
    case this.getAllViewersCallId: {
      this.getAllVewUserList(responseJson)
      break;
    }
    case this.getAllFollowersCallId: {
      this.getAllFollowersList(responseJson)
      break;
    }
    default: {
      if (errorReponse) {
        this.parseApiErrorResponse(errorReponse);
        this.parseApiCatchErrorResponse(errorReponse);
      }
      break;
    }
  }  
    // Customizable Area End
  }

  // Customizable Area Start
  static contextType?: Context<User> = UserContext;
  apiGetAllUsersCallId = '';
  apiUserProjectSurvey = '';
  getLikeForProjectCallId = '';
  apiGetAllRequestTypeCallId = '';
  fileUpload = "";
  apiUserLikeCountCallId = '';
  getAllViewersCallId = "";
  getAllFollowersCallId = '';


  initialType = [];
  initialValues = {
    showResponce: false,
    title: "",
    description: "",
    request_type_id: 0,
    account_ids: this.initialType,
    sender_id: 0,
    created_by_id: this.initialType,
    response: '',
    reference_link: '',
  }
  handleProfileMenuOpen = (event: any) => {
    this.setState({ test1: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });

  };

  onCommentEmojiClick = (event: any, emojiObject: any) => {
    let emoji = emojiObject?.emoji;
    this.setState({ userReply : this.state.userReply + emoji });
  
  }
  handlePeopleManagement = () => {
    this.props.closeModal!==undefined &&  this.props.closeModal();
    this.props.navigation.navigate('PeopleManagement')
  }

  handleCourseComment = async() => {
    if(this.state.userReply !== ""){
      let UserMessageList = this.state.UserMessageList;

      if (this.state.userReply) {
        UserMessageList.push({
          all_read: false,
          attachments: [],
          message: this.state.userReply,
          sender_id: this.state?.QBuserDetails.id,
        });
      }


      var message = {
        type: "chat",
        body: this.state.userReply,
        extension: {
          save_to_history: 1,
          dialog_id: this.state?.DialogDetails._id
        },
        markable: 1
      };
      const userData = await getStorageData('user');

      let currentUser: any = JSON.parse(userData);
      let qb_id = currentUser.qb_id
      var opponentId = this.state?.MessageUSerID.occupants_ids[0] == qb_id ? this.state?.MessageUSerID.occupants_ids[1] : this.state?.MessageUSerID.occupants_ids[0]

      QB.chat.send(opponentId, message);


      setTimeout(() => {
        let scroll_to_bottom = document.getElementById('chat-content');
        if(scroll_to_bottom){
          scroll_to_bottom.scrollTop = scroll_to_bottom.scrollHeight;
        }
      }, 50);
      this.setState({
        UserMessageList: UserMessageList,
        userReply: "",
      });
    }
  }

  handleMesage = async(id: any) => {
    const userData = await getStorageData('user');
    let currentUser: any = JSON.parse(userData);
    
    let qb_id = currentUser.qb_id;



    let thisvar = this;
    var params = {
      type: 3,
      occupants_ids: [id.occupants_ids[0] == qb_id ? id.occupants_ids[1] : id.occupants_ids[0] ]
    };

    await setStorageData("opponentId",id.occupants_ids[0] == qb_id ? id.occupants_ids[1] : id.occupants_ids[0]);

    QB.chat?.dialog.create(params, function (error: any, dialog: any) {
      var params = {
        chat_dialog_id: dialog._id,
        sort_desc: 'date_sent',
        limit: 100,
        skip: 0
      };

      thisvar.setState({ DialogDetails: dialog });

      QB.chat.message.list(params, (error:any, result:any) => thisvar.quickBloxsetMessage(error, result,thisvar,id));
    })
  }
  quickBloxsetMessage = async(error:any, messages:any,thisvar:any,id:any) => {
    console.log("quickBloxsetMessage",error, messages,thisvar,id)
    if (error) {
      thisvar.setState({ IsunbletoLoad: true });
      return true;
    }
    
    thisvar.setState({
      isInduMessage: true,
      MessageUSerID: id,
      UserMessageList: messages.items.reverse() //Need to modified
    }, ()=>{
      thisvar.handleAutoScroll()
    });
  }
  
handleAutoScroll =  () => {
  let scroll_to_bottom = document.getElementById('chat-content');
  if(scroll_to_bottom){
    scroll_to_bottom.scrollTop = scroll_to_bottom.scrollHeight;
  }
}

  onInitialized = async() => {
    let authToken = await getStorageData("authToken")

    this.setState({ loading: true })
    this.apiGetAllUsersCallId = sendAPIRequest(`${configJSON.getUserList}?account_id=0`, {
      headers: { token: authToken },
    });
    this.apiUserProjectSurvey = sendAPIRequest(`${configJSON.getUserProjectSurvey}`,{
      headers : { token: authToken}
    })
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    const { surveyProjectData, UserSurveyDataModel } = this.state;
  
    if (prevState?.surveyProjectData !== surveyProjectData && surveyProjectData) {
      const detailObj = Array.isArray(surveyProjectData)
        ? surveyProjectData.find((item: any) => item?.id === UserSurveyDataModel?.id)
        : Object.values(surveyProjectData).find((item: any) => item?.id === UserSurveyDataModel?.id);
  
      if (detailObj) {
        this.setState({ UserSurveyDataModel: detailObj });
      }
    }
  }
  

  handleGetUsersAPIResponse = (json: any) => {

    if (!json?.data) return

    const users = json.data.map((user: any) => ({
      id: user.id,
      name: `${user?.attributes?.first_name} ${user?.attributes?.last_name}     ${user?.attributes?.email}`,
      profile_pic: user?.attributes?.profile_pic
    }))
    this.setState({ users, userPagination: json.meta.pagination })
  }


  handleGetUserProjectSurveyAPIResponse = async(json: any) => {
    let authToken = await getStorageData("authToken")

    this.setState({...this.state, surveyProjectData : json?.project?.data,surveyData : json?.survey?.data,surveyProjectDataCount:json?.total_count, loading:false})

    this.getLikeForProjectCallId = sendAPIRequest(configJSON.getLikeForProjectEndpoint, {
      headers : {token : authToken}
  })
  }


  handleLogout = () => {

    const msg = new Message(getName(MessageEnum.SessionUpdateMessage))
    msg.addData(
      getName(MessageEnum.SessionUpdateData),
      undefined
    )
    runEngine.sendMessage(msg.id, msg)
    removeStorageData("authToken");
    removeStorageData('profile')
    removeStorageData('ability_type')
    removeStorageData('user')
    removeStorageData('card_id')
    removeStorageData('reportingFrameworkId')
    removeStorageData('reportingFrameworkTitle')
    sessionStorage.clear()
    window.location.replace("/")

  };

  handleMenuClose = () => {
    this.setState({ test1: null })
    this.handleMobileMenuClose();
  };


  handleMobileMenuOpen = (event: any) => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };
  getDetails = async() => {
    let authToken = await getStorageData("authToken")

    this.setState({ loading: true })
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetQueryStrinurl = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.GetDetailsEndPointWeb
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getUserRole = async() => {
    let authToken = await getStorageData("authToken")

    this.setState({ loading: true })
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiGetQueryStrinurl = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.GetUserDetailstWeb
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  paginateUsersOptions = async() => {
    let authToken = await getStorageData("authToken")

    const page = this.state?.userPagination?.next_page
    if (!page) return
    const { userQuery } = this.state;
    const params = new URLSearchParams()
    params.append("page", page);
    params.append("query", userQuery);
    const callId = sendAPIRequest(`${configJSON.getUserList}?account_id=${JSON.parse(this.state?.messageData?.userId || '0')}&${params.toString()}`, {
      headers: { token: authToken },
    })
    this.setState({
      userPaginateCallId: callId
    })
  }

  handleUserSearch = (userQuery: string) => {
    this.setState({ userQuery }, this.searchUser);
  }

  searchUser = async() => {
    let authToken = await getStorageData("authToken")

    const { userQuery } = this.state;
    const params = new URLSearchParams();
    params.append("query", userQuery);
    const callId = sendAPIRequest(`${configJSON.getUserList}?account_id=${JSON.parse(this.state?.messageData.userId || '0')}&${params.toString()}`, {
      headers: { token: authToken },
    })
    this.setState({
      userSearchCallId: callId,
    });
  }

  handleDrawer = () => {
    this.setState({drawerOpen:true})
  }

  handleCloseDrawer = () => {
    this.setState({drawerOpen:false})
  }

  handleUserCoins=(coins:any)=>{
    const corpstageCoins = coins?.total_coin
    this.setState({ corpstageCoins })
  }

  handleNoCoins=(coinData:any)=>{
    this.setState({corpstageCoins:coinData?.total_coin})
  }

  handleLikeChange = async(id?: any) => {
    let authToken = await getStorageData("authToken")

    this.apiUserLikeCountCallId = sendAPIRequest(configJSON.getUserLikeCountEndpoint.replace(':id', id), {
        headers : {token : authToken}
    })
}

handleLikeCount = async(json: any) => {

  let userData: any = await getStorageData('user');
  let id: any = JSON.parse(userData)?.id;
  let userIds: any = [];
  let project_like_id: any = null;
  json?.data?.attributes?.liked_by_users?.map((item: any) => {
      if(item?.likeable_type == "BxBlockProjectportfolio::PromoteContent"){
          userIds?.push(item?.like_by_id)
          
          if(item?.like_by_id == id){
              project_like_id = item?.id;
          }
      }
  });

  this.setState({...this.state, UserSurveyDataModel: {...this.state.UserSurveyDataModel, attributes: {...this.state?.UserSurveyDataModel?.attributes, likes : json?.data, is_liked: userIds.includes(id), project_like_id: project_like_id}}});
}

  handleGetLikeForProject = async(json: any) => {
    let tempSurveyProject = this.state.surveyProjectData;
    let filterLike: any = [];
    let userData: any =await getStorageData('user');

    const projectIds = tempSurveyProject?.map((item: any) => item?.id)

    json?.data?.map((item: any) => {
        if(item?.attributes?.likeable_type === "BxBlockProjectportfolio::PromoteContent"){
            if(projectIds.includes(item?.attributes?.likeable_id.toString())){
                tempSurveyProject?.map((items: any, index: any)=>{
                    if(items.id === item?.attributes?.likeable_id.toString()){
                        tempSurveyProject[index]['attributes']['likes'] = item;
                    }
                })
            }

            if(item?.attributes?.like_by_id == JSON.parse(userData)?.id){
                filterLike.push(item?.attributes?.likeable_id?.toString());
            }
            

        }
    })

    tempSurveyProject?.map((item: any, index: any) => {
        tempSurveyProject[index]['attributes']['is_liked'] = filterLike?.includes(item?.id);
    })
    
    this.setState({...this.state, surveyProjectData: tempSurveyProject});
    
  }

  openNestedMenu = async(e:any) => {
      if(this.context?.total_views){
      this.setState({anchorElForPopupNested: e.currentTarget, anchorElForPopupFollower:null, loading:true})
      this.getAllViewersCallId = sendAPIRequest(configJSON.allViewers, {
        method: 'GET',
        headers: {token: this.tokenData}
      })
    }
  }

  openFollowersList = async(e:any) => {
    
    if(this.context?.total_followers){
      this.setState({...this.state, anchorElForPopupFollower: e.currentTarget, anchorElForPopupNested:null, loading:true})
      const requestURL = configJSON.allFollowers + (`?followers=true`);
      this.getAllFollowersCallId = sendAPIRequest(requestURL, {
        method: 'GET',
        headers: {token: this.tokenData}
      })
    }
  }

  getAllVewUserList = (responseJson:any) => {
    this.setState({allViewer: responseJson.data, loading:false})
  }
  getAllFollowersList = (responseJson:any) => {
    this.setState({allFollowers: responseJson.data, loading: false})
  }
  getNavigationMessage = (route: string, props: any, payload?: any) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);

    message.addData(getName(MessageEnum.NavigationPropsMessage), props);
    
    this.send(message);
  }
  isTokenError = async(tokenError:boolean) => {
    if (tokenError) {
      await removeStorageData('authToken');
      await removeStorageData('user');
      await removeStorageData('saveUserInfo');
      await removeStorageData('reportingFrameworkTitle');
      await removeStorageData('reportingFrameworkId');
      await removeStorageData('ability_type');
      await removeStorageData('card_id')
      await removeStorageData('reportingFrameworkId')
      await removeStorageData('reportingFrameworkTitle')
      this.handleLogout()
      toast.warn('Your session has expired. Please login again to continue.',{toastId: 'success1',});
      this.getNavigationMessage('Home',this.props)
    }
  }
  // Customizable Area End

}

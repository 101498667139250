import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { Job, Category } from './types'
import * as yup from 'yup'
import { toast } from 'react-toastify'
import { sendAPIRequest } from '../../../components/src/utils'
import { sendReportMessage } from '../../ActivityFeed/src/Messages'
import { sendAddRequestMessage } from '../../RequestManagement/src/Messages'
import { sendSharePopupMessage } from '../../share/src/Messages';
import { 
  parseJobCreatedMessage, 
  parseJobUpdatedMessage, 
  parseJobApplyMessage } from './Messages'
import { getStorageData } from "../../../framework/src/Utilities";

yup.addMethod(yup.string, "phoneNumber", function(){
  const validationError = "Please enter a valid phone number"
  return this.test('test-name', validationError, function(value: string | undefined){
    if((value || "").slice(0, 3) === '+91') { // indian number
      const re =  /^(\+91)\d{10}$/
      if(re.test(value || "")) return true
      return this.createError({message: "Number starting with +91 must have 10 digits"})
    } else {
      const re = /^\+\d{5,15}$/
      return re.test(value || "")
    }
    return false
  })
})

yup.addMethod(yup.mixed, "maxFileSize", function(error: string, maxSize: number) {
  return this.test('test-name', error, function(file: File|undefined){
    if(!file) return true
    if(file.size>maxSize) return false
    return true
  })
})

yup.addMethod(yup.mixed, "fileType", function(fileTypes: string[], error: string){
  return this.test('test-name', error, function(file?: File){
    if(!file) return true
    return fileTypes.some(
      ft => (!!file.type.match(ft))
    )
  })
})

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  token: string;
  jobs: Job[];
  selectedJob?: Job;
  selectedMyTab: number;
  modal: 'apply' | 'create' | null;
  loadingJobs: boolean;
  filterQuery: string;
  pagination?: any;
  filterFromDate?: any;
  filterToDate?: any;
  filterByMe?: any;
  filterByFav?: any;
  threedotAnchorEl?: any;
  currentJob?: any;
  currentJobId?: any;
  deleteJobId?: any;
  jobDeleteCallId?: any;
  userRole?: any;
  urlSearchParam?: URLSearchParams;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class JobListing2Controller extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  apiJobsListCallId: string = ""
  apiJobsPaginateCallId: string = ""
  apiJobsFilterCallId: string = ""
  apiCategoryListCallId: string = ""
  apiCreateJobCallId: string = ""
  apiApplyJobCallId: string = ""
  apiFavJobCallIds: string[] = []
  apiUnfavJobCallIds: string[] = []
  favCallIdToPostId:{[key: string]: string} = {}
  unfavCallIdToPostId:{[key: string]: string} = {}
  userRoleCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NewJobCreatedMessage),
      getName(MessageEnum.JobApplyMessage),
      getName(MessageEnum.JobUpdatedMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      token: '',
      jobs: [],
      selectedMyTab: 0,
      modal: null,
      loadingJobs: false,
      filterQuery: '',
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    parseJobCreatedMessage(message, this.handleNewJobCreatedMessage)
    parseJobUpdatedMessage(message, this.handleJobUpdatedMessage)
    parseJobApplyMessage(message, this.handleJobApplyMessage)
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && responseJson.errors) {
        this.parseApiErrorResponse(responseJson.errors);
        this.parseApiCatchErrorResponse(responseJson.errors);
      } else if (responseJson) {
        if (apiRequestCallId === this.apiJobsListCallId ) {
          this.handleListJobsAPIResponse(responseJson)
        } else if (apiRequestCallId === this.apiCreateJobCallId){
          if(responseJson?.data?.attributes) {
            this.setState({ modal: null, jobs: [ responseJson.data.attributes, ...this.state.jobs]  })
            toast.success('Job created successfully')
          }
        } else if (apiRequestCallId === this.apiApplyJobCallId) {
          if(responseJson?.data?.attributes) {
            const jobs = this.state.jobs.map((p: any)=>{
              if(p.id!=this.state.selectedJob?.id) return p
              return ({
                  ...p, 
                  is_applied: true,
              })
            })
            this.setState({ modal: null, jobs })
            toast.success('Applied for job successfully')
          }
        } else if (apiRequestCallId === this.apiJobsFilterCallId) {
          this.handleListJobsAPIResponse(responseJson)
        } else if ( apiRequestCallId === this.apiJobsPaginateCallId ) {
          this.handlePaginateResponse(responseJson)
        }  else if(apiRequestCallId === this.state.jobDeleteCallId){
          const pid = this.state.deleteJobId
          this.closeDeleteModal()
          const jobs = this.state.jobs.filter((p: any) => p.id != pid)
          this.setState({ jobs })
        } else if (this.apiFavJobCallIds.includes(apiRequestCallId)){
          const postId = this.favCallIdToPostId[apiRequestCallId]
          this.apiFavJobCallIds = this.apiFavJobCallIds.filter((x:any) => x !== apiRequestCallId)
          if(responseJson.data){
          delete this.favCallIdToPostId[apiRequestCallId]

          const fav = {...responseJson.data, ...responseJson.data?.attributes}
          const newPosts = this.state.jobs.map(post => {
            if(Number(post.id) == Number(postId))
              return {...post, favourite_id: [fav]}
            return {...post}
            })
          this.setState({ jobs: newPosts })
          }
        } else if(this.apiUnfavJobCallIds.includes(apiRequestCallId)){
          const id = this.unfavCallIdToPostId[apiRequestCallId]
          const jobs = this.state.jobs.map((j: any)=>{
            if(j.id == id)
              return {...j, favourite_id: []}
            return j
          })
          this.setState({ jobs })
        } else if (apiRequestCallId === this.userRoleCallId) {
          this.setState({ 
            userRole: responseJson.user_role || ''
          })
        }
      }
    }
    // Customizable Area End

  }

  // Customizable Area Start
  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  }

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  }

  phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  urlRegExp = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm
  applyFormSchema = yup.object({
    name: yup.string().required().min(2).max(50)
      .matches(/^[a-zA-Z\s]+$/, "Name can only contain alphabets")
      .matches(/^.*\S+.*$/, "Name should not be all spaces").label('Name'),
    email: yup.string().required().email().label('Email'),
    // @ts-ignore
    phone_number: yup.string().required().matches(/^(\+){0,1}[0-9]{8,15}$/, "Enter a valid phone number").label('Phone Number'),
    location: yup.string().required().matches(/^[a-zA-Z0-9 ,]+$/, "Location cannot contain special characters").label('Location'),
    comment: yup.string().required().max(550),
    file: yup.mixed().required("CV is required")
      // @ts-ignore
      .maxFileSize("Resume can't be more than 2Mb in size", 2*1024*1024)
      // @ts-ignore
      .fileType(["application/pdf", "application/doc", "application/docx"], "CV must be a pdf or word document")
  })
  

  createFormSchema = yup.object({
    job_title: yup.string().label('Title').required().max(255),
    job_id_1: yup.string().label('Unique ID').required().matches(/^[a-zA-Z0-9]+$/, "Only alphabets and numbers are accepted"),
    job_id_2: yup.string().label('Unique ID').required().matches(/^[a-zA-Z0-9]+$/, "Only alphabets and numbers are accepted"),
    job_type: yup.array().min(1, 'Select atleast one option'),
    category: yup.number().label('Category').required().min(0, "Please select a Category"),
    description: yup.string().label('Description').required().max(1000),
    reference_link: yup.string().label('Reference Link').required().matches(this.urlRegExp, 'Enter a valid URL'),
    location: yup.string().label('Location').required(),
    experience_required: yup.string().label('Experience').required().matches(/^[a-zA-Z0-9 -.\(\)]$/, "Experience cannot have special characters."),
    business_name: yup.string().label('Business Name').required(),
    salary_type: yup.number().label('Salary Type').required().min(0, "Please select a Salary Type"),
    min_salary: yup.number().label('Minimum salary').required().positive(),
    min_salary_inr: yup.number().label('Minimum salary').required().positive(),
    max_salary: yup.number().label('Maximum Salary').required().positive(),
    max_salary_inr: yup.number().label('Maximum Salary').required().positive(),
    image: yup.mixed()
      // @ts-ignore
      .maxFileSize("Image cannot be more than 500kb", 500*1024)
      // @ts-ignore
      .fileType(['image/jpeg', 'image/jpg', 'image/png'], 'Image must be a jpeg or png file'),
  })


  handleSelectedMyTabChange = (e: React.ChangeEvent<{}>, newVal: number) => {
    this.setState({ selectedMyTab: newVal })
  }

  async componentDidMount() {
    // @todo call account data block for token
    let authToken = await getStorageData('authToken');
    this.setState({token: authToken}, () => {
    this.getAllJobsPosts()
    this.handleUserRole()
  });
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.navigation.history.location.state?.comment) {
      this.getAllJobsPosts()
      this.handleUserRole()
      this.props.navigation.history.replace({ state: {} })
    }
  }
  
  getAllJobsPosts = async() => {
    this.setState({ loadingJobs: true, jobs: [] })
    let url = configJSON.jobsListAPIEndpoint
    if(window.location.pathname.indexOf('/MyMarketplace') === 0)
      url += "/my_marketplace"
    this.apiJobsListCallId = await this.sendGetRequest(url)
  }

  sendGetRequest = async(endpoint: string, ) => {
    let authToken = await getStorageData('authToken');
    const requestEngineMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    const requestEngineMessageId = requestEngineMessage.messageId

    requestEngineMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    )
    requestEngineMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
        "token": authToken,
      })
    );
    requestEngineMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestEngineMessage.id, requestEngineMessage);

    return requestEngineMessageId;
  }
  handleListJobsAPIResponse = (responseJson: any) => {
    let jobs = responseJson?.data
    if(!jobs) jobs = responseJson?.jobs?.data
    if(jobs) {
      this.setState({ 
        jobs: jobs?.map((x: any) => x?.attributes ),
        pagination: responseJson.meta?.pagination, 
        loadingJobs: false
      })
    } else {
      this.setState({ jobs: [], loadingJobs: false })
    }
  }


  showApplyModal = () => {
    this.setState({ modal: 'apply' })
  } 
  showCreateModal = () => { 
    // this.setState({ modal: 'create' }) 
    this.props.navigation.history.push(window.location.pathname+'/create-job')
  }
  hideModal = () => { this.setState({ modal: null }) }

  handleJobApply = (e: React.MouseEvent<HTMLButtonElement>) => {
    const id = Number(e.currentTarget.dataset['id'])
    if(!id) {
      console.warn('button should have data-id attribute')
    } 
    const job = this.state.jobs.find(j => j.id === id)
    if(job?.is_applied){
      toast.warn('You have already applied this job.')
      return
    }
    if(job?.created_by?.id == this.context.id ) {
      toast.warn('Cannot apply for your own job post')
    } else {
      this.setState({ selectedJob: job })
      this.showApplyModal()
    }
  }

  handleJobApplyMessage = (job: any) => {
    this.setState({ selectedJob: job })
    this.showApplyModal()
  }

  handleApplySubmit = (e: React.FormEvent<{}>) => {
    e.preventDefault()
  }

  handleCreateJobSubmit = (data: any) => {
    let { job_id_1="", job_id_2="", attachments="", job_type=[], image="", ...reqData } = {  ...data }
    reqData['job_unique_id'] = `${job_id_1}-${job_id_2}`
    reqData['job_type'] = job_type.join(' , ')

    const body = new FormData()
    if(image) body.append('data[attributes][image]', image)
    if(attachments)
      body.append(`data[attributes][attachments][]`, attachments as string)
        
    Object.entries(reqData).forEach(data => body.append(`data[attributes][${data[0]}]`, data[1] as string))
    
    
    const headers = {
      "token": this.state.token,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.apiCreateJobCallId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.jobCreateAPIEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
  handleApplyJobSubmit = (data: any) => {
      const reqData = {...data, job_id: this.state.selectedJob?.id}
      const body = new FormData()
      Object.entries(reqData).forEach(data => {
        data[1] && body.append(`data[attributes][${data[0]}]`, data[1] as string)
      })

      
      const headers = {
        "token": this.state.token,
      }

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      )
      this.apiApplyJobCallId = requestMessage.messageId

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.jobApplyAPIEndpoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'POST'
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  onLikeChange = (postId: any, delta: number) => {
    const jobs = this.state.jobs.map(p => {
      if(p.id == postId){
          p.total_likes += delta;
          p.like_id = []
      }
      return p
    })
    this.setState({ jobs })
  }
  onLikeIdUpdate = (postId: any, likeObj: any) => {
    const jobs = this.state.jobs.map((p: any) => {
      if (p.id == postId) {
        p.like_id = [likeObj];
      }
      return p
    })
    this.setState({ jobs })
  }

  onJobsFilterChange = (filter: string) => {
    const { token } = this.state
    if(filter === this.state.filterQuery) return;
    this.setState({ filterQuery: filter })
    if(filter) {
      this.apiJobsFilterCallId = sendAPIRequest(`${configJSON.jobFilterAPIEndpoint}?${filter}`, { method: 'GET', headers: { token } })
      this.setState({ loadingJobs: true, jobs: [] })
    } else {
      this.getAllJobsPosts()
    }
  }

  handleFavClick = (id: any) => () => {
    const job = this.state.jobs.find(j=>j.id===id)
    const { token } = this.state
    if(job) {
      if(job.is_favourite) {
        const favourite_id = job.favourite_id[0]
        if(!favourite_id) return ;
        const endpoint = configJSON.unfavJobAPIEndpoint.replace(':id', favourite_id.id)
        const body = new FormData()
        body.append('data[favouriteable_type]', 'BxBlockJoblisting2::Job')
        const callId = sendAPIRequest(endpoint, {method: 'DELETE', body, headers: {token}})
        this.apiUnfavJobCallIds.push(callId)
        this.unfavCallIdToPostId[callId] = job.id as any
      }
      else {
        const endpoint = configJSON.favJobAPIEndpoint
        const body = new FormData()
        body.append('data[favouriteable_type]', 'BxBlockJoblisting2::Job')
        body.append('data[favouriteable_id]', id)
        const callId = sendAPIRequest(endpoint, {method: 'POST', body, headers: { token }})
        this.apiFavJobCallIds.push(callId)
        this.favCallIdToPostId[callId] = id
      } 

      // Optimistic update
      const jobs = this.state.jobs.map(
        j => {
          if(j.id!==id) return j
          return ({...j, is_favourite: !j.is_favourite})
        }
      )
      this.setState({ jobs })
    }
  }

  getFilterQueryParams = () => {
    const query = new URLSearchParams()
    // if (this.state.filterCategories.length) query.append('category_ids', this.state.filterCategories.join(','))
    // if (this.state.filterSubCategories.length) query.append('tag', this.state.filterSubCategories.join(','))
    if (this.state.filterFromDate) query.append('from_date', this.state.filterFromDate.toISOString().slice(0, 10))
    if (this.state.filterToDate) query.append('to_date', this.state.filterToDate.toISOString().slice(0, 10))
    if (this.state.filterQuery) query.append('query', this.state.filterQuery)
    if (this.state.filterByMe) query.append('created_by_me', this.state.filterByMe.toString())
    if (this.state.filterByFav) query.append('favouriteable_type', 'BxBlockJoblisting2::Job')
    return query
  }
  
  handleUserRole = async() => {
    let authToken = await getStorageData('authToken');
    const url = configJSON.getUserRoleEndpoint
    this.userRoleCallId =  sendAPIRequest(url , { method: 'GET', headers: {token:authToken} })
  }

  loadNextPage = async() => {
    const query = this.getFilterQueryParams()

    const isFiltersSet = query.toString().length > 0
    query.append('per_page', '6')
    query.append('page', String(this.state.pagination?.next_page))
    let baseUrl = isFiltersSet ? `${configJSON.jobFilterAPIEndpoint}` : configJSON.jobsListAPIEndpoint
    const myUrl = this.getMyUrl()
    if (myUrl)
      baseUrl = configJSON.getAllPostsEndpoint + myUrl
    this.apiJobsPaginateCallId = await this.sendGetRequest(baseUrl + "?" + query.toString())
  }

  // @todo update this when fixed from the backend
  // return '/my_posts' if opened from left panel, '' otherwise
  getMyUrl = () => {
    return ''
  }

  handlePaginateResponse = (responseJson: any) => {
    let jobs = responseJson?.data
    if(!jobs) jobs = responseJson?.jobs?.data
    if(jobs) {
      jobs = jobs?.map((x: any) => ({...x?.attributes, like_id: x?.attributes?.likes||[]}))
      this.setState({ 
        jobs: [...this.state.jobs, ...jobs],
        pagination: responseJson.meta.pagination, 
        loadingJobs: false
      })
    } else if(responseJson?.message === "Sorry data not available") {
      this.setState({ jobs: [], loadingJobs: false })
    }
  }

  onFilterQueryChange = (query: any) => {
    this.setState({ urlSearchParam: query })
    this.onJobsFilterChange(this.queryToString(query))
  }

  handleThreeDotClose = () => {
    this.setState({ threedotAnchorEl: undefined })
  }
  openThreeDot = (e: any) => this.setState({ threedotAnchorEl: e.currentTarget, currentJobId: e.currentTarget.dataset.id, currentJob: this.state.jobs.find((x: any) => x.id == e.currentTarget.dataset.id) })
  handlePostReport = () => {
    sendReportMessage(this.state.currentJobId, 'BxBlockJobs::Job')
    this.setState({ threedotAnchorEl: null })
  }
  handlePostEdit = () => {
    this.props.navigation.history.push(`${window.location.pathname}/edit-job/${this.state.currentJob.id}`)
    this.handleThreeDotClose()
  }
  handlePostDelete = () => {
    this.setState({ threedotAnchorEl: null, deleteJobId: this.state.currentJobId })
  }
  handleRaiseRequest = () => {
    this.props.navigation.navigate('AddRequest')
    this.handleThreeDotClose()
    const requestOwner = this.context
    let assignedTo = this.state.currentJob.created_by
    assignedTo = { ...assignedTo, first_name: assignedTo.name, last_name: '' }

    setTimeout(() => sendAddRequestMessage({
          disable_fields: ['created_by', 'accounts', 'request_type_id'],
          sender_id: requestOwner,
          created_by: requestOwner,
          accounts: {data: [{ id: String(assignedTo.id), attributes: assignedTo}]},
          "request_type_id": {
            "request_type": {
              "id": 15,
              "title": "Job Related",
            }
          }
        }), 500 // a slight delay to send after component is rendered
    )
  }

  closeDeleteModal = () => this.setState({
    currentJobId: undefined,
    deleteJobId: undefined,
    jobDeleteCallId: undefined,
    currentJob: undefined,
  })

  handleJobDeleteConfirm = () =>{
    const { token } = this.state
    const jobDeleteCallId = sendAPIRequest(configJSON.postDeleteEndpoint.replace(':id', this.state.deleteJobId), {
      method: 'DELETE',
      headers: { token }
    })
    this.setState({ jobDeleteCallId })
  }

  showDetails = (jobId: any) => () => {
    this.props.navigation.history.push(`${window.location.pathname}/job/${jobId}`)
  }

  handleShareClick = (job: any) => () => {
    let data = {
      userId: job?.created_by?.id,
      postId: job?.id,
      link: `${window?.location?.origin}${window.location.pathname}/job/${JSON.parse(job?.id || '0')}`,
      shareableType: 'BxBlockJoblisting2::Job',
      shareableId: job?.id,
    }
    sendSharePopupMessage(data);
  }

  handleNewJobCreatedMessage = (job: any) => {
    const jobs = [job, ...this.state.jobs]
    this.setState({ jobs })
  }

  handleJobUpdatedMessage = (job: any) => {
    console.log("data in job update ", job)
    const jobs = this.state.jobs.map((j: any) => {
      if(j.id!=job.id)
        return j
      return job
    })
    this.setState({ jobs })
  }

  queryToString = (queryParams: URLSearchParams) => {
    const from_date = queryParams.get('from_date')
    const to_date = queryParams.get('to_date')

    if(from_date) {
      queryParams.delete('from_date')
      queryParams.append('from_date', (new Date(from_date)).toISOString().slice(0, 10))
    }
    if(to_date) {
      queryParams.delete('to_date')
      queryParams.append('to_date', (new Date(to_date)).toISOString().slice(0, 10))
    }
    return queryParams.toString()
  }

  toTitleCase(str: string): string {
    return str.replace(
      /\w\S*/g,
      function (txt: string) {
        return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
      }
    );
  }
  // Customizable Area End
  
}

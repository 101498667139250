import React, { useEffect } from "react";
// Customizable Area Start
import TopNavBar from "../../../components/src/TopNavbar";
import {
    Box,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Avatar,
    Typography,
    Button,
    MenuItem,
    Select,
    List, 
    ListItem,
    ListItemAvatar,
    ListItemText,
    ListItemIcon,
    Checkbox,
    FormControl,
    TextField,
    InputBase,
    InputAdornment,
    ListSubheader,
    CircularProgress,
    Popover,
    Paper,
    Divider,
    Badge,
} from "@material-ui/core";
import LanguageOutlinedIcon from '@material-ui/icons/LanguageOutlined';
import { SelectItemUser } from './CreateModal.web'
import { CloseCircleIcon, SearchIcon } from "../../../components/src/Icons.web";
import { useDebounce } from '../../../components/src/utils'
import LanguageIcon from "@material-ui/icons/Language";
import SeeMore from "../../../components/src/SeeMore.web";
import PlusMorePopup from '../../../components/src/PlusMorePopup.web'
import WriteAPost from '../../../components/src/WriteAPostCard.web'
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import LeftMenuWeb from "../../../components/src/LeftMenuWeb";
import RightMenuWeb from "../../../components/src/RightMenuWeb";
import DashboardLayout from "../../../components/src/DashboardLayout.web";
import { FieldEffect } from "../../../components/src/FormikEffect.web";
import SelectInput from "../../../components/src/SelectInput.web";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { LoadingSpinner } from "../../../components/src/LoadingSpinner.web";
import SearchSelect from "../../../components/src/SearchSelect.web";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import OverlayLoader from "../../../components/src/Loader.web";
import FilterPosts from '../../filteritems/src/FilterPosts.web'
import { Scrollbars } from 'react-custom-scrollbars-2';
// import SearchIcon from '@material-ui/icons/Search';
import { Formik, Form, useField } from "formik";
import FormModal, {
    InputRow,
    MInput,
    MCountedTextArea,
    MFileDrop,
    MTimePicker,
    MDatePicker,
    MSelect,
    FInputLabel,
    MTextArea,
} from "../../../components/src/FormModal.web";
import Chip from "@material-ui/core/Chip";
import Flex from "../../../components/src/Flex.web";
import Switch from "../../../components/src/Switch.web";
import { styled } from "@material-ui/styles";
import UserContext from "../../../components/src/UserProvider";
import { CommunityCard, FilterSelect } from "./Components.web";
import ActivityFeed from '../../ActivityFeed/src/ActivityFeed.web'
import CreateModal from './CreateModal.web'
import { icEdit, icShare } from './assets'
import MyCommunitiesController, {
    Props,
    configJSON,
} from "./MyCommunitiesController";
import { Link } from 'react-router-dom'

const getName = (user: any) => `${user?.attributes?.first_name} ${user?.attributes?.last_name}`

// Customizable Area End

export default class MyCommunities extends MyCommunitiesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End

    render() {
        // Customizable Area Start
        const {onCloseSidebar} = this.props
        if(!this.state.communities) return <></>
        const communities = this.state.showAll?this.state.communities:this.state.communities.slice(0, 4)
        const communitiesList = communities.map((com: any) => (
                                <div onClick={()=> {this.handleCommunityClicked(com.id);this.closeSidebar()}}>
                                <Link key={com.id} to={`/community/${com.id}`}>

                            <MenuItem style={{ paddingRight: "30px" }}>

                                    <ListItemIcon>
                                        <LanguageOutlinedIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText 
                                        primary={com.attributes.name} 
                                        style={{ 
                                            color: "grey",
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            wordBreak: 'break-all',
                                            whiteSpace: 'break-spaces',
                                            marginRight: "15px",
                                        }} />
                                    {/* @todo add notification count later */}
                                    <Badge badgeContent={com.attributes.unread_posts} color="error" />
                            </MenuItem>
                                </Link>
                            </div>
                        ))
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <Divider />
                <div style={{ padding: "40px 0px 10px 0px" }}>
                    <Typography 
                        variant="subtitle1" 
                        gutterBottom 
                        style={{ 
                            fontWeight: "bold", 
                            paddingLeft: "15px", 
                            paddingBottom: "10px", 
                        }} 
                        component="div"
                    >
                        My Communities({this.state.total_communities})
                    </Typography>
                    <div style={{height: this.state.showAll?'300px':'176px', overflowY: 'auto', transition: '0.5s all'}}>
                        <Scrollbars
                            onScroll={this.handleScroll}
                            renderTrackHorizontal={
                              (props: any) => <div {...props} style={{display: 'none'}} className="track-horizontal"/>
                            }
                        >
                        {
                            communitiesList
                        }
                        </Scrollbars>
                    </div>
                    <Button 
                        onClick={this.toggleSeeAll}
                        variant="contained" 
                        style={{ borderRadius: "10px", margin: "20px", height: "35px", textTransform: "none" }}
                    >
                        {this.state.showAll?"Hide":"See All"}
                    </Button>


                </div>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

MyCommunitiesController.contextType = UserContext;


// Customizable Area End


// Customizable Area Start
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const PostsIcon = require('../assets/posts.png');
export const CoinsIcon = require('../assets/coins.png');
export const Tooltips = require('../assets/tooltip.png')
export const FollowersIcon = require('../assets/followers.png');
export const ViewsIcon = require('../assets/views.png');
export const UserProfile = require('../assets/user_profile.png');
export const editImg = require("../assets/edit.png");
export const deleteImg = require("../assets/delete.png");
export const iconAdd = require("../assets/iconAdd.png");
export const ArrowIcon = require("../assets/arrow.png");
export const Group2 = require("../assets/Group 2.png");
export const Group1 = require("../assets/Group.png");
export const Group5 = require("../assets/Group 5.png");
export const Group3 = require("../assets/Group 3.png");
export const Group4 = require("../assets/Group 4.png");
export const Group6 = require("../assets/group_Group.png");
export const Group7 = require("../assets/group_Group 2.png");
export const Group8 = require("../assets/group_Group 3.png");
export const Group9 = require("../assets/group_Group 4.png");
export const Group10 = require("../assets/group_Group 5.png");
export const Group11 = require("../assets/group_Group 6.png");
export const backIcon = require("../assets/backIcon.png");
export const warningIcon = require('../assets/warning.png')
export const iconUpload = require('../assets/ic_upload_green.png')
export const EditIcon = require("../assets/edit.png")
export const PdfIcon = require("../assets/image_Bitmap.png")
export const eye = require("../assets/eye.png")
export const male = require("../assets/person.png")
export const female = require("../assets/woman.png")
export const crossIcon = require("../assets/cross.png")
export const xlsxIcon = require("../assets/ic_xlsx_icon.png")
export const ic_upload_green = require("../assets/ic_upload_green.png")
export const pdf = require("../assets/pdf.png")
export const ArrowDownIcon = require("../assets/ic_arrow_down.png")
export const SearchIcon = require("../assets/ic_search.png")
export const DownloadIcon = require("../assets/ic_download.png")
export const ExcelIcon = require("../assets/ic_excel.png")
export const PdfIcon2 = require("../assets/ic_pdf.png")
export const BitmapIcon0 = require("../assets/Bitmap.png")
export const BitmapIcon1 = require("../assets/Bitmap2.png")
export const BitmapIcon3 = require("../assets/Bitmap3.png")
export const BitmapIcon2 = require("../assets/Bitmap4.png")
export const BitmapIcon4 = require("../assets/Bitmap5.png")
export const Selfassesment = require('../assets/Selfassesment.png')
export const Projects = require('../assets/Projects.png')
export const ProjectMilestone = require('../assets/ProjectMilestone.png')
export const ProjectActivities = require('../assets/ProjectActivites.png')
export const DescriptionIcon = require('../assets/descriptionIcon.png')
export const closeIcon = require('../assets/ic_close.png')
export const calendar = require('../assets/ic_calendar.png')
export const docIcon = require("../assets/ic_doc.png")
export const imageIcon =require("../assets/ic_image_icon.png")
export const orgMission =require("../assets/orgMission.png")
export const orgVision = require("../assets/orgVision.png")
export const CEOmsg =require("../assets/CEOmsg.png")
export const ZoomIcon = require('../assets/zoom-in.png');
export const ZoomOutIcon = require('../assets/zoom-out.png');
export const LocationIcon = require('../assets/location.png');
export const videoIcon = require('../assets/ic_video_icon.png');
export const csvIcon = require('../assets/ic_csv_icon.jpg');
export const pdfIcon = require('../assets/ic_pdf.png');
export const xlsIcon = require('../assets/ic_xlsx_icon.png');
export const genericIcon = require('../assets/ic_generic_file.png');
// Customizable Area End

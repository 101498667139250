import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from 'react'
import { sendAPIRequest } from '../../../components/src/utils'
import { Post } from './types'
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { sendSharePopupMessage } from '../../share/src/Messages';
import { sendAddRequestMessage } from '../../RequestManagement/src/Messages'
import { sendReportMessage } from './Messages'
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  inside?: "CommunityDetail" | "UserProfile",
  userId?: string,
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  token: string;
  filterMenuPosition: {
    top: number,
    left: number,
  } | undefined;
  threedotAnchorEl?: any;
  currentPostId?: any; // ID of post currently being edited
  currentPost?: any;
  // true when calendar is opened on, filter by date menu
  loadingPosts: boolean;
  selectingDate?: boolean;
  filterFromDate?: Date | null;
  filterToDate?: Date | null;
  filterCategories: number[];
  filterCategoriesSearchResult: any[];
  categories: any[];
  filterText: string;
  feedPosts: Post[];
  filterQuery: string;
  filterByMe: boolean;
  filterByFav: boolean;
  subcategories: any[];
  selectedPreferences: string[];
  filterSubCategories: any[];
  queryStr: string;
  pagination?: {
    next_page?: number;
  };
  deletePostId?: any;
  postDeleteCallId?: any;
  showChatMobileView?: any;
  userID: number | undefined;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ActivityFeedController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  apiGetAllPostsCallId: string = ""
  apiFilterPostsCallId: string = ""
  apiGetAllCategoriesCallId: string = ""
  apiGetAllSubcategoriesCallId: string = ""
  apiFavPostCallId: string[] = []
  favCallIdToPostId: { [key: string]: string } = {}
  apiPaginatePostsCallId: string = ""
  filterRef?: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NewPostCreatedMessage),
      getName(MessageEnum.PostUpdatedMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      loadingPosts: false,
      filterMenuPosition: undefined,
      token: "",
      selectingDate: false,
      categories: [],
      filterCategories: [],
      filterCategoriesSearchResult: [],
      filterText: '',
      feedPosts: [],
      filterQuery: '',
      filterByMe: false,
      filterByFav: false,
      subcategories: [],
      filterSubCategories: [],
      queryStr: '',
      filterFromDate: null,
      filterToDate: null,
      selectedPreferences: [],
      showChatMobileView: false,
      userID: undefined
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.filterRef = React.createRef()
    // Customizable Area End

  }

    async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }
    
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      let authToken = await getStorageData('authToken');
      this.setState({ token: token || authToken }, () => {
        this.tokenInitialized()
      });
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && responseJson.errors) {
        // this.setState({ refresh: false });
        this.parseApiErrorResponse(responseJson.errors);
        this.parseApiCatchErrorResponse(responseJson.errors);
      }
      else if(this.apiFavPostCallId.includes(apiRequestCallId)){
        this.handleFavPostResponse(apiRequestCallId, responseJson)
      }
      else {
        switch(apiRequestCallId) {
          case this.apiGetAllCategoriesCallId:
            this.handleCategoriesResponse(responseJson);
            break;
        
          case this.apiGetAllPostsCallId:
          case this.apiFilterPostsCallId:
            this.handlePostsResponse(responseJson);
            break;
        
          case this.apiPaginatePostsCallId:
            this.handlePaginatePostsResponse(responseJson);
            break;
  
          case this.apiGetAllSubcategoriesCallId:
          this.handleSubcategoriesResponse(responseJson);
          break;
        case this.state.postDeleteCallId:
          this.handlePostDeleteApi(apiRequestCallId);
          break;
        
          default:
            break;
        }
      }
    } 
    if (message.id === getName(MessageEnum.PostUpdatedMessage)) {
      const updatedPost = message.getData(getName(MessageEnum.PostUpdatedDataMessage))
      this.handleUpdatedPost(updatedPost)
    }

   
    // Customizable Area End

  }

  // Customizable Area Start
  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  }

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  }

  tokenInitialized = () => {
    this.getAllCategories()
    if(this.state.feedPosts.length === 0)
      this.getAllPosts()
    this.getAllSubCategories()
  }
  async componentDidMount() {
    console.log("componentDidMount of activity component");
    
    let authToken = await getStorageData("authToken")
    const userData = await getStorageData('user');
    
    const userId = userData ? (JSON.parse(userData)).id : undefined;
    
    this.setState({ userID: userId,token: authToken },()=> {
      this.tokenInitialized()
    })
  }
  componentDidUpdate(prevProps:any) {
    if (prevProps.navigation.history?.location?.state?.comment || this.props.userId !== prevProps.userId) {
      this.props.navigation.history?.replace({state: {}})
      this.getAllPosts()
    }
  }

  handleCategorySelect = (id: number) => (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    if (this.state.filterCategories.indexOf(id) === -1) {
      // add id
      this.setState({ ...this.getBlankFilters(), filterCategories: [...this.state.filterCategories, id] }, () => this.filterPosts())
    } else {
      // remove id
      this.setState({ ...this.getBlankFilters(), filterCategories: this.state.filterCategories.filter((x: number) => x !== id) }, () => this.filterPosts())
    }
  }

  handleTagSelect = (id: number) => () => {
    if (this.state.filterSubCategories.indexOf(id) === -1) {
      this.setState({ ...this.getBlankFilters(), filterSubCategories: [...this.state.filterSubCategories, id] }, () => this.filterPosts())
    } else {
      this.setState({ ...this.getBlankFilters(), filterSubCategories: this.state.filterSubCategories.filter((x: number) => x !== id) }, () => this.filterPosts())
    }
  }

  handleFilterMenuClose = () => {
    this.setState({ filterMenuPosition: undefined })
    this.filterPosts()
  }

  handleFilterMenuOpen = () => {
    if (!this.filterRef) return;
    const box = this.filterRef?.current?.getBoundingClientRect();
    this.setState({
      filterMenuPosition: {
        top: box?.bottom,
        left: box?.left + 20
      }
    })
  }

  // Generic method to send GET request 
  sendGetRequest = (endpoint: string, body?: Object) => {
    const headers = {
      "Content-Type": "application/json",
      "token": this.state.token,
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    const callId = requestMessage.messageId

    if (body) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return callId;
  }

  getAllCategories = () => {
    this.apiGetAllCategoriesCallId = this.sendGetRequest(configJSON.getAllCategoriesEndpoint)
  }

  // @todo update this when fixed from the backend
  // return '/my_posts' if opened from left panel, '' otherwise
  getMyUrl = () => {
    const { inside, userId } = this.props;
    return inside && inside === 'UserProfile' ? `/${JSON.parse(userId || '0')}/user_posts` : window.location.toString().indexOf('/AllActivityFeed') > 0 ? '' : '/my_posts'
  }

  getCommunitPostsUrl = () => {
    return configJSON.getAllCommunityPostsEnpoint.replace(
      ':id',
      this.props.navigation?.getParam('id')
    )
  }

  getAllPosts = () => {
    const myUrl = this.getMyUrl()
    let url = (this.props.inside === 'UserProfile' ? configJSON.getAllUserPostsEndpoint : configJSON.getAllPostsEndpoint) + myUrl + "?per_page=6"
    if (this.props.inside === 'CommunityDetail')
      url = this.getCommunitPostsUrl()
    this.apiGetAllPostsCallId = this.sendGetRequest(url);
    this.setState({ loadingPosts: true })
  }

  getCategoryById = (id: number) => {
    const cat = this.state.categories.find(c => c.id === id)
    if (cat) return cat.name
    return ""
  }

  getSubCategoryById = (id: number) => {
    const cat = this.state.subcategories.find(c => c.id === id)
    if (cat) return cat.name
    return ""
  }

  getFilterQueryParams = () => {
    const query = new URLSearchParams()
    if (this.state.filterCategories.length) query.append('category_ids', this.state.filterCategories.join(','))
    if (this.state.filterSubCategories.length) query.append('tag', this.state.filterSubCategories.join(','))
    if (this.state.filterFromDate) query.append('from_date', this.state.filterFromDate.toISOString().slice(0, 10))
    if (this.state.filterToDate) query.append('to_date', this.state.filterToDate.toISOString().slice(0, 10))
    if (this.state.filterQuery) query.append('query', this.state.filterQuery)
    if (this.state.filterByMe) query.append('created_by_me', this.state.filterByMe.toString())
    if (this.state.filterByFav) query.append('favouriteable_type', 'BxBlockPosts::Post')
    return query
  }

  filterPosts = () => {
    const queryStr = this.getFilterQueryParams().toString()
    if (queryStr === this.state.queryStr) return
    this.setState({ queryStr, feedPosts: [] })
    let baseUrl = configJSON.getFilterPostEndpoint

    if (this.props.inside === "CommunityDetail")
      baseUrl = this.getCommunitPostsUrl();
    const myUrl = this.getMyUrl()
    if (myUrl) baseUrl = (this.props.inside === 'UserProfile' ? configJSON.getAllUserPostsEndpoint : configJSON.getAllPostsEndpoint) + myUrl
    if (queryStr) {
      this.apiFilterPostsCallId = this.sendGetRequest(`${baseUrl}/?${queryStr}`)
      this.setState({ loadingPosts: true })
    } else {
      this.getAllPosts()
    }
  }

  handleDateInputFocus = () => {
    this.setState({ selectingDate: true })
  }
  handleDateInputBlur = () => (this.setState({ selectingDate: false }))

  handleFromDateChange = (date: any) => {
    this.setState({ ...this.getBlankFilters(), filterFromDate: date, filterToDate: this.state.filterToDate })
  }

  handleToDateChange = (date: any) => {
    this.setState({ ...this.getBlankFilters(), filterToDate: date, filterFromDate: this.state.filterFromDate })
  }

  handleDateFilterSubmit = () => {
    this.setState({ filterMenuPosition: undefined,  loadingPosts: true })
    this.filterPosts()
  }

  handleDateFilterCancel = () => {
    this.setState({ filterMenuPosition: undefined })
  }

  filterText = () => {
    let filterText = "";
    if (this.state.filterFromDate || this.state.filterToDate) {
      filterText += `Date(${this.state.filterFromDate?.toISOString()?.slice(0, 10) || '-'} to ${this.state.filterToDate?.toISOString()?.slice(0, 10) || '-'} ) `
    }

    if (this.state.filterCategories?.length) {
      const cats = this.state.filterCategories.map(this.getCategoryById)
      filterText += `Categories: ${cats.join(',')} `
    }
    if (this.state.filterByMe) filterText += 'Created By Me '
    if (this.state.filterByFav) filterText += "Favourite"
    if (this.state.filterSubCategories?.length) {
      const tags = this.state.filterSubCategories.map(this.getSubCategoryById)
      filterText += `Tags: ${tags.join(',')}`
    }

    return filterText
  }

  refreshFeed = () => {
    if (this.filterText()) {
      this.filterPosts()
    } else {
      this.getAllPosts()
    }
  }

  changeChatMobileView = () => {
    this.setState({
      showChatMobileView: !this.state.showChatMobileView
    })
  }

  getAllSubCategories = () => {
    this.apiGetAllSubcategoriesCallId = sendAPIRequest(configJSON.getAllSubCategoriesEndpoint, {
      method: 'GET',
      headers: {
        token: this.state.token,
      }
    })

  }

  /** set a post as favorite, with optimistic update */
  handleFavoriteClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const postId = e?.currentTarget?.dataset?.id;
    
    console.log("handleFavoriteClick",postId);
    if (!postId) return;
    const post = this.state.feedPosts?.find((x: Post) => x.id == postId)
    // if a fav request already exists, skip this one
    if (Array.from(Object.values(this.favCallIdToPostId)).includes(postId)) return;

    if (post) {
    this.handleFavourite(postId,post)
    }
  }

  handleFavourite = (postId:any,post:any) =>{
    let endpoint = post?.is_favourite ? configJSON.setPostUnFavEndpoint : configJSON.setPostFavEndpoint
    const method = post?.is_favourite ? 'DELETE' : 'POST'
    if (!post.is_favourite) {
      // POST REQUEST
      const body = new FormData()
      body.append("data[favouriteable_type]", "BxBlockPosts::Post")
      body.append("data[favouriteable_id]", postId as string)
      const callId = sendAPIRequest(endpoint, { body, method, headers: { token: this.state.token } })
      this.apiFavPostCallId.push(callId)
      this.favCallIdToPostId[callId] = postId 
    } else {
      // DELETE REQUEST
      if (!post.favourite_id?.length) return;

      endpoint = endpoint.replace(':id', String(post.favourite_id[0].id))
      sendAPIRequest(endpoint, { method, headers: { token: this.state.token } })
    }

    // optimistic update
    const feedPosts = this.state.feedPosts.map(p => {
      if (p.id == postId) {
        p.is_favourite = !p.is_favourite
        p.favourite_id = []
      }
      return p
    })
    this.setState({ feedPosts })
  }

  onFilterQueryChange = (filterQuery: string) => {
    this.setState({ filterQuery }, () => this.filterPosts())
  }

  onFilterCatSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const search = e.target.value;
    const filterCategoriesSearchResult = this.state.categories.filter((c: any) => c.name.includes(search));
    this.setState({ filterCategoriesSearchResult })
  }

  onLikeChange = (postId: any, delta: number) => {
    const feedPosts = this.state.feedPosts.map((p: any) => {
      if (p.id == postId) {
        if (Boolean(p.like_id?.some((project: any) => Number(project.like_by_id) === Number(this.state.userID)))) {
        p.total_likes -= 1;
        p.like_id = p.like_id.filter((project: any) => Number(project.like_by_id) !== Number(this.state.userID))
       }
       else {
        p.total_likes += 1
      }
      }
      return p
    })
    this.setState({ feedPosts })
  }
  
  onLikeIdUpdate = (postId: any, likeObj: any) => {
    const feedPosts = this.state.feedPosts.map((p: any) => {
      if (p.id == postId) {
        p.like_id = [...p.like_id,likeObj];
      }
      return p
    })
    this.setState({ feedPosts })
  }

  getBlankFilters = () => {
    return {
      filterFromDate: null,
      filterToDate: null,
      filterCategories: [],
      filterSubCategories: [],
      filterByMe: false,
      filterByFav: false,
    }
  }

  toggleFilterByMe = () => {
    const filters = this.getBlankFilters()
    filters['filterByMe'] = !this.state.filterByMe
    this.setState({ ...filters, filterMenuPosition: undefined, loadingPosts: true }, () => this.filterPosts())
  }

  toggleFilterByFav = () => {
    const filters = this.getBlankFilters()
    filters['filterByFav'] = !this.state.filterByFav
    this.setState({ ...filters,  filterMenuPosition: undefined, loadingPosts: true }, () => this.filterPosts())
  }

  clearFilters = (e: React.MouseEvent) => {
    e.stopPropagation()
    this.setState({
      filterFromDate: null,
      filterToDate: null,
      filterCategories: [],
      filterSubCategories: [],
      filterByMe: false,
      filterByFav: false,
      queryStr: '',
      feedPosts: [],
    })
    // load posts with no filters
    this.getAllPosts()
  }

  loadNextPage = () => {
    const query = this.getFilterQueryParams()
    const isFiltersSet = query.toString().length > 0
    query.append('per_page', '6')
    query.append('page', String(this.state.pagination?.next_page))
    let baseUrl = isFiltersSet ? `${configJSON.getFilterPostEndpoint}` : configJSON.getAllPostsEndpoint
    const myUrl = this.getMyUrl()
    if (myUrl)
      baseUrl = configJSON.getAllPostsEndpoint + myUrl
    this.apiPaginatePostsCallId = this.sendGetRequest(baseUrl + "?" + query.toString())
    // this.setState({  })
  }

  handleSharePost = (p: Post) => {
    let data = {
      userId: p.created_by.id,
      postId: p.id,
      link: `${window.location.origin}/user/${p.created_by.id}/post/${p.id}`,
      shareableType: 'BxBlockPosts::Post',
      shareableId: p.id,
    }
    sendSharePopupMessage(data);
  }


  handleThreeDotClose = () => this.setState({ threedotAnchorEl: null, currentPostId: undefined })

  openThreeDot = (e: any) => this.setState({ threedotAnchorEl: e.currentTarget, currentPostId: e.currentTarget.dataset.id, currentPost: this.state.feedPosts.find((x: any) => x.id == e.currentTarget.dataset.id) })

  handlePostEdit = () => {
    console.log({ history: this.props.navigation.history })
    const url = `${this.props.navigation.history.location.pathname}/post-edit/${this.state.currentPostId}`
    this.props.navigation.history.push(url)
    this.setState({ threedotAnchorEl: null })
  }

  handlePostDelete = () => {
    this.setState({ threedotAnchorEl: null, deletePostId: this.state.currentPostId })
  }

  handlePostDeleteConfirm = () => {
    const { token } = this.state
    const postDeleteCallId = sendAPIRequest(configJSON.postDeleteEndpoint.replace(':id', this.state.deletePostId), {
      method: 'DELETE',
      headers: { token }
    })
    this.setState({ postDeleteCallId })
  }

  closeDeleteModal = () => this.setState({
    currentPostId: undefined,
    deletePostId: undefined,
    postDeleteCallId: undefined,
    currentPost: undefined,
  })

  handlePostReport = () => {
    sendReportMessage(this.state.currentPostId, 'BxBlockPosts::Post')
    this.setState({ threedotAnchorEl: null }) 
  }

  handleRaiseRequest = (e: any) => {
    this.props.navigation.navigate('AddRequest')
    this.handleThreeDotClose()
    const requestOwner = this.context
    let assignedTo = this.state.currentPost?.created_by
    assignedTo = { ...assignedTo, first_name: assignedTo?.name, last_name: '' }

    setTimeout(() => sendAddRequestMessage({
          disable_fields: ['created_by', 'accounts', 'request_type_id'],
          sender_id: requestOwner,
          created_by: requestOwner,
          accounts: {data: [{ id: String(assignedTo.id), attributes: assignedTo}]},
          "request_type_id": {
            "request_type": {
              "id": 14,
              "title": "Post Related",
            }
          }
        }), 500 // a slight delay to send after component is rendered
    )
  }

  handleTransferCoins = ()=>{
    this.props.navigation.history.push({pathname:`${window.location.pathname}/create-transfer`, state:this.state.currentPost.Sub_category})
    this.setState({ threedotAnchorEl: null })
  }

  handleUpdatedPost = (updatedPost: any) => {
    const allPosts = this.state.feedPosts
    const feedPosts = allPosts.map((p: any) => {
      if (updatedPost.id != p.id) return p
      return updatedPost
    })
    this.setState({ feedPosts })
  }

  goToPost = (post_id: any) => {
    const url = `${this.props.navigation.history.location.pathname}/post/${post_id}`
    this.props.navigation.history.push(url)
  }
  handleCategoriesResponse(responseJson: any) {
    if (responseJson.data) {
      const categories = responseJson.data.map((cat: any) => cat.attributes);
      this.setState({ categories });
    }
  }
  
 handlePostsResponse(responseJson: any) {
    if (Array.isArray(responseJson?.posts?.data || responseJson?.post?.data)) {
      const posts = responseJson?.posts?.data || responseJson?.post?.data;
      const feedPosts = posts?.map((d: any) => ({ ...d.attributes, id: d.id })) || [];
      this.setState({ feedPosts, pagination: responseJson?.meta?.pagination, loadingPosts: false });
    } else {
      this.setState({ feedPosts: [], loadingPosts: false });
    }
  }
  
  handlePaginatePostsResponse(responseJson: any) {
    const posts = responseJson.posts?.data || responseJson.post?.data;
    const newFeedPosts = posts?.map((d: any) => ({ ...d.attributes, id: d.id }));
    const updatedFeedPosts = [...this.state.feedPosts, ...(newFeedPosts || [])];
    this.setState({ feedPosts: updatedFeedPosts, pagination: responseJson.meta.pagination });
  }

  handleSubcategoriesResponse(responseJson: any) {
    if (responseJson.data) {
      const subcategories = responseJson.data.map((x: any) => x.attributes);
      this.setState({ subcategories });
    }
  }
  
  handleFavPostResponse(apiRequestCallId: string, responseJson: any) {
    if (this.apiFavPostCallId.includes(apiRequestCallId)) {
      const postId = this.favCallIdToPostId[apiRequestCallId];
      this.apiFavPostCallId = this.apiFavPostCallId.filter(x => x !== apiRequestCallId);
      delete this.favCallIdToPostId[apiRequestCallId];
      if (responseJson.data) {
        const fav = { ...responseJson.data, ...responseJson.data?.attributes };
        const newPosts = this.state.feedPosts.map(post => {
          if (post.id === postId)
            return { ...post, favourite_id: [...(post.favourite_id || []), fav] };
          return { ...post };
        });
        this.setState({ feedPosts: newPosts });
      }
    }
  }
  
 handlePostDeleteApi(apiRequestCallId: string) {
    if (apiRequestCallId === this.state.postDeleteCallId) {
      const pid = this.state.deletePostId;
      this.closeDeleteModal();
      const updatedFeedPosts = this.state.feedPosts.filter((p: any) => p.id != pid);
      console.log({ pid, updatedFeedPosts });
      this.setState({ feedPosts: updatedFeedPosts });
    }
  }
  
  
  // Customizable Area End

}

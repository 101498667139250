import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as yup from 'yup'
import { toast } from 'react-toastify';
import { sendAPIRequest } from '../../../components/src/utils';
import { sendCourseUpdatedMessage } from './Messages'
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  openModal: boolean;
  courseData: any;
  courseId: string;
  startCourseDate: any,
  endCourseDate: any,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CourseDetailController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getCourseDetailCallId = '';
  courseEditRequestId:string = "" 
  allPreferences = [{id: 1, label: "E - Environmental"}, {id: 2, label: "S - Social"}, {id: 3, label: "G - Governance"}]
  phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  async componentDidMount() {
    super.componentDidMount();
    
    const authToken = await getStorageData('authToken')
    if (authToken == null) {
      this.props.navigation.navigate("Home");
    } else {
    }

    this.onInitialized();
    this.loadCategoriesSubCategories()
  }

  loadCategoriesSubCategories= ()=>{
    const msg = new Message(
      getName(MessageEnum.GetAllCategoriesMessage)
    )
    runEngine.sendMessage(msg.id, msg)

    const msg2 = new Message(
      getName(MessageEnum.GetAllSubCategoriesMessage)
    )
    runEngine.sendMessage(msg2.id, msg2)
  }

  onInitialized = async() => {
    const token = await getStorageData('authToken')
    const course_id = this.props.navigation.getParam('course_id')
    this.getCourseDetailCallId = sendAPIRequest(configJSON.getCourseDataByIDEndpoint.replace(':cid', course_id), {
      method: 'GET',
      headers: { token }
    })
  }

  onCloseModal = () => {
    this.setState({ openModal: false });
    this.props.navigation.goBack();
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReportModalOpenMessage),
      getName(MessageEnum.GetAllCategoriesResponseMessage),
      getName(MessageEnum.GetAllSubCategoriesResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token:'',
      openModal: true,
      courseId: '',
      courseData: {},
      startCourseDate: '',
      endCourseDate: '',
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      
      if (responseJson && responseJson.errors) {
        // this.setState({ refresh: false });
        this.parseApiErrorResponse(responseJson.errors);
        this.parseApiCatchErrorResponse(responseJson.errors);
      } else if (this.getCourseDetailCallId === apiRequestCallId){
        const mode = responseJson.data.attributes?.mode ? this.getMode(responseJson.data.attributes?.mode) :  -1 
        const course_type = responseJson.data.attributes?.course_type ? this.getCourseType(responseJson.data.attributes?.course_type) :  -1
        const course_unique_id = responseJson.data.attributes?.course_unique_id
        const unique_ids = course_unique_id ? course_unique_id.split('-') : ''
        const course_id_1 = (unique_ids && unique_ids[0]) ? unique_ids[0] : ''
        const course_id_2 = (unique_ids && unique_ids[1]) ? unique_ids[1] : ''
        const courseData = { ...responseJson.data.attributes, id: responseJson.data.id, course_id_1,  course_id_2}
        courseData.mode = mode
        courseData.course_type = course_type
      
        console.log(courseData, 'Course Data')
        this.setState({ 
          courseData, courseId: responseJson.data.id, startCourseDate: courseData.start_date, endCourseDate: courseData.end_date
        })
      } else if ( apiRequestCallId === this.courseEditRequestId ) {
        this.setState({ openModal: false });
        if(responseJson?.data?.attributes){
          const courseData = { ...responseJson.data.attributes, id: responseJson.data.id}
          sendCourseUpdatedMessage(courseData)
          toast.success("Course Updated Successfully")
        }
        this.props.navigation.goBack()
      }
       
  }
    // Customizable Area End

  }

  // Customizable Area Start
  getMode = (mode_type: String) => {
    let mode = -1
    if (mode_type === 'online_live') {
      mode = 1
    } else if (mode_type === 'online_selfpaced') {
      mode = 2
    } else if (mode_type === 'offline') {
      mode = 3
    }
    return mode
  }
  getCourseType  = (course_type: String) => {
    let c_type = -1
    if (course_type === 'degree') {
      c_type = 1
    } else if (course_type === 'diploma') {
      c_type = 2
    } else if (course_type === 'certificate') {
      c_type = 3
    } else if (course_type === 'short_team') {
      c_type = 4
    } else if (course_type === 'executive_training') {
      c_type = 5
    }
    return c_type
  }
  editFormSchema = yup.object({
    course_name: yup.string().max(255, 'Course name must not be more than 255 character long').label('Course Name').required(),
    course_type: yup.number().label('Course Type').required('Course type should be selected'),
    preference_ids: yup.array().min(1, "Select atleast one Preference").label('Preferences'),
    category_ids: yup.array().min(1, "Select atleast one Category").label('Categories'),
    organization: yup.string().max(100, 'organization must not be more than 100 character long').label('Organization').required(),
    price_Usd: yup.number().label('Price').required().positive(),
    price: yup.number().label('Price').required().positive(),
    coin_discount: yup.number().label('Discount').required().min(0).max(100, "Allowed maximum is 100"),
    // equivalent_coins: yup.number().label('Equivalent Coins').required(),
    equivalent_coins: yup.number().when("coin_discount", {
      is: (coin_discount) => coin_discount >0,
      then: yup.number().label('Equivalent Coins').min(1,"Minimum value should be one").required('Coin should be there')}),
    description: yup.string().max(1000, 'Description must not be more than 1000 characters long').label('Description').required(),
    mode: yup.number().label('Mode').required(),
    start_date: yup.string().label('Start Date').required(),
    end_date: yup.string().label('End Date').required(),
    eligibility: yup.string().max(200, 'Eligibility must not be more than 200 character long').label('Eligibility').required(),
  })

  handleEditFormSubmit = async(formData: any) => {
    const { course_id_1="", course_id_2="", ...data } = formData;
    const token = await getStorageData('authToken')
    console.log(data);
    const body = new FormData();
    (['preference_ids',
          'category_ids',
          'sub_category_ids',]).forEach((id: any) => {
          body.append(`[data][attributes][${id}]`, `[${String(data[id])}]`)
        }
    );
    delete data.preference_ids;
    delete data.category_ids;
    delete data.sub_category_ids;
    //console.log(body);
    Object.keys(data).forEach(k => body.append(`data[attributes][${k}]`, data[k]));
    this.courseEditRequestId = sendAPIRequest(configJSON.courseEditEndpoint.replace(':id', this.state.courseId), {
      method: 'PUT',
      headers: {'token': token},
      body
    })
  }

  startDateChange = (v: any) => {
    console.log(v, 'start date')
    this.setState({ startCourseDate: v})
  }

  endDateChange = (v: any) => {
    this.setState({endCourseDate: v})
  }
  // Customizable Area End
  
}

"use strict";

const baseURL = "__MARKER_FOR_BACKEND_URL_REPLACEMENT";

Object.defineProperty(exports, "__esModule", {
  value: true,
});

exports.baseURL = baseURL;

// Customizable Area Start
let appId = "";
let authKey = "";
let authSecret = "";
let accKey = "";
let googleClientId = "";
let placeAPIKey = "";

if (baseURL.includes("prod") || baseURL.includes("corpstage.com") || baseURL.includes("uat")) {
  appId = 98450;
  authKey = "ct9KcqaxXbr8bUn";
  authSecret = "xujdP8XyzjEqrU4";
  accKey = "ejQD5sjcPipK-nxrUcYF";
  googleClientId = "85666255229-se59ub2auc80t9o9tg5hehotujl2b0j9.apps.googleusercontent.com";
  placeAPIKey = "AIzaSyBM8oePJzCuKbzZo1QEFQ3y_gN3ywjqMU0"
} else {
  appId = 100868;
  authKey = "Zrw427d5Fh2vzsr";
  authSecret = "4327uvQ8QGvvh9f";
  accKey = "ejQD5sjcPipK-nxrUcYF";
  googleClientId = "132525301895-k8c0bnj2eg3o0im27mvhv0fhcfskptcm.apps.googleusercontent.com";
  placeAPIKey = "AIzaSyBM8oePJzCuKbzZo1QEFQ3y_gN3ywjqMU0"
}

const QuibloxCred = {
  QUICKBLOX_APPLICATION_ID: appId,
  QUICKBLOX_AUTH_KEY: authKey,
  QUICKBLOX_AUTH_SECRET: authSecret,
  QUICKBLOX_ACCOUNT_KEY: accKey,
};

const googleCred = {
  GOOGLE_CLIENT_ID: googleClientId,
  GOOGLE_PLACE_API: placeAPIKey
};
exports.QuibloxCred = QuibloxCred;
exports.googleCred = googleCred;
// Customizable Area End

// Customizable Area Start
import {runEngine} from '../../../framework/src/RunEngine'
import MessageEnum, {
  getName
} from '../../../framework/src/Messages/MessageEnum'
import { Message } from "../../../framework/src/Message";

export function sendNewProductCreatedMessage(product: any){
	let msg = new Message(getName(MessageEnum.NewProductCreatedMessage));
  msg.addData(
    getName(MessageEnum.NewProductData),
    product
  );
  runEngine.sendMessage(msg.id, msg);
  return msg.id
}



export function sendProductUpdatedMessage(product: any){
	let msg = new Message(getName(MessageEnum.ProductUpdatedMessage));
  msg.addData(
    getName(MessageEnum.ProductUpdatedData),
    product
  );
  runEngine.sendMessage(msg.id, msg);
  return msg.id
}

export function parseProductCreatedMessage(msg: Message, callback: (product: any)=>void){
	if(msg.id !== getName(MessageEnum.NewProductCreatedMessage)) return 

	const product = msg.getData(getName(MessageEnum.NewProductData))
	callback(product)
}

export function parseProductUpdatedMessage(msg: Message, callback: (product: any)=>void){
	if(msg.id !== getName(MessageEnum.ProductUpdatedMessage)) return 
	const product = msg.getData(getName(MessageEnum.ProductUpdatedData))
	callback(product)	
}


// Customizable Area End
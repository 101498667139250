Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
const config = require("../../../framework/src/config");
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard"; 
exports.labelBodyText = "dashboard Body";
exports.GetDetailsEndPointWeb = "account_block/accounts/show";
exports.GetUserDetailstWeb = "account_block/accounts/get_user_role";
exports.getUserList = "bx_block_share/get_users";
exports.getUserSurvey = "bx_block_surveys/user_surveys?per_page=4";
exports.getUserProjectSurvey = "bx_block_promote_content/show_survey_projects";
exports.getLikeForProjectEndpoint="/bx_block_like/likes";
exports.getUserLikeCountEndpoint = "bx_block_projectportfolio/promote_contents/get_likes_count?promote_content_id=:id"
exports.allViewers = "account_block/accounts/account_viewed_by"
exports.allFollowers = "/bx_block_followers/follows"
exports.QuibloxCred = config.QuibloxCred;
// Customizable Area End
// Customizable Area Start
import { runEngine } from '../../../framework/src/RunEngine'
import MessageEnum, {
  getName
} from '../../../framework/src/Messages/MessageEnum'
import { Message } from "../../../framework/src/Message";

export function sendNewServiceCreatedMessage(service: any) {
  let msg = new Message(getName(MessageEnum.NewServiceCreatedMessage));
  msg.addData(
    getName(MessageEnum.NewServiceData),
    service
  );
  runEngine.sendMessage(msg.id, msg);
  return msg.id
}

export function sendServiceUpdatedMessage(service: any) {
  let msg = new Message(getName(MessageEnum.ServiceUpdatedMessage));
  msg.addData(
    getName(MessageEnum.ServiceUpdatedData),
    service
  );
  runEngine.sendMessage(msg.id, msg);
  return msg.id
}

export function parseServiceCreatedMessage(msg: Message, callback: (service: any) => void) {
  if (msg.id !== getName(MessageEnum.NewServiceCreatedMessage)) return
  const service = msg.getData(getName(MessageEnum.NewServiceData))
  callback(service)
}

export function parseServiceUpdatedMessage(msg: Message, callback: (service: any) => void) {
  if (msg.id !== getName(MessageEnum.ServiceUpdatedMessage)) return
  const service = msg.getData(getName(MessageEnum.ServiceUpdatedData))
  callback(service)
}

export function sendCoinsUpdatedMessage(coins: any) {
  let msg = new Message(getName(MessageEnum.CorpstageCoinsUpdateMessage));
  msg.addData(
    getName(MessageEnum.CorpstageCoinsUpdateData,
    ),
    coins
  );
  runEngine.sendMessage(msg.id, msg);
  return msg.id
}

export function parseCoinsUpdatedMessage(msg: Message, callback: (coins: any) => void) {
  if (msg.id !== getName(MessageEnum.CorpstageCoinsUpdateMessage))
   return
  const coins = msg.getData(getName(MessageEnum.CorpstageCoinsUpdateData))
  callback(coins)
}

export function sendNoOfCoinsMessage(coins: any) {
  let msg = new Message(getName(MessageEnum.CorpstageCoinsNoMessage));
  msg.addData(
    getName(MessageEnum.CorpstageCoinsNoData,
    ),
    coins
  );
  runEngine.sendMessage(msg.id, msg);
  return msg.id
}

export function parseNoOfCoinsMessage(msg: Message, callback: (coins: any) => void) {
  if (msg.id !== getName(MessageEnum.CorpstageCoinsNoMessage))
   return
  const coins = msg.getData(getName(MessageEnum.CorpstageCoinsNoData))
  callback(coins)
}

// Customizable Area End

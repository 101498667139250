Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "JobListing2";
exports.labelBodyText = "JobListing2 Body";

exports.btnExampleTitle = "CLICK ME";

exports.labelMyJobs = "My Jobs";
exports.labelMyEvents = "My Events"
exports.labelMyProducts = "My Products"
exports.labelMyServices = "My Services"
exports.labelMyCourses = "My Courses"
exports.jobsListAPIEndpoint = '/bx_block_joblisting2/jobs'
exports.jobCreateAPIEndpoint = "/bx_block_joblisting2/jobs"
exports.jobFilterAPIEndpoint = "/bx_block_joblisting2/jobs/filter"
exports.jobApplyAPIEndpoint = "bx_block_joblisting2/apply_jobs"
exports.categoriesListAPIEndpoint = "/bx_block_categories/categories/category"
exports.favJobAPIEndpoint = "bx_block_favourites/favourites"
exports.unfavJobAPIEndpoint = "bx_block_favourites/favourites/:id"
exports.checkUniqueIdAPIEndpoint = "bx_block_joblisting2/jobs/check_unique_id"
exports.generateJobId = "bx_block_joblisting2/generate_job_id/"
exports.postDeleteEndpoint = "bx_block_joblisting2/jobs/:id"
exports.jobDetailEndpoint = "bx_block_joblisting2/jobs/:id"
exports.jobCommentEndpoint = "bx_block_joblisting2/comments"
exports.jobLikeEndpoint = "/bx_block_like/likes"
exports.jobCommentReplyEndpoint = "bx_block_joblisting2/comments/create_reply"
exports.getAllJobCommentEndpoint = "bx_block_joblisting2/get_job_comments/:id"
exports.getUserRoleEndpoint = "account_block/accounts/get_user_role"


exports.labelJobId = "Job ID"
exports.labelJobTitle = "Job Title"
exports.labelDescription = "Description"
exports.labelReferenceLink = "Reference Link"
exports.labelCategory = "Category"
exports.labelJobLocation = "Job Location"
exports.labelExpRequired = "Experience Required"
exports.labelBusinessName = "Business Name"
exports.labelSalaryType = "Salary Type"
exports.labelMinSalary = "Minimum Salary"
exports.labelMaxSalary = "Maximum Salary"
exports.labelJobType = "Job Type"
exports.labelUploadImage = "Upload Image (Optional)"
exports.labelUploadAttachment = "Upload Attachment"
// Customizable Area End
import React from 'react'
import {
    Typography,
} from "@material-ui/core";
const iconUpload = require('./ic_upload_green.png');
const imageIcon = require('./ic_image_icon.png');
const videoIcon = require('./ic_video_icon.png');
const csvIcon = require('./ic_csv_icon.jpg');
const pdfIcon = require('./ic_pdf.png');
const docIcon = require('./ic_doc.png');
const xlsIcon = require('./ic_xlsx_icon.png');
const genericIcon = require('./ic_generic_file.png');
const closeIcon = require('./ic_close.png');
import { useField } from 'formik'
import { styled } from "@material-ui/styles";
import RoundedButton from "./RoundedButton.web";
import OverlayLoader from './Loader.web';
import { toast } from 'react-toastify';

const validateFileSize = (files: File[], maxSize?: number): string => {
    const totalSize = files.map((file) => file.size).reduce((a, b) => a + b, 0);
    const maxSizeInBytes = maxSize ?? Infinity;
    return totalSize > maxSizeInBytes
        ? `Cannot upload document more than ${maxSizeInBytes / 1000000}MB size`
        : "";
};

function fileIcon(fileType: string, filename: string) {
    const extension = filename?.split(".").pop();
    const imageType = fileType?.split('/')?.[0];
    if (fileType === 'image' || imageType === 'image') {
        return imageIcon;
    } else if (fileType === 'video') {
        return videoIcon;
    } else if (fileType === 'text' || extension === 'csv') {
        return csvIcon;
    } else if (fileType === 'application' || fileType === 'application/pdf' || imageType === "application/vnd.ms-excel" || imageType === "application") {
        // doc, xls, pdf
        if (extension === 'pdf') {
            return pdfIcon;
        } else if (extension === 'doc' || extension === 'docx') {
            return docIcon;
        } else if (extension === 'xls' || extension === 'xlsx') {
            return xlsIcon;
        }
    } else {
        return genericIcon;
    }
}

const UploadButton = ({ label, filename: propFilename = "", size: propSize = "", multiple = false, input = false, ...props }: any) => {
    const [field, meta, helpers] = useField(props);
    const [filename, setFilename] = React.useState<string[]>(propFilename ? [propFilename] : []);
    const [filetype, setFiletype] = React.useState<string[]>([]);
    const [fileId, setFileId] = React.useState<number[]>([]);
    const [filesize, setFilesize] = React.useState<string[]>([]);
    const [files, setFiles] = React.useState<{}[]>([]);
    const [removing, setRemoving] = React.useState(false);
    const [validationError, setValidationError] = React.useState("");
    const inputRef = React.useRef<HTMLInputElement>(null);

    const initialFiles = field.value || [];

    React.useEffect(() => {
        let checkID=true;
        initialFiles.map((item:any)=>{if(item.name){
            checkID=false
        }})
        if (initialFiles.length > 0 && checkID) {
            const fileNames = initialFiles.map((file: any) => file.filename);
            const fileTypes = initialFiles.map((file: any) => file.type);
            const fileSizes = initialFiles.map((file: any) => file.size + "KB");
            const fileId = initialFiles.map((file: any) => file.id);
            setFilename(fileNames);
            setFiletype(fileTypes);
            setFilesize(fileSizes);
            setFiles(initialFiles)
            setFileId(fileId)
        }
    }, [initialFiles]);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        helpers.setTouched(true);
        const Files = Array.from(e.currentTarget.files ?? []);
        const Lfiles = [...files, ...Files]
        
        if (Files.length === 0) return;

        const fileSizeError = validateFileSize(Files, props.maxSize);
        if (fileSizeError) {
            setValidationError(props.maxSizeError ?? fileSizeError);
            return;
        }

        
        
        const fileNames = Files.map((file) => file.name);
        const fileTypes = Files.map((file) => file.type);
        const fileSizes = Files.map((file) => Math.floor(file.size / 1000) + "KB");
        
        setFilename((prevFilenames) => [...prevFilenames, ...fileNames]);
        setFiles((prevFile) => [...prevFile, ...Files]);
        setFiletype((prevFiletypes) => [...prevFiletypes, ...fileTypes]);
        setFilesize((prevFilesizes) => [...prevFilesizes, ...fileSizes]);
        helpers.setValue(Lfiles);
        
    };

    const onClick = (e: any) => {
        e.preventDefault();
        return inputRef?.current?.click();
    };

    const onRemove = (index: number, e: any) => {
        e.preventDefault();
        const removeFileId = fileId[index]; // Get the ID of the current file
        const updatedFiles = [...(field?.value || [])];
        updatedFiles.splice(index, 1);
        helpers.setValue(updatedFiles);
      
        const updatedFilenames = [...filename];
        const updatedFiletypes = [...filetype];
        const updatedFilesizes = [...filesize];
      
        updatedFilenames.splice(index, 1);
        updatedFiletypes.splice(index, 1);
        updatedFilesizes.splice(index, 1);
      
        setFilename(updatedFilenames);
        setFiletype(updatedFiletypes);
        setFilesize(updatedFilesizes);
        setFiles(updatedFiles);
        if (typeof props.onDelete === 'function') {
            props.onDelete(removeFileId);
          }
      };

    const onDownload = (index: number,e:any) => {
        e.preventDefault();
        const updatedFiles = [...(files || [])];
        const downloadFile:any= updatedFiles[index]
        if (downloadFile instanceof File) {
            const fileBlob = new Blob([downloadFile], { type: downloadFile.type });
            const fileUrl = URL.createObjectURL(fileBlob);
            openUrl(fileUrl)
            URL.revokeObjectURL(fileUrl);
          } else {
            openUrl(downloadFile?.url)
          }
    }
    const openUrl = (fileUrl:string) => {
        const newTab = window.open((fileUrl), "_blank");
        if (newTab) {
          newTab.focus();
        } else {
            toast.error("Failed to open file in a new tab.")
        }
    }

    const hasFiles = filename.length > 0;

    return (
        <label>
            {input ? <div onClick={onClick} style={{ border: '1px solid #bdbdbd', height: '38px', padding: '8px 8px 0px', borderRadius: '5px' }} >
                <img width="25px" src={iconUpload} style={{ float: 'right' }} />
            </div> :
                <RoundedButton onClick={onClick} startIcon={<img width="25px" src={iconUpload} />}>
                    {label}
                </RoundedButton>
            }



            <input multiple={multiple} type="file" accept={props.filetype ?? "*"} onChange={onChange} ref={inputRef} name={props.name} style={{ display: "none" }} />
            {hasFiles && (
                <>
                
                    {filename.map((name, index) => (
                        <BorderDocument key={index}>
                            <div style={{display:"flex"}} onClick={(e) => onDownload(index,e)}>
                            <img src={fileIcon(filetype[index], name)} width="42px" />
                            <FileInformation>
                                <Typography style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: 'ellipsis',
                                    width: '200px'
                                }}>{name}</Typography>
                                <Typography color="textSecondary">{filesize[index]}</Typography>
                            </FileInformation>
                            </div>
                            <div style={{ position: "absolute", right: "5px", cursor: "pointer" }} onClick={(e) => onRemove(index, e)}>
                                <img src={closeIcon} width="40px" />
                            </div>
                        </BorderDocument>
                    ))}
                    <OverlayLoader loading={removing} />
                </>
            )}
            {meta.touched && (meta.error || validationError) && (
                <Typography color="error">{meta.error ?? validationError}</Typography>
            )}
        </label>
    );
};

const FileInformation = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '15px',
    gap: '5px',
})

const BorderDocument = styled('div')({
    border: '1px solid #999',
    padding: '10px 15px',
    borderRadius: '15px',
    display: 'flex',
    alignItems: 'center',
    width: '50%',
    position: 'relative',
    marginTop: '5px'
})

export default UploadButton
// Customizable Area Start
import {runEngine} from '../../../framework/src/RunEngine'
import MessageEnum, {
  getName
} from '../../../framework/src/Messages/MessageEnum'
import { Message } from "../../../framework/src/Message";

export function sendSharePopupMessage(data: any){
	let msg = new Message(getName(MessageEnum.SharePopupMessaage));
    msg.addData(
      getName(MessageEnum.SharePopupData),
      data
    );
    runEngine.sendMessage(msg.id, msg);
    return msg.id
}

/*
*	execute callback only if message type match
*/ 
function parseSendSharePopupMessage(msg: Message, id: string, callback: (data: any) =>any) {
	if(msg.id ===  id) {
		const sharePopupData = msg.getData(getName(MessageEnum.SharePopupData))
		callback(sharePopupData)
	}
}


export function parseSharePopupMessageData(msg: Message, callback: (data: any) => any) {
	parseSendSharePopupMessage(msg,getName(MessageEnum.SharePopupMessaage),  callback)
}
// Customizable Area End
import React, { Component } from "react";
// Customizable Area Start
import { Box, Grid, Typography, Divider, Button, Badge, Popover,Popper,Paper,ClickAwayListener,MenuList } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import FolderOutlinedIcon from '@material-ui/icons/FolderOutlined';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, Theme, createStyles, withStyles, styled } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import LanguageOutlinedIcon from '@material-ui/icons/LanguageOutlined';
import { withRouter, Redirect } from "react-router-dom";
import DashboardController, { Props } from "../../blocks/dashboard/src/DashboardController";
import MyCommunities from "../../blocks/CommunityForum/src/MyCommunities.web";
import OverlayLoader from '../../components/src/Loader.web'
import { Link } from 'react-router-dom';

const FeedIcon = require('./ic_feed.png')
const FeedIconActive = require('./ic_feed_green.png')
const ProjectsIcon = require('./ic_projects.png')
const RequestsIcon = require('./ic_requests.png')
const RequestsIconActive = require('./ic_requests_green.png')
const SubscriptionIcon = require('./ic_subscription.png')
const SurveyActiveIcon = require('./Survey.png')
const SurveyIcon = require('./SurveyBlack.png')
export const profileAvatar = require('./profile_avatar.png')
const MarketplaceIcon = require('./ic_marketplace.png')
const MarketplaceIconGreen = require('./ic_marketplace_green.png')
const LogoHex = require('./ic_logohex.png')
const PeopleIcon = require('./ic_people.png')
export const PeopleIconActive = require('./ic_people_green.png')
const RectIcon = require('./ic_rect.png')
const EyeIcon = require('./ic_eye.png')
const ESG = require('./ic_esg.png');
const ProjectIconGreen = require('./myproject.png')
const ESGJourney = require('./esgJourney.png')
import CloseIcon from "@material-ui/icons/Close";
// Customizable Area End



export default class LeftMenuWeb extends DashboardController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }


    render() {
        // Customizable Area Start
        console.log({ props: this.props })
        const { navigation, onCloseSidebar } = this.props
        const navigate = (route: string) => () => navigation.navigate(route)
        const matchLocation = (route: string) => window?.location?.pathname?.indexOf(route) !== -1
        const {allFollowers,allViewer} = this.state
        console.log({ userContext: this.context })
        console.log({ userContext: this.context }, "userContext data:::")
        // Customizable Area End
        return (
            // Customizable Area Start
            <OuterGrid container spacing={3}  >
                <OverlayLoader loading={this.state?.loading} />
                <Grid item xs={12} >
                    <DetailBox>
                        <Details>
                            <Avatar>
                                <img src={this.context.profile_pic ? this.context.profile_pic : this.state.profile_pic ? this.state.profile_pic : profileAvatar} alt="img" width="100%" height="100%" />
                            </Avatar>
                            <Contents>
                                <Typography
                                    variant="subtitle1"
                                    component="div"
                                    style={{ fontWeight: "bold", marginLeft: "10px", fontFamily: "Helvetica", overflow: 'hidden' }}
                                >
                                    {this.context.first_name + " " + this.context.last_name}
                                </Typography>
                                <Typography
                                    variant="subtitle2"
                                    component="div"
                                    style={{ color: "grey", fontSize: 12, marginLeft: "10px", fontFamily: "Helvetica" }}
                                >
                                    {this.context.about ? this.context.about : ""}
                                </Typography>
                                <ContentDetails>
                                    <FollowerCoin>
                                        <Follower 
                                            onClick={(event) => this.openFollowersList(event)}
                                            >
                                            <img src={PeopleIcon} />
                                            {this.context?.total_followers}
                                        </Follower>
                                        <StyledPopper
                                            open={Boolean(this.state.anchorElForPopupFollower)}
                                            anchorEl={this.state.anchorElForPopupFollower}
                                            role={undefined}
                                            transition
                                            placement="bottom-end"
                                        >
                                            <Paper>
                                                <ClickAwayListener onClickAway={() => this.setState({ ...this.state, anchorElForPopupFollower: null })}>
                                                    <MenuList style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding:'10px' }}>
                                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '5px',width:'100%' }}>
                                                            <Typography variant="h6" style={{ padding: '15px' }}>Followers</Typography>
                                                            <CloseIcon style={{ cursor: 'pointer' }} onClick={() => this.setState({ ...this.state, anchorElForPopupFollower: null })} />
                                                        </div>
                                                        {allFollowers && allFollowers.length ? allFollowers.map((data) => {
                                                            const userID = data.attributes?.current_user?.id
                                                            return (
                                                                <MenuItem key={userID} >
                                                                    <User onClick={() => {
                                                                        this.setState({ ...this.state, anchorElForPopupFollower: null })
                                                                        navigation.history.push(`/user/${userID}`)
                                                                    }}>
                                                                        <Avatar src={data?.attributes?.current_user_profile_pic} />
                                                                        <Typography>{data?.attributes?.current_user?.first_name + " " + data?.attributes?.current_user?.last_name}</Typography>
                                                                    </User>
                                                                </MenuItem>
                                                                )
                                                            })
                                                            :
                                                            (
                                                                <MenuItem><EmptyDiv>No data</EmptyDiv></MenuItem>
                                                            )
                                                        }
                                                    </MenuList>

                                                </ClickAwayListener>
                                            </Paper>
                                        </StyledPopper>

                                        <Coin onClick={navigate('CorpsStageCoins')}>
                                            <img src={LogoHex} />
                                            {this.state.corpstageCoins || this.context.no_of_coin}
                                        </Coin>
                                    </FollowerCoin>
                                    <ViewPost>
                                        <Views onClick={(event) => this.openNestedMenu(event)}>
                                            <img src={EyeIcon} />
                                            {this.context?.total_views}
                                        </Views>
                                        <StyledPopper
                                            id={this.state.anchorElForPopupNested ? 'nested-popover' : undefined}
                                            open={Boolean(this.state.anchorElForPopupNested)}
                                            anchorEl={this.state.anchorElForPopupNested}
                                            role={undefined}
                                            transition
                                            placement="bottom-end"
                                        >
                                            <Paper>
                                                <ClickAwayListener onClickAway={() => this.setState({ ...this.state, anchorElForPopupNested: null })}>
                                                    <MenuList style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding:'10px' }}>
                                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '5px',width:'100%' }}>
                                                            <Typography variant="h6" style={{ padding: '15px' }}>Viewers</Typography>
                                                            <CloseIcon style={{ cursor: 'pointer' }} onClick={() => this.setState({ ...this.state, anchorElForPopupNested: null })} />
                                                        </div>
                                                        {allViewer && allViewer.length ? allViewer.map((data) => { 
                                                            const viewerData = data?.attributes?.account_viewed_by?.data;
                                                            const userID = viewerData?.id;
                                                            const fullName = `${viewerData?.attributes?.first_name} ${viewerData?.attributes?.last_name}`;
                                                            const profilePic = viewerData?.attributes?.profile_pic;                               
                                                            return (
                                                                <MenuItem key={userID} >
                                                                    <User key={userID} onClick={() => {
                                                                        this.setState({ ...this.state, anchorElForPopupNested: null })
                                                                        navigation.history.replace(`/user/${userID}`)
                                                                    }}>
                                                                    <Avatar src={profilePic} />
                                                                    <Typography>{fullName}</Typography>
                                                            </User>
                                                                </MenuItem>
                                                                )
                                                            })
                                                            :
                                                            (
                                                                <MenuItem><EmptyDiv>No data</EmptyDiv></MenuItem>
                                                            )
                                                        }
                                                    </MenuList>

                                                </ClickAwayListener>
                                            </Paper>
                                        </StyledPopper>
                                        <Post onClick={()=> navigation.navigate('ActivityFeed')}>
                                            <img src={RectIcon} />
                                            {this.context?.total_post}
                                        </Post>
                                    </ViewPost>
                                </ContentDetails>
                            </Contents>

                        </Details>
                    </DetailBox>
                </Grid>
               
                <Grid item xs={12}>
                    <MenuArea>
                        <LeftMenuList>
                            
                             <StyledMenuItem
                                disabled={!(this.context?.role?.role_level > 4)} selected={matchLocation('/ESGJourney') } onClick={navigate(this.context.user_type == "licensed_user" ? 'Corporatepermission' : 'ESGJourney')}
                            >
                                <ListItemIcon>

                                    <img src={matchLocation('/ESGJourney') ? ESG : ESGJourney} width="20px" />
                                </ListItemIcon>
                                <ListItemText primary="ESG Journey" />
                            </StyledMenuItem>
                            
                            <StyledMenuItem selected={matchLocation('/ActivityFeed')} onClick={navigate('ActivityFeed')}>
                                <ListItemIcon>
                                    {/* <PeopleAltOutlinedIcon fontSize="small" /> */}
                                    <img src={matchLocation('/ActivityFeed') ? FeedIconActive : FeedIcon} width="20px" />
                                </ListItemIcon>
                                <ListItemText primary="Feeds" />
                            </StyledMenuItem>

                            <StyledMenuItem  selected={matchLocation('/my_projects')} onClick={navigate('MyProjects')}>
                                <ListItemIcon>
                                    {/* <FolderOutlinedIcon fontSize="small" /> */}
                                    <img src={matchLocation('/my_projects') ? ProjectIconGreen : ProjectsIcon} width="20px" />
                                </ListItemIcon>
                                <ListItemText primary="My Projects" />
                            </StyledMenuItem>

                            <StyledMenuItem selected={matchLocation('/MyMarketplace')} onClick={navigate('MyJobListing2')}>
                                <ListItemIcon>
                                    <img src={matchLocation('/MyMarketplace') ? MarketplaceIconGreen : MarketplaceIcon} width="20px" />
                                </ListItemIcon>
                                <ListItemText primary="My Marketplace" />
                            </StyledMenuItem>

                            <StyledMenuItem selected={matchLocation('/RequestManagement')} onClick={navigate('RequestManagement')}>
                                <ListItemIcon>
                                    {/* <FolderOutlinedIcon fontSize="small" /> */}
                                    <img src={matchLocation('/RequestManagement') ? RequestsIconActive : RequestsIcon} width="20px" />
                                </ListItemIcon>
                                <ListItemText primary="Requests" />
                            </StyledMenuItem>
                            
                            <StyledMenuItem disabled={!(this.context?.role?.role_level > 4 && this.context.user_type != "licensed_user")} selected={matchLocation('/PeopleManagement')} onClick={this.handlePeopleManagement}>
                                <ListItemIcon>
                                    <img src={matchLocation('/PeopleManagement') ? PeopleIconActive : PeopleIcon} width="20px" />
                                </ListItemIcon>
                                <ListItemText primary="People Management" />
                            </StyledMenuItem>
                            
                            <StyledMenuItem disabled={!(this.context.user_type != "licensed_user")} selected={matchLocation('/Customisableusersubscriptions')} onClick={navigate('Customisableusersubscriptions')}>
                                <ListItemIcon>
                                    <img src={SubscriptionIcon} width="20px" />
                                </ListItemIcon>
                                <ListItemText primary="Subscription" />
                            </StyledMenuItem>
                            
                                <StyledMenuItem disabled={!(this.context?.role?.role_level > 3 && this.context.user_type != "licensed_user")} selected={matchLocation('/surveyCards')} onClick={navigate('SurveyCards')}>
                                    <ListItemIcon>
                                        <img src={matchLocation('/Survey') ? SurveyActiveIcon : SurveyIcon} width="20px" />
                                    </ListItemIcon>
                                    <ListItemText primary="Survey" />
                                </StyledMenuItem>

                        </LeftMenuList>
                        {this.context?.role?.name != "Individual User " && <MyCommunities onCloseSidebar={this.props.onCloseSidebar} navigation={{}} id={""} />}

                    </MenuArea>
                </Grid>
                
            </OuterGrid>
            // Customizable Area End
            
        )
    }
}
// Customizable Area Start

const OuterGrid = styled(Grid)({
    // '@media screen and (max-width: 768px)': {
    //     display: 'none',
    //     visibility: 'hidden',
    // }
})

const DetailBox = styled(Box)({
    backgroundColor: "white",
    borderRadius: "5px",
})

const Details = styled(Box)({
    display: "flex",
    margin: "30px 10px 0px 10px",
    padding: "20px 10px",
    '@media screen and (max-width: 985px)': {
        display: 'block',
        // margin: "30px 10px 0px 10px",
        padding: "20px 10px",
        '& .MuiAvatar-root': {
            marginLeft: "40px",
        }
    },
})


const Contents = styled(Box)({
    display: "flex",
    flexDirection: "column",
    overflow: "hidden"
})

const ContentDetails = styled(Box)({
    padding: "15px 15px 0px 5px"
})
const FollowerCoin = styled(Box)({
    display: "flex",
    justifyContent: "center",
    borderBottom: "1px solid #dbd9d9",
    // padding: "10px 5px 0px 10px",
    '& img': {
        color: "grey",
        objectFit: 'contain',
        marginRight: "8px",
        width: '18px',
    },
    '@media screen and (max-width: 768px)': {
        justifyContent: "start",
    },
})

const Follower = styled(Box)({
    display: "flex",
    padding: "15px 20px",
    color: "grey",
    width: "50px",
    cursor: 'pointer',
    '@media screen and (max-width: 768px)': {
        padding: '15px 45px 15px 0px',
    }
})

const Coin = styled(Box)({
    display: "flex",
    borderLeft: "1px solid #dbd9d9",
    color: "grey",
    padding: "15px 20px",
    width: "50px",
    cursor: 'pointer',
    '@media screen and (max-width: 768px)': {
        padding: '15px 10px',
    }
})

const ViewPost = styled(Box)({
    display: "flex",
    justifyContent: "center",
    padding: "0px 10px 10px 10px",
    '& img': {
        color: "grey",
        objectFit: 'contain',
        marginRight: "8px",
        width: '18px',
    },
    '@media screen and (max-width: 768px)': {
        justifyContent: "start",
    },
})

const Views = styled(Box)({
    display: "flex",
    borderRight: "1px solid #dbd9d9",
    color: "grey",
    padding: "15px 20px",
    width: "50px",
    cursor: 'pointer',
    '@media screen and (max-width: 768px)': {
        padding: '15px 45px 15px 0px',
    }
})

const Post = styled(Box)({
    display: "flex",
    padding: "15px 20px",
    color: "grey",
    width: "50px",
    cursor: 'pointer',
    '@media screen and (max-width: 768px)': {
        padding: '15px 10px',
    }
})

const MenuArea = styled(Box)({
    backgroundColor: "white",
    color: '#333',
    borderRadius: "5px",
})

const LeftMenuList = styled(Box)({
    padding: "10px 0px 30px 0px",
})

const StyledPopper = styled(Popper)({
    maxHeight: '80vh',
    overflowY: 'auto',
    marginLeft:'20px',
    overflowX: 'hidden',
    boxShadow: 'rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px'
  });
  

const User = styled('div')({
    padding: '5 10 5 20',
    display: 'flex',
    flexDirection:'row',
    alignItems:'center',
    cursor: 'pointer',
    width:'300px',
    '&:hover': {   
        background: '#f4f0f0',
    },
    '& > div': {
        borderRadius:'25px'
    },
    '& img': {
        width:'45px'
    },
    '& .MuiTypography-root': {
        fontSize:'18px',
        fontWeight:'bold',
        marginLeft:'30px',
    }
})

const EmptyDiv = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    width: '300px',
    '&:hover': {   
        background: '#f4f0f0',
    },
});


const StyledMenuItem = withStyles((theme) => ({
    root: {
        fontSize:'1vw !important',
        backgroundColor: (props: any) => props.selected ? "#dafcd4 !important" : 'white',
        color: (props: any) => props.selected ? '#1ea60a' : '#333',
        borderLeft: (props: any) => props.selected ? "7px solid #1ea60a" : "none",
        '&:hover': {
            borderLeft: "7px solid #1ea60a",
        },
        position: 'relative',
        '&:hover .MuiListItemIcon-root': {
            marginLeft: '-7px',
        },
        '& > div > span': {
            fontWeight: (props: any) => props.selected ? "bold" : 500,
            marginLeft: (props: any) => props.selected ? '7px' : '0px',
        },
        '& > div': {
            marginLeft: (props: any) => props.selected ? '-7px' : '0px' // for 7px left border
        }

    },
}))(MenuItem);

// Customizable Area End




// Customizable Area Start

import React, {useState, useEffect} from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar, 
  Box, 
  Button,
  Checkbox, 
  Chip,
  Grid,
  Typography,
  MenuItem, 
  Select, 
  ListItemText, 
  FormControl, 
  TextField,
  InputBase,
  InputAdornment,
  ListSubheader,
  ListItem,
  ListItemAvatar,
  } from '@material-ui/core';
import { useField } from 'formik'
import {styled} from '@material-ui/styles';
import LanguageIcon from '@material-ui/icons/Language';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Link } from 'react-router-dom'

import FormModal, { 
  InputRow, 
  MInput, 
  MCountedTextArea, 
  MFileDrop, 
  MTimePicker, 
  MDatePicker,
  MSelect,
   } from '../../../components/src/FormModal.web'
import { SearchIcon, AddCircleIcon } from '../../../components/src/Icons.web'
import { useDebounce } from '../../../components/src/utils'
import PlusMore from '../../../components/src/PlusMore.web'
import PlusMorePopup from '../../../components/src/PlusMorePopup.web'
import SeeMore from '../../../components/src/SeeMore.web'
import { capitalize } from 'lodash';
//@ts-ignore
import classes from "./CommunityWeb.module.css"

const getName = (user: any) => `${user?.attributes?.first_name} ${user?.attributes?.last_name}`

const ListItemUser = ({user}: {user:any}) => <ListItem >
      <ListItemAvatar  >
        <Avatar src={user.attributes.profile_pic} />
      </ListItemAvatar> 
      <ListItemText primary={getName(user)} />
    </ListItem>

export function CommunityCard(props: any){
	const data = props.data
  if(!data){
    console.error(`CommunityCard passed data undefined`)
    return <></>
  }
  const onJoinClick = props.onJoinClick
	const adminName = `${data.admin?.data?.attributes?.first_name} ${data?.admin?.data?.attributes?.last_name}`
  const membersList = data.members.data.map((user: any)=> <ListItemUser key={user.id} user={user} />)
  const moderatorsList = data.moderators.data.map((user: any)=> <ListItemUser key={user.id} user={user} />)
  const membersAvatar = data
        .members
        .data
        .slice(0, 3)
        .map(
          (m:any) => <Avatar style={{ width: '38px', height: '38px' }} key={m.id} alt={`${m.attributes.first_name} ${m.attributes.last_name}`} src={m.attributes.profile_pic} />
        )
  const moderatorsAvatar = data
        .moderators
        .data
        .slice(0, 4)
        .map(
          (m:any)=> <Avatar key={m.id} alt={`${m.attributes.first_name} ${m.attributes.last_name}`} src={m.attributes.profile_pic} />)
        
  

	return <div className={classes.parentCard} >
                        <Box style={{backgroundColor:"white", overflow: 'hidden', width: '100%',display:"flex",flexDirection:"column", justifyContent:'space-between', borderRadius:"10px",padding:"20px"}}>
                            <Link to={`/community/${data.id}`}>
                            <Box style={{width: '100%',display:"flex",flexDirection:"column"}}>
                            <Box style={{display:"flex",}}>
                              <Avatar className={classes.profilePic} src={data.community_thumbnail_url} style={{width:"67px", height:"67px"}} >
                              </Avatar>
                              <div style={{display:"flex", width: 'calc( 100% - 90px )', overflow: 'hidden',  flexDirection:"column",marginLeft:"20px",justifyContent:"center"}}>
                                  <Typography className={classes.cardName} style={{fontWeight:"bold", color:"#363535", fontSize: '20px',overflow: 'hidden', textOverflow: 'ellipsis'}}>
                                      {data.name}
                                  </Typography>
                                  <div style={{display:"flex", gap:"2ch", width: '160px'}}>
                                     <div style={{display:"flex", justifyContent: 'center', alignItems: 'center'}}>
                                      <LanguageIcon  style={{color:"#424242",fontSize:16,}}/>
                                      <Typography style={{ textTransform: 'capitalize',color:"#4D4D4D", fontSize:14.58,marginLeft:"6px", fontFamily: 'helveticaneue', fontWeight: 300}}>
                                          {data.community_type}
                                      </Typography>
                                     </div>
                                       <Typography style={{ color:"#A7A7A7",fontSize:14.58}}>
                                          {data.post_count || 0} post
                                       </Typography>
                                  </div>
                              </div>
                            </Box>
                            <Box style={{margin:"20px 0px"}}>
                                <Typography style={{color:"rgba(0,0,0,0.3156)",fontSize:14,lineHeight:'24px', wordBreak: 'break-all'}}>
                                  <SeeMore noWrap text={data.description} />
                                </Typography>
                            </Box>
                            <Box style={{display:"flex", flexDirection:"column", gap: '10px'}}>
                                <PreferenceRow >
	                                <div style={{display: "flex", gap: '10px', flex: 1}}>    
	                                    <Typography className={classes.boldHead} style={{fontSize:16, marginRight:"10px", color: '#000', lineHeight:'24.52px', fontWeight: 600}}>
	                                        Preference:
	                                    </Typography>
	                                    <Typography className={classes.boldValue} style={{color:"#2A353F",fontSize:16,paddingTop:1,width:"max-content",marginRight:"15px"}}>
	                                        {data.preferences.map((p: any)=>p?.slice?.(0,1)).join(', ')}
	                                    </Typography>
	                                </div>
	                                <div style={{display: "flex", gap: '10px', flex: 2}}>
	                                    <Typography className={classes.boldHead} style={{fontSize:16,marginRight:"10px", color: '#000', fontWeight: 600}}>
	                                        Category:
	                                    </Typography>
	                                    <Typography className={classes.boldValue} style={{color:"#2A353F",fontSize:16,paddingTop:1}}>
	                                       {data.categories[0]} 
                                         {
                                           data.categories.length>1 && <>
                                              <PlusMorePopup heading="Category" listItems={data.categories}>
                                                {` +${data.categories.length - 1}`}
                                              </PlusMorePopup>
                                            </>
                                          }
	                                    </Typography>
	                                </div>
                                </PreferenceRow>
                                <div style={{display:"flex",marginBottom:"10px"}}>
                                    <Typography className={classes.boldHead} style={{fontSize:16,marginRight:"10px", minWidth: '110px', color: '#000', fontWeight: 600}}>
                                        Sub Category:
                                    </Typography>
                                    <Typography className={classes.boldValue} style={{color:"#2A353F",fontSize:16,paddingTop:1}}>
                                        {data.Sub_categories[0]}
                                        {
                                          data.Sub_categories.length>1 && <>
                                            <PlusMorePopup heading="Sub Category" listItems={data.Sub_categories}>
                                              {` +${data.Sub_categories.length-1}`}
                                            </PlusMorePopup>
                                          </>
                                        }
                                    </Typography>
                                </div>
                                <div style={{display:"flex",marginBottom:"10px",alignItems: 'center'}}>
                                    <Typography className={classes.boldHead} style={{fontSize:16,marginRight:"10px",paddingTop:10,color:"#000"}}>
                                        Moderators :
                                    </Typography>
                                      <PlusMorePopup style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} heading="Moderators" listItems={moderatorsList}>
                                        <AvatarGroup >
                                            {
                                              moderatorsAvatar
                                            }
                                        </AvatarGroup>
                                        {
                                          data.moderators.meta.total > 4 && <>
                                              {` +${data.moderators.meta.total-4}`}
                                          </>
                                        }
                                      </PlusMorePopup>
                                </div>
                                <div style={{display:"flex",marginBottom:"10px", alignItems: 'center'}}>
                                    <Typography className={classes.boldHead} style={{fontSize:16,marginRight:"10px",color:"#000",paddingTop:10,}}>
                                        Total Members:
                                    </Typography>
                                    <PlusMorePopup style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} heading="Members" listItems={membersList}>
                                      <AvatarGroup >
                                        {
                                          membersAvatar
                                        }
                                      </AvatarGroup>
                                      {
                                        data.members.meta.total > 3 && <>
                                            {` +${data.members.meta.total-3}`}
                                        </>
                                      }
                                    </PlusMorePopup>

                                </div>
                                <div style={{display:"flex"}}>
                                    <Typography className={classes.boldHead} style={{fontSize:16,marginRight:"10px",color:"#000",paddingTop:5}}>
                                        Admin:
                                    </Typography>
                                    <div style={{display:"flex"}}>
                                    <Avatar alt={adminName} src={data.admin.data.attributes.profile_pic} style={{width:"38px",height:"38px", marginRight:"5px"}}/>
                                    <Typography className={classes.boldValue} style={{color:"#2A353F",fontSize:16, maxWidth: '20ch', overflow: 'hidden', textOverflow: 'ellipsis', paddingTop:6,}}>
                                        {adminName} 
                                    </Typography>
                                    </div>
                                </div>
                            </Box>
                            </Box>
                          </Link>
                            <Box style={{ margin: "10px 0px", fontWeight: 'bold', fontSize: '12px', marginTop: '20px'}}>
                              { data.in_community ? <UnjoinedButtonBase grey={data.is_admin||data.is_moderator} onClick={onJoinClick}>Unjoin</UnjoinedButtonBase> : <RoundedButtonBase disabled={data.community_type === "private" && data.join_request_pending} variant="outlined" onClick={onJoinClick}>Join Now</RoundedButtonBase>}
                              {/*  */}
                            </Box>
                          </Box>
                    </div>
}



const RoundedButtonBase = styled(Button)((props:any)=>({
  position: 'relative',
  padding: '10px 0',
  width: '100%',
  fontWeight: 'bold',
  display: 'flex',
  textAlign: 'center',
  justifyContent: 'center',
  textTransform: "capitalize",
  backgroundColor: props.variant==="outlined"?"#FFF ":'#009c05',
  color: props.variant==="outlined"?(props.disabled?"grey":"#009c05"):'white',
  borderRadius: '30px',
  border: props.variant==="outlined"?(props.disabled?"2px solid grey":"2px solid #009c05"):"none",
  '&:hover': {
    backgroundColor: props.variant==="outlined"?"#CCC":'#009c05',
  }
}))
const UnjoinedButtonBase = styled(Button)((props:any)=>({
  position: 'relative',
  padding: '10px 0',
  width: '100%',
  fontWeight: 'bold',
  display: 'flex',
  textAlign: 'center',
  justifyContent: 'center',
  backgroundColor: '#b8b6b6',
  color: 'white !important',
  borderRadius: '30px',
  border: "none",
  textTransform: "capitalize",
  '&:hover': {
    backgroundColor: props.grey?'#b8b6b6':'#009c05',
  }
}))


const PreferenceRow = styled('div')((props: any) => ({
  display: "flex", 
  gap: '5px',
  "@media (max-width: 1500px)": {
    flexDirection: 'column'
  }
}))

/**
* remove linked categories when a preference changes, by calling props.syncF callback
*/
export const FilterSelectPreference = (props: any) => {
  const [field, meta, helpers] = useField('preference_ids')
  const [cfield, cmeta, chelpers] = useField('category_ids')
  const [stfield, stmeta, sthelpers] = useField('sub_category_ids')

  React.useEffect(() => {
    if(props.syncF){
      props.syncF(field.value, cfield.value, chelpers.setValue, stfield.value, sthelpers.setValue)
    }
  }, [field.value, cfield.value, chelpers.setValue, stfield.value, sthelpers.setValue])
  return <FilterSelect {...props} />
}

/**
* remove linked sub-categories when category changes
*/
export const FilterSelectCategory = (props: any) => {
  const [field, meta, helpers] = useField('sub_category_ids')
  const [cfield, cmeta, chelpers] = useField('category_ids')
  React.useEffect(() => {
    if(props.syncF){
      props.syncF(cfield.value, field.value, helpers.setValue)
    }
  }, [field.value, cfield.value])
  console.log({ sub_category_ids: field, meta })
  return <FilterSelect {...props} />
}

export const FilterSelect = (props: any) => { 

	const [field, meta, helpers] = useField(props.name)
	const [fItems, setFItems] = useState<any[]>(props.displayItems || props.items||[])
	const [search, setSearch] = React.useState('')
	const debouncedSearch = useDebounce(search, 300)
  const onSearchChange = (e: any) => {
    setSearch(e.target.value)
  }
	  React.useEffect(() => {
	  	const newItems = (props.displayItems||props.items)
	    		.filter(
	    			(item: any) => (item.label || item.name || '').toLowerCase().indexOf(
	    					debouncedSearch.toLowerCase()
	    				) > -1
	    		)

	    setFItems(newItems)
	  }, [debouncedSearch, setFItems])
  
  // edge case fix, when items are loaded async. with API, and intial value is []
  React.useEffect(() => setFItems(props.displayItems || props.items), [props.items, props.displayItems])

	const renderValue = (selected: any) => {
		if(!selected?.length)
			return <Typography color="textSecondary">Select</Typography>
    // console.log(props.valuesdata,props.items,selected, "valuesdatatat:::")
    // console.log(selected,selected.map((s: any)=>props?.items?.find((i:any)=>i?.id===s.id)).map((i:any)=>i?.label || i?.name).join(', '), "selecteddata:::")
		// // return selected.map((s: any)=>props?.items?.find((i:any)=>i?.id===s)).map((i:any)=>i?.label || i?.name).join(', ') 
    return selected.map((s: any) => props?.items?.find((i: any) => typeof s == "object" ? i?.id === s?.id : i?.id === s)).map((i: any) => i?.label || i?.name).join(', ')
	}
  const {items, ...otherProps} = props
	// open menu below the input field
	const MenuProps = {
	  anchorOrigin: {
	    vertical: "bottom",
	    horizontal: "left"
	  },
	  transformOrigin: {
	    vertical: "top",
	    horizontal: "left"
	  },
	  getContentAnchorEl: null
	}
	return <MSelect multiple displayEmpty MenuProps={MenuProps} renderValue={renderValue} placeholder="Select" inputProps={{placeholder: "Select"}} {...otherProps}>
				<ListSubheader style={{ backgroundColor: 'white' }}>
                        <TextField
                          
                          onChange={onSearchChange}
                          size="small"
                          autoFocus
                          placeholder="Search"
                          fullWidth
                          style={{borderBottom:"1px solid #edebeb", marginTop:"10px", }}
                          onClick={e => e.stopPropagation()}
                          InputProps={{
                              startAdornment: (
                              <InputAdornment position="start">
                                  <SearchIcon width="16px" style={{marginBottom: '5px'}} />
                              </InputAdornment>
                              ),
                              disableUnderline:true,
                          }}
                          // onChange={(e) =>  helpers.setValue({searchText:e.target.value})}
                          onKeyDown={(e) => {
                              if (e.key !== "Escape") {
                              // Prevents autoselecting item while typing (default Select behaviour)
                              e.stopPropagation();
                              }
                          }}
                        />
                        { props.loading && (
                              <Typography style={{paddingLeft: '15px'}}>
                                Loading ....
                              </Typography>)
                        }
                        {props.name === "sub_category_ids" && !props.loading && props.displayItems.length === 0 ? (
                              <Typography style={{paddingLeft: '15px'}}>
                                Select a category first
                              </Typography>)
                              : props.name === "sub_category_ids" && fItems && fItems.length === 0 && (
                                <Typography style={{paddingLeft: '15px'}}>
                                  No match found
                                </Typography>)}
                        {props.name === "category_ids" && !props.loading && props.items.length === 0 ? (
                              <Typography style={{paddingLeft: '15px'}}>
                                Select a preference first
                              </Typography>)
                              :props.name === "category_ids" && fItems && fItems.length === 0 && (
                                <Typography style={{paddingLeft: '15px'}}>
                                  No match found
                                </Typography>)}
                        {props.name === "preference_ids" && fItems && fItems.length === 0 && (
                                <Typography style={{paddingLeft: '15px'}}>
                                  No match found
                                </Typography>)}
                       
                    </ListSubheader>
                    
		{fItems?.map?.((item)=>(
                      <MenuItem style={{
                        height:"50px",
                        display:"flex",
                        padding:"20px 20px",
                        flexDirection: 'row-reverse',
                        whiteSpace: 'normal',
                      }} key={item.id} value={item.id}>
	                      <Checkbox 
	                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
	                        checkedIcon={<CheckBoxIcon fontSize="small" style={{color:"#1ea60a"}} />}
	                        checked={field.value?.indexOf?.(item.id) > -1} 
	                      />
	                      <ListItemText>
	                            {item.label || item.name}
	                      </ListItemText>
	                      </MenuItem>))}
	  </MSelect>
}


// Customizable Area End
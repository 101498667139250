// Customizable Area Start
import MessageEnum, {
  getName,
} from '../../../framework/src/Messages/MessageEnum';
import { IBlock } from '../../../framework/src/IBlock';
import { runEngine } from '../../../framework/src/RunEngine';
import { Message } from '../../../framework/src/Message';
import { Block } from '../../../framework/src/Block';

import { toast } from 'react-toastify'
import { getStorageData, logoutUser } from '../../../framework/src/Utilities';

let config = require('../../../framework/src/config')

const THRESHOLD = 5 * 60 * 1000 // 5min, expire token before it has expired


/**
* @todo implement incremental caching
*/
export default class RestApiClientBlock<Entity> extends Block {
  private props: any;
  private expireRoutineRunning = false;
  private static instance: RestApiClientBlock<any>;

  private constructor() {
    super();
    runEngine.attachBuildingBlock(this as IBlock, [
      getName(MessageEnum.RestAPIRequestMessage),
    ]);
  }

  static getInstance(): RestApiClientBlock<any> {
    if (!RestApiClientBlock.instance) {
      RestApiClientBlock.instance = new RestApiClientBlock();
    }
    return RestApiClientBlock.instance;
  }

  async receive(from: string, message: Message) {
    console.log('Custom API Request Message**' + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIRequestMessage) === message.id) {
      const uniqueApiCallId = message.messageId;
      const {
        RestAPIRequestMethodMessage: method,
        RestAPIResponceEndPointMessage: endpoint,
        RestAPIRequestHeaderMessage: headers,
        RestAPIRequestBodyMessage: body,
        NavigationPropsMessage: props,
      } = message.properties;
      this.props = props;
      this.makeApiCall(uniqueApiCallId, method, endpoint, headers, body);
    }
  }

  async makeApiCall(
    uniqueApiCallId: string,
    method: string,
    endpoint: string,
    headers: any,
    body: string
  ) {

    if(headers?.token) {
      if(!this.isTokenValid()) {
        this.handleTokenExpired()
        return
      }
    }

    let fullURL = endpoint.indexOf('://') === -1 ? config.baseURL + '/' + endpoint : endpoint
    let apiResponseMessage = new Message(
      getName(MessageEnum.RestAPIResponceMessage)
    );
    apiResponseMessage.addData(
      getName(MessageEnum.RestAPIResponceDataMessage),
      uniqueApiCallId
    );

    try {
      let response = await fetch(fullURL, {
        method: method.toUpperCase(),
        headers: headers.length ? JSON.parse(headers) : headers,
        body: body,
      });

      let responseJson = await response.json();

      //setting Response
      apiResponseMessage.addData(
        getName(MessageEnum.RestAPIResponceSuccessMessage),
        responseJson
      );

      console.log('Api Response' + JSON.stringify(responseJson));
    } catch (error) {
      runEngine.debugLog('RestApiClient Error', error);
      //setting Error
      console.log('Api Error' + JSON.stringify(error));
      apiResponseMessage.addData(
        getName(MessageEnum.RestAPIResponceErrorMessage),
        'An error has occuured. Please try again later.'
      );
    }

    if (this.props) {
      apiResponseMessage.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );
    }
    this.send(apiResponseMessage);
  }

  /**
  * Check if token is valid
  */
  isTokenValid = async() => {
    const authToken = await getStorageData('authToken');

    if(!authToken) {
      console.log("Custom API Request Message**", "line120")
      return false // when multiple expired requests are thrown at once
    }
    const jwt = JSON.parse(atob(authToken.split('.')[1]))
    const expiryTime = new Date(jwt.exp * 1000)
    const currentTime = new Date()
    const adjustedTime = new Date(currentTime.getTime() + THRESHOLD)
    if(adjustedTime>expiryTime) {
      return false
    }
    return true
  }

  /**
  * Logout user by clearing localStorage, sending SessionUpdateMessage, rasing toast and redirecting
  */
  handleTokenExpired = async() => {
    if(this.expireRoutineRunning) return
    this.expireRoutineRunning = true
    const msg = new Message(getName(MessageEnum.SessionUpdateMessage))
    msg.addData(
      getName(MessageEnum.SessionUpdateData),
      undefined
    )
    this.send(msg)

    await logoutUser()
    toast.warn('Session Expired')
    setTimeout(() => {
      // window.location.href = window.location.origin // @todo use react-router-dom
      this.expireRoutineRunning = false // 
    }, 2000)
  }
}


// Customizable Area End
import EditEvaluationController, {
    Props,
} from "./EditEvaluationController";
import React from "react";
// Customizable Area Start
import { styled } from '@material-ui/styles';
import { Box, Grid, Typography, Button, Divider, Avatar, Link, MenuItem } from '@material-ui/core'
import TopNavBar from "../../../components/src/TopNavbar";
import LeftMenuWeb from "../../../components/src/LeftMenuWeb";
import { Formik, Form, useField } from "formik";
import { InputRow, MInput, MSelect } from "../../../components/src/FormModal.web";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { MCountedTextArea } from "../../Events2/src/Components.web";
import Maps from '../../maps/src/Maps.web'
import SearchSelect from "../../../components/src/SearchSelect.web";
import OverlayLoader from '../../../components/src/Loader.web';
import UploadButton from '../../../components/src/UploadButtonnew.web';
import UploadButtonSingle from '../../../components/src/UploadButtonSingle.web';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

// @ts-ignore
import * as config from "../../../framework/src/config";
// Customizable Area End
export default class EditEvalutaion extends EditEvaluationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        let intialValues = {

            level5_pri_name: "",
            level5_pri_comment: "",
            level5_sec_name: [],
            level5_sec_comment: "",

            level4_pri_name: "",
            level4_pri_comment: "",
            level4_sec_name: [],
            level4_sec_comment: "",

            level3_pri_name: "",
            level3_pri_comment: "",
            level3_sec_Name: [],
            level3_sec_comment: "",

            level2_pri_Name: "",
            level2_pri_comment: "",
            level2_sec_name: [],
            level2_sec_comment: "",

            level1_pri_Name: "",
            level1_pri_comment: "",
            level1_sec_Name: [],
            level1_sec_comment: "",

            project_Sponsor: "",
            Reference_Document: [],
            Remarks: "",
            Status:"Requested",
            project_finance: "",
            Finance_Sheet_document: "",
            orgainsation_name: this.state.projectEvalintialValues?.organization_name ,
            location: '',
            Project_Name: '',
            Upload_Project_Proposal_Document: [],
            project_Lead: '',
            industry: this.state.projectEvalintialValues?.industry ,
            Organisation_Objective_id: this.state.projectEvalintialValues?.objective_id,
            project_id: this.state.projectEvalintialValues?.project_id,
            conclusion: ""
        }
        let intialvalue = {
            
            level5_pri_name: this.state?.edit?.project_approvers?.[4]?.data?.attributes?.primary_approver?.data?.id,
            level5_pri_comment: this.state?.edit?.project_approvers?.[4]?.data?.attributes?.primary_approver_comment ,
            level5_sec_name: this.handlesecondryapproverlevel1(4),
            level5_sec_comment: this.state?.edit?.project_approvers?.[4]?.data?.attributes?.secondary_approver_comment ,

            level4_pri_name: this.state?.edit?.project_approvers?.[3]?.data?.attributes?.primary_approver?.data?.id ,
            level4_pri_comment: this.state?.edit?.project_approvers?.[3]?.data?.attributes?.primary_approver_comment,
            level4_sec_name: this.handlesecondryapproverlevel1(3), 
            level4_sec_comment: this.state?.edit?.project_approvers?.[3]?.data?.attributes?.secondary_approver_comment,

            level3_pri_name: this.state?.edit?.project_approvers?.[2]?.data?.attributes?.primary_approver?.data?.id, 
            level3_pri_comment: this.state?.edit?.project_approvers?.[2]?.data?.attributes?.primary_approver_comment ,
            level3_sec_Name: this.handlesecondryapproverlevel1(2),
            level3_sec_comment: this.state?.edit?.project_approvers?.[2]?.data?.attributes?.secondary_approver_comment,

            level2_pri_Name: this.state?.edit?.project_approvers?.[1]?.data?.attributes?.primary_approver?.data?.id ,
            level2_pri_comment: this.state?.edit?.project_approvers?.[1]?.data?.attributes?.primary_approver_comment ,
            level2_sec_name: this.handlesecondryapproverlevel1(1), 
            level2_sec_comment: this.state?.edit?.project_approvers?.[1]?.data?.attributes?.secondary_approver_comment ,
            
            level1_pri_Name: this.state?.edit?.project_approvers?.[0]?.data?.attributes?.primary_approver?.data?.id ,
            level1_pri_comment: this.state?.edit?.project_approvers?.[0]?.data?.attributes?.primary_approver_comment ,
            level1_sec_Name: this.handlesecondryapproverlevel1(0) ,
            level1_sec_comment: this.state?.edit?.project_approvers?.[0]?.data?.attributes?.secondary_approver_comment ,
           
            Reference_Document: this.state.edit?.reference_documents,
            Remarks: this.state?.edit?.remarks ,
            Status: this.state?.edit?.status,
            project_finance: this.state?.edit?.project_finance ,
            Finance_Sheet_document:this.state.edit?.finance_sheet,
            orgainsation_name: this.state?.edit?.organization_name  ,
            location: this.state?.edit?.location ,
            industry: this.state.edit?.industry ,
            Upload_Project_Proposal_Document: this.state.edit?.proposal_documents,
            project_Lead: this.state.edit?.requested_by_project_lead?.data?.id ,
            project_Sponsor: this.state?.edit?.project_sponsors?.data?.id ,
            Organisation_Objective_id: this.state?.edit?.project_objective_id,
            project_id: this.state?.edit?.project_id ,
            Project_Name: this.state.edit?.project_name ,
            conclusion: this.state?.edit?.conclusion
        }
        
        let doc_url = ""
        let fileId = ""
        let finance_doc = ""
        let doc_size = ""
        
        if (this.state?.edit?.finance_sheet) {
            
            finance_doc = this.state.edit?.finance_sheet.filename
            fileId = this.state.edit?.finance_sheet.id
            doc_size = this.state.edit?.finance_sheet.size
            doc_url = this.state.edit?.finance_sheet.url
        }
        // Customizable Area End
        return (
            // Customizable Area Start
            <MainBox>
                <OverlayLoader loading={this.state.loading} />
                <Box><TopNavBar navigation={this.props.navigation} id={""} /></Box>
                <OuterGrid container spacing={3}>
                    <LeftGrid item xs={12} sm={12} md={3} lg={3}>
                        <Box ><LeftMenuWeb navigation={this.props.navigation} id={""} /></Box>
                    </LeftGrid>
                    <MiddleGrid item xs={12} sm={12} md={6} lg={6} >
                        <div style={{ display: "flex", alignItems: "center", marginBottom:"15px" }} >
                            <span onClick={this.props.navigation.goBack} style={{ margin: "13px 0px 0px 12px", cursor: "pointer" }} >ESG Journey</span>
                            <div style={{ margin: "10px 0px 0px 12px", }}  > <ArrowBackIosIcon /> </div>
                            <Typography style={{paddingTop: '14px', fontWeight: 'bold'}}> Project Evaluation </Typography>
                        </div>
                        <Formik enableReinitialize
                            initialValues={Object.keys(this.state.edit).length!=0?intialvalue:intialValues}
                            onSubmit={this.onSubmitForm}
                            validationSchema={this.validationSchema}
                        >
                            {({values, handleChange, errors, touched, setFieldValue,setTouched, isValid
                            }) => (
                                <Form translate={undefined} >
                                    <FlexSpaceBetween style={{ gap: 6 }}>
                                        <div style={{ flex: 1 ,padding:'0px 0px'}}>
                                            <InputRow 
                                            label={<ApplyLabel>Organization Name*</ApplyLabel>} 
                                            input={<><MInput style={{padding:'0px 0px'}}  disabled name="orgainsation_name" /></>} 
                                            />
                                        </div>
                                        <div style={{ flex: 1}}>
                                            <InputRow  
                                            label="Location*"  
                                            input={<Maps autoCompleteStyles={undefined} customBorder={""} input {...this.props} />} 
                                            />
                                        </div>
                                    </FlexSpaceBetween>
                                    <FlexSpaceBetween style={{ gap: 6 }}>
                                        <div style={{ flex: 1 }}>
                                            <InputRow 
                                            label={<ApplyLabel>Industry*</ApplyLabel>} 
                                            input={<><MInput  disabled  name="industry" /></>} 
                                            />
                                        </div>
                                        <div style={{ flex: 1 }}>
                                            <InputRow 
                                            label={<ApplyLabel>Organisation Objective ID</ApplyLabel>} 
                                            input={<><MInput disabled name="Organisation_Objective_id" /></>} 
                                            />
                                        </div>
                                    </FlexSpaceBetween>
                                    <FlexSpaceBetween style={{ gap: 6 }}>
                                        <div style={{ flex: 1 }}>
                                            <InputRow 
                                            label={<ApplyLabel>Project ID*</ApplyLabel>} 
                                            input={<><MInput disabled name="project_id" /></>} 
                                            />
                                        </div>
                                        <div style={{ flex: 1 }}>
                                            <InputRow 
                                            label={<ApplyLabel>Project Name*</ApplyLabel>} 
                                            input={<><MInput name="Project_Name" /></>} 
                                            />
                                        </div>
                                    </FlexSpaceBetween>
                                    <div style={{ margin: '10px 0', display: 'flex', flexDirection: 'column' }}>
                                        <label style={{fontSize: '15.35px', color: '#404040',marginBottom:'15px' }}>Upload Project Proposal Document*</label>
                                        <UploadButton
                                            onDelete={this.removeAttachments}
                                            setFileInState={this.setFilesArray}
                                            name="Upload_Project_Proposal_Document"
                                            label="Upload Attachment"
                                            multiple
                                        />
                                    </div>
                                    {/* with Avatar */}
                                    <FlexColoumn>
                                        <InputRow 
                                            data-test-id="ProjectLead"
                                            label={<ApplyLabel>Requested By Project Lead*</ApplyLabel>} 
                                            input={<> <SearchSelect
                                            data-test-id="SearchSelectProjectLead"
                                            name="project_Lead"
                                            label={"Select User"}
                                            pagination={this.state.sponsorPagination}
                                            placeholder="Select User"
                                            hasMore={!!this.state?.sponsorPagination?.next_page}
                                            nextPage={this.paginationSponsorOption}
                                            loading={(!!this.state?.sponsorsearchcallId)}
                                            onSearch={this.handleSponsorSearch}
                                            allItems={this.state?.sponsor}
                                            items={this.state?.sponsor}
                                            SelectItem={SelectItemUser}
                                            SelectedItem={SelectedItem}
                                            multiple={false}
                                            scrollHeight={'275px'}
                                            selectProps={{ style: { minHeight: '48px' } }}
                                            getOptionValue={(user: any) => `${user.first_name} ${user.last_name}`}
                                        /></>} />
                                        <InputRow 
                                            data-test-id="ProjectSponsor"
                                            label={<ApplyLabel>Project Sponsor*</ApplyLabel>} 
                                            input={<> <SearchSelect
                                            data-test-id="ProjectSponsorSearchSelect"
                                            name="project_Sponsor"
                                            label={"Select User"}
                                            placeholder="Select User"
                                            pagination={this.state.sponsorPagination}
                                            hasMore={!!this.state?.sponsorPagination?.next_page}
                                            nextPage={this.paginationSponsorOption}
                                            loading={(!!this.state?.sponsorsearchcallId)}
                                            onSearch={this.handleSponsorSearch}
                                            allItems={this.state?.sponsor}
                                            items={this.state?.sponsor}
                                            multiple={false}
                                            scrollHeight={'275px'}
                                            selectProps={{ style: { minHeight: '48px' } }}
                                           
                                            SelectItem={SelectItemUser}
                                            SelectedItem={SelectedItem}
                                            getOptionValue={(user: any) => `${user.first_name} ${user.last_name}`}
                                        /></>} />
                                        <label>Reference Document</label>
                                        <UploadButton
                                            multiple
                                            setFileInState={this.setFilesArray}
                                            name="Reference_Document"
                                            label="Reference Document"
                                            onDelete={this.removeReferenceAttachments}
                                            
                                        />
                                        <InputRow 
                                        label={<ApplyLabel>Remarks*</ApplyLabel>} 
                                        input={<><MInput name="Remarks" /></>} 
                                        />
                                        <InputRow 
                                        label={<ApplyLabel>Status*</ApplyLabel>} 
                                        input={
                                        <MSelect name="Status" disabled={this.state.status!=="You are authorized to perform this action"}>
                                            <MenuItem value="Requested">Requested</MenuItem>
                                            <MenuItem value="Approved">Approved</MenuItem>
                                            <MenuItem value="Rejected">Rejected</MenuItem>
                                            <MenuItem value="On Hold">On Hold</MenuItem>
                                            <MenuItem value="Need More Details">Need More Details</MenuItem>
                                            <MenuItem value="send for further details"> Send for Further details</MenuItem>
                                        </MSelect>} />
                                        <InputRow 
                                        label={<ApplyLabel>Project Finance*</ApplyLabel>} 
                                        input={<><MInput name="project_finance" /></>} 
                                        />
                                    </FlexColoumn>
                                    <FlexSpaceBetween>
                                        <DownloadButton >
                                            <ArrowDownwardIcon color="inherit" />
                                            <Link style={{textTransform:'none'}} href={`${config.baseURL}/bx_block_project_evaluation/project_evaluation/download_finance_sheet/`} >Download Sample Finance Report</Link>
                                        </DownloadButton>
                                        <UploadButtonSingle
                                            fileId= {fileId}
                                            filename={finance_doc}
                                            filesize={doc_size}
                                            fileurl={doc_url}
                                            maxSize={20 * 1024 * 1024}
                                            name="Finance_Sheet_document"
                                            label="Upload Final Finance Sheet"
                                            onDelete={this.removeFinanceSheetAttachments}
                                            multiple
                                        />
                                    </FlexSpaceBetween>
                                    <label style={{ color: 'grey', marginTop: '10px' }}> Approver* </label>

                                    <Divider />

                                    <label >
                                        <ApplyLabel style={{ marginTop: '20px' }}>Level 1*</ApplyLabel>
                                    </label>
                                    <label style={{ marginTop: '10px' }}>
                                        <ApplyLabel style={{ marginTop: '20px' }}>Primary Approval*</ApplyLabel>
                                    </label>
                                    <FlexSpaceBetween style={{ gap: 7 }}>
                                        <div style={{ flex: 1 }}>
                                            <InputRow data-test-id="Level1" label={<ApplyLabel >Name*</ApplyLabel>} input={<>
                                                <SearchSelect
                                                    data-test-id="Level1SearchSelect"
                                                    name="level1_pri_Name"
                                                    label={"Select User"}                                           
                                                    placeholder="Select User"
                                                    pagination={this.state.userPagination}
                                                    hasMore={!!this.state?.userPagination.next_page}
                                                    nextPage={this.paginateUsersOptions}
                                                    loading={!!this.state?.userSearchCallId}
                                                    onSearch={this.handleUserSearch}
                                                    allItems={this.state?.created_by}
                                                    items={this.state?.created_by}

                                                    multiple={false}
                                                    scrollHeight={'275px'}
                                                    selectProps={{ style: { minHeight: '38px' } }}
                                                    
                                                    SelectItem={SelectItemUser}
                                                    SelectedItem={SelectedItem}
                                                    getOptionValue={(user: any) => `${user.first_name} ${user.last_name}`}
                                                />

                                            </>} />
                                        </div>
                                        <div style={{ flex: 2 }}>
                                            <InputRow 
                                            label={<ApplyLabel >Comment*</ApplyLabel>} 
                                            input={<><MInput name="level1_pri_comment" /></>} 
                                            />
                                        </div>
                                    </FlexSpaceBetween>
                                    <label style={{ marginTop: '10px' }}>
                                        <ApplyLabel style={{ marginTop: '20px' }}>Secondary Approval</ApplyLabel>
                                    </label>
                                    <FlexSpaceBetween style={{ gap: 7 }}>
                                        <div style={{ flex: 1 }}>
                                            <InputRow 
                                            data-test-id="Level1Sec"
                                            label={<ApplyLabel >Name</ApplyLabel>} 
                                            input={
                                                <AssignToSelect
                                                    data-test-id="Level1SecSearchSelect"
                                                    hasMore={!!this.state?.userPagination?.next_page}
                                                    nextPage={this.paginateUsersOptions}

                                                    name="level1_sec_Name"
                                                    label={"Select Users"}
                                                    placeholder="Select Users"

                                                    loading={(!!this.state?.userSearchCallId)}
                                                    onSearch={this.handleUserSearch}
                                                    allItems={this.state?.created_by}
                                                    items={this.state?.created_by}
                                                    selectProps={{ style: { minHeight: '38px' } }}
                                                    
                                                    SelectItem={SelectItemUser}
                                                    SelectedItem={SelectedItem}
                                                    getOptionValue={(user: any) => `${user.first_name} ${user.last_name}`}
                                                />
                                            } />
                                        </div>
                                        <div style={{ flex: 2 }}>
                                            <InputRow 
                                            label={<ApplyLabel >Comment</ApplyLabel>} 
                                            input={<><MInput name="level1_sec_comment" /></>} 
                                            />
                                        </div>
                                    </FlexSpaceBetween>
                                    <label >
                                        <ApplyLabel style={{ marginTop: '20px' }}>Level 2*</ApplyLabel>
                                    </label>

                                    <label style={{ marginTop: '10px' }}>
                                        <ApplyLabel style={{ marginTop: '20px' }}>Primary Approval*</ApplyLabel>
                                    </label>
                                    <FlexSpaceBetween style={{ gap: 7 }}>
                                        <div style={{ flex: 1 }}>
                                            <InputRow 
                                            data-test-id="Level2"
                                            label={<ApplyLabel >Name*</ApplyLabel>} 
                                            input={<>
                                                <SearchSelect
                                                    data-test-id="Level2SearchSelect"
                                                    SelectItem={SelectItemUser}
                                                    SelectedItem={SelectedItem}
                                                    name="level2_pri_Name"
                                                    label={"Select User"}
                                                    placeholder="Select User"
                                                    
                                                    hasMore={!!this.state?.userPagination?.next_page}
                                                    nextPage={this.paginateUsersOptions}
                                                    loading={!!this.state?.userSearchCallId}
                                                    onSearch={this.handleUserSearch}
                                                    allItems={this.state?.created_by}
                                                    items={this.state?.created_by }   
                                                    multiple={false}
                                                    scrollHeight={'275px'}
                                                    selectProps={{ style: { minHeight: '38px' } }} 
                                                    getOptionValue={(user: any) => `${user.first_name} ${user.last_name}`}
                                                />
                                            </>} />
                                        </div>
                                        <div style={{ flex: 2 }}>
                                            <InputRow 
                                            label={<ApplyLabel >Comment*</ApplyLabel>} 
                                            input={<><MInput name="level2_pri_comment" /></>} 
                                            />
                                        </div>
                                    </FlexSpaceBetween>
                                    <label style={{ marginTop: '10px' }}>
                                        <ApplyLabel style={{ marginTop: '20px' }}>Secondary Approval</ApplyLabel>
                                    </label>
                                    <FlexSpaceBetween style={{ gap: 7 }}>
                                        <div style={{ flex: 1 }}>
                                            <InputRow 
                                            data-test-id="Level2Sec"
                                            label={<ApplyLabel >Name</ApplyLabel>} 
                                            input={<>
                                                <Level2ToSelect
                                                    data-test-id="Level2SecSearchSelect"
                                                    name="level2_sec_Name"
                                                    onSearch={this.handleUserSearch}
                                                    placeholder="Select Users"
                                                    
                                                    hasMore={!!this.state?.userPagination?.next_page}
                                                    nextPage={this.paginateUsersOptions}
                                                    loading={(!!this.state?.userSearchCallId)}
                                                    label={"Select Users"}
                                                    
                                                    allItems={this.state?.created_by}
                                                    
                                                    selectProps={{ style: { minHeight: '38px' } }}
                                                    items={this.state?.created_by}
                                                    SelectItem={SelectItemUser}
                                                    SelectedItem={SelectedItem}
                                                    getOptionValue={(user: any) => `${user.first_name} ${user.last_name}`}
                                                />
                                            </>} />
                                        </div>
                                        <div style={{ flex: 2 }}>
                                            <InputRow 
                                            label={<ApplyLabel >Comment</ApplyLabel>} 
                                            input={<><MInput name="level2_sec_comment" /></>} 
                                            />
                                        </div>
                                    </FlexSpaceBetween>
                                    <label >
                                        <ApplyLabel style={{ marginTop: '20px' }}>Level 3</ApplyLabel>
                                    </label>

                                    <label style={{ marginTop: '10px' }}>
                                        <ApplyLabel style={{ marginTop: '20px' }}>Primary Approval</ApplyLabel>
                                    </label>
                                    <FlexSpaceBetween style={{ gap: 7 }}>
                                        <div style={{ flex: 1 }}>
                                            <InputRow 
                                            data-test-id="Level3"
                                            label={<ApplyLabel >Name</ApplyLabel>} 
                                            input={<> <SearchSelect
                                                data-test-id="Level3SearchSelect"
                                                hasMore={!!this.state?.userPagination?.next_page}
                                                nextPage={this.paginateUsersOptions}
                                                name="level3_pri_name"
                                                label={"Select User"}
                                                placeholder="Select User"
                                                
                                                
                                                loading={!!this.state?.userSearchCallId}
                                                onSearch={this.handleUserSearch}
                                                allItems={this.state?.created_by}
                                                items={this.state?.created_by}
                                                multiple={false}
                                                scrollHeight={'275px'}
                                                selectProps={{ style: { minHeight: '38px' } }}
                                                
                                                SelectItem={SelectItemUser}
                                                SelectedItem={SelectedItem}
                                                getOptionValue={(user: any) => `${user.first_name} ${user.last_name}`}
                                            /></>} />
                                        </div>
                                        <div style={{ flex: 2 }}>
                                            <InputRow 
                                            label={<ApplyLabel >Comment</ApplyLabel>} 
                                            input={<><MInput name="level3_pri_comment" /></>} 
                                            />
                                        </div>
                                    </FlexSpaceBetween>
                                    <label style={{ marginTop: '10px' }}>
                                        <ApplyLabel style={{ marginTop: '20px' }}>Secondary Approval</ApplyLabel>
                                    </label>
                                    <FlexSpaceBetween style={{ gap: 7 }}>
                                        <div style={{ flex: 1 }}>
                                            <InputRow 
                                            data-test-id="Level3Sec"
                                            label={<ApplyLabel >Name</ApplyLabel>} 
                                            input={<>
                                                <Level3ToSelect
                                                    data-test-id="Level3SecSearchSelect"
                                                    loading={(!!this.state?.userSearchCallId)}
                                                    onSearch={this.handleUserSearch}
                                                    allItems={this.state?.created_by}
                                                    items={this.state?.created_by }
                                                    selectProps={{ style: { minHeight: '38px' } }}
                                                    hasMore={!!this.state?.userPagination?.next_page}
                                                    nextPage={this.paginateUsersOptions}
                                                    
                                                    SelectItem={SelectItemUser}
                                                    SelectedItem={SelectedItem}
                                                    name="level3_sec_Name"
                                                    label={"Select Users"}
                                                    placeholder="Select Users"
                                                    getOptionValue={(user: any) => `${user.first_name} ${user.last_name}`}
                                                />
                                            </>} />
                                        </div>
                                        <div style={{ flex: 2 }}>
                                            <InputRow 
                                            label={<ApplyLabel >Comment</ApplyLabel>} 
                                            input={<><MInput name="level3_sec_comment" /></>} 
                                            />
                                        </div>
                                    </FlexSpaceBetween>
                                    <label >
                                        <ApplyLabel style={{ marginTop: '20px' }}>Level 4</ApplyLabel>
                                    </label>
                                    <label style={{ marginTop: '10px' }}>
                                        <ApplyLabel style={{ marginTop: '20px' }}>Primary Approval</ApplyLabel>
                                    </label>
                                    <FlexSpaceBetween style={{ gap: 7 }}>
                                        <div style={{ flex: 1 }}>
                                            <InputRow 
                                            data-test-id="Level4"
                                            label={<ApplyLabel >Name</ApplyLabel>} 
                                            input={<>
                                                <SearchSelect
                                                    data-test-id="Level4SearchSelect"
                                                    allItems={this.state?.created_by}
                                                    items={this.state?.created_by }
                                                    
                                                    hasMore={!!this.state?.userPagination?.next_page}
                                                    nextPage={this.paginateUsersOptions}
                                                    loading={!!this.state?.userSearchCallId}
                                                    onSearch={this.handleUserSearch}
                                                    
                                                    name="level4_pri_name"
                                                    label={"Select User"}
                                                    placeholder="Select User"

                                                    multiple={false}
                                                    scrollHeight={'275px'}
                                                    selectProps={{ style: { minHeight: '38px' } }}
                                                   
                                                    SelectItem={SelectItemUser}
                                                    SelectedItem={SelectedItem}
                                                    getOptionValue={(user: any) => `${user.first_name} ${user.last_name}`}
                                                />
                                            </>} />
                                        </div>
                                        <div style={{ flex: 2 }}>
                                            <InputRow 
                                            label={<ApplyLabel >Comment</ApplyLabel>} 
                                            input={<><MInput name="level4_pri_comment" /></>} 
                                            />
                                        </div>
                                    </FlexSpaceBetween>
                                    <label style={{ marginTop: '10px' }}>
                                        <ApplyLabel style={{ marginTop: '20px' }}>Secondary Approval</ApplyLabel>
                                    </label>
                                    <FlexSpaceBetween style={{ gap: 7 }}>
                                        <div style={{ flex: 1 }}>
                                            <InputRow 
                                            data-test-id="Level4Sec"
                                            label={<ApplyLabel >Name</ApplyLabel>} 
                                            input={<>
                                                <Level4ToSelect
                                                    name="level4_sec_name"
                                                    data-test-id="Level4SecSearchSelect"
                                                    placeholder="Select Users"
                                                    
                                                    label={"Select Users"}
                                                    loading={(!!this.state?.userSearchCallId)}
                                                    onSearch={this.handleUserSearch}
                                                    allItems={this.state?.created_by }
                                                    items={this.state?.created_by }
                                                    selectProps={{ style: { minHeight: '56px' } }}
                                                    
                                                    SelectItem={SelectItemUser}
                                                    SelectedItem={SelectedItem}
                                                    hasMore={!!this.state?.userPagination?.next_page}
                                                    nextPage={this.paginateUsersOptions}
                                                    getOptionValue={(user: any) => `${user.first_name} ${user.last_name}`}
                                                />
                                            </>} />
                                        </div>
                                        <div style={{ flex: 2 }}>
                                            <InputRow 
                                            label={<ApplyLabel >Comment</ApplyLabel>} 
                                            input={<><MInput name="level4_sec_comment" /></>} 
                                            />
                                        </div>
                                    </FlexSpaceBetween>
                                    <label >
                                        <ApplyLabel style={{ marginTop: '20px' }}>Level 5</ApplyLabel>
                                    </label>
                                    <label style={{ marginTop: '10px' }}>
                                        <ApplyLabel style={{ marginTop: '20px' }}>Primary Approval</ApplyLabel>
                                    </label>
                                    <FlexSpaceBetween style={{ gap: 7 }}>
                                        <div style={{ flex: 1 }}>
                                            <InputRow 
                                            data-test-id="Level5"
                                            label={<ApplyLabel >Name</ApplyLabel>} 
                                            input={<> <SearchSelect
                                                data-test-id="Level5SearchSelect"
                                                name="level5_pri_name"
                                                label={"Select User"}
                                                placeholder="Select User"
                                                SelectItem={SelectItemUser}
                                                SelectedItem={SelectedItem}
                                                hasMore={!!this.state?.userPagination?.next_page}
                                                nextPage={this.paginateUsersOptions}
                                                loading={!!this.state?.userSearchCallId}
                                                onSearch={this.handleUserSearch}
                                                allItems={this.state?.created_by }
                                                items={this.state?.created_by }
                                                multiple={false}
                                                
                                                selectProps={{ style: { minHeight: '56px' } }}
                                                scrollHeight={'275px'}
                                                
                                                getOptionValue={(user: any) => `${user.first_name} ${user.last_name}`}
                                            /></>} />
                                        </div>
                                        <div style={{ flex: 2 }}>
                                            <InputRow 
                                            label={<ApplyLabel >Comment</ApplyLabel>} 
                                            input={<><MInput name="level5_pri_comment" /></>} 
                                            />

                                        </div>
                                    </FlexSpaceBetween>
                                    <label style={{ marginTop: '10px' }}>
                                        <ApplyLabel style={{ marginTop: '20px' }}>Secondary Approval</ApplyLabel>
                                    </label>
                                    <FlexSpaceBetween style={{ gap: 7 }}>
                                        <div style={{ flex: 1 }}>
                                            <InputRow 
                                            data-test-id="Level5Sec"
                                            label={<ApplyLabel >Name</ApplyLabel>} 
                                            input={<>
                                                <Level5ToSelect
                                                    name="level5_sec_name"
                                                    data-test-id="Level5SecSearchSelect"
                                                    hasMore={!!this.state?.userPagination?.next_page}
                                                    nextPage={this.paginateUsersOptions}
                                                    loading={(!!this.state?.userSearchCallId)}
                                                    label={"Select Users"}
                                                    placeholder="Select Users"
                                                    onSearch={this.handleUserSearch}
                                                    allItems={this.state?.created_by}
                                                    items={this.state?.created_by}
                                                    selectProps={{ style: { minHeight: '56px' } }}
                                                    SelectItem={SelectItemUser}
                                                    
                                                    SelectedItem={SelectedItem}
                                                    getOptionValue={(user: any) => `${user.first_name} ${user.last_name}`}
                                                />
                                            </>} />
                                        </div>
                                        <div style={{ flex: 2 }}>
                                            <InputRow 
                                            label={<ApplyLabel >Comment</ApplyLabel>} 
                                            input={<><MInput name="level5_sec_comment" /></>} 
                                            />
                                        </div>
                                    </FlexSpaceBetween>
                                    <InputRow 
                                    label={<ApplyLabel> Conclusion</ApplyLabel>} 
                                    input={
                                        <MCountedTextArea name="conclusion" placeholder="conclusion" />
                                    } />
                                    <RoundedButtonBase style={{ marginTop: '20px' }}
                                        type="submit"
                                        variant="contained"
                                        disabled = {!isValid}
                                    >
                                        Save
                                    </RoundedButtonBase>
                                </Form>
                            )}
                        </Formik>
                    </MiddleGrid>
                </OuterGrid>
            </MainBox >
            // Customizable Area End
        )}}


// Customizable Area Start
const FlexColoumn = styled('div')({ display: 'flex',flexDirection: 'column',gap: '13px'})

const ApplyLabel = styled('div')({ fontSize: '15.35px', color: '#404040', fontWeight:600 })

const SelectItemUser = ({ item, value }: { item: any, value: any }) => {

    return <>
        <Grid container>
            <Grid item xs={12} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}>
                <div style={{ 
                    display: 'flex', 
                    alignItems: 'center', }}
                >
                    <Avatar 
                    src={(item?.profile_pic)} 
                    aria-label="profile-pic" 
                    style={{width: "30px", height: "30px", marginRight: '1.5rem' }}>
                    </Avatar>
                    <SelectItemUserText >{`${item?.name || ''}`}</SelectItemUserText>
                </div>
                <span style={{marginLeft:"15px", fontSize: "12px",fontWeight: "bold"}} >
                    {`${item?.email}`}
                </span>
                
            </Grid>
        </Grid>
    </>
}

export const SelectedItem = ({ items, value }: { items: any, value: any }) => {
    
    return <>
        <div style={{ 
            display: 'flex', 
            alignItems: 'center', 
            flexWrap: 'wrap' }}
        >
            {(items || []).map((selectedValue: any, i: any) => {
                return (<Typography key={i} align="left">
                    <div style={{ 
                        display: 'flex', 
                        alignItems: 'center' }}
                    >
                        <Avatar className='ml10' style={{ width: '30px', height: '30px', }} src={selectedValue.profile_pic} aria-label="profile-pic"></Avatar>
                        <span className='ml5'>{`${selectedValue.name}` || ''}</span>
                    </div>
                </Typography>)
            })}
        </div>
    </>
}




const MainBox = styled(Box)({
    backgroundColor: "#edebeb",
    display: "flex",
    flexWrap: 'wrap',
    flexDirection: "column",
    paddingBottom: "20px",
    overflowX: "hidden",
});
const MiddleGrid = styled(Grid)({
    width: 'calc(100vw - 300px)',
    maxWidth: 'unset',
    marginTop: '40px',
    flexBasis: 'unset',
    justifyContent: 'center',
    background: 'white',
    '@media screen and (max-width: 768px)': {
        width: '100%'
    }
});

const OuterGrid = styled(Grid)({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
}); 

const LeftGrid = styled(Grid)({
    maxWidth: '300px',
    '@media screen and (max-width: 985px)': {
        maxWidth: '200px',
    },
    '@media screen and (max-width: 768px)': {
        display: 'none',
        visibility: 'hidden',
    },
});

const RoundedButtonBase = styled(Button)({
    backgroundColor: '#009c05',
    color: 'white',
    borderRadius: '5px',
    padding: '10px 40px',
    float: 'right',
    '&:hover': {
        backgroundColor: '#009c05',
    },
    '@media screen and (max-width:600px)': {
        width: '100%'
    },
    '@media screen and (min-width:769px) and (max-width:1200px)': {
        width: '100%'
    }
})

const SelectItemUserText = styled('span')({
    overflow: 'hidden',
    textOverflow: 'ellipsis'
})

export const Level4ToSelect = (props: any) => {

    const [field] = useField('level4_pri_name')

    const items = props.items?.filter((i: any) => i.id !== field.value)
    const allItems = props.allItems?.filter((i: any) => i.id !== field.value)
    
    return <SearchSelect
        name="level4_sec_name"
        onSelectInput={props.onSelectInput}
        label={"Select Users"}
        placeholder="Select Users"

        selectProps={props.selectProps}
        hasMore={props.hasMore}
        nextPage={props.nextPage}
        loading={props.loading}
        onSearch={props.onSearch}
        allItems={allItems}
        items={items}
        
        SelectItem={SelectItemUser}
        SelectedItem={SelectedItem}
        getOptionValue={(user: any) => `${user.first_name} ${user.last_name}`}
    />
}


 export const AssignToSelect = (props: any) => {

    const [field] = useField('level1_pri_Name')

    const items = props.items?.filter((i: any) => i.id !== field.value)
    const allItems = props.allItems?.filter((i: any) => i.id !== field.value)

    return <SearchSelect

        name={props.name}
        label={"Select Users"}
        placeholder="Select Users"

        selectProps={props.selectProps}
        hasMore={props.hasMore}
        nextPage={props.nextPage}
        loading={props.loading}
        onSearch={props.onSearch}
        allItems={allItems}
        items={items}
        
        SelectItem={SelectItemUser}
        SelectedItem={SelectedItem}
        onSelectInput={props.onSelectInput}
        onClose = {props.onClose}
        getOptionValue={(user: any) => `${user.first_name} ${user.last_name}`}
    />
}

const FlexSpaceBetween = styled("div")({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 0",
    // paddingBottom: '0',
    width: "100%",
    "@media screen and (max-width:600px)": {
        flexDirection: "column",
        alignItems: "normal",
    },
    "@media screen and (min-width:769px) and (max-width:1200px)": {
        flexDirection: "column",
        alignItems: "normal",
    },
});

export const Level3ToSelect = (props: any) => {

    const [field] = useField('level3_pri_name')

    const items = props.items?.filter((i: any) => i.id !== field.value)
    const allItems = props.allItems?.filter((i: any) => i.id !== field.value)

    return <SearchSelect
        name="level3_sec_Name"
        label={"Select Users"}
        onSelectInput={props.onSelectInput}
        placeholder="Select Users"
        selectProps={props.selectProps}
        hasMore={props.hasMore}
        nextPage={props.nextPage}
        loading={props.loading}
        onSearch={props.onSearch}
        allItems={allItems}
        items={items}
        
        SelectItem={SelectItemUser}
        SelectedItem={SelectedItem}
        getOptionValue={(user: any) => `${user.first_name} ${user.last_name}`}
    />
}


export const Level2ToSelect = (props: any) => {

    const [field] = useField('level2_pri_Name')
    const items = props.items?.filter((i: any) => i.id !== field.value)
    const allItems = props.allItems?.filter((i: any) => i.id !== field.value)
   
    return <SearchSelect
        name="level2_sec_name"
        label={"Select Users"}
        placeholder="Select Users"
        selectProps={props.selectProps}
        hasMore={props.hasMore}
        nextPage={props.nextPage}
        loading={props.loading}
        onSearch={props.onSearch}
        allItems={allItems}
        onSelectInput={props.onSelectInput}
        items={items}
        
        SelectItem={SelectItemUser}
        SelectedItem={SelectedItem}
        getOptionValue={(user: any) => `${user.first_name} ${user.last_name}`}
    />
}

const DownloadButton = styled(Button)({

    color: '#009c05',
    width: '300px',
    border: '2px solid #009c05',
    height: '50px',
    display: 'flex',
    fontSize: '15px',
    fontWeight: 'bold',
    borderRadius: '30px',
    justifyContent: 'space-between',
})

export const Level5ToSelect = (props: any) => {

    const [field] = useField('level5_pri_name')
    const items = props.items?.filter((i: any) => i.id !== field.value)
    const allItems = props.allItems?.filter((i: any) => i.id !== field.value)
   
    return <SearchSelect
        name="level5_sec_name"
        label={"Select Users"}
        placeholder="Select Users"

        selectProps={props.selectProps}
        hasMore={props.hasMore}
        nextPage={props.nextPage}
        loading={props.loading}
        onSearch={props.onSearch}
        allItems={allItems}
        items={items}
        
        onSelectInput={props.onSelectInput}
        SelectItem={SelectItemUser}
        SelectedItem={SelectedItem}
        getOptionValue={(user: any) => `${user.first_name} ${user.last_name}`}
    />
}
// Customizable Area End




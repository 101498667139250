import React, { Component } from 'react'

// Customizable Area Start
// Customizable Area End

import CustomisableusersubscriptionsController, {
    Props,
    configJSON,
  } from "./CustomisableusersubscriptionsController";
export default class PaymentStripe extends CustomisableusersubscriptionsController {  
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
     <>

     
         {window.location.replace('this.state.stripelink')}
       
        {/* <a target='blank' href={this.state.stripelink}></a> */}
    
     </>
      // Customizable Area End
    )
  }
}

// Customizable Area Start
// Customizable Area End


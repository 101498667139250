Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ActivityFeed";
exports.labelBodyText = "ActivityFeed Body";

exports.btnExampleTitle = "CLICK ME";


exports.labelWriteAPost = "Write a post"
exports.labelSearch = "Search"
exports.labelFilterBy = "Filter by:"
exports.labelCommunity = "Community"
exports.labelCategory = "Category"
exports.labelSubCategory = "Sub Category"



exports.getAllPostsEndpoint = "bx_block_posts/posts"
// exports.getAllPostsEndpoint = "bx_block_posts/posts/my_posts"
exports.postDeleteEndpoint = "bx_block_posts/posts/:id/"
exports.getFilterPostEndpoint = "bx_block_posts/posts/filter_post"
exports.getAllCategoriesEndpoint = "bx_block_categories/categories/category"
exports.setPostFavEndpoint = "bx_block_favourites/favourites/"
exports.setPostUnFavEndpoint = "bx_block_favourites/favourites/:id"
exports.getAllSubCategoriesEndpoint = "bx_block_categories/sub_categories"
exports.reportPostEndpoint = "bx_block_posts/posts/report_posts"
exports.getAllCommunityPostsEnpoint = "bx_block_communityforum/community/:id/posts"
exports.getAllUserPostsEndpoint = "account_block/accounts"
exports.getPostDataEndpoint = "bx_block_posts/posts/:post_id"
exports.getAllPostCommentEndpoint = "bx_block_posts/comments?post_id=:post_id"
exports.postCommentEndpoint = "bx_block_posts/comments"
exports.postLikeEndpoint = "bx_block_like/likes"
exports.postCommentReplyEndpoint = "bx_block_posts/comments/create_reply"
exports.reportCourseEndpoint = "bx_block_cfcourselisting5/courses/report_courses"
exports.reportJobEndpoint = "bx_block_joblisting2/jobs/report_job"
exports.reportEventEndpoint = "/bx_block_events/events/report_events"
exports.reportServicesEndpoint = "/bx_block_content_management/marketplace_services/report_marketplace_service"
exports.reportProductEndpoint = "/bx_block_cfproductlisting11/products/report_products"
// Customizable Area End
Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.labelFilterBy = "Filter by:"
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "RequestManagement";
exports.labelBodyText = "RequestManagement Body";

exports.btnExampleTitle = "CLICK ME";

exports.requestApiCallEndpoint = "bx_block_request_management/requests";
exports.requestApiCallMethodType = "GET";
exports.getUserList = "bx_block_share/get_users";
exports.addRequest = "bx_block_request_management/requests";
exports.getRequestTypes = "bx_block_request_management/request_types";
exports.getAllRequests = "bx_block_request_management/requests";
exports.getRequestDetails = "bx_block_request_management/requests/:id"
exports.sendResponce = "bx_block_request_management/response";
exports.acceptRejectRequest = "bx_block_request_management/approve_or_reject_request";
exports.filterRequest = "bx_block_request_management/filter_request";


// Customizable Area End

// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'
import { createTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import './app.css'
import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import AuthCheckWrapper from "../../components/src/AuthCheckWrapper.web";
import { ModalContainer } from "react-router-modal";
// import HomeScreen from "../../components/src/HomeScreen";
import Adapters from "../../components/src/Adapters.web";
import TopNav from "../../components/src/TopNav";
import { UserProvider } from "../../components/src/UserProvider";

import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlockWeb";
import EmailAccountRegistrationWeb from "../../blocks/email-account-registration/src/EmailAccountRegistrationWeb";
import ForgotPasswordWeb from "../../blocks/forgot-password/src/ForgotPasswordWeb";
import NewPasswordWeb from "../../blocks/forgot-password/src/NewPasswordWeb";
import DashboardWeb from '../../blocks/dashboard/src/DashboardWeb';
import SettingsWeb from "../../blocks/Settings5/src/SettingsWeb";
import ChangePasswordWeb from "../../blocks/Settings5/src/ChangePasswordWeb";
import ForgotPasswordOTPWeb from "../../blocks/forgot-password/src/ForgotPasswordOTPWeb";
import HelpWeb from '../../blocks/Settings5/src/HepWeb';
import TermsWeb from '../../blocks/Settings5/src/TermsWeb';
import CommunityWeb from '../../blocks/CommunityForum/src/CommunityWeb';
import { LinkedInCallback } from '../../components/src/LinkedinLogin.web'

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import PromoteContent from "../../blocks/PromoteContent/src/PromoteContent";
import Events2 from "../../blocks/Events2/src/Events2.web";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import Share from "../../blocks/share/src/Share.web";
import CustomisableUserProfiles from "../../blocks/CustomisableUserProfiles/src/CustomisableUserProfiles.web";
import TeamBuilder from "../../blocks/TeamBuilder/src/TeamBuilder";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import KarmaPoints from "../../blocks/KarmaPoints/src/KarmaPoints";
import BaselineReporting from "../../blocks/dashboard/src/Recommended-project-management/BaselineReporting.web";
import ReferencePolicy from "../../blocks/dashboard/src/Recommended-project-management/ReferencePolicy.web";
import NewPolicy from "../../blocks/dashboard/src/Recommended-project-management/AddBaselineReporting.web"; 
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import PortfolioManagement from "../../blocks/PortfolioManagement/src/PortfolioManagement";
import ProjectNotes from "../../blocks/ProjectNotes/src/ProjectNotes";
import ProjectPortfolio from "../../blocks/ProjectPortfolio/src/ProjectPortfolio";
import PhotoLibrary3 from "../../blocks/PhotoLibrary3/src/PhotoLibrary3";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Surveys from "../../blocks/Surveys/src/Surveys.web.tsx";
import SurveysDetails from '../../blocks/Surveys/src/SurveysDetails.web.tsx';
import SurveyEnduser from '../../blocks/Surveys/src/SurveyEnduser.web.tsx';
import SelfAssesment from '../../blocks/Surveys/src/SelfAssesment.web.tsx';
import AssesmentDashboard from '../../blocks/Surveys/src/AssesmentDashboard.web.tsx';
import CreateSurveys from "../../blocks/Surveys/src/CreateSurveys.web.tsx";
import VideoLibrary from "../../blocks/VideoLibrary/src/VideoLibrary";
import Maps from "../../blocks/maps/src/Maps";
import EmailNotifications from "../../blocks/EmailNotifications/src/EmailNotifications";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import RolesPermissions2 from "../../blocks/RolesPermissions2/src/RolesPermissions2";
// import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import ContentManagement from "../../blocks/ContentManagement/src/ContentManagement";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import Favourites from "../../blocks/Favourites/src/Favourites";
import ContentModeration from "../../blocks/ContentModeration/src/ContentModeration";
import LeadManagement from "../../blocks/LeadManagement/src/LeadManagement";
import ProjectReporting from "../../blocks/ProjectReporting/src/ProjectReporting";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import SubscriptionBilling from "../../blocks/SubscriptionBilling/src/SubscriptionBilling";
import UploadMedia2 from "../../blocks/UploadMedia2/src/UploadMedia2";
import Polling from "../../blocks/Polling/src/Polling";
import TaskAllocator from "../../blocks/TaskAllocator/src/TaskAllocator";
import DataImportexportcsv from "../../blocks/DataImportexportcsv/src/DataImportexportcsv";
import Scoring from "../../blocks/Scoring/src/Scoring";
import ElasticSearch from "../../blocks/ElasticSearch/src/ElasticSearch";
import CfCourseListing5 from "../../blocks/CfCourseListing5/src/CfCourseListing5.web";
import ReviewAndApproval from "../../blocks/ReviewAndApproval/src/ReviewAndApproval";
import BlogPostsManagement from "../../blocks/BlogPostsManagement/src/BlogPostsManagement";
import Payments from "../../blocks/Payments/src/Payments";
import JobListing2 from "../../blocks/JobListing2/src/JobListing2.web";
import CreateJob from "../../blocks/JobListing2/src/CreateJob.web";
import JobDetails from "../../blocks/JobListing2/src/JobDetails.web";
import ProjectData from "../../blocks/analytics/src/projectListing.web";
import LikeAPost from "../../blocks/LikeAPost/src/LikeAPost";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import OpenApi from "../../blocks/OpenApi/src/OpenApi";
import Videos from "../../blocks/videos/src/Videos";
import AdminConsole3 from "../../blocks/AdminConsole3/src/AdminConsole3";
import CfProductListing11 from "../../blocks/CfProductListing11/src/CfProductListing11";
import CreateProduct from "../../blocks/CfProductListing11/src/CreateProduct.web";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import PaymentAdmin2 from "../../blocks/PaymentAdmin2/src/PaymentAdmin2";
import Settings5 from "../../blocks/Settings5/src/Settings5";
import CfCarbonCalculator from "../../blocks/CfCarbonCalculator/src/CfCarbonCalculator";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import CfFundingPage2 from "../../blocks/CfFundingPage2/src/CfFundingPage2";
import ProjecttaskTracking2 from "../../blocks/ProjecttaskTracking2/src/ProjecttaskTracking2";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import AssessmentTest from "../../blocks/AssessmentTest/src/AssessmentTest";
import AddFriends from "../../blocks/AddFriends/src/AddFriends";
import CommunityForum from "../../blocks/CommunityForum/src/CommunityForum";
import CommunityDetailWeb from "../../blocks/CommunityForum/src/CommunityDetail.web";
import Followers from "../../blocks/Followers/src/Followers";
import RequestManagement from "../../blocks/RequestManagement/src/RequestManagement.web";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import MicrosoftLogin3 from "../../blocks/MicrosoftLogin3/src/MicrosoftLogin3";
import ConversationThreading from "../../blocks/ConversationThreading/src/ConversationThreading";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Analytics from "../../blocks/analytics/src/Analytics";
import CorporateProfileEndUser from "../../blocks/analytics/src/CorporateProfileEndUser";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import RecommendationEngine4 from "../../blocks/RecommendationEngine4/src/RecommendationEngine4";
import DesktopNotifications2 from "../../blocks/DesktopNotifications2/src/DesktopNotifications2";
import AllNotifications from "../../blocks/DesktopNotifications2/src/AllNotifications.web";
import PeopleManagement2 from "../../blocks/PeopleManagement2/src/PeopleManagement2";
import SwitchAccounts from "../../blocks/SwitchAccounts/src/SwitchAccounts";
import ActivityFeed from "../../blocks/ActivityFeed/src/ActivityFeed";
import ReportBlock from "../../blocks/ActivityFeed/src/ReportBlock.web";
import { UserDetailsProvider } from "./Context/ProfileContext";
import AddRequest from "../../blocks/RequestManagement/src/AddRequest.web";
import PaymentSuccesfull from "../../blocks/customisableusersubscriptions/src/PaymentSuccessfull.web";
import PaymentFailed from "../../blocks/customisableusersubscriptions/src/PaymentFailed.web";
import Chat9 from '../../blocks/Chat9/src/Chat9.web'
import PostBlock from "../../blocks/ActivityFeed/src/PostBlock.web";
import ProjectBlock from "../../blocks/Surveys/src/ProjectBlock.web.tsx";
import CourseDetails from "../../blocks/CfCourseListing5/src/CourseDetails.web";
import EventDetails from "../../blocks/Events2/src/EventDetails.web";
import CourseEdit from "../../blocks/CfCourseListing5/src/CourseEdit.web";
import EventEdit from "../../blocks/Events2/src/EventEdit.web";
import ProductDetail from "../../blocks/CfProductListing11/src/ProductDetails.web";
import Services from "../../blocks/alert/src/services.web";
import CreateService from '../../blocks/alert/src/CreateService.web'
import EnquireService from '../../blocks/alert/src/EnquireService.web'
import CorpsStageCoins from '../../blocks/alert/src/CorpsStageCoins.web';
import PurchaseCoins from '../../blocks/alert/src/PurchaseCoins.web';
import CreateTransfer from '../../blocks/alert/src/TransferModal.web';
import ServiceDetails from '../../blocks/alert/src/serviceDetails.web';
import AddSurveyQuestions from '../../blocks/Surveys/src/AddSurveyQuestions.web.tsx';
import ESGJourney from '../../blocks/CustomisableUserProfiles/src/ESGJourneyMenuCard.web'
import UserTagedSurvey from '../../blocks/Surveys/src/UserSurvey.web'
import EnquiryProjectSeekingFund from '../../blocks/Surveys/src/EnquiryProjectSeekingFund.web'
import CorporateProfileForm from "../../blocks/CustomisableUserProfiles/src/CorporateProfileForm1.web"
import CarbonCalculator from "../../blocks/CustomisableUserProfiles/src/carbonCalculatorDownload.web"
import StakeHolderProfile from "../../blocks/CustomisableUserProfiles/src/StakeholderProfiles.web"
import ProjectEvaluation from "../../blocks/CustomisableUserProfiles/src/ProjectEvaluation.web"
import FillSurveys from '../../blocks/Surveys/src/FillSurveys.web'
import PeopleManagement from "../../blocks/PeopleManagement2/src/PeopleManagement.web";
import RiskManageMent from "../../blocks/CustomisableUserProfiles/src/RiskManageMent.web"
import Corporatepermission from "../../blocks/Surveys/src/Corporatepermission.web"
import PermissionDashboard from "../../blocks/Surveys/src/PermissionDashboard.web";
import ProjectManagement from "../../blocks/ProjectPortfolio/src/ProjectManagement.web";
import ImpactDashboard from "../../blocks/ProjectPortfolio/src/ImpactDashboard.web";
import RequestToList from "../../blocks/ProjectPortfolio/src/RequestToList.web";
import RequestToListSuccessfull from "../../blocks/ProjectPortfolio/src/RequestToListSuccessfull.web";
import RequestToListEdit from "../../blocks/ProjectPortfolio/src/RequestToListEdit.web";
import ActivityDashboard from "../../blocks/ProjectPortfolio/src/ActivityDashboard.web";
import DueDiligence from "../../blocks/CustomisableUserProfiles/src/DueDiligence.web"
import CreateDueDiligenceTemplate from "../../blocks/CustomisableUserProfiles/src/CreateDueDiligenceTemplate.web"
import DueDiligenceDetails from "../../blocks/CustomisableUserProfiles/src/DueDiligenceDetails.web"
import ProjectMilestone from "../../blocks/ProjectPortfolio/src/ProjectMilestone.web";
import AddMilestone from "../../blocks/ProjectPortfolio/src/AddMilestone.web";
import ViewMilestone from "../../blocks/ProjectPortfolio/src/ViewMilestone.web";
import EditMilestone from "../../blocks/ProjectPortfolio/src/EditMilestone.web";
// import InitiativeEvaluation from "../../blocks/Surveys/src/InitiativeEvaluation.web";
import EditEvaluation from "../../blocks/Surveys/src/EditEvaluation.web";
import ShowEvaluation from "../../blocks/Surveys/src/ShowEvaluation.web";
import UpdatedReports from "../../blocks/CustomisableUserProfiles/src/UpdatedReports.web";
import RiskOpportunityManagementReport from "../../blocks/CustomisableUserProfiles/src/RiskOpportunityManagementReport.web";
import ProjectActivity from "../../blocks/CustomisableUserProfiles/src/ProjectActivityReport.web";
import ProjectMileStoneReport from "../../blocks/CustomisableUserProfiles/src/ProjectMileStoneReport.web";
import ProjectReport from "../../blocks/CustomisableUserProfiles/src/ProjectReport.web";
import AuditTrail from "../../blocks/ProjectPortfolio/src/AuditTrail.web";
import InitiativeEvaluation from "../../blocks/Surveys/src/InitiativeEvaluation.web";
import MyProjects from "../../blocks/analytics/src/MyProjects.web"
import ProjectEndUser from "../../blocks/analytics/src/ProjectEndUser.web"
import EditSeekingFunds from "../../blocks/analytics/src/EditSeekingFunds.web"
import ProjectDetailEndUser from "../../blocks/analytics/src/ProjectDetailEndUser.web"
import ProjectSurvey from "../../blocks/analytics/src/ProjectSurvey.web"
import RequestToCollaborate from "../../blocks/analytics/src/RequestToCollaborate.web";
import CarbonCalculatorCard from "../../blocks/CfCarbonCalculator/src/CfCarbonCalculator.web"
import CarbonCalculatorCardForm from "../../blocks/CfCarbonCalculator/src/CfCarbonCalculatorForm.web"
import SurveyCards from "../../blocks/Surveys/src/SurveyCards.web"
import ReportingFrameworks from "../../blocks/Surveys/src/ReportingFrameworks.web"
import CfCarbonCalculatorReport from "../../blocks/CfCarbonCalculator/src/CfCarbonCalculatorReport.web"

const routeMap = {
  EditEvaluation: {
    component: EditEvaluation,
    path: "/EditEvaluation/:e_id"
  },
  ShowEvaluation: {
    component: ShowEvaluation,
    path: "/ShowEvaluation/:s_id"
  },
  AuditTrail: {
    component: AuditTrail,
    path: "/AuditTrail/:id"
  },
  LinkedInCallback: {
    component: LinkedInCallback,
    path: "/LinkedinRedirect"
  },
  ActivityDashboard: {
    component: ActivityDashboard,
    path: '/ActivityDashboard/:projectId'
  },
 Corporatepermission: {
    component: Corporatepermission,
    path: "/Corporatepermission"
  },
  PermissionDashboard: {
    component: PermissionDashboard,
    path: "/PermissionDashboard/:type"
  },
 ESGJourney: {
    component: ESGJourney,
    path: "/ESGJourney"
  },
  CorporateProfileForm: {
    component: CorporateProfileForm,
    path:"/CorporateProfileForm"
  },
  CarbonCalculator: {
    component: CarbonCalculator,
    path:"/CarbonCalculator"
  },
  CarbonCalculatorCard: {
    component: CarbonCalculatorCard,
    path:"/CarbonCalculatorCard"
  },
  CarbonCalculatorForm: {
    component: CarbonCalculatorCardForm,
    path:"/CarbonCalculatorCardForm"
  },
  DueDiligence: {
    component: DueDiligence,
    path:"/DueDiligence"
  },
  DueDiligenceDetails: {
    component: DueDiligenceDetails,
    path: "/DueDiligenceDetails/:survey_id"
  },
  CreateDueDiligenceTemplate: {
    component: CreateDueDiligenceTemplate,
    path: "*/CreateDueDiligenceTemplate"
  },
  EditDueDilligence: {
    component: CreateDueDiligenceTemplate,
    path: "*/EditDueDilligence/:id"
  },
  CopyEditDueDilligence: {
    component: CreateDueDiligenceTemplate,
    path: "*/CopyDueDilligence/:id"
  },
  StakeHolderProfile: {
    component: StakeHolderProfile,
    path: "/StakeHolderProfile"
  },
  PromoteContent: {
    component: PromoteContent,
    path: "/PromoteContent"
  },
  MyEvents2: {
    component: Events2,
    path: "/MyMarketplace/Events2"
  },
  Events2: {
    component: Events2,
    path: "/Marketplace/Events2"
  },
  PostCreation: {
    component: PostCreation,
    path: "*/PostCreation",
    modal: true,
  },
  PostEdit: {
    component: PostCreation,
    "path": "*/post-edit/:post_id",
    modal: true,
  },
  Posts: {
    component: Posts,
    path: "/Posts"
  },
  PostDetails: {
    component: PostDetails,
    path: "/PostDetails"
  },
  Share: {
    component: Share,
    path: "/Share"
  },
  CustomisableUserProfiles: {
    component: CustomisableUserProfiles,
    path: "/user/:user_id"
  },
  TeamBuilder: {
    component: TeamBuilder,
    path: "/TeamBuilder"
  },
  VisualAnalytics: {
    component: VisualAnalytics,
    path: "/VisualAnalytics"
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: "/Categoriessubcategories"
  },
  KarmaPoints: {
    component: KarmaPoints,
    path: "/KarmaPoints"
  },
  BaselineReporting: {
    component: BaselineReporting,
    path: "/BaselineReporting"
  },
  ReferencePolicy: {
    component: ReferencePolicy,
    path: "/ReferencePolicy"
  },
  NewPolicy: {
    component: NewPolicy,
    path: "/NewPolicy"
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: "/SocialMediaAccountLoginScreen"
  },
  PortfolioManagement: {
    component: PortfolioManagement,
    path: "/PortfolioManagement"
  },
  ProjectNotes: {
    component: ProjectNotes,
    path: "/ProjectNotes"
  },
  ProjectPortfolio: {
    component: ProjectPortfolio,
    path: "/ProjectPortfolio"
  },
  PhotoLibrary3: {
    component: PhotoLibrary3,
    path: "/PhotoLibrary3"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },
  Surveys: {
    component: Surveys,
    path: "/Surveys"
  },
  SurveysDetails: {
    component: SurveysDetails,
    path: "/SurveysDetails/:survey_id"
  },
  CreateSurveys: {
    component: CreateSurveys,
    path: "*/CreateSurveys"
  },
  EditSurveys: {
    component: CreateSurveys,
    path: "*/EditSurveys/:id"
  },
  CopySurveyTemplate: {
    component: CreateSurveys,
    path: "*/CopySurveyTemplate/:id"
  },
  

  VideoLibrary: {
    component: VideoLibrary,
    path: "/VideoLibrary"
  },
  SurveyEnduser: {
    component: SurveyEnduser,
    path:'/SurveyEnduser/:id'
    
  },
  SelfAssesment: {
    component: SelfAssesment,
    path:'/SelfAssesment'
    
  },
  AssesmentDashboard: {
    component: AssesmentDashboard,
    path:'/AssesmentDashboard'
  },
  Maps: {
    component: Maps,
    path: "/Maps"
  },
  EmailNotifications: {
    component: EmailNotifications,
    path: "/EmailNotifications"
  },
  Contactus: {
    component: Contactus,
    path: "/Contactus"
  },
  AddContactus: {
    component: AddContactus,
    path: "/AddContactus"
  },
  RolesPermissions2: {
    component: RolesPermissions2,
    path: "/RolesPermissions2"
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: "/EmailAccountLoginBlock"
  },
  ContentManagement: {
    component: ContentManagement,
    path: "/ContentManagement"
  },
  Filteritems: {
    component: Filteritems,
    path: "/Filteritems"
  },
  Filteroptions: {
    component: Filteroptions,
    path: "/Filteroptions"
  },
  Favourites: {
    component: Favourites,
    path: "/Favourites"
  },
  ContentModeration: {
    component: ContentModeration,
    path: "/ContentModeration"
  },
  LeadManagement: {
    component: LeadManagement,
    path: "/LeadManagement"
  },
  ProjectReporting: {
    component: ProjectReporting,
    path: "/ProjectReporting"
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/ForgotPassword"
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP"
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword"
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: "/Pushnotifications"
  },
  AllNotifications: {
    component: AllNotifications,
    path: "/notifications",
  },
  SubscriptionBilling: {
    component: SubscriptionBilling,
    path: "/SubscriptionBilling"
  },
  UploadMedia2: {
    component: UploadMedia2,
    path: "/UploadMedia2"
  },
  Polling: {
    component: Polling,
    path: "/Polling"
  },
  TaskAllocator: {
    component: TaskAllocator,
    path: "/TaskAllocator"
  },
  DataImportexportcsv: {
    component: DataImportexportcsv,
    path: "/DataImportexportcsv"
  },
  Scoring: {
    component: Scoring,
    path: "/Scoring"
  },
  ElasticSearch: {
    component: ElasticSearch,
    path: "/ElasticSearch"
  },
  MyCfCourseListing5: {
    component: CfCourseListing5,
    path: "/MyMarketplace/CfCourseListing5"
  },
  CfCourseListing5: {
    component: CfCourseListing5,
    path: "/Marketplace/CfCourseListing5"
  },
  ReviewAndApproval: {
    component: ReviewAndApproval,
    path: "/ReviewAndApproval"
  },
  BlogPostsManagement: {
    component: BlogPostsManagement,
    path: "/BlogPostsManagement"
  },
  Payments: {
    component: Payments,
    path: "/Payments"
  },
  MyJobListing2: {
    component: JobListing2,
    path: "/MyMarketplace/JobListing2"
  },
  JobDetails:{
    component: JobDetails,
    path: '*/job/:id'
  },
  ProjectData:{
    component: ProjectData,
    path: '*/projects/:id',
    modal: true,
  },
  JobListing2: {
    component: JobListing2,
    path: "/Marketplace/JobListing2"
  },
  CreateJob: {
    component: CreateJob,
    path: "*/create-job"
  },
  EditJob: {
    component: CreateJob,
    path: "*/edit-job/:id"
  },
  LikeAPost: {
    component: LikeAPost,
    path: "/LikeAPost"
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: "/SocialMediaAccountRegistrationScreen"
  },
  OpenApi: {
    component: OpenApi,
    path: "/OpenApi"
  },
  Videos: {
    component: Videos,
    path: "/Videos"
  },
  AdminConsole3: {
    component: AdminConsole3,
    path: "/AdminConsole3"
  },
  MyCfProductListing11: {
    component: CfProductListing11,
    path: "/MyMarketplace/CfProductListing11"
  },
  CfProductListing11: {
    component: CfProductListing11,
    path: "/Marketplace/CfProductListing11"
  },
  CreateProduct: {
    component: CreateProduct,
    path: "*/create-product/",
  },
  EditProduct: {
    component: CreateProduct,
    path: "*/edit-product/:id"
  },
  Dashboard: {
    component: Dashboard,
    path: "/Dashboard"
  },
  PaymentAdmin2: {
    component: PaymentAdmin2,
    path: "/PaymentAdmin2"
  },
  Settings5: {
    component: Settings5,
    path: "/Settings5"
  },
  CfCarbonCalculator: {
    component: CfCarbonCalculator,
    path: "/CfCarbonCalculator"
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/EmailAccountRegistration"
  },
  CfFundingPage2: {
    component: CfFundingPage2,
    path: "/CfFundingPage2"
  },
  ProjecttaskTracking2: {
    component: ProjecttaskTracking2,
    path: "/ProjecttaskTracking2"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  AssessmentTest: {
    component: AssessmentTest,
    path: "/AssessmentTest"
  },
  AddFriends: {
    component: AddFriends,
    path: "/AddFriends"
  },
  CommunityForum: {
    component: CommunityForum,
    path: "/CommunityForum"
  },
  CommunityDetail: {
    component: CommunityDetailWeb,
    path: "/community/:id",
  },
  Chat9: {
    component: Chat9,
    path: "/Chat9"
  },
  Followers: {
    component: Followers,
    path: "/Followers"
  },
  RequestManagement: {
    component: RequestManagement,
    path: "/RequestManagement"
  },
  AddRequest: {
    component: AddRequest,
    path: "*/AddRequest",
    modal: true,
  },
  Request: {
    component: AddRequest,
    path: "*/request/:id",
    modal: true
  },
  PaymentSuccesfull: {
    component: PaymentSuccesfull,
    path: "*/PaymentSuccessful/:success",
  },
  PaymentFailed: {
    component: PaymentFailed,
    path: "*/PaymentFailed/:failed",
  },
  Customisableusersubscriptions: {
    component: Customisableusersubscriptions,
    path: "/Customisableusersubscriptions"
  },
  SubscriptionDetails: {
    component: SubscriptionDetails,
    path: "/SubscriptionDetails"
  },
  MicrosoftLogin3: {
    component: MicrosoftLogin3,
    path: "/MicrosoftLogin3"
  },
  ConversationThreading: {
    component: ConversationThreading,
    path: "/ConversationThreading"
  },
  Catalogue: {
    component: Catalogue,
    path: "/Catalogue"
  },
  Analytics: {
    component: Analytics,
    path: "/Analytics"
  },
  CorporateProfileEndUser:{
    component: CorporateProfileEndUser,
    path: "/CorporateProfile/:navigationBarTitleText"
  },
  LandingPage: {
    component: LandingPage,
    path: "/LandingPage"
  },
  RecommendationEngine4: {
    component: RecommendationEngine4,
    path: "/RecommendationEngine4"
  },
  DesktopNotifications2: {
    component: DesktopNotifications2,
    path: "/DesktopNotifications2"
  },
  PeopleManagement2: {
    component: PeopleManagement2,
    path: "/PeopleManagement2"
  },
  PeopleManagement: {
    component: PeopleManagement,
    path: "/PeopleManagement"
  },
  SwitchAccounts: {
    component: SwitchAccounts,
    path: "/SwitchAccounts"
  },
  ProjectManagement: {
    component: ProjectManagement,
    path: "/ProjectManagement"
  },
  RequestToList: {
    component: RequestToList,
    path: "*/RequestToList"
  },
  RequestToListEdit: {
    component: RequestToListEdit,
    path: "*/RequestToListEdit/:id"
  },
  RequestToListSuccessfull: {
    component: RequestToListSuccessfull,
    path: "*/RequestToListSuccessfull"
  },

  ActivityFeed: {
    component: ActivityFeed,
    path: "/ActivityFeed"
  },
  AllActivityFeed: {
    component: ActivityFeed,
    path: "/AllActivityFeed"
  },
  Home: {
    component: EmailAccountLoginBlock,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },
  ProjectSurvey: {
    component: ProjectSurvey,
    path: "*/EndUserSurvey/:id"
  },

  // AlertWeb: {
  //   component: AlertBlock,
  //   path: "/AlertWeb",
  //   modal: true
  // },
  // EmailAccountLoginBlockWeb:{
  //   component: EmailAccountLoginBlockWeb,
  //   path: '/EmailAccountLoginBlockWeb'

  // },
  EmailAccountRegistrationWeb: {
    component: EmailAccountRegistrationWeb,
    path: '/EmailAccountRegistrationWeb'
  },
  ForgotPasswordWeb: {
    component: ForgotPasswordWeb,
    path: "/ForgotPasswordWeb"
  },
  NewPasswordWeb: {
    component: NewPasswordWeb,
    path: "/NewPasswordWeb"
  },
  DashboardWeb: {
    component: DashboardWeb,
    path: "/DashboardWeb",

  },
  SettingsWeb: {
    component: SettingsWeb,
    path: "/SettingsWeb"
  },
  ChangePasswordWeb: {
    component: ChangePasswordWeb,
    path: "/ChangePasswordWeb"
  },
  ForgotPasswordOTPWeb: {
    component: ForgotPasswordOTPWeb,
    path: "/ForgotPasswordOTPWeb"
  },
  HelpWeb: {
    component: HelpWeb,
    path: "/HelpWeb"
  },
  TermsWeb: {
    component: TermsWeb,
    path: "/TermsWeb"
  },
  CommunityWeb: {
    component: CommunityWeb,
    path: "/CommunityWeb",
    PrivateRoute: true
  },
  PostBlock: {
    component: PostBlock,
    "path": "*/post/:post_id",
    modal: true,
  },
  ProjectBlock: {
    component: ProjectBlock,
    "path": "*/project/:project_id",
    modal: true,
  },
  CourseDetails: {
    component: CourseDetails,
    "path": "*/course/:course_id",
    modal: true,
  },
  CourseEdit: {
    component: CourseEdit,
    "path": "*/course-edit/:course_id",
    modal: true,
  },
  EventDetails: {
    component: EventDetails,
    "path": "*/event/:event_id",
    modal: true,
  },
  EventEdit: {
    component: EventEdit,
    "path": "*/event-edit/:event_id",
    modal: true,
  },
  ProductDetail: {
    component: ProductDetail,
    "path": "*/product/:product_id"
  },
  MyServices: {
    component: Services,
    path: "/MyMarketplace/Services",
  },
  Services: {
    component: Services,
    path: "/Marketplace/Services",
  },
  CreateService: {
    component: CreateService,
    path: "*/create-service"
  },
  EditService: {
    component: CreateService,
    path: "*/edit-service/:id"
  },
  // EditService: {
  //   component: EditService,
  //   path: "*/edit-service/:service_Id"
  // },
  EnquireService: {
    component: EnquireService,
    path: "*/enquire-service/:id"
  },
  CorpsStageCoins: {
    component: CorpsStageCoins,
    path: "/CorpsStageCoins"
  },
  CreateTransfer: {
    component: CreateTransfer,
    path: "*/create-transfer"
  },
  PurchaseCoins: {
    component: PurchaseCoins,
    path: "/PurchaseCoins"
  },
  ServiceDetails: {
    component: ServiceDetails,
    path: "*/service/:service_id"
  },  
  AddSurveyQuestions: {
    component: AddSurveyQuestions,
    path: "*/AddSurveyQuestions"
  },
  EditSurveyQuestion: {
    component: AddSurveyQuestions,
    path: "*/EditSurveyQuestion/:id"
  },
  UserSurvey:{
    component: UserTagedSurvey,
    path: "/UserSurvey"
  },
  RiskManagement:{
    component: RiskManageMent,
    path:'/RiskManagement'
  },
  RiskOpportunityManagementReport:{
    component: RiskOpportunityManagementReport,
    path: '/RiskOpportunityManagementReport'
  },
  ProjectActivity:{
    component: ProjectActivity,
    path: '/ProjectActivity'
  },
  ProjectMileStoneReport:{
    component: ProjectMileStoneReport,
    path: '/ProjectMileStoneReport'
  },
  ProjectReport:{
    component: ProjectReport,
    path: '/ProjectReport'
  },
  ProjectEvaluation:{
    component:ProjectEvaluation,
    path:'/ProjectEvaluation/:prod_id'
  },
  CopySurveys: {
    component: EnquiryProjectSeekingFund,
    path: "*/EnquiryProjectSeekingFund/:id"
  },

  ProjectMilestone:{
    component:ProjectMilestone,
    path:'/ProjectMilestone/:projectId'
  },
  AddMilestone: {
    component: AddMilestone,
    path: "*/AddMilestone/:projectId"
  },
  ViewMilestone: {
    component: ViewMilestone,
    path: "*/ViewMilestone/:id"
  },
  EditMilestone: {
    component: EditMilestone,
    path: "*/EditMilestone/:id"
  },
  ImpactDashboard: {
    component: ImpactDashboard,
    path: "/ImpactDashboard"
  },
  UpdatedReports: {
    component: UpdatedReports,
    path:"/Reports"
  },
  InitiativeEvaluation: {
    component: InitiativeEvaluation,
    path: "/InitiativeEvaluation"
  },
  MyProjects: {
    component: MyProjects,
    path: "/my_projects"
  },
  ProjectEndUser: {
    component: ProjectEndUser,
    path: "/all_project_end_user"
  },
  EditSeekingFunds: {
    component: EditSeekingFunds,
    path: "*/all_project_end_user/seeking_funds/:id"
  },
  EditSeekingFundsMyProjects: {
    component: EditSeekingFunds,
    path: "*/my_projects/seeking_funds/:id"
  },
  ProjectDetailEndUser: {
    component: ProjectDetailEndUser,
    path:"*/project_detail_enduser/:id"
  },
  RequestToCollaborate: {
    component: RequestToCollaborate,
    path:"*/RequestToCollaborate/:projectId",
    modal: true,
  },
  SurveyCards: {
    component: SurveyCards,
    path: "/SurveyCards"
  },
  ReportingFrameworks: {
    component: ReportingFrameworks,
    path: "/ReportingFrameworks"
  },
  ESGJourneyReportingFrameworks: {
    component: ReportingFrameworks,
    path: "/ESGJourneyReportingFrameworks"
  },
  MandatorySurveys: {
    component: Surveys,
    path: "/MandatorySurveys"
  },
  CfCarbonCalculatorReport: {
    component: CfCarbonCalculatorReport,
    path:"/CfCarbonCalculatorReport"
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

const theme = createTheme({
  typography: {
    fontFamily: 'roboto, sans-serif !important'
  },
  palette: {
    primary: {
      main: '#009c05'
    }
  },
  overrides: {
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: "white"
        }
      }
    }
  }
})


const CategoriesSubcategoriesBlock = new Categoriessubcategories()

class App extends Component {

  // componentDidMount(){
  //   setInterval(() => {
  //     console.log("reloadeddddd")
  //     window.location.reload();
  //   }, 600000);
  //   console.log("inside")
  // }

  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        {/* <TopNav /> */}
        <ThemeProvider theme={theme}>
          <UserProvider>
            <AuthCheckWrapper>            
              <ModalContainer modalClassName="react-router-modal__modal_custom" />
              <Share />
              <ReportBlock />
              {WebRoutesGenerator({ routeMap })}
              <ToastContainer />
            </AuthCheckWrapper>
          </UserProvider>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    );
  }
}

export default App;

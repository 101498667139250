// Customizable Area Start

export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const LikeIcon = require('../assets/ic_thumbsup.png')
export const ShareIcon = require('../assets/ic_share.png')
export const CommentIcon = require('../assets/ic_comment.png')
export const ThumbGreenIcon = require('../assets/ic_thumbgreen.png')
export const ArrowDownIcon = require('../assets/ic_arrow_down.png')
export const TopContributorIcon = require('../assets/ic_topcontributor.png')
export const ColabIcon = require('../assets/ic_colab.png')
export const StarIcon = require('../assets/ic_star.png')
export const OyoAvatar = require('../assets/ic_oyo_profile.png')
export const PostImageSrc = require('../assets/post_image.png')
export const PromotionImage = require('../assets/promotion_image.png')
export const MoreHorizIcon = require('../assets/ic_more_horiz.png')
export const UserProfile = require('../assets/user_profile.png')
export const StarActiveIcon = require('../assets/ic_star_active.png')
export const SearchIcon = require('../assets/ic_search.png')
export const CloseIcon = require("../assets/ic_close.png")
export const LikedIcon = require("../assets/ic_liked.png")
export const SendIcon = require("../assets/ic_send.png")
export const EmogiIcon = require("../assets/smile.png")
export const commentCount = require("../assets/Group_260.png")
export const Smiley = require("../assets/smile.png")


// Customizable Area End
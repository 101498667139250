import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { sendAPIRequest } from '../../../components/src/utils';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import { parseSharePopupMessageData } from "./Messages";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  showModal: boolean;
  token: string;
  users: any[];
  selectedUsers: any[],
  userPaginateCallId?: string;
  userSearchCallId?: string;
  sharePostCallId?: string;
  sharePostScucess: boolean;
  userQuery: any;
  userPagination?: any;
  openModal: boolean;
  messageData: any;
  typeCheck: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ShareController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiGetAllUsersCallId = '';

  validationSchema = Yup.object().shape({
    link: Yup.string().required().label('Link'),
    shared_to_ids: Yup.array().min(1, 'User is a required field.')

  })

  initialValues = {
    link: this.state?.messageData?.link,
    shared_to_ids: []
  }

  async componentDidMount() {
    super.componentDidMount();
    this.onInitialized();
  }

  onInitialized = async() => {
    const authToken = await getStorageData("authToken");
    this.apiGetAllUsersCallId = sendAPIRequest(`${configJSON.getUserList}?account_id=${JSON.parse(this.state?.messageData?.userId || '0')}`, {
      headers: { token: authToken },
    });
  }

  paginateUsersOptions = async() => {
    const authToken = await getStorageData("authToken");
    const page = this.state?.userPagination?.next_page
    if (!page) return
    const { userQuery } = this.state;
    const params = new URLSearchParams()
    params.append("page", page);
    params.append("query", userQuery);
    const callId = sendAPIRequest(`${configJSON.getUserList}?account_id=${JSON.parse(this.state?.messageData?.userId || '0')}&${params.toString()}`, {
      headers: { token: authToken },
    })
    this.setState({
      userPaginateCallId: callId
    })
  }


  handleGetUsersAPIResponse = (json: any) => {
    if (!json?.data) return
    const users = json.data.map((user: any) => ({ id: user.id, name: `${user?.attributes?.first_name} ${user?.attributes?.last_name}     ${user?.attributes?.email}`, profile_pic: user?.attributes?.profile_pic }))
    this.setState({ users, userPagination: json.meta.pagination })
  }

  handleUserSearch = (userQuery: string) => {
    this.setState({ userQuery }, this.searchUser);
  }

  searchUser = async() => {
    const { userQuery } = this.state;
    const authToken = await getStorageData("authToken");
    const params = new URLSearchParams();
    params.append("query", userQuery);
    const callId = sendAPIRequest(`${configJSON.getUserList}?account_id=${JSON.parse(this.state?.messageData.userId || '0')}&${params.toString()}`, {
      headers: { token: authToken },
    })
    this.setState({
      userSearchCallId: callId,
    });
  }

  sharePost = async(data: any) => {
    const { link, shared_to_ids } = data;
    const authToken = await getStorageData("authToken");
    let requestBody = {
      link: this.state?.messageData?.link,
      shareable_type: this.state?.messageData?.shareable_type || this.state?.messageData?.shareableType,
      shareable_id: JSON.parse(this.state?.messageData?.shareable_id || this.state?.messageData?.shareableId || '0'),
      shared_to_ids
    }
    const callId = sendAPIRequest(`${configJSON.sharePost}`, {
      body: requestBody,
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        token: authToken,
      },
    })
    this.setState({
      sharePostCallId: callId,
    });
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SharePopupMessaage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      showModal: true,
      token: "",
      selectedUsers: [],
      userPaginateCallId: '',
      userSearchCallId: '',
      sharePostCallId: '',
      sharePostScucess: false,
      userQuery: '',
      users: [],
      messageData: {},
      openModal: false,
      typeCheck: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    parseSharePopupMessageData(message, this.handleGetSharePopupData);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      runEngine.debugLog("API Message Recived", message);
      if (responseJson && responseJson.errors) {
        this.parseApiErrorResponse(responseJson.errors);
        this.parseApiCatchErrorResponse(responseJson.errors);
      } else if (responseJson) {
        if (apiRequestCallId === this.apiGetAllUsersCallId) {
          this.handleGetUsersAPIResponse(responseJson)
        } else if (apiRequestCallId === this.state?.userPaginateCallId) {
          if (!Array.isArray(responseJson?.data)) return
          const users = [...this.state?.users, ...responseJson.data.map((c: any) => c.attributes)]
          this.setState({ userPaginateCallId: '', userPagination: responseJson?.meta?.pagination, users })
        } else if (apiRequestCallId === this.state?.userSearchCallId) {
          let users: any = [];
          this.setState({ users });
          if (Array.isArray(responseJson?.data)) {
            users = responseJson.data.map((c: any) => c.attributes)
            this.setState({ userSearchCallId: '', userPagination: responseJson?.meta?.pagination, users })
          }
        }
        else if (apiRequestCallId === this.state?.sharePostCallId) {
          this.setState({ sharePostCallId: '', sharePostScucess: true });
          toast("Shared Successfully", { type: 'success' });
          this.setState({ openModal: false });
        } else if (errorReponse) {
          this.parseApiErrorResponse(errorReponse);
          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }
  }

  handleGetSharePopupData = (data: any) => {
    console.log("data console =", data);
    this.initialValues.link = data.link;
    this.setState({ messageData: { ...data }, openModal: true });
    this.setState({typeCheck: data?.shareableType })
  }
  // Customizable Area End
}

import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { sendAPIRequest } from '../../../components/src/utils';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  data?: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtSavedValue: string;
  collaboratorList: any[];
  openModal: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class RequestToCollaborateController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiCollaboratorsId: any;
  requestToCollaboratorId: any

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      txtSavedValue: '',
      collaboratorList: [],
      openModal: true,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    super.componentDidMount();
    const authToken = await getStorageData('authToken');
    if (authToken == null) { this.props.navigation.navigate('Home') }
    this.getModeratorCollaborator()
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
      this.setState({ txtSavedValue: value });
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestId == this.apiCollaboratorsId) {
        if(responseData?.message){
          toast.error(responseData?.message)
        }
        this.setState({ collaboratorList: responseData?.accounts })
      }
      else if(apiRequestId == this.requestToCollaboratorId) {
        toast.success('Request is sent to the collaborator')
        this.onClose()
      }
      else if (responseData && responseData.errors) {
        this.parseApiErrorResponse(responseData.errors);
        this.parseApiCatchErrorResponse(responseData.errors);
      }
    }
    // Customizable Area End
  }


  // Customizable Area Start

  validationSchema = Yup.object().shape({
    description: Yup.string().required().label('Description').min(1).max(1000),
    title: Yup.string().required().label('Request Title'),
  })

  getModeratorCollaborator = async() => {
    const id = this.props.navigation.getParam('projectId')
    const authToken = await getStorageData('authToken');
    this.apiCollaboratorsId = sendAPIRequest(`${configJSON.showModeratorsCollaborators}?project_id=${id}`, {
      method: "GET",
      headers: { token: authToken || '' }
    })
  }

  onClose = () => {
    this.setState({ openModal: false }, () => this.props.navigation.goBack())
  }

  requestCollaborator = async(values: any) => {
    const projectId = this.props.navigation.getParam('projectId')
    const accountId=this.state.collaboratorList?.map((value:any)=>value?.id)
    const authToken = await getStorageData('authToken');
    const body = {
      "data": {
        "title": values?.title,
        "description": values?.description,
        "request_type_id": 23,
        "sender_id": parseInt(this.context?.id),
        "project_id": parseInt(projectId),
        "account_ids":  accountId.toString(),
        "created_by_id": this.context?.id,
    }
    }
    
    this.requestToCollaboratorId = sendAPIRequest(`${configJSON.collaboratorEndPoint}`, {
      method: "POST",
      body,
      headers: {'Content-Type':'application/json' ,token: authToken || ''}
    })
  }
  // Customizable Area End
}

// reference
// https://reactjs.org/docs/context.html
// https://stackoverflow.com/questions/41030361/how-to-update-react-context-from-inside-a-child-component

import React from 'react'
import { setStorageData } from '../../framework/src/Utilities';


export interface User {
	token?: string;
	first_name: string;
	last_name: string;
	profile_pic: string;
	set: (state:any) => void
}

const defaultState: User = {
	set: () => {},
	first_name: "",
	last_name: "",
	profile_pic: ""
};

 const UserContext = React.createContext<User>(defaultState);
export default UserContext;

interface UserProviderProps {
	children?: React.ReactNode;
}

export class UserProvider extends React.Component<UserProviderProps, User> {
	
	set = (s: any) => {
		this.setState(
			{...s, set: this.set}, 
			()=> {
				// @ts-ignore
				localStorage.setItem("user", JSON.stringify(this.state))
				setStorageData('user', JSON.stringify(this.state))
			}
		)
	} 

	state: User = {
		...defaultState,
		set: this.set,
	}

	constructor(props: UserProviderProps){
		super(props)
		const user = localStorage.getItem('user')
		if(user) {
			this.state = {
				...defaultState, 
				set: this.set, 
				...JSON.parse(user)
			}
		}
	}

	render() {
		return <UserContext.Provider value={this.state}>
			{this.props.children}
		</UserContext.Provider>
	}
};

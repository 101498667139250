import React from 'react'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Checkbox,
  IconButton,
  Divider,
  Button,
  Typography,
  TextField,
  Avatar,
  InputAdornment,
  Menu,
  MenuItem,
  ListItemText,
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import { withStyles, styled } from '@material-ui/styles'
import RoundedButton from './RoundedButton.web'

const MoreHorizIcon = require('./ic_more_horiz.png')
const LikeIcon = require('./ic_thumbsup.png')
const ShareIcon = require('./ic_share.png')
const StarIcon = require('./ic_star.png')
const StarActiveIcon = require('./ic_star_active.png')
const CommentIcon = require('./ic_comment.png')
const ThumbGreenIcon = require('./ic_thumbgreen.png')
const OyoAvatar = require('./ic_oyo_profile.png')
const PostImageSrc = require('./post_image.png')
const SurveyGreyIcon= require('./SurveyGrey.png')

interface HeaderProps {
  name?: string;
  profile_pic?: string;
  location?: string;
  created_at?: string;
  avatarLink?: string;
}

interface PostCardProps {
  header?: HeaderProps;
  children?: React.ReactNode;
  onThreeDotClick?: (e: any) => void
  dataId?: any;
}

export default function PostCard({ children, header, onThreeDotClick, dataId }: PostCardProps) {
  const h = header as HeaderProps // type casting to stop TS error
  return <RoundedCard variant="outlined">
    {
      !!header && <CardHeader
        avatar={<Link to={header.avatarLink || ''}>{<Avatar style={{ width: 55, height: 55 }} src={h.profile_pic ? h.profile_pic : ""} />}</Link>}
        title={<Link style={{ fontWeight: 600, fontSize: '1.021rem' }} to={header.avatarLink || ''}>{h?.name} </Link>}
        subheader={`${h?.location || ''} ${h?.created_at || ''}`}
        action={<PostCardDotsMenu onClick={onThreeDotClick} dataId={dataId} />}
      />
    }
    <CardContent style={{ padding: 0 }}>
      {children}
    </CardContent>
  </RoundedCard>
}

export function PostCardDotsMenu(props: any) {
  return <>
    <div onClick={props.onClick} data-id={props.dataId}>
      <IconButton>
        <img src={MoreHorizIcon} width="30px" />
      </IconButton>
    </div>
  </>
}

interface CommonActionsProps {
  likeButton?: React.ReactNode,
  isFavourite?: boolean,
  isHideComment?: boolean,
  isHideStar?: boolean,
  isProject?: boolean,
  onSurveyClick?: () => void,
  onStarClick?: () => void,
  onShareClick?: () => void,
  onCommentClick?: () => void,
}

export function CommonActions({onSurveyClick,isProject, likeButton, onStarClick, isFavourite, onShareClick, onCommentClick, isHideComment, isHideStar }: CommonActionsProps) {
  return <CardActionsStyled>
    <FlexSpaceBetween>
      <CardButtonWrapper>
        {likeButton || <CardButton startIcon={<BtnImg src={LikeIcon} />}>Inspire</CardButton>}
        {!isHideComment && onCommentClick && <CardButton onClick={() => onCommentClick && onCommentClick()} startIcon={<BtnImg src={CommentIcon} />}>Comment</CardButton>}
        {onShareClick && <CardButton onClick={() => onShareClick && onShareClick()} startIcon={<BtnImg src={ShareIcon} />}>Share</CardButton>}
        {isProject && <CardButton onClick={()=> onSurveyClick && onSurveyClick()} startIcon={<BtnImg src={SurveyGreyIcon} />}>Survey</CardButton>}
      </CardButtonWrapper>
      {!isHideStar &&
        <CardButton
          onClick={() => onStarClick && onStarClick()}
          startIcon={<BtnImg src={isFavourite ? StarActiveIcon : StarIcon} />}
        />
      }
    </FlexSpaceBetween>
  </CardActionsStyled>
}

interface ImageProps {
  src: string;
}

export const HorizontalPadding: any = styled('div')({
  padding: '0 20px',

})

const CardActionsStyled = styled(CardActions)({
  padding: '0 20px',
  '@media screen and (max-width: 370px)': {
    padding: '0 0px',
  },
})

export const Image = styled('img')({
  borderRadius: '15px',
  margin: '10px 0',
  minHeight: 'max(250px, 40vh)',
  objectFit: 'cover',
  maxHeight: '350px'
}) as any;

const RoundedCard = styled(Card)({
  borderRadius: '15px',
  padding: '10px 0px',
  margin: '20px 0px'
})

const CardButton = styled(Button)({
  border: 'none',
  color: '#aaaaaa',
  fontWeight: 600,
  textTransform: 'none'
})

const CardButtonWrapper = styled('div')({
  display: 'flex',
  gap: '5px',
  '& @media screen and (max-width: 480px)': {
    gap: '0px',
  }
}) as any;

const BtnImg = styled('img')({
  width: 25,
  '@media screen and (max-width:340px)': {
    width: 18,
  },
}) as any;

const FlexSpaceBetween = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  // flexWrap: 'wrap',
  alignItems: 'center',
  padding: '10px 0',
  width: '100%',
}) as any;

export const SeeMoreText = styled(Typography)({
  fontWeight: 600,
  cursor: 'pointer',
  opacity: 0.75
})

export const PostMainContent = styled('div')({
  padding: '10px 0',
  maxHeight: '350px'
}) as any;

export const PostImage = styled('img')({
  width: '100%',
  borderRadius: '15px',
  margin: '20px 0'
}) as any;
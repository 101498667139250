import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as yup from 'yup'
import { toast } from 'react-toastify';
import { sendAPIRequest } from '../../../components/src/utils';
import { sendEventUpdatedMessage } from './Messages'
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  openModal: boolean;
  eventData: any;
  eventId: string;
  start_date: any,
  end_date: any,
  start_time: any,
  end_time: any,
  removedImages: number[]
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CourseDetailController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getEventDetailCallId = '';
  eventEditRequestId:string = "" 
  allPreferences = [{id: 1, label: "E - Environmental"}, {id: 2, label: "S - Social"}, {id: 3, label: "G - Governance"}]
  phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  async componentDidMount() {
    super.componentDidMount();
    
    const authToken = await getStorageData("authToken")
    if (authToken == null) {
      this.props.navigation.navigate("Home");
    } else {
    }

    this.onInitialized();
    this.loadCategoriesSubCategories()
  }

  loadCategoriesSubCategories= ()=>{
    const msg = new Message(
      getName(MessageEnum.GetAllCategoriesMessage)
    )
    runEngine.sendMessage(msg.id, msg)

    const msg2 = new Message(
      getName(MessageEnum.GetAllSubCategoriesMessage)
    )
    runEngine.sendMessage(msg2.id, msg2)
  }

  onInitialized = async() => {
    const token=await getStorageData("authToken")
    const event_id = this.props.navigation.getParam('event_id')
    this.getEventDetailCallId = sendAPIRequest(configJSON.getEventDataByIdEndpoint.replace(':eid', event_id), {
      method: 'GET',
      headers: { token }
    })
  }

  onCloseModal = () => {
    this.setState({ openModal: false });
    this.props.navigation.goBack();
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReportModalOpenMessage),
      getName(MessageEnum.GetAllCategoriesResponseMessage),
      getName(MessageEnum.GetAllSubCategoriesResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: '',
      openModal: true,
      eventId: '',
      eventData: {},
      start_date: '',
      end_date: '',
      start_time: '',
      end_time: '',
      removedImages:[],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      
      if (responseJson && responseJson.errors) {
        // this.setState({ refresh: false });
        this.parseApiErrorResponse(responseJson.errors);
        this.parseApiCatchErrorResponse(responseJson.errors);
      } else if (this.getEventDetailCallId === apiRequestCallId){
        const event_unique_id = responseJson.data.attributes?.event_unique_id
        // const unique_ids = event_unique_id ? event_unique_id.substring(0, 5) : ''
        const event_id_1 = event_unique_id ? event_unique_id.substring(0, 5) : ''
        const event_id_2 = event_unique_id ? event_unique_id.substring(5, 10) : ''
        const eventData = { ...responseJson.data.attributes, id: responseJson.data.id, event_id_1,  event_id_2}
        this.setState({ 
          eventData, eventId: responseJson.data.id, start_date: eventData.start_date, end_date: eventData.end_date
        })
      } else if ( apiRequestCallId === this.eventEditRequestId ) {
        this.setState({ openModal: false });
        if(responseJson?.data?.attributes){
          const eventData = { ...responseJson.data.attributes, id: responseJson.data.id}
          toast.success("Event Updated Successfully")
          sendEventUpdatedMessage(eventData)
        }
        this.props.navigation.goBack()
      }
       
  }
    // Customizable Area End

  }

  // Customizable Area Start

  editFormSchema = yup.object({
    name: yup.string().label('Event Name').required().max(255),
    link: yup.string().url().required().label('Link'),
    start_date: yup.string().required().label('Start Date'),
    end_date: yup.string().required().label('End Date'),
    start_time: yup.string().required().label('Start Time'),
    end_time: yup.string().required().label('End Time'),
    location: yup.string().required().label('Location'),
    preference_ids: yup.array().min(1, "Select atleast one Preference").label('Preferences'),
    category_ids: yup.array().required().min(1, "Please select a Category").label('Category'),
    sub_category_ids: yup.array().required().min(1, "Please select a Sub Category").label('Sub Category'),
    language: yup.string().required().label('Language'),
    participation_coins: yup.number().min(0, "Can not be less than 0").required().label('Participation Coins'),
    description: yup.string().required().max(1000).label('Description'),
    image: yup
      .mixed()
      .label('Image')
      // @ts-ignore
      .maxFileSize("Max allowed file size is 20Mb", 20*1024*1024)
  })

  handleEditFormSubmit = async(formData: any) => {
    const token=await getStorageData("authToken")
    const { event_id_1="", event_id_2="", ...data } = formData;
    const start_time = formData.start_time.toLocaleTimeString('en-US', {hour: 'numeric', minute: 'numeric'})
    const end_time = formData.end_time.toLocaleTimeString('en-US', {hour: 'numeric', minute: 'numeric'})
    const start_date = formData.start_date.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })
    const end_date = formData.end_date.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })
    if(start_date > end_date){
      toast.warn("End date can not be less than start");
      return;
    }
    if((start_date === end_date) && (data.start_time.getHours() > data.end_time.getHours())){
        toast.warn("End time can not be less than Start");
        return;
    }
    if((start_date === end_date) && data.start_time.getHours() == 12 && data.end_time.getHours() == 23){
      toast.warn("End time can not be less than Start");
      return;
  }
    const body = new FormData();
    (['preference_ids',
          'category_ids',
          'sub_category_ids',]).forEach((id: any) => {
          body.append(`[data][attributes][${id}]`, `[${String(data[id])}]`)
        }
    );
    body.append(`[data][attributes][start_date_time]`, `${start_date}, ${start_time}`)
    body.append(`[data][attributes][end_date_time]`, `${end_date}, ${end_time}`)
    delete data.preference_ids;
    delete data.category_ids;
    delete data.sub_category_ids;
    Object.keys(data).forEach(k => {
      console.log("delete_image_ids", `data[attributes][${k}]`, data[k]);
      body.append(`data[attributes][${k}]`, data[k])
    });
    if(Array.isArray(formData?.image)){
      formData?.image.forEach((im: any) => body.append('data[attributes][images][]', im))
    }  
    body.append("delete_image_ids",JSON.stringify(this.state.removedImages))
    this.eventEditRequestId = sendAPIRequest(configJSON.eventEditEndPoint.replace(':id', this.state.eventId), {
      method: 'PUT',
      headers: {'token': token},
      body
    })
  }
  removeUploadedImages = (imageId:number) => {
    if(imageId){
        this.setState(prevState => ({
          removedImages: [...prevState.removedImages, imageId]
        }))
    }
  }
  // Customizable Area End
  
}

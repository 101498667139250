Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Events2";
exports.labelBodyText = "Events2 Body";
exports.generateEventIdEndpoint = "/bx_block_events/generate_event_id"
exports.eventCommentEndpoint = "/bx_block_events/comments"
exports.getEventDataByIdEndpoint = "/bx_block_events/events/:eid"
exports.getAllCommentEndpoint = "/bx_block_events/comments?event_id=:eid"
exports.getUserRoleEndpoint = "/account_block/accounts/get_user_role"
exports.createEventEndPoint = "/bx_block_events/events"
exports.favEventEndPoint = "/bx_block_favourites/favourites"
exports.eventLikeEndPoint = "bx_block_like/likes"
exports.eventDeleteEndPoint = "bx_block_events/events/:id"
exports.eventCommentReplyEndPoint = "bx_block_events/comments/create_reply"
exports.unFavEventEndPoint = "/bx_block_favourites/favourites/:id"
exports.interestedEventEndPoint = "/bx_block_events/event_interesteds"
exports.eventEditEndPoint = "bx_block_events/events/:id"
exports.myEventsEndpoint = "/bx_block_events/my_events"
exports.apiEventListEndpoint = "/bx_block_events/events"
exports.eventFilterApiEndpoint= "bx_block_events/events/filter"
exports.btnExampleTitle = "CLICK ME";
// Customizable Area End
import React from "react";
// Customizable Area Start
import {
    MenuItem as MenuItemEdit,
    Avatar,
    Typography,
    ListItemText,
    Backdrop, Modal, Fade,
    Box,
    Checkbox,
} from '@material-ui/core';
import { styled } from '@material-ui/styles';
import Flex from "../../../components/src/Flex.web";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { iconUpload, pdfIcon, closeIcon, imageIcon, videoIcon, csvIcon, docIcon, genericIcon, xlsIcon } from './assets'
import { Formik, useField } from 'formik'
import FormModal, { InputRow as InputEdit, MInput, MSelect, MCountedTextArea, MDatePicker } from '../../../components/src/FormModal.web'
import UserContext from "../../../components/src/UserProvider";
import SearchSelect from "../../../components/src/SearchSelect.web";
import OverlayLoader from '../../../components/src/Loader.web'

import UploadButton from '../../../components/src/UploadButtonnew.web'
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { CloseCircleIcon } from "../../../components/src/Icons.web";
// Customizable Area End
import CreateRequestToListController, { Props } from "./ProjectMilstoneController";

// Customizable Area Start
interface KpiModalEditInterface {
    showModal: boolean
    onClose?: any
}

export const KpiEditModal = ({ showModal, onClose }: KpiModalEditInterface) => {
    return (
        <Modal
            open={showModal}
            onClose={() => onClose()}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={showModal}>
                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', maxHeight: '83vh', marginTop: '10vh' }}>

                    <Box style={{ backgroundColor: '#fff', position: 'relative', padding: '30px 30px', borderRadius: '8px', width: '650px', margin: '0 auto' }}>
                        <div style={{ textAlign: 'start', marginTop: "-12", fontSize: '20px', fontWeight: 600 }}>
                            KPI
                        </div>
                        <Box id='close' onClick={() => onClose()} style={{ position: 'absolute', right: '15px', top: '15px', color: '#fff', width: '30px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', borderRadius: '8px' }}>
                            <CloseCircleIcon />
                        </Box>
                        <div style={{ display: 'flex' }}>
                            <KPIEditInformation >
                                <h5>Energy productivity</h5>
                                <p><span />GDP</p>
                                <p><span />USD ($) value-added</p>
                                <p><span />units of revenu</p>
                                <p><span />ounces of gold</p>
                                <p><span />ounces of platinum</p>
                                <p><span />metric tons of steel</p>
                                <p><span />metric tons of cement</p>
                                <p><span />metric tons of cardboard</p>
                                <p><span />metric tons of product</p>
                                <p><span />metric tons of ore processed</p>
                                <p><span />metric tons of aggregate</p>
                                <p><span />metric tons of aluminum</p>
                                <p><span />square meters</p>
                                <p><span />klometers</p>
                                <p><span />passenger kilometers</p>
                                <p><span />revenue passenger kilometers</p>
                                <p><span />liters of product</p>
                                <p><span />units of production</p>
                                <p><span />units of service provided</p>
                                <p><span />ton of oil equivalents (TOE)</p>
                                <p><span />ton of coal equivalents (TCE)</p>
                                <p><span />square feet</p>
                                <p><span />megawatt hours (MWh)</p>
                                <p><span />barrel of oil equivalents (BOE)</p>
                                
                                <p><span />Other</p>
                            </KPIEditInformation>
                            <KPIEditInformation >
                                <h5>Low-carbon buildings</h5>
                                <p><span />Percentage of net zero carbon buildings</p>
                                <p><span />Percentage of buildings with a green building</p>
                                <p><span />Percentage of net zero energy buildings</p>
                                <p><span />Other</p>

                                <h5>Land use change</h5>
                                <p><span />hectares restored</p>
                                <p><span />hectares reforested</p>
                                <p><span />Other</p>
                            </KPIEditInformation>
                        </div>

                    </Box>
                </Box>
            </Fade>
        </Modal>
    )
}
// Customizable Area End

export default class CreateRequestToList extends CreateRequestToListController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    
    // Customizable Area Start
    selectedItem: any;
    getuserDetails: any;


    CreateTemplateModal() {

        const details = this.state.milestoneViewData?.attributes || {};
        let initialValues: any = {
            objectiveId: details?.objective_id,
            projectId: details?.project_id,
            project_name: details?.milestone_unique_id,
            milestone_name: details?.milestone_name,
            milestone_category: details?.sub_category_id || '',
            start_date: details?.start_date,
            end_date: details?.end_date,
            kpi: details?.kpi,
            uom: details?.uom_id || '',
            baseline: details?.baseline,
            target_output_direct: details?.target_output_direct,
            actual_output_direct: details?.actual_output_direct,
            target_outcome_Direct: details?.target_outcome_direct,
            actual_outcome_Direct: details?.actual_outcome_direct,
            target_output_indirect: details?.target_output_indirect,
            actual_output_indirect: details?.actual_output_indirect,
            target_outcome_indirect: details?.target_outcome_indirect,
            actual_outcome_indirect: details?.actual_outcome_indirect,
            estimated_cost: details?.estimated_cost,
            actual_cost: details?.actual_cost,
            estimated_inputs: details?.estimated_inputs,
            actual_inputs: details?.actual_inputs,
            estimated_capex: details?.estimated_capex,
            actual_capex: details?.actual_capex,
            estimated_saving: details?.estimated_savings,
            actual_saving: details?.actual_savings,
            type_of_investment: details?.type_of_investment_id || '',
            description: details?.description,
            status: details?.milestone_status || '',
            milestone_leader: details?.milestone_leaders_ids || [],
            milestone_moderator: details?.milestone_moderators_ids || [],
            uom_please_specify: details?.uom_please_specify,
            type_of_investment_please_specify: details?.type_of_investment_please_specify,
            project_milestone_pdfs: details?.project_milestone_pdfs || []
        }



        function compareObjects(obj1: any, obj2: any) {
            return obj1?.attributes?.id === obj2;
        }

        const filteredMilestoneCategories = this.state?.milestoneCategoriesList?.filter((obj1: any) => {
            let usedMilestoneIds = this.state?.usedMilestoneIds;
            let index = usedMilestoneIds.indexOf(this.state.milestoneViewData?.attributes?.sub_category_id);
            while (index !== -1) {
                usedMilestoneIds.splice(index, 1);
                index = usedMilestoneIds.indexOf(this.state.milestoneViewData?.attributes?.sub_category_id);
            }
            return !usedMilestoneIds?.some((obj2: any) => {
                return compareObjects(obj1, obj2);
            });
        });

        // filteredMilestoneCategories?.filter((obj: any) => {
        //     if (obj?.attributes?.id == this.state.milestoneViewData?.attributes?.sub_category_id) {
        //         this.setState({ milestoneCategaryValue: obj })
        //     }

        // });






        return <><Formik enableReinitialize initialValues={initialValues} onSubmit={this.handelSubmit} validationSchema={this.validationSchema}


        >
            {({ values, setFieldValue, setTouched }: any) => (
                <ModalWrap>
                    <FormModal modalStyle={{ maxWidth: '700px' }} headerTitle={<b>EDIT MILESTONE</b>} submitLabel={"Update"} onClose={this.goBack} >
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                            <InputEdit label={<JobLabel>Objective ID</JobLabel>} input={
                                <MInput name="objectiveId" disabled />
                            } />

                            <InputEdit label={<JobLabel>Project ID</JobLabel>} input={
                                <MInput name="projectId" disabled />
                            } />

                            <InputEdit label={<JobLabel>Milestone
                                ID</JobLabel>} input={
                                    <MInput name="project_name" disabled />
                                } />
                        </div>
                        <InputEdit label={<JobLabel>Milestone Name*</JobLabel>} input={
                            <MInput name="milestone_name" placeholder="" />
                        } />

                        <InputEdit label={<JobLabel>Milestone Category*</JobLabel>}
                         
                            input={<Autocomplete
                                autoHighlight
                                value={this.state.milestoneCategaryValue || {}}
                                onChange={(e, option) => { this.handleRequestTypeChange(e, option, setTouched, setFieldValue) }}
                                loading={false}
                                ListboxProps={{ style: { maxHeight: '150px' } }}
                                options={filteredMilestoneCategories}
                                getOptionLabel={(option: any) => option?.attributes?.name}
                                filterSelectedOptions
                                renderOption={(option, { selected }) => (
                                    <>
                                        {option?.attributes?.name}
                                    </>
                                )}

                                renderInput={(params) => (
                                    <MInput
                                        {...params}
                                        name="milestone_category"
                                        InputProps={{
                                            ...params.InputProps,
                                        }}
                                        variant="outlined"
                                        placeholder="Search"
                                    />
                                )}
                            />}

                        />


                        <Flex gap={8}>
                            <InputEdit label={<JobLabel>Start Date*</JobLabel>} style={{ width: "50%" }} input={

                                <MDatePicker name="start_date"
                                    InputProps={{ placeholder: "Enter Date" }}

                                />
                            } />
                            <InputEdit label={<JobLabel>End Date*</JobLabel>} style={{ width: "50%" }} input={

                                <MDatePicker name="end_date" disablePast
                                    InputProps={{ placeholder: "Enter Date" }}
                                />
                            } />
                        </Flex>
                        <Flex gap={8}>
                            <InputEdit label={<JobLabel>Milestone Leader*</JobLabel>} style={{ width: "50%" }} input={<SearchSelect
                                multiple={true}
                                pagination={this.state.requestorPagination}
                                hasMore={!!this.state.requestorPagination?.next_page}
                                nextPage={this.paginateLeaders}
                                loading={false}
                                onSearch={this.handleLeaderSearchQuery}
                                allItems={[...this.state.selectedLeadersList, ...this.state.leaderLists.filter((item: any) => !this.state.leadersIdList.includes(item.id))]}
                                selectProps={{ onClose: this.handleLeadersClose }}
                                items={[...this.state.selectedLeadersList, ...this.state.leaderLists.filter((item: any) => !this.state.leadersIdList.includes(item.id))]}
                                name="milestone_leader"
                                SelectItem={SelectItemUser}
                                getOptionValue={(user: any) => user.profile_pic}
                                isAvatar={true}
                            />}
                            />
                            <InputEdit label={<JobLabel>Milestone Moderator*</JobLabel>} data-test-id="milestone_moderator" style={{ width: "50%" }} input={<SearchSelect
                                multiple={true}
                                pagination={this.state.moderatorPagination}
                                hasMore={!!this.state.moderatorPagination?.next_page}
                                nextPage={this.paginateModerator}
                                loading={false}
                                onSearch={this.handleModeratorSearchQuery}
                                allItems={[...this.state.selectedModratorsList, ...this.state.moderatorLists.filter((item: any) => !this.state.moderatorsIdList.includes(item.id))]}
                                selectProps={{ onClose: this.handleModeratorClose }}
                                items={[...this.state.selectedModratorsList, ...this.state.moderatorLists.filter((item: any) => !this.state.moderatorsIdList.includes(item.id))]}
                                name="milestone_moderator"
                                data-test-id="searchselect"
                                SelectItem={SelectItemUser}
                                getOptionValue={(user: any) => user.profile_pic}
                                isAvatar={true}
                            />}
                            />

                        </Flex>

                        <InputEdit label={<JobLabel>KPI*

                            <InfoIcon id='kpiModal' onClick={() => this.setState({ kpiModal: true })
                            }
                                style={{ position: 'relative', bottom: "-5px", right: "-8px", fontSize: '19px', cursor: 'pointer' }}
                            />
                        </JobLabel>} input={
                            <MCountedTextArea length={100} maxRows={1} name="kpi" placeholder="" />
                        } />

                        <Flex gap={8}>
                            <InputEdit label={<JobLabel>UoM*</JobLabel>} style={{ width: "50%" }} input={
                                <MSelect name="uom" placeholder="Select"
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "left"
                                        },
                                        PaperProps: {
                                            style: {
                                                maxHeight: 200,
                                            },
                                        },
                                        getContentAnchorEl: null,
                                    }}
                                >
                                    <MenuItemEdit disabled value={-1}>Select</MenuItemEdit>
                                    {this.state.uomList?.map((item: any) => {
                                        return <MenuItemEdit value={item?.id}>
                                            {item?.attributes?.unit_name}
                                        </MenuItemEdit>
                                    })}


                                </MSelect>
                            } />

                            <InputEdit label={<JobLabel>Baseline (Numeric)</JobLabel>} style={{ width: "50%" }} input={
                                <MInput name="baseline" placeholder="" />
                            } />

                        </Flex>

                        {values?.uom == "13" && <Flex gap={8}>
                            <InputEdit label={''} style={{ width: "50%" }} input={
                                <MInput name="uom_please_specify" placeholder="Please specify" />
                            } />
                        </Flex>
                        }

                        <Flex gap={8}>

                            <InputEdit label={<JobLabel>Target Output Direct (Numeric)</JobLabel>} style={{ width: "50%" }} input={
                                <MInput name="target_output_direct" placeholder="" />
                            } />

                            <InputEdit label={<JobLabel>Actual Output Direct (Numeric)</JobLabel>} style={{ width: "50%" }} input={
                                <MInput name="actual_output_direct" placeholder="" />
                            } />
                        </Flex>


                        <InputEdit label={<JobLabel>Target Outcome Direct*</JobLabel>} input={
                            <MCountedTextArea length={100} maxRows={1} name="target_outcome_Direct" placeholder="" />
                        } />

                        <InputEdit label={<JobLabel>Actual Outcome Direct</JobLabel>} input={
                            <MCountedTextArea length={100} maxRows={1} name="actual_outcome_Direct" placeholder="" />
                        } />

                        <Flex gap={8}>

                            <InputEdit label={<JobLabel>Target Output Indirect (Numeric)</JobLabel>} style={{ width: "50%" }} input={
                                <MInput name="target_output_indirect" placeholder="" />
                            } />

                            <InputEdit label={<JobLabel>Actual Output Indirect (Numeric)</JobLabel>} style={{ width: "50%" }} input={
                                <MInput name="actual_output_indirect" placeholder="" />
                            } />
                        </Flex>
                        <InputEdit label={<JobLabel>Target Outcome Indirect</JobLabel>} input={
                            <MCountedTextArea length={100} maxRows={1} name="target_outcome_indirect" placeholder="" />
                        } />

                        <InputEdit label={<JobLabel>Actual Outcome Indirect</JobLabel>} input={
                            <MCountedTextArea length={100} maxRows={1} name="actual_outcome_indirect" placeholder="" />
                        } />
                        <Flex gap={8}>

                            <InputEdit label={<JobLabel>Estimated Cost*</JobLabel>} style={{ width: "50%" }} input={
                                <MInput name="estimated_cost" placeholder="" />
                            } />

                            <InputEdit label={<JobLabel>Actual Cost</JobLabel>} style={{ width: "50%" }} input={
                                <MInput name="actual_cost" placeholder="" />
                            } />
                        </Flex>

                        <InputEdit label={<JobLabel>Estimated Inputs</JobLabel>} input={
                            <MCountedTextArea length={100} maxRows={1} name="estimated_inputs" placeholder="" />
                        } />

                        <InputEdit label={<JobLabel>Actual Inputs</JobLabel>} input={
                            <MCountedTextArea length={100} maxRows={1} name="actual_inputs" placeholder="" />
                        } />

                        <Flex gap={8}>

                            <InputEdit label={<JobLabel>Estimated Capex</JobLabel>} style={{ width: "50%" }} input={
                                <MInput name="estimated_capex" placeholder="" />
                            } />

                            <InputEdit label={<JobLabel>Actual Capex</JobLabel>} style={{ width: "50%" }} input={
                                <MInput name="actual_capex" placeholder="" />
                            } />
                        </Flex>

                        <Flex gap={8}>

                            <InputEdit label={<JobLabel>Estimated Saving</JobLabel>} style={{ width: "50%" }} input={
                                <MInput name="estimated_saving" placeholder="" />
                            } />

                            <InputEdit label={<JobLabel>Actual Saving</JobLabel>} style={{ width: "50%" }} input={
                                <MInput name="actual_saving" placeholder="" />
                            } />
                        </Flex>


                        <InputEdit label={<JobLabel>Type of Investment*</JobLabel>} input={
                            <MSelect name="type_of_investment" placeholder="Select"
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    PaperProps: {
                                        style: {
                                            maxHeight: 200,
                                        },
                                    },
                                    getContentAnchorEl: null,
                                }}
                            >
                                <MenuItemEdit disabled value={-1}>Select</MenuItemEdit>
                                {this.state.typeOfInvestmentsLists.map((item: any) => {
                                    return <MenuItemEdit value={item?.attributes?.id}>{item?.attributes?.name}</MenuItemEdit>
                                })}
                            </MSelect>
                        } />

                        {values?.type_of_investment == "16" &&
                            <InputEdit label={''} input={
                                <MInput name="type_of_investment_please_specify" placeholder="Please specify" />
                            } />
                        }

                        <InputEdit label={<JobLabel>Upload Milestone Plan</JobLabel>} input={
                            <>
                                <div style={{ margin: '10px 0' }}>
                                    <UploadButton
                                        multiple
                                        input
                                        name="project_milestone_pdfs"
                                        setFileInState={this.setFilesArray}
                                        onDelete={(id: any) => { this.onDeleteAttachment(id) }}
                                    />
                                </div>
                            </>
                        } />

                        <InputEdit label={<JobLabel>Description*</JobLabel>} input={
                            <MCountedTextArea length={500} name="description" placeholder="" />
                        } />

                        <InputEdit label={<JobLabel>Milestone Status*</JobLabel>} input={
                            <MSelect name="status" placeholder="Select"  >
                                <MenuItemEdit value="Inprogress">Inprogress</MenuItemEdit>
                                    <MenuItemEdit value="Live">Live</MenuItemEdit>
                                    <MenuItemEdit value="On Hold">On Hold</MenuItemEdit>
                                    <MenuItemEdit value="Closed">Closed</MenuItemEdit>
                            </MSelect>
                        } />
                        <OverlayLoader loading={!!this.state.submittingForm} />
                    </FormModal>
                </ModalWrap>
            )}
        </Formik>
            {this.state.kpiModal && <KpiEditModal showModal={this.state.kpiModal} onClose={() => this.closeKpiModal()} />}
        </>
    }

    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            <>
                {this.CreateTemplateModal()}
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

CreateRequestToListController.contextType = UserContext






const JobLabel = styled('div')({
    fontSize: '15.35px',
    color: '#404040',
    fontFamily: 'HelveticaNeue'
})



export const ModalWrap = styled('div')({
})


const BorderDocument = styled('div')({
    border: '1px solid #999',
    padding: '10px 15px',
    borderRadius: '15px',
    display: 'flex',
    alignItems: 'center',
    width: '50%',
    position: 'relative',
    marginTop: '5px'
})

const FileInformation = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '15px',
    gap: '5px',
})
const KPIEditInformation = styled('div')({
    width: '50%',
    display: 'inline-block',
    '& h5': {
        fontSize: '15px',
        marginBottom: '10px',
        fontWeight: '600'
    },
    '& p': {
        margin: '2px',
        '& span': {
            height: '10px',
            width: '10px',
            background: 'red',
            borderRadius: '50%',
            display: 'inline-block',
            marginRight: '5px'
        }
    }
})
export const SelectItemUser = ({ item, value }: { item: any, value: any[] }) => {
    return <div style={{ width: '100%', display: 'flex', flexDirection: 'row-reverse', alignItems: 'center' }}>
        <ListItemText style={{ flex: 2 }} disableTypography primary={
            <div style={{ display: "flex", alignItems: "center" }}>
                {Array.isArray(value) && <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" style={{ color: "#1ea60a" }} />}
                    checked={value?.findIndex((x) => Number(x) === item?.id) > -1}
                />}
                <Avatar alt="Remy Sharp" src={item.profile_pic} style={{ width: "30px", height: "30px", marginRight: "10px" }} />

                <Typography style={{ color: "#333232", fontWeight: "bold", fontSize: 12, width: '35ch', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {item.first_name} {item.last_name}
                </Typography>
                <Typography component={'div'} style={{ width: '38ch', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {item.email}
                </Typography>
            </div>
        } />
    </div>
}

// Customizable Area End
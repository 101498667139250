import React from "react";
// Customizable Area Start
import ProjectPortfolioController, { Props } from "./ProjectMilstoneController";
import {
	Grid, Box, Typography, Button, Tooltip, Avatar, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Fade, Backdrop, Modal as ModalMilestone
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { styled } from '@material-ui/styles'
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/BorderColor';
import UserContext from "../../../components/src/UserProvider";
import { iconAdd } from "../../JobListing2/src/assets";
import TopNavBar from "../../../components/src/TopNavbar";
import LeftMenuWeb from "../../../components/src/LeftMenuWeb";
import OverlayLoader from '../../../components/src/Loader.web';
import moment from 'moment';
import { toast } from 'react-toastify'
import {  warningIcon, Tooltips,  pdfIcon,  imageIcon, videoIcon, csvIcon, docIcon, genericIcon, xlsIcon,editImg  } from './assets'
// Customizable Area End

// Customizable Area Start
interface UserModalMilestoneInterface {
	showModal: boolean
	handleModal: any
	Delete?: any
	value?: any
	onClose?: any
	onDelete?: any
}

export const UserModalMilestone = ({ showModal, handleModal, Delete, value, onClose, onDelete }:UserModalMilestoneInterface) => {
	return (
		<ModalMilestone
			open={showModal}
			BackdropProps={{
				timeout: 500,
			}}
			closeAfterTransition
			BackdropComponent={Backdrop}
			
			onClose={() => handleModal(value)}
		>
			<Fade in={showModal}>
				<Box style={{ alignItems: 'center', justifyContent: 'center',display: 'flex',  minHeight: '100vh' }}>
					<Box style={{  position: 'relative',backgroundColor: '#fff', padding: '30px 30px', borderRadius: '8px', textAlign: 'center', maxWidth: '500px', margin: '0 auto' }}>

						<Typography component="h2" variant="h6" >
							<img alt='waning' src={warningIcon}  style={{ width: "50px", marginBottom: "24px" }} />
						</Typography>
						<Typography >
							Are you sure you want to delete Milestone ?
						</Typography>
						<Box style={{ display: 'flex', marginTop: '20px', alignItems: 'center', justifyContent: 'center', gap: '20px' }}>
							<button id='delete' onClick={() => onDelete()} style={{ backgroundColor: 'green', color: '#fff', borderRadius: '5px', padding: '5px 20px', border: 'none', cursor: 'pointer' }}>Yes</button>
							<button id='close' onClick={() => onClose()} style={{ backgroundColor: 'red', color: '#fff', borderRadius: '5px', padding: '5px 20px', cursor: 'pointer', border: 'none' }}>No</button>
						</Box>
					</Box>
				</Box>
			</Fade>
		</ModalMilestone>
	)
}


 function fileIcon(fileType: string, filename: string) {
    const extension = filename?.split(".").pop();
    const imageType = fileType?.split('/')?.[0];

    if (fileType === 'image' || imageType === 'image') {
        return imageIcon;
    } else if (fileType === 'video') {
        return videoIcon;
    } else if (fileType === 'text' || extension === 'csv') {
        return csvIcon;
    } else if (fileType === 'application' || fileType === 'application/pdf'||fileType==="application/vnd.ms-excel") {
        // doc, xls, pdf
        if (extension === 'pdf') {
            return pdfIcon;
        } else if (extension === 'doc' || extension === 'docx') {
            return docIcon;
        } else if (extension === 'xls' || extension === 'xlsx') {
            return xlsIcon;
        }
    } else {
        return genericIcon;
    }
}
export const OnMilestone = ({ details, onDelete, onView, onEdit }: any) => {
	return <>
		<CorpsTable>
			<TableContainer>
				<Table aria-label="simple table">

					<StyledTableCell>
						<TH style={{ borderTopLeftRadius: '5px' }}>Objective ID</TH>
						<TH>Project ID</TH>
						<TH>Milestone ID</TH>
						<TH>Milestone Categories*</TH>
						<TH>Milestone Name* <br /> <span style={{ fontSize: "12px", fontWeight: 'lighter' }}>100 Char Limit</span></TH>
						<TH>Start Date*</TH>
						<TH>End Date*</TH>
						<TH>Milestone Leader*</TH>
						<TH>Milestone Moderator*</TH>
						<TH>KPI *</TH>
						<TH>UoM</TH>
						<TH>Baseline</TH>
						<TH>Target Output Direct</TH>
						<TH>Actual Output Direct</TH>
						<TH>Target Outcome Direct</TH>
						<TH>Actual Outcome Direct</TH>
						<TH>Target Outcome Indirect</TH>
						<TH>Actual Outcome Indirect</TH>
						<TH>Estimated Cost</TH>
						<TH>Actual Cost</TH>
						<TH>Estimated Inputs</TH>
						<TH>Actual Inputs</TH>
						<TH>Estimated Capex</TH>
						<TH>Actual Capex</TH>
						<TH>Estimated Savings</TH>
						<TH>Actual Savings</TH>
						<TH>Type of Investment*</TH>
						<TH>Upload Milestone Plan</TH>
						<TH>Description*</TH>
						<TH>Milestone Status</TH>
						<TH style={{ borderTopRightRadius: '5px' }}>Actions</TH>
					</StyledTableCell>

					<TableBody>
						{details.sort((a: any, b: any) => a.id - b.id)?.map?.((item: any) => {
							return <TableRow style={{ textAlign: 'center' }}>
								<TD><TableData>{item?.attributes?.objective_id}</TableData></TD>
								<TD><TableData>{item?.attributes?.project_id}</TableData></TD>
								<TD><TableData id="onview"onClick={() => onView(item.id)} style={{ cursor: 'pointer' }}>{item.attributes.milestone_unique_id}</TableData></TD>
								<TD>{item?.attributes?.sub_category_name}</TD>
								<TD>{item?.attributes?.milestone_name}</TD>
								<TD>{moment(item?.attributes?.start_date).format('DD/MM/YYYY')}</TD>
								<TD>{moment(item?.attributes?.end_date).format('DD/MM/YYYY')}</TD>
								<TD>
									<StyledAvatarView spacing='small' max={4} >
										{item?.attributes?.milestone_leaders?.map((item: any) => <Avatar style={{ marginRight: "5px", height: "25px", width: "25px", border: "3px solid rgb(242, 212, 212)" }} alt={item?.data?.attributes?.first_name} src={item?.data?.attributes?.profile_pic} />)}


									</StyledAvatarView >

								</TD>
								<TD>
									<StyledAvatarView spacing='small' max={4} >
										{item?.attributes?.milestone_moderators?.map((item: any) => <Avatar style={{ marginRight: "5px", height: "25px", width: "25px", border: "3px solid rgb(242, 212, 212)" }} alt={item?.data?.attributes?.first_name} src={item?.data?.attributes?.profile_pic} />)}


									</StyledAvatarView >
								</TD>
								<TD>{item?.attributes?.kpi}</TD>
								<TD>{item?.attributes?.uom}</TD>
								<TD>{item?.attributes?.baseline}</TD>
								<TD>{item?.attributes?.target_output_direct}</TD>
								<TD>{item?.attributes?.actual_output_direct}</TD>
								<TD>
									<Typography
										style={{
											width: '150px',
											wordWrap: 'break-word'
										}}

										className="CustomeText" variant="subtitle2" component="div">
										{item?.attributes?.target_outcome_direct}
									</Typography>
								</TD>
								<TD>
									<Typography
										style={{
											width: '150px',
											wordWrap: 'break-word'
										}}

										className="CustomeText" variant="subtitle2" component="div">
										{item?.attributes?.actual_outcome_direct}
									</Typography>
								</TD>
								<TD>
									<Typography
										style={{
											width: '150px',
											wordWrap: 'break-word'
										}}

										className="CustomeText" variant="subtitle2" component="div">
										{item?.attributes?.target_outcome_indirect}
									</Typography>
								</TD>
								<TD>
									<Typography
										style={{
											width: '150px',
											wordWrap: 'break-word'
										}}

										className="CustomeText" variant="subtitle2" component="div">
										{item?.attributes?.actual_outcome_indirect}
									</Typography>
								</TD>
								<TD>{item?.attributes?.estimated_cost}</TD>
								<TD>{item?.attributes?.actual_cost}</TD>
								<TD>
									<Typography
										style={{
											width: '150px',
											wordWrap: 'break-word'
										}}

										className="CustomeText" variant="subtitle2" component="div">
										{item?.attributes?.estimated_inputs}
									</Typography>
								</TD>
								<TD>
									<Typography
										style={{
											width: '150px',
											wordWrap: 'break-word'
										}}

										className="CustomeText" variant="subtitle2" component="div">
										{item?.attributes?.actual_inputs}
									</Typography>
								</TD>
								<TD>{item?.attributes?.estimated_capex}</TD>
								<TD>{item?.attributes?.actual_capex}</TD>
								<TD>{item?.attributes?.estimated_savings}</TD>
								<TD>{item?.attributes?.actual_savings}</TD>
								<TD>{item?.attributes?.type_of_investment_name}</TD>
								<TD>
								{item?.attributes?.project_milestone_pdfs?.length > 0 ? item?.attributes?.project_milestone_pdfs?.map((file: any) => 
										<div style={{marginBottom:'5px'}}>
										<a href={file?.url} target="_blank" download>
										<span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '60px', display: 'inline-block' }}>{file?.filename}</span>
										<img src={fileIcon(file.type, file.filename)} width="25px" height="25px" />
										</a>
										</div>
								) : <></>}
								</TD>
								<TD >
									<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
										{item.attributes.description?.length > 30 ? <>{item.attributes.description.substring(0, 30)}<span style={{ fontWeight: "bold" }}>{" "}...</span></> : item.attributes.description}
										<Tooltip title={<div style={{
											height: '200px',
											fontSize: '13px',
											width: '200px',
											color: '#000',
											overflow: 'hidden',
											margin: "0px"
										}}>
											{item?.attributes?.description}
										</div>}>
											{<BtnImg src={Tooltips} style={{ marginLeft: '10px' }} />}
										</Tooltip>
									</div>
								</TD>
								<TD>{item.attributes.milestone_status == 'on_hold' ? 'On hold' : item.attributes.milestone_status}</TD>
								<TD>
								<img src={editImg} id="onEdit" onClick={() => onEdit(item.id)}  width="25px" height="25px" style={{cursor:'pointer'}} />
									{/* <EditIcon style={{ marginRight: '10px', cursor: 'pointer', color: 'black' }} /> */}
									<DeleteIcon id="onDelete" style={{ marginLeft: '10px', cursor: 'pointer', color: 'black' }} onClick={() => onDelete(item.id)} /></TD>
							</TableRow>
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</CorpsTable>
	</>
}
// Customizable Area End
export default class ProjectManagement extends ProjectPortfolioController {
	constructor(props: Props) {
		super(props);
		// Customizable Area Start
		// Customizable Area End
	}

  	// Customizable Area Start

	PeopleManagementTabs() {
		const projectId = this.props.navigation.getParam('projectId');
		return <>

			<PeoplePageWrapper>
				<PageWrapperCenterRow>
					<HeadWrapper>
						<Head>
							<ArrowBackIosIcon id="backbutton" style={{ fontWeight: 100, color: 'gray', cursor: 'pointer' }} onClick={(e) => this.props.navigation.history.push('/ProjectManagement')} />
							PROJECT PHASE AND MILESTONE : {this.state.projectDetails?.project_name}
						</Head>
					</HeadWrapper>
					<Grid container style={{ margin: '0px 0px 40px 0px', justifyContent: "space-between" }}>
						<Grid item >

							<RoundedButtonBaseTabs>
								<CorporateLicenceTabs
								id="on_goingproject"
									onClick={(e) => this.onGoingProject()}
									style={this.state.onGoingProjectButton ? { backgroundColor: "#00A100", color: "white" } : { backgroundColor: "white" }}
								>MILESTONE</CorporateLicenceTabs>
								<IndividualUserTabs
								id="on_activity"
									onClick={() => this.onActivityClick()}
									style={this.state.activitesButton ? { backgroundColor: "#00A100", color: "white" } : { backgroundColor: "white" }}
								>ACTIVITIES</IndividualUserTabs>
							</RoundedButtonBaseTabs>
						</Grid>
						{this.state.activitesButton ?
							<Grid item >
								<RoundedButtonBaseCreate
									id="CreateTemplateBtn"
									onClick={() => this.props.navigation.history.push(`${this.props.navigation.history.location.pathname}/AddMilestone`)}
									variant="contained"
									disableElevation
									style={{ backgroundColor: "#00A100", color: "white" }}
									startIcon={<img src={iconAdd} width={30} style={{ marginLeft: '-5px' }} />}
								>
									ADD ACTIVITY
								</RoundedButtonBaseCreate>
							</Grid>
							: <Grid item >
								<RoundedButtonBaseCreate
									id="CreateTemplateBtn1"
									onClick={() => {
										if (this.state.projectDetails?.No_of_milestone) {

											if (this.state.projectDetails?.No_of_milestone > this.state.milestoneData.length) {
												
												this.props.navigation.history.push(`${this.props.navigation.history.location.pathname}/AddMilestone/${projectId}`)

											} else {
												toast.error('Maximum number of milestone created.')
												return;
											}
										} else {
											toast.error('Please fill the no of milestone');
										}
									}}
									variant="contained"
									disableElevation
									style={{ backgroundColor: "#00A100", color: "white" }}
									startIcon={<img src={iconAdd} width={30} style={{ marginLeft: '-5px' }} />}
								>
									ADD MILESTONE
								</RoundedButtonBaseCreate>
							</Grid>}

					</Grid>
					{this.state.activitesButton &&
						<div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', overflow: 'hidden' }}>

							<Grid spacing={4} container >
								<Grid xs={12} item >
								</Grid>
							</Grid>

						</div>
					}


					{this.state.onGoingProjectButton &&
						<div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', overflow: 'hidden' }}>
							<Grid spacing={4} container >
								<Grid xs={12} item >
									<OnMilestone details={this.state.milestoneData}
										onDelete={(id: any) => this.handleModal(id)}
										onView={(id: any) => this.props.navigation.history.push(`${this.props.navigation.history.location.pathname}/ViewMilestone/${id}`)}
										onEdit={(id: any) => this.props.navigation.history.push(`${this.props.navigation.history.location.pathname}/EditMilestone/${id}`)}
									/>
								</Grid>
							</Grid>
							{this.state.delete && <UserModalMilestone showModal={this.state.delete} handleModal={this.handleModal} onClose={this.handleCloseDelete} onDelete={this.onDelete} />}
							<OverlayLoader loading={!!this.state.submittingForm} />
						</div>
					}
				</PageWrapperCenterRow>
			</PeoplePageWrapper>

		</>
	}
	// Customizable Area End


	render() {
		return (
			// Customizable Area Start
			<>
				<ProjectMainBox>
					<Box>
						<TopNavBar id={""} navigation={this.props.navigation}  />
					</Box>

					<OutGrid container spacing={3}>
						<LeftSideGrid item md={3} lg={3} xs={12} sm={12} >
							<Box >
								<LeftMenuWeb id={""} navigation={this.props.navigation}  />
							</Box>
						</LeftSideGrid>
						<MiddleSideGrid item sm={12} md={6} xs={12}  lg={6} >
							{this.PeopleManagementTabs()}
						</MiddleSideGrid>
					</OutGrid>

				</ProjectMainBox>
			</>
			// Customizable Area End
		)
	}
}

// Customizable Area Start
ProjectPortfolioController.contextType = UserContext


const ProjectMainBox = styled(Box)({
	backgroundColor: "#edebeb",
	display: "flex",
	flexWrap: 'wrap',
	flexDirection: "column",
	paddingBottom: "20px",
	overflowX: "hidden",
})

const OutGrid = styled(Grid)({
	display: 'flex',
	flexWrap: 'wrap',
	justifyContent: 'space-between',
})



const LeftSideGrid = styled(Grid)({
	maxWidth: '300px',
	'@media screen and (max-width: 985px)': {
		maxWidth: '200px',
	},
	'@media screen and (max-width: 768px)': {
		display: 'none',
		visibility: 'hidden',
	},
})




const MiddleSideGrid = styled(Grid)({
	width: 'calc(100vw - 300px)',
	maxWidth: 'unset',
	flexBasis: 'unset',
	justifyContent: 'center',
	'@media screen and (max-width: 768px)': {
		width: '100%'
	}
})






const CorporateLicenceTabs = styled(Button)({
	border: "2px solid transparant",
	color: "#009B07",
	fontWeight: 600,
	backgroundColor: "#FFFFFF",
	textTransform: "none",
	borderRadius: "38px",
	height: "100%",
	padding: "0 65px 0 65px",
	'@media screen and (max-width: 1110px) and (min-width: 769px)': {
		padding: "0 35px 0 35px",
	},
	'@media screen and (max-width: 624px)': {
		padding: "0 35px 0 35px",
	},
	'@media screen and (max-width: 500px)': {
		padding: "0 25px 0 25px",
	}
});

const PeoplePageWrapper = styled("div")({
	display: 'flex',
	justifyContent: 'center',
	minHeight: '100vh'
});
const IndividualUserTabs = styled(Button)({
	border: "2px solid transparant",
	color: "#009B07",
	fontWeight: 600,
	backgroundColor: "#FFFFFF",
	textTransform: "none",
	borderRadius: "38px",
	height: "100%",
	padding: "0 50px 0 50px",
	'@media screen and (max-width: 1110px) and (min-width: 769px)': {
		padding: "0 30px 0 30px",
	},
	'@media screen and (max-width: 624px)': {
		padding: "0 30px 0 30px",
	},
	'@media screen and (max-width: 500px)': {
		padding: "0 20px 0 20px",
	}
});


const PageWrapperCenterRow = styled("div")((props: any) => ({
	width: '100%',
}));

const RoundedButtonBaseCreate = styled(Button)({
	backgroundColor: '#009c05',
	color: 'white',
	borderRadius: '20px',
	'&:hover': {
		backgroundColor: '#009c05',
	},
	'@media screen and (max-width:600px)': {
		width: '100%'
	},
	'@media screen and (min-width:769px) and (max-width:1200px)': {
		width: '100%'
	}
})

const RoundedButtonBaseTabs = styled('div')((props: any) => ({
	height: "47px",
	fontWeight: 700,
	display: 'flex',
	textAlign: 'center',
	justifyContent: 'center',
	backgroundColor: "#FFFFFF",
	flex: "wrap",
	color: "#009E00",
	borderRadius: '38px',
	border: " 2px solid #009B07"


}))













const StyledAvatarView = styled(AvatarGroup)({
	'& .MuiAvatar-root': { width: 25, height: 25, fontSize: 13 },
})


const TH = styled(TableCell)({
	color: "#000",
	fontWeight: 600,
	lineHeight: "1.5rem",
	padding: '16px 10px',
	textAlign: 'center',
	position: 'relative',
	minWidth: '120px',
	maxWidth: '150px'
})

const TD = styled(TableCell)({
	lineHeight: "1.5rem",
	padding: '16px 10px',
	textAlign: 'center',
	color: 'grey'
})

const CorpsTable = styled('div')({
	backgroundColor: '#fff',
	borderRadius: "12.97px",
	padding: '10px'
})

const StyledTableCell = styled(TableHead)(({ theme }) => ({

	borderTopLeftRadius: '5px',
	[`& .MuiTableCell-head`]: {
		backgroundColor: '#00a100',
		color: 'white',
		fontSize: '14px'
	},

}));

const TableData = styled('span')({
	color: 'grey',
	borderBottom: '1px solid grey'
});

const HeadWrapper = styled('div')({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	width: '100%',
	marginTop: '25px'
});
const Head = styled('div')({
	display: 'flex',
	alignItems: 'center',
	color: '#000',
	margin: '10px 0px 30px 12px',
	fontSize: '1rem',
	fontFamily: 'HelveticaNeue',
	fontWeight: 600,
	'@media screen and (max-width: 768px)': {
		margin: '20px 0px 10px 12px',
	},
})
const BtnImg = styled('img')({
	width: 25
})
// Customizable Area End
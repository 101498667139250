 // Customizable Area Start
import {runEngine} from '../../../framework/src/RunEngine'
import MessageEnum, {
  getName
} from '../../../framework/src/Messages/MessageEnum'
import { Message } from "../../../framework/src/Message";

export function sendCommunityJoinedMessage(community: any){
	console.log({msg: null})
	let msg = new Message(getName(MessageEnum.CommunityJoinedMessage));
    msg.addData(
      getName(MessageEnum.CommunityDetailData),
      community
    );
    console.log({msg})
    runEngine.sendMessage(msg.id, msg);
    console.log({ msg })
    return msg.id
}

export function sendCommunityUnjoinedMessage(community: any){
	let msg = new Message(getName(MessageEnum.CommunityUnjoinedMessage));
    msg.addData(
      getName(MessageEnum.CommunityDetailData),
      community
    );
    runEngine.sendMessage(msg.id, msg);
    return msg.id
}

/*
*	execute callback only if message type match
*/ 
function parseCommunityJoinUnjoinMessage(msg: Message, id: string, callback: (community: any) =>any) {
	if(msg.id ===  id) {
		const community = msg.getData(getName(MessageEnum.CommunityDetailData))
		callback(community)
	}
}


export function parseCommunityJoinedMessage(msg: Message, callback: (community: any) => any) {
	parseCommunityJoinUnjoinMessage(msg,getName(MessageEnum.CommunityJoinedMessage),  callback)
}


export function parseCommunityUnjoinedMessage(msg: Message, callback: (community: any) => any) {
	parseCommunityJoinUnjoinMessage(msg, getName(MessageEnum.CommunityUnjoinedMessage), callback)
}

// Customizable Area End
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { sendSharePopupMessage } from '../../share/src/Messages';
import { sendAddRequestMessage } from '../../RequestManagement/src/Messages'
import { sendReportMessage } from '../../ActivityFeed/src/Messages'
import * as yup from 'yup'

import { sendAPIRequest } from '../../../components/src/utils'

import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { Event } from './types'
import { toast } from 'react-toastify';
import { parseEventUpdatedMessage, sendEventUpdatedMessage } from './Messages'
import { getStorageData } from "../../../framework/src/Utilities";

yup.addMethod(yup.mixed, "maxFileSize", function(error: string, maxSize: number) {
  return this.test('test-name', error, function(file: File|undefined){
    if(!file) return true
    if(file.size>maxSize) return false
    return true
  })
})
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  selectedMyTab: number;
  modal: 'create' | 'apply' | '';
  events: Event[];
  userRole: any,
  start_date: any,
  end_date: any,
  start_time: any,
  end_time: any,
  eventID: string,
  pagination?: {
    next_page?: number;
  };
  threedotAnchorEl?: any;
  currentEventId?: any;
  currentEvent?: any
  deleteEventId?: any;
  EventDeleteCallId?: any;
  allCategories: any[];
  allSubCategories: any[];
  categoryOptions: any[];
  subCategoryOptions: any[];
  selectedPreferences: string[];
  loadingEvent: boolean,
  loadingPosts: boolean;
  filterQuery: string;
  urlSearchParam: URLSearchParams;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Events2Controller extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  token:string=''
  apiEventListCallId: string = ""
  eventFilterCallId: string = ""
  courseEventRequestId:string = ""
  interestedEventId: string = ""
  eventListRequestId: string = ""
  apiFavCallIds: string[] = []
  apiUnfavCallIds: string[] = []
  favCallIdToPostId:{[key: string]: string} = {}
  unfavCallIdToPostId:{[key: string]: string} = {}
  userRoleCallId:string = "";
  apiPaginateCallId: string = "";
  generateEventRequestId :string = ""
  allPreferences = [{id: 1, label: "E - Environmental"}, {id: 2, label: "S - Social"}, {id: 3, label: "G - Governance"}]
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.GetAllCategoriesResponseMessage),
      getName(MessageEnum.GetAllSubCategoriesResponseMessage),
      getName(MessageEnum.EventUpdatedMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      modal: '',
      selectedMyTab: 1,
      events: [],
      userRole: "",
      start_date: '',
      end_date: '',
      start_time: '',
      end_time: '',
      allCategories: [],
      allSubCategories: [],
      categoryOptions: [],
      subCategoryOptions: [],
      selectedPreferences: [],
      loadingEvent: false,
      loadingPosts: false,
      eventID: '',
      filterQuery: '',
      urlSearchParam: new URLSearchParams()
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      console.log({events: responseJson})
      if (responseJson && responseJson.errors) {
        // this.setState({ refresh: false });
        this.parseApiErrorResponse(responseJson.errors);
        this.parseApiCatchErrorResponse(responseJson.errors);
      }  else if (apiRequestCallId === this.eventListRequestId || apiRequestCallId === this.eventFilterCallId) {
         if(responseJson?.events.data) {
           console.log("getting data from", responseJson.events.data)
           const events = responseJson.events.data.map((e:any) => e.attributes)
           this.setState({ events, loadingEvent: false })
           this.setState({pagination: responseJson.meta.pagination})
           if(apiRequestCallId === this.eventFilterCallId){
            sendEventUpdatedMessage(events)
            this.setState({ loadingEvent: false })
           }
         }
         else
          this.setState({ events: [], loadingEvent: false })
       } else if (apiRequestCallId === this.apiPaginateCallId) {
        if(responseJson?.events.data) {
          console.log("getting data from", responseJson.events.data)
          const events = responseJson.events.data.map((e:any) => e.attributes)
          this.setState({ events, loadingEvent: false })
          this.setState({pagination: responseJson.meta.pagination})
        }
      } else if ( apiRequestCallId === this.courseEventRequestId ) {
        if(responseJson.data)
        {
          this.setState({ events: [{id:responseJson.data.id, ...responseJson.data.attributes}, ...this.state.events], modal: '' })
          toast.success("Event Created Successfully")
        }
        } else if (this.apiFavCallIds.includes(apiRequestCallId)){
          const postId = this.favCallIdToPostId[apiRequestCallId]
          this.apiFavCallIds = this.apiFavCallIds.filter((x:any) => x !== apiRequestCallId)
          delete this.favCallIdToPostId[apiRequestCallId]
          if(responseJson.data){
          const fav = {...responseJson.data, ...responseJson.data?.attributes}
          const newPosts = this.state.events.map(post => {
            if(String(post.id) == postId)
              return {...post, favourite_id: [fav]}
            return {...post}
            })
          this.setState({ events: newPosts })
          }
        } else if(this.apiUnfavCallIds.includes(apiRequestCallId)){
          const id = this.unfavCallIdToPostId[apiRequestCallId]
          const events = this.state.events.map((j: any)=>{
            if(j.id == id)
              return {...j, favourite_id: []}
            return j
          })
          this.setState({ events })
        } else if (apiRequestCallId === this.userRoleCallId) {
          // toast.success('Community created successfully')
          this.setState({ 
            userRole: responseJson.user_role || ''
          })
        } else if (apiRequestCallId === this.generateEventRequestId) {
          this.setState({ 
            eventID: responseJson.event_unique_id || '', modal: 'create'
          })
        } else if (apiRequestCallId === this.state.EventDeleteCallId) {
          const pid = this.state.deleteEventId
          this.closeDeleteModal()
          const events = this.state.events.filter((p: any) => p.id != pid)
          console.log({ pid, events })
          this.setState({ events })
          toast.success("Event Deleted Successfully")
        }
    } else if (message.id === getName(MessageEnum.GetAllCategoriesResponseMessage)) {
      console.log("categories", message)
      const allCategories = message.getData(
        getName(MessageEnum.AllCategoriesDataMessage)
      )
      this.setState({ allCategories })
    } else if (message.id === getName(MessageEnum.GetAllSubCategoriesResponseMessage)) {
      console.log("sub-categories", message)
      
      const allSubCategories = message.getData(
        getName(MessageEnum.AllSubCategoriesDataMessage)
      )
      this.setState({ allSubCategories })
    }
    parseEventUpdatedMessage(message, this.handleEventUpdatedMessage)
    // Customizable Area End

  }

  // Customizable Area Start
  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  }

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  }

  

  async componentDidMount(){
    const token = await getStorageData("authToken")
    this.token = token;
    this.handleUserRole()
    this.getAllEvent()
    this.loadCategoriesSubCategories()
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.navigation.history?.location.state?.comment) {
      this.handleUserRole()
      this.getAllEvent()
      this.loadCategoriesSubCategories()
      this.props.navigation.history.replace({ state: {} })
    }
  }

  getAllEvent = async() => {
    console.log("getAllEvent ")
    const token=await getStorageData("authToken")
    let url = configJSON.apiEventListEndpoint
    let myUrl = this.getMyUrl()
    if(myUrl)
      url = myUrl
    this.setState({ loadingEvent: true })
    this.eventListRequestId = sendAPIRequest(
      url, {
        method: 'GET',
        headers: { 'token': token },
      }    
    )
  }

  handleUserRole = async() => {
    const token=await getStorageData("authToken")
    const url = configJSON.getUserRoleEndpoint
    this.userRoleCallId =  sendAPIRequest(url , { method: 'GET', headers: {token} })
  }

  createEvent = async() => {
    const token = await getStorageData("authToken")
    this.generateEventRequestId = sendAPIRequest(
      configJSON.generateEventIdEndpoint, {
        method: 'GET',
        headers: { 'token': token },
      }    
    )
  }

  createFormSchema = yup.object().shape({
    event_id_1: yup.string()
      .label('Event ID')
      .required(),
      // .matches(/^[a-zA-Z0-9]+$/, 'Event ID can only contain Alpha Numeric Characters'),
    event_id_2: yup.string()
      .label('Event ID')
      .required(),
      // .matches(/^[a-zA-Z0-9]+$/, 'Event ID can only contain Alpha Numeric Characters'),
    name: yup.string().label('Event Name').required().max(255),
    link: yup.string().url().required().label('Link'),
    start_date: yup.string().required().label('Start Date and Time'),
    end_date: yup.string().required().label('End Date and Time'),
    start_time: yup.string().required().label('Start Time'),
    end_time: yup.string().required().label('End Time')
    .when(['start_date', 'end_date', 'start_time'],{
      is: (start_date, end_date, start_time) => (start_date !== end_date) && start_time,
      then: yup.string().required("this is not valid")
    }),
    location: yup.string().required().label('Location'),
    preference_ids: yup.array().min(1, "Select atleast one Preference").label('Preferences'),
    category_ids: yup.array().required().min(1, "Please select a Category").label('Category'),
    sub_category_ids: yup.array().required().min(1, "Please select a Sub Category").label('Sub Category'),
    language: yup.string().required().label('Language'),
    participation_coins: yup.number().typeError("Coin must be a number").min(0, "Can not be less than 0").required().label('Participation Coins'),
    description: yup.string().required().max(1000).label('Description'),
    image: yup
      .mixed()
      .label('Image')
      // @ts-ignore
      .maxFileSize("Max allowed file size is 20Mb", 20*1024*1024)
  })

  hideModal = () => {
    this.setState({ modal: '' })
  }

  validate(){
    const currentTime = new Date().getTime();
    const currentDate = new Date().getDate();
    const startTime = this.state.start_time.getTime();
    const startDate = this.state.start_date.getDate();
    const endTime = this.state.end_time.getTime();
    const endDate = this.state.end_date.getDate();
    if(currentDate == startDate && currentTime > startTime){
      toast.warn("Start time should be more than current time");
    }
  }

  handleCreateFormSubmit = async(formData: any) => {
    console.log("event controller ", formData)
    console.log("ent_time forma ", formData.end_time, typeof(formData.end_time));
    const { event_id_1="", event_id_2="", /*category_ids=[], preference_ids=[], sub_category_ids=[],*/ ...data } = formData;
    const start_time = formData.start_time.toLocaleTimeString('en-US', {hour: 'numeric', minute: 'numeric'})
    const end_time = formData.end_time.toLocaleTimeString('en-US', {hour: 'numeric', minute: 'numeric'})
    const start_date = formData.start_date.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })
    const end_date = formData.end_date.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })

    console.log("checking dates ", start_date, end_date, start_time, end_time);
    // this.validate();
    const currentTime = new Date().getTime();
    const currentDate = new Date().getDate();
    const startTime = this.state.start_time.getTime();
    const startDate = this.state.start_date.getDate();
    const endTime = this.state.end_time.getTime();
    const endDate = this.state.end_date.getDate();
    if(currentDate == startDate && currentTime > startTime){
      toast.warn("Start time should be more than current time");
    } else if(start_date == end_date && start_time >= end_time){
      toast.warn("End time should be more than Start time");
    }
    else{
      const token =await getStorageData("authToken")
    // data.start_date = data.start_date.format("YYYY-MM-DD HH:MM:SS")
    /*console.log(preference_ids);
    console.log(sub_category_ids);
    console.log(category_ids);*/
    const body = new FormData();
    (['preference_ids',
          'category_ids',
          'sub_category_ids',]).forEach((id: any) => {
            console.log("id ", id);
          body.append(`[data][attributes][${id}]`, `[${String(data[id])}]`)
        }
    );
    body.append(`[data][attributes][start_date_time]`, `${start_date}, ${start_time}`)
    body.append(`[data][attributes][end_date_time]`, `${end_date}, ${end_time}`)
    console.log("body ==> ",body)
    for (var pair of body.entries()) {
    console.log(pair[0]+ ', ' + pair[1]); 
}
    delete data.preference_ids;
    delete data.category_ids;
    delete data.sub_category_ids;
    //console.log(body);
    body.append('data[attributes][event_unique_id]', `${event_id_1}${event_id_2}`);
    Object.keys(data).forEach(k => console.log(`data[attributes][${k}]`, data[k]));
    Object.keys(data).forEach(k => body.append(`data[attributes][${k}]`, data[k]));
    if(Array.isArray(formData?.image)){ 
    formData?.image.forEach((im: any) => body.append('data[attributes][images][]', im))}
    this.courseEventRequestId = sendAPIRequest(configJSON.createEventEndPoint, {
      method: 'POST',
      headers: {'token':token},
      body
    })
    }
  }

  setSelectedMyTab = (e: any,idx: number) => {
    this.setState({ selectedMyTab: idx })
  }

  getMyUrl = () => {
    if(window.location.pathname.indexOf('MyMarketplace')>=0){
      return configJSON.myEventsEndpoint
    }
    return ""
  }

  handleEventUpdatedMessage = (job: any) => {
    console.log("data in job update ", job)
    const events = this.state.events.map((j: any) => {
      if(j.id!=job.id)
        return j
      return job
    })
    this.setState({ events })
  }

  onFilterChange = async(filter: string) => {
    console.log("filter ", filter);
    const token = await getStorageData("authToken")
    if(filter === this.state.filterQuery) return
    this.setState({ filterQuery: filter })
    if(filter) {
      let url = configJSON.eventFilterApiEndpoint
      this.eventFilterCallId = sendAPIRequest(`${url}?${filter}`, { method: 'GET', headers: { token } })
      this.setState({ loadingEvent: true, events: [] })
    } else {
      this.setState({ events: [] })
      this.getAllEvent()
    }
  }

  loadNextPage = async() => {
    const token = await getStorageData("authToken")
    const query = this.state.urlSearchParam
    const isFiltersSet = query.toString().length > 0
    query.append('page', String(this.state.pagination?.next_page))
    let baseUrl = isFiltersSet ? `${configJSON.eventFilterApiEndpoint}` : configJSON.productListAPIEndpoint
    const myUrl = this.getMyUrl()
    if (myUrl)
      baseUrl = configJSON.eventFilterApiEndpoint 
    this.apiPaginateCallId = sendAPIRequest(baseUrl + "?" + query.toString(), {
      method: 'GET',
      headers: { token: token }
    })
  }

  queryToString = (queryParams: URLSearchParams) => {
    const from_date = queryParams.get('from_date')
    const to_date = queryParams.get('to_date')

    if(from_date) {
      queryParams.delete('from_date')
      queryParams.append('from_date', (new Date(from_date)).toISOString().slice(0, 10))
    }
    if(to_date) {
      queryParams.delete('to_date')
      queryParams.append('to_date', (new Date(to_date)).toISOString().slice(0, 10))
    }
    return queryParams.toString()
  }


  onFilterQueryChange = (query: any) => {
    this.setState({ urlSearchParam: query })
    this.onFilterChange(this.queryToString(query))
    console.log("query", query);
  }

  loadCategoriesSubCategories= ()=>{
    const msg = new Message(
      getName(MessageEnum.GetAllCategoriesMessage)
    )
    runEngine.sendMessage(msg.id, msg)

    const msg2 = new Message(
      getName(MessageEnum.GetAllSubCategoriesMessage)
    )
    runEngine.sendMessage(msg2.id, msg2)
  }

  preferenceChangeEffect = (ids: number[]) => {
    const lst = ids.map((id)=>this.allPreferences.find((p: any)=>p.id === id)?.label?.slice?.(0, 1));
    const labels = new Set(lst);
    
    const categoryOptions = this.state.allCategories.filter((cat: any) => {
      return cat.preferences.some((p: any)=>labels.has(p.slice(0, 1)))
    })


    this.setState({ categoryOptions, selectedPreferences: lst as any[] })
  }

  categoryChangeEffect = (ids: number[]) => {
    const plookup = new Set(this.state.selectedPreferences) 
   const subCategoryOptions = this.state.allSubCategories.filter((st: any) => {
      console.log({st})
      return st.categories.some((ct: any) => ids.includes(ct.id)) && st.preferences.some(
        (p: any)=> plookup.has(p.slice(0,1))
      )
    })

    console.log({subCategoryOptions})
    this.setState({ subCategoryOptions })
  }

  onInterested = async(interestedId: any) => {
    const events  = this.state.events.map(e => {
      if(e.id === interestedId){
        e.is_interested = true;
      }
      return e;
    })
    this.setState({events});
    const token = await getStorageData("authToken")
    const body = new FormData();
    body.append('id', interestedId);
    this.interestedEventId = sendAPIRequest(
      configJSON.interestedEventEndPoint,
      {
        method: "POST",
        headers: {token},
        body
      })
      // this.getAllEvent();
  }

  onLikeChange = (postId: any, delta: number) => {
    const events = this.state.events.map(p => {
      if(p.id == postId){
          p.total_likes += delta;
          p.like_id = [];
      }
      return p
    })
    this.setState({ events })
  }

  onLikeIdUpdate = (postId: any, likeObj: any) => {
    const events = this.state.events.map((p: any) => {
      if (p.id == postId) {
        p.like_id = [likeObj];
      }
      return p
    })
    this.setState({ events })
  }

  goToEvent = (event_Id: any) => () => {
    const url = `${this.props.navigation.history.location.pathname}/event/${event_Id}`
    this.props.navigation.history.push(url)
  }

  handleThreeDotClose = () => this.setState({ threedotAnchorEl: null, currentEventId: undefined })

  openThreeDot = (e: any) => {
    this.setState({ threedotAnchorEl: e.currentTarget, currentEventId: e.currentTarget.dataset.id, currentEvent: this.state.events.find((x: any) => x.id == e.currentTarget.dataset.id) })
    console.log("current Event=> ", this.state.currentEvent);
    console.log("e.currentTarget", e.currentTarget.dataset);
  }

  handleEventEdit = () => {
    console.log({ history: this.props.navigation.history })
    const url = `${this.props.navigation.history.location.pathname}/event-edit/${this.state.currentEventId}`
    this.props.navigation.history.push(url)
    this.setState({ threedotAnchorEl: null })
  }

  handleEventDelete = () => {
    this.setState({ threedotAnchorEl: null, deleteEventId: this.state.currentEventId })
  }

  handleEventDeleteConfirm = async() => {
    const token = await getStorageData("authToken")
    const EventDeleteCallId = sendAPIRequest(configJSON.eventDeleteEndPoint.replace(':id', this.state.deleteEventId), {
      method: 'DELETE',
      headers: { token }
    })
    this.setState({ EventDeleteCallId })
  }

  closeDeleteModal = () => this.setState({
    currentEventId: undefined,
    deleteEventId: undefined,
    EventDeleteCallId: undefined,
    currentEvent: undefined,
  })

  handleEventReport = () => {
    sendReportMessage(this.state.currentEventId, 'BxBlockEvents::Event')
    this.setState({ threedotAnchorEl: null })
  }

  handleRaiseRequest = (e: any) => {
    this.props.navigation.navigate('AddRequest')
    this.handleThreeDotClose()
    const requestOwner = this.context
    let assignedTo = this.state.currentEvent.created_by
    assignedTo = { ...assignedTo, first_name: assignedTo.name, last_name: '' }

    setTimeout(() => sendAddRequestMessage({
          disable_fields: ['created_by', 'accounts', 'request_type_id'],
          sender_id: requestOwner,
          created_by: requestOwner,
          accounts: {data: [{ id: String(assignedTo.id), attributes: assignedTo}]},
          "request_type_id": {
            "request_type": {
              "id": 9,
              "title": "event related",
            }
          }
        }), 500 // a slight delay to send after component is rendered
    )
  }

  handleFavClick = (id: any) => async() => {
    const event = this.state.events.find(j=>j.id===id)
    const token = await getStorageData("authToken")  
     if(event) {
      if(event.is_favourite) {
        const favourite_id = event.favourite_id[0]
        console.log("unfav", event.favourite_id[0]);
        if(!favourite_id) return ;
        const endpoint = configJSON.unFavEventEndPoint.replace(':id', favourite_id.id)
        const body = new FormData()
        body.append('data[favouriteable_type]', 'BxBlockEvents::Event')
        const callId = sendAPIRequest(endpoint, {method: 'DELETE', body, headers: {token}})
        this.apiUnfavCallIds.push(callId)
        this.unfavCallIdToPostId[callId] = event.id as any
      }
      else {
        const endpoint = configJSON.favEventEndPoint
        const body = new FormData()
        body.append('data[favouriteable_type]', 'BxBlockEvents::Event')
        body.append('data[favouriteable_id]', id)
        const callId = sendAPIRequest(endpoint, {method: 'POST', body, headers: { token }})
        this.apiFavCallIds.push(callId)
        this.favCallIdToPostId[callId] = id
      } 

      // Optimistic update
      const events = this.state.events.map(
        j => {
          if(j.id!==id) return j
          return ({...j, is_favourite: !j.is_favourite})
        }
      )
      this.setState({ events })
    }
  }

  handleShareCourse = (p: any) => () => {
    let data = {
      userId: p?.created_by?.id,
      postId: p?.id,
      link: `${window?.location?.origin}/user/${JSON.parse(p?.created_by?.id || '0')}`,
      shareableType: 'BxBlockEvents::Event',
      shareableId: p?.id,
    }
    sendSharePopupMessage(data);
  }

  startDateChange = (v: any) => {
    console.log("start date ", v);
    this.setState({ start_date: v})
  }

  endDateChange = (v: any) => {
    this.setState({end_date: v})
  }

  startTimeChange = (v: any) => {
    const currentTime = new Date().getTime();
    const currentDate = new Date().getDate();
    const startDate = this.state.start_date.getDate();
    console.log("currentTime =>", currentTime, v.getTime());
    if(startDate == currentDate && currentTime > v.getTime()){
      toast.warn("invalid start time")
    }
    console.log("start time ", v);
    this.setState({ start_time: v })
    console.log("state change ", this.state.start_time);
  }

  endTimeChange = (v: any) => {
    if((this.state.start_date.getDate() == this.state.end_date.getDate()) && this.state.start_time.getTime() >= v.getTime()){
      console.log("this is not valid");
      toast.warn("End time should be more than Start time");
    }
      console.log("end time ", v);
      this.setState({ end_time: v })
  }

  // Customizable Area End
  
}

import React from "react";

// Customizable Area Start
import {
	Box,
	Menu,
	Avatar,
	TextField,
	Hidden,
	MenuItem,
	InputAdornment,
	Backdrop,
	Card,
	Button,
	IconButton,
	Grid,
	Typography,
	Modal,
	TableBody,
	TableContainer,
	Paper,
	TableHead,
	Table,
	TableCell as TableCellView,
	TableRow,
	CircularProgress
} from "@material-ui/core";
import { styled } from '@material-ui/styles';
import Flex from "../../../components/src/Flex.web";
import { InputRow, MInput } from "../../../components/src/FormModal.web";
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloseIcon from "@material-ui/icons/Close";
import { contributors, ArrowDownIcon, iconCorpstage, ic_greenthumb, editIcon } from './assets';
import { Formik, Form } from 'formik';
import { Link } from 'react-router-dom'
import UserContext from "../../../components/src/UserProvider";
import { CloseCircleIcon } from "../../../components/src/Icons.web";
import NestedMenuItem from "../../../components/src/NestedMenuItem.web";
import TopNavBar from "../../../components/src/TopNavbar";
import LeftMenuWeb from "../../../components/src/LeftMenuWeb";
import { commentCount } from "../../ActivityFeed/src/assets";
import { SeekingFundDetailModel } from "../../../blocks/Surveys/src/SeekingFundDetailModel.web";
import RightMenuWeb from "../../../components/src/RightMenuWeb";
import { DatePicker } from '@material-ui/pickers'

export const configJSON = require("./config");

// Customizable Area End

import MyProjectsController, { Props } from "./MyProjectsController.web";
import { CommonActions } from "../../../components/src/PostCard.web";
import LikeAPost from "../../LikeAPost/src/LikeAPost.web";
import Search from "../../../components/src/Search.web";

const statusButton = (status: any) => {
	let statusButton;

	switch (status) {
		case 'Open':
			statusButton = <OpenLiveBtn>Open</OpenLiveBtn>;
			break;
		case 'Closed':
			statusButton = <ClosedLiveBtn>Closed</ClosedLiveBtn>;
			break;
		case 'Funded':
			statusButton = <FundedLiveBtn>Funded</FundedLiveBtn>;
			break;

		default:
			statusButton = <></>;
	}

	return statusButton;
}

export default class MyProjects extends MyProjectsController {
	constructor(props: Props) {
		super(props);
		// Customizable Area Start
		// Customizable Area End
	}

	// Customizable Area Start
	// Customizable Area End


	collaboratorsListModal = (value: any) => {
		if (this.state.tableRowId !== "" && this.state.tableRowId == value?.id && this.state.showCollaborators) {
			return collaboratorList(value,this.state.showCollaborators,this.handleCloseCollaborators)
		}

	}

	showUserLikes = (value: any, attributes: any) => {
		return (
			<CommonActions
				isProject={true}
				onStarClick={this.handleFavoriteClick(value?.id)}
				isFavourite={attributes?.is_favourite}
				onShareClick={this.handleShareCourse(value)}
				onCommentClick={this.goToEvent(value?.id)}
				onSurveyClick={this.handleSurveyOpen(value?.id)}
				likeButton={
					<LikeAPost
						likeable_type="BxBlockProjectManagement::ProjectManagement"
						navigation={this.props.navigation}
						id=""
						isLiked={Boolean(attributes?.project_likes?.some((project: any) => Number(project.like_by_id) === Number(this.state.userID)))}
						post_id={value?.id}
						like_id={attributes?.project_likes.find((project: any) => Number(project.like_by_id) == (this.state.userID))?.id}
						onLikeChange={this.onLikeChange}
						onLikeIdUpdate={this.onLikeIdUpdate}
					/>}
			/>
		)
	}

	showPreference = (value: any): string => {
		switch (value) {
			case "S":
				return "Social";
			case "E":
				return "Environment";
			case "G":
				return "Governance";
			default:
				return "";
		}
	};

	sdgFactorsData = (value: any) => {
		if (this.state.tableRowId == value?.id && this.state.showSdg) {
			return <ShowSdgFactorsData onClose={this.handleCloseSDGFactors} openModal={this.state.showSdg} data={value?.attributes?.sdg_factors} />
		}
	}

	showProjectAndFundsList() {
		if (this.state.showProjectTab) {
			console.log("this.state.profileDashboardData",this.state.profileDashboardData);
			if (this.state.Overlayloader) {
				return <div style={{ display: "flex", justifyContent: "center", width: "100%", minHeight: "350px", alignItems: "center" }}><CircularProgress size={40} /></div>
			}
			else if (this.state.profileDashboardData?.length > 0) {			
				return (
					this.state.profileDashboardData.map((value: any) => {
						const { attributes } = value
						return <ProjectTabContainer>
							{this.ShowProjects(value, attributes)}
						</ProjectTabContainer>
					})
				)
			}
			else {
				return <div style={{ display: "flex", justifyContent: "center", width: "50%", alignItems: "center", minHeight: "144px" }}>No Data Found</div>
			}
		}

		if (this.state.showSeekingFundTab) {
			if (this.state.seekingFundsData?.length > 0) {
				return (
					<SectionItem style={{ display: 'flex', gap: '15px', marginTop: '20px', flexWrap: 'wrap', }}>
						{this.state.seekingFundsData?.map((value: any) => {
							const { attributes } = value

							return (
								<Card style={{ borderRadius: "10px", marginBottom: '20px', width: 'calc(50% - 20px)', border: "1px solid #f2f2f2", }}>
									<CardSection id="SeekingCardButton" style={{ width: '100%', boxSizing: 'border-box', cursor: 'pointer' }} onClick={() => this.handleseekingFunds(value)}>
										<DivSection id="ModelClickDiv">
											<DetailSection style={{ marginBottom: '15px', }}>
												<Box style={{ display: 'flex', alignItems: 'center', gap: '10px', }}>
													<Avatar src={attributes?.image} />
													<SectionItem style={{ fontSize: "22px" }}>
														{attributes?.project_name}
													</SectionItem>
												</Box>
												<SectionItem style={{ display: 'flex', alignItems: 'center', gap: '15px', margin: '0', }}>
													{statusButton(attributes?.project_status)}
												</SectionItem>
											</DetailSection>
											<span >
												<DetailSection style={{ marginBottom: '10px', cursor: 'pointer' }} >
													<SectionText>
														<Typography variant="subtitle1" component="div">Project ID</Typography>
														<Typography component="div" variant="subtitle2">{attributes?.project_unique_id}</Typography>
													</SectionText>
													<SectionText >
														<Typography component="div" variant="subtitle1">Location</Typography>
														<Typography variant="subtitle2" component="div">{attributes?.location}</Typography>
													</SectionText>
												</DetailSection>

												<DetailSection style={{ marginBottom: '10px' }}>
													<SectionText>
														<Typography component="div" variant="subtitle1">Organization Name</Typography>
														<Typography component="div" variant="subtitle2">{attributes?.organization_name}</Typography>
													</SectionText>
													<SectionText>
														<Typography variant="subtitle1" component="div">Preferences</Typography>
														<Typography component="div" variant="subtitle2">{attributes?.preferences?.toString()}</Typography>
													</SectionText>
												</DetailSection>

												<DetailSection>
													<SectionText>
														<Typography component="div" variant="subtitle1">Funding Required</Typography>
														<Typography variant="subtitle2" component="div">{attributes?.funding_amount}</Typography>
													</SectionText>
													<SectionText>
														<Typography variant="subtitle1" component="div">SDG Factors</Typography>
														<div style={{ display: "flex" }}>
															{attributes?.sdg_factors !== "" && attributes?.sdg_factors?.map((val: any) => {
																return (
																	<Avatar style={{ height: '25px', objectFit: 'cover', width: "25px", border: 'solid 1px #196519', marginRight: '-10px', borderRadius: "50%", verticalAlign: "middle" }} src={val?.image_url} />
																)
															})}
														</div>
													</SectionText>
												</DetailSection>
											</span>
										</DivSection>
									</CardSection>
								</Card>
							)
						})}
					</SectionItem>
				)
			}
			else {
				return <div style={{ width: "50%", justifyContent: "center", display: "flex", minHeight: "144px", alignItems: "center" }}>No Data Found</div>
			}
		}
	}

	ShowProjects(value: any, attributes: any) {
		return (
			<>
				<ProjectTabCard>
					<ProjectTabCardHeader>
						<div style={{ display: 'flex', gap: '15px' }}>
							<Link to={`/user/${attributes?.created_by?.data?.attributes?.id}`}>
								<div style={{ width: '55px', cursor: "pointer", height: '55px', background: 'gray', borderRadius: '50%' }}>
									<img style={{ width: '55px', borderRadius: '50%', height: '55px' }} src={attributes?.created_by?.data?.attributes?.profile_pic} />
								</div>
							</Link>
							<div style={{ flexDirection: 'column', display: 'flex', }}>
								<div onClick={() => this.goToProjectDetailEndUser(value?.id)} style={{ cursor: "pointer", fontSize: '19px', color: '#000', marginBottom: '7px' }}>
									<Typography style={{ fontWeight: "bold" }}>{attributes?.project_name}</Typography>
								</div>
								<div onClick={() => this.goToProjectDetailEndUser(value?.id)} style={{ cursor: "pointer", color: 'rgb(119, 113, 113)', fontSize: '16px', display: "flex", width: "100%" }}>
									<div>
										<Typography>{attributes?.location}</Typography>
									</div>
									<div style={{ display: "flex", marginLeft: "240px", }}>
										<Typography style={{ color: '#4a4747', fontWeight: "bold" }}>Preference: </Typography>
										<Typography style={{ marginLeft: "6px", color: "rgb(119, 113, 113)" }}>{this.showPreference(attributes?.preference)}</Typography>
									</div>
								</div>
								<div style={{ color: 'rgb(79, 74, 74)', display: "flex", fontSize: '16px', marginTop: "6px" }}>
									<Typography style={{ fontWeight: "bold", marginRight: "7px" }}>Company Name:</Typography>
									<Link id="companyLink" style={{ textDecorationLine: "underline", cursor: "pointer", color: 'rgb(119, 113, 113)', fontSize: '16px' }} to={`/CorporateProfile/${attributes?.corporate_id}`}>{attributes?.company_name}</Link>
								</div>
							</div>
						</div>
						<div onClick={() => this.goToProjectDetailEndUser(value?.id)} style={{ cursor: "pointer", color: 'rgb(119, 113, 113)', fontSize: '16px', gap: '10px', display: 'flex', alignItems: 'center' }}><img style={{ width: '25px' }} src={iconCorpstage} alt="icon" />
							<Typography style={{ paddingTop: '2px' }}>{attributes?.completed_project_milestones}/{attributes?.total_project_milestones}</Typography>
						</div>
					</ProjectTabCardHeader>
					<p
						style={{
							color: 'rgb(119, 113, 113)',
							fontSize: '16px',
							marginBottom: '20px',
							wordWrap: "break-word"
						}}>
						<Typography>{attributes?.description?.length > 455 ? `${attributes?.description.substring(0, 455)}` : attributes?.description}{attributes?.description?.length > 455 ? <Link style={{ fontWeight: "bold" }} to={`/my_projects/projects/${value?.id}`}>...See more</Link> : null}</Typography>
					</p>
					{attributes?.project_profile_picture?.length > 0 && <div onClick={() => this.goToProjectDetailEndUser(value?.id)} style={{ cursor: "pointer", borderRadius: '6px', minHeight: '100px', width: '100%', marginBottom: '20px' }}>
						<img style={{ width: '100%', height: '41vh', borderRadius: '8px' }} src={attributes?.project_profile_picture} />
					</div>}
					<div style={{
						display: 'flex',
						justifyContent: 'space-between',
						marginBottom: '10px'
					}}>
						<div
							style={{
								gap: '10px',
								display: 'flex',
								alignItems: "baseline"
							}}><span style={{ color: '#000', fontSize: '14px' }}>Collaborators :</span> <div id='Collaborators-btn' onClick={() => attributes?.project_collaborators?.length > 0 && this.handleShowCollaborators(value?.id)} style={{ color: '#989898', fontSize: '12px', cursor: 'pointer' , display: "flex"}}>{attributes?.project_collaborators?.slice(0, 3)?.map((value: any) => value?.data?.attributes?.image ? <img style={{  height: '25px', width: "25px",objectFit: 'cover', border: 'solid 1px #196519', marginRight: '-10px', borderRadius: "50%", verticalAlign: "middle", backgroundColor: '#fff', }} src={value?.data?.attributes?.image} /> : <Avatar style={{ width: "25px", height: '25px', border: 'solid 1px #196519', marginRight: '-10px', borderRadius: "50%", verticalAlign: "middle" , backgroundColor:'a9a9a970', fontSize:'14px', color:'#000'}}>{value?.data?.attributes?.first_name?.charAt(0)?.toUpperCase()}</Avatar>)}</div>
							<div>{attributes?.project_collaborators?.length > 3 && <div data-test-id="more-collaborators-btn"onClick={() => this.handleShowCollaborators(value.id)} style={{ cursor: "pointer", marginLeft: '5px', fontSize: "14px", color: "rgb(119, 113, 113)" }}>{` +${value.attributes?.project_collaborators?.length - 3} more`}</div>}</div>
						</div>
						<div style={{ alignItems: "baseline", display: 'flex', gap: '10px', }}><span style={{ color: '#000', fontSize: '14px' }}>SDG Factors :</span>
							<div style={{ color: 'rgb(119, 113, 113)', fontSize: '14px', display: "flex", marginRight: "14px" }}>
								<div style={{ marginRight: "7px", display: "flex", cursor: 'pointer' }} onClick={() => attributes?.sdg_factors?.length > 0 && this.handleOpenSDGFactors(value?.id)}>
									{attributes?.sdg_factors !== "" && attributes?.sdg_factors?.slice(0, 5)?.map((value: any) => {
										return (
											<div>
												<img style={{ marginRight: '-10px', width: "25px", height: '25px', objectFit: 'cover', border: 'solid 1px #196519', borderRadius: "50%", verticalAlign: "middle" }} src={value?.data?.attributes?.image} />
											</div>
										)
									})}
								</div>
								{attributes?.sdg_factors !== "" && attributes?.sdg_factors?.length > 5 && <Typography style={{ marginLeft: "6px", cursor: "pointer" }} onClick={() => this.handleOpenSDGFactors(value?.id)}>{`+${attributes?.sdg_factors?.length - 5} more`}</Typography>}
							</div>
						</div>
					</div>
					<div style={{ marginTop: '20px', marginBottom: '10px', display: 'flex', justifyContent: 'space-between', }}>
						<div style={{ display: 'flex', gap: '10px', }}>
							<div style={{ color: 'rgb(119, 113, 113)', fontSize: '14px', gap: '6px', display: 'flex', alignItems: 'center' }}>
								<img
									style={{ width: '18px', }} src={ic_greenthumb} /> <div style={{ color: '#000', paddingTop: '2px', fontSize: "14px" }}>{attributes?.project_likes_count}</div>
								<img style={{ width: '18px' }} src={commentCount} /> <div style={{ color: '#000', fontSize: "14px", paddingTop: '2px', }}>{attributes?.project_comments_count}</div>
							</div>
							<div id='TopContributorBtn' onClick={() => this.handleTopContributorsModalOpen(value?.id)} style={{ color: '#000', fontSize: "14px", display: 'flex', alignItems: 'center', cursor: "pointer", gap: '6px' }}><img src={contributors} style={{ width: "19px" }} /> Top Contributors</div>
						</div>
						<div><Button id='goToRequestToCollaboratorPage' onClick={() => this.props.navigation.history.push(`${this.props.navigation.history.location.pathname}/RequestToCollaborate/${value.id}`)} style={{ color: 'rgb(0, 161, 0)', textTransform: 'none', border: 'solid 1px rgb(0, 161, 0)', borderRadius: '25px', fontWeight: 600, fontSize: '13px', padding: '0px 10px 0px 10px', }}>Request To Collaborate</Button></div>
					</div>
				</ProjectTabCard>
				{this.state.tableRowId !== "" && this.state.tableRowId == value?.id && <TopContibutorsList data={attributes?.top_contributors} handleTopContributorsModalClose={this.handleTopContributorsModalClose} openModal={this.state.openContributros} />}
				{this.collaboratorsListModal(value)}
				{this.showUserLikes(value, attributes)}
				{this.sdgFactorsData(value)}
			</>
		)
	}

	MyProjectTabs() {
		return <>
			<div style={{ display: 'flex', flexDirection: 'column', }}>
				<div
					style={{ marginTop: "25px", display: 'flex', flexDirection: 'column', }}
				>
					<RoundedButtonBase style={{ width: 'max-content', display: 'flex', justifyContent: 'space-between', }}>
						<CorporateLicenceTabs
							id="chooseProjectTab"
							onClick={this.chooseProjectTab}
							style={this.state.activeProjectTab ? { backgroundColor: "#00A100", color: "white" } : { backgroundColor: "white" }}
						>MY PROJECTS</CorporateLicenceTabs>
						<IndividualUserTabs
							id="ProjectSeekingBtn"
							onClick={this.chooseSeekingTab}
							style={this.state.activeSeekingFundTab ? { color: "white", backgroundColor: "#00A100" } : { backgroundColor: "white", }}
						>PROJECT SEEKING FUNDS</IndividualUserTabs>
					</RoundedButtonBase>
				</div>
				<div
					style={{ display: 'flex', width: '100%', flexDirection: 'column', }}
				>
					{this.state.showProjectTab &&
						<Grid style={{ width: '100%', marginTop: '25px', gap: '15px', display: 'flex' }}>
							<Flex style={{ width: "30%" }}>
								<Search searchInputValue={this.state.searchInputValue} onChange={(e: any) => this.handleFilterQueryChangeForMyProjects(e)} />
							</Flex>
							<Flex
								style={{ minWidth: '300px', display: "flex", gap: '10px', }}
							>
								<div style={{ flex: 1 }}>
									<TextFieldRounded
										value={this.state.filterText}
										onClick={this.handleFilterMenuOpen}
										// @ts-ignore 
										InputProps={{
											ref: this.filterRef,
											endAdornment: (<InputAdornment position="start">
												{this.state.filterText && <CloseCircleIcon
													style={{ width: '24px', margin: '0 10px', cursor: 'pointer' }}
													onClick={this.clearFilters}
												/>}
												<img src={ArrowDownIcon} width="16" />
											</InputAdornment>)
										}} variant="outlined" placeholder={"Filter by:"} />
									{/* @ts-ignore */}
									<Hidden mdDown>
										<FilterMenu
											sdgFocous={this.state.sdgFocousData}
											userId={this.context?.id}
											menuPosition={this.state.filterMenuPosition}
											handleDateInputFocus={this.handleDateInputFocus}
											handleDateInputBlur={this.handleDateInputBlur}
											onClose={this.handleFilterMenuClose}
											filterByAll={this.state.filterByAll}
											filterByCreatedBy={this.state.filterByCreatedBy}
											filterByCreatedOn={this.state.filterByCreatedOn}
											filterBySDGFactorsId={this.state.filterBySDGFactorsId}
											filterByDimensions={this.state.filterByDimensions}
											toggleFilterByAll={this.toggleFilterByAll}
											toggleFilterByCreatedBy={this.toggleFilterByCreatedBy}
											toggleFilterByCreatedOn={this.toggleFilterByCreatedOn}
											toggleFilterSDGFactor={this.toggleFilterSDGFactor}
											toggleFilterByDimensions={this.toggleFilterByDimensions}
											startDateChange={this.startDateChange}
											createdOn={this.state.createdOn}
											dateControls={{
												handleSubmit: this.handleDateFilterSubmit,
												handleCancel: this.handleDateFilterCancel,
												handleFromDateChange: this.handleFromDateChange,
												handleToDateChange: this.handleToDateChange,
												filterFromDate: this.state.filterFromDate,
												filterToDate: this.state.filterToDate,
												minDate: this.state.filterFromDate,
												maxDate: this.state.filterToDate
											}}
										/>
									</Hidden>
								</div>
							</Flex>
						</Grid>
					}
				</div>
			</div>
			<div>
				{this.showProjectAndFundsList()}
			</div>
		</>
	}

	render() {
		return (
			// Customizable Area Start
			<>
				<MainBox
					style={{
						backgroundColor: "#edebeb",
						display: "flex",
						flexDirection: "column",
						paddingBottom: "20px",
						overflowX: "hidden"
					}}
				>
					<Box>
						<TopNavBar
							navigation={this.props.navigation}
							id={""}
						/>
					</Box>

					<OuterGrid container spacing={3}>
						<LeftGrid item xs={12} md={3} sm={12} lg={3}>
							<Box ><LeftMenuWeb navigation={this.props.navigation} id={""} /></Box>
						</LeftGrid>
						<MiddleGrid item xs={12} md={6} sm={12} lg={6} >
							{
								this.state.openSeekingFunds ?
									<Modal id="ModalComponent"
										open={this.state.openSeekingFunds}
										onClose={() => { this.setState({ openSeekingFunds: false }) }}
										aria-labelledby="simple-model-title"
										aria-describedby="simple-model-description"
									>
										<SeekingFundDetailModel detail={this.state.viewProjectData} navigation={this.props.navigation} handleLikeChange={this.handleLikeChange} onCloseClick={() => { this.setState({ ...this.state, openSeekingFunds: false }) }} />

									</Modal> : this.MyProjectTabs()
							}
						</MiddleGrid>
						<RightGrid item sm={12} md={3} xs={12} lg={3}>
							<Box><RightMenuWeb navigation={this.props.navigation} id={""} /></Box>
						</RightGrid>
					</OuterGrid>
				</MainBox>
			</>
			// Customizable Area End
		);
	}
}

// Customizable Area Start
MyProjectsController.contextType = UserContext

interface SdgFactorProp {
	data: any,
	openModal: boolean,
	onClose: any,
}

const ShowSdgFactorsData = ({ onClose, data, openModal }: SdgFactorProp) => {
	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={openModal}
				closeAfterTransition
				BackdropComponent={Backdrop}
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
				onClose={() => {
					onClose()
				}}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<ModalWrapper
					className="schedule_modal">
					<Grid container spacing={0}>
						<ModalTitle item xs={12} >
							<Typography style={{ paddingTop: '14px', fontWeight: 'bold' }}>SDG Factors</Typography>
							<CloseIconButton onClick={() => {
								onClose()
							}}>
								<CloseIcon style={{}} />
							</CloseIconButton>
						</ModalTitle>

						<Grid item xs={12} style={{
							display: 'flex',
							alignItems: 'center',
							flexDirection: 'column',
							justifyContent: 'center',
							padding: '18px 36px'
						}}>
							<RightFormWrapperDiv>
								<Grid item xs={12}>
									<TableContainer component={Paper}>
										<Table>
											<TableHead>
												<TableCellView style={{ color: 'black', fontWeight: 'bold', }}>S.No</TableCellView>
												<TableCellView style={{ fontWeight: 'bold', color: 'black', }}>Goal</TableCellView>
												<TableCellView style={{ fontWeight: 'bold', color: 'black' }}>Picture</TableCellView>
											</TableHead>
											<TableBody>
												{
													data?.map((value: any, index: any) => {
														return (
															<TableRow>
																<TableCellView>
																	{index + 1}
																</TableCellView>
																<TableCellView>{value?.data?.attributes?.goal}</TableCellView>
																<TableCellView>
																	<Avatar
																		style={{
																			width: "25px",
																			height: "25px"
																		}}
																		src={value?.data?.attributes?.image}
																	/>
																</TableCellView>
															</TableRow>
														)
													})
												}
											</TableBody>
										</Table>
									</TableContainer>
								</Grid>
							</RightFormWrapperDiv>
						</Grid>
					</Grid>
				</ModalWrapper>
			</Modal>
		</div >
	)
}




interface FilterMenuProps {
	filterByAll: boolean
	filterByDimensions: boolean
	filterByCreatedBy: boolean
	filterByCreatedOn: boolean
	filterBySDGFactorsId: boolean
	toggleFilterByAll: any
	toggleFilterByCreatedBy: any
	toggleFilterByCreatedOn: any
	toggleFilterSDGFactor: any
	menuPosition: {
		top: number,
		left: number,
	} | undefined
	onClose: () => void,
	inside?: string,
	sdgFocous: any,
	startDateChange: any,
	createdOn: any,
	userId: any,
	dateControls: any,
	handleDateInputFocus: any,
	handleDateInputBlur: any,
	toggleFilterByDimensions: any
}

export const FilterMenu = ({ handleDateInputBlur, dateControls, handleDateInputFocus, userId, createdOn, startDateChange, sdgFocous, menuPosition, onClose, ...props }: FilterMenuProps) => {
	const clearToDate = (e: React.MouseEvent) => {
		e.stopPropagation()
		dateControls.handleToDateChange(null)
	}

	const clearFromDate = (e: React.MouseEvent) => {
		e.stopPropagation()
		dateControls.handleFromDateChange(null)
	}

	const [nestedMenuName, setNestedMenuName] = React.useState("")

	return (
		<>
			<Menu
				anchorPosition={menuPosition}
				open={!!menuPosition}
				onClose={onClose}
				anchorReference="anchorPosition"
			>
				<MenuItem
					selected={props.filterByAll}
					onClick={() => props.toggleFilterByAll('All')}>
					All
				</MenuItem>
				<NestedMenuItem
					name="sdg_factors_ids"
					nestedMenuName={nestedMenuName}
					setNestedMenuName={setNestedMenuName}
					label="SDG Factors"
					parentMenuOpen={!!menuPosition}
					// @ts-ignore
					MenuProps={{
						getContentAnchorEl: null,
						anchorOrigin: { vertical: 'top', horizontal: 'right', },
						transformOrigin: { vertical: 'top', horizontal: 'left', },
					}}
				>
					<FilterByDateWrapper
						style={{ height: 'auto', width: 'auto' }}>
						{sdgFocous?.map((val: any) => {
							return (
								<MenuItem
									onClick={() => props.toggleFilterSDGFactor(val)}
									selected={props.filterByDimensions}
								>
									{val?.attributes?.goal}
								</MenuItem>
							)
						})}
					</FilterByDateWrapper>
				</NestedMenuItem>
				<NestedMenuItem name="dimensions"
					nestedMenuName={nestedMenuName}
					setNestedMenuName={setNestedMenuName}
					label="Dimensions"
					parentMenuOpen={!!menuPosition}
					// @ts-ignore
					MenuProps={{
						getContentAnchorEl: null,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						transformOrigin: {
							vertical: 'top',
							horizontal: 'left',
						},
					}}
				>
					<FilterByDateWrapper style={{ height: 'auto', width: 'auto' }}>
						<MenuItem onClick={() => props.toggleFilterByDimensions('Environment')} selected={props.filterByDimensions}>Environment</MenuItem>
						<MenuItem onClick={() => props.toggleFilterByDimensions('Social Capital')} selected={props.filterByDimensions}>Social Capital</MenuItem>
						<MenuItem onClick={() => props.toggleFilterByDimensions('Human Capital')} selected={props.filterByDimensions}>Human Capital</MenuItem>
						<MenuItem onClick={() => props.toggleFilterByDimensions('Human Capital')} selected={props.filterByDimensions}>Business Model & Inovation</MenuItem>
						<MenuItem onClick={() => props.toggleFilterByDimensions('Human Capital')} selected={props.filterByDimensions}>Leadership & Governance</MenuItem>
					</FilterByDateWrapper>
				</NestedMenuItem>
				<NestedMenuItem name="created_on"
					nestedMenuName={nestedMenuName}
					setNestedMenuName={setNestedMenuName}
					// keepOpen={keepOpen}
					label="Based on Date"
					parentMenuOpen={!!menuPosition}
					// @ts-ignore
					MenuProps={{
						getContentAnchorEl: null,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						transformOrigin: {
							vertical: 'top',
							horizontal: 'left',
						},
					}}
				>
					<FilterByDateWrapperForDate>
						<FilterByDateRow>Choose Date</FilterByDateRow>
						<FilterByDateRow>
							<span style={{ flex: 1 }}>From</span>
							<div style={{ flex: 3 }}>
								{/* @ts-ignore  */}
								<DatePicker variant="inline"
									inputVariant="outlined"
									maxDate={new Date()}
									onChange={dateControls.handleFromDateChange}
									value={dateControls.filterFromDate}
									autoOk={true}
									clearable
									format="yyyy-MM-dd"
									InputProps={{
										endAdornment: (<InputAdornment position="end">
											{dateControls.filterFromDate && <CloseCircleIcon
												onClick={clearFromDate}
												style={{ width: '24px', cursor: 'pointer' }}
											/>}
										</InputAdornment>)
									}}
									style={{ width: '80%' }}
									inputProps={{
										placeholder: 'Select', onFocus: handleDateInputFocus,
										onBlur: handleDateInputBlur,
									}}
									initialFocusedDate={dateControls.filterToDate}
								/>

							</div>
						</FilterByDateRow>
						<FilterByDateRow> <span style={{ flex: 1 }}>To</span> <div style={{ flex: 3 }}>
							<DatePicker clearable
								inputVariant="outlined"
								format="yyyy-MM-dd"
								minDate={dateControls.filterFromDate || new Date()}
								autoOk={true}
								variant="inline"
								maxDate={new Date()}
								value={dateControls.filterToDate}
								initialFocusedDate={dateControls.filterFromDate}
								style={{ width: '80%' }}
								inputProps={{
									placeholder: 'Select', onFocus: handleDateInputFocus,
									onBlur: handleDateInputBlur
								}}
								onChange={dateControls.handleToDateChange}
								// @ts-ignore
								// shouldDisableDate={(date: any)=> dateControls.filterFromDate && date<dateControls.filterFromDate}
								InputProps={{
									endAdornment: (<InputAdornment position="end">
										{
											dateControls.filterToDate && <CloseCircleIcon
												onClick={clearToDate}
												style={{ width: '24px', cursor: 'pointer' }}
											/>
										}
									</InputAdornment>)
								}}
							/>

						</div>
						</FilterByDateRow>
						<FilterByDateRow> <div style={{ flex: 1 }}></div><div style={{ flex: 3 }}>
							<CancelButton onClick={dateControls.handleCancel}>Cancel</CancelButton>
							<SubmitButton onClick={dateControls.handleSubmit}>Submit</SubmitButton>
						</div>
						</FilterByDateRow>
					</FilterByDateWrapperForDate>

				</NestedMenuItem>
				<MenuItem onClick={() => props.toggleFilterByCreatedBy(userId)} selected={props.filterByCreatedBy}>Created by Me</MenuItem>
			</Menu>
		</>
	)
}

interface TopContributorsProps {
	data: any
	openModal: boolean;
	handleTopContributorsModalClose: any;
}

export const TopContibutorsList = ({ data, handleTopContributorsModalClose, openModal }: TopContributorsProps) => {
	return (
		<div>
			<Modal aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",

				}}
				onClose={() => {
					handleTopContributorsModalClose()
				}}
				open={openModal}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<ModalWrapper className="schedule_modal">
					<Grid container spacing={0}>
						{/* Modal Title */}
						<ModalTitle item xs={12} >
							<Typography style={{ paddingTop: '14px', fontWeight: 'bold' }}>Top Contributors</Typography>
							{/* Close Btn */}
							<CloseIconButton onClick={() => { handleTopContributorsModalClose() }}><CloseIcon style={{}} /></CloseIconButton>
							{/* Close Btn END */}
						</ModalTitle>
						{/* Modal Title END */}

						<Grid item xs={12}
							style={{
								display: 'flex',
								justifyContent: 'center',
								flexDirection: 'column',
								padding: '18px 36px',
								alignItems: 'center',
							}}>
							<RightFormWrapperDiv>
								<Grid
									item xs={12}
								>
									<TableContainer
										component={Paper}
									>
										<Table>
											<TableHead>
												<TableCellView style={{ fontWeight: 'bold', color: 'black' }}>S.No</TableCellView>
												<TableCellView style={{ fontWeight: 'bold', color: 'black' }}>First Name</TableCellView>
												<TableCellView style={{ fontWeight: 'bold', color: 'black' }}>Last Name</TableCellView>
												<TableCellView style={{ fontWeight: 'bold', color: 'black' }}>Preference</TableCellView>
												<TableCellView style={{ fontWeight: 'bold', color: 'black' }}>Company Name</TableCellView>
											</TableHead>
											<TableBody>
												{
													data?.map((value: any, index: any) => {
														return (
															<TableRow>
																<TableCellView>{index + 1}</TableCellView>
																<TableCellView>{value?.first_name}</TableCellView>
																<TableCellView>{value?.last_name}</TableCellView>
																<TableCellView>{value?.preference}</TableCellView>
																<TableCellView>{value?.company_name}</TableCellView>
															</TableRow>
														)
													})
												}
											</TableBody>
										</Table>
									</TableContainer>
								</Grid>
							</RightFormWrapperDiv>
						</Grid>
					</Grid>
				</ModalWrapper>
			</Modal>
		</div >
	)
}

interface CollaboratorListProps {
	openModal: boolean;
	data: any;
	handleCollabratorClose: any;
}

const collaboratorList = ( data:any, openModal:any, handleCollabratorClose:any) => {
	return (
		<Modal
			aria-describedby="transition-modal-description"
			style={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",

			}}
			aria-labelledby="transition-modal-title"
			open={openModal}
			onClose={() => {
				handleCollabratorClose()
			}}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<ModalWrapper
				className="schedule_modal">
				<Grid container spacing={0}>
					{/* Modal Title */}
					<ModalTitle item xs={12} >
						<Typography data-test-id="model-title" style={{ paddingTop: '14px', fontWeight: 'bold' }}>Collaborators</Typography>
						{/* Close Btn */}
						<CloseIconButton data-test-id='model-close-button' onClick={() => { handleCollabratorClose() }}>
							<CloseIcon style={{}} />
						</CloseIconButton>
						{/* Close Btn END */}
					</ModalTitle>
					{/* Modal Title END */}

					<Grid
						item xs={12}
						style={{
							justifyContent: 'center',
							alignItems: 'center',
							flexDirection: 'column',
							padding: '18px 36px',
							maxHeight: "550px",
							display: 'flex',
						}}>
						<RightFormWrapperDiv>
							<Grid item xs={12}>
								<div>
									<Table
										style={{ minWidth: 650 }}
										aria-label="simple table"
									>
										<TableHead>
											<TableRow>
												<TableCellView>S.No</TableCellView>
												<TableCellView>Name</TableCellView>
												<TableCellView>Profile</TableCellView>
												<TableCellView>Location</TableCellView>
											</TableRow>
										</TableHead>
										<TableBody>
											{data?.attributes?.project_collaborators?.map((value: any, index: any) => {
												const {data}=value
												return (
													<TableRow>
														<TableCellView>
															{index + 1}
														</TableCellView>
														<TableCellView>
															{data?.attributes?.full_name}
														</TableCellView>
														<TableCellView>
															{<Avatar style={{ width: 34, height: 34, margin: "auto" }} src={data?.attributes?.image} />}
														</TableCellView>
														<TableCellView>
															{data?.attributes?.location}
														</TableCellView>
													</TableRow>
												)
											})}
										</TableBody>
									</Table>
								</div>
							</Grid>
						</RightFormWrapperDiv>
					</Grid>
				</Grid>
			</ModalWrapper>
		</Modal>
	)
}

const SectionItem = styled(Box)({
	margin: '8px 0px',
	'& .MuiTypography-root.MuiTypography-subtitle1': {
		fontWeight: 'bold',
		fontSize: 11,
	},
	'& .MuiTypography-root.MuiTypography-subtitle2': {
		fontWeight: 'normal',
		color: 'grey',
		fontSize: 14
	}
});

const DetailSection = styled('div')({
	display: "flex",
	justifyContent: "space-between"
})

const CardSection = styled('div')({
	padding: "15px",
	width: "300px"
})

const TextareaView = styled('div')({
	border: '2px solid #bdbdbd',
	color: '#bdbdbd',
	borderRadius: '5px',
	padding: '5px'
})

const DivSection = styled('div')({
})

const MainBox = styled(Box)({
	backgroundColor: "#edebeb",
	display: "flex",
	flexWrap: 'wrap',
	flexDirection: "column",
	paddingBottom: "20px",
	overflowX: "hidden",
})

const SubmitButton = styled(Button)({
	background: '#009c20',
	color: '#FFF',
	'&:hover': {
		background: '#009c20',
		opacity: 0.9
	}
})

const CancelButton = styled(Button)({
	color: '#FFF',
	background: '#999',
	marginRight: '5px',
	'&:hover': {
		background: '#999',
		opacity: 0.9
	}
})

const OuterGrid = styled(Grid)({
	display: 'flex',
	flexWrap: 'wrap',
	justifyContent: 'space-between',
})


const LeftGrid = styled(Grid)({
	maxWidth: '300px',
	'@media screen and (max-width: 985px)': {
		maxWidth: '200px',
	},
	'@media screen and (max-width: 768px)': {
		display: 'none',
		visibility: 'hidden',
	},
})

const MiddleGrid = styled(Grid)({
	width: 'calc(100vw - 600px)',
	maxWidth: 'unset',
	flexBasis: 'unset',
	justifyContent: 'center',
	'@media screen and (max-width: 985px)': {
		width: 'calc(100vw - 500px)',
	},
	'@media screen and (max-width: 768px)': {
		width: '100%'
	}
})

const RightGrid = styled(Grid)({
	maxWidth: '300px',
	'@media screen and (max-width: 985px)': {
		maxWidth: '250px',
	},
	'@media screen and (max-width: 768px)': {
		maxWidth: 'auto',
		width: '100%'
	},
})


const TextFieldRounded = styled(TextField)({
	width: '100%',
	background: '#FFFFFF',
	borderRadius: '15px',
	'& > .MuiOutlinedInput-root': {
		borderRadius: '15px',
	},
	'& > div > input': {
		paddingTop: '14px',
		paddingBottom: '14px',
		'&::placeholder': {
			color: '#484747 !important',
			opacity: '1 !important',
		},
	}
})

const FilterByDateWrapper = styled('div')({
	width: '300px',
	height: '200px',
	padding: '10px 15px',
	background: '#FFF'
})

const FilterByDateWrapperForDate = styled('div')({
	width: '320px',
	height: '220px',
	padding: '10px 15px',
	background: '#FFF'
})

const ProjectTabContainer = styled('div')({
	width: '100%',
	marginTop: '30px',
	borderRadius: '6px',
	background: '#fff',
})

const ProjectTabCard = styled('div')({
	padding: '30px 30px 6px 30px',
	borderBottom: 'solid 1px rgb(227, 227, 227)',
})

const ProjectTabCardHeader = styled('div')({
	display: 'flex',
	marginBottom: '20px',
	alignItems: 'flex-start',
	justifyContent: 'space-between',
	gap: '20px',
})


const SectionText = styled(Box)({
	margin: "4px 0px",
	width: '50%',
	color: '#464646',
	'& .MuiTypography-root.MuiTypography-subtitle1': {
		fontWeight: "bold",
		fontSize: 14,
	},
	'& .MuiTypography-root.MuiTypography-subtitle2': {
		fontWeight: "normal",
		color: "grey",
		fontSize: 14,
	}
})

const ModalWrapper = styled(Box)({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	flexDirection: 'column',
	background: '#fff',
	borderRadius: 8,
	width: '670px',
	position: "relative",
	margin: '0 10px',
})

const FilterByDateRow = styled('div')({
	display: 'flex',
	marginBottom: '20px',
	alignItems: 'center'
})

const IndividualUserTabs = styled(Button)({
	border: "2px solid transparant", color: "#009B07", fontWeight: 600, backgroundColor: "#FFFFFF", textTransform: "none", borderRadius: "38px", height: "100%",
	padding: "0 50px 0 50px",
	'@media screen and (max-width: 1110px) and (min-width: 769px)': {
		padding: "0 30px 0 30px",
	},
	'@media screen and (max-width: 624px)': {
		padding: "0 30px 0 30px",
	},
	'@media screen and (max-width: 500px)': {
		padding: "0 20px 0 20px",
	}
});

const CorporateLicenceTabs = styled(Button)({
	border: "2px solid transparant", color: "#009B07", fontWeight: 600, backgroundColor: "#FFFFFF", textTransform: "none", borderRadius: "38px", height: "100%",
	padding: "0 65px 0 65px",
	'@media screen and (max-width: 1110px) and (min-width: 769px)': {
		padding: "0 35px 0 35px",
	},
	'@media screen and (max-width: 624px)': {
		padding: "0 35px 0 35px",
	},
	'@media screen and (max-width: 500px)': {
		padding: "0 25px 0 25px",
	}
});

const RoundedButtonBase = styled('div')((props: any) => ({
	height: "47px", fontWeight: 700, display: 'flex', textAlign: 'center', justifyContent: 'center', backgroundColor: "#FFFFFF", flex: "wrap",
	color: "#009E00",
	borderRadius: '38px',
	border: " 2px solid #009B07"
}))

const CloseIconButton = styled(IconButton)({
	background: '#676767',
	color: '#fcfcfc',
	width: '32px',
	height: '32px',
	'&:hover': {
		background: '#999',
		opacity: 0.9
	}
})

const DialogCancelButton = styled(Button)({
	color: '#FFF',
	background: '#D0D0D0',
	padding: '10px 18px 8px 18px',
	margin: '5px',
	'&:hover': {
		background: '#999',
		opacity: 0.9
	},
	'@media screen and (max-width:400px)': {
		width: '-moz-available',
	}
})

const ShareNow = styled(Button)({
	background: '#019B07',padding: '10px 18px 8px 18px',
	margin: '5px',color: '#FFF',
	'&:hover': {
		background: '#999',
		opacity: 0.9
	},
	'@media screen and (max-width:400px)': {
		width: '-moz-available',
	}
})

const ModalTitle = styled(Grid)({
	alignItem: 'center',
	flexDirection: 'row',
	width: '100%',
	display: 'flex',
	justifyContent: 'space-between',
	borderBottom: '0.2px solid #cbc1c1',
	padding: '13px 25px 13px 31px'
})

const RightFormWrapper = styled(Form)({
	display: 'flex',
	flexDirection: 'column',
	gap: '25px',
	overflowX: 'hidden',
	width: '100%',
	paddingRight: '10px',
})

const RightFormWrapperDiv = styled('div')({
	width: '100%',
	display: 'flex',
	flexDirection: 'column',
	gap: '25px',
	overflowX: 'hidden',
	paddingRight: '10px',
})

const OpenLiveBtn = styled(Typography)({
	border: '1px solid #E5EDE6',
	fontSize: '14px',
	width: 'max-content',
	fontWeight: 'bold',
	borderRadius: '10px',
	color: '#388141',
	padding: '8px 25px',
	// opacity: 0.11,
	mixBlendMode: 'normal',
	textTransform: 'capitalize',
	background: '#e4f4e7',
})

const FundedLiveBtn = styled(Typography)({
	fontSize: '14px',
	background: '#e3f0ff',
	width: 'max-content',
	fontWeight: 'bold',
	// opacity: 0.11,
	mixBlendMode: 'normal',
	textTransform: 'capitalize',
	border: '1px solid #e3f0ff',
	borderRadius: '10px',
	color: '#3187e0',
	padding: '8px 25px',
})

const ClosedLiveBtn = styled(Typography)({
	padding: '8px 25px',
	fontSize: '14px',
	width: 'max-content',
	background: '#ffe4e5',
	border: '1px solid #ffe4e5',
	color: '#e93951',
	fontWeight: 'bold',
	// opacity: 0.11,
	mixBlendMode: 'normal',
	textTransform: 'capitalize',
	borderRadius: '10px',
})


// Customizable Area End
import React from "react";
// Customizable Area Start
import SurveysController, { Props } from "./SurveysController";
import DashboardLayout from "../../../components/src/DashboardLayout.web";
import {
    Card, Grid, Divider, Typography, InputAdornment, Hidden, TextField, Checkbox, MenuItem, Button, Box, Avatar, Menu,
} from '@material-ui/core'
import { styled } from '@material-ui/styles'
import Flex from "../../../components/src/Flex.web";
import OverlayLoader from '../../../components/src/Loader.web'

import SearchFilter from '../../../components/src/Search.web';

import { makeStyles } from "@material-ui/core/styles";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

// import classes from './ServiceWeb.module.css';

import UserContext from "../../../components/src/UserProvider";
import FlexBase from "../../../components/src/Flex.web";

import { profileAvatar } from "../../../components/src/LeftMenuWeb";
import LinearProgress from '@material-ui/core/LinearProgress';

// import { FilterMenu, FilterMenuMobile } from "../../RequestManagement/src/Filter.web";
import { CloseCircleIcon } from "../../../components/src/Icons.web";
import { ArrowDownIcon, SearchIcon } from "./assets";
import { iconAdd } from "../../JobListing2/src/assets";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import InfiniteScroll from "../../../components/src/InfiniteScroll.web";
import { LoadingSpinner } from "../../../components/src/LoadingSpinner.web";

import { useWindowSize } from "../../../components/src/utils";
import NestedMenuItem from "../../../components/src/NestedMenuItem.web";
import DeleteModal from "../../../components/src/DeleteModal.web";
import Input from "react-select/src/components/Input";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

// Customizable Area End
export default class Surveys extends SurveysController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    ActionButtons = ( item:any, handleCopyTemplates:any, handleEditTemplate:any, handleDeletePopup:any ) => {
        return (
          <div style={{ display: 'flex', gap: '10px', alignItems: 'center', cursor: 'pointer' }}>
            {item?.status !== 'inactive' ? (
              <LiveBtn>{item?.mandatory ? 'Mandatory' : item?.status}</LiveBtn>
            ) : (
              <InactiveBtn>{item?.status}</InactiveBtn>
            )}
            {!item?.mandatory && <FileCopyOutlinedIcon color="disabled" onClick={() => handleCopyTemplates(item)} />}
            <EditOutlinedIcon color="disabled" onClick={() => handleEditTemplate(item?.id)} />
            {!item?.mandatory && <DeleteOutlineIcon data-testid = "deleteTemplate" color="disabled" onClick={() => handleDeletePopup(item)} />}
          </div>
        );
      };

      renderDescription = (item:any, descriptionSize:number) => {
        const truncatedDescription =
          window.screen.width >= 1280 ? item?.description.substring(0, descriptionSize) : item?.description.substring(0, 50);
      
        return item?.description.length > descriptionSize + 10 ? (
          <>
            {truncatedDescription}
            <span style={{ fontWeight: 'bold' }}> See More...</span>
          </>
        ) : (
          item?.description
        );
      };
      renderNoResultMessage = () => {
        const { loadingSurveyTemplates, serveyTemplates } = this.state;
    
        if (!loadingSurveyTemplates && (serveyTemplates === "No data found" || serveyTemplates?.length < 1)) {
          return (
            <Typography align="center" style={{ width: '100%', margin: '40px' }}>
              No Result Found
            </Typography>
          );
        }
    
        return null;
      };
    
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { navigation } = this.props
        const serveyTemplates = this.state?.serveyTemplates?.map((item: any) => item?.attributes)
        console.log(this.state, "this.state:::")
        const descriptionSize = this.state.descriptionSize
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <DashboardLayout token={this.state.token} navigation={navigation}  >
                    <OverlayLoader loading={!!this.state.loadingSurveyTemplates}  /> 
                    {this.state.deleteId && <DeleteModal label="Delete this Survey?" onClose={this.closeDeleteModal} onSubmit={() => this.handleDeleteTemplate(this.state.deleteId)} />}

                    <div>
                        <CoinsHead id="surveyTextHead" data-testid="back-button" onClick={() => this.getNavigationMessage(this.state.SurveyPath , this.props)}>
                        <ArrowBackIosIcon />
                        {this.state.SurveyTitle}
                        </CoinsHead>
                        <FlexSpaceBetween style={{ gap: 6 }}>
                            <SearchDiv>
                                <SearchFilter
                                    onChange={this.onFilterQueryChange}
                                />
                            </SearchDiv>
                            <SearchDiv>
                                <TextFieldRounded
                                    value={this.filterText()}
                                    onClick={this.handleFilterMenuOpen}
                                    // @ts-ignore 
                                    InputProps={{
                                        ref: this.filterRef,
                                        endAdornment: (<InputAdornment position="start">
                                            {this.filterText() && <CloseCircleIcon
                                                onClick={this.clearFilters}
                                                style={{ margin: '0 10px', width: '24px', cursor: 'pointer' }}
                                            />}
                                            <img src={ArrowDownIcon} width="16" />
                                        </InputAdornment>)
                                    }} variant="outlined"
                                    placeholder="Filter by"
                                />
                                {/* @ts-ignore */}
                                <Hidden mdDown>
                                    {/* <FilterMenu
                                                filterByMe={this.state.filterCreatedByMe}
                                                filterStatus={this.state.filterStatus}
                                                seletedFilterStatus={this.onSelecteFilterStatus}
                                                toggleFilterByMe={this.toggleFilterByMe}
                                                handleDateInputFocus={this.handleDateInputFocus}
                                                handleDateInputBlur={this.handleDateInputBlur}
                                                keepOpen={this.state.selectingDate}
                                                menuPosition={this.state.filterMenuPosition}
                                                // onClear={this.clearFilters}
                                                onClose={this.handleFilterMenuClose}
                                                requestTypeControls={{
                                                    requestTypes: this.state.filterRequestTypes,
                                                    handleSelect: this.handleRequestTypeSelect,
                                                    selectedRequestType: this.state.filterRequestType,
                                                }}
                                                dateControls={{
                                                    handleSubmit: this.handleDateFilterSubmit,
                                                    handleCancel: this.handleDateFilterCancel,
                                                    handleFromDateChange: this.handleFromDateChange,
                                                    handleToDateChange: this.handleToDateChange,
                                                    //   filterFromDate: this.state.filterFromDate,
                                                    //   filterToDate: this.state.filterToDate,
                                                    //   minDate: this.state.filterFromDate,
                                                    //   maxDate: this.state.filterToDate
                                                }}
                                            /> */}

                                    <FilterMenu
                                        //   inside={this.props.inside}
                                        //   filterByFav={this.state.filterByFav}
                                        SurveyType = {this.state.SurveyType}
                                        filterByMe={this.state.filterByMe}
                                        handleFilterByStatus={this.handleFilterByStatus}
                                        toggleFilterByMe={this.toggleFilterByMe}
                                        applyResponseRange={this.applyResponseRange}
                                        cancelResponseRange={this.cancelResponseRange}
                                        handleFilterByResponseRange={this.handleFilterByResponseRange}
                                        responseStatusStartingRange={this.state.responseStatusStartingRange}
                                        responseStatusEndingRange={this.state.responseStatusEndingRange}
                                        //   toggleFilterByFav={this.toggleFilterByFav}
                                        //   handleDateInputFocus={this.handleDateInputFocus}
                                        //   handleDateInputBlur={this.handleDateInputBlur}
                                        keepOpen={this.state.selectingDate}
                                        menuPosition={this.state.filterMenuPosition}
                                        onClose={this.handleFilterMenuClose}
                                        tagControls={{
                                            tags: this.state.communities,
                                            selectedTags: this.state.filterCommunities,
                                            handleSelect: this.handleTagSelect,
                                        }}
                                        requestorsControls={{
                                            requestors: this.state.requestors,
                                            selectedRequestors: this.state.filterRequestors,
                                            handleSelect: this.handleRequestorSelect,

                                        }}
                                    //   catControls={{
                                    //     categories: this.state.categories,
                                    //     handleSelect: this.handleCategorySelect,
                                    //     selectedCategories: this.state.filterCategories,
                                    //   }}
                                    />
                                </Hidden>
                                {/* <FilterMenuMobile
                                    menuPosition={this.state.filterMenuPosition}
                                    onClose={this.handleFilterMenuClose}
                                    filterByMe={this.state.filterCreatedByMe}
                                    toggleFilterByMe={this.toggleFilterByMe}
                                    seletedFilterStatus={this.onSelecteFilterStatus}
                                    filterStatus={this.state.filterStatus}
                                    requestTypeControls={{
                                        requestTypes: this.state.requestTypes,
                                        handleSelect: this.handleRequestTypeSelect,
                                        selectedCategories: this.state.filterRequestType,
                                    }}
                                    dateControls={{
                                        handleSubmit: this.handleDateFilterSubmit,
                                        handleCancel: this.handleDateFilterCancel,
                                        handleFromDateChange: this.handleFromDateChange,
                                        handleToDateChange: this.handleToDateChange,
                                        filterFromDate: this.state.filterFromDate,
                                        filterToDate: this.state.filterToDate,
                                        minDate: this.state.filterFromDate,
                                        maxDate: this.state.filterToDate
                                    }}
                                    disabled={ this.state.filterFromDate && this.state.filterToDate?false:true }
                                    /> */}
                            </SearchDiv>
                            <div >
                                <RoundedButtonBase
                                    id="CreateTemplateBtn"
                                    onClick={() => {
                                        this.props.navigation.history.push(`${this.props.navigation.history.location.pathname}/CreateSurveys`)
                                    }}
                                    variant="contained"
                                    disableElevation
                                    startIcon={<img src={iconAdd} width={30} style={{ marginLeft: '-5px' }} />}>
                                    CREATE TEMPLATE
                                    {/* {buttonLabels[selectedTab]} */}
                                </RoundedButtonBase>
                            </div>
                        </FlexSpaceBetween>
                        {/* @ts-ignore */}
                        <InfiniteScroll
                            data-test-id = 'infinitscrollId'
                            dataLength={serveyTemplates?.length} //This is important field to render the next data
                            next={this.loadNextPage}
                            hasMore={(!!this.state.pagination?.next_page) && !this.state.loadingSurveyTemplates}
                            // Show only when this.state.loadingPosts component is not rendered
                            loader={!(!this.state.loadingSurveyTemplates && serveyTemplates?.length === 0) && <LoadingSpinner />}
                        >
                            <SurveyListDiv>
                                <Grid spacing={4} container >
                                    {serveyTemplates && serveyTemplates?.map((item: any) => {
                                        return (    
                                            <Grid key={item.id} xs={12} sm={12} md={12} lg={6} xl={6} item >
                                                <SurveyBox>
                                                {/* @ts-ignore */}
                                                    <Box style={{ width: '100%', display: "flex", flexDirection: "column" }}>
                                                        <FlexBase justify="space-between" align="center" >
                                                            <FlexBase style={{ flexDirection: 'column', width: `calc(100% - 220px)` }} onClick={() => this.props.navigation.history.push(`/SurveysDetails/${item?.id}`)}>
                                                                <TemplateName>{item?.template_name}</TemplateName>
                                                                <SubText>{item?.survey_title}</SubText>
                                                            </FlexBase>
                                                            {this.ActionButtons(
                                                                item,
                                                                this.handleCopyTemplates,
                                                                this.handleEditTemplate,
                                                                this.handleDeletePopup
                                                            )}
                                                        </FlexBase>
                                                    </Box>
                                                    <SurveyDetailBox onClick={() => this.props.navigation.history.push(`/SurveysDetails/${item?.id}`)}>
                                                    <TemplateId>Template ID : {item?.survey_uniq_id}</TemplateId>
                                                        <DescriptionTypography color="textSecondary">
                                                            {this.renderDescription(item, descriptionSize)}
                                                        </DescriptionTypography>
                                                    <div>
                                                        <Grid spacing={2} container alignItems="center" justifyContent="center" wrap="wrap">
                                                            <Grid xs item>
                                                                <Typography style={{ whiteSpace: 'nowrap' }} >Response Status :</Typography>
                                                            </Grid>
                                                            <Grid xs item>
                                                                <LinearProgress variant="determinate" value={item?.response_status} style={{ height: '6px', borderRadius: '5px' }} />
                                                            </Grid>
                                                            <Grid xs item>
                                                                <Typography>{item?.response_status}%</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                    <Divider variant="fullWidth" />
                                                    <Flex justify="space-between">
                                                        <div>
                                                            <Typo>Created By</Typo>
                                                            <Flex justify="center" align="center" gap={13}>
                                                                <BottomAvatar>
                                                                    <img src={item?.created_by?.profile_pic ? item?.created_by?.profile_pic : profileAvatar} alt="img" width="100%" height="100%"   />
                                                                </BottomAvatar>
                                                                <Typography style={{ textTransform: 'capitalize' }}>{`${item?.created_by?.first_name} ${item?.created_by?.last_name}`}</Typography>
                                                            </Flex>
                                                        </div>
                                                        {item?.requested_by && <div>
                                                            <Typo>Requested By</Typo>
                                                            <Flex justify="center" align="center" gap={13}>
                                                                <BottomAvatar>
                                                                    <img src={item?.requested_by?.profile_pic ? item?.requested_by?.profile_pic : profileAvatar} alt="img" width="100%" height="100%"  />
                                                                </BottomAvatar>
                                                                <Typography style={{ textTransform: 'capitalize' }}>{`${item?.requested_by?.first_name} ${item?.requested_by?.last_name}`}</Typography>
                                                            </Flex>
                                                        </div>}
                                                    </Flex>
                                                </SurveyDetailBox>
                                                </SurveyBox>
                                            </Grid>                                          
                                        )
                                    })}
                                </Grid>
                                {this.renderNoResultMessage()}
                            </SurveyListDiv>
                        </InfiniteScroll>
                    </div>
                </DashboardLayout>
            </>
            // Customizable Area End
        )
    }
}
// Customizable Area Start
SurveysController.contextType = UserContext


interface FilterMenuProps {
    filterByMe: boolean,
    SurveyType: boolean,
    // filterByFav: boolean,
    handleFilterByStatus: (status: any) => void,
    handleFilterByResponseRange: (value: any, type: any) => void,
    applyResponseRange: () => void,
    cancelResponseRange: () => void,
    toggleFilterByMe: () => void,
    // toggleFilterByFav: () => void,
    menuPosition: {
        top: number,
        left: number,
    } | undefined
    onClose: () => void,
    // handleDateInputFocus: any,
    // handleDateInputBlur: any,
    keepOpen: any,
    tagControls: {
        tags: any[],
        selectedTags: number[],
        handleSelect: (id: number) => () => void,
    },
    requestorsControls: {
        requestors: any[],
        selectedRequestors: number[],
        handleSelect: (id: number) => () => void
    }
    // catControls: {
    //   categories: any[],
    //   selectedCategories: number[],
    //   handleSelect: (id: number) => (e: any) => void,
    // },
    // dateControls: {
    //   handleFromDateChange: any,
    //   handleToDateChange: any,
    //   handleSubmit: () => void,
    //   handleCancel: () => void,
    //   filterFromDate?: Date | null,
    //   filterToDate?: Date | null,
    //   maxDate?: Date | null,
    //   minDate?: Date | null,
    // },
    responseStatusStartingRange: number;
    responseStatusEndingRange: number;
    inside?: string
}

const FilterMenu = ({ menuPosition, onClose, requestorsControls, tagControls, keepOpen, responseStatusStartingRange, responseStatusEndingRange,SurveyType, ...props }: FilterMenuProps) => {
    // const { categories } = catControls
    const { tags } = tagControls
    const { requestors } = requestorsControls
    const [catSearch, setCatSearch] = React.useState("")
    // const [filteredCats, setFilteredCats] = React.useState<any[]>(categories)
    const [catSearchFocused, setCatSearchFocused] = React.useState(false)
    const [nestedMenuName, setNestedMenuName] = React.useState("")
    const windowSize = useWindowSize()

    const onCatSearchFocus = () => setCatSearchFocused(true)
    const onCatSearchBlur = () => setCatSearchFocused(false)

    // React.useEffect(() => {
    //   setFilteredCats(categories)
    // }, [categories])

    // const onCatSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //   const { value } = e.target
    //   setCatSearch(value)
    //   setFilteredCats(
    //     categories.filter((c: any) => c.name.includes(value))
    //   )
    // }
    console.log(windowSize.width)
    if (windowSize?.width < 1024) return <></>
    // const clearFromDate = (e: React.MouseEvent) => {
    //   e.stopPropagation()
    //   dateControls.handleFromDateChange(null)
    // }
    // const clearToDate = (e: React.MouseEvent) => {
    //   e.stopPropagation()
    //   dateControls.handleToDateChange(null)
    // }
    return (
        <Menu open={!!menuPosition} anchorPosition={menuPosition} onClose={onClose} anchorReference="anchorPosition">
            <MenuItem selected={props.filterByMe} onClick={props.toggleFilterByMe}>
                All
            </MenuItem>
            {/* <MenuItem selected={props.filterByFav} onClick={props.toggleFilterByFav}>Favourite</MenuItem> */}
            {/* <SearchNestedMenu
          name="category"
          nestedMenuName={nestedMenuName}
          setNestedMenuName={setNestedMenuName}
          items={categories}
          selectedIds={catControls.selectedCategories}
          label="Based on Category"
          selectHandler={catControls.handleSelect}
          parentMenuOpen={!!menuPosition}
        /> */}
            <NestedMenuItem
                name="status"
                nestedMenuName={nestedMenuName}
                setNestedMenuName={setNestedMenuName}
                keepOpen={keepOpen}
                label="By Status"
                parentMenuOpen={!!menuPosition}
                // @ts-ignore
                MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },


                }}
            >
                <FilterByDateWrapper>
                    <FilterByDateRow>
                        Choose Status
                    </FilterByDateRow>
                    <MenuItem selected={props.filterByMe} onClick={() => props.handleFilterByStatus('2')}>
                        Live
                    </MenuItem>
                    <MenuItem selected={props.filterByMe} onClick={() => props.handleFilterByStatus('0')}>
                        Active
                    </MenuItem>
                    <MenuItem selected={props.filterByMe} onClick={() => props.handleFilterByStatus('1')}>
                        Inactive
                    </MenuItem>
                </FilterByDateWrapper>
            </NestedMenuItem>

            {SurveyType && <SearchNestedMenu
                nestedMenuName={nestedMenuName}
                name="requested by"
                label="By Requestor"
                items={requestors}
                selectedIds={requestorsControls.selectedRequestors}
                setNestedMenuName={setNestedMenuName}
                parentMenuOpen={!!menuPosition}
                selectHandler={requestorsControls.handleSelect}
            />}

            {SurveyType && <SearchNestedMenu
                nestedMenuName={nestedMenuName}
                name="subcategory"
                items={tags}
                setNestedMenuName={setNestedMenuName}
                label="By Communities"
                selectedIds={tagControls.selectedTags}
                parentMenuOpen={!!menuPosition}
                selectHandler={tagControls.handleSelect}
            />}

            <NestedMenuItem
                name='Respone' label='By Response Range' parentMenuOpen={!!menuPosition}
                // @ts-ignore
                MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },


                }}>
                    <FilterByResponseStatusWrapper>
                    <FilterByResponseStatusRow>
                        RESPONSE RANGE
                    </FilterByResponseStatusRow>
                    <MenuItem>
                        Response Status
                    </MenuItem>
                    <MenuItem>
                        <TextFieldResponseStatus variant="outlined" 
                        value={responseStatusStartingRange}
                        onChange={(e) => props.handleFilterByResponseRange(e.target.value, "start")} 
                        />
                        <RangeInputText>to</RangeInputText>
                        <TextFieldResponseStatus variant="outlined" value={responseStatusEndingRange} onChange={(e) => props.handleFilterByResponseRange(e.target.value, "end")} />
                    </MenuItem>
                    <InputRangeButtonContainer>
                        <InputRangeCancelbtn onClick={props.cancelResponseRange}>
                            CANCEL
                        </InputRangeCancelbtn>
                        <InputRangeApplybtn onClick={props.applyResponseRange}>
                            APPLY
                        </InputRangeApplybtn>
                    </InputRangeButtonContainer>
                    
                </FilterByResponseStatusWrapper>
                </NestedMenuItem>


        </Menu>
    )
}

interface SearchNestedMenuProps {
    items: any[];
    selectedIds: number[];
    selectHandler: (id: number) => (e: any) => void;
    label: string;
    parentMenuOpen: boolean;
    nestedMenuName?: any;
    setNestedMenuName?: any;
    name?: any;
}

function SearchNestedMenu({ items, selectedIds, selectHandler, label, parentMenuOpen, name, nestedMenuName, setNestedMenuName }: SearchNestedMenuProps) {
    const [search, setSearch] = React.useState("")
    const [focus, setFocus] = React.useState(false)
    const [filteredItems, setFilteredItems] = React.useState<any[]>(items);
    const classes = useNestedMenuStyles();
    const onFocus = () => setFocus(true)
    const onBlur = () => setFocus(false)

    const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation();
        const value = e.target.value
        setSearch(value)
    }

    React.useEffect(() => {
        setFilteredItems(items?.filter(
            (item: any) => item?.name.toLowerCase().includes(search.toLowerCase())
        )
        )
    }, [search])

    const stopPropagation = (e: React.KeyboardEvent<any>) => e.stopPropagation()
    return <NestedMenuItem
        name={name}
        nestedMenuName={nestedMenuName}
        setNestedMenuName={setNestedMenuName}
        keepOpen={focus}
        label={label}
        parentMenuOpen={parentMenuOpen}
        // @ts-ignore
        MenuProps={{
            classes: { paper: classes.menuPaper, list: classes.list },
            getContentAnchorEl: null,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
            },
            transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
            }
        }}
    >
        <li onKeyDown={stopPropagation} >
            <TextField
                InputProps={{
                    startAdornment: (<InputAdornment position="start">
                        <img src={SearchIcon} width={20} style={{ paddingLeft: '15px' }} />
                    </InputAdornment>
                    ),
                }}
                fullWidth
                style={{ marginTop: '5px 10px' }}
                inputProps={{ style: { padding: '10px 10px' } }}
                onFocus={onFocus}
                onBlur={onBlur}
                value={search}
                onChange={onSearchChange}
                placeholder="Search" />
        </li>
        {items?.length === 0 && <div style={{ padding: '20px' }}>Loading</div>}
        {filteredItems?.length === 0 && <div style={{ padding: '20px' }}>No match found</div>}
        {
            filteredItems?.map(
                (cat: any) => (
                    <ReverseMenuItem onClick={selectHandler(cat.id)} key={cat.id}>
                        <Checkbox
                            onChange={selectHandler(cat.id)}
                            checked={selectedIds.indexOf(cat.id) !== -1}
                            icon={icon}
                            color="primary"
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                        />
                        <span className={classes.menuItemLabel}>{cat.name}</span>
                    </ReverseMenuItem>
                ))
        }
    </NestedMenuItem>
}

//*********** styles ***************//
const useNestedMenuStyles = makeStyles((theme: any) => ({
    menuPaper: {
        height: 300,
        display: 'flex',
        // Fix for scroll bug 
        // https://github.com/mui/material-ui/issues/10601#issuecomment-400479793
        pointerEvents: 'auto',
    },
    list: {
        width: 400,
        display: 'flex'
    },
    menuItemLabel: {
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }
}));

const FilterByDateRow = styled('div')({
    display: 'flex',
    marginBottom: '20px',
    alignItems: 'center',
    padding:'0px 10px',
    fontWeight:'bold',
})

const FilterByResponseStatusRow = styled('div')({
    display: 'flex',
    marginBottom: '10px',
    alignItems: 'center',
    padding:'0px 15px',
    fontWeight:'bold',
})

const FilterByDateWrapper = styled('div')({
    width: '280px',
    height: '230px',
    padding: '10px 0px',
    background: '#FFF'
})

const FilterByResponseStatusWrapper = styled('div')({
    width: '350px',
    height: '200px',
    padding: '10px 30px 10px 10px',
    background: '#FFF'
})

const ReverseMenuItem = styled(MenuItem)({
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    whiteSpace: 'normal',
})

const DIV = styled('div')({
    '@media (max-width: 768px)': {
        padding: '0px 20px',
    }
})

const CoinsHead = styled('div')({
    margin: '40px 0px 15px 12px',
    fontSize: '1.2rem',
    display: 'flex',
    fontWeight: 600,
    '@media screen and (max-width: 768px)': {
        margin: '20px 0px 10px 12px',
    },
})

const SurveyBox = styled(Box)({
    width: '-moz-available !important',
    backgroundColor: "white",
    overflow: 'hidden',
    display: "flex",
    gap: '18px',
    flexDirection: "column",
    justifyContent: 'space-between',
    borderRadius: "10px",
    padding: "20px"
})


const TemplateName = styled(Typography)({
    fontWeight: "bold",
    color: "#292D32",
    fontSize: '20px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    '@media screen and (max-width:380px)': {
        fontSize:'14px',
    },
})

const SubTextGray = styled('span')({
    color: "rgba(21, 20, 20, 0.65)",
    alignSelf: 'center',
})

const SubText = styled(Typography)({
    color: "#292D32",
    fontSize: '14px',
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    '@media screen and (max-width:380px)': {
        fontSize:'13px',
    },
})

const TemplateId = styled(Typography)({
    background: '#F5F5F5',
    border: '1px solid #E5EDE6',
    borderRadius: '10px',
    color: '#282D33',
    padding: '10px 15px',
    fontSize: '14px',
    width: 'max-content',
})

const LiveBtn = styled(Typography)({
    background: '#ebf3fc',
    border: '1px solid #E5EDE6',
    borderRadius: '10px',
    color: '#2175E7',
    padding: '8px 25px',
    fontSize: '14px',
    width: 'max-content',
    fontWeight: 'bold',
    // opacity: 0.11,
    mixBlendMode: 'normal',
    textTransform: 'capitalize',
})

const InactiveBtn = styled(Typography)({
    background: '#E5E5E5',
    border: '1px solid #E5EDE6',
    borderRadius: '10px',
    color: '#787878',
    padding: '8px 25px',
    fontSize: '14px',
    width: 'max-content',
    fontWeight: 'bold',
    // opacity: 0.11,
    mixBlendMode: 'normal',
    textTransform: 'capitalize',
    
})

const BottomAvatar = styled(Avatar)({
    border: '3px solid rgb(242, 212, 212)',
    '@media screen and (max-width:400px)': {
        height: '35px',
        width: '35px',
        gap: '5px'
    }
})

const TextFieldRounded = styled(TextField)({
    width: '100%',
    background: '#FFFFFF',
    borderRadius: '15px',
    '& > .MuiOutlinedInput-root': {
        borderRadius: '15px',
    },
    '& > div > input': {
        paddingTop: '14px',
        paddingBottom: '14px'
    }
})

const TextFieldResponseStatus = styled(TextField)({
    width: '100%',
    background: '#FFFFFF',
    borderRadius: '8px',
    '& > .MuiOutlinedInput-root': {
        borderRadius: '8px',
    },
    '& > div > input': {
        paddingTop: '15px',
        paddingBottom: '15px',
        textAlign: "center"
    }
})

const FlexSpaceBetween = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 0 27px',
    // paddingBottom: '0',
    width: '100%',
    '@media screen and (max-width:600px)': {
        flexDirection: 'column',
        alignItems: 'normal',
    },
    '@media screen and (min-width:769px) and (max-width:1200px)': {
        flexDirection: 'column',
        alignItems: 'normal',
    }
})

const FlexSpaceBetweenN = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 0',
    paddingBottom: '0',
    width: '100%',
})

const RangeInputText = styled('span')({
    margin:"0px 15px"
})

const RoundedCard = styled(Card)({
    borderRadius: '15px',
    padding: '10px 20px',
    margin: '20px 0px'
})

const RoundedButtonBase = styled(Button)({
    backgroundColor: '#009c05',
    color: 'white',
    borderRadius: '20px',
    '&:hover': {
        backgroundColor: '#009c05',
    },
    '@media screen and (max-width:600px)': {
        width: '100%'
    },
    '@media screen and (min-width:769px) and (max-width:1200px)': {
        width: '100%'
    }
})





const Typo = styled(Typography)({
    fontSize: '0.99rem',
    fontWeight: 600,
    color: '#787878',
    marginBottom: '5px',
})

const InputRangeButtonContainer = styled('div')({
    display:'flex',
    justifyContent:'space-between',
    margin:'20px 15px 0 15px'
})

const InputRangeCancelbtn = styled(Button)({
    background: '#E5E5E5',
    border: '1px solid #E5EDE6',
    borderRadius: '10px',
    color: '#787878',
    padding: '10px',
    fontSize: '14px',
    width:'48%',
    fontWeight: 'bold',
    mixBlendMode: 'normal',
    textTransform: 'uppercase',
})
const InputRangeApplybtn = styled(Button)({
    background: 'rgb(0, 161, 0)',
    border: '1px solid #E5EDE6',
    borderRadius: '10px',
    color: '#ffffff',
    padding: '10px',
    fontSize: '14px',
    fontWeight: 'bold',
    mixBlendMode: 'normal',
    textTransform: 'uppercase',
    width:'48%',
    "&:hover":{
        background: 'rgb(0, 161, 0)',
    }
})

const SurveyDetailBox = styled('div')({
    display:'flex', 
    justifyContent:'space-between', 
    flexDirection:'column', 
    gap:'18px' 
})

const SurveyListDiv = styled('div')({
    display: 'flex', 
    flexWrap: 'wrap',
    gap: '10px',
    overflow:'hidden' 
})

const SearchDiv = styled('div')({
    flex: 1,
})

const DescriptionTypography = styled(Typography)({
    wordWrap: 'break-word',
    alignItems: 'justify', 
    height: '50px' 
    
})
// Customizable Area End
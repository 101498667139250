// Customizable Area Start
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const image_Bitmap = require("../assets/image_Bitmap.png");
export const image_Bradlogo = require("../assets/image_Bradlogo.png");
export const googleLogin = require("../assets/Login With Facebook 2 Copy.png");
export const microsoftLogin = require("../assets/Login With microsoft.png");
export const linkedInLoginLogo = require("../assets/group_Group2.png");
export const CorpLogo = require("../assets/corpstageIconlogo.png")
// Customizable Area End







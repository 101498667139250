// Customizable Area Start
import React, { Component, RefObject } from "react";
import { GoogleApiWrapper, Map, Marker } from "google-maps-react";
import { styled } from '@material-ui/styles';
import { Button, Typography, Divider, Box } from '@material-ui/core';
import Autocomplete from "react-google-autocomplete";
import { SearchIcon, ZoomIcon, ZoomOutIcon, LocationIcon,closeIcon} from "./assets";
import "./googleMapWeb.css";

const configJSON = require("./config");

interface MapContainerProps {
  google: any;
  handleCloseMap: (value:{city: string,state: string,country: string,setCity :any}) => void;
  city: string;
  setCity: any;
}

interface MapContainerState {
  currentLocation: {
    lat: number | null;
    lng: number | null;
  };
  markerPosition: {
    lat: number | null;
    lng: number | null;
  };
  address: string;
  city: string;
  state: string;
  country: string;
}
// Customizable Area End
export class MapContainer extends Component<MapContainerProps, MapContainerState> {
  
  // Customizable Area Start
  mapRef: RefObject<any>;
  // Customizable Area End

  constructor(props: MapContainerProps) {
    super(props);

    // Customizable Area Start
    this.state = {
      currentLocation: {
        lat: null,
        lng: null
      },
      markerPosition: {
        lat: null,
        lng: null
      },
      address: "",
      city: "",
      state: "",
      country: "",
    };

    this.mapRef = React.createRef<any>();
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    if(this.props.city){
      const { google } = this.props;
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ address: this.props.city }, (results: any, status: any) => {
        if (status === google.maps.GeocoderStatus.OK) {
          const { lat, lng } = results[0]?.geometry?.location || {};
          if(results[0]){
            this.getAllPlaceName(results[0].address_components);
          }
          this.setState(
            {
              currentLocation: {
                lat: lat(),
                lng: lng()
              },
              markerPosition: {
                lat: lat(),
                lng: lng()
              },
            },
            this.centerMapOnCurrentLocation
            );
          this.getAddressFromCoordinates(lat(), lng());
        }else{
          this.handleCurrentLocation()
        }
      });
    }else{
      this.handleCurrentLocation()
    }
  }
  handleCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;

        const geocoder = new this.props.google.maps.Geocoder();
        const location = new this.props.google.maps.LatLng(latitude, longitude);
    
        geocoder.geocode({ location }, (results:any, status: {OK: boolean}) => {
          if (status === this.props.google.maps.GeocoderStatus.OK) {
            if (results[0]) {
              this.getAllPlaceName(results[0].address_components);
            }
          }});
        this.setState(
          {
            currentLocation: {
              lat: latitude,
              lng: longitude
            },
            markerPosition: {
              lat: latitude,
              lng: longitude
            }
          },
          this.centerMapOnCurrentLocation
        );
      });
    }
  };

  centerMapOnCurrentLocation = () => {
    const { google } = this.props;
    const { currentLocation } = this.state;
    const map = this.mapRef.current?.map;

    if (google && map) {
      const center = new google.maps.LatLng(
        currentLocation.lat!,
        currentLocation.lng!
      );
      map.panTo(center);
    }
  };

  handleMarkerDragend = (t: any, map: any, coord: any) => {
    const lat = coord.latLng.lat();
    const lng = coord.latLng.lng();
    const geocoder = new this.props.google.maps.Geocoder();
        const location = new this.props.google.maps.LatLng(lat, lng);
    
        geocoder.geocode({ location }, (results:any, status: {OK: boolean}) => {
          if (status === this.props.google.maps.GeocoderStatus.OK) {
            if (results[0]) {
              this.getAllPlaceName(results[0].address_components);
            }
          }});
    this.setState({
      markerPosition: {
        lat,
        lng
      }
    });

    this.getAddressFromCoordinates(lat, lng);
  };

  handleMapClick = (mapProps: any, map: any, event: any) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    const geocoder = new this.props.google.maps.Geocoder();
        const location = new this.props.google.maps.LatLng(lat, lng);
    
        geocoder.geocode({ location }, (results:any, status: {OK: boolean}) => {
          if (status === this.props.google.maps.GeocoderStatus.OK) {
            if (results[0]) {
              this.getAllPlaceName(results[0].address_components);
            }
          }});
    this.setState({
      markerPosition: {
        lat,
        lng
      }
    });

    this.getAddressFromCoordinates(lat, lng);
  };

  handleZoomIn = () => {
    const map = this.mapRef.current?.map;
    if (map) {
      map.setZoom(map.getZoom() + 1);
    }
  };

  handleZoomOut = () => {
    const map = this.mapRef.current?.map;
    if (map) {
      map.setZoom(map.getZoom() - 1);
    }
  };

  getAddressFromCoordinates = (lat: number, lng: number) => {
    const { google } = this.props;
    const geocoder = new google.maps.Geocoder();

    geocoder.geocode({ location: { lat, lng } }, (results: any, status: any) => {

      if (status === google.maps.GeocoderStatus.OK) {
        const address = results[0]?.formatted_address || "";
        this.setState({ address });
      } else {
        this.setState({ address: "" });
      }
    });
  };
  
  getAllPlaceName = (address_components: { types: string; long_name: string }[]) => {
    let city = "";
    let state = "";
    let country = "";
  
    for (const component of address_components) {
      const { types, long_name } = component;
      if (types.includes("locality")) {
        city = long_name;
      } else if (types.includes("administrative_area_level_1")) {
        state = long_name;
      } else if (types.includes("country")) {
        country = long_name;
      }
    }
  
    this.setState({ city, state, country });
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { currentLocation, markerPosition } = this.state;
    // Customizable Area End

    return (
      // Customizable Area Start
      <>
        <ModalSection>
          <MapModelWrapper>
            <SectionItem style={{ fontSize: "14px" }}>
              <TitleSection>
                <Typography style={{ fontSize: "24px", color: "#2A3035" }} variant="subtitle1" component="div">
                  CHOOSE LOCATION
                </Typography>
                <DivSection>
                  <BtnImg id="CloseBtn" src={closeIcon} alt="close" onClick={()=>this.props.handleCloseMap({city: this.state.city,state:this.state.state,country: this.state.country, setCity: this.props.setCity})} />
                </DivSection>
              </TitleSection>
            </SectionItem>
            <Divider />
            <SurveyModelSection>
              <div className="autoComplete-input">
                <img src={SearchIcon} width="20px" style={{ margin: "0 10px" }} alt="search-icon" />
                <Autocomplete
                  types={["(regions)"]}
                  apiKey={configJSON.googleClientId}
                  onPlaceSelected={(place: any) => {
                    const { geometry,address_components  } = place;
                    const { lat, lng } = geometry.location;
                    this.getAllPlaceName(address_components);
                    this.setState({
                      currentLocation: {
                        lat: lat(),
                        lng: lng()
                      },
                      markerPosition: {
                        lat: lat(),
                        lng: lng()
                      },
                      address: place.formatted_address,
                    });
                  }}
                />
              </div>
              <div className="button-group">
                <div>
                  <img onClick={this.handleCurrentLocation} src={LocationIcon} alt="location-img" />
                </div>
                <div>
                  <img onClick={this.handleZoomIn} src={ZoomIcon} alt="zoom-icon" />
                </div>
                <div>
                  <img onClick={this.handleZoomOut} src={ZoomOutIcon} alt="zoom-out-icon" />
                </div>
              </div>
              <Map
                google={this.props.google}
                // @ts-ignore
                showsMyLocationButton={true}
                zoomControl={false}
                mapTypeControl={false}
                streetViewControl={false}
                fullscreenControl={false}
                showsUserLocation={true}
                initialCenter={currentLocation}
                center={currentLocation}
                onClick={this.handleMapClick}
                onReady={this.centerMapOnCurrentLocation}
                ref={this.mapRef}
              >
                {markerPosition.lat !== null && (
                  <Marker
                    // @ts-ignore
                    position={markerPosition}
                    draggable
                    onDragend={this.handleMarkerDragend}
                  />
                )}
              </Map>
            </SurveyModelSection>
          </MapModelWrapper>
        </ModalSection>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const ModalSection = styled('div')({
  top: `50%`,
  left: `50%`,
  transform: `translate(-50%,-50%)`,
  position: `absolute`
})

const DivSection = styled('div')({
  cursor: "pointer"
})


const TitleSection = styled('div')({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  color: '#303539',
});

const SaveBtn = styled(Button)({
  background: '#009B07',
  color: '#ffffff',
  width: '150px',
  height: '40px',
  margin: '20px auto 0',
  border: '1px solid #009B07',
  display: 'flex',
  '&:hover': {
    color: '#009B07',
    border: '1px solid #009B07'
  }
});

const SectionItem = styled(Box)({
  marginBottom: '10px',
  '& .MuiTypography-root.MuiTypography-subtitle1': {
    fontWeight: 'bold',
    fontSize: 11,
  },
  '& .MuiTypography-root.MuiTypography-subtitle2': {
    fontWeight: 'normal',
    color: 'grey',
    fontSize: 14,
  }
});

const MapModelWrapper = styled(Box)({
  background: 'white',
  borderRadius: "20px",
  padding: "35px",
  '@media screen and (max-width:768px)': {
    width: '550px'
  },
  '@media screen and (max-width:600px)': {
    width: '420px'
  },
  '@media screen and (max-width:468px)': {
    width: '320px'
  },
  '@media screen and (max-width:370px)': {
    width: '245px'
  }
})
const BtnImg = styled('img')({
  height: "40px",
  width: "40px",
  '@media screen and (max-width:768px)': {
    height: "30px",
    width: "30px",
  },
});

const SurveyModelSection = styled(Box)({
  backgroundColor: "white",
  overflow: "auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: '80vh',
  width: '1000px',
  position: "relative",
  marginTop: "20px",
  "& .autoComplete": {
    zIndex: 1,
    position: "absolute",
    top: "20px",
    width: "450px",
    borderRadius: "10px",
    left: "20px",
    backgroundColor: "#ffffff",
    "& p": {
      display: "none !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    }
  },
  "& .button-group": {
    position: "absolute",
    bottom: "10px",
    right: "20px",
    zIndex: 1,
    display: "flex",
    flexDirection: "column",
    "& div": {
      padding: "10px",
      backgroundColor: "#ffffff",
      borderRadius: "12px",
      marginBottom: "10px",
      cursor: "pointer",
      "& img": {
        width: "36px",
      }
    },
  },
  "& .autoComplete-input": {
    zIndex: 1,
    position: "absolute",
    top: "20px",
    width: "450px",
    borderRadius: "10px",
    left: "20px",
    backgroundColor: "#ffffff",
    height: "40px",
    display: "flex",
    alignItems: "center",
    "& input": {
      height: "100%",
      width: "100%",
      borderRadius: "20px",
      borderColor: "transparent",
      outline: "none",
    }
  }
});

export default GoogleApiWrapper({
  apiKey: configJSON.googleClientId
})(MapContainer);

// Customizable Area End
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
	getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { sendAPIRequest } from '../../../components/src/utils';
import { sendSharePopupMessage } from '../../share/src/Messages';
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
	navigation: any;
	id: string;
	// Customizable Area Start
	// Customizable Area End
}

interface S {
	// Customizable Area Start
	token: string;
	countCommentLikeNumber: any;
	openModal: boolean;
	likesCount: any;
	courseData: any;
	courseId: string;
	courseCommentData: any[];
	pagination?: {
		next_page?: number;
	};
	userComment: string;
	userReply: string;
	reply_id: '';
	profileDashboardData: any[];
	ProjectDataById: any;
	loading:boolean;
	openCommentEmoji: boolean;
	openCommentReplyEmoji: boolean;
	openReply: '';
	userID: number | undefined;
	// Customizable Area End
}

interface SS {
	id: any;
	// Customizable Area Start
	// Customizable Area End
}

export default class ProjectDataController extends BlockComponent<
	Props,
	S,
	SS
> {

	// Customizable Area Start
	getCourseDetailCallId = '';
	getAllCourseCommentCallId = '';
	courseCommentCallId = '';
	courseLikeCallId = '';
	courseCommentReplyCallId = '';
	getMoreCommentCallId = '';
	countCommentLike: any;
	getLikesNumber: any;
	getProfileDashboardData: any;
	apiFavCourseCallId: string[] = []
	favCallIdToCourseId: { [key: string]: string } = {};
	likeCallIdToCommentId: { [key: string]: string } = {};
	singleProject:any;

	async componentDidMount() {
		super.componentDidMount();
		const authToken = await getStorageData('authToken');
		if (authToken == null) {
			this.props.navigation.navigate("Home");
		}
		const userData = await getStorageData('user');
		const userId = userData ? (JSON.parse(userData)).id : undefined;
		this.setState({userID: userId,token:authToken }, () => {
		this.getSingleProject();
		this.getAllComments();
		this.getLikesCount()
	});
	}

	getAllComments = async() => {
		const token = await getStorageData('authToken') || ''
		const id = this.props.navigation.getParam('id')
		this.getAllCourseCommentCallId = sendAPIRequest(`${configJSON.getComments}?project_management_id=${id}`, {
			method: 'GET',
			headers: { token }
		})
	}
	onCloseModal = () => {
		this.setState({ openModal: false, openReply: '', reply_id: '', userComment: '', openCommentEmoji: false, openCommentReplyEmoji: false });
		if(this.props.navigation.history.location.pathname.includes('/project_detail_enduser') || this.props.navigation.history.location.pathname.includes('/all_project_end_user') || this.props.navigation.history.location.pathname.includes('/my_projects') || this.props.navigation.history.location.pathname.includes('/CorporateProfile')){
			const path = this.props.navigation?.history?.location?.pathname?.split('/projects/')?.length ? this.props.navigation.history.location.pathname.split('/projects/')[0] : this.props.navigation?.history?.location?.pathname;
			this.props.navigation.history.push(`${path}`,{comment:true});
		}
		else{
			this.props.navigation.goBack();
		}
	}
	// Customizable Area End

	constructor(props: Props) {
		super(props);
		this.receive = this.receive.bind(this);

		// Customizable Area Start
		this.subScribedMessages = [
			// Customizable Area Start
			getName(MessageEnum.RestAPIResponceMessage),
			getName(MessageEnum.ReportModalOpenMessage),
			// Customizable Area End
		];

		this.state = {
			// Customizable Area Start
			token: '',
			userID: undefined,
			countCommentLikeNumber: {},
			ProjectDataById: {},
			loading:true,
			profileDashboardData: [],
			likesCount: "",
			openModal: true,
			courseId: '',
			courseData: {},
			courseCommentData: [],
			userComment: '',
			userReply: '',
			reply_id: '',
			openCommentEmoji: false,
			openCommentReplyEmoji: false,
			openReply: '',
			// Customizable Area End
		};
		runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

		// Customizable Area Start
		// Customizable Area End

	}


	async receive(from: string, message: Message) {
		// Customizable Area Start
		if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
			const apiRequestCallId = message.getData(
				getName(MessageEnum.RestAPIResponceDataMessage)
			);
			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);
			if (responseJson && responseJson.errors) {
				this.parseApiErrorResponse(responseJson.errors);
				this.parseApiCatchErrorResponse(responseJson.errors);
				this.setState({loading:false})
			}
			else if (this.getAllCourseCommentCallId === apiRequestCallId) {
				this.handleAllCourseCommentCallId(responseJson)
			}
			else if (this.courseCommentCallId === apiRequestCallId) {
				let userComment = '';
				this.setState({
					userComment
				})
				this.getAllComments();
			}
			else if (this.courseLikeCallId === apiRequestCallId) {
				this.handleCourseCallLikeId(responseJson,apiRequestCallId)
			}
			else if (this.courseCommentReplyCallId === apiRequestCallId) {
				this.handleCommentCallId(responseJson)
			}
			else if(apiRequestCallId == this.singleProject) {
				this.setState({ ProjectDataById: responseJson.project,loading:false })
			}
			else if (apiRequestCallId === this.getLikesNumber) {
				this.setState({ likesCount: responseJson?.likes_count })
			}
			else if (apiRequestCallId === this.countCommentLike) {
				this.setState({ countCommentLikeNumber: responseJson?.data })
			}
		}
		// Customizable Area End

	}

	// Customizable Area Start
	handleShareCourse = (p: any) => () => {
		const id = this.props.navigation.getParam('id')
		let data = {
			link: `${window?.location?.href}/projects/${id}`,
			shareableType: 'BxBlockProjectManagement::ProjectManagement',
			shareableId: p?.id,
		}
		sendSharePopupMessage(data);
	}

	handleAllCourseCommentCallId = (responseJson:any) => {
		let courseCommentData = responseJson?.comments?.data?.map((d: any) => ({ ...d.attributes, id: d.id })) || []
				this.setState({
					courseCommentData, pagination: responseJson?.meta?.pagination
				})
				this.getSingleProject()
	}
	handleCommentCallId = (responseJson:any) => {
		let new_reply = responseJson.data ? { ...responseJson.data.attributes, id: responseJson.data.id, like_count: 0 } : []
		if (new_reply) {
			let courseCommentData = this.state.courseCommentData;
			let updateItem = courseCommentData.find((x) => x.id == this.state.reply_id);
			let index = courseCommentData.indexOf(updateItem);
			updateItem.replies.push(new_reply);
			courseCommentData[index] = updateItem;
			let userReply = '';
			this.setState({
				userReply, courseCommentData, reply_id: ''
			})
		}
	}
	handleCourseCallLikeId = (responseJson:any, apiRequestCallId:any) => {
		const comment_id = this.likeCallIdToCommentId[apiRequestCallId];
				let courseCommentData = this.state.courseCommentData;
				let updateItem = courseCommentData.find((x) => x.id == comment_id);
				let index = courseCommentData.indexOf(updateItem);
				delete this.likeCallIdToCommentId[apiRequestCallId]
				if (responseJson?.data) {
					updateItem.like_count += 1;
					updateItem.is_like = true;
					const likeData = { ...responseJson.data, ...responseJson.data?.attributes }
					updateItem.like_id = [...(this.state.courseData.like_id || []), likeData];
					courseCommentData[index] = updateItem;
					this.setState({ courseCommentData });
				}
				else {
					updateItem.like_count -= 1;
					updateItem.is_like = false;
					updateItem.like_id = [];
					courseCommentData[index] = updateItem;
					this.setState({ courseCommentData });
				}

	}

	handleCourseCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({ userComment: e.target.value })
	}

	handleCourseReplyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({ userReply: e.target.value })
	}

	handleCourseComment = async(id: any) => {
		const token = await getStorageData('authToken') || ''
		const { userComment } = this.state
		const body = new FormData()
		body.append("project_management_id", this.props.navigation.getParam('id'))
		body.append("content", userComment);
		this.courseCommentCallId = sendAPIRequest(configJSON.createComments, {
			method: 'POST',
			body,
			headers: { token }
		})

		let stateEmogi = false;
		this.setState({ openCommentEmoji: stateEmogi });
	}

	handleCommentLike = async(comment: any) => {
		const token = await getStorageData('authToken') || ''
		const body = new FormData()
		body.append("data[attributes][likeable_type]", 'Comment')
		body.append("data[attributes][likeable_id]", comment.id);
		let url = "";
		if (comment.is_like) {
			url = `${configJSON.likeProjectComment}/${comment?.like_id[0]?.id}`
		}
		else{
			url = `${configJSON.likeProjectComment}`
		}
		this.courseLikeCallId = sendAPIRequest(url, {
			method: comment.is_like ? 'DELETE' : 'POST',
			body,
			headers: { token }
		})
		this.likeCallIdToCommentId[this.courseLikeCallId] = comment.id
	}

	handleSetReplyId = (reply_id: any) => {
		this.setState({
			reply_id
		});
	}

	handleReplyComment = async() => {
		const token = await getStorageData('authToken') || ''
		const { userReply, reply_id } = this.state
		this.courseCommentReplyCallId = sendAPIRequest(`${configJSON.commentsReply}?comment_id=${reply_id}&content=${userReply}`, {
			method: 'POST',
			headers: { token }
		})
		let stateEmogi = false;
		this.setState({ openCommentReplyEmoji: stateEmogi });
	}

	loadMore = () => {
		this.getAllComments()
	}

	onLikeChange = (postId: any, delta: number) => {
		let p = this.state.ProjectDataById?.data;
			if (Boolean(p.attributes?.project_likes?.some((project: any) => Number(project.like_by_id) === Number(this.state.userID)))) {
			  p.attributes.project_likes_count -= 1
			  p.attributes.project_likes = p.attributes?.project_likes.filter((project: any) => Number(project.like_by_id) !== Number(this.state.userID))
			} else {
			  p.attributes.project_likes_count += 1
			}
		this.setState({ courseData:p})
		this.getSingleProject()
	}
	onLikeIdUpdate = (postId: any, likeObj: any) => {
		let p = this.state.ProjectDataById?.data;
		p.attributes.project_likes = [likeObj];
		this.setState({ courseData:p })
		this.getSingleProject()
	}

	onCommentEmojiClick = (event: any, emojiObject: any) => {
		let emoji = emojiObject?.emoji;
		let userComment = this.state.userComment + emoji;
		this.setState({ userComment });
	}

	openEmojiComment = () => {
		let stateEmogi = !this.state.openCommentEmoji;
		this.setState({ openCommentEmoji: stateEmogi });
	}

	openEmojiCommentReply = () => {
		let stateEmogi = !this.state.openCommentReplyEmoji;
		this.setState({ openCommentReplyEmoji: stateEmogi });
	}

	onReplyEmojiClick = (event: any, emojiObject: any) => {
		let emoji = emojiObject?.emoji;
		let userReply = this.state.userReply + emoji;
		this.setState({ userReply });
	}

	showReply = (comment_id: any) => {
		this.setState({ openReply: comment_id });
	}

	getProfileDashboard = () => {
		this.getProfileDashboardData = sendAPIRequest(`${configJSON.profileDashboard}`, {
			method: "GET",
			headers: { token: this.state.token || '' }
		})
	}

	getSingleProject = () => {
		const id = this.props.navigation.getParam('id')
		this.singleProject = sendAPIRequest(`${configJSON.projectDetailById}?project_id=${id}`, {
			method: "GET",
			headers: { token: this.state.token || '' }
		})
	}

	getLikesCount = () => {
		const id = this.props.navigation.getParam('id')
		this.getLikesNumber = sendAPIRequest(`${configJSON.getLikes}?project_id=${id}`, {
			method: "GET",
			headers: { token: this.state.token || '' }
		})
	}

	getCommentsLikeCount = () => {
		const id = this.props.navigation.getParam('id')
		this.countCommentLike = sendAPIRequest(`${configJSON.getCommentsLikes}?id=${id}`, {
			method: "GET",
			headers: { token: this.state.token|| '' }
		})
	}
	// Customizable Area End

}

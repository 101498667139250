import React from 'react'
import { CreateCSSProperties, styled } from '@material-ui/styles'

import { Wrapper, Modal, CloseButton } from './FormModal.web'
const CloseIcon = require('./ic_close.png')


interface AttachmentModalProps {
	url?: string;
	onClose?: () => void;
}

export default function AttachmentModal({url, onClose}: AttachmentModalProps){
	if(!url) return null;
	return <Wrapper>
		<ModalFull>
			 <CloseButton onClick={onClose}>
                     <img src={CloseIcon} width="26px" />
           </CloseButton>
			<Iframe src={url} width="100%" height="100%" />
		</ModalFull>
	</Wrapper>
}

const ModalFull = styled(Modal)((props: any) => ({
	maxWidth: '80vw',
	margin: 0,
	overflowX: 'hidden',
	[props.theme.breakpoints.down('sm')]: {
		maxWidth: '100vw',
	}
}))

const Iframe = styled('iframe')((props: any) => ({
	width: '100%',
	height: '80vh',
	marginTop: '30px',
	[props.theme.breakpoints.down('sm')]: {
		height: '95vh',
	},

}))
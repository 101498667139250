// Customizable Area Start
import {runEngine} from '../../../framework/src/RunEngine'
import MessageEnum, {
  getName
} from '../../../framework/src/Messages/MessageEnum'
import { Message } from "../../../framework/src/Message";

export function sendReportMessage(postId: string, postType: string){
	let msg = new Message(getName(MessageEnum.ReportModalOpenMessage));
    msg.addData(
      getName(MessageEnum.ReportModalData),
      {postId, postType}
    );
    runEngine.sendMessage(msg.id, msg);
    return msg.id
}

// Customizable Area End
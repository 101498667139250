import React from 'react'
import {
    Typography,
} from "@material-ui/core";
const iconUpload = require('./ic_upload_green.png')
import { useField } from 'formik'

import FilePreview, { PreviewRow } from './FilePreview.web'
import RoundedButton from "./RoundedButton.web"
import { toast } from 'react-toastify';

const getTotalFileSize = (files: any[])=>{
    const size = files.map(file=>file.size).reduce((a: any, b:any)=>a+b, 0)
    return size
}

const UploadButton = (props: any) => {
    const [field, meta, helpers] = useField(props)
    const [validationError, setValidationError] = React.useState("")
    const inputRef = React.useRef<HTMLInputElement>(null)
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let files = Array.from(e.currentTarget.files ||[])
        const totalFiles = field.value.length + files.length
        if (!files) return
        let newFiles:any=[]
        let oldFiles:any=[]
        if(files && props.isCorporate && props.isCorporate!==undefined){
        //@ts-ignore
        newFiles= e.currentTarget.files ? e.currentTarget.files.item(0).name : []
        field.value.map((t:any)=>oldFiles.push(t.name))
        if(totalFiles>5){
            toast.error("You can select maximum 5 attachments only");
        }
        if(oldFiles.includes(newFiles)){
            toast.error("File already exist");
        }
        (totalFiles>5 && props.isCorporate!==undefined)  ? (files = (field.value||[])) : (oldFiles.includes(newFiles)) ? (files = (field.value||[])) : (files = (field.value||[]).concat(files))
        }
        else {
            files = (field.value||[]).concat(files)
        }
        fileSizeValidation(files)
        
        helpers.setValue(files);
        props.setFileInState(files);
    }

    const fileSizeValidation = (files: any[]) => {
        
        const fsize = getTotalFileSize(files)
        if(props.maxTotalSize < fsize)
            {
                const msg = `Cannot upload files more than ${Math.floor(props.maxTotalSize/(1024*1024))}Mb`
                helpers.setError(msg)
                setValidationError(msg)
            }
        else
            {
                setValidationError("")
                helpers.setError(undefined)
            }
        helpers.setTouched(true)

    }

    const onRemove = (e: any) => {
        const filename = props.isCorporate && props.isCorporate!==undefined  ? e.name: e.currentTarget.dataset.filename
        if(!filename) return
        e?.id!==undefined && props.isCorporate && props.isCorporate!==undefined && props.deleteBoardOperationFiles(props.boardOperationId,e.id,props.attchementType)
        const files = field.value.filter((f: any) => f?.name!=filename)
        helpers.setValue(
            files
        )
        fileSizeValidation(files)
    }

    const onClick = () => inputRef?.current && inputRef?.current?.click()
    const files = field.value || []
    return <><label>
        <RoundedButton onClick={onClick} startIcon={<img width="25px" src={iconUpload} />}>{props.label}</RoundedButton>
        <input 
            multiple
            type="file" 
            accept={props.filetype || "*"} 
            onChange={(e)=>onChange(e)} 
            ref={inputRef} 
            name={props.name} 
            style={{ display: 'none' }} 
        />
        
        {meta.touched && (meta.error || validationError) && <Typography color="error">{meta.error || validationError}</Typography>}
    </label>
    <div style={{ padding: '10px 0' }}>
        <PreviewRow>
                {files.map((f: any)=> <FilePreview isCardDisable={props.isCardDisable} isCorporate={props.isCorporate} key={f.name} file={f} onRemove={onRemove} />)}
        </PreviewRow>
    </div>
    </>
}

export default UploadButton
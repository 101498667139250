import React from "react";
// Customizable Area Start
import {
    MenuItem as MenuItemEdit,
    Avatar as AvatarEdit,
    FormControlLabel,
    Checkbox,
    Typography
} from '@material-ui/core';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import { styled } from '@material-ui/styles';
import { toast } from 'react-toastify';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import PreferenceCatSubCat from '../../categoriessubcategories/src/PreferenceCatSubCat.web'
import OverlayLoader from '../../../components/src/Loader.web'
import { Formik, useField } from 'formik'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import FormModal, { InputRow as InputRowEdit, MInput as MInputEdit, MSelect as MSelectEdit, MCountedTextArea } from '../../../components/src/FormModal.web'
import UserContext from "../../../components/src/UserProvider";
import UploadButton from '../../../components/src/UploadButtonnew.web'
//@ts-ignore
import Maps from '../../maps/src/Maps.web';
import CreateRequestToListController, { Props } from "./RequestToListController";
// Customizable Area End





export default class CreateRequestToListEdit extends CreateRequestToListController {
    selectedItem: any;
    getuserDetails: any;
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start


    CreateTemplateModal() {


      
      

        const getInitialValues = () => {
            const attributes = this.state.projectEditData?.attributes || {};
            const location = attributes?.location || '';
            const formOfFunding = attributes?.form_of_funding || '';
            const status = attributes?.project_status || '';
            const preferenceIds = attributes?.preference_ids || [];
            const categoryIds = attributes?.category_ids || [];
            const subCategoryIds = attributes?.subcategory_ids || [];
            const attachments = attributes?.docs ? attributes?.docs : []
            const project_unique_id = attributes?.project_unique_id || ''

            return {
                ...attributes,
                location,
                form_of_funding: formOfFunding,
                status,
                preference_ids: preferenceIds,
                category_ids: categoryIds,
                sub_category_ids: subCategoryIds,
                attachments: attachments,
                project_unique_id

            };
        };

        const initialValues = getInitialValues();
        return <Formik enableReinitialize initialValues={initialValues} onSubmit={this.handleEditSubmit}


        >
            <ModalWrap>
                <FormModal modalStyle={{ maxWidth: '700px' }} headerTitle={<>
                    <ArrowBackIosIcon style={{ fontWeight: 100, color: 'gray', cursor: 'pointer',top:'5px',position:'relative' }} onClick={() => this.hideModal()} />
                <b>EDIT PROJECT SEEKING FUNDS</b>
                </>} submitLabel={"Update"} onClose={this.hideModal} >
                    <div style={{
                        display: "flex",
                        padding: " 10px",
                        alignItems: "flex-start"
                    }}>
                        <div style={{ position: "relative" }}>

                            <label htmlFor="upload-button">

                                <span style={{ display: "flex", cursor: "pointer" }}>
                                    <AvatarEdit style={{
                                        width: "70px",
                                        height: "70px",
                                    }}>
                                        {this.state?.profile_pic_show != '' && <img src={this.state?.profile_pic_show} alt="img" width="100%" height="100%" />}
                                    </AvatarEdit>
                                    <AvatarEdit style={{
                                        position: "absolute",
                                        color: "white",
                                        top: "60%",
                                        left: "60%",
                                        backgroundColor: "#1ea60a",
                                        width: "25px",
                                        height: "25px",
                                        padding: "3px",
                                        border: "1px solid white"
                                    }}>
                                        <BorderColorIcon fontSize="small" />

                                    </AvatarEdit>
                                </span>
                            </label>
                            <input
                                type="file"
                                accept=".png, .jpeg, .jpg"
                                id="upload-button"
                                style={{ display: "none" }}
                                onChange={e => {
                                    const len = e?.currentTarget?.files;
                                    if (len !== null) {
                                        if (len[0]?.size > 1048576) {
                                            toast.error("Cannot Upload Picture more than 1 MB");
                                            e.currentTarget.files = null;
                                        }
                                        else if (len[0]?.type !== "image/png" && len[0]?.type !== "image/jpeg") {
                                            toast.error("image should only be png or jpeg or jpg")
                                        }
                                        else {
                                            this.handleFileChange(e)
                                        }
                                    }
                                }}
                            />
                        </div>
                        <div style={{ alignSelf: 'center', paddingLeft: '10px' }}>
                            Project Image
                        </div>
                    </div>
                    <InputRowEdit label="Project Name*" input={
                        <MInputEdit name="project_name" placeholder="" />
                    } />
                    <InputRowEdit label="Project ID" input={
                        <MInputEdit name="project_unique_id" disabled placeholder="" />
                    } />

                    <InputRowEdit label="Description*" input={
                        <MCountedTextArea length={100} name="description" placeholder="" />
                    } />
                    <InputRowEdit label={<JobLabel>Attachments</JobLabel>} input={
                        <>
                            <div style={{ margin: '10px 0' }}>
                                <UploadButton
                                    multiple
                                    name="attachments"
                                    setFileInState={this.setFilesArray}
                                    label="Upload Attachment"
                                    onDelete={(id:any)=>{this.onDelete(id)}}
                                />
                            </div>
                        </>
                    } />



                    {/* @ts-ignore */}
                    <InputRowEdit label="Location*" input={<Maps input  {...this.props} />} />


                    <InputRowEdit label="SDG focus*" input={
                        <MSelectEdit
                            labelId="select-users-label"
                            name="sdg_focus"
                            multiple
                            value={this.state.selectedSDGFactor}
                            onChange={(e) => this.setState({ selectedSDGFactor: e.target.value })}
                            renderValue={(selected: any) => (
                                <AvatarGroup max={3}>
                                    {this.state?.sdgFactor?.filter((user: any) => selected.includes(user.id) || this.state.selectedSDGFactor.includes(user.id))
                                        .map((user: any) => (
                                            <AvatarEdit key={user.id} alt={user.name} src={user?.attributes?.image} />
                                        ))}
                                </AvatarGroup>
                            )}
                        >
                            {this.state?.sdgFactor?.map((user: any) => (
                                <MenuItemEdit key='' value={user?.id}>
                                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                        <AvatarEdit src={user?.attributes?.image} />
                                        <label htmlFor={`checkbox-${user?.id}`}>
                                            <FormControlLabel
                                                label={user?.attributes?.goal}
                                                style={{
                                                    height: "50px",
                                                    padding: "5px 5px 5px 15px",
                                                }}

                                                id={`checkbox-${user?.id}`}
                                                control={
                                                    <></>
                                                }
                                            />
                                        </label>

                                    </div>
                                    <Checkbox
                                        checked={this.state.selectedSDGFactor.some((item: any) => item === user?.id)}
                                        value={user?.id}
                                    />
                                </MenuItemEdit>
                            ))}
                        </MSelectEdit>
                    } />

                    <InputRowEdit label="Organization Name*" input={
                        <MInputEdit name="organization_name" placeholder="" />
                    } />

                    <InputRowEdit label="Funding Required (Amount in USD)*" input={
                        <MInputEdit name="funding_amount" placeholder="" />
                    } />

                    <InputRowEdit label="Project Leader" input={
                        <MInputEdit name="project_leader" placeholder="" />
                    } />

                    <PreferenceCatSubCat {...this.props} initialValues={initialValues} prefererence/>


                    <InputRowEdit label={<JobLabel>Form of Funding</JobLabel>} input={
                        <MSelectEdit name="form_of_funding" placeholder="Select" >
                            <MenuItemEdit disabled value={-1}>Select</MenuItemEdit>
                            <MenuItemEdit value="Self Funded">Self Funded</MenuItemEdit>
                            <MenuItemEdit value="Self Funded (Reserves)">Self Funded (Reserves)</MenuItemEdit>
                            <MenuItemEdit value="Unsecured Loans">Unsecured Loans</MenuItemEdit>
                            <MenuItemEdit value="Bank Loans (Secured)">Bank Loans (Secured)</MenuItemEdit>
                            <MenuItemEdit value="Bank Loans (Unsecured)">Bank Loans (Unsecured)</MenuItemEdit>
                            <MenuItemEdit value="Angel Investors">Angel Investors</MenuItemEdit>
                            <MenuItemEdit value="Venture Capital">Venture Capital</MenuItemEdit>
                            <MenuItemEdit value="New Equity">New Equity</MenuItemEdit>
                            <MenuItemEdit value="Crypto(ICO)">Crypto(ICO)</MenuItemEdit>
                            <MenuItemEdit value="Grants">Grants</MenuItemEdit>
                            <MenuItemEdit value="Others">Others</MenuItemEdit>
                        </MSelectEdit>
                    } />

                    <InputRowEdit label={<JobLabel>Status</JobLabel>} input={
                        <MSelectEdit name="status" placeholder="Select">
                            <MenuItemEdit disabled value={-1}>Select</MenuItemEdit>
                            <MenuItemEdit value="Open">Open</MenuItemEdit>
                            <MenuItemEdit value="Closed">Closed</MenuItemEdit>
                            <MenuItemEdit value="Funded">Funded</MenuItemEdit>
                        </MSelectEdit>
                    } />
                    <OverlayLoader loading={!!this.state.submittingForm} />
                </FormModal>
            </ModalWrap>
        </Formik>
    }

    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            <>
                {this.CreateTemplateModal()}
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

CreateRequestToListController.contextType = UserContext






const JobLabel = styled('div')({
    fontSize: '15.35px',
    color: '#404040',
    fontFamily: 'HelveticaNeue'
})



const ModalWrap = styled('div')({
})


// Customizable Area End 

import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { sendAPIRequest } from "../../../components/src/utils";
import { getStorageData } from "../../../framework/src/Utilities";
import { sendAddRequestMessage } from "../../RequestManagement/src/Messages";
import { parseCoinsUpdatedMessage, sendNoOfCoinsMessage } from "./Messages";
import { CorpstageWallet, TransferData } from "./types";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  txtInputValue: string;
  txtSavedValue: string;
  // Customizable Area Start
  token: string;
  corpstageWallet: CorpstageWallet | null;
  modal: 'transfer' | null;
  pages: number;
  current_page: number;
  total_pages: number | undefined;
  transaction_type: number | null;
  to_date: number | null;
  PageSortQuery: boolean;
  sortby: string;
  sortOrder: number | null;
  // userRole: any,
  // pagination?: any,
  loadingCorpstageCoins: boolean,
  // Customizable Area End
  transferCoinsList: TransferData[];
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CorpsStageCoinsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  coinsWalletRequestId: string = ""
  //   userRoleCallId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.GetAllCategoriesResponseMessage),
      getName(MessageEnum.GetAllSubCategoriesResponseMessage),
      getName(MessageEnum.NewServiceCreatedMessage),
      getName(MessageEnum.CorpstageCoinsUpdateMessage)
      // Customizable Area End
    ];


    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      modal: null,
      // Customizable Area Start
      token: '',
      corpstageWallet:null,
      transferCoinsList: [],
      transaction_type: null,
      to_date: null,
      PageSortQuery: false,
      sortOrder: null,
      sortby: '',
      //   userRole: "",
      loadingCorpstageCoins: false,
      pages: 1,
      current_page: 1,
      total_pages: undefined,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.navigation.history.location.state?.isNavigated) {
      this.props.navigation.history.replace({state: {}})
      this.getCoinsList()
    }   
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );
      this.setState({ txtSavedValue: value });
    }
    else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.errors) {
        this.parseApiErrorResponse(responseJson.errors);
        this.parseApiCatchErrorResponse(responseJson.errors);
      } else if (apiRequestCallId === this.coinsWalletRequestId) {
        if (responseJson) {
          const corpstageWallet = responseJson?.user_wallet
          const transferCoinsList = responseJson?.transaction_history?.data.map((item: any) => item.attributes)
          const current_page = responseJson?.meta?.pagination?.current_page
          const total_pages = responseJson?.meta?.pagination?.total_pages
          this.setState({ corpstageWallet, transferCoinsList, current_page, total_pages })
          sendNoOfCoinsMessage(corpstageWallet)
        }
        this.setState({ loadingCorpstageCoins: false })
      }
    }
    parseCoinsUpdatedMessage(message, this.handleCoinsMessage)
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    // this.getUserRole()
    let authToken = await getStorageData('authToken');
    this.setState({token: authToken}, () => {
      this.getCoinsList()
    })
  }

  handleCoinsMessage = (data: any) => {
    const corpstageWallet = data
    this.setState({ corpstageWallet })
  }

  getCoinsList = async() => {
    const token = await getStorageData('authToken');
    this.setState({ loadingCorpstageCoins: true })
    let { sortby, sortOrder, pages } = this.state;
    let sortQuery = ``;
    if (sortby && sortOrder) {
      sortQuery = `&${sortby}=${sortOrder - 1}`
    }
    let url = configJSON.coinsListEndPoint
    this.coinsWalletRequestId = sendAPIRequest(`${url}?page=${pages}${sortQuery}`, {
      method: "GET",
      headers: { 'token': token, 'content-type': 'appliction/json' },
    })
  }

  handleTransfer = () => {
    console.log("handle transfer modal")
  }

  createTransferClick = () => {
    this.props.navigation.history.push(`${window.location.pathname}/create-transfer`)
  }

  handlePurchase = () => {
    this.props.navigation.navigate('PurchaseCoins')
  }

  getNewDate = (date: any) => {
    const newDate = new Date(date).toLocaleDateString();
    return newDate
  }

  handlePageChange = (event: any, value: any) => {
    this.setState({ pages: value }, this.getCoinsList)
  }

  handleSort = (sortby: string, sortOrder: number) => {
    this.setState({ sortby, sortOrder }, this.getCoinsList)
  }

  handleRaiseRequest = (e: any) => {
    this.props.navigation.navigate('AddRequest')
    const requestOwner = this.context
    let assignedTo =  { id: 2, 
                        name: "Corpstage Platform", 
                        location: "", 
                        profile_pic: "", 
                        first_name: "Corpstage Platform", 
                        last_name: "" }

    assignedTo = { ...assignedTo, first_name: assignedTo.name, last_name: '' }

    setTimeout(() => sendAddRequestMessage({
          disable_fields: ['created_by', 'accounts', 'request_type_id'],
          sender_id: requestOwner,
          created_by: requestOwner,
          accounts: {data: [{ id: String(1), attributes: assignedTo }]},
          "request_type_id": {
            "request_type": {
              "id": 11,
              "title": "Corpstage Coin Related",
            }
          }
        }), 1000 // a slight delay to send after component is rendered
    )
  }

  // handleTypeSort = (order: number) => {
  //   this.setState({ PageSortQuery: true }),
  //     this.setState({ transaction_type: order }, () => this.getCoinsList(`&transaction_type=${this.state.transaction_type}`))
  // }

  // handleDateSort = (order: number) => {
  //   // this.setState({ to_date: order }, () => this.getCoinsList(`&to_date=${this.state.to_date}`))
  // }


  // hideModal = () => { this.setState({ modal: null }) }

  // Customizable Area End

}

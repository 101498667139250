import React, { useEffect } from "react";
// Customizable Area Start
import TopNavBar from "../../../components/src/TopNavbar";
import {
  Box,
  Grid,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Button,
  MenuItem,
  Select,
  ListItemText,
  Checkbox,
  Avatar,
  FormControl,
  TextField,
  InputBase,
  InputAdornment,
  ListSubheader,
  CircularProgress,
} from '@material-ui/core';
import LeftMenuWeb from "../../../components/src/LeftMenuWeb";
import RightMenuWeb from "../../../components/src/RightMenuWeb";
import DashboardLayout from '../../../components/src/DashboardLayout.web'
import { FieldEffect, FormikEffect } from '../../../components/src/FormikEffect.web'
import SelectInput from '../../../components/src/SelectInput.web'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { LoadingSpinner } from '../../../components/src/LoadingSpinner.web'
import SearchSelect from '../../../components/src/SearchSelect.web'
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import OverlayLoader from '../../../components/src/Loader.web'
import PreferenceCatSubCat from '../../categoriessubcategories/src/PreferenceCatSubCat.web'
// import SearchIcon from '@material-ui/icons/Search';
import { Formik, Form, useField } from 'formik'
import FormModal, {
  InputRow,
  MInput,
  MCountedTextArea,
  MFileDrop,
  MTimePicker,
  MDatePicker,
  MSelect,
  FInputLabel,
  MTextArea,
} from '../../../components/src/FormModal.web'
import Chip from '@material-ui/core/Chip';
import Flex from '../../../components/src/Flex.web'
import Switch from '../../../components/src/Switch.web'
import { styled } from '@material-ui/styles';
import UserContext from "../../../components/src/UserProvider";
import { CommunityCard, FilterSelect, FilterSelectCategory } from './Components.web'

import CreateModalController, {
  Props,
  configJSON
} from "./CreateModalController";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp
} from "react-native-responsive-screen";
import { RFValue } from "react-native-responsive-fontsize";
import moment from "moment";
import InfiniteScroll from 'react-infinite-scroll-component';

import Community from "./Community";
const ExcelIcon = require('../assets/ic_excel.png')
const DownloadIcon = require('../assets/ic_download.png')
import { SearchIcon, AddCircleIcon } from '../../../components/src/Icons.web'
import SearchInput from '../../../components/src/Search.web'
import { useDebounce } from '../../../components/src/utils'
// @ts-ignore
import classes from './CommunityWeb.module.css'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,

      //   minWidth: 150,
    },
  },
};
// Customizable Area End
export default class CreateModal extends CreateModalController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  createModal() {
    const initialValues = {
      name: '',
      image: '',
      description: '',
      community_type: this.state.community_type,
      moderator_ids: [],
      preference_ids: [],
      category_ids: [],
      sub_category_ids: [],
      user_ids: [],
      ...(this.props.initialValues ||{}),
    }
    const user = this.context
      return <Formik validateOnMount={!!this.props.initialValues} validationSchema={this.createFormSchema} initialValues={initialValues} onSubmit={this.handleCreateCommunitySubmit}>
        <FormModal isDirty={!!this.props.initialValues} headerTitle={
          <Flex justify="space-between" style={{ marginRight: '35px' }}>
            <b>{this.props.community_id?"EDIT COMMUNITY":"CREATE COMMUNITY"}</b>
          </Flex>}
          submitLabel="SUBMIT"
          onClose={this.clearAndClose}
        >
          <FormikEffect onChange={console.log} />
          
          <InputRow label={<>Community Name*</>} input={<><MInput name="name" placeholder="" /></>} />
          <InputRow label="Upload Image (Optional)" input={<>
            <MFileDrop
              name="image"
              inputProps={{ accept: "image/*" }}
            />
          </>}
          />
          <InputRow label={<>Description*</>} input={<><MInput multiline rows={4} name="description" /></>} />

          <Flex align="center" justify="start" gap="25px" style={{flexWrap:'wrap'}} >
            <Flex ><FInputLabel>Community Type. :</FInputLabel></Flex>
            <Flex align="center" justify="center" gap="5px">
              <CommunityType name="community_type" />
            </Flex>
          </Flex>
          <InputRow label="Admin" input={<><div style={{
            display: "flex", fontSize: 14,
            borderRadius: "5px",
            border: "1px solid #BEBEBE",
            height: "45px",
            textAlign: "center",
            alignItems: "center",
            justifyContent: "start",
            padding: "0 10px",
          }}>
            <Avatar alt={`${user.first_name} ${user.last_name}`} src={user.profile_pic} style={{ width: "30px", height: "30px", marginRight: "10px" }} />
            <Typography style={{ color: "#333232" }}>
              {user.first_name} {user.last_name}
            </Typography>
          </div>
          </>} />
          <InputRow label={<Flex>Moderator*<Typography color="textSecondary">(Minimum 3 moderators)</Typography></Flex>} input={<ModeratorSelect
            pagination={this.state.moderatorPagination}
            hasMore={!!this.state.moderatorPagination?.next_page}
            nextPage={this.paginateModerators}
            loading={this.state.moderatorSearchCallId !== ""}
            onSearch={this.handleModeratorSearch}
            allItems={this.state.moderatorOptions}
            selectProps={{onClose: this.handleModeratorClose}}
            items={this.state.moderatorOptions}
            name="moderator_ids"
            SelectItem={SelectItemUser}
            getOptionValue={(user: any) => `${user.first_name} ${user.last_name}`}
          />}
          />

          <PreferenceCatSubCat {...this.props} />
          <ImportIdsInject name="user_ids" importIds={this.state.importIds} />
          <InputRow label={<FlexBtn justify="space-between" align="center">
            <span>Add Members*</span>
            <FlexR>
                <ImportCSVButton onImport={this.importCsv} loading={this.state.importLoading} />
                <DownloadLink href="/user emails.csv"><DownloadSampleButton /></DownloadLink>
            </FlexR>
            
          </FlexBtn>}
            input={<>
              <MemberSelect
                hasMore={!!this.state.memberPagination?.next_page}
                pagination={this.state.memberPagination}
                nextPage={this.paginateMembers}
                loading={this.state.memberSearchCallId !== ""}
                allItems={this.state.memberOptions}
                items={this.state.memberOptions}
                onSearch={this.handleMemberSearch}
                selectProps={{onClose: this.handleMemberClose}}
                name="user_ids"
                SelectItem={SelectItemUser}
                getOptionValue={(user: any) => `${user.first_name} ${user.last_name}`}
              />
              <Typography variant="subtitle2" color="textSecondary">{this.state.importError}</Typography>
            </>
            } />

        </FormModal>
      </Formik>
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    if(!this.props.open) return <></>
    const modal = <>
        {this.createModal()}
        <OverlayLoader loading={this.state.loadingCreate} />
      </>

    // Customizable Area End
    return (
      // Customizable Area Start
        modal
      // Customizable Area End

    );
  }
}

// Customizable Area Start

CreateModalController.contextType = UserContext

const ImportCSVButton = (props: any) => {
  const ref = React.useRef<HTMLInputElement | null>(null)
  const onClick = () => ref?.current && ref.current?.click?.()
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => props.onImport && props.onImport(e.target.files)
  return <>
    <ImportButtonBase loading={props.loading} downloadIcon onClick={onClick} {...props} />
    <input onChange={handleChange} name="csv_file" type="file" accept=".csv" style={{ display: 'none' }} ref={ref} />
  </>
}

const DownloadSampleButton = () => {
  return <ImportButtonBase />
}

const ImportButtonBase = (props: any) => {
  const icon = props.downloadIcon ? DownloadIcon : ExcelIcon
  const clickHandler = props.onClick || (() => { });
  const label = !props.downloadIcon ? "Download Sample" : "Import from CSV"
  return <ImportButton onClick={clickHandler}>
    {!props.loading && <img src={icon} width="25px" />}{!props.loading && label}
    {props.loading && <CircularProgress size={18} />}{props.loading && "Loading..."}
  </ImportButton>
}

const ImportButton = styled('div')({
  cursor: 'pointer',
  backgroundColor: '#ececec',
  color: '#7A7A7A',
  border: '1px solid #aaa',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  padding: '5px 12px',
  borderRadius: '6px',
  fontSize: '13px',
})

const ImportIdsInject = (props: any) => {
  const [field, meta, helpers] = useField(props)
  console.log({ ids: props.importIds })
  React.useEffect(() => {
    helpers.setValue([...(field.value || []), ...props.importIds])
  }, [props.importIds])
  return <></>
}

const CommunityType = (props: any) => {
  const [field, meta, helpers] = useField("community_type")

  const handleChange = (e: any) => {
    helpers.setValue(e.target.checked)
  }

  return <>
    <Typography color={field.value ? "textSecondary" : "textPrimary"}>Private</Typography>
    <Switch disabled checked={field.value} onChange={handleChange} name="community_type" />
    <Typography color={!field.value ? "textSecondary" : "textPrimary"}>Public</Typography>
  </>
}

export const SelectItemUser = ({ item, value }: { item: any, value: any[] }) => {
  const selected = value?.indexOf?.(item.id) > -1

  return <div style={{ width: '100%', display: 'flex', flexDirection: 'row-reverse', alignItems: 'center' }}>
    <Checkbox
      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
      checkedIcon={<CheckBoxIcon fontSize="small" style={{ color: "#1ea60a" }} />}
      checked={selected} 
   />
    <Typography component={'div'} style={{ flex: 3, overflow: 'hidden', textOverflow: 'ellipsis' }}>
      {item.email}
    </Typography>
    <ListItemText style={{ flex: 2 }} disableTypography primary={
      <div style={{ display: "flex", alignItems: "center" }}>
        <Avatar alt="Remy Sharp" src={item.profile_pic} style={{ width: "30px", height: "30px", marginRight: "10px" }} />
        <Typography style={{ color: "#333232", fontWeight: "bold", fontSize: 12, width: '35ch', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {item.first_name} {item.last_name}
        </Typography>
      </div>
    } />
  </div>
}





const RoundedButtonBase = styled(Button)((props: any) => ({
  position: 'relative',
  padding: '10px 0',
  fontWeight: 700,
  width: '100%',
  display: 'flex',
  textAlign: 'center',
  justifyContent: 'center',
  backgroundColor: props.variant === "outlined" ? "#FFF !important" : '#009c05',
  color: props.variant === "outlined" ? "#009c05" : 'white',
  borderRadius: '30px',
  height: '48px',
  border: props.variant === "outlined" ? "2px solid #009c05" : "none",
  '&:hover': {
    backgroundColor: props.variant === "outlined" ? "#CCC" : '#009c05',
  },
  '& > .MuiButton-label': {
    fontSize: '13px'
  },
  maxWidth: props.single?'320px':'unset',
}))

const FlexBtn = styled(Flex)({
  '@media screen and (max-width:600px)':{
    flexDirection:'column',
    alignItems:'start'
  }
})

const FlexR = styled('div')({
  display:'flex',
  gap:'10px',
  flexWrap:'wrap',
  '@media screen and (max-width:600px)':{
    width :'100%',
    justifyContent:'space-evenly'
  },
  '@media screen and (max-width:466px)':{
    flexDirection:'column',
    width:'-webkit-fill-available'
  }
})

const RoundedButtonWithIcon = styled(RoundedButtonBase)({
  '& > .MuiButton-label': {
    fontSize: '13px',
    display: 'flex',
    paddingLeft: '25px',
  }
})

const CreateButtonWrapper = styled('div')((props: any) => ({
  display: 'flex',
  gap: '15px',
  '@media (max-width: 1200px)': {
    flexDirection: 'column'
  },
  justifyContent: props.single?"flex-end":"space-between",
}))

const DownloadLink = styled('a')({
  textDecoration: 'none'
})

const MemberSelect = (props: any) => {
  const [field, meta, helpers] = useField("user_ids")
  const itemsData = props.items
  // const items = props.items.filter((item: any)=> !field.value.includes(item.id) )
  return <SearchSelect {...props} items={itemsData} />
}

const ModeratorSelect = (props: any) => {
  const [field, meta, helpers] = useField("moderator_ids")
  const itemsData = props.items
  // const items = props.items.filter((item: any)=> !field.value.includes(item.id) )
  return <SearchSelect {...props} items={itemsData} />
}


// Customizable Area End

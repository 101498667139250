
import React, { Component, useEffect, useState } from "react";
import {
  Dialog,
  Box,
  Grid,
  DialogContent,
  Typography,
  Divider,
  Button,
  TextField
} from "@material-ui/core";
import { withStyles, styled, makeStyles } from '@material-ui/styles'
import {Formik, Form, useField} from 'formik'

import { CloseIcon } from "../../blocks/postcreation/src/assets";
import { formatMoneyINR, formatMoneyUSD } from './utils'
import * as Yup from 'yup';
import {FormikSubmit} from './FormModal.web'

function calculatePeriod(startDate: any, endDate : any, period: any){
  var diffMonth = 1;
  let currentDate = new Date();
  if(startDate && endDate && period){
    for(let i = 1 ; i <= (parseInt(period.split(" ")[0])) ; i++){
      let start = new Date(`${startDate.split("/")[2]}-${startDate.split("/")[1]}-${startDate.split("/")[0]}`);
      start.setMonth(start.getMonth() + i);
      if(currentDate < start){
        if(diffMonth == 1){
          diffMonth = ((parseInt(period.split(" ")[0])) - i) + 1;
        }
      }
      console.log("new date", start, currentDate < start, currentDate)
    }
    // console.log("diffMonth",new Date(`${startDate.split("/")[2]}-${startDate.split("/")[1]}-${startDate.split("/")[0]}`),new Date(`${endDate.split("/")[2]}-${endDate.split("/")[1]}-${endDate.split("/")[0]}`), parseInt(period.split(" ")[0]))
    // var dt2 = new Date(`${endDate.split("/")[2]}-${endDate.split("/")[1]}-${endDate.split("/")[0]}`);
    // diffMonth =(dt2.getTime() - currentDate.getTime()) / 1000;
    // diffMonth /= (60 * 60 * 24 * 7 * 4);
    // diffMonth = diffMonth > parseInt(period.split(" ")[0]) ? parseInt(period.split(" ")[0]) : Math.abs(Math.ceil(diffMonth));
    // console.log("diffMonth",diffMonth,`${endDate.split("/")[2]}-${endDate.split("/")[1]}-${endDate.split("/")[0]}`)
    return diffMonth;
    // var months;
    // months = (parseInt(endDate.split("/")[2]) - startDate.getFullYear()) * 12;
    // months -= startDate.getMonth() + 1;
    // months += parseInt(endDate.split("/")[1]);
    // return months <= 0 ? 0 : months;
  }else{
    return parseInt(period.split(" ")[0]);
  }
}

export default function ChooseLicenseSubscribtionPopup(props: any){ 
    const { open, closepopup } = props;
    console.log({props, "ChooseLicense":""})
    const sub = {
        ...props.selectedSubscription.attributes, 
        ...props.selectedSubscription,
      }
    const period = calculatePeriod(sub.start_date, sub.end_date, sub.period)
    const [addPm, setAddPm] = useState("0");
    const [addInd, setAddInd] = useState("0");
    const addPmCost = {
      usd: (parseInt(addPm)||0)*sub.future_corp_usd*period,
      inr: (parseInt(addPm)||0)*sub.future_corpo_inr*period
    }
    const addIndCost = {
      usd: (parseInt(addInd)||0)*sub.future_individual_usd*period,
      inr: (parseInt(addInd)||0)*sub.future_individual_inr*period,
    }
    const handleSubmit = () => {
      if(props.onSubmit) {
        props.onSubmit({
          user_subscription_id: parseInt(sub.id),
          corporate_user_lice: parseInt(addPm)||0, 
          indivi_user_lice: parseInt(addInd)||0, 
          total_cost_inr: addPmCost.inr+ addIndCost.inr, 
          total_cost_usd: addPmCost.usd+ addIndCost.usd, 
        })
      }
    }
    const schema = Yup.object().shape({
      addPm: Yup.number().typeError("Must be a number")
.min(0,"Must be a positive number").label('Additional PM').required("Required"),
      addInd: Yup.number().typeError("Must be a number")
.min(0, "Must be a positive number").label('Additional Individual').required("Required"),
    })
    const initialValues = {
      addPm: '0',
      addInd: '0'
    }
    return (
      <Dialog open={open} onClose={closepopup} maxWidth="lg">
        <DialogContent>
        <Formik 
          validationSchema={schema} 
          initialValues={initialValues} 
          onSubmit={handleSubmit}>
          <Form translate="no">
          <OuterBox>
            <HeaderBox>
              <div>
                <TextHeading style={{fontWeight:"bold"}}>PURCHASE NOW</TextHeading>
              </div>
              <div onClick={closepopup}>
                <img
                  style={{ width: "30px", height: "30px" }}
                  src={CloseIcon}
                />
              </div>
            </HeaderBox>
            <Divider variant="fullWidth" />
            <TitleBox>
                <Typography>
                    EXISTING PACKAGE
                </Typography>
            </TitleBox>
            <ContetentBoxOne>
              <div>
                <TextHeading style={{fontWeight:"bold"}}>
                  {sub.package_name}<span> ({sub.package_role})</span>
                </TextHeading>
              </div>
              <div>
                <TextHeading style={{fontWeight:"bold"}}>{formatMoneyUSD(sub.total_cost_usd)} <span>({formatMoneyINR(sub.total_cost_inr)})</span></TextHeading>
              </div>
            </ContetentBoxOne>
            <ContetentBoxTwo>
              <div><TextSubHeading>Members Covered : <span style={{fontWeight:"bold"}}>{sub.member_covered}</span> </TextSubHeading></div>
              <div><TextSubHeading>Period :  <span style={{fontWeight:"bold"}}>{sub.period}</span></TextSubHeading></div>
              <div><TextSubHeading>Start Date :  <span style={{fontWeight:"bold"}}>{sub.start_date}</span></TextSubHeading></div>
              <div><TextSubHeading>End Date : <span style={{fontWeight:"bold"}}>{sub.end_date}</span> </TextSubHeading></div>
             

            </ContetentBoxTwo>
            <ContetentBoxThree>
            <div><TextSubHeading>PM/Corporate Licence Covered : <span style={{fontWeight:"bold",marginRight:"4px"}}>{sub.total_pm_corpo_licences ? sub.total_pm_corpo_licences : '-'}</span> </TextSubHeading></div>
            <div><TextSubHeading>Individual users covered : <span style={{fontWeight:"bold",marginRight:"4px"}}>{sub.total_individual_licences ? sub.total_individual_licences : '-'}</span> </TextSubHeading></div>
            <div><TextSubHeading>Amount paid : <span style={{fontWeight:"bold",marginRight:"4px"}}>${sub.total_cost_usd} / ₹{sub.total_cost_inr}</span> </TextSubHeading></div>
            </ContetentBoxThree>
            <DescBox>
              <FadedText>
              {sub.package_discription}
              </FadedText>
            </DescBox>
            <AdditionalLicenseContent>
              <AdditionalLicenseContentInner>
              <div style={{
                marginRight:"24px"
              }}><TextSubHeading style={{ fontWeight:"bold" }}>Add PM / Corporate employee licences</TextSubHeading></div>
              <div><LightText>({formatMoneyUSD(sub.future_corp_usd)} / {formatMoneyINR(sub.future_corpo_inr)} per licence per month ) </LightText></div>

              </AdditionalLicenseContentInner>
              

            </AdditionalLicenseContent>
            <AdditonalPMLicense>
                <Box>
                  <TextSubHeading style={{ fontWeight:"bold" }}>Charges per licence</TextSubHeading>
                  <Box  
                 style={{
                   width:"144px",
                   height:"44px",
                   border:"1px solid #EBEBEB",
                   borderRadius:"7px",
                   backgroundColor:"#FBFBFB",
                    marginTop:"15px",
                    alignItems:"center",
                    
                    display:"flex",
                    justifyContent:"center"
                 }}
                  >
                    <Typography><span style={{fontWeight:"bold",marginRight:"4px"}}>{formatMoneyUSD(sub.future_corp_usd)}</span>({formatMoneyINR(sub.future_corpo_inr)})</Typography>
                   

                  </Box>
                  
                   </Box>
                   <Box style={{
                     alignItems:"center",
                     justifyContent:"center",
                     marginTop:"30px"
                   }}>*</Box>
                   
                <Box>
                  <TextSubHeading style={{ fontWeight:"bold" }}> No of months </TextSubHeading>
                  <Box  
                 style={{
                   width:"144px",
                   height:"44px",
                   border:"1px solid #EBEBEB",
                   borderRadius:"7px",
                   backgroundColor:"#FBFBFB",
                   marginTop:"15px",
                   alignItems:"center",
                   display:"flex",
                   justifyContent:"center"
                 }}
                  >
                    <Typography>{period}</Typography>

                  </Box>
                 </Box>
                 <Box style={{
                     alignItems:"center",
                     justifyContent:"center",
                     marginTop:"30px"
                   }}>*</Box>
                <Box>
                  <TextSubHeading style={{ fontWeight:"bold" }}>No of members </TextSubHeading>
                  <Box  
                 style={{
                   // width:"144px",
                   // height:"44px",
                   // border:"1px solid #EBEBEB",
                   // borderRadius:"7px",
                   // backgroundColor:"#FBFBFB",
                   // marginTop:"15px",
                   alignItems:"center",
                   display:"flex",
                   justifyContent:"center"
                 }}
                  >
                    <FInput 
                      name="addPm"
                      onChange={(e: any) => setAddPm(e)}
                    />
                   

                  </Box>
                  </Box>
                  <Box style={{
                     alignItems:"center",
                     justifyContent:"center",
                     marginTop:"30px"
                   }}>=</Box>
                <Box>
                  <TextSubHeading style={{ fontWeight:"bold" }}>Variable Cost</TextSubHeading>
                  <Box  
                 style={{
                   width:"144px",
                   height:"44px",
                   border:"1px solid #EBEBEB",
                   borderRadius:"7px",
                   backgroundColor:"#FBFBFB",
                   marginTop:"15px",
                   display:"flex",
                   justifyContent:"center",
                   alignItems:"center"
                 }}
                  >
                    <Typography style={{textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}}>
                      <span style={{fontWeight:"bold",marginRight:"4px"}}>{formatMoneyUSD(addPmCost.usd)}</span>({formatMoneyINR(addPmCost.inr)})
                    </Typography>

                  </Box>
                  </Box>

            
              

            </AdditonalPMLicense>

            <AdditionalLicense>             <div style={{
                marginRight:"24px",
                marginTop:"12px"
              }}>
                <TextSubHeading style={{ fontWeight:"bold" }}>Add individual user licences</TextSubHeading></div>
              <div style={{ marginTop:"12px" }}><LightText>({formatMoneyUSD(sub.future_individual_usd)} / {formatMoneyINR(sub.future_individual_inr)} per licence per month )</LightText></div>

              
            </AdditionalLicense>
            <AdditonalCorporateLicense>             
                <Box>
                  <TextSubHeading style={{ fontWeight:"bold" }}>Charges per licence</TextSubHeading>
                  <Box  
                 style={{
                   width:"144px",
                   height:"44px",
                   border:"1px solid #EBEBEB",
                   borderRadius:"7px",
                   backgroundColor:"#FBFBFB",
                    marginTop:"15px",
                    alignItems:"center",
                    display:"flex",
                    justifyContent:"center"
                 }}
                  >
                   
                   <Typography><span style={{fontWeight:"bold",marginRight:"4px"}}>{formatMoneyUSD(sub.future_individual_usd)}</span>({formatMoneyINR(sub.future_individual_inr)})</Typography>
                  </Box>
                  
                   </Box>
                   <Box style={{
                     alignItems:"center",
                     justifyContent:"center",
                     marginTop:"30px"
                   }}>*</Box>
                <Box>
                  <TextSubHeading style={{ fontWeight:"bold" }}> No of months </TextSubHeading>
                  <Box  
                 style={{
                   width:"144px",
                   height:"44px",
                   border:"1px solid #EBEBEB",
                   borderRadius:"7px",
                   backgroundColor:"#FBFBFB",
                   marginTop:"15px",
                   alignItems:"center",
                   display:"flex",
                   justifyContent:"center"
                 }}
                  >
                    <Typography>{period}</Typography>

                  </Box>
                 </Box>
                 <Box style={{
                     alignItems:"center",
                     justifyContent:"center",
                     marginTop:"30px"
                   }}>*</Box>
                <Box>
                  <TextSubHeading style={{ fontWeight:"bold" }}>No of members </TextSubHeading>
                  <Box  
                 style={{
                   // width:"144px",
                   // height:"44px",
                   // border:"1px solid #EBEBEB",
                   // borderRadius:"7px",
                   // backgroundColor:"#FBFBFB",
                   // marginTop:"15px",
                   alignItems:"center",
                   display:"flex",
                   justifyContent:"center"
                 }}
                  >
                   
                  <FInput
                    name="addInd" 
                    onChange={
                      (e:any) => setAddInd(e)
                    }  
                  />
                  </Box>
                  </Box>
                  <Box style={{
                     alignItems:"center",
                     justifyContent:"center",
                     marginTop:"30px"
                   }}>=</Box>
                <Box>
                  <TextSubHeading style={{ fontWeight:"bold" }}>Variable Cost</TextSubHeading>
                  <Box  
                 style={{
                   width:"144px",
                   height:"44px",
                   border:"1px solid #EBEBEB",
                   borderRadius:"7px",
                   backgroundColor:"#FBFBFB",
                   marginTop:"15px", alignItems:"center",
                   display:"flex",
                   justifyContent:"center"
                 }}
                  >
                    <Typography style={{textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}}>
                      <span style={{fontWeight:"bold",marginRight:"4px"}}>{formatMoneyUSD(addIndCost.usd)}</span>({formatMoneyINR(addIndCost.inr)})
                    </Typography>
                   

                  </Box>
                  </Box>

            
              

            </AdditonalCorporateLicense>
            {/* Footer */}
            <FooterOne>
               <FooterOneInner>
                <FooterText>Additional Members :<span>{(parseInt(addPm)||0)+(parseInt(addInd)||0)}</span></FooterText></FooterOneInner>
                <Box
                style={{
                  display:"flex",
                 
                }}
                >
              <FooterText>Additional Variable Cost : <span style={{marginRight:"8px,",fontSize:"18px"}}>{formatMoneyUSD(addPmCost.usd+addIndCost.usd)} ({formatMoneyINR(addPmCost.inr+addIndCost.inr)})</span></FooterText>

                </Box>
               
              
              
            </FooterOne>
            <FooterTwo>
              <Box
               style={{
                 marginTop:"13px",
                 marginBottom:"15px"
               }}
              >
                {(addPm == '0' && addInd == '0') ?
                  <CardButton
                    disabled={true}
                    variant="contained" 
                    color="primary" 
                    disableElevation
                  >
                    PAY NOW
                  </CardButton>
                :
                <FormikSubmit
                  component={PayButton}
                  isDirty={true}
                  props={{color:"primary",disabledElevation:true,variant:"contained"}}
                >
                  Pay Now
                </FormikSubmit>
                }
                
              </Box>
            </FooterTwo>
              

          
          </OuterBox>
          </Form>
          </Formik>
        </DialogContent>
      </Dialog>
    );
  }

//*********** styles ***************//
const FadedText = styled(Typography)({
  color: "#717171cf",
  fontSize:"14px"
})
const TextHeading = styled(Typography)({
  color: "#000000",
  fontSize:"20px"
})
const TextSubHeading = styled(Typography)({
  color: "#000000",
  fontSize:"14px"
})
const LightText = styled(Typography)({
  color: "#515C51",
  fontSize:"14px"
})
const PayButton = styled(Button)({
  fontSize:"14px",
  borderRadius:"6px",
  width:"154px"
})
const FooterText = styled(Typography)({
  color: "#000000",
  fontSize:"14px",
  fontWeight:"bold"
 
 
})

const TextFieldChoosePackage = styled(TextField)({
 textDecoration:"!important none",
 border:0,
 outline:0,
 marginTop:"15px",
 fontFamily:"helvetica",
 "& > div > input": {
  "padding": "14px 14px",
  width: "80px"
 }
})

const CardButton = styled(Button)({
  width: "153px",
  height: "38px",
  // backgroundColor: "#009B07",
  // color: "#FFFFFF",
  marginTop: "1px",
  textTransform: "capitalize",
  fontWeight: 700
});

const OuterBox = styled(Box)({
  width: "700px",
  height: "670px",
  '@media screen and (max-width: 768px)': {
    width: "auto",
  }
});

const HeaderBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "8px",
})

const TitleBox = styled(Box)({
  display: "flex",
  justifyContent: "flex-start",
  marginBottom: "4px",
  marginTop:"8px",
})

const ContetentBoxOne = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  marginTop: "18px",
  '@media screen and (max-width: 520px)': {
    display: "unset",
  }
})

const ContetentBoxTwo = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  marginTop: "8px",
  '@media screen and (max-width: 520px)': {
    display: "unset",
  }
})

const ContetentBoxThree = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  marginTop: "8px",
  '@media screen and (max-width: 520px)': {
    display: "unset",
  }
})

const DescBox = styled(Box)({
  display: "flex",
  justifyContent: "flex-start",
  marginTop: "8px",
})

const AdditionalLicenseContent = styled(Box)({
  display: "flex",
  justifyContent: "flex-start",
  marginTop: "8px",
  '@media screen and (max-width: 520px)': {
    display: "unset",
  }
})

const AdditionalLicenseContentInner = styled(Box)({
  display: "flex",
  alignItems: "center",
  '@media screen and (max-width: 520px)': {
    display: "unset",
  }
})

const AdditionalLicense = styled(Box)({
  display: "flex",
  alignItems: "center",
  '@media screen and (max-width: 520px)': {
    display: "unset",
  }
})

const FooterOne = styled(Box)({
  display:"flex",
  justifyContent: "space-between",
  marginTop:"12px",
  '@media screen and (max-width: 520px)': {
    display: "unset",
  }
})

const AdditonalPMLicense = styled(Box)({
  display: "flex",
  justifyContent: "space-evenly",
  marginTop: "8px",
  border:"1.07178px solid #C0D3C0",
  borderRadius:"10px",
  height:"140px",
  alignItems:"center",
  overflow: "auto",
  '@media screen and (max-width: 680px)': {
    justifyContent: "unset",
  }
})

const AdditonalCorporateLicense = styled(Box)({
  display: "flex",
  justifyContent: "space-evenly",
  marginTop: "8px",
  border:"1.07178px solid #C0D3C0",
  borderRadius:"10px",
  height:"144px",
  alignItems:"center",
  overflow: "auto",
  '@media screen and (max-width: 680px)': {
    justifyContent: "unset",
  }
})

const FooterOneInner = styled(Box)({
  display:"flex",
  justifyContent:"space-between",
  '@media screen and (max-width: 520px)': {
    marginTop: "12px",
  }
})

const FooterTwo = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
})

const FInput = (props: any) => {
  const [field, meta, helpers] = useField(props.name)
  const onChange = (e: any) => {
    helpers.setTouched(true)
    helpers.setValue(e.target.value, true)
    if(props.onChange) props.onChange(e.target.value)
  }

  return <TextFieldChoosePackage
    variant="outlined"
    error={meta.touched&&!!meta.error} 
    value={field.value}
    onChange={onChange}
    helperText={meta.error} 
  />
}
import React from "react";
// Customizable Area Start
import TopNavBar from "../../../../components/src/TopNavbar";
import {
    Box, Grid, Typography, Button, Table,
    TableBody,
    TableContainer,
    TableHead,
    TableCell,
    TableRow,
    Paper,
    Tooltip,
    Avatar
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { styled } from '@material-ui/styles'
import LeftMenuWeb from "../../../../components/src/LeftMenuWeb";
import DeleteModal from '../../../../components/src/DeleteModal.web';
import { iconAdd } from '../../../JobListing2/src/assets';
import CancelIcon from '@material-ui/icons/Cancel';
import { toolTipImg, deleteImg, editImg, iconUpload, PdfIcon } from '../assets'
import { Formik, useField } from 'formik'
import FormModal, {
    InputRow,
    MInput,
    MCountedTextArea,
} from '../../../../components/src/FormModal.web'
import RoundedButton from '../../../../components/src/RoundedButton.web'
import OverlayLoader from '../../../../components/src/Loader.web'
import UploadButton from '../../../../components/src/UploadButtonnew.web'

import BaselineReportingController, {
    Props,
} from "./BaselineReportingController";
// @ts-ignore
import classes from './ReferencePolicy.module.css'
import UserContext from "../../../../components/src/UserProvider";
// Customizable Area End
export default class ReferencePolicy extends BaselineReportingController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    createPolicy() {
        const initialValues = {
            policy_name: this.state?.editPolicyData?.attributes?.name || '',
            description: this.state?.editPolicyData?.attributes?.description || '',
            attachments: this.state?.editPolicyData?.attributes?.policy_documents || [],
        }
        let attachmentName = this.state?.editPolicyData?.attributes?.policy_documents
        if (this.state?.editPolicyData != null){
            console.log("state attachments", this.state?.editPolicyData?.attributes?.policy_documents);
            attachmentName = this.state?.editPolicyData?.attributes?.policy_documents?.map((j: any) => j.filename).join(', ')
          }
          console.log("form initial value ", initialValues)

        if (this.state.modal !== 'create') return <></>
        else
            return <Formik initialValues={initialValues} onSubmit={this.handleCreateFormSubmit} validationSchema={this.validationSchema}>
                <div className={classes.modalTopCls}>
                    <FormModal isDirty={true} headerTitle={<CreateHeader>{this.state?.editPolicyData != null ? "Edit Policy" : "Create New Policy"}</CreateHeader>} submitLabel={this.state?.editPolicyData != null ? "Save" : "Submit"} onClose={this.hideModal} onSubmit={this.handleCreateFormSubmit}>
                        <InputRow label="Policy Name*" input={<><MInput name="policy_name" placeholder="Enter Policy Name" /></>} />
                        {/* @ts-ignore */}
                        <InputRow label="Description*" input={<MCountedTextArea class={classes.rowTextStyleClass} name="description" />} />
                        {console.log(initialValues.attachments, initialValues.description, "^^^^^^")}
                        <div style={{ margin: '10px 0' }}>
                            <UploadButton
                                onDelete={this.removeAttachments}
                                name="attachments"
                                label="Upload Attachment"
                                setFileInState={this.setFilesArray}
                                multiple
                                filetype=".pdf"
                            />
                        </div>
                        
                        <div style={{display:'flex', flexDirection:'row', alignItems:'center', width:'100%', border:'1px solid gray', borderRadius: '5px', height:'45px',}}>
                        <Avatar style={{marginLeft: '20px', width: 32.26, height: 31.87, marginRight: '10px' }} 
                        src={this.context.profile_pic} />
                        {this.context.first_name+" "+this.context.last_name}
                        </div>
                    </FormModal>
                </div>
            </Formik>
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const createPolicy = this.createPolicy()

        const { navigation } = this.props;
        const { allPolicies } = this.state;
        console.log("allPolicies", allPolicies);
        const path = this?.context?.user_type == "licensed_user" ? 'Corporatepermission' : 'ESGJourney'
        // Customizable Area End

        return (
            // Customizable Area Start
            <>
                {createPolicy}
                <MainBox>
                    {this.state?.deletePoicy && <DeleteModal label="Are you sure you want to delete this Policy?" onClose={this.closeModal} onSubmit={this.onDeletePolicy(this.state?.deletePolicyId)} />}
                    <Box>
                        <TopNavBar navigation={this.props.navigation} id={""} />
                    </Box>

                    <OuterGrid container spacing={3}>
                        <LeftGrid item xs={12} sm={12} md={3} lg={3}>
                            <Box >
                                <LeftMenuWeb navigation={this.props.navigation} id={""} />
                            </Box>
                        </LeftGrid>
                        <MiddleGrid item xs={12} sm={12} md={6} lg={6} >
                            <SurveyCardWrapper>
                            <OverlayLoader loading={this.state.loading} />
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <CoinsHead>
                                    <span onClick={() => this.props.navigation.navigate(path)} style={{ margin: "0px 0px 5px 12px", cursor: "pointer", color: 'black' }} >ESG Journey</span>
                                    <div onClick={() => this.props.navigation.navigate(path)} style={{ margin: "0px 0px 5px 12px", color: 'black' }}  >
                                        <ArrowBackIosIcon />
                                    </div>
                                        <Typography variant='h5' style={{ fontWeight: 'bold', color: 'black' }} >REFERENCE POLICIES</Typography>
                                    </CoinsHead>
                                    <RoundedButtonBase
                                        id="newPolicyBtn"
                                        onClick={this.openModal}
                                        variant="contained"
                                        disableElevation
                                        disabled={this.state?.isdisabled}
                                        startIcon={<img src={iconAdd} width={30} style={{ marginLeft: '-5px' }} />}>
                                        New Policy
                                    </RoundedButtonBase>
                                </div>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ fontWeight: 'bold', color: 'black' }}>S No.</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', color: 'black' }}>Policy Name</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', color: 'black' }}>Description</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', color: 'black' }}>Attachments</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', color: 'black' }}>Created By</TableCell>
                                                <TableCell style={{ fontWeight: 'bold', color: 'black' }}>Last Updated</TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {allPolicies?.map((row: any, i: number) => (
                                                <>
                                                    <TableRow>
                                                        <TableCell>{i + 1}</TableCell>
                                                        <TableCell>{row?.attributes?.name.substring(0,15)}</TableCell>
                                                        <TableCell><TooltipWrapper style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                                                            {row?.attributes?.description.substring(0,22)}
                                                            <Tooltip title={<div style={{height:'150px', width:'120px',color:'#000', margin: "0px"}}>
                                                                {row?.attributes?.description}
                                                                </div>}>
                                                                {<BtnImg src={toolTipImg} style={{ marginLeft: '10px' }} />}
                                                            </Tooltip>
                                                            </TooltipWrapper>
                                                        </TableCell>
                                                        <TableCell>
                                                                {row?.attributes?.policy_documents?.map((data:any, i:number)=> {
                                                                    return(
                                                                    <WrapDoc style={{display: 'flex', flexDirection: 'row'}}>
                                                                        {data?.filename.substring(0,13)}
                                                                        <img src={PdfIcon} onClick={()=> window.open(data.url)}/>
                                                                    </WrapDoc>      )         
                                                                })}
                                                        </TableCell>
                                                        <TableCell>
                                                            <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                                                            <Avatar style={{ width: 32.26, height: 31.87, marginRight: '10px' }} 
                                                            src={this.context.profile_pic} onClick={() => navigation.navigate(``)} />
                                                            {row?.attributes?.created_by.first_name+ " "+row?.attributes?.created_by.last_name}
                                                            </div>
                                                        </TableCell>
                                                        <TableCell>{row?.attributes?.updated_at}</TableCell>
                                                        <TableCell>{<BtnImg id="editBtn" src={editImg} onClick={this.handleEditTemplate(row?.id)} />}</TableCell>
                                                        <TableCell>{<BtnImg id="deleteBtn" src={deleteImg} onClick={this.deletePolicy(row?.id)} />}</TableCell>
                                                    </TableRow>
                                                </>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </SurveyCardWrapper>
                        </MiddleGrid>
                    </OuterGrid>

                </MainBox>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
BaselineReportingController.contextType = UserContext

const MainBox = styled(Box)({
    backgroundColor: "#edebeb",
    display: "flex",
    flexWrap: 'wrap',
    flexDirection: "column",
    paddingBottom: "20px",
    overflowX: "hidden",
})

const OuterGrid = styled(Grid)({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
})

const LeftGrid = styled(Grid)({
    maxWidth: '300px',
    '@media screen and (max-width: 985px)': {
        maxWidth: '200px',
    },
    '@media screen and (max-width: 768px)': {
        display: 'none',
        visibility: 'hidden',
    },
})

const MiddleGrid = styled(Grid)({
    width: 'calc(100vw - 300px)',
    maxWidth: 'unset',
    flexBasis: 'unset',
    justifyContent: 'center',
    '@media screen and (max-width: 768px)': {
        width: '100%'
    }
})

const CoinsHead = styled('div')({
    display: 'flex',
    alignItems: 'center',
    color: 'gray',
    margin: '40px 0px 30px 12px',
    fontSize: '1rem',
    fontFamily: 'HelveticaNeue',
    fontWeight: 500,
    '@media screen and (max-width: 768px)': {
        margin: '20px 0px 10px 12px',
    },
})

const RoundedButtonBase = styled(Button)({
    backgroundColor: '#009c05',
    color: 'white',
    borderRadius: '20px',
    '&:hover': {
        backgroundColor: '#009c05',
    }
})

const BtnImg = styled('img')({
    width: 21
})

const WrapDoc = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    '& img': {
        width: '20px',
        marginLeft: '2px',
    }
})

const SurveyCardWrapper = styled(Box)({
    width: '95%',
    height: 'auto',
    // marginTop: '30px',
    marginRight: '20px',
    padding: '20px',
})

const CreateHeader = styled('div')({
    fontSize: '24px',
    fontWeight: 500,
    fontFamily: 'HelveticaNeue'
})

const TooltipWrapper = styled('div')({
    '& .MuiTooltip-tooltip': {
        width: 50,
        height: 50,
        backgroundColor: '#fff'
    }
})


// Customizable Area End

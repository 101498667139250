import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  Dimensions,
  Platform
} from "react-native";
import { useField, } from 'formik'

import { Box, Grid, Typography, InputBase, Chip, Modal, Divider, IconButton, Button, TextField, FormControl, Select, MenuItem, Checkbox, ListItemText, } from '@material-ui/core';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import { Autocomplete } from '@material-ui/lab';
import { styled } from '@material-ui/styles'
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";

// Customizable Area End

const viewportHeight = Dimensions.get('window').height;
const viewportWidth = Dimensions.get('window').width;

import MapView from 'react-native-maps';

import MapsController, {
  Props,
  configJSON
} from "./MapsController";


export default class Maps extends MapsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    // @ts-ignore
    if (this.props.input)
      return <FormikTextField
        // style={{fontWeight: "bold", marginLeft: "10px", color: "#4a4949"}}
        name="location"
        placeholder="Search Location"
        seed={this.state.seed}
        style={{ ...this.props?.autoCompleteStyles }}
        currentLocation={this.state.currentLocation}
        {...this.props}
        InputProps={{
          disableUnderline: true,
          endAdornment: (
            <IconButton disableRipple disableTouchRipple disableFocusRipple style={{ backgroundColor: 'transparent',padding:'7px' }} onClick={this.getCurrentLocation}>
              <MyLocationIcon style={{ color: "#0F68F0" }} />
              <Typography variant="subtitle2" gutterBottom style={{
                fontWeight: "normal",
                color: "#0F68F0",
                marginTop: "5px",
                marginLeft: "5px",
                display: "block",
                
              }}
              >
                {this.state.loading ? "Loading..." : "Current Location"}
              </Typography>
            </IconButton>
          )
        }}
      />

    return (
      <MapView
        style={styles.map}
        initialRegion={{
          latitude: 37.78825,
          longitude: -122.4324,
          latitudeDelta: 0.0922,
          longitudeDelta: 0.0421,
        }}
      />

    );
  }
  // Customizable Area End
}

// Customizable Area Start
const styles = StyleSheet.create({
  map: {
    width: viewportWidth,
    height: viewportHeight,
    position: 'absolute',
    top: 0,
    bottom: 0
  }
});
const AutocompleteStyled = styled(Autocomplete)({
    '& .MuiAutocomplete-inputRoot ': {
      '& > fieldset': {
      }
    },
    '& > div > div': {
      padding: '0 !important',
    },
  })

export const FormikTextField = (props: any) => {
  const [field, meta, helpers] = useField(props.name)
  const {
    ready,
    value,
    suggestions: { status, data, loading },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
      types: ['(cities)']
    },
    debounce: 300,
  });

  React.useEffect(() => {
    if(props.currentLocation){
    helpers.setValue(props.currentLocation || "")
    setValue(props.currentLocation || "", false)
  }
  }, [props.currentLocation, props.seed])

  const handleChange = (e: any, val: any) => {
    console.log({ e: e?.target?.value, val })
    if (e)
      setValue(e.target.value)
  }
  const handleValueChange = (e: any, val: any) => {
    console.log({ e2: e?.target?.value, val })

    if (e)
      helpers.setValue(val?.description || val || "")
  }

  
  return <>
    <AutocompleteStyled
      options={data}
      defaultValue={props.currentLocation ? props.currentLocation : field.value}
      onInputChange={handleChange}
      onChange={handleValueChange}
      value={field.value}
      loading={loading}
      getOptionLabel={(option: any) => {
        if (typeof option === 'string') return option
        return `${option?.description} `
      }}
      renderInput={(params: any) => <StyledTextField
        {...field}
        {...props}
        {...params}
        value={value || ""}
        error={!!(meta.touched && meta.error)}
        onChange={handleChange}
        variant="outlined"
        InputProps={{
          ...params.InputProps,
          disabled: !ready,
          ...props.InputProps,
          role: 'presentation'
        }}
      />}

    />
    <Typography variant="subtitle2" component='span' color="error">
      {(meta.touched && meta.error) ? meta.error : ' '}
    </Typography>
  </>
}




const StyledTextField = styled(TextField)({
  padding: 0,
  '& input + fieldset': {
    borderColor: '#E0E0E0',
  },
  '& > div > input': {
    paddingLeft: '15px !important'
  },
}) as typeof TextField

// fontSize:14, 
//                borderRadius:"5px",
//                height: "45px",
//                textAlign: "center",
//                justifyContent: "center",
// Customizable Area End

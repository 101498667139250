import React from "react";

// Customizable Area Start
import {
  Grid,
  Divider,
  Modal,
  Backdrop,
  MenuItem,
  Box,
  Button,
  InputAdornment
} from '@material-ui/core'
import { styled } from '@material-ui/styles'
import { Formik } from 'formik'
import FormModal, { InputRow, MInput, MSelect, MCountedTextArea, MDatePicker } from './Components.web'
import UserContext from '../../../components/src/UserProvider'
import Flex from '../../../components/src/Flex.web'
import PreferenceCatSubCat from '../../categoriessubcategories/src/PreferenceCatSubCat.web'
// @ts-ignore
import classes from './CfCourseListing5Web.module.css'

// Customizable Area End

import CourseEditController, {
  Props,
  
} from "./CourseEditController";
export default class CourseEdit extends CourseEditController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { navigation } = this.props
    let { courseData } = this.state
    const user = this.context
    const course_id_1 = courseData?.course_id_1 || ''
    const course_id_2 = courseData?.course_id_2 || ''
    const initialValues = { course_id_1, course_id_2, 
      course_name: courseData?.course_name || '', 
      course_type: courseData?.course_type ? (courseData?.course_type - 1 ) : -1,
      organization: courseData?.organization || '', 
      price: courseData?.price || '', 
      price_Usd: courseData?.price_Usd || '', 
      coin_discount: courseData?.coin_discount || '', 
      description: courseData?.description || '',
      equivalent_coins: courseData?.equivalent_coins || '',
      mode: courseData?.mode ? (courseData?.mode - 1) : -1,  
      start_date: courseData?.start_date ||  null, 
      end_date: courseData?.end_date || null, 
      eligibility: courseData?.eligibility || '', 
      preference_ids: courseData?.preference_id || [], 
      category_ids: courseData?.category_id || [], 
      sub_category_ids: courseData?.sub_category_id || []
    }
    // Customizable Area End
    return (
      // Customizable Area Start
      
        <Wrapper>
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
            open={this.state.openModal}
            //onClose={}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
    
            <Box
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                background: '#fff',
                borderRadius: 8,
                width: '700px',
                position: "relative",
            }} className="schedule_modal">
                <Grid container spacing={0}>
                <ModelBody item xs={12}>

                <Formik initialValues={initialValues} enableReinitialize={ true } onSubmit={this.handleEditFormSubmit} validationSchema={this.editFormSchema} >
                    {({ values, errors }: any) => (
                    <div className={ classes.modalTopCls }>
                    <FormModal headerTitle={<b>Update Course</b>} isDirty={true} onClose={this.onCloseModal} onSubmit={this.handleEditFormSubmit} submitLabel="Update">
                    
                        <InputRow label="Course ID*" input={
                        <IDWrapper style={{ display: 'flex', gap: '20px' }}>
                            <Flex2>
                            <MInput name="course_id_1" disabled placeholder="Course ID" />
                            </Flex2>
                            <Flex2>
                            <MInput name="course_id_2" disabled placeholder="Course ID" />
                            </Flex2>
                        </IDWrapper>
                        } />
                        <InputRow label="Course Name*" input={
                            <MInput name="course_name" placeholder="Enter course name"/>
                        } />

                        <PreferenceCatSubCat {...this.props} />

                        <InputRow label="Course Type*" input={
                        <MSelect defaultValue={ -1 } name="course_type" placeholder="Course" style={{ paddingLeft: '10px'}}>
                            <MenuItem disabled value={-1}>Select</MenuItem>
                            <MenuItem value={0}>Degree</MenuItem>
                            <MenuItem value={1}>Diploma</MenuItem>
                            <MenuItem value={2}>Certification</MenuItem>
                            <MenuItem value={3}>Short Team</MenuItem>
                            <MenuItem value={4}>Executive Training</MenuItem>
                        </MSelect>
                        } />
                        <InputRow label="Orgnaization*" input={
                            <MInput name="organization" placeholder="Enter organization"/>
                        } />
                        <InputRow label="Price*" rowClass={classes.rowPriceStyleClass} labelClass={classes.labelStyleClass} input={
                        <div>
                        <div >
                        <MInput name="price_Usd"  {...dollarFieldProps}/>
                        </div>
                        <div style={{ marginTop: '15px' }}>
                        <MInput name="price" {...rupeeFieldProps}/>
                        </div>
                        </div>
                        } />
                        <InputRow label="Discount % *" input={
                            <MInput name="coin_discount" placeholder="0"/>
                        } />
                        <InputRow label="Equivalent Coins*" input={
                            <MInput name="equivalent_coins" placeholder="0" disabled={values.coin_discount <= 0}/>
                        } />
                        <InputRow label="Description*" input={
                            <MCountedTextArea name="description" placeholder="Description" class={classes.rowTextStyleClass}/>
                        } />
                        <InputRow label="Mode*" input={
                        <MSelect defaultValue={ -1 } name="mode" placeholder="Course" style={{ paddingLeft: '10px'}}>
                            <MenuItem disabled value={-1}>Select</MenuItem>
                            <MenuItem value={0}>Online - live</MenuItem>
                            <MenuItem value={1}>Online - SelfPaced</MenuItem>
                            <MenuItem value={2}>Offline</MenuItem>
                        </MSelect>
                        } />
                        <InputRow label="Start Date*" input={
                            <MDatePicker  name="start_date" InputProps={{placeholder: "Enter Date"}} maxDate={this.state.endCourseDate || null} minDate={this.state.startCourseDate || null} onChange={this.startDateChange}/>
                        } />
                        <InputRow label="End Date*" input={
                            <MDatePicker name="end_date" InputProps={{placeholder: "Enter Date"}} minDate={this.state.startCourseDate || null} onChange={this.endDateChange}/>
                        } />
                        <InputRow label="Eligibility*" rowClass={classes.rowElStyleClass} labelClass={classes.labelStyleClass} input={
                            <MInput name="eligibility" placeholder="Enter eligibility" />
                        } />
                    </FormModal>
                    </div>
                      )}
                    </Formik>

                </ModelBody>

                </Grid>
            </Box>
        </Modal >`
        </Wrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
CourseEditController.contextType = UserContext
const dollarFieldProps = {
    placeholder: "Enter",
    InputProps: {
      startAdornment: <InputAdornment position="start" style={{ paddingLeft: '6px' }}>$ <Divider style={{ marginLeft: '15px', border: '1px solid #f1f1f1', height: '44px'}} /></InputAdornment> 
    }
  }
  
  const rupeeFieldProps = {
    placeholder: "Enter",
    InputProps: {
      startAdornment: <InputAdornment position="start" style={{ paddingLeft: '6px' }}>₹ <Divider style={{ marginLeft: '15px', border: '1px solid #f1f1f1', height: '44px'}} /></InputAdornment>
    }
  }
  
const ModelBody = styled(Grid)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'unset',
  flexDirection: 'column',
  padding: '0px'
})
const CloseButton = styled(Button)({
  position: 'absolute',
  top: '-10px',
  right: '-50px'
})
const Wrapper = styled('div')({
  zIndex: 1010,
  minWidth: '100vw',
  minHeight: '100vh',
  background: 'rgba(0,0,0,0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const IDWrapper = styled('div')({
  display: 'flex', 
  gap: '5px',
  '@media screen and (max-width: 480px)': {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  }
})

const Flex2 = styled(Flex)({
  flex: 1,
  '@media screen and (max-width: 480px)': {
    flex: 'none',
    width: '100%',
  }
})

// Customizable Area End

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from '../../../framework/src/Utilities';
import UserContext, { User } from "../../../components/src/UserProvider";
import React, { Context } from 'react'
import { toast } from 'react-toastify';
import { sendAPIRequest } from '../../../components/src/utils';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtSavedValue: string;
  // Customizable Area Start
  loading: boolean;
  cfCalculatorReportList: Array<any>;
  total_pages: number;
  current_page: number;
  searchQuery: string;
  filterFromDate?: Date | null;
  filterToDate?: Date | null;
  selectedDate?: boolean;
  filterMenuPosition: {
    top: number,
    left: number,
  } | undefined;
  filterByAll: boolean;
  filterByStatus: boolean;
  filterByPrefrence: boolean;
  showModal: string;
  isdisabled: boolean;
  editData: any;
  model_name: string;
  grModel:boolean,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class StakeholderProfileFormController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  static contextType?: Context<User> = UserContext;
  path = ''
  token = ''
  getGhgReportsCallId = ''
  getGhgReportFilterCallId = ''
  filterRef?: any
  updateGHGReportTableStatus=''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage),

      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      txtSavedValue: '',
      // Customizable Area Start
      loading: true,
      cfCalculatorReportList: [],
      total_pages: 1,
      current_page: 1,
      searchQuery: '',
      filterFromDate: null,
      filterToDate: null,
      selectedDate: false,
      filterMenuPosition: undefined,
      filterByAll: false,
      filterByStatus: false,
      filterByPrefrence: false,
      showModal: '',
      isdisabled: false,
      editData: {},
      model_name:'',
      grModel:false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.setState({ loading: true });
    this.path = this.context.user_type == "licensed_user" ? 'Corporatepermission' : 'ESGJourney'
    let authTokenReq = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(authTokenReq);
    const authTokenData = await getStorageData('authToken')
    const ability_type = await getStorageData('ability_type')
    const abilityType = this.props?.navigation?.history?.location?.state?.state?.ability_type;
    if(abilityType == "read" || ability_type == 'read'){
      this.setState({isdisabled:true})
    }
    this.token = authTokenData;
    this.getReportTableData()
    this.filterRef = React.createRef()
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && responseJson.error) {
        this.parseApiErrorResponse(responseJson.error);
        this.parseApiCatchErrorResponse(responseJson.error);
        toast.error(responseJson.error);
        this.setState({ loading: false });
      }
      else if (responseJson) {
        if (apiRequestCallId === this.getGhgReportsCallId) {
          this.setReportTableDate(responseJson)
        }
        if (apiRequestCallId === this.getGhgReportFilterCallId) {
          this.setReportTableDate(responseJson)
        }
        if (apiRequestCallId === this.updateGHGReportTableStatus){
          this.setState({ showModal: '',loading: true },()=>{
            this.getReportTableData()
          })
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  setReportTableDate(responseJson: any) {
    const { reports } = responseJson

    this.setState({
      total_pages: reports?.meta?.pagination?.total_pages,
      current_page: reports?.meta?.pagination?.current_page,
      loading: false,
      cfCalculatorReportList: reports?.data || [],
      model_name: reports?.model_name
    });
  }

  getReportTableData = () => {
    this.getGhgReportsCallId = sendAPIRequest(`${configJSON.getGhgReportsTable}?page=${this.state.current_page}&per_page=${20}&search_query=${this.state.searchQuery}`, {
      method: 'GET',
      headers: { token: this.token }
    })
  }

  getReportFilterTableData = () => {
    this.getGhgReportFilterCallId = sendAPIRequest(`${configJSON.getGhgReportFilterTable}?page=${this.state.current_page}&per_page=${20}&search_query=${this.state.searchQuery}&from_date=${this.state.filterFromDate}&to_date=${this.state.filterToDate}`, {
      method: 'GET',
      headers: { token: this.token }
    })
  }

  showPopup() {
    toast.error("No Report Found")
  }

  handlePageChange = (event: any, page: number) => {
    this.setState({ current_page: page },
      () => {
        if (this.state.filterFromDate && this.state.filterToDate) {
          this.getReportFilterTableData()
        } else {
          this.getReportTableData()
        }
      });
  }

  onFilterQueryChange = (searchQuery: string) => {
    this.setState({ loading: true, current_page: 1, searchQuery }, () => this.getReportTableData())
  }

  handleToDateChange = (date: any) => {
    this.setState({ ...this.getBlankFilters(), filterToDate: date, filterFromDate: this.state.filterFromDate })
  }

  handleFromDateChange = (date: any) => {
    this.setState({ ...this.getBlankFilters(), filterFromDate: date, filterToDate: this.state.filterToDate })
  }

  handleDateInputBlur = () => (this.setState({ selectedDate: false }))

  handleDateInputFocus = () => {
    this.setState({ selectedDate: true })
  }


  handleDateFilterCancel = () => {
    this.setState({ filterMenuPosition: undefined })
  }

  handleDateFilterSubmit = () => {
    this.setState({ filterMenuPosition: undefined, current_page: 1, loading: true }, () => this.getReportFilterTableData())
  }

  clearFilters = (e: React.MouseEvent) => {
    e?.stopPropagation()
    this.setState({
      filterByAll: false,
      filterFromDate: null,
      filterToDate: null,
      current_page: 1
    }, () => this.getReportTableData())
  }

  filterText = () => {
    let filterText = "";
    if (this.state.filterByAll) filterText += "All"
    if (this.state.filterFromDate || this.state.filterToDate) {
      filterText += `Date(${this.state.filterFromDate?.toISOString()?.slice(0, 10) || '-'} to ${this.state.filterToDate?.toISOString()?.slice(0, 10) || '-'} ) `
    }
    return filterText
  }

  handleFilterMenuOpen = () => {
    if (!this.filterRef) return;
    const box = this.filterRef?.current?.getBoundingClientRect();
    this.setState({
      filterMenuPosition: {
        top: box?.bottom,
        left: box?.left + 20
      }
    })
  }

  toggleFilterByAll = (value: string) => {
    const filters = this.getBlankFilters()
    filters['filterByAll'] = !this.state.filterByAll
    this.setState({ ...filters, current_page: 1, filterMenuPosition: undefined }, () => {
      this.filterText()
      this.getReportTableData()
    })
    this.handleFilterMenuClose()
  }

  handleFilterMenuClose = () => {
    this.setState({ filterMenuPosition: undefined })
  }

  getBlankFilters = () => {
    return {
      filterToDate: null,
      filterByAll: false,
      filterFromDate: null,
    }
  }

  getNavigationMessage = (route: string, props: any, payload?: any) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);

    message.addData(getName(MessageEnum.NavigationPropsMessage), props);

    this.send(message);
  }

  handleEditStatus = async (data: any) => {
    this.setState({ showModal: 'create', editData: data })
  }

  handleSubmit = async (data: any) => {
    let verifiedStatus = data.verified == 'true' ? true : false
    let updatedBody = {
      "report": {
        "model_name": this.state.editData.model_name,
        "id": this.state.editData.id,
        "data": {
          "verified": verifiedStatus
        }
      }
    }
    this.handleCreateQuestion(updatedBody)
   
  }
  async handleCreateQuestion(body:any): Promise<void> {
    let token = this.token    
    this.updateGHGReportTableStatus = sendAPIRequest(configJSON.updateGHGReportTableStatus, {
      method: 'PUT',
      headers: { token, "Content-Type": "application/json"},
      body: body,
    });
    }
    grModelOpen=()=>{
      this.setState({ grModel: !this.state.grModel })
    }
  // Customizable Area End
}

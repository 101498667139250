import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
//@ts-ignore
import { Context } from "react";
import UserContext, { User } from "../../../components/src/UserProvider";
import { sendAPIRequest } from '../../../components/src/utils';
import { toast } from 'react-toastify';
import { GetUsersAPIResponse,UserListData } from './types'
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
const QuickBlox = require('quickblox/quickblox.min').QuickBlox;
const QB = new QuickBlox();
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  closeModal?:()=>void;
  showChatMobileView?: any;
  onCloseSidebar?: any;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  drawerOpen: boolean,
  token: string;
  errorMsg: string;
  loading: boolean;
  mobileMoreAnchorEl:any;
  profile_pic:any;
  first_name:string;
  last_name:string;
  about:string;
  isInduMessage:boolean;
  MessageUSerID:any;
  UserMessageList:any;
  userReply:any;
  isAddMessageList:any;
  users:any[];
  isFormInitialized:boolean;
  defaultUsers:any[];
  filesArray:any[];
  submitRequestCallId?:string;
  respondRequestCallId?:string;
  userQuery:any;
  userPagination?:any;
  messageData:any;
  isRecallAPI?:boolean;
  MessagesList:any;
  DialogDetails:any;
  QBuserDetails:any;
  openCommentReplyEmoji:boolean;
  IsunbletoLoad:boolean;
  isserach:boolean;
  serachvalue:boolean;
  MessagesListS:any;
  viewChat:any;
  totalUnreadMessageCount:any;
  userLoading:boolean;
  userString:string;
  // Customizable Area End
}
interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChatMenuController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiGetQueryStrinurl: string = "";
  
  dashboardApiCallId: string = "";
  apiDashboardItemCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    this.subScribedMessages = [
      // Customizable Area Start

      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CorpstageCoinsUpdateMessage),
      getName(MessageEnum.CorpstageCoinsNoMessage)
      // Customizable Area End

    ];

    this.state = {
      // Customizable Area Start
      drawerOpen: false,
      errorMsg: "",
      token: "",
      loading: false,
      mobileMoreAnchorEl:null,
      profile_pic:'',
      first_name:'',
      last_name:'',
      about:'',
      isInduMessage: false,
      MessageUSerID: '',
      UserMessageList: [],
      userReply: "",
      isAddMessageList: false,
      isFormInitialized: false,
      users: [],
      defaultUsers: [],
      filesArray: [],
      userQuery: "",
      messageData: "",
      isRecallAPI: false,
      MessagesList: [],
      DialogDetails: "",
      QBuserDetails: "",
      openCommentReplyEmoji: false,
      IsunbletoLoad: false,
      isserach:false,
      serachvalue:false,
      MessagesListS: [],
      viewChat: window.screen.width < 768 ? true : false,
      totalUnreadMessageCount: 0,
      userLoading:false,
      userString:'',
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
 
    let authToken = await getStorageData("authToken")
    const userData = await getStorageData('user') ||'';
        let profile_pic = userData ? JSON.parse(userData).profile_pic : "";
    this.setState({userString:userData, profile_pic:profile_pic});
    if (authToken == null) {
      this.props.navigation.navigate('Home')
    }
    this.onInitialized()
    this.timeOut(2000)
    this.quickBlocksInit();
   
    // Customizable Area End
  }

  // Customizable Area Start
  quickBlocksInit = async() => {
    const userData = await getStorageData('user');
    let currentUser: any = JSON.parse(userData);
    let qb_login = currentUser.email;
    let qb_pass = currentUser.qb_pass;

    if (qb_login && qb_pass) {
      const QB_AUTH_KEY = configJSON.QuibloxCred.QUICKBLOX_AUTH_KEY;
      const QB_APPLICATION_ID = configJSON.QuibloxCred.QUICKBLOX_APPLICATION_ID;
      const QB_AUTH_SECRET = configJSON.QuibloxCred.QUICKBLOX_AUTH_SECRET;
      const QB_ACCOUNT_KEY = configJSON.QuibloxCred.QUICKBLOX_ACCOUNT_KEY;

      QB.init(QB_APPLICATION_ID, QB_AUTH_KEY, QB_AUTH_SECRET, QB_ACCOUNT_KEY);

      this.timeOut(3000)
      QB.createSession( (error: any, result: any) => (this.sessionTimeOut(error, qb_login,qb_pass)));

    } else {
      this.setState({ IsunbletoLoad: true });
    }
  }

  sessionTimeOut (error: any, qb_login:any,qb_pass:any) {
    let thisvar = this
    if (error) {
      thisvar.setState({ IsunbletoLoad: true });
      return true;
    }
    thisvar.timeOut(3000)
    thisvar.quickBloxLogin(qb_login,qb_pass);
  }

  quickBloxLogin = (qb_login: string, qb_pass: string) => {
    let params = { login: qb_login, password: qb_pass };
    
    QB.login(params, (error: any, result: any) => {
      if (error) {
        toast.error("In Chat Section "+error.message.errors.base[0]);
        this.setState({ IsunbletoLoad: true });
      } else {
        const userCredentials = {
          userId: result.id,
          password: qb_pass
        };
        QB.chat.connect(userCredentials);
        this.setState({ QBuserDetails: result });
        this.getContactlist();
        QB.chat.onMessageListener = this.onMessage;
      }
    });
  };

  timeOut = (ms:number) => new Promise((resolve) => setTimeout(resolve, ms));

  // Customizable Area End
  
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      runEngine.debugLog("API Message Received", message);

      if (responseJson && responseJson.errors) {
        this.parseApiErrorResponse(responseJson.errors);
        this.parseApiCatchErrorResponse(responseJson.errors);
      } else {
        switch (apiRequestCallId) {
          case this.apiGetAllUsersCallId:
            this.handleGetUsersAPIResponse(responseJson);
            break;

          case this.userPaginateCallId:
            this.handlePaginationResponse(responseJson);
            break;

          case this.userSearchCallId:
            this.handlePaginationResponse(responseJson, true);
            break;

          default:
            break;
    }
    }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handlePaginationResponse = (responseJson: any, clearUsers = false) => {
    if (responseJson && Array.isArray(responseJson.data)) {
      const users = responseJson.data.map((item: any) => item.attributes);
      if (this.state) {
        this.setState({
          userPagination: responseJson.meta.pagination,
          users: clearUsers ? users : this.state.users.concat(users),
          userLoading:false
        });
      }
    }
  }

  static contextType?: Context<User> = UserContext;
  apiGetAllUsersCallId = '';
  userPaginateCallId = '';
  userSearchCallId= '';
  getLikeForProjectCallId = '';
  fileUpload = "";
  getAllViewersCallId = "";


  initialType = [];
  initialValues = {
    showResponce: false,
    title: "",
    description: "",
    request_type_id: 0,
    account_ids: this.initialType,
    sender_id: 0,
    created_by_id: this.initialType,
    response: '',
    reference_link: '',
  }


  onMessage = (userId:any, msg: any) => {
    let thisvar:any = this;
    let UserMessageList = this.state.UserMessageList;
    console.log("onMessage",msg);
    if(msg){
      UserMessageList.push({
        all_read: false,
        message: msg.body,
        sender_id: userId,
        attachments: msg.extension?.attachments
      
      });
      thisvar.setState({
        UserMessageList: UserMessageList
      },()=>{
        this.handleAutoScroll()
        this.getContactlist();
        this.forceUpdate()
      });
    }
   
  }

  onCommentEmojiClick = (event: any, emojiObject: any) => {
    let emoji = emojiObject ? emojiObject.emoji : '';
    this.setState({ userReply : this.state.userReply + emoji });
  
  }

  handleCourseComment = async() => {
    if(this.state.userReply !== ""){
      let UserMessageList = this.state.UserMessageList;

      if (this.state.userReply) {
        UserMessageList.push({
          message: this.state.userReply,
          all_read: false,
          sender_id: this.state.QBuserDetails?.id,
          attachments: [],
        });
      }

      let message = {
        type: "chat",
        extension: {
          dialog_id: this.state?.DialogDetails._id,
          save_to_history: 1,
        },
        body: this.state.userReply,
        markable: 1
      };
      const userData = await getStorageData('user');

      let current_User: any = JSON.parse(userData);

      let qbId = current_User.qb_id

      let opponent_Id = this.state?.MessageUSerID.occupants_ids[0] == qbId ? this.state?.MessageUSerID.occupants_ids[1] : this.state?.MessageUSerID.occupants_ids[0]

      QB.chat.send(opponent_Id, message);

      setTimeout(() => {
        this.handleAutoScroll()

      }, 50);

      this.setState({
        userReply: "",
        UserMessageList: UserMessageList,
      });

    }
  }

  handleMesage = async(id: any) => {
    const userData = await getStorageData('user');

    let current_user: any = JSON.parse(userData);
    
    let QbId = current_user.qb_id;

    let thisvar = this;

    let params = {
      type: 3,
      occupants_ids: [id.occupants_ids[0] == QbId ? id.occupants_ids[1] : id.occupants_ids[0] ]
    };

    await setStorageData("opponentId",id.occupants_ids[0] == QbId ? id.occupants_ids[1] : id.occupants_ids[0]);

    QB.chat.dialog.create(params, function (error: any, dialog: any) {
      let params = {
        chat_dialog_id: dialog._id,
        sort_desc: 'date_sent',
        limit: 100,
        skip: 0
      };

      thisvar.setState({ DialogDetails: dialog });

      QB.chat.message.list(params, (error:any, result:any) => thisvar.quickBloxsetMessage(error, result,thisvar,id));
    })
  }
  quickBloxsetMessage = async(error:any, messages:any,thisvar:any,id:any) => {
    if (error) {
      thisvar.setState({ IsunbletoLoad: true });
      return true;
    }
    
    thisvar.setState({
      isInduMessage: true,
      MessageUSerID: id,
      UserMessageList: messages.items.reverse() //Need to modified
    }, ()=>{
      thisvar.handleAutoScroll()
    });
  }

  handleAddUSerDetails = async(e: any) => {


    if (e?.qb_id) {
      let thisvar = this;
      let params = {
        type: 3,
        occupants_ids: [e.qb_id]
      };

      await setStorageData("opponentId",e.qb_id);

      QB.chat.dialog.create(params, function (error: any, dialog: any) {

        let params = {
          limit: 100,
          sort_desc: 'date_sent',
          skip: 0,
          chat_dialog_id: dialog._id,
        };

        thisvar.setState({ 
          DialogDetails: dialog 
        });

        QB.chat.message.list(params, function (error: any, messages: any) {

          thisvar.setState({
            UserMessageList: messages.items.reverse(),//Need to modified,
            MessageUSerID: dialog,
            isAddMessageList: false,
            isInduMessage: true,
          },()=>{

            thisvar.handleAutoScroll()

          });
        });
      })
    } else {

      toast("User Not register", { type: 'error' });

    }
  }
handleAutoScroll =  () => {

  let scroll_to_bottomm = document.getElementById('chat-content');

  if(scroll_to_bottomm){
    
    scroll_to_bottomm.scrollTop = scroll_to_bottomm.scrollHeight;

  }
}
  handleClose = async() => {
    
    let oppId = await getStorageData("opponentId");
    const resetState = () => {
      this.setState({
        isInduMessage: false,
        isAddMessageList: false,
        MessageUSerID: "",
        UserMessageList: [],
        userReply: "",
        openCommentReplyEmoji: false
      });
      this.getContactlist();
    };
    if(oppId){
      let params = {
        type: 3,
        occupants_ids: [(oppId)]
      };
      QB.chat.dialog.create(params, () => resetState())
    }else{
      resetState();
    }
  }

  getContactlist = () => {
    let params = {
      type: {
        in: [3]
      }
    };
    let thisvar = this;

    QB.chat.dialog.list(params, function (error: any, dialogs: any) {
      if (error) {
        thisvar.setState({ IsunbletoLoad: true });
        return true;
      }
      let totalCount = 0;
      if(dialogs.items.length > 0){
        dialogs.items.map((data: any) => totalCount = totalCount + parseInt(data.unread_messages_count))
      }
      thisvar.setState({ MessagesList: dialogs.items, MessagesListS: dialogs.items, totalUnreadMessageCount: totalCount });
    });
  }

  onListSerach = (e: any) => {
    if (e) {
      let MessagesList = this.state.MessagesListS;
      let data: any = [];
      MessagesList.map((list: any) => {
        if ((list.name.toLowerCase()).match(e.toLowerCase())) {
          data.push(list);
        }
      });

      this.setState({ MessagesList: data, isserach : true, serachvalue: e });

    } else {
      this.setState({ MessagesList: this.state.MessagesListS });

    }
  }

  onInitialized = async() => {
    let authToken = await getStorageData("authToken")

    this.setState({ loading: true , userLoading: true });
    this.apiGetAllUsersCallId = sendAPIRequest(`${configJSON.getUserList}?account_id=0`, {
      headers: { token: authToken },
    });
  }

  handleGetUsersAPIResponse = (json: GetUsersAPIResponse) => {
    if (!json || !json.data) return;
  
    const users: UserListData[] = json.data.map((user) => {
      const id = user.id;
      const attributes = user.attributes || {};
      const firstName = attributes.first_name || '';
      const lastName = attributes.last_name || '';
      const email = attributes.email || '';
      const profilePic = attributes.profile_pic;
  
      return {
        id,
        name: `${firstName} ${lastName} ${email}`,
        profile_pic: profilePic
      };
    });
  
    const pagination = json.meta ? json.meta.pagination : {};
  
    this.setState({ users, userPagination: pagination });
  }

  paginateUsersOptions = async() => {
    let authToken = await getStorageData("authToken")

    this.setState({userLoading:true})
    const page = this.state.userPagination?.next_page
    if (!page) return
    const { userQuery } = this.state;
    const params = new URLSearchParams()
    params.append("page", page);
    params.append("query", userQuery);
    this.userPaginateCallId = sendAPIRequest(`${configJSON.getUserList}?account_id=${JSON.parse(this.state.messageData?.userId || '0')}&${params.toString()}`, {
      headers: { token: authToken },
    })
  }

  handleUserSearch = (userQuery: string) => {
    this.setState({ userQuery }, this.searchUser);
  }

  searchUser = async() => {
    let authToken = await getStorageData("authToken")
    this.setState({userLoading:true})
    const { userQuery } = this.state;
    const params = new URLSearchParams();
    params.append("query", userQuery);
    this.userSearchCallId= sendAPIRequest(`${configJSON.getUserList}?account_id=${JSON.parse(this.state.messageData?.userId || '0')}&${params.toString()}`, {
      headers: { token: authToken },
    })
  }

  handleAttachment = (files: any) => {
    let inputFile = files.target?.files[0];
    this.setState({ filesArray: inputFile}) 
    let thisvar = this;
    let current_User: any = JSON.parse(this.state.userString);

    let qbId = current_User?.qb_id

    let opponent_Id = this.state?.MessageUSerID.occupants_ids[0] == qbId ? this.state?.MessageUSerID.occupants_ids[1] : this.state?.MessageUSerID.occupants_ids[0]

  
    let fileParams = {
      name: inputFile.name,
      file: inputFile,
      type: inputFile.type,
      size: inputFile.size,
      public: true,
    };
  
    QB.content.createAndUpload(fileParams, async function (error: any, result: any) {
      if (!error) {
        const url = await QB.content.publicUrl(result.uid);
        const fileName = result.name
        const fileSize = (result.size / (1024 * 1024)).toFixed(2);
        const content_type = result.content_type
        const attachmentData = {fileName,fileSize,type:content_type,url,uid:result.uid}
        let UserMessageList = thisvar.state.UserMessageList;
          UserMessageList.push({
            message: result.name,
            all_read: false,
            sender_id: thisvar.state.QBuserDetails?.id,
            attachments: [attachmentData],
          });
        
  
        let message = {
          type: "chat",
          body: result.name,
          extension: {
            save_to_history: 1,
            dialog_id: thisvar.state.DialogDetails._id,
            attachments: [attachmentData],
          },
        };
        thisvar.setState({
          userReply: "",
          UserMessageList: UserMessageList,
        },()=>{
          thisvar.handleAutoScroll()});
        QB.chat.send(opponent_Id, message)
      } else {
        console.error("File upload error:", error);
      }
    });
  };
  
  downloadAttachment = async (fileUid: string, fileName?: string, contentType?: any) => {
    try {
      const url = await QB.content.publicUrl(fileUid);
      const response = await fetch(url);
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);

      // Create a download link
      const a = document.createElement("a");
      a.href = blobUrl;
      a.download = fileName || "downloaded-file";
      a.click();
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  }  
  // Customizable Area End

}

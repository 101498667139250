import React from "react";
// Customizable Area Start
import TopNavBar from "../../../components/src/TopNavbar";
import {Box, Grid,Typography} from '@material-ui/core';
import { styled } from '@material-ui/styles'
import LeftMenuWeb from "../../../components/src/LeftMenuWeb";
import RightMenuWeb from "../../../components/src/RightMenuWeb";
import Help from './Help';

import Settings5Controller, {
  Props,
  configJSON
} from "./Settings5Controller";
// Customizable Area End
export default class HelpWeb extends Settings5Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start

  // Customizable Area End
    return (
      // Customizable Area Start
      <MainBox style={{backgroundColor:"#edebeb", display:"flex",flexDirection:"column", paddingBottom:"20px",overflowX:"hidden"}}>
      <Box>
        <TopNavBar navigation={this.props.navigation} id={""}/>
      </Box>
      
           <OuterGrid container spacing={3}>
               <LeftGrid  item xs={12} sm={12} md={3} lg={3}>
                   <Box >
                       <LeftMenuWeb navigation={this.props.navigation} id={""}/>
                   </Box>    
               </LeftGrid>
               <MiddleGrid  item xs={12} sm={12} md={6} lg={6} >
                    <Help navigation={this.props.navigation} id={""}/>
                </MiddleGrid>
               <RightGrid  item xs={12} sm={12} md={3} lg={3}>
               <Box >
                   <RightMenuWeb navigation={this.props.navigation} id={""}/>
                   </Box>
               </RightGrid>
           </OuterGrid>
          
  </MainBox>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const MainBox = styled(Box)({
	backgroundColor:"#edebeb",
	display:"flex",
	flexWrap: 'wrap',
	flexDirection:"column",
	paddingBottom:"20px",
	overflowX:"hidden",
})

const OuterGrid = styled(Grid)({
	display: 'flex', 
	flexWrap: 'wrap',
	justifyContent: 'space-between',
})

const LeftGrid = styled(Grid)({
	maxWidth: '300px',
	'@media screen and (max-width: 985px)': {
        maxWidth: '200px',
      },
	  '@media screen and (max-width: 768px)': {
        display: 'none',
        visibility: 'hidden',
    },
})

const MiddleGrid = styled(Grid)({
	width: 'calc(100vw - 600px)',
	maxWidth: 'unset',
	flexBasis: 'unset',
	display: 'flex',
	justifyContent: 'center',
	'@media screen and (max-width: 985px)': {
        width: 'calc(100vw - 500px)',
      },
	'@media screen and (max-width: 768px)': {
        width: '100%'
      }
})

const RightGrid = styled(Grid)({
	maxWidth: '300px',
	'@media screen and (max-width: 985px)': {
        maxWidth: '250px',
      },
	  '@media screen and (max-width: 768px)': {
        maxWidth: 'auto',
		width: '100%'
    },
})

// Customizable Area End


import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
// Customizable Area Start
import * as Yup from 'yup';
export const configJSON = require("./config");
import { sendAPIRequest } from "../../../components/src/utils";
import { toast } from 'react-toastify'
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End
export interface Props {
  // Customizable Area Start

  navigation: any;
  id: string;

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string,
  status:string,
  userPagination?: any;
  sponsorPagination?: any;
  userPaginateCallId?: string;
  filesArray: string[],
  sponsorsearchcallId: string,
  txtSavedValue: string,
  userQuery: any,
  sponsorQuery: string,
  requestOwnerLoading: boolean,
  userSearchCallId?: string,
  created_by: any[],
  seletedData:any[],
  sponsorPaginateCallId: string,
  sponsor: any[],
  edit: any,
  loading:boolean,
  removedAttachments: number[],
  removedReferenceAttachments: number[],
  removedFinanceSheetAttachments: number[],
  projectEvalintialValues: { objective_id: any, organization_name: any, industry: any, project_id: any, project_lead: any },
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}



export default class ProjectEvaluationController extends BlockComponent<
  Props,
  S,
  SS
> {  
  // Customizable Area Start
  apiFetchProjectEvalid: string = "";
  apiGetAllRequestTypeCallId = '';
  apiGetAllUsersCallId = '';
  apiDownLoadFinanceSheet: string = '';
  apiGetSponsorcallId: string = ''
  apiPostprojectcallId: string = "";
  apishowprojectId: string = '';
  apigetStatus:string=""
  validationSchema = Yup.object().shape({
    location: Yup.string().label('Location ').required(),
    Project_Name: Yup.string().label('Project name ').required(),
    Remarks: Yup.string().label("Remarks ").required(),
    Status: Yup.string().label("Status ").required(),
    industry: Yup.string().label("Industry ").required(),
    Organisation_Objective_id: Yup.string().required(),
    project_id: Yup.string().label("Project Id ").required(),
    project_Lead: Yup.string().label("Requested By Project Lead").required(),
    project_Sponsor: Yup.string().label("Project Sponsor").required(),
    project_finance: Yup.string().label("Project Finance").required(),
    level1_pri_Name: Yup.string().label("Level 1 Approval").required(),
    level1_pri_comment: Yup.string().label("Comment").required(),
    level2_pri_Name: Yup.string().label("Level 2 Approval").required(),
    level2_pri_comment: Yup.string().label("Comment").required(),
    Finance_Sheet_document: Yup.string().label("Finance Sheet Document").required("Attachement is required"),
    Upload_Project_Proposal_Document: Yup.array().min(1).label("Project Proposal Document").required("Attachement is required")

  })

  handleFinanceSheet(data:any, body:any){
    if (data.Finance_Sheet_document) {
      let file = data.Finance_Sheet_document[0]
      if(file){
        body.append('finance_sheet', file)
      }
    }
  }
  

  onSubmitForm = async (data: any) => {
    const body = new FormData()
    const statusBody = new FormData()
    console.log(data.level5_sec_comment,"ppopopopop")
    body.append('organization_name', data.orgainsation_name)
    body.append('location', data.location)
    body.append('industry', data.industry)
    body.append('project_objective_id', data.Organisation_Objective_id)
    body.append('project_id', data.project_id as string)
    body.append('project_name', data.Project_Name)
    body.append('requested_by_project_lead', data.project_Lead)
    body.append('project_sponsors', data.project_Sponsor as string)
    body.append('remarks', data.Remarks)
    body.append('project_finance', data.project_finance as string)
    if(data.Status != "Approved"){
      body.append('status',data.Status)
    }
    
    this.handleFinanceSheet(data, body)
    
    
    data.Upload_Project_Proposal_Document.forEach((file: any) => {if(!file?.id){body.append('proposal_documents[]', file)}})
    data.Reference_Document.forEach((file: any) => {if(!file?.id){body.append('reference_documents[]', file)}})
    body.append('project_approvers_attributes[0][level]', 'level 1')
    body.append('project_approvers_attributes[0][primary_approver_id]', data.level1_pri_Name as string)
    body.append('project_approvers_attributes[0][primary_approver_comment]', data.level1_pri_comment)
    data?.level1_sec_Name?.forEach((id: any) => body.append('project_approvers_attributes[0][secondary_approver_ids][]', id as string))
    body.append('project_approvers_attributes[0][secondary_approver_comment]', data.level1_sec_comment)
   
    body.append('project_approvers_attributes[1][level]', 'level 2')
    body.append('project_approvers_attributes[1][primary_approver_id]', data.level2_pri_Name as string)
    body.append('project_approvers_attributes[1][primary_approver_comment]', data.level2_pri_comment)
    data?.level2_sec_name.forEach((id: any) => body.append('project_approvers_attributes[1][secondary_approver_ids][]', id as string))
    body.append('project_approvers_attributes[1][secondary_approver_comment]', data.level2_sec_comment)
   
    if(data.level3_pri_name){
      body.append('project_approvers_attributes[2][level]', 'level 3')
      body.append('project_approvers_attributes[2][primary_approver_id]', data.level3_pri_name)
      body.append('project_approvers_attributes[2][primary_approver_comment]', data.level3_pri_comment)
      data.level3_sec_Name.forEach((id: any) => body.append('project_approvers_attributes[2][secondary_approver_ids][]', id))
      body.append('project_approvers_attributes[2][secondary_approver_comment]', data.level3_sec_comment)
     }
  
      if(data.level4_pri_name){
      body.append('project_approvers_attributes[3][level]', 'level 4')
      body.append('project_approvers_attributes[3][primary_approver_id]', data.level4_pri_name)
      body.append('project_approvers_attributes[3][primary_approver_comment]', data.level4_pri_comment)
      data.level4_sec_name.forEach((id: any) => body.append('project_approvers_attributes[3][secondary_approver_ids][]', id))
      body.append('project_approvers_attributes[3][secondary_approver_comment]', data.level4_sec_comment)
      }
      if(data.level5_pri_name){
      body.append('project_approvers_attributes[4][level]', 'level 5')
      body.append('project_approvers_attributes[4][primary_approver_id]', data.level5_pri_name)
      body.append('project_approvers_attributes[4][primary_approver_comment]', data.level5_pri_comment)
      data.level5_sec_name.forEach((id: any) => body.append('project_approvers_attributes[4][secondary_approver_ids][]', id))
      body.append('project_approvers_attributes[4][secondary_approver_comment]', data.level5_sec_comment)
      }

    
    

    body.append('conclusion', data.conclusion)
    statusBody.append('project_id', data.project_id as string)
    statusBody.append('status',data.Status)
    let authToken = await getStorageData('authToken');
    
    const token = await getStorageData("authToken");

    if (this.state.removedReferenceAttachments.length > 0) {
      const removeAttachmentUrl =  configJSON.deleteProjectEvaluationReferenceAttachmentEndPoint
      const removeAttachmentBody = {project_id: data.project_id, ids: this.state.removedReferenceAttachments };
      const removeAttachmentPromise = new Promise<void>((resolve, reject) => {
        sendAPIRequest(removeAttachmentUrl, {
          method: 'POST',
          headers: { token,'content-type': 'application/json' },
          body: removeAttachmentBody,
        });
        resolve();
      });
      await removeAttachmentPromise;
    }

    if (this.state.removedAttachments.length > 0) {
      const removeAttachmentUrl =  configJSON.deleteProjectEvaluationAttachmentEndPoint
      const removeAttachmentBody = {project_id: data.project_id, ids: this.state.removedAttachments };
      const removeAttachmentPromise = new Promise<void>((resolve, reject) => {
        sendAPIRequest(removeAttachmentUrl, {
          method: 'POST',
          headers: { token,'content-type': 'application/json' },
          body: removeAttachmentBody,
        });
        resolve();
      });
      await removeAttachmentPromise;
    }

    if (this.state.removedFinanceSheetAttachments.length > 0) {
      const removeAttachmentUrl =  configJSON.deleteProjectEvaluationFinanceAttachmentEndPoint
      const removeAttachmentBody = {project_id: data.project_id, ids: this.state.removedFinanceSheetAttachments };
      const removeAttachmentPromise = new Promise<void>((resolve, reject) => {
        sendAPIRequest(removeAttachmentUrl, {
          method: 'POST',
          headers: { token,'content-type': 'application/json' },
          body: removeAttachmentBody,
        });
        resolve();
      });
      await removeAttachmentPromise;
    }

    if (Object.keys(this.state.edit).length!=0) {
      this.apiPostprojectcallId = sendAPIRequest(configJSON.updateprojectEval, {
        method: 'PUT',
        headers: {
          'token': authToken,
        },
        body
      })
    }
  
    else {
      this.apiPostprojectcallId = sendAPIRequest(configJSON.postproject, {
        method: 'POST',
        headers: {
          'token': authToken
        },
        body
      })
    } 
  }
  paginationSponsorOption = async(type = '') => {
    let authToken = await getStorageData('authToken');
    
    const page = this.state?.sponsorPagination?.next_page;
    if (!page) return
    const params = new URLSearchParams()
    params.append("page", page);
    const { sponsorQuery } = this.state;
    params.append("sponsors", sponsorQuery);
    const callId = sendAPIRequest(`${configJSON.getSponsorList}/?${params.toString()}`, {
      headers: { token: authToken },
    })
    this.setState({
      sponsorPaginateCallId: callId
    })
  }

  handleSponsorSearch = (sponsorQuery: any) => {
    this.setState({ sponsorQuery }, this.searchSponsor);
  }

  searchSponsor = async() => {
    let authToken = await getStorageData('authToken');
    const { sponsorQuery } = this.state;
    const params = new URLSearchParams();
    params.append("sponsors", sponsorQuery);
    const callId = sendAPIRequest(`${configJSON.getSponsorList}/?${params.toString()}`, {
      headers: { token: authToken },
    })
    this.setState({
      sponsorsearchcallId: callId,
    });
  }

  paginateUsersOptions = async(type = '') => {
    let authToken = await getStorageData('authToken');
    const page = this.state?.userPagination?.next_page;
    if (!page) return
    const params = new URLSearchParams()
    params.append("page", page);
    const { userQuery } = this.state;
    params.append("approvers", userQuery);
    const callId = sendAPIRequest(`${configJSON.getUserList}/?${params.toString()}`, {
      headers: { token: authToken },
    })

    this.setState({
      userPaginateCallId: callId
    })
  }
  handleUserSearch = (userQuery: string) => {
    
    this.setState({ userQuery, requestOwnerLoading: false }, this.searchUser);
  }
  searchUser = async() => {
    let authToken = await getStorageData('authToken');

    const { userQuery } = this.state;
    const params = new URLSearchParams();
    params.append("approvers", userQuery);
    const callId = sendAPIRequest(`${configJSON.getUserList}/?${params.toString()}`, {
      headers: { token: authToken },
    })
    this.setState({
      userSearchCallId: callId,
    });
  }
  handlesecondryapproverlevel1 = (index: number) => {
    const y = this.state?.edit?.project_approvers?.[index]?.data?.attributes?.secondary_approvers?.length
    let arr1 = []
    for (let i = 0; i < y; i++) {
      arr1.push(this.state?.edit?.project_approvers?.[index]?.data?.attributes?.secondary_approvers[i]?.data?.id)
    }
    return arr1
  }

  handleSponsor = (responseJson:any)=>{
 if (responseJson?.project_sponsors) {
          this.setState({ sponsorsearchcallId: ''})
          this.handleGetSponsorApiresponse(responseJson)
        } else {
          let sponsor: any = []
          this.setState({ sponsor })
        }
  }
  handleApprovers = (responseJson:any)=>{
    if (responseJson?.approvers) {
      this.setState({ userSearchCallId: '' })
      this.handleGetUsersAPIResponse(responseJson)
    }
    else {
      let created_by: any = [];
      this.setState({ created_by });
    }
  }
  handlesponsorPagination = (responseJson:any)=>{
    let sponsor: any = []
        if (responseJson?.project_sponsors) {
          const create = responseJson.project_sponsors.data.map((user: any) => ({ id: user.id, name: `${user?.attributes?.first_name}  ${user?.attributes?.last_name}`, email: user?.attributes?.email, profile_pic: user?.attributes?.profile_pic }))
          const sponsor = [...this.state.sponsor, ...create]
          this.setState({ sponsorsearchcallId: '', sponsor, sponsorPagination: responseJson.project_sponsors?.meta.pagination})
        }
  }
  handleUserpagination = (responseJson:any)=>{
    let created_by: any = []
    if (responseJson?.approvers) {
      const create = responseJson.approvers.data.map((user: any) => ({ id: user.id, name: `${user?.attributes?.first_name}  ${user?.attributes?.last_name}`, email: user?.attributes?.email, profile_pic: user?.attributes?.profile_pic }))
      const created_by = [...this.state.created_by, ...create]
      this.setState({ userSearchCallId: '', userPagination: responseJson.approvers.meta.pagination, created_by })
    }
  }
  
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );
      this.setState({ txtSavedValue: value });
    }

    const apiRequestCall = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
   
    if (responseJson && responseJson.errors) {
      this.handleProjectNameAlreadyTaken(responseJson)
      this.parseApiErrorResponse(responseJson.errors);
      this.parseApiCatchErrorResponse(responseJson.errors);
    } else if (responseJson) {
      switch (apiRequestCall) {
        case this.apiFetchProjectEvalid:
          this.handleapiFetchProjectEvalidResponse(responseJson)
          break;
        case this.state?.userSearchCallId:
          this.handleGetUsersAPIResponse(responseJson)
          break;
        case  this.apiGetSponsorcallId:

          this.handleGetSponsorApiresponse(responseJson)
          break;
        case this.apishowprojectId:
          this.handleapishowprojectIdresponse(responseJson)
          break;
        case this.apigetStatus:
          console.log(responseJson.message, "$$$$$$$$$getstatus")
          this.setState({status:responseJson.message})  
          break;
        case this.apiPostprojectcallId:
          this.props.navigation.history.push(`/RiskManagement`)  
          break;
        default:
          break;
      }
      if (apiRequestCall === this.state?.userSearchCallId) {
        this.handleApprovers(responseJson)
      }
      else if (apiRequestCall === this.state.userPaginateCallId) {
        this.handleUserpagination(responseJson)
      }
      if (apiRequestCall === this.state.sponsorsearchcallId) {
        this.handleSponsor(responseJson)
      }
      else if (apiRequestCall === this.state.sponsorPaginateCallId) {
        this.handlesponsorPagination(responseJson)
       
      }
      else if (errorReponse) {
        this.parseApiErrorResponse(errorReponse);
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  removeAttachments = (attachmentId:number) => {
    console.log(attachmentId, "!!!!!!!!!AID")
    if(attachmentId){
        this.setState(prevState => ({
            removedAttachments: [...prevState.removedAttachments, attachmentId]
        }))
    }
}
removeReferenceAttachments = (attachmentId:number) => {
  console.log(attachmentId, "!!!!!!!!!RAID")
  if(attachmentId){
      this.setState(prevState => ({
          removedReferenceAttachments: [...prevState.removedReferenceAttachments, attachmentId]
      }))
  }
}
removeFinanceSheetAttachments = (attachmentId:number) => {
  console.log(attachmentId, "!!!!!!!!!RFSID")
  if(attachmentId){
      this.setState(prevState => ({
          removedFinanceSheetAttachments: [...prevState.removedFinanceSheetAttachments, attachmentId]
      }))
  }
}

  setFilesArray = (file: any) => {
    this.setState({ filesArray: [file] });
  }

  handleProjectNameAlreadyTaken = (responseJson :any)=>{
    if(responseJson.errors[1] == "Project name project name already taken"){
      this.setState({loading: false})
      toast.error("Project name already exists. Try with a different name.")
    }
  }

  handleapishowprojectIdresponse = async(responseJson:any)=>{
    let authToken = await getStorageData('authToken');
    if(responseJson.error == "No access provided"){
      this.setState({loading:false})
      toast.error(responseJson.reason)
    }else{
      responseJson?.project_evaluation?.data?.attributes.project_approvers.sort(
        (a:any, b:any) => a.data.id - b.data.id
      );
    this.setState({ edit: responseJson?.project_evaluation?.data?.attributes,loading:false },()=>{
      this.apigetStatus = sendAPIRequest(`${configJSON.getUpdateStatus}/?project_id=${this.state?.edit?.project_id}`,{
        method: 'GET',
        headers: { token: authToken },
      })
    })
  }
  }
  handleapiFetchProjectEvalidResponse = (responseJson: any)=>{
    if(responseJson.error == "No access provided"){
      toast.error(responseJson.reason)
      this.setState({
        loading:false
      })
    }else{
      this.setState({ projectEvalintialValues: responseJson.prefilled_data,loading:false })
    }
  }
  handleGetUsersAPIResponse = (json: any) => {  
    const created_by = json.approvers?.data.map((user: any) => ({ id: user.id, name: `${user?.attributes?.first_name}  ${user?.attributes?.last_name}`, email: user?.attributes?.email, profile_pic: user?.attributes?.profile_pic }))
    this.setState({ created_by, userPagination: json.approvers?.meta.pagination  })
  }
  handleGetSponsorApiresponse = (json: any) => {
    const sponsor = json.project_sponsors?.data.map((user: any) => ({ id: user.id, name: `${user?.attributes?.first_name}  ${user?.attributes?.last_name}`, email: user?.attributes?.email, profile_pic: user?.attributes?.profile_pic }))
    this.setState({ sponsor, sponsorPagination: json.project_sponsors?.meta.pagination})
  }
  handleDownReport = async(id: any) => {
    let authToken = await getStorageData('authToken');

    this.apiDownLoadFinanceSheet = sendAPIRequest(`${configJSON.getFinanceReport}/?${id.toString()}`, {
      headers: { token: authToken },
    })
  }

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area End
    ];
    this.state = {
      // Customizable Area Start
      token: '',
      filesArray: [],
      txtSavedValue: '',
      userQuery: '',
      sponsorQuery: '',
      userSearchCallId: '',
      requestOwnerLoading: false,
      userPagination: {},
      sponsorPagination: {},
      created_by: [],
      seletedData:[],
      sponsor: [],
      edit: {},
      status:'',
      sponsorsearchcallId: '',
      loading:false,
      sponsorPaginateCallId: '',
      removedAttachments: [],
      removedReferenceAttachments: [],
      removedFinanceSheetAttachments: [],
      projectEvalintialValues: { organization_name: '', industry: '', objective_id: '', project_id: '', project_lead: {} }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    let authToken = await getStorageData('authToken');
    this.setState({token: authToken}, () => {
    this.onInitialized();
    });
  }
  onInitialized = async() => {
    const id = this.props.navigation.getParam('prod_id')
    const edit = this.props.navigation.history.location.state
    let authToken = await getStorageData('authToken');
    this.setState({loading:true})
    if (!edit) {
      this.apiFetchProjectEvalid = sendAPIRequest(`${configJSON.getProjectEvalution}/?objective_id=${id}`, {
        method: 'GET',
        headers: { 'token': authToken },

      })
    }
    else {
      this.apishowprojectId = sendAPIRequest(`${configJSON.getShowApi}/?objective_id=${id}`, {
        method: 'GET',
        headers: { 'token': authToken }
      })
    }

    this.apiGetSponsorcallId = sendAPIRequest(configJSON.getSponsorList, {
      method: 'GET',
      headers: { token: authToken },
    });
    
   

  }
  // Customizable Area End
}

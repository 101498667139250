// Customizable Area Start
export const mapLocation = require("../assets/locationMap.png");
export const OyoAvatar = require('../assets/ic_oyo_profile.png')
export const iconCalendar = require("../assets/ic_calendar.png")
export const iconCorp = require("../assets/ic_corp.png")
export const iconGreenThumb = require("../assets/ic_greenthumb.png")
export const SendIcon = require("../assets/ic_send.png")
export const EmogiIcon = require("../assets/smile.png")
export const LikedIcon = require("../assets/ic_greenthumb.png")
export const CloseIcon = require("../assets/ic_close.png")
export const ExcelIcon = require('../assets/ic_excel.png')
export const DownloadIcon = require('../assets/ic_download.png')
export const DangerIcon = require('../assets/ic_danger.png')
// Customizable Area End
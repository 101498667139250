import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { Product } from './types'
import * as yup from 'yup'
import { toast } from 'react-toastify'
import { parseProductCreatedMessage, parseProductUpdatedMessage } from './Message'
import { sendReportMessage } from '../../ActivityFeed/src/Messages'
import { sendAPIRequest } from '../../../components/src/utils'
import { sendAddRequestMessage } from '../../RequestManagement/src/Messages'
import { sendSharePopupMessage } from '../../share/src/Messages';
import { getStorageData } from "../../../framework/src/Utilities";


yup.addMethod(yup.mixed, "maxFileSize", function(error: string, maxSize: number) {
  return this.test('test-name', error, function(file: File[]|undefined){
    if(!file) return true
    let arr = Array.isArray(file)?file: [file]
    return !arr.some(f => f.size>maxSize)
  })
})
yup.addMethod(yup.mixed, "maxTotalFileSize", function(error: string, maxSize: number) {
  return this.test('test-name', error, function(file: File[]|undefined){
    if(!file) return true
    // @ts-ignore
    return !(file.reduce((a: File, b: File) => ((a.size) + (b.size)), 0) > maxSize)
  })
})
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  selectedMyTab: number;
  modal: 'create' | 'rate' | '';
  categories: any[];
  token: string;
  products: Product[];
  selectedProduct?: Product;
  modalRating: number;
  loadingPosts: boolean;
  filterQuery: string;
  userRole?: any;
  pagination?: any;
  loadingProducts?: boolean;
  urlSearchParam: URLSearchParams;
  threedotAnchorEl?: any;
  deleteProductId?: any;
  currentProduct?: any;
  currentProductId?: any;
  productDeleteCallId?: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CfProductListing11Controller extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  token: string =''
  productCreateCallId: string = ""
  productListCallId: string = ""
  categoryListCallId: string = ""
  productFilterCallId: string = ""
  apiFavCallIds: string[] = []
  apiUnfavCallIds: string[] = []
  favCallIdToPostId: {[key: string]: string} = {}
  unfavCallIdToPostId:{[key: string]: string} = {}
  userRoleCallId:string = "";
  apiPaginateCallId: string = "";
  rateProductRequestId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.GetAllCategoriesResponseMessage),
      getName(MessageEnum.GetAllSubCategoriesResponseMessage),
      getName(MessageEnum.NewProductCreatedMessage),
      getName(MessageEnum.ProductUpdatedMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      selectedMyTab: 2,
      modal: '',
      categories: [],
      token: '',
      products: [],
      modalRating: 0,
      loadingPosts: false,
      filterQuery: '',
      userRole: "",
      urlSearchParam: new URLSearchParams()
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    parseProductUpdatedMessage(message, this.handleProductUpdatedMessage)
    parseProductCreatedMessage(message, this.handleProductCreatedMessage)
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.errors) {
        // this.setState({ refresh: false });
        this.parseApiErrorResponse(responseJson.errors);
        this.parseApiCatchErrorResponse(responseJson.errors);
      } else if ( apiRequestCallId === this.productCreateCallId ) {
        if(responseJson.data) {
          this.setState({ 
            products: [{id:responseJson.data.id, ...responseJson.data.attributes}, ...this.state.products],
            modal: '' 
          })
          toast.success('Created Product Successfully')
        } else {

        }
      } else if ( apiRequestCallId === this.productListCallId || apiRequestCallId ===  this.productFilterCallId) {
        console.log({ productListCallId: responseJson })
        if(responseJson?.products?.data && Array.isArray(responseJson?.products?.data))
          this.setState({ 
            products: responseJson.products.data.map((data: any) => ({...data.attributes, id: data.id})),
            pagination: responseJson?.meta?.pagination,
            loadingPosts: false, 
          })
        else
          this.setState({ products: [], loadingPosts: false })
      } else if ( apiRequestCallId === this.categoryListCallId ) {
        if(Array.isArray(responseJson))
            this.setState({categories: responseJson})
      } else if (apiRequestCallId === this.rateProductRequestId) {
        const products = this.state.products.map((p: any)=>{
          if(p.id!=this.state.selectedProduct?.id) return p
          return ({
              ...p, 
              is_rated: true, 
              average_rating: responseJson?.data?.attributes?.average_rating,
          })
        })
        this.setState({ modalRating: 0, modal: '', selectedProduct: undefined, products })
        toast.success("Rated Product Successfully")
      } else if (apiRequestCallId === this.apiPaginateCallId) {
        if(!(responseJson?.products?.data && Array.isArray(responseJson?.products?.data)))
          return 
        this.setState({
          products: [
            ...this.state.products, 
            ...responseJson.products.data.map((data: any)=>({ ...data.attributes, id: data.id })),
          ],
          pagination: responseJson.meta.pagination,
          loadingPosts: false,
        })
      } else if(apiRequestCallId === this.state.productDeleteCallId){
        const pid = this.state.deleteProductId
        this.closeDeleteModal()
        const products = this.state.products.filter((p: any) => p.id != pid)
        this.setState({ products })
      } else if (this.apiFavCallIds.includes(apiRequestCallId)){
          const postId = this.favCallIdToPostId[apiRequestCallId]
          this.apiFavCallIds = this.apiFavCallIds.filter((x:any) => x !== apiRequestCallId)
          delete this.favCallIdToPostId[apiRequestCallId]
          if(responseJson.data){
          const fav = {...responseJson.data, ...responseJson.data?.attributes}
          const newPosts = this.state.products.map(post => {
            if(String(post.id) === postId)
              return {...post, favourite_id: [...(post.favourite_id|| []), fav]}
            return {...post}
            })
          this.setState({ products: newPosts })
        }
      } else if(this.apiUnfavCallIds.includes(apiRequestCallId)){
        const id = this.unfavCallIdToPostId[apiRequestCallId]
        const products = this.state.products.map((j: any)=>{
          if(j.id == id)
            return {...j, favourite_id: []}
          return j
        })
        this.setState({ products })
      } else if (apiRequestCallId === this.userRoleCallId) {
        // toast.success('Community created successfully')
        this.setState({ 
          userRole: responseJson.user_role || ''
        })
      }
    }


    // Customizable Area End

  }

  // Customizable Area Start
  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  }

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  }
  
  componentDidMount = async () => {
    const token = await getStorageData('authToken')
    this.token = token
    this.setState({token: token},()=>{
      this.handleUserRole()
      this.getAllPosts()
    });
    
    // this.categoryListCallId = sendAPIRequest(configJSON.categoryListAPIEndpoint, {headers: { token: this.state.token }})
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.navigation.history.location.state?.comment) {
      this.handleUserRole()
      this.getAllPosts()
      this.props.navigation.history.replace({ state: {} })
    }
  }

  handleUserRole = () => {
    const { token } = this.state
    const url = configJSON.getUserRoleEndpoint
    this.userRoleCallId =  sendAPIRequest(url , { method: 'GET', headers: {token} })
  }

  setSelectedMyTab = (idx: number) => this.setState({selectedMyTab: idx})
  hideModal = ()=> this.setState({modal: '', modalRating: 0})
  
  openThreeDot = (e: any) => this.setState({ threedotAnchorEl: e.currentTarget, currentProductId: e.currentTarget.dataset.id, currentProduct: this.state.products.find((x: any) => x.id == e.currentTarget.dataset.id) })
  
  handleThreeDotClose = () => {
    this.setState({ threedotAnchorEl: undefined })
  }

  handleProductReport = () => {
    sendReportMessage(this.state.currentProductId, 'BxBlockCfproductlisting11::Product')
    this.setState({ threedotAnchorEl: null })
  }

  handleProductEdit = () => {
    this.props.navigation.history.push(`${window.location.pathname}/edit-product/${this.state.currentProduct.id}`)
    this.handleThreeDotClose()
  }

  handlePostDelete = () => {
    this.setState({ threedotAnchorEl: null, deleteProductId: this.state.currentProductId })
  }


  closeDeleteModal = () => this.setState({
    currentProductId: undefined,
    deleteProductId: undefined,
    productDeleteCallId: undefined,
    currentProduct: undefined,
  })

  handleProductDeleteConfirm = () =>{
    const { token } = this.state
    const productDeleteCallId = sendAPIRequest(configJSON.productDeleteEndpoint.replace(':id', this.state.deleteProductId), {
      method: 'DELETE',
      headers: { token }
    })
    this.setState({ productDeleteCallId })
  }

  handleRaiseRequest = () => {
    this.props.navigation.navigate('AddRequest')
    this.handleThreeDotClose()
    const requestOwner = this.context
    let assignedTo = this.state.currentProduct.created_by
    assignedTo = { ...assignedTo, first_name: assignedTo.name, last_name: '' }

    setTimeout(() => {sendAddRequestMessage({
          disable_fields: ['created_by', 'accounts', 'request_type_id'],
          sender_id: requestOwner,
          created_by: requestOwner,
          accounts: {data: [{ id: String(assignedTo.id), attributes: assignedTo}]},
          "request_type_id": {
            "request_type": {
              "id": 8,
              "title": "Product Related",
            }
          }
        })
        // window.location.reload();
      }, 500 // a slight delay to send after component is rendered
    )
  }

  handleCreateProductSubmit = (data: any) => {
    const { product_id_1="", product_id_2="", images=[], ...reqData} = data
    reqData['product_unique_id'] = `${product_id_1}-${product_id_2}`
    reqData['category_id'] = 1

    const body = new FormData()
    for(const item of Object.entries(reqData)){
      body.append(`data[attributes][${item[0]}]`, item[1] as string)
    }
  
    for(const image of images) {
      body.append(`data[attributes][images][]`, image)
    }

    this.productCreateCallId = sendAPIRequest(configJSON.productCreateAPIEndpoint, 
      {
        method: 'POST', 
        body, 
        headers: { token: this.state.token }
      }
    )
  }
  onRateClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const id = Number(e.currentTarget.dataset.id)
    const selectedProduct = this.state.products.find(x => x.id == id )
    if(!selectedProduct) return 
    if(selectedProduct.is_rated){
      toast.warn('You have already rated this product.')
      return 
    }
    if(selectedProduct.created_by.id == this.context.id){
      toast.warn('You cannot rate your own product.')
      return 
    }
    console.log({selectedProduct})
    this.setState({modal: 'rate', selectedProduct })
  }
  setModalRating = ( modalRating: number ) => {
    this.setState({ modalRating })
  }

  handleRateProductSubmit = (value: any) => {
    this.rateProductRequestId = sendAPIRequest(configJSON.productRateAPIEndpoint, {
      method: 'POST',
      body: {
        "data":{
          "attributes": {
            "rating": value.rating,
            "product_id": this.state.selectedProduct?.id,
          }
        }
      },
      headers: { token: this.state.token, "content-type": "application/json" }
    })
    
  }

  onFilterChange = (filter: string) => {
    const { token } = this.state
    if(filter === this.state.filterQuery) return
    this.setState({ filterQuery: filter })
    if(filter) {
      let url = configJSON.productFilterAPIEndpoint
      if(this.getMyUrl())
        url = configJSON.productListAPIEndpoint + this.getMyUrl()
      this.productFilterCallId = sendAPIRequest(`${url}?${filter}`, { method: 'GET', headers: { token } })
      this.setState({ loadingPosts: true, products: [] })
    } else {
      this.setState({ products: [] })
      this.getAllPosts()
    }
  }

  getAllPosts = () => {
    this.setState({ loadingPosts: true })
    let url = configJSON.productListAPIEndpoint
    const myUrl = this.getMyUrl()
    this.productListCallId = sendAPIRequest(url+myUrl, {
      headers: { token: this.state.token }
    })
  }
  
  onLikeChange = (postId: any, delta: number) => {
    const products = this.state.products.map(p => {
      if(p.id == postId){
          p.total_likes += delta;
      }
      return p
    })
    this.setState({ products })
  }
  onLikeIdUpdate = (id: any, likeObj: any) => {
    const products = this.state.products.map((p: any) => {
      if (p.id == id) {
        p.like_id = [likeObj];
      }
      return p
    })
    this.setState({ products })
  }

  handleShareClick = (p:any) => () => {
    let data = {
      userId: p?.created_by?.id,
      postId: p?.id,
      link: `${window?.location?.origin}/user/${JSON.parse(p?.created_by?.id || '0')}`,
      shareableType: 'BxBlockCfproductlisting11::Product',
      shareableId: p?.id,
    }
    sendSharePopupMessage(data);
  }

  goToProduct = (product_Id: any) => () => {
    const url = `${this.props.navigation.history.location.pathname}/product/${product_Id}`
    this.props.navigation.history.push(url)
  }

  handleFavClick = (id: any) => () => {
    const product = this.state.products.find(j=>j.id===id)
    const { token } = this.state
    if(product) {
      if(product.is_favourite) {
        const favourite_id = product.favourite_id[0]
        if(!favourite_id) return ;
        const endpoint = configJSON.unfavAPIEndpoint.replace(':id', favourite_id.id)
        const body = new FormData()
        body.append('data[favouriteable_type]', 'BxBlockCfproductlisting11::Product')
        const callId = sendAPIRequest(endpoint, {method: 'DELETE', body, headers: {token}})
        this.apiUnfavCallIds.push(callId)
        this.unfavCallIdToPostId[callId] = product.id as any
      }
      else {
        const endpoint = configJSON.favAPIEndpoint
        const body = new FormData()
        body.append('data[favouriteable_type]', 'BxBlockCfproductlisting11::Product')
        body.append('data[favouriteable_id]', id)
        const callId = sendAPIRequest(endpoint, {method: 'POST', body, headers: { token }})
        this.apiFavCallIds.push(callId)
        this.favCallIdToPostId[callId] = id
      } 

      // Optimistic update
      const products = this.state.products.map(
        j => {
          if(j.id!==id) return j
          return ({...j, is_favourite: !j.is_favourite})
        }
      )
      this.setState({ products })
    }
  }

  handleProductUpdatedMessage = (product: any) => {
    const products = this.state.products.map((j: any) => {
      if(j.id!=product.id)
        return j
      return product
    })
    this.setState({ products })
  }

  handleProductCreatedMessage = (product: any) => {
    const products = [product, ...this.state.products]
    this.setState({ products })
  }

  getMyUrl = () => {
    if(window.location.pathname.indexOf('MyMarketplace')>=0)
      return "/my_products"
    return ""
  }
  
  onFilterQueryChange = (query: any) => {
    this.setState({ urlSearchParam: query })
    this.onFilterChange(this.queryToString(query))
  }
  queryToString = (queryParams: URLSearchParams) => {
    const from_date = queryParams.get('from_date')
    const to_date = queryParams.get('to_date')

    if(from_date) {
      queryParams.delete('from_date')
      queryParams.append('from_date', (new Date(from_date)).toISOString().slice(0, 10))
    }
    if(to_date) {
      queryParams.delete('to_date')
      queryParams.append('to_date', (new Date(to_date)).toISOString().slice(0, 10))
    }
    return queryParams.toString()
  }

  loadNextPage = () => {
    const query = this.state.urlSearchParam
    const isFiltersSet = query.toString().length > 0
    query.append('page', String(this.state.pagination?.next_page))
    let baseUrl = isFiltersSet ? `${configJSON.productFilterAPIEndpoint}` : configJSON.productListAPIEndpoint
    const myUrl = this.getMyUrl()
    if (myUrl)
      baseUrl = configJSON.productListAPIEndpoint + myUrl
    this.apiPaginateCallId = sendAPIRequest(baseUrl + "?" + this.queryToString(query), {
      method: 'GET',
      headers: { token: this.token }
    })
  }
  // Customizable Area End
  
}

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as yup from 'yup'
import { toast } from 'react-toastify'
import { sendCommunityJoinedMessage, sendCommunityUnjoinedMessage } from './Messages'
import { sendAPIRequest } from '../../../components/src/utils'
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { sendAddRequestMessage } from '../../RequestManagement/src/Messages'
import { getStorageData } from "../../../framework/src/Utilities";

// @ts-ignore
yup.addMethod(yup.mixed, "maxFileSizeSingle", function(error: string, maxSize: number) {
  return this.test('max-file-size-single', error, function(file: File|undefined){
    if(!file) return true
    if(file.size>maxSize) return false
    return true
  })
})
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  token: string;
  open:boolean;
  image:any;
  checked:boolean;
  searchText:string;
  selectedOption:any[];
  preferences:any[];
  selectedPreferences: string[];
  addmembers:any[];
  modal: string;
  communities: any[];
  allCategories: any[];
  allSubCategories: any[];
  loadingCreate: boolean;
  memberOptions: any[];
  moderatorOptions: any[];
  allUserOptions: any[];
  moderatorSearchCallId: string;
  memberSearchCallId: string;
  community_type: boolean;
  categoryOptions: any[];
  subCategoryOptions: any[];
  loadingCommunities: boolean;
  communityQuery?: string;
  communitiesPagination?: {
    next_page?: number;
    total_pages: number;
  };
  sampleExcelUrl?: string;
  importIds: number[];
  importLoading: boolean;
  importError?: string;
  memberPagination?: any;
  moderatorPagination?: any;
  moderatorQuery: any;
  memberQuery: any;
  memberPaginateCallId?: any;
  moderatorPaginateCallId?: any;
  userRole?: any;
  communityPaginateCallId?: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CommunityForumController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  listCommunitiesCallId: string = "";
  createCommunityCallId: string = "";
  userRoleCallId:string = "";
  initUsersCallId: string = "";
  initUsersList: any[] = []; // List of users for moderator/member dropdown initially
  initUsersPagination: any = undefined; // pagination for member moderator dropdown
  moderatorSearchCallId: string = "";
  memberSearchCallId: string = "";
  communityFilterCallId: string = "";
  allPreferences = [{id: 1, label: "E - Environmental"}, {id: 2, label: "S - Social"}, {id: 3, label: "G - Governance"}]
  getSampleExcelCallId: string = "";
  importCsvCallId: string = "";
  followCommunityCallId: string = "";
  joinCommunityCallId:{[key: string]: string} = {}
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const authToken = await getStorageData('authToken');
    this.setState({token: authToken}, () => {
      if(authToken==null){
        this.props.navigation.navigate('Home')
      } else {
        this.handleUserRole();
        this.loadCommunities()
        this.loadCategoriesSubCategories()   
        this.loadInitialUsers()
      }
    });
    // Customizable Area End
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.GetAllCategoriesResponseMessage),
      getName(MessageEnum.GetAllSubCategoriesResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      open:false,
      image:null,
      checked:false,
      searchText:'',
      selectedOption:[],
      preferences:[],
      selectedPreferences: [],
      addmembers:[],
      token: '',
      modal: '',
      communities: [],
      allCategories: [],
      allSubCategories: [],
      loadingCommunities: false,
      loadingCreate: false,
      moderatorOptions: [],
      memberOptions: [],
      allUserOptions: [],
      moderatorSearchCallId: "",
      memberSearchCallId: "",
      community_type: false,
      categoryOptions: [],
      subCategoryOptions: [],
      importIds: [],
      importLoading: false,
      
      moderatorQuery: '',
      memberQuery: '',

      userRole: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.onImageChange = this.onImageChange.bind(this);
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    if(message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.errors) {
        // this.setState({ refresh: false });
        this.parseApiErrorResponse(responseJson.errors);
        this.parseApiCatchErrorResponse(responseJson.errors);
      } else if (apiRequestCallId === this.listCommunitiesCallId || apiRequestCallId === this.communityFilterCallId) {
        let communities = []
        if(Array.isArray(responseJson?.communities?.data))
          communities = responseJson.communities.data
        else if (Array.isArray(responseJson?.data))
          communities = responseJson.data
        
        this.setState({
          communities,
          loadingCommunities: false,
          communitiesPagination: responseJson.meta?.pagination,
        })
      } else if(apiRequestCallId === this.state.communityPaginateCallId) {
        let communities = this.state.communities
        communities = [...communities, ...(responseJson?.communities?.data || responseJson?.data)]
        this.setState({
          communities,
          communitiesPagination: responseJson.meta?.pagination
        })
      } else if (apiRequestCallId === this.createCommunityCallId) {
        toast.success('Community created successfully')
        this.setState({ loadingCreate: false, modal: '', communities: [ responseJson.data, ...this.state.communities] })
      }
      else if (apiRequestCallId === this.userRoleCallId) {
        // toast.success('Community created successfully')
        console.log("Myuserrole",responseJson.user_role)
        this.setState({ 
          userRole: responseJson.user_role
        })
      }
      else if (apiRequestCallId === this.initUsersCallId) {
        if(!Array.isArray(responseJson.data)) return;
        const allUserOptions = responseJson.data.map((d:any) => d.attributes)
        const pagination = responseJson.meta.pagination
        this.initUsersList = allUserOptions
        this.initUsersPagination = pagination
        this.setState({ 
          moderatorOptions: allUserOptions, 
          memberOptions: allUserOptions, 
          allUserOptions, 
          memberPagination: pagination, 
          moderatorPagination: pagination  
        })
      } else if (apiRequestCallId === this.state.moderatorPaginateCallId) {
        if(Array.isArray(responseJson.data)) {
          const options = responseJson.data.map((d:any) => d.attributes)
          const moderatorOptions = this.filterUnique(this.state.moderatorOptions.concat(options))
          this.setState({
            moderatorOptions,
            moderatorPagination: responseJson.meta.pagination
          })
        }
      } else if (apiRequestCallId === this.state.memberPaginateCallId) {
        if(Array.isArray(responseJson.data)) {
          const options = responseJson.data.map((d:any) => d.attributes)
          const memberOptions = this.filterUnique(this.state.memberOptions.concat(options))
          this.setState({
            memberOptions,
            memberPagination: responseJson?.meta?.pagination
          })
        }
      } else if (apiRequestCallId === this.state.moderatorSearchCallId) {
        if(!Array.isArray(responseJson.data)) 
          this.setState({ 
            moderatorSearchCallId: '', 
            moderatorOptions: [], 
            moderatorPagination: undefined })
        else {
          const moderatorOptions = responseJson.data.map((d:any) => d.attributes)
          this.setState({ 
            moderatorSearchCallId: '', 
            moderatorOptions, 
            moderatorPagination: responseJson.meta.pagination 
          })
        }
      } else if (apiRequestCallId === this.state.memberSearchCallId) {
        if(!Array.isArray(responseJson.data)) 
          this.setState({ memberSearchCallId: '', memberOptions: [], memberPagination: undefined })
        else {
          const memberOptions = responseJson.data.map((d:any) => d.attributes)
          this.setState({ 
            memberSearchCallId: '', 
            memberOptions, 
            memberPagination: responseJson?.meta?.pagination 
          })
        }
      } else if (apiRequestCallId === this.importCsvCallId) {
        if(Array.isArray(responseJson?.data)) {
          const importIds = responseJson.data.map((d:any) => d.attributes.id )
          const emailErrors = responseJson.meta.invalid_emails
          const allUserOptions = responseJson.data.map((d: any) => d.attributes)
          toast.success(`${importIds.length} users imported from CSV`)
          this.setState({ 
            importIds, 
            importLoading: false, 
            allUserOptions 
          })
          if(emailErrors?.length) this.setState({ importError: `Could not import ${emailErrors?.length} emails, invalid or not found.` })
        }
      } else if (apiRequestCallId === this.followCommunityCallId) {
          // toast.success(responseJson?.message)
          const community_id = this.joinCommunityCallId[apiRequestCallId]
          delete this.joinCommunityCallId[apiRequestCallId]
          
          let newCommunities = [ ...this.state.communities]
          let in_community
          let thiscommunity 
          
            const current_user = { attributes: this.context, id: this.context.id}
          newCommunities = newCommunities.map((community) => {
            if (community.id === community_id) {
              thiscommunity = community
              if(community.attributes.community_type === "public")
              {
                community.attributes.in_community = community.attributes.in_community ? false : true
                in_community = community.attributes.in_community
                community.attributes.members.meta.total += community.attributes.in_community ? 1 : -1
                
                if(in_community)
                community.attributes.members.data = [...community.attributes.members.data, current_user]
                else
                  community.attributes.members.data = community.attributes.members.data.filter((com: any)=>com.id!=current_user.id)
                console.log({members: community.attributes.members.data})
              } else {
                in_community = community.attributes.in_community
                if(in_community) {
                  community.attributes.in_community = false
                  in_community = false
                  community.attributes.members.data = community.attributes.members.data.filter((com: any)=>com.id!=current_user.id)
                } else
                community.attributes.join_request_pending = true
              }
            }


            return community
          })
          if(in_community) {
            sendCommunityJoinedMessage(thiscommunity)
          } else {
            sendCommunityUnjoinedMessage(thiscommunity)
          }
          this.setState({ communities: newCommunities})
      }
    } else if (message.id === getName(MessageEnum.GetAllCategoriesResponseMessage)) {
      console.log("categories", message)
      const allCategories = message.getData(
        getName(MessageEnum.AllCategoriesDataMessage)
      )
      this.setState({ allCategories })
    } else if (message.id === getName(MessageEnum.GetAllSubCategoriesResponseMessage)) {
      console.log("sub-categories", message)
      
      const allSubCategories = message.getData(
        getName(MessageEnum.AllSubCategoriesDataMessage)
        )
      this.setState({ allSubCategories })
    }
    // Customizable Area End
    
  }

  // Customizable Area Start
  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  }

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  }

  createFormSchema = yup.object().shape({
    name: yup.string().required().label('Community Name').max(160),
    // @ts-ignore
    image: yup.mixed().label('Image').maxFileSizeSingle("Image must be less than 1Mb", 1024*1024),
    description: yup.string().required().max(1000).label('Description'),
    moderator_ids: yup.array().min(3, "Atleast 3 moderators are required").label('Moderators'),
    preference_ids: yup.array().min(1, "Select atleast one Preference").label('Preferences'),
    category_ids: yup.array().min(1, "Select atleast one Category").label('Categories'),
    // sub_category_ids: yup.array().min(1, "Select atleast one Sub-Category").label('Sub-Categories'),
    members: yup.array(),
    user_ids: yup.array().min(1, "Select atleast one Member"),
  })

  handleClickOpen = () => {
    // setOpen(true);
    console.log("clicked");
    this.setState({open:true});
  };
  handleClose = () => {
    // setOpen(false);
    this.setState({open:false});

  };
  onImageChange = (event:any) => {
   
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      this.setState({
        image: URL.createObjectURL(img)
      });
    }
  };
  handleSwitchChange = (event:any) => {
    this.setState({checked: event.target.checked },()=>console.log(this.state.checked));
    
  };

  handleChange = (prop:any) => (event:any) => {
    this.setState({ ...this.state, [prop]: event.target.value });
    // console.log(this.state.email,"values")
  };

  handleDelete = (chipToDelete: any) => () => {
    this.setState({
      preferences: this.state.preferences.filter(c => c != chipToDelete) //filter will produce a copy of the array
    },()=>console.log(this.state.preferences,'***'));
  };
  handleDeleteOption = (chipToDelete: any) => () => {
    this.setState({
      selectedOption: this.state.selectedOption.filter(c => c != chipToDelete) //filter will produce a copy of the array
    },()=>console.log(this.state.selectedOption,'***'));
  };
  handleDeleteMember = (chipToDelete: any) => () => {
    this.setState({
      addmembers: this.state.addmembers.filter(c => c != chipToDelete) //filter will produce a copy of the array
    },()=>console.log(this.state.addmembers,'***'));
  };

  hideModal = () => {
    this.setState({ modal: '' })
  }

  loadCommunities = () => {
    const { token } = this.state
    this.setState({ loadingCommunities: true })
    this.listCommunitiesCallId = sendAPIRequest(configJSON.listCommunitiesEndpoint, { headers: {token} })
  }
  refreshCommunities = () => {
    if(this.state.communityQuery)
      this.queryCommunityListing(this.state.communityQuery)
    else
      this.loadCommunities()
  }

  loadCategoriesSubCategories= ()=>{
    const msg = new Message(
      getName(MessageEnum.GetAllCategoriesMessage)
    )
    runEngine.sendMessage(msg.id, msg)

    const msg2 = new Message(
      getName(MessageEnum.GetAllSubCategoriesMessage)
    )
    runEngine.sendMessage(msg2.id, msg2)
  }

  // for create community form
  loadInitialUsers = () => {
    const { token } = this.state
    this.initUsersCallId = sendAPIRequest(
      configJSON.getUsersEndpoint,
      { method: 'GET', headers: { token } }
    )
  }

  handleCreateCommunitySubmit = (rawData: any)=>{
    const data = rawData

    const body = new FormData();
    (['moderator_ids',
          'preference_ids',
          'category_ids',
          'sub_category_ids',
          'user_ids',]).forEach((id: any) => {
          body.append(`[data][attributes][${id}]`, `[${String(data[id])}]`)
          // data[id].forEach((val: any) => body.append(`[data][attributes][${id}]`, val))
        }
    );

    body.append('[data][attributes][community_type]', (!data.community_type?'1':'0') )
    body.append('[data][attributes][name]', data.name)
    body.append('[data][attributes][description]', data.description)
    if(data.image) body.append('[data][attributes][image]', data.image)
    body.append('[data][attributes][community_id]', "")
    const { token } = this.state
    this.setState({ loadingCreate: true })
    this.createCommunityCallId = sendAPIRequest(configJSON.createCommunityEndpoint, {
      method: 'POST',
      headers: {
        token,
        'Content-Encoding': 'gzip'
      },
      body,
    })

  }

  handleUserRole = () => {
    const { token } = this.state
    const url = configJSON.getUserRoleEndpoint
    this.userRoleCallId =  sendAPIRequest(url , { method: 'GET', headers: {token} })
  }

  handleUserSearch = (query: string, page?: number) => {
    console.log({page})
    const { token } = this.state
    const params = new URLSearchParams()
    query && params.append('query', query)
    page && params.append('page', String(page))
    const url = configJSON.getUsersEndpoint +"?"+ params.toString()
    return sendAPIRequest(url , { method: 'GET', headers: {token} })
  }

  handleModeratorSearch = (query: string) => {
    console.log({ query })
    if(query)
      this.setState({ 
        moderatorSearchCallId: this.handleUserSearch(query), 
        moderatorQuery: query, 
        moderatorPaginateCallId: '' 
      })
    else 
      this.setState({ 
        moderatorOptions: this.state.allUserOptions, 
        moderatorPagination: this.initUsersPagination, 
        moderatorQuery: '',
        moderatorSearchCallId: '',
      })
  }

  handleMemberSearch = (query: string) => {
    if(query)
      this.setState({ 
        memberSearchCallId: this.handleUserSearch(query), 
        memberQuery: query, 
        memberPaginateCallId: '' 
      }) 
    else
      this.setState({ 
        memberOptions: this.state.allUserOptions, 
        memberPagination: this.initUsersPagination, 
        memberQuery: '',
        memberSearchCallId: '' 
      })
  }

  handleMemberClose = ()=>{
    this.setState({ 
      memberSearchCallId: '', 
      memberQuery: '',
      memberPaginateCallId: '', 
      memberOptions: this.state.allUserOptions, 
      memberPagination: this.initUsersPagination,   
    })
  }
  handleModeratorClose = ()=>{
    this.setState({ 
      moderatorSearchCallId: '', 
      moderatorQuery: '',
      moderatorPaginateCallId: '', 
      moderatorOptions: this.state.allUserOptions, 
      moderatorPagination: this.initUsersPagination,   
    })
  }
  preferenceChangeEffect = (ids: number[]) => {
    const lst = ids.map((id)=>this.allPreferences.find((p: any)=>p.id === id)?.label?.slice?.(0, 1));
    const labels = new Set(lst);
    
    const categoryOptions = this.state.allCategories.filter((cat: any) => {
      return cat.preferences.some((p: any)=>labels.has(p.slice(0, 1)))
    })


    this.setState({ categoryOptions, selectedPreferences: lst as any[] })
  }

  categoryChangeEffect = (ids: number[]) => {
    const plookup = new Set(this.state.selectedPreferences) 
   const subCategoryOptions = this.state.allSubCategories.filter((st: any) => {
      console.log({st})
      return st.categories.some((ct: any) => ids.includes(ct.id)) && st.preferences.some(
        (p: any)=> plookup.has(p.slice(0,1))
      )
    })

    console.log({subCategoryOptions})
    this.setState({ subCategoryOptions })
  }

  queryCommunityListing = (query: string) => {
    this.setState({ communityQuery: query })
    const {token} = this.state
    if(query) {
      this.setState({ loadingCommunities: true })
      this.communityFilterCallId = sendAPIRequest(
          `${configJSON.listCommunitiesEndpoint}/filter/?query=${query}`, 
          { headers: {token} }
        )
    } else if(token) {
      this.loadCommunities()
    }
  }




  importCsv = (files: File[]) => {
    const file = Array.from(files)[0]
    if(file) {
      const {token} = this.state
      const body = new FormData()
      body.append('file', file)
      this.importCsvCallId = sendAPIRequest(configJSON.importSampleCsvEndpoint, {
        method: 'POST',
        body,
        // headers: {token}
      })
      this.setState({ importLoading: true })
    }
  }

  paginateCommunities = () => {
    const { token } = this.state
    // communityFilterCallId
    const query = this.state.communityQuery
    let communityPaginateCallId = ""
    if(!query)
     communityPaginateCallId = sendAPIRequest(`${configJSON.listCommunitiesEndpoint}?page=${this.state.communitiesPagination?.next_page}`, {
      headers: { token }
    })
    else
      communityPaginateCallId = sendAPIRequest(`${configJSON.listCommunitiesEndpoint}/filter?query=${query}&page=${this.state.communitiesPagination?.next_page}`, {
        headers: {token}
      })
    this.setState({ communityPaginateCallId })
  }

  paginateMembers = () => {
    if(this.state.memberSearchCallId!=='') return
    const {next_page} = this.state.memberPagination
    this.setState({ memberPaginateCallId: this.handleUserSearch(this.state.memberQuery, next_page) })
  }

  paginateModerators = () => {
    if(this.state.moderatorSearchCallId!=='') return
    const {next_page} = this.state.moderatorPagination
    this.setState({ moderatorPaginateCallId: this.handleUserSearch(this.state.moderatorQuery, next_page) })
   }

  followCommunities = (community_id: any) => {
    const community = this.state.communities.find((x: any)=> x.id == community_id)
    if(community.attributes.is_admin) {
      toast.warn("You are admin, cannot unjoin your own community.")
      return
    }
    else if(community.attributes.is_moderator) {
      toast.warn("Moderator cannot unjoin a community")
      return
    }
    const { token } = this.state
    const body = new FormData();
    body.append('[data][attributes][community_id]', community_id)
    const url = community.attributes.community_type === "private"?configJSON.followPvtCommunityEndpoint:configJSON.followCommunityEndpoint
    this.followCommunityCallId = sendAPIRequest(url, {
      method: 'POST',
      body,
      headers: {token}
    })
    this.joinCommunityCallId[this.followCommunityCallId] = community_id
  }

  /**
  * From list of users, remove duplicate users
  */
  filterUnique = (items: any[]) => {
    const hashmap = new Set()
    return items.filter((i: any)=>{
      if(hashmap.has(i.id)) return false
      hashmap.add(i.id)
      return hashmap
    })
  }

  handleCreateResponse = (responseJson: any) => {
    console.log({responseJson})
    if(responseJson?.attributes){
      this.setState({ communities: [ responseJson, ...this.state.communities ], modal: '' })
    }
  }

  requestPublicCommunity = () => {
    this.props.navigation.navigate('AddRequest') 

    setTimeout(() => sendAddRequestMessage({
          disable_fields: [ 'request_type_id', 'accounts'],
          // sender_id: requestOwner,
          // created_by: requestOwner,
          // accounts: {data: [{ id: assignedTo.id, attributes: assignedTo}]},
          "request_type_id": {
            "request_type": {
              "id": 10,
              "title": "Community Related",
            }
          },
          "accounts": {data: [{id: 1, type: 'account', attributes: {
            first_name: "Corpstage",
            last_name: "Admin",
            id: 1,
          }}]}
        }), 500 // a slight delay to send after component is rendered
    )
  }
  // Customizable Area End
  
}

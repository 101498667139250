import React from 'react'
import { withStyles, styled } from '@material-ui/styles'

interface FlexProps extends React.HTMLAttributes<HTMLDivElement> {
	// Flex Direction
	col?: Boolean;
	row?: Boolean;
	colRev?: Boolean;
	rowRev?: Boolean;
	// FlexSize
	f?: number | string;
	/** Gap */
	gap?: number | string;
	/** padding */
	px?: number | string;
	py?: number | string;

	wrap?: boolean;

	justify?: 'center' | 'start' | 'end' | 'space-between';
	align?: 'center' | 'start' | 'end';
}

const FlexBase = styled((props: FlexProps)=><div {...props}>{props.children}</div>)(({theme, ...props}) => {
	const { gap, col, f, px, py, justify, align } = props
	const style: any = {
		display: 'flex',
	}
	if(gap) style['gap'] = gap
	if(col) style['flex-direction'] = 'column'
	if(f) style['flex'] = f
	if(px || py) style['padding'] = `${py || 0} ${px || 0}`
	if(justify) style['justify-content'] = justify
	if(align) style['align-items'] = align
	if(props.wrap) style['flex-wrap'] = 'wrap'
	return style
})

export default FlexBase;
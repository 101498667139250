Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.PostAPiMethod = "POST";
exports.exampleApiContentType = "application/json";

exports.PostApiMethodType = "GET";
exports.patchPostAPiMethod = "PATCH";
exports.postGetUrl = "posts/posts";
exports.postContentType = "application/json";
exports.deletePostAPiMethod = "DELETE";
exports.CategoryGetUrl = "categories/categories";
exports.btnExampleTitle = "CLICK ME";
exports.getAllCatergoryEndPoint = "categories/categories";

// exports.getAllCommunitiesEndpoint = "bx_block_communityforum/community/community/"
exports.getAllCommunitiesEndpoint = "/bx_block_communityforum/community/user_community_list/";
exports.getAllCategoriesEndpoint = "bx_block_categories/categories/category";
exports.getSubCategoriesEndpoint = "bx_block_categories/sub_categories/:id/sub_category";
exports.createPostEndpoint = "bx_block_posts/posts"
exports.updatePostEndpoint = "bx_block_posts/posts/:id"
exports.getPostDetailEndpoint = "bx_block_posts/posts/:id"
exports.getAllSubCategoriesEndpoint = "bx_block_categories/sub_categories"

// Job Post Config
exports.createJobPostEndpoint = "bx_block_joblisting2/jobs";

// Customizable Area End
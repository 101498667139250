// Customizable Area Start
import ShowEvaluationController, {
    Props,
} from "./ShowEvaluationController";
import React from "react";
import { styled } from '@material-ui/styles';
import { Box, Grid, Typography, Button, Divider, Avatar, Link, MenuItem } from '@material-ui/core'
import TopNavBar from "../../../components/src/TopNavbar";
import LeftMenuWeb from "../../../components/src/LeftMenuWeb";
import { Formik, Form, useField } from "formik";
import { InputRow, MInput, MSelect } from "../../../components/src/FormModal.web";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { MCountedTextArea } from "../../Events2/src/Components.web";
import Maps from '../../maps/src/Maps.web'
import SearchSelect from "../../../components/src/SearchSelect.web";
import OverlayLoader from '../../../components/src/Loader.web'
import RoundedButton from '../../../components/src/RoundedButton.web';
import {iconUpload } from "./assets"
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
// @ts-ignore
import * as config from "../../../framework/src/config";
// Customizable Area End
export default class ShowEvalutaion extends ShowEvaluationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        let intialValues = {
            orgainsation_name: this.state.projectEvalintialValues?.organization_name ,
            location: '',
            industry: this.state.projectEvalintialValues?.industry ,
            Organisation_Objective_id: this.state.projectEvalintialValues?.objective_id,
            project_id: this.state.projectEvalintialValues?.project_id,
            Project_Name: '',
            Upload_Project_Proposal_Document: "",
            project_Lead: this.state.projectEvalintialValues?.project_lead?.data?.id,
            Finance_Sheet_document: "",
            project_Sponsor: "",
            Reference_Document: "",
            Remarks: "",
            Status:"Requested",
            project_finance: "",
        
            level5_sec_comment: "",
            level1_pri_comment: "",
            level1_sec_Name: [],
            level1_sec_comment: "",
            level4_sec_comment: "",
            level2_pri_comment: "",
            level2_sec_name: [],
            level2_sec_comment: "",
            level3_pri_name: "",
            level3_pri_comment: "",
            level3_sec_Name: [],
            level3_sec_comment: "",
            level4_pri_name: "",
            level4_pri_comment: "",
            level2_pri_Name: "",
            level4_sec_name: [],
            level5_pri_comment: "",
            level5_pri_name: "",
            level1_pri_Name: "",
            level5_sec_name: [],
            
            conclusion: ""
        }
        let intialvalue = {
            orgainsation_name: this.state?.edit?.organization_name  ,
            location: this.state?.edit?.location ,
            industry: this.state.edit?.industry ,
            Organisation_Objective_id: this.state?.edit?.project_objective_id,
            project_id: this.state?.edit?.project_id ,
            Project_Name: this.state.edit?.project_name ,
            Upload_Project_Proposal_Document: "",
            project_Lead: this.state.edit?.requested_by_project_lead?.data?.id ,
            project_Sponsor: this.state?.edit?.project_sponsors?.data?.id ,
            Reference_Document: "",
            Remarks: this.state?.edit?.remarks ,
            Status: this.state?.edit?.status,
            project_finance: this.state?.edit?.project_finance ,
            Finance_Sheet_document: "",
            level1_pri_Name: this.state?.edit?.project_approvers?.[0]?.data?.attributes?.primary_approver?.data?.id ,
            level1_sec_Name: this.handlesecondryapproverlevel1(0),
            level3_sec_Name: this.handlesecondryapproverlevel1(2),

            level1_sec_comment: this.state?.edit?.project_approvers?.[0]?.data?.attributes?.secondary_approver_comment ,
            level2_pri_Name: this.state?.edit?.project_approvers?.[1]?.data?.attributes?.primary_approver?.data?.id ,
            level2_sec_name: this.handlesecondryapproverlevel1(1), 
            level3_pri_comment: this.state.edit?.project_approvers?.[2]?.data?.attributes?.primary_approver_comment ,
            level1_pri_comment: this.state.edit?.project_approvers?.[0]?.data?.attributes?.primary_approver_comment ,

            level2_sec_comment: this.state?.edit?.project_approvers?.[1]?.data?.attributes?.secondary_approver_comment ,
            level3_pri_name: this.state?.edit?.project_approvers?.[2]?.data?.attributes?.primary_approver?.data?.id, 
            level3_sec_comment: this.state?.edit?.project_approvers?.[2]?.data?.attributes?.secondary_approver_comment,
            level4_pri_name: this.state?.edit?.project_approvers?.[3]?.data?.attributes?.primary_approver?.data?.id ,
            level4_sec_name: this.handlesecondryapproverlevel1(3), 
            level5_sec_comment: this.state.edit?.project_approvers?.[4]?.data?.attributes?.secondary_approver_comment ,
            level5_pri_name: this.state?.edit?.project_approvers?.[4]?.data?.attributes?.primary_approver?.data?.id,
            level4_pri_comment: this.state.edit?.project_approvers?.[3]?.data?.attributes?.primary_approver_comment,
            level2_pri_comment: this.state.edit?.project_approvers?.[1]?.data?.attributes?.primary_approver_comment ,

            level4_sec_comment: this.state.edit?.project_approvers?.[3]?.data?.attributes?.secondary_approver_comment,
            level5_pri_comment: this.state?.edit?.project_approvers?.[4]?.data?.attributes?.primary_approver_comment ,
            level5_sec_name: this.handlesecondryapproverlevel1(4),
            conclusion: this.state?.edit?.conclusion
        }
        
      
        
        

        
        let proposal_doc = ""
        if (this.state.edit?.proposal_documents) {
            proposal_doc = this.state.edit?.proposal_documents.map((j: any) => j.filename).join(', ')
        }
        let finance_doc = ""
        if (this.state.edit?.finance_sheet) {
            finance_doc = this.state.edit?.finance_sheet.filename
        }
        let reference_document = ""
        if (this.state.edit?.reference_documents) {
            reference_document = this.state.edit?.reference_documents.map((j: any) => j.filename).join(',')
        }
        // Customizable Area End
        return (
            // Customizable Area Start
            <MainBoxWrapper>
                <OverlayLoader loading={this.state.loading} />
                <Box>
                    <TopNavBar navigation={this.props.navigation} id={""} />
                </Box>
                <OuterGrid container spacing={3}>
                    <LeftGridWrapper item xs={12} sm={12} md={3} lg={3}>
                        <Box >
                            <LeftMenuWeb navigation={this.props.navigation} id={""} />
                        </Box>
                    </LeftGridWrapper>
                    {/* <div style={{ display: "flex", alignItems: "center" }}>
                        <span
                        onClick={() => this.props.navigation.navigate("ESGJourney")}
                        style={{ margin: "40px 0px 15px 12px", cursor: "pointer" }}
                        >
                        ESG Journey
                        </span>
                        <div onClick={() => this.props.navigation.navigate("ESGJourney")} style={{ margin: "40px 0px 15px 12px" }}>
                        <ArrowBackIosIcon />
                        </div>
                    </div> */}
                    <MiddleGrid item xs={12} sm={12} md={6} lg={6} >
                    <div style={{ display: "flex", alignItems: "center",padding: "10px 0" }}>
                            <span
                        id="navigation_ESG"
                        onClick={() => this.props.navigation.navigate("ESGJourney")}
                        style={{ cursor: "pointer" }}
                        >
                        ESG Journey
                            </span>
                        <div id="navigation_ESG1" style={{paddingLeft: "10px"}} onClick={() => this.props.navigation.navigate("ESGJourney")} >
                        <ArrowBackIosIcon />
                        </div>
                            <span
                                id="navigate_InitiativeEvaluation"
                        onClick={() => this.props.navigation.navigate("InitiativeEvaluation")}
                        style={{ cursor: "pointer" }}
                        >
                        Initiative Evaluation
                        </span>
                        <div id="navigate_InitiativeEvaluation1" style={{paddingLeft: "10px"}} onClick={() => this.props.navigation.navigate("InitiativeEvaluation")} >
                        <ArrowBackIosIcon />
                        </div>
                        <Typography style=
                            {{
                                fontWeight: 'bold',
                                fontSize: '20px'
                            }}>
                            PROJECT EVALUATION
                        </Typography>
                    </div>
                        <Formik enableReinitialize
                            initialValues={Object.keys(this.state.edit).length!=0?intialvalue:intialValues}
                            onSubmit={this.onSubmitForm}
                            validationSchema={this.validationSchema}
                        >
                            {({
                                values,
                                handleChange,
                                errors,
                                touched,
                                setFieldValue,
                                setTouched,
                            }) => (
                                <Form translate={undefined} >
                                    <FlexSpaceBetween style={{ gap: 6 }}>
                                        <div style={{ flex: 1 ,padding:'0px 0px'}}>
                                            <InputRow label={<ApplyLabel>Organization Name*</ApplyLabel>} input={<><MInput style={{padding:'0px 0px'}} disabled name="orgainsation_name" /></>} />
                                        </div>
                                        <div style={{ flex: 1}}>
                                            <InputRow  label="Location*"  input={<Maps autoCompleteStyles={undefined} customBorder={""} input {...this.props} />} />
                                        </div>
                                    </FlexSpaceBetween>
                                    <FlexSpaceBetween style={{ gap: 6 }}>
                                        <div style={{ flex: 1 }}>
                                            <InputRow label={<ApplyLabel>Industry*</ApplyLabel>} input={<><MInput disabled name="industry" /></>} />
                                        </div>
                                        <div style={{ flex: 1 }}>
                                            <InputRow label={<ApplyLabel>Organisation Objective_id</ApplyLabel>} input={<><MInput disabled name="Organisation_Objective_id" /></>} />
                                        </div>
                                    </FlexSpaceBetween>
                                    <FlexSpaceBetween style={{ gap: 6 }}>
                                        <div style={{ flex: 1 }}>
                                            <InputRow label={<ApplyLabel>Project ID*</ApplyLabel>} input={<><MInput disabled name="project_id" /></>} />
                                        </div>
                                        <div style={{ flex: 1 }}>
                                            <InputRow label={<ApplyLabel>Project Name*</ApplyLabel>} input={<><MInput disabled name="Project_Name" /></>} />
                                        </div>
                                    </FlexSpaceBetween>
                                    <div style={{ margin: '10px 0', display: 'flex', flexDirection: 'column' }}>
                                        <label style={{
                                            fontSize: '15.35px',
                                            color: '#404040',
                                            marginBottom: '15px',
                                            fontFamily: 'HelveticaNeue',
                                        }}>Upload Project Proposal Document*</label>
                                        <UploadButton
                                            name="Upload_Project_Proposal_Document"
                                            label="Upload Attachment"
                                            filename={proposal_doc}
                                            maxSize={20 * 1024 * 1024}
                                            
                                        />
                                    </div>
                                    {/* with Avatar */}
                                    <FlexColoumn>
                                        <label
                                            style={{

                                            fontSize: '15.35px',
                                            fontFamily: 'HelveticaNeue',
                                            color: '#404040',
                                            
                                            
                                        }}>Requested By Project Lead</label>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%', border: '1px solid gray', borderRadius: '5px', height: '45px', }}>
                                            <Avatar style={{ marginLeft: '20px', width: 32.26, height: 31.87, marginRight: '10px' }}
                                                src={Object.keys(this.state.edit).length != 0 ?
                                                    this.state?.edit?.requested_by_project_lead?.data?.attributes?.profile_pic :
                                                    this.state.projectEvalintialValues?.project_lead?.data?.attributes?.profile_pic} />
                                            {Object.keys(this.state.edit).length != 0 ?
                                                this.state?.edit?.requested_by_project_lead?.data?.attributes.first_name + " " +
                                                this.state?.edit?.requested_by_project_lead?.data?.attributes.last_name :
                                                this.state.projectEvalintialValues?.project_lead?.data?.attributes?.first_name + " " + this.state.projectEvalintialValues?.project_lead?.data?.attributes?.last_name
                                            }
                                        </div>
                                        <InputRow data-test-id = "input_projectSponsor" label={<ApplyLabel>Project Sponsor*</ApplyLabel>} input={<> <SearchSelect
                                            multiple={false}
                                            scrollHeight={'275px'}
                                            selectProps={{ style: { minHeight: '48px' } }}
                                            hasMore={!!this.state?.sponsorPagination?.next_page}
                                            nextPage={this.paginationSponsorOption}
                                            loading={(!!this.state?.sponsorsearchcallId)}
                                            onSearch={this.handleSponsorSearch}
                                            allItems={this.state?.sponsor}
                                            items={this.state?.sponsor}
                                            name="project_Sponsor"
                                            label={"Select User"}
                                            placeholder="Select User"
                                            SelectItem={SelectItemUser}
                                            SelectedItem={SelectedItem}
                                            getOptionValue={(user: any) => `${user.first_name} ${user.last_name}`}
                                        /></>} />
                                        <label>Reference Document</label>
                                        <UploadButton
                                            name="Reference_Document"
                                            label="Reference Document"
                                            multiple
                                            filename={reference_document}
                                            maxSize={20 * 1024 * 1024}
                                            
                                        />
                                        <InputRow label={<ApplyLabel>Remarks*</ApplyLabel>} input={<><MInput disabled name="Remarks" /></>} />
                                        <InputRow label={<ApplyLabel>Status*</ApplyLabel>} input={
                                            <MSelect name="Status"
                                                disabled>
                                                <MenuItem
                                                    value="Requested">Requested</MenuItem>
                                                <MenuItem
                                                    value="Approved">Approved</MenuItem>
                                                <MenuItem
                                                    value="Rejected">Rejected</MenuItem>
                                                <MenuItem
                                                    value="On Hold">On Hold</MenuItem>
                                                <MenuItem
                                                    value="Need More Details">Need More Details</MenuItem>
                                                <MenuItem
                                                    value="send for further details"> Send for Further details</MenuItem>
                                        </MSelect>} />
                                        <InputRow label={<ApplyLabel>Project Finance*</ApplyLabel>} input={<><MInput disabled name="project_finance" /></>} />
                                    </FlexColoumn>
                                    <FlexSpaceBetween>
                                        <DownloadButton >
                                            <ArrowDownwardIcon color="inherit" />
                                            <Link style={{textTransform:'none'}} href={`${config.baseURL}/bx_block_project_evaluation/project_evaluation/download_finance_sheet/`} >Download Sample Finance Report</Link>
                                        </DownloadButton>
                                        <UploadButton
                                            filename={finance_doc}
                                            label="Upload Final Finance Sheet"
                                            multiple
                                            maxSize={20 * 1024 * 1024}
                                            name="Finance_Sheet_document"
                                            
                                        />
                                    </FlexSpaceBetween>
                                    <label style={{ color: 'grey', marginTop: '10px' }}>
                                        Approver*
                                    </label>
                                    <Divider />
                                    <label ><ApplyLabel
                                        style={{ marginTop: '20px' }}>Level1*</ApplyLabel></label>
                                    <label
                                        style={{ marginTop: '10px' }}><ApplyLabel style={{ marginTop: '20px' }}>Primary Approval*</ApplyLabel></label>
                                    <FlexSpaceBetween
                                        style={{ gap: 7 }}>
                                        <div
                                            style={{ flex: 1 }}>
                                            <InputRow label={<ApplyLabel >Name*</ApplyLabel>} input={<>
                                                <SearchSelect
                                                    multiple={false}
                                                    scrollHeight={'275px'}
                                                    selectProps={{ style: { minHeight: '38px' } }}
                                                    hasMore={false}
                                                    allItems={this.state?.created_by}
                                                    items={this.state?.created_by}
                                                    name="level1_pri_Name"
                                                    label={"Select User"}                                           
                                                    placeholder="Select User"
                                                    nextPage={this.paginateUsersOptions}
                                                    loading={!!this.state.userSearchCallId}
                                                    onSearch={this.handleUserSearch}
                                                    
                                                    SelectItem={SelectItemUser}
                                                    SelectedItem={SelectedItem}
                                                    getOptionValue={(user: any) => `${user.first_name} ${user.last_name}`}
                                                    disabled
                                                />

                                            </>} />
                                        </div>
                                        <div style={{ flex: 2 }}>
                                            <InputRow label={<ApplyLabel >Comment</ApplyLabel>} input={<><MInput name="level1_pri_comment" /></>} />
                                        </div>
                                    </FlexSpaceBetween>
                                    <label style={{ marginTop: '10px' }}><ApplyLabel style={{ marginTop: '20px' }}>Secondary Approval</ApplyLabel></label>
                                    <FlexSpaceBetween style={{ gap: 7 }}>
                                        <div style={{ flex: 1 }}>
                                            <InputRow label={<ApplyLabel >Name</ApplyLabel>} input={
                                                <AssignToSelect
                                                    selectProps={{ style: { minHeight: '38px' } }}
                                                    hasMore={!!this.state?.userPagination?.next_page}
                                                    nextPage={this.paginateUsersOptions}
                                                    loading={(!!this.state?.userSearchCallId)}
                                                    onSearch={this.handleUserSearch}
                                                    allItems={this.state?.created_by}
                                                    items={this.state?.created_by}
                                                    name="level1_sec_Name"
                                                    label={"Select Users"}
                                                    placeholder="Select Users"
                                                    SelectItem={SelectItemUser}
                                                    SelectedItem={SelectedItem}
                                                    getOptionValue={(user: any) => `${user.first_name} ${user.last_name}`}
                                                    disabled
                                                />
                                            } />
                                        </div>
                                        <div style={{ flex: 2 }}>
                                            <InputRow label={<ApplyLabel >Comment</ApplyLabel>} input={<><MInput name="level1_sec_comment" /></>} />
                                        </div>
                                    </FlexSpaceBetween>
                                    <label ><ApplyLabel style={{ marginTop: '20px' }}>Level2*</ApplyLabel></label>

                                    <label style={{ marginTop: '10px' }}><ApplyLabel style={{ marginTop: '20px' }}>Primary Approval*</ApplyLabel></label>
                                    <FlexSpaceBetween style={{ gap: 7 }}>
                                        <div style={{ flex: 1 }}>
                                            <InputRow label={<ApplyLabel >Name*</ApplyLabel>} input={<>
                                                <SearchSelect
                                                    multiple={false}
                                                    scrollHeight={'275px'}
                                                    selectProps={{ style: { minHeight: '38px' } }}
                                                    hasMore={!!this.state?.userPagination?.next_page}
                                                    nextPage={this.paginateUsersOptions}
                                                    loading={!!this.state?.userSearchCallId}
                                                    onSearch={this.handleUserSearch}
                                                    allItems={this.state?.created_by}
                                                    items={this.state?.created_by }                                                  
                                                    name="level2_pri_Name"
                                                    label={"Select User"}
                                                    placeholder="Select User"
                                                    SelectItem={SelectItemUser}
                                                    SelectedItem={SelectedItem}
                                                    getOptionValue={(user: any) => `${user.first_name} ${user.last_name}`}
                                                    disabled
                                                />
                                            </>} />
                                        </div>
                                        <div style={{ flex: 2 }}>
                                            <InputRow label={<ApplyLabel >Comment</ApplyLabel>} input={<><MInput name="level2_pri_comment" /></>} />
                                        </div>
                                    </FlexSpaceBetween>
                                    <label style={{ marginTop: '10px' }}><ApplyLabel style={{ marginTop: '20px' }}>Secondary Approval</ApplyLabel></label>
                                    <FlexSpaceBetween style={{ gap: 7 }}>
                                        <div style={{ flex: 1 }}>
                                            <InputRow label={<ApplyLabel >Name</ApplyLabel>} input={<>
                                                <Level2ToSelect
                                                    selectProps={{ style: { minHeight: '38px' } }}
                                                    hasMore={!!this.state?.userPagination?.next_page}
                                                    nextPage={this.paginateUsersOptions}
                                                    loading={(!!this.state?.userSearchCallId)}
                                                    onSearch={this.handleUserSearch}
                                                    allItems={this.state?.created_by}
                                                    items={this.state?.created_by}
                                                    name="level2_sec_Name"
                                                    label={"Select Users"}
                                                    placeholder="Select Users"
                                                    SelectItem={SelectItemUser}
                                                    SelectedItem={SelectedItem}
                                                    getOptionValue={(user: any) => `${user.first_name} ${user.last_name}`}
                                                />
                                            </>} />
                                        </div>
                                        <div style={{ flex: 2 }}>
                                            <InputRow label={<ApplyLabel >Comment</ApplyLabel>} input={<><MInput name="level2_sec_comment" /></>} />
                                        </div>
                                    </FlexSpaceBetween>
                                    <label ><ApplyLabel style={{ marginTop: '20px' }}>Level 3</ApplyLabel></label>

                                    <label style={{ marginTop: '10px' }}><ApplyLabel style={{ marginTop: '20px' }}>Primary Approval</ApplyLabel></label>
                                    <FlexSpaceBetween style={{ gap: 7 }}>
                                        <div style={{ flex: 1 }}>
                                            <InputRow label={<ApplyLabel >Name</ApplyLabel>} input={<> <SearchSelect
                                                multiple={false}
                                                scrollHeight={'275px'}
                                                selectProps={{ style: { minHeight: '38px' } }}
                                                hasMore={!!this.state?.userPagination?.next_page}
                                                nextPage={this.paginateUsersOptions}
                                                loading={!!this.state?.userSearchCallId}
                                                onSearch={this.handleUserSearch}
                                                allItems={this.state?.created_by}
                                                items={this.state?.created_by}
                                                name="level3_pri_name"
                                                label={"Select User"}
                                                placeholder="Select User"
                                                SelectItem={SelectItemUser}
                                                SelectedItem={SelectedItem}
                                                getOptionValue={(user: any) => `${user.first_name} ${user.last_name}`}
                                            /></>} />
                                        </div>
                                        <div style={{ flex: 2 }}>
                                            <InputRow label={<ApplyLabel >Comment</ApplyLabel>} input={<><MInput name="level3_pri_comment" /></>} />
                                        </div>
                                    </FlexSpaceBetween>
                                    <label style={{ marginTop: '10px' }}><ApplyLabel style={{ marginTop: '20px' }}>Secondary Approval</ApplyLabel></label>
                                    <FlexSpaceBetween style={{ gap: 7 }}>
                                        <div style={{ flex: 1 }}>
                                            <InputRow label={<ApplyLabel >Name</ApplyLabel>} input={<>
                                                <Level3ToSelect
                                                    selectProps={{ style: { minHeight: '38px' } }}
                                                    hasMore={!!this.state?.userPagination?.next_page}
                                                    nextPage={this.paginateUsersOptions}
                                                   
                                                    loading={(!!this.state?.userSearchCallId)}
                                                    onSearch={this.handleUserSearch}
                                                    allItems={this.state?.created_by}
                                                    items={this.state?.created_by }
                                                    name="level3_sec_Name"
                                                    label={"Select Users"}
                                                    placeholder="Select Users"
                                                    SelectItem={SelectItemUser}
                                                    SelectedItem={SelectedItem}
                                                    getOptionValue={(user: any) => `${user.first_name} ${user.last_name}`}
                                                />
                                            </>} />
                                        </div>
                                        <div style={{ flex: 2 }}>
                                            <InputRow label={<ApplyLabel >Comment</ApplyLabel>} input={<><MInput name="level3_sec_comment" /></>} />
                                        </div>
                                    </FlexSpaceBetween>
                                    <label ><ApplyLabel style={{ marginTop: '20px' }}>Level 4</ApplyLabel></label>
                                    <label style={{ marginTop: '10px' }}><ApplyLabel style={{ marginTop: '20px' }}>Primary Approval</ApplyLabel></label>
                                    <FlexSpaceBetween style={{ gap: 7 }}>
                                        <div style={{ flex: 1 }}>
                                            <InputRow label={<ApplyLabel >Name</ApplyLabel>} input={<>
                                                <SearchSelect
                                                    multiple={false}
                                                    scrollHeight={'275px'}
                                                    selectProps={{ style: { minHeight: '38px' } }}
                                                    hasMore={!!this.state?.userPagination?.next_page}
                                                    nextPage={this.paginateUsersOptions}
                                                    loading={!!this.state?.userSearchCallId}
                                                    onSearch={this.handleUserSearch}
                                                    allItems={this.state?.created_by}
                                                    items={this.state?.created_by }
                                                    name="level4_pri_name"
                                                    label={"Select User"}
                                                    placeholder="Select User"
                                                    SelectItem={SelectItemUser}
                                                    SelectedItem={SelectedItem}
                                                    getOptionValue={(user: any) => `${user.first_name} ${user.last_name}`}
                                                />
                                            </>} />
                                        </div>
                                        <div style={{ flex: 2 }}>
                                            <InputRow label={<ApplyLabel >Comment</ApplyLabel>} input={<><MInput name="level4_pri_comment" /></>} />
                                        </div>
                                    </FlexSpaceBetween>
                                    <label style={{ marginTop: '10px' }}><ApplyLabel style={{ marginTop: '20px' }}>Secondary Approval</ApplyLabel></label>
                                    <FlexSpaceBetween style={{ gap: 7 }}>
                                        <div style={{ flex: 1 }}>
                                            <InputRow label={<ApplyLabel >Name</ApplyLabel>} input={<>
                                                <Level4ToSelect
                                                    selectProps={{ style: { minHeight: '56px' } }}
                                                    hasMore={!!this.state?.userPagination?.next_page}
                                                    nextPage={this.paginateUsersOptions}
                                                    loading={(!!this.state?.userSearchCallId)}
                                                    onSearch={this.handleUserSearch}
                                                    allItems={this.state?.created_by }
                                                    items={this.state?.created_by }
                                                    name="level4_sec_name"
                                                    label={"Select Users"}
                                                    placeholder="Select Users"
                                                    SelectItem={SelectItemUser}
                                                    SelectedItem={SelectedItem}
                                                    getOptionValue={(user: any) => `${user.first_name} ${user.last_name}`}
                                                />
                                            </>} />
                                        </div>
                                        <div style={{ flex: 2 }}>
                                            <InputRow label={<ApplyLabel >Comment</ApplyLabel>} input={<><MInput name="level4_sec_comment" /></>} />
                                        </div>
                                    </FlexSpaceBetween>
                                    <label ><ApplyLabel style={{ marginTop: '20px' }}>Level 5</ApplyLabel></label>
                                    <label style={{ marginTop: '10px' }}><ApplyLabel style={{ marginTop: '20px' }}>Primary Approval</ApplyLabel></label>
                                    <FlexSpaceBetween style={{ gap: 7 }}>
                                        <div style={{ flex: 1 }}>
                                            <InputRow label={<ApplyLabel >Name</ApplyLabel>} input={<> <SearchSelect
                                                multiple={false}
                                                scrollHeight={'275px'}
                                                selectProps={{ style: { minHeight: '56px' } }}
                                                hasMore={!!this.state?.userPagination?.next_page}
                                                nextPage={this.paginateUsersOptions}
                                                loading={!!this.state?.userSearchCallId}
                                                onSearch={this.handleUserSearch}
                                                allItems={this.state?.created_by }
                                                items={this.state?.created_by }
                                                name="level5_pri_name"
                                                label={"Select User"}
                                                placeholder="Select User"
                                                SelectItem={SelectItemUser}
                                                SelectedItem={SelectedItem}
                                                getOptionValue={(user: any) => `${user.first_name} ${user.last_name}`}
                                            /></>} />
                                        </div>
                                        <div style={{ flex: 2 }}>
                                            <InputRow label={<ApplyLabel >Comment</ApplyLabel>} input={<><MInput name="level5_pri_comment" /></>} />

                                        </div>
                                    </FlexSpaceBetween>
                                    <label style={{ marginTop: '10px' }}><ApplyLabel style={{ marginTop: '20px' }}>Secondary Approval</ApplyLabel></label>
                                    <FlexSpaceBetween style={{ gap: 7 }}>
                                        <div style={{ flex: 1 }}>
                                            <InputRow label={<ApplyLabel >Name</ApplyLabel>} input={<>
                                                <Level5ToSelect
                                                    selectProps={{ style: { minHeight: '56px' } }}
                                                    hasMore={!!this.state?.userPagination?.next_page}
                                                    nextPage={this.paginateUsersOptions}
                                                    loading={(!!this.state?.userSearchCallId)}
                                                    onSearch={this.handleUserSearch}
                                                    allItems={this.state?.created_by}
                                                    items={this.state?.created_by}
                                                    name="level5_sec_name"
                                                    label={"Select Users"}
                                                    placeholder="Select Users"
                                                    SelectItem={SelectItemUser}
                                                    SelectedItem={SelectedItem}
                                                    getOptionValue={(user: any) => `${user.first_name} ${user.last_name}`}
                                                />
                                            </>} />
                                        </div>
                                        <div style={{ flex: 2 }}>
                                            <InputRow label={<ApplyLabel >Comment</ApplyLabel>} input={<><MInput name="level5_sec_comment" /></>} />
                                        </div>
                                    </FlexSpaceBetween>
                                    <InputRow label={<ApplyLabel> Conclusion</ApplyLabel>} input={
                                        <MCountedTextArea disabled name="conclusion" placeholder="conclusion" />
                                    } />
                                </Form>
                            )}
                        </Formik>
                    </MiddleGrid>
                </OuterGrid>
               
            </MainBoxWrapper >
            // Customizable Area End
        )
    }
}

// Customizable Area Start
const ModalTitle = styled(Grid)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItem: 'center',
    flexDirection: 'row',
    width: '100%',
    padding: '10px 20px 10px 32px'
});
const ApplyLabel = styled('div')({
    fontSize: '15.35px',
    color: '#404040',
    fontFamily: 'HelveticaNeue'
})
const FlexColoumn = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '13px'

})
export const SelectedItem =
    ({ items, value }: { items: any, value: any }) => {
    
    return <>
        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
            {(items || []).map((selectedValue: any, i: any) => {
                return (<Typography key={i} align="left">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar className='ml10' style={{ width: '30px', height: '30px', }} src={selectedValue.profile_pic} aria-label="profile-pic"></Avatar>
                        <span className='ml5'>{`${selectedValue.name}` || ''}</span>
                    </div>
                </Typography>)
            })}
        </div>
    </>
}


export const SelectItemUser =
    ({ item, value }: { item: any, value: any }) => {

    return <>
        <Grid container>
            <Grid item xs={12} style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}>
                <div style={{ display: 'flex', alignItems: 'center', }}>
                    <Avatar src={(item?.profile_pic)} aria-label="profile-pic" style={{ marginRight: '2rem' }}>
                    </Avatar>
                    <SelectItemUserText >{`${item.name || ''}`}</SelectItemUserText>
                </div>
                <span>{`${item.email}`}</span>
                {/* <Checkbox checked={value?.indexOf?.(item.id) > -1} />  */}
            </Grid>
        </Grid>
    </>
}
const LeftGridWrapper = styled(Grid)({
    maxWidth: '300px',
    '@media screen and (max-width: 985px)': {
        maxWidth: '200px',
    },
    '@media screen and (max-width: 768px)': {
        display: 'none',
        visibility: 'hidden',
    },
});
const MainBoxWrapper = styled(Box)({
    backgroundColor: "#edebeb",
    display: "flex",
    flexWrap: 'wrap',
    flexDirection: "column",
    paddingBottom: "20px",
    overflowX: "hidden",
});
const MiddleGrid = styled(Grid)({
    width: 'calc(100vw - 300px)',
    maxWidth: 'unset',
    marginTop: '40px',
    flexBasis: 'unset',
    justifyContent: 'center',
    background: 'white',
    '@media screen and (max-width: 768px)': {
        width: '100%'
    }
});

const SelectItemUserText = styled('span')({
    overflow: 'hidden',
    textOverflow: 'ellipsis'
})
const RoundedButtonBase = styled(Button)({
    backgroundColor: '#009c05',
    color: 'white',
    borderRadius: '5px',
    padding: '10px 40px',
    float: 'right',
    '&:hover': {
        backgroundColor: '#009c05',
    },
    '@media screen and (max-width:600px)': {
        width: '100%'
    },
    '@media screen and (min-width:769px) and (max-width:1200px)': {
        width: '100%'
    }
})
const OuterGrid = styled(Grid)({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
}); const FlexSpaceBetween = styled("div")({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 0",
    // paddingBottom: '0',
    width: "100%",
    "@media screen and (max-width:600px)": {
        flexDirection: "column",
        alignItems: "normal",
    },
    "@media screen and (min-width:769px) and (max-width:1200px)": {
        flexDirection: "column",
        alignItems: "normal",
    },
});
export const AssignToSelect =(props: any)=>{
    const [field] = useField('sender_id')
    const items = props.items.filter((i: any) => i.id !== field.value)
    const allItems = props.allItems.filter((i: any) => i.id !== field.value)

    return <SearchSelect
        selectProps={props.selectProps}
        hasMore={props.hasMore}
        nextPage={props.nextPage}
        loading={props.loading}
        onSearch={props.onSearch}
        allItems={allItems}
        items={items}
        name={props.name}
        label={"Select Users"}
        placeholder="Select Users"
        SelectItem={SelectItemUser}
        SelectedItem={SelectedItem}
        onSelectInput={props.onSelectInput}
        onClose = {props.onClose}
        getOptionValue={(user: any) => `${user.first_name} ${user.last_name}`}
    />
}
export const Level2ToSelect = (props: any) => {
    const [field] = useField('sender_id')
    const items = props.items.filter((i: any) => i.id !== field.value)
    const allItems = props.allItems.filter((i: any) => i.id !== field.value)
   
    return <SearchSelect
        selectProps={props.selectProps}
        hasMore={props.hasMore}
        nextPage={props.nextPage}
        loading={props.loading}
        onSearch={props.onSearch}
        allItems={allItems}
        onSelectInput={props.onSelectInput}
        items={items}
        name="level2_sec_name"
        label={"Select Users"}
        placeholder="Select Users"
        SelectItem={SelectItemUser}
        SelectedItem={SelectedItem}
        getOptionValue={(user: any) => `${user.first_name} ${user.last_name}`}
    />
}
 export const Level3ToSelect = (props: any) => {
    const [field] = useField('sender_id')
    const items = props.items.filter((i: any) => i.id !== field.value)
    const allItems = props.allItems.filter((i: any) => i.id !== field.value)

    return <SearchSelect
        selectProps={props.selectProps}
        hasMore={props.hasMore}
        nextPage={props.nextPage}
        loading={props.loading}
        onSearch={props.onSearch}
        allItems={allItems}
        items={items}
        name="level3_sec_Name"
        label={"Select Users"}
        onSelectInput={props.onSelectInput}
        placeholder="Select Users"
        SelectItem={SelectItemUser}
        SelectedItem={SelectedItem}
        getOptionValue={(user: any) => `${user.first_name} ${user.last_name}`}
    />
}
export const Level4ToSelect = (props: any) => {
    const [field] = useField('sender_id')
    const items = props.items.filter((i: any) => i.id !== field.value)
    const allItems = props.allItems.filter((i: any) => i.id !== field.value)
    
    return <SearchSelect
        selectProps={props.selectProps}
        hasMore={props.hasMore}
        nextPage={props.nextPage}
        loading={props.loading}
        onSearch={props.onSearch}
        allItems={allItems}
        items={items}
        name="level4_sec_name"
        onSelectInput={props.onSelectInput}
        label={"Select Users"}
        placeholder="Select Users"
        SelectItem={SelectItemUser}
        SelectedItem={SelectedItem}
        getOptionValue={(user: any) => `${user.first_name} ${user.last_name}`}
    />
}
export const Level5ToSelect = (props: any) => {
    
    const [field] = useField('sender_id')
    const items = props.items.filter((i: any) => i.id !== field.value)
    const allItems = props.allItems.filter((i: any) => i.id !== field.value)
   
    return <SearchSelect
        selectProps={props.selectProps}
        hasMore={props.hasMore}
        nextPage={props.nextPage}
        loading={props.loading}
        onSearch={props.onSearch}
        allItems={allItems}
        items={items}
        name="level5_sec_name"
        label={"Select Users"}
        placeholder="Select Users"
        onSelectInput={props.onSelectInput}
        SelectItem={SelectItemUser}
        SelectedItem={SelectedItem}
        getOptionValue={(user: any) => `${user.first_name} ${user.last_name}`}
    />
}

const DownloadButton = styled(Button)({

    color: '#009c05',
    width: '300px',
    border: '2px solid #009c05',
    height: '50px',
    display: 'flex',
    fontSize: '15px',
    fontWeight: 'bold',
    borderRadius: '30px',
    justifyContent: 'space-between',
})

export const UploadButton = (props: any) => {
    const [,_,helpers] = useField(props)
    const [filename, setFilename] = React.useState(props.filename || "")
    React.useEffect(() => {
        setFilename(props.filename)
    }, [props.filename])
    const [validationError, setValidationError] = React.useState("")
    const inputRef = React.useRef<HTMLInputElement>(null)
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log(validationError)
        helpers.setTouched(true)
        const file = e.currentTarget.files && Array.from(e.currentTarget.files)
        if (!file) return
        const totalSize = file.map((f: any) => f.size).reduce((a: number, b: number) => a + b)
        if (props.maxSize && totalSize > props.maxSize) {
            setValidationError(props.maxSizeError || `Cannot upload document more than 20Mb size`)
            return
        }
        helpers.setValue(file)
        setFilename(file.map((f: any) => f.name).join(', '))
    }

    const onClick = () => inputRef?.current && inputRef?.current?.click()

    return <label>
        <RoundedButton onClick={onClick} startIcon={<img width="25px" src={iconUpload} />}>{props.label}</RoundedButton>
        <input multiple={props.multiple}id="input-buttonUpload" type="file" accept={props.filetype || "*"} onChange={onChange} ref={inputRef} name={props.name} style={{ display: 'none' }} />
        <div style={{ marginTop: '15px' }}>{filename}</div>
    </label>
}
// Customizable Area End

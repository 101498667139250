import React from "react";
// Customizable Area Start
import UpdatedReportsController, {
	Props,
} from "./UpdatedReportsController.web";
import { styled } from '@material-ui/styles'
import {
	Grid,
	Box,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableCell,
	TableRow,
	Paper,
	Avatar,
	Link
} from '@material-ui/core';
import TopNavBar from "../../../components/src/TopNavbar";
import LeftMenuWeb from "../../../components/src/LeftMenuWeb";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Pagination from '@material-ui/lab/Pagination';
import { xlsxIcon } from './assets';
import OverlayLoader from '../../../components/src/Loader.web'
// Customizable Area End
export default class ProjectActivity extends UpdatedReportsController {
	constructor(props: Props) {
		super(props);
		// Customizable Area Start
		// Customizable Area End
	}
	// Customizable Area Start
	// Customizable Area End

	render() {
		// Customizable Area Start
		const { navigation } = this.props;

		const ImportButtonBase = (props: any) => {
			const icon = xlsxIcon
			const clickHandler = props.onClick || (() => { });
			const label = "Download"
			return <ImportButton onClick={clickHandler}>
				<img src={icon} width="25px" />{label}
			</ImportButton>
		}

		const DownloadButton = () => {
			return <ImportButtonBase />
		}

  		// Customizable Area End

		return (

			// Customizable Area Start
			<MainBox>
				<Box>
					<TopNavBar navigation={this.props.navigation} id={""} />
				</Box>

				<OuterGrid container spacing={3}>
					<LeftGrid item xs={12} sm={12} md={3} lg={3}>
						<Box >
							<LeftMenuWeb /*closeModal={this.closeModal}*/ navigation={this.props.navigation} id={""} />
						</Box>
					</LeftGrid>
					<MiddleGrid item xs={12} sm={12} md={6} lg={6} >
						<div style={{ justifyContent: "space-between", display: "flex", alignItems: "center" }}>
							<div style={{ display: "flex" }} >
								<div onClick={navigation.goBack} style={{ cursor: "pointer", margin: "40px 0px 15px 12px", }} data-test-id="back-button" >
									<ArrowBackIosIcon />
								</div>
								<CoinsHead>PROJECT ACTIVITY REPORT</CoinsHead>
							</div>
							{(this.state.projectActivityUrl!==null && this.state.projectActivityUrl!==undefined && this.state.projectActivityUrl?.length>0) ? <Link href={this.state.projectActivityUrl} data-test-id="download-project-report-url">
								<DownloadButton />
							</Link> :
								<div data-test-id="download-project-report" onClick={() => this.showPopup()}>
									<DownloadButton />
								</div>
							}
						</div>
						<div style={{ marginTop: '20px', alignItems: 'center' }}>
							<TableContainer component={Paper}>
								<Table>
									<TableHead>
										<TableRowBox>
											<TableCell style={{ fontWeight: 'bold', color: 'black' }}>SL.no</TableCell>
											<TableCell style={{ fontWeight: 'bold', color: 'black' }}>Milestone ID</TableCell>
											<TableCell style={{ fontWeight: 'bold', color: 'black' }}>Milestone Name</TableCell>
											<TableCell style={{ fontWeight: 'bold', color: 'black' }}>Project ID</TableCell>
											<TableCell style={{ fontWeight: 'bold', color: 'black' }}>Project Name</TableCell>
											<TableCell style={{ fontWeight: 'bold', color: 'black' }}>Activity ID</TableCell>
											<TableCell style={{ fontWeight: 'bold', color: 'black' }}>Activity Name</TableCell>
											<TableCell style={{ fontWeight: 'bold', color: 'black' }}>Owner</TableCell>
											<TableCell style={{ fontWeight: 'bold', color: 'black' }}>Description</TableCell>
											<TableCell style={{ fontWeight: 'bold', color: 'black' }}>Is Dependent</TableCell>
											<TableCell style={{ fontWeight: 'bold', color: 'black' }}>Predecessor</TableCell>
										</TableRowBox>
									</TableHead>
									<TableBody>
										{this.state.projectActivityList?.length>0 ? this.state.projectActivityList?.map((value: any, index: any) => {
											const { attributes } = value
											return (
												<TableBodyBox>
													<TableCell>{10 * (this.state.currentPageOfProjectActivity - 1) + (index + 1)}</TableCell>
													<TableCell>{attributes?.milestone_unique_id}</TableCell>
													<TableCell >{attributes?.milestone_name}</TableCell>
													<TableCell>{attributes?.project_id}</TableCell>
													<TableCell>{attributes?.project_name}</TableCell>
													<TableCell>{attributes?.activity_id}</TableCell>
													<TableCell>{attributes?.activity_name}</TableCell>
													<TableCell>
														<div style={{ display: 'flex', gap: '10px', alignItems: 'center', justifyContent: 'center' }}>
															<Avatar style={{ width: 34, height: 34, margin: "auto" }} src={attributes?.activity_owner?.data?.attributes?.profile_pic} alt="profile picture" />
															<div>
																{attributes?.activity_owner?.data?.attributes?.first_name} {attributes?.activity_owner?.data?.attributes?.last_name}
															</div>
														</div>
													</TableCell>
													<TableCell>{attributes?.description}</TableCell>
													<TableCell>{attributes?.is_dependent}</TableCell>
													<TableCell>{attributes?.predecessor[0]?.toString()}</TableCell>
												</TableBodyBox>
											)
										}) : <TableCell style={{textAlign:"center"}} colSpan={14}>No Data Found</TableCell> }
									</TableBody>
								</Table>
							</TableContainer>
							<Pagin>
								{window.screen.width > 950 ?
									<Pagination disabled={this.state.totalPageOfProjectActivity <= 1} defaultPage={1} color="primary" variant="outlined" count={this.state.totalPageOfProjectActivity} page={this.state.currentPageOfProjectActivity} onChange={this.handlePageChangeOfProjectActivity} />
									:
									<Pagination disabled={this.state.totalPageOfProjectActivity <= 1} defaultPage={1} color="primary" size="small" count={this.state.totalPageOfProjectActivity} page={this.state.currentPageOfProjectActivity} onChange={this.handlePageChangeOfProjectActivity} />
								}
							</Pagin>
							<OverlayLoader loading={this.state.overlayLoaderForActivity} />
						</div>
					</MiddleGrid>
				</OuterGrid>
			</MainBox>
			// Customizable Area End
		)
	}
}


// Customizable Area Start
const ImportButton = styled('div')({
	cursor: 'pointer',
	marginTop: "16px",
	color: '#1F7244',
	border: '1px solid #1F7244',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	gap: '10px',
	padding: '5px 12px',
	borderRadius: '28px',
	fontSize: '13px',
})

const MainBox = styled(Box)({
	backgroundColor: "#edebeb",
	display: "flex",
	flexWrap: 'wrap',
	flexDirection: "column",
	paddingBottom: "20px",
	overflowX: "hidden",
})

const OuterGrid = styled(Grid)({
	display: 'flex',
	flexWrap: 'wrap',
	justifyContent: 'space-between',
})

const LeftGrid = styled(Grid)({
	maxWidth: '300px',
	'@media screen and (max-width: 985px)': {
		maxWidth: '200px',
	},
	'@media screen and (max-width: 768px)': {
		display: 'none',
		visibility: 'hidden',
	},
})

const MiddleGrid = styled(Grid)({
	width: 'calc(100vw - 300px)',
	maxWidth: 'unset',
	flexBasis: 'unset',
	paddingRight: '30px !important',
	justifyContent: 'center',
	'@media screen and (max-width: 768px)': {
		width: '100%'
	}
})


const TableRowBox = styled(TableRow)({
	'& th': {
		borderRight: '1px solid rgba(224, 224, 224, 1)',
		textAlign: 'center',
	},
	'& th:first-child': {
		minWidth: '45px',
	},
	'& th:nth-child(2)': {
		minWidth: '120px',
	},
	'& th:nth-child(3)': {
		minWidth: '110px',
	},
	'& th:nth-child(4)': {
		minWidth: '110px',
	},
	'& th:nth-child(5)': {
		minWidth: '90px',
	},
	'& th:nth-child(6)': {
		minWidth: '110px',
	},
})

const TableBodyBox = styled(TableRow)({
	'& td': {
		borderRight: '1px solid rgba(224, 224, 224, 1)',
		textAlign: 'center',
	},
})

const Pagin = styled('div')({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	padding: '10px',
	'@media screen and (max-width:350px)': {
		display: 'initial',
		padding: '10px 1px',
	},
	'@media screen and (min-width:768px) and (max-width:850px)': {
		display: 'initial',
		padding: '10px 1px',
	},
	'& > MuiPaginationItem-icon': {
		color: 'green',
	},

	ul: {
		"& > .MuiPaginationItem-root": {
			color: "green"
		}
	}
})

const CoinsHead = styled("div")({
	margin: "37px 0px 15px 12px",
	fontSize: "20px",
	fontFamily: "HelveticaNeue",
	fontWeight: 600,
	"@media screen and (max-width: 768px)": {
		margin: "20px 0px 10px 12px",
	},
});

// Customizable Area End
import React from 'react'
import { CreateCSSProperties, styled } from '@material-ui/styles';
// @ts-ignore
import classes from './Switch.module.css'


export default function Switch(props: any){
	return (  <label className={classes.switch}>
		  <input {...props} type="checkbox" />
		  <span className={!props.toggleDisabled ? classes.sliderDisabled : classes.slider}></span>
		</label>)
}




// const SwitchWrapper = styled('label')({
// 	position: 'relative',
//   	display: 'inline-block',
//   	width: '71px',
//   	height: '40px',
// })

// export default function Switch(props: any) {
// 	return (
// 		<SwitchWrapper >
// 			<Input checked {...props} type="checkbox" />
// 			<Slider ></Slider>
// 		</SwitchWrapper>)
// }


// const Slider = styled('span')({
// 	position: "absolute",
// 	cursor: "pointer",
// 	top: 0,
// 	left: 0,
// 	right: 0,
// 	bottom: 0,
// 	background: "#dedede",
// 	borderRadius: "40px",
// 	transition: "0.4s",
// 	"&:before": {
// 	  position: "absolute",
// 	  content: "''",
// 	  height: "32px",
// 	  width: "32px",
// 	  background: "#fff",
// 	  borderRadius: "50%",
// 	  left: "4px",
// 	  bottom: "4px",
// 	  transition: "0.4s",
// 	}
// })

// const Input = styled('input')({
// 	display: 'none',
// 	[`&:checked + ${Slider}`]: {
// 		color: 'red',
// 	},
// 	[`&:checked + ${Slider}:before`]: {
// 		translateX: '30px',
// 	}
// })
import React from "react";
// Customizable Area Start
import {
    Avatar,
} from '@material-ui/core'
import { styled } from '@material-ui/styles'
import { Formik } from 'formik'
import FormModal, { InputRow, MInput, MCountedTextArea } from '../../../components/src/FormModal.web'
import UserContext from "../../../components/src/UserProvider";
import EnquiryProjectSeekingFundController, { Props } from "./EnquiryProjectSeekingFundController";
//@ts-ignore
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import Attachments from "../../../components/src/Attachments.web";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
// Customizable Area End

export default class CreateSurveys extends EnquiryProjectSeekingFundController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    selectedItem: any;
    getuserDetails: any;
    CreateTemplateModal() {
        const getInitialData = () => {
            const attributes = this.state.projectPromoteData?.attributes || {};
            const project_unique_id = attributes?.project_unique_id || ''

            return {
                ...attributes,
                project_unique_id

            };
        };

        const initialValues = getInitialData();
        return <Formik enableReinitialize initialValues={initialValues} onSubmit={this.handleCreateTemplateSubmit } validationSchema={this.validationSchema}
        >
            {() => (
                <ModalWrap>
                    <FormModal modalStyle={{ maxWidth: '700px' }} headerTitle={<ModalTitleWrapper><ArrowBackIosIcon onClick={this.hideModal} /><b>Enquiry</b></ModalTitleWrapper>} submitLabel={"submit"} onClose={this.hideModal} >
                        <InputRow label="Project Name" input={
                            <MInput name="project_name" placeholder="" disabled />
                        } />
                        <InputRow label="Project ID" input={
                            <MInput name="project_unique_id" disabled />
                        } />
                        <InputRow label="SDG Foucs" input={
                            <AvatarGroup spacing='small' max={4} >
                                {this.state.projectPromoteData?.attributes?.images?.map((sdg: any) => (
                                    <Avatar alt='' src={sdg[0]?.image_url} />
                                ))}
                            </AvatarGroup>
                        } />

                        <InputRow label="Requested By" input={
                            <MInput name="project_leader" disabled />
                        } />

                        <InputRow label="Request Description*" input={
                            <MCountedTextArea length={100} name="request_description" placeholder="" />
                        } />

                        <InputRow label="Project Link" input={
                            <MInput name="project_link" disabled />
                        } />

                        <div style={{ marginTop: '-5%' }}>
                            {!!this.state.projectPromoteData?.attributes?.docs ?
                                <Attachments files={this.state.projectPromoteData?.attributes?.docs} />
                            :
                            <div style={{ marginTop: '23px' }} >
                                No Attachment Found
                            </div>
                        }
                        </div>
                       
                        <InputRow label="Company Name" input={
                            <MInput name="company_title" placeholder="" />
                        } />
                        <InputRow label="Business Email" input={
                            <MInput name="business_email" placeholder="" />
                        } />

                    </FormModal>
                </ModalWrap>
            )}
        </Formik>
    }

    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            <>
                {this.CreateTemplateModal()}
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

EnquiryProjectSeekingFundController.contextType = UserContext


const ModalWrap = styled('div')({
})

const ModalTitleWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center'
})


// Customizable Area End
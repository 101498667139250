import React from "react";

// Customizable Area Start
import {
	Box,
	Menu,
	Avatar,
	TextField,
	Hidden,
	MenuItem,
	InputAdornment,
	Backdrop,
	Card,
	CircularProgress,
	Button,
	IconButton,
	Grid,
	Modal,
	Typography,
	TableBody,
	TableContainer,
	Paper,
	TableHead,
	TableCell as TableCol,
	TableRow,
	Table
} from "@material-ui/core";
import { commentCount } from "../../ActivityFeed/src/assets";
import DateFnsUtils from '@date-io/date-fns';
import CloseIcon from "@material-ui/icons/Close";
import TopNavBar from "../../../components/src/TopNavbar";
import LeftMenuWeb from "../../../components/src/LeftMenuWeb";
import { styled } from '@material-ui/styles';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import Flex from "../../../components/src/Flex.web";
import { SeekingFundDetailModel } from "../../../blocks/Surveys/src/SeekingFundDetailModel.web";
import { InputRow, MInput } from "../../../components/src/FormModal.web";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { contributors, ArrowDownIcon, iconCorpstage, ic_greenthumb, editIcon } from './assets';
import { Formik, Form } from 'formik';
import { Link } from 'react-router-dom'
import UserContext from "../../../components/src/UserProvider";
import { CloseCircleIcon } from "../../../components/src/Icons.web";
import NestedMenuItem from "../../../components/src/NestedMenuItem.web";
import RightMenuWeb from "../../../components/src/RightMenuWeb";
import { DatePicker } from '@material-ui/pickers'

export const configJSON = require("./config");

// Customizable Area End

import MyProjectsController, { Props } from "./MyProjectsController.web";
import { CommonActions } from "../../../components/src/PostCard.web";
import LikeAPost from "../../LikeAPost/src/LikeAPost.web";
import Search from "../../../components/src/Search.web";

const statusButton = (status: any) => {
	let statusButton;

	switch (status) {
		case 'Open':
			statusButton = <LiveBtnOpen>Open</LiveBtnOpen>;
			break;
		case 'Closed':
			statusButton = <LiveBtnClosed>Closed</LiveBtnClosed>;
			break;
		case 'Funded':
			statusButton = <LiveBtnFunded>Funded</LiveBtnFunded>;
			break;
		
		default:
			statusButton = <></>;
	}

	return statusButton;
}

export default class ProjectEndUser extends MyProjectsController {
	constructor(props: Props) {
		super(props);
		// Customizable Area Start
		// Customizable Area End
	}

	// Customizable Area Start

	showCollaboratorsListModal = (value:any) => {
		const { tableRowId, showCollaborators } = this.state;
		const shouldShowModal = tableRowId !== "" && tableRowId === value?.id && showCollaborators;
	
		if (shouldShowModal) {
			return (
				showCollaboratorList(
					value,
					showCollaborators,
					this.handleCloseCollaborators)
				
			);
		}
	};

	sdgFactorsData = (value:any) => {
		const { tableRowId, showSdg } = this.state;
		const shouldShowModal = tableRowId === value?.id && showSdg;
		const sdgFactorsData = value?.attributes?.sdg_factors;
	
		if (shouldShowModal) {
			return (
				<SdgFactors
					openModal={showSdg}
					onClose={this.handleCloseSDGFactors}
					data={sdgFactorsData}
				/>
			);
		}
	};

	showProjects(value: any, attributes: any) {
		return (
			<>
				<ProjectTabCard>
					<ProjectTabCardHeader>
						<ProjectDiv>
							<Link 
								to={`/user/${attributes?.created_by?.data?.attributes?.id}`}
							>
								<div 
									style={{ cursor: "pointer", 
									width: '55px', 
									height: '55px', 
									background: 'gray', 
									borderRadius: '50%'
								}}>
									<img 
										style={{ width: '55px', 
										height: '55px', 
										borderRadius: '50%' 
									}} 
									src={attributes?.created_by?.data?.attributes?.profile_pic} 
									/>
								</div>
							</Link>
							<div 
								style={{ display: 'flex', 
								flexDirection: 'column', 
							}}
							>
								<div 
									onClick={() => this.goToProjectDetailEndUser(value?.id)} 
									style={{ cursor: "pointer", color: '#000', fontSize: '19px', marginBottom: '7px' }}
								>
									<Typography style={{ fontWeight: "bold" }}>
										{attributes?.project_name}
									</Typography>
								</div>
								<div onClick={() => this.goToProjectDetailEndUser(value?.id)} style={{ cursor: "pointer", color: 'rgb(119, 113, 113)', fontSize: '16px', display: "flex", width: "100%" }}>
									<div><Typography>{attributes?.location}</Typography></div>
									<div 
										style={{ marginLeft: "240px", 
										display: "flex" }}
									>
										<Typography 
											style={{ fontWeight: "bold" ,
											color:'#4a4747'}}
											>Preference: </Typography>
										<Typography style={{ marginLeft: "6px", color: "rgb(119, 113, 113)" }}>{this.showPreference(attributes?.preference)}</Typography>
									</div>
								</div>
								<div style={{ color: 'rgb(79, 74, 74)',display: "flex", fontSize: '16px', marginTop: "6px" }}>
									<Typography style={{ marginRight: "7px" ,fontWeight: "bold" }}> Company Name: </Typography>
									<Typography data-testid="companyLink" style={{ fontSize: '16px', textDecorationLine: "underline", cursor: "pointer", color: 'rgb(119, 113, 113)' }} onClick={() => this.navigationFun({id:attributes?.corporate_id, props:this.props, screenName:"CorporateProfileEndUser"})}>
										{attributes?.company_name}
									</Typography> 
								</div>
							</div>
						</ProjectDiv>
						<div 
							onClick={() => this.goToProjectDetailEndUser(value?.id)}
						  style={{ cursor: "pointer", color: 'rgb(119, 113, 113)', fontSize: '16px', gap: '10px', display: 'flex', alignItems: 'center' }}><img style={{ width: '25px' }} src={iconCorpstage} alt="icon" />
							<Typography style={{paddingTop: '2px'}}>
								{attributes?.completed_project_milestones}/{attributes?.total_project_milestones}
							</Typography>
						</div>
					</ProjectTabCardHeader>
					<p 
						style={{ wordWrap: "break-word", color: 'rgb(119, 113, 113)', fontSize: '16px', marginBottom: '20px' }}
					>
							{attributes?.description?.length > 455 ? `${attributes?.description.substring(0, 455)}` : attributes?.description}{attributes?.description?.length > 455 ? <Link style={{ fontWeight: "bold" }} to={`/all_project_end_user/projects/${value?.id}`}>...See more</Link> : null}
					</p>
					{attributes?.project_profile_picture?.length>0 && <div onClick={() => this.goToProjectDetailEndUser(value?.id)} style={{ cursor: "pointer", borderRadius: '6px', minHeight: '100px', width: '100%', marginBottom: '20px' }}>
						<img style={{ width: '100%', height: '41vh', borderRadius: '8px' }} src={attributes?.project_profile_picture} />
					</div>}
					<div 
						style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}
					>
						<div style={{ gap: '10px', alignItems: "baseline" , display: 'flex',}}>
							<span style={{ color: '#000', fontSize: '14px' }}>Collaborators :</span> {attributes && Array.isArray(attributes.project_collaborators) && attributes.project_collaborators.length>0 && <><div data-test-id="Collaborator-button" onClick={() => this.handleShowCollaborators(value?.id)} style={{ color: '#989898', fontSize: '12px', cursor:'pointer', display: "flex" }}>
								{attributes.project_collaborators.slice(0, 3).map((item: any) => (item.data && item.data.attributes?.image ? 
								<img style={{ width: "25px", height: '25px', objectFit: 'cover', border: 'solid 1px #196519', borderRadius: "50%", marginRight: '-10px', verticalAlign: "middle", backgroundColor: '#fff',  }} src={item?.data?.attributes?.image} /> 
								: <Avatar style={{ width: "25px", height: '25px', border: 'solid 1px #196519', marginRight: '-10px', borderRadius: "50%", verticalAlign: "middle" , backgroundColor:'a9a9a970',color:'#000', fontSize:'14px', }}>
									{item.data.attributes?.first_name?.charAt(0)?.toUpperCase()}
									</Avatar>))}
						</div>
							<div>
								{attributes.project_collaborators.length > 3 && <div data-test-id="Collaborator-button-more" onClick={() => this.handleShowCollaborators(value.id)} style={{ cursor: "pointer", marginLeft: '5px', fontSize: "14px", color: "rgb(119, 113, 113)" }}>{` +${value?.attributes?.project_collaborators?.length - 3} more`}</div>}
							</div></>}
						</div>
						<div style={{ display: 'flex', gap: '10px', alignItems: "baseline" }}><span style={{ color: '#000', fontSize: '14px' }}>SDG Factors :</span>
							<div style={{ color: 'rgb(119, 113, 113)', fontSize: '14px', display: "flex", marginRight: "14px" }}>
								<div data-test-id="SDG-button" onClick={() => attributes?.sdg_factors?.length>0 && this.handleOpenSDGFactors(value?.id)} style={{cursor:'pointer', marginRight: "7px", display: "flex" }}>
									{attributes?.sdg_factors !== "" && attributes?.sdg_factors?.slice(0, 5)?.map((value: any) => {
										return (
											<div>
												<img 
												style={{ width: "25px", height: '25px', objectFit: 'cover', border: 'solid 1px #196519', marginRight: '-10px', borderRadius: "50%", verticalAlign: "middle" }} src={value?.data?.attributes?.image} 
												/>
											</div>
										)
									})}
								</div>
								{attributes?.sdg_factors !== "" && attributes?.sdg_factors?.length > 5 && <Typography style={{ marginLeft: "6px", cursor: "pointer" }} onClick={() => this.handleOpenSDGFactors(value?.id)}>{`+${attributes?.sdg_factors?.length - 5} more`}</Typography>}
							</div>
						</div>
					</div>
					<div 
						style={{ display: 'flex', marginTop: '20px', justifyContent: 'space-between', marginBottom: '10px' }}
					>
						<div 
							style={{ display: 'flex', gap: '10px', }}
						>
							<div 
							style={{ color: 'rgb(119, 113, 113)', fontSize: '14px', gap: '6px', display: 'flex', alignItems: 'center' }}>
								<img style={{ width: '18px' }} src={ic_greenthumb} /> <div style={{ color: '#000', fontSize: "14px",paddingTop: '2px' }}>
									{attributes?.project_likes_count}
								</div>
								<img style={{ width: '18px' }} src={commentCount} /> <div style={{ color: '#000', fontSize: "14px", paddingTop: '2px' }}>{attributes?.project_comments_count}</div>
							</div>
							<div onClick={() => this.handleTopContributorsModalOpen(value?.id)} 
							style={{ color: '#000', fontSize: "14px", gap: '6px', display: 'flex', alignItems: 'center', cursor: "pointer" }}><img src={contributors} style={{ width: "19px" }} /> Top Contributors</div>
						</div>
						<div><Button id='clickOnRequestToCollaborate' onClick={() => this.props.navigation.history.push(`${this.props.navigation.history.location.pathname}/RequestToCollaborate/${value.id}`)} style={{ color: 'rgb(0, 161, 0)', border: 'solid 1px rgb(0, 161, 0)', borderRadius: '25px', fontWeight: 600, fontSize: '13px', padding: '0px 10px 0px 10px', textTransform:"none" }}>Request To Collaborate</Button></div>
					</div>
				</ProjectTabCard>
				<CommonActions
					onShareClick={this.handleShareCourse(value)}
					onStarClick={this.handleFavClick(value?.id)}
					isFavourite={attributes?.is_favourite}
					onCommentClick={this.goToEvent(value?.id)}
					isProject={true}
					onSurveyClick={this.handleSurveyOpen(value?.id)}
					likeButton={
						<LikeAPost
							id=""
							navigation={this.props.navigation}
							likeable_type="BxBlockProjectManagement::ProjectManagement"
							isLiked={Boolean(attributes?.project_likes?.some((project: any) => Number(project.like_by_id) === Number(this.state.userID)))}
							post_id={value?.id}
							like_id={attributes?.project_likes.find((project: any) => Number(project.like_by_id) == (this.state.userID))?.id}
							onLikeChange={this.onLikeChangeForProjects}
							onLikeIdUpdate={this.onLikeIdUpdateForProjects}
						/>}
				/>
				{this.state.tableRowId !== "" && this.state.tableRowId == value?.id && <ShowTopContibutors data={attributes?.top_contributors} handleTopContributorsModalClose={this.handleTopContributorsModalClose} openModal={this.state.openContributros} />}
				{this.showCollaboratorsListModal(value)}
				{this.sdgFactorsData(value)}
			</>
		)
	}

	showPreference = (value:any) => {
		if(value!==null || value!=="null"){
			if(value=="S"){
				return "Social"
			}
			else if(value=="E"){
				return "Environment"
			}
			else if(value=="G"){
				return "Governance"
			}
			else {
				return ""
			}
		}
		else{
			return ""
		}
	}

	showProjectAndFeeds = () => {
		if(this.state.showSeekingFundTab){
			if(this.state.seekingFundsData?.length>0){
				return(
					<SectionItem style={{ display: 'flex', gap: '15px', marginTop: '20px', flexWrap: 'wrap', }}>
						{this.state.seekingFundsData?.map((value: any) => {
							const { attributes } = value
							return (
								<Card 
								style={{ borderRadius: "10px", marginBottom: '20px', width: 'calc(50% - 20px)', border: "1px solid #f2f2f2", }}
								>
									<CardSection id='SeekingCardBtn' style={{ width: '100%', boxSizing: 'border-box', cursor: 'pointer' }} onClick={() => this.handleseekingFunds(value)}>
										<DivSection id="ModelClickDiv">
											<DetailSection style={{ marginBottom: '15px', }}>
												<Box style={{ display: 'flex', alignItems: 'center', gap: '10px', }}>
													<Avatar src={attributes?.image} />
													<SectionItem style={{ fontSize: "22px" }}>
														{attributes?.project_name}
													</SectionItem>
												</Box>

												<SectionItem 
													style={{ display: 'flex', alignItems: 'center', gap: '15px', margin: '0', }}
												>
												{statusButton(attributes?.project_status)}
												</SectionItem>
											</DetailSection>
											<span >

												<DetailSection style={{ marginBottom: '10px' }}>
													<SectionText>
														<Typography variant="subtitle1" component="div">Project ID</Typography>
														<Typography variant="subtitle2" component="div">
															{attributes?.project_unique_id}
														</Typography>
													</SectionText>
													<SectionText>
														<Typography variant="subtitle1" component="div">Location</Typography>
														<Typography variant="subtitle2" component="div">
															{attributes?.location}
														</Typography>
													</SectionText>
												</DetailSection>

												<DetailSection style={{ marginBottom: '10px' }}>
													<SectionText>
														<Typography component="div" variant="subtitle1" >Organization Name</Typography>
														<Typography variant="subtitle2" component="div">
															{attributes?.organization_name}
														</Typography>
													</SectionText>
													<SectionText>
														<Typography variant="subtitle1" component="div">Preferences</Typography>
														<Typography component="div" variant="subtitle2">
															{attributes?.preferences?.toString()}
														</Typography>
													</SectionText>
												</DetailSection>

												<DetailSection>
													<SectionText>
														<Typography variant="subtitle1" component="div">Funding Required</Typography>
														<Typography variant="subtitle2" component="div">
															{attributes?.funding_amount}
														</Typography>
													</SectionText>
													<SectionText>
														<Typography variant="subtitle1" component="div">SDG Factors</Typography>
														<div style={{ display: "flex" }}>
															{attributes?.sdg_factors !== "" && attributes?.sdg_factors !== null && attributes?.sdg_factors !== undefined && attributes?.sdg_factors?.map((val: any) => {
																return (
																	<Avatar style={{ borderRadius: "50%", width: "25px", height: '25px', objectFit: 'cover', border: 'solid 1px #196519', marginRight: '-10px',  verticalAlign: "middle" }} src={val?.image_url} />
																)
															})}
														</div>
													</SectionText>
												</DetailSection>
											</span>
										</DivSection>
									</CardSection>
								</Card>
							)
						})}
					</SectionItem>
				)
			}
			else{
				return <div style={{ width: "50%", minHeight: "144px", display: "flex", justifyContent: "center",  alignItems: "center" }}>No Data Found</div>
			}
		}

		if(this.state.allProjects){
			if(this.state.loader){
				return <div style={{ minHeight: "350px", display: "flex", justifyContent: "center", width: "100%",  alignItems: "center" }}><CircularProgress size={40} /></div>
			}
			else if(Array.isArray(this.state.allProjectsData) && this.state.allProjectsData.length){
				return(
					this.state.allProjectsData.map((value: any) => {
						const { attributes } = value
						return <ProjectTabContainer>
							{this.showProjects(value, attributes)}
						</ProjectTabContainer>
					})
				)
			}
			else{
				return <div style={{ minHeight: "144px", alignItems: "center", display: "flex", justifyContent: "center", width: "50%" }}>No Data Found</div>
			}
		}
	}

	ProjectEndUserTabs() {
		return <>
					<div style={{ display: 'flex', flexDirection: 'column', }}>
						<div style={{ display: 'flex',width: 'max-content', flexDirection: 'column', marginTop: "25px" }}>
							<RoundedButtonBase style={{ display: 'flex', justifyContent: 'space-between',}}>
								<CorporateLicenceTabs
									id="chooseAllProjects"
									onClick={this.chooseAllProject}
									style={this.state.activeAllProjectTab ? { backgroundColor: "#00A100", color: "white" } : { backgroundColor: "white" }}
								>PROJECTS</CorporateLicenceTabs>
								<IndividualUserTabs
									id="projectSeekingBtn"
									onClick={this.chooseSeekingTab}
									style={this.state.activeSeekingFundTab ? { color: "white" ,  backgroundColor: "#00A100",} : { backgroundColor: "white" }}
								>PROJECT SEEKING FUNDS</IndividualUserTabs>
							</RoundedButtonBase>
							</div>
						<div style={{ width: '100%', display: 'flex', flexDirection: 'column', }}>
							{this.state.allProjects &&
									<Grid style={{ width: '100%', marginTop: '25px', gap: '15px', display: 'flex' }}>
									<Flex style={{ width: "30%" }}>
										<Search searchInputValue={this.state.searchInputValue} onChange={(e: any) => this.handleFilterQueryChange(e)} />
									</Flex>
									<Flex style={{ minWidth: '300px', display: "flex", gap: '10px', }}>
										<div style={{ flex: 1 }}>
											<TextFieldRounded
												value={this.state.filterText}
												onClick={this.handleFilterMenuOpen}
												// @ts-ignore 
												InputProps={{
													ref: this.filterRef,
													endAdornment: (<InputAdornment position="start">
														{this.state.filterText && <CloseCircleIcon
															onClick={this.clearFilters}
															style={{ margin: '0 10px', cursor: 'pointer' , width: '24px'}}
														/>}
														<img src={ArrowDownIcon} width="16" />
													</InputAdornment>)
												}} variant="outlined" placeholder={"Filter by:"} />
											{/* @ts-ignore */}
											<Hidden mdDown>
												<FilterMenu
													onClose={this.handleFilterMenuClose}
													userId={this.context?.id}
													sdgFocous={this.state.sdgFocousData}
													menuPosition={this.state.filterMenuPosition}
													filterByAll={this.state.filterByAll}
													filterByCreatedBy={this.state.filterByCreatedBy}
													filterByCreatedOn={this.state.filterByCreatedOn}
													filterBySDGFactorsId={this.state.filterBySDGFactorsId}
													handleDateInputFocus={this.handleDateInputFocus}
													handleDateInputBlur={this.handleDateInputBlur}
													filterByDimensions={this.state.filterByDimensions}
													toggleFilterByAll={this.toggleFilterByAll}
													toggleFilterByCreatedBy={this.toggleFilterByCreatedBy}
													toggleFilterByCreatedOn={this.toggleFilterByCreatedOn}
													toggleFilterSDGFactor={this.toggleFilterSDGFactor}
													toggleFilterByDimensions={this.toggleFilterByDimensions}
													startDateChange={this.startDateChange}
													createdOn={this.state.createdOn}
													dateControls={{
														handleFromDateChange: this.handleFromDateChange,
														handleToDateChange: this.handleToDateChange,
														handleSubmit: this.handleDateFilterSubmit,
														handleCancel: this.handleDateFilterCancel,
														filterFromDate: this.state.filterFromDate,
														filterToDate: this.state.filterToDate,
														minDate: this.state.filterFromDate,
														maxDate: this.state.filterToDate
													}}
												/>
											</Hidden>
										</div>
									</Flex>
								</Grid>}
						</div>
					</div>
					{this.showProjectAndFeeds()}
				</>
	}
	// Customizable Area End
	render() {
		return (
			// Customizable Area Start
			<>
				<MainBox style={{ flexDirection:"column", backgroundColor:"#edebeb", display:"flex", paddingBottom:"20px",overflowX:"hidden"}}>
						<Box>
							<TopNavBar navigation={this.props.navigation} id={""} />
						</Box>

						<OuterGridContainer container spacing={3}>
							<LeftGridContainer item xs={12} sm={12} md={3} lg={3}>
								<Box >
									<LeftMenuWeb navigation={this.props.navigation} id={""} />
								</Box>
							</LeftGridContainer>
							<MiddleGridContainer item xs={12} sm={12} md={6} lg={6} >
								{this.state.openSeekingFunds ? <Modal
								aria-labelledby="simple-model-title"
								id="ModalComponent"
								aria-describedby="simple-model-description"
								onClose={() => { this.setState({ openSeekingFunds: false }) }}
								open={this.state.openSeekingFunds}
							>

								<SeekingFundDetailModel 
									detail={this.state.viewProjectData} 
									navigation={this.props.navigation} 
									handleLikeChange={this.handleLikeChange} 
									onCloseClick={() => { this.setState({ ...this.state, openSeekingFunds: false }) }} 
								/>

							</Modal> : 
							this.ProjectEndUserTabs()}
							</MiddleGridContainer>
							<RightGridContainer  item xs={12} sm={12} md={3} lg={3}>
							<Box >
								<RightMenuWeb navigation={this.props.navigation} id={""}/>
								</Box>
							</RightGridContainer>
						</OuterGridContainer>
				</MainBox>
			</>
			// Customizable Area End
		);
	}
}

// Customizable Area Start
MyProjectsController.contextType = UserContext


interface PropsSdgFactor {openModal: boolean,data: any,onClose: any}

const SdgFactors = ({ 
	onClose,
	data, 
	openModal }: PropsSdgFactor) => {
	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",

				}}
				open={openModal}
				onClose={() => {
					onClose()
				}}
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
				closeAfterTransition
			>
				<ModalWrapper
					
				className="schedule_modal">
					<Grid container spacing={0}>
						<ModalTitle item xs={12} >
							<Typography style={{ paddingTop: '14px', fontWeight: 'bold' }}>
								SDG Factors
							</Typography>
							<CloseIconButton onClick={() => {
								onClose()
							}}>
								<CloseIcon style={{}} />
							</CloseIconButton>
						</ModalTitle>

						<Grid item xs={12} style={{
							alignItems: 'center',
							display: 'flex',
							justifyContent: 'center',
							flexDirection: 'column',
							padding: '18px 36px'
						}}>
								<RightFormWrapperDiv>
									<Grid item xs={12}>
										<TableContainer component={Paper}>
											<Table>
												<TableHead>
													<TableCol style={{ fontWeight: 'bold', color: 'black' }}>S.No</TableCol>
													<TableCol style={{ fontWeight: 'bold', color: 'black' }}>Goal</TableCol>
													<TableCol style={{ fontWeight: 'bold', color: 'black' }}>Picture</TableCol>
												</TableHead>
												<TableBody>
													{data?.map((value: any, index: any) => {
														return (
															<TableRow>
																<TableCol>
																	{index + 1}
																</TableCol>
																<TableCol>
																	{value?.data?.attributes?.goal}
																</TableCol>
																<TableCol>
																	<Avatar 
																	style={{ width: "25px", height: "25px" }}
																	src={value?.data?.attributes?.image} />
																</TableCol>
															</TableRow>
														)
													})}
												</TableBody>
											</Table>
										</TableContainer>
									</Grid>
								</RightFormWrapperDiv>
						</Grid>
					</Grid>
				</ModalWrapper>
			</Modal>
		</div >
	)
}

interface FilterMenuProps {
	filterByCreatedBy: boolean
	filterByCreatedOn: boolean
	filterByAll: boolean
	filterBySDGFactorsId: boolean
	filterByDimensions: boolean
	toggleFilterByAll: any
	toggleFilterByCreatedBy: any
	toggleFilterByCreatedOn: any
	toggleFilterSDGFactor: any
	toggleFilterByDimensions: any
	menuPosition: {
		top: number,
		left: number,
	} | undefined
	onClose: () => void,
	inside?: string,
	sdgFocous: any,
	startDateChange: any,
	createdOn: any,
	handleDateInputFocus:any,
	handleDateInputBlur:any,
	userId: any,
	dateControls:any,
}

export const FilterMenu = ({handleDateInputBlur,handleDateInputFocus,dateControls, userId, createdOn, startDateChange, sdgFocous, menuPosition, onClose, ...props }: FilterMenuProps) => {
	const [nestedMenuName, setNestedMenuName] = React.useState("")
	const clearFromDate = (e: React.MouseEvent) => {
    e.stopPropagation()
    dateControls.handleFromDateChange(null)
  }
  const clearToDate = (e: React.MouseEvent) => {
    e.stopPropagation()
    dateControls.handleToDateChange(null)
  } 
	return (
		<>
			<Menu open={!!menuPosition} anchorPosition={menuPosition} onClose={onClose} anchorReference="anchorPosition">
				<MenuItem selected={props.filterByAll} onClick={() => props.toggleFilterByAll('All')}>
					All
				</MenuItem>
				<NestedMenuItem
					name="sdg_factors_ids"
					nestedMenuName={nestedMenuName}
					setNestedMenuName={setNestedMenuName}
					label="SDG Factors"
					parentMenuOpen={!!menuPosition}
					// @ts-ignore
					MenuProps={{
						getContentAnchorEl: null,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						transformOrigin: {
							vertical: 'top',
							horizontal: 'left',
						},
					}}
				>
					<FilterByDateWrapper style={{ height: 'auto', width: 'auto' }}>
						{sdgFocous?.map((val: any) => {
							return (
								<MenuItem onClick={() => props.toggleFilterSDGFactor(val)} selected={props.filterByDimensions}>
									{val?.attributes?.goal}
								</MenuItem>
							)
						})}
					</FilterByDateWrapper>
				</NestedMenuItem>
				<NestedMenuItem
					name="dimensions"
					nestedMenuName={nestedMenuName}
					setNestedMenuName={setNestedMenuName}
					label="Dimensions"
					parentMenuOpen={!!menuPosition}
					// @ts-ignore
					MenuProps={{
						getContentAnchorEl: null,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						transformOrigin: {
							vertical: 'top',
							horizontal: 'left',
						},
					}}
				>
					<FilterByDateWrapper style={{ height: 'auto', width: 'auto' }}>
						<MenuItem onClick={() => props.toggleFilterByDimensions('Environment')} selected={props.filterByDimensions}>
							Environment
						</MenuItem>
						<MenuItem onClick={() => props.toggleFilterByDimensions('Social Capital')} selected={props.filterByDimensions}>
							Social Capital
						</MenuItem>
						<MenuItem onClick={() => props.toggleFilterByDimensions('Human Capital')} selected={props.filterByDimensions}>
							Human Capital
						</MenuItem>
						<MenuItem onClick={() => props.toggleFilterByDimensions('Human Capital')} selected={props.filterByDimensions}>
							Business Model & Inovation
						</MenuItem>
						<MenuItem onClick={() => props.toggleFilterByDimensions('Human Capital')} selected={props.filterByDimensions}>
							Leadership & Governance
						</MenuItem>
					</FilterByDateWrapper>
				</NestedMenuItem>

				<NestedMenuItem
					name="created_on"
					nestedMenuName={nestedMenuName}
					setNestedMenuName={setNestedMenuName}
					// keepOpen={keepOpen}
					label="Based on Date"
					parentMenuOpen={!!menuPosition}
					// @ts-ignore
					MenuProps={{
					getContentAnchorEl: null,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					transformOrigin: {
						vertical: 'top',
						horizontal: 'left',
					},
					}}
				>
					<FilterByDateWrapperForDate>
					<FilterByDateRow>
						Choose Date
					</FilterByDateRow>
					<FilterByDateRow>
						<span style={{ flex: 1 }}>From</span><div style={{ flex: 3 }}>
						{/* @ts-ignore  */}
						<DatePicker
							inputVariant="outlined"
							style={{ width: '80%' }}
							onChange={dateControls.handleFromDateChange}
							variant="inline"
							clearable
							value={dateControls.filterFromDate}
							autoOk={true}
							format="yyyy-MM-dd"
							InputProps={{
							endAdornment: (<InputAdornment position="end">
								{dateControls.filterFromDate && <CloseCircleIcon
								style={{cursor: 'pointer', width: '24px' }}
								onClick={clearFromDate}
								/>}
							</InputAdornment>)
							}}
							maxDate={new Date()}
							inputProps={{
							placeholder: 'Select',
							onFocus: handleDateInputFocus,
							onBlur: handleDateInputBlur,
							}}
							initialFocusedDate={dateControls.filterToDate}
						/>

						</div>
					</FilterByDateRow>
					<FilterByDateRow><span style={{ 
						flex: 1 
					}}>To</span>
						<div style={{ flex: 3 }}>
						<DatePicker autoOk={true}
							inputVariant="outlined"
							clearable
							variant="inline"
							minDate={dateControls.filterFromDate || new Date()}
							onChange={dateControls.handleToDateChange}
							value={dateControls.filterToDate}
							inputProps={{
								placeholder: 'Select', onFocus: handleDateInputFocus,
								onBlur: handleDateInputBlur
								}}
							format="yyyy-MM-dd"
							maxDate={new Date()}
							style={{ width: '80%' }}
							// @ts-ignore
							// shouldDisableDate={(date: any)=> dateControls.filterFromDate && date<dateControls.filterFromDate}
							InputProps={{
							endAdornment: (<InputAdornment position="end">
								{dateControls.filterToDate && <CloseCircleIcon
								onClick={clearToDate}
								style={{ width: '24px', cursor: 'pointer' }}
								/>}
							</InputAdornment>)
							}}
							initialFocusedDate={dateControls.filterFromDate}
						/>

						</div>
					</FilterByDateRow>
					<FilterByDateRow>
						<div style={{ flex: 1 }}></div><div style={{ flex: 3 }}>
						<CancelButton onClick={dateControls.handleCancel}>Cancel</CancelButton>
						<SubmitButton onClick={dateControls.handleSubmit}>Submit</SubmitButton>
						</div>
					</FilterByDateRow>
					</FilterByDateWrapperForDate>
				</NestedMenuItem>
				<MenuItem onClick={() => props.toggleFilterByCreatedBy(userId)} selected={props.filterByCreatedBy}>Created by Me</MenuItem>
			</Menu>
		</>
	)
}


const showCollaboratorList = (data:any, openModal:boolean, handleCloseCollaborators:any) => {
	return (
		<Modal
			onClose={() => {
				handleCloseCollaborators()
			}}
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
			open={openModal}
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,}}
			closeAfterTransition

		>
			<ModalWrapper
				 className="schedule_modal"
			>
				<Grid 
				container 
				spacing={0}
				>

					{/* Modal Title */}
					<ModalTitle 
					item xs={12} 
					>
						<Typography data-test-id="model-title" style={{ paddingTop: '14px', fontWeight: 'bold' }}>
							Collaborators
						</Typography>
						{/* Close Btn */}
						<CloseIconButton 
						data-test-id='model-close-button'
						onClick={() => {
							handleCloseCollaborators()
						}}>
							<CloseIcon style={{}} />
						</CloseIconButton>
						{/* Close Btn END */}
					</ModalTitle>
					{/* Modal Title END */}

					<Grid item 
					xs={12} 
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						padding: '18px 36px',
						flexDirection: 'column',
						maxHeight: "550px",
					}}>
							<RightFormWrapperDiv>
								<Grid item xs={12}>
									<div>
										<Table 
										style={{ minWidth: 650 }} 
										aria-label="simple table"
										>
											<TableHead>
												<TableRow>
													<TableCol>S.No</TableCol>
													<TableCol>Name</TableCol>
													<TableCol>Profile</TableCol>
													<TableCol>Location</TableCol>
												</TableRow>
											</TableHead>
											<TableBody>
												{data?.attributes?.project_collaborators?.map((item: any, index: any) => {
													const {data} = item
													return (
														<TableRow>
															<TableCol>{index + 1}</TableCol>
															<TableCol>{data?.attributes?.full_name}</TableCol>
															<TableCol>{<Avatar style={{ width: 34, height: 34, margin: "auto" }} src={data?.attributes?.image} />}</TableCol>
															<TableCol>{data?.attributes?.location}</TableCol>
														</TableRow>
													)
												})}
											</TableBody>
										</Table>
									</div>
								</Grid>
							</RightFormWrapperDiv>
					</Grid>
				</Grid>
			</ModalWrapper>
		</Modal>
	)
}

interface PropsTopContributors {
	openModal: boolean;
	data: any
	handleTopContributorsModalClose: any;
}

const ShowTopContibutors = ({ data, openModal, handleTopContributorsModalClose }: PropsTopContributors) => {
	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={openModal}
				onClose={() => {
					handleTopContributorsModalClose()
				}}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",

				}}
			>
				<ModalWrapper
					className="schedule_modal">
					<Grid container spacing={0}>

						{/* Modal Title */}
						<ModalTitle item xs={12} >
							<Typography style={{ paddingTop: '14px', fontWeight: 'bold' }}>
								Top Contributors
							</Typography>
							{/* Close Btn */}
							<CloseIconButton onClick={() => {
								handleTopContributorsModalClose()
							}}>
								<CloseIcon style={{}} />
							</CloseIconButton>
							{/* Close Btn END */}
						</ModalTitle>
						{/* Modal Title END */}

						<Grid item xs={12} style={{
							padding: '18px 36px',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							flexDirection: 'column',
						}}>
								<RightFormWrapperDiv>
									<Grid item xs={12}>
										<TableContainer component={Paper}>
											<Table>
												<TableHead>
													<TableCol style={{ fontWeight: 'bold', color: 'black' }}>S.No</TableCol>
													<TableCol style={{ fontWeight: 'bold', color: 'black' }}>First Name</TableCol>
													<TableCol style={{ fontWeight: 'bold', color: 'black' }}>Last Name</TableCol>
													<TableCol style={{ fontWeight: 'bold', color: 'black' }}>Preference</TableCol>
													<TableCol style={{ fontWeight: 'bold', color: 'black' }}>Company Name</TableCol>
												</TableHead>
												<TableBody>
													{data?.map((value: any, index: any) => {
														return (
															<TableRow>
																<TableCol>{index + 1}</TableCol>
																<TableCol>{value?.first_name}</TableCol>
																<TableCol>{value?.last_name}</TableCol>
																<TableCol>{value?.preference}</TableCol>
																<TableCol>{value?.company_name}</TableCol>
															</TableRow>
														)
													})}
												</TableBody>
											</Table>
										</TableContainer>
									</Grid>
								</RightFormWrapperDiv>
						</Grid>
					</Grid>
				</ModalWrapper>
			</Modal>
		</div >
	)
}

const IndividualUserTabs = styled(Button)({
	border: "2px solid transparant", color: "#009B07", fontWeight: 600, backgroundColor: "#FFFFFF", textTransform: "none", borderRadius: "38px", height: "100%",
	padding: "0 50px 0 50px",
	'@media screen and (max-width: 1110px) and (min-width: 769px)': {
		padding: "0 30px 0 30px",
	},
	'@media screen and (max-width: 624px)': {
		padding: "0 30px 0 30px",
	},
	'@media screen and (max-width: 500px)': {
		padding: "0 20px 0 20px",
	}
});

const MainBox = styled(Box)({
	backgroundColor: "#edebeb",
	display: "flex",
	flexWrap: 'wrap',
	flexDirection: "column",
	paddingBottom: "20px",
	overflowX: "hidden",
})

const CorporateLicenceTabs = styled(Button)({
	border: "2px solid transparant", color: "#009B07", fontWeight: 600, backgroundColor: "#FFFFFF", textTransform: "none", borderRadius: "38px", height: "100%",
	padding: "0 65px 0 65px",
	'@media screen and (max-width: 1110px) and (min-width: 769px)': {
		padding: "0 35px 0 35px",
	},
	'@media screen and (max-width: 624px)': {
		padding: "0 35px 0 35px",
	},
	'@media screen and (max-width: 500px)': {
		padding: "0 25px 0 25px",
	}
});

const OuterGridContainer = styled(Grid)({
	flexWrap: 'wrap',
	justifyContent: 'space-between',
	display: 'flex',
})

const RightFormWrapper = styled(Form)({
	width: '100%',
	gap: '25px',
	overflowX: 'hidden',
	display: 'flex',
	flexDirection: 'column',
	paddingRight: '10px',
})

const LeftGridContainer = styled(Grid)({
	maxWidth: '300px',
	'@media screen and (max-width: 985px)': {
        maxWidth: '200px',
      },
	  '@media screen and (max-width: 768px)': {
        display: 'none',
        visibility: 'hidden',
    },
})

const RightGridContainer = styled(Grid)({
	maxWidth: '300px',
	'@media screen and (max-width: 985px)': {
        maxWidth: '250px',
      },
	  '@media screen and (max-width: 768px)': {
        maxWidth: 'auto',
		width: '100%'
    },
})

const MiddleGridContainer = styled(Grid)({
	width: 'calc(100vw - 600px)',
	maxWidth: 'unset',
	flexBasis: 'unset',
	justifyContent: 'center',
	'@media screen and (max-width: 985px)': {
        width: 'calc(100vw - 500px)',
      },
	'@media screen and (max-width: 768px)': {
        width: '100%'
      }
})

const SubmitButton = styled(Button)({
  background: '#009c20',
  color: '#FFF',
  '&:hover': {
    background: '#009c20',
    opacity: 0.9
  }
})

const CancelButton = styled(Button)({
  color: '#FFF',
  background: '#999',
  marginRight: '5px',
  '&:hover': {
    background: '#999',
    opacity: 0.9
  }
})

const SectionItem = styled(Box)({
	margin: '8px 0px',
	'& .MuiTypography-root.MuiTypography-subtitle1': {
		fontWeight: 'bold',
		fontSize: 11,
	},
	'& .MuiTypography-root.MuiTypography-subtitle2': {
		fontWeight: 'normal',
		color: 'grey',
		fontSize: 14
	}
});

const DetailSection = styled('div')({
	display: "flex",
	justifyContent: "space-between"
})

const CardSection = styled('div')({
	padding: "15px",
	width: "300px"
})

const DivSection = styled('div')({
})

const TextFieldRounded = styled(TextField)({
	background: '#FFFFFF',
	width: '100%',
	borderRadius: '15px',
	'& > .MuiOutlinedInput-root': {
		borderRadius: '15px',
	},
	'& > div > input': {
		paddingTop: '14px',
		paddingBottom: '14px',
		'&::placeholder': {
			color: '#484747 !important',
			opacity: '1 !important',
		},
	}
})

const FilterByDateWrapper = styled('div')({
	width: '300px',
	height: '200px',
	padding: '10px 15px',
	background: '#FFF'
})

const FilterByDateWrapperForDate = styled('div')({
	padding: '10px 15px',
	background: '#FFF',
	width: '320px',
	height: '220px',
})


const ProjectTabContainer = styled('div')({
	marginTop: '30px',
	borderRadius: '6px',
	width: '100%',
	background: '#fff',
})

const FilterByDateRow = styled('div')({
	alignItems: 'center',
	marginBottom: '20px',
  display: 'flex',
});

const ProjectTabCardHeader = styled('div')({
	display: 'flex',
	marginBottom: '20px',
	alignItems: 'flex-start',
	justifyContent: 'space-between',
	gap: '20px',
})

const ProjectTabCard = styled('div')({
	borderBottom: 'solid 1px rgb(227, 227, 227)',
	padding: '30px 30px 6px 30px',
})

const SectionText = styled(Box)({
	margin: "4px 0px",width: '50%',color: '#464646',
	'& .MuiTypography-root.MuiTypography-subtitle1': {
		fontWeight: "bold",
		fontSize: 14,
	},
	'& .MuiTypography-root.MuiTypography-subtitle2': {
		fontWeight: "normal",
		color: "grey",
		fontSize: 14,
	}
})

const ProjectDiv= styled('div')({
	display: 'flex', 
	gap: '15px' 
})

const RoundedButtonBase = styled('div')((props: any) => ({
	height: "47px", fontWeight: 700, display: 'flex', textAlign: 'center', justifyContent: 'flex-start', backgroundColor: "#FFFFFF", flex: "wrap",
	color: "#009E00",
	borderRadius: '38px',
	border: " 2px solid #009B07"
}))

const CloseIconButton = styled(IconButton)({
	background: '#676767',
	color: '#fcfcfc',
	width: '32px',
	height: '32px',
	'&:hover': {
		background: '#999',
		opacity: 0.9
	}
})

const DialogCancelBtn = styled(Button)({
	color: '#FFF',
	background: '#D0D0D0',
	padding: '10px 18px 8px 18px',
	margin: '5px',
	'&:hover': {
		background: '#999',
		opacity: 0.9
	},
	'@media screen and (max-width:400px)': {
		width: '-moz-available',
	}
})

const ShareNowBtn = styled(Button)({
	color: '#FFF',
	background: '#019B07',
	padding: '10px 18px 8px 18px',
	margin: '5px',
	'&:hover': {
		background: '#999',
		opacity: 0.9
	},
	'@media screen and (max-width:400px)': {
		width: '-moz-available',
	}
})

const ModalTitle = styled(Grid)({
	display: 'flex',
	justifyContent: 'space-between',
	alignItem: 'center',
	flexDirection: 'row',
	width: '100%',
	borderBottom: '0.2px solid #cbc1c1',
	padding: '13px 25px 13px 31px'
})

const FormWrapper = styled(Form)({
	width: '100%',
	display: 'flex',
	flexDirection: 'column',
	gap: '25px',
	overflowX: 'hidden',
	paddingRight: '10px',
})

const ModalWrapper = styled(Box)({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	flexDirection: 'column',
	background: '#fff',
	borderRadius: 8,
	width: '670px',
	position: "relative",
	margin: '0 10px',
})

const RightFormWrapperDiv = styled('div')({
	display: 'flex',
	flexDirection: 'column',
	width: '100%',
	overflowX: 'hidden',
	paddingRight: '10px',
	gap: '25px',
})

const LiveBtnOpen = styled(Typography)({
	background: '#e4f4e7',
	color: '#388141',
	padding: '8px 25px',
	fontSize: '14px',
	border: '1px solid #E5EDE6',
	borderRadius: '10px',
	width: 'max-content',
	fontWeight: 'bold',
	// opacity: 0.11,
	mixBlendMode: 'normal',
	textTransform: 'capitalize',
})

const LiveBtnFunded = styled(Typography)({
	padding: '8px 25px',
	fontSize: '14px',
	width: 'max-content',
	background: '#e3f0ff',
	border: '1px solid #e3f0ff',
	borderRadius: '10px',
	color: '#3187e0',
	fontWeight: 'bold',
	// opacity: 0.11,
	mixBlendMode: 'normal',
	textTransform: 'capitalize',
})

const LiveBtnClosed = styled(Typography)({
	border: '1px solid #ffe4e5',
	background: '#ffe4e5',
	padding: '8px 25px',
	fontSize: '14px',
	borderRadius: '10px',
	mixBlendMode: 'normal',
	textTransform: 'capitalize',
	color: '#e93951',
	width: 'max-content',
	fontWeight: 'bold',
	// opacity: 0.11,
})
// Customizable Area End
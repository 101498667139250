import React from "react";

// Customizable Area Start
import {
	Box,
	Backdrop,
	Button,
	IconButton,
	Grid,
	Typography,
	Modal,
	TableBody,
	TableContainer,
	Paper,
	TableHead,
	Table,
	TableCell,
	TableRow,
	CircularProgress
} from "@material-ui/core";
import { styled } from '@material-ui/styles';
import Flex from "../../../components/src/Flex.web";
import CloseIcon from "@material-ui/icons/Close";
import UserContext from "../../../components/src/UserProvider";

export const configJSON = require("./config");

// Customizable Area End

import MyProjectsController, { Props } from "./ProjectSurveyController.web";
import Search from "../../../components/src/Search.web";
import { UserSurveyDetailModel } from "../../../blocks/Surveys/src/UserSurveyDetailModel.web";

export default class ProjectSurvey extends MyProjectsController {
	constructor(props: Props) {
		super(props);
		// Customizable Area Start
		// Customizable Area End
	}

	// Customizable Area Start
	// Customizable Area End
	render() {
		const renderSurveyData = () => {
			if(this.state.loading){
				return <TableRow><TableCell style={{ textAlign: 'center' }} colSpan={5}><CircularProgress /></TableCell></TableRow>
			}
			else if(this.state.surveyList?.length > 0 ){
				return this.state.surveyList?.map((value: any, index: any) => {
					const { attributes } = value?.attributes?.surveys?.data
					return (
						<TableRow style={{ cursor: 'pointer' }} id='clickedToSelectSurvey' onClick={() => this.handleLaunchSurveyOpen(value?.attributes?.surveys?.data)}>
							<TableCell>{index + 1}</TableCell>
							<TableCell>{attributes?.survey_title || ''}</TableCell>
							<TableCell>{attributes?.status || ''}</TableCell>
							<TableCell>{`${attributes?.created_by?.first_name || ''} ${attributes?.created_by?.last_name || ''}`}</TableCell>
							<TableCell>{`${attributes?.requested_by?.first_name || ''} ${attributes?.requested_by?.last_name || ''}`}</TableCell>
						</TableRow>
					)
				})
			}
			else{
				return <TableRow><TableCell style={{ textAlign: 'center' }} colSpan={5}>No Survey's Found</TableCell></TableRow>
			}
		}
		return (
			// Customizable Area Start
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",

				}}
				open={true}
				onClose={() => {
					this.handleSurveyClose()
				}}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Box
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						flexDirection: 'column',
						background: '#fff',
						borderRadius: 8,
						width: '650px',
						position: "relative",
						margin: '0 10px',
					}} className="schedule_modal">
					<Grid container spacing={0}>

						{/* Modal Title */}
						<ModalTitle item xs={12} >
							<Typography style={{ paddingTop: '14px', fontWeight: 'bold' }}>
								Survey Details
							</Typography>
							{/* Close Btn */}
							<CloseIconButton id='closeSurvey' onClick={() => {
								this.handleSurveyClose()
							}}>
								<CloseIcon style={{}} />
							</CloseIconButton>
							{/* Close Btn END */}
						</ModalTitle>
						{/* Modal Title END */}
						<Flex style={{ width: "50%", marginTop: '14px', marginLeft: '8px' }}>
							<Search searchInputValue={this.state.searchSurvey} data-test-id='surveySearchQuery' onChange={(e: any) => this.handleSurveySearch(e)} />
						</Flex>
						<Grid item xs={12} style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							flexDirection: 'column',
							padding: '18px 36px',
							maxHeight: '50vh',
							overflowY: 'scroll'
						}}>
							<RightFormWrapperDiv>
								<Grid item xs={12}>
									<TableContainer component={Paper}>
										<Table>
											<TableHead>
												<TableCell style={{ fontWeight: 'bold', color: 'black' }}>S.No</TableCell>
												<TableCell style={{ fontWeight: 'bold', color: 'black' }}>Survey Title</TableCell>
												<TableCell style={{ fontWeight: 'bold', color: 'black' }}>Status</TableCell>
												<TableCell style={{ fontWeight: 'bold', color: 'black' }}>Created By</TableCell>
												<TableCell style={{ fontWeight: 'bold', color: 'black' }}>Requested By</TableCell>
											</TableHead>
											<TableBody>
												{renderSurveyData()}
											</TableBody>
										</Table>
									</TableContainer>
								</Grid>
							</RightFormWrapperDiv>
						</Grid>
					</Grid>
					{this.state.launchSuervey && <ShowSurvey surveyList={this.state.selectedSurvey} navigation={this.props.navigation} handleLaunchSurveyOpen={this.handleLaunchSurveyOpen} handleLaunchSurveyClose={this.handleLaunchSurveyClose} />}
				</Box>
			</Modal>

			// Customizable Area End
		);
	}
}

// Customizable Area Start
MyProjectsController.contextType = UserContext


// Customizable Area End

export const ShowSurvey = ({ surveyList, navigation, handleLaunchSurveyClose, handleLaunchSurveyOpen }: any) => {
	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			style={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",

			}}
			open={handleLaunchSurveyOpen}
			onClose={() => {
				handleLaunchSurveyClose()
			}}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Box
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					flexDirection: 'column',
					background: '#fff',
					borderRadius: 8,
					width: '650px',
					position: "relative",
					margin: '0 10px',
				}} className="schedule_modal">
				<Grid container spacing={0}>

					<UserSurveyDetailModel navigation={navigation || ''} detail={surveyList} onCloseClick={() => handleLaunchSurveyClose()} />

				</Grid>
			</Box>
		</Modal>
	)
}

const CloseIconButton = styled(IconButton)({
	background: '#676767',
	color: '#fcfcfc',
	width: '32px',
	height: '32px',
	'&:hover': {
		background: '#999',
		opacity: 0.9
	}
})

const MainBox = styled(Box)({
	backgroundColor: "#edebeb",
	display: "flex",
	flexWrap: 'wrap',
	flexDirection: "column",
	paddingBottom: "20px",
	overflowX: "hidden",
})

const OuterGrid = styled(Grid)({
	display: 'flex',
	flexWrap: 'wrap',
	justifyContent: 'space-between',
})

const DialogCancelButton = styled(Button)({
	color: '#FFF',
	background: '#D0D0D0',
	padding: '10px 18px 8px 18px',
	margin: '5px',
	'&:hover': {
		background: '#999',
		opacity: 0.9
	},
	'@media screen and (max-width:400px)': {
		width: '-moz-available',
	}
})

const LeftGrid = styled(Grid)({
	maxWidth: '300px',
	'@media screen and (max-width: 985px)': {
		maxWidth: '200px',
	},
	'@media screen and (max-width: 768px)': {
		display: 'none',
		visibility: 'hidden',
	},
})

const MiddleGrid = styled(Grid)({
	width: 'calc(100vw - 600px)',
	maxWidth: 'unset',
	flexBasis: 'unset',
	justifyContent: 'center',
	'@media screen and (max-width: 985px)': {
		width: 'calc(100vw - 500px)',
	},
	'@media screen and (max-width: 768px)': {
		width: '100%'
	}
})

const ModalTitle = styled(Grid)({
	display: 'flex',
	justifyContent: 'space-between',
	alignItem: 'center',
	flexDirection: 'row',
	width: '100%',
	borderBottom: '0.2px solid #cbc1c1',
	padding: '13px 25px 13px 31px'
})

const RightFormWrapperDiv = styled('div')({
	width: '100%',
	display: 'flex',
	flexDirection: 'column',
	gap: '25px',
	overflowX: 'hidden',
	paddingRight: '10px',
})

const RightGrid = styled(Grid)({
	maxWidth: '300px',
	'@media screen and (max-width: 985px)': {
		maxWidth: '250px',
	},
	'@media screen and (max-width: 768px)': {
		maxWidth: 'auto',
		width: '100%'
	},
})
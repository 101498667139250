import React from 'react'
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { toast } from 'react-toastify';

import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { esgPathRecords, sendAPIRequest } from '../../../components/src/utils';
import { getStorageData } from '../../../framework/src/Utilities';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  token: string;
  filterMenuPosition: {
    top: number,
    left: number,
  } | undefined;
  filterRequestTypes: any[];
  filterRequestType?: string;
  filterCreatedByMe: boolean;
  filterStatus: string;
  selectingDate?: boolean;
  serveyTemplates:any;
  surveyId:any;
  deleteTemplteId:any;
  urlSearchParam:string;
  filterQuery:any;
  loadingSurveyTemplates:boolean;
  queryStr:string;
  pagination?: {
    next_page?: number;
  };
  filterByMe:boolean;
  filterByFav: boolean;
  communities: any[];
  requestors:any[];
  filterCommunities: any[];
  filterRequestors:any[];
  filterByStatus:any;
  deleteId:any;
  notFound:string;
  responseStatusStartingRange: number;
  responseStatusEndingRange: number;
  isdisabled: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class DueDiligenceController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  filterRef?: any;
  surveyTemplateCallId : string='';
  templateDeleteCallId :string="";
  templatesFilterCallId:string="";
  apiPaginateTemplatesCallId:string="";
  taggedCommunitiesCallId:string="";
  filterSurveyTemplateCallId:string="";
  requestorsCallId:string="";
  showEsgCardsAPICallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      token: '',
      filterMenuPosition: undefined,
      filterRequestTypes: [],
      filterRequestType: '',
      filterCreatedByMe: false,
      filterStatus: '',
      serveyTemplates:[],
      surveyId:'',
      deleteTemplteId:'',
      urlSearchParam:'',
      filterQuery:'',
      loadingSurveyTemplates:false,
      queryStr:'',
      filterByMe: false,
      filterByFav: false,
      communities: [],
      requestors:[],
      filterCommunities: [],
      filterRequestors:[],
      filterByStatus:'',
      deleteId:'',
      notFound:'',
      responseStatusStartingRange: 0,
      responseStatusEndingRange: 100,
      isdisabled: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.filterRef = React.createRef()
    // Customizable Area End

  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    let authToken = await getStorageData('authToken');
    const userData = await getStorageData('user');
    const userObj = JSON.parse(userData);
    let licensedUser = userObj?.role?.role_level < 5 || userObj.user_type === "licensed_user"
    if(licensedUser){
      this.esgAccess(authToken)
    }
    if (authToken == null) {
        this.props.navigation.navigate('Home')
    }
    const access = this.props?.navigation?.history?.location?.state?.ability_type
    console.log("access rights =", access);
    if (access == "read") {
      this.setState({ isdisabled: true })
    }
    this.setState({token: authToken},() => {
    this.getSurveyTemplates()
    this.getRequestors()
    this.getCommunitiesByCreator()
    })
    // Customizable Area End
    console.log(this.context, "logined id :::")
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.handleAPIResponse(message); 
    }
    // Customizable Area End

  }

  // Customizable Area Start
  handleAPIResponse(message: any) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
  
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
  
    if (responseJson && responseJson.errors) {
      this.parseApiErrorResponse(responseJson.errors);
      this.parseApiCatchErrorResponse(responseJson.errors);
    } else {
      this.handleApiCallIds(apiRequestCallId, responseJson);
    }
  }

  handleApiCallIds(apiRequestCallId:any, responseJson:any) {
    switch (apiRequestCallId) {
      case this.surveyTemplateCallId:
        this.handleSurveyTemplateCall(responseJson);
        break;
      case this.apiPaginateTemplatesCallId:
        this.handlePaginateTemplatesCall(responseJson);
        break;
      case this.templateDeleteCallId:
        this.handleTemplateDeleteCall();
        break;
      case this.taggedCommunitiesCallId:
        this.handleTaggedCommunitiesCall(responseJson);
        break;
      case this.requestorsCallId:
        this.handleRequestorsCall(responseJson);
        break;
      case this.filterSurveyTemplateCallId:
        this.handleFilterSurveyTemplateCall(responseJson);
        break;
      case this.showEsgCardsAPICallId : 
        this.handleEsgCardsShowAuth(responseJson)
        break
      default:
        break;
    }
  }

  handleSurveyTemplateCall(responseJson:any) {
    if (responseJson) {
      this.setState({ loadingSurveyTemplates: false });
      if (!Array.isArray(responseJson?.surveys?.data)) return;
      const serveyTemplates = responseJson.surveys.data;
      console.log(responseJson, serveyTemplates, responseJson.meta.pagination, "filter esponse all:::");
      this.setState({ serveyTemplates });
      this.setState({ pagination: responseJson.meta.pagination });
    }
  }

  handlePaginateTemplatesCall(responseJson:any) {
    if (responseJson) {
      const res = [...this.state.serveyTemplates, responseJson.surveys.data].flat();
      this.setState({ serveyTemplates: res, pagination: responseJson.meta.pagination });
    }
  }

  handleTemplateDeleteCall() {
    const dId = this.state.deleteTemplteId
        const serveyTemplates = this.state.serveyTemplates.filter((p: any) => p.id != dId)
        this.setState({ serveyTemplates })
  }

  handleTaggedCommunitiesCall(responseJson:any) {
    if (responseJson) {
      const communities = responseJson.communities?.data?.map((item: any) => item?.attributes)
      this.setState({ communities })
  }
  }

  handleRequestorsCall(responseJson:any) {
    if (responseJson){
      const req = responseJson?.data?.map((item: any) => item?.attributes)
      const requestors = req?.map((item:any)=>{
        let reqArr= {id:item?.id, name:`${item?.first_name} ${item?.last_name}`}
        return reqArr
      })
      this.setState({ requestors })
    }
  }

  handleFilterSurveyTemplateCall(responseJson:any) {
    if (responseJson?.surveys){
      const filteredData=responseJson?.surveys?.data
      const notFound=responseJson?.surveys
      if(notFound=="No data found"){
        this.setState({notFound:notFound, loadingSurveyTemplates:false})
      }else{
        this.setState({serveyTemplates:filteredData, loadingSurveyTemplates:false})
      } 
    }
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  }

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  }

  

  clearFilters = (e?: React.MouseEvent) => {
    console.log("checkll:::")
    e && e.stopPropagation()
    this.setState({
      // filterFromDate: null,
      // filterToDate: null,
      filterByStatus: '',
      filterRequestType: '',
      // filterCreatedByMe: false,
      queryStr: '',
      filterCommunities:[],
      filterRequestors:[],
      loadingSurveyTemplates:true,
      responseStatusStartingRange: 0,
      responseStatusEndingRange: 100,
    })
    // load posts with no filters
    this.getSurveyTemplatesApi();
    // this.filterPosts()
  }

  filterText = () => {
    let filterText = "";
    

    if(this.state.filterRequestors?.length) {
      const requ= this.state.filterRequestors.map(this.getrequestors)
      if(requ)filterText +=`Requested by: ${requ}`
    }
    if (this.state.filterCommunities?.length) {
      const tags = this.state.filterCommunities.map(this.getCommunitiesById)
      console.log(tags, "tags:::")
      filterText += `Communities: ${tags.join(',')}`
    }


    if (typeof this.state?.filterStatus === "boolean") {
      filterText += `Status: ${this.state.filterStatus?"Complete":"Pending"}`
    }
    if (this.state.filterCreatedByMe) filterText += 'Created By Me'
    if (this.state.filterRequestType?.length) {
      const req = this.state.filterRequestTypes.find(
        (x:any) => x.id == this.state.filterRequestType
      )
      if(req)
        filterText += `Request Type: ${req?.name}`
    }

    return filterText
  }

  getrequestors = (id: number) => {
    const cat = this.state.requestors.find(c => c.id === id)
    if (cat) return cat.name
    return ""
  }

  handleRequestorSelect = (id: number) => () => {
    if (this.state.filterRequestors.indexOf(id) === -1) {
      this.setState({ ...this.getBlankFilters(), filterRequestors: [...this.state.filterRequestors, id] }, () => this.filterPosts())
    } else {
      this.setState({ ...this.getBlankFilters(), filterRequestors: this.state.filterRequestors.filter((x: number) => x !== id) }, () => this.filterPosts())
    }
  }

  

  handleRequestTypeSelect = (id: number) => (e: any) => {
    
  }

  handleDateFilterSubmit = () => {
    // this.filterRequests()
  }

  handleFromDateChange = (date: any) => {
    // this.setState({ ...this.getBlankFilters(), filterFromDate: date, filterToDate: this.state.filterToDate })
  }

  handleToDateChange = (date: any) => {
    // this.setState({ ...this.getBlankFilters(), filterToDate: date, filterFromDate: this.state.filterFromDate })
  }

  toggleFilterByMe = () => {
    this.clearFilters()
  }

  handleDeletePopup=(data:any)=>{
    if(data?.status == "live"){
      toast.warn("Can not delete Live Template");
    }
    else{
      this.setState({deleteId: data?.id})
    }
  }

  closeDeleteModal = () => {
    this.setState({deleteId: undefined})
  }

  handleFilterByStatus = (status:any) => {
    console.log(status, "statusb  ::::")
    const filters = this.getBlankFilters()
    console.log(filters, "filter:::")
    // filters['filterByStatus'] = '0'
    console.log(filters, "filter:::")

    this.setState({ filterByStatus:status,  filterMenuPosition: undefined }, () => this.filterPosts())
  }

  handleFilterByResponseRange = (value: any, type: any) => {
    if(type === "start"){
      this.setState({...this.state, responseStatusStartingRange: value})
    }else if(type === "end"){
      this.setState({...this.state, responseStatusEndingRange: value})
    }
  }

  cancelResponseRange = () => {
    this.setState({ ...this.state, responseStatusStartingRange: 0, responseStatusEndingRange: 100, filterMenuPosition: undefined}, () => this.filterPosts())
  }

  applyResponseRange = () => {
    this.setState({... this.state, filterMenuPosition: undefined}, () => this.filterPosts())
  }

  handleScreenSizeFunction = (screenSize : any) => {
    return screenSize <= 1550 ? 45 : 50
  }

  handleDateInputFocus = () => {
    this.setState({ selectingDate: true })
  }
  handleDateInputBlur = () => (this.setState({ selectingDate: false }));

  onSelecteFilterStatus = (status: any) => (e: any) => {
    
  }

  handleDateFilterCancel = () => {
    this.setState({ filterMenuPosition: undefined })
  }

  filterPosts = () => {
    const queryStr = this.getFilterQueryParams().toString()
    console.log(queryStr, "query str :::")
    
    this.setState({ queryStr, serveyTemplates: [], loadingSurveyTemplates: true })

    if (queryStr) {
      this.filterSurveyTemplateCallId = sendAPIRequest(`${configJSON.getSurveyTemplates}?${queryStr}`, {
            method: "GET",
            headers: { 'token': this.state.token },
        })
     
    } else {
      this.getSurveyTemplates()
    }
  }

  
  loadNextPage = () => {
    const query = this.getFilterQueryParams()
    console.log(query, "query::::")
    
    query.append('per_page', '6')
    query.append('page', String(this.state.pagination?.next_page))
  
    
    this.apiPaginateTemplatesCallId = sendAPIRequest(configJSON.getSurveyTemplates + "?" + query.toString(), {
      method:'GET',
      headers:{'token':this.state.token},
    })
    this.setState({  })
  }

  getSurveyTemplates = ()=>{
    if(this.state.token){
      this.setState({loadingSurveyTemplates:true})
      const queryStr = this.getFilterQueryParams().toString()
      console.log(queryStr, "query str :::")
      
      this.setState({ queryStr, serveyTemplates: [] })
      
      this.surveyTemplateCallId = sendAPIRequest(`${configJSON.getSurveyTemplates}?${queryStr}&per_page=6`, {
          method: "GET",
          headers: { 'token': this.state.token },
      })
    }  

   
}

getSurveyTemplatesApi=()=>{
  this.surveyTemplateCallId = sendAPIRequest(`${configJSON.getSurveyTemplates}`, {
    method: "GET",
    headers: { 'token': this.state.token },
})
}


handleDeleteTemplate = (id:any) => {
  const { token } = this.state
  this.templateDeleteCallId = sendAPIRequest(configJSON.deleteSurveyTemplate.replace(':id', id), {
    method: 'DELETE',
    headers: { token }
  })
  this.setState({deleteTemplteId:id, deleteId:''})
}

getCommunitiesById = (id: number) => {
  const cat = this.state.communities.find(c => c.id === id)
  if (cat) return cat.name
  return ""
}

getCommunitiesByCreator=async()=>{
  let authToken = await getStorageData('authToken');
  const id=this.context.id
  this.taggedCommunitiesCallId = sendAPIRequest(`${configJSON.getTaggedCommunities}?requested_by_id=${id}`, {
    method: 'GET',
    headers: {
      token: authToken,
      'content-type': 'appliction/json'
    },
  })
  this.setState({communities:[]})
}

getRequestors=async()=>{
  let authToken = await getStorageData('authToken');
  this.requestorsCallId = sendAPIRequest(`${configJSON.getRequestorsEndpoint}`, {
    method: 'GET',
    headers: {
      token: authToken,
      'content-type': 'appliction/json'
    },
  })
}

handleEditTemplate = (id:any) => () =>{
  const url = `${this.props.navigation.history.location?.pathname}/EditDueDilligence/${id}`
  this.props.navigation.history.push(url)
}

onFilterQueryChange = (filterQuery: string) => {
  this.setState({ filterQuery }, () => this.getSurveyTemplates())
}



handleCopyTemplates = (data:any)=> () => {
  if(this.state.isdisabled){
    toast.warn("You do not have permission to perform this action")
  }
  else{
    console.log(data, "copydata")
    const url = `${this.props.navigation.history.location?.pathname}/CopyDueDilligence/${data?.id}`
    this.props.navigation.history.push(url)
  }
}
getBlankFilters = () => {
  return {
    // filterFromDate: null,
    // filterToDate: null,
    // filterCategories: [],
    filterCommunities: [],
    // filterByMe: false,
    // filterByFav: false,
    filterRequestors:[],
    filterByStatus:''
  }
}

// getFilterQueryParams = () => {
//   const query = new URLSearchParams()
//   if (this.state.filterQuery) query.append('query', this.state.filterQuery)
//   return query
// }

getFilterQueryParams = () => {
  const query = new URLSearchParams()
 
  if(this.state.filterRequestors.length) query.append('requested_by_ids', this.state.filterRequestors.join(','))
  if (this.state.filterCommunities.length) query.append('community_ids', this.state.filterCommunities.join(','))
  if (this.state.filterQuery) query.append('query', this.state.filterQuery)
  if (this.state.filterByStatus) query.append('status', this.state.filterByStatus)
  if (this.state.responseStatusStartingRange > -1 && this.state.responseStatusEndingRange > -1){
    query.append('from', String(this.state.responseStatusStartingRange));
    query.append('to', String(this.state.responseStatusEndingRange));
  }
 
  console.log(this.state.filterRequestors, "filter com:::")
  
  return query
}
handleFilterMenuClose = () => {
  this.setState({ filterMenuPosition: undefined })
  // this.filterPosts()
}

handleFilterMenuOpen = (e: React.MouseEvent) => {
  if (!this.filterRef) return;
  const box = this.filterRef.current?.getBoundingClientRect();
  this.setState({
    filterMenuPosition: {
      top: box?.bottom,
      left: box?.left + 20
    }
  })
}

handleTagSelect = (id: number) => () => {
  if (this.state.filterCommunities.indexOf(id) === -1) {
    this.setState({ ...this.getBlankFilters(), filterCommunities: [...this.state.filterCommunities, id] }, () => this.filterPosts())
  } else {
    this.setState({ ...this.getBlankFilters(), filterCommunities: this.state.filterCommunities.filter((x: number) => x !== id) }, () => this.filterPosts())
    console.log("else:::")
  }
}

navigationTo = (route: string, props: Props) => {
  const navigateDataMessage = new Message(getName(MessageEnum.NavigationMessage));
  navigateDataMessage.addData(getName(MessageEnum.NavigationTargetMessage), route);
  navigateDataMessage.addData(getName(MessageEnum.NavigationPropsMessage), props);   
  this.send(navigateDataMessage);
}
esgAccess = async (authToken:string) => {
  this.showEsgCardsAPICallId = sendAPIRequest(`${configJSON.showAllCards}`, {
    method: "GET",
    headers: {token: authToken},
  })
}
handleEsgCardsShowAuth(esgData:{
  access: {
    data: {
      id: string;
      type: string;
      attributes: {
        id: number;
        created_at: string;
        user_name: string | null;
        updated_at: string;
        company_name: string | null;
        email: string;
        ability_type: string;
        ability_card: string;
        profile_picture: string | null;
        card_banner: string;
        card_serial_number: number;
      };
    }[];
  }}){
  let accessibility = esgPathRecords(esgData)
  if(!accessibility){
    let backLink = "ActivityFeed"
    let warning = "Sorry, You do not have access to this page"
    toast.warn(warning)
    this.navigationTo(backLink,this.props)   
  }
}

  // Customizable Area End

}
// Customizable Area Start
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const backIcon = require("../assets/backIcon.png");
export const activeicon = require("../assets/correctsymbol.png");
export const  declineicon =require("../assets/crossicon.png")
export const  view_Path =require("../assets/view_Path.png")
export const  PaymentSuccessLogo =require("../assets/PaymentSucessfullLogo.png")
// Customizable Area End





import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

import { Post } from './types'
import { sendAPIRequest } from '../../../components/src/utils';
import { sendSharePopupMessage } from '../../share/src/Messages';


import { sendPostUpdatedMessage } from '../../postcreation/src/Messages';
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  openModal: boolean;
  postData: any;
  postId: string;
  postCommentData: any[];
  pagination?: {
    next_page?: number;
  };
  userComment: string;
  userReply: string;
  reply_id: '';
  openCommentEmoji: boolean;
  openCommentReplyEmoji: boolean;
  openReply: '';
  threedotAnchorEl?: any;
  currentPostId?: any;
  currentPost?: any;
  deletePostId?: any;
  postDeleteCallId?: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PostController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getPostDetailCallId = '';
  getAllPostCommentCallId = '';
  postCommentCallId = '';
  postLikeCallId = '';
  postCommentReplyCallId = '';
  getMoreCommentCallId = '';
  apiFavPostCallId: string[] = []
  favCallIdToPostId: { [key: string]: string } = {}
  likeCallIdToCommentId: { [key: string]: string } = {}

  async componentDidMount() {
    super.componentDidMount();
    
    const token = await getStorageData('authToken') || '';
    if (token == null) {
      this.props.navigation.navigate("Home");
    } 
      
    this.setState({token: token || ''})
    this.onInitialized();
  }
  onInitialized = async() => {
    const token  = await getStorageData('authToken');
    const post_id = this.props.navigation.getParam('post_id')
    this.getPostDetailCallId = sendAPIRequest(configJSON.getPostDataEndpoint.replace(':post_id', post_id), {
      method: 'GET',
      headers: { token }
    })

    this.getAllComments();
  }

  getAllComments = async() => {
    const token  = await getStorageData('authToken');

    const post_id = this.props.navigation.getParam('post_id')

    this.getAllPostCommentCallId = sendAPIRequest(configJSON.getAllPostCommentEndpoint.replace(':post_id', post_id), {
      method: 'GET',
      headers: { token }
    })
  }
  onCloseModal = () => {
    this.setState({ openModal: false });
    if(this.props.navigation.history.location.pathname.includes('/ActivityFeed') || this.props.navigation.history.location.pathname.includes('/AllActivityFeed')){
			const path = this.props.navigation?.history?.location?.pathname?.split('/post/')?.length ? this.props.navigation.history.location.pathname.split('/post/')[0] : this.props.navigation?.history?.location?.pathname;
      this.props.navigation.history.push(`${path}`,{comment:true});
		}
    else{
      this.props.navigation.goBack();
    }
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReportModalOpenMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token:  '',
      openModal: true,
      postId: '',
      postData: {},
      postCommentData: [],
      userComment: '',
      userReply: '',
      reply_id: '',
      openCommentEmoji: false,
      openCommentReplyEmoji: false,
      openReply: '',
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      
      if (responseJson && responseJson.errors) {
        // this.setState({ refresh: false });
        this.parseApiErrorResponse(responseJson.errors);
        this.parseApiCatchErrorResponse(responseJson.errors);
      } else if (this.getPostDetailCallId === apiRequestCallId){
        const postData = responseJson.data.attributes
        this.setState({ 
          postData
        })
      } else if (this.getAllPostCommentCallId === apiRequestCallId){
        let postCommentData = responseJson?.comments?.data?.map((d: any) => ({ ...d.attributes, id: d.id })) || []
        this.setState({ 
          postCommentData, pagination: responseJson?.meta?.pagination
        })
      } else if (this.postCommentCallId === apiRequestCallId){
        /*let new_comment = responseJson.data ? { ...responseJson.data.attributes, id: responseJson.data.id, like_count: 0, is_like: false } : []
        if (new_comment) {
          let postCommentData = this.state.postCommentData;
          postCommentData.unshift(new_comment);
          let userComment = '';
          this.setState({ 
            userComment, postCommentData
          })
        }*/
        let userComment = '';
        this.setState({ 
          userComment
        })
        this.getAllComments();
      } else if (this.postLikeCallId === apiRequestCallId){
        const comment_id = this.likeCallIdToCommentId[apiRequestCallId];
        let postCommentData = this.state.postCommentData;
        let updateItem = postCommentData.find((x) => x.id == comment_id);
        let index = postCommentData.indexOf(updateItem);
        delete this.likeCallIdToCommentId[apiRequestCallId]
        //let comment_id = responseJson?.data?.attributes?.likeable_id
        if (responseJson?.data) {
          updateItem.like_count += 1;
          updateItem.is_like = true;
          const likeData = { ...responseJson.data, ...responseJson.data?.attributes }
          updateItem.like_id = [...(this.state.postData.like_id || []), likeData];
          postCommentData[index] = updateItem;
          this.setState({postCommentData});
        }
        else {
          updateItem.like_count -= 1;
          updateItem.is_like = false;
          updateItem.like_id = [];
          postCommentData[index] = updateItem;
          this.setState({postCommentData});
        }
      } else if (this.postCommentReplyCallId === apiRequestCallId){
        let new_reply = responseJson.data ? { ...responseJson.data.attributes, id: responseJson.data.id, like_count: 0 } : []
        if (new_reply) {
          let postCommentData = this.state.postCommentData;
          let updateItem = postCommentData.find((x) => x.id == this.state.reply_id);
          let index = postCommentData.indexOf(updateItem);
          updateItem.replies.push(new_reply);
          postCommentData[index] = updateItem;
          let userReply = '';
          this.setState({ 
            userReply, postCommentData, reply_id: ''
          })
        }
      } else if (this.getMoreCommentCallId === apiRequestCallId){
        let oldPostCommentData = responseJson?.comments?.data?.map((d: any) => ({ ...d.attributes, id: d.id })) || []
        let postCommentData = [...this.state.postCommentData, ...oldPostCommentData] ;
        this.setState({ 
          postCommentData, pagination: responseJson?.meta?.pagination
        })
      } else if (this.apiFavPostCallId.includes(apiRequestCallId)) {
        const postId = this.favCallIdToPostId[apiRequestCallId]
        this.apiFavPostCallId = this.apiFavPostCallId.filter(x => x !== apiRequestCallId)
        delete this.favCallIdToPostId[apiRequestCallId]
        if (responseJson.data) {
          const fav = { ...responseJson.data, ...responseJson.data?.attributes }
          let postData = { ...this.state.postData, favourite_id: [...(this.state.postData.favourite_id || []), fav] }
          this.setState({ postData })
          const updatedPost = {id:postData.id, ...postData}
          sendPostUpdatedMessage(updatedPost)
        }
      }
       
    }
    // Customizable Area End

  }

  // Customizable Area Start
  handleSharePost = (p: Post) => {
    const postID = p.id
    const createdById = p.created_by.id // refactor the old function
    let data = {
      userId: createdById,
      postId: postID,
      link: `${window.location.origin}/user/${createdById}/post/${p.id}`,
      shareableType: 'BxBlockPosts::Post',
      shareableId: postID,
    }
    sendSharePopupMessage(data);
  }

  handlePostCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ userComment: e.target.value })
  }

  handlePostReplyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ userReply: e.target.value })
  }

  handlePostComment = (post_id: any) => {
    const { token, userComment } = this.state
    const body = new FormData()
    body.append("post_id", post_id)
    body.append("content", userComment);

    this.postCommentCallId = sendAPIRequest(configJSON.postCommentEndpoint, {
      method: 'POST',
      body,
      headers: { token }
    })

    let stateEmogi = false;
    this.setState({ openCommentEmoji: stateEmogi });
  }

  handleCommentLike = (comment: any) => {
    const { token } = this.state
    const body = new FormData()
    body.append("data[attributes][likeable_type]", 'Comment')
    body.append("data[attributes][likeable_id]", comment.id);
    let url = configJSON.postLikeEndpoint;
    if (comment.is_like) {
      url = configJSON.postLikeEndpoint + '/' + comment?.like_id[0]?.id
    }
    this.postLikeCallId = sendAPIRequest(url, {
      method: comment.is_like ? 'DELETE' : 'POST',
      body,
      headers: { token }
    })
    this.likeCallIdToCommentId[this.postLikeCallId] = comment.id
  }

  handleSetReplyId = (reply_id: any) => {
    this.setState({
      reply_id
    });
  }

  handleReplyComment = () => {
    const { token, userReply, reply_id } = this.state
    const body = new FormData()
    body.append("comment_id", reply_id)
    body.append("content", userReply);

    this.postCommentReplyCallId = sendAPIRequest(configJSON.postCommentReplyEndpoint, {
      method: 'POST',
      body,
      headers: { token }
    })

    let stateEmogi = false;
    this.setState({ openCommentReplyEmoji: stateEmogi });
  }

  loadMore = () => {
    const { token } = this.state
    const post_id = this.props.navigation.getParam('post_id')
    let post_url = configJSON.getAllPostCommentEndpoint.replace(':post_id', post_id) + '&page=' + this.state.pagination?.next_page;
    this.getMoreCommentCallId = sendAPIRequest(post_url, {
      method: 'GET',
      headers: { token }
    })
  }

  onLikeChange = (postId: any, delta: number) => {
    let postData = this.state.postData;
    postData.total_likes += delta;
    postData.like_id = [];
    this.setState({ postData })
  }
  onLikeIdUpdate = (postId: any, likeObj: any) => {
    let postData = this.state.postData;
    postData.like_id = [likeObj];
    this.setState({ postData })
  }

  handleFavoriteClick = () => {
    let postData = this.state.postData;
    // if a fav request already exists, skip this one
    //if (Array.from(Object.values(this.favCallIdToPostId)).includes(postId)) return;

    if (postData) {
      let endpoint = postData.is_favourite ? configJSON.setPostUnFavEndpoint : configJSON.setPostFavEndpoint
      const method = postData.is_favourite ? 'DELETE' : 'POST'
      if (!postData.is_favourite) {
        // POST REQUEST
        const body = new FormData()
        body.append("data[favouriteable_type]", "BxBlockPosts::Post")
        body.append("data[favouriteable_id]", postData.id as string)
        const callId = sendAPIRequest(endpoint, { body, method, headers: { token: this.state.token } })
        this.apiFavPostCallId.push(callId)
        this.favCallIdToPostId[callId] = postData.id
      } else {
        // DELETE REQUEST
        if (!postData.favourite_id?.length) return;

        endpoint = endpoint.replace(':id', String(postData.favourite_id[0].id))
        sendAPIRequest(endpoint, { method, headers: { token: this.state.token } })
      }
      // optimistic update
      postData.is_favourite = !postData.is_favourite
      postData.favourite_id = []
      this.setState({ postData })
      const updatedPost = {id:postData.id, ...postData}
      sendPostUpdatedMessage(updatedPost)
    }
  }

  onCommentEmojiClick = (event: any, emojiObject: any) => {
    let emoji = emojiObject?.emoji;
    let userComment = this.state.userComment + emoji;
    this.setState({ userComment });
  }

  openEmojiComment = () => {
    let stateEmogi = !this.state.openCommentEmoji;
    this.setState({ openCommentEmoji: stateEmogi });
  }

  openEmojiCommentReply = () => {
    let stateEmogi = !this.state.openCommentReplyEmoji;
    this.setState({ openCommentReplyEmoji: stateEmogi });
  }

  onReplyEmojiClick = (event: any, emojiObject: any) => {
    let emoji = emojiObject?.emoji;
    let userReply = this.state.userReply + emoji;
    this.setState({ userReply });
  }

  showReply = (comment_id: any) => {
    this.setState({ openReply: comment_id });
  }
  // Customizable Area End
  
}

import React from 'react'
import { useField } from 'formik'
import {
    Typography,
    MenuItem,
    TextField,
    InputAdornment,
    ListSubheader,
    Checkbox as CBox,
    FormControl,
    InputLabel,
    Avatar,
    Chip
} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import { throttle } from 'throttle-debounce';
import { styled } from '@material-ui/styles';
import { useDebounce } from './utils'
import SelectInput from './SelectInput.web'
import { SearchIcon } from './Icons.web'
// @ts-ignore
import classes from './SearchSelect.module.css';
import { makeStyles } from '@material-ui/styles';
import PlusMorePopup from './PlusMorePopup.web';
import { profileAvatar } from "../../components/src/LeftMenuWeb";

interface SearchSelectProps {
    name: string;
    // these items will be added to the cache, use for populating values not in items
    preCacheItems?: any[];
    items: any;
    allItems: any[];
    onSearch?: (query: string) => void;
    hasMore: boolean;
    nextPage: () => void;
    getOptionValue: (item: any) => any;
    SelectItem: any;
    loading: boolean;
    placeholder?: string;
    [x: string]: any;
    selectProps?: any;
    onSelectInput?: any;
    from?: string;
    disableField?:any;
    taggedUser?:any;
    isAvatar?:any;

}

const useStyles = makeStyles({
    paper: {
        maxHeight: (props: any) => props.scrollHeight || '300px !important',
        maxWidth: '300px',
        "& ul": {
            backgroundColor: "#FFF",
        },
    }
});

const SearchSelect = (props: SearchSelectProps) => {
    console.log(props, "props in search selected :::");
    const [field, meta, helpers] = useField(props.name || "")
    const items = props.allItems
    const { hasMore, nextPage, multiple = true } = props
    const [search, setSearch] = React.useState('')
    const [paginationLoading, setPaginationLoading] = React.useState(false)
    const debouncedSearch = useDebounce(search, 300)
    const SelectItem = props.SelectItem;
    const SelectedItem = props.SelectedItem;
    const getOptionValue: any = (props.getOptionValue) || ((s: any) => String(s));
    const [itemsCache, setItemsCache] = React.useState<any>({})
    const taggedUser = props?.taggedUser;

    React.useEffect(() => {
        if (props.preCacheItems) {
            const cache = { ...itemsCache }
            props.preCacheItems?.forEach((item: any) => { cache[item.id] = item })
            setItemsCache(cache)
        }
    }, [props.preCacheItems])

    React.useEffect(() => {
        const cache = { ...itemsCache }
        props.items?.forEach((item: any) => { cache[item.id] = item })
        setItemsCache(cache)
    }, [props.items])
    console.log({ itemsCache }, "itemsCache:::")

    React.useEffect(() => {
        setPaginationLoading(false)
    }, [props.items])

    const onSearchChange = (e: any) => {
        setSearch(e.target.value)
    }

    React.useEffect(() => {
        props.onSearch && props.onSearch(debouncedSearch)
    }, [debouncedSearch, props.onSearch])

    const _handleScroll = throttle(300, (event: any) => {
        if (!event?.type) return;
        if (!hasMore || paginationLoading) return
        const SCROLL_THREASHOLD = 20
        const HEIGHT = 300 // hardcoded list height
        const st = event.target.scrollTop
        const sh = event.target.scrollHeight
        if (sh <= st + HEIGHT + SCROLL_THREASHOLD) {
            typeof nextPage === "function" && nextPage()
            setPaginationLoading(true)
        }
    })
    const handleScroll = (e: any) => {
        e.persist()
        _handleScroll(e)
    }

    const { paper } = useStyles(props);

    const MenuProps = {
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
        },
        transformOrigin: {
            vertical: "top",
            horizontal: "left"
        },
        getContentAnchorEl: null,
        classes: { paper: paper },
        onScroll: handleScroll,
        style: { maxWidth: '300px', maxHeight: '150px !important;' }
    }

    const handleClick = (e: any) => {
        console.log(e, "ytytee::")
        if (!multiple && e === undefined) {
            // Deselect the option if it is already selected in single-select mode
            helpers.setValue('')
          }
        
        // let result = items && items?.filter((f: any) => e?.some((s: any) => s === f))
        // console.log(result, "result:::")
        props.onSelectInput && props.onSelectInput(e)
    }
  

    const handleDeleteChip=(id:any)=>{
        console.log("kgfyhj")
    }

    if(taggedUser){
        return <div style={{ gap:'5PX', display:'flex', alignItems: 'center', background: '#F5F5F5', border:'0.01rem solid #B3AEAE', borderRadius:'25px',paddingRight: "7px"}}>
        <BottomAvatar style={{border: '3px solid rgb(242,212,212)'}}>
        <img src={taggedUser[0]?.profile_pic ? taggedUser[0]?.profile_pic : profileAvatar} alt="img" width="100%" height="100%" />
        </BottomAvatar>
        <SubTextGray>{taggedUser[0]?.attributes.email}{" "}</SubTextGray>
        {taggedUser?.length>1 && <PlusMorePopup heading="Tagged Users" listItems={taggedUser?.map((com:any)=> com.attributes.email)}>
        +{taggedUser?.length - 1}
        </PlusMorePopup>}
        </div>  
    }

    // Implement this on the backend, when new items are added results could get shuffled
    // const sortedItems = props.items.sort((a: any, b:any)=>`${a.first_name} ${a.last_name}`.localeCompare(`${b.first_name} ${b.last_name}`))
    return <>
        <SelectInput
            {...(props.selectProps || {})}
            multiple={multiple}
            displayEmpty
            fullWidth
            // disabled={props?.disableField || false}
            onClick={(e: any) => handleClick(e.target.value)}
            onClose={() => setSearch('')}
            {...field}
            // @ts-ignore 
            MenuProps={MenuProps}
            loading={props.loading || paginationLoading}
            error={meta.touched && Boolean(meta.error)}
            renderValue={(_selected) => {
                const selected = _selected as any;
                if (selected?.length === 0) {
                    return <div style={{ display: "flex" }}>
                        <Typography color="textSecondary">
                            {props.placeholder || "Select"}
                        </Typography>
                    </div>;
                }
                console.log({ selected }, "selected")
                const names = multiple ? (selected || []).map?.((id: any) => itemsCache[id])?.filter((s: any) => !!s)?.map?.(getOptionValue)?.join?.(', ') : [itemsCache[selected]]?.filter((s: any) => !!s)?.map?.(getOptionValue)?.join?.(', ');
                const selectedValues = multiple ? (selected || []).map?.((id: any) => itemsCache[id])?.filter((s: any) => !!s)?.map((user: any) => user) : [itemsCache[selected]]?.filter((s: any) => !!s)?.map((user: any) => user);
                const coinNames =multiple ? (selected || []).map?.(({id}:any)=>itemsCache[id])?.filter((f:any)=>!!f)?.map?.(getOptionValue)?.join?.(', ') : "text"
                return props.SelectedItem ?
                    <SelectedItem items={selectedValues} />
                    : props.isChip ? 
                       (selectedValues?.map((item:any)=>{
                            return (<Chip style={{margin:'0 2px'}}
                            size="medium"
                            avatar={<Avatar src={item?.profile_pic} />}
                            label={props.isChipWithName ? `${item?.first_name} ${item?.last_name}` : item?.email} 
                            // onDelete={()=>props.handleDeleteChip(item?.id)} 
                            />)
                        }))
                    : props.isAvatar?   (<StyledAvatar max={4}>
                       { selectedValues?.map((item:any)=>{
                        return (<Avatar src={item?.profile_pic}  style={{ width: "20px", height: "20px" }}/>)
                    })}</StyledAvatar>):(<Typography align="left">{props.from === "coinTransfer" ? coinNames : names || ''}</Typography>);
            }}
        >
            <SubHeader>
                <TextField
                    onChange={onSearchChange}
                    size="small"
                    autoFocus
                    placeholder={"Search"}
                    fullWidth
                    onClick={(e: any) => { e.preventDefault(); e.stopPropagation() }}
                    InputProps={{
                        className: classes.searchSelectInput,
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon width="16px" style={{ marginBottom: '5px' }} />
                            </InputAdornment>
                        ),
                        disableUnderline: true,
                    }}
                    // onChange={(e) =>  helpers.setValue({searchText:e.target.value})}
                    onKeyDown={(e) => {
                        if (e.key !== "Escape") {
                            // Prevents autoselecting item while typing (default Select behaviour)
                            e.stopPropagation();
                        }
                    }}
                />
                {(!props.loading) && props.loading && (
                    <Typography style={{ padding: '15px', background: 'white' }}>
                        Loading ....
                    </Typography>)
                }
                {
                     props.items?.length === 0 && <Typography style={{ padding: '15px' }}>
                        No match found
                    </Typography>
                }
            </SubHeader>
            {props.items?.map((item: any) => (
                <MenuItem style={{
                    height: "40px",
                    display: "flex",
                    padding: "20px 20px",
                    flexDirection: 'row-reverse',
                    justifyContent: 'space-between',
                // }} value={item.id} key={item.id}>
                 }} value={props.from === "coinTransfer" ? item : item?.id} key={item.id}>  
                    <SelectItem item={item} value={field.value as any[]} />
                </MenuItem>
            ))}
            {paginationLoading && <div style={{ padding: '20px', color: '#999' }}>Loading...</div>}
        </SelectInput>
        <Typography variant="caption" color="error">
            {meta.touched && Boolean(meta.error) && meta.error}
        </Typography>
    </>
}


export default SearchSelect


export const Checkbox = (props: any) => <CBox
    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
    checkedIcon={<CheckBoxIcon fontSize="small" style={{ color: "#1ea60a" }} />}
    {...props}
/>

const SubHeader = styled(ListSubheader)({
    background: 'white',
    borderBottom: '1px solid rgba(0, 0, 0, 0.3)'
})

const SubTextGray = styled('span')({
    color: "rgba(21, 20, 20, 0.65)",
    alignSelf: 'center',
})

const BottomAvatar = styled(Avatar)({
    margin: '2px',
    '@media screen and (max-width:400px)': {
        height: '35px',
        width: '35px',
        gap: '5px'
    }
})

const StyledAvatar = styled(AvatarGroup)({
	'& .MuiAvatar-root': { width: 20, height: 20, fontSize: 15 },
})

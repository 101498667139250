import React from 'react'
import { useFormikContext, useField } from 'formik'


interface FormikEffectProps {
	onChange: any;
}

export function FormikEffect({ onChange }: FormikEffectProps){
	const { values, setFieldError, ...other } = useFormikContext();
	// console.log({other})
	console.log({values})
	React.useEffect(() => {
		onChange && onChange({ values, setFieldError })
	}, [values, setFieldError])	
	return null
}

interface FieldEffectProps {
	name: string,
	onChange: any,
}


export function FieldEffect({ name, onChange }: FieldEffectProps) {
	const [field, meta, helpers] = useField(name)
	React.useEffect(() => {
		// console.log({fieldValue: field.value})
		onChange && onChange(field.value)
	}, [field.value, onChange])

	return <></>
}


export default FormikEffect
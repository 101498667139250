import React from "react";
// Customizable Area Start
import DashboardLayout from "../../../components/src/DashboardLayout.web";
import {
    InputBase, Chip, Grid, Divider, Typography, IconButton, Button, Box, Avatar,
} from '@material-ui/core'
import { styled } from '@material-ui/styles'
import ShareIcon from '@material-ui/icons/Share';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CancelIcon from '@material-ui/icons/Cancel';
import { Link } from 'react-router-dom';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined';
import { BitmapIcon0, BitmapIcon1, BitmapIcon2, BitmapIcon3, BitmapIcon4 } from "./assets";
import UserContext from "../../../components/src/UserProvider";
import Flex from "../../../components/src/Flex.web";
import SeeMore from "../../../components/src/SeeMore.web";
import PlusMorePopup from '../../../components/src/PlusMorePopup.web'
import { profileAvatar } from "../../../components/src/LeftMenuWeb";
import LinearProgress from '@material-ui/core/LinearProgress';
import DeleteModal from '../../../components/src/DeleteModal.web'
import DueDiligenceDetailsController, { Props } from "./DueDiligenceDetailsController";
import OverlayLoader from '../../../components/src/Loader.web'
// Customizable Area End
export default class DueDiligenceDetails extends DueDiligenceDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderComplexity1 = (allQuestion: any, surveyData: any,) => {
        return (
            <>
                <QuestionBox style={{ minHeight: allQuestion?.data?.length < 1 ? '250px' : 'auto' }}>
                    <Box style={{ width: '100%', display: "flex", flexDirection: "column" }}>
                        <Flex justify="space-between">
                            <Flex style={{ flexDirection: 'column' }}>
                                <TemplateName>Questions</TemplateName>
                                <Temp>
                                    {allQuestion?.data?.length < 1 && <SubText>No questions added!</SubText>}
                                </Temp>
                            </Flex>
                            {(surveyData?.status == "active" || surveyData?.mandatory) &&
                                <div>
                                    <AddBtn id='addQuestion' onClick={this.onQuestionAdd}>
                                        <AddCircleIcon />
                                        ADD
                                    </AddBtn>
                                </div>}
                        </Flex>
                    </Box>
                    {/* {true && <div> */}
                    {allQuestion?.data?.map((question: any) => {
                        if (question?.attributes?.response_type == "subjective") {
                            return (
                                <Box style={{ width: '100%', display: "flex", flexDirection: "column", border: '1px solid #B3AEAE', borderRadius: '15px' }}>
                                    <div style={{ padding: '15px', }}>
                                        <Flex justify="space-between">
                                            <Flex style={{ flexDirection: 'column', }}>
                                                <TemplateName style={{ fontSize: '15px' }}>{question?.attributes?.question} {question?.attributes?.question_type == "mandatory" && <span style={{ color: 'red' }}> * </span>}</TemplateName>
                                            </Flex>
                                            <div style={{ gap: '10px', display: 'flex', flexDirection: 'row' }}>
                                                <DeleteOutlineIcon data-test-id='deleteQuestion' color="disabled" onClick={this.deleteQuestion(question?.id)} />
                                                <EditOutlinedIcon color="disabled" onClick={this.onEditQuestion(question?.id)} />
                                            </div>
                                        </Flex>
                                        {/* <div style={{padding: '15px', marginTop: '10px', borderRadius: '15px', background:"rgba(96, 89, 89, 0.1)"}}>
                                                <SeeMore text={`Reply Here`} />
                                                </div> */}
                                        {/* <Attachment>
                                                    <Attachments
                                                    files={([{ id: 1300, url: "https://corpstageplatform-95566-ruby.b95566.dev.eastus.az.sv…0b94afa4cdcd33e6891/Terms%20and%20Conditions%20Corpstage.pdf", filename: "document.pdf",type: "application",size: 179.55}]).map((j: any) => ({ ...j, }))}
                                                    />
                                                </Attachment> */}
                                    </div>
                                </Box>
                            )
                        } else if (question?.attributes?.response_type == "multiple_choice" && question?.attributes?.response_type == "summation") {
                            return (
                                <Box style={{ width: '100%', display: "flex", flexDirection: "column", border: '1px solid #B3AEAE', borderRadius: '15px' }}>
                                    <div style={{ padding: '15px', }}>
                                        <Flex justify="space-between">
                                            <Flex style={{ flexDirection: 'column', }}>
                                                <TemplateName style={{ fontSize: '15px' }}>{question?.attributes?.question} {question?.attributes?.question_type == "mandatory" && <span style={{ color: 'red' }}> * </span>}</TemplateName>
                                            </Flex>
                                            <div style={{ gap: '10px', display: 'flex', flexDirection: 'row' }}>
                                                <DeleteOutlineIcon color="disabled" onClick={this.deleteQuestion(question?.id)} />
                                                <EditOutlinedIcon color="disabled" onClick={this.onEditQuestion(question?.id)} />
                                            </div>
                                        </Flex>
                                        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly', gap: '10px', marginTop: '10px' }}>
                                            {question?.attributes?.options.map((options: any) => {
                                                return (
                                                    <CustomChip>{options?.title}</CustomChip>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </Box>
                            )
                        } else if (question?.attributes?.response_type == "rating") {
                            return (
                                <Box style={{ width: '100%', display: "flex", flexDirection: "column", border: '1px solid #B3AEAE', borderRadius: '15px' }}>
                                    <div style={{ padding: '15px', }}>
                                        <Flex justify="space-between">
                                            <Flex style={{ flexDirection: 'column', }}>
                                                <TemplateName style={{ fontSize: '15px' }}>{question?.attributes?.question} {question?.attributes?.question_type == "mandatory" && <span style={{ color: 'red' }}> * </span>}</TemplateName>
                                            </Flex>
                                            <div style={{ gap: '10px', display: 'flex', flexDirection: 'row' }}>
                                                <DeleteOutlineIcon color="disabled" onClick={this.deleteQuestion(question?.id)} />
                                                <EditOutlinedIcon color="disabled" onClick={this.onEditQuestion(question?.id)} />
                                            </div>
                                        </Flex>
                                        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', justifyContent: 'space-evenly', marginTop: '10px' }}>
                                            <CustomChip><BtnImg src={BitmapIcon0} />{question?.attributes?.options[0]?.title}</CustomChip>
                                            <CustomChip><BtnImg src={BitmapIcon1} />{question?.attributes?.options[1]?.title}</CustomChip>
                                            <CustomChip><BtnImg src={BitmapIcon2} />{question?.attributes?.options[2]?.title}</CustomChip>
                                            <CustomChip><BtnImg src={BitmapIcon3} />{question?.attributes?.options[3]?.title}</CustomChip>
                                            <CustomChip><BtnImg src={BitmapIcon4} />{question?.attributes?.options[4]?.title}</CustomChip>
                                        </div>
                                    </div>
                                </Box>
                            )
                        } else if (question?.attributes?.response_type == "scoring") {
                            return (
                                <Box style={{ width: '100%', display: "flex", flexDirection: "column", border: '1px solid #B3AEAE', borderRadius: '15px' }}>
                                    <div style={{ padding: '15px', }}>
                                        <Flex justify="space-between">
                                            <Flex style={{ flexDirection: 'column', }}>
                                                <TemplateName style={{ fontSize: '15px' }}>{question?.attributes?.question} {question?.attributes?.question_type == "mandatory" && <span style={{ color: 'red' }}> * </span>}</TemplateName>
                                            </Flex>
                                            <div style={{ gap: '10px', display: 'flex', flexDirection: 'row' }}>
                                                <DeleteOutlineIcon color="disabled" onClick={this.deleteQuestion(question?.id)} />
                                                <EditOutlinedIcon color="disabled" onClick={this.onEditQuestion(question?.id)} />
                                            </div>
                                        </Flex>
                                        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly', gap: '10px', marginTop: '10px' }}>
                                            {question?.attributes?.options.map((options: any) => {
                                                return (
                                                    <CustomChip>{options?.title}{" - "}{options?.score}</CustomChip>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </Box>
                            )
                        }
                    })}

                </QuestionBox>
            </>
        )
    }

    renderComplexity2 = (surveyData: any) => {
        return (
            <>
                <Box style={{ width: '100%', display: "flex", flexDirection: "column" }}>
                    <Flex justify="space-between">
                        <Flex style={{ flexDirection: 'column' }}>
                            <TemplateName>{surveyData?.template_name?.substring(0, 50)}{surveyData?.template_name?.length > 50 ? "..." : ""}</TemplateName>
                            <Temp style={{ display: 'flex', flexWrap: 'wrap' }}>
                                <SubText>{surveyData.survey_title?.substring(0, 30)}{surveyData?.survey_title?.length > 30 ? "..." : ""}</SubText>
                                <TemplateId>Template ID : {surveyData?.survey_uniq_id}</TemplateId>
                            </Temp>
                        </Flex>
                        <div>
                            {!surveyData?.mandatory && (<DeleteOutlineIcon data-test-id='deleteSurvey' color="disabled" onClick={this.handleDeletePopup(surveyData)} />)}
                            <EditOutlinedIcon id='editTemplate' color="disabled" onClick={this.handleEditTemplate(surveyData?.id)} />
                        </div>
                    </Flex>
                </Box>
                <SeeMore text={`${surveyData?.description}`} />
                <div>
                    <Grid style={{ marginBottom: '12px' }} spacing={2} container alignItems="center" justifyContent="flex-start" wrap="wrap" >
                        <Grid xs item style={{ flexGrow: 0 }}>
                            <Typography style={{ whiteSpace: 'nowrap' }} >Start Date : <SubTextGray>{surveyData?.start_date}</SubTextGray></Typography>
                        </Grid>
                        <Divider style={{ height: '20px', alignSelf: 'center', marginLeft: '15px', marginRight: "15px" }} orientation="vertical" flexItem />
                        <Grid xs item style={{ flexGrow: 0 }}>
                            <Typography style={{ whiteSpace: 'nowrap' }} >End Date : <SubTextGray>{surveyData?.end_date}</SubTextGray></Typography>
                        </Grid>
                        {surveyData?.inactive_date && <Divider style={{ height: '20px', alignSelf: 'center', marginLeft: '15px', marginRight: "15px" }} orientation="vertical" flexItem />}
                        <Grid xs item style={{ flexGrow: 0 }}>
                            {surveyData?.inactive_date && <Typography style={{ whiteSpace: 'nowrap' }} >Inactive Date : <SubTextGray>{surveyData?.inactive_date}</SubTextGray></Typography>}
                        </Grid>
                    </Grid>
                    <Grid style={{ marginBottom: '12px' }} spacing={2} container alignItems="center" justifyContent="flex-start" wrap="wrap">
                        <Grid xs item style={{ flexGrow: 0 }}>
                            {/* <div style={{display: 'flex', flexWrap: 'wrap',}}> */}
                            <Typography style={{ whiteSpace: 'nowrap' }} >Tagged Communities : <SubTextGray>{surveyData?.tagged_communities?.data[0] != undefined && surveyData?.tagged_communities?.data[0].attributes.name}{" "}{surveyData?.tagged_communities?.data?.length > 1 && <PlusMorePopup heading="Tagged Communities" listItems={surveyData?.tagged_communities?.data?.map((com: any) => com.attributes.name)}>
                                +{surveyData?.tagged_communities?.data?.length - 1}
                            </PlusMorePopup>}</SubTextGray>
                            </Typography>
                            {/* </div> */}
                        </Grid>
                        {surveyData?.tagged_project && <Divider style={{ height: '20px', alignSelf: 'center', marginLeft: '15px', marginRight: "15px" }} orientation="vertical" flexItem />}
                        <Grid xs item style={{ flexGrow: 0 }}>
                            {surveyData?.tagged_project && <Typography style={{ whiteSpace: 'nowrap' }} >Tagged Project : <SubTextGray>{ }</SubTextGray></Typography>}
                        </Grid>
                    </Grid>
                    <Grid style={{ marginBottom: '12px' }} spacing={2} container alignItems="center" justifyContent="flex-start" wrap="wrap">
                        <Grid xs item style={{ flexGrow: 0 }}>
                            {/* <div style={{display: 'flex', flexWrap: 'wrap',}}> */}
                            <Typography style={{ whiteSpace: 'nowrap' }} >Approximate Time to Complete The Survey: <SubTextGray>{surveyData?.survey_time ? surveyData?.survey_time : 0}{" mins"}</SubTextGray> </Typography>
                            {/* </div> */}
                        </Grid>
                        <Divider style={{ height: '20px', alignSelf: 'center', marginLeft: '15px', marginRight: "15px" }} orientation="vertical" flexItem />
                        <Grid xs item style={{ flexGrow: 0 }}>
                            {surveyData?.reminder_frequency && <Typography style={{ whiteSpace: 'nowrap' }} >Reminder Frequency : <SubTextGray>{surveyData?.reminder_duration}{" "}{surveyData?.reminder_duration ? surveyData?.reminder_type : ""}</SubTextGray></Typography>}
                        </Grid>
                    </Grid>
                    <Grid style={{ marginBottom: '12px' }} spacing={2} container alignItems="center" justifyContent="flex-start" wrap="wrap">
                        <Grid xs item style={{ flexGrow: 0 }}>
                            <Typography style={{ whiteSpace: 'nowrap' }} >Target Respondents: <SubTextGray>{surveyData?.no_of_respondents}</SubTextGray></Typography>
                        </Grid>
                        <Divider style={{ height: '20px', alignSelf: 'center', marginLeft: '15px', marginRight: "15px" }} orientation="vertical" flexItem />
                        <Grid xs item style={{ flexGrow: 0 }}>
                            <Typography style={{ whiteSpace: 'nowrap' }} >No of Responses : <SubTextGray>{surveyData?.no_of_responses}</SubTextGray></Typography>
                        </Grid>
                        <Divider style={{ height: '20px', alignSelf: 'center', marginLeft: '15px', marginRight: "15px" }} orientation="vertical" flexItem />
                        <Grid xs item style={{ flexGrow: 0 }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography style={{ whiteSpace: 'nowrap' }} >Response Status : </Typography>
                                <LinearProgress variant="determinate" value={surveyData?.response_status} style={{ height: '6px', borderRadius: '5px', maxWidth: '170px', minWidth: '80px', marginLeft: "8px" }} />
                                <Typography style={{ marginLeft: '5px' }}>{surveyData?.response_status}%</Typography>
                            </div>
                        </Grid>
                    </Grid>
                    {this.renderComplexityGrid(surveyData)}
                </div>
            </>
        )
    }

    renderComplexityGrid = (surveyData:any) => {
        return <>
            <Grid style={{ marginBottom: '0px' }} spacing={2} container alignItems="center" justifyContent="center" wrap="wrap">
                <Grid xs item>
                    <Flex justify="start" align="center" gap={8}>
                        <Typography style={{ whiteSpace: 'nowrap' }} > <SubTextGray>Created By : </SubTextGray> </Typography>
                        <Link to={`/user/${surveyData?.created_by?.id}`}>
                            <BottomAvatar style={{ border: '3px solid rgb(242,212,212)' }} >
                                <img src={this.context.profile_pic ? this.context.profile_pic : profileAvatar} alt="img" width="100%" height="100%" />
                            </BottomAvatar>
                        </Link>
                        <Link to={`/user/${surveyData?.created_by?.id}`}>
                            <Typography style={{ fontSize: '15px', fontWeight: 550 }}>{surveyData?.created_by?.first_name ? surveyData?.created_by?.first_name : ""}{" " + surveyData?.created_by?.last_name ? surveyData?.created_by?.last_name : ""}</Typography>
                        </Link>
                    </Flex>
                </Grid>
                <Grid xs item>
                    <Flex justify="start" align="center" gap={8}>
                        <Typography style={{ whiteSpace: 'nowrap' }} > <SubTextGray>Requested By : </SubTextGray></Typography>
                        <Link to={`/user/${surveyData?.requested_by?.id}`}>
                            <BottomAvatar style={{ border: '3px solid rgb(242,212,212)' }}>
                                <img src={surveyData.requested_by?.profile_pic ? surveyData.requested_by?.profile_pic : profileAvatar} alt="img" width="100%" height="100%" />
                            </BottomAvatar>
                        </Link>
                        <Link to={`/user/${surveyData?.requested_by?.id}`}>
                            <Typography style={{ fontSize: '15px', fontWeight: 550 }}>{surveyData?.requested_by?.first_name ? surveyData?.requested_by?.first_name : ""}{" "}{surveyData?.requested_by?.last_name ? surveyData?.requested_by?.last_name : ""}</Typography>
                        </Link>
                    </Flex>
                </Grid>
                <Grid xs item>
                    <Flex justify="end" align="center" gap={8}>
                        <EmailBtn data-test-id='openEMailPopup' onClick={this.openEmailPopup}>
                            Send Email
                        </EmailBtn>
                    </Flex>
                </Grid>
            </Grid></>
    }
    // Customizable Area End

    render() {
        // Customizable Area Start

        const { navigation } = this.props;
        const { surveyData, allQuestion } = this.state;
        console.log("surveyData ", surveyData)
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <DashboardLayout token={this.state.token} navigation={navigation}>
                    <OverlayLoader loading={this.state.loader} />
                    {this.state.deleteTemplteId && <DeleteModal data-test-id='deleteTemplate' label="Delete this Survey?" onClose={this.closeDeleteModal} onSubmit={this.handleDeleteTemplate(surveyData?.id)} />}
                    {this.state.deleteQuestion && <DeleteModal data-test-id='deletequestionSubmit' label="Delete this Question?" onClose={this.closeModal} onSubmit={this.onDeleteQuestion(this.state?.deleteId)} />}
                    <DIV>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <CoinsHead>
                                <ArrowBackIosIcon id='goBack' onClick={this.onBack} />
                                Back
                            </CoinsHead>
                            <DownloadButton id='downloadReport' onClick={() => this.downloadReport('excel')}>
                                <ArrowDownwardIcon color="inherit" />
                                Download Report
                            </DownloadButton>
                        </div>
                        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                            {/* <Grid spacing={2} container> */}
                            {/* <Grid xs={12} sm={12} md={12} lg={6} xl={6} item> */}
                            <SurveyBox style={{ backgroundColor: "white", width: '100%', overflow: 'hidden', display: "flex", gap: '18px', flexDirection: "column", justifyContent: 'space-between', borderRadius: "10px", padding: "20px" }}>
                                {this.renderComplexity2(surveyData)}
                                <Divider variant="fullWidth" />
                                <Grid spacing={2} container alignItems="center" justifyContent="center" wrap="wrap">
                                    <Grid xs item>
                                        <Flex style={{ width: '100%', display: 'flex' }} justify="start" align="center" gap={8}>
                                            <Typography style={{ whiteSpace: 'nowrap' }} > <SubTextGray>Tagged Users : </SubTextGray> </Typography>
                                            <div style={{ gap: '5PX', display: 'flex', alignItems: 'center', background: '#F5F5F5', border: '0.01rem solid #B3AEAE', borderRadius: '25px', paddingRight: "7px" }}>
                                                <Link to={`/user/${surveyData?.tagged_users?.data[0]?.id}`}>
                                                    <BottomAvatar style={{ border: '3px solid rgb(242,212,212)' }}>
                                                        <img src={surveyData?.tagged_users?.data[0]?.profile_pic ? surveyData?.tagged_users?.data[0]?.profile_pic : profileAvatar} alt="img" width="100%" height="100%" />
                                                    </BottomAvatar>
                                                </Link>
                                                <Link to={`/user/${surveyData?.tagged_users?.data[0]?.id}`}>
                                                    <SubTextGray>{surveyData?.tagged_users?.data[0]?.attributes.email}{" "}</SubTextGray>
                                                </Link>
                                                {surveyData?.tagged_users?.data?.length > 1 && <PlusMorePopup heading="Tagged Users" listItems={surveyData?.tagged_users?.data?.map((com: any) => com.attributes.email)}>
                                                    +{surveyData?.tagged_users?.data?.length - 1}
                                                </PlusMorePopup>}
                                            </div>
                                        </Flex>
                                    </Grid>
                                    {!surveyData?.mandatory &&
                                        <Grid xs item>
                                            <Flex justify="end" align="center" gap={8}>
                                                <SubTextGray>Share : </SubTextGray>
                                                <ShareButton aria-label="settings" onClick={this.handleShareClick(surveyData)}>
                                                    <ShareIcon />
                                                </ShareButton>
                                            </Flex>
                                        </Grid>
                                    }

                                </Grid>
                            </SurveyBox>

                            {this.renderComplexity1(allQuestion, surveyData)}
                            {/* </Grid> */}
                            {/* </Grid> */}
                        </div>
                    </DIV>
                </DashboardLayout>
                {this.state.emailPopup &&
                    <div style={{ position: 'fixed', bottom: 0, right: 0, width: '330px', height: '430px', background: '#fff', border: '2px solid #E5EDE6', borderRadius: '10px' }}>
                        {/* this is right corner box */}
                        <Box style={{ display: 'flex', justifyContent: 'space-between', height: '30px', alignItems: 'center', padding: '10px', background: '#0d0629' }}>
                            <Typography style={{ color: '#fff', fontWeight: 'bold', fontSize: '1rem' }}>COMPOSE</Typography>
                            <CancelIcon style={{ color: '#fff', cursor: 'pointer' }} onClick={this.closeEmailPopup} />
                        </Box>
                        <div style={{ overflow: 'auto', height: '400px', display: 'flex', flexDirection: 'column' }}>
                            <Box style={{ display: 'flex', height: '30px', alignItems: 'center', padding: '10px' }}>
                                <Typography style={{ marginRight: '10px' }}>From:</Typography>
                                <Chip
                                    size="medium"
                                    avatar={<Avatar alt="Natacha" src="/static/images/avatar/1.jpg" />}
                                    label={this.context.email}
                                />
                            </Box>
                            <Divider variant="fullWidth" />
                            <Box style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', padding: '10px' }}>
                                <Typography style={{ marginRight: '10px', position: 'static' }}>{"To: "}</Typography>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '3px' }}>
                                        {this.state?.sendTo?.map((email: any, index: number) => {
                                            return (
                                                <Chip
                                                    size="medium"
                                                    avatar={<Avatar alt="Natacha" src="/static/images/avatar/1.jpg" />}
                                                    label={email}
                                                    onDelete={() => this.deleteEmail(email)}
                                                />)
                                        }
                                        )}

                                    </div>
                                    <InputBase style={{ width: '100%', paddingLeft: '10px' }}
                                        data-test-id='addEmail'
                                        // value={this.state?.subject}
                                        value={this.state?.newEmail}
                                        onChange={this.emailValue}
                                        onKeyDown={this.addEmail}
                                        autoFocus
                                    />
                                </div>
                            </Box>
                            <Divider variant="fullWidth" />
                            <Box style={{ display: 'flex', height: '30px', alignItems: 'center', padding: '10px' }}>
                                <Typography>Subject:</Typography>
                                <InputBase style={{ width: '100%', paddingLeft: '10px' }}
                                    data-test-id='changeSubject'
                                    value={this.state?.subject}
                                    onChange={this.changeSubject}
                                />
                                {/* <Chip
                            size="medium"
                            avatar={<Avatar alt="Natacha" src="/static/images/avatar/1.jpg" />}
                            label="kavin@gmail.com"
                            // onDelete={handleDelete}
                        /> */}
                            </Box>
                            <Divider variant="fullWidth" />
                            <InputBase
                                data-test-id='changeContent'
                                id="outlined-multiline-static"
                                multiline
                                rows={8}
                                value={this.state.content}
                                onChange={this.changeContent}
                                //   variant="standard"
                                style={{ width: '100%', paddingLeft: '10px', marginBottom: '5px' }}
                            />
                            <Box style={{ display: 'flex', margin: '5px', paddingBottom: '5px', gap: '5px', justifyContent: 'flex-end' }}>
                                <EmailBtn style={{ background: 'gray' }}>
                                    <AttachFileOutlinedIcon />
                                    Attachment
                                </EmailBtn>
                                <EmailBtn data-test-id='sendEMail' onClick={this.handleSendEmail}>
                                    Send
                                </EmailBtn>
                            </Box>
                        </div>
                    </div>}
            </>
            // Customizable Area End
        )
    }
}


// Customizable Area Start
DueDiligenceDetailsController.contextType = UserContext




const DIV = styled('div')({
    '@media (max-width: 768px)': {
        padding: '0px 20px',
    }
})

const CoinsHead = styled('div')({
    display: 'flex',
    alignItems: 'center',
    color: 'gray',
    margin: '40px 0px 30px 12px',
    fontSize: '1rem',
    fontFamily: 'HelveticaNeue',
    fontWeight: 500,
    '@media screen and (max-width: 768px)': {
        margin: '20px 0px 10px 12px',
    },
})

const Attachment = styled('div')({
    padding: '5px 0',
    marginTop: '10px',
    minWidth: '250px',
    '@media screen and (max-width:480px)': {
        '& > div > div > div': {
            width: '170px',
            height: '35px',
        },
    },
    '& > div > p': {
        display: 'none',
    }
})

const SurveyBox = styled(Box)({
    width: '-moz-available !important'
})

const QuestionBox = styled(Box)({
    // width: '-moz-available !important',
    backgroundColor: "white",
    width: '100%',
    overflow: 'hidden',
    display: "flex",
    gap: '18px',
    flexDirection: "column",
    justifyContent: 'space-between',
    borderRadius: "10px",
    padding: "20px"
})

const TemplateName = styled(Typography)({
    fontWeight: "bold",
    color: "#292D32",
    fontSize: '20px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
})

const SubText = styled(Typography)({
    color: "#292D32",
    fontSize: '14px',
})

const SubTextGray = styled('span')({
    color: "rgba(21, 20, 20, 0.65)",
    alignSelf: 'center',
})

const Temp = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '10px',
})

const TemplateId = styled(Typography)({
    background: '#F5F5F5',
    border: '1px solid #E5EDE6',
    borderRadius: '10px',
    color: '#282D33',
    padding: '10px 15px',
    fontSize: '14px',
    fontWeight: 'bold',
    width: 'max-content',
})

const DownloadButton = styled(Button)({
    // background: '',
    display: 'flex',
    justifyContent: 'space-between',
    textTransform: 'none',
    color: 'Green',
    border: '1px solid Green',
    borderRadius: '15px',
    fontSize: '12px',
    width: '150px',
    height: '30px',
    fontWeight: 'bold',
})

const LiveBtn = styled(Typography)({
    background: '#ebf3fc',
    border: '1px solid #E5EDE6',
    borderRadius: '10px',
    color: '#2175E7',
    padding: '8px 25px',
    fontSize: '14px',
    width: 'max-content',
    fontWeight: 'bold',
    // opacity: 0.11,
    mixBlendMode: 'normal'
})

const ShareButton = styled(IconButton)({
    background: '#EBEBEB',
    '@media screen and (max-width:480px)': {
        marginTop: '20px'
    }
});

const EmailBtn = styled(Button)({
    background: 'Green',
    color: '#fff',
    borderRadius: '20px',
    fontSize: '12px',
    width: '120px',
    fontWeight: 'bold',
    '&:hover': {
        background: 'Green',
    }
})

const AddBtn = styled(Button)({
    background: 'Green',
    color: '#fff',
    borderRadius: '20px',
    fontSize: '12px',
    width: '95px',
    fontWeight: 'bold',
    justifyContent: 'space-evenly',
    '&:hover': {
        background: 'Green',
    }
})

const BottomAvatar = styled(Avatar)({
    margin: '2px',
    '@media screen and (max-width:400px)': {
        height: '35px',
        width: '35px',
        gap: '5px'
    }
})

const CustomChip = styled(Box)({
    background: 'rgba(96, 89, 89, 0.1)',
    padding: '10px',
    borderRadius: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '100%',
    // minWidth:'130px',
})

const BtnImg = styled('img')({
    width: 25
})

// Customizable Area End
Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
const config = require("../../../framework/src/config");
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "PeopleManagement2";
exports.labelBodyText = "PeopleManagement2 Body";
exports.QuibloxCred = config.QuibloxCred;
exports.createUserPeopleManagement = "bx_block_peoplemanagement2/people_managements/create_individual_user";
exports.btnExampleTitle = "CLICK ME";
exports.getPeopleManagementData = "bx_block_peoplemanagement2/people_managements";
exports.subscriptionDetail = "bx_block_peoplemanagement2/people_managements/get_subscription_status"
// Customizable Area End
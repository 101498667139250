import React from "react";
// Customizable Area Start
// @ts-ignore
import classes from './PermissionWeb.module.css'
import TopNavBar from "../../../components/src/TopNavbar";
import SearchSelect from "../../../components/src/SearchSelect.web";
import {
    Box, Grid, Typography,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Avatar,
    InputAdornment,
    TextField,
    Button,
    RadioGroup,
    FormControlLabel,
    Radio,
    ListItemText,
    
} from '@material-ui/core';
import { styled } from '@material-ui/styles'
import LeftMenuWeb from "../../../components/src/LeftMenuWeb";
import { EditIcon, deleteImg, SearchIcon } from './assets';
import { iconAdd } from '../../JobListing2/src/assets';
import { Formik, Field } from 'formik'
import FormModal, {
    InputRow,
    MInput,
  
  } from '../../../components/src/FormModal.web'
  import OverlayLoader from '../../../components/src/Loader.web'

import PermissionDashboardController, {
    Props,
} from "./PermissionDashboardController";
import UserContext from "../../../components/src/UserProvider";
import DeleteModal from '../../../components/src/DeleteModal.web';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
// Customizable Area End
export default class PermissionDashboard extends PermissionDashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

// Customizable Area Start
editSurveyAssesment(){
    const initialValues = {
      email_id: this.state?.editData?.attributes?.user_id || '',
      name: this.state?.editData?.attributes?.user_name || '',
      access: this.state?.editData?.attributes?.ability_type || 'read',
      }

  if (this.state?.modal != 'create') return <></>
    else
      return <Formik enableReinitialize initialValues={initialValues} onSubmit={this.submitAccess} validationSchema={this.validationSchema}>
        {({ values, errors }: any) => (
        <div className={classes.modalTopCls}>
          {console.log("chcek values ",values)}

          <FormModal headerTitle={<CreateHeader>MANAGE ACCESS - {this.state.editData ? 'EDIT USER' : 'ADD NEW USER'}</CreateHeader>} submitLabel="SAVE" onClose={this.handleClose} >
            {console.log(this.state.allEmails, "****emails")}
            {!this.state.editData && <InputRow label="Email Id*" input={<SearchSelect
                            multiple={false}
                            pagination={this.state.emailPagination}
                            hasMore={!!this.state.emailPagination?.next_page}
                            nextPage={this.paginateEmails}
                            loading={this.state.requestorSearchCallId !== ""}
                            onSearch={this.handleEmailSearch}
                            allItems={this.state.allEmails}
                            selectProps={{ onClose: this.handleEmailClose, disabled: false}}
                            items={this.state.allEmails}
                            name="email_id"
                            SelectItem={SelectItemUserWithoutCheckbox}
                            SelectedItem={SelectedItem}
                            getOptionValue={(user: any) => `${user.attributes.first_name} ${user.attributes.last_name}`}
                            isChip={false}
                            />
                        }
                        />}
            { this.state.editData && <InputRow label="Email Id*" input={<MInput name="name" disabled={true} value={`${this.state.editData.attributes.email}  - ${this.state.editData.attributes.user_name} `}/>} />}
            <InputRow label="Access Level*" input={
                <Field 
                render={({field}:any) => <><RadioGroup {...field} aria-label="quiz" 
                name="access" 
                value={(values?.access)}
                style={{flexDirection:'column'}}
                >
                    <FormControlLabel style={{marginLeft:'0', marginRight:'10'}} value={'read'} control={<Radio style={{color:'gray'}}/>} label="Read only" />
                    <FormControlLabel style={{marginLeft:'0', marginRight:'10'}} value={'write'} control={<Radio style={{color:'gray'}}/>} label="Read and Write" />
                </RadioGroup>
                </>
            }
            />
            } />   
          </FormModal>
        </div>
         )} 
      </Formik>
  }
// Customizable Area End

    render() {
    // Customizable Area Start
    const editAssesment = this.editSurveyAssesment();
    // Customizable Area End
    return (
        // Customizable Area Start
        <MainBox>
            {editAssesment}
            {this.state?.deleteAccess && <DeleteModal label="Delete this Access?" onClose={this.closeDeletePopup} onSubmit={this.deleteAccess(this.state?.deleteAccessId)} />}
            <Box>
                <TopNavBar navigation={this.props.navigation} id={""} />
            </Box>

            <OuterGrid container spacing={3}>
                <LeftGrid item xs={12} sm={12} md={3} lg={3}>
                    <Box >
                        <LeftMenuWeb navigation={this.props.navigation} id={""} />
                    </Box>
                </LeftGrid>
                <MiddleGrid item xs={12} sm={12} md={6} lg={6} >
                <OverlayLoader loading={this.state.loading} />
                    <SurveyCardWrapper>
                        <HeadWrapper>
                            <Head>
                            <Typography id="ESG" style={{color:'gray', fontWeight:'bold'}} onClick={() => this.props.navigation.navigate("ESGJourney")}> ESG Journey </Typography>
                            <ArrowBackIosIcon style={{fontWeight:100,color:'gray', marginLeft: '10px'}} onClick={() => this.props.navigation.navigate("ESGJourney")}/>
                                Manage Access for {this.props.navigation.getParam('type')}
                            </Head>
                            <div style={{display:'flex', flexDirection:'row'}}>
                                <div style={{ width: '250px', height: '42px', background: '#fff', borderRadius: '15px', marginRight:'50px' }}>
                                    <CustomTextField
                                        size="small"
                                        variant="outlined"
                                        InputProps={{
                                            startAdornment: (<InputAdornment position="start">
                                                <img src={SearchIcon} width={20} />
                                            </InputAdornment>
                                            ),
                                        }}
                                        placeholder="Search"
                                        onChange={(e)=> this.handleSearch(e)}
                                        style={{ width: '250' }}
                                    />
                                </div>
                                <RoundedButtonBase
                                    // onClick={this.openModal}
                                    id="addButton"
                                    variant="contained"
                                    disableElevation
                                    onClick={()=> this.setState({modal:'create'})}
                                    startIcon={<img src={iconAdd} width={30} style={{ marginLeft: '-5px' }} />}>
                                    ADD NEW
                                </RoundedButtonBase>
                            </div>
                        </HeadWrapper>
                        <TableContainer>
                            <Table style={{ width: '100%', background: '#fff', overflowX: 'hidden' }}>
                                <TableHead>
                                    <TableRow>
                                        <TH>S.No</TH>
                                        <TH>Username</TH>
                                        <TH>User Profile Photo</TH>
                                        <TH>Email</TH>
                                        <TH>Access</TH>
                                    </TableRow>
                                </TableHead>
                                {this.state.accessUsers?.map((data:any, i)=> {
                                return(
                                <TableBody>
                                    <TableRow>
                                        <TR>{i+1}</TR>
                                        <TR>{data.attributes.user_name}</TR>
                                        <TR>
                                            <div style={{ display: 'flex', justifyContent: 'center', }}>
                                                <Avatar src={data.attributes.profile_picture} />
                                            </div>
                                        </TR>
                                        <TR>{data.attributes.email}</TR>
                                        <TR>{data.attributes.ability_type == 'write' ? "Read, Write" : 'Read'}</TR>
                                        <TR><img width="20px" src={EditIcon} style={{cursor:'pointer'}} onClick={()=> {this.handleEdit(data?.id)}} /></TR>
                                        <TR><img src={deleteImg} width="25px" style={{cursor:'pointer'}} onClick={this.openDeletePopup(data?.id)}/></TR>
                                    </TableRow>
                                </TableBody>
                                )})}
                            </Table>
                        </TableContainer>
                        {
                            (this.state?.accessUsers?.length == 0 && !this.state?.loading) &&
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', fontSize: '25px', marginTop: '25px' }}>No Result Found</div>
                        }
                    </SurveyCardWrapper>
                </MiddleGrid>
            </OuterGrid>

        </MainBox>
        // Customizable Area End
    );
}
}

// Customizable Area Start
PermissionDashboardController.contextType = UserContext

const CreateHeader = styled('div')({
    fontSize: '17px',
    fontWeight: 600,
  })

  const ApplyLabel = styled('div')({
    fontSize: '13px',
    color: '#404040',
    fontFamily: 'HelveticaNeue'
})

const MainBox = styled(Box)({
    backgroundColor: "#edebeb",
    display: "flex",
    flexWrap: 'wrap',
    flexDirection: "column",
    paddingBottom: "20px",
    overflowX: "hidden",
})

const OuterGrid = styled(Grid)({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
})

const LeftGrid = styled(Grid)({
    maxWidth: '300px',
    '@media screen and (max-width: 985px)': {
        maxWidth: '200px',
    },
    '@media screen and (max-width: 768px)': {
        display: 'none',
        visibility: 'hidden',
    },
})

const MiddleGrid = styled(Grid)({
    width: 'calc(100vw - 300px)',
    maxWidth: 'unset',
    flexBasis: 'unset',
    justifyContent: 'center',
    '@media screen and (max-width: 768px)': {
        width: '100%'
    }
})

const SurveyCardWrapper = styled(Box)({
    width: '95%',
    height: 'auto',
    marginTop: '30px',
    marginRight: '20px',
    padding: '20px',
})

const Cards = styled('div')({
    display: 'flex',
    flexWrap: 'wrap',
    gap: '20px'
})

const HeadWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
})

const ArroWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'flex-end',
    color: 'gray',
    marginRight: '10px',
    fontWeight: 100
})

const LogoWrapper = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    '& img': {
        width: '60px',
    }
})

const SurveyCard = styled(Box)({
    width: '250px',
    height: '200px',
    padding: ' 20px 10px',
    marginBottom: '20px',
    boxShadow: '1px 2px 20px #CABFC1',
    borderRadius: '10px',
    background: '#fff'
})

const AssesmentHeading = styled(Typography)({
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 'bold',
    marginBottom: '15px',
    marginTop: '30px'
})

const Head = styled('div')({
    display: 'flex',
    alignItems: 'center',
    color: '#000',
    margin: '10px 0px 30px 12px',
    fontSize: '1rem',
    fontWeight: 600,
    '@media screen and (max-width: 768px)': {
        margin: '20px 0px 10px 12px',
    },
})

const TH = styled(TableCell)({
    color: "#000",
    fontWeight: 600,
    lineHeight: "1.5rem",
    fontSize: '0.85rem',
    padding: '16px 10px',
    textAlign: 'center',
    position: 'relative',
})

const TR = styled(TableCell)({
    color: "#000;",
    lineHeight: "1.5rem",
    fontSize: '0.85rem',
    padding: '16px 10px',
    textAlign: 'center',
    position: 'relative',
})

const CustomTextField = styled(TextField)({

'& fieldset': {
    border: '1px solid #c2c0c0',
    borderRadius: '12px',
    height: '45px',
},
})

const RoundedButtonBase = styled(Button)({
    backgroundColor: '#009c05',
    color: 'white',
    borderRadius: '20px',
    '&:hover': {
        backgroundColor: '#009c05',
    }
})

export const SelectedItem = ({ items, value }: { items: any, value: any }) => {

    return <>
    {console.log("items =", items)}
        <div id= "selectItem" style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
            {(items || []).map((selectedValue: any, i: any) => {
                return (<div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                >
                    <Typography component={'div'} style={{ flex: 3, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {selectedValue?.attributes.email}
                    </Typography>
                    <ListItemText style={{ flex: 2 }} user-id={selectedValue.id} disableTypography primary={
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Typography style={{ color: "#333232", fontWeight: "bold", fontSize: 12, width: '35ch', overflow: 'hidden', textOverflow: 'ellipsis' }}
                                user-id={selectedValue.id}>
                                {selectedValue.attributes.first_name} {selectedValue.attributes.last_name}
                            </Typography>
                        </div>
                    } />
                </div>)
            })}
        </div>
    </>
}

export const SelectItemUserWithoutCheckbox = ({ item, value }: { item: any, value?: any[] }) => {
    return <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}
    >{console.log("items => ", item)}
        <Typography component={'div'} style={{ flex: 3, overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {item?.attributes.email}
        </Typography>
        <ListItemText style={{ flex: 2 }} user-id={item?.id} disableTypography primary={
            <div style={{ display: "flex", alignItems: "center" }}>
                <Typography style={{ color: "#333232", fontWeight: "bold", fontSize: 12, width: '35ch', overflow: 'hidden', textOverflow: 'ellipsis' }}
                    user-id={item?.id}>
                    {item?.attributes.first_name} {item?.attributes.last_name}
                </Typography>
            </div>
        } />
    </div>
}

// Customizable Area End
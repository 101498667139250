import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { Context } from "react";
import UserContext, { User } from "../../../components/src/UserProvider";
import { esgPathRecords,sendAPIRequest } from "../../../components/src/utils";
import { toast } from 'react-toastify';
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CfCarbonCalculatorController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  static contextType?: Context<User> = UserContext;
  showEsgAPICallId:string = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage),
      
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
     if(responseJson) {
    if(apiRequestCallId === this.showEsgAPICallId){
      this.handleEsgCardsAuth(responseJson)
    }}}
    // Customizable Area End

  }
  
  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    let token = await getStorageData('authToken');
    const userStr = await getStorageData('user');
    const userData = JSON.parse(userStr);
    let licensedUser = userData?.role?.role_level < 5 || userData.user_type === "licensed_user"
    if(licensedUser){
      this.esgAccess(token)
    }}
  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  }

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  }

  createDataMenu(
    Name: string,
    File: string,
    img: string,
    type: string,
    cardNo: string,
  ) {
    return { Name, File, img, type,cardNo};
  }
  navigationTo = (route: string, props: Props) => {
    const navigateDataMessage = new Message(getName(MessageEnum.NavigationMessage));
    navigateDataMessage.addData(getName(MessageEnum.NavigationTargetMessage), route);
    navigateDataMessage.addData(getName(MessageEnum.NavigationPropsMessage), props);   
    this.send(navigateDataMessage);
  }
  esgAccess = async (authToken:string) => {
    this.showEsgAPICallId = sendAPIRequest(`${configJSON.showAllCards}`, {
      method: "GET",
      headers: {token: authToken},
    })
  }

  handleEsgCardsAuth(esgCardsData:{
    access: {
      data: {
        id: string;
        type: string;
        attributes: {
          id: number;
          created_at: string;
          user_name: string | null;
          company_name: string | null;
          updated_at: string;
          email: string;
          ability_card: string;
          ability_type: string;
          profile_picture: string | null;
          card_serial_number: number;
          card_banner: string;
        };
      }[];
    }}){
    let accessibility = esgPathRecords(esgCardsData)
    if(!accessibility){
      let warning = "Sorry, You do not have access to this page"
      let backLink = "ActivityFeed"
      toast.warn(warning)
      this.navigationTo(backLink,this.props)   
    }
  }
  // Customizable Area End
  
}

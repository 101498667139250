import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from 'react-toastify';
import * as yup from 'yup'
import { sendAPIRequest } from "../../../components/src/utils";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  token: string;
  // Customizable Area Start
  allActivity: any[];
  milestoneIds: any[];
  selectedId: any;
  selectedMilestomeName: string;
  selectedActivityId: string;
  deleteAccess: boolean;
  deleteActivityId: number;
  editedData: any,
  isEdit: boolean;
  modal: string;
  allPredecessor: any[];
  loading: boolean
  actionType: string;
  moderatorLists: any[];
  moderatorPagination?: any,
  allModratorOptions: any[],
  modratorsSearchCallId: string,
  moderatorPaginateCallId?: any,
  moderatorQuery: any,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SurveysController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  userRoleCallId= '';
  getAllActivityCallId= '';
  getMilestoneCallId= '';
  generateActivityIdCallId= '';
  createActivityCallId= '';
  deleteActivityCallId= '';
  editActivityCallId= '';
  getPredecessorCallId= '';
  editSubmitActivityCallId= '';
  projectModeratorsCallId: string = '';
  projectName: string = '';
  initModratorPagination: any = undefined;

  async componentDidMount() {
    super.componentDidMount();
    const authToken = await getStorageData("authToken")
    if (authToken == null) {
      this.props.navigation.navigate("Home");
    } else {
        this.getAllActivities();
        this.getProjectOwners();
        this.getPredecessorData()
        this.projectName = await this.getDataFromStorage('projectName')
    }

  }

  getProjectOwners = async() => {
    const id = this.props.navigation.getParam('projectId')
    const token = await getStorageData("authToken")
    this.projectModeratorsCallId = sendAPIRequest(`${configJSON.projectOwnerEndPoint}&project_id=${id}`, {
      method: "GET",
      headers: { token },
    })
  }

  getAllActivities = async() => { 
    const id = this.props.navigation.getParam('projectId')
    this.setState({loading: true})
    const token = await getStorageData("authToken")
    this.getAllActivityCallId = sendAPIRequest(`${configJSON.getAllActivities}?project_id=${id}`, {
      method: 'GET',
      headers: {token}
    })
    this.getMilestoneCallId = sendAPIRequest(`${configJSON.getMilestoneIds}?project_id=${id}`, {
      method: 'GET',
      headers: {token}
    })
  }

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      token: '',
      // Customizable Area Start
      modal: '',
      allActivity: [],
      milestoneIds: [],
      selectedId: '',
      selectedMilestomeName: '',
      selectedActivityId: '',
      deleteAccess: false,
      deleteActivityId: 0,
      editedData: {},
      isEdit: false,
      allPredecessor: [],
      loading: false,
      actionType: '',
      moderatorLists: [],
      allModratorOptions: [],
      modratorsSearchCallId: "",
      moderatorQuery: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && responseJson.errors) {
        this.parseApiErrorResponse(responseJson.errors);
        this.parseApiCatchErrorResponse(responseJson.errors);
      } else if(responseJson) {
        switch(apiRequestCallId) {
          case this.getAllActivityCallId:
            this.setState({allActivity: responseJson.data, loading: false});
            break;
          case this.getMilestoneCallId:
            this.setState({milestoneIds: responseJson.data });
            break;
          case this.generateActivityIdCallId:
            this.setState({selectedActivityId: responseJson.activity_id});
            break;
          case this.createActivityCallId:
            this.state.allActivity.push(responseJson.data);
            this.setState({loading: false, isEdit:false, modal:''});
            toast.success("Activity Created Successfully");
            break;
          case this.deleteActivityCallId:
            toast.success("Activity Deleted Successfully");
            let allActivity = this.state.allActivity.filter((x:any) => x.id != this.state.deleteActivityId);
            this.setState({deleteAccess: false, allActivity, modal:''});
            break;
          case this.editActivityCallId:
            this.setState({editedData: responseJson.data, modal:'create'});
            break;
          case this.getPredecessorCallId:
            this.setState({allPredecessor: responseJson.data});
            break;
          case this.editSubmitActivityCallId:
            toast.success("Activity Updated Successfully");
            const activityData = this.state.allActivity.map((act) =>
                act.id == responseJson.data.id ? { ...act, attributes: responseJson.data.attributes } : act
            );
            this.setState({loading:false , modal: '', allActivity:activityData});
            
            break;
          default:
            this.othercallId(apiRequestCallId, responseJson)
            break;
        }
        
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  othercallId = (apiRequestCallId:any, responseJson:any) =>{
    switch(apiRequestCallId) {
      case this.projectModeratorsCallId:
            const ownerOptions = responseJson?.project_moderators.data.map((d: any) => d.attributes)
            this.initModratorPagination = responseJson?.project_moderators?.meta?.pagination;
            this.setState({moderatorLists :ownerOptions, allModratorOptions: ownerOptions, moderatorPagination: responseJson?.project_moderators?.meta?.pagination })
            break;
      case this.state.moderatorPaginateCallId:
            const owneroptions = responseJson?.project_moderators?.data.map((d: any) => d.attributes)
            const ownersOptions = this.filterUnique(this.state.moderatorLists.concat(owneroptions))
            this.setState({ moderatorLists: ownersOptions, moderatorPagination: responseJson?.project_moderators?.meta?.pagination })
            break;
      case this.state.modratorsSearchCallId:
              if (!Array.isArray(responseJson.project_moderators?.data))
                this.setState({
                  modratorsSearchCallId: '',
                  moderatorLists: [],
                  moderatorPagination: undefined
                })
              else {
                const ownersOptions = responseJson?.project_moderators?.data.map((d: any) => d.attributes)
                this.setState({
                  modratorsSearchCallId: '',
                  moderatorLists: ownersOptions,
                  moderatorPagination: responseJson?.project_moderators?.meta?.pagination,
                })
              }
              break;
    }
  }

  validationSchema = yup.object().shape({
      milestoneId: yup.string().required("Milestone Id is required"),
      milestoneName: yup.string().required("Milestone Name is required"),
      activityId: yup.string().required("Activity Id is required"),
      activityName: yup.string().max(100).required("Activity Name is required"),
      description: yup.string().max(500).required("Description is required"),
      is_dependent: yup.string().required("Is Dependent is required"),
      predecessor: yup.string().when('is_dependent', {is: 'Yes', then: yup.string().required("Predecessor is required")}),
      inputs: yup.string().max(100).required("Inputs is required"),
      assigned_date: yup.string().required("Assigned Date is required"),
      start_date: yup.string().required("Start Date is required"),
      actual_Start_date: yup.string().required("Actual Start Date is required"),
      end_date: yup.string().required("End Date is required"),
      actual_end_date: yup.string().required("Actual End Date is required"),
      criticality: yup.string().required("Criticality is required"),
      status: yup.string().required("Status is required"),
      comments: yup.string().required("Comments is required"),
      owner: yup.number().required("Owner is a required field")
  })

  filterUnique = (items: any[]) => {
    console.log(items, "##########FU")
    const hashmap = new Set()
    return items.filter((i: any) => {
      if (hashmap.has(i.id)) return false
      hashmap.add(i.id)
      return hashmap
    })
  }

  paginateOwner = () => {
    if (this.state.modratorsSearchCallId !== '') return
    const { next_page } = this.state.moderatorPagination
    this.setState({ moderatorPaginateCallId: this.handleModeratorSearch(this.state.moderatorQuery, next_page) })
  }
  handleModeratorSearch = async(query: string, page?: number) => {
    console.log({ page })
    const id = this.props.navigation.getParam('projectId')
    const token = await getStorageData("authToken")
    const params = new URLSearchParams()
    query && params.append('moderators', query)
    page && params.append('page', String(page))
    params.append('project_id', id)
    const url = configJSON.projectOwnerEndPoint + "&" + params.toString()
    return sendAPIRequest(url, { method: 'GET', headers: { token } })
  }
  handleModeratorSearchQuery = async(query: string) => {
    console.log({ query }, "query::>>>")
    let msCallID = await this.handleModeratorSearch(query)
    if (query)
      this.setState({
        modratorsSearchCallId: msCallID,
        moderatorQuery: query,
        moderatorPaginateCallId: ''
      })
    else
      this.setState({
        moderatorLists: this.state.allModratorOptions,
        moderatorPagination: this.initModratorPagination,
        moderatorQuery: '',
        modratorsSearchCallId: '',
      })
  }
  handleEdit = (id:any, type:string) => async() =>{
    const token = await getStorageData("authToken")
    this.setState({isEdit: true})
    this.setState({actionType: type, deleteActivityId: id})
    this.editActivityCallId = sendAPIRequest(configJSON.editActivity.replace(':id', id), {
      method: 'GET',
      headers: {token}
    })
  }

  onBack = () => {
    this.props.navigation.goBack();
  }

  closeDeleteModal = () => {
    this.setState({deleteActivityId: 0, deleteAccess: false, editedData: null})
  }

  openModal = async() => {
    this.setState({ modal: 'create', actionType:'ADD'})
  }
  getPredecessorData = async() =>{
    const id = this.props.navigation.getParam('projectId')
    const token = await getStorageData("authToken")
    this.getPredecessorCallId = sendAPIRequest(`${configJSON.predecessorValues}?project_id=${id}`, {
      method: 'GET',
      headers: {token}
    })
  }
  closeModal = () => {
    this.setState({modal: '', isEdit:false})
  }

  openDeletePopup = (data:any) => () => {
    this.setState({deleteAccess: true, deleteActivityId: data})
  }

  deleteActivity = (id:number) => async() => {
    const token = await getStorageData("authToken")
    this.deleteActivityCallId = sendAPIRequest(configJSON.deleteActivity.replace(':id', id), {
      method: 'DELETE',
      headers: {token}
    })
  }

  selectMileStone = async(event: any) => {
    this.setState({
      selectedId: event.target.value.id,
      selectedMilestomeName: event.target.value.milestone_name,
    })
    const token = await getStorageData("authToken")
    this.setState({ modal: 'create' })
    this.generateActivityIdCallId = sendAPIRequest(`${configJSON.generateActivityId}?milestone_unique_id=${event.target.value.milestone_id}`, {
      method: 'GET',
      headers: {token}
    })
  }

  handleSubmit = async(data:any, handleReset:any) => {
    this.setState({loading: true})
    const token = await getStorageData("authToken")
    const body = new FormData();
    body.append('data[owner]', data.owner)
    body.append('data[milestone_name]', data.milestoneId)
    body.append('data[project_milestone_id]', data.milestoneId)
    body.append('data[activity_id]', data.activityId)
    body.append('data[activity_name]', data.activityName)
    body.append('data[description]', data.description)
    body.append('data[is_dependent]', data.is_dependent)
    body.append('data[predecessor][]', data.predecessor)
    body.append('data[inputs]', data.inputs)
    body.append('data[assigned_date]', data.assigned_date)
    body.append('data[start_date]', data.start_date)
    body.append('data[actual_start_date]', data.actual_Start_date)
    body.append('data[end_date]', data.end_date)
    body.append('data[actual_end_date]', data.actual_end_date)
    body.append('data[criticality]', data.criticality)
    body.append('data[status]', data.status)
    body.append('data[activity_comments]', data.comments)
    body.append('data[milestone_unique_id]', data.milestoneId)

    if(this.state?.isEdit) {
      this.editSubmitActivityCallId = sendAPIRequest(configJSON.editEndPoint.replace(':id', this.state.editedData.id) , {
        method: 'PUT',
        headers: {token},
        body
      })
    }
    else {
      this.createActivityCallId = sendAPIRequest(configJSON.createActivity, {
        method:'POST',
        headers: {token},
        body
      })
    }
  }

  extractAttributes(data:any) {
    if (!data) return {};
    const { attributes } = data;
    return {
      milestoneId: attributes?.project_milestone_id,
      milestoneName: attributes?.milestone_name,
      activityId: attributes?.activity_id,
      activityName: attributes?.activity_name,
      description: attributes?.description,
      is_dependent: attributes?.is_dependent,
      predecessor: attributes?.predecessor?.length > 0 && attributes.predecessor[0][0],
      inputs: attributes?.inputs,
      assigned_date: attributes?.assigned_date,
      start_date: attributes?.start_date,
      actual_Start_date: attributes?.actual_start_date,
      end_date: attributes?.end_date,
      actual_end_date: attributes?.actual_end_date,
      criticality: attributes?.criticality,
      status: attributes?.status,
      comments: attributes?.activity_comments,
      owner: attributes?.activity_owner?.data?.attributes?.id
    };
  }

  getDataFromStorage = async(key: string) => {
    let data = await getStorageData(key);
    return data
  }


  // Customizable Area End
  
}

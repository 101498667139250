import React from "react";

// Customizable Area Start
import { 
  Button, 
  Divider, 
  InputAdornment, 
  MenuItem, 
  Paper, 
  Tabs, 
  Tab, 
  Typography, 
  CircularProgress } from '@material-ui/core'
import { styled } from '@material-ui/styles'
import { Formik, useField, useFormikContext } from 'formik'
import OverlayLoader from '../../../components/src/Loader.web'

import { iconLocation, iconAdd, iconUpload, logoIkea, logoCowi, logoWashington } from '../../JobListing2/src/assets'
import FilterItems from '../../filteritems/src/Filteritems.web'
import PreferenceCatSubCat from '../../categoriessubcategories/src/PreferenceCatSubCat.web'
import DashboardLayout from '../../../components/src/DashboardLayout.web'
import RoundedButton from '../../../components/src/RoundedButton.web'
import FormModal, { InputRow, MInput, MSelect, MFileDrop, MCountedTextArea, MCheckboxes } from '../../../components/src/FormModal.web'
import UserContext from "../../../components/src/UserProvider";
import MarketplaceTopbar from '../../../components/src/MarketplaceTopbar.web'
import FileDrop from '../../../components/src/FileDrop.web'
import SeeMore from '../../../components/src/SeeMore.web'
import Flex from '../../../components/src/Flex.web'
import { LoadingSpinner } from '../../../components/src/LoadingSpinner.web'
import LikeAPost from '../../LikeAPost/src/LikeAPost.web'
import Maps from '../../maps/src/Maps.web'
// import { CreateFormValues } from './types'

interface ApplyFormValues {
  name: string;
  email: string;
  phone_number: string;
  location: string|null;
  comment: string;
  file: File | string;
}
// Customizable Area End

import CreateServiceController, {
  Props,
  configJSON
} from "./CreateServiceController";

export default class CreateService extends CreateServiceController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }


  // Customizable Area Start
  

  CreateServiceModal() {
    const user = this.context
    let service_id_1 = `${user.first_name.slice(0,3)}${user.last_name.slice(0, 2)}`.toUpperCase()
    let service_id_2 = this.state.randomServiceId || '-----'
    if(this.state.service?.service_unique_id) {
      service_id_1 = this.state.service?.service_unique_id.split('-')[0]
      service_id_2 = this.state.service?.service_unique_id.split('-')[1]
    }
    let initialValues: any = {
      service_id_1,
      service_id_2,
      preference_ids: this.state.service?.preference_ids || [],
      category_ids: this.state.service?.category_ids || [],
      sub_category_ids: this.state.service?.subcategory_ids || [],
      service_name: this.state.service?.service_name || '',
      location: this.state.service?.location || '',
      service_experience: this.state.service?.sub_category_ids || '0', 
      coin_discount: this.state.service?.coin_discount || '0',
      equivalent_coins: this.state.service?.equivalent_coins || '0',
      skills: this.state.service?.skills || '',
      price_min_usd: this.state.service?.price_min_usd || '',
      price_max_usd: this.state.service?.price_max_usd || '',
      price_min_inr: this.state.service?.price_min_inr || '',
      price_max_inr: this.state.service?.price_max_inr || '',
      description: this.state.service?.description || '',
      attachments: this.state.service?.images || '',
    }
    
    const isEditPage = !!this.props.navigation.getParam('id')
    
    return <Formik enableReinitialize initialValues={initialValues} validationSchema={this.createFormSchema} onSubmit={this.handleCreateServiceSubmit}>
      {({ values, errors }: any) => (
      <FormModal headerTitle={isEditPage ? <b>EDIT SERVICE</b> : <b>CREATE SERVICE</b>} submitLabel={isEditPage?"UPDATE":"create"} onClose={this.hideModal}>
        <FormikLogger />
        <InputRow label="Service ID" input={
                 <IDWrapper>
                    <Flex2>
                      <MInput name="service_id_1" disabled placeholder="Service ID" />
                    </Flex2>
                    <Flex2>
                      <MInput name="service_id_2" disabled placeholder="Service ID" />
                    </Flex2>
                  </IDWrapper>
              } />
        <InputRow label="Service Name*" input={
              <MInput name="service_name" placeholder="Enter service name" />
        } />
        
        {(!isEditPage || this.state.service) && <PreferenceCatSubCat initialValues={this.state.service} {...this.props} />}
        <InputRow label="Service Experience*" input={
              <MInput name="service_experience" placeholder="0" />
        } />
        {/* @ts-ignore */}
        <InputRow label="Location*" input={<Maps input {...this.props} />} />
        <InputRow label="Coin Discount (%)*" input={
              <MInput name="coin_discount" placeholder="0" />
        } />
        <InputRow label="Equivalent Coins*" input={
              <MInput name="equivalent_coins" placeholder="0" disabled={values.coin_discount<=0}/>
        } />
        <InputRow label="Skills*" input={
              <MInput name="skills" placeholder="Enter experience" />
        } />
        <InputRow rowClass="price-label" label={
          <FlexWebWrap><Flex1>Minimum Price*</Flex1><Flex1>Maximum Price*</Flex1></FlexWebWrap>
        } input={
            <FlexCol style={{ gap: '5px' }}>
              <Flex col gap="5px">
              <FlexMobileWrap1>
                <FlexRow><Flex1>Minimum Price*</Flex1></FlexRow>
              </FlexMobileWrap1>
                <MinimumPriceWrapper>
                  <Flex f={1} col>
                    <MInput name="price_min_usd"  {...dollarFieldProps} />
                  </Flex>
                  <Flex f={1} col>
                    <MInput name="price_max_usd"  {...dollarFieldProps} />
                  </Flex>
                </MinimumPriceWrapper>
                <FlexMobileWrap>
                <FlexRow><Flex1>Maximum Price*</Flex1></FlexRow>
                </FlexMobileWrap>
                <MaximumPriceWrapper gap="5px">
                  <Flex f={1} col>
                    <MInput name="price_min_inr" {...rupeeFieldProps} />
                  </Flex>
                  <Flex f={1} col>
                    <MInput name="price_max_inr" {...rupeeFieldProps} />
                  </Flex>
                </MaximumPriceWrapper>
              </Flex>
            </FlexCol>
        } />
        <InputRow label="Description*" input={
              <MCountedTextArea name="description" placeholder="Service description" />
        } />
        <InputRow label="Upload Image (optional)" input={
        <>
          <MFileDrop inputProps={{accept: "image/*"}} multiple name="attachments" />
        </>
        } />
        <OverlayLoader loading={!!this.state.submittingForm} />
      </FormModal>
      )}
    </Formik>
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const tabLabels = [
      configJSON.labelMyServices, 
      configJSON.labelMyEvents, 
      configJSON.labelMyProducts, 
      configJSON.labelMyServices, 
      configJSON.labelMyCourses,
    ]
    // Customizable Area End
    return (
      // Customizable Area Start
      <ModealWrapper>
        {this.CreateServiceModal()}
      </ModealWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

CreateServiceController.contextType = UserContext

function FormikLogger(){
  const formikContext = useFormikContext()
  console.log({formikContext}) 
  return<></>
}

const UploadButton = (props: any) => {
  const [field, meta, helpers] = useField(props)
  const [filename, setFilename] = React.useState(props.filename||"")
  React.useEffect(() => {
    setFilename(props.filename)
  }, [props.filename])
  const [validationError, setValidationError] = React.useState("")
  const inputRef = React.useRef<HTMLInputElement >(null)
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    helpers.setTouched(true)
    const file = e.currentTarget.files && Array.from(e.currentTarget.files)
    if(!file) return
    const totalSize = file.map((f: any)=>f.size).reduce((a: number,b: number)=>a+b)
  console.log("totalFileSize", {totalSize})
    if(props.maxSize&&totalSize>props.maxSize) {
      setValidationError(props.maxSizeError || `Cannot upload document more than 20Mb size`)
      return 
    }
    helpers.setValue(file)
    setFilename(file.map((f:any) => f.name).join(', '))
  }

  const onClick = () => inputRef?.current && inputRef?.current?.click()

  return <label>
            <RoundedButton onClick={onClick} startIcon={<img width="25px" src={iconUpload} />}>{props.label}</RoundedButton> 
            <input multiple={props.multiple} type="file" accept={props.filetype || "*"} onChange={onChange} ref={inputRef} name={props.name} style={{ display: 'none' }} />
            <div>{filename}</div>

            {meta.touched && (meta.error || validationError) && <Typography color="error">{meta.error || validationError}</Typography>}
         </label>
}

const dollarFieldProps = {
  placeholder: "Enter",
  InputProps: {
    startAdornment: <InputAdornment position="start">$</InputAdornment>
  }
}

const rupeeFieldProps = {
  placeholder: "Enter",
  InputProps: {
    startAdornment: <InputAdornment position="start">₹</InputAdornment>
  }
}

const ApplyHeader = styled('div')({
  fontSize: '24px',
  fontWeight: 500,
  fontFamily: 'HelveticaNeue'
})

const IDWrapper = styled('div')({
  display: 'flex', 
  gap: '5px',
  '@media screen and (max-width: 480px)': {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  }
})

const FlexRow = styled('div')({
  display: 'flex',
  flexDirection: 'row',
})
const Flex1 = styled('div')({
  flex: 1,
  fontWeight: 600,
  color: '#404040'
})

const Flex2 = styled('div')({
  flex: 1,
  '@media screen and (max-width: 480px)': {
    flex: 'none',
    width: '100%',
  }
})

const FlexCol = styled('div')({
  display: 'flex',
  flexDirection: 'column'
})

const MinimumPriceWrapper = styled(Flex)({
  gap: '5px',
  '@media screen and (max-width: 480px)': {
    display: 'flex',
    flexDirection: 'column',
  }
})

const MaximumPriceWrapper = styled(Flex)({
  gap: '5px',
  '@media screen and (max-width: 480px)': {
    display: 'flex',
    flexDirection: 'column',
  }
})

const FlexWebWrap = styled(FlexRow)({
  display: 'flex',
  width:"100%",
  '@media screen and (max-width: 480px)': {
    display: 'none',
  },
})

const FlexMobileWrap = styled('div')({
  display: 'none',
  '@media screen and (max-width: 480px)': {
    fontSize: '13px',
    display: 'flex',
    marginTop: '25px', 
    marginBottom: '5px',
  }
})

const FlexMobileWrap1 = styled('div')({
  display: 'none',
  '@media screen and (max-width: 480px)': {
    fontSize: '13px',
    display: 'flex',
    // marginTop: '25px', 
    marginBottom: '5px',
  }
})

const RoundedButtonBase = styled(Button)({
  backgroundColor: '#009c05',
  color: 'white',
  '&:hover': {
    backgroundColor: '#009c05',
  }
})

const FlexSpaceBetween = styled('div')({
  display: 'flex',
  justifyContent: 'space-between'
})


const recommendedServices = [{
  title: 'Lecturer Sustainable Finance',
  company: 'University of Washington',
  location: 'Toronto, Canada',
  created_at: '2 hr ago',
  logo: logoWashington,
}, {
  title: 'Sustainability Expert',
  company: 'IKEA',
  location: 'Toronto, Canada',
  created_at: '2 hr ago',
  logo: logoIkea,
}, {
  title: 'Senior Consultant and Project Manager',
  company: 'IKEA',
  location: 'Toronto, Canada',
  created_at: '2 hr ago',
  logo: logoCowi,
},]

interface RJCardProps {
  title: string;
  company: string;
  location: string;
  created_at: string;
  logo: any;
}

const RJCard = (props: RJCardProps) => {
  return <RJCardWrapper>
    <div>
      <img src={props.logo} />
    </div>
    <div>
      <RJCardTitle variant="h6">{props.title}</RJCardTitle>
      <RJContent>
        <span>{props.company}</span>
        <Divider orientation="vertical" />
        <span><img src={iconLocation} width="20" style={{marginBottom: '-4px', marginRight: '5px'}} />{props.location}</span>
        <Divider orientation="vertical" />
        <span>{props.created_at}</span>
      </RJContent>
    </div>
  </RJCardWrapper>
}

const RJCardTitle = styled(Typography)({

})

const RJContent = styled('div')({
  height: '20px',
  display: 'flex',
  gap: '15px',
  color: '#444'
})

const RJCardWrapper = styled('div')({
  padding: '20px',
  display: 'flex',
  alignItems: 'center',
  gap: '25px',
})

const RecommendedServices = () => {
  return <RJWrapper>
    <RJHeader><b>Services</b> you may be interested in</RJHeader>
    <Divider />
    {recommendedServices.map((rj, idx) => <React.Fragment key={idx}>
        <RJCard {...rj} />
        <Divider />
      </React.Fragment>)}
    <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
      <RoundedButton>SEE MORE</RoundedButton>
    </div>
  </RJWrapper>
}

const RJWrapper = styled('div')({
  borderRadius: '2px',
  margin: '20px 0px',
  background: 'white',
  padding: '10px 0px',
})

const RJHeader = styled('div')({
  borderRadius: '15px',
  border: '3px solid #eee',
  padding: '20px 20px',
  margin: '20px 10px',
  color: '#444'
}) 

const ModealWrapper = styled('div')({
  "& .price-label":{
    "& p":{
      "& div":{
        width: "100%",
      }
    }
  }
})

// Customizable Area End

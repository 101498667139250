import React from 'react';
// Customizable Area Start
import { Card, Divider, CardHeader, CardContent, CardActions, Typography, Popper, ClickAwayListener } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import ShareIcon from '@material-ui/icons/Share';
import { styled } from '@material-ui/styles';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import PublicProfileController, {
  Props
} from './PublicProfileController.web';
import CloseIcon from "@material-ui/icons/Close";
import Loader from "../../../components/src/Loader";
import {
  PostsIcon,
  CoinsIcon,
  FollowersIcon,
  ViewsIcon,
  UserProfile
} from './assets';
import SeeMore from '../../../components/src/SeeMore.web';
// @ts-ignore
import classes from './PublicProfileCard.module.css';
import Flex from '../../../components/src/Flex.web';
import { Link } from 'react-router-dom'
// Customizable Area End
export default class PublicProfileCard extends PublicProfileController {

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  showCompany(userProfile:any){
    let corporateId = userProfile && userProfile.id ? userProfile.id : null;
    let companyName = userProfile && userProfile.company_name ? userProfile.company_name : '';
    return(
      <>
         {userProfile.corporate_profile_card_present ? 
            <Link  style={{ textDecorationLine: "underline" }} to={`/CorporateProfile/${corporateId || ''}`}>{companyName}</Link>
            : `${companyName}`
        }
      </>
    )
  }

  followersPopover() {
    return (
      <ClickAwayListener data-test-id="followers-popup-clickAway" onClickAway={() => this.handleCloseConnection(true)}>
        <PopupWrapper>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '5px' }}>
            <div><Typography variant="h6" style={{ padding: '15px' }}>Followers</Typography></div>
            <div> <CloseIcon style={{ cursor: 'pointer' }} id="closeId" data-test-id="close-followers-popup" onClick={() => { this.handleCloseConnection(true) }} /> </div>
          </div>
          {this.state.allFollowers && this.state.allFollowers.length ? this.state.allFollowers.map((data: any) => {
            const userID = data.attributes?.current_user?.id
            return (
              <User data-test-id="follower-popup-item" onClick={() => { this.handleCloseConnection(true); this.props.navigation.history.push(`/user/${userID}`) }}>
                <Avatar src={data.attributes?.current_user_profile_pic} />
                <Typography>{data.attributes?.current_user?.first_name + " " + data.attributes?.current_user?.last_name}</Typography>
              </User>
            )
          }) : <NoData data-test-id="no-followers-found">No Data</NoData>
          }
        </PopupWrapper>
      </ClickAwayListener>
    )
  }

  viewersPopover() {
    return (
      <ClickAwayListener data-test-id="viewer-popup-clickAway" onClickAway={() => this.handleCloseConnection(false)}>
        <PopupWrapper>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '5px' }}>
            <div><Typography variant="h6" style={{ padding: '15px' }}>Viewers</Typography></div>
            <div> <CloseIcon style={{ cursor: 'pointer' }} id="closeId" data-test-id="close-viewers-popup" onClick={() => { this.handleCloseConnection(false) }} /> </div>
          </div>
          {this.state.allViewer?.length ? this.state.allViewer.map((data: any) => {
            return (
              <User data-test-id="Viewer-popup" onClick={() => { this.handleCloseConnection(false); this.props.navigation.history.push(`/user/${data?.attributes?.account_viewed_by?.data?.id}`) }}>
                <Avatar src={data?.attributes?.account_viewed_by?.data?.attributes?.profile_pic} />
                <Typography>{data?.attributes?.account_viewed_by?.data?.attributes?.first_name + " " + data?.attributes?.account_viewed_by?.data?.attributes?.last_name}</Typography>
              </User>

            )
          }
          ) : <NoData data-test-id="no-viewer-found">No Data</NoData>
          }
        </PopupWrapper>
      </ClickAwayListener>
    )
  }
  
  // Customizable Area End

  render() {
    // Customizable Area Start
    let { userProfile } = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      <RoundedCard variant="outlined">
        <Loader loading={this.state.loading} />
        <StyledHeader
          avatar={
            <Avatar style={{ width: 75.26, height: 70.87 }} src={userProfile?.profile_pic || UserProfile} aria-label="profile-pic">
            </Avatar>
          }
          action={
            <CardActions disableSpacing>
              {!this.state?.isCurrentUser && <FollowButton onClick={() => { this.followUnFollowUser() }} aria-label="settings">
                {userProfile?.is_follow ? 'Unfollow' : 'Follow'}
              </FollowButton>}
              <ShareButton aria-label="settings" onClick={() => { this.handleShareProfile(userProfile) }}>
                <ShareIcon />
              </ShareButton>
            </CardActions>
          }
          title={<Typography data-test-id="user-name-title" className={`${classes.userName}`}>{userProfile?.user_name}</Typography>}
          subheader={
            <HeaderGrid container alignItems="center">
              <span style={{ textTransform: 'capitalize', fontSize: '1rem' }}>{userProfile?.location}</span>
              <Divider orientation="vertical" flexItem />
              <span style={{ fontSize: '1rem' }}>{userProfile?.total_post || 0} post</span>
            </HeaderGrid>
          }
        />
        <CustomCardContent>
          <StyledGrid item xs={12}>
            <Grid container spacing={1}>
              <Grid item>
                <TransparentChip
                  data-test-id="followers-chip"
                  className='followers-chip'
                  avatar={<Avatar className={`ml0 ${classes.chipAvatar}`} src={FollowersIcon}></Avatar>}
                  label={<Typography className={`${classes.txtColor} ${classes.chipText}`}><span className={`bold ${classes.mr3} ${classes.txtColor} `}>{(this.state.followers || 0)}</span>Followers</Typography>}
                  onClick={(event)=>this.openFollowerList(event)} />
                <StyledPopper
                  open={Boolean(this.state.anchorElForPopupFollower)}
                  anchorEl={this.state.anchorElForPopupFollower}
                  style={{ marginTop: '10px' }}
                >
                  {this.followersPopover()}
                </StyledPopper>
              </Grid>
              <Grid item>
                <TransparentChip
                  avatar={<Avatar className={`ml0 ${classes.chipAvatar}`} src={CoinsIcon}></Avatar>}
                  label={<Typography className={`${classes.txtColor} ${classes.chipText}`}><span className={`bold ${classes.mr3} ${classes.txtColor} `}>{this.state.userProfile.no_of_coin}</span>Coins</Typography>}
                  onClick={this.handleClick} />
              </Grid>
              <Grid item>
                <TransparentChip
                  data-test-id="viewers-chip"
                  className='viewers-chip'
                  avatar={<Avatar className={`ml0 ${classes.chipAvatar}`} src={ViewsIcon}></Avatar>}
                  label={<Typography className={`${classes.txtColor} ${classes.chipText}`}><span className={`bold ${classes.mr3} ${classes.txtColor} `}>{userProfile?.total_views || 0}</span>Views</Typography>}
                  onClick={(event) => this.openViewsMenu(event)} />
                <StyledPopper
                  open={Boolean(this.state.anchorElForPopupNested)}
                  anchorEl={this.state.anchorElForPopupNested}
                  style={{ marginTop: '10px' }}
                >
                  {this.viewersPopover()}
                </StyledPopper>
              </Grid>
              <Grid item>
                <TransparentChip
                  avatar={<Avatar className={`ml0 ${classes.chipAvatar}`} src={PostsIcon}></Avatar>}
                  label={<Typography className={`${classes.txtColor} ${classes.chipText}`}><span className={`bold ${classes.mr3} ${classes.txtColor} `}>{userProfile?.total_post || 0}</span>Posts</Typography>}
                  onClick={this.goToPosts} />
              </Grid>
            </Grid>
          </StyledGrid>
          <StyledGridMobile>
            {/* <Grid container spacing={1}> */}
              <Part>
                <TransparentChip
                  avatar={<Avatar className={`ml0 ${classes.chipAvatar}`} src={FollowersIcon}></Avatar>}
                  label={<Typography className={`${classes.txtColor} ${classes.chipText}`}><span className={`bold ${classes.mr3} ${classes.txtColor} `}>{(this.state.followers || 0)}</span>Followers</Typography>}
                  onClick={this.handleClick} />
                <TransparentChip
                  avatar={<Avatar className={`ml0 ${classes.chipAvatar}`} src={CoinsIcon}></Avatar>}
                  label={<Typography className={`${classes.txtColor} ${classes.chipText}`}><span className={`bold ${classes.mr3} ${classes.txtColor} `}>{0}</span>Coins</Typography>}
                  onClick={this.handleClick} />
              </Part>
              <Part>
                <TransparentChip
                  avatar={<Avatar className={`ml0 ${classes.chipAvatar}`} src={ViewsIcon}></Avatar>}
                  label={<Typography className={`${classes.txtColor} ${classes.chipText}`}><span className={`bold ${classes.mr3} ${classes.txtColor} `}>{userProfile?.total_views || 0}</span>Views</Typography>}
                  onClick={this.handleClick} />
                <TransparentChip
                  avatar={<Avatar className={`ml0 ${classes.chipAvatar}`} src={PostsIcon}></Avatar>}
                  label={<Typography className={`${classes.txtColor} ${classes.chipText}`}><span className={`bold ${classes.mr3} ${classes.txtColor} `}>{userProfile?.total_post || 0}</span>Posts</Typography>}
                  onClick={this.handleClick} />
              </Part>
            {/* </Grid> */}
          </StyledGridMobile>
          {/* <Grid style={{ flexWrap: 'wrap', marginBottom: '13px' }} container spacing={3}>
            <Grid item xs={4} sm={4} md={6}>
              <Typography><span className='bold '>Email</span>: <span >{userProfile?.email || ''}</span></Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={6}>
              <Typography ><span className='bold '>Company</span>: <span >{userProfile?.company_name || ''}</span></Typography>
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <Typography><span className='bold '>Preference</span>: {(userProfile?.preferences || []).map((preference: string) => preference?.charAt(0).toUpperCase()).join(',')}</Typography>
            </Grid>
          </Grid> */}
          <InfoWrapper gap={20}>
            {/* <Flex gap={10} style={{textAlign:'initial'}}>
              <Typography><span className='bold '>Email</span>: <span>{userProfile?.email || ''}</span></Typography>
            </Flex> */}
            <Flex gap={10} style={{textAlign:'initial'}}>
              <Typography ><span className='bold '>Company</span>: <span >
                {this.showCompany(userProfile)}
                </span></Typography>
            </Flex>
            <Flex gap={10} style={{textAlign:'initial'}}>
              <Typography><span className='bold '>Preference</span>: {(userProfile?.preferences || []).map((preference: string) => preference?.charAt(0).toUpperCase()).join(',')}</Typography>
            </Flex>
          </InfoWrapper>
          <Typography variant="body2" color="textSecondary" component="p" style={{marginTop: '15px'}}>
            {userProfile?.about &&
              (<SeeMore limit={180} text={userProfile?.about} />)}
          </Typography>
        </CustomCardContent>
      </RoundedCard>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const RoundedCard = styled(Card)({
  borderRadius: '15px',
  padding: '10px 20px',
  margin: '20px 0px'
});


const PopupWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
})

const User = styled('div')({
  padding: '5 10 5 20',
  display: 'flex',
  flexDirection:'row',
  alignItems:'center',
  width:'300px',
  cursor:'pointer',
  '&:hover': {   
      background: '#f4f0f0',
  },
  '& > div': {
      borderRadius:'25px'
  },
  '& img': {
      width:'45px'
  },
  '& .MuiTypography-root': {
      fontSize:'18px',
      fontWeight:'bold',
      marginLeft:'30px',
  }
})

const StyledHeader = styled(CardHeader)({
  padding: '10px',
  '& .MuiCardHeader-action':{
    alignSelf:'center',
},
  '@media screen and (max-width:550px)':{
    flexDirection:'column',
    '& .MuiCardHeader-action':{
      alignSelf:'center',
  }
  },
  '@media screen and (min-width:768px) and (max-width:1200px)':{
    flexDirection:'column',
    '& .MuiCardHeader-action':{
      alignSelf:'center',
  }
  }
});

const FollowButton = styled(IconButton)({
  borderRadius: '20px',
  background: '#049C00',
  color: '#FAFAFA',
  fontSize: '16px',
  padding: '12px 35px 12px 35px',
  marginRight: '10px',
  fontWeight: 600,
  '&:hover': {
    background: '#999',
    opacity: 0.9
  },
  '@media screen and (max-width:480px)': {
    marginTop: '15px'
   }
});

const InfoWrapper = styled(Flex)({
  flexWrap:'wrap',
  alignItems: 'center',
  justifyContent: 'start',
  '@media screen and (max-width: 480px)': {
    justifyContent: 'start',
  }
})

const ShareButton = styled(IconButton)({
  background: '#EBEBEB',
  '@media screen and (max-width:480px)': {
    marginTop: '20px'
   }
});

const TransparentChip = styled(Chip)({
  background: 'transparent',
  fontSize: '14px',
  border: '1px solid #A4A4A4',
  padding: '15px',
  paddingRight: '0px'
});

const CustomCardContent = styled(CardContent)({
  padding: '0 16px 16px 16px'
});

const StyledGrid = styled(Grid)({
  marginBottom: '25px',
  marginLeft: '85px',
  '@media screen and (max-width:599px)': {
    display: 'none',
    marginLeft: '65px',
   },
});

const StyledPopper = styled(Popper)({
  maxHeight: '80vh',
  overflowY: 'auto',
  overflowX: 'hidden',
  marginLeft:'20px',
  backgroundColor: "white",
  paddingBottom:'10px',
  boxShadow: 'rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px'
});


const HeaderGrid = styled(Grid)({
   gap: '10px',
   '@media screen and (max-width:599px)': {
    display: 'flex',
    flexDirection: 'column',
    gap: '0px',
   },
})

const StyledGridMobile = styled('div')({
  display: 'none',
  marginBottom: '25px',
  marginLeft: '15px',
  gap: '10px',
  justifyContent: 'center',
  '@media screen and (max-width:599px)': {
    display: 'flex',
    flexWrap: 'wrap',
    // marginLeft: '65px',
   },
});

const Part = styled('div')({
  display: 'flex',
  // marginBottom: '10px',
  gap: '10px'
})

const NoData = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  width: '300px',
  padding:'10px',
  cursor: 'pointer',
  '&:hover': {   
      background: '#f4f0f0',
  },
});


// Customizable Area End
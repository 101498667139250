Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CfProductListing11";
exports.labelBodyText = "CfProductListing11 Body";
exports.productCreateAPIEndpoint = "/bx_block_cfproductlisting11/products"
exports.productListAPIEndpoint = "/bx_block_cfproductlisting11/products"
exports.productFilterAPIEndpoint = "/bx_block_cfproductlisting11/products/filter"
exports.productRateAPIEndpoint = "/bx_block_cfproductlisting11/ratings"
exports.categoryListAPIEndpoint = "/bx_block_categories/categories/category"
exports.favAPIEndpoint = "bx_block_favourites/favourites"
exports.unfavAPIEndpoint = "bx_block_favourites/favourites/:id"
exports.generateProductId = "bx_block_cfproductlisting11/products/generate_unique_id"
exports.getProductByIdEndpoint = "/bx_block_cfproductlisting11/products/:pid"
exports.getAllCommentEndpoint = "/bx_block_cfproductlisting11/comments";
exports.productCommentEndpoint = "/bx_block_cfproductlisting11/comments";
exports.productReplyCommentEndpoint = "/bx_block_cfproductlisting11/comments/create_reply";
exports.productCommentLikeEndpoint = "/bx_block_like/likes";
exports.productCommentUnlikeEndpoint = "/bx_block_like/likes/:id";
exports.productDeleteEndpoint = "/bx_block_cfproductlisting11/products/:id";
exports.productUpdateEndpoint = "/bx_block_cfproductlisting11/products/:id";
exports.btnExampleTitle = "CLICK ME";
exports.getUserRoleEndpoint = "account_block/accounts/get_user_role"
// Customizable Area End
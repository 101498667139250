// Customizable Area Start
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const iconLocation = require('../assets/ic_location.png');
export const iconAdd = require('../assets/ic_add.png')
export const iconUpload = require('../assets/ic_upload.png')
export const logoIkea = require('../assets/logo_ikea.png');
export const logoCowi = require('../assets/logo_cowi.png');
export const logoWashington = require('../assets/logo_washington.png');
export const iconUploadBlack = require('../assets/ic_upload_black.png')
export const OyoAvatar = require('../assets/ic_oyo_profile.png')
export const SendIcon = require("../assets/ic_send.png")
export const EmogiIcon = require("../assets/smile.png")
export const LikedIcon = require("../assets/ic_greenthumb.png")
export const CloseIcon = require("../assets/ic_close.png")
export const iconThumbGreen = require("../assets/ic_greenthumb.png")
export const LogoHex = require('../assets/ic_logohex.png')
// Customizable Area End
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { sendAPIRequest } from '../../../components/src/utils';
import * as yup from 'yup';
import { toast } from 'react-toastify'
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  token: string;
  onGoingProjectButton: boolean;
  activitesButton: boolean;
  milestoneData: any;
  milestoneViewData: any;
  onSeekingFundEdit: boolean;
  viewProjectData: any;
  delete: boolean;
  deleteId: any;
  requestorQuery: any;
  moderatorQuery: any;
  milestoneUniqueId: any;
  uomList: any;
  milestoneCategoriesList: any;
  leaderLists: any[];
  requestorPagination?: any;
  moderatorLists: any[];
  moderatorPagination?: any;
  typeOfInvestmentsLists: any[];
  requestorSearchCallId: string;
  modratorsSearchCallId: string;
  requestorPaginateCallId?: any;
  moderatorPaginateCallId?: any;
  allRequestorOptions: any[],
  allModratorOptions: any[],
  filesArray?: any;
  projectDetails: any;
  submittingForm:boolean;
  kpiModal:boolean;
  usedMilestoneIds: any,
  milestoneEnddate?:any,
  milestoneCategaryValue:any,
  removedAttachments:number[];
  selectedModratorsList:any[],
  moderatorsIdList:any[],
  selectedLeadersList:any[],
  leadersIdList:any[],
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProjectPortfolioController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  milestoneCallId: string = '';
  milestoneDeleteCallId: string = '';
  getMilestoneViewCallId: string = "";
  apiUpdateRequestCallId: string = "";
  apiRequestCallId: string = "";
  milestoneUniqueCallId: string = "";
  umoCallId: string = "";
  milestoneCategoriesCallId: string = "";
  leadersCallId: string = "";
  moderatorCallId: string = "";
  typeOfInvestmentCallId: string = "";
  requestorSearchCallId: string = "";
  getProjectDetailsCallId: string = "";
  milestoneDeleteViewCallId :string="";
  attachmentDeleteCallId:string="";
  initRequestorPagination: any = undefined;
  initModratorPagination: any = undefined;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.GetAllCategoriesResponseMessage),
      getName(MessageEnum.GetAllSubCategoriesResponseMessage),
      // Customizable Area End
    ];
  
    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      token: '',
      onGoingProjectButton: true,
      activitesButton: false,
      milestoneData: [],
      viewProjectData: [],
      onSeekingFundEdit: false,
      milestoneViewData: {},
      delete: false,
      deleteId: '',
      milestoneUniqueId: '',
      requestorSearchCallId: "",
      modratorsSearchCallId: '',
      uomList: [],
      milestoneCategoriesList: [],
      leaderLists: [],
      moderatorLists: [],
      typeOfInvestmentsLists: [],
      requestorQuery: '',
      moderatorQuery: '',
      allRequestorOptions: [],
      allModratorOptions: [],
      filesArray: [],
      projectDetails: {},
      submittingForm:false,
      kpiModal:false,
      usedMilestoneIds:[],
      milestoneEnddate:'',
      milestoneCategaryValue:{},
      removedAttachments:[],
      selectedModratorsList:[],
      moderatorsIdList:[],
      selectedLeadersList:[],
      leadersIdList:[],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End

  }

  // Customizable Area Start
  async componentDidMount() {
    await super.componentDidMount();
    const authToken = await getStorageData("authToken")
    const id = this.props.navigation.getParam('id')
    const projectId = this.props.navigation.getParam('projectId');
    if (authToken == null) {
      this.props.navigation.navigate('Home')
    }
    if (id) {
      this.getMilestoneViewCallId = sendAPIRequest(`${configJSON.getMilestoneEndpoint}/${id}`, {
        method: 'GET',
        headers: { 'token': authToken }
      })
    }
    if (projectId) {
      this.setState({submittingForm:true})
      this.getProjectDetailsCallId = sendAPIRequest(`${configJSON.getProjectDataEndpoint}?project_id=${projectId}`, {
        method: 'GET',
        headers: { 'token': authToken }
      })
    }
    this.setState({token:authToken}, () => {
      this.getUomApi();
      this.getMilestoneCategoriesApi();
      this.getLeadersApi();
      this.getModeratorApi();
      this.getTypeOfInvestmentsApi();
      this.getMilestoneApi(); 
    })  
  }
  // Customizable Area End

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);


    // Customizable Area Start
    this.getResponse(message);
    this.getResponse2(message);
    this.getDropdownResponse(message);
    // Customizable Area End
  }


  // Customizable Area Start
  async getResponse(message: any) {
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    const apiRequestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    switch (apiRequestId) {
      case this.milestoneCallId:
        if (responseJson.data) {
          let milestoneCategoriesId=responseJson?.data?.map((item:any)=>item?.attributes?.sub_category_id);
          let milestoneEnddate=responseJson?.data?.sort((a: any, b: any) => a.id - b.id);
          this.setState({ milestoneData: responseJson?.data,usedMilestoneIds:milestoneCategoriesId,milestoneEnddate:milestoneEnddate?.[milestoneEnddate.length-1]?.attributes?.end_date });
        }
        break;
      case this.milestoneDeleteCallId:
        if (responseJson.success) {
          this.setState({ deleteId: '', delete: false,submittingForm:false });
          toast.success('Milestone deleted successfully.');
          this.getMilestoneApi();
        }
        break;
      case this.getMilestoneViewCallId:
        if (responseJson.data) {
          let defaltVal=this.state.milestoneCategoriesList?.filter((obj:any)=>obj?.attributes?.id == responseJson?.data?.attributes?.sub_category_id)
          let moderatorsIdList = responseJson.data?.attributes?.milestone_moderators.map((id : any) => Number(id.data.id));
          let leadersIdList = responseJson.data?.attributes?.milestone_leaders.map((id : any) => Number(id.data.id));

          this.setState({ milestoneViewData: responseJson?.data,
            moderatorsIdList,
            leadersIdList,
            selectedLeadersList:responseJson.data?.attributes?.milestone_leaders.map((d: any) => d.data.attributes),
            selectedModratorsList:responseJson.data?.attributes?.milestone_moderators.map((d: any) => d.data.attributes),
            milestoneCategaryValue:defaltVal[0], filesArray: responseJson?.data?.attributes?.project_milestone_pdfs })
        }
        break;
     
      case this.milestoneUniqueCallId:
        this.setState({ milestoneUniqueId: responseJson?.milestone_id })
        break;
      case this.umoCallId:
          this.setState({ uomList: responseJson?.data })
        break;
      case this.milestoneCategoriesCallId:
        if (responseJson.data) {
          let defaltVal=responseJson?.data?.filter((obj:any)=>obj?.attributes?.id == this.state.milestoneViewData?.attributes?.sub_category_id)
         
          this.setState({ milestoneCategoriesList: responseJson?.data ,milestoneCategaryValue:defaltVal[0]})
        }
        break;
      case this.leadersCallId:
        const leadersOptions = responseJson?.milestone_leaders?.data.map((d: any) => d.attributes)
        this.initRequestorPagination = responseJson?.milestone_leaders?.meta?.pagination
        this.setState({ leaderLists: leadersOptions, allRequestorOptions: leadersOptions, requestorPagination: responseJson?.milestone_leaders?.meta?.pagination })

        break;
      case this.moderatorCallId:
        const moderatorOptions = responseJson.project_moderators.data.map((d: any) => d.attributes)
        this.initModratorPagination = responseJson.project_moderators.meta.pagination;
        this.setState({ moderatorLists: moderatorOptions, allModratorOptions: moderatorOptions, moderatorPagination: responseJson?.project_moderators.meta.pagination })

        break;
      case this.apiUpdateRequestCallId:

        const management_id =  await getStorageData('projectId');

         if (responseJson.data) {
          toast.success('Milestone updated successfully.');
          window.location.href = `/ProjectMilestone/${management_id}`
        }else{
          toast.error('Something went wrong.');
          window.location.href = `/ProjectMilestone/${management_id}`
        }

        break;
      case this.typeOfInvestmentCallId:
        if (responseJson.data) {
          this.setState({ typeOfInvestmentsLists: responseJson?.data })
        }
        break;
     default:
        break;
    }
  }

  getResponse2(message: any) {
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    const apiRequestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    switch (apiRequestId) {
    case this.state.requestorPaginateCallId:
        const options = responseJson?.milestone_leaders?.data.map((d: any) => d.attributes)
        const leaderOptions = this.filterUnique(this.state.leaderLists.concat(options))
        this.setState({ leaderLists: leaderOptions, requestorPagination: responseJson?.milestone_leaders?.meta?.pagination })

        break;
      case this.state.moderatorPaginateCallId:
        const modratoroptions = responseJson?.project_moderators.data.map((d: any) => d.attributes)
        const modratorOptions = this.filterUnique(this.state.moderatorLists.concat(modratoroptions))
        this.setState({ moderatorLists: modratorOptions, moderatorPagination: responseJson?.project_moderators.meta.pagination })

        break;
      case this.state.modratorsSearchCallId:
          if (!Array.isArray(responseJson &&responseJson.project_moderators?.data))
            this.setState({
              modratorsSearchCallId: '',
              moderatorLists: [],
              moderatorPagination: undefined
            })
          else {
            const modratorOptions = responseJson &&responseJson.project_moderators.data.map((d: any) => d.attributes)
            this.setState({
              modratorsSearchCallId: '',
              moderatorLists: modratorOptions,
              moderatorPagination: responseJson &&responseJson.project_moderators.meta.pagination,
            })
          }
          break;
      default:
        break;
    }
  }

  async getDropdownResponse(message: any) {
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }

    const apiRequestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    switch (apiRequestId) {
      case this.state.requestorSearchCallId:
        if (!Array.isArray(responseJson &&responseJson.milestone_leaders?.data))
          this.setState({
            requestorSearchCallId: '',
            leaderLists: [],
            requestorPagination: undefined
          })
        else {
          const requestorOptions = responseJson &&responseJson.milestone_leaders.data.map((d: any) => d.attributes)
          this.setState({
            requestorSearchCallId: '',
            leaderLists: requestorOptions,
            requestorPagination: responseJson &&responseJson.milestone_leaders.meta.pagination,
          })
        }
        break;

     

      case this.getProjectDetailsCallId:
        if(this.props.navigation.history.location.pathname.includes('/AddMilestone')){
          this.getMilestoneIdApi(responseJson?.project_id);
        }
        this.setState({ projectDetails: responseJson,submittingForm:false })

        break;
        case this.milestoneDeleteViewCallId:
          const management_id = await getStorageData('projectId');
          toast.success('Milestone deleted successfully.');
          window.location.href = `/ProjectMilestone/${management_id}`
        break;
        case this.apiRequestCallId:
          const projectId = this.props.navigation.getParam('projectId');
          if (responseJson.data) {
            toast.success('Milestone created successfully.');
            window.location.href = `/ProjectMilestone/${projectId}`
          }else{
            toast.error('Something went wrong.');
            window.location.href = `/ProjectMilestone/${projectId}`
          }
          break;
          case this.attachmentDeleteCallId:
            this.setState({ submittingForm: false })
            break;
      default:
        break;
    }

  }

onActivityClick = () => {
  const id = this.props.navigation.getParam('projectId')
  this.props.navigation.history.push(`/ActivityDashboard/${id}`)
}

  filterUnique = (items: any[]) => {
    const hashmap = new Set()
    return items.filter((i: any) => {
      if (hashmap.has(i.id)) return false
      hashmap.add(i.id)
      return hashmap
    })
  }


  paginateLeaders = () => {
    if (this.state.requestorSearchCallId !== '') return
    const { next_page } = this.state.requestorPagination
    this.setState({ requestorPaginateCallId: this.handleLeadersSearch(this.state.requestorQuery, next_page) })
  }


  paginateModerator = () => {
    if (this.state.modratorsSearchCallId !== '') return
    const { next_page } = this.state.moderatorPagination
    this.setState({ moderatorPaginateCallId: this.handleModeratorSearch(this.state.moderatorQuery, next_page) })
  }


  handleModeratorSearch = async(query: string, page?: number) => {
    const token = await getStorageData("authToken")
    const params = new URLSearchParams()
    const projectId = await getStorageData('projectId');
    query && params.append('moderators', query)
    page && params.append('page', String(page))
    params.append('project_id', String(projectId))
    const url = configJSON.getModeratorEndpoint + "?" + params.toString()
    return sendAPIRequest(url, { method: 'GET', headers: { token } })
  }

  handleLeadersSearch = (query: string, page?: number) => {
    const { token } = this.state;
    const params = new URLSearchParams()
    query && params.append('leaders', query)
    page && params.append('page', String(page))
    const url = configJSON.getLeadersEndpoint + "?" + params.toString()
    return sendAPIRequest(url, { method: 'GET', headers: { token } })
  }

  handleLeaderSearchQuery = (query: string) => {
    if (query)
      this.setState({
        requestorSearchCallId: this.handleLeadersSearch(query),
        requestorQuery: query,
        requestorPaginateCallId: ''
      })
    else
      this.setState({
        leaderLists: this.state.allRequestorOptions,
        requestorPagination: this.initRequestorPagination,
        requestorQuery: '',
        requestorSearchCallId: '',
      })
  }

  handleModeratorSearchQuery = async(query: string) => {
    let msCallId = await this.handleModeratorSearch(query)
    if (query)
      this.setState({
        modratorsSearchCallId: msCallId,
        moderatorQuery: query,
        moderatorPaginateCallId: ''
      })
    else
      this.setState({
        moderatorLists: this.state.allModratorOptions,
        moderatorPagination: this.initModratorPagination,
        moderatorQuery: '',
        modratorsSearchCallId: '',
      })
  }

  handleLeadersClose = () => {
    this.setState({
      requestorSearchCallId: '',
      requestorQuery: '',
      requestorPaginateCallId: '',
      leaderLists: this.state.allRequestorOptions,
      requestorPagination: this.initRequestorPagination,
    })
  }

  handleModeratorClose = () => {
    this.setState({
      modratorsSearchCallId: '',
      moderatorQuery: '',
      moderatorPaginateCallId: '',
      moderatorLists: this.state.allModratorOptions,
      moderatorPagination: this.initModratorPagination,
    })
  }


  validationSchema = yup.object().shape({
    description:yup.string().label('Description').required().max(500),
    milestone_category:yup.string().label('Milestone Category').required(),
    milestone_name:yup.string().label('Milestone Name').required().max(100),
    milestone_leader:yup.array().min(1,'Select atleast one Milestone Leader').required(),
    milestone_moderator:yup.array().min(1,'Select atleast one Milestone Moderator').required(),
    kpi:yup.string().label('KPI').required(),
    uom:yup.string().label('UoM').required(),
    baseline:yup.number().typeError('Baseline must be a number').nullable(),
    target_output_direct:yup.number().typeError('Target output direct must be a number').nullable(),
    actual_output_direct:yup.number().typeError('Actual output direct must be a number').nullable(),
    target_output_indirect:yup.number().typeError('Target output indirect must be a number').nullable(),
    actual_output_indirect:yup.number().typeError('Actual output indirect must be a number').nullable(),
    target_outcome_Direct:yup.string().label('Target outcome direct').required().max(100),
    target_outcome_indirect:yup.string().label('Target outcome indirect').required().max(100),
    actual_outcome_Direct:yup.string().label('Actual outcome direct').max(100),
    actual_outcome_indirect:yup.string().label('Actual outcome indirect').max(100),
    estimated_inputs:yup.string().label('Estimated inputs').max(100),
    actual_inputs:yup.string().label('Actual inputs').max(100),
    estimated_cost:yup.number().typeError('Only numeric values is allowed').required(),
    start_date: yup.string().label('Required field is mandatory').required().nullable(),
    end_date: yup.string().label('Required field is mandatory').required().nullable(),
    type_of_investment:yup.string().label('Type of Investment').required(),
    status:yup.string().label('Milestone Status').required(),
    actual_cost: yup.number().typeError('Only numeric values is allowed').nullable(),
    estimated_capex: yup.number().typeError('Only numeric values is allowed').nullable(),
    actual_capex: yup.number().typeError('Only numeric values is allowed').nullable(),
    estimated_saving: yup.number().typeError('Only numeric values is allowed').nullable(),
    actual_saving: yup.number().typeError('Only numeric values is allowed').nullable(),
  });

  onGoingProject = () => {
    this.setState({ onGoingProjectButton: true, activitesButton: false });
  }

  onSeekingFund = () => {
    this.setState({ onGoingProjectButton: false, activitesButton: true });
  }

  onBack = () => {
    this.setState({ onSeekingFundEdit: false })
  }


  handleModal = (id: any) => {
    this.setState({ deleteId: id, delete: true })
  }
  handleCloseDelete = () => {
    this.setState({ delete: false })
  }
  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  }

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  }
  closeKpiModal = () => {
    this.setState({kpiModal:false});
  }
  getMilestoneApi = async() => {
    const projectId = await getStorageData('projectId');
    this.milestoneCallId = sendAPIRequest(`${configJSON.getMilestoneEndpoint}?project_id=${projectId}`, {
      method: "GET",
      headers: { 'token': this.state.token },
    })
  }

  getUomApi = () => {
    this.umoCallId = sendAPIRequest(`${configJSON.getUOMId}`, {
      method: "GET",
      headers: { 'token': this.state.token },
    })
  }
  getLeadersApi = () => {
    this.leadersCallId = sendAPIRequest(`${configJSON.getLeadersEndpoint}`, {
      method: "GET",
      headers: { 'token': this.state.token },
    })
  }

  getModeratorApi = async() => {
    const projectId = await getStorageData('projectId');
    this.moderatorCallId = sendAPIRequest(`${configJSON.getModeratorEndpoint}?project_id=${projectId}`, {
      method: "GET",
      headers: { 'token': this.state.token },
    })
  }


  getMilestoneCategoriesApi = async() => {
    const projectId = await getStorageData('projectId');
    this.milestoneCategoriesCallId = sendAPIRequest(`${configJSON.getMilestoneCategoriesEndpoint}?project_id=${projectId}`, {
      method: "GET",
      headers: { 'token': this.state.token },
    })
  }

  getTypeOfInvestmentsApi = () => {
    this.typeOfInvestmentCallId = sendAPIRequest(`${configJSON.getTypeOfInvestmentsEndpoint}`, {
      method: "GET",
      headers: { 'token': this.state.token },
    })
  }

  getMilestoneIdApi = (id: any) => {
    this.milestoneUniqueCallId = sendAPIRequest(`${configJSON.getMilestoneUniqueId}?project_id=${id}`, {
      method: "GET",
      headers: { 'token': this.state.token },
    })
  }

  goBack = () => { this.props.navigation.goBack() }

  onDelete = () => {
    this.setState({submittingForm:true})
    this.milestoneDeleteCallId = sendAPIRequest(`${configJSON.getMilestoneEndpoint}/${this.state.deleteId}`, {
      method: "DELETE",
      headers: { 'token': this.state.token },
    })
  }

  onDeleteView= () => {
    this.milestoneDeleteViewCallId = sendAPIRequest(`${configJSON.getMilestoneEndpoint}/${this.state.deleteId}`, {
      method: "DELETE",
      headers: { 'token': this.state.token },
    })
  }

  setFilesArray = (file: any) => {
    this.setState({ filesArray: [file] });
  }

  onDeleteAttachment = (attachmentId:any) => {
    if(attachmentId){
      this.setState(prevState => ({
          removedAttachments: [...prevState.removedAttachments, attachmentId]
      }))
  }
  }


  handleRequestTypeChange = (e: any, option: any, setTouched: any, setFieldValue: any) => {
    setTouched({ milestone_category: true });
    setFieldValue('milestone_category', (!option ? 0 : JSON.parse(option?.attributes?.id || '')));
    this.setState({ milestoneCategaryValue: (!option ? {} : option) });
  };

  handelSubmit = async (data: any) => {
    let { objectiveId = "", projectId = '', project_name = "", milestone_category = '', milestone_name = "", start_date = "", end_date = "",
      kpi = "", uom = "", baseline = "", target_output_direct = "", actual_output_direct = "", target_outcome_Direct = "",
      actual_outcome_Direct = "", target_output_indirect = "", actual_output_indirect = "", target_outcome_indirect = "",
      actual_outcome_indirect = "", estimated_cost = "", actual_cost = "", estimated_inputs = "", actual_inputs = "", estimated_capex = "",
      actual_capex = "", estimated_saving = "", actual_saving = "", type_of_investment = "", description = "", status = "",
      milestone_moderator = [], milestone_leader = [], project_milestone_pdfs = [],type_of_investment_please_specify='',uom_please_specify=""
    } = { ...data }
    this.setState({submittingForm:true})
    const body = new FormData()
    const projectIdU = this.props.navigation.getParam('projectId');
    const management_id = await getStorageData('projectId');
    body.append('data[project_management_id]', projectIdU != undefined ? projectIdU : management_id)
    body.append('data[project_id]', projectId ? projectId : this.state.projectDetails?.project_id)
    body.append('data[milestone_unique_id]', project_name != '' ? project_name : this.state.milestoneUniqueId)
    body.append('data[milestone_name]', milestone_name)
    body.append('data[milestone_categories]', milestone_category)
    body.append('data[description]', description)
    body.append('data[start_date]', start_date)
    body.append('data[end_date]', end_date)
    body.append('data[kpi]', kpi)
    body.append('data[uom]', uom)
    body.append('data[uom_please_specify]', uom_please_specify)
    body.append('data[baseline]', baseline)
    body.append('data[target_output_direct]', target_output_direct)
    body.append('data[actual_output_direct]', actual_output_direct)
    body.append('data[target_outcome_direct]', target_outcome_Direct)
    body.append('data[actual_outcome_direct]', actual_outcome_Direct)
    body.append('data[target_output_indirect]', target_output_indirect)
    body.append('data[actual_output_indirect]', actual_output_indirect)
    body.append('data[target_outcome_indirect]', target_outcome_indirect)
    body.append('data[actual_outcome_indirect]', actual_outcome_indirect)
    body.append('data[estimated_cost]', estimated_cost)
    body.append('data[actual_cost]', actual_cost)
    body.append('data[estimated_inputs]', estimated_inputs)
    body.append('data[actual_inputs]', actual_inputs)
    body.append('data[estimated_capex]', estimated_capex)
    body.append('data[actual_capex]', actual_capex)
    body.append('data[estimated_savings]', estimated_saving)
    body.append('data[actual_savings]', actual_saving)
    body.append('data[type_of_investment]', type_of_investment)
    body.append('data[type_of_investment_please_specify]', type_of_investment_please_specify)
    body.append('data[milestone_status]', status)
    milestone_moderator.forEach((id: any) => body.append('data[milestone_moderators][]', id))
    milestone_leader.forEach((id: any) => body.append('data[milestone_leaders][]', id))
    project_milestone_pdfs.forEach((file: any) => file?.id?'':body.append("data[project_milestone_pdfs][]", file));
    
    const id = this.props.navigation.getParam('id')
    if (this.state.removedAttachments.length > 0) {
      const removeAttachmentUrl = configJSON.deleteMilestoneAttachmentEndpoint.replace(':id', id);         
      const removeAttachmentBody = { ids: this.state.removedAttachments };
      const removeAttachmentPromise = new Promise<void>((resolve, reject) => {
        sendAPIRequest(removeAttachmentUrl, {
          method: 'PUT',
          headers: {"token": this.state.token,'content-type': 'application/json' },
          body: removeAttachmentBody,
        });
        resolve();
      });
      await removeAttachmentPromise;
    }


    const headers = {
      "token": this.state.token,
    }
    let url = configJSON.getMilestoneEndpoint;
    if (id) {
      url = `${url}/${id}`;
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    let method = '';
    if (id) {
      this.apiUpdateRequestCallId = requestMessage.messageId;
      method = "PUT"
    } else {
      this.apiRequestCallId = requestMessage.messageId;
      method = "POST"
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End

}

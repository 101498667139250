enum MessageEnum {
  EntityPostedMessage,
  PostEntityMessage,
  GetEntityMessage,
  NavigationMessage,
  NavigationScreenNameMessage,
  NavigationRaiseMessage,
  NavigationTargetMessage,
  NavigationPropsMessage,
  NavigationPayLoadMessage,
  NavigationMobilePhoneOTPMessage,
  NavigationMobilePhoneAdditionalDetailsMessage,
  NavigationNewPasswordMessage,
  NavigationInfoPageMessage,
  NavigationHomeScreenMessage,
  NavigationMobilePhoneLogInMessage,
  NavigationEmailLogInMessage,
  NavigationSocialLogInMessage,
  NavigationTermAndConditionMessage,
  NavigationPrivacyPolicyMessage,
  NavigationForgotPasswordPageInfo,
  NavigationForgotPasswordOTPMessage,
  NavigationForgotPasswordMessage,
  NavigationSocialMediaAccountLogin,
  NavigationSocialMediaAccountCreation,
  NavigateEmailSignUpMessage,
  RestAPIRequestMessage,
  RestAPIRequestMethodMessage,
  RestAPIRequestHeaderMessage,
  RestAPIRequestBodyMessage,
  RestAPIResponceMessage,
  RestAPIResponceDataMessage,
  RestAPIResponceSuccessMessage,
  RestAPIResponceErrorMessage,
  RestAPIResponceEndPointMessage,
  AlertMessage,
  AlertTypeMessage,
  AlertTitleMessage,
  AlertBodyMessage,
  CountryCodeMessage,
  CountyCodeDataMessage,
  EnterOTPAsForgotPasswordMessage,
  AuthTokenPhoneNumberMessage,
  AuthTokenEmailMessage,
  AuthTokenDataMessage,
  InfoPageMessage,
  InfoPageTitleMessage,
  InfoPageBodyMessage,
  InfoPageButtonTextMessage,
  InfoPageDataMessage,
  InfoPageNavigationScreenMessage,
  AccoutResgistrationSuccess,
  AccoutLoginSuccess,
  SessionRequestMessage,
  SessionRequestedBy,
  SessionResponseMessage,
  SessionResponseData,
  SessionResponseToken,
  SessionResponseError,
  SessionSaveMessage,
  AuthenticateUserMessage,
  LoginSuccessMessage,
  LoginFaliureMessage,
  LoginUserName,
  LoginPassword,
  LoginCountryCode,
  LoginIsRememberMe,
  RequestUserCredentials,
  ReciveUserCredentials,
  RequestUserSession,
  // triggered on user login, signup and logout
  // on logout SessionUpdateData is undefined
  SessionUpdateMessage,
  SessionUpdateData,
  
  ReciveUserSession,
  NavigationAlertWebMessage,
  AlertButtonPositiveText,
  AlertButtonPositiveMessage,
  AlertButtonNegativeText,
  AlertButtonNegativeMessage,
  AlertButtonNeutralText,
  AlertButtonNeutralMessage,
  NavigationPlaceSearchMessage,
  SelectedPlaceMessage,
  SelectedPlaceData,
  SelectedPlaceDetails,
  UserDeletesProfileImageMessage,
  SearchIconOnNavigationBarMessage,
  AddStopToSearchLocationDirectionMessage,
  SelectedRoleMessage,
  SelectedRoleDataMessage,
  SplashScreenCompleteMessage,
  OnboardingGuideControllerSkipOnboarding,
  OnboardingGuideControllerDoneOnboarding,
  NavigationDiscoverAllMessage,
  InitiatePayment,
  NavigationLocationMessage,
  LocationReqestingScreenIdMessage,
  NavigationCalendarMessage,
  LocationReqestingScreenMessage,
  AddressSelectionMessage,
  SelectedServiceMessage,
  SelectedOfferMessage,
  SelectedTimeMessage,
  SelectedDateMessage,
  NavigationShoppingcartMessage,
  SelectedSpecialistMessage,
  ServiceProviderIdMessage,
  NavigationReviewsMessage,
  NavigationDiscountsOffersMessage,
  NavigationOfferDetailsMessage,
  SelectedSpecialOfferDataMessage,
  NavigationStoreLocatorMessage,
  NearbyVansApiMessage,
  CalendarEstimatedArrivalTimeApiMessage,
  SPDBUpdateVanLocationApiMessage,
  SPAMDUpdateVanLocationApiMessage,
  SPAMLUpdateVanLocationApiMessage,
  NearbyVansResponseMessage,
  CalendarEstimatedArrivalTimeResponseMessage,
  NavigationDashboardMessage,
  SPDBUpdateVanLocationResponseMessage,
  NavigationAppointmentDetailsMessage,
  SPAMDUpdateVanLocationResponseMessage,
  NavigationAppointmentLocationMessage,
  SPAMLUpdateVanLocationResponseMessage,
  SPAMDGetAppointmentDetailsResponseMessage,
  NavigationAppointmentManagementMessage,
  UAMUpdateOrderResponseMessage,
  UAMCancelOrderResponseMessage,
  SPAMDGetAppointmentDetailsApiMessage,
  UAMCancelOrderApiMessage,
  UAMUpdateOrderApiMessage,
  NavigationPaymentsMessage,
  OrderSuccessDataMessage,
  CalendarProviderDetailsResponseMessage,
  CalendarProviderDetailsApiMessage,
  ScheduleCreateAvailabilityResponseMessage,
  ScheduleCreateAvailabilityApiMessage,
  ScheduleGetServicesResponseMessage,
  NavigationCatalogueMessage,
  ScheduleGetServicesApiMessage,
  NavigationServiceProviderSchedule,
  CalendarOfferProviderResponseMessage,
  CalendarAvailableSlotsResponseMessage,
  CalendarAvailableSlotsApiMessage,
  CalendarOfferProviderApiMessage,
  CustomSubscriptionDataMessage,
  PostDetailDataMessage,
  HelpCentreMessageData,
  NewPostCreatedMessage,
  NewPostCreatedDataMessage,
  PostUpdatedMessage,
  PostUpdatedDataMessage,
  NewJobCreatedMessage,
  NewJobData,
  JobUpdatedMessage,
  JobUpdatedData,
  JobApplyMessage,
  JobApplyData,
  NewServiceCreatedMessage,
  NewServiceData,
  ServiceUpdatedMessage,
  ServiceUpdatedData,
  NewProductCreatedMessage,
  NewProductData,
  ProductUpdatedMessage,
  ProductUpdatedData,
  
  GetAllCategoriesMessage,
  GetAllSubCategoriesMessage,
  GetAllCategoriesResponseMessage,
  GetAllSubCategoriesResponseMessage,
  AllCategoriesDataMessage,
  AllSubCategoriesDataMessage,
  StartGoogleLoginMessage,
  CommunityJoinedMessage,
  CommunityUnjoinedMessage,
  CommunityDetailData,
  SharePopupMessaage,
  SharePopupData,
  AddRequestMessaage,
  AddRequestData,
  RequestUpdateMessage,
  RequestCreateMessage,
  RequestRespondMessage,
  RequestData,
  ExportCSVMessage,
  ExportCSVLinkData,
  ExportCSVComplete,
  ReportModalOpenMessage,
  ReportModalData,
  MarkAllNotificationReadMessage,
  NewNotificationMessage,
  NewNotificationData,
  CourseUpdatedMessage,
  CourseUpdatedDataMessage,
  EventUpdatedMessage,
  EventUpdatedDataMessage,
  CorpstageCoinsUpdateMessage,
  CorpstageCoinsUpdateData,
  CorpstageCoinsNoMessage,
  CorpstageCoinsNoData,
  ReportingFrameworksNavData
}

export const getName = (myEnum: MessageEnum) => {
  return MessageEnum[myEnum];
};

const StringIsNumber = (value: any) => isNaN(Number(value)) === false;
const isNavigationMessage = (value: string) => value.indexOf('navigateTo');

// Turn enum into array
export const enumToArray = (myEnum: any) => {
  return Object.keys(myEnum)
    .filter(StringIsNumber)
    .map(key => myEnum[key]);
};

export const enumToNavigationArray = (myEnum: any) => {
  return enumToArray(myEnum).filter(isNavigationMessage);
};

export default MessageEnum;

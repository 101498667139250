import React from "react";

// Customizable Area Start
import {
  StyleSheet,
  View,
  ScrollView,
  TouchableWithoutFeedback,
} from "react-native";
import { Link } from 'react-router-dom'
import {Box, Grid,Typography,Button,TextField,Modal,Divider} from '@material-ui/core';
import {image_Bitmap,image_Bradlogo} from "../src/assets";
import Hidden from '@material-ui/core/Hidden';
import {styled} from "@material-ui/core/styles";
const configJSON = require("./config")
import OverlayLoader from '../../../components/src/Loader.web';
// Customizable Area End

import OTPInputAuthController, {
  Props
} from "../../otp-input-confirmation/src/OTPInputAuthController";

export default class ForgotPasswordOTP extends OTPInputAuthController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
       // Customizable Area Start
       const buttonLabel = this.state.navTypeData==="email" ? "Verify & Create Account":"Verify OTP"
    // Customizable Area End
    return (
      // Customizable Area Start
      <ScrollView keyboardShouldPersistTaps="always" style={styles.container}>
        <TouchableWithoutFeedback
          onPress={() => {
            this.hideKeyboard();
          }}
        >
          {/* Customizable Area Start */}
          <View>
            <form  data-test-id='OTPform' style={{minHeight: '100vh'}} onSubmit={this.OnsubmitOtp}>
            <div style={{display:"flex", alignItems:"center",justifyContent:"center",margin:"70px auto", }}>
            <Grid container spacing={1}>
                   <Grid item xs={12} sm={12} md={6} lg={6}  >
                   <Hidden smDown>
                    <Box style={{backgroundColor:"white", height:"500px",display:'flex',flexDirection:'column', alignItems:"center",justifyContent:"center", position:"relative",}}>
                              <img src ={image_Bitmap} width="450px" height="350px" style={{borderRadius:"10px",}}/>
                        </Box>
                        </Hidden>
                   </Grid>
                   <Grid item xs={12} sm={12} md={6} lg={6} style={{"overflowX":"hidden"}} >
                      <Box style={{padding:"0px 70px 40px 70px",}}>
                      <Grid container>
                           <Grid item xs={12}>
                            <Box style={{display:'flex',flexDirection:'column', borderRadius:"10px",padding:"20px",alignItems:"center",justifyContent:"center", position:"relative",}}>
                                <Link to="/">
                                  <img src ={image_Bradlogo} width="150px" height="50px" style={{borderRadius:"10px",}}/>     
                                </Link>
                            </Box>  
                           </Grid>
                           <Grid item xs={12}>
                           <Typography variant="h5" gutterBottom style={{fontWeight:"bold",textAlign:"center",marginTop:"50px"}} component="div">
                             Enter OTP
                            </Typography>
                            <Box style={{display:"flex",justifyContent:"center",alignItems:"center",margin:"20px 20px",}}>     
                            <Typography variant="subtitle2" gutterBottom component="div" style={{color:"#a6a4a4"}}>
                             Enter your OTP here
                            </Typography>
                            </Box>
                                    
                           </Grid>
                           <Grid item xs={12}>
                          <Box style={{display:"flex",alignItems:"center", justifyContent:"center"}}>
                            <OTPInputRow submit={this.OnsubmitOtp} onChange={this.setOtp}/>
                          </Box>
                           </Grid>
                           <Grid item xs={12}>
                            <Typography color="error" variant="subtitle2" style={{marginTop: '10px'}} align="center">
                              {this.state.otpAPIError}
                            </Typography>
                           </Grid>
                           <Grid item xs={12} style={{marginTop: '10px'}}>
                            <Typography color="textSecondary" align="center">
                              { configJSON.labelOtpTimeRemaining.replace("{time}", this.timeRemaining())}
                            </Typography>
                           </Grid>
                           <Grid item xs={12}>
                           <Typography 
                            id='resendOTP'
                            variant="body2" 
                            gutterBottom 
                            style={{fontWeight:"bold",textAlign:"center",marginTop:"30px",color: this.state.counterId? "#777777":"#1ea60a"}} 
                            component="div" 
                            onClick={this.handleResendOTP}>
                             Resend OTP
                            </Typography>
                           </Grid>
                           <Grid item xs={12}>
                           <Box style={{display:"flex",justifyContent:"center",alignItems:"center",margin:"20px 0px",}}>
                            { this.state.otp.length === 5 ?   
                            <Button type="submit" variant="contained" disabled={this.state.isLoading} style={{width:"50%",backgroundColor:"#1ea60a", borderRadius:"10px",color:"white", height:"50px", fontWeight:"bold",textTransform:"none", minWidth:'200PX' }} >
                              {this.state.isLoading?buttonLabel:buttonLabel}
                            </Button> : 
                            <Button type="submit" variant="contained" disabled={this.state.isLoading} style={{width:"50%",backgroundColor:"#d0d0d0", borderRadius:"10px",color:"white", height:"50px", fontWeight:"bold",textTransform:"none", minWidth:'200PX' }} >
                              {this.state.isLoading?buttonLabel:buttonLabel}
                            </Button> }
                  
                               </Box>
                           </Grid>
                       </Grid>
                      </Box>
                   </Grid>

               </Grid>
               <Modal
            style={{display:"flex", justifyContent:"center", alignItems:"center"}}
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div style={{backgroundColor:"white",padding:"10px 20px 20px 20px", width:"50%",display:"flex",flexDirection:"column", }}>
          {
            this.state.modalHeading && <>
              <h3 id="simple-modal-title">{this.state.modalHeading}</h3>
              <Divider/>
            </>
          }
          <p id="simple-modal-description">
          {this.state.modalContent}
          </p>
          <Divider/>
          <Button id='handleCloseId' variant="contained" color="primary" onClick={this.handleClose} style={{width:"50px",margin:"10px 0px 0px 0px",}} >
                        Ok
                    </Button>
        </div>
          </Modal>
               </div>
               <OverlayLoader loading={this.state.isLoading} />
            </form>
          </View>
          {/* Customizable Area End */}
        </TouchableWithoutFeedback>
      </ScrollView>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const OTPInput = styled(TextField)({
   color:"grey",
   fontSize:14,
   width:"25px",
   height:"25px",
   margin:"5px 5px",
   borderRadius:"5px",
   border:"1px solid #b3b3b3",
   textAlign:"center",
   justifyContent:"center",
   backgroundColor:"#fafafa",
   padding:"5px 0px 5px 10px",
})

interface OTPInputRowProps {
  value?: string;
  onChange?: (x: string) => void;
  submit?: (e: any) => void;
}

export const OTPInputRow = (props: OTPInputRowProps) => {
  const ref = React.useRef<(HTMLInputElement|null)[]>([])
  const [otp, setOtp] = React.useState(Array(5).fill(""))

  React.useEffect(() => {
    props.onChange && props.onChange(otp.join(""))
  }, [otp])

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log('dd1')
    const id = Number(e.currentTarget.dataset.id)
    const value = e.target.value
    if(isNaN(Number(value)))
      return
    if(value.length == 0){
      return
    }
    if(value.length < 2)
    {
      const newOtp = [...otp]
      newOtp[id] = value
      setOtp(newOtp)
    } else {
      const newOtp = [...otp]
      if (newOtp[id] === value[0] ) {
        newOtp[id] = value[1]
      } else {
        newOtp[id] = value[0]
      }
      
      console.log(newOtp, 'Otp value')
      setOtp(newOtp)
    }
    if( id+1 < 5 ) {
      const nextInput = ref?.current[id+1]
      nextInput && nextInput.focus()
      console.log({nextInput})
    }
  }

  const onKeyUp = (e: any) => {

    const id = Number(e.currentTarget.dataset.id)
    if(e.key === "Backspace") {
      const newOtp = [...otp]
      newOtp[id] = ""
      setOtp(newOtp)
      const current = ref?.current
      if( id-1 >= 0 && current ) {
        current[id-1]?.focus?.()
         current[id-1]?.select?.()
      }
    } else if (e.key === "Enter") {
      props.submit && props.submit(undefined)
    }
  }

  return <>
    {Array(5).fill(0).map(
      (val, idx) => <OTPInput
        data-test-id='OTPInputId'
        autoFocus={idx === 0}
        key={idx}
        value={otp[idx]} 
        onChange={onChange}
        onKeyUp={onKeyUp}
        inputProps={{'data-id': idx, ref: (el: any)=>(ref.current[idx]=el), autoFocus: idx===0}} 
        data-id={idx} 
        InputProps={{disableUnderline:true}} />)
    }
  </>
}



const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignSelf: "center",
    display:"flex",
    width: "100%",
  },
  titleWhySignUp: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },
  titleOtpInfo: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },

  phoneInputMobile: {
    flexDirection: "row",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginBottom: 64,
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true,
    padding: 10
  },

  phoneInputWeb: {
    flex: 1,
    flexDirection: "row",
    marginBottom: 64,
    fontSize: 18,
    padding: 10,
    borderBottomColor: "#767676",
    borderBottomWidth: 1
  },

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    marginBottom: 10,
    padding: 10
  }
});
// Customizable Area End

import React, { useRef } from 'react'
import { styled } from '@material-ui/styles'
import {Button} from '@material-ui/core'
const CloseIcon = require('./ic_close.png')

const iconUpload = require('./ic_upload_black.png')

export interface FileDropProps {
	/** onChange callback called when new files are selected */
	onChange?: (files: File[]) => void;
	onRemove?: (e?:any ) => void ;
	showDelete?: boolean;
	inputProps?: any;
	fileUrl?: string;
	multiple?: boolean;
	fileType?: string;
}

export default function FileDrop({ onChange, showDelete, onRemove, inputProps={}, ...props }: FileDropProps) {
	const inputRef = useRef<HTMLInputElement|null>(null)
	function handleClick() {
		inputRef?.current?.click()
	}
	function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
		const files = e.target.files;
		if(files && onChange){
			onChange(Array.from(files))
		}
	}
	function handleFileDrop(e: React.DragEvent<HTMLDivElement>){
		let files = e.dataTransfer.files
		if(files && onChange) {
			onChange(Array.from(files))
		}
	}
	let imageUrl: string = ''
	if(typeof props.fileUrl === "string") 
		imageUrl = props.fileUrl
	else if(inputRef?.current?.files && inputRef?.current?.files[0]) 
		imageUrl = URL.createObjectURL( inputRef?.current?.files[0] || '' )
	let placeholder = (!imageUrl|| inputProps.multiple) && <div style={{display: 'flex', justifyContent: 'center'}}>
				<img style={{margin: '0 10px'}} src={iconUpload} width="20px" />
				Upload image
			</div>
	

	return <Wrapper onDrop={handleFileDrop} onClick={handleClick}>
		{placeholder}
		{showDelete && !inputProps.multiple && <CloseButton onClick={onRemove}><img alt="delete image" src={CloseIcon} width="26px" /></CloseButton>}
		<input 
			ref={inputRef} 
			style={{ display: 'none' }} 
			onChange={handleChange} 
			type={props.fileType||"file"}  
			{...inputProps} 
		/>
		{imageUrl && !inputProps.multiple && <img width="100%" height="auto" src={imageUrl}/>}
	</Wrapper>
}

const CloseButton = styled(Button)({
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 1010
  })

const Wrapper = styled('div')( (props) => ({
	color: '#777',
	width: '100%',
	borderRadius: '15px',
	border: '3px dashed #CCC',
	height: '180px',
	dispaly: 'flex', 
	justifyContent: 'center',
	alignItems: 'center',
	display: 'flex',
	cursor: 'pointer',
	overflow: 'hidden',
	position: 'relative'
}))
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import Snackbar from '@material-ui/core/Snackbar';
import React, { Context } from "react";
import { toast } from 'react-toastify'
import { sendAPIRequest } from '../../../components/src/utils'


import SnackbarContent from '@material-ui/core/SnackbarContent';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
//@ts-ignore
// import UserDetailsContext , { UserContextType } from '../../../web/src/Context/ProfileContext'
import UserContext, {User} from "../../../components/src/UserProvider";
import {HelpSupportItem} from "./types"
import * as yup from 'yup';
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  getTaggedCompany:any[];
  enteredCompany:string;
  selectedTaggedCompany:any;
  showPassword: Boolean;
  showPassword2: Boolean;
  showPassword3: Boolean;
  preference:any[];
  subscription:any;
  role:any;
  expanded:any;
  email:string;
  first_name:string;
  tagged_with_company:string;
  last_name:string;
  password:string;
  location:string;
  about:string;
  company_name:string;
  designation:string;
  current_password:string;
  new_password:string;
  confirm_password:string;
  isLoading:boolean;
  opened:boolean;
  profile_pic:any;
  snackbarmsg:string;
  color:string;
  contain:string;
  file:any;
  question:HelpSupportItem[];
  formProfileData:any;
  token: string;
  currentLocation: string;
  loading: boolean;
  
  termsFile: string;
  total_followers?: any;
  total_post?: any;
  total_views?: any;

  numPages : number | null;
  pageNumber : number;
  allFollowers: any[];
  allViewer: any[];
  anchorElForPopupFollower: any;
  anchorElForPopupNested: any;
  contactUs:any[];
  corporate_company_name: string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Settings5Controller extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  static contextType?: Context<User> = UserContext;
  updateAccountApiCallId:any;
  updateProfilePic:any;
  updateAccountGetApiCallId:any;
  settingsChangePassword:any;
  getTermsandConditions:any;
  getHelpandSupport:any;
  taggedCompanyData:any;
  getAllFollowersCallId = '';
  getAllViewersCallId = "";
  getContactUsDataCallId="";

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const authToken = await getStorageData("authToken");
    console.log(authToken,'auth token check');
    if(authToken==null){
      this.props.navigation.navigate('Home')
    }
    this.getDetails();
    this.getTermsAndConditions();
    this.getHelpAndSupport();
    this.getContactUsData();
    // Customizable Area End
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      getTaggedCompany:[],
      enteredCompany:'',
      selectedTaggedCompany:{},
      showPassword: false,
      showPassword2: false,
      showPassword3: false,
      preference:[],
      subscription: {},
      role: "",
      expanded:"false",
      email:"",
      first_name:"",
      tagged_with_company:"",
      last_name:"",
      password:"",
      location:"",
      about:"",
      company_name:"",
      designation:"",
      current_password:'',
      new_password:'',
      confirm_password:'',
      isLoading:false,
      opened:false,
      profile_pic:'',
      snackbarmsg:"",
      color:'',
      contain:'',
      file : "",
      question:[],
      formProfileData:{},

      termsFile: '',
      token: '',
      currentLocation: '',
      loading: false,

      numPages : null,
      pageNumber: 1,
      allFollowers: [],
      allViewer: [],
      anchorElForPopupFollower: null,
      anchorElForPopupNested:null,
      contactUs : [],
      corporate_company_name:''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.handleFileChange= this.handleFileChange.bind(this);
    this.fileUploaded=this.fileUploaded.bind(this);
    // Customizable Area End

  }

  onDocumentLoadSuccess({ numPages } : {numPages : number}) {
    this.setState({numPages});
  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

        if (apiRequestCallId != null) {
          if (responseJson && responseJson.errors ) {
            this.parseApiCatchErrorResponse(errorReponse);
          }

          switch (apiRequestCallId) {
           case this.updateAccountGetApiCallId :
            this.getProfileData(responseJson)
            break;
           case this.getTermsandConditions:
              this.setState({
                isLoading:false,
                contain:responseJson.contain,
                termsFile: responseJson.file
              })
            break;
            case this.getHelpandSupport:
              this.setState({   
                question:responseJson.data,
                isLoading:false,
              })
            break;
           case this.taggedCompanyData:
                this.setState({getTaggedCompany:responseJson?.data || []})
            break;
            case this.settingsChangePassword:
             this.getPasswordChange(responseJson)
            break;
            case this.updateAccountApiCallId:
             this.profileUpdate(responseJson)
            break;
            case this.getCurrentLocationCallId:
              if(responseJson.location) {
                this.setState({ currentLocation: responseJson.location, loading: false })
              }
              else {
                //Check Error Response
                this.setState({opened:true,snackbarmsg:JSON.stringify(responseJson.error),color:"#f73939"})
                toast.error(JSON.stringify(responseJson.error))
              }
            break;
            case this.updateProfilePic:
             this.profilePictureUpdate(responseJson)
            break;
            case this.getAllFollowersCallId: {
              this.getAllFollowersList(responseJson)
              break;
            }
            case this.getAllViewersCallId: {
              this.getAllViewers(responseJson)
              break;
            }
            case this.getContactUsDataCallId: {
              this.setState({contactUs: responseJson.data,isLoading:false})
            }
          }
        this.parseApiCatchErrorResponse(errorReponse);
        }
    }
    // Customizable Area End

  }

  // Customizable Area Start
  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  }

  formatRoleName = (str: any) => {
    let mySentence = str;
    if(mySentence){
      const words = mySentence.split(" ");

      for (let i = 0; i < words.length; i++) {
          words[i] = words[i][0]?.toUpperCase() + words[i]?.substr(1);
      }
      mySentence = words.join(" ");
    }
    return mySentence;
  }

  formatDate = (userdate: any) => {
    let blogDate = new Date(userdate); 
    if(blogDate){
      let date = blogDate.toLocaleDateString("en-us", { day: "numeric" }); 
      let month = blogDate.toLocaleDateString("en-us", { month: "long" }); 
      let year = blogDate.toLocaleDateString("en-us", { year: "numeric" }); 
      return `${date} ${(month).slice(0,3)}, ${year}`;
    }else{
      return blogDate;
    }
  }

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  }

  handleClickShowPassword=()=> {
   this.setState({ showPassword: !this.state.showPassword });
  };
  handleClickShowPassword2=()=> {
  this.setState({ showPassword2: !this.state.showPassword2 });
  };
  handleClickShowPassword3=()=> {
   this.setState({ showPassword3: !this.state.showPassword3 });
   };
  
  handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
   event.preventDefault();
  };
  Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }
  
  handleChange = (prop:any) => (event:any) => {
    this.setState({ ...this.state, [prop]: event.target.value });
    // console.log(this.state.email,"values")
  };
  
  handleChangePreference = (chip:any,setFieldValue:any) => {
    console.log("chip",chip.target.value)
    this.setState({
      preference: chip.target.value //shorthand way to copy an array and add a new element
    },()=>{setFieldValue("preference",this.state.preference)});
  };
  
  handleDelete = (chipToDelete: any,setFieldValue:any) => () => {
    console.log("chipToDelete",chipToDelete)
    this.setState({
      preference: this.state.preference.filter(c => c !== chipToDelete) //filter will produce a copy of the array
    },()=>{setFieldValue("preference",this.state.preference)});
  };

  handleChangeButton = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    this.setState({expanded:newExpanded ? panel : false})  
  };

  //   applyFormSchema = yup.object({
  //   about: yup.string().min(1, "Must have a character")
  //   .max(10, "Must be shorter than 550")
  //   .required(),
  //   first_name: yup.string().required(),
  //   last_name: yup.string().required(),
  //   email_id: yup.string().email("Must be a valid email address")
  //   .required("Must enter an email"),
  //   company_name: yup.string().required(),
  //   designation : yup.string().required(),
  //   preference : yup.string().required(),

  //   // phone_number: yup.string().required().matches(this.phoneRegExp, 'Phone number is not valid'),
  //   location: yup.string().required(),
  //   // comment: yup.string().required(),
  // })

  handleUpdateInfo=async(e:any)=>{
    this.setState({ isLoading: true })
    const authToken = await getStorageData("authToken");
    const header = {
      "Content-Type": configJSON.updateApiContentType,
      token: authToken,
    };
    console.log(header," settingsToken")
    console.log(e.preference)
    const preferenceData = this.state.preference.map((item: any, id: any)=> {
     return  item === "Environment" ? 1 : item === "Social" ? 2 : item === "Governance" && 3
    })

    const attrs = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      email: this.state.email,
      location:this.state.currentLocation || this.state.location,
      preference_ids:preferenceData,
      preference: this.state.preference.join(','),
      about:this.state.about,
      designation:this.state.designation,
      company_name: this.state.company_name
      // profile_pic:this.state.image
    };
    const data = {
      attributes: attrs
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateAPiEndPointWeb
    );
    console.log( configJSON.updateAPiEndPointWeb)

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    console.log(JSON.stringify(httpBody));

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    // return true;
  }
  getDetails = async() => {
    const authToken = await getStorageData("authToken");
  this.setState({isLoading:true});
    const header = {
      "Content-Type": configJSON.updateApiContentType,
      token: authToken
    };
    console.log(header," settingsToken")

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateAPiGetDetailsEndPointWeb
    );
    console.log( configJSON.updateAPiGetDetailsEndPointWeb)

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateAPiGetMethod
    );
    this.updateAccountGetApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getTermsAndConditions=async()=>{
    const authToken = await getStorageData("authToken");
    this.setState({isLoading:true});
      const header = {
        "Content-Type": configJSON.updateApiContentType,
        token:authToken
      };
      console.log(header," settingsToken")
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getTermsandConditions = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getTermsAndConditions
      );
      console.log( configJSON.getTermsAndConditions)
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.updateAPiGetMethod
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
    getHelpAndSupport=async()=>{
      const authToken = await getStorageData("authToken");
      this.setState({isLoading:true});
        const header = {
          "Content-Type": configJSON.updateApiContentType,
          token: authToken,
        };
        console.log(header," settingsToken")
    
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getHelpandSupport = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.getHelpAndSupport
        );
        console.log( configJSON.getTermsAndConditions)
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.updateAPiGetMethod
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
      }
  
  handleChangeCopy=(e:any)=>{
    e.preventDefault();
  }
  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }
    
  handleChangePassword=async(e:any)=>{
    this.setState({isLoading:true})
    // console.log(this.state.current_password);
    // console.log(this.state.new_password);
    // console.log(this.state.confirm_password);
    const token = await getStorageData("authToken");
   

    // // if (!(RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/)).test(this.state.new_password)) {
    // //   this.setState({isLoading:false});
    // //   this.setState({opened:true,snackbarmsg: configJSON.passwordError,color:"#f73939"})
    // //   // this.showAlert(configJSON.errorTitle, configJSON.passwordError);
    // //   return false;
    // // }
    
    // // if (!(RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/)).test(this.state.confirm_password)) {
    // //   this.setState({isLoading:false});
    // //   this.setState({opened:true,snackbarmsg: configJSON.passwordError,color:"#f73939"})
    // //   // this.showAlert(configJSON.errorTitle, configJSON.passwordError);
    // //   return false;
    // // }

    // // if(this.state.new_password !==this.state.confirm_password){
    // //   this.setState({isLoading:false});
    // //   this.setState({opened:true,snackbarmsg: "Passwords are not same",color:"#f73939"})
    // // }
    // // else{
    //   // this.setState({isLoading:false});
    // // console.log("response")
    const header = {
        "Content-Type": configJSON.updateApiContentType,
        token:token
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      //GO TO REQUEST STATE
      this.settingsChangePassword = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.updateChangePasswordEndPointWeb
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      const data = {
        current_password: e.current_password,
        new_password: e.new_password,
        confirm_password:e.confirm_password,
      };
  
      const httpBody = {
        data: data
      };
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.updateAPiMethod
      );
     
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      
      runEngine.sendMessage(requestMessage.id, requestMessage);
      
    //  return true;
    e.current_password = ""
    e.new_password = ""
    e.confirm_password = ""


    this.setState({isLoading:false})
  
    return e;
    }
  // }


  handleLoginPage = async()=>{
    await removeStorageData("authToken");
    await removeStorageData("profile");
    this.props.navigation.navigate('Home');
  }

  handleClose = () => {
    this.setState({opened:false})
  };
  handleOpen = () => {
    this.setState({opened:true})
  };

  handleCompany=(newValue:any) => {
    this.setState({ company_name: newValue?.attributes?.company_name || ''})
    // this.setState({ getTaggedCompany: [] })
  }

  handleFileChange = (event: any) => {
    console.log(event.target.files[0],'test1')
    if(event?.target?.files){
      this.setState({
        profile_pic: URL.createObjectURL(event.target.files[0]),
      
        
        formProfileData: event?.target?.files[0]
      },()=>{
        this.fileUploaded(this.state.formProfileData);
        
        this.context.set({profile_pic: this.state.profile_pic})
      });
    }
  }
  fileUploaded=async(profile_pic:any)=>{
    console.log("asasasaaaa", profile_pic);
    const authToken = await getStorageData("authToken");
    const formData = new FormData();
    formData.append("image", profile_pic);
        const header = {
          token: authToken
        };
      

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    this.updateProfilePic = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateProfilePicWeb
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PUT'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  
}

getCurrentLocationCallId: string = ""

  getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords
        const query = new URLSearchParams({
            'data[attributes][latitude]': String(latitude), 
            'data[attributes][longitude]': String(longitude)
          })
        // this.setState({location: {latitude, longitude}})
        this.setState({ loading: true })
        this.getCurrentLocationCallId = sendAPIRequest(
            `${configJSON.getPlaceNameEndpoint}?${query}`,
            {
              method: 'GET', 
              headers: {},
            })

      });
    } else {
      toast.warn("GeoLocation not supported in your browser.")
    }
  } 

  setCompanyValue = (company:any) => {
    const companyName= !company ? '' : company
    this.setState({company_name:companyName})

  }

  searchTaggedCompany=async(company:any)=>{
    const token= await getStorageData("authToken") || ''
    this.taggedCompanyData=sendAPIRequest(
      `${configJSON.taggedCompany}?search_company_name=${company}`,
      {
        method: 'GET', 
        headers: { 'token': token, 'content-type': 'appliction/json' },
      })

  }

  profileUpdate = (responseJson:any) => {
    if (!responseJson.errors) {
      if(responseJson.data.attributes){
         this.context.set({ ...responseJson.data.attributes })
         this.syncStateFromAPI(responseJson)
       }
       toast.success("Profile successfully updated")
     } else {
       //Check Error Response
      this.setState({isLoading:false})
       toast.error(JSON.stringify(responseJson.errors))
     }
  }

  getProfileData=(responseJson:any)=>{
    if(responseJson && !responseJson.errors){
      this.syncStateFromAPI(responseJson)
      this.setState({company_name:responseJson.data.attributes?.company_name})
      this.context.profile_pic = responseJson.data.attributes?.profile_pic
      const data={id: responseJson.data.id, ...responseJson.data.attributes}
      this.context.set({...data})
    }
  }

  getPasswordChange = (responseJson:any) => {
    if (!responseJson.errors) {
      this.setState({
        isLoading:false, current_password:"", new_password:"", confirm_password:""
      })
      console.log(responseJson,"response");
      
      toast.success("Password Changed Successfully")
      setTimeout(() => {
        this.handleLoginPage()
      }, 3000);
    } else {
      //Check Error Response
      this.setState({isLoading:false})
      toast.error("Invalid Current Password") 
    }
  }

  profilePictureUpdate = (responseJson:any) => {
    if (!responseJson.error) {
      this.setState({ isLoading: false })
      toast.success("Profile pic successfully updated")
      this.context.set({ profile_pic: responseJson.data.attributes.profile_pic })
     }
     else {
      toast.error(JSON.stringify(responseJson.error))
     }
  }

  syncStateFromAPI = (responseJson: any) => {
    this.setState({
      enteredCompany:responseJson.data.attributes.company_name,
      first_name:responseJson.data.attributes.first_name,
      last_name:responseJson.data.attributes.last_name,
      email:responseJson.data.attributes.email,
      preference:responseJson.data.attributes.preference,
      location:responseJson.data.attributes.location,
      profile_pic:responseJson.data.attributes.profile_pic,
      designation:responseJson.data.attributes.designation,
      tagged_with_company:responseJson.data.attributes.tagged_with_company,
      company_name:responseJson.data.attributes.company_name,
      about:responseJson.data.attributes.about,
      subscription: responseJson.data.attributes.subscription,
      role: responseJson.data.attributes.role,
       total_followers: responseJson.data.attributes.total_followers,
       total_post: responseJson.data.attributes.total_post,
       total_views: responseJson.data.attributes.total_views,
       corporate_company_name:responseJson.data.attributes.corporate_company_name
    })
    this.searchTaggedCompany('')
     this.setState({isLoading:false})
  }

  openFollowerList = async(e:any) => { 
    const authToken = await getStorageData("authToken");
    if(this.context?.total_followers){
      this.setState({...this.state, anchorElForPopupFollower: e.currentTarget,anchorElForPopupNested:null, loading:true}) 
      this.getAllFollowersCallId = sendAPIRequest(configJSON.allFollowers, { method: 'GET', headers: {token: authToken} })
    } 
  }

  getAllFollowersList = (responseJson:any) => {
    this.setState({allFollowers: responseJson.data, loading: false})
  }
  
  handleCloseConnection = (follower:boolean) => {
    if(follower){
      this.setState({anchorElForPopupFollower:null})
    }else{
      this.setState({anchorElForPopupNested:null})
    }
  }
  
  openViewsMenu = async(e:any) => {
    const authToken = await getStorageData("authToken");
    if(this.context?.total_views){
      this.setState({...this.state, anchorElForPopupNested: e.currentTarget, anchorElForPopupFollower:null, loading:true})
      this.getAllViewersCallId = sendAPIRequest(configJSON.allViewers, {
        method: 'GET',
        headers: {token: authToken}
      })
    }
  }
  
  getAllViewers = (responseJson:any) => {
    this.setState({allViewer: responseJson.data, loading:false})
  }
  getContactUsData = async() => {
    const token = await getStorageData("authToken") || '';
    this.getContactUsDataCallId = sendAPIRequest(`${configJSON.getContactUs}`, {
      method: 'GET',
      headers: { token }
    })
  }
  // Customizable Area End
  
}

import React from "react";

// Customizable Area Start
import {
  Button,
  Divider,
  InputAdornment,
  MenuItem,
  Paper,
  Tabs,
  Tab,
  ListItemText,
  Avatar,
  Typography,
  CircularProgress,
  Checkbox,
  TableContainer, Table, TableHead, TableRow, TableCell, TableBody,
} from '@material-ui/core'
import { styled } from '@material-ui/styles'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ClearIcon from '@material-ui/icons/Clear';
import { Field, FieldArray, Form, Formik, useField, useFormikContext, getIn } from 'formik'
import FormModal, { InputRow, MInput, MSelect, MFileDrop, MCountedTextArea, MCheckboxes } from '../../../components/src/FormModal.web'
import UserContext from "../../../components/src/UserProvider";
import Flex from '../../../components/src/Flex.web'
import TransferModalController, { Props } from "./TransferModalController";
import SearchSelect from "../../../components/src/SearchSelect.web";
import { DangerIcon, DownloadIcon, ExcelIcon, iconCorp } from "./assets";
import { getName } from "../../../framework/src/Messages/MessageEnum";
import { ErrorMessageProps } from "./types";
//@ts-ignore
import classes from "./ServiceWeb.module.css"
// Customizable Area End
export default class CreateTransfer extends TransferModalController {
  selectedItem: any;
  getuserDetails: any;
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  CreateTransferModal() {
    const user = this.context
    const wallet = this.state.corpstageWallet
    const transferData = this.state.transferData
    const cmmnt=transferData.map((item:any)=>item?.comment)
    const data = this.state.importedUsers
    let initialValuesData: any = {
      beneficiary_ids: (data && data.length > 0) ? data.map((item)=>{item.coins="";return item;}) : 
      transferData?.length>0 ? transferData.map((i:any)=>({coins:i.no_of_coins,email:i.email })):
      (this.state?.postUser && this.state?.postUser?.length>0) ? this.state?.postUser?.map((e:any)=>({email:"abc@gmail.com", coins:0})):
      [],
      comments: cmmnt[0] || "",
    }
    return <Formik enableReinitialize initialValues={initialValuesData} onSubmit={this.handleTransferSubmit} validationSchema={this.validationSchema}
    >
      {({ values, errors }: any) => (
        <ModalWrap>
        <FormModal isDirty={this.state.enableBtn} modalStyle={{ height: window.screen.width < 768 ? "380px":'620px' , marginRight:'0px', margin :'20px' }} headerTitle={<b>TRANSFER TO</b>} submitLabel="transfer" onClose={this.hideModal} onSubmitDisable={this.state.disableBtn} >
          <Flex col gap='20px'>
            <>
              <InputRow label={<FlexR><div>Beneficiary*</div><ImportBtn>
                <ImportCSVButton onImport={this.importCsv} loading={this.state.importLoading} />
                <DownloadLink href="/user emails.csv"><DownloadSampleButton /></DownloadLink>
              </ImportBtn></FlexR>} input={<SearchSelect
                pagination={this.state.moderatorPagination}
                hasMore={!!this.state.moderatorPagination?.next_page}
                nextPage={this.paginateModerators}
                loading={this.state.moderatorSearchCallId !== ""}
                onSearch={this.handleModeratorSearch}
                allItems={this.state.moderatorOptions}
                selectProps={{ onClose: this.handleModeratorClose }}
                items={this.state.moderatorOptions}
                name="beneficiary_ids"
                SelectItem={SelectItemUser}
                getOptionValue={(user: any) => `${user.first_name} ${user.last_name}`}
                // onSelectInput={this.onSelectInput}
                from="coinTransfer"
              />
              }
              />
            </>
            <InputRow label="" input={
              <FieldArray
                name="beneficiary_ids"
                render={(arrayHelpers:any) => {
                  return (
                    <>
                      <TableContainer style={{ border: '1px solid rgb(224, 224, 224)', borderRadius: '12.97px' }}>
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TH>SL. No.</TH>
                              <TH>Beneficiary Email</TH>
                              <TH>No. of Coins</TH>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {console.log(values, errors, "values data")}
                            {values.beneficiary_ids && values.beneficiary_ids?.length > 0 ? (
                              values.beneficiary_ids?.map((us: any, index: any) => {
                                return <TableRow>
                                  <TR><Flex justify="center">{index + 1}</Flex></TR>
                                  <TR>
                                    <Flex align="center">
                                      <Avatar alt="Remy Sharp" src={us.profile_pic} style={{ width: "30px", height: "30px", marginRight: "10px" }} />
                                      {us.email}
                                    </Flex>
                                  </TR>
                                  <TR>
                                    <div key={index}>
                                      <Flex gap='10px' align="center" justify="center">
                                        <Field
                                          name={`beneficiary_ids[${index}].coins`}
                                          render={({ field, form: { touched, errors } }: any) => {
                                            return (
                                              <>
                                                <Flex col>
                                                  <InputRow label="" rowClass={classes.setWidth} input={
                                                    <MInput name={`beneficiary_ids[${index}].coins`} placeholder="Enter no. of coins" onBlur={(e:any)=>this.handleBlur(Number(e.target.value), index)}/>
                                                  } />
                                                  <Typography style={{ color: 'red', fontSize: '15px' }}>
                                                    {this.errMssg(index, us) && <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'flex-start', marginTop: '10px', textAlign: 'left' }}>
                                                      <img width="28px" src={DangerIcon}
                                                        style={{
                                                          // objectFit: 'contain',
                                                          marginRight: '6px',
                                                          marginTop: '3px'
                                                        }}
                                                      />
                                                      <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        {this.errMssg(index, us)}
                                                      </div>
                                                    </div>}
                                                  </Typography>
                                                </Flex>
                                                {/* <ErrorMessage name={`beneficiaries.[${index}].coins`} /> */}
                                              </>
                                            )
                                          }}
                                        />
                                        {/* <ClearIcon onClick={() => this.handleRemoveField(arrayHelpers,us,index)}  style={{ color: "#D0D0D0" }} /> */}
                                        <ClearIcon onClick={() => this.handleRemoveField(arrayHelpers,us, index)} style={{ color: "#D0D0D0" }} />
                                      </Flex>
                                    </div>
                                  </TR>
                                </TableRow>
                              })
                            ) : (
                              <TableRow>
                                <TR colSpan={3}>
                                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <img width="28px" src={iconCorp} style={{
                                      color: "grey", objectFit: 'contain',
                                    }} />
                                  </div>
                                  <Typography align="center">No Beneficiary is Selected</Typography></TR>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Flex justify="end" style={{ padding: '5px' }}>
                        <Typography>Total Coins : <b>{this.state.totalCoins}</b></Typography>
                      </Flex>
                      <InputRow label="Comments*" input={
                        <MCountedTextArea name="comments" placeholder="" />
                      } />
                    </>
                  )
                }
                }
              />
            } />
          </Flex>
        </FormModal>
        </ModalWrap>
      )}
    </Formik>
  }

  deficientCoinsModal() {
    const { navigation } = this.props
    const wallet = this.state.corpstageWallet
   { console.log(this.state.corpstageWallet, "this.state.corpstageWalletthis.state.corpstageWallet")}

    return <Formik initialValues={{}} onSubmit={this.handlePurchaseCoins} navigation={() => navigation.navigate('/PurchaseCoins')}>
      <FormModal isDirty={true} modalStyle={{ height: '460px' }} headerTitle={<b>Deficient Coins</b>} submitLabel="purchase" cancelLabel="go back" onClose={this.hideDeficientModal}>
        <Flex col px='10px' gap="10px" align="start" justify="start">
          <Flex col>
            <Typo variant="h6">(A) Number of coins to be transferred</Typo>
            <TC>{this.state.totalSelectedCoins}</TC>
          </Flex>
          <Flex col>
            <Typo variant="h6">(B) Number of coins in account</Typo>
            <TC>{wallet?.total_coin}</TC>
          </Flex>
          <Flex col>
            <Typo variant="h6">(A-B) Balance coins to be purchased</Typo>
            <TC style={{ color: 'red' }}>{this.state.totalSelectedCoins - wallet?.total_coin}</TC>
          </Flex>
        </Flex>
      </FormModal>
    </Formik>
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        {this.state.modal === 'deficient' ?
          this.deficientCoinsModal() :
          this.CreateTransferModal()
        }
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

TransferModalController.contextType = UserContext

const ErrorMessage = ({ name }: ErrorMessageProps) => (
  <Field
    name={name}
    render={({ form }: any) => {
      const error = getIn(form.errors, name);
      const touch = getIn(form.touched, name);
      return touch && error ? error : null;
    }}
  />
);

const B = styled('b')({
  // '@media screen and (max-width:550px)':{
  //   fontSize:'1rem',
  // }
})

const TH = styled(TableCell)({
  color: "#000",
  fontWeight: 600,
  lineHeight: "1.5rem",
  padding: '16px 10px',
  whiteSpace:'nowrap',
})

const TR = styled(TableCell)({
  lineHeight: "1.5rem",
  padding: '16px 10px',
  //   '& MuiSvgIcon-root' :{
  //     color: 'red',
  // }
})

const FlexR = styled('div')({
  display:'flex',
  justifyContent:'space-between',
  alignItems:'center',
  '@media screen and (max-width: 500px)':{
    flexDirection:'column-reverse',
    alignItems:'start',
 },
})


const ImportCSVButton = (props: any) => {
  const ref = React.useRef<HTMLInputElement | null>(null)
  const onClick = () => ref?.current && ref.current?.click?.()
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => props.onImport && props.onImport(e.target.files)
  return <>
    <ImportButtonBase loading={props.loading} downloadIcon onClick={onClick} {...props} />
    {/* <ImportButtonBase /> */}
    <input id="csvID" className="userFile" onChange={handleChange} name="csv_file" type="file" accept=".csv" style={{ display: 'none' }} ref={ref} />
  </>
}

const ImportButtonBase = (props: any) => {
  const icon = props.downloadIcon ? DownloadIcon : ExcelIcon
  const clickHandler = props.onClick || (() => { });
  const label = !props.downloadIcon ? "Download Sample" : "Import from CSV"
  return <ImportButton onClick={clickHandler}>
    {!props.loading && <img src={icon} width="25px" />}{!props.loading && label}
    {props.loading && <CircularProgress size={18} />}{props.loading && "Loading..."}
  </ImportButton>
}

const ImportButton = styled('div')({
  cursor: 'pointer',
  backgroundColor: '#ececec',
  color: '#7A7A7A',
  // border: '1px solid #aaa',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  padding: '5px 12px',
  borderRadius: '6px',
  fontSize: '13px',
})

const ImportBtn = styled('div')({
  gap:'5px',
  display:'flex',
  '@media screen and (max-width:500px)':{
    width:'100%',
    justifyContent:'center',
    marginBottom:'15px',
  }
})

const DownloadLink = styled('a')({
  textDecoration: 'none'
})

const DownloadSampleButton = () => {
  return <ImportButtonBase />
}

const SelectItemUser = ({ item, value }: { item: any, value: any[] }) => {
  console.log(item, value, "value", "datatat")
  return <div style={{ width: '100%', display: 'flex', flexDirection: 'row-reverse', alignItems: 'center' }}
  >
    <Checkbox
      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
      checkedIcon={<CheckBoxIcon fontSize="small" style={{ color: "#1ea60a" }} />}
      checked={value?.findIndex(x => x?.id === item?.id) > -1}
    />
    <Typography component={'div'} style={{ flex: 3, overflow: 'hidden', textOverflow: 'ellipsis' }}>
      {item.email}
    </Typography>
    <ListItemText style={{ flex: 2 }} user-id={item.id} disableTypography primary={
      <div style={{ display: "flex", alignItems: "center" }}>
        <Avatar alt="Remy Sharp" src={item.profile_pic} style={{ width: "30px", height: "30px", marginRight: "10px" }} />
        <Typography style={{ color: "#333232", fontWeight: "bold", fontSize: 12, width: '35ch', overflow: 'hidden', textOverflow: 'ellipsis' }}
          user-id={item.id}>
          {item.first_name} {item.last_name}
        </Typography>
      </div>
    } />
  </div>
}

const TC = styled('div')({
  fontSize: '1.5rem',
  fontFamily: 'Helvetica',
  fontWeight: 700,
  lineHeight: '35px',
  textAlign: 'initial',
})

const Typo = styled(Typography)({
  fontSize: '1rem',
  lineHeight: '3.5',
  color: 'grey'
})

const ModalWrap = styled('div')({
  '@media screen and (max-width:768px)':{
    '& > div ':{
      alignItems:'center'
    }
  }
})


// Customizable Area End
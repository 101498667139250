import React from "react";

// Customizable Area Start
import { icThumbsUp, icThumbsUpGreen } from './assets'
import { styled } from '@material-ui/styles'
import { Button } from '@material-ui/core'
// Customizable Area End

import LikeAPostController, {
  Props,
  configJSON
} from "./LikeAPostController";

export default class LikeAPost extends LikeAPostController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { isLiked } = this.state
    // Customizable Area End
    return (
      // Customizable Area Start
      <CardButton data-test-id='onLikeClick' isLiked={isLiked} onClick={this.handleLikeClick} startIcon={<BtnImg width={isLiked?'28px':'20px'}  src={isLiked?icThumbsUpGreen:icThumbsUp} />}> 
        Inspire
      </CardButton>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const CardButton = styled(Button)((p: any) => {
  return {
    color: p.isLiked ? p.theme?.palette?.primary?.main:'#aaaaaa',
    fontWeight: 600,
    textTransform: 'none'
  
  }
})


const BtnImg = styled('img')({
  width: 25
})
// Customizable Area End

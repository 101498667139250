import React from 'react'
import { styled } from '@material-ui/styles'
const starIcon = require('./ic_rating_star.png')

export interface RatingProps {
	total?: number;
	onChange?: (x: number) => void;
	value?: number;
	disabled?: boolean;
}

export default function Rating( props : RatingProps){
	const {total=5, onChange} = props 
	const icon = <ImgIcon src={starIcon}  />
	const emptyIcon = <ImgIcon src={starIcon} style={{opacity: 0.6}} />
	const value = props.value || 0 
	const handleClick=(e: React.MouseEvent<HTMLDivElement>) => {
		onChange && onChange(Number(e.currentTarget.dataset.value) || 1)
	}
	const IC = props.disabled?IconWrapperDisabled:IconWrapper
	return <RatingWrapper>
		{Array(total).fill(0).map((_, idx)=> <IC key={idx} data-value={idx+1} onClick={handleClick}>{idx+1<=value?icon:emptyIcon}</IC>)}
	</RatingWrapper>
} 

const RatingWrapper = styled('div')({
	display: 'flex',
	gap: '5px'
})



const IconWrapperDisabled = styled('div')({
	width: '32px',
	transition: 'all 0.3s',
})

const IconWrapper = styled(IconWrapperDisabled)((props:any) => ({
	cursor: 'pointer',
	'&:hover': {
		transform: 'scale(1.2)'
	}
}))

const ImgIcon = styled('img')({
	width: '100%',
	height: 'auto'
})
import React from "react";
import {
  Typography,
  Box,
  TextField,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import DashboardLayout from "../../../components/src/DashboardLayout.web";
import ArrowForwardOutlined from '@material-ui/icons/ArrowForwardOutlined';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import ReportingFrameworksController, { Props } from "./ReportingFrameworksController";
import SearchFilter from '../../../components/src/Search.web';

export default class ReportingFrameworks extends ReportingFrameworksController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  render() {
    const { navigation } = this.props;
    const path = this.getBackPath()
    
    return (
      // Customizable Area Start
      <DashboardLayout navigation={navigation} >
        <MsWrapper>
          <MsHeadingWrapper>
            <MsPageTitle onClick={() => this.navigationMessage(path,this.props)} data-testid='navigateBack'>
              <ArrowBackIosIcon />
            </MsPageTitle>
            <CoinsHead>Reporting Frameworks</CoinsHead>
          </MsHeadingWrapper>
          <FlexSpaceBetween style={{ gap: 6 }}>
            <SearchWraper>
              <SearchFilter
                onChange={this.onFilterReportingCards}
              />
            </SearchWraper>
          </FlexSpaceBetween>
          <MsCardWrapper>
            {this.state.reportingCardsData && this.state.reportingCardsData.length ?  this.state.reportingCardsData.map((row) => (
              <SurveyBox key={row.title} onClick={async() => {
                this.setData(row.title, row.id)
                this.props.navigation.history.push(row.route, this.props.navigation.history.location.state)
              }}>
                <div>
                  <MsCardContent>
                    <HedingText>{row.title}</HedingText>
                    <LogoDiv>
                      <img style={{ width: 80 }} src={row.icon} alt={row.title} />
                    </LogoDiv>
                  </MsCardContent>
                  {
                    row.description.map((description:string) => (
                      <Typography key={description} style={{ marginTop: '10px' }}>{description}</Typography>
                    ))
                  }
                </div>
                <MsCardArrowWrapper>
                  <ArrowForwardOutlined color="disabled" />
                </MsCardArrowWrapper>
              </SurveyBox>

            )):
            <Nodata>
             {this.state.noData}
            </Nodata>
          }
          </MsCardWrapper>
        </MsWrapper>
      </DashboardLayout>
      // Customizable Area End
    );
}
  
}

// Customizable Area Start
const HedingText = styled(Typography)({
  fontWeight: 700,
  fontSize: "20px", 
  overflow: 'hidden', 
  textOverflow: 'ellipsis', 
  display: 'block', 
  textTransform: 'capitalize', 
  whiteSpace: 'pre-line',
  lineHeight: '20px',
})

const Nodata = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
})

const SurveyBox = styled(Box)({
  width: 'calc(50% - 73px)',
  backgroundColor: "white",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  borderRadius: "15px",
  padding: "30px 30px 20px 30px",
  filter: "drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.11))",
  cursor: "pointer"
})

const MsWrapper = styled("div")({
  display: 'flex', 
  flexWrap: 'wrap',
  flexDirection:'column', 
  padding: '0px 40px'
});

const LogoDiv = styled('div')({
  display:"flex",
  justifyContent: "center",
  '& img':{
      objectFit: 'cover'
  }
})

const CoinsHead = styled("div")({
  margin: "40px 0px 25px 0px",
  fontSize: "1.4rem",
  fontWeight: "bolder",
  textTransform: "capitalize",
  "@media screen and (max-width: 768px)": {
    margin: "20px 0px 10px 12px",
  },
});

const MsHeadingWrapper = styled("div")({
  display: "flex", 
  alignItems: "center"
});

const MsCardWrapper = styled("div")({
  display:'flex', 
  gap:'25px', 
  flexWrap:'wrap'
});

const MsCardArrowWrapper = styled("div")({
  display: 'flex', 
  justifyContent: 'end'
});

const TextFieldRounded = styled(TextField)({
  width: '100%',
  background: '#FFFFFF',
  borderRadius: '15px',
  '& > .MuiOutlinedInput-root': {
      borderRadius: '15px',
  },
  '& > div > input': {
      paddingTop: '14px',
      paddingBottom: '14px'
  }
})

const FlexSpaceBetween = styled('div')({
  padding: '10px 0 27px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  // paddingBottom: '0',
  '@media screen and (max-width:600px)': {
      flexDirection: 'column',
      alignItems: 'normal',
  },
  '@media screen and (min-width:769px) and (max-width:1200px)': {
      flexDirection: 'column',
      alignItems: 'normal',
  }
})

const MsPageTitle = styled("span")({
  margin: "30px 5px 15px 12px", 
  cursor: "pointer",
  display: 'flex',
  alignItems: "center",
  gap: "15px",
});


const MsCardContent = styled("div")({
  display: 'flex', 
  justifyContent: 'space-between', 
  alignItems: "center",
});

const SearchWraper = styled("div")({
  width: '50%'
});
// Customizable Area End
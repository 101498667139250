import React, { Component } from "react";
// Customizable Area Start
import DashboardLayout from "../../../components/src/DashboardLayout.web";
import {
  Card,
  Typography,
  Button,
  CardHeader, 
  CardContent,
  Box,
  ButtonBase,
  Grid,
} from "@material-ui/core";
import { withStyles, styled } from "@material-ui/styles";
import SubscribtionHistory from "./SubscribtionHistory.web";
import CustomisableusersubscriptionsController, {
  Props,
  configJSON,
} from "./CustomisableusersubscriptionsController";
import SubscribtionPlan from "./SubscribtionPlan.web";
// Customizable Area End

export default class Customisableusersubscriptions extends CustomisableusersubscriptionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { navigation } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <DashboardLayout navigation={navigation} token={this.state.token}>
        <PageWrapper>
          <CenterRow> 
          <Grid container justifyContent="center">
          <Grid item >
                  <RoundedButtonBase>
                    
                     
                      <PlanCardButton
                  onClick={this.chooseSubscribtionPlan}
                  style={this.state.activeChooseButton ? { backgroundColor: "#00A100",color:"white" } : { backgroundColor: "white" }}
                  >SUBSCRIPTION PLAN</PlanCardButton>
                  <HistoryCardButton
                  onClick={this.chooseSubscribtionHistory}
                  style={this.state.activeSubscribtionHistoryButton ? { backgroundColor: "#00A100" ,color:"white" } : { backgroundColor: "white" }}
                  >SUBSCRIPTION HISTORY</HistoryCardButton>

               
                

                  </RoundedButtonBase>
                  </Grid>

</Grid>
           
        

           
            {
              this.state.subscribtionPlan?
                <SubscribtionPlan initCall="plan" navigation={undefined} id={""} route={undefined} data={undefined}/>
                  :
                <SubscribtionHistory initCall="history" navigation={undefined} id={""} route={undefined} data={undefined}/>
            }
            
           
          </CenterRow>
        </PageWrapper>
      </DashboardLayout>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
//*********** styles ***************//
const CardButtonPackage=styled(Button)({
  width:"154px",
  height:"38px",
  backgroundColor: "#009B07",
  color: "#FFFFFF",
  '&:hover': {
    background:'#009B07',
    opacity: 0.9,
    color:"FFFFFF",
    borderRadius:"009B07"
  }
})
const PlanCardButton = styled(Button)({
  border: "2px solid transparant",
  color: "#009B07",
  fontWeight: 600,
  backgroundColor: "#FFFFFF",
  textTransform: "none",
  borderRadius: "38px",
  height: "100%",
  padding: "0 65px 0 65px",
  '@media screen and (max-width: 1110px) and (min-width: 769px)': {
    padding: "0 35px 0 35px",
  },
  '@media screen and (max-width: 624px)': {
    padding: "0 35px 0 35px",
  },
  '@media screen and (max-width: 500px)': {
    padding: "0 25px 0 25px",
  }
});
const HistoryCardButton = styled(Button)({
  border: "2px solid transparant",
  color: "#009B07",
  fontWeight: 600,
  backgroundColor: "#FFFFFF",
  textTransform: "none",
  borderRadius: "38px",
  height: "100%",
  padding: "0 50px 0 50px",
  '@media screen and (max-width: 1110px) and (min-width: 769px)': {
    padding: "0 30px 0 30px",
  },
  '@media screen and (max-width: 624px)': {
    padding: "0 30px 0 30px",
  },
  '@media screen and (max-width: 500px)': {
    padding: "0 20px 0 20px",
  }
});
const CardButtonLicense = styled(Button)(({theme}) => ({ 
  // maxWidth: '60vw',
  border: "none",
  color: "#515C51",
  fontWeight: 600,
  backgroundColor: "#C9C9C9",
  textTransform: "none",
  borderRadius: "10px",
  width: "472px",
  height: "56px",
  '&:hover': {
    background: '#C9C9C9',
    opacity: 0.9,
    color:"#515C51",
    borderRadius:"009B07"
  },
 
}))


const PageWrapper = styled("div")({
  display: 'flex',
  justifyContent: 'center',
  minHeight: '100vh'
});
const CenterRow = styled("div")((props: any) => ({
  // maxWidth: '60vw',
  width: '100%',
  marginTop: '60px'
 
  
 
}));

const RoundedButtonBase = styled('div')((props: any) => ({
  
  // maxWidth:"490px",
  height:"47px",
  // padding: '10px 0',
  fontWeight: 700,
  display: 'flex',
  textAlign: 'center',
  justifyContent: 'center', 
  backgroundColor:"#FFFFFF",
  flex:"wrap",
  color:"#009E00",
  borderRadius:'38px',
  border:" 2px solid #009B07"
  
  
}))


const StyledButton = withStyles((theme) => ({
  root: {
        '& .MuiButtonBase-root': {
          background: '#009B07',
        },
        '&:focus': {
              background:'#009B07',
              opacity: 0.9,
              color:"#FFFFFF",
              borderRadius:"009B07"
            }
            
    },
}))((props: any) => <Button {...props} />)

// Customizable Area End


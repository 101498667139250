import React from "react";
// Customizable Area Start
import DashboardLayout from "../../../components/src/DashboardLayout.web";
import { styled } from "@material-ui/styles";
import {
    Avatar,
    Typography,
    Box,
    Grid,
    CardHeader
} from "@material-ui/core";
import {
    Group2, Group5, Group1, Group3, Group4, Group6, Group7, Group8, Group9, Group10, Group11
} from './assets'
import PersonIcon from '@material-ui/icons/Person';
import StakeholderProfileFormController, { Props, configJSON }  from "./StakeholderProfileFormController";
import ArrowForwardOutlined from '@material-ui/icons/ArrowForwardOutlined';
import OverlayLoader from '../../../components/src/Loader.web';
import { setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End


export default class ESGJourneyMenuCard extends StakeholderProfileFormController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
      }
    
      // Customizable Area Start
      // Customizable Area End
    render() {
        // Customizable Area Start
        const { navigation } = this.props

        const rows = [
            this.createDataMenu('Step 1', 'Corporate Profile Form', 'CorporateProfileForm', Group1, 'Corporate Profile Form','1'),
            this.createDataMenu('Step 2', '1) Stakeholder Profile Form\n2) Corporate Governance', 'StakeHolderProfile', Group2, 'Stakeholder Corporate Profile','2'),
            this.createDataMenu('Step 3', 'Self Assessments', 'ESGJourneyReportingFrameworks', Group6, 'Self Assessments','3'),
            this.createDataMenu('Step 4', 'Self Assessment Dashboard', 'AssesmentDashboard', Group3, 'Self Assessment Dashboard','4'),
            this.createDataMenu('Step 5', 'Risk and Opportunity Management', 'RiskManagement', Group4, 'Risk and Opportunity Management','5'),
            this.createDataMenu('Step 6', 'Initiative Evaluation', 'InitiativeEvaluation', Group7, 'Initiative Evaluation','6'),
            this.createDataMenu('Step 7', 'Supplier Due Diligence Form. Assessment Survey-Scoring', 'DueDiligence', Group8, 'Supplier Due Diligence Form','7'),
            this.createDataMenu('Step 8', 'Carbon Emission Calculator - GHG Tool', 'CarbonCalculatorCard', Group9, 'Carbon Emission Calculator - GHG Tool','8'),
            this.createDataMenu('Step 9', 'Reports', 'UpdatedReports', Group10, 'Reports','9'),
            this.createDataMenu('Step 10', 'Reference Policies', 'ReferencePolicy', Group11, 'Reference Policies','10'),
        ]
        // Customizable Area End
        return (
            // Customizable Area Start
            <DashboardLayout isDashboard={true} isUserSurvey={false} navigation={navigation} >
                <OverlayLoader loading={this.state.loading} />
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', marginTop: '30px', padding: '0px 40px' }}>
                    <Grid spacing={4} container >
                    <DashboardHead>ESG Journey - {this.state.level}</DashboardHead>
                        {rows.map((row, index) => (
                            <Grid xs={12} sm={6} md={6} lg={3} xl={3} item key={index+1}>
                                {console.log(row, "checkingrow=")}
                                <SurveyBox style={{ backgroundColor: "white", display: "flex", height: '315px', flexDirection: "column", justifyContent: 'space-between', borderRadius: "10px", padding: "20px" }}>
                                    <Box id="cardBtn" style={{ width: '100%', display: "flex", flexDirection: "column",cursor:"pointer" }} onClick={() => this.handleOnClick(row) }>
                                        <Box >
                                            <Box style={{ float: 'right', position: 'relative', marginRight: '13px' }}>
                                                <ArrowForwardOutlined style={{ position: 'absolute',cursor:"pointer" }} color="disabled" />
                                            </Box>
                                            <Box style={{ position: 'relative', color: 'white', marginLeft: '-25px' }}>
                                                <img src={Group5} style={{ width: 105.26, height: 51.87 }} />
                                                <Typography style={{ position: 'absolute', left: '30px', top: '40%' }}>{row.Step}</Typography>
                                            </Box>
                                        </Box>
                                        <StepButton style={{ width: '100%', display: 'flex', flexDirection: 'column', marginBottom:'5px', justifyContent:'center' }} >
                                            <div style={{ display: 'flex',justifyContent:'center',alignItems: "center"}} >
                                                <LogoDiv>
                                                    <Avatar style={{ width: 105.26, height: 101.87}} src={row.img} />
                                                </LogoDiv>
                                            </div>
                                            <HedingText style={{height:'120px', fontWeight: 600, marginBottom: '4px', fontSize: "1.021rem", overflow: 'hidden', textOverflow: 'ellipsis', display: 'block', textTransform: 'capitalize', whiteSpace: 'pre-line', textAlign:'center', lineHeight: '2' }}>{row.Name}</HedingText>
                                        </StepButton>
                                    </Box>
                                        <div id="accessIconbtn" style={{display:'flex', justifyContent:'end'}}
                                            onClick={()=> { setStorageData('card_id', `${row.cardNo}`)
                                                navigation.history.push(`/PermissionDashboard/${row.type}`)}}>
                                                <PersonIcon color="disabled"/>
                                        </div>
                                </SurveyBox>
                            </Grid>
                        ))}
                    </Grid>
                </div>
            </DashboardLayout>


            // Customizable Area End
        );
    }
}
// Customizable Area Start
//*********** styles ***************//

const SurveyBox = styled(Box)({
    width: '-moz-available !important'
})

const StepButton = styled(Box)({
    
    '@media screen and (max-width:600px)': {
        width: '100%',
        display: 'flex',
        justifyContent: 'cneter',
        flexDirection: 'column',
        // marginLeft: '65px',
        textAline:'center'
    },
    '@media screen and (min-width:769px) and (max-width:1200px)': {
        width: '100%',
        textAline:'center'
    }
})
const HedingText = styled(Typography)({
    
    '@media screen and (max-width:600px)': {
        width: '100%',
        textAline:'center'
    },
    '@media screen and (min-width:769px) and (max-width:1200px)': {
        width: '100%',
        textAline:'center'
    }
})


const ArrowBase = styled(Typography)({
    float: 'right',
    marginBottom: '20px',
    '@media screen and (max-width:600px)': {
        width: '100%'
    },
    '@media screen and (min-width:769px) and (max-width:1200px)': {
        width: '100%'
    }
})

const DashboardHead = styled('div')({
    width: '100%',
    margin: '25px 0px 0px 12px',
    fontSize: '1.3rem',
    fontWeight: 600,
    '@media screen and (max-width: 768px)': {
        margin: '20px 0px 10px 12px',
        fontSize: '1rem',
    }
})

const LogoDiv = styled('div')({
    margin: "5px 0 5px 0",
    '& img':{
        objectFit: 'contain'
    }
})
  // Customizable Area End


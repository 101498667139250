import React from "react";
// Customizable Area Start
import {
  Box,
  Grid,
  Avatar,
  Typography,
  Chip,
  Divider,
  TextField,
  FormControl,
  Snackbar,
  SnackbarContent,
  Select,
  Checkbox,
  InputBase,
  MenuItem,
  ListItemText,
  IconButton,
  Button,
} from "@material-ui/core";
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
} from "@material-ui/core/styles";
import HttpsOutlinedIcon from "@material-ui/icons/HttpsOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import { withRouter, Redirect } from "react-router-dom";
import * as yup from "yup";

import Settings5Controller, { Props, configJSON } from "./Settings5Controller";
import { Formik } from "formik";
import { valueContainerCSS } from "react-select/src/components/containers";
// Customizable Area End
export default class Password extends Settings5Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    //@ts-ignore
    const { classes } = this.props;
    const validationSchema = yup.object({

      current_password: yup
      .string()
      .required("Password is required"),
      new_password: yup
        .string()
        .required("Password is required")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/,
          "Must Contain 6 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        ).notOneOf(
          [yup.ref("current_password")],
          "New Password cannot be same as Old Password."
      )
        ,
      confirm_password: yup
        .string()
        .required("")
        .oneOf([yup.ref("new_password"), null], "Password must match with New Password "),
    });
    const initialValues = {
      current_password: "",
      new_password: "",
      confirm_password: "",
    };

    

    const changePassword = async (e : any) => {
      const res =  await this.handleChangePassword(e)
      
    }

    // Customizable Area End
    return (
      // Customizable Area Start
      <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit = {e => 
         changePassword(e)
        }          //{this.handleChangePassword}
      >
        {({ handleChange, handleBlur , handleSubmit, errors, setFieldTouched, touched ,values }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Box
                  style={{
                    backgroundColor: "white",
                    borderRadius: "10px",
                    paddingBottom: "20px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      margin: "30px 10px 0px 10px",
                      padding: "50px 10px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="h5"
                      gutterBottom
                      style={{
                        fontWeight: "bold",
                        textAlign: "center",
                        marginBottom: "20px",
                        fontFamily: "Helvetica"
                      }}
                      component="div"
                    >
                      Change Password
                    </Typography>

                    <div
                      style={{
                        width: "70%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <div style={{ width: "100%" }}>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          style={{
                            fontWeight: "bold",
                            color: "#4f4e4e",
                            marginTop: "10px",
                            textAlign: "start",
                            fontFamily: "Helvetica"
                          }}
                          component="div"
                        >
                          Enter Old Password
                        </Typography>
                        <TextField
                        
                          placeholder="Enter Password"
                          id = "current_password"
                          // onCopy={this.handleChangeCopy}
                          // onPaste={this.handleChangeCopy}
                          // onCut={this.handleChangeCopy}
                          // value={this.state.current_password}
                          value={values.current_password}
                          // onChange={this.handleChange("current_password")}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type={this.state.showPassword ? "text" : "password"}
                          InputProps={{
                            disableUnderline: true,
                            startAdornment: (
                              <HttpsOutlinedIcon
                                style={{
                                  color: "#c9c9c9",
                                  marginRight: "10px",
                                }}
                              />
                            ),
                            endAdornment: (
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={this.handleClickShowPassword}
                                onMouseDown={this.handleMouseDownPassword}
                                edge="end"
                              >
                                {this.state.showPassword ? (
                                  <VisibilityOutlinedIcon
                                    style={{ color: "#c9c9c9" }}
                                  />
                                ) : (
                                  <VisibilityOffOutlinedIcon
                                    style={{ color: "#c9c9c9" }}
                                  />
                                )}
                              </IconButton>
                            ),
                          }}
                          style={{
                            color: "grey",
                            fontSize: 14,
                            width: "95%",
                            margin: "10px 0px",
                            borderRadius: "5px",
                            border: "1px solid #edebeb",
                            height: "45px",
                            textAlign: "center",
                            justifyContent: "center",
                            padding: "0 10px",
                          }}
                        />
                        <div
                         style={{fontSize: "12px",
                         color: "red",
                         marginTop: "0.25rem"}}
                         >{errors.current_password && touched.current_password && errors.current_password}</div>
                      </div>
                      <div style={{ width: "100%" }}>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          style={{
                            fontWeight: "bold",
                            color: "#4f4e4e",
                            marginTop: "10px",
                            textAlign: "start",
                            fontFamily: "Helvetica"
                          }}
                          component="div"
                        >
                          Enter New Password
                        </Typography>
                        <TextField
                          placeholder="Enter Password"
                          id = "new_password"
                          // onCopy={this.handleChangeCopy}
                          // onPaste={this.handleChangeCopy}
                          // onCut={this.handleChangeCopy}
                          value={values.new_password}
                          // onChange={this.handleChange("new_password")}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          type={this.state.showPassword2 ? "text" : "password"}
                          InputProps={{
                            disableUnderline: true,
                            startAdornment: (
                              <HttpsOutlinedIcon
                                style={{
                                  color: "#c9c9c9",
                                  marginRight: "10px",
                                }}
                              />
                            ),
                            endAdornment: (
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={this.handleClickShowPassword2}
                                onMouseDown={this.handleMouseDownPassword}
                                edge="end"
                              >
                                {this.state.showPassword2 ? (
                                  <VisibilityOutlinedIcon
                                    style={{ color: "#c9c9c9" }}
                                  />
                                ) : (
                                  <VisibilityOffOutlinedIcon
                                    style={{ color: "#c9c9c9" }}
                                  />
                                )}
                              </IconButton>
                            ),
                          }}
                          style={{
                            color: "grey",
                            fontSize: 14,
                            width: "95%",
                            margin: "10px 0px",
                            borderRadius: "5px",
                            border: "1px solid #edebeb",
                            height: "45px",
                            textAlign: "center",
                            justifyContent: "center",
                            padding: "0 10px",
                          }}
                        />
                        <div
                         style={{fontSize: "12px",
                         color: "red",
                         marginTop: "0.25rem"}}
                         >{errors.new_password && touched.new_password && errors.new_password}</div>

                      </div>
                      <div style={{ width: "100%" }}>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          style={{
                            fontWeight: "bold",
                            color: "#4f4e4e",
                            marginTop: "10px",
                            textAlign: "start",
                          }}
                          component="div"
                        >
                          Confirm New Password
                        </Typography>
                        <TextField
                          placeholder="Enter Password"
                          id = "confirm_password"
                          // value={this.state.confirm_password}
                          value = {values.confirm_password}
                          onChange={handleChange}
                          onBlur={handleBlur}

                          type={this.state.showPassword3 ? "text" : "password"}
                          InputProps={{
                            disableUnderline: true,
                            startAdornment: (
                              <HttpsOutlinedIcon
                                style={{
                                  color: "#c9c9c9",
                                  marginRight: "10px",
                                }}
                              />
                            ),
                            endAdornment: (
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={this.handleClickShowPassword3}
                                onMouseDown={this.handleMouseDownPassword}
                                edge="end"
                              >
                                {this.state.showPassword3 ? (
                                  <VisibilityOutlinedIcon
                                    style={{ color: "#c9c9c9" }}
                                  />
                                ) : (
                                  <VisibilityOffOutlinedIcon
                                    style={{ color: "#c9c9c9" }}
                                  />
                                )}
                              </IconButton>
                            ),
                          }}
                          style={{
                            color: "grey",
                            fontSize: 14,
                            width: "95%",
                            margin: "10px 0px",
                            borderRadius: "5px",
                            border: "1px solid #edebeb",
                            height: "45px",
                            textAlign: "center",
                            justifyContent: "center",
                            padding: "0 10px",
                          }}
                        />
                        <div 
                        style={{fontSize: "12px",
                         color: "red",
                         marginTop: "0.25rem"}}
                         >
                           {errors.confirm_password && touched.confirm_password && errors.confirm_password}
                           </div>

                      </div>
                          {
                            console.log(errors.new_password)
                            
                          }
                      {this.state.isLoading ? (
                        <Button
                          endIcon={
                            <CircularProgress style={{ color: "white" }} />
                          }
                          variant="contained"
                          disabled
                          style={{
                            width: "100%",
                            backgroundColor: "#bfbfbf",
                            color: "white",
                            height: "50px",
                            margin: "10px 0px",
                            borderRadius: "10px",
                          }}
                        >
                          Submit
                        </Button>
                      ) : (
                        values.current_password !== "" && values.new_password!== "" && values.confirm_password!== "" 
                        && errors.new_password == undefined && errors.current_password == undefined && errors.confirm_password == undefined
                      )? (
                        <Button
                          variant="contained"
                          type="submit"
                          // onClick={this.handleLoginPage}
                          style={{
                            width: "100%",
                            backgroundColor: "#1ea60a",
                            color: "white",
                            height: "50px",
                            margin: "10px 0px",
                            borderRadius: "10px",
                          }}
                        >
                          Submit
                        </Button>
                      )
                    :(
                      <Button
                        
                          variant="contained"
                          disabled
                          style={{
                            width: "100%",
                            backgroundColor: "#bfbfbf",
                            color: "white",
                            height: "50px",
                            margin: "10px 0px",
                            borderRadius: "10px",
                          }}
                        >
                          Submit
                        </Button>
                    )
                    
                    }
                    </div>
                  </div>
                </Box>
              </Grid>
              <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                open={this.state.opened}
                autoHideDuration={3000}
                onClose={this.handleClose}
              >
                <SnackbarContent
                  style={{
                    backgroundColor: this.state.color,
                  }}
                  message={
                    <span id="client-snackbar">{this.state.snackbarmsg}</span>
                  }
                />
              </Snackbar>
            </Grid>
          </form>
        )}
      </Formik>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

// Customizable Area End

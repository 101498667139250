import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { parseNewQuestionMessage} from "./Messages";
import { sendAPIRequest } from '../../../components/src/utils';
import { sendSharePopupMessage } from '../../share/src/Messages';
import { toast } from 'react-toastify';
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  token: string;
  emailPopup: boolean;
  surveyData: any;
  allQuestion: any;
  deleteTemplteId: any;
  deleteQuestion: boolean;
  deleteId: number;
  check: boolean;
  emailData: any;
  subject: string;
  content: string;
  emailErrorMsg: string;
  sendTo: any[];
  newEmail: string;
  modal: string;
  anchorElForPopup: HTMLButtonElement | null;
  loader: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class DueDiligenceDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getServeyDetailCallId = '';
  getAllQuestionId = '';
  questionDeleteCallId = '';
  templateDeleteCallId = '';
  sendEmailCallId = '';
  downloadReportCallId = '';
  

  async componentDidMount() {
    super.componentDidMount();
    console.log("component mounted")
    const authToken = await getStorageData('authToken');
    if (authToken == null) {
      this.props.navigation.navigate("Home");
    } 
    this.setState({token: authToken}, () => {
    this.onInitialized();
    })
  }

  onInitialized = () => {
    const { token } = this.state
    const survey_id = this.props.navigation?.getParam('survey_id')
    this.getServeyDetailCallId = sendAPIRequest(configJSON.getSurveyByIdEndpoint.replace(':sid', survey_id), {
      method: 'GET',
      headers: { token }
    })
    this.getAllQuestion();
  }

  getAllQuestion = () => {
    console.log("under get all question");
    const { token } = this.state
    const survey_id = this.props.navigation?.getParam('survey_id')
    this.getAllQuestionId = sendAPIRequest(`/bx_block_surveys/surveys/${survey_id}/survey_questions`, {
      method: 'GET',
      headers: {token},
    })
  }

  async componentDidUpdate() {
    console.log("component did update", this.props.navigation.history);
    if(this.props.navigation.history.action === "REPLACE" && this.state?.check){
      console.log("component did update replace -====");
      setTimeout(()=> location.reload(), 1000);
    
      this.setState({check: false});
    }
  }

  handleCoinsMessage = (data: any) => {
    
    this.setState({ allQuestion: {...this.state?.allQuestion, data} })
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.log("constructor")

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      token: '',
      emailPopup: false,
      surveyData: {},
      allQuestion: [],
      deleteTemplteId: '',
      deleteQuestion : false,
      deleteId: 0,
      check: true,
      emailData: {},
      subject: 'Share your valuable thoughts with us',
      content: ``,
      emailErrorMsg: "",
      sendTo: [],
      newEmail: '',
      modal: '',
      anchorElForPopup: null,
      loader: true,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.handleRestAPIResponse(message);
      }
    parseNewQuestionMessage(message, this.handleCoinsMessage)
    // Customizable Area End
  }

  // Customizable Area Start

  
    
  async handleRestAPIResponse(message: Message) {
      const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
      );
      
      let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      
      if (responseJson && responseJson.errors) {
      this.parseApiErrorResponse(responseJson.errors);
      this.parseApiCatchErrorResponse(responseJson.errors);
      } else {
      this.processApiResponse(apiRequestCallId, responseJson);
      }
  }    
  
  async processApiResponse(apiRequestCallId: string, responseJson: any) {
    switch (apiRequestCallId) {
      case this.getServeyDetailCallId:
        
    const surveyData = { ...responseJson?.data?.attributes, id: responseJson?.data?.id }
    this.setState({ surveyData,loader:false });
    break;
    case this.getAllQuestionId:
    const allQuestion = { ...responseJson?.questions }
    this.setState({ allQuestion });
    break;
    case this.questionDeleteCallId:
    toast.success(responseJson?.message);
    break;
    case this.templateDeleteCallId:
    this.setState({ deleteTemplteId: undefined });
    this.props.navigation.goBack();
    break;
    case this.sendEmailCallId:
    toast.success(responseJson?.message);
    this.closeEmailPopup();
    this.setState({ sendTo: [] });
    break;
    case this.downloadReportCallId:
    this.handleDownloadReport(responseJson);
    break;
    }
  }
  
  async handleDownloadReport(responseJson: any) {
    if (responseJson?.download_url_Link == null) {
    toast.warn("Report is not generated");
    } else {
    window.open(responseJson?.download_url_Link);
    }
    }

  handleEditTemplate = (id:any) => () =>{
    const url = `${this.props.navigation.history?.location?.pathname}/EditDueDilligence/${id}`
    this.props.navigation.history.push(url)
  }

  emailValue = (e:any) => {
    this.setState({newEmail: e?.target?.value})
    console.log("new Email", this.state?.newEmail);
  }

  handleRegex = () => {
   return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  }

  validateEmail = (email:any) => {
    if(email
      ?.toLowerCase()
      ?.match(
        this.handleRegex()
      )){
        this.setState({emailErrorMsg: ""})
        return true
      }
      toast.warn("Email is not valid");
      this.setState({emailErrorMsg: "Email is not valid"})
      return false
  };

  addEmail = (e:any) => {
    console.log("onkeypress", e.keyCode)
    if(e.keyCode == 13){
      if(this.validateEmail(this.state?.newEmail)){
        this.setState({sendTo: [...this.state?.sendTo, this.state?.newEmail]})
        this.setState({newEmail: ""})
      }
    }
  }

  deleteEmail = (index:number) => {
    const sendTo = this.state?.sendTo.filter((x:any)=> x != index)
    this.setState({sendTo})
  }

  emailValidation = () => {
    let isValid = true
    if(this.state?.subject == "" || this.state?.subject == null){
      this.setState({emailErrorMsg: "Subject is mandatory"})
      toast.warn("Subject is mandatory")
      isValid = false;
    }
    if(this.state?.content == "" || this.state?.content == null){
      this.setState({emailErrorMsg: "Content is Mandatory"})
      toast.warn("Content is Mandatory")
      isValid = false;
    }
    if(this.state?.newEmail?.length < 1 && this.state?.sendTo?.length == 0) {
      this.setState({emailErrorMsg: "To Field is Mandatory"})
      toast.warn("To Field is Mandatory")
      isValid = false;
    }
    return isValid;
  }

  handleSendEmail = () => {
    let valid = this.emailValidation();
    if(this.state?.sendTo?.length == 0 && this.state?.newEmail?.length > 0){
      toast.warn("press enter to add email")
    
    }
    else{
    const id = this.props.navigation.getParam('survey_id')
    console.log("from ==> ", id, this.context.email)
    const body = new FormData();
    
    body.append('id', id);
    body.append('link', `${window?.location?.origin}/SurveysDetails/${id || "0"}`);
    this.state?.sendTo?.map((email, index: number) => {
      body.append(`account_email[${index}]`, email);
    })
    const {token} = this.state;
    console.log("error msg", this.state?.emailErrorMsg)
    if(valid){
      this.sendEmailCallId = sendAPIRequest(configJSON.senEmailEndpoint, {
        method: 'POST',
        headers: {token},
        body,
      })
    }
  }
  }

  downloadReport = (fileType:any) => {
    const {token} = this.state;
    const id = this.state?.surveyData?.id
    console.log("id is ", id);
    console.log("this.context", this.context?.role?.role_level);
    this.downloadReportCallId = sendAPIRequest(`${configJSON.downloadReportEndpoint}/${id}/download_report_surveyer`,{
      method: 'GET',
      headers: {token},
    })
    }

  changeSubject = (e:any) => {
    this.setState({subject: e.target.value})
  }

  changeContent = (e:any) => {
    this.setState({content: e.target.value})
  }

  onDeleteQuestion = (id:number) => () => {
    console.log("allQuestion delete1 ",this.state?.allQuestion);
    const { token } = this.state
    const survey_id = this.props.navigation.getParam('survey_id')
    this.questionDeleteCallId = sendAPIRequest(`${configJSON.getSurveyByIdEndpoint.replace(':sid', survey_id)}/survey_questions/${id}`, {
      method: 'DELETE',
      headers: { token }
    })
    const ReemainQuestion = this.state?.allQuestion?.data?.filter((question:any)=> question.id != id);
    this.setState({allQuestion: {data: ReemainQuestion}, deleteQuestion: false})
    // this.setState()
  }

  deleteQuestion = (id: number) => () => {
    this.setState({deleteQuestion: true})
    this.setState({deleteId: id});
  }

  onEditQuestion = (id:number) => () => {
    this.props.navigation.history.push({pathname:`EditSurveyQuestion/${id}`, question_id: id, state: this.state?.surveyData?.id, survey_id: this.props.navigation.getParam('survey_id')})
  }

  onBack = () => {
    this.props.navigation.goBack();
  }

  closeDeleteModal = () => {
    this.setState({deleteTemplteId: undefined})
  }

  closeModal = () => {
    this.setState({deleteQuestion: false})
  }

  handleDeletePopup = (data:any) => () => {
    if(data?.status == "live"){
      toast.warn("Can not delete Live Template");
    }
    else{
      this.setState({deleteTemplteId: data?.id})
    }
  }

  handleDeleteTemplate = (id:any) => () => {
    const { token } = this.state
    this.templateDeleteCallId = sendAPIRequest(configJSON.deleteSurveyTemplate.replace(':id', id), {
      method: 'DELETE',
      headers: { token }
    })
    this.setState({deleteTemplteId:id})
  }

  onQuestionAdd = () => {
    this.props.navigation.history.push({pathname:`AddSurveyQuestions`,state: this.state?.surveyData?.id, survey_id: this.props.navigation.getParam('survey_id')})
  }

  openEmailPopup = () => {
    this.setState({
      emailPopup:true,
      content: `As an open and transparent company committed to sustainability, we truly value feedback of our stakeholders. Please share with us your experience of dealing and engaging  with our company by taking part in this short survey.
      About the survey:
      To access the survey, click on this link.
      It will take approximately ${this.state?.surveyData?.survey_time ? this.state?.surveyData?.survey_time : 0} minutes to complete this survey. Please provide your response by ${this.state?.surveyData?.end_date}.
      
      Thanks
      Company Stakeholders engagement  Team`
    })
  }

  closeEmailPopup = () => {
    this.setState({emailPopup: false})
  }

  handleShareClick  = (s: any) => () => {
    let data = {
      userId: s?.created_by?.id,
      postId: s?.id,
      link: `${window?.location?.origin}/SurveysDetails/${s?.id || "0"}`,
      shareableType: 'BxBlockSurveys::Survey',
      shareableId: s?.id,
    }
    sendSharePopupMessage(data);
  }
  // Customizable Area End
  
}

import { BlockComponent } from "../../../framework/src/BlockComponent";
export const configJSON = require("./config");
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";

import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { sendAPIRequest } from '../../../components/src/utils';
import { toast } from 'react-toastify';
import RequestManagement from "../../RequestManagement/src/RequestManagement.web";
import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End

}
interface S {
  // Customizable Area Start
  requestors: any[];
  loadingSurveyTemplates: boolean;
  token: string;
  txtSavedValue: string;
  isdisabled: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}





export default class ESGJourneyController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  requestorsCallId: string = '';
  downloadReportCallId = '';
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      txtSavedValue: "A",
      requestors: [],

      loadingSurveyTemplates: false,
      token: '',
      isdisabled: false,
      // Customizable Area End
    }
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    const authToken = await getStorageData("authToken");
    if (authToken == null) {
      this.props.navigation.navigate('Home')
    }
    const access = this.props?.navigation?.history?.location?.state?.ability_type
    console.log("access rights =", access);
    if(access == "read"){
      this.setState({isdisabled:true})
    }
    this.esgJourneyCards()
  }

  onBack = () => {
    this.props.navigation.goBack();
  }
  // Customizable Area End


  async receive(from: string, message: Message) {
    console.log("rece-self");

    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );


      if (responseJson && responseJson.errors) {
        this.parseApiErrorResponse(responseJson.errors);
        this.parseApiCatchErrorResponse(responseJson.errors);
      }
      else if (apiRequestCallId === this.requestorsCallId) {
        console.log("self-a", responseJson.data);

        if (responseJson) {
          const req = responseJson?.data?.map((item: any) => item?.attributes) || []
          console.log("self-asse", req)

          this.setState({ requestors: req })
        }
      }
      else if (this.downloadReportCallId == apiRequestCallId) {
        if (!responseJson?.surveys?.data?.attributes?.download_url) {   //download_link_url for pdf
          toast.warn("Report is not generated");
        }
        else {
          window.open(responseJson?.surveys?.data?.attributes?.download_url);
        }
      }
    }
    // Customizable Area End

  }



  // Customizable Area Start
  esgJourneyCards = async() => {
    const authToken = await getStorageData("authToken");
    const frameWork = await getStorageData("reportingFrameworkId");

    this.requestorsCallId = sendAPIRequest(`${configJSON.esgJourneyCards}?reporting_framework_id=${frameWork}`, {
      method: "GET",
      headers: {
        token: authToken,
        'content-type': 'appliction/json'
      },
    })
  }

  handleSelfAssesment = () => {
    const { navigation } = this.props;
    navigation.navigate('selfassessment',)
  }

  fillSurvey = (id:number) => {
    this.props.navigation.history.push(`SurveyEnduser/${id}`, {ability_type: this.props?.navigation?.history?.location?.state?.ability_type})
  }

  downloadPDFReport = async(fileType: any, id: any) => {
    const token = await getStorageData("authToken") || ''
    console.log("pdfid", id)

    this.downloadReportCallId = sendAPIRequest((fileType === 'pdf') && configJSON.downloadSurveyPdfReport.replace(':id', id), {
      method: 'GET',
      headers: { token },
    })
  }
  // Customizable Area End
}

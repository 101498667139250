import React from "react";

//Customizable Area Start
import {
  View,
  StyleSheet,
  ScrollView,
  KeyboardAvoidingView,
  TouchableWithoutFeedback
} from "react-native";
import {Box, Grid,Typography, IconButton,Button,TextField,Modal,Divider} from '@material-ui/core';
import {image_Bitmap,image_Bradlogo} from "../src/assets";
import Hidden from "@material-ui/core/Hidden";
import HttpsOutlinedIcon from '@material-ui/icons/HttpsOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import { Formik, Form, useField } from "formik";
import CountryCodeSelector from "../../country-code-selector/src/CountryCodeSelector";
import ForgotPasswordController, { Props } from "./ForgotPasswordController";
import { FormikSubmit } from "../../../components/src/FormModal.web";
import { styled } from '@material-ui/styles';
import OverlayLoader from '../../../components/src/Loader.web';
//Customizable Area End

export default class NewPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    //Customizable Area End
  }

  //Customizable Area Start
  //Customizable Area End
  render() {
    //Customizable Area Start
    //Customizable Area End

    return (
      //Customizable Area Start
      <KeyboardAvoidingView
        behavior={this.isPlatformiOS() ? "padding" : undefined}
        style={{ flex: 1 }}
      >
        <ScrollView
          keyboardShouldPersistTaps="always"
          style={
            this.isPlatformWeb() ? styles.containerWeb : styles.containerMobile
          }
        >
          <TouchableWithoutFeedback onPress={() => this.hideKeyboard()}>
            {/* Customizable Area Start */}
            <View>
              <Formik validationSchema={this.forgotPasswordValidationSchema} onSubmit={this.submitNewPassword} initialValues={{password: "", confirm_password: ""}}>
              <Form noValidate translate="no" >
              <Grid container spacing={1}>
                   <Grid item xs={12} sm={6} md={6} lg={6}  >
                     <Hidden mdDown>
                    <Box style={{backgroundColor:"white", height:"100%",display:'flex',flexDirection:'column', alignItems:"center",justifyContent:"center", position:"relative",}}>
                            <img src ={image_Bitmap} width="500px" height="400px" style={{borderRadius:"10px",}}/>
                                    
                        </Box>
                        </Hidden>
                   </Grid>
                   <Grid item xs={12} sm={12} md={6} lg={6} >
                      <Box style={{padding:"40px 70px 40px 70px",}}>
                      <Grid container>
                           <Grid item xs={12}>
                            <Box style={{display:'flex',flexDirection:'column', borderRadius:"10px",padding:"20px",alignItems:"center",justifyContent:"center", position:"relative", marginBottom:"30px"}}>
                                <img src ={image_Bradlogo} width="150px" height="50px" style={{borderRadius:"10px",}}/>     
                            </Box>  
                           </Grid>

                           <Grid item xs={12}>
                           <Typography variant="h5" gutterBottom style={{fontWeight:"bold",textAlign:"center"}} component="div">
                             Set Password
                            </Typography>
                            <Box style={{display:"flex",justifyContent:"center",alignItems:"center",}}>     
                            <Typography variant="subtitle2" gutterBottom style={{fontWeight:"normal",color:"grey",textAlign:"center",}} component="div">
                             Generate your new Password
                            </Typography>
                            </Box>
                                    
                           </Grid>
                           <Grid item xs={12}>
                          <Box >
                          <Typography variant="subtitle1" gutterBottom style={{fontWeight:"bold", color:"#4f4e4e",marginTop:"10px"}} component="div">
                            Enter New Password
                            </Typography>
                            <FormikTextField
                              data-test-id='showNewpasswordField'
                              autoFocus
                              name="password" 
                              placeholder="Enter New Password" 
                              onCopy={this.handleChangeCopy}
                              onPaste={this.handleChangeCopy}
                              onCut={this.handleChangeCopy}
                              type={this.state.showPassword ? 'text' : 'password'}
                              InputProps={{disableUnderline:true,
                                startAdornment: (
                                    <HttpsOutlinedIcon style={{color:"#c9c9c9",marginRight:"10px"}}/>
                                ),
                                endAdornment: (
                                    <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={this.handleClickShowPassword}
                                    onMouseDown={this.handleMouseDownPassword}
                                    edge="end"
                                    >
                                    {this.state.showPassword ? <VisibilityOffOutlinedIcon  style={{color:"#c9c9c9"}}/> : <VisibilityOutlinedIcon style={{color:"#c9c9c9"}}/>}
                                  </IconButton>
                                )
                              }}
                            
                             />
                          </Box>
                           </Grid>
                           <Grid item xs={12}>
                          <Box >
                          <Typography variant="subtitle1" gutterBottom style={{fontWeight:"bold", color:"#4f4e4e",marginTop:"10px"}} component="div">
                             Confirm New Password
                            </Typography>
                            <FormikTextField
                              name="confirm_password" 
                              placeholder="Confirm New Password" 
                              onCopy={this.handleChangeCopy}
                              onPaste={this.handleChangeCopy}
                              onCut={this.handleChangeCopy} 
                              type={this.state.showPassword2 ? 'text' : 'password'}
                              InputProps={{disableUnderline:true,
                                startAdornment: (
                                    <HttpsOutlinedIcon style={{color:"#c9c9c9",marginRight:"10px"}}/>
                                ),
                                endAdornment: (
                                  <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={this.handleClickShowPassword2}
                                  onMouseDown={this.handleMouseDownPassword}
                                  edge="end"
                                >
                                {this.state.showPassword2 ? <VisibilityOffOutlinedIcon  style={{color:"#c9c9c9"}}/> : <VisibilityOutlinedIcon style={{color:"#c9c9c9"}}/>}
                            </IconButton>
                                 
                          )
                            }}
                             />

 
                          </Box>
                           </Grid>
                           <Grid item xs={12}>
                            <Typography color="error">
                              {this.state.newPasswordAPIError}
                            </Typography>
                           </Grid>
                           <Grid item xs={12}>
                           <Box style={{display:"flex",justifyContent:"center",alignItems:"center",margin:"20px 0px",}}>
                               
                           <FormikSubmit component={FormButton} props={{}}>
                           {this.state.isLoading?'Submit':'Submit'}
                            </FormikSubmit>
                         </Box>
                           </Grid>
                       </Grid>
                      </Box>
                   </Grid>

               </Grid>
               <Modal
            style={{display:"flex", justifyContent:"center", alignItems:"center"}}
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <div style={{backgroundColor:"white",padding:"10px 20px 20px 20px", width:"50%",display:"flex",flexDirection:"column", }}>
          {this.state.modalHeading && <>
            <h3 id="simple-modal-title">{this.state.modalHeading}</h3>
            <Divider/> 
          </>
          }
          <p id="simple-modal-description">
          {this.state.modalContent}
          </p>
          <Divider/>
          <Button variant="contained" color="primary" onClick={this.handleClose} style={{width:"50px",margin:"10px 0px 0px 0px",}} >
                        Ok
                    </Button>
        </div>
          </Modal>
          <OverlayLoader loading={this.state.isLoading? true: false} />
              </Form>
            </Formik>
            </View>
            {/* Customizable Area End */}
          </TouchableWithoutFeedback>
        </ScrollView>
      </KeyboardAvoidingView>
      //Customizable Area End
    );
  }
}

// Customizable Area Start
const FormikTextField = (props: any) => {
  const [field, meta, helpers] = useField(props.name)
  return <>
          <TextField
            error={!!(meta.touched && meta.error)}
           {...field}
           {...props} 
           style={{ 
             color:"grey",
             fontSize:14, 
             width:"95%",
             margin:"10px 0px",
             borderRadius:"5px",
             border: `1px solid ${!!(meta.touched && meta.error)?'#F44336':'#edebeb'}`,
             height: "45px",
             textAlign: "center",
             justifyContent: "center",
             padding: "0 10px",
           }} 
          />
          <Typography color="error">
            {(meta.touched && meta.error) ? meta.error : ' ' }
          </Typography>
        </>
}
const styles = StyleSheet.create({
  containerMobile: {
    flex: 1,
    alignSelf: "center",
    display:"flex",
    width: "100%",

  },
  containerWeb: {
    flex: 1,
    alignSelf: "center",
    display:"flex",
    width: "100%",

  },
  countryCodeSelector: {
    flex: 3,
    marginTop: 20,
    textAlign: "left",
    textAlignVertical: "center"
  },
  button: {
    marginTop: 16,
    justifyContent: "center",
    alignItems: "center",
    width: "80%",
    zIndex: -1
  },

  flexContainer: {
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "flex-end",
    justifyContent: "flex-start",
    width: "100%"
  },

  headline: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center"
  },

  webInput: {
    marginTop: 20,
    width: "100%",
    zIndex: -1
  },

  inputAfterCountryCode: {
    width: "100%",
    zIndex: -1
  },

  mobileInput: {
    flexDirection: "column",
    alignItems: "stretch",
    fontSize: 16,
    textAlign: "left",
    backgroundColor: "#00000000",
    marginTop: 20,
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    includeFontPadding: true
  },

  codeInput: {
    marginTop: 20,
    width: "30%"
  },

  phoneInput: {
    flex: 3,
    marginTop: 20
  },

  noBorder: {
    borderBottomWidth: 0
  },

  titleText: {
    fontSize: 32,
    color: "#6200EE",
    fontWeight: "bold"
  },

  stepText: {
    marginBottom: 32,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8
  },

  emailText: {
    marginBottom: 16,
    fontSize: 16,
    textAlign: "left",
    marginVertical: 8,
    fontWeight: "bold"
  },

  bgRectBorder: {
    borderWidth: 1,
    borderColor: "#767676",
    borderRadius: 2,
    marginTop: 20,
    minHeight: 40,
    fontSize: 18,
    textAlignVertical: "center",
    padding: 10
  },

  bgRectWeb: {
    marginTop: 40
  },

  errorStyle: {
    color: "red",
    textAlign: "center"
  }
});

const BaseButton = styled(Button)({
  color: '#FFFFFF !important',
  padding: '5px 25px',
  width: "100%",
  height:"50px",
  borderRadius:"10px",
  fontWeight:"bold",
  textTransform:"none"
 })
 
 const GreenButton = styled(BaseButton)({
  background: '#1ea60a',
  '&:hover': {
    opacity: 0.9,
    background: '#009b21',
  },
 })
 
 const CardButton = styled(BaseButton)({
   background: '#d0d0d0',
   '&:hover': {
    opacity: 0.9,
    background: '#d0d0d0',
   },
 })

 const FormButton = (props: any) => {
   const Btn = props.disabled?CardButton:GreenButton
   return <Btn {...(props||{})}>{props.children}</Btn>
 }
 // Customizable Area End
import React from "react";

// Customizable Area Start
import {
	Box, 
	Table,
	Avatar,
	TableBody,
	TableContainer,
	Paper,
	TableHead,
	TableCell,
	TableRow,
	CardContent,
	Backdrop,
	Card,
	Button,
	IconButton,
	Grid,
	Typography,
	Modal
} from "@material-ui/core";
import { styled } from '@material-ui/styles';
import { InputRow, MInput } from "../../../components/src/FormModal.web";
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloseIcon from "@material-ui/icons/Close";
import DashboardLayout from "../../../components/src/DashboardLayout.web";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import LanguageIcon from '@material-ui/icons/Language';
import { location, corpstageCoini, contributors, iconCorpstage, ic_greenthumb, MaleIcon, FemalIcon, Group1 } from './assets';
import ReactSpeedometer from "react-d3-speedometer";
import OverlayLoader from '../../../components/src/Loader.web';
import { Formik, Form } from 'formik';
import { Link } from 'react-router-dom'
import UserContext from "../../../components/src/UserProvider";
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import ArrowForwardOutlined from '@material-ui/icons/ArrowForwardOutlined';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment'
import { PdfIcon } from "../../Surveys/src/assets";
import SeeMore from "../../../components/src/SeeMore.web";

export const configJSON = require("./config");

// Customizable Area End
import CorporateProfileController, { Props } from "./CorporateProfileController.web";
import { CommonActions } from "../../../components/src/PostCard.web";
import LikeAPost from "../../LikeAPost/src/LikeAPost.web";
import ImpactDashboard from "../../ProjectPortfolio/src/ImpactDashboard.web";
import { commentCount } from "../../ActivityFeed/src/assets";

export default class CorporateProfileEndUser extends CorporateProfileController {
	constructor(props: Props) {
		super(props);
		// Customizable Area Start
		// Customizable Area End
	}

	// Customizable Area Start
	// Customizable Area End

	render() {		
		let urlParam = this.props.navigation?.history?.location?.pathname.split("/",) ?? []	
		let param = urlParam[urlParam?.length-1] ?? ""

		return (
			// Customizable Area Start
			<>
				<DashboardLayout navigation={this.props.navigation} >
					<RoundedCard>
						<Box style={{ position: "relative" }} >
							<CorporateCoverPic><img style={{ width: "100%", height: "100%" }} src={this.state.profileDashboardData?.attributes?.cover_pic} /></CorporateCoverPic>
							<CorporateProfilePic id='corporateProfilePic'><Avatar style={{ width: "110px", height: "110px", borderRadius: "50%" }} src={this.state.profileDashboardData?.attributes?.logo} /></CorporateProfilePic>
						</Box>
						<CorporateContainer>
							<CorporateWrapper >
								<CorporateName>
									<Typography style={{ fontSize: "1.9rem", fontWeight: "bold" }}>{this.state.profileDashboardData?.attributes?.company_name || ''}</Typography>
									<Typography style={{ color: "#818283" }}>{this.state.profileDashboardData?.attributes?.sector || ''}</Typography>
								</CorporateName>

								<Box style={{ display: "flex", justifyContent: "space-between" }}>
									<Box style={{ marginRight: "30px" }} >
										<Typography style={{ fontWeight: "bold", fontSize: "0.9rem" }} >Corpstage Coins</Typography>
										<Box style={{ display: "flex", width: "44.5px", margin: "0 auto" }}>
											<img height="25px" width="20px" src={corpstageCoini} alt="" />
											<Typography style={{ color: "#818283", marginLeft: "7px" }}>
												{this.state.totalCoins}
											</Typography>
										</Box>
									</Box>
									<Box>
										{this.state.profileDashboardData?.attributes?.website_link !== undefined && this.state.profileDashboardData?.attributes?.website_link !== null && this.state.profileDashboardData?.attributes?.website_link !== "" ?
											<a href={`${this.state.profileDashboardData?.attributes?.website_link}`} target="_blank" rel="noopener">
												<RoundedButtonMore>
													<Typography style={{ fontWeight: "bold", fontSize: "0.8rem" }} >Go to Website</Typography>
													<LanguageIcon style={{ color: "#fff", marginLeft: "9px" }} />
												</RoundedButtonMore>
											</a> :
											<RoundedButtonMore>
												<Typography style={{ fontWeight: "bold", fontSize: "0.8rem" }} >Go to Website</Typography>
												<LanguageIcon style={{ color: "#fff", marginLeft: "9px" }} />
											</RoundedButtonMore>
										}
									</Box>
								</Box>
							</CorporateWrapper>
							<Box style={{ margin: "16px 0", display: "flex" }} >
								<img height="25px" width="20px" src={location} alt="" />
								<Typography style={{ color: "#818283", marginLeft: "10px" }}>
									{`${this.state.profileDashboardData?.attributes?.state || ''},${this.state.profileDashboardData?.attributes?.country || ''}`}
								</Typography>
							</Box>
							<Box>
								<Typography style={{ color: "#818283", marginBottom: "28px" }}>
									<SeeMore limit={160} text={this.state.profileDashboardData?.attributes?.business_description || ''} />
								</Typography>
							</Box>
						</CorporateContainer>
					</RoundedCard>
					<RoundedCard>
						<CorporateProjectContainer>
							<Box>
								<Typography style={{ fontSize: "1.2rem", fontWeight: "bold" }}>Category wise Projects</Typography>
								<Typography style={{ color: "#818283", marginTop: "10px" }} >No. of Projects : {this.state.categoryWiseProjectData?.total_projects} </Typography>
							</Box>
							<Box style={{ display: "flex", margin: "25px auto",width:"100%" }}>
								{this.state.categoryWiseProjectData?.projects?.length != 0  && this.state.categoryWiseProjectData?.projects?.map((el:any,index:number)=>{
									return <PercentBar minWidth={"65px"} borderRadius={index != 0 && index != this.state.categoryWiseProjectData?.projects?.length && "50px 0px 0px 50px"} marginLeft={ index != 0 &&"-27px"} width={`${el.category_project_percentage/this.state.overallPercent}`} style={{ backgroundColor: `${el.color_code}`}} >
										<PercentBarTypo>
										{`${el?.category_project_percentage && parseInt(el.category_project_percentage.toFixed(0))}%`}
									</PercentBarTypo>
								</PercentBar>
								})}
							</Box>
							<Box style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr" }} >
								{this.state.categoryWiseProjectData?.projects?.length != 0 && this.state.categoryWiseProjectData?.projects?.map((item:any) => {
									return <Box style={{ display: "flex", width: "120px" }} >
									<Box style={{ width: "15px" }}>
										<ColorCircle style={{ backgroundColor: `${item?.color_code}` }}></ColorCircle>
									</Box>
									<Box>
										<ProjectDetailsTypo>
											{item?.project_category}
										</ProjectDetailsTypo>
									</Box>
								</Box>
								})}
							</Box>
						</CorporateProjectContainer>
					</RoundedCard>
					<PageWrapper>
						<CenterRow>
							<div style={{ display: 'flex', gap: '20px' }}>
								<div style={{ margin: '0px 0px 40px 0px', width: '250px' }}>
									<Card style={{ maxWidth: '100%', marginBottom: '20px', borderRadius: "6px" }}>
										<CardContent style={{ padding: '30px', background: '#fff', }}>
											<Box style={{ marginBottom: '20px', }}>
												<Typography variant="h5" style={{ color: '#000', fontWeight: 600, fontSize: '16px', marginBottom: '4px', }}>
													Sector
												</Typography>
												<Typography style={{ color: 'rgb(132, 132, 132)', fontWeight: 400, fontSize: '12px', marginBottom: '0px', }}>
													{this.state.profileDashboardData?.attributes?.sector}
												</Typography>
											</Box>

											<Box style={{ marginBottom: '20px', }}>
												<Typography variant="h5" style={{ color: '#000', fontWeight: 600, fontSize: '16px', marginBottom: '4px', }}>
													Industry
												</Typography>
												<Typography style={{ color: 'rgb(132, 132, 132)', fontWeight: 400, fontSize: '12px', marginBottom: '0px', }}>
													{this.state.profileDashboardData?.attributes?.industry}
												</Typography>
											</Box>

											<Box style={{ marginBottom: '20px', }}>
												<Typography variant="h5" style={{ color: '#000', fontWeight: 600, fontSize: '16px', marginBottom: '4px', }}>
													Sub-Industry
												</Typography>
												<Typography style={{ color: 'rgb(132, 132, 132)', fontWeight: 400, fontSize: '12px', marginBottom: '0px', }}>
													{this.state.profileDashboardData?.attributes?.sub_industry}
												</Typography>
											</Box>

											<Box style={{ marginBottom: '0px', }}>
												<Typography variant="h5" style={{ color: '#000', fontWeight: 600, fontSize: '16px', marginBottom: '4px', }}>
													Company Registered Headquaters
												</Typography>
												<Typography style={{ color: 'rgb(132, 132, 132)', lineHeight: '20px', fontWeight: 400, fontSize: '12px', marginBottom: '0px', }}>
													{this.state.profileDashboardData?.attributes?.headquarters}
												</Typography>
											</Box>
										</CardContent>
									</Card>

									<Card style={{ maxWidth: '100%', marginBottom: '20px', borderRadius: "6px" }}>
										<CardContent style={{ padding: '30px', background: '#fff', }}>
											<div style={{ display: "flex", alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }}>
												<Typography variant="h4" style={{ color: '#000', fontWeight: 600, fontSize: '18px', marginBottom: '0px', }}>
													Certifications
												</Typography>
												<Typography style={{ color: '#000', fontWeight: 500, fontSize: '14px', marginBottom: '0px', cursor: "pointer" }} onClick={this.handleCertificationOpen}>See all</Typography>
											</div>
											{this.state.profileDashboardData?.attributes?.archived_certifications?.slice(0, 3)?.map((value: any) => {
												return <div style={{ display: "flex", alignItems: 'center', justifyContent: 'space-between', marginBottom: '0px' }}>
													<Typography variant="h5" style={{ color: 'rgb(113, 113, 113)', fontWeight: 400, fontSize: '16px', marginBottom: '0px', }}>
														{value?.title}
													</Typography>
													{value?.title && <CheckCircleIcon style={{ color: 'rgb(0, 161, 0)' }} />}
												</div>
											})}
										</CardContent>
									</Card>

									<Card style={{ maxWidth: '100%', marginBottom: '20px', borderRadius: "6px" }}>
										<CardContent style={{ padding: '30px', background: '#fff', }}>
											<Typography variant="h4" style={{ color: '#000', textAlign: 'center', fontWeight: 600, fontSize: '18px', marginBottom: '0px', }}>
												Directors by Gender
											</Typography>
											<div style={{ width: '100%', display: 'flex', gap: '20px', marginTop: '20px' }}>
												<div style={{ width: '50%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }}>
													<div><img style={{ width: '80px' }} src={FemalIcon} alt='womenIcon' /></div>
													<Typography style={{ color: 'rgb(40, 40, 40)', fontWeight: 400, fontSize: '18px', marginTop: '10px', }}>
														Women
													</Typography>
													<Typography variant="h5" style={{ color: 'rgb(40, 40, 40)', fontWeight: 600, fontSize: '25px', marginBottom: '0px', }}>
														{this.state.profileDashboardData?.attributes?.woman_owner_percent ? `${this.state.profileDashboardData?.attributes?.woman_owner_percent}%` : ""}
													</Typography>
												</div>
												<div style={{ width: '50%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }}>
													<div><img style={{ width: '80px' }} src={MaleIcon} alt='maleIcon' /></div>
													<Typography style={{ color: 'rgb(40, 40, 40)', fontWeight: 400, fontSize: '18px', marginTop: '10px', }}>
														Men
													</Typography>
													<Typography variant="h5" style={{ color: 'rgb(40, 40, 40)', fontWeight: 600, fontSize: '25px', marginBottom: '0px', }}>
														{this.state.profileDashboardData?.attributes?.woman_owner_percent ? `${100 - Number(this.state.profileDashboardData?.attributes?.woman_owner_percent)}%` : ""}
													</Typography>
												</div>
											</div>
										</CardContent>
									</Card>

									<Card style={{ maxWidth: '100%', marginBottom: '20px', borderRadius: "6px" }}>
										<CardContent style={{ padding: '30px', background: '#fff' }}>
											<Typography variant="h4" style={{ color: '#000', textAlign: 'center', fontWeight: 600, fontSize: '18px', marginBottom: '0px', }}>
												Average age of all <br /> Directors
											</Typography>
											<div style={{ width: '100%', gap: '0px', marginTop: '20px' }}>
												<div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }}>
													<div>
														{this.state.avergaeAge !== null && this.state.min_age_directors !== null && this.state.max_age_directors !== null &&
															<ReactSpeedometer
																value={this.state.avergaeAge}
																minValue={this.state.min_age_directors}
																maxValue={this.state.max_age_directors}
																width={220}
																ringWidth={5}
																height={120}
																needleColor="#f76e1d"
																segmentColors={[
																	"green",
																]}
																needleHeightRatio={0.7}
															/>}
													</div>
													<div style={{ display: 'flex', padding: '11px', width: '216px', justifyContent: 'space-between' }}>
														<Typography variant="h5" style={{ color: 'rgb(113, 113, 113)', fontWeight: 400, fontSize: '14px', marginBottom: '0px', }}>Min</Typography>
														<Typography variant="h5" style={{ color: 'rgb(113, 113, 113)', fontWeight: 400, fontSize: '14px', marginBottom: '0px', }}>Average</Typography>
														<Typography variant="h5" style={{ color: 'rgb(113, 113, 113)', fontWeight: 400, fontSize: '14px', marginBottom: '0px', }}>Max</Typography>
													</div>
												</div>
												<div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }}>
													<div style={{ display: 'flex', width: '216px', justifyContent: 'space-between' }}>
														<Typography variant="h5" style={{ fontWeight: "bold", fontSize: '18px', marginBottom: '0px', }}>{this.state.min_age_directors}</Typography>
														<Typography variant="h5" style={{ fontWeight: "bold", fontSize: '18px', marginBottom: '0px', }}>{this.state.avergaeAge}</Typography>
														<Typography variant="h5" style={{ fontWeight: "bold", fontSize: '18px', marginBottom: '0px', }}>{this.state.max_age_directors}</Typography>
													</div>
												</div>
											</div>
										</CardContent>
									</Card>

									<Card style={{ maxWidth: '100%', marginBottom: '20px' }}>
										<CardContent style={{ background: '#fff', }}>
											<Typography variant="h4" style={{ color: '#000', textAlign: 'center', fontWeight: 600, fontSize: '18px', marginBottom: '0px', }}>
												Percentage of <br /> Independent Directors
											</Typography>
											<div style={{ width: '100%', display: 'flex', gap: '20px', marginTop: '20px' }}>
												<div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }}>
													<Typography variant="h5" style={{ color: 'rgb(0, 161, 0)', fontWeight: 600, fontSize: '30px', marginBottom: '0px', }}>
														{this.state.profileDashboardData?.attributes?.indigenous_owner_percent ? `${this.state.profileDashboardData?.attributes?.indigenous_owner_percent}%` : ""}
													</Typography>
												</div>
											</div>
										</CardContent>
									</Card>
								</div>
								<div style={{ display: 'flex', flexDirection: 'column', width: 'calc(100% - 270px)' }}>
								{(this.userCompanyName == this.state.profileDashboardData?.attributes?.company_name && (this.userTypeInfo === "licensed_user" && (this.userRoleData !== undefined && this.userRoleData >= 4)))   ? <RoundedButtonBase style={{ display: 'flex', }}>
										<ProjectsCardButton
											onClick={this.chooseProjectTab}
											style={this.state.activeChooseButton ? { backgroundColor: "#00A100", color: "white" } : { backgroundColor: "white" }}
										>PROJECTS</ProjectsCardButton>
											<ESGCardButton		
											data-testId="esg-card"									
												onClick={this.chooseESGTab}
												style={this.state.activeESGTab ? { backgroundColor: "#00A100", color: "white" } : { backgroundColor: "white" }}
											>ESG JOURNEY</ESGCardButton>
										<ImpactDashboardCardButton
											onClick={this.chooseImpactDashboardTab}
											style={this.state.activeImpactDashboardButton ? { backgroundColor: "#00A100", color: "white" } : { backgroundColor: "white" }}
										>IMPACT DASHBOARD</ImpactDashboardCardButton>
									</RoundedButtonBase> : <RoundedButtonBase style={{ display: 'flex', }}>
										<ProjectsCardButton1
											onClick={this.chooseProjectTab}
											style={this.state.activeChooseButton ? { backgroundColor: "#00A100", color: "white" } : { backgroundColor: "white" }}
										>PROJECTS</ProjectsCardButton1>
										<ProjectsCardButton1
											onClick={this.chooseImpactDashboardTab}
											style={this.state.activeImpactDashboardButton ? { backgroundColor: "#00A100", color: "white" } : { backgroundColor: "white" }}
										>IMPACT DASHBOARD</ProjectsCardButton1>
									</RoundedButtonBase>}

									{this.state.showProjectTab && this.state.projectsList?.length <=0 && <div style={{ display: "flex", justifyContent: "center", width: "100%", minHeight: "129px", alignItems: "center" }}>No Data Found</div>}
									{(this.state.showProjectTab && this.state.projectsList?.length > 0) && this.state.projectsList?.map((val: any) => {
										return <ProjectTabContainer>
											<ProjectTabCard>
												<ProjectTabCardHeader>
													<div style={{ display: 'flex', gap: '15px' }}>
															<Link to={`/user/${val?.attributes?.project_leaders[0]?.data?.attributes?.id}`}>
															<div style={{ width: '40px', height: '40px', background: 'gray', borderRadius: '50%' }}>
																<img style={{ width: '40px', height: '40px', borderRadius: '50%' }} src={val?.attributes?.project_leaders[0]?.data?.attributes?.profile_pic} />
															</div>
															</Link>
														<div id={'RedirectToProjectDetail'} onClick={() => this.goToProjectDetailEndUser(val?.id)} style={{ display: 'flex', flexDirection: 'column', }}>
															<div style={{ color: '#000', fontSize: '19px', marginBottom: '7px', fontWeight:"bold" }}>{val?.attributes?.project_name}</div>
															<div style={{ color: '#989898', fontSize: '14px', }}>{val?.attributes?.location}</div>
														</div>
													</div>
													<div style={{ color: '#989898', fontSize: '14px', gap: '10px', display: 'flex', alignItems: 'center' }}><img style={{ width: '25px' }} src={iconCorpstage} alt="icon" /> <Typography  style={{paddingTop: '2px'}}>{val?.attributes?.completed_project_milestones}/{val?.attributes?.total_project_milestones}</Typography></div>
												</ProjectTabCardHeader>
												<p style={{ wordBreak:"break-word", cursor: "pointer", color: '#989898', fontSize: '14px', marginBottom: '20px' }}>
													{val?.attributes?.description.length > 455 ? `${val?.attributes?.description.substring(0, 455)}` : val?.attributes?.description}{val?.attributes?.description.length > 455 ? <Link style={{ fontWeight: "bold" }} to={`/CorporateProfile/projects/${val?.id}`}>...See more</Link> : null}
												</p>
												{val?.attributes?.project_profile_picture?.length>0 && <div id='goToProjectDetail' onClick={() => this.goToProjectDetailEndUser(val?.id)} style={{ cursor: "pointer", borderRadius: '6px', minHeight: '100px', width: '100%', marginBottom: '20px' }}>
													<img style={{ width: '100%', height: '41vh', borderRadius: '8px' }} src={val?.attributes?.project_profile_picture} />
												</div>}
												<div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
													<div style={{ display: 'flex', gap: '10px', alignItems: "baseline" }}><span style={{ color: '#000', fontSize: '12px' }}>Collaborators :</span> <div data-test-id="collab-btn" style={{ color: '#989898', fontSize: '12px', cursor:'pointer', display: "flex"}} onClick={()=> val?.attributes?.project_collaborators?.length>0 && this.handleShowCollaborators(val?.id)}>{val?.attributes?.project_collaborators?.slice(0, 3)?.map((collaborator: any) => collaborator?.data?.attributes?.image ? <img style={{ width: "25px", height: '25px', objectFit: 'cover', border: 'solid 1px #196519', marginRight: '-10px', borderRadius: "50%", verticalAlign: "middle", backgroundColor: '#fff' }} src={collaborator?.data?.attributes?.image} /> :  <Avatar style={{ width: "25px", height: '25px', border: 'solid 1px #196519', marginRight: '-10px', borderRadius: "50%", verticalAlign: "middle" , backgroundColor:'a9a9a970',color:'#000', fontSize:'14px', }}>{collaborator?.data?.attributes?.first_name?.charAt(0)?.toUpperCase()}</Avatar>)}</div>
														<div>{val?.attributes?.project_collaborators?.length > 3 && <div id='collaboratorList' onClick={()=>this.handleShowCollaborators(val?.id)} style={{cursor:"pointer", marginLeft: '5px', fontSize: "12px", color: "gray" }}>{` +${val?.attributes?.project_collaborators?.length - 3} more`}</div>}</div>
													</div>
													<div style={{ display: 'flex', gap: '10px', alignItems: "baseline" }}><span style={{ color: '#000', fontSize: '12px' }}>SDG Factors :</span> <div style={{ color: '#989898', fontSize: '12px', }}>
														<div style={{display:"flex"}}>
															<div style={{marginRight:"7px", display:"flex" , cursor:'pointer'}} id='viewSDGFactors' onClick={()=> val?.attributes?.sdg_factors !== "" && this.handleOpenSDGFactors(val?.id)}>
															{val?.attributes?.sdg_factors !== "" && val?.attributes?.sdg_factors?.slice(0,5)?.map((value: any) => {
																return (
																	<div>
																		<img style={{ width: "25px", height: '25px', objectFit: 'cover', border: 'solid 1px #196519', marginRight: '-10px', borderRadius: "50%", verticalAlign: "middle" }} src={value?.data?.attributes?.image} />
																	</div>
																)
															})}
															</div>
															{val?.attributes?.sdg_factors!=="" && val?.attributes?.sdg_factors?.length>5 && <Typography style={{marginLeft:"6px", cursor:"pointer"}} onClick={()=>this.handleOpenSDGFactors(val?.id)}>{`+${val?.attributes?.sdg_factors?.length-5} more`}</Typography>}
														</div>
													</div>
													</div>
												</div>
												<div style={{ display: 'flex', marginTop: '20px', justifyContent: 'space-between', marginBottom: '10px' }}>
													<div style={{ display: 'flex', gap: '10px', }}>
														<div style={{ color: '#000', fontSize: '12px', gap: '6px', display: 'flex', alignItems: 'center' }}>
															<img style={{ width: '18px' }} src={ic_greenthumb} /> {val?.attributes?.project_likes_count}
															<img style={{ width: '18px' }} src={commentCount} /> {val?.attributes?.project_comments_count}
														</div>
														<div id='veiwTopContributorList' onClick={() => this.handleTopContributorsModalOpen(val?.id)} style={{ color: '#000', fontSize: '12px', gap: '6px', display: 'flex', alignItems: 'center', cursor: "pointer" }}><img src={contributors} style={{ width: "19px" }} /> Top Contributors</div>
													</div>
													<div><Button id='collaboratorOpenBtn' onClick={() => this.props.navigation.history.push(`${this.props.navigation.history.location.pathname}/RequestToCollaborate/${val?.id}`)} style={{ color: 'rgb(0, 161, 0)', border: 'solid 1px rgb(0, 161, 0)', borderRadius: '25px', fontWeight: 600, fontSize: '10px', padding: '0px 10px 0px 10px', textTransform:'none' }}>Request To Collaborate</Button></div>
												</div>
											</ProjectTabCard>
											<CommonActions
												isProject={true}
												onStarClick={() => { }}
												onSurveyClick={this.handleSurveyOpen(val?.id)}
												onShareClick={this.handleShareCourse(val)}
												isFavourite={false}
												onCommentClick={this.goToEvent(val?.id)}
												isHideStar={true}
												likeButton={
													<LikeAPost
														navigation={this.props.navigation}
														id=""
														likeable_type="BxBlockProjectManagement::ProjectManagement"
														isLiked={Boolean(val?.attributes?.project_likes?.some((project: any) => Number(project.like_by_id) === Number(this.state.userID)))}
														post_id={val?.id}
														like_id={val?.attributes?.project_likes.find((project: any) => Number(project.like_by_id) == (this.state.userID))?.id}
														onLikeChange={this.onLikeChange}
														onLikeIdUpdate={this.onLikeIdUpdate}
													/>}
											/>
											{this.state.tableRowId !== "" && this.state.tableRowId == val?.id && <TopContibutors data={val?.attributes?.top_contributors} handleTopContributorsModalClose={this.handleTopContributorsModalClose} openModal={this.state.openContributros} />}
											{this.state.tableRowId !== "" && this.state.tableRowId == val?.id  && this.state.showCollaborators && <CollaboratorList data={val} openModal={this.state.showCollaborators} handleCollabratorClose={this.handleCloseCollaborators}/>}
											{this.state.tableRowId==val?.id && this.state.showSdg && <ShowSdgFactors openModal={this.state.showSdg} onClose={this.handleCloseSDGFactors} data={val?.attributes?.sdg_factors}/>}
										</ProjectTabContainer>
									})
									}
									{this.state.showImpactDashboard && <Box style={{ margin: '30px auto 0 auto' }}><ImpactDashboard navigation={this.props.navigation} isProfile={true} id={""} companyName={this.props.navigation.getParam('navigationBarTitleText')}/></Box>}
									{this.state.showESGTab && <Box data-testId="card-data" style={{ margin: '20px auto 0 auto' }}><Cards>
										{ (Array.isArray(this.state.requestors) && this.state.requestors.length>0) && this.state.requestors.map((data: any) => {
											return (
												<SurveyCard data-testid ="ESGCardBox" onClick={()=>this.goToPage(data.attributes)}>
													<ArroWrapper>
														<ArrowForwardOutlined color="disabled" />
													</ArroWrapper>
													<LogoWrapper>
														<img src={Group1} />
													</LogoWrapper>
													<AssesmentHeading>
														{data?.attributes?.ability_card}
													</AssesmentHeading>
													<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', gap: '2px' }}>
														<ImportContactsIcon color="disabled" />
														{data?.attributes?.ability_type == "write" && <EditIcon color="disabled" />}
													</div>
												</SurveyCard>)
										})}
									</Cards></Box>}
								</div>
							</div>
						</CenterRow>
					</PageWrapper>
				</DashboardLayout>
				<OverlayLoader loading={this.state.Overlayloader} />
				{this.state.openCertificationModal && <CertificationsModal handleCertificationClose={this.handleCertificationClose} profileDashboardData={this.state.profileDashboardData} openCertificationModal={this.state.openCertificationModal} />}
			</>
			// Customizable Area End
		);
	}
}

// Customizable Area Start
CorporateProfileController.contextType = UserContext


interface CollaboratorListProps {
	openModal:boolean;
	handleCollabratorClose:any;
	data:any;
}

export const CollaboratorList = ({data,openModal,handleCollabratorClose}:CollaboratorListProps) => {
	return (
		<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",

				}}
				open={openModal}
				onClose={() => {
					handleCollabratorClose()
				}}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Box
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						flexDirection: 'column',
						background: '#fff',
						borderRadius: 8,
						width: '650px',
						position: "relative",
						margin: '0 10px',
					}} className="schedule_modal">
					<Grid container spacing={0}>

						{/* Modal Title */}
						<ModalTitle item xs={12} >
							<Typography style={{ paddingTop: '14px', fontWeight: 'bold' }}>
								Collaborators
							</Typography>
							{/* Close Btn */}
							<CloseIconButton id='closeIconOnListOfCollaborator' onClick={() => {
								handleCollabratorClose()
							}}>
								<CloseIcon style={{}} />
							</CloseIconButton>
							{/* Close Btn END */}
						</ModalTitle>
						{/* Modal Title END */}

						<Grid item xs={12} style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							flexDirection: 'column',
							padding: '18px 36px',
							maxHeight: "550px",
						}}>
								<RightFormWrapperDiv >
									<Grid item xs={12}>
										<div>
										<Table style={{ minWidth: 650 }} aria-label="simple table">
										<TableHead>
											<TableRow>
												<TableCell>S.No</TableCell>
												<TableCell>Name</TableCell>
												<TableCell>Profile</TableCell>
												<TableCell>Location</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{data?.attributes?.project_collaborators?.map((collaborator:any, index:any)=> {
												const {data} =collaborator
												return(
												<TableRow>
												<TableCell>{index+1}</TableCell>
												<TableCell>{data?.attributes?.full_name}</TableCell>
												<TableCell>{<Avatar style={{ width: 34, height: 34, margin: "auto" }} src={data?.attributes?.image} />}</TableCell>
												<TableCell>{data?.attributes?.location}</TableCell>
												</TableRow>
												)
											})}
										</TableBody>
										</Table>
										</div>
									</Grid>
								</RightFormWrapperDiv>
						</Grid>
					</Grid>
				</Box>
			</Modal>
	)
}
interface TopContributorsProps {
	openModal: boolean; handleTopContributorsModalClose: any; data: any
}

export const TopContibutors = ({ openModal, handleTopContributorsModalClose, data, }: TopContributorsProps) => {
	return (
		<div>
			<Modal
				aria-describedby="transition-modal-description"
				aria-labelledby="transition-modal-title"
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
				open={openModal}
				closeAfterTransition
				onClose={() => {
					handleTopContributorsModalClose()
				}}
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Box
					style={{
						alignItems: 'center',
						display: 'flex',
						justifyContent: 'center',
						background: '#fff',
						borderRadius: 8,
						width: '650px',
						flexDirection: 'column',
						position: "relative",
						margin: '0 10px',
					}} className="schedule_modal">
					<Grid container spacing={0}>

						{/* Modal Title */}
						<ModalTitle item xs={12} >
							<Typography style={{ paddingTop: '14px', fontWeight: 'bold' }}>
								Top Contributors
							</Typography>
							{/* Close Btn */}
							<CloseIconButton id='TopContributorClose' onClick={() => {
								handleTopContributorsModalClose()
							}}>
								<CloseIcon style={{}} />
							</CloseIconButton>
							{/* Close Btn END */}
						</ModalTitle>
						{/* Modal Title END */}

						<Grid item xs={12} style={{
							display: 'flex',
							flexDirection: 'column',
							padding: '18px 36px',
							justifyContent: 'center',
							alignItems: 'center',
						}}>
								<RightFormWrapperDiv>
									<Grid item xs={12}>
										<TableContainer component={Paper}>
											<Table>
												<TableHead>
													<TableCell style={{ fontWeight: 'bold', color: 'black' }}>S.No</TableCell>
													<TableCell style={{ fontWeight: 'bold', color: 'black' }}>First Name</TableCell>
													<TableCell style={{ fontWeight: 'bold', color: 'black' }}>Last Name</TableCell>
													<TableCell style={{ fontWeight: 'bold', color: 'black' }}>Preference</TableCell>
													<TableCell style={{ fontWeight: 'bold', color: 'black' }}>Company Name</TableCell>
												</TableHead>
												<TableBody>
													{data?.map((value: any, index: any) => {
														return (
															<TableRow>
																<TableCell>{index + 1}</TableCell>
																<TableCell>{value?.first_name}</TableCell>
																<TableCell>{value?.last_name}</TableCell>
																<TableCell>{value?.preference}</TableCell>
																<TableCell>{value?.company_name}</TableCell>
															</TableRow>
														)
													})}
												</TableBody>
											</Table>
										</TableContainer>
									</Grid>
								</RightFormWrapperDiv>
						</Grid>
					</Grid>
				</Box>
			</Modal>
		</div >
	)
}

interface SdgFactorProps {
	data:any,
	openModal:boolean,
	onClose:any,
}

export const ShowSdgFactors = ({onClose,data,openModal}: SdgFactorProps) => {
	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",

				}}
				open={openModal}
				onClose={() => {
					onClose()
				}}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Box
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						flexDirection: 'column',
						background: '#fff',
						borderRadius: 8,
						width: '650px',
						position: "relative",
						margin: '0 10px',
						maxHeight:'550px',
					}} className="schedule_modal">
					<Grid container spacing={0}>
						<ModalTitle item xs={12} >
							<Typography style={{ paddingTop: '14px', fontWeight: 'bold' }}>
								SDG Factors
							</Typography>
							<CloseIconButton id='closeSDGComponent' onClick={() => {
								onClose()
							}}>
								<CloseIcon style={{}} />
							</CloseIconButton>
						</ModalTitle>

						<Grid item xs={12} style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							flexDirection: 'column',
							padding: '18px 36px'
						}}>
								<RightFormWrapperDiv>
									<Grid item xs={12}>
										<TableContainer component={Paper}>
											<Table>
												<TableHead>
													<TableCell style={{ fontWeight: 'bold', color: 'black' }}>S.No</TableCell>
													<TableCell style={{ fontWeight: 'bold', color: 'black' }}>Goal</TableCell>
													<TableCell style={{ fontWeight: 'bold', color: 'black' }}>Picture</TableCell>
													</TableHead>
												<TableBody>
													{data?.map((value: any, index: any) => {
														return (
															<TableRow>
																<TableCell>{index + 1}</TableCell>
																<TableCell>{value?.data?.attributes?.goal}</TableCell>
																<TableCell>
																	<Avatar style={{width:"25px", height:"25px"}} src={value?.data?.attributes?.image}/>
																</TableCell>
															</TableRow>
														)
													})}
												</TableBody>
											</Table>
										</TableContainer>
									</Grid>
								</RightFormWrapperDiv>
						</Grid>
					</Grid>
				</Box>
			</Modal>
		</div >
	)
}

export const CertificationsModal = ({ profileDashboardData, openCertificationModal, handleCertificationClose }: any) => {
	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",

				}}
				open={openCertificationModal}
				onClose={() => {
					handleCertificationClose()
				}}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Box
					style={{
						display: 'flex',
						flexDirection: 'column',
						background: '#fff',
						borderRadius: 8,
						width: '650px',
						maxHeight: '550px',
						overflowY : 'scroll',
						position: "relative",
						margin: '0 10px',
					}} className="schedule_modal">
					<Grid container spacing={0}>
						<ModalTitle item xs={12} >
							<Typography style={{ paddingTop: '14px', fontWeight: 'bold' }}>
								CERTIFICATIONS
							</Typography>
							<CloseIconButton id='certificationCLose' onClick={() => {
								handleCertificationClose()
							}}>
								<CloseIcon style={{}} />
							</CloseIconButton>
						</ModalTitle>
						<Grid item xs={12} style={{
							display: 'flex',
							justifyContent: 'center',
							flexDirection: 'column',
							padding: '18px 36px'
						}}>
							<Grid item xs={12}>
								<TableWrapper>
									<TableContainer component={Paper}>
										<Table>
											<TableHead>
												<TableRow>
													<TableCell style={{ fontWeight: 'bold', color: 'black' }}>
														SL.No
													</TableCell>
													<TableCell style={{ fontWeight: 'bold', color: 'black' }}>
														Name
													</TableCell>
													<TableCell style={{ fontWeight: 'bold', color: 'black' }}>
														Expiry Date
													</TableCell>
													<TableCell style={{ fontWeight: 'bold', color: 'black' }}>
														Description
													</TableCell>
													<TableCell style={{ fontWeight: 'bold', color: 'black' }}>
														Attachments
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{
													profileDashboardData?.attributes?.archived_certifications?.length>0 && profileDashboardData?.attributes?.archived_certifications?.map((value:any,index:any)=>{
														return(
															<TableRow>
																<TableCell>{index+1}</TableCell>
																<TableCell>{value?.title}</TableCell>
																<TableCell>{moment(value?.expire_at).format("YYYY-MM-DD")}</TableCell>
																<TableCell>
																	<SeeMore textColor={"black"} limit={28} text={value?.description} />
																</TableCell>
																{Object.values(value?.document)?.length>0 && <TableCell><a href={value?.document?.path}>
																	<div style={{display:"flex"}}>
																	{value?.document?.name}
																	<img style={{width:"26px", marginLeft:"8px"}} src={PdfIcon} alt=""/>
																	</div>
																	</a></TableCell>}
															</TableRow>
														)
													})
												}
											</TableBody>
										</Table>
									</TableContainer>
								</TableWrapper>
								<div style={{ margin: "auto", display: 'grid', justifyContent: 'center' }}>
									<DialogCancelButton id='closeCertification' onClick={() => {
										handleCertificationClose()
									}}>
										CLOSE
									</DialogCancelButton>
								</div>
							</Grid>
						</Grid>
					</Grid>
				</Box>
			</Modal>
		</div >
	)
}

const ModalWrapper = styled(Box)({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	flexDirection: 'column',
	background: '#fff',
	borderRadius: 8,
	width: '670px',
	position: "relative",
	margin: '0 10px',
})

const RoundedCard = styled(Card)({
	borderRadius: "10px",
	margin: "30px 0px",
});

const CorporateContainer = styled(Box)({
	width: "90%",
	margin: "0 auto"
});

const FilterByDateWrapper = styled('div')({
	width: '300px',
	height: '200px',
	padding: '10px 15px',
	background: '#FFF'
})

const CorporateProjectContainer = styled(Box)({
	width: "90%",
	margin: "25px auto"
});

const ProjectTabContainer = styled('div')({
	width: '100%',
	marginTop: '30px',
	borderRadius: '6px',
	background: '#fff',
})

const ProjectTabCard = styled('div')({
	padding: '30px 30px 6px 30px',
	borderBottom: 'solid 1px rgb(227, 227, 227)',
})

const ProjectTabCardHeader = styled('div')({
	display: 'flex',
	marginBottom: '20px',
	alignItems: 'flex-start',
	justifyContent: 'space-between',
	gap: '20px',
	cursor: "pointer"
})

const PercentBar = styled(Box)({
	borderRadius: "50px",
});
const AssesmentHeading = styled(Typography)({
	display: 'flex',
	justifyContent: 'center',
	fontWeight: 'bold',
	marginBottom: '15px',
	marginTop: '30px',
	fontSize: ".9rem"
})

const LogoWrapper = styled(Box)({
	display: 'flex',
	justifyContent: 'center',
	'& img': {
		width: '60px',
	}
})

const ColorCircle = styled(Box)({
	height: "15px",
	width: "15px",
	borderRadius: "50%",
});

const SurveyCard = styled(Box)({
	width: '200px',
	height: '220px',
	padding: ' 20px 10px',
	marginBottom: '20px',
	marginRight: '20px',
	boxShadow: '1px 2px 20px #CABFC1',
	borderRadius: '10px',
	background: '#fff'
})

const Cards = styled('div')({
	display: "grid",
	gridTemplateColumns: "1fr 1fr",
	// gap:"20px"
})
const ProjectDetailsTypo = styled(Typography)({
	color: "#818283",
	fontSize: "0.7rem",
	marginLeft: "10px",
});

const PercentBarTypo = styled(Typography)({
	padding: "5px 15px",
	color: "white",
	fontWeight: "bold",
});

const CorporateCoverPic = styled(Box)({
	backgroundColor: "grey",
	width: "100%",
	height: "220px",
	borderRadius: "10px",

});

const ArroWrapper = styled('div')({
	display: 'flex',
	justifyContent: 'flex-end',
	color: 'gray',
	marginRight: '10px',
	fontWeight: 100
})


const CorporateProfilePic = styled(Box)({
	// backgroundColor: "red",
	width: "110px",
	height: "110px",
	borderRadius: "50%",
	position: "absolute",
	top: "160",
	left: "50",
});

const CorporateName = styled(Box)({
	fontSize: "1.2rem",
	fontFamily: "HelveticaNeue",

});

const CorporateWrapper = styled(Box)({
	display: "flex",
	justifyContent: "space-between",
	marginTop: "65px",

});

const RoundedButtonMore = styled(Button)({
	cursor: "pointer",
	backgroundColor: "#009c05",
	color: "white",
	borderRadius: "50px",
	padding: "10px 20px",
	fontFamily: "HelveticaNeue",
	// float: "left",
	"&:hover": {
		backgroundColor: "#009c05",
	},
	"@media screen and (max-width:600px)": {
		width: "100%",
	},
	"@media screen and (min-width:769px) and (max-width:1200px)": {
		width: "100%",
	},
});

const TableRowBox = styled(TableRow)({
	'& th:first-child': {
		minWidth: '45px',
	},
	'& th:nth-child(2)': {
		minWidth: '120px',
	},
	'& th:nth-child(3)': {
		minWidth: '340px',
	},
	'& th:nth-child(4)': {
		minWidth: '140px',
	},
	'& th:nth-child(5)': {
		minWidth: '340px',
	},
	'& th:nth-child(6)': {
		minWidth: '160px',
	},
})

const PageWrapper = styled("div")({
	display: 'flex',
	justifyContent: 'center',
	minHeight: '100vh'
});

const CenterRow = styled("div")((props: any) => ({
	width: '100%',

}));

const ProjectsCardButton = styled(Button)({
	border: "2px solid transparant",
	color: "#009B07",
	fontWeight: 600,
	backgroundColor: "#FFFFFF",
	textTransform: "none",
	borderRadius: "38px",
	height: "100%",
	padding: "0 5px 0 5px",
	minWidth: '30%',
	'@media screen and (max-width: 1110px) and (min-width: 769px)': {
		padding: "0 15px 0 15px",
	},
	'@media screen and (max-width: 624px)': {
		padding: "0 15px 0 15px",
	},
	'@media screen and (max-width: 500px)': {
		padding: "0 10px 0 10px",
	}
});
const ProjectsCardButton1 = styled(Button)({
	border: "2px solid transparant",
	color: "#009B07",
	fontWeight: 600,
	backgroundColor: "#FFFFFF",
	textTransform: "none",
	borderRadius: "38px",
	height: "100%",
	padding: "0 5px 0 5px",
	minWidth: '50%',
	'@media screen and (max-width: 1110px) and (min-width: 769px)': {
		padding: "0 15px 0 15px",
	},
	'@media screen and (max-width: 624px)': {
		padding: "0 15px 0 15px",
	},
	'@media screen and (max-width: 500px)': {
		padding: "0 10px 0 10px",
	}
});


const TableWrapper = styled('div')({
	overflow: 'auto',
	'& .MuiTableCell-root': {
		borderRight: '1px solid rgba(224, 224, 224, 1)',
		textAlign: 'center',
	},
	'& .table-icons img': {
		margin: '0px 5px',
	}
})

const ESGCardButton = styled(Button)({
	border: "2px solid transparant",
	color: "#009B07",
	fontWeight: 600,
	backgroundColor: "#FFFFFF",
	textTransform: "none",
	borderRadius: "38px",
	height: "100%",
	padding: "0 5px 0 5px",
	minWidth: '30%',
	'@media screen and (max-width: 1110px) and (min-width: 769px)': {
		padding: "0 15px 0 15px",
	},
	'@media screen and (max-width: 624px)': {
		padding: "0 15px 0 15px",
	},
	'@media screen and (max-width: 500px)': {
		padding: "0 10px 0 10px",
	}
});

const ImpactDashboardCardButton = styled(Button)({
	border: "2px solid transparant",
	color: "#009B07",
	fontWeight: 600,
	backgroundColor: "#FFFFFF",
	textTransform: "none",
	borderRadius: "38px",
	height: "100%",
	padding: "0 5px 0 5px",
	minWidth: '40%',
	'@media screen and (max-width: 1110px) and (min-width: 769px)': {
		padding: "0 15px 0 15px",
	},
	'@media screen and (max-width: 624px)': {
		padding: "0 15px 0 15px",
	},
	'@media screen and (max-width: 500px)': {
		padding: "0 10px 0 10px",
	}
});

const RoundedButtonBase = styled('div')((props: any) => ({
	height: "47px",
	fontWeight: 700,
	display: 'flex',
	textAlign: 'center',
	justifyContent: 'center',
	backgroundColor: "#FFFFFF",
	flex: "wrap",
	color: "#009E00",
	borderRadius: '38px',
	border: " 2px solid #009B07"
}))

const CloseIconButton = styled(IconButton)({
	background: '#676767',
	color: '#fcfcfc',
	width: '32px',
	height: '32px',
	'&:hover': {
		background: '#999',
		opacity: 0.9
	}
})

const DialogCancelButton = styled(Button)({
	color: '#FFF',
	background: '#D0D0D0',
	padding: '10px 18px 8px 18px',
	margin: '5px',
	'&:hover': {
		background: '#999',
		opacity: 0.9
	},
	'@media screen and (max-width:400px)': {
		width: '-moz-available',
	}
})

const ShareNowButton = styled(Button)({
	color: '#FFF',
	background: '#019B07',
	padding: '10px 18px 8px 18px',
	margin: '5px',
	'&:hover': {
		background: '#999',
		opacity: 0.9
	},
	'@media screen and (max-width:400px)': {
		width: '-moz-available',
	}
})

const ModalTitle = styled(Grid)({
	display: 'flex',
	justifyContent: 'space-between',
	alignItem: 'center',
	flexDirection: 'row',
	width: '100%',
	borderBottom: '0.2px solid #cbc1c1',
	padding: '13px 25px 13px 31px'
})

const RightFormWrapper = styled(Form)({
	width: '100%',
	display: 'flex',
	flexDirection: 'column',
	gap: '25px',
	overflowX: 'hidden',
	paddingRight: '10px',
	maxHeight: '375px'
})

const RightFormWrapperDiv = styled('div')({
	width: '100%',
	display: 'flex',
	flexDirection: 'column',
	gap: '25px',
	overflowX: 'hidden',
	paddingRight: '10px',
	maxHeight: '375px'
})

// Customizable Area End
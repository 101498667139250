import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
	getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import firebase from "firebase";

// Customizable Area Start
import { sendAPIRequest } from '../../../components/src/utils';
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
	navigation: any;
	id: string;
	data?: any;
	// Customizable Area Start
	// Customizable Area End
}

interface S {
	// Customizable Area Start
	txtSavedValue: string;
	showSurvey: boolean;
	surveyList: any[];
	searchSurvey: string;
	loadingSurvey: boolean;
	launchSuervey: boolean;
	loading: boolean;
	selectedSurvey: any;
	// Customizable Area End
}

interface SS {
	id: any;
	// Customizable Area Start
	// Customizable Area End
}

export default class MyProjectsController extends BlockComponent<Props, S, SS> {
	// Customizable Area Start
	userSurveyCallId: any;
	// Customizable Area End

	constructor(props: Props) {
		super(props);
		this.receive = this.receive.bind(this);

		// Customizable Area Start
		this.subScribedMessages = [
			getName(MessageEnum.AccoutLoginSuccess),
			// Customizable Area Start
			getName(MessageEnum.RestAPIResponceMessage),
			// Customizable Area End
		];

		this.state = {
			// Customizable Area Start
			txtSavedValue: "",
			showSurvey: true,
			surveyList: [],
			searchSurvey: "",
			loadingSurvey: false,
			launchSuervey: false,
			loading: false,
			selectedSurvey: [],
			// Customizable Area End
		};
		runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
		// this.filterRef = React.createRef()
		// Customizable Area Start
		// Customizable Area End
	}

	async componentDidMount() {
		// Customizable Area Start
		super.componentDidMount();
		const authToken = await getStorageData('authToken');
		if (authToken == null) {
			this.props.navigation.navigate('Home')
		}
		this.handleSurvey()
		// Customizable Area End
	}
	async receive(from: string, message: Message) {
		runEngine.debugLog("Message Recived", message);
		// Customizable Area Start
		if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
			let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
			this.showAlert(
				"Change Value",
				"From: " + this.state.txtSavedValue + " To: " + value
			);
			this.setState({ txtSavedValue: value });
		}
		if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
			const apiRequestCallId = message.getData(
				getName(MessageEnum.RestAPIResponceDataMessage)
			);

			let responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);

			if (responseJson && responseJson.errors) {
				this.parseApiErrorResponse(responseJson.errors);
				this.parseApiCatchErrorResponse(responseJson.errors);
			}

			if (apiRequestCallId == this.userSurveyCallId) {
				if(this.state.searchSurvey!==""){
					const filteredData = responseJson?.data?.filter((el: any) => {
						return el?.attributes?.surveys?.data?.attributes?.survey_title?.toLowerCase().includes(this.state.searchSurvey?.toLowerCase()) || el?.attributes?.surveys?.data?.attributes?.status?.toLowerCase().includes(this.state.searchSurvey?.toLowerCase())
					})
					this.setState({ surveyList: filteredData, loading: false })
			    }
				else{
					this.setState({ surveyList: responseJson?.data, loading: false })
				}
			}
		}
		// Customizable Area End
	}


	// Customizable Area Start

	handleSurveySearch = (e: any) => {
		 this.setState({searchSurvey:e},(()=>this.handleSurvey()))
	}

	handleSurvey = async() => {
		this.setState({ loading: true })
		const authToken = await getStorageData('authToken');
		this.userSurveyCallId = sendAPIRequest(`${configJSON.userSurveyEndPoint}?project_id=${this.props.navigation.getParam('id')}`, {
			method: 'GET',
			headers: { 'token': authToken || '' }
		})
	}

	handleSurveyClose = () => {
		// this.setState({ showSurvey: false })
		this.props.navigation.goBack()
	}

	handleLaunchSurveyOpen = (data: any) => {
		this.setState({ launchSuervey: true, selectedSurvey: data })
	}

	handleLaunchSurveyClose = () => {
		this.setState({ launchSuervey: false, selectedSurvey: [] })
	}
	// Customizable Area End
}

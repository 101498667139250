import React from "react";
// Customizable Area Start
import TopNavBar from "../../../components/src/TopNavbar";
import {
  Box, Grid,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  MenuItem,
  Avatar,
  Typography,
  ListItemText,
} from '@material-ui/core';
import DeleteModal from '../../../components/src/DeleteModal.web';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import UserContext from "../../../components/src/UserProvider";
import { styled } from '@material-ui/styles'
import LeftMenuWeb from "../../../components/src/LeftMenuWeb";
import { iconAdd } from '../../JobListing2/src/assets';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Flex from '../../../components/src/Flex.web'
import { Formik } from 'formik'
import FormModal, {
  InputRow,
  MInput,
  MTextArea,
  MSelect,
  MCountedTextArea,
  MDatePicker,
} from '../../../components/src/FormModal.web'
import SearchSelect from "../../../components/src/SearchSelect.web";
import OverlayLoader from '../../../components/src/Loader.web'
import { deleteImg, editImg } from './assets'

import ActivityDashboardController, {
  Props,
} from "./ActivityDashboardController";
// @ts-ignore
import classes from './ActivityDashboardWeb.module.css'
// Customizable Area End
export default class ActivityDashboard extends ActivityDashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  ActivityPopup() {
    const initialValues = {
      milestoneId: this.state?.selectedId,
      milestoneName: this.state?.selectedMilestomeName,
      activityId: this.state?.selectedActivityId,
      activityName: '',
      description: '',
      is_dependent: 'Yes',
      predecessor: '',
      inputs: '',
      assigned_date: new Date(),
      start_date: new Date(),
      actual_Start_date: new Date(),
      end_date: new Date(),
      actual_end_date: new Date(),
      criticality: '',
      status: '',
      comments: '',
      owner: []
    }

    const editValues = this.extractAttributes(this.state.editedData);

    if (this.state.modal !== 'create') return <></>
    else
      return <Formik enableReinitialize initialValues={this.state.isEdit ? editValues : initialValues} onSubmit={this.handleSubmit} validationSchema={this.validationSchema} >
        {({ values, errors }: any) => (
          <div className={classes.modalTopCls}>
            <FormModal headerTitle={
              <CreateHeader>
              <div style={{display: 'flex',flexDirection:'row', alignItems:'center'}}> 
              <ArrowBackIosIcon style={{fontWeight:100,color:'gray',cursor: "pointer"}} onClick={this.closeModal}/>
              <div> {`${this.state.actionType} ACTIVITY`}
              </div>
              </div>
              {this.state.actionType == "VIEW" &&
              <div style={{marginRight:'50px'}}>
              <Button 
                variant="outlined" 
                style={{color:'green', marginRight:'5px'}} startIcon={<EditIcon />} 
                onClick={this.handleEdit(this.state.deleteActivityId, "EDIT")}>
                   EDIT 
              </Button>
              <Button 
                variant="outlined" 
                onClick={this.openDeletePopup(this.state.deleteActivityId)} 
                style={{color:'red'}} startIcon={<DeleteIcon />}>
                   DELETE 
              </Button>
              </div>}
              </CreateHeader>} submitLabel="SAVE" onClose={this.closeModal} >
              {this.state.isEdit ?
                <InputRow label="Milestone ID*" input={<MSelect name="milestoneId" value={values?.milestoneId} onChange={this.selectMileStone}>
                {this.state.milestoneIds?.map((ids:any) => {
                  return (
                    <MenuItem value={ids?.attributes?.id}>{ids?.attributes?.id}</MenuItem>
                  )
                })}
              </MSelect>} /> : 
              <InputRow label="Milestone ID*" input={<MSelect name="milestoneId" value={values.selectedId} onChange={this.selectMileStone}>
              {this.state.milestoneIds?.map((ids:any) => {
                return (
                  <MenuItem value={ids?.attributes}>{ids?.attributes?.id+" - "+ids?.attributes?.milestone_name}</MenuItem>
                )
              })}
            </MSelect>} />}
              <InputRow label="Milestone Name*" input={<><MInput name="milestoneName" style={{ background: '#ddd7d7bf' }} disabled /></>} />
              <InputRow label="Activity ID*" input={<><MInput name="activityId" style={{ background: '#ddd7d7bf' }} disabled /></>} />
              <InputRow label="Activity Name*" input={<><MInput name="activityName" /></>} />
              <InputRow label="Description*" input={<><MCountedTextArea name="description" maxLength={500} /></>} />
              <InputRow label="Is Dependent*" input={<MSelect name="is_dependent" >
                  <MenuItem value={"Yes"}>{"Yes"}</MenuItem>
                  <MenuItem value={"No"}>{"No"}</MenuItem>
              </MSelect>} />
              {this.state.isEdit ?
                <InputRow label="Predecessor (Note : Mandatory if value is Yes in Dependent field)*" input={
              <MSelect name="predecessor" disabled={values?.is_dependent == "No"} >
                {this.state.allPredecessor?.map((data:any)=> {
                    return(
                      <MenuItem value={data.id}>{data.attributes.activity_id}{data.attributes.activity_name}</MenuItem>
                      )}
                    )}
              </MSelect>} /> : 
              <InputRow label="Predecessor (Note : Mandatory if value is Yes in Dependent field)*" input={
                <MSelect name="predecessor" disabled={values?.is_dependent == "No"} >
                  {this.state.allPredecessor?.map((data:any)=> {
                    return(
                      <MenuItem value={data.id}>{data.attributes.activity_id}{data.attributes.activity_name}</MenuItem>
                      )}
                    )}
                </MSelect>} />}
              <InputRow label="Inputs*" input={<><MCountedTextArea name="inputs" maxLength={100}/></>} />
              <Flex gap="10px" style={{display: 'flex', flexWrap:'wrap'}}>
              <Flex1>
              <InputRow label="Owner*" input={<> <SearchSelect
										multiple={false}
										pagination={this.state.moderatorPagination}
										hasMore={!!this.state.moderatorPagination?.next_page}
										nextPage={this.paginateOwner}
										loading={false}
										onSearch={this.handleModeratorSearchQuery}
										allItems={this.state.moderatorLists}
										items={this.state.moderatorLists}
										name="owner"
										selectProps={{ onClose: ()=>{} }}
										SelectItem={SelectItemUser}
                    SelectedItem={SelectedItem}
										getOptionValue={(user: any) => user.first_name} /></>} />
              </Flex1>
              <Flex1>
              <InputRow label="Assigned Date*" input={<><MDatePicker name="assigned_date" /></>} />
              </Flex1>
              </Flex>
              <Flex gap="10px" style={{display: 'flex', flexWrap:'wrap'}}>
              <Flex1>
              <InputRow label="Start Date*" input={<><MDatePicker name="start_date" /></>} />
              </Flex1>
              <Flex1>
              <InputRow label="Actual Start Date*" input={<><MDatePicker name="actual_Start_date" /></>} />
              </Flex1>
              </Flex>
              <Flex gap="10px" style={{display: 'flex', flexWrap:'wrap'}}>
              <Flex1>
              <InputRow label="End Date*" input={<><MDatePicker name="end_date" minDate={values?.start_date} /></>} />
              </Flex1>
              <Flex1>
              <InputRow label="Actual End Date*" input={<><MDatePicker name="actual_end_date" minDate={values?.start_date} /></>} />
              </Flex1>
              </Flex>
              <Flex gap="10px" style={{display: 'flex', flexWrap:'wrap'}}>
              <Flex1>
              <InputRow label="Criticality*" input={<MSelect name="criticality" >
                <MenuItem value={"Low"}>{"Low"}</MenuItem>
                <MenuItem value={"Medium"}>{"Medium"}</MenuItem>
                <MenuItem value={"High"}>{"High"}</MenuItem>
              </MSelect>} />
              </Flex1>
              <Flex1>
              <InputRow label="Status*" input={<MSelect name="status">
                <MenuItem value={"Assigned"}>{"Assigned"}</MenuItem>
                <MenuItem value={"Reassigned"}>{"Reassigned"}</MenuItem>
                <MenuItem value={"On Hold"}>{"On Hold"}</MenuItem>
                <MenuItem value={"Suspended"}>{"Suspended"}</MenuItem>
              </MSelect>} />
              </Flex1>
              </Flex>
              <InputRow label="Comments*" input={<><MTextArea name="comments" /></>} />
            </FormModal>
          </div>
        )}
      </Formik>
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const ActivityPopup = this.ActivityPopup();
    console.log("all activity = ", this.state.editedData, this.state?.isEdit)
    const activities:any = this.state.allActivity;
    // Customizable Area End
    return (
      // Customizable Area Start
      <MainBox>
        {ActivityPopup}
        {this.state?.deleteAccess && <DeleteModal label="‘Are you sure you want to delete Activity?" onClose={this.closeDeleteModal} onSubmit={this.deleteActivity(this.state?.deleteActivityId)} />}
        <Box>
          <TopNavBar navigation={this.props.navigation} id={""} />
        </Box>

        <OuterGrid container spacing={3}>
          <LeftGrid item xs={12} sm={12} md={3} lg={3}>
            <Box >
              <LeftMenuWeb navigation={this.props.navigation} id={""} />
            </Box>
          </LeftGrid>
          <MiddleGrid item xs={12} sm={12} md={6} lg={6} >
            <SurveyCardWrapper>
              <OverlayLoader loading={this.state.loading} />
              <HeadWrapper>
                <Head>
                <ArrowBackIosIcon style={{fontWeight:100,color:'gray'}} onClick={()=> this.props.navigation.goBack()}/>
                  PROJECT PHASE AND MILESTONE : {this.projectName}
                </Head>
              </HeadWrapper>
              <FilderWrapper>
                <RoundedButtonBaseTabs>
                  <CorporateLicenceTabs data-test-id="MileStoneButton" style={{ backgroundColor: "white", color: "#00A100" }} 
                  onClick={async()=>{
                    const management_id = await this.getDataFromStorage('projectId')
                    this.props.navigation.history.push(`/ProjectMilestone/${management_id}`)
                  }}
                  >
                    MILESTONE
                  </CorporateLicenceTabs>
                  <IndividualUserTabs style={{ backgroundColor: "#00A100", color: "white" }}>
                    ACTIVITY
                  </IndividualUserTabs>
                </RoundedButtonBaseTabs>
                <RoundedButtonBase
                  id="addActivityBtn"
                  onClick={this.openModal}
                  variant="contained"
                  disableElevation
                  startIcon={<img src={iconAdd} width={30} style={{ marginLeft: '-5px' }} />}>
                  ADD ACTIVITY
                </RoundedButtonBase>
              </FilderWrapper>
              <TableContainer>
                <Table style={{ width: '100%', background: '#fff', overflowX: 'hidden' }}>
                  <TableHead>
                    <TableRow>
                      <TH>Milestone ID</TH>
                      <TH>Milestone Name</TH>
                      <TH>Activity ID</TH>
                      <TH>Activity Name</TH>
                      <TH>Owner</TH>
                      <TH>Description</TH>
                      <TH>Is Dependent</TH>
                      <TH>Predecessor</TH>
                      <TH>Inputs</TH>
                      <TH>Actions</TH>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {activities?.map((act:any)=> {
                      return(
                    <TableRow>
                      <TR>{act.attributes.project_milestone_id}</TR>
                      <TR>{act.attributes.milestone_name}</TR>
                      <TR onClick={this.handleEdit(act?.id, "VIEW")} style={{cursor:'pointer'}} >{act?.attributes?.activity_id}</TR>
                      <TR>{act.attributes.activity_name}</TR>
                      <TR>{act.attributes.activity_owner.data.attributes.first_name}</TR>
                      <TR>{act.attributes.description}</TR>
                      <TR>{act.attributes.is_dependent}</TR>
                      <TR>{act.attributes.predecessor?.length > 0 ? "Yes" : "No"}</TR>
                      <TR>{act.attributes.inputs}</TR>
                      <TR>{<>
                            <BtnImg src={editImg} style={{marginRight: '15px'}} onClick={this.handleEdit(act?.id, "EDIT")}/>
                            <BtnImg src={deleteImg} onClick={this.openDeletePopup(act?.id)}/>
                            </>}
                      </TR>
                    </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </SurveyCardWrapper>
          </MiddleGrid>
        </OuterGrid>

      </MainBox>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
 export const SelectItemUser = ({ item, value }: { item: any, value: any[] }) => {
	return <div style={{ width: '100%', display: 'flex', flexDirection: 'row-reverse', alignItems: 'center' }}>
		<ListItemText style={{ flex: 2 }} disableTypography primary={
			<div style={{ display: "flex", alignItems: "center" }}>
				<Avatar alt="Remy Sharp" src={item?.profile_pic} style={{ width: "30px", height: "30px", marginRight: "10px" }} />
				<Typography style={{ color: "#333232", fontWeight: "bold", width: "30%", fontSize: 12, overflow: 'hidden', textOverflow: 'ellipsis' }}>{item?.first_name} {item?.last_name}</Typography>
				<Typography component={'div'} style={{ width: '65%', fontSize: 12, fontWeight: "bold", overflow: 'hidden', textOverflow: 'ellipsis' }}>{item?.email}</Typography>
      </div>
		} />
	</div>
}
export const SelectedItem = ({ items, value }: { items: any, value: any }) => {
  return <>
      <div 
      style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}
      >
          {(items || []).map((selectedValue: any, i: any) => {
              return (<Typography key={i} align="left">
                  <div 
                  style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <Avatar className='ml10' style={{ width: '30px', height: '30px', }} src={selectedValue.profile_pic} aria-label="profile-pic"></Avatar>
                    <span className='ml5'>
                      {`${selectedValue.first_name} ${selectedValue.last_name}`  || ''}
                    </span>
                  </div>
              </Typography>)})}
      </div>
  </>
}
ActivityDashboardController.contextType = UserContext

const MainBox = styled(Box)({
  backgroundColor: "#edebeb",
  display: "flex",
  flexWrap: 'wrap',
  flexDirection: "column",
  paddingBottom: "20px",
  overflowX: "hidden",
})

const OuterGrid = styled(Grid)({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
})

const LeftGrid = styled(Grid)({
  maxWidth: '300px',
  '@media screen and (max-width: 985px)': {
    maxWidth: '200px',
  },
  '@media screen and (max-width: 768px)': {
    display: 'none',
    visibility: 'hidden',
  },
})

const MiddleGrid = styled(Grid)({
  width: 'calc(100vw - 300px)',
  maxWidth: 'unset',
  flexBasis: 'unset',
  justifyContent: 'center',
  '@media screen and (max-width: 768px)': {
    width: '100%'
  }
})

const SurveyCardWrapper = styled(Box)({
  width: '95%',
  height: 'auto',
  marginTop: '20px',
  marginRight: '20px',
  padding: '20px',
})

const FilderWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '15px',
})

const CreateHeader = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontSize: '18px',
  fontWeight: 'bold',
  fontFamily: 'HelveticaNeue'
})

const HeadWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%'
})

const RoundedButtonBaseTabs = styled('div')((props: any) => ({
  height: "47px",
  fontWeight: 700,
  display: 'flex',
  textAlign: 'center',
  justifyContent: 'center',
  backgroundColor: "#FFFFFF",
  flex: "wrap",
  color: "#009E00",
  borderRadius: '38px',
  border: " 2px solid #009B07"
}))

const RoundedButtonBase = styled(Button)({
  backgroundColor: '#009c05',
  color: 'white',
  borderRadius: '20px',
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: '#009c05',
  }
})

const Flex1 = styled('div')({
  flex: 1,
  '@media screen and (max-width: 480px)': {
    flex: 'none',
    width: '100%',
  }

})

const CorporateLicenceTabs = styled(Button)({
  border: "2px solid transparant",
  color: "#009B07",
  fontWeight: 600,
  backgroundColor: "#FFFFFF",
  textTransform: "none",
  borderRadius: "38px",
  height: "100%",
  padding: "0 65px 0 65px",
  '@media screen and (max-width: 1110px) and (min-width: 769px)': {
    padding: "0 35px 0 35px",
  },
  '@media screen and (max-width: 624px)': {
    padding: "0 35px 0 35px",
  },
  '@media screen and (max-width: 500px)': {
    padding: "0 25px 0 25px",
  }
});

const IndividualUserTabs = styled(Button)({
  border: "2px solid transparant",
  color: "#009B07",
  fontWeight: 600,
  backgroundColor: "#FFFFFF",
  textTransform: "none",
  borderRadius: "38px",
  height: "100%",
  padding: "0 50px 0 50px",
  '@media screen and (max-width: 1110px) and (min-width: 769px)': {
    padding: "0 30px 0 30px",
  },
  '@media screen and (max-width: 624px)': {
    padding: "0 30px 0 30px",
  },
  '@media screen and (max-width: 500px)': {
    padding: "0 20px 0 20px",
  }
});

const TH = styled(TableCell)({
  color: "#fff",
  fontWeight: 600,
  lineHeight: "1.5rem",
  fontSize: '0.83rem',
  padding: '16px 10px',
  textAlign: 'center',
  borderRight: '1px solid #c5c0c0',
  background: '#00A100'
})

const TR = styled(TableCell)({
  color: "#373232;",
  lineHeight: "1.5rem",
  fontSize: '0.85rem',
  padding: '16px 10px',
  textAlign: 'center',
  position: 'relative',
  borderRight: '1px solid #c5c0c0',
})

const TRLast = styled(TableCell)({
  color: "#000",
  lineHeight: "1.5rem",
  fontSize: '0.85rem',
  padding: '16px 10px',
  textAlign: 'center',
  position: 'relative',
})



const Head = styled('div')({
  display: 'flex',
  alignItems: 'center',
  color: '#000',
  margin: '10px 0px 30px 12px',
  fontSize: '1rem',
  fontFamily: 'HelveticaNeue',
  fontWeight: 600,
  '@media screen and (max-width: 768px)': {
    margin: '20px 0px 10px 12px',
  },
})

const BtnImg = styled('img')({
  width: 21
})

// Customizable Area End

/**
*	Generic form modal with two action buttons, one close button and title 
**/
import React, { useEffect } from 'react'
import { CreateCSSProperties, styled } from '@material-ui/styles'
import Rating, {RatingProps} from './Rating.web'
import {
  Avatar,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  SelectProps,
  TextField as TextFieldBase,
  TextFieldProps,
  Typography
} from '@material-ui/core';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { DatePicker, TimePicker } from '@material-ui/pickers'
import { Formik, Form, useField, useFormikContext } from 'formik'
import SelectInput from './SelectInput.web'
import FileDropBase from './FileDrop.web'
import Flex from './Flex.web'
import CountedTextArea from './CountedTextArea.web'
const CloseIcon = require('./ic_close.png')
const CalendarIcon = require('./ic_calendar.png')
const ClockIcon = require('./ic_clock.png')

interface FormModalProps {
  headerTitle?: string | JSX.Element;
  onClose: () => void;
  onSubmit?: (() => void) | ((e: React.FormEvent<{}>) => void);
  children: React.ReactNode;
  cancelLabel?: string;
  submitLabel?: string;
  modalStyle?: any;
  isDirty?: boolean; // if true context.dirty is not checked, this enables button for edit/update form
  onSubmitDisable?: boolean;
  buttonHide?:boolean;
}

interface SelectInputProps extends SelectProps {
  onSelectInput?:any;
}

export default function FormModal({ headerTitle, onClose, onSubmit, children, cancelLabel, submitLabel, isDirty, modalStyle, onSubmitDisable,buttonHide }: FormModalProps) {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => { document.body.style.overflow = "auto"; }
  }, [])
  return <Wrapper>
    <Modal style={modalStyle || {}}>
      <Scrollbars
        autoHide
        hideTracksWhenNotNeeded
        renderTrackHorizontal={
          (props: any) => <div {...props} style={{ display: 'none' }} className="track-horizontal" />
        }>
        <ModalBody translate="yes">
          <TypoHead>
            <TYPO variant="h6">
              {headerTitle}
            </TYPO>
            <CloseButton onClick={onClose}>
              <img src={CloseIcon} width="35px" />
            </CloseButton>
          </TypoHead>
          <Divider />
          <Flex col gap="25px">
            {children}
          </Flex>
          <Divider />
          {!buttonHide?<ModalActions>
            <CardButton onClick={onClose}>{cancelLabel || 'Cancel'}</CardButton>
            <FormikSubmit isDirty={isDirty} component={FormButton} props={{}} onSubmitDisable={onSubmitDisable}>
              {submitLabel || 'Submit'}
            </FormikSubmit>
          </ModalActions>:<></>}

        </ModalBody>
      </Scrollbars>
    </Modal>
  </Wrapper>
}


interface InputRow {
  label: string | JSX.Element;
  input: JSX.Element;
  notWrapped?: boolean;
  rowClass?: string;
  labelClass?: string;
  style?: any;
  icon?: any;
}

export function FRating(props: RatingProps){
  const [field, meta, helpers] = useField('rating')
  function handleChange (rating: number){
    helpers.setValue(rating)
    helpers.setTouched(true)
    props.onChange&&props.onChange(rating)
  }
  return <Rating {...props} onChange={handleChange} />
}

export function InputRow({ icon,label, input, notWrapped, rowClass,style, labelClass }: InputRow) {
  if (!notWrapped)
    return <InputRowWrapper className={rowClass} style={style}>
      <FInputLabel style={{ display: "flex" }} className={labelClass} ><GreyLabel>{label}</GreyLabel> {icon && <Icon> <img height="20px" width="20px" src={icon} alt="" /></Icon>}</FInputLabel>
      <div>{input}</div>
    </InputRowWrapper>

  return <InputRowWrapper>
  
    {label}
    {input}
  </InputRowWrapper>
}

export const FInputLabel = styled(Typography)(  {
  fontSize: '0.813rem !important',
  lineHeight: '1.345rem',
  fontWeight: 600,
  marginBottom: '5px',
  // fontFamily: 'helveticaneue',
}) as typeof Typography


export function MSelect(props: SelectInputProps) {
  const [field, meta, helpers] = useField(props.name || '')
  return <>
    <SelectInput fullWidth error={meta.touched && Boolean(meta.error)} {...field} {...props}>
      {props.children}
    </SelectInput>
    <Typography variant="caption" color="error"> {meta.touched && Boolean(meta.error) ? String(meta.error) : null} </Typography>
  </>
}

export function MInput(props: TextFieldProps) {
  const [field, meta, helpers] = useField(props.name || '')
  return <>
    <MInputBase variant="outlined" error={meta.touched && Boolean(meta.error)} {...field} fullWidth {...props} />
    <Typography variant="caption" color="error"> {meta.touched && Boolean(meta.error) ? String(meta.error) : null} </Typography>
  </>
}

export function MFileDrop(props: any) {
  const [field, meta, helpers] = useField(props.name || '')
  // for already uploaded document
  const [uploaded_field, uploaded_meta, uploaded_helpers] = useField("uploaded_"+(props.name||'')) 
  
  const onChange = (e: any) => {
    helpers.setTouched(true, true)
    if (props.multiple) {
      const newFiles = (field.value || []).concat(Array.from(e.target.files))
      helpers.setValue(newFiles)
    } else {
      helpers.setValue(e.target.files[0])
    }
  }

  // only called for multi-input
  const onRemove = (e: React.MouseEvent<HTMLButtonElement>) => {
    const filename = e.currentTarget.dataset.filename
    const id = e.currentTarget.dataset.id
    if(id) {
      const newFiles = uploaded_field.value.filter((file: any)=>file.id!=id)
      uploaded_helpers.setValue(newFiles)
      if (typeof props.onDelete === 'function') {
        props.onDelete(id);
      }      
    }
    else if(filename) {
      const newFiles = field.value.filter((file: File) => file.name !== filename)
      helpers.setValue(newFiles)
    }
  }

  const onSingleRemove = (e: any) => {
    e.stopPropagation()
    helpers.setValue('')
  }

  const fileUrl = field.value
  const inputProps = props.inputProps || {}
  const previewFiles = (uploaded_field.value||[]).concat(field.value || []) 
  console.log({previewFiles})
  return <>
    <FileDropBase onRemove={onSingleRemove} showDelete={!!fileUrl} fileUrl={fileUrl} inputProps={{ onChange, ...inputProps, multiple: props.multiple }} />
    {/*<input type="file" onChange={onChange}  />*/}
    <Typography>For an ideal image representation, pixel dimensions should be 1080*550</Typography>
    {props.multiple && (field.value||uploaded_field.value) && <PreviewFiles files={previewFiles} onRemove={onRemove} />}
    <Typography variant="caption" color="error"> {meta.touched && Boolean(meta.error) ? String(meta.error) : null} </Typography>
  </>
}

const PreviewFiles = ({ files, onRemove }: { files: FileOrUploadedFile[], onRemove: (e: React.MouseEvent<HTMLButtonElement>) => void }) => {
  const fp = files?.map && files.map(
        (file: FileOrUploadedFile) => {
          if(file.name)
            return <FilePreview 
              key={file.name} 
              file={file} 
              onRemove={onRemove} />
          else
            return <FilePreview 
              key={file.id}
              file={file}
              onRemove={onRemove}
            />
        }
      )
  console.log({previewFiles: fp})
  return <Flex wrap gap="5px">
    {
      fp
    }
  </Flex>
}
interface FileOrUploadedFile extends File{
  id: string;
  url: string; 
  filename: string;
}

// type FileOrUploadedFile = 
//   | File
//   | {
//       id: string,
//     url: string,
//     filename: string,
//   }; 

const FilePreview = ({ file, onRemove }: { file: FileOrUploadedFile, onRemove: (e: React.MouseEvent<HTMLButtonElement>) => void }) => {
  const CloseButton = styled(Button)({
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 1010
  })

  const clipEllipses = (name: string) => (name.slice(0, 30) + ((name.length >= 30) ? "..." : ""))
  console.log("previewFiles")
  return <div style={{ position: 'relative', width: 'max-content', marginTop: '10px' }}>
    <CloseButton data-id={file.id} data-filename={file?.filename || file?.name} onClick={onRemove}><img src={CloseIcon} width="26px" /></CloseButton>
    {(file.type || "").startsWith('image') && <PreviewImage alt={file?.name} src={file.url||URL.createObjectURL(file)} />}
  </div>
}

const PreviewTileStyle: CreateCSSProperties<{}> = {
  width: '100px',
  height: '150px',
  borderRadius: '10px',
  objectFit: 'cover',
  padding: '0px 5px',

}

const PreviewImage = styled('img')({
  ...PreviewTileStyle
})

export function MTimePicker(props: any) {
  const [field, meta, helpers] = useField(props.name || '')
  const value = field.value
  const onChange = (e: any) => {
    props.onChange && props.onChange(e)
    helpers.setValue(e)
  }

  let inputProps: any = {
    endAdornment: <InputAdornment position="end"><img src={ClockIcon} width="20px" /></InputAdornment>
  }
  if (props.InputProps)
    inputProps = { ...props.InputProps, ...inputProps }

  return <>
    <TimePickerBase
      inputVariant="outlined"
      fullWidth
      {...props}
      error={meta.touched && Boolean(meta.error)}
      InputProps={inputProps}
      value={value}
      onChange={onChange} />
    <Typography variant="caption" color="error"> {meta.touched && Boolean(meta.error) ? String(meta.error) : null} </Typography>
  </>
}

export function MCountedTextArea(props: any) {
  const [field, meta, helpers] = useField(props.name || '')
  const onChange = (e: any) => helpers.setValue(e.target.value)
  return <>
    <CountedTextArea maxLength={props.length || 1000} {...field} error={meta.touched && Boolean(meta.error)} {...props} onChange={onChange} />
    <Typography variant="caption" color="error"> {meta.touched && Boolean(meta.error) ? String(meta.error) : null} </Typography>
  </>
}
export function MTextArea(props: any) {
  const [field, meta, helpers] = useField(props.name || '')
  const onChange = (e: any) => helpers.setValue(e.target.value)
  return <>
    <CountedTextArea {...field} error={meta.touched && Boolean(meta.error)} {...props} onChange={onChange} />
    <Typography variant="caption" color="error"> {meta.touched && Boolean(meta.error) ? String(meta.error) : null} </Typography>
  </>
}

interface FormikSubmitProps {
  children: any;
  component: any;
  props: any;
  isDirty?: boolean; // if true context.dirty is not checked, this enables button for edit/update form
  onSubmitDisable?:boolean;
}

export function FormikSubmit(props: FormikSubmitProps) {
  const SubmitButton = props.component
  const context = useFormikContext()
  if (!SubmitButton) return <></>
  return <SubmitButton {...(props.props || {})} disabled={(!(context.isValid && (context.dirty || props.isDirty )))|| props.onSubmitDisable} type="submit">
    {props.children}
  </SubmitButton>
}

export function MDatePicker(props: any) {
  const [field, meta, helpers] = useField(props.name || '')
  let value = field.value // default value is null

  const onChange = (e: any) => {
    props.onChange && props.onChange(e)
    helpers.setValue(e)
  }

  let inputProps: any = {
    endAdornment: <InputAdornment position="end"><img src={CalendarIcon} width="20px" /></InputAdornment>
  }
  if (props.InputProps)
    inputProps = { ...props.InputProps, ...inputProps }
  return <>
    <DatePickerBase data-testid = "datePicker" error={meta.touched && Boolean(meta.error)} inputVariant="outlined" fullWidth format="dd/MM/yyyy" value={value} {...props} InputProps={inputProps} onChange={onChange} />
    <Typography variant="caption" color="error"> {meta.touched && Boolean(meta.error) ? String(meta.error) : null} </Typography>
  </>
}

export function MCheckboxes(props: any) {
  const [field, meta, helpers] = useField(props.name || '')
  const handleChange = (e: any) => {
    const { checked, value } = e.target
    if (checked) helpers.setValue([...field.value, value])
    else helpers.setValue(field.value.filter((x: string) => x !== value))
  }
  return <>
    <div>
      {
        props.options?.map(
          (option: any) => <span key={option[0]}>
            <Checkbox 
              color="primary" 
              checked={field.value.includes(option[1])} 
              value={option[1]} 
              name={props.name} 
              onChange={handleChange} 
            /> {option[0]}
          </span>)
      }
    </div>
    <Typography variant="caption" color="error"> {meta.touched && Boolean(meta.error) ? String(meta.error) : null} </Typography>
  </>
}

const TimePickerBase = styled(TimePicker)({
  '& > div > input': {
    padding: '13px 10px'
  }
})

const MInputBase = styled(TextFieldBase)({
  '& > div > input': {
    padding: '13px 10px'
  }
})

const DatePickerBase = styled(DatePicker)({
  '& > div > input': {
    padding: '13px 10px'
  }
})

const FlexCol = styled('div')({
  display: 'flex',
  flexDirection: 'column',
})

const InputRowWrapper = styled(FlexCol)({
  gap: '5px',
})


export const Wrapper = styled('div')({
  top: 0,
  left: 0,
  position: 'fixed',
  minWidth: '100vw',
  minHeight: '100vh',
  height: '100%',
  background: 'rgba(0,0,0,0.5)',
  display: 'flex',
  justifyContent: 'center',
  // alignItems: 'center',
  zIndex: 10,
}) as any;

export const Modal = styled(Paper)({
  borderRadius: '23px',
  margin: '20px 40px',
  width: '100%',
  marginRight: '40px',
  maxWidth: '600px',
  minHeight: '150px',
  padding: '20px 10px 20 20',
  position: 'relative',
  overflowY: 'auto',
  zIndex: 15,
  // '@media screen and (max-width: 480px)': {
  //   top: '20%',
  // },
})

export const ModalBody = styled(Form)({
  display: 'flex',
  gap: '15px',
  flexDirection: 'column',
  paddingRight: '15px'
})

const ModalActions = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '20px',
  gap: '10px'
}) as any;

export const CloseButton = styled(Button)({
  position: 'absolute',
  top: '-5px',
  right: '0px'
})

const BaseButton = styled(Button)({
  color: '#FFFFFF !important',
  padding: '5px 25px',

})

const GreenButton = styled(BaseButton)({
  background: '#009b21',
  '&:hover': {
    opacity: 0.9,
    background: '#009b21',
  },
})

const CardButton = styled(BaseButton)({
  background: '#d0d0d0',
  '&:hover': {
    opacity: 0.9,
    background: '#009c05',
  },
})

const GreyLabel = styled('div')({
  fontSize: '13px',
  color: '#404040',
  fontFamily: 'roboto'
})

const Icon = styled('div')({
  height: "20px",
  width: "20px",
  marginLeft:"5px"
})

const TypoHead = styled('div')({
  '@media screen and (max-width:600px)':{
    marginTop:'6px',
  }
})

const TYPO = styled(Typography)({
  '@media screen and (max-width:600px)':{
    fontSize:'1rem',
  }
})


const FormButton = (props: any) => {
  const Btn = props.disabled ? CardButton : GreenButton
  return <Btn {...(props || {})}>{props.children}</Btn>
} 
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

import { toast } from 'react-toastify';
import { sendAPIRequest } from '../../../components/src/utils';
import { sendSharePopupMessage } from '../../share/src/Messages';
import {  sendProductUpdatedMessage } from './Message'
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  modal: 'create' | 'rate' | '';
  modalRating: number;
  token: string;
  openModal: boolean;
  productData: any;
  eventId: string;
  productCommentData: any[];
  pagination?: {
    next_page?: number;
  };
  userComment: string;
  userReply: string;
  reply_id: '';
  openCommentEmoji: boolean;
  openCommentReplyEmoji: boolean;
  openReply: '';
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CourseDetailController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getProducttDetailCallId = '';
  getAllProductCommentCallId = '';
  productCommentCallId = '';
  interestedEventId: string = "";
  eventLikeCallId = '';
  eventCommentReplyCallId = '';
  getMoreCommentCallId = '';
  rateProductRequestId: string = "";
  apiFavCourseCallId: string[] = []
  favCallIdToCourseId: { [key: string]: string } = {}
  likeCallIdToCommentId: { [key: string]: string } = {}

  async componentDidMount() {
    super.componentDidMount();
    const authToken = await getStorageData('authToken');
    if (authToken == null) {
      this.props.navigation.navigate("Home");
    } else {
    }

    this.onInitialized();
  }

  onInitialized = async() => {
    const token = await getStorageData('authToken');
    const product_id = this.props.navigation.getParam('product_id')
    this.getProducttDetailCallId = sendAPIRequest(configJSON.getProductByIdEndpoint.replace(':pid', product_id), {
      method: 'GET',
      headers: { token }
    })

    this.getAllComments();
  }

  getAllComments = async() => {
    const token = await getStorageData('authToken');
    const product_id = this.props.navigation.getParam('product_id')
    const body = new FormData()
    body.append("product_id", product_id)
    console.log("product_id", product_id, body);

    this.getAllProductCommentCallId = sendAPIRequest(`${configJSON.getAllCommentEndpoint}?product_id=${product_id}`, {
      method: 'GET',
      headers: { token },
    })
  }
  onCloseModal = () => {
    this.setState({ openModal: false });
    if(this.props.navigation.history.location.pathname.includes('CfProductListing11')){
      this.props.navigation.history.push(`/MyMarketplace/CfProductListing11`,{comment:true});
    }
    else{
      this.props.navigation.goBack()
    }
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReportModalOpenMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: '',
      modal: '',
      modalRating: 0,
      openModal: true,
      eventId: '',
      productData: {},
      productCommentData: [],
      userComment: '',
      userReply: '',
      reply_id: '',
      openCommentEmoji: false,
      openCommentReplyEmoji: false,
      openReply: '',
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      
      if (responseJson && responseJson.errors) {
        // this.setState({ refresh: false });
        this.parseApiErrorResponse(responseJson.errors);
        this.parseApiCatchErrorResponse(responseJson.errors);
      } else if (this.getProducttDetailCallId === apiRequestCallId){
        const productData = { ...responseJson.data.attributes, id: responseJson.data.id }
        this.setState({ 
          productData
        })
      } else if (this.getAllProductCommentCallId === apiRequestCallId){
        console.log("comment stored", responseJson)
        let productCommentData = responseJson?.comments?.data?.map((d: any) => ({ ...d.attributes, id: d.id })) || []
        this.setState({ 
          productCommentData, pagination: responseJson?.meta?.pagination
        })
        console.log("productCommentData ", productCommentData);
      } else if (this.productCommentCallId === apiRequestCallId){
        let userComment = '';
        this.setState({ 
          userComment
        })
        this.getAllComments();
      } else if (this.eventLikeCallId === apiRequestCallId){
        const comment_id = this.likeCallIdToCommentId[apiRequestCallId];
        let productCommentData = this.state.productCommentData;
        let updateItem = productCommentData.find((x) => x.id == comment_id);
        let index = productCommentData.indexOf(updateItem);
        delete this.likeCallIdToCommentId[apiRequestCallId]
        //let comment_id = responseJson?.data?.attributes?.likeable_id
        if (responseJson?.data) {
          updateItem.like_count += 1;
          updateItem.is_like = true;
          const likeData = { ...responseJson.data, ...responseJson.data?.attributes }
          updateItem.like_id = [...(this.state.productData.like_id || []), likeData];
          productCommentData[index] = updateItem;
          this.setState({productCommentData});
        }
        else {
          updateItem.like_count -= 1;
          updateItem.is_like = false;
          updateItem.like_id = [];
          productCommentData[index] = updateItem;
          this.setState({productCommentData});
        }
      } else if (this.eventCommentReplyCallId === apiRequestCallId){
        let new_reply = responseJson.data ? { ...responseJson.data.attributes, id: responseJson.data.id, like_count: 0 } : []
        if (new_reply) {
          let productCommentData = this.state.productCommentData;
          let updateItem = productCommentData.find((x) => x.id == this.state.reply_id);
          let index = productCommentData.indexOf(updateItem);
          updateItem.replies.push(new_reply);
          productCommentData[index] = updateItem;
          let userReply = '';
          this.setState({ 
            userReply, productCommentData, reply_id: ''
          })
        }
      } else if (this.getMoreCommentCallId === apiRequestCallId){
        let oldCourseCommentData = responseJson?.comments?.data?.map((d: any) => ({ ...d.attributes, id: d.id })) || []
        let productCommentData = [...this.state.productCommentData, ...oldCourseCommentData] ;
        this.setState({ 
          productCommentData, pagination: responseJson?.meta?.pagination
        })
      } else if (this.apiFavCourseCallId.includes(apiRequestCallId)) {
        const postId = this.favCallIdToCourseId[apiRequestCallId]
        this.apiFavCourseCallId = this.apiFavCourseCallId.filter(x => x !== apiRequestCallId)
        delete this.favCallIdToCourseId[apiRequestCallId]
        if (responseJson.data) {
          const fav = { ...responseJson.data, ...responseJson.data?.attributes }
          let productData = { ...this.state.productData, favourite_id: [...(this.state.productData.favourite_id || []), fav] }
          let updatedProduct = {id:responseJson.data.id, ...productData}
          this.setState({ productData })
          sendProductUpdatedMessage(updatedProduct)
        }
      } else if (apiRequestCallId === this.rateProductRequestId) {
        let productData = {id: responseJson.data.id, ...{...this.state.productData, is_rated:true, average_rating:responseJson?.data?.attributes?.average_rating}}
        this.setState({productData :{...this.state.productData, is_rated:true, average_rating:responseJson?.data?.attributes?.average_rating}})
        this.setState({ modalRating: 0, modal: '' })
        sendProductUpdatedMessage(productData)
        toast.success("Rated Product Successfully")
      }
       
    }
    // Customizable Area End

  }

  // Customizable Area Start
  handleShareCourse = (p: any) => () => {
    let data = {
      userId: p?.created_by?.id,
      postId: p?.id,
      link: `${window?.location?.origin}/user/${JSON.parse(p?.created_by?.id || '0')}`,
      shareableType: 'BxBlockCfproductlisting11::Product',
      shareableId: p?.id,
    }
    sendSharePopupMessage(data);
  }

  handleProductCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ userComment: e.target.value })
  }

  handleProductReplyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({ userReply: e.target.value })
  }

  handleProductComment = async(product_id: any) => {
    const token = await getStorageData('authToken');
    const { userComment } = this.state
    const body = new FormData()
    body.append("product_id", product_id)
    body.append("content", userComment);

    this.productCommentCallId = sendAPIRequest(configJSON.productCommentEndpoint, {
      method: 'POST',
      body,
      headers: { token }
    })

    let stateEmogi = false;
    this.setState({ openCommentEmoji: stateEmogi });
  }

  handleCommentLike = async(event: any) => {
    const token = await getStorageData('authToken');
    const body = new FormData()
    body.append("data[attributes][likeable_type]", 'Comment')
    body.append("data[attributes][likeable_id]", event.id);
    let url = configJSON.productCommentLikeEndpoint;
    if (event.is_like) {
      url = configJSON.productCommentLikeEndpoint + '/' + event?.like_id[0]?.id
    }
    this.eventLikeCallId = sendAPIRequest(url, {
      method: event.is_like ? 'DELETE' : 'POST',
      body,
      headers: { token }
    })
    this.likeCallIdToCommentId[this.eventLikeCallId] = event.id
  }

  handleSetReplyId = (reply_id: any) => {
    this.setState({
      reply_id
    });
  }

  handleReplyComment = async() => {
    const token = await getStorageData('authToken');
    const { userReply, reply_id } = this.state
    const body = new FormData()
    body.append("comment_id", reply_id)
    body.append("content", userReply);

    this.eventCommentReplyCallId = sendAPIRequest(configJSON.productReplyCommentEndpoint, {
      method: 'POST',
      body,
      headers: { token }
    })

    let stateEmogi = false;
    this.setState({ openCommentReplyEmoji: stateEmogi });
  }

  loadMore = async() => {
    const token = await getStorageData('authToken');
    const product_id = this.props.navigation.getParam('product_id')
    let product_url = configJSON.getAllCourseCommentEndpoint.replace(':pid', product_id) + '&page=' + this.state.pagination?.next_page;
    this.getMoreCommentCallId = sendAPIRequest(product_url, {
      method: 'GET',
      headers: { token }
    })
  }

  onLikeChange = (postId: any, delta: number) => {
    console.log("post id=", postId)
    let productData = this.state.productData;
    productData.total_likes += delta;
    productData.like_id = [];
    this.setState({ productData })
  }
  onLikeIdUpdate = (postId: any, likeObj: any) => {
    console.log("post id=", postId)
    let productData = this.state.productData;
    productData.like_id = [likeObj];
    this.setState({ productData })
  }

  handleFavoriteClick = async() => {
    let productData = this.state.productData;
    console.log(productData, 'product DATA')
    // if a fav request already exists, skip this one
    //if (Array.from(Object.values(this.favCallIdToPostId)).includes(postId)) return;

    if (productData) {
      let endpoint = productData.is_favourite ? configJSON.unfavAPIEndpoint : configJSON.favAPIEndpoint
      const method = productData.is_favourite ? 'DELETE' : 'POST'
      if (!productData.is_favourite) {
        const token = await getStorageData('authToken');
        // POST REQUEST
        const body = new FormData()
        body.append("data[favouriteable_type]", "BxBlockCfproductlisting11::Product")
        body.append("data[favouriteable_id]", productData.id as string)
        const callId = sendAPIRequest(endpoint, { body, method, headers: { token: token } })
        this.apiFavCourseCallId.push(callId)
        this.favCallIdToCourseId[callId] = productData.id
      } else {
        const token = await getStorageData('authToken');
        // DELETE REQUEST
        if (!productData.favourite_id?.length) return;

        endpoint = endpoint.replace(':id', String(productData.favourite_id[0].id))
        sendAPIRequest(endpoint, { method, headers: { token: token } })
      }
      // optimistic update
      productData.is_favourite = !productData.is_favourite
      productData.favourite_id = []
      this.setState({ productData })
      const updatedProduct = {id:productData.id, ...productData}
      sendProductUpdatedMessage(updatedProduct)
    }
  }

  onCommentEmojiClick = (event: any, emojiObject: any) => {
    let emoji = emojiObject?.emoji;
    let userComment = this.state.userComment + emoji;
    this.setState({ userComment });
  }

  openEmojiComment = () => {
    let stateEmogi = !this.state.openCommentEmoji;
    this.setState({ openCommentEmoji: stateEmogi });
  }

  openEmojiCommentReply = () => {
    let stateEmogi = !this.state.openCommentReplyEmoji;
    this.setState({ openCommentReplyEmoji: stateEmogi });
  }

  onReplyEmojiClick = (event: any, emojiObject: any) => {
    let emoji = emojiObject?.emoji;
    let userReply = this.state.userReply + emoji;
    this.setState({ userReply });
  }

  showReply = (comment_id: any) => {
    this.setState({ openReply: comment_id });
  }

  setModalRating = ( modalRating: number ) => {
    this.setState({ modalRating })
  }

  hideModal = ()=> this.setState({modal: '', modalRating: 0})

  handleRateProductSubmit = async(value: any) => {
    const token = await getStorageData('authToken');
    this.rateProductRequestId = sendAPIRequest(configJSON.productRateAPIEndpoint, {
      method: 'POST',
      body: {
        "data":{
          "attributes": {
            "rating": value.rating,
            "product_id": this.state.productData?.id,
          }
        }
      },
      headers: { token: token, "content-type": "application/json" }
    })
    
  }

  onRateClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    // this.props.navigation.navigate("rateProduct")
    console.log("rate")
    const id = Number(e.currentTarget.dataset.id)
    const productData = this.state.productData
    if(!productData) return 
    if(productData.is_rated){
      toast.warn('You have already rated this product.')
      return 
    }
    if(productData.created_by.id == this.context.id){
      toast.warn('You cannot rate your own product.')
      return 
    }
    console.log({productData})
    this.setState({modal: 'rate', productData })
  }
  // Customizable Area End
  
}

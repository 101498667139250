import React from "react";
import PurchaseCoinsController, { Props } from "./PurchaseCoinsController";

// Customizable Area Start
import DashboardLayout from "../../../components/src/DashboardLayout.web";
import {
    Card, CardHeader, CardContent, Grid, Divider, Typography, IconButton, MenuItem, Button, Avatar, Menu, TableContainer, Table, TableHead, TableRow, TableCell, TableBody,
} from '@material-ui/core'
import { styled } from '@material-ui/styles'
import OverlayLoader from '../../../components/src/Loader.web'
import Flex from "../../../components/src/Flex.web";
import SeeMore from "../../../components/src/SeeMore.web";

// import UserContext from "../../../components/src/UserProvider";
// Customizable Area End
export default class PurchaseCoins extends PurchaseCoinsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End
    render() {
        const { navigation } = this.props
        const corpstageCoins = this.state.corpstageCoins
        return (
            // Customizable Area Start
            <>
                <DashboardLayout token={this.state.token || ''} navigation={navigation}>
                    <CoinsHead>
                        Purchase Corpstage Coins
                    </CoinsHead>
                    <OverlayLoader loading={!!this.state.loadingCorpstageCoins} />
                    {corpstageCoins && corpstageCoins.length > 0 ?
                        corpstageCoins.map((c) => (
                            <RoundedCard>
                                <Flex col gap='15px'>
                                    <Typography style={{ fontSize: '20px', fontWeight: 600 }}>{c.name}</Typography>
                                    <SeeMore limit={100} text={c.description} />
                                </Flex>
                                <Flex justify="space-between" col style={{ alignItems: 'flex-end' }}>
                                    <div style={{ textAlign: 'end' }}>
                                        <Flex style={{ alignItems: 'baseline' }}>
                                            <TC>{c.no_of_coin}</TC>
                                            <Typography style={{ marginLeft: '10px' }}>Coins</Typography>
                                        </Flex>
                                        <Typography style={{ fontSize: '0.9rem', marginTop: '0.5rem' }}>{`$${c.package_price_usd} (₹${c.package_price_inr})`}</Typography>
                                    </div>
                                    <RoundedBtn onClick={this.handlePurchase} data-id={c.id}>Purchase</RoundedBtn>
                                </Flex>
                            </RoundedCard>
                        )) : (!this.state.loadingCorpstageCoins && <Typography align="center">No Coins Found</Typography>)
                    }
                </DashboardLayout>
            </>
            // Customizable Area End
        )
    }
}

// Customizable Area Start
const CoinsHead = styled('div')({
    margin: '40px 0px ',
    fontSize: '1.2rem',
    fontWeight: 600,
    '@media screen and (max-width:768px)':{
        margin:'20px 20px',
    },
})

const RoundedCard = styled(Card)({
    borderRadius: '15px',
    padding: '20px 20px',
    margin: '20px 0px',
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '125px',
    // alignItems: 'center',
    // alignContent: 'center',
    gap: '40px',
    // padding: '20px 0px',
    '& SeeMore': {
        backgroundColor: 'red'
    },
    '@media screen and (max-width: 768px)':{
        margin:'15px',
     },
     '@media screen and (max-width: 480px)':{
        flexDirection:'column',
     },
     '@media screen and (min-width: 768px) and (max-width:985px)':{
        flexDirection:'column',
     },
})

const RoundedBtn = styled(Button)({
    color: '#fff',
    backgroundColor: '#00A100',
    border: '1px solid #009c05',
    borderRadius: '6.43px',
    padding: '5px 30px',
    textTransform: 'none',
    fontWeight: 700,
    '&:hover': {
        color: '#fff',
        backgroundColor: '#00A100',
    }
})

const TC = styled(Typography)({
    fontWeight: 700,
    fontSize: '25px',
    lineHeight: '30px'
})

// CorpsStageCoinsController.contextType = UserContext

// Customizable Area End
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { sendAPIRequest } from '../../../components/src/utils';
import React,{ Context } from 'react'
import * as yup from 'yup'
import { getStorageData } from '../../../framework/src/Utilities';
import { toast } from 'react-toastify';
import UserContext, { User } from "../../../components/src/UserProvider";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtSavedValue: string;
  // Customizable Area Start
  selectedCategory: unknown,
  loading: boolean,
  cfNavActivate: string,
  tableHeader:(string | { name: string; children: (string | { name: string; children: string[] })[] })[],
  CfStationaryCombustionTableData: any,
  cfStationaryCombustionForm: boolean,
  isEditCfStationaryCombustionForm: boolean,
  editDataCfStationaryCombustionForm: any,
  editCfStationaryCombustionDataIndex: any,
  fuelDropdownData: string[],
  fuelDropdownDataEfExist: string[]
  unitsDropdownData: string[],
  facilityIdDropdownData: string[],

  cfPurchasedElectricityForm: boolean,
  isEditCfPurchasedElectricityForm: boolean,
  cfPurchasedElectricityTableData: any,
  editDataCfPurchasedElectricityForm: any,
  editCfPurchasedElectricityDataIndex: any,

  cfTransportationTableData: any,
  cfTransportationForm: boolean,
  isEditCfTransportationForm: boolean,
  editDataCfTransportationForm: any,
  editCfTransportationDataIndex: any,
  transportDropdownData: any,

  cfMobileCombustionTableData: any,
  cfMobileCombustionForm: boolean,
  isEditCfMobileCombustionForm: boolean,
  editDataCfMobileCombustionForm: any,
  editCfMobileCombustionDataIndex: any,
  mobileCombustionDropdownData: any,

  cfRefrigerentsTableData: any,
  cfRefrigerentsForm: boolean,
  isEditCfRefrigerentsForm: boolean,
  editDataCfRefrigerentsForm: any,
  editCfRefrigerentsDataIndex: any,
  refrigerentsDropdownData: any,

  yearsDropdownData: string[],
  countriesDropdownData: string[],
  typesOfScope:{ name: string; key: string}[],
  scopeFormTitle: string,
  saveAsDraftData: any,
  total_pages: number,
  current_page: number,
  
  scDeleteId: number[],
  mcDeleteId: number[],
  rfDeleteID: number[],
  peDeleteId: number[],
  tsDeleteId: number[],

  isDeleteModelOpen: boolean,
  deleteModelIndex: any,
  isDraftModelOpen: boolean,
  isSaveModelOpen: boolean

  SaveDraftObserver: boolean,

  isViewForm: boolean,
  isReadable: boolean,

  submitDataLoader: boolean,
  pdfUrl:string,
  fuelListStationary: any,
  fuelSourceListMobile: any,
  vehicleTypeListMobile: any,
  activityTypeList: any,
  vehicleTypeListTransport: any,
  filterPurchasedEmissionFactor: any,
  unitsOfMeasurement: any
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class StakeholderProfileFormController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start

  static contextType?: Context<User> = UserContext;

  getstationaryCombustionCallId = ''
  getPurchasedElectricityCallId = ''
  getTransportationCallId = ''
  getmobileCombustionCallId = ''
  getrefrigerentsCallId = ''

  token=''


  draftDataTransportationsCallID=''
  draftDataPeCallID=''
  draftDataRfCallID=''
  draftDataMcCallID=''
  draftDataScCallID=''

  submitDataScCallID=''
  submitDataMcCallID=''
  submitDataRfCallID=''
  submitDataPeCallID=''
  submitDataTsCallID=''
  path = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage),
      
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.AlertMessage),
      // Customizable Area End
    ];

    this.state = {
      selectedCategory: 'Test',
      txtSavedValue:'',
      // Customizable Area Start
      pdfUrl: '',
      scopeFormTitle: '',
      typesOfScope : [
        { name: 'S1-STATIONARY COMBUSTION', key: 's1_stationaryCombustion' },
        { name: 'S1-Mobile Combustion', key: 's1_mobileCombustion' },
        { name: 'S1-Refrigerants', key: 's1_refrigerants' },
        { name: 'S2-Purchased Electricity', key: 's2_purchasedElectricity' },
        { name: 'S3-Transportation', key: 's3_transportation' }
      ],
      loading:true,
      cfNavActivate: "s1_stationaryCombustion",
      tableHeader:[],

      CfStationaryCombustionTableData:[],
      cfStationaryCombustionForm: false,
      isEditCfStationaryCombustionForm: false,
      editDataCfStationaryCombustionForm: {},
      editCfStationaryCombustionDataIndex: {
        ind : null,
        key : null,
      },
      
      cfPurchasedElectricityTableData: [],
      cfPurchasedElectricityForm: false,
      isEditCfPurchasedElectricityForm: false,
      editDataCfPurchasedElectricityForm: {},
      editCfPurchasedElectricityDataIndex: {
        ind: null,
        key: null,
      },
     
      cfTransportationTableData: [],
      cfTransportationForm: false,
      isEditCfTransportationForm: false,
      editDataCfTransportationForm: {},
      editCfTransportationDataIndex: {
        ind: null,
        key: null,
      },
      transportDropdownData: {
        category: ['Business Travel', 'Employee Commute', 'Upstream T&D','Capital Goods','Fuel and Energy Realted Activities(Not Included In Scope 1 Or 2)','Waste generated in Operations','Upstream Leased Assests','Downsteam Transportation and Distribution','Processing of Sold Products','Downstream Leases Assets','Franchises','Investments'],
        emission_factor_dataset: ['Custom emission factor', 'UK DEFRA', 'US EPA'],
        mode_of_transport: ['Air', 'Bus', 'Car', 'Ferry', 'Rail'],
        activity_type: ['Distance', 'Passenger Distance', 'Vehicle Distance', 'Weight Distance'],
        activity_type_cef: ['Example S3'],
        vehicle_type: [
          { emission_factor_dataset: 'Custom emission factor', mode_of_transport: 'Air', value: '#REF!' },
          { emission_factor_dataset: 'Custom emission factor', mode_of_transport: 'Bus', value: '#REF!' },
          { emission_factor_dataset: 'Custom emission factor', mode_of_transport: 'Car', value: '#REF!' },
          { emission_factor_dataset: 'Custom emission factor', mode_of_transport: 'Ferry', value: '#REF!' },
          { emission_factor_dataset: 'Custom emission factor', mode_of_transport: 'Rail', value: '#REF!' },

          { emission_factor_dataset: 'US EPA', mode_of_transport: 'Air', value: 'Air Travel - Long Haul (>= 2300 miles)' },
          { emission_factor_dataset: 'US EPA', mode_of_transport: 'Air', value: 'Air Travel - Medium Haul (>= 300 miles, < 2300 miles)' },
          { emission_factor_dataset: 'US EPA', mode_of_transport: 'Air', value: 'Air Travel - Short Haul (< 300 miles)' },
          { emission_factor_dataset: 'US EPA', mode_of_transport: 'Bus', value: 'Bus' },

          { emission_factor_dataset: 'US EPA', mode_of_transport: 'Rail', value: 'Commuter Rail D' },
          { emission_factor_dataset: 'US EPA', mode_of_transport: 'Rail', value: 'Intercity Rail (i.e. Amtrak) C' },
          { emission_factor_dataset: 'US EPA', mode_of_transport: 'Rail', value: 'Transit Rail (i.e. Subway, Tram) E' },

          { emission_factor_dataset: 'US EPA', mode_of_transport: 'Car', value: 'Light-Duty Truck B' },
          { emission_factor_dataset: 'US EPA', mode_of_transport: 'Car', value: 'Motorcycle' },
          { emission_factor_dataset: 'US EPA', mode_of_transport: 'Car', value: 'Passenger Car A' },

          { emission_factor_dataset: 'US EPA', mode_of_transport: 'Ferry', value: '#REF!' },

          { emission_factor_dataset: 'UK DEFRA', mode_of_transport: 'Car', value: 'Average Car - Diesel' },
          { emission_factor_dataset: 'UK DEFRA', mode_of_transport: 'Car', value: 'Average Car - Petrol' },
          { emission_factor_dataset: 'UK DEFRA', mode_of_transport: 'Car', value: 'Average Car - Hybrid' },
          { emission_factor_dataset: 'UK DEFRA', mode_of_transport: 'Car', value: 'Average Car - CNG' },
          { emission_factor_dataset: 'UK DEFRA', mode_of_transport: 'Car', value: 'Average Car - LPG' },
          { emission_factor_dataset: 'UK DEFRA', mode_of_transport: 'Car', value: 'Average Car - Plug-in Hybrid Electric Vehicle' },
          { emission_factor_dataset: 'UK DEFRA', mode_of_transport: 'Car', value: 'Average Car - Battery Electric Vehicle' },
          { emission_factor_dataset: 'UK DEFRA', mode_of_transport: 'Car', value: 'Average Car - Unknown' },
          { emission_factor_dataset: 'UK DEFRA', mode_of_transport: 'Car', value: 'Motorbike' },
          { emission_factor_dataset: 'UK DEFRA', mode_of_transport: 'Car', value: 'Taxi - regular' },

          { emission_factor_dataset: 'UK DEFRA', mode_of_transport: 'Air', value: 'Air Travel - Domestic, to/from UK - Average passenger_RF' },
          { emission_factor_dataset: 'UK DEFRA', mode_of_transport: 'Air', value: 'Air Travel - Short-haul, to/from UK - Average passenger_RF' },
          { emission_factor_dataset: 'UK DEFRA', mode_of_transport: 'Air', value: 'Air Travel - Short-haul, to/from UK - Economy class_RF' },
          { emission_factor_dataset: 'UK DEFRA', mode_of_transport: 'Air', value: 'Air Travel - Short-haul, to/from UK - Business class_RF' },
          { emission_factor_dataset: 'UK DEFRA', mode_of_transport: 'Air', value: 'Air Travel - Long-haul, to/from UK - Average passenger_RF' },
          { emission_factor_dataset: 'UK DEFRA', mode_of_transport: 'Air', value: 'Air Travel - Long-haul, to/from UK - Economy class_RF' },
          { emission_factor_dataset: 'UK DEFRA', mode_of_transport: 'Air', value: 'Air Travel - Long-haul, to/from UK - Premium economy class_RF' },
          { emission_factor_dataset: 'UK DEFRA', mode_of_transport: 'Air', value: 'Air Travel - Long-haul, to/from UK - Business class_RF' },
          { emission_factor_dataset: 'UK DEFRA', mode_of_transport: 'Air', value: 'Air Travel - Long-haul, to/from UK - First class_RF' },
          { emission_factor_dataset: 'UK DEFRA', mode_of_transport: 'Air', value: 'Air Travel - International, to/from non-UK - Average passenger_RF' },
          { emission_factor_dataset: 'UK DEFRA', mode_of_transport: 'Air', value: 'Air Travel - International, to/from non-UK - Economy class_RF' },
          { emission_factor_dataset: 'UK DEFRA', mode_of_transport: 'Air', value: 'Air Travel - International, to/from non-UK - Premium economy class_RF' },
          { emission_factor_dataset: 'UK DEFRA', mode_of_transport: 'Air', value: 'Air Travel - International, to/from non-UK - Business class_RF' },
          { emission_factor_dataset: 'UK DEFRA', mode_of_transport: 'Air', value: 'Air Travel - International, to/from non-UK - First class_RF' },

          { emission_factor_dataset: 'UK DEFRA', mode_of_transport: 'Bus', value: 'Local Bus' },

          { emission_factor_dataset: 'UK DEFRA', mode_of_transport: 'Rail', value: 'National rail' },
          { emission_factor_dataset: 'UK DEFRA', mode_of_transport: 'Rail', value: 'International rail' },
          { emission_factor_dataset: 'UK DEFRA', mode_of_transport: 'Rail', value: 'Light rail and tram' },

          { emission_factor_dataset: 'UK DEFRA', mode_of_transport: 'Ferry', value: 'Ferry - Average (all passenger)' },
        ],
        units_of_measurement: ['km', 'mile', 'nautical mile'],
        emission_calculation_methodology: ["Average data (Physical unit) method",
          "Hybrid method",
          "Supplier-specific calculation method",
          "Fuel-based method",
          "Activity-based method",
          "Gamma Generalised Linear Model (Gamma GLM)",
          "Bottom-up model",
          "Spend-based method"]
      },

      cfMobileCombustionTableData: [],
      cfMobileCombustionForm: false,
      isEditCfMobileCombustionForm: false,
      editDataCfMobileCombustionForm: {},
      editCfMobileCombustionDataIndex: {
        ind: null,
        key: null,
      },
      mobileCombustionDropdownData: {
        activity_type: ['Custom emission factor', 'Distance Activity', 'Fuel Use'],
        fuel_source: [
          'Aviation Gasoline',
          'Biodiesel (100%)',
          'Compressed Natural Gas',
          'Diesel Fuel',
          'Ethanol (100%)',
          'Jet Fuel',
          'Motor Gasoline'
        ],
        fuel_source_cef:[
          'Example S1 - Mobile'
        ],
        vehicle_type: [

          { fuel_source: 'Motor Gasoline', value: 'Gasoline Passenger Cars' },
          { fuel_source: 'Motor Gasoline', value: 'Gasoline Light-duty Trucks (Vans, Pickup Trucks, SUVs)' },
          { fuel_source: 'Motor Gasoline', value: 'Gasoline Heavy-duty Vehicles' },
          { fuel_source: 'Motor Gasoline', value: 'Hybrid (Gasoline) Passenger Cars' },
          { fuel_source: 'Motor Gasoline', value: 'Gasoline Agricultural Equipment' },
          { fuel_source: 'Motor Gasoline', value: 'Gasoline Ships and Boats' },
          { fuel_source: 'Motor Gasoline', value: 'Gasoline Motorcycles' },
          { fuel_source: 'Motor Gasoline', value: 'Other Gasoline Non-Road Vehicles' },

          { fuel_source: 'Diesel Fuel', value: 'Diesel Passenger Cars' },
          { fuel_source: 'Diesel Fuel', value: 'Diesel Light-duty Trucks' },
          { fuel_source: 'Diesel Fuel', value: 'Diesel Medium- and Heavy-duty Vehicles' },
          { fuel_source: 'Diesel Fuel', value: 'Diesel Agricultural Equipment' },
          { fuel_source: 'Diesel Fuel', value: 'Diesel Ships and Boats' },
          { fuel_source: 'Diesel Fuel', value: 'Diesel Medium- and Heavy-duty Vehicles' },
          { fuel_source: 'Diesel Fuel', value: 'Other Diesel Non-Road Vehicles' },

          { fuel_source: 'Biodiesel (100%)', value: 'Biodiesel Passenger Cars' },
          { fuel_source: 'Biodiesel (100%)', value: 'Biodiesel Light-duty Vehicles' },
          { fuel_source: 'Biodiesel (100%)', value: 'Biodiesel Medium- and Heavy-duty Vehicles' },
          { fuel_source: 'Biodiesel (100%)', value: 'Biodiesel Medium- and Heavy-duty Vehicles' },

          { fuel_source: 'Compressed Natural Gas', value: 'CNG Light-duty Vehicles' },
          { fuel_source: 'Compressed Natural Gas', value: 'CNG Medium- and Heavy-duty Vehicles' },

          { fuel_source: 'Ethanol (100%)', value: 'Ethanol Light-duty Vehicles' },
          { fuel_source: 'Ethanol (100%)', value: 'Ethanol Medium- and Heavy-duty Vehicles' },

          { fuel_source: 'Jet Fuel', value: 'Jet Fuel Aircraft' },
          { fuel_source: 'Aviation Gasoline', value: 'Aviation Gasoline Aircraft' },
        ],
        unit_of_fuel_amount: ['L', 'bbl', 'ccf', 'gal (US)', 'm3', 'scf'],
        unit_of_fuel_amount_distance: ['nautical mile', 'mile', 'Km']
      },

      cfRefrigerentsTableData: [],
      cfRefrigerentsForm: false,
      isEditCfRefrigerentsForm: false,
      editDataCfRefrigerentsForm: {},
      editCfRefrigerentsDataIndex: {
        ind: null,
        key: null,
      },
      refrigerentsDropdownData: {
        refrigerant_used: [
          "Aviation Gasoline",
          "Biodiesel (100%)",
          "Carbon dioxide",
          "Carbon tetrachloride",
          "CFC-11",
          "CFC-12",
          "CFC-13",
          "CFC-113",
          "CFC-114",
          "CFC-115",
          "Ethanol (100%)",
          "Ferry",
          "Gasoline Agricultural Equipment",
          "Gasoline Heavy-duty Vehicles",
          "Gasoline Light-duty Trucks (Vans, Pickup Trucks, SUVs)",
          "Gasoline Motorcycles",
          "Gasoline Passenger Cars",
          "Gasoline Ships and Boats",
          "HCFC-123",
          "HCFC-124",
          "HCFC-141b",
          "HCFC-142b",
          "HCFC-21",
          "HCFC-225ca",
          "HCFC-225cb",
          "Halon-1211",
          "Halon-1301",
          "Halon-2402",
          "HFC-134a (R-134a)",
          "HFC-134a (R-135a)",
          "HFC-134a (R-136a)",
          "HFC-134a (R-137a)",
          "HFC-134a (R-138a)",
          "HFC-134a (R-139a)",
          "HFC-134a (R-140a)",
          "HFC-134a (R-141a)",
          "HFC-134a (R-142a)",
          "HFC-134a (R-143a)",
          "HFC-134a (R-144a)",
          "HFC-134a (R-145a)",
          "HFC-236cb (R-236cb)",
          "HFC-236ea (R-236ea)",
          "HFC-236fa (R-236fa)",
          "HFC-245ca (R-245ca)",
          "HFC-245fa (R-245fa)",
          "HFC-365mfc",
          "HFC-43-10mee (R-4310)",
          "Methane",
          "Methyl bromide",
          "Methyl chloroform",
          "Motor Gasoline",
          "Nitrogen trifluoride",
          "Nitrous oxide",
          "Other Gasoline Non-Road Vehicles",
          "PFC-116 (Perfluoroethane)",
          "PFC-14 (Perfluoromethane)",
          "PFC-218 (Perfluoropropane)",
          "PFC-3-1-10 (Perfluorobutane)",
          "PFC-4-1-12 (Perfluoropentane)",
          "PFC-5-1-14 (Perfluorohexane)",
          "PFC-9-1-18 (Perfluorodecalin)",
          "R-22 (HCFC-22)",
          "R-401A",
          "R-401B",
          "R-401C",
          "R-402A",
          "R-402B",
          "R-403A",
          "R-403B",
          "R-404A",
          "R-407A",
          "R-407B",
          "R-407C",
          "R-407D",
          "R-407E",
          "R-407F",
          "R-408A",
          "R-410A",
          "R-410B",
          "R-411A",
          "R-411B",
          "R-412A",
          "R-415A",
          "R-415B",
          "R-416A",
          "R-417A",
          "R-417B",
          "R-417C",
          "R-418A",
          "R-419A",
          "R-419B",
          "R-420A",
          "R-421A",
          "R-421B",
          "R-422A",
          "R-422B",
          "R-422C",
          "R-422D",
          "R-422E",
          "R-423A",
          "R-424A",
          "R-425A",
          "R-426A",
          "R-427A",
          "R-428A",
          "R-429A",
          "R-430A",
          "R-431A",
          "R-434A",
          "R-435A",
          "R-437A",
          "R-438A",
          "R-439A",
          "R-444A",
          "R-445A",
          "R-500",
          "R-503",
          "R-504",
          "R-507",
          "R-507A",
          "R-509",
          "R-509A",
          "R-512A",
          "Sulfur hexafluoride"
        ]
      },      

      fuelDropdownData: [
        "Anthracite Coal",
        "Bituminous Coal",
        "Sub-bituminous Coal",
        "Lignite Coal",
        "Mixed (Commercial Sector)",
        "Mixed (Electric Power Sector)",
        "Mixed (Industrial Coking)",
        "Mixed (Industrial Sector)",
        "Coal Coke",
        "Municipal Solid Waste",
        "Petroleum Coke (Solid)",
        "Plastics",
        "Tires",
        "Agricultural Byproducts",
        "Peat",
        "Solid Byproducts",
        "Wood and Wood Residuals",
        "Natural Gas",
        "Blast Furnace Gas",
        "Coke Oven Gas",
        "Fuel Gas",
        "Propane Gas",
        "Landfill Gas",
        "Other Biomass Gases",
        "Asphalt and Road Oil",
        "Aviation Gasoline",
        "Butane",
        "Butylene",
        "Crude Oil",
        "Distillate Fuel Oil No. 1",
        "Distillate Fuel Oil No. 2",
        "Distillate Fuel Oil No. 4",
        "Ethane",
        "Ethylene",
        "Heavy Gas Oils",
        "Isobutane",
        "Isobutylene",
        "Kerosene",
        "Kerosene-Type Jet Fuel",
        "Liquefied Petroleum Gases (LPG)",
        "Lubricants",
        "Motor Gasoline",
        "Naphtha (<401 deg F)",
        "Natural Gasoline",
        "Other Oil (>401 deg F)",
        "Pentanes Plus",
        "Petrochemical Feedstocks",
        "Petroleum Coke",
        "Propane",
        "Propylene",
        "Residual Fuel Oil No. 5",
        "Residual Fuel Oil No. 6",
        "Special Naphtha",
        "Unfinished Oils",
        "Used Oil",
        "Biodiesel (100%)",
        "Ethanol (100%)",
        "Rendered Animal Fat",
        "Vegetable Oil"        
      ],
      fuelDropdownDataEfExist: ["Example S1"],
      unitsDropdownData: [
        'Btu',
        'GJ',
        'kWh',
        'mmBtu',
        'MJ',
        'MWh',
        'therm'
      ],
      facilityIdDropdownData: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10",
        "11", "12", "13", "14", "15", "16", "17", "18", "19", "20",
        "21", "22", "23", "24", "25", "26", "27", "28", "29", "30",
        "31", "32", "33", "34", "35", "36", "37", "38", "39", "40",
        "41", "42", "43", "44", "45", "46", "47", "48", "49", "50"],
      yearsDropdownData: ["2019","2020", "2021", "2022", "2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030", "2031", "2032", "2033", "2034", "2035", "2036", "2037", "2038", "2039", "2040", "2041", "2042", "2043", "2044", "2045", "2046", "2047", "2048", "2049", "2050"],
      countriesDropdownData: [
        'Afghanistan',
        'Albania',
        'Algeria',
        'Andorra',
        'Angola',
        'Antigua and Barbuda',
        'Argentina',
        'Armenia',
        'Australia',
        'Austria',
        'Azerbaijan',
        'Bahamas',
        'Bahrain',
        'Bangladesh',
        'Barbados',
        'Belarus',
        'Belgium',
        'Belize',
        'Benin',
        'Bhutan',
        'Bolivia',
        'Bosnia and Herzegovina',
        'Botswana',
        'Brazil',
        'Brunei',
        'Bulgaria',
        'Burkina Faso',
        'Burundi',
        'Côte d\'Ivoire',
        'Cabo Verde',
        'Cambodia',
        'Cameroon',
        'Canada',
        'Central African Republic',
        'Chad',
        'Chile',
        'China',
        'Colombia',
        'Comoros',
        'Congo (Congo-Brazzaville)',
        'Costa Rica',
        'Croatia',
        'Cuba',
        'Cyprus',
        'Czechia (Czech Republic)',
        'Democratic Republic of the Congo',
        'Denmark',
        'Djibouti',
        'Dominica',
        'Dominican Republic',
        'Ecuador',
        'Egypt',
        'El Salvador',
        'Equatorial Guinea',
        'Eritrea',
        'Estonia',
        'Eswatini (fmr. "Swaziland")',
        'Ethiopia',
        'Fiji',
        'Finland',
        'France',
        'Gabon',
        'Gambia',
        'Georgia',
        'Germany',
        'Ghana',
        'Greece',
        'Grenada',
        'Guatemala',
        'Guinea',
        'Guinea-Bissau',
        'Guyana',
        'Haiti',
        'Holy See',
        'Honduras',
        'Hungary',
        'Iceland',
        'India',
        'Indonesia',
        'Iran',
        'Iraq',
        'Ireland',
        'Israel',
        'Italy',
        'Jamaica',
        'Japan',
        'Jordan',
        'Kazakhstan',
        'Kenya',
        'Kiribati',
        'Kuwait',
        'Kyrgyzstan',
        'Laos',
        'Latvia',
        'Lebanon',
        'Lesotho',
        'Liberia',
        'Libya',
        'Liechtenstein',
        'Lithuania',
        'Luxembourg',
        'Madagascar',
        'Malawi',
        'Malaysia',
        'Maldives',
        'Mali',
        'Malta',
        'Marshall Islands',
        'Mauritania',
        'Mauritius',
        'Mexico',
        'Micronesia',
        'Moldova',
        'Monaco',
        'Mongolia',
        'Montenegro',
        'Morocco',
        'Mozambique',
        'Myanmar (formerly Burma)',
        'Namibia',
        'Nauru',
        'Nepal',
        'Netherlands',
        'New Zealand',
        'Nicaragua',
        'Niger',
        'Nigeria',
        'North Korea',
        'North Macedonia',
        'Norway',
        'Oman',
        'Pakistan',
        'Palau',
        'Palestine State',
        'Panama',
        'Papua New Guinea',
        'Paraguay',
        'Peru',
        'Philippines',
        'Poland',
        'Portugal',
        'Qatar',
        'Romania',
        'Russia',
        'Rwanda',
        'Saint Kitts and Nevis',
        'Saint Lucia',
        'Saint Vincent and the Grenadines',
        'Samoa',
        'San Marino',
        'Sao Tome and Principe',
        'Saudi Arabia',
        'Senegal',
        'Serbia',
        'Seychelles',
        'Sierra Leone',
        'Singapore',
        'Slovakia',
        'Slovenia',
        'Solomon Islands',
        'Somalia',
        'South Africa',
        'South Korea',
        'South Sudan',
        'Spain',
        'Sri Lanka',
        'Sudan',
        'Suriname',
        'Sweden',
        'Switzerland',
        'Syria',
        'Tajikistan',
        'Tanzania',
        'Thailand',
        'Timor-Leste',
        'Togo',
        'Tonga',
        'Trinidad and Tobago',
        'Tunisia',
        'Turkey',
        'Turkmenistan',
        'Tuvalu',
        'Uganda',
        'Ukraine',
        'United Arab Emirates',
        'United Kingdom',
        'United States of America',
        'Uruguay',
        'Uzbekistan',
        'Vanuatu',
        'Venezuela',
        'Vietnam',
        'Yemen',
        'Zambia',
        'Zimbawe',
      ],

      saveAsDraftData: [],
      total_pages: 1,
      current_page: 1,

      scDeleteId: [],
      mcDeleteId: [],
      rfDeleteID: [],
      peDeleteId: [],
      tsDeleteId: [],

      isDeleteModelOpen: false,
      deleteModelIndex: null,
      isDraftModelOpen: false,
      isSaveModelOpen: false,

      SaveDraftObserver: false,

      isViewForm: false,

      isReadable: false,

      submitDataLoader: false,
      fuelListStationary: [],
      fuelSourceListMobile: [],
      vehicleTypeListMobile: [],
      activityTypeList:[],
      vehicleTypeListTransport:[],
      filterPurchasedEmissionFactor: [],
      unitsOfMeasurement: [
        { activity_type: 'Example S3', value: 'km' },
        { activity_type: 'Example S3', value: 'mile' },
        { activity_type: 'Example S3', value: 'nautical mile' },
      ]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.setState({ loading: true });
    this.path = this.context.user_type == "licensed_user" ? 'Corporatepermission' : 'ESGJourney'
    let authTokenReq = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(authTokenReq);
    const authTokenData = await getStorageData('authToken')
    this.token = authTokenData;
    const tabHeaderData = this.getTableHeaderDataByType('s1_stationaryCombustion')
    this.setState({ cfNavActivate: 's1_stationaryCombustion', tableHeader: tabHeaderData, CfStationaryCombustionTableData: [] }, () => {
      this.getstationaryCombustionData();
    })

    const abilityType = this.props?.navigation?.history?.location?.state?.state?.ability_type;
    const ability_type = await getStorageData('ability_type')
    if(abilityType == "read" || ability_type == "read"){
      this.setState({isReadable:true})
    }
    // Customizable Area End
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage)); this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );
      this.setState({ txtSavedValue: value });
    }
      runEngine.debugLog("API Message Recived", message);
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
  
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if(responseJson && responseJson.error){
          this.parseApiErrorResponse(responseJson.error);
          this.parseApiCatchErrorResponse(responseJson.error);
          toast.error(responseJson.error);
          this.setState({ loading: false,submitDataLoader: false });
        }
        else if (responseJson) {
          switch (apiRequestCallId) {
            case this.getstationaryCombustionCallId:
              const stationaryCombustionData = responseJson.ghgs?.data?.map((data:any) => data.attributes)
              this.setState({
                CfStationaryCombustionTableData: stationaryCombustionData || [], 
                total_pages: responseJson.ghgs?.meta?.pagination?.total_pages,
                current_page: responseJson.ghgs?.meta?.pagination?.current_page,
                loading:false
              });
            break; 

            case this.getmobileCombustionCallId:
              const mcData = responseJson.mobile_combustions?.data?.map((data:any) => data.attributes)
              this.setState({
                cfMobileCombustionTableData: mcData || [],
                total_pages: responseJson.mobile_combustions?.meta?.pagination?.total_pages,
                current_page: responseJson.mobile_combustions?.meta?.pagination?.current_page,
                loading:false
              });
            break;  

            case this.getrefrigerentsCallId:
              const refrigerentsData = responseJson.refrigerants?.data?.map((data:any) => data.attributes)
              this.setState({
                cfRefrigerentsTableData: refrigerentsData || [],
                total_pages: responseJson.refrigerants?.meta?.pagination?.total_pages,
                current_page: responseJson.refrigerants?.meta?.pagination?.current_page,
                loading:false
              });
            break;

            case this.getPurchasedElectricityCallId:
              const purchasedElectricityData = responseJson.ghgs?.data?.map((data:any) => data.attributes)
              this.setState({
                cfPurchasedElectricityTableData: purchasedElectricityData || [],
                total_pages: responseJson.ghgs?.meta?.pagination?.total_pages,
                current_page: responseJson.ghgs?.meta?.pagination?.current_page,
                loading:false
              });
            break;  

            case this.getTransportationCallId:
              const transportationData = responseJson.transportations?.data?.map((data:any) => data.attributes)
              this.setState({
                cfTransportationTableData: transportationData || [],
                total_pages: responseJson.transportations?.meta?.pagination?.total_pages,
                current_page: responseJson.transportations?.meta?.pagination?.current_page,
                loading:false
              });
            break;

            case this.draftDataScCallID:
              this.setState({ scDeleteId: [] });
              this.getstationaryCombustionData();
              toast.success("Data Saved As Draft.")
            break;

            case this.draftDataMcCallID:
              this.setState({  mcDeleteId: [] });
              this.getmobileCombustionData();
              toast.success("Data Saved As Draft.")
            break;

            case this.draftDataRfCallID:
              this.setState({  rfDeleteID: [] });
              this.getrefrigerentsData();
              toast.success("Data Saved As Draft.")
            break;

            case this.draftDataPeCallID:
              this.setState({ peDeleteId: [] });
              this.getPurchasedElectricityData();
              toast.success("Data Saved As Draft.")
            break;

            case this.draftDataTransportationsCallID:
              this.setState({ tsDeleteId: [] });
              this.getTransportationData();
              toast.success("Data Saved As Draft.")
            break;

            case this.submitDataScCallID:
            case this.submitDataMcCallID:
            case this.submitDataRfCallID:
            case this.submitDataPeCallID:
            case this.submitDataTsCallID:
              toast.success("Data Saved Successfully.")
              this.setState({ 
                submitDataLoader: false, 
                pdfUrl:responseJson.url, 
                scDeleteId: [], 
                mcDeleteId: [], 
                rfDeleteID: [], 
                peDeleteId: [], 
                tsDeleteId: [],
                CfStationaryCombustionTableData:[],
                cfPurchasedElectricityTableData: [],
                cfTransportationTableData: [],
                cfMobileCombustionTableData: [],
                cfRefrigerentsTableData: [], 
                SaveDraftObserver:false
              });
            break;
          }
          this.setState({ loading: false });
        }
      }

    // Customizable Area End
  }

  // Customizable Area Start
  handlePageChange = (event: any, value: any) => {
    switch (this.state.cfNavActivate) {
      case "s1_stationaryCombustion":
        this.setState({ current_page: value }, () => this.getstationaryCombustionData(value));
        break;

      case "s1_mobileCombustion":
        this.setState({ current_page: value }, () => this.getmobileCombustionData(value));
        break;

      case "s1_refrigerants":
        this.setState({ current_page: value }, () => this.getrefrigerentsData(value));
        break;

      case "s2_purchasedElectricity":
        this.setState({ current_page: value }, () => this.getPurchasedElectricityData(value));
        break;

      case "s3_transportation":
        this.setState({ current_page: value }, () => this.getTransportationData(value));
        break;
    }
  }

  stationaryCombustionFormSchema = yup.object({
    facility_id: yup.string().required('Facility Id is Mandatory'),
    start_date: yup.string().required('start_date is Mandatory'),
    end_date: yup.string().required('end_date is Mandatory'),
    custom_emissions_factors: yup.string().required('Custom Emissions Factors is Mandatory'),
    amount_of_fuel: yup.number().required('Amount of fuel is Mandatory').typeError('Amount of fuel must be a number').max(9999999999, 'Amount of fuel field cannot exceed 10 digits'),
    fuel_name: yup.string().required('Fuel is Mandatory'),
    unit_name: yup.string().required('Units (e.g.kg or kWh) is Mandatory'),
  })

  mobileCombustionFormSchema = yup.object({ 
    description: yup.string().required('Description is Mandatory').max(550, 'Description must be at most 550 characters'),
    facility_id: yup.string().required('Facility Id is Mandatory'),
    activity_type: yup.string().required('Activity Type is Mandatory'),
    activity_amount: yup.number().required('Activity Amount is Mandatory').typeError('Activity Amount must be a number').max(9999999999, 'Activity Amount field cannot exceed 10 digits'),
    fuel_source: yup.string().required('Fuel Source is Mandatory'),
    vehicle_type: yup.string().required('Vehicle Type is Mandatory'),
    unit_of_fuel_amount: yup.string().required('Unit of Fuel Amount is Mandatory'),
  })

  refrigerentsFormSchema = yup.object({
    facility_id: yup.string().required('Facility Id is Mandatory'),
    refrigerant_purchased_from_producers: yup.number().required('Refrigerant purchased from producers/ distributors is Mandatory').typeError('Refrigerant purchased from producers/ distributors must be a number').max(9999999999, 'Refrigerant purchased from producers/ distributors field cannot exceed 10 digits'),
    refrigerant_returned_by_equipment_users: yup.number().required('Refrigerant returned by equipment users is Mandatory').typeError('Refrigerant returned by equipment users must be a number').max(9999999999, 'Refrigerant returned by equipment users field cannot exceed 10 digits'),
    refrigerant_off_site_recycling_purchases: yup.number().required('Refrigerant returned after off-site recycling or reclamation is Mandatory').typeError('Refrigerant returned after off-site recycling or reclamation must be a number').max(9999999999, 'Refrigerant returned after off-site recycling or reclamation field cannot exceed 10 digits'),
    refrigerant_charged_into_equipment: yup.number().required('Refrigerant charged into equipment is Mandatory').typeError('Refrigerant charged into equipment must be a number').max(9999999999, 'Refrigerant charged into equipment field cannot exceed 10 digits'),
    refrigerant_delivered_to_equipment_users_containers: yup.number().required('Refrigerant delivered to equipment users in containers is Mandatory').typeError('Refrigerant delivered to equipment users in containers must be a number').max(9999999999, 'Refrigerant delivered to equipment users in containers field cannot exceed 10 digits'),
    refrigerant_returned_to_producers: yup.number().required('Refrigerant returned to refrigerant producers is Mandatory').typeError('Refrigerant returned to refrigerant producers must be a number').max(9999999999, 'Refrigerant returned to refrigerant producers field cannot exceed 10 digits'),
    refrigerant_off_site_recycling_sales: yup.number().required('Refrigerant sent off-site for recycling or reclamation is Mandatory').typeError('Refrigerant sent off-site for recycling or reclamation must be a number').max(9999999999, 'Refrigerant sent off-site for recycling or reclamation field cannot exceed 10 digits'),
    refrigerant_sent_off_site_destruction: yup.number().required('Refrigerant sent off-site for destruction is Mandatory').typeError('Refrigerant sent off-site for destruction must be a number').max(9999999999, 'Refrigerant sent off-site for destruction field cannot exceed 10 digits'),
    capacity_of_partially_charged_equipment: yup.number().required('Nameplate Capacity of Partially Charged Equipment is Mandatory').typeError('Nameplate Capacity of Partially Charged Equipment must be a number').max(9999999999, 'Nameplate Capacity of Partially Charged Equipment field cannot exceed 10 digits'),
    pressure_of_partial_charge: yup.number().required('Density or Pressure of partial charge is Mandatory').typeError('Density or Pressure of partial charge must be a number').max(9999999999, 'Density or Pressure of partial charge  field cannot exceed 10 digits'),
    pressure_of_full_charge: yup.number().required('Density or Pressure of full charge is Mandatory').typeError('Density or Pressure of full charge must be a number').max(9999999999, 'Density or Pressure of full charge field cannot exceed 10 digits'),
    type_of_air_conditioning_and_refrigeration_equipment: yup.number().required('Type of Air Conditioning and Refrigeration Equipment is Mandatory').typeError('Type of Air Conditioning and Refrigeration Equipment must be a number').max(9999999999, 'Type of Air Conditioning and Refrigeration Equipment field cannot exceed 10 digits'),
    refrigerant_inventory_beginning_year: yup.number().required('Refrigerant inventory in storage, not equipment at the beginning of the year is Mandatory').typeError('Refrigerant inventory in storage, not equipment at the beginning of the year must be a number').max(9999999999, 'Refrigerant inventory in storage, not equipment at the beginning of the year field cannot exceed 10 digits'),
    refrigerant_inventory_end_year: yup.number().required('Refrigerant inventory in storage, not equipment at the end of the year is Mandatory').typeError('Refrigerant inventory in storage, not equipment at the end of the year must be a number').max(9999999999, 'Refrigerant inventory in storage, not equipment at the end of the year field cannot exceed 10 digits'),
    refrigerant_used:yup.string().required('Refrigerant Used is Mandatory'),
  })

  purchasedElectricityFormSchema = yup.object({
    facility_id: yup.string().required('Facility Id is Mandatory'),
    amount_of_electricity_consumption: yup.number().required('Amount Of Electricity Consumption is Mandatory').typeError('Amount Of Electricity Consumption must be a number').max(9999999999, 'Amount Of Electricity Consumption field cannot exceed 10 digits'),
    calculation_approach: yup.string().required('Calculation Approach is Mandatory'),
    type_of_emission_factor: yup.string().required('Types Of Emission Factor is Mandatory'),
    custom_emission_factor: yup.string().required('Custom Emission Factor is Mandatory'),
    units: yup.string().required('Units is Mandatory'),
  })

  transportationFormSchema = yup.object({
    facility_id: yup.string().required('Facility Id is Mandatory'),
    description: yup.string().required('Description is Mandatory').max(550, 'Description must be at most 550 characters'),
    category: yup.string().required('Category is Mandatory'),
    amount_of_activity_type: yup.number().when('category', {
      is: (value) => value === '' || value === 'Business Travel' || value === 'Employee Commute' || value === 'Upstream T&D',
      then: yup.number().required('Amount of Activity Type is Mandatory').typeError('Amount of Activity Type must be a number').max(9999999999, 'Amount of Activity Type field cannot exceed 10 digits'),
      otherwise: yup.number()
    }),
    emission_factor_dataset: yup.string().required('Emission Factor Dataset is Mandatory'),
    mode_of_transport: yup.string().when('category', {
    is: (value) => value === "" || value === 'Business Travel' || value === 'Employee Commute' || value === 'Upstream T&D',
    then: yup.string().required('Mode Of Transport is Mandatory'),
    otherwise: yup.string()
  }),
  activity_type: yup.string().when('category', {
    is: (value) => value === "" || value === 'Business Travel' || value === 'Employee Commute' || value === 'Upstream T&D',
    then: yup.string().required('Activity Type is Mandatory'),
    otherwise: yup.string()
  }),
  vehicle_type: yup.string().when('category', {
    is: (value) => value === "" || value === 'Business Travel' || value === 'Employee Commute' || value === 'Upstream T&D',
    then: yup.string().required('Vehicle Type is Mandatory'),
    otherwise: yup.string()
  }),
    units_of_measurement:yup.string().required('Units Of Measurement is Mandatory'),

  })
  
  CfNavActivation = (key: string) => {
    const tabHeaderData = this.getTableHeaderDataByType(key);

    if(this.state.SaveDraftObserver){
      this.handleDraftData(this.state.cfNavActivate);
    }

    this.setState({ cfNavActivate: key, tableHeader: tabHeaderData, loading: true, pdfUrl: ''})
    switch(key) {
      case "s1_stationaryCombustion":
          this.getstationaryCombustionData()
        break;
      case "s1_mobileCombustion":
          this.getmobileCombustionData()
        break;
      case "s1_refrigerants":
          this.getrefrigerentsData()
        break;
      case "s2_purchasedElectricity":
          this.getPurchasedElectricityData()
        break;
      case "s3_transportation":
          this.getTransportationData()
        break;
    }
  }

  CfHandleFormModel = (activeTab: string) => {
    switch (activeTab) {
      case "s1_stationaryCombustion":
        this.setState({ cfStationaryCombustionForm: !this.state.cfStationaryCombustionForm, scopeFormTitle: 'S1-STATIONARY COMBUSTION'  })
        break;

      case "s1_mobileCombustion":
        this.setState({cfMobileCombustionForm: !this.state.cfMobileCombustionForm,scopeFormTitle:'S1-Mobile Combustion' })
        break;

      case "s1_refrigerants":
        this.setState({cfRefrigerentsForm: !this.state.cfRefrigerentsForm,scopeFormTitle:'S1-Refrigerants' })
        break;

      case "s2_purchasedElectricity":
        this.setState({cfPurchasedElectricityForm: !this.state.cfPurchasedElectricityForm,scopeFormTitle:'S2-Purchased Electricity' })
        break;

      case "s3_transportation":
        this.setState({cfTransportationForm: !this.state.cfTransportationForm,scopeFormTitle:'S3-Transportation' })
        break;
    }
  }

  handleSubmitData = (activeTab: string) => {
    this.setState({ submitDataLoader: true });

    switch (activeTab) {
      case "s1_stationaryCombustion":
        this.submitDataScCallID = sendAPIRequest(configJSON.saveScTable, {
          method: 'POST',
          body: {
            stationary_combustions: this.state.CfStationaryCombustionTableData,
            deleted_ids: this.state.scDeleteId
          },
          headers: {
            "Content-Type": "application/json",
            token: this.token
          }
        })
      break;
      case "s1_mobileCombustion":
        this.submitDataMcCallID = sendAPIRequest(configJSON.saveMcTable, {
          method: 'POST',
          body: {
            mobile_combustions: this.state.cfMobileCombustionTableData,
            deleted_ids: this.state.mcDeleteId
          },
          headers: {
            "Content-Type": "application/json",
            token: this.token
          }
        })
      break;
      case "s1_refrigerants":
        this.submitDataRfCallID = sendAPIRequest(configJSON.saveRfTable, {
          method: 'POST',
          body: {
            refrigerants: this.state.cfRefrigerentsTableData,
            deleted_ids: this.state.rfDeleteID
          },
          headers: {
            "Content-Type": "application/json",
            token: this.token
          }
        })
      break;
      case "s2_purchasedElectricity":
        this.submitDataPeCallID = sendAPIRequest(configJSON.savePeTable, {
          method: 'POST',
          body: {
            ghgs: this.state.cfPurchasedElectricityTableData,
            deleted_ids: this.state.peDeleteId
          },
          headers: {
            "Content-Type": "application/json",
            token: this.token
          }
        })
      break;
      case "s3_transportation":
        this.submitDataTsCallID = sendAPIRequest(configJSON.saveTsTable, {
          method: 'POST',
          body: {
            transportations: this.state.cfTransportationTableData,
            deleted_ids: this.state.tsDeleteId
          },
          headers: {
            "Content-Type": "application/json",
            token: this.token
          }
        })
      break;
    }

  }

  handleDraftData = async (activeTab:string) => {
    this.setState({loading: true});
    switch(activeTab) {
      case "s1_stationaryCombustion":
        this.setState({saveAsDraftData: [{type: activeTab,data: this.state.CfStationaryCombustionTableData}]});
        this.draftDataScCallID = sendAPIRequest(configJSON.draftScTable, {
          method: 'POST',
          body: {
            stationary_combustions: this.state.CfStationaryCombustionTableData,
            deleted_ids: this.state.scDeleteId
          },
          headers: {
            "Content-Type": "application/json",
            token: this.token
          }
        })
        break;
      case "s1_mobileCombustion":
        this.setState({saveAsDraftData: [{type: activeTab,data: this.state.cfMobileCombustionTableData}]});
        this.draftDataMcCallID = sendAPIRequest(configJSON.draftMcTable, {
          method: 'POST',
          body: {
            mobile_combustions: this.state.cfMobileCombustionTableData,
            deleted_ids: this.state.mcDeleteId
          },
          headers: {
            "Content-Type": "application/json",
            token: this.token
          }
        })
        break;
      case "s1_refrigerants":
        this.setState({saveAsDraftData: [{type: activeTab,data: this.state.cfRefrigerentsTableData}]});
        this.draftDataRfCallID = sendAPIRequest(configJSON.draftRfTable, {
          method: 'POST',
          body: {
            refrigerants: this.state.cfRefrigerentsTableData,
            deleted_ids: this.state.rfDeleteID
          },
          headers: {
            "Content-Type": "application/json",
            token: this.token
          }
        })
        break;
      case "s2_purchasedElectricity":
        this.setState({saveAsDraftData: [{type: activeTab,data: this.state.cfPurchasedElectricityTableData}]});
        this.draftDataPeCallID = sendAPIRequest(configJSON.draftPeTable, {
          method: 'POST',
          body: {
            ghgs: this.state.cfPurchasedElectricityTableData,
            deleted_ids: this.state.peDeleteId
          },
          headers: {
            "Content-Type": "application/json",
            token: this.token
          }
        })
        break;
      case "s3_transportation":
        this.setState({saveAsDraftData: [{type: activeTab,data: this.state.cfTransportationTableData}]});
        this.draftDataTransportationsCallID = sendAPIRequest(configJSON.draftTransportationTable, {
          method: 'POST',
          body: {
            transportations: this.state.cfTransportationTableData,
            deleted_ids: this.state.tsDeleteId
          },
          headers: {
            "Content-Type": "application/json",
            token: this.token
          }
        })
        break;
    }
    this.setState({SaveDraftObserver: false});
  }

  getInitialValuesSc(isEdit:boolean, editData:any) {
    return isEdit
      ? {
          facility_id: editData.facility_id,
          start_date: editData.start_date,
          end_date: editData.end_date,
          amount_of_fuel: editData.amount_of_fuel,
          custom_emissions_factors: editData.custom_emissions_factors,
          location: editData.location,
          fuel_name: editData.fuel_name,
          unit_name: editData.unit_name
        }
      : {
          facility_id: "",
          amount_of_fuel: "",
          custom_emissions_factors: "",
          location: "",
          fuel_name: "",
          unit_name: "",
          start_date:new Date(),
          end_date:new Date()
        };
  }

  getInitialValuesRf(isEdit:boolean, editData:any) {
    if (isEdit) {
      return {
        start_date: editData.start_date,
        end_date: editData.end_date,
        facility_id: editData.facility_id,
        location: editData.location,
        type_of_air_conditioning_and_refrigeration_equipment: editData.type_of_air_conditioning_and_refrigeration_equipment,
        refrigerant_used: editData.refrigerant_used,
        refrigerant_inventory_beginning_year: editData.refrigerant_inventory_beginning_year,
        refrigerant_inventory_end_year: editData.refrigerant_inventory_end_year,
        refrigerant_purchased_from_producers: editData.refrigerant_purchased_from_producers,
        refrigerant_returned_by_equipment_users: editData.refrigerant_returned_by_equipment_users,
        refrigerant_off_site_recycling_purchases: editData.refrigerant_off_site_recycling_purchases,
        refrigerant_charged_into_equipment: editData.refrigerant_charged_into_equipment,
        refrigerant_delivered_to_equipment_users_containers: editData.refrigerant_delivered_to_equipment_users_containers,
        refrigerant_returned_to_producers: editData.refrigerant_returned_to_producers,
        refrigerant_off_site_recycling_sales: editData.refrigerant_off_site_recycling_sales,
        refrigerant_sent_off_site_destruction: editData.refrigerant_sent_off_site_destruction,
        capacity_of_partially_charged_equipment: editData.capacity_of_partially_charged_equipment,
        pressure_of_partial_charge: editData.pressure_of_partial_charge,
        pressure_of_full_charge: editData.pressure_of_full_charge
      };
    } else {
      return {
        start_date: new Date(),
        end_date: new Date(),
        facility_id: "",
        location: "",
        type_of_air_conditioning_and_refrigeration_equipment: "",
        refrigerant_used: "",
        refrigerant_inventory_beginning_year: "",
        refrigerant_inventory_end_year: "",
        refrigerant_purchased_from_producers: "",
        refrigerant_returned_by_equipment_users: "",
        refrigerant_off_site_recycling_purchases: "",
        refrigerant_charged_into_equipment: "",
        refrigerant_delivered_to_equipment_users_containers: "",
        refrigerant_returned_to_producers: "",
        refrigerant_off_site_recycling_sales: "",
        refrigerant_sent_off_site_destruction: "",
        capacity_of_partially_charged_equipment: "",
        pressure_of_partial_charge: "",
        pressure_of_full_charge: ""
      };
    }
  }  

  getInitialValuesTS(isEdit:boolean) {
    if (isEdit) {
      return {
        facility_id:  this.state.editDataCfTransportationForm.facility_id,
        start_date: this.state.editDataCfTransportationForm.start_date,
        end_date: this.state.editDataCfTransportationForm.end_date ,
        description: this.state.editDataCfTransportationForm.description,
        category:this.state.editDataCfTransportationForm.category,
        location: this.state.editDataCfTransportationForm.location ,
        emission_factor_dataset: this.state.editDataCfTransportationForm.emission_factor_dataset,
        mode_of_transport: this.state.editDataCfTransportationForm.mode_of_transport ,
        activity_type: this.state.editDataCfTransportationForm.activity_type ,
        vehicle_type:this.state.editDataCfMobileCombustionForm.vehicle_type,
        amount_of_activity_type: this.state.editDataCfMobileCombustionForm.amount_of_activity_type,
        emission_calculation_methodology: this.state.editDataCfMobileCombustionForm.emission_calculation_methodology,
          number_of_units: this.state.editDataCfMobileCombustionForm.number_of_units,
        units_of_measurement: this.state.editDataCfTransportationForm.units_of_measurement,
        co2_emissions: this.state.editDataCfTransportationForm.co2_emissions,
        percentage_emissions: this.state.editDataCfTransportationForm.percentage_emissions,
        co2_tonnes: this.state.editDataCfTransportationForm.co2_tonnes,
        ch4_tonnes: this.state.editDataCfTransportationForm.ch4_tonnes,
        n2o_tonnes: this.state.editDataCfTransportationForm.n2o_tonnes,
        co2e_tonnes: this.state.editDataCfTransportationForm.co2e_tonnes,
        biofuel_co2_tonnes: this.state.editDataCfTransportationForm.biofuel_co2_tonnes,
        ef_kg: this.state.editDataCfTransportationForm.ef_kg,
        total_emission: this.state.editDataCfTransportationForm.total_emission,
      };
    } else {
      return {
        facility_id:  '',
        start_date: new Date(),
        end_date: new Date(),
        description: '',
        category:'',
        location: '' ,
        emission_factor_dataset: '',
        mode_of_transport: '',
        activity_type: '',
        vehicle_type:'',
        amount_of_activity_type: '',
        emission_calculation_methodology: '',
          number_of_units: '',
        units_of_measurement: '',
        co2_emissions: '',
        percentage_emissions: '',
        co2_tonnes: '',
        ch4_tonnes: '',
        n2o_tonnes:'',
        co2e_tonnes: '',
        biofuel_co2_tonnes: '',
        ef_kg: '',
        total_emission: '',
      };
    }
  }  

  getTableHeaderDataByType(type:string) {
    switch (type) {
      case 's1_stationaryCombustion':
          return [
          'Facility ID',
          'Start Date',
          'End Date',
          'Custom Emission Factors',
          'Location (Country)',
          'Fuel',
          'Amount of fuel',
          'Units (e.g., kg or kWh)'
        ];
      case 's1_mobileCombustion':
        return [
          'Start Date',
          'End Date',
          'Description',
          'Facility ID',
          'Location (Country)',
          'Activity Type',
          'Fuel Source',
          'Vehicle Type',
          'Activity Amount',
          'Unit of Fuel Amount'
        ];
      case 's1_refrigerants':
        return [
          'Start Date',
          'End Date',
          "Facility ID",
          "Location (Country)",
          {
            name: 'Equipment and Refrigerant Type',
            children: [
              'Type of Air Conditioning and Refrigeration Equipment',
              'Refrigerant Used'
            ]
          },
          {
            name: 'Decrease in Inventory (kilograms)',
            children: [
              'Refrigerant inventory (in storage, not equipment) at the beginning of the year',
              'Refrigerant inventory (in storage, not equipment) at the end of the year'
            ]
          },
          {
            name: 'Purchases/Acquisitions of Refrigerant (kilograms)',
            children: [
              'Refrigerant purchased from producers/ distributors',
              'Refrigerant returned by equipment users',
              'Refrigerant returned after off-site recycling or reclamation'
            ]
          },
          {
            name: 'Sales/Disbursements of Refrigerant (kilograms)',
            children: [
              '"Refrigerant charged into equipment* If not known, see steps A1 to A4 for a default value"',
              'Refrigerant delivered to equipment users in containers',
              'Refrigerant returned to refrigerant producers',
              'Refrigerant sent off-site for recycling or reclamation',
              'Refrigerant sent off-site for destruction'
            ]
          },
          {
            name: 'Refrigerant charged into equipment (kilograms)',
            children: [
              'Nameplate Capacity of Partially Charged Equipment',
              'Density or Pressure** of partial charge',
              'Density or Pressure** of full charge'
            ]
          }
        ];
      case 's2_purchasedElectricity':
        return [
          'Start Date',
          'End Date',
          "Facility ID",
          "Location (Country)",
          "Amount of Electricity Consumption",
          "Units",
          "Calculation Approach",
          "Type of Emission Factor",
          "Custom Emission Factor"
        ];
      case 's3_transportation':
        return [
          "Facility ID",
          'Start Date',
          'End Date',
          "Description",
          "Category",
          "Location (Country)",
          "Emission Factor Dataset",
          "Mode of Transport",
          "Activity Type",
          "Vehicle Type",
          "Amount of Activity Type",
          "Units of Measurement",
          "Emissions (metric tons CO2e)",
          "Percentage of emissions calculated using data obtained from suppliers or value chain partners",
          "CO2 (tonnes)",
          "CH4 (tonnes)",
          "N2O (tonnes)",
          "CO2e (tonnes)",
          "Biofuel COe (tonnes)",
          "EF (kgCO2e/unit)"
        ];
      default:
        return [];
    }
  }

  getstationaryCombustionData = (page = 1) => {
    this.getstationaryCombustionCallId = sendAPIRequest(`${configJSON.getstationaryCombustionTable}?page=${page}&per_page=${25}`, {
      method: 'GET',
      headers: { token: this.token }
    })
  }

  getmobileCombustionData = (page = 1) => {
    this.getmobileCombustionCallId = sendAPIRequest(`${configJSON.getmobileCombustionTable}?page=${page}&per_page=${25}`, {
      method: 'GET',
      headers: { token: this.token }
    })
  }

  getrefrigerentsData = (page = 1) => {
    this.getrefrigerentsCallId = sendAPIRequest(`${configJSON.getrefrigerentsTable}?page=${page}&per_page=${25}`, {
      method: 'GET',
      headers: { token: this.token }
    })
  }

  getPurchasedElectricityData = (page = 1) => {
    this.getPurchasedElectricityCallId = sendAPIRequest(`${configJSON.getPurchasedElectricityTable}?page=${page}&per_page=${25}`, {
      method: 'GET',
      headers: { token: this.token }
    })
  }

  getTransportationData = (page = 1) => {
    this.getTransportationCallId = sendAPIRequest(`${configJSON.getTransportationTable}?page=${page}&per_page=${25}`, {
      method: 'GET',
      headers: { token: this.token }
    })
  }

  handleSubmitCurrentData = (currentData:any, index:string, values:any) => {
    let updatedData = currentData;
    if (updatedData[index].id) {
      updatedData[index] = { ...values, id: updatedData[index].id };
    } else {
      updatedData[index] = { ...values };
    }
    return updatedData;
  }

  handleScSubmission = (values:any, onCloseCfModal:(key:string)=>void) => {
    if(this.state.isEditCfStationaryCombustionForm){
      const currentData = [...this.state.CfStationaryCombustionTableData];
      const index = this.state.editCfStationaryCombustionDataIndex.ind;

      const cData = this.handleSubmitCurrentData(currentData, index, values);
      this.setState({CfStationaryCombustionTableData: cData});
    }
    else{
      this.setState({CfStationaryCombustionTableData: [...this.state.CfStationaryCombustionTableData, values]})
    }
    
    this.setState({isEditCfStationaryCombustionForm: false});
    onCloseCfModal("s1_stationaryCombustion");
  }

  handleFormSubmission = (activeTab:string, values:any, onCloseCfModal:(key:string)=>void) => {
    switch (activeTab) {
      case 's1_stationaryCombustion':
        this.handleScSubmission(values, onCloseCfModal);
      break;
      case 's1_mobileCombustion':
        if(this.state.isEditCfMobileCombustionForm){
          const currentData = [...this.state.cfMobileCombustionTableData];
          const index = this.state.editCfMobileCombustionDataIndex.ind;
          
          const cData = this.handleSubmitCurrentData(currentData, index, values);
          this.setState({cfMobileCombustionTableData: cData});
        }
        else {
          this.setState({cfMobileCombustionTableData: [...this.state.cfMobileCombustionTableData, values]})
        }
        
        this.setState({isEditCfMobileCombustionForm: false});
        onCloseCfModal("s1_mobileCombustion");
      break;  
      case "s1_refrigerants":
        if(this.state.isEditCfRefrigerentsForm){
          const currentData = [...this.state.cfRefrigerentsTableData];
          const index = this.state.editCfRefrigerentsDataIndex.ind;

          const cData = this.handleSubmitCurrentData(currentData, index, values);
          this.setState({cfRefrigerentsTableData: cData});
        }
        else{
          this.setState({cfRefrigerentsTableData: [...this.state.cfRefrigerentsTableData, values]})
        }
        
        this.setState({isEditCfRefrigerentsForm: false});
        onCloseCfModal("s1_refrigerants");
      break;   
      case "s2_purchasedElectricity":
        if(this.state.isEditCfPurchasedElectricityForm){
          const currentData = [...this.state.cfPurchasedElectricityTableData];
          const index = this.state.editCfPurchasedElectricityDataIndex.ind;

          const cData = this.handleSubmitCurrentData(currentData, index, values);
          this.setState({cfPurchasedElectricityTableData: cData});
        }
        else{
          this.setState({cfPurchasedElectricityTableData: [...this.state.cfPurchasedElectricityTableData, values]})
        }
        
        this.setState({isEditCfPurchasedElectricityForm: false});
        onCloseCfModal("s2_purchasedElectricity");
      break;  
      case "s3_transportation": 
        if(this.state.isEditCfTransportationForm){
          const currentData = [...this.state.cfTransportationTableData];
          const index = this.state.editCfTransportationDataIndex.ind;

          const cData = this.handleSubmitCurrentData(currentData, index, values);
          this.setState({cfTransportationTableData: cData});
        }
        else{
          this.setState({cfTransportationTableData: [...this.state.cfTransportationTableData, values]})
        }
        
        this.setState({isEditCfTransportationForm: false});
        onCloseCfModal("s3_transportation");
      break;
    }

    this.setState({SaveDraftObserver: true});
    toast.success("Data Added Successfully.");
  };

  handleDeleteData = (index:number) => {
    switch (this.state.cfNavActivate) {
      case "s1_stationaryCombustion":
        const currentRowSc = this.state.CfStationaryCombustionTableData.find((data: any, i: number) => i == index)

        currentRowSc.id && this.setState({scDeleteId: [...this.state.scDeleteId, currentRowSc.id]})

        this.setState(prevState => ({
          CfStationaryCombustionTableData: prevState.CfStationaryCombustionTableData.filter((data: any, i: number) => i !== index),
        }));
      break;

      case "s1_mobileCombustion":
        const currentRowMc = this.state.cfMobileCombustionTableData.find((data: any, i: number) => i == index)

        currentRowMc.id && this.setState({mcDeleteId: [...this.state.mcDeleteId, currentRowMc.id]})

        this.setState(prevState => ({
          cfMobileCombustionTableData: prevState.cfMobileCombustionTableData.filter((data: any, i: number) => i !== index),
        }));
      break;

      case "s1_refrigerants":
        const currentRowRf = this.state.cfRefrigerentsTableData.find((data: any, i: number) => i == index)

        currentRowRf.id && this.setState({rfDeleteID: [...this.state.rfDeleteID, currentRowRf.id]})

        this.setState(prevState => ({
          cfRefrigerentsTableData: prevState.cfRefrigerentsTableData.filter((data: any, i: number) => i !== index),
        }));
      break;

      case "s2_purchasedElectricity":
        const currentRowPe = this.state.cfPurchasedElectricityTableData.find((data: any, i: number) => i == index)

        currentRowPe.id && this.setState({peDeleteId: [...this.state.peDeleteId, currentRowPe.id]})

        this.setState(prevState => ({
          cfPurchasedElectricityTableData: prevState.cfPurchasedElectricityTableData.filter((data: any, i: number) => i !== index),
        }));
      break;

      case "s3_transportation":
        const currentRowTs = this.state.cfTransportationTableData.find((data: any, i: number) => i == index)

        currentRowTs.id && this.setState({tsDeleteId: [...this.state.tsDeleteId, currentRowTs.id]})

        this.setState(prevState => ({
          cfTransportationTableData: prevState.cfTransportationTableData.filter((data: any, i: number) => i !== index),
        }));

      break;
    }
   
    this.setState({SaveDraftObserver: true});
  }

  showBoolean(givenValue: boolean | string) {
    let orginalValue = this.checkBoolean(givenValue);
    let upValue = Boolean(orginalValue)
    let value
    if (upValue) {
      value = 'Yes';
    } else if (!upValue) {
      value = 'No';
    } else {
      return null
    }

    return value;
  }

  checkBoolean(givenValue: boolean | string) {
    if (typeof givenValue === 'boolean') {
      return givenValue;
    } else {
      let booleanValue;
      if (givenValue.toLowerCase() === 'yes' || givenValue.toLowerCase() == 'true') {
        booleanValue = true;
      } else if (givenValue.toLowerCase() === 'no' || givenValue.toLowerCase() == 'false') {
        booleanValue = false;
      } else {
        return null
      }

      return booleanValue;
    }
  }

  getNavigationMessage = (route: string, props: any, payload?: any) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);

    message.addData(getName(MessageEnum.NavigationPropsMessage), props);
    
    this.send(message);
  }

  getFuelListStationary = (event: any, callback: Function) => {
    let fuelRes1 = this.state.fuelDropdownDataEfExist.sort()
    let fuelRes2 = this.state.fuelDropdownData.sort()
    let fuelList = event.target.value == "true" ? fuelRes1 : fuelRes2
    
    this.setState({
      fuelListStationary: fuelList
    }, () => callback());
  }

  mobileCombustionFuelSource = (event: any, callback: Function) => {
    let { mobileCombustionDropdownData } = this.state
    let fuelSourceList = event.target.value == "Custom emission factor" ? mobileCombustionDropdownData.fuel_source_cef : mobileCombustionDropdownData.fuel_source;
    let vehicleTypeList = event.target.value == "Custom emission factor" ? [{ fuel_source: 'Example S1 - Mobile', value: '#N/A' }] : []
    this.setState({
      fuelSourceListMobile: fuelSourceList,
      vehicleTypeListMobile: vehicleTypeList
    }, () => callback());
  }

  mobileCombustionVehicleType = (event: any, callback: Function) => {
    let { mobileCombustionDropdownData } = this.state
    let vehicleTypeList = mobileCombustionDropdownData.vehicle_type.filter((data: any) => event.target.value == data.fuel_source).sort((a: any, b: any) => a.value.localeCompare(b.value, 'en', { sensitivity: 'base' }));
    this.setState({
      vehicleTypeListMobile: vehicleTypeList
    }, () => callback());
  }

  purchasedEmissionFactorValue = (e: any, values: any, setFieldValue: any) => {
    let filterList = ['0']
    if (e.target.value == 'Custom emission factor') {
      if (values.calculation_approach == 'Heat/Steam') {
        filterList = ['Example S2 - heat']
      } else {
        filterList = ['Example S2']
      }
    }
    this.setState({
      filterPurchasedEmissionFactor: filterList
    }, () => {
      setFieldValue('custom_emission_factor', this.setDefaultValue(this.state.filterPurchasedEmissionFactor))
    });
  }

  transportationFactorDataset = (event: any, setFieldValue: any) => {
    let { transportDropdownData } = this.state
    let activityTypeList = event.target.value == "Custom emission factor" ? transportDropdownData.activity_type_cef : transportDropdownData.activity_type
    this.setState({
      activityTypeList: activityTypeList,
    }, () => {
      setFieldValue('activity_type', this.setDefaultValue(this.state.activityTypeList));
      setFieldValue('mode_of_transport', '');
      setFieldValue('vehicle_type', '');
      setFieldValue('units_of_measurement', '');
    });
  }

  transportationVehicleType = (event: any, values: any, setFieldValue: any) => {
    let vehicleTypeListTransport = this.state.transportDropdownData.vehicle_type.filter((data: any) => event.target.value == data.mode_of_transport && data.emission_factor_dataset == values.emission_factor_dataset).sort((a: any, b: any) => a.value.localeCompare(b.value, 'en', { sensitivity: 'base' }))
    this.setState({
      vehicleTypeListTransport
    }, () => {
      setFieldValue('vehicle_type', this.setDefaultValue(this.state.vehicleTypeListTransport))
    });
  }

  transportUnitsOfMeasurement = (event: any) => {
    let measurementUnit = [
      { activity_type: 'Distance', value: 'nautical mile' },
      { activity_type: 'Distance', value: 'mile' },
      { activity_type: 'Distance', value: 'km' },
      { activity_type: 'Passenger Distance', value: 'passenger-mile' },
      { activity_type: 'Passenger Distance', value: 'passenger-km' },
      { activity_type: 'Vehicle Distance', value: 'Vehicle-mile' },
      { activity_type: 'Vehicle Distance', value: 'vehicle-km' },
      { activity_type: 'Weight Distance', value: 'ton-mile' },
      { activity_type: 'Weight Distance', value: 'tonne-km' },
    ]
    let filterMeasurementUnit = measurementUnit.filter((data: any) => event.target.value == data.activity_type).sort((a: any, b: any) => a.value.localeCompare(b.value, 'en', { sensitivity: 'base' }))
    this.setState({
      unitsOfMeasurement: filterMeasurementUnit
    });
  }

  setDefaultValue = (setState: any) => {
    let result = typeof setState[0] == 'object' ? setState[0].value : setState[0]
    return setState.length === 1 ? result : ''
  }  

  checkedCategory = () => {
    if (this.state.selectedCategory === "") return true;
    return ['Business Travel', 'Upstream T&D', 'Employee Commute'].includes(this.state.selectedCategory as string); 
  }

  // Customizable Area End

}

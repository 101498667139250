import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as yup from "yup";
import { toast } from "react-toastify";
import { sendCommunityJoinedMessage, sendCommunityUnjoinedMessage } from './Messages'
import { sendAPIRequest } from "../../../components/src/utils";
import {sendSharePopupMessage} from '../../share/src/Messages'
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtSavedValue: string;
  // Customizable Area Start
  token: string;
  userRole?: any;
  modal?: string;
  community?: any;
  initialValues?: any; // value for edit form
  addMemberAnchorEl?: any;
  memberOptions: any[];
  memberQuery?: string;
  memberSearchCallId?: string;
  memberPagination?: any;
  memberPaginateCallId?: any;
  addMemberCallId?: string;
  showAll: boolean;
  memberPopQuery: string;
  moderatorPopQuery: string;
  followCommunityCallId?: string;
  memberPopPaginateCallId?: string;
  memberPopSearchCallId?: string;
  moderatorPopPaginateCallId?: string;
  moderatorPopSearchCallId?: string;
  openSharePostModal?: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CommunityDetailController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  initMemberCallId: string = "";
  initUsersCallId: string = "";
  initUsersList: any[] = [];
  initUsersPagination?: any;
  getCommunityDetailCallId: string = "";
  token: string = "";


  // Customizable Area End
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const authToken = await getStorageData('authToken');
    this.token = authToken;
    console.log(authToken, "auth token check");
    if (authToken == null) {
      this.props.navigation.navigate("Home");
    } else {
    }
    this.setState({token: authToken}, () => {
    this.initDetailPage()
  });
    // Customizable Area End
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.GetAllCategoriesResponseMessage),
      getName(MessageEnum.GetAllSubCategoriesResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtSavedValue: "",
      // Customizable Area Start
      token: "",
        memberOptions: [],
        memberPaginateCallId: '',
        memberPopQuery: '',
        moderatorPopQuery: '',
        showAll: false,
        memberPopPaginateCallId: '',
        memberPopSearchCallId: '',
        moderatorPopSearchCallId: '',
        moderatorPopPaginateCallId: '',
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if(apiRequestCallId === this.initUsersCallId) {
        if(!Array.isArray(responseJson?.data)) return;
        const allUserOptions = responseJson.data.map((d:any) => d.attributes)
        const pagination = responseJson.meta.pagination
        this.initUsersList = allUserOptions
        this.initUsersPagination = pagination
        this.setState({ 
          memberOptions: allUserOptions, 
          memberPagination: pagination, 
        })
      } else if (apiRequestCallId === this.state.memberSearchCallId) {
        if(!Array.isArray(responseJson?.data)) 
          this.setState({ memberSearchCallId: '', memberOptions: [], memberPagination: undefined })
        else {
          const memberOptions = responseJson.data.map((d:any) => d.attributes)
          this.setState({ 
            memberSearchCallId: '', 
            memberOptions, 
            memberPagination: responseJson?.meta?.pagination 
          })
        }
      } else if (apiRequestCallId === this.state.memberPaginateCallId) {
        if(Array.isArray(responseJson?.data)) {
          const options = responseJson.data.map((d:any) => d.attributes)
          const memberOptions = this.filterUnique(this.state.memberOptions.concat(options))
          this.setState({
            memberOptions,
            memberPaginateCallId: '',
            memberPagination: responseJson?.meta?.pagination
          })
        }
      } else if (apiRequestCallId === this.state.addMemberCallId) {
        toast.success("Members added successfully")
        this.loadInitialUsers()
        const community = {...(responseJson?.data?.attributes||{}), updatedAt: Date()}
        this.setState({ addMemberCallId: '', addMemberAnchorEl: null, community  })
      } else if (apiRequestCallId === this.getCommunityDetailCallId) {
        if(responseJson?.data?.attributes) {
          const community = {...(responseJson.data.attributes||{}), updatedAt: Date()}
          this.setState({ community, initialValues: this.communityToInitialValues(community) })
          this.getCommunityDetailCallId = ""
        }
      } else if(apiRequestCallId === this.state.followCommunityCallId) {
        const community = {...this.state.community};
        if(community.community_type === "public") {
          if(community.in_community){
            sendCommunityUnjoinedMessage(community)
          } else {
            sendCommunityJoinedMessage(community)
          }
          community['in_community'] = !community.in_community;
          this.loadCommunityDetail()
        } else {
          if(community.in_community)
          { 
            sendCommunityUnjoinedMessage(community)
            community.in_community = false
            this.loadCommunityDetail()
          }
          else
            community['join_request_pending'] = true  
          toast.success("Request sent to moderators")      
        }
        this.setState({ community, followCommunityCallId: '' });
      } else if (apiRequestCallId === this.state.memberPopPaginateCallId) {
        if(!Array.isArray(responseJson?.data)) return;
        let community = {...this.state.community}
        community.members.data = community.members.data.concat(responseJson.data)
        community.members.meta.pagination = responseJson.meta.pagination
        this.setState({
          community,
          memberPopPaginateCallId: '',
        })
      } else if (apiRequestCallId === this.state.memberPopSearchCallId){
       if(!Array.isArray(responseJson?.data)) return;
        let community = {...this.state.community}
        community.members.data = responseJson.data
        community.members.meta.pagination = responseJson.meta?.pagination
        this.setState({ 
          community,
          memberPopSearchCallId: '',
        }) 
      } else if (apiRequestCallId === this.state.moderatorPopPaginateCallId) {
        if(!Array.isArray(responseJson?.data)) return;
        let community = {...this.state.community}
        community.moderators.data = community.moderators.data.concat(responseJson.data)
        community.moderators.meta.pagination = responseJson.meta.pagination
        this.setState({
          community,
          moderatorPopPaginateCallId: '',
        })
      } else if (apiRequestCallId === this.state.moderatorPopSearchCallId){
       if(!Array.isArray(responseJson?.data)) return;
        let community = {...this.state.community}
        community.moderators.data = responseJson.data
        community.moderators.meta.pagination = responseJson.meta?.pagination
        this.setState({ 
          community,
          moderatorPopSearchCallId: '',
        }) 
      }
      
      //   if (responseJson && responseJson.errors) {
      //     // this.setState({ refresh: false });
      //     this.parseApiErrorResponse(responseJson.errors);
      //     this.parseApiCatchErrorResponse(responseJson.errors);
      //   } else if (apiRequestCallId === this.listCommunitiesCallId || apiRequestCallId === this.communityFilterCallId) {
      //     let communities = []
      //     if(Array.isArray(responseJson?.communities?.data))
      //       communities = responseJson.communities.data
      //     else if (Array.isArray(responseJson?.data))
      //       communities = responseJson.data

      //     this.setState({
      //       communities,
      //       loadingCommunities: false,
      //       communitiesPagination: responseJson.meta?.pagination,
      //     })
      //   }
      // Customizable Area End
    }
  }
  // Customizable Area Start
  hideModal = () => this.setState({ modal: '' })

  communityToInitialValues = (community: any) => {
    return {
      ...community,
      community_type: community.community_type !== "private",
      user_ids: community.member_ids,
      image: community.community_thumbnail_url,
      sub_category_ids: community.Sub_category_ids
    }
  }

  getModerators = () => {
    if (!this.state.community) return []
    const mods =  this.state.community.moderators.data.map((m: any) => m.attributes)
    console.log({moderators: mods})
    return mods
  }

  getMembers = () => {
    if(!this.state.community) return []
    return this.state.community.members.data.map((m: any) => m.attributes)
  }

  addMemberClick = (e: any) => {
    if(this.state.community.is_moderator||this.state.community.is_admin)
      this.setState({ addMemberAnchorEl: e.currentTarget })
    else toast.warn("Only admin and moderator can add members.") 
  }
  
  editCommunityClick = (e: any) => {
    if(this.state.community.is_admin) {
      this.setState({ modal: 'edit' })
    } else toast.warn("Only admin can edit this community.")
  }

  initDetailPage = () => {
    this.loadInitialUsers()
    this.loadCommunityDetail()
  }

  // for create community form
  loadInitialUsers = () => {
    const { token } = this.state
    const id = this.props.navigation.getParam('id')
    this.initUsersCallId = sendAPIRequest(
      configJSON.addMembersEndpoint.replace(':id', id),
      { method: 'GET', headers: { token } }
    )
  }

  addMemberSearch = (query: string) => {
    if(query){
      this.setState({ 
        memberSearchCallId: this.handleUserSearch(query), 
        memberQuery: query, 
        memberPaginateCallId: '' 
      })
    } else {
      this.setState({ 
        memberOptions: this.initUsersList, 
        memberPagination: this.initUsersPagination, 
        memberQuery: '',
        memberSearchCallId: '',
      })
    }
  }

  handleUserSearch = (query: string, page?: number) => {
    const id = this.props.navigation.getParam('id')
    const { token } = this.state
    const params = new URLSearchParams()
    query && params.append('query', query)
    page && params.append('page', String(page))
    const url = configJSON.addMembersEndpoint.replace(':id', id) +"?"+ params.toString()
    return sendAPIRequest(url , { method: 'GET', headers: {token} })
  }

  // callback to paginate members onscroll
  addMemberPaginate = () => {
    if(this.state.memberPaginateCallId !== '') return
    if(!this.state.memberPagination) return
    const { next_page } = this.state.memberPagination
    if(next_page)
      this.setState({ memberPaginateCallId: this.handleUserSearch(
        this.state.memberQuery || '', 
        next_page)  
      })
  }

  /**
  * From list of users, remove duplicate users
  */
  filterUnique = (items: any[]) => {
    const hashmap = new Set()
    return items.filter((i: any)=>{
      if(hashmap.has(i.id)) return false
      hashmap.add(i.id)
      return hashmap
    })
  }

  handleAddMembersSubmit = (account_ids: any[]) => {
    const url = configJSON.addMembersEndpoint.replace(':id', this.state.community?.id)
    const {token} = this.state
    const addMemberCallId = sendAPIRequest(url, {
      method: 'POST',
      headers: { token, 'Content-Type': 'application/json' },
      body: { account_ids }
    })
    this.setState({ addMemberCallId })
  }

  loadCommunityDetail = () => {
    const { token } = this.state
    const id = this.props.navigation.getParam('id')
    this.getCommunityDetailCallId = sendAPIRequest(configJSON.getCommunityDetailEndpoint.replace(':id', id), {
      method: 'GET',
      headers: { token }
    })
  }

  handleCommunityUpdateCallback = (data: any) => {
    if(data.attributes)
      this.setState({ 
        community: {...data.attributes, updatedAt: Date()}, 
        initialValues: this.communityToInitialValues(data.attributes) 
      })
  }

  joinUnjoinClick = () => {
    if(this.state.community.is_admin) {
      toast.warn("Admin cannot unjoin")
    } else if (this.state.community.is_moderator){
      toast.warn("Moderator cannot unjoin")
    } else{
      const community_id = this.state.community.id
      const { token } = this.state
      const body = new FormData();
      const url = this.state.community.community_type === "private"?configJSON.followPvtCommunityEndpoint:configJSON.followCommunityEndpoint
      body.append('[data][attributes][community_id]', community_id)
      const followCommunityCallId = sendAPIRequest(url, {
        method: 'POST',
        body,
        headers: {token}
      })
      this.setState({ followCommunityCallId })
    }
  }

  handleMemberPopSearch = (query: string) => {
    let callId = ''
    if(query == this.state.memberPopQuery) return
    const { token, community }  = this.state
    const qs = new URLSearchParams({ query })
    callId = sendAPIRequest(
        configJSON.listCommunityMembersEndpoint.replace(':id', community.id)+"?"+qs.toString(), 
        {
          method: 'GET',
          headers: { token }
        }
      )
    this.setState({ 
      memberPopSearchCallId: callId, 
      memberPopQuery: query, 
      memberPopPaginateCallId: ''  
    })
  }

  handleModeratorPopSearch = (query: string) => {
    let callId = ''
    if(query == this.state.moderatorPopQuery) return
    const { token, community }  = this.state
    const qs = new URLSearchParams({ query })
    callId = sendAPIRequest(
        configJSON.listCommunityModeratorsEndpoint.replace(':id', community.id)+"?"+qs.toString(), 
        {
          method: 'GET',
          headers: { token }
        }
      )
    this.setState({ 
      moderatorPopSearchCallId: callId, 
      moderatorPopQuery: query, 
      moderatorPopPaginateCallId: ''  
    })
  }

  handleModeratorPopPaginate = () => {
    if(this.state.moderatorPopPaginateCallId) return
      let callId = ''
    const {token, community} = this.state
    const community_id = community.id
    let page = community.moderators?.meta?.pagination?.next_page
    if(!page && community.moderators.data.length<community.moderators.meta.total)
      page = 2
    const query = this.state.moderatorPopQuery
    const qs = new URLSearchParams({page})
    if(query)
        qs.append('query', query)
    if(page)
      callId = sendAPIRequest(
        configJSON.listCommunityModeratorsEndpoint.replace(':id', community_id)+"?"+qs.toString(), 
        {
          method: 'GET',
          headers: { token }
        }
      )
    this.setState({ moderatorPopPaginateCallId: callId  })
  }

  handleMemberPopPaginate = () => {
    if(this.state.memberPopPaginateCallId) return // prevent double calls
    let callId = ''
    const { token, community }  = this.state
    const community_id = community.id
    let page = community.members?.meta?.pagination?.next_page
    if(!page && community.members.data.length<community.members.meta.total)
      page = 2
    const query = this.state.memberPopQuery
    // if(query !== this.state.memberPopQuery)
    const qs = new URLSearchParams({ page })
    if(query)
      qs.append('query', query)
    if(page)
      callId = sendAPIRequest(
        configJSON.listCommunityMembersEndpoint.replace(':id', community_id)+"?"+qs.toString(), 
        {
          method: 'GET',
          headers: { token }
        }
      )

    this.setState({ memberPopPaginateCallId: callId  })
  }

  shareCommunityClick = () => {
    const id = this.state.community.id
     let data = {
      userId: id,
      link: `${window.location.origin}/community/${id}/`,
      shareableType: 'BxBlockCommunityforum::Community',
      shareableId: id
    }
    sendSharePopupMessage(data)
  }

  handleMemberExport = () => {
    const msg = new Message(getName(MessageEnum.ExportCSVMessage))
    msg.addData(
      getName(MessageEnum.ExportCSVLinkData),
      configJSON.exportMembersEndpoint.replace(':id', this.state?.community?.id)
    )
    runEngine.sendMessage(msg.id, msg)
  }
  
  handleModeratorExport = () => {
    const msg = new Message(getName(MessageEnum.ExportCSVMessage))
    msg.addData(
      getName(MessageEnum.ExportCSVLinkData),
      configJSON.exportModeratorsEndpoint.replace(':id', this.state?.community?.id)
    )
    runEngine.sendMessage(msg.id, msg)
  }
  // Customizable Area End
}

// Customizable Area Start
const sampleCommunity: any = {
  id: 127,
  name: "Builder test two",
  description: "Builder test two",
  community_type: "private",
  account_id: 276,
  in_community: false,
  post_count: 2,
  preferences: ["Environment", "Social"],
  preference_ids: [1, 2],
  categories: ["SDG"],
  category_ids: [1],
  Sub_categories: ["GOAL 14: Life Below Water", "GOAL 15: Life on Land"],
  sub_category_ids: [14,15],
  community_thumbnail_url: "https://corpstageplatform-95566-ruby.b95566.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcklCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--b52a1aff080e982f6f55c3e3926ff4d38ebe1b30/Screenshot%202022-05-17%20at%206.32.42%20PM.png",
  admin: {
    data: {
      id: "276",
      type: "community_data",
      attributes: {
        id: 276,
        first_name: "Sergio",
        last_name: "Perez",
        profile_pic:
          "",
      },
    },
  },
  tags: {
    data: [],
  },
  moderators: {
    data: [
      {
        id: "268",
        type: "community_data",
        attributes: {
          id: 268,
          first_name: "Sneha",
          last_name: "Khanna",
          profile_pic: "",
        },
      },
      {
        id: "267",
        type: "community_data",
        attributes: {
          id: 267,
          first_name: "Sneha",
          last_name: "Khanna",
          profile_pic: "",
        },
      },
      {
        id: "266",
        type: "community_data",
        attributes: {
          id: 266,
          first_name: "Sneha",
          last_name: "Khanna",
          profile_pic: "",
        },
      },
    ],
    meta: {
      total: 3,
    },
  },
  members: {
    data: [
      {
        id: "297",
        type: "community_data",
        attributes: {
          id: 297,
          first_name: "nitin",
          last_name: "test",
          profile_pic: "",
        },
      },
    ],
    meta: {
      total: 1,
    },
  },
};
// Customizable Area End

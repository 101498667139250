import React from "react";
import CorpsStageCoinsController, { Props } from "./CorpsStageCoinsController";

// Customizable Area Start
import DashboardLayout from "../../../components/src/DashboardLayout.web";
import {
    Paper, Divider, Typography, Button, Avatar, TableContainer, Table, TableHead, TableRow, TableCell, TableBody,
} from '@material-ui/core'
import { styled } from '@material-ui/styles'
import Flex from "../../../components/src/Flex.web";
import OverlayLoader from '../../../components/src/Loader.web'
import { iconCorp } from "./assets";
import Pagination from '@material-ui/lab/Pagination';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {Popover} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";

// import classes from './ServiceWeb.module.css';

import UserContext from "../../../components/src/UserProvider";
// Customizable Area End
export default class CorpsStageCoins extends CorpsStageCoinsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { navigation } = this.props
        const wallet = this.state.corpstageWallet
        const transferHistory = this.state.transferCoinsList
        return (
            <>
                <DashboardLayout token={this.state.token || ''} navigation={navigation}>
                    <OverlayLoader loading={!!this.state.loadingCorpstageCoins} />
                    <DIV>
                    <CoinsHead>
                        Corpstage Coins
                    </CoinsHead>
                    <CorpCoins>
                        <FlexD >
                            <Flex col>
                                <Typography style={{whiteSpace : 'nowrap', textAlign:'center'}}>Total no of Coins</Typography>
                                <TC>{wallet?.total_coin}</TC>
                            </Flex>
                            <Divider orientation="vertical" flexItem />
                            <Flex col>
                                <Typography style={{whiteSpace : 'nowrap', textAlign:'center'}}>Debited</Typography>
                                <TC>{wallet?.debited}</TC>
                            </Flex>
                            <Divider orientation="vertical" flexItem />
                            <Flex col>
                                <Typography style={{whiteSpace : 'nowrap', textAlign:'center'}}>Credited</Typography>
                                <TC>{wallet?.credited}</TC>
                            </Flex>
                        </FlexD>
                        <Flex>
                            <Flex col gap="8px">
                                <RoundedBtn style={{ padding: '0px 0px', backgroundColor: '#DAF3DA' }} onClick={this.handleRaiseRequest}>
                                    Raise issue/ Request
                                </RoundedBtn>
                                <Flex gap="8px">
                                    <RoundedBtn onClick={this.createTransferClick} style={{ padding: '0px 20px' }}>
                                        Transfer
                                    </RoundedBtn>
                                    <RoundedBtn onClick={this.handlePurchase} style={{ padding: '0px 20px', backgroundColor: '#009E00', color: '#fff' }}>
                                        Purchase
                                    </RoundedBtn>
                                </Flex>
                            </Flex>
                        </Flex>
                    </CorpCoins>
                    <CoinsHead>
                        Transaction History
                    </CoinsHead>
                    <CorpsTable>
                    {/* <Paper style={{ width: '100%', overflow: 'hidden' }}> */}
                        <TableContainer component={Paper} >
                            <Table style={{minWidth:650}} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TH>Sl. No.</TH>
                                        <TH>
                                            <Flex justify="center" align="center">
                                                <div>Date</div>
                                                <div>
                                                    <div><ArrowDropUpIcon style={{ color: "grey" }} onClick={() => this.handleSort('to_date', 1)} /></div>
                                                    <div><ArrowDropDownIcon style={{ color: "grey" }} onClick={() => this.handleSort('to_date', 2)} /></div>
                                                </div>
                                            </Flex>
                                        </TH>
                                        {window.screen.width>768 &&
                                        <TH>Details</TH>
                                        }
                                        <TH>
                                            <Flex justify="center" align="center">
                                                <div>Type</div>
                                                <div>
                                                    <div><ArrowDropUpIcon style={{ color: "grey" }} onClick={() => this.handleSort('transaction_type', 1)} /></div>
                                                    <div><ArrowDropDownIcon style={{ color: "grey" }} onClick={() => this.handleSort('transaction_type', 2)} /></div>
                                                </div>
                                            </Flex>
                                        </TH>
                                        <TH>No of coins</TH>
                                        <TH style={{ textAlign: 'start' }}>Transfer to/by</TH>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {transferHistory && transferHistory.length > 0 ? (transferHistory?.map((item: any, index: number) => {
                                        return (
                                            <>
                                                <TableRow>
                                                    <TR>{8 * (this.state.current_page - 1) + (index + 1)}</TR>
                                                    <TR>{this.getNewDate(item?.created_at)}</TR>
                                                    {window.screen.width>768 && 
                                                    <TR style={{ textTransform: 'capitalize' }}>
                                                        <span title={item?.details}>{item?.details.length>8 ? `${item?.details.substr(0, 8)}...` : item?.details}</span>
                                                        {/* <MouseOverPopover cmmnts={item?.details} /> */}
                                                    </TR>
                                                    }

                                                    <TR style={{ textTransform: 'capitalize' }}>{item?.transaction_type}</TR>
                                                    <TR>{item?.no_of_coins}</TR>
                                                    <TR>
                                                        <Flex align="center" justify="start" style={{ textTransform: 'capitalize' }} >
                                                            <Avatar alt={item?.transfer_to_by} src={item?.profile_pic?.profile_pic} style={{ width: "30px", height: "30px", marginRight: "10px" }} />
                                                            {item?.transfer_to_by}
                                                        </Flex>
                                                    </TR>
                                                </TableRow>
                                            </>
                                        )
                                    })) :
                                        (

                                        <TableRow style={{ height: '400px' }}>
                                            <TI colSpan={6}>
                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <img width="50px" src={iconCorp} style={{
                                                        color: "grey", objectFit: 'contain',
                                                    }} />
                                                </div>
                                                <Typography align="center" style={{ lineHeight: '3.5rem' }}>No Beneficiary is Selected</Typography></TI>
                                        </TableRow>
                                        
                                        )
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {/* </Paper> */}
                        <Pagin>
                            {window.screen.width>950 ?
                            <Pagination color="primary" variant="outlined"  count={this.state.total_pages} page={this.state.pages} onChange={this.handlePageChange} />
                             :
                            <Pagination color="primary" size="small" count={this.state.total_pages} page={this.state.pages} onChange={this.handlePageChange} />
                            } 
                        </Pagin>
                    </CorpsTable>
                    </DIV>
                </DashboardLayout>
                {/* {this.state.modal === 'transfer' && transferModal} */}
            </>
        )
        // Customizable Area End
    }
}

// Customizable Area Start
CorpsStageCoinsController.contextType = UserContext

const useStyles = makeStyles((theme) => ({
    popover: {
      pointerEvents: "none",
    },
    paper: {
    //   padding: theme.spacing(1),
    //   position:'absolute',
      backgroundColor:'red',
      minWidth:'400px'
    }
  }));

const MouseOverPopover=(props:any)=> {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  
    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handlePopoverClose = () => {
      setAnchorEl(null);
    };
    const classes = useStyles();
    const open = Boolean(anchorEl);
  
    return (
      <div>
        <Typography
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
         {props.cmmnts.length>8 ? props.cmmnts.substr(0, 8): props.cmmnts}
        </Typography>
        {/* <Popover
          id="mouse-over-popover"
        //   sx={{
        //     pointerEvents: 'none',
        //   }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography>I use Popover.</Typography>
        </Popover> */}
        <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left"
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography>{props.cmmnts}</Typography>
      </Popover>
      </div>
    );
  }

const DIV = styled('div')({
    '@media (max-width: 768px)':{
        padding:'0px 20px',
    }
})

const TI = styled(TableCell)({
    lineHeight: "2.5rem",
    padding: '16px 10px',
})

const CoinsHead = styled('div')({
    margin: '40px 0px 15px 12px',
    fontSize: '1.2rem',
    fontWeight: 600,
    '@media screen and (max-width: 768px)':{
       margin: '20px 0px 10px 12px',
     },
})

const FlexD=styled('div')({
     width: '50%',
     display   :'flex',
     gap:'10px',
     justifyContent:'space-between',
     alignItems:'center',
     '@media screen and (max-width: 479px)':{
        width:'100%',
        flexDirection:'column',
        alignItems:'center',
        gap:'6px',
     },
     '@media screen and (min-width:768px) and (max-width: 985px)':{
        width:'100%',
        flexDirection:'column',
        alignItems:'center',
        gap:'6px',
     },
     '@media screen and (max-width: 1200px)':{
        width:'70%',
     },
     '@media screen and (min-width:768px) and (max-width: 850px)':{
        width:'100%',
     },
})

const CorpCoins = styled('div')((props: any) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'fff',
    borderRadius: '12.97px',
    lineHeight: '20px',
    flex: 1,
    padding: '20px',
    gap: '60px',

    // [props.theme.breakpoints.between('xs', 'sm')]: {
    //     flexDirection: 'column',
    //     alignItems: 'flex-start',
    //     backgroundColor: 'red',
    // }

    '@media screen and (max-width: 479px)': {
        flexDirection: 'column',
        alignItems: 'center',
        gap:'20px',
    },
    '@media (min-width:480px) and (max-width:767px)':{
        flexDirection:'column',
        gap:'20px',
    },
    '@media screen and (max-width: 1200px)':{
        flexDirection:'column',
        gap:'20px',
     },
}))

const ARI=styled('div')((props:any)=>({
    [props.theme.down('xs')]:{
        margin:0,
    },
}))

const TC = styled('div')({
    fontSize: '1.5rem',
    fontWeight: 700,
    lineHeight: '35px',
    textAlign: 'initial',
    '@media screen and (max-width: 479px)':{
        textAlign:'center',
    },
    '@media screen and (min-width:768px) and (max-width: 985px)':{
        textAlign:'center',
     },
})

const CorpsTable = styled('div')({
    backgroundColor: '#fff',
    borderRadius: "12.97px",
    padding: '10px'
})

const RoundedBtn = styled(Button)({
    color: '#009c05',
    border: '1px solid #009c05',
    borderRadius: '38px',
    padding: '10px 20px',
    textTransform: 'none',
    fontWeight: 600
})

const TH = styled(TableCell)({
    color: "#000",
    fontWeight: 600,
    lineHeight: "1.5rem",
    padding: '16px 10px',
    textAlign: 'center',
    position: 'relative',
})

const TR = styled(TableCell)({
    lineHeight: "1.5rem",
    padding: '16px 10px',
    textAlign: 'center'
})

const AR = styled('div')({
    width: '20px',
    // float: 'right',
    position: 'absolute',
    right: '30%',
    top: '8%'
})

const Pagin = styled('div')({


    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px',
    '@media screen and (max-width:350px)':{
        display:'initial',
        padding:'10px 1px',
    },
    '@media screen and (min-width:768px) and (max-width:850px)':{
        display:'initial',
        padding:'10px 1px',
    }
    
    // '& > MuiPaginationItem-icon': {
    //    color: 'green',    
    //   },

    // ul: {
    //     "& > .MuiPaginationItem-root": {
    //       color: "green"
    //     }
    //   }
})


//   const ActionRoundedButtonInterested = styled(Button)((props: any)=>({
//     color: '#fff',
//     background: '#2caa38',
//     border: '2px solid #79bb74',
//     borderRadius: '25px',
//     padding: '5px 35px',
//     textTransform: 'none',
//     fontWeight: 600,
//     "&:hover": {
//       background: '#2caa38',
//     }
//    }))

// Customizable Area End


import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as yup from 'yup'
import { toast } from 'react-toastify'

import { sendAPIRequest } from '../../../components/src/utils'
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  open?: boolean;
  initialValues?: any; // initial values for edit form
  prefererence?:any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  token: string;
  open:boolean;
  image:any;
  checked:boolean;
  searchText:string;
  selectedOption:any[];
  preferences:any[];
  selectedPreferences: string[];
  addmembers:any[];
  modal: string;
  communities: any[];
  allCategories: any[];
  allSubCategories: any[];
  loadingCreate: boolean;
  memberOptions: any[];
  moderatorOptions: any[];
  allUserOptions: any[];
  moderatorSearchCallId: string;
  memberSearchCallId: string;
  community_type: boolean;
  categoryOptions: any[];
  subCategoryOptions: any[];
  loadingCommunities: boolean;
  communityQuery?: string;
  communitiesPagination?: {
    next_page?: number;
    total_pages: number;
  };
  sampleExcelUrl?: string;
  importIds: number[];
  importLoading: boolean;
  importError?: string;
  memberPagination?: any;
  moderatorPagination?: any;
  moderatorQuery: any;
  memberQuery: any;
  memberPaginateCallId?: any;
  moderatorPaginateCallId?: any;
  userRole?: any;
  communityPaginateCallId?: any;
  preference_ids: any[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PreferenceCatSubCatController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  listCommunitiesCallId: string = "";
  createCommunityCallId: string = "";
  userRoleCallId:string = "";
  initUsersCallId: string = "";
  initUsersList: any[] = []; // List of users for moderator/member dropdown initially
  initUsersPagination: any = undefined; // pagination for member moderator dropdown
  moderatorSearchCallId: string = "";
  memberSearchCallId: string = "";
  communityFilterCallId: string = "";
  allPreferences: any[] = [{id: 1, label: "E - Environmental"}, {id: 2, label: "S - Social"}, {id: 3, label: "G - Governance"}];
  allRequestPreferences: any[] = [{id: 1, label: "E - Environment"}, {id: 2, label: "S - Social"}, {id: 3, label: "G - Governance"}]
  preferenceIdToChar: any = {
    1: 'E',
    2: 'S',
    3: 'G'
  }
  getSampleExcelCallId: string = "";
  importCsvCallId: string = "";
  followCommunityCallId: string = "";
  joinCommunityCallId:{[key: string]: string} = {}
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const authToken = await getStorageData('authToken')

    if(authToken==null){
      this.props.navigation.navigate('Home')
    } else {
      this.loadCategoriesSubCategories()
    }
    // Customizable Area End
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.GetAllCategoriesResponseMessage),
      getName(MessageEnum.GetAllSubCategoriesResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      open:false,
      image:null,
      checked:false,
      searchText:'',
      selectedOption:[],
      preferences:[],
      selectedPreferences: [],
      addmembers:[],
      token: '',
      modal: '',
      communities: [],
      allCategories: [],
      allSubCategories: [],
      loadingCommunities: false,
      loadingCreate: false,
      moderatorOptions: [],
      memberOptions: [],
      allUserOptions: [],
      moderatorSearchCallId: "",
      memberSearchCallId: "",
      community_type: false,
      categoryOptions: [],
      subCategoryOptions: [],
      importIds: [],
      importLoading: false,
      moderatorQuery: '',
      memberQuery: '',
      userRole: "",
      preference_ids: [],
      
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // this.handleUserRole();
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    if(message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.errors) {
        // this.setState({ refresh: false });
        this.parseApiErrorResponse(responseJson.errors);
        this.parseApiCatchErrorResponse(responseJson.errors);
      } 
       
    } else if (message.id === getName(MessageEnum.GetAllCategoriesResponseMessage)) {
      const allCategories = message.getData(
        getName(MessageEnum.AllCategoriesDataMessage)
      )
      this.setState({ allCategories }, () => {
        if(!this.props.navigation.getParam('id')) return // check if edit page
        this.preferenceChangeEffect(this.props.initialValues.preference_ids)
        
      })
    } else if (message.id === getName(MessageEnum.GetAllSubCategoriesResponseMessage)) {
      
      const allSubCategories = message.getData(
        getName(MessageEnum.AllSubCategoriesDataMessage)
      )
      this.setState({ allSubCategories }, ()=>{
        if(!this.props.navigation.getParam('id')) return
        this.categoryChangeEffect(this.props.initialValues.category_ids)
      })
    }
    // Customizable Area End

  }

  // Customizable Area Start
  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  }

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  }
  
  loadCategoriesSubCategories= () => {
    setTimeout(() => {
      const msg = new Message(
        getName(MessageEnum.GetAllCategoriesMessage)
      )
      runEngine.sendMessage(msg.id, msg)
      
    }, 1000)
    setTimeout(() => {
      const msg2 = new Message(
        getName(MessageEnum.GetAllSubCategoriesMessage)
      )
      runEngine.sendMessage(msg2.id, msg2)
    }, 1000)
    
  }

  // for create community form
  



  

  

  



  preferenceChangeEffect = (ids: number[]) => {
    const lst = ids.map((id: any) => this.allPreferences.find((p: any)=>p.id === id)?.label?.slice?.(0, 1));
    const labels = new Set(lst);
    function filterCatBelongsToPreferences(cat: any){
      return cat.preferences.some((p: any)=>labels.has(p.slice(0, 1)))
    }
    const categoryOptions = this.state.allCategories.filter(filterCatBelongsToPreferences)

    const catIds = categoryOptions.map((c: any) => c.id)
    this.setState(
      { categoryOptions, selectedPreferences: lst as any[] }, 
    )
  }

  categoryChangeEffect = (ids: number[], preferences?: any[]) => {
    let plookup = new Set(preferences||this.state.selectedPreferences)
    if(this.props.initialValues?.preference_ids && plookup.size === 0)
      plookup = new Set(
          this.props.initialValues?.preference_ids.map(
            (p: any) => this.preferenceIdToChar[p]
          )
        ) 
   const subCategoryOptions = this.state.allSubCategories.filter((st: any) => {
      console.log({st})
      return st.categories.some((ct: any) => ids.includes(ct.id)) && st.preferences.some(
        (p: any)=> plookup.has(p.slice(0,1))
      )
    })

    console.log({catChangeEffect: subCategoryOptions, allSubCategories: this.state.allSubCategories, ids, plookup})
    this.setState({ subCategoryOptions })
  }

  




  


  

  /**
  * From list of users, remove duplicate users
  */
  filterUnique = (items: any[]) => {
    const hashmap = new Set()
    return items.filter((i: any)=>{
      if(hashmap.has(i.id)) return false
      hashmap.add(i.id)
      return hashmap
    })
  }

  /**
  * Compare arr and brr, regardless 
  */
  arrayEquals = (arr: any[], brr: any[]) => {
    if(arr.length!=brr.length) return false
    const aLookup = new Set(arr)
    return brr.every((b: any) => aLookup.has(b))
  }
  // remove category when preference is changed
  syncCatsWithPreference = (preference_ids: any[], cat_ids: any[], setCat: any, sub_cat_ids: any[], setSubCat: any) => {
    if(this.state.allCategories.length == 0) return 
    const PREF_MAP: any = {1: 'E', 2: 'S', 3: 'G'}
    const preferences = preference_ids.map((p: any)=>PREF_MAP[p])

    if(this.arrayEquals(this.state.selectedPreferences, preferences)) return 
    
    const cats = cat_ids.map((id: any)=>this.state.allCategories.find((ct: any)=>ct.id == id))
    
    const newCats = cats.filter((cat: any) => cat.preferences.some((p: any)=>preferences.includes(p.slice(0,1))))
    const newCatsIds = newCats.map((c: any) => c.id)
    if(!this.arrayEquals(cat_ids, newCatsIds))
    {
      setCat(newCatsIds)
    }

    // change sub-categories according to preferences
    let stselected = sub_cat_ids.map((id: any)=>this.state.allSubCategories.find((x: any)=>x.id==id))
    stselected = stselected.filter((st: any)=>st.preferences.some((p: any)=> preferences.includes(p.slice(0,1)))).map((st: any) => st.id)
    if(!this.arrayEquals(sub_cat_ids, stselected)) {
      setSubCat(stselected)
    }

    this.categoryChangeEffect(newCatsIds, preferences)
    this.preferenceChangeEffect(preference_ids)
  }

  // remove subCats when category is changed
  syncSubCatWithCats = (cat_ids: any[], sub_cat_ids: any[], setSubCat: any) => {
    if(this.state.allSubCategories.length === 0) return
    const sCats = sub_cat_ids.map((id: any) => this.state.allSubCategories.find((st: any)=>st.id ==id))
    const catLookup = new Set(cat_ids)
    const filteredCats = sCats.filter((st: any)=>{
      if(!st) return false
      return st.categories.some((cat: any)=>catLookup.has(cat.id))
    }).map((st: any)=>st.id)
    if(!this.arrayEquals(sub_cat_ids, filteredCats))
      setSubCat(filteredCats)
  }
  // Customizable Area End
  
}

import React from "react";

// Customizable Area Start
import CustomisableUserProfilesController, {
    Props,
    configJSON
} from "./CustomisableUserProfilesController";

import ActivityFeed from '../../ActivityFeed/src/ActivityFeed.web'
import DashboardLayout from "../../../components/src/DashboardLayout.web";
import OverlayLoader from "../../../components/src/Loader.web";
import PublicProfileCard from "./PublicProfileCard.web";
// Customizable Area End
export default class CustomisableUserProfiles extends CustomisableUserProfilesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.myRef = React.createRef();
        // Customizable Area End
    }

    // Customizable Area Start
    myRef: any;
    async componentDidMount() {
        super.componentDidMount();
        this.executeScroll();
    }
    executeScroll = () => this.myRef?.current?.scrollIntoView();
    // Customizable Area End

    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <DashboardLayout
                token={this.state.token}
                navigation={this.props.navigation}
            >
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div ref={this.myRef} style={{ maxWidth: "900px", width: "100%" }}>

                    {/* Public Profile Detail Start */}
                    <PublicProfileCard
                        navigation={this.props.navigation}
                        id=""
                        userId={JSON.stringify(this.props?.navigation?.getParam('user_id'))}
                    />
                    {/* Public Profile Detail End */}

                    <ActivityFeed
                        inside="UserProfile"
                        userId={JSON.stringify(this.props?.navigation?.getParam('user_id'))}
                        {...this.props}
                    />

                    <OverlayLoader
                        loading={!this.state.loadingPosts}
                    />
                </div>
                </div>
            </DashboardLayout>

            // Customizable Area End
        );
    }
}

// Customizable Area Start
// Customizable Area End

import React from "react";
// Customizable Area Start
import DashboardLayout from "../../../components/src/DashboardLayout.web";
import ImpactDashboardController, { Props } from "./ImpactDashboardController";
import { styled } from '@material-ui/styles';
import Chart from "react-apexcharts";
import { Table, TableHead, TableRow, TableBody, TableCell, Button, Modal, Typography, Divider, Box, TextField } from '@material-ui/core';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { closeIcon } from './assets';
import OverlayLoader from '../../../components/src/Loader.web'
// Customizable Area End
export default class ImpactDashboard extends ImpactDashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { navigation } = this.props;
        // Customizable Area End

        return (
        // Customizable Area Start
        <>{
            this.props.isProfile ?
                <DashBoardChart state={this.state} isProfile={true} handleOpen={this.handleOpen} handleClose={this.handleClose} handleCahnge={this.handleCahnge} handleUpdateData={this.handleUpdateData} isFirstColumnDuplicate={this.isFirstColumnDuplicate} />
                : <DashboardLayout isUserSurvey={false} isDashboard={true} token={this.state.token} navigation={navigation}  >
                    <DashBoardChart state={this.state} isProfile={false} handleOpen={this.handleOpen} handleClose={this.handleClose} handleCahnge={this.handleCahnge} handleUpdateData={this.handleUpdateData} isFirstColumnDuplicate={this.isFirstColumnDuplicate} />
                </DashboardLayout>
        }<OverlayLoader loading={this.state.loading} />
        </>
        // Customizable Area End
        )
    }
}
// Customizable Area Start
const HandleDataPointBtn: Function = ({ isProfile, handleOpen, SeriesForTabel, tableHead, endpointHead, role, initialData }: any) => {
    return !isProfile && role ? <DataPointBtn onClick={() => handleOpen(SeriesForTabel, tableHead, endpointHead, initialData)}>
        <EditIconText>
            <BorderColorIcon style={{ fontSize: '16px', marginRight: '10px' }} />Data points
        </EditIconText>
    </DataPointBtn> : null
}

export const DashBoardChart: Function = ({ state, handleOpen, handleClose, handleCahnge, isProfile, handleUpdateData, isFirstColumnDuplicate}: any) => {
   let style = {}
   let  Style2 = {}
   if(isProfile){
     style = { width: '100%', flexBasis: '100%' } 
     Style2 = { padding: '0' }
   }
    const role = state.role;
    const isEditable = !isProfile && role ? true : false

    return <DashboardWraper style={Style2}>
        {!isProfile ? <DashboardHead>Dashboard</DashboardHead> : null}
        <BarChartBox>
            <Chart options={!isProfile ? state.barOptions: {...state.barOptions,plotOptions: {
                    bar: {
                        borderRadius: 5,
                        columnWidth: '100%',
                        barWidth: '95%',
                        dataLabels: {
                            position: "top",
                            offsetX: -25
                        }
                    }
                },}} series={state.barSeries} type="bar" height={"400px"} />
        </BarChartBox>
        <BarChartBox>
            <Chart options={!isProfile ? state.FactorBarOption : {
                ...state.FactorBarOption, plotOptions: {
                    bar: {
                        borderRadius: 5,
                        columnWidth: '100%',
                        dataLabels: {
                            position: "top"
                        }
                    }
                },
                theme: {
                    palette: 'palette1'
                },
                stroke: {
                    colors: ["transparent"],
                    width: 5
                },
            }} series={state.FactorBarSeries} type="bar" height={"400px"}
            />
        </BarChartBox>
        <DashboardBox>
            <DashboardHead>GHG Emissions</DashboardHead>
            <DashboardChartWraper style={isProfile ? { width: '100%', flexBasis: '100%' } : {}}>
                {isEditable ? <DataPointBtn id="scope1btn" style={{ right: '210px', top: '6%' }} onClick={() => handleOpen('GHGEmissionsScope1SeriesForTabel', 'SCOPE 1 GHG EMISSIONS', 'GhgEmissionFirstScope', 'emptyData')}>
                    <EditIconText>
                        <BorderColorIcon style={{ fontSize: '16px', marginRight: '10px' }} />Data points
                    </EditIconText>
                </DataPointBtn> : null}
                <Chart options={state.GHGEmissionsScope1Option} series={state.GHGEmissionsScope1Series} type="area" height={"350px"} />
            </DashboardChartWraper>
            <DashboardChartWraper style={isProfile ? { width: '100%', flexBasis: '100%' } : {}}>
                {isEditable ? <DataPointBtn id="scope2btn" style={{ right: '210px', top: '6%' }} onClick={() => handleOpen('GHGEmissionsScope2SeriesForTabel', 'SCOPE 2 GHG EMISSIONS', 'GhgEmissionSecondScope', 'emptyData')}>
                    <EditIconText>
                        <BorderColorIcon style={{ fontSize: '16px', marginRight: '10px' }} />Data points
                    </EditIconText>
                </DataPointBtn> : null}
                <Chart options={state.GHGEmissionsScope2Option} series={state.GHGEmissionsScope2Series} type="area" height={"350px"} />
            </DashboardChartWraper>
            <DashboardChartWraper style={isProfile ? { width: '100%', flexBasis: '100%' } : {}}>
                {isEditable ? <DataPointBtn id='scope3btn' style={{ right: '210px', top: '6%' }} onClick={() => handleOpen('GHGEmissionsScope3SeriesForTabel', 'SCOPE 3 GHG EMISSIONS', 'GhgEmissionThirdScope', 'emptyData')}>
                    <EditIconText>
                        <BorderColorIcon style={{ fontSize: '16px', marginRight: '10px' }} />Data points
                    </EditIconText>
                </DataPointBtn> : null}
                <Chart options={state.GHGEmissionsScope3Option} series={state.GHGEmissionsScope3Series} type="area" height={"350px"} />
            </DashboardChartWraper>
            <DashboardChartWraper style={isProfile ? { width: '100%', flexBasis: '100%' } : {}}>
                <Chart options={state.GHGEmissionsTotalScopeOption} series={state.GHGEmissionsTotalScopeSeries} type="area" height={"350px"} />
            </DashboardChartWraper>
        </DashboardBox>
        <DashboardBox>
            <DashboardHead>Land, Water, Waste and Air Emissions</DashboardHead>
            <DashboardChartWraper style={style}>
                <HandleDataPointBtn role={role}
                    isProfile={isProfile} handleOpen={handleOpen} SeriesForTabel={'barChartForLandSeriesForTabel'} tableHead={'Land owned, Leased or mangeed'} endpointHead={'LandEmissions'} initialData={'LandEmissions'}
                />
                <Chart options={state.barChartForLandOption} series={state.barChartForLandSeries} type="bar" height={"350px"} />
            </DashboardChartWraper>
            <DashboardChartWraper style={style}>
                <HandleDataPointBtn role={role}
                    isProfile={isProfile} handleOpen={handleOpen} SeriesForTabel={'WaterEmissionsSeriesForTabel'} tableHead={'Water'} endpointHead={'WaterEmissions'} initialData={'WaterEmissions'}
                />
                <Chart options={state.barChartForWaterOption} series={state.barChartForWaterSeries} type="bar" height={"350px"} />
            </DashboardChartWraper>
            <DashboardChartWraper style={style}>
                <HandleDataPointBtn role={role}
                    isProfile={isProfile} handleOpen={handleOpen} SeriesForTabel={'barChartForWasteSeriesForTabel'} tableHead={'Waste'} endpointHead={'WasteEmissions'} initialData={'emptyAirData'}
                />
                <Chart options={state.barChartForWasteOption} series={state.barChartForWasteSeries} type="bar" height={"350px"} />
            </DashboardChartWraper>
            <DashboardChartWraper style={style}>
                <HandleDataPointBtn role={role}
                    isProfile={isProfile} handleOpen={handleOpen} SeriesForTabel={'AirEmissionsSeriesForTabel'} tableHead={'Air Emissions'} endpointHead={'AirEmissions'} initialData={'emptyAirData'}
                />
                <Chart options={state.barChartForAirOption} series={state.barChartForAirSeries} type="bar" height={"350px"} />
            </DashboardChartWraper>
        </DashboardBox>
        <DashboardBox>
            <DashboardHead>Categorywise Fines for Non-Compliance</DashboardHead>
            <DashboardChartWraper style={style}>
                {isEditable ? <DataPointBtn id="fineChart1" onClick={() => handleOpen('lineChartForCountryForTabel', 'Fine by Country', 'FundNumbers', 'CategoryEmptyData')}>
                    <EditIconText>
                        <BorderColorIcon style={{ fontSize: '16px', marginRight: '10px' }} />Data points
                    </EditIconText>
                </DataPointBtn> : null}
                <Chart options={state.lineChartForCountryOption} series={state.lineChartForCountrySeries} type="line" height={"350px"} />
            </DashboardChartWraper>
            <DashboardChartWraper style={style}>
                {isEditable ? <DataPointBtn id="fineChart2" onClick={() => handleOpen('lineChartForFinesForTabel', 'Fine by monetary value of fines (US$)', 'MonetaryFund', 'CategoryEmptyData')}>
                    <EditIconText>
                        <BorderColorIcon style={{ fontSize: '16px', marginRight: '10px' }} />Data points
                    </EditIconText>
                </DataPointBtn> : null}
                <Chart options={state.lineChartForFinesOption} series={state.lineChartForFinesSeries} type="line" height={"350px"} />
            </DashboardChartWraper>
        </DashboardBox>
        <Modal
            id="ModalComponent"
            open={state.openModel}
            onClose={() => handleClose()}
            aria-labelledby="simple-model-title"
            aria-describedby="simple-model-description"
        >
            <ChartModel detail={state?.selectedData} onCloseClick={handleClose} handleChange={handleCahnge} handleUpdateData={handleUpdateData} isFirstColumnDuplicate={isFirstColumnDuplicate} />
        </Modal>
    </DashboardWraper>
}

export const ChartModel: Function = ({ detail, onCloseClick, handleChange, handleUpdateData,isFirstColumnDuplicate }: any) => {
    return <ModalSection>
        <SurveyModelWrapper>
            <SectionItem style={{ fontSize: "14px" }}>
                <TitleSection>
                    <Typography style={{ fontSize: "24px", color: "#2A3035" }} variant="subtitle1" component="div">
                        {detail?.header}
                    </Typography>
                    <DivSection>
                        <BtnImg id="CloseBtn" src={closeIcon} onClick={() => onCloseClick()} alt="close" />
                    </DivSection>
                </TitleSection>
            </SectionItem>
            <Divider />
            <SurveyModelSection>

                <Table stickyHeader aria-label="sticky table" >
                    <TableHead>
                        <TableRow>
                            {detail.tableHead?.map((tableRow: string, index: number) =>
                                tableRow == 'Id' ? null :
                                    <TR key={index} style={{ fontWeight: 'bold' }}>
                                        {tableRow}
                                    </TR>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {detail.tableData?.map((item: any, index: number) =>
                            <TableRow key={index}>
                                {Object.keys(item).map((value: string, index1: number) =>
                                    value == 'id' ? null :
                                        <TR key={index1}>
                                            <MInputBase id="InputField" error={detail.isError===item?.[value]} type={value === 'country' ? "text" : "number"} name={value} value={item?.[value] === 0 && value === 'year' ? 'yyyy' : item?.[value]} onChange={(event) => handleChange(event, index, value)} placeholder={value === 'year' ? "yyyy" : ""} />
                                        </TR>
                                )}
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </SurveyModelSection>
            <SaveBtn id="handleUpdateBtn" onClick={() => handleUpdateData()}>
                Save
            </SaveBtn>
        </SurveyModelWrapper>
    </ModalSection>
}


const DashboardHead = styled('div')({
    width: '100%',
    margin: '40px 0px 15px 12px',
    fontSize: '2rem',
    marginLeft: '0px',
    fontWeight: 600,
    '@media screen and (max-width: 768px)': {
        margin: '20px 0px 10px 12px',
        fontSize: '1rem',
    }
})

const BarChartBox = styled('div')({
    background: "#ffffff",
    padding: "30px 15px",
    borderRadius: "15px",
    marginBottom: "20px",
    '& .apexcharts-legend.apexcharts-align-right .apexcharts-legend-series': {
        display: 'flex'
    }
})

const DashboardWraper = styled('div')({
    padding: "0 10px 0 10px",
    '@media screen and (max-width: 985px)': {
        padding: "0 5px 0 5px"
    },

})

const DashboardBox = styled('div')({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: 'wrap'
})

const DashboardChartWraper = styled('div')({
    position: 'relative',
    width: '48.5%',
    flexBasis: '48.5%',
    background: "#ffffff",
    borderRadius: "15px",
    marginBottom: "20px",
    padding: "30px 5px",
    '@media screen and (max-width: 850px)': {
        flexBasis: '40%',
    },
    '@media screen and (max-width: 985px)': {
        flexBasis: '100%',
    },
})
const TR = styled(TableCell)({
    color: "#000",
    lineHeight: "1.5rem",
    minWidth: "100px",
    fontSize: '0.9rem',
    padding: '16px 10px',
    textAlign: 'center',
    position: 'relative',
    borderRight: '1px solid gray',
    '&:last-child': {
        borderRight: 'none',
    }
})

const DataPointBtn = styled(Button)({
    background: '#ffffff',
    zIndex: 1,
    border: '1px solid #cacaca',
    color: '#858585',
    position: 'absolute',
    right: '15px',
    '& .MuiButton-label': {
        height: '20px !important',
        display: 'block'
    }, '@media screen and (max-width:768px)': {
        right: '5px !important'
    },
})

const EditIconText = styled('div')({
    display: 'flex',
    textTransform: 'initial',
    alignItems: 'center'
})

const ModalSection = styled('div')({
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%,-50%)`,
    position: `absolute`
})

const DivSection = styled('div')({
    cursor: "pointer"
})

const SaveBtn = styled(Button)({
    background: '#009B07',
    color: '#ffffff',
    width: '150px',
    height: '40px',
    margin: '20px auto 0',
    border: '1px solid #009B07',
    display: 'flex',
    '&:hover': {
        color: '#009B07',
        border: '1px solid #009B07'
    }
});

const SectionItem = styled(Box)({
    marginBottom: '10px',
    '& .MuiTypography-root.MuiTypography-subtitle1': {
        fontWeight: 'bold',
        fontSize: 11,
    },
    '& .MuiTypography-root.MuiTypography-subtitle2': {
        fontWeight: 'normal',
        color: 'grey',
        fontSize: 14,
        // paddingTop: '10px',
    }
});

const SurveyModelWrapper = styled(Box)({
    background: 'white',
    borderRadius: "20px",
    padding: "35px",
    '@media screen and (max-width:768px)': {
        width: '550px'
    },
    '@media screen and (max-width:600px)': {
        width: '420px'
    },
    '@media screen and (max-width:468px)': {
        width: '320px'
    },
    '@media screen and (max-width:370px)': {
        width: '245px'
    }
})

const SurveyModelSection = styled(Box)({
    backgroundColor: "white",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    maxHeight: '80vh',
});

const BtnImg = styled('img')({
    height: "40px",
    width: "40px",
    '@media screen and (max-width:768px)': {
        height: "30px",
        width: "30px",
    },
});

const TitleSection = styled('div')({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: '#303539',
});

const MInputBase = styled(TextField)({
    '& .MuiInput-underline::before': {
        width: '0'
    },
    '& > div > input': {
        cursor: 'pointer',
        '&::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0
        },
        '&[type=number]': {
            MozAppearance: 'textfield'
        }
    }
})
// Customizable Area End
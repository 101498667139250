import React from "react";

// Customizable Area Start
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  TextInput,
  Image,
  ScrollView,
  TouchableWithoutFeedback,
  Platform
} from "react-native";
import CircularProgress from '@material-ui/core/CircularProgress';
import { CreateCSSProperties, styled } from '@material-ui/styles';
import {Box, Grid,Typography,IconButton,Button,TextField,Divider} from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import Modal from '@material-ui/core/Modal';
import { useField, Formik, Form, useFormikContext } from 'formik'
// import { createStyles, withStyles,makeStyles} from "@material-ui/core/styles";
import {image_Bitmap,googleLogin,microsoftLogin,linkedInLoginLogo, CorpLogo} from "../src/assets";
import DraftsOutlinedIcon from '@material-ui/icons/DraftsOutlined';
import HttpsOutlinedIcon from '@material-ui/icons/HttpsOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
//@ts-ignore
import UserContext from "../../../components/src/UserProvider";
import ForgotPassword from "../../forgot-password/src/ForgotPasswordWeb";
import { GoogleLogin } from 'react-google-login';
import MicrosoftLogin from "react-microsoft-login";
import { toast } from 'react-toastify'
import { LinkedIn } from '../../../components/src/LinkedinLogin.web'
import OverlayLoader from '../../../components/src/Loader.web'
import { configJSON } from './EmailAccountLoginController'
import { FormikSubmit } from "../../../components/src/FormModal.web";
// Customizable Area End

import EmailAccountLoginController, {
  Props
} from "./EmailAccountLoginController";

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
     //@ts-ignore
     const {navigation}= this.props
    // Customizable Area End
    return (
       // Customizable Area Start
      // Required for all blocks
      <div>        
            {/* {this.isPlatformWeb() ? (
              <Text style={styles.labelTitle}>{this.labelTitle}</Text>
            ) : null}

            <Text style={styles.titleWhySignUp}>{this.state.labelHeader}</Text> */}

        <Grid container spacing={0} >
      <Grid item md={6} lg={6}  >
       <Hidden smDown>
       <Box style={{backgroundColor:"white", height:"100%",display:'flex',flexDirection:'column', alignItems:"center",justifyContent:"center", position:"relative",}}>
               <img src ={image_Bitmap} width="500px" height="400px" style={{borderRadius:"10px",}}/>
                       
           </Box>
        </Hidden>
      </Grid>
      
      <Grid item xs={12} sm={12} md={6} lg={6} >
         <Box style={{padding:"20px 40px 30px 40px",}}>
         <Grid container style={{ display: 'flex', justifyContent: 'center' }}>
              <Grid item xs={12}>
               <Box style={{display:'flex',flexDirection:'column', borderRadius:"10px",padding:"20px",alignItems:"center",justifyContent:"center", position:"relative",}}>
                   <img src ={CorpLogo} width="150px" height="50px" style={{borderRadius:"10px",}}/>     
               </Box>  
              </Grid>
              <Grid item xs={12} style={{marginTop: '40px'}}>
              <Typography variant="h5" gutterBottom style={{fontWeight:"bold",textAlign:"center"}} component="div">
                Login
               </Typography>
                       
               <Typography variant="subtitle2" gutterBottom style={{fontWeight:"normal",color:"grey",textAlign:"center"}} component="div">
                Login to your account to continue
               </Typography>
                       
              </Grid>
              <Formik 
                validationSchema={this.loginFormSchema}
                initialValues={{email: '', password: ''}}
                // @ts-ignore 
                onSubmit={this.emailLogIn}>
                <Form 
                  noValidate
                  translate="no"
                  // @ts-ignore
                  style={{width: '100%',marginBottom:'10px', maxWidth: '520px'}}
                  
                  >
              <Grid item xs={12}>
             <Box>
             <Typography variant="subtitle1" gutterBottom style={{fontWeight:"bold",color:"#4f4e4e",}} component="div">
                Email<sup>*</sup>
               </Typography>
               <FormikTextField
                name="email" 
                type="email" 
                placeholder="Enter Email Address" 
                InputProps={{disableUnderline:true,
                   startAdornment: (
                       <DraftsOutlinedIcon style={{color:"#c9c9c9", margin:"0 10px"}}/>
                 )
               }}
               style={{ color:"grey",fontSize:14,
               width:"95%",
               margin:"10px 0px",
               borderRadius:"5px",
               border:"1px solid #edebeb",
               height:"45px",
               textAlign:"center",
               justifyContent:"center",
              //  padding:"0 10px",
              
              
              
              
               }}  />


             </Box>

              </Grid>
              <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom style={{fontWeight:"bold", color:"#4f4e4e",marginTop:"10px"}} component="div">
                Password<sup>*</sup>
               </Typography>
               <FormikTextField 
                name="password"
                placeholder="Enter Password"
                // onCopy={this.handleChangeCopy}
                // onPaste={this.handleChangeCopy}
                // onCut={this.handleChangeCopy}
                type={this.state.showPassword? 'text' : 'password'}
                InputProps={{disableUnderline:true,
                   startAdornment: (
                       <HttpsOutlinedIcon style={{color:"#c9c9c9",margin:"0 10px"}}/>
                 ),
                 endAdornment: (
                   <IconButton
                   aria-label="toggle password visibility"
                   onClick={this.handleClickShowPassword}
                  //  onMouseDown={this.handleMouseDownPassword}
                   edge="end"
               >
                   {this.state.showPassword  ? <VisibilityOffOutlinedIcon  style={{color:"#c9c9c9" , marginRight:'10px'}}/> : <VisibilityOutlinedIcon style={{color:"#c9c9c9",marginRight:'10px'}}/>}
               </IconButton>
                    
             )
               }}
               style={{ 
              
                color:"grey",fontSize:14, width:"95%",
               margin:"10px 0px",
               borderRadius:"5px",
               border:"1px solid #edebeb",
               height:"45px",
               textAlign:"center",
               justifyContent:"center",
               padding:"0 10px",
               opacity:0.5
             
           }} 
                />

              </Grid>
              <Grid item xs={12}>
                <Typography color="error">
                  {this.state.loginAPIError}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                  <Box style={{display:"flex",alignItems:"flex-end",justifyContent:"flex-end"}}>
                  <Typography variant="subtitle1" gutterBottom style={{fontWeight:"normal",color:"#1ea60a",margin:"10px 10px", cursor:"pointer"}} component="div" 
                  onClick={()=>{navigation.navigate("ForgotPasswordWeb")}} 
                  >
               Forgot Password?
               </Typography>

                  </Box>

              </Grid>
              <Grid item xs={12}>
                <FormikSubmit component={FormButton} props={{}}>
                    {this.state.isLoading? 'Log in':'Log in'}
                </FormikSubmit>
              </Grid>
            </Form>
            </Formik>
              <Grid item xs={12}>
                  <Box style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                  <Typography 
                  variant="subtitle2" gutterBottom 
                  style={{
                      fontWeight:"normal",
                      color:"grey",
                      margin:"0px 3px"}} 
                      component="div" >
               Not a member? 
               </Typography>
               
               <Typography variant="subtitle2" gutterBottom style={{ fontFamily: 'HelveticaNeue',fontWeight:"bold",color:"grey",margin:"0px 3px",cursor:"pointer"}} component="div"
                onClick={()=>{navigation.navigate("EmailAccountRegistrationWeb")}} 
                >
                Sign Up
               </Typography>
                  </Box>

              </Grid>
              <Grid item xs={12}>
                  <Box style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                  <Typography variant="subtitle2" gutterBottom style={{fontFamily: 'HelveticaNeue', fontWeight:"bold",color:"grey",marginTop:"30px"}} component="div" >
               Or Log in with
               </Typography>

                  </Box>

              </Grid>
              <Grid item xs={12}>
                  <Box style={{
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"center", 
                    
                  }}>
                    <GoogleLogin
                      clientId={configJSON.googleClientId}
                      render={renderProps => (
                        <div  style={{
                          padding:"5px",
                          margin:"5px", 
                          cursor: 'pointer', 
                          position: 'relative', 
                          opacity: this.state.socialLoading==="google"?"0.6":"1"
                          }} 
                          onClick={() => {
                            if(this.state.socialLoading) return;
                            this.setState({ socialLoading: 'google' })
                            renderProps.onClick()
                          }}>
                          {this.state.socialLoading === "google"}
                          <img src ={googleLogin} width="40px" height="40px"/>
                        </div>
                      )}
                      buttonText="Login"
                      onSuccess={this.handleGoogleLogin}
                      onFailure={()=>this.setState({ socialLoading: "" })}
                      cookiePolicy={'single_host_origin'}
                    />
                  <MicrosoftLogin
                    useLocalStorageCache
                    withUserData
                    graphScopes={["user.read", "email"]}
                    clientId={configJSON.microsoftClientID} 
                    authCallback={this.handleMicrosoftLogin}>
                      <div style={{ padding:"5px", margin:"5px", cursor: 'pointer' }}>
                        <img src ={microsoftLogin} width="40px" height="40px"/>
                      </div>
                  </MicrosoftLogin>
                  <LinkedIn
                    clientId={configJSON.linkedinClientId}
                    redirectUri={`${window.location.origin}/LinkedinRedirect`}
                    onSuccess={(code: any) => {
                       console.log(code);
                       this.handleLinkedinLogin(code)
                    }}
                    onError={(error: any) => {
                      console.log(error);
                      //toast.warn("Invalid Login Details, Please try again.")
                      this.setState({ socialLoading: "" })
                    }}
                  >
                    {({ linkedInLogin }) => (
                      <div onClick={() => {if(this.state.socialLoading) return;this.setState({ socialLoading: 'linkedin' }); linkedInLogin(); }}   style={{padding:"5px",margin:"5px", position: 'relative', cursor: 'pointer'}}>
                        {this.state.socialLoading === "linkedin"}
                        <img src ={linkedInLoginLogo} width="40px" height="40px" alt="Sign in with Linked In" />
                      </div>  
                    )}
                  </LinkedIn>
                   
                  </Box>
              </Grid>
          </Grid>
         </Box>
      </Grid>
        </Grid>
        <Modal
        style={{display:"flex", justifyContent:"center", alignItems:"center"}}
        open={this.state.open}
        onClose={this.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={{backgroundColor:"white",padding:"10px 20px 20px 20px", width:"50%",display:"flex",flexDirection:"column", }}>
      <h3 id="simple-modal-title">Error Message</h3>
      <Divider/>
      <p id="simple-modal-description" >
       {this.state.modalContent}
      </p>
      <Divider/>
      <Button variant="contained" color="primary" onClick={this.handleClose} style={{width:"50px",margin:"10px 0px 0px 0px",}} >
          Ok
      </Button>
    </div>
      </Modal>
      <OverlayLoader loading={this.state.isLoading || (!!this.state.socialLoading)} />
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
EmailAccountLoginController.contextType = UserContext
const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignSelf: "center",
    display:"flex",
    width: "100%",
    

  },
  view:{
    display:"flex",
    
    
  }
  
});
const ImgSection = styled("div")( params =>{
  console.log(params,"params")
  return {
    display:"none",
    
  }
})

const FormikTextField = (props: any) => {
  const [field, meta, helpers] = useField(props.name)
  const onChange = (e: any)=>{
    helpers.setValue(e.target.value)
    e.stopPropagation()
    e.preventDefault()
  }
  return <>
  
          <TextField
            error={!!(meta.touched && meta.error)}
           {...field}
           {...props}
           onKeyDown={(e: any)=>{
            console.log({e})
            // e?.stopPropagation?.()
            // e.preventDefault()
          }} 
          onChange={onChange}
           style={{ 
             color:"grey",
             fontSize:14, 
             width:"100%",
             marginBottom:"5px",
             borderRadius:"5px",
             border: `1px solid ${!!(meta.touched && meta.error)?'#F44336':'#edebeb'}`,
             height: "45px",
             textAlign: "center",
             justifyContent: "center",
            //  padding: "0 10px",
            
             

           }} 
         
          />
          <Typography color="error">
            {(meta.touched && meta.error) ? meta.error : ' ' }
          </Typography>
        </>
}


export const LoadingOver = () => {
  return <div style={{ 
    position: 'absolute', 
    width: '100%', 
    height: '100%', 
    backgroundColor: 'rgba(0,0,0,0.2)', 
    top: 0,
    left: 0,
    right: 0,
    borderRadius: '3px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
    }}>
    <CircularProgress />
  </div>
}

const BaseButton = styled(Button)({
  color: '#FFFFFF !important',
  padding: '5px 25px',
  width: "100%",
  height:"50px", 
  margin:"10px 0px"
 })
 
 const GreenButton = styled(BaseButton)({
  background: '#1ea60a',
  textTransform:'capitalize',
  '&:hover': {
    opacity: 0.9,
    background: '#009b21',
  },
 })
 
 const CardButton = styled(BaseButton)({
  textTransform:'capitalize',
  background: '#d0d0d0',
   '&:hover': {
    opacity: 0.9,
    background: '#d0d0d0',
   },
 })

 const FormButton = (props: any) => {
   const Btn = props.disabled?CardButton:GreenButton
   return <Btn {...(props||{})}>{props.children}</Btn>
 }
// Customizable Area End

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as yup from 'yup'
import { toast } from 'react-toastify'
import { sendAPIRequest } from '../../../components/src/utils'

import { getStorageData } from "../../../framework/src/Utilities";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  showModal: boolean;
  postId: string;
  postType: string;

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ReportController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  reportCallId: string = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReportModalOpenMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: '',
      showModal: false,
      postId: '',
      postType: ''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      
      if (responseJson && responseJson.errors) {
        // this.setState({ refresh: false });
        this.parseApiErrorResponse(responseJson.errors);
        this.parseApiCatchErrorResponse(responseJson.errors);
      } else if (this.reportCallId === apiRequestCallId){
        this.handleClose()
        toast.success("Report raised.")
      }
      // else if (apiRequestCallId === this.apiEventListCallId) {
        // if(Array.isArray(responseJson?.data)) {
          // const events = responseJson.data.map((e:any) => e.attributes)
          // this.setState({ events, loadingPosts: false })
        // }
      // } 
    } else if(getName(MessageEnum.ReportModalOpenMessage) === message.id) {
      const data = message.getData(
        getName(MessageEnum.ReportModalData)
      )
      this.setState({ showModal: true, ...data })
    }
    // Customizable Area End

  }

  

  // Customizable Area Start
  handleClose = () => {
    this.setState({ showModal: false, postId: '', postType: '' })
  }

  handleSubmit = async(data: any) => {
    let url: string = configJSON.reportPostEndpoint
    if (this.state.postType === 'BxBlockCfcourselisting5::Course') {
      url = configJSON.reportCourseEndpoint
    } if (this.state.postType === 'BxBlockJobs::Job') {
      url = configJSON.reportJobEndpoint
    } if (this.state.postType === 'BxBlockEvents::Event') {
      url = configJSON.reportEventEndpoint
    } if (this.state.postType === 'BxBlockCfproductlisting11::Product') {
      url = configJSON.reportProductEndpoint
    } if (this.state.postType === 'BxBlockContentManagement::MarketplaceService') {
      url = configJSON.reportServicesEndpoint
    }
    const token = await getStorageData('authToken')
    const body: any = {
      id: this.state.postId,
      reason: data.reason
    }
    const callId = sendAPIRequest(url, {
      method: 'POST',
      body,
      headers: {token, 'Content-Type': 'application/json'}
    })
    this.reportCallId = callId
    
    // this.handleClose()
  }
  // Customizable Area End
  
}

// Customizable Area Start
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const Coin_logo = require("../assets/ic_logohex.png");
export const Post_logo = require("../assets/ic_rect.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const logout_arrow = require("../assets/logout_arrow.png")
export const terms_cond = require("../assets/terms_cond.png")
export const address=require("../assets/address.png")
export const Contact=require("../assets/contact_helpsupport.png")
export const inbox=require("../assets/inbox_help_support.png")
export const phone=require("../assets/phone_help_support.png")
// Customizable Area End





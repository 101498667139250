import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as yup from 'yup'

import { sendAPIRequest } from '../../../components/src/utils'
import { getStorageData } from '../../../framework/src/Utilities';

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    txtInputValue: string;
    txtSavedValue: string;
    enableField: boolean;
    // Customizable Area Start
    token: string;
    projectPromoteData?: any;
    selectedSDGFactor?:any;
    submittingForm?:any;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class EnquiryProjectSeekingFundController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    apiServicesFilterCallId: string = ""
    getProjectPromoteCallId: string = '';
    apiEnquiriesCallId:string="";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            // Customizable Area Start
            token: '',
            projectPromoteData:{},
            selectedSDGFactor:[],
            submittingForm:false
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End

    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        const authToken = await getStorageData('authToken');
        const id = this.props.navigation.getParam('id')
        
        if (authToken == null) {
            this.props.navigation.navigate('Home')
        }
        this.setState({token: authToken}, () => {
          if (id) {
            this.getProjectPromoteCallId = sendAPIRequest(`${configJSON.ProjectPromoteEndpoint}/${id}`, {
              method: 'GET',
              headers: { 'token': authToken }
            })
          }
        })
      }
    // Customizable Area End
    async receive(from: string, message: Message) {

        runEngine.debugLog("Message Recived", message);


        // Customizable Area Start
        this.getResponse(message);
    
        // Customizable Area End
    }

    // Customizable Area Start
    getResponse(message: any) {
        if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
          return;
        }
      
        const apiRequestId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
      
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
      
        switch (apiRequestId) {
        
          case this.getProjectPromoteCallId:
            if (responseJson.data) {
              this.setState({
                projectPromoteData: responseJson.data,
              });
            }
            break;
            case this.apiEnquiriesCallId:
                 this.hideModal()
                break;
          default:
            break;
        }
      }



    hideModal = () => { this.props.navigation.goBack(); }

 
    validationSchema = yup.object().shape({
      request_description: yup.string().label('Request Description').max(100)
    })

    handleCreateTemplateSubmit = (data: any) => {
      const id = this.props.navigation.getParam('id')
        let { project_name = "", request_description = '', project_unique_id = '',
         project_leader = '',  project_link = '',company_title= '', business_email = '',
    } = { ...data }
    const body = new FormData()
    body.append('data[project_name]', project_name)
    body.append('data[promote_content_id]', id)
    body.append('data[request_description]', request_description)
    body.append('data[business_email]', business_email)
    body.append('data[project_link]', project_link)
    // body.append('data[project_leader]', project_leader)
    body.append('data[company_name]', company_title)
    this.state?.projectPromoteData?.attributes?.sdg_focus_ids.forEach((file: any) => body.append("data[sdg_focus_ids][]", file));
  
  
  
    for (const value of body.entries()) {
      console.log("value", value);
    }
  
    const headers = {
      "token": this.state.token,
    }
    let url = configJSON.projectPromoteEnquiryEndpoint;
  
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
   
      this.apiEnquiriesCallId=requestMessage.messageId;
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      url
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );
  
    this.setState({ submittingForm: true })
    runEngine.sendMessage(requestMessage.id, requestMessage);
    }


    // Customizable Area End

}


import React from "react";
import { Select, SelectProps, CircularProgress } from "@material-ui/core";
import { CreateCSSProperties, styled } from "@material-ui/styles";

import Flex from "./Flex.web";
const iconArrowDown = require("./ic_arrow_down.png");

interface SelectInputProps extends SelectProps {
  loading?: boolean;
  onSelectInput?:any;
}

export const EndAdornment = (props: any) => {
  return (
    <Flex gap="10px" justify="center" align="center" style={{position: 'absolute', right: 0, pointerEvents: "none", ...(props.style||{})}} >
      {props.loading && <CircularProgress size={20} />}
      <img src={iconArrowDown} width="15px" style={{ padding: "10px" }} />
    </Flex>
  );
};

export default function SelectInput({ children, ...props }: SelectInputProps) {
  const handleSelect=(event:any)=>{  props?.onSelectInput && props.onSelectInput(event)  }
  return (
    <SelectBase
      style={{ width: "100%" }}
      variant="outlined"
      IconComponent={()=><EndAdornment {...props} />}
      onClick={(e:any)=>handleSelect(e.target.value)}
      {...props}
    >
      {children}
    </SelectBase>
  );
}

const SelectBase = styled(Select)({
  "& > div": {
    padding: "13px 10px",
  },
  "& > div > p": {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: '2ch',
  },
});

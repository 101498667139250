Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start
const config = require("../../../framework/src/config");
exports.errorEmailNotValid = "Email not valid.";
exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Log in";
exports.passwordError="Pasword Must contains alteast 1 upper case ,1 lower case,1 number , 1 special character"

exports.linkedinClientId = "86481vg8gj7y6s"
exports.microsoftClientID = "3d750902-2249-4c60-8013-41d85ebd49bf"

exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";
exports.loginAPiEndPointWeb="bx_block_login/logins";
exports.LinkedinLoginAPiEndPointWeb="bx_block_login/linkedin_login";
exports.googleClientId = config.googleCred.GOOGLE_CLIENT_ID;
// Customizable Area End
Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.getSubscriptionAPiEndPoint =
  "customisable_user_subscriptions/subscriptions";
exports.getSubscriptionAPiMethod = "GET";
exports.subscriptionApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "customisableusersubscriptions";
exports.labelBodyText = "customisableusersubscriptions Body";
exports.btnExampleTitle = "CLICK ME";
exports.subscriptions = "Subscriptions";
exports.chooseSubscription = "Choose from the list of subscriptions";
exports.subscriptionDetailsScreenName = "SubscriptionDetails";
exports.goldCoinImageUrl =
  "https://akm-img-a-in.tosshub.com/sites/btmt/images/stories/goldcoin_660_102616075839.jpg";
exports.moreLicense = "/bx_block_custom_user_subs/more_licence"
exports.reactiveLicense = "/bx_block_custom_user_subs/user_subscriptions/reactive"
exports.currencySymbol = "₹";
exports.zero = "0";
exports.subscribed = "Subscribed";
exports.subscribe = "Subscribe";
exports.subscriptionDetails = "Subscription Details";
exports.confirmSubscription = "Confirm Subscription";
exports.noSubscriptionAvailable = "No subscriptions available";
exports.getAllSubscribtionPlanUrl = 'bx_block_custom_user_subs/subscriptions';
exports.getAllSubscribtionHistoryUrl = 'bx_block_custom_user_subs/user_subscriptions';
exports.reactivateSubscribtionUrl = 'bx_block_custom_user_subs/user_subscriptions/reactive';
exports.postSubscribtionPlanRequestUrl = 'bx_block_custom_user_subs/subscription_reqs';
exports.postpaymentDetailtUrl = 'bx_block_custom_user_subs/user_subscriptions';
exports.getshowsubscribtionRequest = 'bx_block_custom_user_subs/subscription_reqs/';
exports.paymentSubscribtionPlanUrl = 'bx_block_custom_user_subs/checkout';
exports.payNowUrl = 'bx_block_custom_user_subs/checkout';
exports.contentType = 'application/json';
exports.getApiMethodType = "GET";
exports.getApiHistoryMethodType = "GET";
exports.requestSubscribtionPlan = "POST";
exports.paymentpostSubscribtionPlan = "POST";
exports.defaultPaymentUrl = '/Customisableusersubscriptions';
exports.afterPaymemtSuccessRoutes = [{ success: 'coins_success', url: '/CorpsStageCoins' }]

// Customizable Area End

// Customizable Area Start
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const image_Bitmap = require("../assets/image_Bitmap.png");
export const image_Bradlogo = require("../assets/image_Bradlogo.png");
export const image_preference = require("../assets/Preferences.png");
export const GreenTickIcon = require("../assets/green_tick.png")
export const CorpLogo = require("../assets/corpstageIconlogo.png")
// export const {register} = require("../src/api")
// Customizable Area End


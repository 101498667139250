import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { esgPathRecords, sendAPIRequest } from '../../../components/src/utils';
import { toast } from 'react-toastify';
import * as yup from 'yup'
import moment from "moment";
import { getStorageData } from "../../../framework/src/Utilities";
interface ESGCardsAttributes {
  user_name: string | null;
  updated_at: string;
  id: number;
  created_at: string;
  email: string;
  company_name: string | null;
  profile_picture: string | null;
  ability_card: string;
  ability_type: string;
  card_serial_number: number;
  card_banner: string;
}

interface ResESGRecord {
  attributes: ESGCardsAttributes;
  id: string;
  type: string;
}

interface ESGCards {
  access: {
    data: ResESGRecord[];
  };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  token: string;
  // Customizable Area Start
  user: any,
  emailPopup: boolean;
  assesments: any[];
  allQuestion: any;
  deleteTemplteId: any;
  deleteQuestion: boolean;
  deleteId: number;
  check: boolean;
  emailData: any;
  subject: string;
  content: string;
  emailErrorMsg: string;
  sendTo: any[];
  newEmail: string;
  modal: string;
  anchorElForPopup: HTMLButtonElement | null;
  anchorElForPopupNested: HTMLButtonElement | null;
  anchorElForPopupDate: HTMLButtonElement | null;
  startDate: Date | null,
  endDate: Date | null,
  editData: any,
  userRole: any,
  editId: any,
  loading: boolean,
  filterName: string,
  isdisabled: boolean,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SurveysController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getServeyDetailCallId = '';
  getAllQuestionId = '';
  questionDeleteCallId = '';
  templateDeleteCallId = '';
  sendEmailCallId = '';
  downloadReportCallId = '';
  filterCallId = '';
  showdataCallId = '';
  userRoleCallId= '';
  editAssessmentCallId= '';
  esgCardAccessApiCallId: string = '';

  async componentDidMount() {
    super.componentDidMount();
    console.log("component mounted")
    const authToken = await getStorageData("authToken");
    const userData = await getStorageData("user");
    const user = JSON.parse(userData)
    let isLicensedUser = user?.role?.role_level < 5 || user.user_type === "licensed_user"    
    if(isLicensedUser){
      this.esgAccessApi(authToken)
    }
    if (authToken == null) {
      this.props.navigation.navigate("Home");
    } else {
      this.setState({user: userData}, () => {
        const access = this.props?.navigation?.history?.location?.state?.ability_type
        console.log("access rights =", access);
        if (access == "read") {
          this.setState({ isdisabled: true })
        }
          this.getUserRole();
      });
    }

  }
  getUserRole = async () => {
    const token = await getStorageData('authToken') || ''
    const url = configJSON.getUserRoleEndpoint
    this.userRoleCallId =  sendAPIRequest(url , { method: 'GET', headers: {token} })
  }

  onInitialized = async () => {
    const token = await getStorageData('authToken') || ''
    const { userRole } = this.state
    this.setState({filterName:"All"})
    console.log("check local ", userRole);
    if(userRole == "Super Admin"){
    this.setState({loading:true})
     this.getServeyDetailCallId = sendAPIRequest(configJSON.getAdminAssesment, {
      method: 'GET',
      headers: { token }
    })
    }
    else{
    this.setState({loading:true})
    this.getServeyDetailCallId = sendAPIRequest(configJSON.getAllAssesments, {
      method: 'GET',
      headers: { token }
    })
    }
  }

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.log("constructor")

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      token: '',
      // Customizable Area Start
      user: '',
      emailPopup: false,
      assesments: [],
      allQuestion: [],
      deleteTemplteId: '',
      deleteQuestion : false,
      deleteId: 0,
      check: true,
      emailData: {},
      subject: 'Share your valuable thoughts with us',
      content: ``,
      emailErrorMsg: "",
      sendTo: [],
      newEmail: '',
      modal: '',
      anchorElForPopup: null,
      anchorElForPopupNested: null,
      anchorElForPopupDate: null,
      startDate: null,
      endDate: null,
      editData: {},
      userRole: "",
      editId: '',
      loading: false,
      filterName: '',
      isdisabled: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && responseJson.errors) {
        this.parseApiErrorResponse(responseJson.errors);
        this.parseApiCatchErrorResponse(responseJson.errors);
      } else if (this.esgCardAccessApiCallId == apiRequestCallId){
        this.handleEsgPageAuthorization(responseJson)
      }
      else if(this.getServeyDetailCallId == apiRequestCallId){
        console.log("response is ", responseJson)
        const assesments = responseJson.responses
        this.setState({assesments, loading:false});
      } else if(this.filterCallId == apiRequestCallId){
        console.log("filtered data ", responseJson)
        this.setState({startDate: null, endDate:null})
        this.setState({assesments: responseJson.responses, 
            anchorElForPopupNested: null, 
            anchorElForPopup: null, 
            loading:false, anchorElForPopupDate: null})
      } else if(this.showdataCallId == apiRequestCallId) {
        console.log("show api res ", responseJson);
        this.setState({editData: responseJson})
      } else if (apiRequestCallId === this.userRoleCallId) {
          this.setState({ 
              userRole: responseJson.user_role || ''
            })
            this.onInitialized()
            console.log("user role =>", this.state?.userRole)
      } else if(this.editAssessmentCallId == apiRequestCallId) {
        console.log("response edit ", responseJson)
        this.state?.assesments.map((data:any, i:number) => {
            if(responseJson.response_id == data.response_id){
                data.verify_to = responseJson.verify_to
                data.comments = responseJson.comments
                data.status = responseJson.status
                data.approved_by = responseJson.approved_by
                data.verified_by = responseJson.verified_by
            }
        })
        this.setState({modal:''})
        toast.success("Updated Successfully")
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  validationSchema = yup.object().shape({
    survey_name: yup.string().required("Survey Name is required"),
    assesment_date: yup.string().required("Assesment Date is required"),
    score: yup.number().required("Score is required"),
    assessment_by: yup.string().required("Assesment By is required"),
    approved_by: yup.string().max(160).required("Approved By is required"),
    // verified_by: yup.string().max(160).required("Verified By is required"),
    verify_to: yup.number().nullable(),
    verified_by: yup
         .string()
         .when('verify_to', {
             is: 1,
             then: yup.string().required('Verified By is required')
         }),
    comments: yup.string().required("Comment is Required")
  })


  handleEditTemplate = (id:any) => () =>{
    const url = `${this.props.navigation.history.location.pathname}/EditSurveys/${id}`
    this.props.navigation.history.push(url)
  }

  handleSearch = async (e:any) => {
    const token= await getStorageData('authToken') || ''
    if(this.state?.userRole == "Super Admin"){
          setTimeout(()=> {
            this.setState({loading:true})
          },1000)
            this.filterCallId = sendAPIRequest(`${configJSON.getAdminAssesment}?search=${e?.target?.value}`, {
                method: 'GET',
                headers: { token }
              })
              setTimeout(()=> {

                this.setState({loading:false})
              },3000)
    }
    else{
    this.setState({loading:true})
    this.filterCallId = sendAPIRequest(`${configJSON.getAllAssesments}?search=${e?.target?.value}`, {
        method: 'GET',
        headers: { token }
      })
    }
  }

  handleFilterByAssesmentName = async (e:any) => {
    const token = await getStorageData('authToken') || ''
    this.setState({filterName:e})
    if(this.state?.userRole == "Super Admin"){
        this.setState({loading:true})
        this.filterCallId = sendAPIRequest(`${configJSON.getAdminAssesment}?search=${e}`, {
            method: 'GET',
            headers: { token }
          }) 
    }
    else{
    this.setState({loading:true})
    this.filterCallId = sendAPIRequest(`${configJSON.getAllAssesments}?search=${e}`, {
        method: 'GET',
        headers: { token }
      })
    }
  }

  openMenu = (e:any) => {
    console.log("event is", e)
    this.setState({...this.state, anchorElForPopup: e?.currentTarget})
  }

  openNestedMenu = (e:any) => {
    this.setState({...this.state, anchorElForPopupNested: e?.currentTarget})
  }

  openNestedDateMenu = (e:any) => {
    this.setState({...this.state, anchorElForPopupDate: e?.currentTarget})
  }

  handleStartDate = (date: Date | null) => {
    this.setState({startDate: date})
  }

  handleEndDate = (date: Date | null) => {
    this.setState({endDate: date})
  }

  filterDate = async() => {
    if(this.state.startDate != null && this.state.endDate != null){
      this.setState({filterName: `${moment(this.state.startDate).format('DD/MM/YYYY')} - ${moment(this.state.endDate).format('DD/MM/YYYY')}`})
        if(this.state?.userRole == "Super Admin"){
            this.setState({loading:true})
            const token= await getStorageData('authToken') || ''
            this.filterCallId = sendAPIRequest(`${configJSON.getAdminAssesment}?date_from=${this.state.startDate}&date_to=${this.state.endDate}`, {
            method: 'GET',
            headers: { token }
            })
        }
        else{
            this.setState({loading:true})
            const token= await getStorageData('authToken') || ''
            this.filterCallId = sendAPIRequest(`${configJSON.getAllAssesments}?date_from=${this.state.startDate}&date_to=${this.state.endDate}`, {
            method: 'GET',
            headers: { token }
            })

        }
    }
    else{
        toast.warn("Please fill both the date")
    }
  }

  handleSubmit = async (data:any) => {
    const body = new FormData();
    console.log("submt data", data)
    body.append('approved_by', data.approved_by)
    body.append('status_response',data.verify_to == 0 ? 0 : data.status)
    body.append('verified_by', data.verified_by)
    body.append('comments', data.comments)
    body.append('verify_to', data.verify_to)
    const token= await getStorageData('authToken')|| ''
    this.editAssessmentCallId = sendAPIRequest(configJSON.editApiendpoint.replace(':id', this.state.editId), {
        method: 'PUT',
        headers: {token},
        body
    })
  }

  handleEdit = async (id:any) => {
    if(this.state.isdisabled){
      toast.warn("You do not have permission to perform this action")
    }
    else{
      this.setState({modal:'create', editId:id})
      const token= await getStorageData('authToken') || ''
      this.showdataCallId = sendAPIRequest(`${configJSON.showAssesment}?response_id=${id}`, {
          method: 'GET',
          headers: { token }
        })
    }
  }

  onBack = () => {
    this.props.navigation.goBack();
  }

  closeDeleteModal = () => {
    this.setState({deleteTemplteId: undefined})
  }

  closeModal = () => {
    this.setState({deleteQuestion: false})
  }

  handleDeletePopup = (data:any) => () => {
    if(data?.status == "live"){
      toast.warn("Can not delete Live Template");
    }
    else{
      this.setState({deleteTemplteId: data?.id})
    }
  }

  allowOnlyCharacters = (event:any) => {
    let ASCIICode = event.charCode;
    if (
      (ASCIICode >= 65 && ASCIICode <= 90) ||
      (ASCIICode >= 97 && ASCIICode <= 122) ||
      ASCIICode === 32
    ) {
    } else {
      event.preventDefault();
    }
  };
  esgAccessApi = async (authToken:string) => {
    this.esgCardAccessApiCallId = sendAPIRequest(`${configJSON.showAllCards}`, {
      method: "GET",
      headers: {token: authToken},
    })
  }
  navigateFunction = (route: string, props: Props) => {
    const navigateMessageData = new Message(getName(MessageEnum.NavigationMessage));
    navigateMessageData.addData(getName(MessageEnum.NavigationTargetMessage), route);
    navigateMessageData.addData(getName(MessageEnum.NavigationPropsMessage), props);   
    this.send(navigateMessageData);
  }
  handleEsgPageAuthorization(esgData:ESGCards){
    let isAuthorised = esgPathRecords(esgData)
    if(!isAuthorised ){
      let redirectionPath = "ActivityFeed"
      let errMessage = "Sorry, You do not have access to this page"
      toast.warn(errMessage)
      this.navigateFunction(redirectionPath,this.props)   
    }
  }
  // Customizable Area End
  
}

import React from "react";
import RequestToCollaborateController, { Props } from './RequestToCollaborateController.web'
import {
  Box,
  Backdrop,
  Button,
  IconButton,
  Grid,
  Typography,
  Modal,
} from "@material-ui/core";
import { styled } from '@material-ui/styles';
import { Formik, Form } from 'formik';
import CloseIcon from "@material-ui/icons/Close";
import { InputRow, MInput } from "../../../components/src/FormModal.web";
import UserContext from '../../../components/src/UserProvider'

export default class RequestToCollaborate extends RequestToCollaborateController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Modal
        open={this.state.openModal}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
        BackdropComponent={Backdrop}
        style={{
          display: "flex",
          alignItems: "center", justifyContent: "center",
        }}
        onClose={() => {
          this.onClose()
        }}
        BackdropProps={{ timeout: 500, }}
      >
        <ModalWrapper className="schedule_modal">
          <Grid container
            spacing={0}
          >
            <ModalTitle xs={12} item >
              <Typography
                style={{
                  paddingTop: '14px', fontWeight: 'bold'
                }}>
                Request To Collaborate
              </Typography>
              <CloseIconButton id='clickOnCrossIcon' onClick={() => this.onClose()}>
                <CloseIcon style={{}} />
              </CloseIconButton>
            </ModalTitle>

            <Grid item xs={12} style={{
              display: 'flex', flexDirection: 'column',
              justifyContent: 'center', padding: '18px 36px',
              alignItems: 'center',
            }}>
              <Formik
                initialValues={{ description: '', title: '' }}
                onSubmit={(values) => this.requestCollaborator(values)}
                validationSchema={this.validationSchema}
              >
                {({
                  handleSubmit,
                  values,
                  handleChange
                }) => {
                  return (
                    <RightFormWrapper translate="no" onSubmit={handleSubmit} >
                      <Grid item xs={12} style={{ padding: '10px' }}>
                        <InputRow
                          label="Request Title*"
                          rowClass='formRow'
                          labelClass='formLabel'
                          input={
                            <>
                              <MInput
                                variant="outlined"
                                name="title"
                                fullWidth
                                value={values?.title}
                                onChange={handleChange}
                              />
                            </>} />
                        <InputRow
                          label="Description*"
                          rowClass='formRow'
                          labelClass='formLabel'
                          input={
                            <>
                              <MInput
                                variant="outlined"
                                name="description"
                                multiline
                                maxRows={4}
                                minRows={4}
                                fullWidth
                                value={values?.description}
                                onChange={handleChange}
                              />
                            </>} />
                        <div style={{ marginTop: '15px' }}>
                          <DialogCancelButton id='clickedOnCancel' onClick={() => this.onClose()}>
                            CANCEL
                          </DialogCancelButton>
                          <ShareNow type="submit">
                            Request to collaborate
                          </ShareNow>
                        </div>
                      </Grid>
                    </RightFormWrapper>
                  )
                }}
              </Formik>
            </Grid>
          </Grid>
        </ModalWrapper>
      </Modal>
      // Customizable Area End
    );
  }
}
RequestToCollaborateController.contextType = UserContext


const ModalWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  background: '#fff',
  borderRadius: 8,
  width: '670px',
  position: "relative",
  margin: '0 10px',
})

export const RightFormWrapper = styled(Form)({
  display: 'flex',
  flexDirection: 'column',
  gap: '25px',
  overflowX: 'hidden',
  width: '100%',
  paddingRight: '10px',
})

const ShareNow = styled(Button)({
  background: '#019B07', padding: '10px 18px 8px 18px',
  margin: '5px', color: '#FFF',
  '&:hover': {
    background: '#999',
    opacity: 0.9
  },
  '@media screen and (max-width:400px)': {
    width: '-moz-available',
  }
})

const ModalTitle = styled(Grid)({
  alignItem: 'center',
  flexDirection: 'row',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  borderBottom: '0.2px solid #cbc1c1',
  padding: '13px 25px 13px 31px'
})

const CloseIconButton = styled(IconButton)({
  background: '#676767',
  color: '#fcfcfc',
  width: '32px',
  height: '32px',
  '&:hover': {
    background: '#999',
    opacity: 0.9
  }
})

const DialogCancelButton = styled(Button)({
  color: '#FFF',
  background: '#D0D0D0',
  padding: '10px 18px 8px 18px',
  margin: '5px',
  '&:hover': {
    background: '#999',
    opacity: 0.9
  },
  '@media screen and (max-width:400px)': {
    width: '-moz-available',
  }
})

import React from 'react'
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { sendAPIRequest } from '../../../components/src/utils';
import { getStorageData } from '../../../framework/src/Utilities';
import { toast } from 'react-toastify'
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  surveyCards: any;
  cardsRow: any[];
  loading: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SurveyCardsController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getCardCallId:string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPropsMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      cardsRow: [],
      surveyCards: {},
      loading:true
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    let token = await getStorageData('authToken')
    if (token == null) {
      this.props.navigation.navigate('Home')
    }
   const userString = await getStorageData('user');
    const userJson = JSON.parse(userString);
    const { role: { role_level = 0 } = {}, user_type = '' } = userJson ?? {};
    const isAllowed = role_level >= 4 && user_type !== "licensed_user";
    if(!isAllowed){
      let warning = "Sorry, You do not have access to this page"
      let homeLink = "ActivityFeed"
      toast.warn(warning)
      this.getNavigationMessage(homeLink,this.props)    
    }
    this.getCardDatas()
    // Customizable Area End
  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && responseJson.errors) {
        this.parseApiErrorResponse(responseJson.errors);
        this.parseApiCatchErrorResponse(responseJson.errors);
        this.setState({loading: false})

      } else if(this.getCardCallId == apiRequestCallId){
        this.generateRows(responseJson);
      }
    }
    // Customizable Area End

  }

  getNavigationMessage = (route: string, props: any, payload?: any) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), route);

    message.addData(getName(MessageEnum.NavigationPropsMessage), props);

    this.send(message);
  }

 
  generateRows(surveyCards:any) {
    let rows :any = [];

    surveyCards?.data.forEach((card:any) => {
      if(card.attributes.name === 'Non Mandatory Assessment Survey'){
        rows.push(this.createCardDataMenu(card.attributes.name, 'Surveys', card.attributes.image, card.attributes.name, card.id));
      }else {
        rows.push(this.createCardDataMenu(card.attributes.name, 'ReportingFrameworks', card.attributes.image, card.attributes.name, card.id));
      }
    });
        
    this.setState({cardsRow: rows , loading: false})
  }
  createCardDataMenu(
    Name: string,
    File: string,
    img: string,
    type: string,
    cardNo: string,
  ) {
    return { Name, File, img, type, cardNo };
  }
  
  getCardDatas = async() => {

    let token = await getStorageData('authToken')
    
    this.getCardCallId = sendAPIRequest(configJSON.getCardsEndPoint, {
      method: 'GET',
      headers: {token : token},
    })
  }

}
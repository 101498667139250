import React from "react";
// Customizable Area Start
import TopNavBar from "../../../components/src/TopNavbar";
import {Box, Grid,Accordion,AccordionSummary,Typography,AccordionDetails,Button} from '@material-ui/core';
import LeftMenuWeb from "../../../components/src/LeftMenuWeb";
import RightMenuWeb from "../../../components/src/RightMenuWeb";

import DashboardController, { Props } from "./DashboardController";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp
} from "react-native-responsive-screen";
import { RFValue } from "react-native-responsive-fontsize";
import moment from "moment";
// Customizable Area End
export default class DashboardWeb extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
 
  // Customizable Area End
    
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
        <Box style={{backgroundColor:"#edebeb",display:"flex",flexDirection:"column",paddingBottom:"20px",overflowX:"hidden"}}>
        <Box>
          <TopNavBar navigation={this.props.navigation} id={this.state.token} />
        </Box>
        
         <Grid container spacing={5}>
             <Grid  item xs={12} sm={12} md={3} lg={3}>
                 <Box >
                     <LeftMenuWeb navigation={this.props.navigation} id={""}/>
                 </Box>
             </Grid>
             <Grid  item xs={12} sm={12} md={6} lg={6} >
             <Box >
               posts
             </Box>
             </Grid>
             <Grid  item xs={12} sm={12} md={3} lg={3} >
             <Box >
                    <RightMenuWeb navigation={this.props.navigation} id={""}/>
                 </Box>
             </Grid>
         </Grid>
            
    </Box>
    // Customizable Area End

    );
  }
}

// Customizable Area Start

// Customizable Area End

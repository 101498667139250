import React from "react"
// Customizable Area Start
import { styled } from '@material-ui/styles'
import { Button, Typography, Checkbox, TextField } from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';
// Customizable Area End
import ScoringController, { Props } from "./ScoringController";
export default class Scoring extends ScoringController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() { 
        // Customizable Area Start
        const { setError, scoringQuestionFn }: Props = this.props;
        const score_options = (this.state.score_options && this.state.score_options.length) ? this.state.score_options.filter(item => item.is_deleted !== true).length : 0;
        // Customizable Area End
        return (
            // Customizable Area Start
            <div>
                {this.state.score_options && this.state.score_options.map((option: { [key: string]: string | number | boolean }, index: number) => {                    
                    const checked =  Boolean(option.checked)
                    return (option.is_deleted !== true ? <WrapperDiv key={index} style={{ display: 'flex', flexDirection: 'row', width: '100%', marginBottom: '10px' }}>

                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                <Typography style={{ alignSelf: 'center', marginRight: '10px' }}> {this.getScoringOptionIndexLabel(index)}</Typography>
                                <Checkbox
                                    name={`score_options[${index}]`}
                                    style={{ color: "#1ea60a", fontSize: 10 }}
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                    onChange={(event) => this.changeChecked(index, event.target.checked)}
                                    defaultChecked={checked}
                                    checked={checked}
                                />
                                <MInputBase variant="outlined" name={`score_options[${index}].title`} onChange={(event) => this.handleChange(event.target.value, index, 'title', scoringQuestionFn)} placeholder="" style={{ marginRight: '10px' }} value={option.title} />
                                <Typography variant="caption" color="error">  </Typography>
                                <MInputBase data-test-id="inputBase" variant="outlined" name={`score_options[${index}].answer`}
                                    disabled={!checked}
                                    placeholder="" onChange={(event) => this.handleChange(event.target.value, index, 'answer', scoringQuestionFn)} value={option.answer} />
                            </div>
                            <Typography variant="caption" color="error" style={{ textAlign: 'end' }}> {this.state.erorrs?.[index]?.value} </Typography>
                            {<Typography style={{ color: 'red', fontSize: '0.8rem', marginLeft: '25px' }}>{setError?.[`score_title${index}` as keyof typeof setError] || ""}</Typography>}
                        </div>
 
                        <button
                            style={{ background: '#fff', border: "none" }}
                            disabled={score_options < 3}
                            type="button"
                            name="removeOp"
                            className="removeButton"
                            data-test-id="removeButton"
                            onClick={() => this.handleEdit(index, scoringQuestionFn)}
                        >
                            <CancelIcon style={{ color: '#eee', cursor: 'pointer', fontSize: '2.5rem' }} />
                        </button>
                    </WrapperDiv> : <></>)
                }
                )}
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                    <AddBtn className="add-more-button" data-test-id="add-more-options" name='addMoreOption' type="button" onClick={() => {
                        this.addOption(scoringQuestionFn);
                    }}>
                        <AddCircleIcon />
                        Add more options
                    </AddBtn>
                </div>
            </div>
            // Customizable Area End
        )

    }
}


// Customizable Area Start
const WrapperDiv = styled('div')({
    '& > div': {
        width: '100%',
    }
})

const AddBtn = styled(Button)({
    background: 'Green',
    color: '#fff',
    borderRadius: '20px',
    fontSize: '12px',
    width: '200px',
    fontWeight: 'bold',
    justifyContent: 'space-evenly',
    '&:hover': {
        background: 'Green',
    }
})

const MInputBase = styled(TextField)({
    '& > div > input': {
        padding: '13px 10px'
    },
    width: '44%'
})
// Customizable Area End
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as yup from 'yup'
import { toast } from 'react-toastify'

import { sendAPIRequest } from '../../../components/src/utils'
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";

// @ts-ignore
yup.addMethod(yup.mixed, "maxFileSizeSingle", function(error: string, maxSize: number) {
  return this.test('max-file-size-single', error, function(file: File|undefined){
    if(!file) return true
    if(typeof file === "string") return true
    if(file.size>maxSize) return false
    return true
  })
})
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  onClose: () => void;
  onCreate: (community: any) => void;
  open?: boolean;
  initialValues?: any; // initial values for edit form
  moderators?: any[]; // intial value of moderators for edit form, cached value is helpful
  members?: any[]; // intial value of members for edit form, cached value is helpful
  community_id?: string|number; // id of the community, only when editing
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  token: string;
  open:boolean;
  image:any;
  checked:boolean;
  searchText:string;
  selectedOption:any[];
  preferences:any[];
  selectedPreferences: string[];
  addmembers:any[];
  modal: string;
  communities: any[];
  allCategories: any[];
  allSubCategories: any[];
  loadingCreate: boolean;
  memberOptions: any[];
  moderatorOptions: any[];
  allUserOptions: any[];
  moderatorSearchCallId: string;
  memberSearchCallId: string;
  community_type: boolean;
  categoryOptions: any[];
  subCategoryOptions: any[];
  loadingCommunities: boolean;
  communityQuery?: string;
  communitiesPagination?: {
    next_page?: number;
    total_pages: number;
  };
  sampleExcelUrl?: string;
  importIds: number[];
  importLoading: boolean;
  importError?: string;
  memberPagination?: any;
  moderatorPagination?: any;
  moderatorQuery: any;
  memberQuery: any;
  memberPaginateCallId?: any;
  moderatorPaginateCallId?: any;
  userRole?: any;
  communityPaginateCallId?: any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CreateModalController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  listCommunitiesCallId: string = "";
  createCommunityCallId: string = "";
  userRoleCallId:string = "";
  initUsersCallId: string = "";
  initUsersList: any[] = []; // List of users for moderator/member dropdown initially
  initUsersPagination: any = undefined; // pagination for member moderator dropdown
  moderatorSearchCallId: string = "";
  memberSearchCallId: string = "";
  communityFilterCallId: string = "";
  allPreferences: any[] = [{id: 1, label: "E - Environmental"}, {id: 2, label: "S - Social"}, {id: 3, label: "G - Governance"}]
  preferenceIdToChar: any = {
    1: 'E',
    2: 'S',
    3: 'G'
  }
  getSampleExcelCallId: string = "";
  importCsvCallId: string = "";
  followCommunityCallId: string = "";
  joinCommunityCallId:{[key: string]: string} = {}
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const authToken = await getStorageData('authToken');
    if(authToken==null){
      this.props.navigation.navigate('Home')
    } else {
    this.setState({token: authToken}, () => {
      this.handleUserRole();
      this.loadCategoriesSubCategories()   
      this.loadInitialUsers()
    })}
    // Customizable Area End
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.GetAllCategoriesResponseMessage),
      getName(MessageEnum.GetAllSubCategoriesResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      open:false,
      image:null,
      checked:false,
      searchText:'',
      selectedOption:[],
      preferences:[],
      selectedPreferences: [],
      addmembers:[],
      token: '',
      modal: '',
      communities: [],
      allCategories: [],
      allSubCategories: [],
      loadingCommunities: false,
      loadingCreate: false,
      moderatorOptions: [],
      memberOptions: [],
      allUserOptions: [],
      moderatorSearchCallId: "",
      memberSearchCallId: "",
      community_type: false,
      categoryOptions: [],
      subCategoryOptions: [],
      importIds: [],
      importLoading: false,
      
      moderatorQuery: '',
      memberQuery: '',

      userRole: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    console.log({ moderatorOptions: this.state.moderatorOptions, props: this.props })
    this.onImageChange = this.onImageChange.bind(this);
    // Customizable Area End

  }

  async receive(from: string, message: Message) {
    
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    if(message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson.errors) {
        // this.setState({ refresh: false });
        this.parseApiErrorResponse(responseJson.errors);
        this.parseApiCatchErrorResponse(responseJson.errors);
      } else if (apiRequestCallId === this.listCommunitiesCallId || apiRequestCallId === this.communityFilterCallId) {
        let communities = []
        if(Array.isArray(responseJson?.communities?.data))
          communities = responseJson.communities.data
        else if (Array.isArray(responseJson?.data))
          communities = responseJson.data
        
        this.setState({
          communities,
          loadingCommunities: false,
          communitiesPagination: responseJson.meta?.pagination,
        })
      } else if(apiRequestCallId === this.state.communityPaginateCallId) {
        let communities = this.state.communities
        communities = [...communities, ...(responseJson?.communities?.data || responseJson?.data)]
        this.setState({
          communities,
          communitiesPagination: responseJson.meta?.pagination
        })
      } else if (apiRequestCallId === this.createCommunityCallId) {
        if(this.props.community_id) toast.success("Community updated successfully")
        else toast.success('Community created successfully')
        this.props.onCreate && this.props.onCreate(responseJson.data)
        this.clearAndClose()
        this.setState({ loadingCreate: false, modal: '', })
      }
      else if (apiRequestCallId === this.userRoleCallId) {
        // toast.success('Community created successfully')
        console.log("Myuserrole",responseJson.user_role)
        this.setState({ 
          userRole: responseJson.user_role
        })
      }
      else if (apiRequestCallId === this.initUsersCallId) {
        if(!Array.isArray(responseJson.data)) return;
        let allUserOptions = responseJson.data.map((d:any) => d.attributes)
        let allModeratorOptions = (this.props.moderators || []).concat(allUserOptions)
        if(this.props.moderators && this.props.moderators.length > 0){
          let idList = this.props.moderators.map((id : any) => id.id);
          let data = allModeratorOptions.filter((item: any)=> !idList.includes(item.id) )
          allModeratorOptions = [...this.props.moderators, ...data];
        }
        let allMemberOptions = allUserOptions;
        if(this.props.members && this.props.members.length > 0){
          let idList = this.props.members.map((id : any) => id.id);
          let data = allMemberOptions.filter((item: any)=> !idList.includes(item.id) )
          allMemberOptions = [...this.props.members, ...data];
        }
        const pagination = responseJson.meta.pagination
        this.initUsersList = allUserOptions
        this.initUsersPagination = pagination
        this.setState({ 
          moderatorOptions: allModeratorOptions, 
          memberOptions: allMemberOptions, 
          allUserOptions, 
          memberPagination: pagination, 
          moderatorPagination: pagination  
        })
      } else if (apiRequestCallId === this.state.moderatorPaginateCallId) {
        if(Array.isArray(responseJson.data)) {
          const options = responseJson.data.map((d:any) => d.attributes)
          if(this.props.moderators && this.props.moderators.length > 0){
            let idList = this.props.moderators.map((id : any) => id.id);
            let data = options.filter((item: any)=> !idList.includes(item.id) )
            const moderatorOptions = this.filterUnique(this.state.moderatorOptions.concat(data))
            this.setState({
              moderatorOptions,
              moderatorPagination: responseJson.meta.pagination
            })
          }else{
            const moderatorOptions = this.filterUnique(this.state.moderatorOptions.concat(options))
            this.setState({
              moderatorOptions,
              moderatorPagination: responseJson.meta.pagination
            })
          }
        }
      } else if (apiRequestCallId === this.state.memberPaginateCallId) {
        if(Array.isArray(responseJson.data)) {
          let options = responseJson.data.map((d:any) => d.attributes)
          if(this.props.members && this.props.members.length > 0){
            let idList = this.props.members.map((id : any) => id.id);
            let data = options.filter((item: any)=> !idList.includes(item.id))
            const memberOptions = this.filterUnique(this.state.memberOptions.concat(data))
            this.setState({
              memberOptions,
              memberPagination: responseJson?.meta?.pagination
            })
          }else{
            const memberOptions = this.filterUnique(this.state.memberOptions.concat(options))
            this.setState({
              memberOptions,
              memberPagination: responseJson?.meta?.pagination
            })
          }
        }
      } else if (apiRequestCallId === this.state.moderatorSearchCallId) {
        if(!Array.isArray(responseJson.data)) 
          this.setState({ 
            moderatorSearchCallId: '', 
            moderatorOptions: [], 
            moderatorPagination: undefined })
        else {
          if(this.props.moderators && this.props.moderators.length > 0){
            let options = responseJson.data.map((d:any) => d.attributes)
            let idList = this.props.moderators.map((id : any) => id.id);
            let data = options.filter((item: any)=> !idList.includes(item.id) )
            const moderatorOptions = this.filterUnique(this.state.moderatorOptions.concat(data))
            this.setState({
              moderatorOptions,
              moderatorPagination: responseJson.meta.pagination
            })
          }else{
            const moderatorOptions = responseJson.data.map((d:any) => d.attributes)
            this.setState({ 
              moderatorSearchCallId: '', 
              moderatorOptions, 
              moderatorPagination: responseJson.meta.pagination 
            })
          }
        }
      } else if (apiRequestCallId === this.state.memberSearchCallId) {
        if(!Array.isArray(responseJson.data)) 
          this.setState({ memberSearchCallId: '', memberOptions: [], memberPagination: undefined })
        else {
          let options = responseJson.data.map((d:any) => d.attributes)
          if(this.props.members && this.props.members.length > 0){
            let idList = this.props.members.map((id : any) => id.id);
            let data = options.filter((item: any)=> !idList.includes(item.id))
            const memberOptions = this.filterUnique(this.state.memberOptions.concat(data))
            this.setState({
              memberSearchCallId: '', 
              memberOptions, 
              memberPagination: responseJson?.meta?.pagination
            })
          }else{
            const memberOptions = this.filterUnique(this.state.memberOptions.concat(options))
            this.setState({ 
              memberSearchCallId: '', 
              memberOptions, 
              memberPagination: responseJson?.meta?.pagination 
            })
          }
        }
      } else if (apiRequestCallId === this.importCsvCallId) {
        if(Array.isArray(responseJson?.data)) {
          const importIds = responseJson.data.map((d:any) => d.attributes.id )
          const emailErrors = responseJson.meta.invalid_emails
          const newUsers = responseJson.data
            .map((d: any) => d.attributes)
          let memberOptions = [...this.state.memberOptions, ...newUsers]
          toast.success(`${importIds.length} users imported from CSV`)
          this.setState({ 
            importIds, 
            importLoading: false,
            memberOptions, 
            // allUserOptions 
          })
          if(emailErrors?.length) this.setState({ importError: `Could not import ${emailErrors?.length} emails, invalid or not found.` })
        }
      } else if (apiRequestCallId === this.followCommunityCallId) {
        if(responseJson?.message) {
          toast.success(responseJson?.message)
          const community_id = this.joinCommunityCallId[apiRequestCallId]
          delete this.joinCommunityCallId[apiRequestCallId]
          let newCommunities = [ ...this.state.communities]
          newCommunities = newCommunities.map((community) => {
            if (community.id === community_id) {
              community.attributes.in_community = community.attributes.in_community ? false : true
            }
            return community
          })
          this.setState({ communities: newCommunities})
        }
      }
    } else if (message.id === getName(MessageEnum.GetAllCategoriesResponseMessage)) {
      const allCategories = message.getData(
        getName(MessageEnum.AllCategoriesDataMessage)
      )
      this.setState({ allCategories }, () => {
        if(!this.props.navigation.getParam('id')) return // check if edit page
        this.preferenceChangeEffect(this.props.initialValues.preference_ids)
        
      })
    } else if (message.id === getName(MessageEnum.GetAllSubCategoriesResponseMessage)) {
      
      const allSubCategories = message.getData(
        getName(MessageEnum.AllSubCategoriesDataMessage)
      )
      this.setState({ allSubCategories }, ()=>{
        if(!this.props.navigation.getParam('id')) return
        this.categoryChangeEffect(this.props.initialValues.category_ids)
      })
    }
    // Customizable Area End

  }

  // Customizable Area Start
  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address"
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed()
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  }

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  }

  clearAndClose = () => {
    this.setState({ importError: '' })
    this.props.onClose && this.props.onClose()
  }
  createFormSchema = yup.object().shape({
    name: yup.string().required().label('Community Name').matches(/^[A-Za-z0-9:\- ]+$/, 'Community Name can only have alphabets and numbers').max(160),
    // @ts-ignore
    image: yup.mixed().label('Image').maxFileSizeSingle("Image must be less than 1Mb", 1024*1024),
    description: yup.string().required().max(1000).label('Description'),
    moderator_ids: yup.array().min(3, "Atleast 3 moderators are required").label('Moderators'),
    preference_ids: yup.array().min(1, "Select atleast one Preference").label('Preferences'),
    category_ids: yup.array().min(1, "Select atleast one Category").label('Categories'),
    sub_category_ids: yup.array().min(1, "Select atleast one Sub-Category").label('Sub-Categories'),
    // members: yup.array(),
    user_ids: yup.array().min(1, "Select atleast one Member"),
  })

  handleClickOpen = () => {
    // setOpen(true);
    this.setState({open:true});
  };
  handleClose = () => {
    // setOpen(false);
    this.setState({open:false});

  };
  onImageChange = (event:any) => {
   
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      this.setState({
        image: URL.createObjectURL(img)
      });
    }
  };
  handleSwitchChange = (event:any) => {
    this.setState({checked: event.target.checked },()=>console.log(this.state.checked));
    
  };

  handleChange = (prop:any) => (event:any) => {
    this.setState({ ...this.state, [prop]: event.target.value });
    // console.log(this.state.email,"values")
  };

  handleDelete = (chipToDelete: any) => () => {
    this.setState({
      preferences: this.state.preferences.filter(c => c != chipToDelete) //filter will produce a copy of the array
    },()=>console.log(this.state.preferences,'***'));
  };
  handleDeleteOption = (chipToDelete: any) => () => {
    this.setState({
      selectedOption: this.state.selectedOption.filter(c => c != chipToDelete) //filter will produce a copy of the array
    },()=>console.log(this.state.selectedOption,'***'));
  };
  handleDeleteMember = (chipToDelete: any) => () => {
    this.setState({
      addmembers: this.state.addmembers.filter(c => c != chipToDelete) //filter will produce a copy of the array
    },()=>console.log(this.state.addmembers,'***'));
  };

  hideModal = () => {
    this.setState({ modal: '' })
  }

  

  loadCategoriesSubCategories= ()=>{
    const msg = new Message(
      getName(MessageEnum.GetAllCategoriesMessage)
    )
    runEngine.sendMessage(msg.id, msg)

    const msg2 = new Message(
      getName(MessageEnum.GetAllSubCategoriesMessage)
    )
    runEngine.sendMessage(msg2.id, msg2)
  }

  // for create community form
  loadInitialUsers = () => {
    const { token } = this.state
    this.initUsersCallId = sendAPIRequest(
      configJSON.getUsersEndpoint,
      { method: 'GET', headers: { token } }
    )
  }



  handleCreateCommunitySubmit = (rawData: any) => {
    const data = rawData

    const body = new FormData();
    (['moderator_ids',
          'preference_ids',
          'category_ids',
          'sub_category_ids',
          'user_ids',]).forEach((id: any) => {
          body.append(`[data][attributes][${id}]`, `[${String(data[id])}]`)
          // data[id].forEach((val: any) => body.append(`[data][attributes][${id}]`, val))
        }
    );

    body.append('[data][attributes][community_type]', (!data.community_type?'1':'0') )
    body.append('[data][attributes][name]', data.name)
    body.append('[data][attributes][description]', data.description)
    if(data.image !== '')
      body.append('[data][attributes][image_id]', '[image.id]')
    if(data.image && typeof data.image !== "string") 
      body.append('[data][attributes][image]', data.image)
    body.append('[data][attributes][community_id]', "")
    const { token } = this.state
    this.setState({ loadingCreate: true })
    const { community_id } = this.props
    console.log({ props: this.props })
    const url = community_id?configJSON.updateCommunityEndpoint.replace(':id', community_id):configJSON.createCommunityEndpoint
    this.createCommunityCallId = sendAPIRequest(url, {
      method: community_id?'PATCH':'POST',
      headers: {
        token,
        'Content-Encoding': 'gzip'
      },
      body,
    })

  }

  handleUserRole = () => {
    const { token } = this.state
    const url = configJSON.getUserRoleEndpoint
    this.userRoleCallId =  sendAPIRequest(url , { method: 'GET', headers: {token} })
  }

  handleUserSearch = (query: string, page?: number) => {
    console.log({page})
    const { token } = this.state
    const params = new URLSearchParams()
    query && params.append('query', query)
    page && params.append('page', String(page))
    const url = configJSON.getUsersEndpoint +"?"+ params.toString()
    return sendAPIRequest(url , { method: 'GET', headers: {token} })
  }

  handleModeratorSearch = (query: string) => {
    console.log({ query })
    if(query)
      this.setState({ 
        moderatorSearchCallId: this.handleUserSearch(query), 
        moderatorQuery: query, 
        moderatorPaginateCallId: '' 
      })
    else 
      this.setState({ 
        moderatorOptions: this.state.allUserOptions, 
        moderatorPagination: this.initUsersPagination, 
        moderatorQuery: '',
        moderatorSearchCallId: '',
      })
  }

  handleMemberSearch = (query: string) => {
    if(query)
      this.setState({ 
        memberSearchCallId: this.handleUserSearch(query), 
        memberQuery: query, 
        memberPaginateCallId: '' 
      }) 
    else
      this.setState({ 
        memberOptions: this.state.allUserOptions, 
        memberPagination: this.initUsersPagination, 
        memberQuery: '',
        memberSearchCallId: '' 
      })
  }

  handleMemberClose = ()=>{
    this.setState({ 
      memberSearchCallId: '', 
      memberQuery: '',
      memberPaginateCallId: '', 
      memberOptions: this.state.allUserOptions, 
      memberPagination: this.initUsersPagination,   
    })
  }
  handleModeratorClose = () => {
    this.setState({ 
      moderatorSearchCallId: '', 
      moderatorQuery: '',
      moderatorPaginateCallId: '', 
      moderatorOptions: this.state.allUserOptions, 
      moderatorPagination: this.initUsersPagination,   
    })
  }
  preferenceChangeEffect = (ids: number[]) => {
    const lst = ids.map((id: any) => this.allPreferences.find((p: any)=>p.id === id)?.label?.slice?.(0, 1));
    const labels = new Set(lst);
    const categoryOptions = this.state.allCategories.filter((cat: any) => {
      return cat.preferences.some((p: any)=>labels.has(p.slice(0, 1)))
    })

    console.log({ preferencechangeEffect: ids, categoryOptions, lst, allCategories:this.state.allCategories })
    this.setState({ categoryOptions, selectedPreferences: lst as any[] })
  }

  categoryChangeEffect = (ids: number[]) => {
    let plookup = new Set(this.state.selectedPreferences)
    if(this.props.initialValues?.preference_ids && plookup.size === 0)
      plookup = new Set(
          this.props.initialValues?.preference_ids.map(
            (p: any) => this.preferenceIdToChar[p]
          )
        ) 
   const subCategoryOptions = this.state.allSubCategories.filter((st: any) => {
      console.log({st})
      return st.categories.some((ct: any) => ids.includes(ct.id)) && st.preferences.some(
        (p: any)=> plookup.has(p.slice(0,1))
      )
    })

    console.log({catChangeEffect: subCategoryOptions, allSubCategories: this.state.allSubCategories, ids, plookup})
    this.setState({ subCategoryOptions })
  }

  




  importCsv = (files: File[]) => {
    const file = Array.from(files)[0]
    if(file) {
      const {token} = this.state
      const body = new FormData()
      body.append('file', file)
      this.importCsvCallId = sendAPIRequest(configJSON.importSampleCsvEndpoint, {
        method: 'POST',
        body,
        headers: {token}
      })
      this.setState({ importLoading: true })
    }
  }

  
  paginateMembers = () => {
    if(this.state.memberSearchCallId!=='') return
    const {next_page} = this.state.memberPagination
    this.setState({ memberPaginateCallId: this.handleUserSearch(this.state.memberQuery, next_page) })
  }

  paginateModerators = () => {
    if(this.state.moderatorSearchCallId!=='') return
    const {next_page} = this.state.moderatorPagination
    this.setState({ moderatorPaginateCallId: this.handleUserSearch(this.state.moderatorQuery, next_page) })
   }

  

  /**
  * From list of users, remove duplicate users
  */
  filterUnique = (items: any[]) => {
    const hashmap = new Set()
    return items.filter((i: any)=>{
      if(hashmap.has(i.id)) return false
      hashmap.add(i.id)
      return hashmap
    })
  }

  /**
  * Compare arr and brr, regardless 
  */
  arrayEquals = (arr: any[], brr: any[]) => {
    if(arr.length!=brr.length) return false
    const aLookup = new Set(arr)
    return brr.every((b: any) => aLookup.has(b))
  }

  // remove subCats when category is changed
  syncSubCatWithCats = (cat_ids: any[], sub_cat_ids: any[], setSubCat: any) => {
    if(this.state.allSubCategories.length === 0) return
    const sCats = sub_cat_ids.map((id: any) => this.state.allSubCategories.find((st: any)=>st.id ==id))
    const catLookup = new Set(cat_ids)
    const filteredCats = sCats.filter((st: any)=>{
      if(!st) return false
      return st.categories.some((cat: any)=>catLookup.has(cat.id))
    }).map((st: any)=>st.id)
    if(!this.arrayEquals(sub_cat_ids, filteredCats))
      setSubCat(filteredCats)
    console.log({sCats, sub_cat_ids, filteredCats})
  }
  // Customizable Area End
  
}

import React, { useEffect } from "react";
// Customizable Area Start
import DashboardLayout from "../../../components/src/DashboardLayout.web";
// @ts-ignore
import classes from './AddStakeholder.module.css';
import Autocomplete from '@material-ui/lab/Autocomplete';
import OverlayLoader from '../../../components/src/Loader.web';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import StakeholderProfileFormController, {
	Props,
} from "./StakeholderProfileFormController";
import {
	deleteImg, ExcelIcon, editImg, warningIcon, eye, male, female, ic_upload_green, pdf, imageIcon, docIcon
} from './assets'
import { iconAdd } from '../../JobListing2/src/assets';
import UploadButton from "../../../components/src/UploadButton.web";
import { CloseCircleIcon } from "../../../components/src/Icons.web";
import {
	Box,
	Fade,
	Button,
	Modal,
	Backdrop,
	CircularProgress,
	Grid,
	IconButton,
	Radio,
	RadioGroup,
	FormControlLabel,
	InputAdornment,
	Typography,
	MenuItem,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableCell,
	TableRow,
	Paper,
	Avatar,
	Chip,
	ListItemText,
	Checkbox,
} from "@material-ui/core";
import Flex from "../../../components/src/Flex.web";
import CloseIcon from "@material-ui/icons/Close";
import { styled } from "@material-ui/styles";
import { Formik, Form, Field } from "formik";
import { InputRow, MInput, MSelect, MDatePicker } from "../../../components/src/FormModal.web";
import Pagination from '@material-ui/lab/Pagination';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import SearchSelect from "../../../components/src/SearchSelect.web";
import UserContext from '../../../components/src/UserProvider'
// Customizable Area End
export default class StakeholderProfiles extends StakeholderProfileFormController {
	constructor(props: Props) {
		super(props);
		// Customizable Area Start
		// Customizable Area End
	}

	// Customizable Area Start
	showBoardOperationFormData = () => {
		return (
			<>
				<div style={{ paddingTop: '10px', marginTop: '40px', fontSize: '18px', fontWeight: 600, }}>
					<span>Board Operations and Committees</span>
				</div>
				<div style={{ paddingTop: '10px', marginTop: '15px' }}>
					<Formik
						data-test-id='boardoperationform'
						initialValues={{
							id: this.state.boardOperationData?.id,
							minutes_of_last_three_board_meetings: this.state.boardOperationData?.attributes?.minutes_of_last_three_board_meetings,
							reference_of_audit_committees: this.state.boardOperationData?.attributes?.reference_of_audit_committees,
							head_of_internal_auditor: this.state.boardOperationData?.attributes?.head_of_internal_auditor,
							internal_auditor_reporting_to: this.state.boardOperationData?.attributes?.internal_auditor_reporting_to,
							partner_engaged_names: this.state.boardOperationData?.attributes?.partner_engaged_names,
							statutory_auditor_consulting_company_name: this.state.boardOperationData?.attributes?.statutory_auditor_consulting_company_name,
							terms_of_engagement_of_statutory_auditors: this.state.boardOperationData?.attributes?.terms_of_engagement_of_statutory_auditors,
							auditor_fees_amount: this.state.boardOperationData?.attributes?.auditor_fees_amount,
							upload_minutes_of_last_three_board_meetings: this.state.boardOperationData?.attributes?.upload_minutes_of_last_three_board_meetings,
							upload_reference_of_audit_committees: this.state.boardOperationData?.attributes?.upload_reference_of_audit_committees,
							upload_reference_of_remuneration_committees: this.state.boardOperationData?.attributes?.upload_reference_of_remuneration_committees,
							upload_reference_of_nomination_committees: this.state.boardOperationData?.attributes?.upload_reference_of_nomination_committees,
							fees_determination: this.state.boardOperationData?.attributes?.fees_determination,
							upload_agenda: this.state.boardOperationData?.attributes?.upload_agenda,
							agenda_of_first_board_meetings: this.state.agendaOfFirstBoardMeetings,
							agenda_of_second_board_meetings: this.state.agendaOfSecondBoardMeetings,
							agenda_of_third_board_meetings: this.state.agendaOfThirdBoardMeetings,
							review: this.state.boardOperationData?.attributes?.review
						}}
						enableReinitialize
						validationSchema={this.createValidationSchema}
						validate={values => {
							const errors: any = {};
							if (values?.agenda_of_first_board_meetings == null && (values?.agenda_of_second_board_meetings !== null || values?.agenda_of_third_board_meetings !== null)) {
								errors.agenda_of_first_board_meetings = "Please select atleast one date or clear all the three borad meetings date"
							}
							if (values?.agenda_of_second_board_meetings == null && (values?.agenda_of_first_board_meetings !== null || values?.agenda_of_third_board_meetings !== null)) {
								errors.agenda_of_second_board_meetings = "Please select atleast one date or clear all the three borad meetings date";
							}
							if (values?.agenda_of_third_board_meetings == null && (values?.agenda_of_first_board_meetings !== null || values?.agenda_of_second_board_meetings !== null)) {
								errors.agenda_of_third_board_meetings = "Please select atleast one date or clear all the three borad meetings date";
							}
							return errors;
						}}
						onSubmit={(values) => {
							this.state.boardOperationData?.id !== null ? this.editBoardOperationsList(values) : this.createBoardOperations(values)
						}}
					>
						{({handleSubmit,values,handleChange}) => {
							return (
								<BoardOperationBox translate={'no'} onSubmit={handleSubmit}>
									{this.state.loadingBoardOperation ? <div style={{ textAlign: "center" }}><CircularProgress size={36} /></div> :
										<>
											<div>
												<div style={{ display: "flex", gap: '15px' }}>
													<div style={{ width: '50%' }}>
														<InputRow data-test-id='firstBoardMeetingId' label={<Flex>First Board meeting
															<Typography color="textSecondary"></Typography></Flex>} input={
																<MDatePicker data-test-id='firstboardMeeting' style={{ margin: 0 }} maxDate={new Date()} clearable clearText="Clear" name="agenda_of_first_board_meetings" InputProps={{ placeholder: "Enter First Board Meeting" }} onChange={this.dateChangeOfFirstMeeting} />
															} /></div>
													<div style={{ width: '50%' }}>
														<InputRow data-test-id='secondBoardMeetingId' label={<Flex>Second Board meeting
															<Typography color="textSecondary"></Typography></Flex>} input={
																<MDatePicker data-test-id='secondboardMeeting' style={{ margin: 0 }} maxDate={new Date()} clearable clearText="Clear" name="agenda_of_second_board_meetings" InputProps={{ placeholder: "Enter Second Board Meeting" }} onChange={this.dateChangeOfSecondMeeting} />
															} />
													</div>
												</div>
												<div style={{ marginBottom: "15px", marginTop: "8px" }}>
													<InputRow data-test-id='thirdBoardMeetingId' label={<Flex>Third Board meeting
														<Typography color="textSecondary"></Typography></Flex>} input={
															<MDatePicker data-test-id='thirdboardMeeting' style={{ margin: 0 }} maxDate={new Date()} clearable clearText="Clear" name="agenda_of_third_board_meetings" InputProps={{ placeholder: "Enter Third Board Meeting" }} onChange={this.dateChangeOfThirdMeeting} />
														} />
												</div>
												<Box className="upload-btn">
													<Box>
														<InputRow data-test-id='uploadAgenda' label={""}
															input={<UploadButton
																data-test-id='uploadAgendaId'
																attchementType={"upload_agenda"}
																deleteBoardOperationFiles={this.deleteBoardOperationFiles}
																isCorporate={true}
																isCardDisable={this.state.disabled}
																boardOperationId={values.id}
																multiple={true}
																filename={this.state.filesArray}
																maxSize={20 * 1024 * 1024}
																maxSizeError={"Cannot upload document more than 2Mb"}
																name="upload_agenda"
																label="Upload Attachment"
																setFileInState={this.setFilesArray} />}
														/>
													</Box>
													<Box style={{ fontStyle: 'italic', color: '#000' }}>(Maximum 5 attachments)</Box>
												</Box>
											</div>
											<div>
												<InputRow label={<Flex>Minutes of last three Board meetings
													<Typography color="textSecondary"></Typography></Flex>} input={
														<MInput name="minutes_of_last_three_board_meetings" placeholder="Enter Here" onChange={handleChange} value={values.minutes_of_last_three_board_meetings} />
													} />
												<Box className="upload-btn">
													<Box>
														<InputRow data-test-id='uploadMinOfLastThree' label={""}
															input={<UploadButton
																data-test-id='uploadMinOfLastThreeId'	
																attchementType={"upload_minutes_of_last_three_board_meetings"}
																isCorporate={true}
																boardOperationId={values.id}
																isCardDisable={this.state.disabled}
																deleteBoardOperationFiles={this.deleteBoardOperationFiles}
																multiple={true}
																filename={this.state.boardMeetingsAttachements}
																maxSize={20 * 1024 * 1024}
																maxSizeError={"Cannot upload document more than 2Mb"}
																name="upload_minutes_of_last_three_board_meetings"
																label="Upload Attachment"
																setFileInState={this.setBoardMeetingsArray} />}
														/>
													</Box>
													<Box style={{ fontStyle: 'italic', color: '#000' }}>(Maximum 5 attachments)</Box>
												</Box>
											</div>
											<div>
												<InputRow label={<Flex>Attach director signed copies of Terms of Reference of Audit
													Committees
													<Typography color="textSecondary"></Typography></Flex>} input={
														<MInput name="reference_of_audit_committees" placeholder="Enter Here" onChange={handleChange} value={values.reference_of_audit_committees} />
													} />
												<Box className="upload-btn">
													<Box>
														<InputRow data-test-id='deleteBoardOperationFileId' label={""}
															input={<UploadButton
																data-test-id='deleteId'
																attchementType={"upload_reference_of_audit_committees"}
																boardOperationId={values.id}
																isCardDisable={this.state.disabled}
																deleteBoardOperationFiles={this.deleteBoardOperationFiles}
																isCorporate={true}
																multiple={true}
																filename={this.state.referenceOfAuditCommittees}
																maxSize={20 * 1024 * 1024}
																maxSizeError={"Cannot upload document more than 2Mb"}
																name="upload_reference_of_audit_committees"
																label="Upload Attachment"
																setFileInState={this.setAuditCommitteesArray} />}
														/>
													</Box>
													<Box style={{ fontStyle: 'italic', color: '#000' }}>(Maximum 5 attachments)</Box>
												</Box>
											</div>
											<div>
												<Flex style={{ marginBottom: "19px", fontSize: "13px", color: "#404040", fontFamily: "HelveticaNeue", fontWeight: 600 }}>Attach director signed copies of Terms of Reference of Remuneration Committees
													<Typography color="textSecondary"></Typography></Flex>
												<Box className="upload-btn">
													<Box>
														<InputRow data-test-id='renumerationId' label={""}
															input={<UploadButton
																data-test-id='renumeration'
																attchementType={"upload_reference_of_remuneration_committees"}
																boardOperationId={values.id}
																isCorporate={true}
																isCardDisable={this.state.disabled}
																deleteBoardOperationFiles={this.deleteBoardOperationFiles}
																multiple={true}
																filename={this.state.remunirationCommittees}
																maxSize={20 * 1024 * 1024}
																maxSizeError={"Cannot upload document more than 2Mb"}
																name="upload_reference_of_remuneration_committees"
																label="Upload Attachment"
																setFileInState={this.setRemunarationCommitteesArray} />}
														/>
													</Box>
													<Box style={{ fontStyle: 'italic', color: '#000' }}>(Maximum 5 attachments)</Box>
												</Box>
											</div>
											<div>
												<Flex style={{ marginBottom: "19px", fontSize: "13px", color: "#404040", fontFamily: "HelveticaNeue", fontWeight: 600 }}>Attach director signed copies of Terms of Reference of Nomination
													Committees
													<Typography color="textSecondary"></Typography></Flex>
												<Box className="upload-btn">
													<Box>
														<InputRow data-test-id='nominationId' label={""}
															input={<UploadButton
																data-test-id='nomination'
																attchementType={"upload_reference_of_nomination_committees"}
																boardOperationId={values.id}
																isCorporate={true}
																isCardDisable={this.state.disabled}
																deleteBoardOperationFiles={this.deleteBoardOperationFiles}
																multiple={true}
																filename={this.state.nominationCommittees}
																maxSize={20 * 1024 * 1024}
																maxSizeError={"Cannot upload document more than 2Mb"}
																name="upload_reference_of_nomination_committees"
																label="Upload Attachment"
																setFileInState={this.setNominationCommitteesArray} />}
														/>
													</Box>
													<Box style={{ fontStyle: 'italic', color: '#000' }}>(Maximum 5 attachments)</Box>
												</Box>
											</div>
											<div>
												<InputRow label={<Flex>Head of Internal Auditor - Name
													<Typography color="textSecondary"></Typography></Flex>} input={
														<MInput name="head_of_internal_auditor" placeholder="Enter Here" onChange={handleChange} value={values.head_of_internal_auditor} />
													} />
											</div>
											<div>
												<>
													<InputRow label={<Flex>Internal Auditor Reporting to
														<Typography color="textSecondary"></Typography></Flex>} input={
															<MInput id='internalAuditor' onFocus={() => this.setState({ showChip: false })} onBlur={() => this.setState({ showChip: true })} name="internal_auditor_reporting_to" placeholder="Enter Here" onChange={handleChange} value={values.internal_auditor_reporting_to} />
														} />
													{this.state.showChip && values.internal_auditor_reporting_to !== "" &&
														<Chip
															style={{ bottom: "10px", position: "relative" }}
															label={values.internal_auditor_reporting_to}
															className={classes.chip}
														/>
													}
												</>
											</div>
											<div>
												<InputRow label={<Flex>Statutory Auditor Consulting company Name
													<Typography color="textSecondary"></Typography></Flex>} input={
														<MInput name="statutory_auditor_consulting_company_name" placeholder="Enter Here" onChange={handleChange} value={values.statutory_auditor_consulting_company_name} />
													} />
											</div>
											<div>
												<InputRow label={<Flex>Partner engaged (names)
													<Typography color="textSecondary"></Typography></Flex>} input={
														<MInput name="partner_engaged_names" placeholder="Enter Here" onChange={handleChange} value={values.partner_engaged_names} />
													} />
											</div>
											<div>
												<InputRow label={<Flex>Terms of engagement of statutory auditors
													<Typography color="textSecondary"></Typography></Flex>} input={
														<MInput name="terms_of_engagement_of_statutory_auditors" placeholder="Enter Here" onChange={handleChange} value={values.terms_of_engagement_of_statutory_auditors} />
													} />
											</div>
											<div>
												<InputRow label={<Flex>Auditor's fees amount
													<Typography color="textSecondary"></Typography></Flex>} input={
														<MInput name="auditor_fees_amount" onChange={handleChange} value={values.auditor_fees_amount} {...dollarFieldProps} />
													} />
											</div>
											<div>
												<Flex style={{ marginBottom: "19px", fontSize: "13px", color: "#404040", fontFamily: "HelveticaNeue", fontWeight: 600 }}>Fees determination resolution
													<Typography color="textSecondary"></Typography></Flex>
												<Box className="upload-btn">
													<Box>
														<InputRow data-test-id='setDeterminationResolutionArrayId' label={""}
															input={<UploadButton
																data-test-id='setDeterminationResolutionArrayDataId'
																attchementType={"fees_determination"}
																boardOperationId={values.id}
																deleteBoardOperationFiles={this.deleteBoardOperationFiles}
																multiple={true}
																isCardDisable={this.state.disabled}
																isCorporate={true}
																filename={this.state.determinationResolution}
																maxSize={20 * 1024 * 1024}
																maxSizeError={"Cannot upload document more than 2Mb"}
																name="fees_determination"
																label="Upload Attachment"
																setFileInState={this.setDeterminationResolutionArray} />}
														/>
													</Box>
													<Box style={{ fontStyle: 'italic', color: '#000' }}>(Maximum 5 attachments)</Box>
												</Box>
											</div>
											<div>
												<Flex style={{ marginBottom: "19px", fontSize: "13px", color: "#404040", fontFamily: "HelveticaNeue", fontWeight: 600 }}>Review of statutory Auditors
													<Typography color="textSecondary"></Typography></Flex>
												<Box className="upload-btn">
													<Box>
														<InputRow data-test-id='setStatutoryAuditorsArrayId' label={""}
															input={<UploadButton
															data-test-id='setStatutoryAuditorsArrayDataId'
																attchementType={"review"}
																boardOperationId={values.id}
																deleteBoardOperationFiles={this.deleteBoardOperationFiles}
																multiple={true}
																isCardDisable={this.state.disabled}
																isCorporate={true}
																filename={this.state.statutoryAuditors}
																maxSize={20 * 1024 * 1024}
																maxSizeError={"Cannot upload document more than 2Mb"}
																name="review"
																label="Upload Attachment"
																setFileInState={this.setStatutoryAuditorsArray} />}
														/>
													</Box>
													<Box style={{ fontStyle: 'italic', color: '#000' }}>(Maximum 5 attachments)</Box>
												</Box>
											</div>
											<BtnSave>
												<Button
													disabled={
														(this.state.agendaOfThirdBoardMeetings == null &&
														this.state.agendaOfSecondBoardMeetings == null &&
														this.state.agendaOfFirstBoardMeetings == null &&
														!values?.auditor_fees_amount &&
														!values?.head_of_internal_auditor &&
														!values?.internal_auditor_reporting_to &&
														!values?.minutes_of_last_three_board_meetings &&
														!values?.reference_of_audit_committees &&
														values?.fees_determination?.length <= 0 &&
														values?.review?.length <= 0 &&
														!values?.statutory_auditor_consulting_company_name &&
														!values?.terms_of_engagement_of_statutory_auditors &&
														values?.upload_agenda?.length <= 0 &&
														values?.upload_minutes_of_last_three_board_meetings?.length <= 0 &&
														values?.upload_reference_of_audit_committees?.length <= 0 &&
														values?.upload_reference_of_remuneration_committees?.length <= 0 &&
														values?.upload_reference_of_nomination_committees?.length <= 0 &&
														!values?.partner_engaged_names) || this.state.disabled
													} style={{ backgroundColor: "#009B07" }} type="submit">SAVE</Button>
											</BtnSave>
										</>}
								</BoardOperationBox>
							)
						}}
					</Formik>
				</div>
			</>
		)
	}

	showStakeHolderTable = () => {
		return (
			<div style={{ marginTop: '20px', alignItems: 'center' }}>
				<TableContainer component={Paper}>
					{this.state.loading ? <LoaderContainer>Loading...</LoaderContainer> :
						<Table>
							<TableHead>
								<TableRowBox>
									<TableCell style={{ fontWeight: 'bold', color: 'black' }}>SL.No</TableCell>
									<TableCell style={{ fontWeight: 'bold', color: 'black' }}>Key Stakeholders</TableCell>
									<TableCell style={{ fontWeight: 'bold', color: 'black' }}>Engagement Approach</TableCell>
									<TableCell style={{ fontWeight: 'bold', color: 'black' }}>Engagement Tools</TableCell>
									<TableCell style={{ fontWeight: 'bold', color: 'black' }}>Frequency/Timeline</TableCell>
									<TableCell style={{ fontWeight: 'bold', color: 'black' }}>Stakeholder Manager</TableCell>
									<TableCell></TableCell>
									<TableCell></TableCell>
								</TableRowBox>
							</TableHead>
							<TableBody>
								{this.state.stackholderProfileList?.length > 0 ? this.state.stackholderProfileList?.map((item: any, index: any) => {
									return (
										<>
											<TableRow>
												<TableCell>{10 * (this.state.current_page - 1) + (index + 1)}</TableCell>
												<TableCell>{item?.attributes?.key_stakeholders}</TableCell>
												<TableCell style={{ width: "min-content" }}>{item?.attributes?.engagement_approach?.toString()}</TableCell>
												<TableCell>{item?.attributes?.engagement_tools}</TableCell>
												<TableCell style={{ width: "min-content" }}>{item?.attributes?.frequency_timeline}</TableCell>
												<TableCell>
													<div style={{ display: "flex", verticalAlign: "middle" }}>
														{item?.attributes?.stakeholder_manager_first_name?.map((x: any, index: any) => {
															return (
																<>
																	<Avatar style={{ width: "25px", height: "25px", marginRight: "8px" }} src={item?.attributes?.profile_pic[0] !== null ? item?.attributes?.profile_pic[0][index]?.image_url : ''} />
																	<div style={{ marginRight: "9px" }}>{item?.attributes?.stakeholder_manager_first_name[index] + " " + item?.attributes?.stakeholder_manager_last_name[index]}</div>
																</>
															)
														})}
													</div>
												</TableCell>
												<TableCell>{<BtnImg style={{ cursor: 'pointer' }} id='editStakeholder' src={editImg} onClick={() => this.handleEditStackHolder(item?.id, item)} />}</TableCell>
												<TableCell>{<BtnImg style={{ cursor: 'pointer' }} id='deleteStakeholder' src={deleteImg} onClick={() => this.handleModal(item)} />}</TableCell>
											</TableRow>
											{(this.state.tableRowId == item.id && this.state.delete) && <UserModal deleteStakeHolder={true} Delete={this.handleDeletePopup} showModal={this.state.delete} handleModal={this.handleCloseDelete} value={item} />}
											{(this.state.tableRowId == item.id && this.state.editProfile) && <AddStakeHolderForm paginateStackholder={this.paginateStackholder} stackholderPagination={this.state.stackholderPagination} handleStackholderSearchQuery={this.handleStackholderSearchQuery} handleApproversClose={this.handleApproversClose} selectedStakeHolderManager={this.state.selectedStakeHolderManager} stakeholderManagerData={this.state.stakeholderManagerData} engagementData={this.state.engagementData} handleShowEngagementField={this.handleShowEngagementField} FrequencyTimelineState={this.state.FrequencyTimeline} EngagementApproachState={this.state.EngagementApproach} data={item} showEngagementApproachOthersField={this.state.showEngagementApproachOthersField} showFrequencyTimelineOthersField={this.state.showFrequencyTimelineOthersField} isEditStakeHolder={true} handleEngagementApproach={this.handleEngagementApproach} handleFrequencyTimeline={this.handleFrequencyTimeline} handleEnagegementTools={this.handleEnagegementTools} addStakeHolderProfileForms={this.editStakeHolderProfileForms} handleStackHolderManager={this.handleStackHolderManager} handleKeyStackHolder={this.handleKeyStackHolder} KeyStackHolder={this.state.KeyStackHolder} onCloseStakeHolderModal={this.onCloseStackHolderModal} open={this.state.editProfile} />}
										</>
									)
								}) : <TableRow>
									<TableCell style={{ textAlign: 'center' }} colSpan={8}>No Data Found</TableCell>
								</TableRow>}
							</TableBody>
						</Table>}
				</TableContainer>
				<Pagin>
					{window.screen.width > 950 ?
						<Pagination disabled={this.state.total_pages <= 1} defaultPage={1} color="primary" variant="outlined" count={this.state.total_pages} page={this.state.current_page} onChange={this.handlePageChange} />
						:
						<Pagination disabled={this.state.total_pages <= 1} defaultPage={1} color="primary" size="small" count={this.state.total_pages} page={this.state.current_page} onChange={this.handlePageChange} />
					}
				</Pagin>
			</div>
		)
	}

	showCorporateGovernanceTable = () => {
		return (
			<div style={{ marginTop: '20px', alignItems: 'center' }}>
				<TableWrapper>
					<TableContainer component={Paper}>
						{this.state.loading ? <LoaderContainer>Loading...</LoaderContainer> :
							<Table>
								<TableHead>
									<TableRow>
										<TableCell style={{ fontWeight: 'bold', color: 'black' }}>
											SL.No
										</TableCell>
										<TableCell style={{ fontWeight: 'bold', color: 'black' }}>
											Relationship Status
										</TableCell>
										<TableCell style={{ fontWeight: 'bold', color: 'black' }}>
											Age{' '}
										</TableCell>
										<TableCell style={{ fontWeight: 'bold', color: 'black' }}>
											Gender Diversity
										</TableCell>
										<TableCell style={{ fontWeight: 'bold', color: 'black' }}>
											First Name
										</TableCell>
										<TableCell style={{ fontWeight: 'bold', color: 'black' }}>
											Last Name
										</TableCell>
										<TableCell style={{ fontWeight: 'bold', color: 'black' }}>
											No. of Years Associated With Company
										</TableCell>
										<TableCell style={{ fontWeight: 'bold', color: 'black' }}>
											Level of Qualification
										</TableCell>
										<TableCell style={{ fontWeight: 'bold', color: 'black' }}>
											Level of Experience
										</TableCell>
										<TableCell style={{ fontWeight: 'bold', color: 'black' }}>
											Industries Experience
										</TableCell>
										<TableCell style={{ fontWeight: 'bold', color: 'black' }}>
											No. of Other Directorships Held in Other Companies
										</TableCell>
										<TableCell style={{ fontWeight: 'bold', color: 'black' }}>
											No. of Other Directorships in Related Companies
										</TableCell>
										<TableCell style={{ fontWeight: 'bold', color: 'black' }}>
											Family Member/ Non-Family
										</TableCell>
										<TableCell style={{ fontWeight: 'bold', color: 'black' }}>
											Board Committee
										</TableCell>
										<TableCell style={{ fontWeight: 'bold', color: 'black' }}>
											Board
										</TableCell>
										<TableCell style={{ fontWeight: 'bold', color: 'black' }}>
											Chairman of Committee
										</TableCell>
										<TableCell style={{ fontWeight: 'bold', color: 'black' }}>
											Profile Pic Upload
										</TableCell>
										<TableCell style={{ fontWeight: 'bold', color: 'black' }}>
											Linkedin Profile / Reference Links
										</TableCell>
										<TableCell></TableCell>
										<TableCell></TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{this.state.corporateGovernanceData?.length > 0 ?
										this.state.corporateGovernanceData?.map((row, index) => (
											<>
												<TableRow>
													<TableCell>{10 * (this.state.governance_current_page - 1) + (index + 1)}</TableCell>
													<TableCell>
														{row?.attributes?.relationship_status}
													</TableCell>
													<TableCell>{row?.attributes?.age}</TableCell>
													<TableCell>{row?.attributes?.gender == "male" ? "Male" : row?.attributes?.gender == "female" && "Female"}</TableCell>
													<TableCell>{row?.attributes?.first_name}</TableCell>
													<TableCell>{row?.attributes?.last_name}</TableCell>
													<TableCell>
														{row?.attributes?.no_of_years_associated}
													</TableCell>
													<TableCell>{row?.attributes?.qualification}</TableCell>
													<TableCell>{row?.attributes?.total_experience}</TableCell>
													<TableCell>
														{row?.attributes?.industry_experience}
													</TableCell>
													<TableCell>
														{row?.attributes?.no_of_directorships}
													</TableCell>
													<TableCell>
														{row?.attributes?.no_of_other_directorships}
													</TableCell>
													<TableCell>{row?.attributes?.member_type == "family member" ? "Family Member" : "Non-Family Member"}</TableCell>
													<TableCell>{row?.attributes?.board_committee}</TableCell>
													<TableCell>
														{row?.attributes?.board}
													</TableCell>
													<TableCell>
														{row?.attributes?.chairman_of_committee[0]}
													</TableCell>
													<TableCell><Avatar style={{ width: 34, height: 34, margin: "auto" }} src={row?.attributes?.profile_pic} /></TableCell>
													<TableCell>{row?.attributes?.links}</TableCell>
													<TableCell className='table-icons'>

														<BtnImg
															id='ShowCorporateGovernance'
															src={eye}
															style={{ cursor: 'pointer' }}
															onClick={() => this.handleShowCorporateGovernance(row)}
														/>


														<BtnImg
															id='EditCorporateGovernance'
															src={editImg}
															style={{ cursor: 'pointer' }}
															onClick={() => this.handleEditCorporateGovernance(row)}
														/>


														<BtnImg
															id='DeleteCorporateGovernance'
															src={deleteImg}
															style={{ cursor: 'pointer' }}
															onClick={() => this.handleDeleteCorporateGovernance(row)}
														/>

													</TableCell>
												</TableRow>
												{(this.state.tableRowId == row?.id && this.state.isEditGovernance && this.state.corporateForm) && <CorporateGovernanceModal row={row} deleteCVDocumentField={this.deleteCVDocumentField} handleDeleteIndustry={this.handleDeleteIndustry} setIndustryExperience={this.handleChangeIndustryValues} industryExperience={this.state.industryExperience} createFormSchema={this.createFormSchema} imageUrl={this.state.imageUrl} removeProfilePic={this.removeProfilePic} cv={this.state.cv} handleCvFileUpload={this.handleCvFileUpload} showCorporateForm={this.state.showcorporateForm} handleEditCorporateGovernanceData={this.handleEditCorporateGovernanceData} defaultValue={row} profilePic={this.state.profilePic} setProfilePic={this.setProfilePic} isEditGovernance={this.state.isEditGovernance} handleAddCorporateGovernanceData={this.handleAddCorporateGovernanceData} onCloseCorporateModal={this.onCloseCorporateModal} corporateForm={this.state.corporateForm} />}
												{(this.state.tableRowId == row?.id && this.state.deleteCorporateGovernanace) && <UserModal deleteStakeHolder={false} Delete={this.handleDeleteGovernance} showModal={this.state.deleteCorporateGovernanace} handleModal={this.handleDeleteGovernanceModal} value={row} />}
											</>
										)) : <TableRow>
											<TableCell style={{ textAlign: 'center' }} colSpan={12}>No Data Found</TableCell>
										</TableRow>}
								</TableBody>
							</Table>
						}
					</TableContainer>
				</TableWrapper>
				<Pagin>
					{window.screen.width > 950 ?
						<Pagination disabled={this.state.governance_total_pages <= 1} defaultPage={1} color="primary" variant="outlined" count={this.state.governance_total_pages} page={this.state.governance_current_page} onChange={this.handleGovernancePageChange} />
						:
						<Pagination disabled={this.state.governance_total_pages <= 1} defaultPage={1} color="primary" size="small" count={this.state.governance_total_pages} page={this.state.governance_current_page} onChange={this.handleGovernancePageChange} />
					}
				</Pagin>
				{this.showBoardOperationFormData()}
				<OverlayLoader loading={this.state.isLoading} />
			</div>
		)
	}

  	// Customizable Area End

	render() {
		// Customizable Area Start
		const { navigation } = this.props;
		const path = this?.context?.user_type == "licensed_user" ? 'Corporatepermission' : 'ESGJourney'
  		// Customizable Area End
		return (
			// Customizable Area Start
			<DashboardLayout isUserSurvey={false} navigation={navigation}>
				<div style={{ display: "flex", alignItems: "center" }} >
					<span id='goBack' onClick={() => this.props.navigation.navigate(path)} style={{ margin: "40px 0px 15px 12px", cursor: "pointer" }} >ESG Journey</span>
					<div id='goBackToEsg' onClick={() => this.props.navigation.navigate(path)} style={{ margin: "40px 0px 15px 12px", }}  >
						<ArrowBackIosIcon />
					</div>
					<CoinsHead>{this.state.subscribtionPlan ? 'STAKEHOLDER PROFILE FORM' : 'CORPORATE GOVERNANCE'}</CoinsHead>
				</div>
				<PageWrapper>
					<CenterRow>
						<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
							<RoundedButtonBase>
								<PlanCardButton
									id='stakeholderTab'
									onClick={this.chooseSubscribtionPlan}
									style={this.state.activeChooseButton ? { backgroundColor: "#00A100", color: "white" } : { backgroundColor: "white" }}
								>STAKEHOLDER PROFILE FORM</PlanCardButton>
								<HistoryCardButton
									id='corporateGovernanceTab'
									onClick={this.chooseSubscribtionHistory}
									style={this.state.activeSubscribtionHistoryButton ? { backgroundColor: "#00A100", color: "white" } : { backgroundColor: "white" }}
								>CORPORATE GOVERNANCE</HistoryCardButton>
							</RoundedButtonBase>
							<RoundedButton
								id='addNew'
								onClick={() => this.state.subscribtionPlan ? this.handleAddNewProfile(navigation) : this.handleAddCorporateGovernance()}
								variant="contained"
								disableElevation
								disabled={this.state?.disabled}
								startIcon={<img src={iconAdd} width={30} style={{ marginLeft: '-5px' }} />}>
								Add New
							</RoundedButton>
						</div>
						{
							this.state.subscribtionPlan ?
								(this.showStakeHolderTable())

								: (
									this.showCorporateGovernanceTable()
								)
						}
						{this.state.addNewProfile && (
							<AddStakeHolderForm paginateStackholder={this.paginateStackholder} stackholderPagination={this.state.stackholderPagination} handleStackholderSearchQuery={this.handleStackholderSearchQuery} handleApproversClose={this.handleApproversClose} selectedStakeHolderManager={this.state.selectedStakeHolderManager} stakeholderManagerData={this.state.stakeholderManagerData} engagementData={this.state.engagementData} handleShowEngagementField={this.handleShowEngagementField} showEngagementApproachOthersField={this.state.showEngagementApproachOthersField} showFrequencyTimelineOthersField={this.state.showFrequencyTimelineOthersField} isEditStakeHolder={false} handleEngagementApproach={this.handleEngagementApproach} EngagementApproachState={this.state.EngagementApproach} FrequencyTimelineState={this.state.FrequencyTimeline} handleFrequencyTimeline={this.handleFrequencyTimeline} handleEnagegementTools={this.handleEnagegementTools} addStakeHolderProfileForms={this.addStakeHolderProfileForms} handleStackHolderManager={this.handleStackHolderManager} handleKeyStackHolder={this.handleKeyStackHolder} KeyStackHolder={this.state.KeyStackHolder} onCloseStakeHolderModal={this.onCloseStackHolderModal} open={this.state.addNewProfile} />
						)}
						{this.state.corporateForm && !this.state.isEditGovernance && <CorporateGovernanceModal deleteCVDocumentField={this.deleteCVDocumentField} handleDeleteIndustry={this.handleDeleteIndustry} setIndustryExperience={this.handleChangeIndustryValues} industryExperience={this.state.industryExperience} createFormSchema={this.createFormSchema} imageUrl={this.state.imageUrl} removeProfilePic={this.removeProfilePic} cv={this.state.cv} handleCvFileUpload={this.handleCvFileUpload} showCorporateForm={this.state.showcorporateForm} handleEditCorporateGovernanceData={this.handleEditCorporateGovernanceData} profilePic={this.state.profilePic} setProfilePic={this.setProfilePic} isEditGovernance={this.state.isEditGovernance} handleAddCorporateGovernanceData={this.handleAddCorporateGovernanceData} onCloseCorporateModal={this.onCloseCorporateModal} corporateForm={this.state.corporateForm} />}
					</CenterRow>
				</PageWrapper>
				<OverlayLoader loading={this.state.isLoading} />
			</DashboardLayout>
			// Customizable Area End
		);
	}
}

// Customizable Area Start
interface UserModalInterface {
	showModal: boolean
	handleModal: any
	Delete: any
	value: any
	deleteStakeHolder: boolean
}

export const UserModal = ({ showModal, handleModal, Delete, deleteStakeHolder, value }: UserModalInterface) => {
	return (
		<Modal
			open={showModal}
			onClose={() => handleModal(value)}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={showModal}>
				<Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
					<Box style={{ backgroundColor: '#fff', position: 'relative', padding: '30px 30px', borderRadius: '8px', textAlign: 'center', maxWidth: '500px', margin: '0 auto' }}>
						<Box id='closeDelteModal' onClick={() => handleModal(value)} style={{ position: 'absolute', right: '15px', top: '15px', color: '#fff', width: '20px', height: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', borderRadius: '8px' }}>
							<CloseCircleIcon />
						</Box>
						<Typography variant="h6" component="h2">
							<img src={warningIcon} alt='waning' style={{ width: "50px", marginBottom: "24px" }} />
						</Typography>
						<Typography >
							{deleteStakeHolder ? 'Are you sure you want to delete Stakeholder profile ?' : 'Are you sure you want to delete Corporate Governance profile ?'}
						</Typography>
						<Box style={{ display: 'flex', marginTop: '20px', alignItems: 'center', justifyContent: 'center', gap: '20px' }}>
							<button
								id='DeleteData'
								onClick={(e) => Delete(value?.id)}
								style={{ backgroundColor: 'green', color: '#fff', borderRadius: '5px', padding: '5px 20px', border: 'none', cursor: 'pointer' }}>Yes</button>
							<button id='doNotDeleteData' onClick={() => handleModal(value)} style={{ backgroundColor: 'red', color: '#fff', borderRadius: '5px', padding: '5px 20px', cursor: 'pointer', border: 'none' }}>No</button>
						</Box>
					</Box>
				</Box>
			</Fade>
		</Modal>
	)
}

interface CorporateGovernanceModalInterface {
	corporateForm: boolean,
	createFormSchema: any;
	onCloseCorporateModal: any,
	handleAddCorporateGovernanceData: any,
	defaultValue?: any,
	isEditGovernance: boolean,
	profilePic: any,
	imageUrl: string | null,
	setProfilePic: any,
	handleEditCorporateGovernanceData: any
	showCorporateForm: boolean,
	handleCvFileUpload: any,
	cv: any,
	removeProfilePic: any,
	industryExperience: any,
	setIndustryExperience: any,
	handleDeleteIndustry: any,
	deleteCVDocumentField: any,
	row?: any
}

export const CorporateGovernanceModal = ({ row, deleteCVDocumentField, handleDeleteIndustry, setIndustryExperience, industryExperience, createFormSchema, removeProfilePic, cv, imageUrl, handleCvFileUpload, showCorporateForm, profilePic, handleEditCorporateGovernanceData, setProfilePic, defaultValue, isEditGovernance, corporateForm, onCloseCorporateModal, handleAddCorporateGovernanceData }: CorporateGovernanceModalInterface) => {
	const inputRef = React.useRef<HTMLInputElement>(null)
	const refInput = React.useRef<HTMLInputElement>(null)
	const uploadPicture = () => refInput?.current && refInput?.current?.click()
	const removePicture = () => {
		removeProfilePic()
	}
	const showTitle = () => {
		if (isEditGovernance && !showCorporateForm) {
			return <b>EDIT USER</b>
		}
		else if (isEditGovernance && showCorporateForm) {
			return <b>VIEW USER</b>
		}
		else {
			return <b>ADD USER</b>
		}
	}

	const genderIntialValue = () => {
		return isEditGovernance ? defaultValue?.attributes?.gender : 'male'
	}

	const getId = () => {
		return isEditGovernance ? defaultValue?.id : ''
	}

	const showIcon = (value: any) => {
		if (value?.name?.includes('.pdf')) {
			return <img src={pdf} alt={''} style={{ width: '24px', paddingLeft: '15px', paddingRight: '15px' }} />
		}
		else if (value?.name?.includes('.xlsx')) {
			return <img src={ExcelIcon} alt={''} style={{ width: '24px', paddingLeft: '15px', paddingRight: '15px' }} />
		}
		else if (value?.name?.includes('.doc')) {
			return <img src={docIcon} alt={''} style={{ width: '24px', paddingLeft: '15px', paddingRight: '15px' }} />
		}
		else {
			return <img src={imageIcon} alt={''} style={{ width: '24px', paddingLeft: '15px', paddingRight: '15px' }} />
		}
	}

	return (
		<ModalWrapper
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			style={{
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				overflow: 'scroll',

			}}
			open={corporateForm}
			onClose={(_, reason) => {
				if (reason !== "backdropClick") {
					onCloseCorporateModal();
				}
			}}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Box
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					flexDirection: 'column',
					background: '#fff',
					borderRadius: 8,
					width: '924px',
					position: "relative",
					margin: '10px',
				}}>
				<Grid container spacing={0}>
					<Box style={{ paddingLeft: 30, paddingRight: 30, paddingTop: 20, width: '100%' }}>
						<ModalTitle style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 18, borderColor: '#e6e6e6', }} item xs={12} >
							<Typography style={{ paddingTop: '6px', fontWeight: 'bold' }}>
								{showTitle()}
							</Typography>
							<CloseIconButton onClick={onCloseCorporateModal}>
								<CloseIcon style={{}} />
							</CloseIconButton>
						</ModalTitle>
					</Box>
					<ModelBody item xs={12} style={{ paddingRight: '8px' }}>
						<Grid item xs={12} style={{ width: "100%", }}>
							<Formik
								initialValues={{
									id: getId(),
									gender: genderIntialValue(),
									relationship_status: isEditGovernance ? defaultValue?.attributes?.relationship_status : '',
									age: isEditGovernance ? defaultValue?.attributes?.age : '',
									first_name: isEditGovernance ? defaultValue?.attributes?.first_name : '',
									last_name: isEditGovernance ? defaultValue?.attributes?.last_name : '',
									total_experience: isEditGovernance ? defaultValue?.attributes?.total_experience : '',
									no_of_years_associated: isEditGovernance ? defaultValue?.attributes?.no_of_years_associated : '',
									qualification: isEditGovernance ? defaultValue?.attributes?.qualification : '',
									industry_experience: isEditGovernance ? industryExperience : [],
									no_of_other_directorships: isEditGovernance ? defaultValue?.attributes?.no_of_other_directorships : '',
									no_of_directorships: isEditGovernance ? defaultValue?.attributes?.no_of_directorships : '',
									member_type: isEditGovernance ? defaultValue?.attributes?.member_type : 'family member',
									links: isEditGovernance ? defaultValue?.attributes?.links : '',
									board_committee: isEditGovernance ? defaultValue?.attributes?.board_committee : '',
									chairman_of_committee: isEditGovernance ? defaultValue?.attributes?.chairman_of_committee : '',
									profile_pic: [],
									cv: cv || []
								}}
								//@ts-ignore
								validationSchema={createFormSchema}
								validate={values => {
									const errors: any = {}
									if (profilePic?.length <= 0 && !isEditGovernance) {
										errors.profile_pic = 'Required';
									}
									if (imageUrl == null && !showCorporateForm && isEditGovernance) {
										errors.profile_pic = 'Required';
									}
									if (industryExperience?.length <= 0) {
										errors.industry_experience = "Required"
									}
									return errors
								}}
								onSubmit={(values) => {
									isEditGovernance ? handleEditCorporateGovernanceData(values, defaultValue?.id) : handleAddCorporateGovernanceData(values)
								}}
							>
								{({
									values,
									handleChange,
									handleSubmit,
									errors,
									setFieldValue
								}) => {
									return (
										<RightFormWrapper style={{ paddingRight: '25px' }} translate="no" onSubmit={handleSubmit}>
											<InputRow
												label={
													<Flex>
														Relationship Status<sup>*</sup>
														<Typography color="textSecondary" />
													</Flex>
												}
												input={
													<MSelect MenuProps={{
														anchorOrigin: {
															vertical: "bottom",
															horizontal: "left",

														},
														transformOrigin: {
															vertical: "top",
															horizontal: "left"
														},
														getContentAnchorEl: null
													}} disabled={showCorporateForm} defaultValue={''} name="relationship_status" style={{ paddingLeft: '10px' }}>
														<MenuItem disabled value={''}>Select</MenuItem>
														<MenuItem value={'Executive'}>Executive</MenuItem>
														<MenuItem value={'Non Executive, Independent'}>Non Executive, Independent</MenuItem>
														<MenuItem value={'Lead Independent Director'}>Lead Independent Director</MenuItem>
													</MSelect>
												}
											/>
											<InputRow
												label={
													<Flex>
														Age<sup>*</sup>
														<Typography color="textSecondary" />
													</Flex>
												}
												input={
													<MInput
														disabled={showCorporateForm}
														variant="outlined"
														fullWidth
														name="age"
														onChange={handleChange}
														value={values?.age}
														style={{ marginBottom: "0px" }}
													/>
												}
											/>
											<InputRow label={
												<Flex>
													Gender<sup>*</sup>
													<Typography color="textSecondary" />
												</Flex>
											} input={
												<Field
													disabled={showCorporateForm}
													render={({ field }: any) => <><RadioGroup {...field} aria-label="quiz"
														name="gender"
														value={values?.gender}
														style={{ flexDirection: 'row' }}
													>
														<img style={{ width: '27px', right: '-9px', position: 'relative', height: '27px', paddingTop: '5px' }} src={male} />
														<FormControlLabel
															disabled={showCorporateForm}
															value="male"
															label="Male"
															labelPlacement="start"
															control={<Radio style={{ color: '#000' }} />}
														/>

														<img style={{ width: '27px', right: '-9px', position: 'relative', height: '27px', paddingTop: '5px' }} src={female} />
														<FormControlLabel
															disabled={showCorporateForm}
															value="female"
															labelPlacement="start"
															control={<Radio style={{ color: '#000' }} />}
															label="Female"
														/>
														<FormControlLabel
															disabled={showCorporateForm}
															value="others"
															labelPlacement="start"
															control={<Radio style={{ color: '#000' }} />}
															label="Prefer not to say"
														/>
													</RadioGroup>
													</>
													}
												/>
											} />
											<Box
												style={{
													width: "100%",
													display: "flex",
													flexDirection: "row",
													gap: "30px",
												}}
											>
												<Box style={{ width: "50%" }}>
													<InputRow
														label={
															<Flex>
																First Name<sup>*</sup>
																<Typography color="textSecondary" />
															</Flex>
														}
														input={
															<MInput
																disabled={showCorporateForm}
																variant="outlined"
																fullWidth
																name="first_name"
																onChange={handleChange}
																value={values?.first_name}
																style={{ marginBottom: "0px" }}
															/>
														}
													/>
												</Box>
												<Box style={{ width: "50%" }}>
													<InputRow
														label={
															<Flex>
																Last Name<sup>*</sup>
																<Typography color="textSecondary" />
															</Flex>
														}
														input={
															<MInput
																disabled={showCorporateForm}
																variant="outlined"
																fullWidth
																name="last_name"
																onChange={handleChange}
																value={values?.last_name}
																style={{ marginBottom: "0px" }}
															/>
														}
													/>
												</Box>
											</Box>
											<InputRow
												label={
													<Flex>
														No. of years associated<sup>*</sup>
														<Typography color="textSecondary" />
													</Flex>
												}
												input={
													<MInput
														disabled={showCorporateForm}
														variant="outlined"
														fullWidth
														name="no_of_years_associated"
														value={values?.no_of_years_associated}
														onChange={handleChange}
														style={{ marginBottom: "0px" }}
													/>
												}
											/>
											{!showCorporateForm ?
												<InputRow data-test-id='cvDocument' label={"Document Upload"}
													input={<UploadButton
														data-test-id='cvDocumentId'
														attchementType={"cv"}
														deleteBoardOperationFiles={deleteCVDocumentField}
														isCorporate={true}
														boardOperationId={values.id}
														multiple={true}
														maxSize={20 * 1024 * 1024}
														maxSizeError={"Cannot upload document more than 2Mb"}
														name="cv"
														label="Document Upload"
														setFileInState={handleCvFileUpload} />}
												/> :
												<InputRow
													data-test-id='cvUpload'
													label={
														<Flex>
															Document Upload
															<Typography color="textSecondary" />
														</Flex>
													} input={
														<>
															<RoundedButtonUpload startIcon={<img width="25px" src={ic_upload_green} />}>Document Upload</RoundedButtonUpload>
															<div style={{ padding: '10px 0' }}>
																<div style={{ position: 'relative', width: 'max-content', display: 'flex' }}>
																	{cv?.length > 0 && cv?.map((value: any) => {
																		return (
																			<>
																				<div style={{ color: "#808080" }}>{value?.name}</div>
																				<div>
																					{value?.name !== "" && <>
																						{showIcon(value)}
																					</>
																					}
																				</div>
																			</>
																		)
																	})}
																</div>
															</div>
														</>
													} />
											}
											<InputRow
												label={
													<Flex>
														Qualification<sup>*</sup>
														<Typography color="textSecondary" />
													</Flex>
												}
												input={
													<MSelect MenuProps={{
														anchorOrigin: {
															vertical: "bottom",
															horizontal: "left",

														},
														transformOrigin: {
															vertical: "top",
															horizontal: "left"
														},
														getContentAnchorEl: null
													}} disabled={showCorporateForm} defaultValue={''} name="qualification" style={{ paddingLeft: '10px' }}>
														<MenuItem disabled value={''}>Select</MenuItem>
														<MenuItem value={'Master of Engineering'}>Master of Engineering</MenuItem>
														<MenuItem value={'Bachelors Degree in Economics'}>Bachelor's Degree in Economics</MenuItem>
														<MenuItem value={'MBA'}>MBA</MenuItem>
														<MenuItem value={'PHD'}>PHD</MenuItem>
														<MenuItem value={'Bachleors'}>Bachelor's</MenuItem>
													</MSelect>
												}
											/>
											<InputRow
												label={
													<Flex>
														Total Experience<sup>*</sup>
														<Typography color="textSecondary" />
													</Flex>
												}
												input={
													<MInput
														disabled={showCorporateForm}
														variant="outlined"
														fullWidth
														value={values?.total_experience}
														onChange={handleChange}
														name="total_experience"
														style={{ marginBottom: "0px" }}
													/>
												}
											/>

											<FieldSetWrapper xs={12} >
												<FieldLabel
												>
													Industry Experience
												</FieldLabel>
												<Box style={{
													margin: "10px 12px",
													marginRight: '0px'
												}}>

													<MSelect
														id="industry_experience"
														name="industry_experience"
														multiple
														disabled={showCorporateForm}
														displayEmpty
														fullWidth
														onChange={(e) => setIndustryExperience(e, setFieldValue)}
														renderValue={(selected) => (
															<Box style={{ display: "flex", }}>
																<Typography component={'span'} style={{ fontSize: "14px", margin: "0px", padding: "0" }}>
																	Select
																</Typography>
															</Box>)
														}

														MenuProps={{
															anchorOrigin: {
																vertical: "bottom",
																horizontal: "left",

															},
															transformOrigin: {
																vertical: "top",
																horizontal: "left"
															},
															getContentAnchorEl: null
														}}
													>
														<MenuItem style={{
															height: "50px",
															padding: "20px 20px",
														}} key="E" value="Engineering">
															<Checkbox
																icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
																checkedIcon={<CheckBoxIcon fontSize="small" style={{ color: "#1ea60a" }} />}
																checked={values.industry_experience.indexOf("Engineering") > -1}
															/>
															<ListItemText disableTypography primary={
																<div style={{ display: "flex", justifyContent: "space-between" }}>
																	<Typography>
																		Engineering
																	</Typography>
																	<Typography>

																	</Typography>
																</div>} />
														</MenuItem>

														<MenuItem style={{
															height: "50px",
															padding: "20px 20px",
														}} key="A" value="Automobile and engineering">
															<Checkbox
																icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
																checkedIcon={<CheckBoxIcon fontSize="small" style={{ color: "#1ea60a" }} />}
																checked={values.industry_experience.indexOf("Automobile and engineering") > -1}
															/>
															<ListItemText disableTypography primary={
																<div style={{ display: "flex", justifyContent: "space-between" }}>
																	<Typography style={{ justifyContent: "start", marginRight: "45px" }}>
																		Automobile and Engineering
																	</Typography>
																	<Typography>

																	</Typography>

																</div>} />
														</MenuItem>
														<MenuItem style={{
															height: "50px",
															padding: "20px 20px",
														}} key="C" value="Consulting">
															<Checkbox
																icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
																checkedIcon={<CheckBoxIcon fontSize="small" style={{ color: "#1ea60a" }} />}
																checked={values.industry_experience.indexOf("Consulting") > -1}
															/>
															<ListItemText disableTypography primary={
																<div style={{ display: "flex", justifyContent: "space-between" }}>
																	<Typography>
																		Consulting
																	</Typography>
																	<Typography>

																	</Typography>
																</div>} />
														</MenuItem>

														<MenuItem style={{
															height: "50px",
															padding: "20px 20px",
														}} key="R" value="Retailing">
															<Checkbox
																icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
																checkedIcon={<CheckBoxIcon fontSize="small" style={{ color: "#1ea60a" }} />}
																checked={values.industry_experience.indexOf("Retailing") > -1}
															/>
															<ListItemText disableTypography primary={
																<div style={{ display: "flex", justifyContent: "space-between" }}>
																	<Typography>
																		Retailing
																	</Typography>
																	<Typography>

																	</Typography>
																</div>} />
														</MenuItem>

														<MenuItem style={{
															height: "50px",
															padding: "20px 20px",
														}} key="S" value="Supply chain">
															<Checkbox
																icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
																checkedIcon={<CheckBoxIcon fontSize="small" style={{ color: "#1ea60a" }} />}
																checked={values.industry_experience.indexOf("Supply chain") > -1}
															/>
															<ListItemText disableTypography primary={
																<div style={{ display: "flex", justifyContent: "space-between" }}>
																	<Typography>
																		Supply Chain
																	</Typography>
																	<Typography>

																	</Typography>
																</div>} />
														</MenuItem>

														<MenuItem style={{
															height: "50px",
															padding: "20px 20px",
														}} key="ET" value="Economist">
															<Checkbox
																icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
																checkedIcon={<CheckBoxIcon fontSize="small" style={{ color: "#1ea60a" }} />}
																checked={values.industry_experience.indexOf("Economist") > -1}
															/>
															<ListItemText disableTypography primary={
																<div style={{ display: "flex", justifyContent: "space-between" }}>
																	<Typography>
																		Economist
																	</Typography>
																	<Typography>

																	</Typography>
																</div>} />
														</MenuItem>
													</MSelect>
												</Box>
												{values.industry_experience?.map((prefrence: any) => (
													<Chip
														key={prefrence}
														label={prefrence}
														onDelete={!showCorporateForm && handleDeleteIndustry(prefrence, setFieldValue)}
														style={{ margin: "5px", marginLeft: '10px' }}
													/>
												))}
											</FieldSetWrapper>
											<InputRow
												label={
													<Flex>
														Number Of Other Directorships Held In Other
														Companies
														<sup>*</sup>
														<Typography color="textSecondary" />
													</Flex>
												}
												input={
													<MInput
														disabled={showCorporateForm}
														variant="outlined"
														fullWidth
														onChange={handleChange}
														value={values?.no_of_other_directorships}
														name="no_of_other_directorships"
														style={{ marginBottom: "0px" }}
													/>
												}
											/>
											<InputRow
												label={
													<Flex>
														Number of Other Directorships Related Companies
														<sup>*</sup>
														<Typography color="textSecondary" />
													</Flex>
												}
												input={
													<MInput
														disabled={showCorporateForm}
														variant="outlined"
														fullWidth
														value={values?.no_of_directorships}
														onChange={handleChange}
														name="no_of_directorships"
														style={{ marginBottom: "0px" }}
													/>
												}
											/>
											<InputRow label={
												<Flex>
													Family Member/Non-Family Member<sup>*</sup>
													<Typography color="textSecondary" />
												</Flex>
											} input={
												<Field
													render={({ field }: any) => <><RadioGroup {...field} aria-label="quiz"
														name="member_type"
														value={values?.member_type}
														style={{ flexDirection: 'row' }}
													>
														<FormControlLabel
															disabled={showCorporateForm}
															value="family member"
															control={<Radio style={{ color: '#000' }} />}
															label="Family Member"
														/>
														<FormControlLabel
															disabled={showCorporateForm}
															value="non-family member"
															control={<Radio style={{ color: '#000' }} />}
															label="Non-Family Member"
														/>
													</RadioGroup>
													</>
													}
												/>
											} />
											<InputRow
												label={
													<Flex>
														Board Committee
														<sup>*</sup>
														<Typography color="textSecondary" />
													</Flex>
												}
												input={
													<MSelect MenuProps={{
														anchorOrigin: {
															vertical: "bottom",
															horizontal: "left",

														},
														transformOrigin: {
															vertical: "top",
															horizontal: "left"
														},
														getContentAnchorEl: null
													}} disabled={showCorporateForm} defaultValue={''} name="board_committee" style={{ paddingLeft: '10px' }}>
														<MenuItem disabled value={''}>Select</MenuItem>
														<MenuItem value={'Audit Committee-Approver'}>Audit Committee-Approver</MenuItem>
														<MenuItem value={'Nomination Committee-Approver'}>Nomination Committee-Approver</MenuItem>
														<MenuItem value={'Remuneration Committee-Approver'}>Remuneration Committee-Approver</MenuItem>
														<MenuItem value={'Sustainability Committee-Approver'}>Sustainability Committee-Approver</MenuItem>
														<MenuItem value={'Risk Management Committee-Approver'}>Risk Management Committee-Approver</MenuItem>
														<MenuItem value={'Shareholder Grievance Committee-Approver'}>Shareholder Grievance Committee-Approver</MenuItem>
														<MenuItem value={'Audit Committee-Member'}>Audit Committee-Member</MenuItem>
														<MenuItem value={'Nomination Committee-Member'}>Nomination Committee-Member</MenuItem>
														<MenuItem value={'Remuneration Committee-Member'}>Remuneration Committee-Member</MenuItem>
														<MenuItem value={'Sustainability Committee-Member'}>Sustainability Committee-Member</MenuItem>
														<MenuItem value={'Risk Management Committee-Member'}>Risk Management Committee-Member</MenuItem>
														<MenuItem value={'Shareholder Grievance Committee-Member'}>Shareholder Grievance Committee-Member</MenuItem>
													</MSelect>
												}
											/>
											<InputRow
												label={
													<Flex>
														Chairman of Committee
														<sup>*</sup>
														<Typography color="textSecondary" />
													</Flex>
												}
												input={
													<MSelect MenuProps={{
														anchorOrigin: {
															vertical: "bottom",
															horizontal: "left",

														},
														transformOrigin: {
															vertical: "top",
															horizontal: "left"
														},
														getContentAnchorEl: null
													}} disabled={showCorporateForm} defaultValue={''} name="chairman_of_committee" style={{ paddingLeft: '10px' }}>
														<MenuItem disabled value={''}>Select</MenuItem>
														<MenuItem value={'Audit Committee-Approver'}>Audit Committee-Approver</MenuItem>
														<MenuItem value={'Nomination Committee-Approver'}>Nomination Committee-Approver</MenuItem>
														<MenuItem value={'Remuneration Committee-Approver'}>Remuneration Committee-Approver</MenuItem>
														<MenuItem value={'Sustainability Committee-Approver'}>Sustainability Committee-Approver</MenuItem>
														<MenuItem value={'Risk Management Committee-Approver'}>Risk Management Committee-Approver</MenuItem>
														<MenuItem value={'Shareholder Grievance Committee-Approver'}>Shareholder Grievance Committee-Approver</MenuItem>
													</MSelect>
												}
											/>
											{!showCorporateForm &&
												<InputRow
													data-test-id='uploadProfilePic'
													label={
														<Flex>
															Profile Pic Upload <sup>*</sup>
															<Typography color="textSecondary" />
														</Flex>
													}
													input={<><label>
														<RoundedButtonUpload data-test-id='uploadPic' onClick={uploadPicture} startIcon={<img width="25px" src={ic_upload_green} />}>Upload Profile Pic</RoundedButtonUpload>
														<input
															type="file"
															accept="image/*"
															onChange={setProfilePic}
															ref={refInput}
															name="profile_pic"
															style={{ display: 'none' }}
														/>
														{errors.profile_pic && <Typography color="error" style={{ fontSize: "12px" }}>Required</Typography>}
													</label>
														<div style={{ padding: '10px 0', bottom: '24px' }}>
															<div>
																{imageUrl !== "" && imageUrl !== null && <div style={{ display: "grid", position: "relative", width: "118px", }}>
																	<CloseCircleIcon data-test-id='removePic' onClick={() => removePicture()} style={{ cursor: 'pointer', right: 0, position: 'absolute' }} />
																	<><img src={imageUrl} alt={''} style={{ width: '95px', position: "relative", top: "20px" }} /></>
																</div>}
															</div>
														</div>
													</>
													}
												/>}
											{(!showCorporateForm && isEditGovernance && imageUrl == "") &&
												<div style={{ bottom: '24px', position: "relative" }}>
													<div>
														{imageUrl == "" && <div style={{ display: "grid", position: "relative", width: "118px", }}>
															<CloseCircleIcon onClick={() => removePicture()} style={{ cursor: 'pointer', right: 0, position: 'absolute' }} />
															<><img src={defaultValue.attributes.profile_pic} alt={''} style={{ width: '95px', position: "relative", top: "20px" }} /></>
														</div>}
													</div>
												</div>}
											{(showCorporateForm && isEditGovernance) && <InputRow
												label={
													<Flex>
														Profile Pic
														<sup>*</sup>
														<Typography color="textSecondary" />
													</Flex>
												}
												input={
													<div style={{ padding: '10px 0' }}>
														<div>
															{(imageUrl == "" || imageUrl == null) && <div style={{ display: "grid", position: "relative", width: "118px", }}>
																<><img src={defaultValue.attributes.profile_pic} alt={''} style={{ width: '95px', position: "relative", top: "20px" }} /></>
															</div>}
														</div>
													</div>}
											/>
											}
											<InputRow
												label={
													<Flex>
														Linkedin Profile / Reference Links
														<Typography color="textSecondary" />
													</Flex>
												}
												input={
													<MInput
														disabled={showCorporateForm}
														variant="outlined"
														fullWidth
														value={values?.links}
														onChange={handleChange}
														name="links"
														style={{ marginBottom: "0px" }}
													/>
												}
											/>
											<Box
												style={{
													display: "flex",
													flexDirection: "row",
													justifyContent: "flex-end",
													marginTop: "20px",
													gap: "20px",
												}}
											>
												<Button
													style={{
														backgroundColor: "rgb(205 205 205)",
														paddingTop: "8px",
														minWidth: "110px",
														color: "#fff",
													}}
													onClick={onCloseCorporateModal}
												>
													CANCEL
												</Button>
												<BtnSave>
													<Button
														disabled={showCorporateForm}
														type="submit"
														style={{
															backgroundColor: "#009B07",
															paddingTop: "8px",
															minWidth: "110px",
														}}
													>
														SAVE
													</Button>
												</BtnSave>
											</Box>
										</RightFormWrapper>
									)
								}}
							</Formik>
						</Grid>
					</ModelBody>
				</Grid>
			</Box>
		</ModalWrapper >
	)
}

interface AddStakeHolderFormInterface {
	addStakeHolderProfileForms: any,
	onCloseStakeHolderModal: any,
	KeyStackHolder: any,
	FrequencyTimelineState?: any,
	EngagementApproachState?: any,
	handleKeyStackHolder: any,
	handleStackHolderManager: any,
	handleEngagementApproach: any,
	handleFrequencyTimeline: any,
	handleEnagegementTools: any,
	open: boolean,
	isEditStakeHolder: boolean,
	data?: any,
	showFrequencyTimelineOthersField: boolean,
	showEngagementApproachOthersField: boolean,
	handleShowEngagementField: any,
	engagementData: any,
	stakeholderManagerData: any[],
	selectedStakeHolderManager: any,
	handleApproversClose: any,
	handleStackholderSearchQuery: any,
	paginateStackholder: any,
	stackholderPagination: any
}

export const AddStakeHolderForm = ({ stackholderPagination, paginateStackholder, handleStackholderSearchQuery, handleApproversClose, selectedStakeHolderManager, stakeholderManagerData, engagementData, handleShowEngagementField, showFrequencyTimelineOthersField, showEngagementApproachOthersField, FrequencyTimelineState, EngagementApproachState, data, isEditStakeHolder, open, handleEngagementApproach, handleFrequencyTimeline, handleEnagegementTools, addStakeHolderProfileForms, handleStackHolderManager, handleKeyStackHolder, KeyStackHolder, onCloseStakeHolderModal }: AddStakeHolderFormInterface) => {
	const keyStackholder = [
		'Tax Authorities',
		'Ministry of Environment',
		'NGOs/NPO',
		'Corporate Law Authorities',
		'None',
		'Bankers',
		'Stock Exchange',
		'Communities',
		'Customers',
		'Employees',
		'Suppliers',
		'Shareholders',
	];

	const EngagementApproach = [
		'Consult',
		'Disclose or Replace',
		'Involve',
		'Others - Please Specify',
	]

	const EngagementTools = [
		'Face to Face Meetings',
		'Emails',
		'Newsletters',
		'Satisfaction Survey/Poll',
		'Financial Analysis',
		'Disclosures and Reporting',
		'Ensuring Compliance',
		'Evaluation Report',
		'Benchmark',
		'Multi-Stakeholder Meetings',
		'Workshop',
		'Webinars',
		'Board Representation',
	]

	const FrequencyTimeline = [
		'Monthly',
		'Quarterly',
		'Half-Yearly',
		'Annually',
		'Others - Please Specify',
	]
	useEffect(() => {
		if (isEditStakeHolder && data?.attributes?.engagement_approach !== "" && data?.attributes?.engagement_approach[0] !== "") {
			handleShowEngagementField(data)
		}
	}, [])

  	// Customizable Area End
	return (
		// Customizable Area Start
		<div>
			<ModalWrapper
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",

				}}
				open={open}
				onClose={(_, reason) => {
					if (reason !== "backdropClick") {
						onCloseStakeHolderModal();
					}
				}}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Box
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						flexDirection: 'column',
						background: '#fff',
						borderRadius: 8,
						width: '924px',
						position: "relative",
						margin: '10px',
					}} className="schedule_modal">
					<Grid container spacing={0}>
						<Box style={{ paddingLeft: 30, paddingRight: 30, paddingTop: 20, width: '100%' }}>
							<ModalTitle style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 18, borderColor: '#e6e6e6', }} item xs={12} >
								<Typography style={{ paddingTop: '6px', fontWeight: 'bold' }}>

									{isEditStakeHolder ? <b>EDIT STAKEHOLDER PROFILE</b> : <b>ADD NEW STAKEHOLDER</b>}
								</Typography>
								<CloseIconButton onClick={onCloseStakeHolderModal}>
									<CloseIcon style={{}} />
								</CloseIconButton>
							</ModalTitle>
						</Box>
						<ModelBody item xs={12} style={{ paddingRight: '12px', }}>
							<Formik
								initialValues={{
									key_stakeholders: isEditStakeHolder ? data?.attributes?.key_stakeholders.split(',') : [],
									engagement_approach: isEditStakeHolder ? data?.attributes?.engagement_approach : [],
									engagement_tools: isEditStakeHolder ? data?.attributes?.engagement_tools.split(',') : [],
									frequency_timeline: isEditStakeHolder ? data?.attributes?.frequency_timeline.split(',') : [],
									other_please_specify: isEditStakeHolder ? engagementData : '',
									frequency_timeline_other_please_specify: '',
									stakeholder_manager: isEditStakeHolder ? data?.attributes?.stakeholder_manager_id?.map((x: any) => x.toString()) : [],
								}}
								validate={values => {
									const errors: any = {};
									if (KeyStackHolder?.length <= 0) {
										errors.key_stakeholders = "Please select atleast one"
									}
									if (values?.stakeholder_manager?.length <= 0) {
										errors.stakeholder_manager = 'Please select atleast one';
									}
									if (!values?.other_please_specify && showEngagementApproachOthersField) {
										errors.other_please_specify = "Required or remove the 'Others - Please Specify' option"
									}
									if (!values?.frequency_timeline_other_please_specify && showFrequencyTimelineOthersField) {
										errors.frequency_timeline_other_please_specify = "Required or remove the 'Others - Please Specify' option"
									}
									return errors;
								}}
								enableReinitialize
								onSubmit={(values) => {
									isEditStakeHolder ? addStakeHolderProfileForms(values, data) : addStakeHolderProfileForms(values)
								}}>
								{({ handleChange, values, touched, setFieldValue, setTouched, handleSubmit }) => {
									return (
										<RightFormWrapper style={{ paddingRight: '25px' }} translate="no" onSubmit={handleSubmit}>
											<div className={`${classes.linkInput}`} style={{ width: '100%' }}>
												<InputRow data-test-id="keyStakeholderId"  style={{ marginBottom: 20, }}
													label={<Flex>Key Stakeholders<sup>*</sup>
														<Typography color="textSecondary"></Typography></Flex>}
													input={
														<Autocomplete
															multiple
															id="keyStakeholderIdAutocomplete"
															options={keyStackholder.map((option) => option)}
															defaultValue={(isEditStakeHolder && data?.attributes?.key_stakeholders !== "") ? data?.attributes?.key_stakeholders?.split(',') : []}
															onChange={(e, newValue) => {
																handleKeyStackHolder(newValue)
															}}
															renderTags={(value: readonly string[], getTagProps) =>
																value.map((option: string, index: number) => (
																	<Chip variant="outlined" label={option} {...getTagProps({ index })} />
																))
															}
															renderInput={(params) => (
																<MInput
																	{...params}
																	name="key_stakeholders"
																	variant="outlined"
																/>
															)}
														/>
													}
												/>
												<InputRow data-test-id='engagegmentApproach' style={{ marginBottom: 20, }}
													label={<Flex>Engagement Approach
														<Typography color="textSecondary"></Typography></Flex>}
													input={
														<Autocomplete
															multiple
															id="engagegmentApproachId"
															value={EngagementApproachState}
															options={EngagementApproach.map((option) => option)}
															defaultValue={isEditStakeHolder && data?.attributes?.engagement_approach[0] !== "" && data?.attributes?.engagement_approach !== "" ? data?.attributes?.engagement_approach : []}
															onChange={(e, newValue) => {
																handleEngagementApproach(newValue)
															}}
															renderTags={(value: readonly string[], getTagProps) =>
																value.map((option: string, index: number) => (
																	<Chip style={{ height: "100%" }} variant="outlined" label={<Typography style={{ whiteSpace: 'normal' }}>{option}</Typography>} {...getTagProps({ index })} />
																))
															}
															renderInput={(params) => (
																<MInput
																	{...params}
																	name="engagement_approach"
																	variant="outlined"
																/>
															)}
														/>
													}
												/>
												{showEngagementApproachOthersField &&
													<InputRow style={{ marginBottom: 20, }}
														label={<Flex>Please Specify Other's Option For Engagement Approach <sup>*</sup>
															<Typography color="textSecondary"></Typography></Flex>}
														input={
															<MInput value={values?.other_please_specify} name="other_please_specify" placeholder="Please Specify" onChange={handleChange} />
														}
													/>
												}
												<InputRow data-test-id='engagementToolsId' style={{ marginBottom: 20, }}
													label={<Flex>Engagement Tools
														<Typography color="textSecondary"></Typography></Flex>}
													input={
														<Autocomplete
															multiple
															id="engagementTools"
															options={EngagementTools.map((option) => option)}
															defaultValue={(isEditStakeHolder && data?.attributes?.engagement_tools !== "") ? data?.attributes?.engagement_tools?.split(',') : []}
															onChange={(e, newValue) => {
																handleEnagegementTools(newValue)
															}}
															renderTags={(value: readonly string[], getTagProps) =>
																value.map((option: string, index: number) => (
																	<Chip variant="outlined" label={option} {...getTagProps({ index })} />
																))
															}
															renderInput={(params) => (
																<MInput
																	{...params}
																	name="engagement_tools"
																	variant="outlined"
																/>
															)}
														/>
													}
												/>
												<InputRow data-test-id='frequencyId' style={{ marginBottom: 20, }}
													label={<Flex>Frequency/Timeline
														<Typography color="textSecondary"></Typography></Flex>}
													input={
														<Autocomplete
															id='frequencyAutoComplete'
															options={FrequencyTimeline}
															defaultValue={(isEditStakeHolder && data?.attributes?.frequency_timeline !== "") ? data?.attributes?.frequency_timeline : ''}
															onChange={(e, newValue) => {
																handleFrequencyTimeline(newValue)
															}}
															renderInput={(params) => (
																<MInput
																	{...params}
																	name="frequency_timeline"
																	variant="outlined"
																/>
															)}
														/>
													}
												/>
												{showFrequencyTimelineOthersField &&
													<InputRow style={{ marginBottom: 20, }}
														label={<Flex>Please Specify Other's Option For Frequency/Timeline <sup>*</sup>
															<Typography color="textSecondary"></Typography></Flex>}
														input={
															<MInput name="frequency_timeline_other_please_specify" placeholder="Please Specify" value={values?.frequency_timeline_other_please_specify} onChange={handleChange} />
														}
													/>
												}
												<InputRow style={{ marginBottom: 20, }}
													label={<Flex>Stakeholder Manager*
														<Typography color="textSecondary"></Typography></Flex>}
													input={
														<SearchSelect
															data-test-id='manager'
															multiple={true}
															pagination={stackholderPagination}
															hasMore={!!stackholderPagination?.next_page}
															nextPage={paginateStackholder}
															loading={false}
															onSearch={handleStackholderSearchQuery}
															allItems={stakeholderManagerData}
															items={stakeholderManagerData}
															name="stakeholder_manager"
															selectProps={{ onClose: handleApproversClose }}
															SelectItem={SelectItemUser}
															SelectedItem={SelectedItem}
															getOptionValue={(user: any) => user.email} />
													}
												/>
												<div style={{ display: "flex", marginRight: "12px" }}>
													{values?.stakeholder_manager?.length > 0 && stakeholderManagerData?.map((item: any) => values?.stakeholder_manager?.includes(item?.id) &&
														<Chip
															style={{ marginRight: "12px" }}
															avatar={<Avatar alt="Natacha" src={item?.attributes?.profile_pic} />}
															label={item?.attributes?.first_name + ' ' + item?.attributes?.last_name}
														/>


													)}
												</div>
												<Grid item xs={12} style={{
													display: 'flex',
													justifyContent: 'right',
													alignItems: 'cender',
													flexDirection: 'row',
													paddingTop: '19px',
												}}>

													<DialogCancelButton onClick={onCloseStakeHolderModal}>
														CANCEL
													</DialogCancelButton>
													<ShareNowButton color="primary" variant="contained" type="submit">
														SUBMIT
													</ShareNowButton>
												</Grid>
											</div>
										</RightFormWrapper>
									)
								}}
							</Formik>
						</ModelBody>
					</Grid>
				</Box>
			</ModalWrapper >
		</div >
		// Customizable Area End
	);
}

// Customizable Area Start

export const SelectItemUser = ({ item, value }: { item: any, value: any[] }) => {
	return <div style={{ width: '100%', display: 'flex', flexDirection: 'row-reverse', alignItems: 'center' }}>
		<ListItemText style={{ flex: 2 }} disableTypography primary={
			<div style={{ display: "flex", alignItems: "center" }}>
				<Avatar alt="Remy Sharp" src={item?.attributes?.profile_pic} style={{ width: "30px", height: "30px", marginRight: "10px" }} />

				<Typography style={{ color: "#333232", fontWeight: "bold", width: "30%", fontSize: 12, overflow: 'hidden', textOverflow: 'ellipsis' }}>
					{item?.attributes?.first_name} {item?.attributes?.last_name}
				</Typography>
				<Typography component={'div'} style={{ width: '65%', fontSize: 12, fontWeight: "bold", overflow: 'hidden', textOverflow: 'ellipsis' }}>
					{item?.attributes?.email}
				</Typography>

				{Array.isArray(value) && <Checkbox
					icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
					checkedIcon={<CheckBoxIcon fontSize="small" style={{ color: "#1ea60a" }} />}
					checked={value?.findIndex((x) => x == item?.id) > -1}
				/>}

			</div>
		} />
	</div>
}

export const SelectedItem = ({ items, value }: { items: any, value: any }) => {
	return <>
		<div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
			{(items || []).map((selectedValue: any, i: any) => {
				return (<Typography key={i} align="left">
					<div style={{ display: 'flex', alignItems: 'center' }}>
						{/* <Avatar className='ml10' style={{ width: '30px', height: '30px', }} src={selectedValue?.attributes.profile_pic} aria-label="profile-pic"></Avatar> */}
						<span className='ml5'>{`${selectedValue?.attributes.email}` || ''}</span>
					</div>
				</Typography>)
			})}
		</div>
	</>
}
StakeholderProfileFormController.contextType = UserContext
//*********** styles ***************//

const TableWrapper = styled('div')({
	overflow: 'auto',
	'& .MuiTableCell-root': {
		borderRight: '1px solid rgba(224, 224, 224, 1)',
		textAlign: 'center',
	},
	'& .table-icons img': {
		margin: '0px 5px',
	}
})
const ModelBody = styled(Grid)({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	flexDirection: 'column',
	padding: '15px 32px',
})

export const ModalWrapper = styled(Modal)({
	'& + iframe': {
		display: 'none',
	}
})

export const RightFormWrapper = styled(Form)({
	width: '100%',
	display: 'flex',
	flexDirection: 'column',
	gap: '25px',
	padding: '10px 0',
	overflowX: 'hidden',
	paddingRight: '10px',
	maxHeight: '490px',
	boxSizing: 'border-box',
})

const LoaderContainer = styled('div')({
	margin: "auto",
	width: "100%",
	padding: "19px",
	display: "flex",
	justifyContent: "center"
})

const RoundedButtonUpload = styled(Button)({
	color: '#009c05',
	border: '2px solid #009c05',
	borderRadius: '25px',
	padding: '10px 20px',
	textTransform: 'none',
	fontWeight: 600
})

const TableRowBox = styled(TableRow)({
	'& th:first-child': {
		minWidth: '45px',
	},
	'& th:nth-child(2)': {
		minWidth: '120px',
	},
	'& th:nth-child(3)': {
		minWidth: '340px',
	},
	'& th:nth-child(4)': {
		minWidth: '140px',
	},
	'& th:nth-child(5)': {
		minWidth: '340px',
	},
	'& th:nth-child(6)': {
		minWidth: '160px',
	},
})

const Pagin = styled('div')({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	padding: '10px',
	'@media screen and (max-width:350px)': {
		display: 'initial',
		padding: '10px 1px',
	},
	'@media screen and (min-width:768px) and (max-width:850px)': {
		display: 'initial',
		padding: '10px 1px',
	},
	'& > MuiPaginationItem-icon': {
		color: 'green',
	},

	ul: {
		"& > .MuiPaginationItem-root": {
			color: "green"
		}
	}
})
const BtnImg = styled('img')({
	width: 25
})
const CloseIconButton = styled(IconButton)({
	background: '#676767',
	color: '#fcfcfc',
	width: '32px',
	height: '32px',
	'&:hover': {
		background: '#999',
		opacity: 0.9
	}
})

const ModalTitle = styled(Grid)({
	display: 'flex',
	justifyContent: 'space-between',
	alignItem: 'center',
	flexDirection: 'row',
	width: '100%',
	borderBottom: '0.2px solid #cbc1c1',
	padding: '10px 20px 10px 32px'
})

const BtnSave = styled(Box)({
	textAlign: "right",
	"& button": {
		color: "#fff",
		minWidth: "100px",
	},
});


const DialogCancelButton = styled(Button)({
	color: '#FFF',
	background: '#D0D0D0',
	padding: '10px 18px 10px 18px',
	margin: '5px',
	'&:hover': {
		background: '#999',
		opacity: 0.9
	}
})

const ShareNowButton = styled(Button)({
	padding: '10px 18px 10px 18px',
	margin: '5px',
	'&:hover': {
		opacity: 0.9
	}
})

const PlanCardButton = styled(Button)({
	border: "2px solid transparant",
	color: "#009B07",
	fontWeight: 600,
	backgroundColor: "#FFFFFF",
	textTransform: "none",
	borderRadius: "38px",
	height: "100%",
	padding: "0 65px 0 65px",
	'@media screen and (max-width: 1110px) and (min-width: 769px)': {
		padding: "0 35px 0 35px",
	},
	'@media screen and (max-width: 624px)': {
		padding: "0 35px 0 35px",
	},
	'@media screen and (max-width: 500px)': {
		padding: "0 25px 0 25px",
	}
});
const HistoryCardButton = styled(Button)({
	border: "2px solid transparant",
	color: "#009B07",
	fontWeight: 600,
	backgroundColor: "#FFFFFF",
	textTransform: "none",
	borderRadius: "38px",
	height: "100%",
	padding: "0 50px 0 50px",
	'@media screen and (max-width: 1110px) and (min-width: 769px)': {
		padding: "0 30px 0 30px",
	},
	'@media screen and (max-width: 624px)': {
		padding: "0 30px 0 30px",
	},
	'@media screen and (max-width: 500px)': {
		padding: "0 20px 0 20px",
	}
});

const PageWrapper = styled("div")({
	display: 'flex',
	justifyContent: 'center',
	minHeight: '100vh'
});
const CenterRow = styled("div")((props: any) => ({
	width: '100%',
	marginTop: '12px'
}));

const RoundedButtonBase = styled('div')((props: any) => ({
	height: "47px",
	fontWeight: 700,
	display: 'flex',
	textAlign: 'center',
	justifyContent: 'center',
	backgroundColor: "#FFFFFF",
	flex: "wrap",
	color: "#009E00",
	borderRadius: '38px',
	border: " 2px solid #009B07"
}))

const RoundedButton = styled(Button)({
	backgroundColor: '#009c05',
	color: 'white',
	borderRadius: '20px',
	'&:hover': {
		backgroundColor: '#009c05',
	}
})
export const BoardOperationBox = styled(Form)({
	width: "100%",
	background: "#FFFFFF",
	padding: "40px",
	borderRadius: "6px",
	boxSizing: "border-box",
	border: "1px solid rgba(224, 224, 224, 1)",
	"& label": {
		color: "#484848",
		fontWeight: "500",
		marginBottom: "12px",
	},
	"& .MuiFormControl-root.MuiTextField-root": {
		width: "100%",
		marginBottom: "20px",
	},
	"& .upload-btn": {
		width: "100%",
		display: "flex",
		alignItems: "center",
		gap: "20px",
		"& button": {
			width: "fit-content",
			padding: "11px 30px 7px 30px",
			border: "solid 1px #009B07",
			color: "#009B07",
			borderRadius: "30px",
		},
	},
	"& .MuiFormControl-root": {
		width: "100%",
	},
});

const FieldSetWrapper = styled(Grid)({
	marginLeft: '-12px',
	'@media (max-width: 1470px)': {
		marginTop: '15px'
	}
})

const FieldLabel = styled('div')({
	fontWeight: 600,
	color: "#404040",
	fontFamily: "helveticaneue",
	fontSize: '13px',
	marginLeft: '12px !important'
})


const dollarFieldProps = {
	placeholder: "Enter Here",
	InputProps: {
		startAdornment: <InputAdornment position="start">$</InputAdornment>
	}
}

const CoinsHead = styled("div")({
	margin: "36px 0px 15px 12px",
	fontSize: "16px",
	fontWeight: 600,
	"@media screen and (max-width: 768px)": {
		margin: "20px 0px 10px 12px",
	},
});
// Customizable Area End



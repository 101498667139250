import React from "react";
// Customizable Area Start
import { Popover, Box, Grid, Avatar, Typography, Chip, Divider, Snackbar, SnackbarContent, TextField, Checkbox,  MenuItem, ListItemText, Button } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import Autocomplete from "@material-ui/lab/Autocomplete";
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify'
import FormModal, { InputRow, MInput } from '../../../components/src/FormModal.web';
import { MSelect } from '../../../components/src/FormModal.web';
import {
    StyleSheet,
} from "react-native";
import DraftsOutlinedIcon from '@material-ui/icons/DraftsOutlined';
import OverlayLoader from '../../../components/src/Loader.web'


import Settings5Controller, {
    Props,
} from "./Settings5Controller";
import { image_preference } from "../../email-account-registration/src/assets";
import { Formik } from "formik";
import { Coin_logo } from './assets'
import { Post_logo } from "./assets";
import './SettingsProfile.css';
import CloseIcon from "@material-ui/icons/Close";

type SettingsProps = {
    subscription: any;
    company_name: any;
    handleCompany: any;
    preference: any;
    handleDelete: any;
    location: any;
    handleChangePreference: any;
    searchTaggedCompany: any;
    setCompanyValue:any;
    designation: any;
    tagged_with_company: any;
    handleChange: any;
    getTaggedCompany: any;
    email: any;
    formatDate: any;
    role: any;
    formatRoleName: any;
    formikProps: any;
    about: any;
    total_views: any;
    total_post: any;
    profile_pic: any;
    total_followers: any;
    context: any;
    handleFileChange: any;
    first_name: any;
    last_name: any;
    loading: any;
    isLoading: any;
    opened: any;
    handleClose: any;
    color: any;
    snackbarmsg: any;
    openFollowerList: any;
    anchorElForPopupFollower: any;
    state: any;
    setState:any;
    handleCloseConnection:any;
    openViewsMenu: any;
    anchorElForPopupNested: any;
    corporate_company_name:string
}

type initialValuesProps = {
    about: string;
    first_name: string;
    last_name: string;
    email: string;
    location: string;
    company_name: string;
    designation: string;
    preference: string[];
    tagged_with_company: string;
    // currentLocation :string;
}

// Customizable Area End
export default class SettingProfile extends Settings5Controller {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        //@ts-ignore
        const { classes } = this.props
        const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 8;
        const initialValues: initialValuesProps = {
            about: this.state.about || "",
            first_name: this.state.first_name || "",
            last_name: this.state.last_name || "",
            email: this.state.email || "",
            location: this.state.location || "",
            company_name: this.state.company_name || "",
            designation: this.state.designation || "",
            preference: this.state.preference || [],
            tagged_with_company: this.state.tagged_with_company,
        }
        // Customizable Area End
        return (
            // Customizable Area Start
            <Formik initialValues={initialValues}
                validate={values => {
                    const errors: any = {};
                    if (!/^[a-zA-Z\s]*$/.test(this.state.first_name)) {
                        errors.first_name = 'Invalid name';
                    }if (!/^[a-zA-Z\s]*$/.test(this.state.last_name)) {
                        errors.last_name = 'Invalid name';
                    } if (!this.state.email) {
                        errors.email = 'Required';
                    } if (!this.state.first_name) {
                        errors.first_name = 'Required';
                    } if (!this.state.location) {
                        errors.location = 'Required';
                    } if (!this.state.last_name) {
                        errors.last_name = 'Required';
                    } if (!/^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?\s]{0,550}$/i.test(this.state.about)) {
                        errors.about = 'Character length should be in between 0-550';
                    } if (!/^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?\s]{0,60}$/i.test(this.state.designation)) {
                        errors.designation = 'Character length should be in between 0-60';
                    } if (this.state.preference.length <= 0) {
                        errors.preference = 'Select atleast one preference';
                    }
                    return errors;
                }}
                onSubmit={(e) => {
                    this.handleUpdateInfo(e)
                }}
            >
                {(formikProps) => (
                    <SettingsForm
                        loading={this.state.loading}
                        isLoading={this.state.isLoading}
                        opened={this.state.opened}
                        handleClose={this.handleClose}
                        color={this.state.color}
                        snackbarmsg={this.state.snackbarmsg}
                        location={this.state.location}
                        handleDelete={this.handleDelete}
                        designation={this.state.designation}
                        email={this.state.email}
                        handleChangePreference={this.handleChangePreference}
                        preference={this.state.preference}
                        handleCompany={this.handleCompany}
                        setCompanyValue={this.setCompanyValue}
                        searchTaggedCompany={this.searchTaggedCompany}
                        company_name={this.state.company_name}
                        tagged_with_company={this.state.tagged_with_company}
                        getTaggedCompany={this.state.getTaggedCompany}
                        formikProps={formikProps}
                        handleChange={this.handleChange}
                        formatDate={this.formatDate}
                        role={this.state.role}
                        formatRoleName={this.formatRoleName}
                        subscription={this.state.subscription}
                        total_followers={this.state.total_followers}
                        context={this.context}
                        total_post={this.state.total_post}
                        total_views={this.state.total_views}
                        profile_pic={this.state.profile_pic}
                        about={this.state.about}
                        first_name={this.state.first_name}
                        last_name={this.state.last_name}
                        handleFileChange={this.handleFileChange}
                        openFollowerList={this.openFollowerList}
                        anchorElForPopupFollower={this.state.anchorElForPopupFollower}
                        state={this.state}
                        setState={this.setState}
                        handleCloseConnection={this.handleCloseConnection}
                        openViewsMenu={this.openViewsMenu}
                        anchorElForPopupNested={this.state.anchorElForPopupNested}
                        corporate_company_name={this.state.corporate_company_name}
                    />
                )}
            </Formik>
            // Customizable Area End
        );
    }
}
// Customizable Area Start

const styles = StyleSheet.create({
    map: {
        position: 'absolute',
        top: 0,
        bottom: 0
    }
});


const SettingsForm = ({
    loading,
    isLoading,
    opened,
    handleClose,
    color,
    snackbarmsg,
    location,
    handleDelete,
    designation,
    handleChangePreference,
    preference,
    handleCompany,
    searchTaggedCompany,
    formikProps,
    formatDate,
    company_name,
    getTaggedCompany,
    tagged_with_company,
    email,
    handleChange,
    subscription,
    role,
    formatRoleName,
    total_views,
    total_post,
    profile_pic,
    total_followers,
    context,
    about,
    handleFileChange,
    first_name,
    last_name,
    openFollowerList,
    anchorElForPopupFollower,
    state,
    setState,
    handleCloseConnection,
    openViewsMenu,
    anchorElForPopupNested,
    setCompanyValue,
    corporate_company_name
}: SettingsProps) => {
    const handleFile= (len:any,e:any) => {
        if (len === null) {
          return;
          } else if (len[0]?.size > 1048576) {
          toast.error("Cannot Upload Picture more than 1 MB");
          e.currentTarget.files = null;
      } else if (len[0]?.type !== "image/png" && len[0]?.type !== "image/jpeg") {
          toast.error("image should only be png or jpeg")
      } else {
          handleFileChange(e)
      }
      }

      const profilePic = () => {
        return(
            profile_pic ? (
                <div style={{ display: "flex", cursor: "pointer" }}>
                    <Avatar style={{width: "70px",height: "70px",}}>
                        <img src={profile_pic} alt="img" width="100%" height="100%" />
                    </Avatar>
                    <Avatar style={{position: "absolute",color: "white",top: "60%", left: "60%",backgroundColor: "#1ea60a",width: "25px",height: "25px",padding: "3px",border: "1px solid white"}}>
                        <BorderColorIcon fontSize="small" />
                    </Avatar>
                </div>
            ) : (
                <>
                    <span style={{ display: "flex", cursor: "pointer" }}>
                        <Avatar style={{ width: "70px",height: "70px", }}>
                        </Avatar>
                        <Avatar style={{ position: "absolute",color: "white",top: "60%", left: "60%",backgroundColor: "#1ea60a",width: "25px", height: "25px", padding: "3px",border: "1px solid white" }}>
                            <BorderColorIcon fontSize="small" />
                        </Avatar>
                    </span>
                </>
            )
        )
      }

      const showSubscription = () => {
        return(
                subscription && Object.keys(subscription).length>0 ? (
                    <SubscriptionCard title={formatRoleName(subscription.package_role)} date={formatDate(subscription.package_end_date)} />
                ) : (
                    <>
                        {role && Object.keys(role).length>0 ? (
                          <SubscriptionCard title={formatRoleName(role.name)} />
                    ) : (
                        <SubscriptionCard title="Individual User" />
                    )}
                </>
            )
        )
      }

      const PopupWrapper = styled('div')({
        display: 'flex',
        flexDirection: 'column',
      })

      const User = styled('div')({
        padding: '5 10 5 20',
        display: 'flex',
        flexDirection:'row',
        alignItems:'center',
        width:'300px',
        '&:hover': {   
            background: '#f4f0f0',
        },
        '& > div': {
            borderRadius:'25px'
        },
        '& img': {
            width:'45px'
        },
        '& .MuiTypography-root': {
            fontSize:'18px',
            fontWeight:'bold',
            marginLeft:'30px',
        }
    })
 const displayCompanyName = (role.role_level > 4 || tagged_with_company === true) ? corporate_company_name :company_name;
    return(
    <form onSubmit={formikProps.handleSubmit}>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Box style={{ backgroundColor: "white",borderRadius: "10px", paddingBottom: "20px"}}>
                    <div style={{display: "flex", margin: "30px 10px 0px 10px",padding: "20px 10px",alignItems: "flex-start"
                    }}>
                        <div style={{ position: "relative" }}>
                            <label htmlFor="upload-button">
                               {profilePic()}
                            </label>
                            <input
                                type="file"
                                accept=".png, .jpeg"
                                data-test-id='fileUploadBtn'
                                id="upload-button"
                                style={{ display: "none" }}
                                onChange={e => {
                                    var temp;
                                    const len = e?.currentTarget?.files;
                                    handleFile(len,e)
                                }} />
                        </div>
                        <div style={{ marginLeft: "10px" }}>
                            <Typography
                                variant="subtitle1"
                                component="div"
                                style={{color: '#000',fontWeight: "bolder",marginLeft: "10px",marginBottom: '10px',textTransform: 'capitalize',fontSize: '1.1rem',fontFamily: "helvetica"}}
                            >
                                {first_name + " " + last_name}
                            </Typography>
                            <div className="icons">
                                <div style={{ width: '100%', display: 'flex', gap: '5px', marginLeft: '5px' }}>
                                    <div><StatsChip
                                        label={<><b>{total_followers}</b> Connections</>}
                                        variant='outlined'
                                        id="Connections"
                                        icon={<PeopleAltOutlinedIcon fontSize="small" />}
                                        onClick={(event)=>openFollowerList(event)}
                                    />
                                    <Popover
                                        id={Boolean(anchorElForPopupFollower) ? 'popover' : undefined}
                                        open={Boolean(anchorElForPopupFollower)}
                                        anchorEl={anchorElForPopupFollower}
                                        onClose={handleCloseConnection}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        style={{ marginTop: '10px' }}
                                    >
                                        <PopupWrapper>
                                        <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', padding:'5px'}}>
                                                <div><Typography variant="h6" style={{padding:'15px'}}>Followers</Typography></div>
                                                <div> <CloseIcon style={{cursor:'pointer'}} id="closeId" onClick={()=>{handleCloseConnection(true)}}/> </div>
                                            </div>
                                            {state.allFollowers?.map((data:any)=> {
                                                return(
                                                <User>
                                                    <Link to={`/user/${data?.attributes?.account?.id}`}>
                                                        <Avatar src={data?.attributes?.profile_pic} />
                                                    </Link>
                                                    <Typography>{data?.attributes?.account?.first_name+ " "+data?.attributes?.account?.last_name}</Typography>
                                                </User>

                                                )
                                            })}
                                        </PopupWrapper>
                                    </Popover>
                                    </div>
                                    <div>
                                        <StatsChip
                                            label={<><b>{context.no_of_coin}</b> Coins</>}
                                            variant='outlined'
                                            icon={<img src={Coin_logo} style={{ height: 20 }} />}
                                            onClick={() => { }}
                                        /></div>
                                    <div>
                                        <StatsChip
                                            label={<><b>{total_views}</b> Views</>}
                                            variant='outlined'
                                            icon={<VisibilityOutlinedIcon fontSize="small" />}
                                            id="viewBtn"
                                            onClick={(event) => openViewsMenu(event)}
                                        />
                                        <Popover
                                            id={Boolean(anchorElForPopupNested) ? 'nested-popover' : undefined}
                                            open={Boolean(anchorElForPopupNested)}
                                            anchorEl={anchorElForPopupNested}
                                            onClose={()=>{handleCloseConnection(false)}}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right',
                                            }}
                                            style={{ marginTop: '10px' }}
                                        >
                                            <PopupWrapper>
                                                <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', padding:'5px'}}>
                                                    <div><Typography variant="h6" style={{padding:'15px'}}>Viewers</Typography></div>
                                                   <div> <CloseIcon style={{cursor:'pointer'}} onClick={()=>{handleCloseConnection(false)}}/> </div>
                                                </div>
                                                {state.allViewer?.map((data:any)=> {
                                                    return(
                                                    <User>
                                                        <Link to={`/user/${data?.attributes?.account_viewed_by?.data?.id}`}>
                                                            <Avatar src={data?.attributes?.account_viewed_by?.data?.attributes?.profile_pic} />
                                                        </Link>
                                                        <Typography>{data?.attributes?.account_viewed_by?.data?.attributes?.first_name+ " "+data?.attributes?.account_viewed_by?.data?.attributes?.last_name}</Typography>
                                                    </User>

                                                    )
                                                })}
                                            </PopupWrapper>
                                        </Popover>
                                    </div>
                                    <div>
                                        <StatsChip
                                            label={<><b>{total_post}</b> Posts</>}
                                            variant='outlined'
                                            icon={<img src={Post_logo} style={{ height: 15 }} />}
                                            onClick={() => { }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ margin: "10px 20px 20px 20px" }}>
                        <Divider />
                    </div>
                    <div style={{ margin: "10px 20px" }}>
                        <SubscriptionHeading
                            variant="subtitle2"
                            component="div"
                        >
                            Activated Subscription :
                        </SubscriptionHeading>
                        <Grid container spacing={1}>
                            {showSubscription()}
                            {/* <SubscriptionCard title="Service Provider"  date=" 17 Aug,2022"  />
                            <SubscriptionCard title="Portfolio Manager"  date=" 17 Aug,2022"  /> */}
                            <Grid item xs={12}>
                                <FieldLabel
                                    variant="subtitle2"
                                    component="div"
                                >
                                    About Me
                                </FieldLabel>
                                <FieldWrapper>
                                    <Typography
                                        variant="subtitle2"
                                        component='span'
                                        style={{ fontWeight: "normal", color: "grey", fontSize: "12", fontFamily: "Helvetica" }}
                                    >
                                        <StyledTextField
                                            fullWidth
                                            rows={4}
                                            placeholder="About me"
                                            value={about}
                                            id="about"
                                            onChange={handleChange('about')}
                                            onBlur={formikProps.handleBlur}
                                            multiline
                                            variant="outlined"
                                            InputProps={{
                                                style: { color: 'rgba(42, 52, 63, 0.83)'},
                                                error: !!(formikProps.errors.about && formikProps.touched.about)
                                            }} />
                                    </Typography>
                                </FieldWrapper>
                                <div style={{fontSize: "12px",color: "red",margin: "0.25rem 1rem" }}>
                                    {formikProps.errors.about && formikProps.touched.about && formikProps.errors.about}
                                </div>                                            
                            </Grid>
                            <FieldSetWrapper item xs={6}>
                                <FieldLabel
                                    variant="subtitle2"
                                    component="div"
                                >
                                    First Name
                                </FieldLabel>
                                <InputRow label="" input={
                                    <InputRow style={{ width: '97%', marginLeft: "9px" }} label="" input={
                                        <MInput name="first_name" placeholder="" onChange={handleChange('first_name')}
                                            value={first_name}
                                        />
                                    }
                                    />
                                } />
                            </FieldSetWrapper>
                            <FieldSetWrapper item xs={6}>
                                <FieldLabel
                                    variant="subtitle2"
                                    component="div"
                                >
                                    Last Name
                                </FieldLabel>
                                <InputRow label="" input={
                                    <InputRow style={{ width: '97%', marginLeft: "9px" }} label="" input={
                                        <MInput name="last_name" placeholder="" onChange={handleChange('last_name')}
                                            value={last_name}
                                        />}
                                    />
                                } />
                            </FieldSetWrapper>
                            <FieldSetWrapper item xs={12}>
                                <FieldLabel
                                    variant="subtitle2"
                                    component="div"

                                >
                                    Email
                                </FieldLabel>
                                <FieldWrapper>
                                    <Typography
                                        variant="subtitle2"
                                        component='span'
                                        style={{ fontWeight: "normal", color: "#4a4949", fontSize: "12", }}
                                    >
                                        <StyledTextField fullWidth placeholder="Enter Email"
                                            id="email"
                                            InputProps={{
                                            disabled: true,
                                            error: !!(formikProps.errors.email && formikProps.touched.email),
                                            startAdornment: (
                                                <DraftsOutlinedIcon
                                                style={{ color: "#c9c9c9", marginRight: "10px" }}
                                            />
                                            )
                                            }}
                                            value={email}
                                            variant="outlined"
                                            onChange={handleChange('email')}
                                            onBlur={formikProps.handleBlur}
                                        />
                                    </Typography>
                                    <div style={{ fontSize: "12px", color: "red", margin: "0.25rem 1rem" }}>
                                        {formikProps.errors.email && formikProps.touched.email && "must be a valid email"}
                                    </div>
                                </FieldWrapper>
                            </FieldSetWrapper>
                            <FieldSetWrapper item xs={12}>
                                <FieldLabel
                                    variant="subtitle2"
                                    component="div"

                                >
                                    PM / Company Name
                                </FieldLabel>
                                <FieldWrapper>
                                    {email !== "" ?
                                        <>
                                            <InputRow style={{ width: '100%' }} label="" input={<Autocomplete
                                                id="company_name"
                                                freeSolo
                                                defaultValue={{
                                                    attributes: {
                                                        company_name: displayCompanyName,
                                                    }
                                                }}
                                                autoComplete
                                                includeInputInList
                                                noOptionsText={'no result found, please enter manually'}
                                                disabled={tagged_with_company && company_name.length > 0}
                                                getOptionLabel={(option: any) => option?.attributes?.company_name || ''}
                                                options={getTaggedCompany}
                                                onInputChange={(e: any) => setCompanyValue(e?.target?.value)}
                                                onChange={(e, newValue) => {
                                                    handleCompany(newValue)
                                                }}
                                                renderInput={(params) => <MInput name="company_name" variant="outlined" {...params} />}
                                            />
                                            } />
                                            {tagged_with_company && <Chip
                                                key={company_name}
                                                label={displayCompanyName}
                                                style={{ margin: "5px" }}
                                            />
                                            }
                                        </> :  <StyledTextField fullWidth
                                            disabled={true}
                                            variant="outlined"
                                        />}
                                </FieldWrapper>
                            </FieldSetWrapper>
                            <FieldSetWrapper item xs={12}>
                                <FieldLabel
                                    variant="subtitle2"
                                    component="div"

                                >
                                    Designation
                                </FieldLabel>
                                <FieldWrapper>
                                    <InputRow label="" input={
                                        <MInput name="designation" placeholder="" onChange={handleChange('designation')} value={designation} />
                                    } />
                                </FieldWrapper>
                            </FieldSetWrapper>
                            <FieldSetWrapper xs={12} >
                                <FieldLabel
                                    variant="subtitle2"
                                    component="div"
                                >
                                    Preference <sup>*</sup>
                                </FieldLabel>
                                <Box style={{
                                    margin: "10px 12px",
                                    marginRight: '0px'
                                }}>
                                    <MSelect
                                        id="preference"
                                        name="preference"
                                        multiple
                                        displayEmpty
                                        fullWidth
                                        value={preference}
                                        onChange={(e) => handleChangePreference(e, formikProps.setFieldValue)}
                                        renderValue={(selected) => (
                                            <Box style={{ display: "flex", }}>
                                                <img src={image_preference} width="20px" height="15px" style={{ borderRadius: "10px", paddingTop: "4px", marginRight: "5px" }} />
                                                <Typography component={'span'} style={{ fontSize: "14px", margin: "0px", padding: "0" }}>
                                                    Select
                                                </Typography>
                                            </Box>)
                                        }
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left",
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left"
                                            },
                                            getContentAnchorEl: null
                                        }}
                                    >
                                        <MenuItem style={{
                                            height: "50px",
                                            padding: "20px 20px",
                                        }} key="E" value="Environment">
                                            <Checkbox
                                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                checkedIcon={<CheckBoxIcon fontSize="small" style={{ color: "#1ea60a" }} />}
                                                checked={preference.indexOf("Environment") > -1} />
                                            <ListItemText disableTypography primary={
                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <Typography>
                                                        E
                                                    </Typography>
                                                    <Typography>
                                                        #Environment
                                                    </Typography>
                                                    <Typography>

                                                    </Typography>
                                                </div>} />
                                        </MenuItem>
                                        <MenuItem style={{
                                            height: "50px",
                                            padding: "20px 20px",
                                        }} key="S" value="Social">
                                            <Checkbox
                                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                checkedIcon={<CheckBoxIcon fontSize="small" style={{ color: "#1ea60a" }} />}
                                                checked={preference.indexOf("Social") > -1} />
                                            <ListItemText disableTypography primary={
                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <Typography>
                                                        S
                                                    </Typography>
                                                    <Typography style={{ justifyContent: "start", marginRight: "45px" }}>
                                                        #Social
                                                    </Typography>
                                                    <Typography>
                                                    </Typography>
                                                </div>} />
                                        </MenuItem>
                                        <MenuItem style={{
                                            height: "50px",
                                            padding: "20px 20px",
                                        }} key="G" value="Governance">
                                            <Checkbox
                                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                checkedIcon={<CheckBoxIcon fontSize="small" style={{ color: "#1ea60a" }} />}
                                                checked={preference.indexOf("Governance") > -1} />
                                            <ListItemText disableTypography primary={
                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <Typography>
                                                        G
                                                    </Typography>
                                                    <Typography>
                                                        #Governance
                                                    </Typography>
                                                    <Typography>
                                                    </Typography>
                                                </div>} />
                                        </MenuItem>
                                    </MSelect>
                                </Box>
                                {preference?.map((preference: any) => (
                                    <Chip
                                        key={preference}
                                        label={preference}
                                        onDelete={handleDelete(preference, formikProps.setFieldValue)}
                                        style={{ margin: "5px", marginLeft: '10px' }}
                                    />
                                ))}
                            </FieldSetWrapper>
                            <FieldSetWrapper className="locationWrapper" item xs={12}>
                                <FieldLabel
                                    variant="subtitle2"
                                    component="div"
                                >
                                    Location<sup>*</sup>
                                </FieldLabel>
                                <FieldWrapper>
                                    <InputRow label="" input={<MInput value={location} name="location" placeholder="" disabled onChange={handleChange('location')} />} />
                                </FieldWrapper>
                            </FieldSetWrapper>
                            <Grid item xs={12}>
                                <div style={{ display: "flex", justifyContent: "end", alignItems: "end", marginRight: "12px" }}>
                                    <Button
                                        disabled={!preference.length || !first_name || !last_name || !location || !email} 
                                        variant="contained"
                                        type="submit"
                                        color="primary"
                                        style={{ color: "white", height: "50px",margin: "10px 0px", width: "140px",fontFamily: 'helveticaneue', fontWeight: 500, fontSize: '18px', textTransform: 'none', borderRadius: "12px", }}
                                    >
                                        Save
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Box>
            </Grid>
            <OverlayLoader loading={Boolean(loading || isLoading)} />                            
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: 'center' }}
                open={opened}
                autoHideDuration={3000}
                onClose={handleClose}
            >
                <SnackbarContent style={{
                    backgroundColor: color,
                }}
                    message={<span id="client-snackbar">{snackbarmsg}</span>}
                />
            </Snackbar>

        </Grid>
    </form>
    )
}

const SubscriptionHeading = styled(Typography)({
    fontWeight: 500,
    marginLeft: "10px",
    color: "#404040",
    fontSize: '18.35px',
    '@media (max-width: 1470px)': {
        fontSize: '16px',
    }
}) as typeof Typography

const StatsChip = styled(Chip)({
    color: "#858585",
    height: "35px",
    padding: "5px 5px",
    borderRadius: '20px',
})

const FieldSetWrapper = styled(Grid)({
    marginTop: '30px',
    '@media (max-width: 1470px)': {
        marginTop: '15px'
    }
}) as typeof Grid

const SubscriptionCard = ({ title, date }: any) => {
    return <SubscriptionItem item xs={12} lg={9}>
        <SubscriptionWrapper>
            <SubscriptionTitle
                variant="subtitle2"
                color="primary"
                component='span'
            >
                {title}
            </SubscriptionTitle>
            {date &&
                <SubscriptionSubtitle
                    variant="subtitle2"
                    component='span'
                    style={{}}
                >
                    Expires on: {date}
                </SubscriptionSubtitle>
            }
        </SubscriptionWrapper>
    </SubscriptionItem>
}

const SubscriptionItem = styled(Grid)({
    padding: '0',
    marginTop: '12px',
    alignItems: "center",
    '@media (max-width: 1470px)': {
        marginTop: '8px',
    }
})

const SubscriptionWrapper = styled(Box)({
    backgroundColor: "#F2F4F5",
    borderRadius: "11px",
    display: "table-cell",
    padding: "18px 27px",
    margin: "10px 5px",
    alignItems: "center",
    '@media (max-width: 1470px)': {
        padding: "10px 10px",
    }
}) as typeof Box

const SubscriptionTitle = styled(Typography)((props: any) => ({
    fontWeight: "bold",
    color: "#009b07",
    fontSize: '18.35px',
    lineHeight: '26px',
    '@media (max-width: 1470px)': {
        fontSize: '14px'
    }
})) as typeof Typography

const SubscriptionSubtitle = styled(Typography)((props: any) => ({
    fontWeight: "normal",
    marginLeft: "10px",
    color: "#6A7279",
    fontSize: "14px",
    lineHeight: '26px',
    marginBottom: '-6px',
    '@media (max-width: 1470px)': {
        fontSize: '11px',
        marginBottom: '-4px'
    }
})) as typeof Typography


const FieldLabel = styled(Typography)({
    fontWeight: 500,
    marginLeft: "10px",
    color: "#404040",
    fontSize: '16px'
}) as typeof Typography

const StyledTextField = styled(TextField)({
    '& input + fieldset': {
        borderColor: '#E0E0E0'
    }
}) as typeof TextField

const FieldWrapper = styled(Box)({
    marginLeft: '10px',
    marginTop: '16px'
}) as typeof Box

// Customizable Area End

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Post } from './types';
import { sendAPIRequest } from '../../../components/src/utils';
import { toast } from 'react-toastify';
import { sendSharePopupMessage } from '../../share/src/Messages';
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
export interface ESGData {
  id: number;
  user_name: string | null;
  created_at: string;
  updated_at: string;
  email: string;
  company_name: string;
  ability_card: string;
  ability_type: "write" | "read" | "edit"; // Add other possible values if needed
  profile_picture: string | null;
  card_serial_number: number;
  card_banner: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  showProjectTab: boolean;
  overallPercent:number,
  showCollaborators: boolean;
  userReply: string;
  isLikeChange: boolean;
  categoryWiseProjectData: any,
  rowId: any;
  events: Event[];
  renderLikeIcon: boolean;
  activeImpactDashboardButton: boolean;
  activeChooseButton: boolean;
  openReply: string;
  Overlayloader:boolean;
  showImpactDashboard: boolean;
  loading: boolean;
  txtInputValue: any;
  txtSavedValue: string;
  profileDashboardData: {
    attributes:{
    cover_pic:string;
    logo:string;
    company_name:string;
    sector:string;
    website_link:string;
    state:string;
    country:string;
    business_description:string;
    industry:string;
    sub_industry:string;
    headquarters:string;
    archived_certifications:any;
    woman_owner_percent:number;
    indigenous_owner_percent:string;
  }
  };
  openCertificationModal:boolean;
  projectsList: any;
  showSdg:boolean;
  avergaeAge: number | null;
  max_age_directors: number | null;
  min_age_directors: number | null;
  numberOfLikes: any;
  showComments: any;
  openModal:boolean;
  showCommentField: boolean;
  openCommentReplyEmoji: boolean;
  reply_id: string;
  fetchCollaborats:boolean;
  feedPosts: Post[];
  openCommentEmoji: boolean;
  userComment: string;
  totalCoins:any;
  collabratorsList:any[];
  selectedCollabrators: any[];
  showESGTab: boolean;
  activeESGTab: boolean;
  requestors: any[];
  openContributros:boolean;
  tableRowId:any;
  userID: number | undefined;
  islicensedUser:boolean;
  profileCompletionESGCheck:boolean;
  esgSelfAssessmentCheck:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CorporateProfileController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getProfileDashboardData: string = '';
  getLikesCountData: any;
  listOfCategoryWiseProjects: any;
  addCollabrator:any;
  getAllCollabrators: any
  requestorsCallId:any
  checkESGRequiredDataCallId:string = ''
  userCompanyName: string | undefined;
  userTypeInfo:string | undefined;
  userRoleData:number | undefined; 
  userDataId:number
  actions:{card:string,action:string}[] = [
    { card: "Corporate Profile Form", action: "CorporateProfileForm" },
    { card: "Stakeholder Corporate Profile", action: "StakeHolderProfile" },
    { card: "Self Assessments", action: "ESGJourneyReportingFrameworks" },
    { card: "Self Assessment Dashboard", action: "AssesmentDashboard" },
    { card: "Risk and Opportunity Management", action: "RiskManagement" },
    { card: "Initiative Evaluation", action: "InitiativeEvaluation" },
    { card: "Supplier Due Diligence Form", action: "DueDiligence" },
    { card: "Download Carbon Calculator- GHG Tool", action: "CarbonCalculatorCard" },
    { card: "Carbon Emission Calculator - GHG Tool", action: "CarbonCalculatorCard" },
    { card: "Reports", action: "Reports" },
    { card: "Reference Policies", action: "ReferencePolicy" }
  ];
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage),      
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      showCollaborators:false,
      showSdg:false,
      overallPercent:0,
      fetchCollaborats:false,
      showProjectTab: true,
      openContributros:false,
      showESGTab: false,
      activeESGTab: false,
      tableRowId:"",
      openCertificationModal:false,
      selectedCollabrators:[],
      collabratorsList:[],
      openModal:false,
      Overlayloader:false,
      categoryWiseProjectData: [],
      isLikeChange: false,
      rowId: "",
      totalCoins:"",
      events: [],
      userReply: "",
      loading: false,
      feedPosts: [],
      avergaeAge: null,
      max_age_directors: null,
      min_age_directors: null,
      openReply: '',
      renderLikeIcon: false,
      userComment: '',
      projectsList: [],
      openCommentReplyEmoji: false,
      activeImpactDashboardButton: false,
      activeChooseButton: true,
      showImpactDashboard: false,
      txtInputValue: "",
      reply_id: '',
      openCommentEmoji: false,
      txtSavedValue: "",
      profileDashboardData: {
      attributes:{cover_pic:"",
      logo:"",
      company_name:"",
      sector:"",
      website_link:"",
      state:"",
      country:"",
      business_description:"",
      industry:"",
      sub_industry:"",
      headquarters:"",
      archived_certifications:[],
      woman_owner_percent:0,
      indigenous_owner_percent: "",
    }},
      requestors:[],
      numberOfLikes: [],
      showComments: [],
      showCommentField: false,
      userID: undefined,
      islicensedUser: false,
      profileCompletionESGCheck:false,
      esgSelfAssessmentCheck:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    super.componentDidMount();
    const authToken = await getStorageData('authToken');
    if (authToken == null) {
      this.props.navigation.navigate('Home')
    }
  
    this.setState({ loading: true,Overlayloader:true },() => {
      this.getProfileDashboard()
      this.getCategoryWiseProject()
      this.esgJourneyCards()
      this.checkStatusESGRequiredData()
      this.getUserData()
    })
    // Customizable Area End
  }

  componentDidUpdate(prevProps:any) {
    if (prevProps?.navigation?.history?.location?.state?.comment) {
      this.getProfileDashboard();
      this.props.navigation.history.replace({state: {}})
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );
      this.setState({ txtSavedValue: value });
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && responseJson.errors) {
        this.setState({ loading: false,Overlayloader:false,fetchCollaborats:false })
      }
      else if (apiRequestCallId === this.getProfileDashboardData) {
        this.handleCompanyData(responseJson)
      }
      else if (apiRequestCallId === this.getLikesCountData) {
        this.setState({ numberOfLikes: responseJson })
      }
      else if (apiRequestCallId === this.listOfCategoryWiseProjects) {

        let sum = 0;
        responseJson?.projects?.forEach((item:any)=>{
          sum += (+item.category_project_percentage);
        })
        this.setState({ categoryWiseProjectData: responseJson,overallPercent:sum });

      }
      else if (apiRequestCallId === this.requestorsCallId) {
       this.handleRequestorCallId(responseJson)
      }
      else if(apiRequestCallId === this.checkESGRequiredDataCallId){
        this.setState({profileCompletionESGCheck: responseJson.user_has_complete_profile, esgSelfAssessmentCheck:responseJson.has_user_completed_self_assesment})
      }
    }
    // Customizable Area End

  }
  // Customizable Area Start

  chooseProjectTab = () => {
    this.setState({ activeImpactDashboardButton: false })
    this.setState({ activeChooseButton: true })
    this.setState({ showProjectTab: true });
    this.setState({ showImpactDashboard: false,activeESGTab:false,showESGTab:false});
  }

  handleCompanyData = async(responseJson:any) => {
    
    if(responseJson?.error){
      this.setState({ Overlayloader:false,loading: false})
    }
    this.setState({
      Overlayloader:false,loading: false,
    },()=> {
      this.setState({
        profileDashboardData: responseJson?.data?.card?.data,
        avergaeAge: responseJson?.average_age_directors,
        min_age_directors: responseJson?.min_age_directors ,
        max_age_directors: responseJson?.max_age_directors,
        projectsList: responseJson?.projects?.data,
        totalCoins: responseJson?.total_coins,
      })
    })
  }

  handleRequestorCallId = (responseJson:any) =>{
    if (responseJson) {
      const req = responseJson?.access?.data
      this.setState({ requestors: req })
      console.log("this.state.requestors.map",this.state.requestors);
      
    }
  }
  chooseImpactDashboardTab = () => {
    this.setState({ activeImpactDashboardButton: true })
    this.setState({ activeChooseButton: false })
    this.setState({ showProjectTab: false });
    this.setState({ showImpactDashboard: true,showESGTab:false,activeESGTab:false });
  }
  chooseESGTab = () => {
    this.setState({ activeImpactDashboardButton: false })
    this.setState({ activeChooseButton: false })
    this.setState({ showProjectTab: false });
    this.setState({ showImpactDashboard: false,showESGTab:true,activeESGTab:true });
  }

  getProfileDashboard = async() => {
    const authToken = await getStorageData('authToken');
    const companyName = this.props.navigation.getParam('navigationBarTitleText')
    this.getProfileDashboardData = sendAPIRequest(`${configJSON.companyNameEndpoint}?corporate_id=${companyName}`, {
      method: "GET",
      headers: { token: authToken || '' }
    })
  }
  getLikesCount = async(id: any) => {
    const authToken = await getStorageData('authToken');
    this.getLikesCountData = sendAPIRequest(`${configJSON.getLikes}?project_id=${id}`, {
      method: "GET",
      headers: { 'token': authToken || '' }
    })
  }


  esgJourneyCards = async() => {
    const authToken = await getStorageData('authToken');
    this.requestorsCallId = sendAPIRequest(`${configJSON.showAllCards}`, {
      method: "GET",
      headers: {token: authToken},
    })
  }

  handleShareCourse = (p: any) => () => {
    let data = {
      link: `${window?.location?.origin}/CorporateProfile/projects/${p?.id}`,
      shareableType: 'BxBlockProjectManagement::ProjectManagement',
      shareableId: p?.id,
    }
    sendSharePopupMessage(data);
  }

  goToEvent = (event_Id: any) => () => {
    this.props.navigation.history.push(`${this.props.navigation.history.location.pathname}/projects/${event_Id}`)  
  }

  onLikeChange = (postId: any, delta: number) => {
    const projectsList = this.state.projectsList.map((p: any) => {
      if (p.id == postId) {
        if (Boolean(p.attributes?.project_likes?.some((project: any) => Number(project.like_by_id) === Number(this.state.userID)))) {
          p.attributes.project_likes_count -= 1
          p.attributes.project_likes = p.attributes?.project_likes.filter((project: any) => Number(project.like_by_id) !== Number(this.state.userID))
        } else {
          p.attributes.project_likes_count += 1
        }
      }
      return p
    })
    this.setState({ projectsList })
    // this.getProfileDashboard()
  }

  onLikeIdUpdate = (postId: any, likeObj: any) => {
    const projectsList = this.state.projectsList.map((p: any) => {
      if (p.id == postId) {
        p.attributes.project_likes = [...p.attributes.project_likes, likeObj];
      }
      return p
    })
    this.setState({ projectsList })
    // this.getProfileDashboard()
  }

  getCategoryWiseProject = async() => {
    const companyName = this.props.navigation.getParam('navigationBarTitleText')
    const authToken = await getStorageData('authToken');
    this.listOfCategoryWiseProjects = sendAPIRequest(`${configJSON.categoryWiseProjects}?corporate_id=${companyName}`, {
      method: "GET",
      headers: { 'token': authToken || '' }
    })
  }


  handleCollabrators = (value:any) => {
      this.setState({selectedCollabrators:value})
  }

  handleCertificationClose = () => {
    this.setState({openCertificationModal:false})
  }

  handleCertificationOpen = () => {
    this.setState({openCertificationModal:true})
  }

  goToProjectDetailEndUser = (id:any) => {
    this.props.navigation.history.push(`/project_detail_enduser/${id}`)
  }

  handleTopContributorsModalOpen = (id:any) => {
    this.setState({openContributros:true,tableRowId:id})
  }

  handleTopContributorsModalClose = () => {
    this.setState({openContributros:false})
  }

  handleShowCollaborators = (id:any) => {
    this.setState({showCollaborators:true,tableRowId:id})
  }

  handleCloseCollaborators = () => {
    this.setState({showCollaborators:false, tableRowId:""})
  }

  handleOpenSDGFactors = (id:any) => {
    this.setState({tableRowId:id, showSdg:true})
  }

  handleCloseSDGFactors = () => {
    this.setState({showSdg:false,tableRowId:""})
  }

  handleSurveyOpen = (id: any) => () => {
    this.props.navigation.history.push(`${this.props.navigation.history.location.pathname}/EndUserSurvey/${id}`)
     }

     checkStatusESGRequiredData = async () => {
      this.setState({loading: true})
      this.checkESGRequiredDataCallId = sendAPIRequest(`${configJSON.getStatusESGRequiredDatEndpoint}`, {
        method: 'GET',
        headers: {
          token: await getStorageData('authToken'),
          'content-type': 'appliction/json'
        },
      })
    }
    goToPage = async (data:ESGData): Promise<void> => {
      await setStorageData("ability_type", data.ability_type);
      
    
      const actionObject = this.actions.find((item) => item.card === data.ability_card);
      if (actionObject) {
        const { action } = actionObject;
        if (action === "InitiativeEvaluation" || action === "RiskManagement") {
          if (this.state.profileCompletionESGCheck === false) {
            toast.warn("Please complete your profile");
          } else if (this.state.esgSelfAssessmentCheck === false) {
            toast.warn("Please complete self assessment");
          } else {
            this.navigatePage(action, this.props)
          }
        } else {
          this.navigatePage(action, this.props)
        }
      }
    };
    navigatePage = (route: string, props: Props) => {
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), route);
  
      message.addData(getName(MessageEnum.NavigationPropsMessage), props);
      
      this.send(message);
    }

     getUserData = async () =>  {
           const userString = await getStorageData('user');
          const userData = JSON.parse(userString) || {};
          this.userDataId = userData.id;
          this.userCompanyName = userData.company_name;
          this.userTypeInfo = userData.user_type;
          this.userRoleData = userData.role?.role_level;
  }
  // Customizable Area End
}

import React from "react";

// Customizable Area Start
import {
    Box,
    Button,
    Typography,
    Modal,
    Backdrop,
    Grid,
    IconButton,
    Avatar,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { styled } from '@material-ui/styles';
import SearchSelect, { Checkbox } from "../../../../components/src/SearchSelect.web";
import { Formik, Form } from 'formik';
// @ts-ignore
import { InputRow, MInput, MCountedTextArea, } from '../../../../components/src/FormModal.web';
import Flex from '../../../../components/src/Flex.web';
import OverlayLoader from '../../../../components/src/Loader.web'
import UploadButton from '../../../../components/src/UploadButton.web'
import AddBaselineReportingController, { Props } from "./AddBaselineReportingController";

// Customizable Area End

export default class NewPolicy extends AddBaselineReportingController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End

    render() {
        // Customizable Area Start

        // Customizable Area End
        return (
            // Customizable Area Start
            <div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",

                    }}
                    open={this.state.openModal}
                    onClose={(_, reason) => {
                        if (reason !== "backdropClick") {
                            this.onCloseModal();
                        }
                    }}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            background: '#fff',
                            borderRadius: 8,
                            width: '650px',
                            position: "relative",
                            margin: '10px',
                        }} className="schedule_modal">
                        <Grid container spacing={0}>

                            {/* Modal Title */}
                            <ModalTitle item xs={12} >
                                <Typography variant="h6">
                                    CREATE NEW POLICY
                                </Typography>
                                {/* Close Btn */}
                                <CloseIconButton onClick={this.onCloseModal}>
                                    <CloseIcon style={{}} />
                                </CloseIconButton>
                                {/* Close Btn END */}
                            </ModalTitle>
                            {/* Modal Title END */}

                            <ModelBody item xs={12} >
                                <Formik initialValues={this.initialValues} validationSchema={this.validationSchema} onSubmit={this.onSumitForm}>
                                    {({ errors, touched, setFieldValue, setTouched }) => (
                                        <RightFormWrapper translate="no">
                                            <div>
                                                <InputRow
                                                    label="Policy Name*"
                                                    rowClass='formRow'
                                                    labelClass='formLabel'
                                                    input={<><MInput disabled={this.state.isViewRequest} name="title" placeholder="" /></>}
                                                />
                                                <InputRow
                                                    label="Organization vision*"
                                                    rowClass='formRow'
                                                    labelClass='formLabel'
                                                    input={
                                                        <MCountedTextArea name="description" placeholder="Type here" />
                                                    }
                                                />
                                                <div style={{ margin: '10px 0' }}>
                                                    <UploadButton
                                                        filename={"attachmentName"}
                                                        maxSize={20 * 1024 * 1024}
                                                        name="attachments"
                                                        label="Upload Attachment"
                                                    />
                                                </div>
                                                <InputRow
                                                    label={<Flex>Created By<sup>*</sup>
                                                        <Typography color="textSecondary"></Typography></Flex>}
                                                    rowClass='formRow'
                                                    labelClass='formLabel'
                                                    input={
                                                        <SearchSelect
                                                            selectProps={{ style: { minHeight: '56px' }, disabled: true }}
                                                            hasMore={!!this.state?.userPagination?.next_page}
                                                            nextPage={this.paginateUsersOptions}
                                                            loading={false}
                                                            onSearch={this.handleUserSearch}
                                                            allItems={(this.state?.created_by || [])}
                                                            items={(this.state?.created_by || [])}
                                                            name="created_by_id"
                                                            label={"Select User"}
                                                            placeholder="Select User"
                                                            SelectItem={SelectItemUser}
                                                            SelectedItem={SelectedItem}
                                                            getOptionValue={(user: any) => `${user.first_name} ${user.last_name}`}
                                                        />
                                                    }
                                                />

                                            </div>

                                            {((!this.state.isViewRequest && this.state?.isFormInitialized) || (!this.state.isViewResponce)) && (
                                                <Grid item xs={12} style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'cender',
                                                    flexDirection: 'row',
                                                }}>
                                                    <DialogCancelButton onClick={this.onCloseModal}>
                                                        CANCEL
                                                    </DialogCancelButton>
                                                    <ShareNowButton color="primary" variant="contained" disableElevation type="submit">
                                                        CREATE
                                                    </ShareNowButton>
                                                </Grid>)
                                            }
                                        </RightFormWrapper>
                                    )}
                                </Formik>
                            </ModelBody>
                            <OverlayLoader loading={Boolean(this.state.respondRequestCallId || this.state.submitRequestCallId)} />
                        </Grid>
                    </Box>
                </Modal >
            </div >
            // Customizable Area End
        );
    }
}

// Customizable Area Start




const SelectItemUser = ({ item, value }: { item: any, value: any }) => {
    return <>
        <Grid container>
            <Grid item xs={12} style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}>
                <div>
                    <SelectItemUserText >{`${item.first_name || ''} ${item.last_name || ''}`}</SelectItemUserText>
                    <Avatar src={(item?.profile_pic)} aria-label="profile-pic">
                    </Avatar>
                </div>
                <span>{`${item.email}`}</span>
                <Checkbox checked={value?.indexOf?.(item.id) > -1} />
            </Grid>
        </Grid>
    </>
}

const SelectedItem = ({ items, value }: { items: any, value: any }) => {
    return <>
        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
            {(items || []).map((selectedValue: any, i: any) => {
                return (<Typography key={i} align="left">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar className='ml10' style={{ width: '30px', height: '30px', }} src={selectedValue.profile_pic} aria-label="profile-pic"></Avatar>
                        <span className='ml5'>{`${selectedValue.first_name} ${selectedValue.last_name}` || ''}</span>
                    </div>
                </Typography>)
            })}
        </div>
    </>
}


const ModelBody = styled(Grid)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '15px 32px'
})


const SelectItemUserText = styled('span')({
    overflow: 'hidden',
    textOverflow: 'ellipsis'
})

const CloseIconButton = styled(IconButton)({
    background: '#676767',
    color: '#fcfcfc',
    width: '32px',
    height: '32px',
    '&:hover': {
        background: '#999',
        opacity: 0.9
    }
})

const DialogCancelButton = styled(Button)({
    color: '#FFF',
    background: '#D0D0D0',
    padding: '10px 18px 10px 18px',
    margin: '5px',
    '&:hover': {
        background: '#999',
        opacity: 0.9
    }
})

const ShareNowButton = styled(Button)({
    padding: '10px 18px 10px 18px',
    margin: '5px',
    '&:hover': {
        opacity: 0.9
    }
})

const ModalTitle = styled(Grid)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItem: 'center',
    flexDirection: 'row',
    width: '100%',
    borderBottom: '0.2px solid #cbc1c1',
    padding: '10px 20px 10px 32px'
})

const RightFormWrapper = styled(Form)({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '25px',
    padding: '10px 0',
    overflowX: 'hidden',
    paddingRight: '10px',
    maxHeight: '500px'
})

const CharCounter = styled(Typography)({
    position: 'relative',
    bottom: 0,
    right: '10px',
})

// Customizable Area End

import React from "react";
// Customizable Area Start
import {
    MenuItem,
    Avatar,
    Typography,
    ListItemText,
    Checkbox,
    Backdrop, Modal, Fade,
    Box
} from '@material-ui/core';
import { styled } from '@material-ui/styles';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Flex from "../../../components/src/Flex.web";
import { Formik} from 'formik'
import FormModal, { InputRow, MInput, MSelect, MCountedTextArea, MDatePicker } from '../../../components/src/FormModal.web'
import UserContext from "../../../components/src/UserProvider";
import SearchSelect from "../../../components/src/SearchSelect.web";
import OverlayLoader from '../../../components/src/Loader.web';
import { CloseCircleIcon } from "../../../components/src/Icons.web";
import UploadButtonInput from '../../../components/src/UploadButtonnew.web'
// Customizable Area End
import CreateRequestToListController, { Props } from "./ProjectMilstoneController";

// Customizable Area Start
interface KpiModalInterface {
    showModal: boolean
    onClose?: any
}

export const KpiModal = ({ showModal, onClose }: KpiModalInterface) => {
    return (
        <Modal
            open={showModal}
            BackdropProps={{
                timeout: 500,
            }}
            BackdropComponent={Backdrop}
          
            onClose={() => onClose()}
            closeAfterTransition
        >
            <Fade in={showModal}>
                <Box style={{ justifyContent: 'center', maxHeight: '83vh',display: 'flex', alignItems: 'center',  marginTop: '10vh' }}>

                    <Box style={{ backgroundColor: '#fff', padding: '30px 30px', borderRadius: '8px',position: 'relative',  width: '650px', margin: '0 auto' }}>
                        <div style={{ marginTop: "-12", fontSize: '20px', textAlign: 'start', fontWeight: 600 }}>
                            KPI
                        </div>
                        <Box  onClick={() => onClose()} id='close' style={{ position: 'absolute', right: '15px', top: '15px', color: '#fff', width: '30px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', borderRadius: '8px' }}>
                            <CloseCircleIcon />
                        </Box>
                        <div style={{ display: 'flex' }}>
                            <KPIInformation >
                                <h5>Energy productivity</h5>
                                <p><span />GDP</p>
                                <p><span />USD ($) value-added</p>
                                <p><span />units of revenu</p>
                                <p><span />ounces of gold</p>
                                <p><span />ounces of platinum</p>
                                <p><span />metric tons of aggregate</p>
                                <p><span />metric tons of aluminum</p>
                                <p><span />metric tons of steel</p>
                                <p><span />metric tons of cement</p>
                                <p><span />metric tons of cardboard</p>
                                <p><span />metric tons of product</p>
                                <p><span />metric tons of ore processed</p>
                                <p><span />square meters</p>
                                <p><span />klometers</p>
                                <p><span />passenger kilometers</p>
                                <p><span />revenue passenger kilometers</p>
                                <p><span />liters of product</p>
                                <p><span />units of production</p>
                                <p><span />units of service provided</p>
                                <p><span />square feet</p>
                                <p><span />megawatt hours (MWh)</p>
                                <p><span />barrel of oil equivalents (BOE)</p>
                                <p><span />ton of oil equivalents (TOE)</p>
                                <p><span />ton of coal equivalents (TCE)</p>
                                <p><span />Other</p>
                            </KPIInformation>
                            <KPIInformation >
                                <h5>Low-carbon buildings</h5>
                                <p><span />Percentage of net zero carbon buildings</p>
                                <p><span />Percentage of net zero energy buildings</p>
                                <p><span />Percentage of buildings with a green building</p>
                                <p><span />Other</p>

                                <h5>Land use change</h5>
                                <p><span />hectares reforested</p>
                                <p><span />hectares restored</p>
                                <p><span />Other</p>
                            </KPIInformation>
                        </div>

                    </Box>
                </Box>
            </Fade>
        </Modal>
    )
}
// Customizable Area End

export default class CreateRequestToList extends CreateRequestToListController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    selectedItem: any;
    getuserDetails: any;

    CreateTemplateModal() {
        let initialValues: any = {
            milestone_leader: [],
            milestone_moderator: [],
            start_date: null,
            end_date: null
        }













        return <>
            <Formik enableReinitialize initialValues={initialValues} onSubmit={this.handelSubmit} validationSchema={this.validationSchema}


            >
                {({ values, setFieldValue, setTouched }: any) => (
                    <ModalWrap>
                        <FormModal modalStyle={{ maxWidth: '700px' }} headerTitle={<b>ADD MILESTONE</b>} submitLabel={"Save"} onClose={()=>{
                            this.getMilestoneApi()
                            this.goBack()}} >
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                                <InputRow label={<JobLabel>Objective ID</JobLabel>} input={
                                    <MInput name="objectiveId" value={this.state.projectDetails?.objective_id} disabled />
                                } />

                                <InputRow label={<JobLabel>Project ID</JobLabel>} input={
                                    <MInput name="projectId" value={this.state.projectDetails?.project_id} disabled />
                                } />

                                <InputRow label={<JobLabel>Milestone ID</JobLabel>} input={
                                    <MInput name="project_name" value={this.state.milestoneUniqueId} disabled />
                                } />
                            </div>
                            <InputRow label={<JobLabel>Milestone Name*</JobLabel>} input={
                                <MCountedTextArea length={100} maxRows={1} name="milestone_name" placeholder="" />
                            } />

                            <InputRow label={<JobLabel>Milestone Category*</JobLabel>}

data-test-id="milestone_category"

                                input={<Autocomplete
                                    autoHighlight
                                    onChange={(e, option) => { this.handleRequestTypeChange(e, option, setTouched, setFieldValue) }}
                                    loading={false}
                                    options={this.state.milestoneCategoriesList}
                                    data-test-id="search_milestone_category"
                                    getOptionLabel={(option: any) => option?.attributes?.name}
                                    filterSelectedOptions
                                    ListboxProps={{ style: { maxHeight: '150px' } }}
                                    renderOption={(option, { selected }) => (
                                        <>
                                            {option?.attributes?.name}
                                        </>
                                    )}

                                    renderInput={(params) => (
                                        <MInput
                                            {...params}
                                            name="milestone_category"
                                            InputProps={{
                                                ...params.InputProps,
                                            }}
                                            variant="outlined"
                                            placeholder="Search"
                                        />
                                    )}
                                />
                                }


                            />
                            <Flex gap={8}>
                                <InputRow label={<JobLabel>Start Date*</JobLabel>} style={{ width: "50%" }} input={

                                    <MDatePicker name="start_date" disablePast
                                        InputProps={{ placeholder: "Enter Date" }}
                                        minDate={this.state?.milestoneEnddate || null}

                                        maxDate={values.end_date != null ? values?.end_date : undefined}
                                    />
                                } />
                                <InputRow label={<JobLabel>End Date*</JobLabel>} style={{ width: "50%" }} input={

                                    <MDatePicker name="end_date" disablePast
                                        InputProps={{ placeholder: "Enter Date" }}
                                        minDate={values.start_date || null}
                                    />
                                } />
                            </Flex>
                            <Flex gap={8}>
                                <InputRow label={<JobLabel>Milestone Leader*</JobLabel>}  data-test-id="milestone_leader" style={{ width: "50%" }} input={<SearchSelect
                                    multiple={true}
                                    pagination={this.state.requestorPagination}
                                    hasMore={!!this.state.requestorPagination?.next_page}
                                    nextPage={this.paginateLeaders}
                                    loading={false}
                                    onSearch={this.handleLeaderSearchQuery}
                                    allItems={this.state.leaderLists}
                                    selectProps={{ onClose: this.handleLeadersClose }}
                                    items={this.state.leaderLists}
                                    name="milestone_leader"
                                    data-test-id="searchselect1"
                                    SelectItem={SelectItemUser}
                                    getOptionValue={(user: any) => user.profile_pic}
                                    isAvatar={true}
                                />}
                                />

                                <InputRow label={<JobLabel>Milestone Moderator*</JobLabel>}data-test-id="milestone_moderator" style={{ width: "50%" }} input={<SearchSelect
                                    multiple={true}
                                    pagination={this.state.moderatorPagination}
                                    hasMore={!!this.state.moderatorPagination?.next_page}
                                    nextPage={this.paginateModerator}
                                    loading={false}
                                    onSearch={this.handleModeratorSearchQuery}
                                    allItems={this.state.moderatorLists}
                                    selectProps={{ onClose: this.handleModeratorClose }}
                                    items={this.state.moderatorLists}
                                    name="milestone_moderator"
                                    data-test-id="searchselect"
                                    SelectItem={SelectItemUser}
                                    getOptionValue={(user: any) => user.profile_pic}
                                    isAvatar={true}
                                />}
                                />

                            </Flex>

                            <InputRow label={<JobLabel >KPI*
                                <InfoIcon onClick={() => this.setState({ kpiModal: true })
                                }
                                    style={{ position: 'relative', bottom: "-5px", right: "-8px", fontSize: '19px', cursor: 'pointer' }}
                                /></JobLabel>} input={
                                    <MInput name="kpi" placeholder="" />
                                } />

                            <Flex gap={8}>
                                <InputRow label={<JobLabel>UoM*</JobLabel>} style={{ width: "50%" }} input={
                                    <MSelect
                                        name="uom"
                                        placeholder="Select"
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left"
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left"
                                            },
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 200,
                                                },
                                            },
                                            getContentAnchorEl: null,
                                        }}
                                    >
                                        <MenuItem disabled value={-1}>Select</MenuItem>
                                        {this.state.uomList?.map((item: any) => {
                                            return <MenuItem value={item?.id}>
                                                {item?.attributes?.unit_name}
                                            </MenuItem>
                                        })}


                                    </MSelect>
                                } />

                                <InputRow label={<JobLabel>Baseline (Numeric)</JobLabel>} style={{ width: "50%" }} input={
                                    <MInput name="baseline" placeholder="" />
                                } />

                            </Flex>

                            {values?.uom == "13" && <Flex gap={8}>
                                <InputRow label={''} style={{ width: "50%" }} input={
                                    <MInput name="uom_please_specify" placeholder="Please specify" />
                                } />
                            </Flex>
                            }

                            <Flex gap={8}>

                                <InputRow label={<JobLabel>Target Output Direct (Numeric)</JobLabel>} style={{ width: "50%" }} input={
                                    <MInput name="target_output_direct" placeholder="" />
                                } />

                                <InputRow label={<JobLabel>Actual Output Direct (Numeric)</JobLabel>} style={{ width: "50%" }} input={
                                    <MInput name="actual_output_direct" placeholder="" />
                                } />
                            </Flex>


                            <InputRow label={<JobLabel>Target Outcome Direct*</JobLabel>} input={
                                <MCountedTextArea length={100} maxRows={1} name="target_outcome_Direct" placeholder="" />
                            } />

                            <InputRow label={<JobLabel>Actual Outcome Direct</JobLabel>} input={
                                <MCountedTextArea length={100} maxRows={1} name="actual_outcome_Direct" placeholder="" />
                            } />

                            <Flex gap={8}>

                                <InputRow label={<JobLabel>Target Output Indirect (Numeric)</JobLabel>} style={{ width: "50%" }} input={
                                    <MInput name="target_output_indirect" placeholder="" />
                                } />

                                <InputRow label={<JobLabel>Actual Output Indirect (Numeric)</JobLabel>} style={{ width: "50%" }} input={
                                    <MInput name="actual_output_indirect" placeholder="" />
                                } />
                            </Flex>
                            <InputRow label={<JobLabel>Target Outcome Indirect*</JobLabel>} input={
                                <MCountedTextArea length={100} maxRows={1} name="target_outcome_indirect" placeholder="" />
                            } />

                            <InputRow label={<JobLabel>Actual Outcome Indirect</JobLabel>} input={
                                <MCountedTextArea length={100} maxRows={1} name="actual_outcome_indirect" placeholder="" />
                            } />
                            <Flex gap={8}>

                                <InputRow label={<JobLabel>Estimated Cost*</JobLabel>} style={{ width: "50%" }} input={
                                    <MInput name="estimated_cost" placeholder="" />
                                } />

                                <InputRow label={<JobLabel>Actual Cost</JobLabel>} style={{ width: "50%" }} input={
                                    <MInput name="actual_cost" placeholder="" />
                                } />
                            </Flex>

                            <InputRow label={<JobLabel>Estimated Inputs</JobLabel>} input={
                                <MCountedTextArea length={100} maxRows={1} name="estimated_inputs" placeholder="" />
                            } />

                            <InputRow label={<JobLabel>Actual Inputs</JobLabel>} input={
                                <MCountedTextArea length={100} maxRows={1} name="actual_inputs" placeholder="" />
                            } />

                            <Flex gap={8}>

                                <InputRow label={<JobLabel>Estimated Capex</JobLabel>} style={{ width: "50%" }} input={
                                    <MInput name="estimated_capex" placeholder="" />
                                } />

                                <InputRow label={<JobLabel>Actual Capex</JobLabel>} style={{ width: "50%" }} input={
                                    <MInput name="actual_capex" placeholder="" />
                                } />
                            </Flex>

                            <Flex gap={8}>

                                <InputRow label={<JobLabel>Estimated Savings</JobLabel>} style={{ width: "50%" }} input={
                                    <MInput name="estimated_saving" placeholder="" />
                                } />

                                <InputRow label={<JobLabel>Actual Savings</JobLabel>} style={{ width: "50%" }} input={
                                    <MInput name="actual_saving" placeholder="" />
                                } />
                            </Flex>


                            <InputRow label={<JobLabel>Type of Investment*</JobLabel>} input={
                                <MSelect name="type_of_investment" placeholder="Select"
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "left"
                                        },
                                        PaperProps: {
                                            style: {
                                                maxHeight: 200,
                                            },
                                        },
                                        getContentAnchorEl: null,
                                    }}
                                >
                                    <MenuItem disabled value={-1}>Select</MenuItem>
                                    {this.state.typeOfInvestmentsLists.map((item: any) => {
                                        return <MenuItem value={item?.attributes?.id}>{item?.attributes?.name}</MenuItem>
                                    })}

                                </MSelect>
                            } />

                            {values?.type_of_investment == "16" &&
                                <InputRow label={''} input={
                                    <MInput name="type_of_investment_please_specify" placeholder="Please specify" />
                                } />
                            }

                            <InputRow label={<JobLabel>Upload Milestone Plan</JobLabel>} input={
                                <>
                                    <div style={{ margin: '10px 0' }}>
                                        <UploadButtonInput
                                            multiple
                                            input
                                            name="project_milestone_pdfs"
                                            setFileInState={this.setFilesArray}
                                        />
                                    </div>
                                </>
                            } />

                            <InputRow label={<JobLabel>
                                Description*</JobLabel>} input={
                                    <MCountedTextArea length={500} name="description" placeholder="" />
                                } />

                            <InputRow label={<JobLabel>Milestone Status*</JobLabel>} input={
                                <MSelect name="status" placeholder="Select"  >
                                    <MenuItem value="Inprogress">Inprogress</MenuItem>
                                    <MenuItem value="Live">Live</MenuItem>
                                    <MenuItem value="On Hold">On Hold</MenuItem>
                                    <MenuItem value="Closed">Closed</MenuItem>
                                </MSelect>
                            } />
                            <OverlayLoader loading={!!this.state.submittingForm} />
                        </FormModal>
                    </ModalWrap>
                )}

            </Formik>
            {this.state.kpiModal && <KpiModal showModal={this.state.kpiModal} onClose={() => this.closeKpiModal()} />}
        </>
    }

    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            <>
                {this.CreateTemplateModal()}
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

CreateRequestToListController.contextType = UserContext



export const SelectItemUser = ({ item, value }: { item: any, value: any[] }) => {
    return <div style={{ width: '100%', display: 'flex', flexDirection: 'row-reverse', alignItems: 'center' }}>
        <ListItemText style={{ flex: 2 }} disableTypography primary={
            <div style={{ display: "flex", alignItems: "center" }}>
                {Array.isArray(value) && <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" style={{ color: "#1ea60a" }} />}
                    checked={value?.findIndex((x) => Number(x) === item?.id) > -1}
                />}
                <Avatar alt="Remy Sharp" src={item.profile_pic} style={{ width: "30px", height: "30px", marginRight: "10px" }} />

                <Typography style={{ color: "#333232", fontWeight: "bold", fontSize: 12, width: '35ch', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {item.first_name} {item.last_name}
                </Typography>
                <Typography component={'div'} style={{ width: '38ch', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {item.email}
                </Typography>
            </div>
        } />
    </div>
}


const JobLabel = styled('div')({
    fontSize: '15.35px',
    color: '#404040',
    fontFamily: 'roboto'
})



const ModalWrap = styled('div')({
})
const BorderDocument = styled('div')({
    border: '1px solid #999',
    padding: '10px 15px',
    borderRadius: '15px',
    display: 'flex',
    alignItems: 'center',
    width: '50%',
    position: 'relative',
    marginTop: '5px'
})

const FileInformation = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '15px',
    gap: '5px',
})

const KPIInformation = styled('div')({
    width: '50%',
    display: 'inline-block',
    '& h5': {
        fontSize: '15px',
        marginBottom: '10px',
        fontWeight: '600'
    },
    '& p': {
        margin: '2px',
        '& span': {
            height: '10px',
            width: '10px',
            background: 'red',
            borderRadius: '50%',
            display: 'inline-block',
            marginRight: '5px'
        }
    }
})

// Customizable Area End
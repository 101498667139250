import React, { useState, useEffect } from "react";
// Customizable Area Start

import { Box, Typography, Divider, Button } from '@material-ui/core';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import Avatar from '@material-ui/core/Avatar';
import { styled } from '@material-ui/styles';
import { sendSharePopupMessage } from '../../share/src/Messages';

// @ts-ignore
export const configJSON = require("./config");
const CloseIcon = require('../assets/ic_close.png');

const QuickBlox = require('quickblox/quickblox.min').QuickBlox;
const QB = new QuickBlox();
import { LocationIcon, ThumbGreenIcon } from "./assets";
import { Link } from 'react-router-dom';
import { CommonActions } from "../../../components/src/PostCard.web";
import LikeAPost from "../../LikeAPost/src/LikeAPost.web";
import ProjectBlock from "./ProjectBlock.web";

//customizable Area End
const statusButton = (status: any) => {
    let statusButton;

    switch (status) {
        case 'Open':
            statusButton = <LiveBtnOpen>Open</LiveBtnOpen>;
            break;
        case 'Closed':
            statusButton = <LiveBtnClosed>Closed</LiveBtnClosed>;
            break;
        case 'Funded':
            statusButton = <LiveBtnFunded>Funded</LiveBtnFunded>;
            break;

        default:
            statusButton = <></>;
    }

    return statusButton;
}
export const SeekingFundDetailModel: Function = ({ detail, onCloseClick, navigation, handleLikeChange }: any) => {

    const [isCommentSection, setIsCommentSection] = useState(false);
    const [isLikeChange, setIsLikeChange] = useState(false);

    useEffect(() => {
        if (detail?.id) {
            handleLikeChange(detail.id);
        }
    }, [isLikeChange]);

    const handleSharePost = () => {
        let data = {
            link: `${window.location.origin}/${configJSON.likeSharePost}/${detail?.attributes?.account_id}`,
            shareable_type: "BxBlockProjectportfolio::PromoteContent",
            shareable_id: detail?.id.toString(),
        }
        sendSharePopupMessage(data);
    }

    const handleComment = () => {
        setIsCommentSection(true);
    }

    return <>
        <ModalSection>
            <SurveyModelWrapper>
                <SurveyModelSection>
                    <SectionItem style={{ fontSize: "14px", marginBottom: '0' }}>
                        <TitleSection>
                            <TitleDivContainer>
                                <Typography style={{ fontSize: "24px", color: "#2d3237" }} variant="subtitle1" component="div">
                                    {detail?.attributes?.project_name}
                                </Typography>
                                <SubTitle style={{ fontSize: "18px", color: "#6e6e6e", display: "flex", alignItems: "flex-end" }}>
                                    <img src={LocationIcon} width="20" /><span>&nbsp;&nbsp;{detail?.attributes?.location}</span>
                                </SubTitle>
                            </TitleDivContainer>
                            <DivBtnSection>
                                {statusButton(detail?.attributes?.project_status)}
                                <BtnImg id="CloseBtn" src={CloseIcon} onClick={() => onCloseClick()} style={{ height: "40px", width: "40px" }} alt="close" />
                            </DivBtnSection>
                        </TitleSection>
                    </SectionItem>
                    <Divider />
                    <MobileSectionItem>
                        <DivSection>
                            <SectionItem style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
                                <SubTitle style={{ fontSize: "14px", color: "#3d3d3d" }}>
                                    Project ID&nbsp;&nbsp;:&nbsp;&nbsp;
                                </SubTitle>
                                <Typography variant="subtitle2" component="div" style={{ fontSize: "14px", fontWeight: 'bold', lineHeight: "normal", color: '#393b3e' }}>
                                    {detail?.attributes?.project_unique_id}
                                </Typography>
                            </SectionItem>
                            <SectionItem style={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
                                <SubTitle style={{ fontSize: "14px", color: "#3d3d3d" }}>
                                    Preferences&nbsp;&nbsp;:&nbsp;&nbsp;
                                </SubTitle>

                                {/* <UserAvtarDetail>
                            <Avatar style={{ marginRight: "5px", height: "30px", width: "30px", border:"3px solid rgb(242, 212, 212)" }} src={detail?.attributes?.created_by?.profile_pic} alt={detail?.attributes?.created_by?.first_name} /> */}
                                <Typography variant="subtitle2" component="div" style={{ color: "#393b3e", fontWeight: 'bold' }}>
                                    {detail?.attributes?.preferences?.join(", ")}
                                </Typography>
                                {/* </UserAvtarDetail> */}
                            </SectionItem>
                            <SectionItem style={{ display: "flex", alignItems: "center" }}>
                                <SubTitle style={{ fontSize: "14px", color: "#3d3d3d" }}>
                                    {`Funding Required\u00A0\u00A0:\u00A0\u00A0`}
                                </SubTitle>
                                <SubTitle style={{ fontSize: "18px", color: "green", fontWeight: "bold" }}>
                                    {`$${detail?.attributes?.funding_amount}`}
                                </SubTitle>
                            </SectionItem>
                        </DivSection>
                        <DivMobileSection style={{ marginTop: '-5%' }}>
                            <MobileSectionItemLeft>
                                <SubTitle style={{ fontSize: "14px", color: "#3d3d3d" }}>
                                    Organization Name&nbsp;&nbsp;:&nbsp;&nbsp;
                                </SubTitle>
                                <Typography variant="subtitle2" component="div" style={{ fontSize: "14px", fontWeight: 'bold', lineHeight: "normal", color: '#393b3e' }}>
                                    {detail?.attributes?.organization_name}
                                </Typography>
                            </MobileSectionItemLeft>
                            <MobileSectionItemLeft>
                                <SubTitle style={{ fontSize: "14px", color: '#585858' }}>
                                    SDG Focus&nbsp;&nbsp;:&nbsp;&nbsp;
                                </SubTitle>
                                <AvatarGroup spacing='small'>
                                    {detail?.attributes?.images?.map((sdgImageURL: any, index: any) => <Avatar style={{ marginRight: "5px", height: "25px", width: "25px", border: "3px solid rgb(255, 255, 255)" }} src={sdgImageURL.length > 0 && sdgImageURL[0]?.image_url} alt={detail?.attributes?.goals_sdg[index]} />)}
                                </AvatarGroup>
                            </MobileSectionItemLeft>
                        </DivMobileSection>
                    </MobileSectionItem>
                    <Divider />
                    <SectionItem>
                        <Typography variant="subtitle1" component="div" style={{ fontSize: "16px", color: '#363c43' }}>Description</Typography>
                        <Typography variant="subtitle2" component="div" style={{ fontSize: "14px", color: "#bababa", wordWrap: 'break-word' }}>
                            {detail?.attributes?.description}
                        </Typography>
                    </SectionItem>
                    <Divider />
                    <LikeWrapper><img src={ThumbGreenIcon} width="30" /> <span>{detail?.attributes?.likes?.attributes?.like_count}</span></LikeWrapper>
                    <ActionItem>
                        <CommonActions
                            onStarClick={() => { }}
                            onShareClick={() => handleSharePost()}
                            isFavourite={false}
                            isHideComment={isCommentSection}
                            onCommentClick={() => handleComment()}
                            isHideStar={true}
                            likeButton={
                                <LikeAPost
                                    navigation={navigation}
                                    id=""
                                    likeable_type="BxBlockProjectportfolio::PromoteContent"
                                    isLiked={detail?.attributes?.is_liked || ""}
                                    post_id={detail?.attributes?.likes?.id}
                                    like_id={detail?.attributes?.project_like_id}
                                    onLikeChange={() => setIsLikeChange(!isLikeChange)}
                                    onLikeIdUpdate={() => { handleLikeChange(detail?.id) }}
                                />}
                        />
                    </ActionItem>
                    <Divider />

                    {isCommentSection ? <ProjectBlock navigation={navigation} detail={{ detail }} onClose={() => setIsCommentSection(false)} /> :
                        <div style={{display: "flex",justifyContent: "center"}}>
                            {detail.attributes.project_enquired? <PlanCardButton disabled id="launchSurveyBtn" style={{  backgroundColor: "#808080",color: "white",height: "45px", width: "100%" }}>
                                Enquire
                            </PlanCardButton> :
                                <Link style={{ width: "70%" }} to={`UserSurvey/EnquiryProjectSeekingFund/${detail.id}`}>
                                    <PlanCardButton onClick={() => onCloseClick()} id="launchSurveyBtn" style={{ backgroundColor: "#00A100", color: "white", height: "45px", width: "100%" }}>
                                        Enquire
                                    </PlanCardButton>
                                </Link>
                            }

                        </div>
                    }
                </SurveyModelSection>
            </SurveyModelWrapper>
        </ModalSection>
    </>
};

// Customizable Area Start


const LikeWrapper = styled('div')({
    display: "flex",
    alignItems: "center",
    gap: "8px"
})

const DivSection = styled('div')({
    cursor: "pointer",
    width: "50%"
})

const TitleDivContainer = styled('div')({
    display: "flex",
    flexDirection: "column",
    gap: "10px"
})

const DivBtnSection = styled('div')({
    cursor: "pointer",
    display: "flex",
    gap: "10px",
    alignItems: "center"
})

const InactiveBtn = styled(Typography)({
    background: '#E4F4E7',
    border: "1px solid #F2F4F2",
    borderRadius: '10px',
    color: '#45974D',
    padding: '5px 25px',
    fontSize: '14px',
    width: 'max-content',
    fontWeight: 'bold',
    mixBlendMode: 'normal',
    textTransform: 'capitalize',
    height: 'max-content'
});

const DivMobileSection = styled('div')({
    display: "flex",
    flexDirection: "column",
    rowGap: "3px"
})

const ModalSection = styled('div')({
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%,-50%)`,
    position: `absolute`,
})

const LaunchSurveySection = styled('div')({
    display: "flex",
    justifyContent: "center"
})

const PlanCardButton = styled(Button)({
    border: '2px solid transparant',
    color: '#009B07',
    fontWeight: 600,
    backgroundColor: '#FFFFFF',
    textTransform: 'none',
    borderRadius: '38px',
    // height:'100px',
    padding: '0 65px 0 65px',
    '@media screen and (max-width : 1110px) and (min-width:769px)': {
        padding: '0 35px 0 35px',
    },
    '@media screen and (max-width: 624px)': {
        padding: '0 35px 0 35px',
    },
    '@media screen and (max-width: 500px)': {
        padding: '0 25px 0 25px'
    }
});

const SectionItem = styled(Box)({
    // margin: '8px 0px',
    '& .MuiTypography-root.MuiTypography-subtitle1': {
        fontWeight: 'bold',
        fontSize: 11,
    },
    '& .MuiTypography-root.MuiTypography-subtitle2': {
        fontWeight: 'normal',
        color: 'grey',
        fontSize: 14,
        // paddingTop: '10px',
    }
});

const ActionItem = styled(Box)({
    '& div': {
        padding: "0"
    }
})

const MobileSectionItem = styled(Box)({
    margin: '0px',
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    marginBottom: '-10px',
    '& .MuiTypography-root.Muitypography-subtitle-1': {
        fontWeight: 'bold',
        fontSize: 14
    },
    '& .MuiTypography-root.MuiTypography-subtitle2': {
        fontWeight: 'normal',
        color: 'grey',
        fontSize: 14,
    },
    '@media screen and (max-width:486px)': {
        display: 'block'
    }
});

const MobileSectionItemLeft = styled(Box)({
    margin: '8px 0px',
    display: 'flex',
    alignItems: 'center',
    '& .MuiTypography-root.MuiTypography-subtitle1': {
        fontWeight: 'bold',
        fontSize: '12px',
    },
    '& .MuiTypography-root.MuiTypography-subtitle2': {
        fontWeight: 'normal',
        color: 'grey',
        fontSize: 14
    },
});

const SurveyModelWrapper = styled(Box)({
    background: 'white',
    borderRadius: "20px",
    padding: "35px",
    width: '700px',
    '@media screen and (max-width:768px)': {
        width: '550px'
    },
    '@media screen and (max-width:600px)': {
        width: '420px'
    },
    '@media screen and (max-width:468px)': {
        width: '320px'
    },
    '@media screen and (max-width:370px)': {
        width: '245px'
    }
})

const SurveyModelSection = styled(Box)({
    backgroundColor: "white",
    overflow: "auto",
    display: "flex",
    gap: "18px",
    flexDirection: "column",
    justifyContent: "space-between",
    maxHeight: '80vh',
});

const BtnImg = styled('img')({
    height: "40px",
    width: "40px"
});

const TitleSection = styled('div')({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "start",
    color: '#303539'
})

const SubTitle = styled('div')({
    fontWeight: 'normal'
})
const LiveBtnOpen = styled(Typography)({
    background: '#e4f4e7',
    border: '1px solid #E5EDE6',
    borderRadius: '10px',
    color: '#388141',
    padding: '8px 25px',
    fontSize: '14px',
    width: 'max-content',
    fontWeight: 'bold',
    // opacity: 0.11,
    mixBlendMode: 'normal',
    textTransform: 'capitalize',
})

const LiveBtnFunded = styled(Typography)({
    background: '#e3f0ff',
    border: '1px solid #e3f0ff',
    borderRadius: '10px',
    color: '#3187e0',
    padding: '8px 25px',
    fontSize: '14px',
    width: 'max-content',
    fontWeight: 'bold',
    // opacity: 0.11,
    mixBlendMode: 'normal',
    textTransform: 'capitalize',
})

const LiveBtnClosed = styled(Typography)({
    background: '#ffe4e5',
    border: '1px solid #ffe4e5',
    borderRadius: '10px',
    color: '#e93951',
    padding: '8px 25px',
    fontSize: '14px',
    width: 'max-content',
    fontWeight: 'bold',
    // opacity: 0.11,
    mixBlendMode: 'normal',
    textTransform: 'capitalize',
})

// Customizable Area End
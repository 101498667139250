Object.defineProperty(exports, "__esModule", {
    value: true
});

// Customizable Area Start
exports.servicesListEndPoint = "/bx_block_content_management/marketplace_services"
exports.generateServiceId = "/bx_block_content_management/generate_marketplace_service_id"
exports.serviceDetailEndpoint = "/bx_block_content_management/marketplace_services/:id"
exports.serviceCreateAPIEndpoint = "/bx_block_content_management/marketplace_services/"
exports.serviceEnquireEndpoint = "/bx_block_content_management/service_enquiries"
exports.getUserRoleEndpoint = "account_block/accounts/get_user_role"
exports.rateServiceEndpoint = "/bx_block_content_management/rate_services"
exports.getServiceByIdEndpoint = "/bx_block_content_management/marketplace_services/:sid"
exports.getAllCommentEndpoint = "/bx_block_content_management/marketplace_service_comments/:sid"
exports.serviceCommentEndpoint = "/bx_block_content_management/comments"
exports.serviceCommentReplyEndpoint = "/bx_block_content_management/comments/create_reply"
exports.likeCommentEndpoint = "/bx_block_like/likes"
exports.deleteServiceEndpoint = "/bx_block_content_management/marketplace_services/:id"
exports.gerServiceFilterSearchEndpoint = "/bx_block_content_management/marketplace_services/my_marketplace_search_and_filter"

exports.setFavEndpoint = "bx_block_favourites/favourites/"
exports.setUnFavEndpoint = "bx_block_favourites/favourites/:id"

exports.corpstageCoins = "/bx_block_corpstage_coin/corpstage_coins"
exports.purchaseLinkEndPoint = "/bx_block_corpstage_coin/corpstage_coin_checkout"
exports.coinsListEndPoint = "bx_block_corpstage_coin/transaction_listing"
exports.getUsersEndpoint = "account_block/accounts/filter"
exports.transferCoinsEndPoint="bx_block_corpstage_coin/transfer_coin"
exports.importCsvEndpoint="bx_block_corpstage_coin/import_file"
exports.successMessageEndpoint="bx_block_corpstage_coin/coin_purchase_transaction"
// Customizable Area End

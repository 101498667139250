import React from "react";
// Customizable Area Start
import UpdatedReportsController, {
	Props,
} from "./UpdatedReportsController.web";
import { styled } from '@material-ui/styles'
import {
	Grid,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableCell as TableCellContainer,
	TableRow,
	Paper,
	Link,
	Backdrop,
	Box,
	Fade,
	Modal,
	Typography,
} from '@material-ui/core';
import TopNavBar from "../../../components/src/TopNavbar";
import LeftMenuWeb from "../../../components/src/LeftMenuWeb";
import { CloseCircleIcon } from "../../../components/src/Icons.web";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Pagination from '@material-ui/lab/Pagination';
import { xlsxIcon, DescriptionIcon, pdfIcon, csvIcon, docIcon, imageIcon } from './assets';
import { Divider } from "react-native-elements";
import OverlayLoader from '../../../components/src/Loader.web'
// Customizable Area End

export default class RiskOpportunityManagementReport extends UpdatedReportsController {
	constructor(props: Props) {
		super(props);
		// Customizable Area Start
		// Customizable Area End
	}
	// Customizable Area Start
	renderDocIcon(val:any){
		const lastDotIndex = val?.filename?.lastIndexOf(".");
		const extension= val?.filename?.slice(((lastDotIndex - 1) >>> 0) + 2) || ''
		const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp"];
		let imageToRender = '';

		//imageIcon
		if(imageExtensions.includes(extension.toLowerCase())){
			imageToRender = imageIcon
		}
		else if(extension=='pdf'){
			imageToRender = pdfIcon
		}
		else if(extension.includes('doc')){
			imageToRender=docIcon
		}
		else if(extension.includes('xls')){
			imageToRender=xlsxIcon
		}
		else if(extension=='csv'){
			imageToRender=csvIcon
		}
		
		return <div style={{display:"flex"}}>{lastDotIndex == -1 ? val?.filename : val?.filename.slice(0, 10) } <img style={{width:"24px", height:'24px', verticalAlign: 'middle', marginLeft: '5px'}} src={imageToRender}/></div>
	}

	// Customizable Area End

	render() {
  		// Customizable Area Start
		const { navigation } = this.props;

		const ImportButtonBase = (props: any) => {
			const icon = xlsxIcon
			const clickHandler = props.onClick || (() => { });
			const label = "Download"
			return <ImportButton onClick={clickHandler}>
				<img src={icon} width="25px" />{label}
			</ImportButton>
		}

		const DownloadBtn = () => {
			return <ImportButtonBase />
		}

		// Customizable Area End

		return (
      		// Customizable Area Start
			<MainBox>
				<Box>
					<TopNavBar navigation={this.props.navigation} id={""} />
				</Box>

				<OuterGrid container spacing={3}>
					<LeftGrid item xs={12} sm={12} md={3} lg={3}>
						<Box >
							<LeftMenuWeb 
								navigation={this.props.navigation} 
								id={""}
							/>
						</Box>
					</LeftGrid>
					<MiddleGrid item xs={12} sm={12} md={6} lg={6} >
						<div>
							<div style={{ justifyContent: "space-between", display: "flex", alignItems: "center" }}>
								<div style={{ display: "flex" }} >
									<div onClick={navigation.goBack} style={{ cursor: "pointer", margin: "40px 0px 15px 12px", }}  >
										<ArrowBackIosIcon />
									</div>
									<CoinsHead>RISK AND OPPORTUNITY MANAGEMENT REPORT </CoinsHead>
								</div>
								{(this.state.riskUrl !== undefined && this.state.riskUrl !== null && this.state.riskUrl?.length>0) ? <Link href={this.state.riskUrl}>
									<DownloadBtn />
								</Link> :
									<div id='download_report' onClick={() => this.showPopup()}>
										<DownloadBtn />
									</div>
								}
							</div>
							<div style={{ marginTop: '20px', alignItems: 'center' }}>
								<TableContainer component={Paper}>
									<Table>
										<TableHead>
											<TableRowBox>
												<TableCellContainer style={{ fontWeight: 'bold', color: 'black' }}>SL.no</TableCellContainer>
												<TableCellContainer style={{ fontWeight: 'bold', color: 'black' }}>Objective ID</TableCellContainer>
												<TableCellContainer style={{ fontWeight: 'bold', color: 'black' }}>Project ID</TableCellContainer>
												<TableCellContainer style={{ fontWeight: 'bold', color: 'black' }}>Project Name</TableCellContainer>
												<TableCellContainer style={{ fontWeight: 'bold', color: 'black' }}>Issue/Concern</TableCellContainer>
												<TableCellContainer style={{ fontWeight: 'bold', color: 'black' }}>Preferences</TableCellContainer>
												<TableCellContainer style={{ fontWeight: 'bold', color: 'black' }}>PESTLE</TableCellContainer>
												<TableCellContainer style={{ fontWeight: 'bold', color: 'black' }}>Scope Of Operations</TableCellContainer>
												<TableCellContainer style={{ fontWeight: 'bold', color: 'black' }}>Description</TableCellContainer>
												<TableCellContainer style={{ fontWeight: 'bold', color: 'black' }}>Category</TableCellContainer>
												<TableCellContainer style={{ fontWeight: 'bold', color: 'black' }}>Reference</TableCellContainer>
												<TableCellContainer style={{ fontWeight: 'bold', color: 'black' }}>Documents</TableCellContainer>
												<TableCellContainer style={{ fontWeight: 'bold', color: 'black' }}>Impact-Desc</TableCellContainer>
												<TableCellContainer style={{ fontWeight: 'bold', color: 'black' }}>{"Impact(1-5)"}</TableCellContainer>
											</TableRowBox>
										</TableHead>
										<TableBody>
											{this.state.riskAndOpportunityList?.length>0 ? this.state.riskAndOpportunityList?.map((value: any, index: any) => {
												const { attributes } = value
												return (
													<>
														<TableBodyBox>
															<TableCellContainer>{10 * (this.state.currentPageOfRisk - 1) + (index + 1)}</TableCellContainer>
															<TableCellContainer>{attributes?.objective_id}</TableCellContainer>
															<TableCellContainer >{attributes?.project_id}</TableCellContainer>
															<TableCellContainer>{attributes?.project_name}</TableCellContainer>
															<TableCellContainer>{attributes?.issues_concern}</TableCellContainer>
															<TableCellContainer>{attributes?.preferences?.toString()}</TableCellContainer>
															<TableCellContainer>{attributes?.pestile}</TableCellContainer>
															<TableCellContainer>{attributes?.scope_of_operations}</TableCellContainer>
															<TableCellContainer style={{ cursor: "pointer" }} id='viewDescription' onClick={() => attributes?.description!==null && this.handleModalOpen(value?.id)}>
																{attributes?.description!==null ? `${attributes?.description?.split(' ')[0]?.slice(0,10)}...` : ""}
																{attributes?.description!==null && <img style={{ width: '25px', verticalAlign: 'middle', }} src={DescriptionIcon} />}
															</TableCellContainer>
															<TableCellContainer>{attributes?.category}</TableCellContainer>
															<TableCellContainer>{attributes?.reference}</TableCellContainer>
															<TableCellContainer>{attributes?.pdf_documents !== "" ? attributes?.pdf_documents?.map((val: any) => <Link style={{ color: "rgba(0, 0, 0, 0.87)" }} href={val?.url}>{this.renderDocIcon(val)}</Link>) : " "}</TableCellContainer>
															<TableCellContainer>{attributes?.impact_desc}</TableCellContainer>
															<TableCellContainer>{attributes?.impact}</TableCellContainer>
														</TableBodyBox>
														{this.state.tableRowId == value?.id &&
															<DetailModal showModal={this.state.openModal} value={attributes?.description} handleModalClose={this.handleModalClose} />
														}
													</>
												)
											}) : <TableCellContainer style={{textAlign:"center"}} colSpan={14}>No Data Found</TableCellContainer> }
										</TableBody>
									</Table>
								</TableContainer>
								<Pagin>
									{window.screen.width > 950 ?
										<Pagination disabled={this.state.totalPageOfRisk <= 1} defaultPage={1} color="primary" variant="outlined" count={this.state.totalPageOfRisk} page={this.state.currentPageOfRisk} onChange={this.handlePageChangeOfRiskManagement} />
										:
										<Pagination disabled={this.state.totalPageOfRisk <= 1} defaultPage={1} color="primary" size="small" count={this.state.totalPageOfRisk} page={this.state.currentPageOfRisk} onChange={this.handlePageChangeOfRiskManagement} />
									}
								</Pagin>
								<OverlayLoader loading={this.state.overlayLoaderForRisk} />
							</div>
						</div>
					</MiddleGrid>
				</OuterGrid>
			</MainBox>
			// Customizable Area End
		)
	}
}

// Customizable Area Start
export const DetailModal = ({ showModal, handleModalClose, value }: any) => {
	return (
		<Modal
			open={showModal}
			onClose={() => handleModalClose()}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={showModal}>
				<Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
					<Box style={{ backgroundColor: '#fff', position: 'relative', padding: '30px 30px', borderRadius: '8px', textAlign: 'center', maxWidth: '500px', margin: '0 auto', minWidth: "408px" }}>
						<Box onClick={() => handleModalClose()} style={{ position: 'absolute', right: '15px', top: '15px', color: '#fff', width: '20px', height: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', borderRadius: '8px' }}>
							<CloseCircleIcon />
						</Box>
						<Typography variant="h6" component="h1">
							Details
						</Typography>
						<Divider />
						<div style={{ padding: "8px", marginTop: "5px", textAlign: "justify" }}>
							<Typography>{value}</Typography>
						</div>
						<Box style={{ display: 'flex', marginTop: '20px', alignItems: 'center', justifyContent: 'center', gap: '20px' }}>
							<button onClick={() => handleModalClose()} style={{ backgroundColor: 'red', color: '#fff', borderRadius: '5px', padding: '5px 20px', cursor: 'pointer', border: 'none' }}>Close</button>
						</Box>
					</Box>
				</Box>
			</Fade>
		</Modal>
	)
}

const MainBox = styled(Box)({
	backgroundColor: "#edebeb",
	display: "flex",
	flexWrap: 'wrap',
	flexDirection: "column",
	paddingBottom: "20px",
	overflowX: "hidden",
})

const OuterGrid = styled(Grid)({
	display: 'flex',
	flexWrap: 'wrap',
	justifyContent: 'space-between',
})

const LeftGrid = styled(Grid)({
	maxWidth: '300px',
	'@media screen and (max-width: 985px)': {
		maxWidth: '200px',
	},
	'@media screen and (max-width: 768px)': {
		display: 'none',
		visibility: 'hidden',
	},
})

const MiddleGrid = styled(Grid)({
	width: 'calc(100vw - 300px)',
	maxWidth: 'unset',
	flexBasis: 'unset',
	paddingRight: '30px !important',
	justifyContent: 'center',
	'@media screen and (max-width: 768px)': {
		width: '100%'
	}
})

const TableRowBox = styled(TableRow)({
	'& th': {
		borderRight: '1px solid rgba(224, 224, 224, 1)',
		textAlign: 'center',
	},
	'& th:first-child': {
		minWidth: '45px',
	},
	'& th:nth-child(2)': {
		minWidth: '120px',
	},
	'& th:nth-child(3)': {
		minWidth: '110px',
	},
	'& th:nth-child(4)': {
		minWidth: '110px',
	},
	'& th:nth-child(5)': {
		minWidth: '90px',
	},
	'& th:nth-child(6)': {
		minWidth: '110px',
	},
})

const TableBodyBox = styled(TableRow)({
	'& td': {
		borderRight: '1px solid rgba(224, 224, 224, 1)',
		textAlign: 'center',
	},
})

const Pagin = styled('div')({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	padding: '10px',
	'@media screen and (max-width:350px)': {
		display: 'initial',
		padding: '10px 1px',
	},
	'@media screen and (min-width:768px) and (max-width:850px)': {
		display: 'initial',
		padding: '10px 1px',
	},
	'& > MuiPaginationItem-icon': {
		color: 'green',
	},

	ul: {
		"& > .MuiPaginationItem-root": {
			color: "green"
		}
	}
})

const CoinsHead = styled("div")({
	margin: "37px 0px 15px 12px",
	fontSize: "20px",
	fontFamily: "HelveticaNeue",
	fontWeight: 600,
	"@media screen and (max-width: 768px)": {
		margin: "20px 0px 10px 12px",
	},
});

const ImportButton = styled('div')({
	cursor: 'pointer',
	marginTop: "16px",
	color: '#1F7244',
	border: '1px solid #1F7244',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	gap: '10px',
	padding: '5px 12px',
	borderRadius: '28px',
	fontSize: '13px',
})

// Customizable Area End

import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

import { toast } from 'react-toastify'


import { sendAPIRequest } from '../../../components/src/utils'
import { setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  autoCompleteStyles:any;
  input:any;
  customBorder:string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  currentLocation: string;
  loading: boolean;
  seed: any; // used for forced re-render
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class MapsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: '',
      currentLocation: '',
      loading: false,
      seed: 0
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      console.log({events: responseJson})
      if (responseJson && responseJson.errors) {
        // this.setState({ refresh: false });
        this.parseApiErrorResponse(responseJson.errors);
        this.parseApiCatchErrorResponse(responseJson.errors);
      } else if (apiRequestCallId === this.getCurrentLocationCallId) {
        if(responseJson.location) {
          await setStorageData("user_currency",responseJson.currency)
          this.setState({ currentLocation: responseJson.location, loading: false, seed: this.state.seed+1 })
        }
      } else if (apiRequestCallId === this.getLocationFromIPCallId) {
        this.setState({ loading: false, seed: this.state.seed+1, currentLocation: this.formatBdcResponse(responseJson) })
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start

  getCurrentLocationCallId: string = ""
  getLocationFromIPCallId: string = ""

  getCurrentLocation = () => {
    if (navigator.geolocation) {
      this.setState({ loading: true })
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords
        const query = new URLSearchParams({
            'data[attributes][latitude]': String(latitude), 
            'data[attributes][longitude]': String(longitude)
          })
        // this.setState({location: {latitude, longitude}})
        const { token } = this.state
        
        this.getCurrentLocationCallId = sendAPIRequest(
            `${configJSON.getPlaceNameEndpoint}?${query}`,
            {
              method: 'GET', 
              headers: {},
            })

      }, 
      () => {
        // @ts-ignore
        // console.warn(arguments)
        // toast.warn("Some error occured fetching your location, try searching.")});
        this.getLocationFromIP()
    }, {timeout: 10000})
    } else {
      // toast.warn("GeoLocation not supported in your browser.")
      this.setState({ loading: false });
      this.getLocationFromIP();
    }
  }
  
  /**
   * Fallback method to fetch location from IP, if `navigator.geolocation` fails
   */
  getLocationFromIP = () => {
    this.setState({ loading: true })
    this.getLocationFromIPCallId = sendAPIRequest(configJSON.bdcApiEndpoint, {
      method: "GET",
      headers: {},
    })
  }

  formatBdcResponse = (responseJson: any) => {
    const localityInfo = responseJson['localityInfo']['administrative'];
    const country = localityInfo[0].name;
    const state = localityInfo[1].name;
    const city = localityInfo[2].name.replace('district', '').trim();
    return `${city}, ${state}, ${country}`;
  }
  // Customizable Area End
}

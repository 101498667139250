Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CfCarbonCalculator";
exports.labelBodyText = "CfCarbonCalculator Body";
exports.getstationaryCombustionTable = "bx_block_cfcarboncalculator/stationary_combustions";
exports.getPurchasedElectricityTable = "bx_block_cfcarboncalculator/ghg_scope2_purchased_electricity";
exports.getTransportationTable = "bx_block_cfcarboncalculator/transportations";
exports.getmobileCombustionTable = "bx_block_cfcarboncalculator/mobile_combustions";
exports.getrefrigerentsTable = "bx_block_cfcarboncalculator/refrigerants";

exports.draftTransportationTable = "bx_block_cfcarboncalculator/transportations/draft";
exports.draftPeTable = "bx_block_cfcarboncalculator/ghg_scope2_purchased_electricity/draft";
exports.draftRfTable = "bx_block_cfcarboncalculator/refrigerants/draft";
exports.draftMcTable = "bx_block_cfcarboncalculator/mobile_combustions/draft";
exports.draftScTable = "bx_block_cfcarboncalculator/stationary_combustions/draft";

exports.saveScTable = "bx_block_cfcarboncalculator/stationary_combustions/submit";
exports.saveMcTable = "bx_block_cfcarboncalculator/mobile_combustions/submit";
exports.saveRfTable = "bx_block_cfcarboncalculator/refrigerants/submit";
exports.savePeTable = 'bx_block_cfcarboncalculator/ghg_scope2_purchased_electricity/submit';
exports.saveTsTable = 'bx_block_cfcarboncalculator/transportations/submit';

exports.btnExampleTitle = "CLICK ME";
exports.labelFilterBy = "Filter by:"
exports.getGhgReportsTable = "bx_block_cfcarboncalculator/ghg_reports";
exports.getGhgReportFilterTable = "bx_block_cfcarboncalculator/ghg_reports/filter";
exports.updateGHGReportTableStatus = "bx_block_cfcarboncalculator/ghg_reports/update_ghg_report" ;
exports.modeOfTransport = "Mode Of Transport";
exports.amountOfActivityType = "Amount of Activity Type";
exports.emissionMethodology = "Emission Calculation Methodology";
exports.numberOfUnits = "Number of units";
exports.emissionco2e = "Emissions (metric tons CO2e)";
exports.totalEmission = "Total Emission";
exports.showAllCards = "bx_block_abilities/abilities/show_esg_journey"
// Customizable Area End
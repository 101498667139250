import React from "react";
// Customizable Area Start
import { styled } from '@material-ui/styles'
import { Card, 
  CardHeader, 
  CardContent, 
  CardActions,
  Checkbox,
  CircularProgress,
  Grid, 
  IconButton, 
  Divider, 
  Button, 
  Typography, 
  TextField,
  Avatar, 
  InputAdornment,
  Menu,
  MenuItem,
  ListItemText, } from '@material-ui/core'

import { ArrowDownIcon } from './assets'
import Flex from '../../../components/src/Flex.web'
import SearchFilter from '../../../components/src/Search.web'
import NestedMenuItem from '../../../components/src/NestedMenuItem.web'
export const configJSON = require("./config");
// Customizable Area End

import FilteritemsController, { Props } from "./FilteritemsController";


export default class Filteritems extends FilteritemsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
 
  render() {
    // Customizable Area Start
    return (
      <>
        <Flex f={2}>
          <SearchFilter onChange={this.handleQueryChange} />
        </Flex>
        <Flex f={2}>
           <FilterMenu
              filterByMe={this.state.filterByMe}
              filterByFav={this.state.filterByFav}
              toggleFilterByMe={this.toggleFilterByMe}
              toggleFilterByFav={this.toggleFilterByFav} 
              handleDateInputFocus={this.handleDateInputFocus} 
              handleDateInputBlur={this.handleDateInputBlur} 
              keepOpen={this.state.selectingDate} 
              menuPosition={this.state.filterMenuPosition} 
              onClose={this.handleFilterMenuClose}
              dateControls={{
                  handleSubmit: this.handleDateFilterSubmit,
                  handleCancel: this.handleDateFilterCancel,
                  handleFromDateChange: this.handleFromDateChange,
                  handleToDateChange: this.handleToDateChange,
                  filterFromDate: this.state.filterFromDate,
                  filterToDate: this.state.filterToDate,
                }}
              /> 
          <TextFieldRounded
            value={this.filterText()}
            onClick={this.handleFilterMenuOpen}
            // @ts-ignore 
            InputProps={{ endAdornment: (<InputAdornment position="start"><img src={ArrowDownIcon} width="16" /></InputAdornment>)
          }} variant="outlined" placeholder={configJSON.labelFilterBy}  />
        </Flex>
      </>
    );
    // Customizable Area End
  }
  
}

// Customizable Area Start
const TextFieldRounded = styled(TextField)({
  width: '100%',
  background: '#FFFFFF',
  borderRadius: '15px',    
  '& > .MuiOutlinedInput-root': {
    borderRadius: '15px',    
  },
  '& > div > input': {
    paddingTop: '14px',
    paddingBottom: '14px'
  }
})

const FilterMenu = ({ menuPosition, onClose, handleDateInputFocus, handleDateInputBlur, keepOpen, dateControls, ...props  }: FilterMenuProps) => {

    return (
  <Menu open={!!menuPosition} anchorPosition={menuPosition} onClose={onClose} anchorReference="anchorPosition">
    <MenuItem selected={props.filterByMe} onClick={props.toggleFilterByMe}>
      Created By me
    </MenuItem>
    <MenuItem selected={props.filterByFav} onClick={props.toggleFilterByFav}>Favourite</MenuItem>
    
    <NestedMenuItem keepOpen={keepOpen} label="Based on Date" parentMenuOpen={!!menuPosition}>
      <FilterByDateWrapper>
        <FilterByDateRow>
          Choose Date
        </FilterByDateRow>
        <FilterByDateRow>
          <span style={{ flex: 1 }}>From</span>
          <div style={{ flex: 3 }}> 
            <TextField 
              type="date" 
              variant="outlined" 
              onChange={dateControls.handleFromDateChange}
              value={dateControls.filterFromDate} 
              placeholder="Select" 
              inputProps={{
                onFocus: handleDateInputFocus,
                onBlur: handleDateInputBlur
              }} />
          </div>
        </FilterByDateRow>
        <FilterByDateRow>
          <span style={{ flex: 1 }}>To</span>
          <div style={{ flex: 3 }}>
            <TextField 
              type="date" 
              variant="outlined" 
              placeholder="Select" 
              onChange={dateControls.handleToDateChange}
              value={dateControls.filterToDate}
              inputProps={{
                onFocus: handleDateInputFocus,
                onBlur: handleDateInputBlur
              }} />
            </div> 
        </FilterByDateRow>
        <FilterByDateRow>
          <div style={{ flex: 1 }}></div>
          <div style={{ flex: 3 }}>
            <CancelButton onClick={dateControls.handleCancel}>Cancel</CancelButton>
            <SubmitButton onClick={dateControls.handleSubmit}>Submit</SubmitButton>
          </div>
        </FilterByDateRow>
      </FilterByDateWrapper>
    </NestedMenuItem>
  </Menu>
)}


interface FilterMenuProps {
  filterByMe: boolean,
  filterByFav: boolean,
  toggleFilterByMe: ()=>void,
  toggleFilterByFav: ()=>void,
  menuPosition: {
    top: number,
    left: number,
  } | undefined
  onClose: ()=>void,
  handleDateInputFocus: any, 
  handleDateInputBlur: any, 
  keepOpen: any,
  dateControls: {
    handleFromDateChange: any,
    handleToDateChange: any,
    handleSubmit: () => void,
    handleCancel: () => void,
    filterFromDate?: string,
    filterToDate?: string,
  }
}


const FilterByDateWrapper = styled('div')({
  width: '300px', 
  height: '200px', 
  padding: '10px 15px',
  background: '#FFF'
})

const FilterByDateRow = styled('div')({
  display: 'flex',
  marginBottom: '10px',
  alignItems: 'center'
})

const SubmitButton = styled(Button)({
  background: '#009c20',
  color: '#FFF',
  '&:hover': {
    background: '#009c20',
    opacity: 0.9
  }
})

const CancelButton = styled(Button)({
  color: '#FFF',
  background: '#999',
  marginRight: '5px',
  '&:hover': {
    background: '#999',
    opacity: 0.9
  }
})
// Customizable Area End
